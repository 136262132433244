import GoogleMap from 'components/common/GoogleMap';
import { isNil, map } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { filteredLeases } from 'selectors/lease';
import GoogleMapUtils from 'components/common/GoogleMap/utils';
import { DOMLegend } from '../Shared/MapLegends';

const VACANT = 'Vacant';
const ON_THE_MARKET = 'On the Market';

function InfoWindow({ photo, dom, bed, bath, sqft, address }) {
  const stopPropagation = (e) => e.stopPropagation();

  return (
    <div onClick={stopPropagation} className="z-50 cursor -top-24 relative w-60 h-auto left-10 block bg-white border rounded overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
      <div className="rounded overflow-hidden shadow-lg h-full flex flex-col">
        <div className="relative group h-40">
          <img className="w-full h-full object-cover" src={photo} alt="House" referrerPolicy="no-referrer" />
        </div>
        <div className="p-3">
          <div className="font-bold text-sm mb-2">{`${dom} DOM`}</div>
          <p className="text-gray-700 text-xs">
            {`${bed} Beds | ${bath} Baths | ${sqft} Sq.Ft`}
          </p>
          <p className="text-gray-700 text-sm mt-1">{address}</p>
        </div>
      </div>
    </div>
  );
}

function Map() {
  const { activeFilters } = useSelector(state => state.dashboardFilter);
  const leaseData = useSelector(filteredLeases);
  const [filteredData, setFilteredData] = useState([]);
  const [places, setPlaces] = useState();
  const [defaultCenter, setDefaultCenter] = useState();

  useEffect(() => {
    const newData = leaseData.filter(lease => {
      const hasLatLong = !isNil(lease.attributes.property.latitude) && !isNil(lease.attributes.property.latitude);
      const isVacant = lease.attributes.occupancyStatus === VACANT;
      const isOnMarket = lease.attributes.propertyStatus === ON_THE_MARKET;
      return hasLatLong && isVacant && isOnMarket;
    });

    setFilteredData(newData);
  }, [leaseData, activeFilters]);

  useEffect(() => {
    const mappedPlaces = map(filteredData, (lease) => ({
      ...lease.attributes,
      ...lease.attributes.property,
      active: false,
      id: lease.id,
      lat: Number(lease.attributes.property?.latitude || lease.latitude),
      lng: Number(lease.attributes.property?.longitude || lease.longitude),
      showInfoWindow: false,
      subject: false,
      text: lease.attributes.property?.address || lease.address,
      markerColor: lease.attributes.dom < 100 ? '#0C4599' : 'red',
    }));
    setPlaces(mappedPlaces);
    if (mappedPlaces.length > 0) {
      setDefaultCenter({ lat: mappedPlaces[0]?.lat, lng: mappedPlaces[0]?.lng });
    }
  }, [filteredData, activeFilters]);

  if (filteredData.length === 0) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-center border border-gray-300 rounded-lg p-4 max-w-md mx-auto">
          <p className="text-lg font-semibold text-gray-700">No Data Available</p>
          <p className="text-gray-500">
            There are no available properties matching your criteria.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full">
      {places && defaultCenter && (
        <GoogleMap
          defaultCenter={defaultCenter}
          hoveredId={null}
          infoWindow={InfoWindow}
          legendComponent={<DOMLegend />}
          places={places}
          mapType={GoogleMapUtils.MAP_TYPE_ROADMAP}
        />
      )}
    </div>
  );
}

export default Map;
