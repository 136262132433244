import { camelCaseKeys } from '../components/utils';
import { apiSlice } from './apiSlice';
import { IMAGES_TAG } from './utils';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchImage: builder.query({
        query: ({ id }) => `/api/images/${id}`,
        transformResponse: response => camelCaseKeys(response),
        providesTags: (result, error, arg) => [{ type: IMAGES_TAG, id: arg.id }],
      }),
    };
  },
});

export const {
  useFetchImageQuery,
} = extendedApiSlice;
