import cx from 'classnames';
import RoundButton from './common/RoundButton';
import { X } from './icons';

export default function Alert({ children, className, text, type, wide, onDismissClick }) {
  // spell out entire class names so they get compiled into CSS classes
  // ref: https://tailwindcss.com/docs/optimizing-for-production
  const style = {
    success: 'border-green-300 bg-green-100',
    warning: 'border-yellow-300 bg-yellow-100',
    info: 'border-blue-300 bg-blue-100',
    danger: 'border-red-300 bg-red-100',
    unauthorized: 'border-red-300 bg-red-100',
  }[type];
  return (
    <div
      className={cx(
        'flex content-center items-center border-l-8 text-gray-800 rounded-sm shadow',
        style,
        className,
        {
          'px-4 py-2': !wide,
          'p-6': wide,
          'pr-16': wide && onDismissClick,
          'pr-10': !wide && onDismissClick,
          'relative': onDismissClick,
        },
      )}
    >
      {children || text}
      {onDismissClick && (
        <RoundButton onClick={onDismissClick} padding="absolute top-4 right-4 p-2">
          <X className="h-6" />
        </RoundButton>
      )}
    </div>
  );
}
