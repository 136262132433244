import { formatDate } from 'components/utils';
import {
  convertInchesToTwip,
  BorderStyle,
  ImageRun,
  TabStopType,
  AlignmentType,
  UnderlineType,
  WidthType,
  TextRun,
  Tab,
  Paragraph,
  TableCell,
  TableRow,
  ExternalHyperlink,
  Header,
} from 'docx';
import { isNil } from 'lodash';

export const DEFAULT_CELL_WIDTH = {
  width: {
    size: 5505,
    type: WidthType.DXA,
  },
};

export const cellMargin = {
  top: convertInchesToTwip(0.03),
  bottom: convertInchesToTwip(0.03),
  left: convertInchesToTwip(0.04),
  right: convertInchesToTwip(0.04),
};

export const NO_MARGIN = {
  type: WidthType.NIL,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

export const tableCellTopBorder = {
  top: {
    style: BorderStyle.SINGLE,
    size: 5,
    color: 'FFFFFF',
  },
};

export const tableCellRightBorder = {
  right: {
    style: BorderStyle.SINGLE,
    size: 5,
    color: 'FFFFFF',
  },
};

export const tableCellLeftBorder = {
  left: {
    style: BorderStyle.SINGLE,
    size: 5,
    color: 'FFFFFF',
  },
};

export const tableCellBottomBorder = {
  bottom: {
    style: BorderStyle.SINGLE,
    size: 5,
    color: 'FFFFFF',
  },
};

export const DEFAULT_BORDER_STYLE = {
  style: BorderStyle.SINGLE,
  size: 4,
  color: 'auto',
};

export const DEFAULT_TABLE_BORDERS = {
  top: DEFAULT_BORDER_STYLE,
  bottom: DEFAULT_BORDER_STYLE,
  left: DEFAULT_BORDER_STYLE,
  right: DEFAULT_BORDER_STYLE,
  insideHorizontal: DEFAULT_BORDER_STYLE,
  insideVertical: DEFAULT_BORDER_STYLE,
};

export const DEFAULT_TABLE_MARGINS = {
  type: WidthType.NIL,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

// http://officeopenxml.com/WPSectionPgMar.php
export const pageMargin = {
  top: convertInchesToTwip(0.5), // space between top of page to top of text body
  right: convertInchesToTwip(0.2),
  bottom: convertInchesToTwip(0.3),
  left: convertInchesToTwip(0.2),
  header: convertInchesToTwip(0.2), // space between top of page to top of header
};

// US letter size
export const PAGE_WIDTH = convertInchesToTwip(8.5);
export const PAGE_HEIGHT = convertInchesToTwip(11);

const convertInchesToEmu = (inches) => inches * 914400;

const calcImageAspectRatio = async (imageDataUri) => {
  const decoded = new Image();
  decoded.src = imageDataUri;
  try {
    await decoded.decode();
    return decoded.naturalWidth / decoded.naturalHeight;
  } catch (e) {
    console.error(e);
    return 1;
  }
};

export const imageRunFromUrl = async (url, widthInches, aspectRatio = null, fetchOptions = null) => {
  const image = await fetch(url, fetchOptions);
  const imageBlob = await image.blob();

  let imgAspectRatio = aspectRatio;
  if (isNil(aspectRatio)) {
    let imageDataUri;
    try {
      imageDataUri = URL.createObjectURL(imageBlob);
      imgAspectRatio = calcImageAspectRatio(imageDataUri);
    } finally {
      if (imageDataUri !== undefined) {
        URL.revokeObjectURL(imageDataUri);
      }
    }
  }

  const imgWidth = convertInchesToEmu(widthInches) / 9525;

  return new ImageRun({
    data: imageBlob,
    transformation: {
      width: imgWidth,
      height: imgWidth / imgAspectRatio,
    },
  });
};

export const imageRunFromDataUri = async (dataUri, widthInches, aspectRatio = null) => {
  const imgAspectRatio = aspectRatio ?? await calcImageAspectRatio(dataUri);
  const imgWidth = convertInchesToEmu(widthInches) / 9525;

  return new ImageRun({
    data: dataUri,
    transformation: {
      width: imgWidth,
      height: imgWidth / imgAspectRatio,
    },
  });
};

export const makeExternalHyperlink = (label, destination, style = 'Hyperlink') => (
  new ExternalHyperlink({
    children: [new TextRun({ text: label, style })],
    link: destination,
  })
);

export const tableHeader = (text, columnSpan, center = false, fill = '09336b') => (
  new TableRow({
    tableHeader: true,
    children: [
      new TableCell({
        width: { size: 100, type: WidthType.PERCENTAGE },
        columnSpan,
        children: [new Paragraph({
          text,
          ...(center && { alignment: AlignmentType.CENTER }),
        })],
        margins: cellMargin,
        shading: { fill, color: 'FFFFFF' },
      }),
    ],
  })
);

export const tableProperties = {
  width: { size: 100, type: WidthType.PERCENTAGE },
  borders: {
    ...DEFAULT_TABLE_BORDERS,
    insideVertical: { style: BorderStyle.NONE, size: 0 },
  },
  margins: DEFAULT_TABLE_MARGINS,
};

export const DEFAULT_STYLE = { size: 18 };
export const UNDERLINE_STYLE = {
  ...DEFAULT_STYLE,
  underline: { type: UnderlineType.SINGLE },
};
export const BOLD_STYLE = {
  ...DEFAULT_STYLE,
  bold: true,
};
export const HEADER_STYLE = {
  ...DEFAULT_STYLE,
  ...BOLD_STYLE,
  ...UNDERLINE_STYLE,
};

export const makeTableRow = (rowHead, rowHeadColspan, data, dataColSpan = 1, style = DEFAULT_STYLE, showTopBorder = false) => (
  new TableRow({
    children: [
      new TableCell({
        margins: cellMargin,
        borders: { ...(!showTopBorder && tableCellTopBorder), ...tableCellRightBorder },
        columnSpan: rowHeadColspan,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: rowHead,
                ...style,
              }),
            ],
          }),
        ],
      }),
      ...data.map((value, index) => (
        new TableCell({
          margins: cellMargin,
          borders: { ...(!showTopBorder && tableCellTopBorder), ...(data.length === index + 1 ? null : tableCellRightBorder) },
          columnSpan: dataColSpan,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: value,
                  ...style,
                }),
              ],
              alignment: AlignmentType.RIGHT,
            }),
          ],
        })
      )),
    ],
  })
);

export const buildHeader = (deal) => {
  const { data: { attributes: { name, organization, portfolio } } } = deal;
  return new Header({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            text: organization.name,
            bold: true,
            color: '0000FF',
            size: 30,
            font: 'Calibri',
          }),
          new TextRun({
            children: [
              new Tab(),
              formatDate(new Date(), 'M/d/yyyy'),
            ],
            font: 'Calibri',
          }),
          new TextRun({
            text: `${portfolio.name} — ${name}`,
            bold: true,
            color: '0000FF',
            size: 20,
            font: 'Calibri',
            break: 1,
          }),
        ],
        tabStops: [
          {
            type: TabStopType.RIGHT,
            position: PAGE_WIDTH - pageMargin.left - pageMargin.right,
          },
        ],
      }),
    ],
  });
};
