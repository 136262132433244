import { UNIT_RENT_SOURCE_ZILLOW } from 'components/constants';
import { formatListingSource } from 'components/utils';

function MlsSource({ onClick, getValue, row: { original } }) {
  return (
    <div className="w-max text-primary underline cursor-pointer" onClick={() => onClick(original)}>{formatListingSource(getValue())}</div>
  );
}

function LinkableSource({ getValue, row: { original: { sourceName } } }) {
  // if relative address, prepend zillow domain
  const linkUrl = ((sourceName === UNIT_RENT_SOURCE_ZILLOW) && (getValue().charAt(0) === '/')) ? `https://www.zillow.com${getValue()}` : getValue();

  return (
    <a
      className="w-max text-primary underline"
      href={linkUrl}
      target="_blank"
      rel="noreferrer"
    >
      {sourceName}
    </a>
  );
}

export default function SourceCell(props) {
  const { getValue, row: { original } } = props;
  // if listingId is present, assume its an MLS listing and render as MLS source
  if (original.listingId) {
    return <MlsSource {...props} />;
  }

  if (getValue('sourceLink')) {
    return <LinkableSource {...props} />;
  }

  return getValue() || '';
}
