/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeRentCompSetId: null,
  allComps: null,
  baseSelectedRentComps: null,
  filteredRentCompIds: null,
  selectedRentCompIds: null,
};

const rentCompsSlice = createSlice({
  name: 'rentComps',
  initialState,
  reducers: {
    setActiveRentCompSetId: (state, { payload }) => {
      state.activeRentCompSetId = payload;
      state.allComps = null;
      state.baseSelectedRentComps = null;
      state.selectedRentCompIds = null;
      state.filteredRentCompIds = null;
    },
    setAllComps: (state, { payload }) => {
      state.allComps = payload;
    },
    setBaseSelectedRentComps: (state, { payload }) => {
      state.baseSelectedRentComps = payload;
      state.selectedRentCompIds = payload;
    },
    setFilteredRentCompIds: (state, { payload }) => {
      state.filteredRentCompIds = payload;
    },
    setSelectedRentCompIds: (state, { payload }) => {
      state.selectedRentCompIds = payload;
    },
  },
});

export const {
  setActiveRentCompSetId,
  setAllComps,
  setBaseSelectedRentComps,
  setFilteredRentCompIds,
  setSelectedRentCompIds,
} = rentCompsSlice.actions;

export default rentCompsSlice.reducer;
