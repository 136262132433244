/* eslint-disable no-nested-ternary */
import cx from 'classnames';
import { LAYOUT, MODELLED_INDIVIDUALLY } from 'components/constants';
import {
  calcCashFlowsOfIndividualScenarios,
  calcOpExRatio,
  calcReturnMetrics,
  calcReturnMetricsOfIndividualScenarios,
  calcSourcesAndUses,
  CUSTOM_EXIT_PRICE,
  EXPENSE_METHOD_ITEMIZED,
} from 'components/dcf/dcf';
import { LOAN_SIZING_FORMATTING_METHOD, LOAN_SIZING_PARAM_KEY } from 'components/dcf/debt';
import { annualizeMonthlyReturns } from 'components/finance';
import { Arrow } from 'components/icons';
import Map from 'components/property/components/Map';
import MapModal from 'components/property/components/MapModal';
import Badge from 'components/shared/Badge';
import DataTable from 'components/shared/Table/DataTable';
import {
  dotProductBy,
  formatCurrency,
  formatCurrencyAbbreviated,
  formatDate,
  formatEquityMultiple,
  formatInteger,
  formatPercentage,
  sumArrays,
  titleCase,
} from 'components/utils';
import { every, filter, groupBy, isNil, map, meanBy, range, round, sum, sumBy, uniqWith } from 'lodash';
import { useMemo, useState } from 'react';
import { childScenarios } from './portfolio-deal.utils';

const DEFAULT_MAP_OPTIONS = {
  fullscreenControl: true,
  zoomControl: false,
  streetViewControl: false,
  keyboardShortcuts: false,
  zoom: 12,
  minZoom: 7,
  mapTypeControl: true,
  mapTypeControlOptions: {
    mapTypeIds: ['roadmap', 'satellite', 'hybrid'],
  },
};

function StatCell({ label, value }) {
  return (
    <div className="px-4 first:pl-0 first:pr-4 last:pl-4 last:pr-0 text-center">
      <div className="text-lg">{value || '-'}</div>
      <div className="text-xs font-light">{label}</div>
    </div>
  );
}

function StatRow({ stats }) {
  // ignore stats with null values
  const cleanedStats = filter(stats, 'value');
  return (
    <div className="w-full flex justify-start items-center divide-x">
      {cleanedStats.map(stat => (
        <StatCell key={stat.label} {...stat} />
      ))}
    </div>
  );
}

function ReturnsSection({ returnMetrics }) {
  if (isNil(returnMetrics)) return null;

  const {
    leveredIrr,
    unleveredIrr,
    leveredEquityMultiple,
    unleveredEquityMultiple,
    leveredAverageCashOnCash,
    unleveredAverageCashOnCash,
    grossStabilizedYield,
    stabilizedYield,
  } = returnMetrics;

  const firstRowStats = [
    {
      label: 'Gross Stabilized Yield',
      value: formatPercentage(grossStabilizedYield, 2),
    },
    {
      label: 'Levered Avg. Cash-on-Cash',
      value: formatPercentage(leveredAverageCashOnCash, 2),
    },
    {
      label: 'Levered IRR',
      value: formatPercentage(leveredIrr, 2),
    },
    {
      label: 'Levered Equity Multiple',
      value: formatEquityMultiple(leveredEquityMultiple, 2),
    },
  ];

  const secondRowStats = [
    {
      label: 'Net Stabilized Yield',
      value: formatPercentage(stabilizedYield, 2),
    },
    {
      label: 'Unlevered Avg. Cash-on-Cash',
      value: formatPercentage(unleveredAverageCashOnCash, 2),
    },
    {
      label: 'Unlevered IRR',
      value: formatPercentage(unleveredIrr, 2),
    },
    {
      label: 'Unlevered Equity Multiple',
      value: formatEquityMultiple(unleveredEquityMultiple, 2),
    },
  ];

  return (
    <div className="bg-white p-3 rounded-lg w-max">
      <StatRow stats={firstRowStats} />
      <div className="mt-6">
        <StatRow stats={secondRowStats} />
      </div>
    </div>
  );
}

function ParametersSection({ cashFlows, model, properties, returnMetrics }) {
  const { dcfParams } = model;
  const {
    closingDate,
    customExitPrice,
    exitCapRate,
    exitPriceMethod,
    holdPeriod,
  } = dcfParams;
  const {
    acquisition,
    capital,
    expenses,
    sale,
  } = cashFlows;

  const opExRatio = calcOpExRatio(cashFlows);

  const acqCost = sum(acquisition.cost);
  const saleCost = sum(sale.cost);
  const totalRenovationCost = sum(sumArrays(capital.belowTheLineExpenses.map(v => v * -1), capital.total));

  const firstRowStats = [
    {
      label: 'Closing Date',
      value: formatDate(closingDate),
    },
    {
      label: 'Acq. Cost',
      value: formatCurrency(Math.abs(acqCost)),
    },

    {
      label: 'Hold Period',
      value: `${holdPeriod} Years`,
    },
    (exitPriceMethod === CUSTOM_EXIT_PRICE) ? {
      label: 'Sale Price',
      value: formatCurrency(customExitPrice),
    } : {
      label: 'Exit Cap Rate',
      value: formatPercentage(exitCapRate),
    },
    {
      label: 'Closing Cost',
      value: formatCurrency(Math.abs(saleCost)),
    },
  ];

  const secondRowStats = [
    {
      label: 'Total Renovation Cost',
      value: formatCurrency(Math.abs(totalRenovationCost)),
    },
    {
      label: 'Stabilization Month',
      value: returnMetrics.stabilizationMonth,
    },
    {
      label: 'OpEx Ratio',
      value: formatPercentage(opExRatio),
    },
  ];

  if (model.dcfParams.expenseMethod === EXPENSE_METHOD_ITEMIZED) {
    const avgTaxes = sum(expenses.taxes) / properties.length / 12 / holdPeriod;
    secondRowStats.push(...[
      {
        label: 'Avg. Taxes',
        value: formatCurrency(avgTaxes),
      },
    ]);
  }

  const { loanSizingMethod } = dcfParams;
  const thirdRowStats = [
    {
      label: 'Loan Type',
      value: loanSizingMethod.toUpperCase(),
    },
    {
      label: 'Loan Size',
      value: LOAN_SIZING_FORMATTING_METHOD[loanSizingMethod](dcfParams[LOAN_SIZING_PARAM_KEY[loanSizingMethod]]),
    },
    {
      label: 'Loan Rate',
      value: formatPercentage(dcfParams.couponRate),
    },
  ];

  return (
    <div className="bg-white p-3 rounded-lg">
      <div className="text-xs text-gray-600 mb-3 italic">Entry / Exit</div>
      <StatRow stats={firstRowStats} />
      <div className="mt-6">
        <div className="text-xs text-gray-600 mb-3 italic">CapEx & Expenses</div>
        <StatRow stats={secondRowStats} />
      </div>
      <div className="mt-6">
        <div className="text-xs text-gray-600 mb-3 italic">Financing</div>
        <StatRow stats={thirdRowStats} />
      </div>
    </div>
  );
}

const getPortfolioScenarios = (deal, model) => ((deal.modellingMethod === MODELLED_INDIVIDUALLY)
  ? childScenarios({ scenarios: model.scenarios })
  : [model.scenario]);

const getStatsForUnits = (properties, units, scenarios, multiplicities) => {
  const totalMultiplicity = multiplicities !== undefined ? sum(Object.values(multiplicities)) : 0;
  const calcMean = (collection, key) => (
    multiplicities !== undefined ? (
      sum(map(collection, key).map((value, idx) => value * multiplicities[idx])) / totalMultiplicity
    ) : (
      meanBy(collection, key)
    )
  );

  return {
    properties: multiplicities !== undefined ? totalMultiplicity : properties.length,
    units: multiplicities !== undefined ? totalMultiplicity : units.length,
    bedrooms: calcMean(units, 'bedrooms'),
    bathrooms: calcMean(units, 'bathrooms'),
    sqft: calcMean(units, 'rsf'),
    vintage: calcMean(properties, 'yearBuilt'),
    price: calcMean(scenarios, 'parameters.purchasePrice'),
    rent: calcMean(units, 'marketRent'),
  };
};

const NO_MARKET_ASSIGNED_LABEL = 'No Market Assigned';

const getPropertyBreakdownTableData = (deal, properties, scenarios) => {
  const propertiesByMarket = groupBy(properties, p => p.market || NO_MARKET_ASSIGNED_LABEL);
  if (deal.modellingMethod === MODELLED_INDIVIDUALLY) {
    const tableData = Object.keys(propertiesByMarket).map(market => {
      const marketProperties = propertiesByMarket[market];
      const propertyIds = marketProperties.map(p => p.id);
      const marketScenarios = scenarios.filter(s => propertyIds.includes(s.propertyId));
      const marketUnits = marketScenarios.flatMap(scenario => scenario.parameters.units);
      return { market, ...getStatsForUnits(marketProperties, marketUnits, marketScenarios, marketScenarios.map(({ parameters: { multiplicity } }) => multiplicity ?? 1)) };
    });

    const allUnits = scenarios.flatMap(scenario => scenario.parameters.units);
    if (Object.keys(propertiesByMarket).length > 1) {
      tableData.push({ market: 'All', ...getStatsForUnits(properties, allUnits, scenarios, scenarios.map(({ parameters: { multiplicity } }) => multiplicity ?? 1)) });
    }
    return tableData;
  } else {
    const primaryScenario = scenarios.find(s => s.primary);
    const allUnits = primaryScenario.parameters.units;

    const tableData = Object.keys(propertiesByMarket).map(market => {
      const marketProperties = propertiesByMarket[market];
      const propertyAddresses = marketProperties.map(p => p.address);
      const marketUnits = allUnits.filter(u => propertyAddresses.includes(u.address));
      return { market, ...getStatsForUnits(marketProperties, marketUnits, [primaryScenario]) };
    });

    // only show All column if there are multiple markets
    if (Object.keys(propertiesByMarket).length > 1) {
      tableData.push({ market: 'All', ...getStatsForUnits(properties, allUnits, [primaryScenario]) });
    }
    return tableData;
  }
};

function ByMarketStats({ deal, properties, scenarios }) {
  const tableData = getPropertyBreakdownTableData(deal, properties, scenarios);

  const columns = [
    {
      header: 'Market',
      accessorKey: 'market',
      enableSorting: false,
    },
    {
      header: 'Properties',
      accessorKey: 'properties',
      enableSorting: false,
      meta: { textAlign: 'center' },
    },
  ];
  // only add units column if there are properties with multiple units
  if (tableData.find(rowData => rowData.properties !== rowData.units)) {
    columns.push({
      header: 'Units',
      accessorKey: 'units',
      enableSorting: false,
      meta: { textAlign: 'center' },
    });
  }

  columns.push(...[
    {
      header: 'Avg. Beds',
      accessorKey: 'bedrooms',
      cell: ({ getValue }) => (Number.isFinite(getValue()) ? round(getValue(), 1) : '-'),
      enableSorting: false,
      meta: { textAlign: 'center' },
    },
    {
      header: 'Avg. Baths',
      accessorKey: 'bathrooms',
      cell: ({ getValue }) => (Number.isFinite(getValue()) ? round(getValue(), 1) : '-'),
      enableSorting: false,
      meta: { textAlign: 'center' },
    },
    {
      header: 'Avg. Sq Ft',
      accessorKey: 'sqft',
      cell: ({ getValue }) => formatInteger(getValue()),
      enableSorting: false,
      meta: { textAlign: 'center' },
    },
    {
      header: 'Avg. Vintage',
      accessorKey: 'vintage',
      cell: ({ getValue }) => (Number.isFinite(getValue()) ? round(getValue(), 0) : '-'),
      enableSorting: false,
      meta: { textAlign: 'center' },
    },
  ]);

  if (deal.modellingMethod === MODELLED_INDIVIDUALLY) {
    columns.push({
      header: 'Avg. Price',
      accessorKey: 'price',
      cell: ({ getValue }) => formatCurrencyAbbreviated(getValue()),
      enableSorting: false,
      meta: { textAlign: 'center' },
    });
  }

  columns.push({
    header: 'Avg. Rent',
    accessorKey: 'rent',
    cell: ({ getValue }) => formatCurrency(getValue()),
    enableSorting: false,
    meta: { textAlign: 'center' },
  });

  return (
    <div className="w-full">
      <div className="mb-2">Property Breakdown</div>
      <div className="bg-white p-3">
        <DataTable
          data={tableData}
          columns={columns}
          tdClassName="py-2"
          trClassName={row => ((row.original.market === 'All') ? 'italic' : null)}
        />
      </div>
    </div>
  );
}

function CashFlowSummary({ cashFlows, model, returnMetrics }) {
  const { holdPeriod } = model.dcfParams;
  const cashFlowAnnualRange = range(0, holdPeriod + 1);
  const headerRow = (
    <tr>
      <th className="font-medium h-10 px-2 border-r border-b">Year</th>
      {cashFlowAnnualRange.map(year => (
        <th key={year} className="font-medium h-10 px-2 border-b text-center">{year}</th>
      ))}
    </tr>
  );

  const makeTableRow = ({ label, data, formatter }) => (
    <tr>
      <td className="px-2 py-1 border-r">{label}</td>
      {data.map((d, index) => (
        <td key={index} className="px-2 py-1 text-right">{d ? (formatter ? formatter(d) : d) : null}</td>
      ))}
    </tr>
  );

  const entryExitData = [...cashFlowAnnualRange].fill(null);
  entryExitData[0] = cashFlows.acquisition.price[0];
  entryExitData[entryExitData.length - 1] = cashFlows.sale.price.slice(-1)[0];
  const annualizedNois = annualizeMonthlyReturns(cashFlows.netOperatingIncome, holdPeriod * 12).slice(0, holdPeriod);

  return (
    <div className="w-full overflow-auto bg-white p-3 rounded-lg">
      <table className="text-sm">
        <thead>
          {headerRow}
        </thead>
        <tbody>
          {makeTableRow({
            label: 'Entry / Exit',
            data: entryExitData,
            formatter: formatCurrency,
          })}
          {makeTableRow({
            label: 'NOI',
            data: [null, ...annualizedNois],
            formatter: formatCurrency,
          })}
          {makeTableRow({
            label: 'Unlevered Cash-on-Cash',
            data: [null, ...returnMetrics.annualizedUnleveredCashOnCash],
            formatter: formatPercentage,
          })}
          {makeTableRow({
            label: 'Levered Cash-on-Cash',
            data: [null, ...returnMetrics.annualizedLeveredCashOnCash],
            formatter: formatPercentage,
          })}
        </tbody>
      </table>
    </div>
  );
}

function SourcesAndUses({ cashFlows }) {
  const {
    totalEquity,
    totalFinancing,
    totalSources,
    purchasePrice,
    acquisitionCost,
    originationFee,
    totalAcquisitionCapital,
    followOnCapitalProjects,
    totalUses,
  } = calcSourcesAndUses(cashFlows);

  return (
    <table className="w-full text-sm bg-white p-3 rounded-lg">
      <thead>
        <tr>
          <th className="font-medium h-10 px-2 border-b">Sources</th>
          <th className="font-medium h-10 px-2 border-b">$</th>
          <th className="font-medium h-10 px-2 border-b border-r">%</th>
          <th className="font-medium h-10 px-2 border-b">Uses</th>
          <th className="font-medium h-10 px-2 border-b">$</th>
          <th className="font-medium h-10 px-2 border-b">%</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-2 py-1">Total Equity</td>
          <td className="px-2 py-1 text-right">{formatCurrency(totalEquity)}</td>
          <td className="px-2 py-1 text-right border-r">{formatPercentage(totalEquity / totalSources)}</td>
          <td className="px-2 py-1">Purchase Price</td>
          <td className="px-2 py-1 text-right">{formatCurrency(purchasePrice)}</td>
          <td className="px-2 py-1 text-right">{formatPercentage(purchasePrice / totalUses)}</td>
        </tr>
        <tr>
          <td className="px-2 py-1">Total Financing</td>
          <td className="px-2 py-1 text-right">{formatCurrency(totalFinancing)}</td>
          <td className="px-2 py-1 border-r text-right">{formatPercentage(totalFinancing / totalSources)}</td>
          <td className="px-2 py-1">Acquisition Costs</td>
          <td className="px-2 py-1 text-right">{formatCurrency(acquisitionCost)}</td>
          <td className="px-2 py-1 text-right">{formatPercentage(acquisitionCost / totalUses)}</td>
        </tr>
        <tr>
          <td />
          <td />
          <td className="px-2 py-1 border-r text-right" />
          <td className="px-2 py-1">Acq. Origination Fees</td>
          <td className="px-2 py-1 text-right">{formatCurrency(originationFee)}</td>
          <td className="px-2 py-1 text-right">{formatPercentage(originationFee / totalUses)}</td>
        </tr>
        <tr>
          <td />
          <td />
          <td className="px-2 py-1 border-r text-right" />
          <td className="px-2 py-1 border-t border-b">Total Acquisition Capital</td>
          <td className="px-2 py-1 border-t border-b text-right">{formatCurrency(totalAcquisitionCapital)}</td>
          <td className="px-2 py-1 border-t border-b text-right">{formatPercentage(totalAcquisitionCapital / totalUses)}</td>
        </tr>
        <tr>
          <td />
          <td />
          <td className="px-2 py-1 border-r text-right" />
          <td className="px-2 py-1">Follow On Capital Projects</td>
          <td className="px-2 py-1 text-right">{formatCurrency(followOnCapitalProjects)}</td>
          <td className="px-2 py-1 text-right">{formatPercentage(followOnCapitalProjects / totalUses)}</td>
        </tr>
        <tr>
          <td className="px-2 py-1 border-t">Total Sources</td>
          <td className="px-2 py-1 text-right border-t">{formatCurrency(totalSources)}</td>
          <td className="px-2 py-1 border-t border-r" />
          <td className="px-2 py-1 border-t">Total Uses</td>
          <td className="px-2 py-1 text-right border-t">{formatCurrency(totalUses)}</td>
          <td className="px-2 py-1 border-t" />
        </tr>
      </tbody>
    </table>
  );
}

function PriceStat({ className, label, price, numberOfUnits, rsf }) {
  const pricePerUnit = numberOfUnits ? (price / numberOfUnits) : null;
  const pricePerSqFt = rsf ? (price / rsf) : null;

  return (
    <div className={cx('text-center', className)}>
      <div className="text-xs text-gray-500 mb-2">{label}</div>
      <div>{formatCurrency(price)}</div>
      <div className="text-xs text-gray-600 my-1">{pricePerUnit && `${formatCurrency(pricePerUnit)} / Unit`}</div>
      <div className="text-xs text-gray-600">{pricePerSqFt && `${formatCurrency(pricePerSqFt)} PSF`}</div>
    </div>
  );
}

function PropertiesSummary({ deal, model, properties }) {
  const scenarios = getPortfolioScenarios(deal, model);

  return (
    <div className="w-full flex justify-start items-center mt-6">
      <ByMarketStats deal={deal} properties={properties} scenarios={scenarios} />
    </div>
  );
}

function PriceSummary({ deal, model, properties }) {
  const { scenario } = model;

  let numberOfUnits = scenario.parameters.units.length;
  let totalRsf = sumBy(scenario.parameters.units, 'rsf');
  let purchasePrice = scenario?.parameters?.purchasePrice || 1;
  let listPrice = scenario?.parameters?.listPrice || 1;

  if (deal.modellingMethod === MODELLED_INDIVIDUALLY) {
    const individualScenarios = childScenarios({ scenarios: model.scenarios, primaryOnly: true });
    numberOfUnits = dotProductBy(individualScenarios, 'parameters.units.length', ({ parameters: { multiplicity } }) => multiplicity ?? 1);
    purchasePrice = dotProductBy(individualScenarios, 'parameters.purchasePrice', ({ parameters: { multiplicity } }) => multiplicity ?? 1);
    listPrice = dotProductBy(individualScenarios, 'parameters.listPrice', ({ parameters: { multiplicity } }) => multiplicity ?? 1);
    totalRsf = dotProductBy(individualScenarios, ({ parameters: { units } }) => sumBy(units, 'rsf'), ({ parameters: { multiplicity } }) => multiplicity ?? 1);
  }

  const bidAskPercentDiff = (purchasePrice > 0) ? (1 - (purchasePrice / listPrice)) : null;
  let avm = null;
  if (every(properties, p => p.data?.avm)) {
    avm = sumBy(properties, 'data.avm.estimatedValueAmount');
  }

  return (
    <div className="flex w-max items-center divide-x mb-3 bg-white p-3 rounded-lg">
      <PriceStat
        className="pr-6"
        label="Ask Price"
        price={listPrice}
        numberOfUnits={numberOfUnits}
        rsf={totalRsf}
      />
      <div className="flex justify-between px-6">
        <PriceStat
          label="Bid Price"
          price={purchasePrice}
          numberOfUnits={numberOfUnits}
          rsf={totalRsf}
        />
        {(isNil(bidAskPercentDiff) || (bidAskPercentDiff === 0)) ? null : (
          <div className="h-fit mt-7 pl-2 flex items-center text-xs text-gray-600">
            <Arrow className="fill-gray-600 w-4" fill={null} direction={(bidAskPercentDiff > 0) ? 'down' : 'up'} />
            {`${formatPercentage(Math.abs(bidAskPercentDiff))} Ask`}
          </div>
        )}
      </div>
      <PriceStat
        className="px-6"
        label="Acquistion Cost"
        price={scenario.returnMetrics.unleveredBasis[0]}
        numberOfUnits={numberOfUnits}
        rsf={totalRsf}
      />
      {avm ? (
        <PriceStat
          className="pl-6"
          label="AVM"
          price={avm}
          numberOfUnits={numberOfUnits}
          rsf={totalRsf}
        />
      ) : null}
    </div>
  );
}

export default function PortfolioDealOverview({ context }) {
  const { data, modelData } = context;
  const { deal, properties } = data;
  const { model } = modelData;

  const [showMapModal, setShowMapModal] = useState(false);

  let { cashFlows } = model;
  let returnMetrics;
  if (deal.modellingMethod === MODELLED_INDIVIDUALLY) {
    const scenarios = getPortfolioScenarios(deal, model);
    cashFlows = calcCashFlowsOfIndividualScenarios(scenarios);
    returnMetrics = calcReturnMetricsOfIndividualScenarios(scenarios, cashFlows);
  } else {
    const { dcfParams } = model;
    returnMetrics = calcReturnMetrics(cashFlows, dcfParams);
  }

  const mapProperties = useMemo(() => (
    uniqWith(
      properties,
      ({ latitude: latLeft, longitude: lngLeft }, { latitude: latRight, longitude: lngRight }) => (
        latLeft === latRight && lngLeft === lngRight
      ),
    )
  ), [properties]);

  return (
    <div
      className="overflow-auto bg-gray-100"
      style={{
        width: `calc(100vw - ${LAYOUT.rightNavWidth + LAYOUT.sidebarWidth + LAYOUT.portfolioSidebarWidth}px)`,
        height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)`,
      }}
    >
      <div className="grid grid-cols-5 h-128">
        <div className="col-span-3 p-6">
          <h1 className="text-2xl mb-6">{deal.name}</h1>
          <PriceSummary
            deal={deal}
            model={modelData.model}
            properties={properties}
          />
          <div className="mt-3">
            <PropertiesSummary deal={deal} model={modelData.model} properties={properties} />
          </div>
        </div>
        <div className="col-span-2 p-6 relative">
          <Map options={DEFAULT_MAP_OPTIONS} properties={mapProperties} borderRadius="0.5rem" />
          <MapModal setShowMapModal={setShowMapModal} showMapModal={showMapModal}>
            <Map properties={mapProperties} />
          </MapModal>
          <Badge label="Click to expand" className="cursor-pointer absolute bottom-10 right-8 bg-white border" visible onClick={() => setShowMapModal(true)} />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-6 p-6">
        <div>
          <div className="w-full mb-4">Returns</div>
          <ReturnsSection returnMetrics={returnMetrics} />
          <div className="w-full mt-12 mb-4">Cash Flow Summary</div>
          <CashFlowSummary cashFlows={cashFlows} model={modelData.model} returnMetrics={returnMetrics} />
        </div>
        <div>
          <div className="w-full mb-4">Parameters</div>
          <ParametersSection
            cashFlows={cashFlows}
            model={modelData.model}
            properties={properties}
            returnMetrics={returnMetrics}
          />
          <div className="w-full mt-12 mb-4">Sources & Uses</div>
          <SourcesAndUses cashFlows={cashFlows} />
        </div>
      </div>
    </div>
  );
}
