import { createElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useBlocker, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { camelCase, isEqual, map, sum, uniq } from 'lodash';
import { setModelParameterWarning, setShowCreateDealModal, showSaveChangesModal } from 'actions/deal_navigation';
import { resetPendingParams, setPendingParams, setBaseParams } from 'actions/model';
import { LAYOUT, MODELLED_INDIVIDUALLY } from 'components/constants';
import {
  useCreateScenarioMutation,
  useFetchRentCompSetsQuery,
  useFetchSaleCompSetsQuery,
  useUpdateScenarioMutation,
} from 'redux/apiSlice';
import { clearToast, showToast } from 'redux/toastSlice';
import { TOAST_CREATE_MODEL_CHANGES, TOAST_DEFAULT_MODEL_CHANGES, TOAST_SCENARIO_MODEL_CHANGES } from 'components/toast';
import CapEx from './CapEx';
import { calcCashFlows, calcReturnMetrics, displayUnits } from './dcf';
import DebtParameters from './DebtParameters';
import Expenses from './Expenses';
import GeneralParameters from './GeneralParameters';
import ModelOverview from './ModelOverview';
import RentRoll from './RentRoll';
import RevenueParameters from './RevenueParameters';
import TaxesParameters from './Taxes/Taxes';
import WaterfallParameters from './WaterfallParameters';
import { LoadingIndicator } from '../icons';
import { parseEventValue } from '../utils';
import SFRSummary from './SFRSummary';

const SFR_SUMMARY_TAB = { sfrSummary: ['SFR Summary', SFRSummary] };

const PARAMETER_TABS = {
  general: ['General', GeneralParameters],
  rentRoll: ['Rent Roll', RentRoll],
  revenue: ['Revenue', RevenueParameters],
  expenses: ['Expenses', Expenses],
  taxes: ['Taxes', TaxesParameters],
  capEx: ['CapEx', CapEx],
  financing: ['Financing', DebtParameters],
  waterfall: ['Waterfall', WaterfallParameters],
};

function ParameterTab({ active, label, onClick }) {
  const clazz = classNames(
    'hover:text-gray-700 mx-4 text-sm font-medium',
    'flex h-14 pl-6 items-center',
    'tracking-wider text-xs font-semibold',
    'cursor-pointer',
    {
      'py-4.5 bg-primary-dark bg-opacity-8 rounded-full text-gray-800': active,
      'text-gray-400 pl-10 text-neutral-dark': !active,
    },
  );
  return (
    <div className={clazz} onClick={onClick} name={camelCase(`sidebar_${label}`)}>{label}</div>
  );
}

function ModelLeftNav({ activeParameterTab, setActiveParameterTab, updatedTabs }) {
  return (
    <div className="w-64 mt-4">
      {map(updatedTabs, (info, key) => (
        <ParameterTab
          key={key}
          active={key === activeParameterTab}
          label={info[0]}
          onClick={() => setActiveParameterTab(key)}
        />
      ))}
    </div>
  );
}

function ParameterWarning() {
  return (
    <div className="absolute left-0 right-0 mx-auto bottom-24 z-30 w-max bg-yellow-600 p-4 rounded-xl text-base text-center text-white">
      {'The current parameters are invalid and cannot be saved.\nContact support if you think there is an issue.'}
    </div>
  );
}

export default function Model({ context }) {
  const [annualRentGrowthEntry, setAnnualRentGrowthEntry] = useState(false);
  const [unitRenovationEntry, setUnitRenovationEntry] = useState(true);
  const [activeParameterTab, setActiveParameterTab] = useState('general');
  const tabsLoadedRef = useRef(false);

  const { data, modelData, propertyId } = context;
  const { deal, parcels, property } = data;

  const { data: rentCompSets } = useFetchRentCompSetsQuery({
    propertyId: property?.id,
    dealId: deal?.id,
  }, { skip: !property?.id || !deal?.id });
  const primaryRentCompSet = rentCompSets && rentCompSets.find(rcs => rcs.primary);
  const { data: saleCompSets } = useFetchSaleCompSetsQuery({
    propertyId: property?.id,
    dealId: deal?.id,
  }, { skip: !property?.id || !deal?.id });
  const primarySaleCompSet = saleCompSets && saleCompSets.find(scs => scs.primary);

  const [updateScenarioMutation] = useUpdateScenarioMutation();
  const [createScenarioMutation] = useCreateScenarioMutation();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (modelData) {
      dispatch(setBaseParams(modelData.model));
    }
  }, [modelData]);

  const model = useSelector(state => state.model);
  const { showModelParameterWarning } = useSelector(state => state.navigation.modal);
  const scenario = modelData?.model?.scenario;

  const baseParams = model?.baseParams;
  const modelParams = model?.pendingParams;
  const paramsUnsaved = !isEqual(baseParams?.dcfParams, modelParams?.dcfParams);

  // decide wether to show SFR Summary Tab based on total number of units in modeled rent roll
  const units = modelParams.dcfParams ? displayUnits(modelParams.dcfParams) : [];
  const numberOfUnits = sum(units.map(u => u.number));
  const canViewSFRSummaryTab = numberOfUnits < 2;
  const updatedTabs = canViewSFRSummaryTab ? { ...SFR_SUMMARY_TAB, ...PARAMETER_TABS } : PARAMETER_TABS;

  // initialize the annual rent growth rate toggle state
  useEffect(() => {
    if (model?.baseParams?.dcfParams) {
      const rentGrowthRates = model.baseParams.dcfParams.rentGrowthRates || [];
      setAnnualRentGrowthEntry(uniq(rentGrowthRates).length > 1);
      if (!tabsLoadedRef.current) {
        // ref is a hack that is necessary to properly show the SFR model tab
        // because we don't know to display it until the model is loaded because
        // it depends on the unit information within the model params
        tabsLoadedRef.current = true;
        setActiveParameterTab(Object.keys(updatedTabs)[0]);
      }
    }
  }, [model.baseParams]);

  const {
    state: blockerState,
    proceed,
    reset,
  } = useBlocker(paramsUnsaved);

  const resetParamsAndClearToast = useCallback(() => {
    dispatch(resetPendingParams());
    dispatch(clearToast());
  }, [dispatch]);

  const saveChanges = useCallback(async () => {
    if (modelParams.scenario) {
      // TODO: handle error
      await updateScenarioMutation({
        id: scenario.id,
        dealId: scenario.dealId,
        parameters: modelParams.dcfParams,
      });
      dispatch(clearToast());
    } else if (deal?.id) {
      // TODO: handle error
      const result = await createScenarioMutation({
        name: 'Portfolio Defaults (Copy)',
        dealId: deal.id,
        parameters: modelParams.dcfParams,
      });
      const newScenarioId = result.data.id;
      // TODO: we'd rather use 'navigate' to have a smooth transition, but this triggers
      // the blocker state, so this is a hack to work around that but requires a full page reload
      window.location = `${location.pathname}?scenarioId=${newScenarioId}`;
    }
  }, [dispatch, location, modelParams, deal, scenario, createScenarioMutation, updateScenarioMutation]);

  useEffect(() => {
    if (blockerState === 'blocked') {
      const onCancel = () => reset();
      const onDoNotSave = () => {
        resetParamsAndClearToast();
        proceed();
      };
      const onSave = async () => {
        if (modelParams.scenario || deal?.id) {
          saveChanges();
          proceed();
        } else {
          reset();
          dispatch(setShowCreateDealModal(true));
        }
      };
      dispatch(showSaveChangesModal(true, onCancel, onDoNotSave, onSave));
    }
  }, [blockerState]);

  useEffect(() => {
    if (modelParams) {
      if (paramsUnsaved) {
        if (modelParams.scenario) {
          dispatch(showToast(TOAST_SCENARIO_MODEL_CHANGES({ reset: resetParamsAndClearToast, save: saveChanges })));
        } else if (deal?.id) {
          dispatch(showToast(TOAST_DEFAULT_MODEL_CHANGES({ reset: resetParamsAndClearToast, save: saveChanges })));
        } else {
          dispatch(showToast(TOAST_CREATE_MODEL_CHANGES({ reset: resetParamsAndClearToast, save: () => dispatch(setShowCreateDealModal(true)) })));
        }
      } else {
        dispatch(clearToast());
      }
    }
  }, [modelParams]);

  if ((deal?.modellingMethod === MODELLED_INDIVIDUALLY) && !propertyId) {
    return (
      <div className="flex mt-12 w-full justify-center text-xl">
        Deal is modeled individually.
        <Link className="text-tertiary underline mx-2" to={location.pathname.replace('model', 'properties')}>Select a property</Link>
        to view the model
      </div>
    );
  }

  if (!modelParams) {
    return (
      <div className="flex mt-12 w-full justify-center">
        <LoadingIndicator className="w-8 text-blue-400" />
      </div>
    );
  }

  const onChange = (event) => {
    const param = event.target.name;
    const newValue = parseEventValue(event);
    dispatch(setPendingParams({ param, newValue }));
    dispatch(setModelParameterWarning(false));
  };

  const renderParameterTab = (cashFlows, tab, returnMetrics) => {
    const { customCompRent, dcfParams, defaultParams, financials, listing, properties, taxes } = modelParams;
    if (tab && updatedTabs[tab]) {
      return createElement(updatedTabs[tab][1], {
        activeParameterTab,
        annualRentGrowthEntry,
        cashFlows,
        customCompRent,
        dcfParams,
        defaultParams,
        financials,
        listing,
        model,
        onChange,
        parameters: dcfParams,
        paramsUnsaved,
        parcels,
        primaryRentCompSet,
        primarySaleCompSet,
        properties,
        property,
        returnMetrics,
        setActiveParameterTab,
        setAnnualRentGrowthEntry,
        setUnitRenovationEntry,
        taxes,
        unitRenovationEntry,
      });
    }
    return null;
  };

  const cashFlows = calcCashFlows(modelParams.dcfParams);
  const returnMetrics = calcReturnMetrics(cashFlows, modelParams.dcfParams);

  return (
    <div className="w-full z-0 border-t bg-gray-100" style={{ height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)` }}>
      <ModelOverview
        modelParams={modelParams}
        property={property}
        returnMetrics={returnMetrics}
      />
      <div className="flex">
        <ModelLeftNav activeParameterTab={activeParameterTab} setActiveParameterTab={setActiveParameterTab} updatedTabs={updatedTabs} />
        <div
          className="grow px-6 py-4 overflow-auto overflow-x-auto"
          style={{
            height: `calc(100vh - ${LAYOUT.modelOverviewHeight + LAYOUT.dealHeaderHeight}px)`,
            maxWidth: `calc(100vw - ${336 + LAYOUT.rightNavWidth}px)`, /* 336 is combined width of both left navs */
          }}
        >
          {renderParameterTab(cashFlows, activeParameterTab, returnMetrics)}
        </div>
      </div>
      {showModelParameterWarning && <ParameterWarning />}
    </div>
  );
}
