import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { apiSlice } from 'redux/apiSlice';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchEmailTemplates: builder.query({
        query: () => ({ url: '/api/email_templates' }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: ['EmailTemplates'],
      }),
      createEmailTemplate: builder.mutation({
        query: (emailTemplate) => ({
          url: '/api/email_templates/',
          method: 'POST',
          body: snakeCaseKeys(emailTemplate),
        }),
        invalidatesTags: ['EmailTemplates'],
      }),
      updateEmailTemplate: builder.mutation({
        query: ({ id, ...data }) => ({
          url: `/api/email_templates/${id}`,
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: ['EmailTemplates'],
      }),
      fetchTaskPlaceholders: builder.query({
        query: (task) => ({ url: `/api/deals/${task.dealId}/tasks/${task.id}/placeholders` }),
        transformResponse: response => response,
      }),
      sendTaskEmail: builder.mutation({
        query: ({ task, taskEmail }) => ({
          url: `/api/deals/${task.dealId}/tasks/${task.id}/send_email`,
          method: 'POST',
          body: snakeCaseKeys({ taskEmail }),
        }),
      }),
      fetchTaskEmail: builder.query({
        query: ({ task }) => ({ url: `/api/deals/${task.dealId}/tasks/${task.id}/task_emails` }),
        transformResponse: response => camelCaseKeys(response.data.map(obj => obj.attributes)),
      }),

    };
  },
});

export const {
  useFetchEmailTemplatesQuery,
  useCreateEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
  useFetchTaskPlaceholdersQuery,
  useSendTaskEmailMutation,
  useFetchTaskEmailQuery,
} = extendedApiSlice;
