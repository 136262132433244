import { useCallback } from 'react';
import Input from 'components/Input';
import { parseEventValue } from 'components/utils';
import FilterControl from './FilterControl';
import { useFilterInputState } from './useFilterInputState';

export default function RangeFilter({ type }) {
  const [[rangeMin, rangeMax] = [], setState] = useFilterInputState();

  /** @type {import('react').ChangeEventHandler<HTMLInputElement>} */
  const onChange = useCallback((evt) => {
    const evtVal = parseEventValue(evt);
    const changeIdx = evt.target.name === 'min' ? 0 : 1;
    setState((prev) => (prev ?? [undefined, undefined]).with(changeIdx, evtVal));
  }, [setState]);

  return (
    <FilterControl>
      <div className="flex items-center gap-x-4.5">
        <Input
          name="min"
          onChange={onChange}
          placeholder="No min"
          type={type}
          value={rangeMin}
          width="w-40"
        />
        &mdash;
        <Input
          name="max"
          onChange={onChange}
          placeholder="No max"
          type={type}
          value={rangeMax}
          width="w-40"
        />
      </div>
    </FilterControl>
  );
}
