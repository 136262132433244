import { isValidElement } from 'react';
import classNames from 'classnames';
import { isNil, isUndefined } from 'lodash';
import { Chevron } from './icons';
import IncludeTooltip from './IncludeTooltip';

const INDENTATION_PADDING = {
  '-1': '',
  '0': 'pl-7',
  '1': 'pl-12',
  '2': 'pl-16',
};

export function TH({ border, colSpan, tooltip, value, width }) {
  const className = classNames(
    'relative px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center',
    width,
    { 'border-r-2': border },
  );
  if (tooltip?.text) {
    return (
      <th className={className} colSpan={colSpan || '1'}>
        <IncludeTooltip alignRight={tooltip.alignRight} tooltipText={tooltip.text}>
          {value}
        </IncludeTooltip>
      </th>
    );
  }
  return <th className={className} colSpan={colSpan || '1'}>{value}</th>;
}

export function TR(props) {
  const {
    borderBottom,
    borders = [],
    center,
    className,
    collapsed,
    collapsible = false,
    data,
    dataClassName,
    formatter,
    hidden,
    indentation = 0,
    label,
    labelClassName,
    onClick,
    tdClassName,
  } = props;
  const clazz = classNames(
    'hover:bg-primary-50',
    className,
    {
      'hidden': hidden,
      'cursor-pointer': onClick,
    },
  );

  let icon = null;
  let labelContents = label;
  if (collapsible) {
    icon = isUndefined(collapsed) ? null : <Chevron className="h-5" direction={collapsed ? 'right' : 'down'} />;
    labelContents = icon ? (
      <div className={`flex flex-row ${INDENTATION_PADDING[indentation - 1]}`}>
        <div className="mr-1">{icon}</div>
        <div>{label}</div>
      </div>
    ) : label;
  }
  const labelPadding = classNames(
    INDENTATION_PADDING[indentation],
    'py-2',
  );
  return (
    <tr onClick={onClick} className={clazz}>
      {labelContents && (
        <TD
          borderBottom={borderBottom}
          className={classNames('text-left', labelClassName, tdClassName)}
          borderRight={borders.includes(0)}
          icon={icon}
          padding={icon ? undefined : labelPadding}
          contents={labelContents}
        />
      )}
      {data.map((value, index) => (
        <TD
          key={index}
          borderBottom={borderBottom}
          borderRight={borders.includes(labelContents ? index + 1 : index)}
          className={classNames(center ? 'text-center' : 'text-right', dataClassName, tdClassName)}
          contents={(isValidElement(value) || isNil(value) || !formatter) ? value : formatter(value)}
        />
      ))}
    </tr>
  );
}

export function TD({ borderBottom, borderRight, className, padding, contents }) {
  const clazz = classNames(
    'text-sm',
    'whitespace-nowrap',
    padding || (isValidElement(contents) ? 'px-2 py-1' : 'px-2 py-2'),
    className,
    {
      'border-b-2': borderBottom,
      'border-r-2': borderRight,
    },
  );

  return (
    <td className={clazz}>{contents}</td>
  );
}
