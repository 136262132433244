import { useMemo } from 'react';
import { naturalSortComparator } from 'components/utils';
import { useCoreRowModel } from 'components/shared/Table/DataTableContext';
import ComboboxFilter from './ComboboxFilter';
import { useFilterId } from './filterHooks';

export default function ReactTableColumnUniqueValuesComboboxFilter() {
  const filterId = useFilterId();
  const { flatRows } = useCoreRowModel();

  const colValuesSorted = useMemo(() => {
    const colValuesUnique = new Set(flatRows.map((row) => row.getValue(filterId)));
    colValuesUnique.delete(null);
    colValuesUnique.delete(undefined);
    colValuesUnique.delete('');

    return Array.from(colValuesUnique).toSorted(naturalSortComparator);
  }, [filterId, flatRows]);

  return (
    <ComboboxFilter options={colValuesSorted} />
  );
}
