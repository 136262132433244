import { LoadingIndicator } from 'components/icons';

export default function EmptyLoadingState({ text = 'Loading...' }) {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <LoadingIndicator className="w-6 text-primary-500" />
      {text && <p className="mt-2 text-base text-gray-600">{text}</p>}
    </div>
  );
}
