import { merge, partial, trim, without } from 'lodash';
import { UNIT_RENTS_SOURCES } from 'components/constants';
import { parseEventValue } from 'components/utils';
import Input from 'components/Input';
import { FormField } from 'components/Form';
import Chip from 'components/shared/Chip';
import InfoPopover from 'components/portfolio/Menu/InfoPopover';
import { useInitialFilters } from 'components/rentComps/rentComps';

const RENT_COMP_FILTERS_DESCRIPTION = 'These filters will be applied as the default filters on the rent comp tab when viewing any property within this portfolio.';
const bedBathOffsetLabel = type => `Values represent a lower and upper offset limit from the subject property's ${type} count. A value of 0 represents an exact mach and no value represents no limit. As an example, values of [1, 2] implies for a property with 3 ${type}s, only rent comps with 2-5 ${type}s will pass this filter.`;
const LAST_SEEN_AT_OFFSET_LABEL = 'Values represent the a lower and upper offset limit in days before the current date. For example, values of [7, 0] would filter for rent comps from within the past week.';
const RSF_OFFSET_LABEL = 'Values represent the percent below and above the subject property\'s RSF with an empty value representing no limit. For example, values of [20%, 30%] for a subject property of 1,000 RSF would filter for all rent comps between 800 and 1,300 RSF.';
const YEAR_BUILT_OFFSET_LABEL = 'Values represent a lower and upper offset limit from the subject property\'s year built. For example, values of [10, 15] for a subject property built in 1980 would filter for rent comps built between 1970 and 1995.';

function FilterSubSection({ children, label, hint }) {
  return (
    <div className="mb-8">
      <div className="flex items-center gap-x-2 mb-1 ">
        <div className="text-xs font-medium tracking-wide uppercase text-tertiary-darker">{label}</div>
        {hint && <InfoPopover title={label} detail={hint} />}
      </div>
      {children}
    </div>
  );
}

// portfolio specific rent comp filters
const DEFAULT_PORTFOLIO_RENT_COMP_FILTERS = {
  bedroomOffset: [null, null],
  bathroomOffset: [null, null],
  effectiveLastSeenAtOffset: [null, null],
  rsfPercentOffset: [null, null],
  yearBuiltOffset: [null, null],
};

export const OFFSET_FILTERS = Object.keys(DEFAULT_PORTFOLIO_RENT_COMP_FILTERS);

export default function RentCompFilters({ portfolio, setPortfolio }) {
  const rentCompFilters = merge({}, useInitialFilters(), DEFAULT_PORTFOLIO_RENT_COMP_FILTERS, portfolio.rentCompFilters);
  const {
    actualRent,
    currentAskingRent,
    daysOnMarket,
    distance,
    effectiveLastSeenAtOffset,
    bathroomOffset,
    bedroomOffset,
    numberOfUnits,
    resolvedStatus,
    sourceName,
    rsfPercentOffset,
    yearBuiltOffset,
    zipCode,
  } = rentCompFilters;

  const [minBathroomOffset, maxBathroomOffset] = bathroomOffset;
  const [minBedroomOffset, maxBedroomOffset] = bedroomOffset;
  const [minNumberOfUnits, maxNumberOfUnits] = numberOfUnits;
  const [minRsfPercentOffset, maxRsfPercentOffset] = rsfPercentOffset;
  const [minCurrentAskingRent, maxCurrentAskingRent] = currentAskingRent;
  const [minActualRent, maxActualRent] = actualRent;
  const [minEffectiveLastSeenAtOffset, maxEffectiveLastSeenAtOffset] = effectiveLastSeenAtOffset;
  const [minDaysOnMarket, maxDaysOnMarket] = daysOnMarket;
  const [minYearBuiltOffset, maxYearBuiltOffset] = yearBuiltOffset;

  const onChange = (filterKey, filterValue) => {
    setPortfolio({
      ...portfolio,
      rentCompFilters: {
        ...portfolio.rentCompFilters,
        [filterKey]: filterValue,
      },
    });
  };

  const rangeChange = (filterKey, index, event) => {
    const updatedValue = [...rentCompFilters[filterKey]];
    updatedValue[index] = parseEventValue(event);
    onChange(filterKey, updatedValue);
  };
  const zipChange = e => onChange('zipCode', parseEventValue(e).split(',').map(trim));
  const sourceNameChange = (source) => {
    if (sourceName.includes(source)) {
      onChange('sourceName', without(sourceName, source));
    } else {
      onChange('sourceName', [...sourceName, source]);
    }
  };
  const onStatusChange = (index) => {
    const updatedValue = [...resolvedStatus];
    updatedValue[index][1] = !updatedValue[index][1];
    onChange('resolvedStatus', updatedValue);
  };

  return (
    <>
      <div className="w-1/2 text-neutral-dark text-sm font-normal">
        {RENT_COMP_FILTERS_DESCRIPTION}
      </div>
      <div className="w-1/2 mt-6 p-6 bg-white rounded-lg border border-gray-300">
        <FilterSubSection label="Bedroom Offset" hint={bedBathOffsetLabel('bedroom')}>
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minBedroomOffset}
              type="number"
              min="0"
              step="1"
              placeholder="No min"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'bedroomOffset', 0)}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxBedroomOffset}
              type="number"
              min="0"
              step="1"
              placeholder="No max"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'bedroomOffset', 1)}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Bathroom Offset" hint={bedBathOffsetLabel('bathroom')}>
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minBathroomOffset}
              type="number"
              min="0"
              step="1"
              placeholder="No min"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'bathroomOffset', 0)}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxBathroomOffset}
              type="number"
              min="0"
              step="1"
              placeholder="No max"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'bathroomOffset', 1)}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="RSF % Offset" hint={RSF_OFFSET_LABEL}>
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minRsfPercentOffset}
              type="percent"
              min="0"
              placeholder="No min"
              padding="py-2 px-3 w-56"
              onChange={partial(rangeChange, 'rsfPercentOffset', 0)}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxRsfPercentOffset}
              type="percent"
              min="0"
              placeholder="No max"
              padding="py-2 px-3 w-56"
              onChange={partial(rangeChange, 'rsfPercentOffset', 1)}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Distance (mi)">
          <div className="my-2">{`Radius: ${distance[1]} mi`}</div>
          <Input
            onChange={(e) => onChange('distance', [null, parseEventValue(e)])}
            type="range"
            width="w-full"
            value={distance[1]}
            min="0.1"
            max="2"
            step="0.1"
          />
          <div className="flex justify-between">
            <div>0.1 mi</div>
            <div>2 mi</div>
          </div>
        </FilterSubSection>

        <FilterSubSection label="Unit Count">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minNumberOfUnits}
              type="number"
              placeholder="No min"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'numberOfUnits', 0)}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxNumberOfUnits}
              type="number"
              placeholder="No max"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'numberOfUnits', 1)}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Asking Rent">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minCurrentAskingRent}
              type="number"
              placeholder="No min $"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'currentAskingRent', 0)}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxCurrentAskingRent}
              type="number"
              placeholder="No max $"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'currentAskingRent', 1)}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Actual Rent">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minActualRent}
              type="number"
              placeholder="No min $"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'actualRent', 0)}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxActualRent}
              type="number"
              placeholder="No max $"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'actualRent', 1)}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Zip Code" helperText="Separate multiple zip codes with a comma">
          <div className="flex">
            <FormField
              value={zipCode}
              type="text"
              placeholder="Zip code"
              padding="py-2 px-3 "
              onChange={zipChange}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Source">
          <div className="flex space-x-2">
            {UNIT_RENTS_SOURCES.map((source, index) => (
              <Chip
                key={index}
                leadingIcon={sourceName.includes(source)}
                label={source}
                onClick={partial(sourceNameChange, source)}
                selected={sourceName.includes(source)}
              />
            ))}
          </div>
        </FilterSubSection>

        {/* <FilterSubSection label="Status" helperText="Status is the current state of the unit's rental listing as of today's date based on data available to Honeycomb.">
          <div className="flex space-x-2">
            {UNIT_RENTS_STATUSES.map((status, index) => (
              <Chip
                key={index}
                leadingIcon={resolvedStatus[index][1]}
                label={status}
                onClick={partial(onStatusChange, index)}
                selected={resolvedStatus[index][1]}
              />
            ))}
          </div>
        </FilterSubSection> */}

        <FilterSubSection label="As of Date (Days Ago)" hint={LAST_SEEN_AT_OFFSET_LABEL}>
          <div className="flex justify-between items-center space-x-2">
            <FormField
              type="number"
              min="0"
              placeholder="No min"
              value={minEffectiveLastSeenAtOffset}
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'effectiveLastSeenAtOffset', 0)}
            />
            <div className="mt-1 pl-7 w-2.5">-</div>
            <FormField
              type="number"
              min="0"
              placeholder="No max"
              value={maxEffectiveLastSeenAtOffset}
              padding="ml-10 py-2 px-3 w-60"
              onChange={partial(rangeChange, 'effectiveLastSeenAtOffset', 1)}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Days on Market (DoM)">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minDaysOnMarket}
              type="number"
              placeholder="No min"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'daysOnMarket', 0)}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxDaysOnMarket}
              type="number"
              placeholder="No max"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'daysOnMarket', 1)}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Year Built Offset" hint={YEAR_BUILT_OFFSET_LABEL}>
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minYearBuiltOffset}
              type="number"
              min="0"
              placeholder="No min"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'yearBuiltOffset', 0)}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxYearBuiltOffset}
              type="number"
              min="0"
              placeholder="No max"
              padding="py-2 px-3 w-60"
              onChange={partial(rangeChange, 'yearBuiltOffset', 1)}
            />
          </div>
        </FilterSubSection>
      </div>
    </>
  );
}
