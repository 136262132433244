export default function Toggle({ className, checked, name, onClick, disabled, small = false }) {
  const outerWidth = small ? 'w-8' : 'w-10';
  const innerWidth = small ? 'w-4' : 'w-6';
  const height = small ? 'h-4' : 'h-6';
  const opacity = disabled ? 'opacity-50' : '';
  return (
    <div className={`${outerWidth} ${opacity} ${className} relative inline-block align-middle select-none`} onClick={disabled ? undefined : onClick}>
      <input type="checkbox" checked={!!checked} readOnly name={name} className={`${innerWidth} ${height} toggle-checkbox absolute block rounded-full bg-white border-4 border-gray-300 appearance-none focus:outline-none cursor-pointer`} />
      <label htmlFor={name} className={`${height} toggle-label block overflow-hidden rounded-full bg-gray-300 cursor-pointer`} />
    </div>
  );
}

export function ToggleWithLabels({ className, checked, label1, label2, name, onClick, disabled, small = false }) {
  return (
    <div className={`${className} flex flex-row items-center`}>
      <label className="mr-3 text-xs font-medium text-gray-500 uppercase tracking-wider">{label1}</label>
      <div>
        <Toggle small={small} checked={checked} disabled={disabled} name={name} onClick={onClick} />
      </div>
      <label className="ml-3 text-xs font-medium text-gray-500 uppercase tracking-wider">{label2}</label>
    </div>
  );
}
