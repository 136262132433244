import { memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseISO } from 'date-fns';
import DataTable from 'components/shared/Table/DataTable';
import { DataTableContent } from 'components/shared/Table/DataTableContext';
import { useFetchOrganizationsQuery } from 'redux/organizationApiSlice';
import ReactTableColumnFilter from 'components/shared/newfilters/ReactTableColumnFilter';
import ReactTableFilter from 'components/shared/newfilters/ReactTableFilters';
import BooleanFilterChip from 'components/shared/newfilters/BooleanFilterChip';
import FilterToggle from 'components/shared/newfilters/FilterToggle';
import ChipGroup from 'components/shared/ChipGroup';
import { dataTableMeta } from 'components/shared/Table/table.helpers';
import { Check, Pencil } from 'components/icons';
import { formatDate } from 'components/utils';
import { CHANNEL_LABELS } from 'components/constants';
import InactiveFilterChip from './InactiveFilterChip';

const TABLE_INITIAL_STATE = {
  sorting: [{ id: 'lastActivityAt', desc: true }],
  columnFilters: [{ id: 'inactive', value: false }],
  columnVisibility: { inactive: false },
};

const arrayValue = (array) => {
  if (array.length > 3) {
    return `${array.slice(0, 2).join(', ')} + ${array.length - 2} more`;
  }
  return array.join(', ');
};

const inactiveFilterFn = (row, _, inactive) => {
  if (!inactive) {
    return !row.getValue('inactive');
  }
  return true;
};

const useColumns = (navigate) => useMemo(
  () => [{
    header: 'Name',
    accessorKey: 'name',
  }, {
    header: 'Channels',
    accessorKey: 'channels',
    cell: ({ getValue }) => getValue().map((channelId) => CHANNEL_LABELS[channelId] ?? channelId).join(', '),
  }, {
    header: 'Markets',
    accessorKey: 'markets',
    cell: ({ getValue }) => arrayValue(getValue()),
  }, {
    id: 'newBuildOnly',
    header: 'New Build Only?',
    accessorFn: ({ config }) => config.newBuildOnly,
    cell: ({ getValue }) => (getValue() ? <div className="flex justify-center"><Check /></div> : null),
  }, {
    header: 'Active Deals',
    accessorKey: 'activeDeals',
    meta: { ...dataTableMeta.textRight },
  }, {
    header: 'Closed Deals',
    accessorKey: 'closedDeals',
    meta: { ...dataTableMeta.textRight },
  }, {
    header: 'Dead Deals',
    accessorKey: 'deadDeals',
    meta: { ...dataTableMeta.textRight },
  }, {
    header: 'Active Users',
    accessorKey: 'activeUsersCount',
    meta: { ...dataTableMeta.textRight },
  }, {
    header: 'Trial Users',
    accessorKey: 'trialUsersCount',
    meta: { ...dataTableMeta.textRight },
  }, {
    id: 'lastActivityAt',
    header: 'Last Activity',
    accessorFn: ({ lastActivityAt }) => (lastActivityAt ? parseISO(lastActivityAt) : null),
    cell: ({ getValue }) => formatDate(getValue(), 'yyyy-MM-dd HH:mm:ss zzz'),
    sortingFn: 'datetime',
    sortDescFirst: true,
  }, {
    id: 'inactive',
    accessorFn: ({ activeUsersCount }) => !activeUsersCount,
    filterFn: inactiveFilterFn,
  }, {
    id: 'menu',
    header: '',
    enableSorting: false,
    accessorFn: (row) => row,
    cell: memo(({ getValue }) => (
      <div className="relative">
        <div className="flex justify-center gap-x-1 items-center">
          <div className="flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 cursor-pointer" onClick={(event) => { event.stopPropagation(); event.preventDefault(); navigate(`/organizations/${getValue().id}`); }}>
            <Pencil className="w-6 h-6 mx-auto" />
          </div>
        </div>
      </div>
    )),
  }],
  [navigate],
);



export default function OrganizationIndex() {
  const navigate = useNavigate();
  const { currentData, isLoading } = useFetchOrganizationsQuery();

  return (
    <div className="p-6 h-0 flex-1">
      <div className="bg-white">
        <DataTable
          tableContainerClassName="max-h-full"
          data={currentData}
          columns={useColumns(navigate)}
          isLoading={isLoading}
          initialState={TABLE_INITIAL_STATE}
        >
          <div className="flex justify-between items-center py-1.5 px-3 border-b">
            <div>
              <ReactTableFilter>
                <ChipGroup>
                  <ReactTableColumnFilter
                    label="New Build Only"
                    staticDisplayValue="New Build Only"
                    columnId="newBuildOnly"
                  >
                    <BooleanFilterChip>
                      <FilterToggle />
                    </BooleanFilterChip>
                  </ReactTableColumnFilter>
                  <ReactTableColumnFilter columnId="inactive">
                    <InactiveFilterChip />
                  </ReactTableColumnFilter>
                </ChipGroup>
              </ReactTableFilter>
            </div>
          </div>
          <DataTableContent />
        </DataTable>
      </div>
    </div>
  );
}
