import Chip from 'components/shared/Chip';
import { useOnApply, useOnClear } from 'components/shared/newfilters/filterHooks';
import { useNodeState } from 'components/shared/TreeProvider/TreeProvider';
import { Check } from 'components/icons';

export default function TestDealFilterChip() {
  const [{ selected }] = useNodeState();
  const onApply = useOnApply();
  const label = 'Include Test Deals';
  const onClear = useOnClear();
  const onClick = () => (selected ? onClear() : onApply({ value: false }));

  // set selected prop to !selected var because we want the
  // default UI of filtering out test deals to look "unselected"
  return (
    <Chip
      leadingIcon={!selected && <Check />}
      selected={!selected}
      label={label}
      onClick={onClick}
    />
  );
}
