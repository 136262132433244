import { Plus } from 'components/icons';
import Button from 'components/shared/NewButton';

function LeadingIconPlus() {
  return (
    <Plus className="w-4" />
  );
}

export default function Buttons() {
  return (
    <div className="grid grid-cols-2">
      <div className="flex col-span-2">
        <div className="w-1/2">
          <p>Filled</p>
          <div className="flex justify-start py-6">
            <Button filled label="Enabled" className="mr-6" />
            <Button filled label="Disabled" disabled />
          </div>
          <p>Filled with leading icon</p>
          <div className="flex justify-start py-6">
            <Button filled label="Enabled" leadingIcon={<LeadingIconPlus />} className="mr-6" />
            <Button filled label="Disabled" leadingIcon={<LeadingIconPlus />} disabled />
          </div>
        </div>
        <div className="w-1/2">
          <p>Tonal</p>
          <div className="flex justify-start py-6">
            <Button tonal label="Enabled" className="mr-6" />
            <Button tonal label="Disabled" disabled />
          </div>
          <p>Tonal with leading icon</p>
          <div className="flex justify-start py-6">
            <Button tonal label="Enabled" leadingIcon={<LeadingIconPlus />} className="mr-6" />
            <Button tonal label="Disabled" leadingIcon={<LeadingIconPlus />} disabled />
          </div>
        </div>
      </div>
      <div className="flex col-span-2">
        <div className="w-1/2">
          <p>Outlined</p>
          <div className="flex justify-start py-6">
            <Button outlined label="Enabled" className="mr-6" />
            <Button outlined label="Disabled" disabled />
          </div>
          <p>Outlined with leading icon</p>
          <div className="flex justify-start py-6">
            <Button outlined label="Enabled" leadingIcon={<LeadingIconPlus />} className="mr-6" />
            <Button outlined label="Disabled" leadingIcon={<LeadingIconPlus />} disabled />
          </div>
        </div>
        <div className="w-1/2">
          <p>Text</p>
          <div className="flex justify-start py-6">
            <Button textOnly label="Enabled" className="mr-6" />
            <Button textOnly label="Disabled" disabled />
          </div>
          <p>Text with leading icon</p>
          <div className="flex justify-start py-6">
            <Button textOnly label="Enabled" leadingIcon={<LeadingIconPlus />} className="mr-6" />
            <Button textOnly label="Disabled" leadingIcon={<LeadingIconPlus />} disabled />
          </div>
        </div>
      </div>
    </div>
  );
}
