import { isNull, range } from 'lodash';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import {
  calcLoanProceeds,
  monthlyRate,
} from './debt';
import { TH, TR } from '../Table';
import { annualizeMonthlyReturns } from '../finance';
import {
  dateFromString,
  formatCurrency,
  formatDate,
  formatPercentage,
} from '../utils';

function Row({ begBalance, date, draw, endBalance, month, interestPayment, principalPayment, rate, repayment }) {
  const data = [
    formatDate(date),
    month,
    Math.floor((month - 1) / 12) + 1,
    formatPercentage(rate, 2),
    formatCurrency(begBalance),
    formatCurrency(draw),
    formatCurrency(Math.abs(interestPayment)),
    formatCurrency(Math.abs(principalPayment)),
    formatCurrency(Math.abs(interestPayment + principalPayment)),
    formatCurrency(repayment),
    formatCurrency(endBalance),
  ];
  return (
    <TR borderBottom={!!repayment} data={data} />
  );
}

export default function Financing({ cashFlows, dcfParams }) {
  const { closingDate, couponRate, holdPeriod, refinancingCouponRate, refinancingSizingMethod, term } = dcfParams;

  const parsedClosingDate = dateFromString(closingDate);
  const dates = range(1, term + 1).map(month => startOfMonth(addMonths(parsedClosingDate, month)));

  const annualNOIs = annualizeMonthlyReturns(cashFlows.netOperatingIncome);
  const proceeds = cashFlows.financing.acquisitionLoanPaymentRepayments[0];
  const rate = monthlyRate(couponRate);
  const monthlyDraws = cashFlows.financing.futureFundingMonthlyDraws;
  const { interestPayments, principalPayments } = cashFlows.financing;
  const balances = [proceeds];
  dates.forEach((_, index) => {
    if (index === dates.length - 1) {
      balances.push(0);
    } else {
      const prevBalance = balances[index];
      const draw = monthlyDraws[index];
      const payment = principalPayments[index];
      const newBalance = prevBalance + draw + payment;
      balances.push(newBalance);
    }
  });

  let refinancingRows = null;
  if (!isNull(refinancingSizingMethod)) {
    const refinancingProceeds = calcLoanProceeds(annualNOIs, dcfParams, true);
    const refinancingInterestPayments = interestPayments.slice(term);
    const refinancingPrincipalPayments = principalPayments.slice(term);
    const refinancingBalances = [refinancingProceeds];
    const refinancingDate = endOfMonth(addMonths(parsedClosingDate, term));
    const refinancingDates = range(1, (holdPeriod * 12) - term + 1).map(month => startOfMonth(addMonths(refinancingDate, month)));
    refinancingDates.forEach((_, index) => {
      if (index === refinancingDates.length - 1) {
        refinancingBalances.push(0);
      } else {
        const prevBalance = refinancingBalances[index];
        const payment = refinancingPrincipalPayments[index];
        const newBalance = prevBalance + payment;
        refinancingBalances.push(newBalance);
      }
    });
    const refinancingRate = monthlyRate(refinancingCouponRate);

    refinancingRows = (
      <>
        <tr><td className="p-2 border-b text-tertiary-darker font-medium tracking-wide uppercase" colSpan="11">Refinancing</td></tr>
        <TR data={[formatDate(refinancingDate), 0, 0, null, null, null, null, null, null, null, formatCurrency(refinancingProceeds)]} />
        {refinancingDates.map((date, index) => (
          <Row
            key={index}
            begBalance={refinancingBalances[index]}
            date={date}
            draw={0}
            endBalance={refinancingBalances[index + 1]}
            month={index + 1}
            interestPayment={refinancingInterestPayments[index]}
            principalPayment={refinancingPrincipalPayments[index]}
            rate={refinancingRate}
            repayment={index === refinancingDates.length - 1 ? refinancingBalances[index] + refinancingPrincipalPayments[index] : 0}
          />
        ))}
      </>
    );
  }

  return (
    <table className="min-w-full divide-y divide-gray-200 bg-white">
      <thead className="bg-gray-50 border-b-2">
        <tr>
          <TH value="Date" />
          <TH value="Month" />
          <TH value="Year" />
          <TH value="Interest Rate" />
          <TH value="Beginning Balance" />
          <TH value="Additional Draws" />
          <TH value="Interest" />
          <TH value="Principal" />
          <TH value="Total Payment" />
          <TH value="Repayment" />
          <TH value="Ending Balance" />
        </tr>
      </thead>
      <tbody>
        <TR data={[formatDate(parsedClosingDate), 0, 0, null, null, null, null, null, null, null, formatCurrency(proceeds)]} />
        {dates.map((date, index) => (
          <Row
            key={index}
            begBalance={balances[index]}
            date={date}
            draw={monthlyDraws[index]}
            endBalance={balances[index + 1]}
            month={index + 1}
            interestPayment={interestPayments[index]}
            principalPayment={principalPayments[index]}
            rate={rate}
            repayment={index === dates.length - 1 ? balances[index] + principalPayments[index] : 0}
          />
        ))}
        {refinancingRows}
      </tbody>
    </table>
  );
}
