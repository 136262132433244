import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, keyBy, pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  apiSlice,
  useFetchCustomCompRentsQuery,
  useFetchDealSourcingListingsQuery,
  useFetchDealSourcingOffMarketPropertiesQuery,
  useFetchDealSourcingValuationsQuery,
  useFetchDealStatusesQuery,
} from 'redux/apiSlice';
import {
  useFetchHomeModelsQuery,
  useFetchHomeModelValuationsQuery,
  useFetchHomeModelsPipelineStatusQuery,
} from 'redux/homeModelApiSlice';
import {
  ACTIVE_STATUS,
  CHANNEL_LISTED,
  CHANNEL_NEW_BUILD,
  CHANNEL_OFF_MARKET,
  CHANNEL_OFF_MARKET_MARKETPLACE,
  DEAL_STATE_ACTIVE,
  DEAL_STATE_CLOSED,
  DEAL_STATE_DEAD,
} from 'components/constants';
import { filterBySelectedArea, filterItems, sortItems } from 'components/DealSourcing/filters';
import { naturalSortComparator } from 'components/utils';
import { useFetchOffMarketMarketplaceListingsQuery } from '../../redux/listingApiSlice';

const EMPTY_ARRAY = [];
const EMPTY_MAP = {};
export const DEFAULT_FILTERS = {
  [CHANNEL_LISTED]: {
    type: CHANNEL_LISTED,
    area: null,
    bathroomsTotalInteger: { useExact: false, selections: [] },
    bedroomsTotal: { useExact: false, selections: [] },
    buildingArea: [],
    daysOnMarket: [],
    excludesHomesWithPool: false,
    goingInCapRate: [],
    grossStabilizedYield: [],
    leveredEquityMultiple: [],
    leveredIrr: [],
    listingContractDate: [],
    listPrice: [],
    numberOfUnitsTotal: [],
    pipeline: [DEAL_STATE_ACTIVE],
    pricePerSquareFoot: [],
    pricePerUnit: [],
    propertyType: null,
    propertySubType: [],
    stabilizedYield: [],
    standardStatus: [ACTIVE_STATUS],
    unleveredEquityMultiple: [],
    unleveredIrr: [],
    y1Yield: [],
    yearBuilt: [],
    zipCodes: [],
  },
  [CHANNEL_NEW_BUILD]: {
    type: CHANNEL_NEW_BUILD,
    area: null,
    availability: [],
    excludesHomesWithPool: false,
    grossStabilizedYield: [],
    goingInCapRate: [],
    leveredEquityMultiple: [],
    leveredIrr: [],
    livingArea: [],
    listPrice: [],
    pricePerSquareFootRange: [],
    bedroomsTotal: {},
    bathroomsTotalInteger: {},
    propertySubType: [],
    stabilizedYield: [],
    pipeline: [DEAL_STATE_ACTIVE, DEAL_STATE_DEAD, DEAL_STATE_CLOSED],
    unleveredEquityMultiple: [],
    unleveredIrr: [],
    y1Yield: [],
    zipCodes: [],
  },
  [CHANNEL_OFF_MARKET]: {
    type: CHANNEL_OFF_MARKET,
    area: null,
    excludesHomesWithPool: false,
    bedroomsTotal: {},
    bathroomsTotalInteger: {},
    propertySubType: [],
    rentableBuildingArea: [],
    yearBuilt: [],
    zipCodes: [],
  },
  [CHANNEL_OFF_MARKET_MARKETPLACE]: {
    type: CHANNEL_OFF_MARKET_MARKETPLACE,
    area: null,
    bathroomsTotalInteger: { useExact: false, selections: [] },
    bedroomsTotal: { useExact: false, selections: [] },
    buildingArea: [],
    daysOnMarket: [],
    excludesHomesWithPool: false,
    goingInCapRate: [],
    grossStabilizedYield: [],
    leveredEquityMultiple: [],
    leveredIrr: [],
    listingContractDate: [],
    listPrice: [],
    numberOfUnitsTotal: [],
    pipeline: [DEAL_STATE_ACTIVE],
    pricePerSquareFoot: [],
    pricePerUnit: [],
    propertyType: null,
    propertySubType: [],
    stabilizedYield: [],
    standardStatus: [ACTIVE_STATUS],
    unleveredEquityMultiple: [],
    unleveredIrr: [],
    y1Yield: [],
    yearBuilt: [],
    zipCodes: [],
  },
};

export const HOME_MODEL_ID_PREFIX = 'hm-';

export const defaultFilters = (channel, portfolio) => {
  const filters = DEFAULT_FILTERS[channel];
  const defaultFilterKeys = Object.keys(filters);
  const portfolioDefaultFilters = portfolio?.parameters?.defaultFilters && pick(portfolio?.parameters?.defaultFilters, defaultFilterKeys);
  return {
    ...filters,
    ...(channel === CHANNEL_OFF_MARKET ? {} : (portfolioDefaultFilters || {})),
  };
};

export const isGeometryFilterUsed = ({ area, polygon, circle }) => !!(area || polygon || circle);

const serverSideFilters = ({ area, polygon, circle }) => ({ area, polygon, circle });

export const useUpdateDealStatusCache = ({ id }) => {
  const dispatch = useDispatch();

  return useCallback((listingId, pipelineStatus, dealId) => dispatch(
    apiSlice.util.updateQueryData('fetchDealStatuses', id, (draftStatuses) => {
      // immer callback
      // eslint-disable-next-line no-param-reassign
      draftStatuses[listingId] ??= {};
      Object.assign(draftStatuses[listingId], { id: dealId, stage: pipelineStatus });
    }),
  ), [dispatch, id]);
};

const extractPropertySubTypes = (items) => (
  [...new Set(items?.map(({ propertySubType }) => propertySubType).filter(type => !!type))]
    .sort((left, right) => left.localeCompare(right, undefined, { sensitivity: 'base' }))
);

const extractHomeBuilders = (items) => (
  Array
    .from(new Set(items.map(({ homeBuilder: { name } }) => name)))
    .sort(naturalSortComparator())
);

const extractSubdivisions = (items) => (
  Array
    .from(new Set(items.map(({ subdivision: { name } }) => name)))
    .sort(naturalSortComparator())
);

export const buildPropertiesQueryArgs = ({ filters }) => serverSideFilters(filters);

export const selectDealSourcingListings = createSelector(
  [
    ({ isFetching }) => isFetching,
    ({ isUninitialized }) => isUninitialized,
    ({ currentData }) => currentData,
  ],
  (isFetching, isUninitialized, currentData) => ({
    isFetching,
    isUninitialized,
    currentData: currentData || EMPTY_ARRAY,
  }),
);

export const useDealSourcingItems = ({ settings, filters, portfolio }) => {
  const {
    channel,
    market,
    sortBy,
    multiPortfolioSortBy,
    multiPortfolio,
  } = settings;

  // fetch off-market
  const propertiesQueryOptions = {
    skip: (channel !== CHANNEL_OFF_MARKET) || !isGeometryFilterUsed(filters),
    selectFromResult: result => ({
      ...result,
      currentData: result.currentData || EMPTY_ARRAY,
    }),
  };
  const {
    currentData: properties,
    isFetching: isFetchingProperties,
    isUninitialized: isPropertiesUninitialized,
  } = useFetchDealSourcingOffMarketPropertiesQuery(buildPropertiesQueryArgs({ filters }), propertiesQueryOptions);
  const isPropertiesReady = propertiesQueryOptions.skip || (!isFetchingProperties && !isPropertiesUninitialized);

  // fetch new build
  const newBuildQueryOptions = {
    skip: (channel !== CHANNEL_NEW_BUILD),
    selectFromResult: result => ({
      ...result,
      currentData: result.currentData || EMPTY_ARRAY,
    }),
  };
  const {
    currentData: newBuilds,
    isFetching: isFetchingNewBuilds,
    isUninitialized: isNewBuildsUninitialized,
  } = useFetchHomeModelsQuery({ market }, newBuildQueryOptions);
  const isNewBuildsReady = newBuildQueryOptions.skip || (!isFetchingNewBuilds && !isNewBuildsUninitialized);

  const {
    currentData: newBuildPipelineData,
    isFetching: isFetchingNewBuildPipelineData,
  } = useFetchHomeModelsPipelineStatusQuery({ portfolio }, {
    skip: channel !== CHANNEL_NEW_BUILD,
    selectFromResult: result => ({
      ...result,
      currentData: result.currentData || EMPTY_MAP,
    }),
  });

  const {
    currentData: newBuildValuations,
    isFetching: isFetchingNewBuildValuations,
  } = useFetchHomeModelValuationsQuery(portfolio.id, {
    skip: (channel !== CHANNEL_NEW_BUILD) || !portfolio.autoValuations,
    selectFromResult: result => ({
      ...result,
      currentData: result.currentData || EMPTY_ARRAY,
    }),
  });

  // fetch listings
  const listingsQueryOptions = {
    skip: channel !== CHANNEL_LISTED,
    selectFromResult: selectDealSourcingListings,
  };
  const {
    currentData: listings,
    isFetching: isFetchingListings,
    isUninitialized: isListingsUninitialized,
  } = useFetchDealSourcingListingsQuery({ market }, listingsQueryOptions);
  const isListingsReady = listingsQueryOptions.skip || (!isFetchingListings && !isListingsUninitialized);

  const { data: customCompRents } = useFetchCustomCompRentsQuery(portfolio, { skip: (channel !== CHANNEL_LISTED) || !portfolio.autoCompRents });

  const {
    currentData: listingValuations,
    isFetching: isFetchingListingValuations,
  } = useFetchDealSourcingValuationsQuery(portfolio.id, {
    skip: (channel !== CHANNEL_LISTED) || !portfolio.autoValuations,
    selectFromResult: result => ({
      ...result,
      currentData: result.currentData || EMPTY_MAP,
    }),
  });

  const {
    currentData: dealStatusesData,
    isFetching: isFetchingDealStatuses,
  } = useFetchDealStatusesQuery(portfolio.id, {
    skip: channel !== CHANNEL_LISTED && channel !== CHANNEL_OFF_MARKET_MARKETPLACE,
    selectFromResult: result => ({
      ...result,
      currentData: result.currentData || EMPTY_MAP,
    }),
  });

  const skipOffMarketMarketplace = channel !== CHANNEL_OFF_MARKET_MARKETPLACE;
  const {
    currentData: marketplace,
    isFetching: isFetchingOffMarketMarketplaceListings,
    isUninitialized: isOffMarketMarketplaceListingsUninitialized,
  } = useFetchOffMarketMarketplaceListingsQuery({ market }, { skip: skipOffMarketMarketplace });
  const isOffMarketMarketplaceReady = skipOffMarketMarketplace || (!isFetchingOffMarketMarketplaceListings && !isOffMarketMarketplaceListingsUninitialized);

  const isFetching = !isListingsReady || !isPropertiesReady || !isNewBuildsReady || !isOffMarketMarketplaceReady || isFetchingListingValuations || isFetchingDealStatuses || isFetchingNewBuildPipelineData || isFetchingNewBuildValuations;

  const decoratedListings = useMemo(() => {
    if (!listings) return null;
    return listings.map(listing => {
      const { id: dealId, stage: dealStatus, deadReason, deletedAt } = dealStatusesData[listing.id] ?? {};
      return {
        ...listing,
        ...(listingValuations[listing.id] ?? {}),
        customCompRent: customCompRents?.[listing.id],
        dealId,
        dealStatus,
        deadReason,
        deletedAt,
      };
    });
  }, [customCompRents, dealStatusesData, listings, listingValuations]);

  const decoratedNewBuilds = useMemo(() => {
    if (!newBuilds || !newBuildPipelineData) {
      return null;
    }

    const valuationMap = keyBy(newBuildValuations || [], 'homeModelId');

    return newBuilds.map(newBuild => {
      const pipelineData = newBuildPipelineData[newBuild.id];
      const valuationData = valuationMap[newBuild.id];

      let transformed = newBuild;
      if (pipelineData) {
        transformed = {
          deletedAt: pipelineData.deletedAt,
          deadReason: pipelineData.deadReason,
          dealStatus: pipelineData.stage,
          dealId: pipelineData.id,
          ...transformed,
        };
      }
      if (valuationData) {
        transformed = {
          ...transformed,
          ...valuationData.returnMetrics,
        };
      }
      return transformed;
    });
  }, [newBuilds, newBuildPipelineData, newBuildValuations]);

  let preFilteredItems;
  if (channel === CHANNEL_LISTED) {
    preFilteredItems = decoratedListings;
  } else if (channel === CHANNEL_NEW_BUILD) {
    preFilteredItems = decoratedNewBuilds;
  } else if (channel === CHANNEL_OFF_MARKET) {
    preFilteredItems = properties;
  } else if (channel === CHANNEL_OFF_MARKET_MARKETPLACE) {
    preFilteredItems = marketplace;
  }

  const filteredItems = useMemo(() => {
    let filteredItemsTemp = preFilteredItems;
    if (!filteredItemsTemp || isEmpty(filteredItemsTemp)) {
      return EMPTY_ARRAY;
    }
    if (channel !== CHANNEL_OFF_MARKET) {
      filteredItemsTemp = filterBySelectedArea(filteredItemsTemp, filters);
    }
    filteredItemsTemp = filterItems(filteredItemsTemp, filters, new Date());
    filteredItemsTemp = sortItems(filteredItemsTemp, multiPortfolio ? [multiPortfolioSortBy, sortBy] : [sortBy]);
    return filteredItemsTemp;
  }, [preFilteredItems, channel, filters, multiPortfolio, multiPortfolioSortBy, sortBy]);

  const propertySubTypes = useMemo(() => (
    preFilteredItems?.length ? extractPropertySubTypes(preFilteredItems) : EMPTY_ARRAY
  ), [preFilteredItems]);

  const homeModelBuilders = useMemo(() => (
    decoratedNewBuilds?.length ? extractHomeBuilders(newBuilds) : EMPTY_ARRAY
  ), [decoratedNewBuilds?.length, newBuilds]);

  const homeModelSubdivisions = useMemo(() => (
    decoratedNewBuilds?.length ? extractSubdivisions(newBuilds) : EMPTY_ARRAY
  ), [decoratedNewBuilds?.length, newBuilds]);

  return useMemo(() => ({
    filteredItems,
    isFetching,
    propertySubTypes,
    homeModelBuilders,
    homeModelSubdivisions,
  }), [filteredItems, homeModelBuilders, homeModelSubdivisions, isFetching, propertySubTypes]);
};
