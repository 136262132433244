import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import { CircleInfo } from 'components/icons';

export default function InfoPopover({ children, className, heading, text }) {
  return (
    <Popover className={classNames('relative', className)}>
      {({ open }) => (
        <>
          <Popover.Button as="span" className={classNames('focus:outline-none active:outline-none rounded-full cursor-pointer', open ? 'bg-gray-200' : '')}>
            <CircleInfo className="w-5 h-5" />
          </Popover.Button>
          <Popover.Panel className="absolute mt-1 left-0 z-30 w-90 p-4 bg-white rounded-lg shadow">
            {children || (
              <>
                {heading && <div className="text-neutral-medium font-medium text-base">{heading}</div>}
                <div className="text-neutral-medium font-medium text-sm">{text}</div>
              </>
            )}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
