import { Chevron } from 'components/icons';
import { formatCounty, naturalSortComparator } from 'components/utils';
import { useOutletContext } from 'react-router-dom';
import ExpandableText from 'components/shared/ExpandableText';
import SubdivisionAreaDetailsTable from './SubdivisionAreaDetailsTable';
import SubdivisionHomeModelsTable from './SubdivisionHomeModelsTable';
import SubdivisionMap from './SubdivisionMap';

function SubdivisionDetails({ children }) {
  return (
    <div className="flex flex-col gap-y-4 p-4 bg-white rounded-lg">
      {children}
    </div>
  );
}

function SubdivisionDetailLine({ children }) {
  return (
    <div className="flex flex-col gap-y-2 max-w-[100ch]">
      {children}
    </div>
  );
}

function SubdivisionDetailTitle({ children }) {
  return (
    <div className="text-title-sm text-neutral-dark whitespace-pre-wrap">
      {children}
    </div>
  );
}

function SubdivisionDetailContent({ children }) {
  return (
    <div className="text-body-md text-neutral-dark text-pretty">
      {children}
    </div>
  );
}

export default function SubdivisionDetail({ subdivision }) {
  if (!subdivision) {
    // TODO: show loading indicator
    return null;
  }

  const {
    amenities,
    averageSchoolRating,
    city,
    county,
    demographics,
    description,
    geoCoords,
    homeModels,
    market,
    name,
    restrictions,
    state,
    zip,
  } = subdivision;

  return (
    <div className="flex flex-col gap-y-4 size-full max-w-fit px-10 pt-4 mx-auto overflow-hidden">
      <div className="flex flex-col gap-y-1 col-span-full py-3">
        <div className="flex flex-row items-center gap-x-2 text-label-md text-neutral-light stroke-neutral-light last:*:text-neutral-dark">
          <div>{market}</div>
          <Chevron direction="right" className="size-3" />
          <div>{`${formatCounty(county)} County`}</div>
        </div>
        <div className="text-headline-sm">{`${name}, ${city}, ${state} ${zip}`}</div>
      </div>
      <div className="flex flex-row justify-between gap-x-8 overflow-x-auto">
        <div className="grow space-y-6 h-full min-w-max max-w-max overflow-auto pb-8">
          <SubdivisionDetails>
            <SubdivisionDetailLine>
              <SubdivisionDetailTitle>Restrictions</SubdivisionDetailTitle>
              <SubdivisionDetailContent>{restrictions || 'No restrictions'}</SubdivisionDetailContent>
            </SubdivisionDetailLine>

            <SubdivisionDetailLine>
              <SubdivisionDetailTitle>Description</SubdivisionDetailTitle>
              <SubdivisionDetailContent>
                <ExpandableText text={description} />
              </SubdivisionDetailContent>
            </SubdivisionDetailLine>

            <SubdivisionDetailLine>
              <SubdivisionDetailTitle>Amenities</SubdivisionDetailTitle>
              <SubdivisionDetailContent>
                {amenities.toSorted(naturalSortComparator()).join(', ') || 'N/A'}
              </SubdivisionDetailContent>
            </SubdivisionDetailLine>
          </SubdivisionDetails>

          <div className="mb-3 text-xl text-gray-700">Inventory</div>

          <SubdivisionHomeModelsTable homeModels={homeModels} subdivision={subdivision} className="p-3 bg-white rounded-lg" />
        </div>
        <aside className="w-128 min-w-96 space-y-8 overflow-auto pb-8">
          <SubdivisionMap
            latitude={geoCoords.lat}
            longitude={geoCoords.lng}
            className="aspect-video h-auto min-w-full max-w-full"
          />
          <SubdivisionAreaDetailsTable
            averageSchoolRating={averageSchoolRating}
            demographics={demographics}
            latitude={geoCoords.lat}
            longitude={geoCoords.lng}
            className="min-w-full bg-white"
          />
        </aside>
      </div>
    </div>
  );
}

export function SubdivisionDetailOutletContainer() {
  const { subdivision } = useOutletContext();

  return (
    <SubdivisionDetail subdivision={subdivision} />
  );
}
