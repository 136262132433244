import { useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { Card } from 'components/common/Card';
import { groupBy, maxBy, minBy, sumBy } from 'lodash';
import { eachMonthOfInterval, parseISO } from 'date-fns';
import { ABBR_MONTHS } from 'components/constants';
import { titleCase } from 'components/utils';

const { CanvasJSChart } = CanvasJSReact;
const formatMonthYear = date => `${titleCase(ABBR_MONTHS[date.getMonth()])} ${date.getFullYear() - 2000}`;

function CapitalNeedsChart({ deals }) {
  // TODO: make sure deals are filtered by deals that are not closed
  const firstCloseDate = minBy(deals, 'attributes.closingDate').attributes.closingDate;
  const lastCloseDate = maxBy(deals, 'attributes.closingDate').attributes.closingDate;
  const monthRange = eachMonthOfInterval({
    start: parseISO(firstCloseDate),
    end: parseISO(lastCloseDate),
  });
  const capitalsNeedsByMonth = groupBy(deals, d => formatMonthYear(parseISO(d.attributes.closingDate)));

  const [options] = useState({
    data: [
      {
        type: 'column',
        dataPoints: monthRange.map(monthDate => {
          const monthYearStr = formatMonthYear(monthDate);
          const dealsClosingInMonth = capitalsNeedsByMonth[monthYearStr];
          return {
            label: monthYearStr,
            y: sumBy(dealsClosingInMonth, 'attributes.acquisitionBasis'),
          };
        }),
      },
    ],
  });

  return (
    <Card header="Capital Needs">
      <CanvasJSChart options={options} />
    </Card>
  );
}

export default CapitalNeedsChart;
