import { useSelector } from 'react-redux';
import { ceil, compact, isNaN, isNil, max, mean, min, round } from 'lodash';
import { selectCurrentPropertyId } from 'components/DealConstruction/dealConstruction';
import { LoadingIndicator } from 'components/icons';
import { useRentCompsCensusTractMetricQuery } from 'redux/apiSlice';
import { formatCurrencyAbbreviated, formatInteger, formatNone, formatPercentageGrowth, median } from 'components/utils';

const COMPARABLE_CELL_WIDTH = 91;
const FIRST_COLUMN_WIDTH = 175;
const SECOND_COLUMN_WIDTH = 93;
const THIRD_COLUMN_WIDTH = 366;
const FIRST_HEADER_HEIGHT = 61;
const BORDER_RIGHT = 'border-r';
const BORDER_BOTTOM = 'border-b';

const locationData = (censusTractsMetrics, selectedUnitRents, subject) => {
  const comparableSetspropertyIds = selectedUnitRents.map(unitRent => unitRent.property.id);
  const subjectCensusTractMetric = censusTractsMetrics?.[subject.propertyId];
  const crimeIndexRawData = comparableSetspropertyIds.map(id => censusTractsMetrics?.[id]?.data?.CRMCYPROC);
  const householdIncomeRawData = compact(comparableSetspropertyIds.map(id => censusTractsMetrics?.[id]?.data?.MEDVAL_CY));
  const populationGrowthRawData = comparableSetspropertyIds.map(id => censusTractsMetrics?.[id]?.data?.POPGRWCYFY);
  const householdGrowthRawData = comparableSetspropertyIds.map(id => censusTractsMetrics?.[id]?.data?.HHGRWCYFY);

  const table = [];

  const secondHeader = [
    ['', formatNone, FIRST_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    ['', formatNone, SECOND_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    ['MIN', formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    ['AVG', formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    ['MEDIAN', formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    ['MAX', formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
  ];
  table.push(secondHeader);

  const minYearBuilt = ceil(min(selectedUnitRents.map(({ property }) => property.yearBuilt)));
  const averageYearBuilt = ceil(mean(compact(selectedUnitRents.map(({ property }) => property.yearBuilt))));
  const medianYearBuilt = ceil(median(compact(selectedUnitRents.map(({ property }) => property.yearBuilt))));
  const maxYearBuilt = ceil(max(selectedUnitRents.map(({ property }) => property.yearBuilt)));
  table.push([
    ['YEAR BUILT', formatNone, FIRST_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [subject.property.yearBuilt, formatNone, SECOND_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [minYearBuilt, formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [averageYearBuilt, formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [medianYearBuilt, formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [maxYearBuilt, formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
  ]);

  const minproperySquareFootage = ceil(min(selectedUnitRents.map(({ property }) => property.rentableBuildingArea)));
  const averageproperySquareFootage = ceil(mean(compact(selectedUnitRents.map(({ property }) => property.rentableBuildingArea))));
  const medianproperySquareFootage = ceil(median(compact(selectedUnitRents.map(({ property }) => property.rentableBuildingArea))));
  const maxproperySquareFootage = ceil(max(selectedUnitRents.map(({ property }) => property.rentableBuildingArea)));
  table.push([
    ['PROPERTY SF', formatNone, FIRST_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [subject.property.rentableBuildingArea, formatInteger, SECOND_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [minproperySquareFootage, formatInteger, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [averageproperySquareFootage, formatInteger, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [medianproperySquareFootage, formatInteger, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [maxproperySquareFootage, formatInteger, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
  ]);

  const minLotSizeSquareFootage = ceil(min(selectedUnitRents.map(({ property }) => property.landAreaSf)));
  const averageLotSizeSquareFootage = ceil(mean(compact(selectedUnitRents.map(({ property }) => property.landAreaSf))));
  const medianLotSizeSquareFootage = ceil(median(compact(selectedUnitRents.map(({ property }) => property.landAreaSf))));
  const maxLotSizeSquareFootage = ceil(max(selectedUnitRents.map(({ property }) => property.landAreaSf)));
  table.push([
    ['LOT SIZE SF', formatNone, FIRST_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [subject.property.landAreaSf, formatInteger, SECOND_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [minLotSizeSquareFootage, formatInteger, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [averageLotSizeSquareFootage, formatInteger, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [medianLotSizeSquareFootage, formatInteger, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [maxLotSizeSquareFootage, formatInteger, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
  ]);

  const minPropertyUnit = ceil(min(selectedUnitRents.map(({ property }) => property.numberOfUnits)));
  const averagePropertyUnit = ceil(mean(compact(selectedUnitRents.map(({ property }) => property.numberOfUnits))));
  const medianPropertyUnit = ceil(median(compact(selectedUnitRents.map(({ property }) => property.numberOfUnits))));
  const maxPropertyUnit = ceil(max(selectedUnitRents.map(({ property }) => property.numberOfUnits)));
  table.push([
    ['PROPERTY UNITS', formatNone, FIRST_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [subject.property.numberOfUnits, formatNone, SECOND_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [minPropertyUnit, formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [averagePropertyUnit, formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [medianPropertyUnit, formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [maxPropertyUnit, formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
  ]);

  const minCrimeIndex = ceil(min(crimeIndexRawData));
  const averageCrimeIndex = ceil(mean(crimeIndexRawData));
  const medianCrimeIndex = ceil(median(crimeIndexRawData));
  const maxCrimeIndex = ceil(max(crimeIndexRawData));
  table.push([
    ['CRIME INDEX', formatNone, FIRST_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [subjectCensusTractMetric?.data.CRMCYPROC, formatNone, SECOND_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [minCrimeIndex, formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [averageCrimeIndex, formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [medianCrimeIndex, formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [maxCrimeIndex, formatNone, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
  ]);

  const minHouseholdIncome = round(min(householdIncomeRawData), 2);
  const averageHouseholdIncome = round(mean(householdIncomeRawData), 2);
  const medianHouseholdIncome = round(median(householdIncomeRawData), 2);
  const maxHouseholdIncome = round(max(householdIncomeRawData), 2);
  table.push([
    ['HOUSEHOLD INCOME', formatNone, FIRST_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [subjectCensusTractMetric?.data.MEDVAL_CY, formatCurrencyAbbreviated, SECOND_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [minHouseholdIncome, formatCurrencyAbbreviated, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [averageHouseholdIncome, formatCurrencyAbbreviated, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [medianHouseholdIncome, formatCurrencyAbbreviated, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [maxHouseholdIncome, formatCurrencyAbbreviated, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
  ]);

  const minPopulationGrowth = min(populationGrowthRawData);
  const averagePopulationGrowth = mean(populationGrowthRawData);
  const medianPopulationGrowth = median(populationGrowthRawData);
  const maxPopulationGrowth = max(populationGrowthRawData);
  table.push([
    ['POPULATION GROWTH', formatNone, FIRST_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [subjectCensusTractMetric?.data.POPGRWCYFY, formatPercentageGrowth, SECOND_COLUMN_WIDTH, [BORDER_RIGHT, BORDER_BOTTOM].join(' ')],
    [minPopulationGrowth, formatPercentageGrowth, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [averagePopulationGrowth, formatPercentageGrowth, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [medianPopulationGrowth, formatPercentageGrowth, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
    [maxPopulationGrowth, formatPercentageGrowth, COMPARABLE_CELL_WIDTH, BORDER_BOTTOM],
  ]);

  const minHouseholdGrowth = min(householdGrowthRawData);
  const averageHouseholdGrowth = mean(householdGrowthRawData);
  const medianHouseholdGrowth = median(householdGrowthRawData);
  const maxHouseholdGrowth = max(householdGrowthRawData);
  table.push([
    ['HOUSEHOLD GROWTH', formatNone, FIRST_COLUMN_WIDTH, [BORDER_RIGHT].join(' ')],
    [subjectCensusTractMetric?.data.HHGRWCYFY, formatPercentageGrowth, SECOND_COLUMN_WIDTH, [BORDER_RIGHT].join(' ')],
    [minHouseholdGrowth, formatPercentageGrowth, COMPARABLE_CELL_WIDTH],
    [averageHouseholdGrowth, formatPercentageGrowth, COMPARABLE_CELL_WIDTH],
    [medianHouseholdGrowth, formatPercentageGrowth, COMPARABLE_CELL_WIDTH],
    [maxHouseholdGrowth, formatPercentageGrowth, COMPARABLE_CELL_WIDTH],
  ]);

  return table;
};

function TableCell({ cell, cellIndex, rowIndex }) {
  const [value, format, cellWidth, border] = cell;
  let textColor = rowIndex === 0 ? 'text-neutral-medium' : '';
  textColor = cellIndex === 0 && rowIndex === 0 ? `${textColor} bg-primary-surface` : '';
  const align = cellIndex === 0 && rowIndex !== 0 ? 'text-left text-neutral-medium bg-primary-surface uppercase' : 'text-right text-neutral-dark';

  return (
    <div
      style={{ width: `${cellWidth}px`, height: '53px' }}
      className={`pt-4 px-4 text-xs font-medium ${align} ${textColor} ${border}`}
    >
      {isNaN(value) || isNil(value) || value === 0 ? '-' : format(value)}
    </div>
  );
}

function TableRow({ row, rowIndex }) {
  return row.map((cell, cellIndex) => <TableCell key={`table-one-${rowIndex}-${cellIndex}`} cell={cell} cellIndex={cellIndex} rowIndex={rowIndex} />);
}

function LocationSummaryTab({ selectedUnitRents, subject }) {
  const propertyId = useSelector(state => selectCurrentPropertyId(state));
  const selectedPropertyIds = compact([propertyId, ...selectedUnitRents.map(unitRent => unitRent.property.id)]);
  const { data: censusTractsMetrics, isFetching: isFetchingCensusTractsMetrics } = useRentCompsCensusTractMetricQuery({ selectedPropertyIds });

  if (isFetchingCensusTractsMetrics) {
    return (
      <div className="w-8/12 py-6 flex flex-col container">
        <div className="flex w-full justify-center">
          <LoadingIndicator className="w-8 text-blue-400" />
        </div>
      </div>
    );
  }

  const table = locationData(censusTractsMetrics, selectedUnitRents, subject);

  return (
    <div className="mx-auto">
      <div className="text-base font-medium mb-4 text-neutral-dark text-left">Property/Location Summary</div>
      <div className="flex flex-col border border-gray-200 rounded-lg" style={{ width: '631px' }}>
        <div className="flex border-b border-gray-200 bg-primary-surface text-neutral-dark text-base font-medium" style={{ height: `${FIRST_HEADER_HEIGHT}px` }}>
          <div className="py-4.5 text-center border-r border-gray-200" style={{ width: `${FIRST_COLUMN_WIDTH}px` }} />
          <div className="py-4.5 text-center border-r border-gray-200" style={{ width: `${SECOND_COLUMN_WIDTH}px` }}>Subject </div>
          <div className="py-4.5 text-center border-gray-200" style={{ width: `${THIRD_COLUMN_WIDTH}px` }}>Comparable Set </div>
        </div>
        {
          table.map((row, rowIndex) => (
            <div key={`table-one-${rowIndex}`} className="flex">
              <TableRow row={row} rowIndex={rowIndex} />
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default LocationSummaryTab;
