import cx from 'classnames';
import { useRef, useState } from 'react';
import Button from './Button';

/**
 * @param {HTMLInputElement['accept']} accept
 * @param {(files: File[]) => void} onFileUpload
 */
export default function FileUploadArea({ accept, onFileUpload }) {
  const [dragging, setDragging] = useState(false);
  const areaDiv = useRef(null);

  const handleFileInput = (e) => {
    onFileUpload(Array.from(e.target.files));
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files.length > 0) {
      onFileUpload(Array.from(e.dataTransfer.files));
    }
  };

  const dragStart = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const dragEnd = (event) => {
    if (!areaDiv.current || areaDiv.current.contains(event.relatedTarget)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  return (
    <div
      ref={areaDiv}
      className={cx(
        'w-full h-full flex flex-col justify-center items-center text-center border-dashed border-2 border-gray-300 cursor-pointer',
        { 'bg-black bg-opacity-8': dragging },
      )}
      onDragEnter={dragStart}
      onDragOver={dragStart}
      onDragLeave={dragEnd}
      onDrop={onDrop}
    >
      <label className="pb-20 pt-6 px-12 text-black text-opacity-54 cursor-pointer">
        Drop files here to upload or use the &lsquo;Attach a File&rsquo; action
        <input id="fileUpload" type="file" multiple accept={accept} className="hidden" onChange={handleFileInput} />
      </label>

      <Button
        transparent
        shadow={false}
        submit={false}
        text={<label htmlFor="fileUpload" className="cursor-pointer">Attach a File</label>}
        className="font-semibold text-black border border-black border-opacity-12 -mt-16 mb-6"
      />
    </div>
  );
}
