import { createColumnHelper } from '@tanstack/react-table';
import {
  CurrencyCell,
  RouteLinkCell,
  ZonedDatetimeCell,
} from 'components/shared/Table/Cells';
import DataTable from 'components/shared/Table/DataTable';
import { dataTableMeta, selectRowOnClick, tableConfigMeta } from 'components/shared/Table/table.helpers';
import { useFetchSelfQuery } from '../../redux/apiSlice';
import { useFetchOffMarketMarketplaceOffersQuery } from '../../redux/offMarketMarketplaceApiSlice';

/** @type {import('@tanstack/react-table').TableOptions['defaultColumn']} */
export const OFFER_DEFAULT_COLUMN = {
  sortUndefined: 'last',
};

const columnHelper = createColumnHelper();

const listingOrgNameColumn = columnHelper.accessor('marketplaceOrganization.name', { header: 'Listed By' });
const offeringOrgNameColumn = columnHelper.accessor(row => row.clientName || row.organization.name, { header: 'Offered By' });
const dealLinkColumn = columnHelper.display({
  id: 'dealLink',
  cell: RouteLinkCell,
  meta: {
    linkPath: '/deals/:id',
    ...tableConfigMeta({ visibility: false, order: 'hidden' }),
  },
});

const offMarketBrokerColumns = [
  dealLinkColumn,
  listingOrgNameColumn,
  offeringOrgNameColumn,
  columnHelper.accessor('market', { header: 'Market', filterFn: 'equals' }),
  columnHelper.accessor('address', { header: 'Address' }),
  columnHelper.accessor('city', { header: 'City' }),
  columnHelper.accessor('state', { header: 'State' }),
  columnHelper.accessor('zip', {
    header: 'Zip',
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('offerStatus', { header: 'Offer Status' }),
  columnHelper.accessor('offerDate', { header: 'Offer Date', cell: ZonedDatetimeCell }),
  columnHelper.accessor('offerPrice', {
    header: 'Offer Price',
    cell: CurrencyCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('contractPrice', {
    header: 'Contract Price',
    cell: CurrencyCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('listingPrice', {
    header: 'Listing Price',
    cell: CurrencyCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('createdAt', {
    header: 'Created At',
    cell: ZonedDatetimeCell,
    sortDescFirst: true,
    meta: {
      datetimeFormat: 'MMM d, yyyy (zzz)',
      // use user's timezone
      datetimeTimeZone: null,
      ...dataTableMeta.textRight,
    },
  }),
  columnHelper.accessor('updatedAt', {
    header: 'Updated At',
    cell: ZonedDatetimeCell,
    sortDescFirst: true,
    meta: {
      datetimeFormat: 'MMM d, yyyy (zzz)',
      // use user's timezone
      datetimeTimeZone: null,
      ...dataTableMeta.textRight,
    },
  }),
];

const allColumnsSet = new Set(offMarketBrokerColumns);
const brokerOnlyColumnsSet = new Set([dealLinkColumn, listingOrgNameColumn, offeringOrgNameColumn]);

const offMarketSellerColumns = Array.from(allColumnsSet.difference(brokerOnlyColumnsSet));

/** @type {import('@tanstack/react-table').InitialTableState} */
const tableInitialState = {};

/**
 * @param {string} tableContainerClassName
 * @param {import('react').ReactNode} children
 */
export default function OffMarketOfferTable({ children }) {
  const { currentData, isUninitialized, isFetching } = useFetchOffMarketMarketplaceOffersQuery();
  const { currentData: { settings: { offMarketBroker } = {} } = {}, isFetching: isFetchingSelf } = useFetchSelfQuery();

  return (
    <DataTable
      virtual
      columns={offMarketBroker === true ? offMarketBrokerColumns : offMarketSellerColumns}
      data={currentData}
      initialState={tableInitialState}
      defaultColumn={OFFER_DEFAULT_COLUMN}
      isLoading={(isUninitialized || isFetching || isFetchingSelf) && !currentData}
      tableContainerClassName="h-max max-h-container whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0"
      onRowClick={selectRowOnClick}
      enableMultiRowSelection={false}
    >
      {children}
    </DataTable>
  );
}
