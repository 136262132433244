/* eslint-disable jsx-a11y/img-redundant-alt */
import { Chevron, X } from 'components/icons';
import Modal from 'components/NewModal';
import Badge from 'components/shared/Badge';
import RoundButton from 'components/common/RoundButton';
import { formatCurrency, formatInteger } from 'components/utils';
import { useState, useEffect, useCallback } from 'react';

function PhotoGalleryHeader({ setShowPhotoGalleryModal }) {
  return (
    <div className="sticky top-0 bg-white z-10 flex items-center justify-between p-4 border-b">
      <div className="flex cursor-pointer" onClick={() => setShowPhotoGalleryModal(false)}>
        <Chevron direction="left" className="w-8 h-8 text-black" />
        <h3 className="text-base font-medium text-gray-900 mt-1 hover:underline">Back to form</h3>
      </div>
      <RoundButton
        name="closeModalButton"
        padding="p-2 border border-neutral-dark border-opacity-12"
        onClick={() => setShowPhotoGalleryModal(false)}
      >
        <X className="w-5" />
      </RoundButton>
    </div>
  );
}

function PhotoGallery({ pictures, handleGalleryClick, setShowPhotoGalleryModal }) {
  const renderImagesInRows = () => {
    const rows = [];
    for (let i = 0; i < pictures.length; i += 3) {
      rows.push(
        <div key={i} className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
          <div className="cursor-pointer w-full h-full md:col-span-2" onClick={() => handleGalleryClick(i)}>
            <img
              src={pictures[i]?.url}
              alt={`Picture ${i + 1}`}
              className="object-contain w-full h-full rounded"
              referrerPolicy="no-referrer"
            />
          </div>
          {pictures[i + 1] && (
            <div className="cursor-pointer w-full h-full" onClick={() => handleGalleryClick(i + 1)}>
              <img
                src={pictures[i + 1]?.url}
                alt={`Picture ${i + 2}`}
                className="object-contain w-full h-full rounded"
                referrerPolicy="no-referrer"
              />
            </div>
          )}
          {pictures[i + 2] && (
            <div className="cursor-pointer w-full h-full" onClick={() => handleGalleryClick(i + 2)}>
              <img
                src={pictures[i + 2]?.url}
                alt={`Picture ${i + 3}`}
                className="object-contain w-full h-full rounded"
                referrerPolicy="no-referrer"
              />
            </div>
          )}
        </div>,
      );
    }
    return rows;
  };

  return (
    <Modal show size="large" showHeader={false} customHeader={<PhotoGalleryHeader setShowPhotoGalleryModal={setShowPhotoGalleryModal}/>}>
      {renderImagesInRows()}
    </Modal>
  );
}

function PhotoSlider({ pictures, currentIndex, handleNext, handlePrev, listing, property, setShowPhotoSliderModal }) {
  return (
    <Modal
      show
      onClose={() => setShowPhotoSliderModal(false)}
      size="fullScreen"
      showHeader={false}
      placement="center"
      backgroundColor="bg-black bg-opacity-80"
    >
      <div className="flex flex-col w-full h-full overflow-hidden">
        <div className="flex justify-between items-center w-full h-20 border-b border-white px-4">
          <h3 className="text-xl text-white font-bold">Photos</h3>
          <RoundButton name="outputModalButton" padding="p-2 border border-white" onClick={() => setShowPhotoSliderModal(false)}>
            <X className="w-5 text-white" />
          </RoundButton>
        </div>
        <div className="relative flex flex-col items-center justify-center select-none" style={{ height: 'calc(100vh - 5rem)' }}>
          <div className="h-auto md:h-3/4 bg-white rounded-xl relative">
            <img
              src={pictures[currentIndex]?.url}
              alt={`Picture ${currentIndex + 1}`}
              className="object-contain rounded-xl h-full p-0.5 md:p-1"
              referrerPolicy="no-referrer"
            />
            <div className="absolute text-center top-2 right-2 m-auto py-1.5 px-3 text-white text-sm font-medium rounded-lg bg-neutral-medium bg-opacity-40">
              {`${currentIndex + 1} of ${pictures.length}`}
            </div>
          </div>
          <div className="text-white text-sm mt-2 text-center font-semibold">
            {`${listing.status}: ${formatCurrency(listing.listPrice)} `}
            (
            {property.propertyBedroomsTotal}
            {' '}
            Beds,
            {property.propertyBathroomsTotal}
            {' '}
            Baths,
            {formatInteger(property.rentableBuildingArea)}
            {' '}
            Sq. Ft.)
          </div>
          <div
            className="flex absolute left-1 md:left-5 top-1/2 transform -translate-y-1/2 w-12 h-12 py-2 px-2 cursor-pointer border border-neutral-dark/[.12] rounded-full bg-white bg-opacity-36"
            onClick={handlePrev}
          >
            <Chevron direction="left" className="w-8 h-8 text-black" />
          </div>
          <div
            className="flex absolute right-1 md:right-5 top-1/2 transform -translate-y-1/2 w-12 h-12 py-2 px-2 cursor-pointer border border-neutral-dark/[.12] rounded-full bg-white bg-opacity-36"
            onClick={handleNext}
          >
            <Chevron direction="right" className="w-8 h-8 text-black" />
          </div>
        </div>
      </div>
    </Modal>
  );
}

function NewPhotoModal({ property, listing, pictures = [] }) {
  const [showPhotoGalleryModal, setShowPhotoGalleryModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showPhotoSliderModal, setShowPhotoSliderModal] = useState(false);

  const handleImageClick = () => {
    setShowPhotoGalleryModal(true);
  };

  const handleGalleryClick = index => {
    setCurrentIndex(index);
    setShowPhotoSliderModal(true);
  };

  const handleNext = useCallback(e => {
    e.stopPropagation();
    setCurrentIndex(prevIndex => (prevIndex + 1) % pictures.length);
  }, [pictures.length]);

  const handlePrev = useCallback(e => {
    e.stopPropagation();
    setCurrentIndex(prevIndex => (prevIndex - 1 + pictures.length) % pictures.length);
  }, [pictures.length]);

  const handleKeyDown = useCallback(e => {
    const nextKeys = ['ArrowRight', 'ArrowDown'];
    const prevKeys = ['ArrowLeft', 'ArrowUp'];

    if ([...nextKeys, ...prevKeys].includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (nextKeys.includes(e.key)) {
      handleNext(e);
    } else if (prevKeys.includes(e.key)) {
      handlePrev(e);
    }
  }, [handleNext, handlePrev]);

  useEffect(() => {
    if (showPhotoSliderModal) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showPhotoSliderModal, handleKeyDown]);

  return (
    <div className="w-full h-max">
      <div className="relative cursor-pointer" onClick={handleImageClick}>
        <img alt={pictures[0]?.url} className="rounded-xl object-contain" referrerPolicy="no-referrer" src={pictures[0]?.url} />
        <Badge
          label={pictures.length ? `${pictures.length} Photos` : ''}
          className="absolute bottom-5 right-5 bg-white border"
          onClick={handleImageClick}
        />
      </div>
      {showPhotoGalleryModal && (
        <PhotoGallery pictures={pictures} handleGalleryClick={handleGalleryClick} setShowPhotoGalleryModal={setShowPhotoGalleryModal} />
      )}
      {showPhotoSliderModal && (
        <PhotoSlider
          pictures={pictures}
          currentIndex={currentIndex}
          handleNext={handleNext}
          handlePrev={handlePrev}
          listing={listing}
          property={property}
          setShowPhotoSliderModal={setShowPhotoSliderModal}
        />
      )}
    </div>
  );
}

export default NewPhotoModal;
