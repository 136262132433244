import { useEffect, useMemo, useState } from 'react';
import { snakeCaseKeys } from 'components/utils';
import { isEqual, isNil, some } from 'lodash';
import { createPortfolioPath, portfolioPath } from 'components/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchPortfolioQuery } from 'redux/apiSlice';
import { LAYOUT } from 'components/constants';
import { LoadingIndicator } from 'components/icons';
import PortfolioHeader from 'components/portfolio/PortfolioHeader';
import SideMenu, { MENU_ITEMS } from 'components/portfolio/Menu/Menu';
import PortfolioForm from 'components/portfolio/PortfolioForm';

export default function PortfolioEdit({ newPortfolio = false }) {
  const { portfolioId } = useParams();
  const { currentData: basePortfolio, refetch: refetchPortfolio } = useFetchPortfolioQuery({ id: newPortfolio ? 'new' : portfolioId });
  // TODO: handle error if fetch fails

  const [portfolio, setPortfolio] = useState();
  useEffect(() => {
    if (basePortfolio) {
      setPortfolio(basePortfolio);
    }
  }, [basePortfolio]);

  const [currentTab, setCurrentTab] = useState(MENU_ITEMS[0].value);
  const [alert, setAlert] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [invalidTabs, setInvalidTabs] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const validatePortfolioParams = (portfolioToValidate) => {
    setValidationErrors({});
    setInvalidTabs([]);
    if (isNil(portfolio.parameters.defaultEffectiveTaxRate)) {
      setInvalidTabs([...invalidTabs, 'taxes']);
      setValidationErrors({ defaultEffectiveTaxRate: 'Required: Used if Assessor Tax Rate is turned off or unavailable' });
      return ({ text: 'Required: Used if Assessor Tax Rate is turned off or unavailable', type: 'warning' });
    }
    if (portfolioToValidate.parameters.refinancingSizingMethod && portfolioToValidate.parameters.term >= ((portfolioToValidate.parameters.holdPeriod - 1) * 12)) {
      return ({ text: 'Loan term cannot exceed hold period if refinancing', type: 'warning' });
    }
    if (some(portfolioToValidate.parameters.capitalItems, (capitalItem) => capitalItem.startDate == null || capitalItem.startDate === false || !capitalItem.quantity || !capitalItem.label)) {
      return ({ text: 'Your capital items are missing required info', type: 'warning' });
    }
    if (some(portfolioToValidate.parameters.capitalFees, (capitalFee) => capitalFee.startDate == null || capitalFee.startDate === false || !capitalFee.quantity || !capitalFee.label)) {
      return ({ text: 'Your capital fees are missing required info', type: 'warning' });
    }
    return null;
  };

  // TODO: move to redux
  const onUpdate = () => {
    const validationAlert = validatePortfolioParams(portfolio);
    if (validationAlert) {
      setAlert(validationAlert);
      return;
    }
    setIsUpdating(true);
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    fetch(portfolioPath(portfolio), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
      body: JSON.stringify(snakeCaseKeys(portfolio)),
    })
      .then(response => response.json())
      .then(() => {
        // TODO: handle error states
        refetchPortfolio();
        setAlert({ text: 'Updates saved', type: 'success' });
        setIsUpdating(false);
        setTimeout(() => setAlert(null), 4000);
      });
  };

  const navigate = useNavigate();
  const onCreate = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Please note: after creating a new Portfolio, it may take up to an hour for return metrics to display on the Deal Sourcing page.\nThe Investment Criteria & Assumptions entered here will be displayed immediately on the Property Page and Model page for individual listings.') === true) {
      const validationAlert = validatePortfolioParams(portfolio);
      if (validationAlert) {
        setAlert(validationAlert);
        return;
      }
      setIsUpdating(true);
      const csrfToken = document.querySelector('[name=csrf-token]').content;
      fetch(createPortfolioPath, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
        },
        body: JSON.stringify(snakeCaseKeys(portfolio)),
      }).then(response => response.json())
        .then(json => {
          // TODO: handle error states
          navigate(portfolioPath({ id: json.id }), { replace: true });
        });
    }
  };

  const onReset = () => {
    setPortfolio(basePortfolio ?? {});
  };

  const hasChanges = useMemo(() => !isEqual(basePortfolio, portfolio), [basePortfolio, portfolio]);

  return (
    <div className="bg-gray-100">
      <PortfolioHeader
        editing
        hasChanges={hasChanges}
        portfolio={basePortfolio}
        onCreate={onCreate}
        onReset={onReset}
        onUpdate={onUpdate}
      />
      <div className="flex">
        <div className="w-1/5 pt-6 overflow-y-auto" style={{ height: `calc(100vh - ${LAYOUT.portfolioHeaderHeight}px)` }}>
          <SideMenu currentTab={currentTab} setCurrentTab={setCurrentTab} invalidTabs={invalidTabs} />
        </div>
        <div className="w-4/5 p-6 overflow-y-auto" style={{ height: `calc(100vh - ${LAYOUT.portfolioHeaderHeight}px)` }}>
          {!portfolio ? (
            <LoadingIndicator className="w-6 text-primary-500" />
          ) : (
            <PortfolioForm
              portfolio={portfolio}
              setPortfolio={setPortfolio}
              currentTab={currentTab}
              alert={alert}
              setAlert={setAlert}
              isUpdating={isUpdating}
              setIsUpdating={setIsUpdating}
              validatePortfolioParams={validatePortfolioParams}
              basePortfolio={basePortfolio ?? {}}
              validationErrors={validationErrors}
            />
          )}
        </div>
      </div>
    </div>
  );
}
