import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Popover } from '@headlessui/react';
import { useBlocker } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getStorageValue, setStorageValue } from 'hooks/useLocalStorage';
import { cloneDeep, isEmpty, isEqual, partial, set, sum, sumBy } from 'lodash';
import { clearToast, showToast } from 'redux/toastSlice';
import { showSaveChangesModal } from 'actions/deal_navigation';
import { useUpdateScenariosMutation } from 'redux/dealApiSlice';
import Button from 'components/shared/NewButton';
import {
  coalesceProperty,
  dotProductBy,
  formatCurrency,
  formatDate,
  formatEquityMultiple,
  formatInteger,
  formatPercentage,
  parseEventValue,
} from 'components/utils';
import { HOA_FEE_EXPENSE_ITEM_NAME, LAYOUT } from 'components/constants';
import { TOAST_UNSAVED_CHANGES } from 'components/toast';
import { calcCashFlows, calcReturnMetrics, calcReturnMetricsOfIndividualScenarios } from 'components/dcf/dcf';
import DataTable from 'components/shared/Table/DataTable';
import { AddressCell } from 'components/shared/Table/Cells';
import Toggle from 'components/Toggle';
import useElementHeight from 'hooks/useElementHeight';
import Input from 'components/Input';
import { Check, LoadingIndicator, X } from 'components/icons';
import { ITEMIZED_INPUT_METHOD_DPM } from 'components/dcf/itemizedItem';
import { dataTableMeta, enableConfigPresets, tableConfigMeta } from 'components/shared/Table/table.helpers';
import { InlineFormField, InlineInfoField } from 'components/Form';
import DataTableConfig from 'components/shared/Table/dataTableConfig/DataTableConfig';
import DataTableConfigPanelBody from 'components/shared/Table/dataTableConfig/DataTableConfigPanelBody';
import { EMPTY_PRESET, getDefaultPresetId, STORAGE_VERSION, visibilityStorageKey } from 'components/shared/Table/dataTableConfig/utils';
import { DataTableContent } from 'components/shared/Table/DataTableContext';
import { useFetchHomeModelQuery } from '../../redux/homeModelApiSlice';
import { matchesBuyBox } from './portfolio-deal.utils';

const TOGGLE_COLUMNS_HEIGHT = 40;
const SUMMARY_HEIGHT = 64;
const LOCAL_STORAGE_PORTFOLIO_DEAL_SUMMARY_VERSION = 1;

const getTableId = ({ newBuildDeal = false }) => {
  if (newBuildDeal) {
    return 'PortfolioDealSummaryNewBuild';
  }

  return 'PortfolioDealSummaryIndividual';
};

const ALL_TABLE_IDS = Object.freeze([
  getTableId({ individuallyModelled: true }),
  getTableId({ newBuildDeal: true }),
]);

const DEFAULT_PRESET = Object.freeze({
  ...EMPTY_PRESET,
  visibility: Object.freeze({
    capital: false,
    stabilizationMonth: false,
    taxes: false,
    hoa: false,
    rollToMarket: false,
  }),
});
const CONFIG_PRESETS = Object.freeze(Object.fromEntries(ALL_TABLE_IDS.map((tableId) => (
  [
    tableId,
    Object.freeze({
      [getDefaultPresetId({ tableId })]: Object.freeze({
        ...DEFAULT_PRESET,
        id: getDefaultPresetId({ tableId }),
      }),
    }),
  ]
))));

// migrate old DataTable.PortfolioDealSummary.columnVisibility settings to new storage format
/** @type {Record<string, boolean> | null} */
const localStorageColVisibility = getStorageValue('DataTable.PortfolioDealSummary.columnVisibility', null, LOCAL_STORAGE_PORTFOLIO_DEAL_SUMMARY_VERSION);
if (localStorageColVisibility !== null) {
  ALL_TABLE_IDS.forEach((tableId) => {
    const presetId = getDefaultPresetId({ tableId });
    Object.entries(localStorageColVisibility).forEach(([columnId, visibility]) => {
      setStorageValue(visibilityStorageKey({ columnId, presetId }), visibility, STORAGE_VERSION);
    });
  });

  setStorageValue('DataTable.PortfolioDealSummary.columnVisibility', null, LOCAL_STORAGE_PORTFOLIO_DEAL_SUMMARY_VERSION);
}

const getScenario = (property, scenarios) => scenarios.find(s => s.primary && (property.id === s.propertyId));
const getUnits = (property, scenario) => scenario.parameters.units;
const getParcel = (property, parcels) => parcels.find(parcel => parcel.apn === property.apnMin);
const getRent = (scenario, rentType) => sumBy(scenario.parameters.units, rentType);
const getReturnMetric = (scenario, metric) => scenario?.returnMetrics?.[metric];

function TableConfigButton() {
  return (
    <Popover className="relative">
      <Popover.Button as="div" className="mr-2">
        <Button label="Toggle Columns" outlined small />
      </Popover.Button>
      <Popover.Panel className="absolute right-0 z-30 w-max max-h-96 overflow-auto bg-white rounded shadow">
        <DataTableConfigPanelBody className="p-2" />
      </Popover.Panel>
    </Popover>
  );
}

function InputCell({ column, getValue, table, row: { original } }) {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const initialScenarioValue = useMemo(() => column.accessorFn({ scenario: original.initialScenario }), [column, original]);
  const isChanged = initialScenarioValue !== value;

  return (
    <div className="w-24">
      <Input
        className={isChanged ? 'bg-blue-50' : null}
        addOnClassName="overflow-hidden"
        type="currency"
        value={value}
        onChange={(e) => table.options.meta?.onScenarioChange(original.scenario?.id, column.columnDef.meta.name, parseEventValue(e))}
      />
    </div>
  );
}

function HomeModelQuantityCell({
  getValue,
  row: {
    original: {
      property: { homeModelId },
      scenario: {
        id: scenarioId,
        parameters: { homeModelDeliverySchedule },
      },
    },
  },
  table: { options: { meta: { onScenarioChange } } },
}) {
  const { currentData } = useFetchHomeModelQuery(homeModelId);
  const { numAvailable, futureDeliveries } = currentData ?? {};
  // combine dates specified in the scenario and dates in home model
  // there could be dates in the scenario that are not in the home model
  const deliveryDates = useMemo(() => (
    Array
      .from(new Set(
        [...(futureDeliveries ?? []), ...(homeModelDeliverySchedule ?? [])].map(([date]) => date),
      ))
      .toSorted()
  ), [homeModelDeliverySchedule, futureDeliveries]);

  const futureDeliveriesMap = useMemo(() => (
    Object.fromEntries(futureDeliveries ?? [])
  ), [futureDeliveries]);
  const deliveryScheduleMap = useMemo(() => (
    Object.fromEntries(homeModelDeliverySchedule ?? [])
  ), [homeModelDeliverySchedule]);

  return (
    <Popover className="relative w-20">
      <Popover.Button className="rounded border cursor-text py-1 px-2 w-full bg-white text-right">
        {getValue()}
      </Popover.Button>

      <Popover.Panel className="absolute left-0 w-80 mt-1 p-2 z-30 flex flex-col gap-y-3 rounded border bg-white shadow">
        {!currentData ? (
          <LoadingIndicator className="size-6 mx-auto text-tertiary" />
        ) : (
          <>
            <InlineInfoField label="Availability">
              <div className="flex flex-col text-right tabular-nums text-sm">
                <div>{`Available Now: ${numAvailable}`}</div>
                {deliveryDates.map((date) => (
                  <div key={date}>{`${formatDate(date, 'MMM yyyy')}: ${futureDeliveriesMap[date] ?? 0}`}</div>
                ))}
              </div>
            </InlineInfoField>
            <InlineFormField
              type="number"
              name="quantity"
              value={getValue()}
              onChange={(evt) => onScenarioChange(scenarioId, 'multiplicity', parseEventValue(evt))}
            />
            {deliveryDates.map((date) => (
              <InlineFormField
                key={date}
                type="number"
                name={`quantity${date}`}
                label={`Quantity (${formatDate(date, 'MMM yyyy')})`}
                value={deliveryScheduleMap?.[date] ?? 0}
                onChange={(evt) => (
                  onScenarioChange(
                    scenarioId,
                    'homeModelDeliverySchedule',
                    Object.entries({ ...deliveryScheduleMap, [date]: parseEventValue(evt) }),
                  )
                )}
              />
            ))}
          </>
        )}
      </Popover.Panel>
    </Popover>
  );
}

function HomeModelAvailableNow({ row: { original: { property: { homeModelId } } } }) {
  const { currentData } = useFetchHomeModelQuery(homeModelId);
  const { numAvailable } = currentData ?? {};
  return numAvailable;
}

function HomeModelAvailableTotal({ row: { original: { property: { homeModelId } } } }) {
  const { currentData } = useFetchHomeModelQuery(homeModelId);
  const { numAvailable, futureDeliveries } = currentData ?? {};
  const totalFuture = futureDeliveries ? sumBy(futureDeliveries, pair => pair[1]) : 0;
  return numAvailable + totalFuture;
}

const ADDRESS_COLUMN = {
  id: 'address',
  header: 'Address',
  enableHiding: false,
  accessorKey: 'property.address',
  cell: AddressCell,
  sortingFn: 'text',
  meta: { className: 'pl-3', ...tableConfigMeta({ order: 'readonly' }) },
};

const COMMON_COLUMNS = [
  ADDRESS_COLUMN,
  {
    id: 'propertyType',
    header: 'Property Type',
    accessorKey: 'property.propertyType',
  },
  {
    id: 'bed',
    header: 'Bed',
    accessorKey: 'property.bedrooms',
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'bath',
    header: 'Bath',
    accessorKey: 'property.bathrooms',
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'sqft',
    header: 'Sq Ft',
    accessorKey: 'property.livingArea',
    cell: ({ getValue }) => formatInteger(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'vintage',
    header: 'Vintage',
    accessorKey: 'property.yearBuilt',
    meta: { ...dataTableMeta.textRight },
  },
];

const NEW_BUILD_COLUMNS = [
  {
    id: 'multiplicity',
    header: 'Quantity',
    accessorKey: 'scenario.parameters.multiplicity',
    cell: HomeModelQuantityCell,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'availableNow',
    header: 'Available Now',
    cell: HomeModelAvailableNow,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'availableTotal',
    header: 'Available Total',
    cell: HomeModelAvailableTotal,
    meta: { ...dataTableMeta.textRight },
  },

];

const INDIVIDUAL_COLUMNS = [
  {
    id: 'askPrice',
    header: 'Ask Price',
    accessorFn: row => row.scenario?.parameters?.listPrice,
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'bidPrice',
    header: 'Bid Price',
    accessorFn: row => row.scenario?.parameters?.purchasePrice,
    cell: InputCell,
    meta: { ...dataTableMeta.textRight, name: 'purchasePrice' },
  },
  {
    id: 'totalCost',
    header: 'Total Cost',
    accessorFn: row => row.scenario?.returnMetrics?.unleveredBasis?.[0],
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'arv',
    header: 'ARV',
    accessorFn: row => row.property?.data?.avm?.estimatedValueAmount,
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'builtInEquity',
    header: 'Built-In Equity',
    accessorFn: row => {
      const arv = row.property?.data?.avm?.estimatedValueAmount;
      const totalAcquistionCost = row.scenario?.returnMetrics?.unleveredBasis?.[0];
      return (arv && totalAcquistionCost) ? (arv / totalAcquistionCost) - 1 : null;
    },
    cell: ({ getValue }) => formatPercentage(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'yield',
    header: 'Yield',
    accessorFn: row => getReturnMetric(row.scenario, 'stabilizedYield'),
    cell: ({ getValue }) => formatPercentage(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'irr',
    header: 'IRR',
    accessorFn: row => getReturnMetric(row.scenario, 'leveredIrr'),
    cell: ({ getValue }) => formatPercentage(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'equityMultiple',
    header: 'Eq Mult',
    accessorFn: row => getReturnMetric(row.scenario, 'leveredEquityMultiple'),
    cell: ({ getValue }) => formatEquityMultiple(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'inPlaceRent',
    header: 'In-Place Rent',
    accessorFn: row => getRent(row.scenario, 'inPlaceRent'),
    cell: InputCell,
    meta: { name: 'units[0].inPlaceRent', ...dataTableMeta.textRight },
  },
  {
    id: 'marketRent',
    header: 'Market Rent',
    accessorFn: row => getRent(row.scenario, 'marketRent'),
    cell: InputCell,
    meta: { name: 'units[0].marketRent', ...dataTableMeta.textRight },
  },
  {
    id: 'capital',
    header: 'Renovation',
    accessorFn: row => getReturnMetric(row.scenario, 'totalCapital'),
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'stabilizationMonth',
    header: 'Stab. Month',
    accessorFn: row => row.scenario?.returnMetrics?.stabilizationMonth,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'taxes',
    header: 'Taxes',
    accessorFn: row => {
      const taxes = row.scenario?.returnMetrics?.taxes;
      return taxes ? sum(taxes.slice(0, 12)) : null;
    },
    cell: ({ getValue }) => (getValue() ? formatCurrency(getValue()) : '-'),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'hoa',
    header: 'HOA',
    accessorFn: row => {
      const hoaExpenseItem = row.scenario?.parameters?.expenseItems?.find(ei => ei.name === HOA_FEE_EXPENSE_ITEM_NAME);
      if (hoaExpenseItem) {
        const { inputMethod, inputValue } = hoaExpenseItem;
        return inputMethod === ITEMIZED_INPUT_METHOD_DPM ? inputValue * 12 : inputValue;
      } else {
        return null;
      }
    },
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'rollToMarket',
    header: 'Lease End Month',
    accessorFn: row => row.scenario?.parameters?.units?.[0]?.rollToMarket,
    meta: { ...dataTableMeta.textRight },
  },
];

const getColumns = ({ newBuildDeal, selectedProperties, togglePropertyActive }) => {
  const columns = [
    ...COMMON_COLUMNS,
    ...(newBuildDeal ? NEW_BUILD_COLUMNS : []),
    ...INDIVIDUAL_COLUMNS,
  ];

  columns.push({
    id: 'buybox',
    header: 'Buy Box',
    accessorKey: 'matchesBuyBox',
    cell: ({ row }) => (
      <div
        className="flex justify-center px-1 py-1"
      >
        {row.original.matchesBuyBox ? <Check className="w-6 text-success-500" /> : <X className="w-6 text-error-500" />}
      </div>
    ),
    meta: { textAlign: 'center' },
  });

  columns.push({
    id: 'active',
    header: '',
    enableHiding: false,
    cell: ({ row }) => (
      <div
        className="flex justify-center px-1 py-1"
        onClick={() => togglePropertyActive(row.original.property.id)}
      >
        <Toggle checked={selectedProperties[row.original.property.id]} />
      </div>
    ),
    meta: { textAlign: 'center', ...dataTableMeta.disableTableConfig },
  });

  if (newBuildDeal) {
    const addressCellIdx = columns.indexOf(ADDRESS_COLUMN);
    columns[addressCellIdx] = { ...ADDRESS_COLUMN, header: 'Home Model' };
  }
  return columns;
};

function StatCell({ label, value }) {
  return (
    <div className="text-center">
      <div>{value}</div>
      <div className="text-xs text-gray-500">{label}</div>
    </div>
  );
}

function SummarySection({ newBuildDeal, scenarios, tableData }) {
  const {
    stabilizedYield,
    leveredIrr,
    leveredEquityMultiple,
  } = useMemo(() => (scenarios.length ? calcReturnMetricsOfIndividualScenarios(scenarios) : {}), [scenarios]);

  const stats = [
    {
      label: newBuildDeal ? 'Home Models' : 'Properties',
      value: (scenarios.length < tableData.length) ? (
        <div className="flex justify-center items-center">
          <div>{scenarios.length}</div>
          <div className="ml-2 text-gray-400 text-sm">{`/  ${tableData.length}`}</div>
        </div>
      ) : scenarios.length,
    },
    {
      label: 'Ask Price',
      value: formatCurrency(dotProductBy(scenarios, 'parameters.listPrice', ({ parameters: { multiplicity } }) => multiplicity ?? 1)),
    },
    {
      label: 'Bid Price',
      value: formatCurrency(dotProductBy(scenarios, 'parameters.purchasePrice', ({ parameters: { multiplicity } }) => multiplicity ?? 1)),
    },
    {
      label: 'Yield',
      value: formatPercentage(stabilizedYield),
    },
    {
      label: 'IRR',
      value: formatPercentage(leveredIrr),
    },
    {
      label: 'Equity Multiple',
      value: formatEquityMultiple(leveredEquityMultiple),
    },
    {
      label: 'Buy Box Match',
      value: (
        <div className="flex justify-center items-center">
          <div>{tableData.filter(d => d.matchesBuyBox).length}</div>
          <div className="ml-2 text-gray-400 text-sm">{`/  ${tableData.length}`}</div>
        </div>
      ),
    },
  ];

  return (
    <div className="h-16 flex justify-evenly items-center gap-x-4 px-3 border-b bg-white">
      {stats.map(stat => (
        <StatCell key={stat.label} {...stat} />
      ))}
    </div>
  );
}

export default function PortfolioDealSummary({ context }) {
  const dispatch = useDispatch();
  const { data, modelData } = context;
  const { portfolio } = data;
  const { deal, parcels, properties, homeModels } = data;
  const { model: { scenarios: persistedScenarios } } = modelData;
  const newBuildDeal = homeModels && !isEmpty(homeModels);
  const [initialScenarios, setInitialScenarios] = useState(persistedScenarios);
  const [scenarios, setScenarios] = useState(initialScenarios);
  const onReset = () => setScenarios(initialScenarios);
  const onScenarioChange = (scenarioId, path, value) => {
    setScenarios(previousScenarios => previousScenarios.map(scenario => {
      if (scenario.id === scenarioId) {
        const updatedParameters = cloneDeep(scenario.parameters);
        set(updatedParameters, path, value);
        const returnMetrics = calcReturnMetrics(calcCashFlows(updatedParameters), updatedParameters);
        return { ...scenario, returnMetrics, parameters: updatedParameters };
      } else {
        return scenario;
      }
    }));
  };
  if (newBuildDeal) {
    const findMin = ({ parameters: { multiplicity, homeModelAddresses, homeModelDeliverySchedule } }) => (
      Math.max(
        multiplicity ?? 0,
        homeModelAddresses?.length ?? 0,
        sum(homeModelDeliverySchedule?.map(([, value]) => value)),
      )
    );

    if (scenarios.some(({ parameters: { multiplicity, homeModelAddresses, homeModelDeliverySchedule } }) => {
      const minMultiplicity = findMin({ parameters: { homeModelAddresses, homeModelDeliverySchedule } });
      return (multiplicity ?? 0) < minMultiplicity;
    })) {
      setScenarios((prev) => (
        prev.map((scenario) => ({
          ...scenario,
          parameters: {
            ...scenario.parameters,
            multiplicity: findMin(scenario),
          },
        }))
      ));
    }
  }
  const [selectedProperties, setSelectedProperties] = useState(properties.reduce((result, property) => ({ [property.id]: true, ...result }), {}));
  const togglePropertyActive = useCallback(propertyId => {
    setSelectedProperties(prevSelected => ({ ...prevSelected, [propertyId]: !prevSelected[propertyId] }));
  }, [setSelectedProperties]);
  const [updateScenariosMutation] = useUpdateScenariosMutation();

  const columns = useMemo(
    () => getColumns({
      newBuildDeal,
      selectedProperties,
      togglePropertyActive,
    }),
    [newBuildDeal, selectedProperties, togglePropertyActive],
  );

  const tableData = useMemo(() => properties.map(property => {
    const parcel = getParcel(property, parcels);
    const scenario = getScenario(property, scenarios);
    const initialScenario = getScenario(property, initialScenarios);
    const normalizedProperty = coalesceProperty(property, parcel, null, getUnits(property, scenario));
    return {
      parcel,
      property: normalizedProperty,
      initialScenario,
      matchesBuyBox: matchesBuyBox(normalizedProperty, scenario, portfolio),
      scenario,
    };
  }), [initialScenarios, parcels, portfolio, properties, scenarios]);

  const selectedScenarios = useMemo(
    () => scenarios.filter(s => s.primary && selectedProperties[s.propertyId]),
    [scenarios, selectedProperties],
  );

  const containerRef = useRef();
  const containerHeight = useElementHeight(containerRef);

  const pendingChanges = useMemo(
    () => !isEqual(initialScenarios.map(s => s.parameters), scenarios.map(s => s.parameters)),
    [scenarios, initialScenarios],
  );

  const onSave = useCallback(async (onSuccess) => {
    const updatedScenarios = scenarios.filter(s => s.parameters !== initialScenarios.find(is => is.id === s.id).parameters);
    const response = await updateScenariosMutation({ dealId: deal.id, scenarios: updatedScenarios });
    if (response.error) {
      console.error(response.error);
    } else {
      const newScenarios = persistedScenarios.map(scenario => {
        const updatedScenario = response.data.scenarios.find(s => s.id === scenario.id);
        if (updatedScenario) {
          return updatedScenario;
        } else {
          return { ...scenario };
        }
      });
      setScenarios(newScenarios);
      setInitialScenarios(newScenarios);
      onSuccess();
    }
  }, [scenarios, initialScenarios]);

  useEffect(() => {
    if (pendingChanges) {
      dispatch(showToast(TOAST_UNSAVED_CHANGES({ reset: onReset, save: partial(onSave, () => dispatch(clearToast())) })));
    } else {
      dispatch(clearToast());
    }
  }, [dispatch, pendingChanges, onSave]);

  const {
    state: blockerState,
    proceed,
    reset,
  } = useBlocker(pendingChanges);

  useEffect(() => {
    if (blockerState === 'blocked') {
      const onCancel = () => reset();
      const onDoNotSave = () => {
        onReset();
        dispatch(clearToast());
        proceed();
      };
      dispatch(showSaveChangesModal(true, onCancel, onDoNotSave, partial(onSave, () => proceed())));
    }
  }, [blockerState]);

  const tableId = getTableId({ newBuildDeal });
  return (
    <div
      ref={containerRef}
      style={{
        width: `calc(100vw - ${LAYOUT.rightNavWidth + LAYOUT.sidebarWidth}px)`,
        height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)`,
      }}
    >
      <DataTable
        columns={columns}
        data={tableData}
        tableHeight={containerHeight - (TOGGLE_COLUMNS_HEIGHT + SUMMARY_HEIGHT)}
        meta={{ onScenarioChange, ...enableConfigPresets({ presets: CONFIG_PRESETS[tableId] }) }}
      >
        <div className="w-full">
          <SummarySection newBuildDeal={newBuildDeal} scenarios={selectedScenarios} tableData={tableData} />
          <div className="flex justify-end h-10 items-center bg-white">
            <DataTableConfig tableId={tableId}>
              <TableConfigButton />
            </DataTableConfig>
          </div>
          <DataTableContent />
        </div>
      </DataTable>
    </div>
  );
}
