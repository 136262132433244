/* eslint-disable react/destructuring-assignment */
/* eslint-disable quotes */
import { memo, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortBy } from 'lodash';
import {
  useFetchPermissionsQuery,
  useFetchPortfolioSummaryQuery,
} from 'redux/apiSlice';
import { Pencil, Trash } from 'components/icons';
import { portfolioEditPath, portfolioPath } from 'components/routes';
import { formatCurrencyAbbreviated, formatMultiplier, formatPercentage } from 'components/utils';
import PortfolioHeader from 'components/portfolio/PortfolioHeader';
import DataTable from 'components/shared/Table/DataTable';

const formatMarketsString = markets => {
  const sortedMarkets = sortBy(markets);

  if (markets.length > 4) {
    return [
      sortedMarkets[0],
      sortedMarkets[1],
      sortedMarkets[2],
      `${sortedMarkets[3]}, ...`,
    ].join(",\n");
  } else {
    return sortedMarkets.join(",\n");
  }
};

const useColumns = (canDeletePortfolio, deletePortfolio, navigate) => useMemo(
  () => [{
    header: '',
    accessorKey: 'photoUrl',
    cell: ({ getValue }) => (
      <div className="h-24">
        <img className="h-full w-full object-cover" src={getValue()} loading="lazy" referrerPolicy="no-referrer" alt="Portfolio" />
      </div>
    ),
    meta: { className: 'w-[10%]' },
  }, {
    header: 'Name',
    accessorKey: 'name',
    cell: ({ getValue }) => <div className="text-lg">{getValue()}</div>,
    meta: { className: 'w-[18%]' },
  }, {
    header: 'Client',
    accessorKey: 'client',
    cell: ({ getValue }) => <div className="text-lg">{getValue()}</div>,
    meta: { className: 'w-[10%]' },
    sortingFn: 'text',
  }, {
    header: 'Markets',
    accessorKey: 'markets',
    cell: ({ getValue }) => <div className="text-sm whitespace-pre-line">{formatMarketsString(getValue())}</div>,
    meta: { className: 'w-[8%]' },
  }, {
    header: 'Active Deal Value',
    accessorKey: 'activeDealsTotalValue',
    cell: ({ getValue }) => <div>{formatCurrencyAbbreviated(getValue())}</div>,
    meta: { className: 'w-[9%]', textAlign: 'right' },
  }, {
    header: 'Active Deals',
    accessorKey: 'activeDeals',
    cell: ({ row, getValue }) => (
      <div>
        <div>{getValue()}</div>
        <div className="text-xs text-gray-500">{`${row.original.activeUnits} Units`}</div>
      </div>
    ),
    meta: { className: 'w-[7%]', textAlign: 'right' },
  }, {
    header: 'Cash Yield',
    accessorKey: 'metrics.unleveredCashOnCash',
    cell: ({ row, getValue }) => (
      <div>
        <div>{formatPercentage(getValue())}</div>
        <div className="text-sm text-gray-500">{formatPercentage(row.original.metrics.leveredCashOnCash)}</div>
      </div>
    ),
    meta: { className: 'w-[8%]', textAlign: 'right' },
  }, {
    header: 'Equity Multiple',
    accessorKey: 'metrics.unleveredEquityMultiple',
    cell: ({ row, getValue }) => (
      <div>
        <div>{formatMultiplier(getValue())}</div>
        <div className="text-sm text-gray-500">{formatMultiplier(row.original.metrics.leveredEquityMultiple)}</div>
      </div>
    ),
    meta: { className: 'w-[8%]', textAlign: 'right' },
  }, {
    header: 'IRR',
    accessorKey: 'metrics.unleveredIrr',
    cell: ({ row, getValue }) => (
      <div>
        <div>{formatPercentage(getValue())}</div>
        <div className="text-sm text-gray-500">{formatPercentage(row.original.metrics.leveredIrr)}</div>
      </div>
    ),
    meta: { className: 'w-[8%] text-center', textAlign: 'right' },
  }, {
    header: 'Closed Deals',
    accessorKey: 'closedDeals',
    cell: ({ row, getValue }) => (
      <div>
        <div>{getValue()}</div>
        <div className="text-xs text-gray-500">{`${row.original.closedUnits} Units`}</div>
      </div>
    ),
    meta: { className: 'w-[7%]', textAlign: 'right' },
  }, {
    header: '',
    id: 'menu',
    cell: memo(({ row }) => (
      <div className="relative">
        <div className="flex justify-center gap-x-2 items-center">
          <div className="flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 cursor-pointer" onClick={(event) => { event.stopPropagation(); navigate(portfolioEditPath(row.original)); }}>
            <Pencil className="w-6 h-6 mx-auto" />
          </div>
          {canDeletePortfolio && (
            <div className="flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 cursor-pointer" onClick={(event) => { event.stopPropagation(); deletePortfolio(row.original); }}>
              <Trash className="w-6 h-6 mx-auto" />
            </div>
          )}
        </div>
      </div>
    )),
    meta: { className: 'w-[7%]' },
  }],
  [canDeletePortfolio, navigate],
);

const deletePortfolio = (portfolio) => {
  const { id, name } = portfolio;
  // eslint-disable-next-line no-alert
  if (!window.confirm(`Are you sure you would like to delete portfolio: ${name}?\n\nDeleting a portfolio will delete all associated deals and cannot be undone.`)) {
    return;
  }
  const csrfToken = document.querySelector('[name=csrf-token]').content;
  fetch(portfolioPath({ id }), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken,
    },
    body: JSON.stringify({ id }),
  }).then(() => {
    window.alert('Portfolio is being deleted, it may take a few minutes before changes are reflected across the platform.');
  }).catch(error => {
    console.error(error);
  });
};

export default function PortfoliosList() {
  const [activePortfolios, setActivePortfolios] = useState([]);
  const {
    data: {
      canCreatePortfolio = false,
      canDeletePortfolio = false,
    } = {},
    isLoading: isPermissionsLoading,
  } = useFetchPermissionsQuery();
  const {
    data: portfolioSummaryData = {},
    isLoading: isSummaryLoading,
  } = useFetchPortfolioSummaryQuery();
  const navigate = useNavigate();

  const isLoading = isPermissionsLoading || isSummaryLoading;

  const {
    activeDeals,
    activeDealsTotalValue,
    closedDeals,
    portfolios,
  } = portfolioSummaryData;

  useEffect(() => {
    if (portfolios) {
      setActivePortfolios(portfolios);
    }
  }, [portfolios]);

  const stats = [{
    label: 'Active Deal Value',
    value: formatCurrencyAbbreviated(activeDealsTotalValue),
  }, {
    label: 'Active Deals',
    value: activeDeals,
  }, {
    label: 'Closed Deals',
    value: closedDeals,
  }];

  const onRowClick = (row) => {
    navigate(`/portfolios/${row.original.id}`);
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <PortfolioHeader
        canCreatePortfolio={canCreatePortfolio}
        stats={stats}
      />
      <div className="p-6 h-0 flex-1">
        <DataTable
          tableContainerClassName="max-h-full"
          data={activePortfolios}
          columns={useColumns(canDeletePortfolio, deletePortfolio, navigate)}
          isLoading={isLoading}
          onRowClick={onRowClick}
        />
      </div>
    </div>
  );
}
