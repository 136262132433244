import { Fragment, useCallback, useMemo } from 'react';
import { RadioGroup } from '@headlessui/react';
import Chip from 'components/shared/Chip';
import { useFilterInputState } from './useFilterInputState';

const undefinedSymbol = Symbol('');
export default function FilterChipsRadioGroup({ options }) {
  const [state, setState] = useFilterInputState();

  const optionsWithSafeUndefined = useMemo(() => (
    options.map((opt) => (opt.value !== undefined ? opt : { ...opt, value: undefinedSymbol }))
  ), [options]);

  const stateWithSafeUndefined = state !== undefined ? state : undefinedSymbol;
  const setStateWithSafeUndefined = useCallback((value) => {
    setState(value !== undefinedSymbol ? value : undefined);
  }, [setState]);

  return (
    <RadioGroup value={stateWithSafeUndefined} onChange={setStateWithSafeUndefined} className="flex flex-row flex-wrap gap-2">
      {optionsWithSafeUndefined.map(({ label, value }) => (
        <RadioGroup.Option key={value !== undefinedSymbol ? value : 'undefined'} value={value} as={Fragment} leadingIcon label={label}>
          {({ checked }) => <Chip selected={checked} />}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
}
