import { createSlice } from '@reduxjs/toolkit';

const NO_TOAST = {};
const initialState = { activeToast: NO_TOAST };

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    showToast: (state, { payload }) => {
      state.activeToast = payload;
    },
    clearToast: state => {
      state.activeToast = NO_TOAST;
    },
    /* eslint-enable no-param-reassign */
  },
});

export const { clearToast, showToast } = toastSlice.actions;
export default toastSlice.reducer;
