import { memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import DataTable from 'components/shared/Table/DataTable';
import { LoadingIndicator } from 'components/icons';
import { useFetchTaskEmailQuery } from 'redux/emailTemplateApiSplice';
import Modal from 'components/NewModal';
import iconLogo from 'assets/icon_logo.png';
import { generateEmailHtml } from 'components/Features/EmailTemplate/generateEmailHtml';
import { snakeCaseKeys } from 'components/utils';
import { format } from 'date-fns';

const TaskEmailStatusBadge = memo(({ status }) => {
  const badgeClass = classNames(
    'inline-block px-3 py-1 rounded-full text-sm font-semibold tracking-wide shadow-sm',
    {
      'bg-yellow-100 text-yellow-700': status === 'pending' || status === 'sending',
      'bg-green-100 text-green-700': status === 'sent',
      'bg-red-100 text-red-700': status === 'failed',
    },
  );

  const formattedStatus = status.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

  return <span className={badgeClass}>{formattedStatus}</span>;
});

function DetailRow({ label, value, isEmptyText = 'N/A', isTextRed = false }) {
  return (
    <div className="flex justify-between items-center">
      <span className="font-medium text-gray-900 text-xs">
        {label}
        :
      </span>
      <div className={`ml-2 text-xs w-full text-right ${isTextRed ? 'text-red-600' : 'text-gray-600'}`}>
        {value || <span className="text-gray-400">{isEmptyText}</span>}
      </div>
    </div>
  );
}

function TaskEmailDetails({ taskEmail }) {
  const formattedStatusUpdatedAt = taskEmail.statusUpdatedAt
    ? format(new Date(taskEmail.statusUpdatedAt), 'd MMM, h:mm a')
    : null;

  return (
    <div className="p-6 bg-white rounded-lg space-y-6">
      <div className="grid grid-cols-1 gap-6 text-gray-700">
        <DetailRow
          label="Status"
          value={<TaskEmailStatusBadge status={taskEmail.status} />}
        />
        <DetailRow label="Subject" value={taskEmail.subject} />
        <DetailRow label="To" value={taskEmail.to.join(', ')} />
        <DetailRow label="CC" value={taskEmail.cc.length > 0 ? taskEmail.cc.join(', ') : null} />
        <DetailRow label="BCC" value={taskEmail.bcc.length > 0 ? taskEmail.bcc.join(', ') : null} />
        <DetailRow label="Status Updated At" value={formattedStatusUpdatedAt} />
        {taskEmail.failureReason && (
          <DetailRow
            label="Failure Reason"
            value={taskEmail.failureReason}
            isTextRed
          />
        )}
      </div>
    </div>
  );
}

function TaskEmailModal({ currentUser, taskEmail, onClose }) {
  const { organization: { logoUrl } } = currentUser;
  const iframeRef = useRef(null);
  const [iframeHeight, setIframeHeight] = useState(400);
  const logoImageUrl = logoUrl || iconLogo;

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow && taskEmail.emailTemplate.content) {
      const htmlContent = generateEmailHtml(taskEmail.emailTemplate, snakeCaseKeys(taskEmail.placeholders), logoImageUrl);

      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(htmlContent);
      doc.close();

      const updateHeight = () => {
        const newHeight = doc.body.scrollHeight;
        setIframeHeight(newHeight);
      };

      // Wait for the content to render
      iframe.onload = updateHeight;
    }
  }, [taskEmail, logoImageUrl]);

  return (
    <Modal title="Task Email Details" size="extraLarge" onClose={onClose} show>
      <div className="grid grid-cols-12">
        <div className="col-span-4 border-r border-gray-200">
          <TaskEmailDetails taskEmail={taskEmail} />
        </div>

        <div className="col-span-8">
          <iframe
            title="Email Preview"
            ref={iframeRef}
            style={{
              width: '100%',
              height: `${iframeHeight}px`,
              border: 'none',
              overflow: 'hidden',
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

const TaskNameCell = memo(({ name }) => (
  <div>{name}</div>
));

const EmailTemplateTitleCell = memo(({ title }) => (
  <div>{title}</div>
));

const columns = () => [
  {
    id: 'task',
    header: 'Task',
    accessorKey: 'task.name',
    cell: ({ getValue }) => <TaskNameCell name={getValue()} />,
  },
  {
    id: 'email_template',
    header: 'Email Template',
    accessorKey: 'emailTemplate.title',
    cell: ({ getValue }) => <EmailTemplateTitleCell title={getValue()} />,
  },
  {
    id: 'status',
    header: 'Status',
    accessorKey: 'status',
    cell: ({ getValue }) => <TaskEmailStatusBadge status={getValue()} />,
  },
  {
    id: 'subject',
    header: 'Subject',
    accessorKey: 'subject',
  },
  {
    id: 'to',
    header: 'To',
    accessorKey: 'to',
    cell: ({ getValue }) => <div>{getValue().join(', ')}</div>,
  },
  {
    id: 'cc',
    header: 'CC',
    accessorKey: 'cc',
    cell: ({ getValue }) => <div>{getValue().join(', ') || 'N/A'}</div>,
  },
];

function TaskEmailTable({ task, onRowClick }) {
  const { data: taskEmails = [], isLoading, isError, error } = useFetchTaskEmailQuery({ task });

  if (isLoading) {
    return (
      <div className="absolute inset-0 bg-white bg-opacity-75 flex justify-center items-center z-10">
        <LoadingIndicator className="h-6 w-6 mr-2" />
        <span className="text-gray-700">Loading Task Emails...</span>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="text-red-500">
        Error loading task emails:
        {' '}
        {error?.data?.message || 'Unknown error'}
      </div>
    );
  }

  return (
    <DataTable
      columns={columns()}
      data={taskEmails}
      tableContainerClassName="flex-1 overflow-auto"
      enableMultiRowSelection={false}
      onRowClick={(row) => onRowClick(row.original)}
      tdClassName="p-2 cursor-pointer"
      thClassName="p-2 bg-gray-100"
    />
  );
}

function TaskEmails({ currentUser, task }) {
  const [selectedTaskEmail, setSelectedTaskEmail] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = (taskEmail) => {
    setSelectedTaskEmail(taskEmail);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTaskEmail(null);
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Task Emails</h2>
      <TaskEmailTable task={task} onRowClick={handleRowClick} />

      {isModalOpen && selectedTaskEmail && (
        <TaskEmailModal currentUser={currentUser} taskEmail={selectedTaskEmail} onClose={handleCloseModal} />
      )}
    </div>
  );
}

export default TaskEmails;
