import FilterChipsRadioGroup from 'components/shared/newfilters/FilterChipsRadioGroup';
import FilterControl from 'components/shared/newfilters/FilterControl';
import FilterToggle from 'components/shared/newfilters/FilterToggle';

const options = [
  { value: undefined, label: 'All' },
  { value: true, label: 'Needs Underwriting' },
];

export default function NeedDataFilter() {
  return (
    <>
      <FilterToggle />
      <FilterControl>
        <FilterChipsRadioGroup options={options} />
      </FilterControl>
    </>
  );
}
