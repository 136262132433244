import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { apiSlice } from 'redux/apiSlice';
import { HOME_BUILDERS_TAG, SUBDIVISIONS_TAG } from './utils';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      createHomeBuilder: builder.mutation({
        query: ({ name, website }) => ({
          url: '/api/home_builders',
          method: 'POST',
          body: snakeCaseKeys({ name, website }),
        }),
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchHomeBuilder: builder.query({
        query: (id) => ({ url: `/api/home_builders/${id}` }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: [HOME_BUILDERS_TAG, SUBDIVISIONS_TAG],
      }),
      fetchHomeBuilders: builder.query({
        query: homeBuilderIds => ({
          url: '/api/home_builders',
          params: snakeCaseKeys({ homeBuilderIds }),
        }),
        transformResponse: response => camelCaseKeys(response).data.map(obj => obj.attributes),
        providesTags: [HOME_BUILDERS_TAG, SUBDIVISIONS_TAG],
      }),
    };
  },
});

export const {
  useCreateHomeBuilderMutation,
  useFetchHomeBuilderQuery,
  useFetchHomeBuildersQuery,
} = extendedApiSlice;
