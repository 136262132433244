import { scenarioPath } from '../routes';
import { find, groupBy, map, mapValues, sortBy } from 'lodash';
import classNames from 'classnames';

function PropertyTab({ label, onClick }) {
  const clazz = classNames(
    'flex justify-center items-center',
    'w-36 py-5',
    'uppercase tracking-wider text-xs font-medium',
    'cursor-pointer',
    'text-gray-50 hover:bg-secondary-400',
  );
  return (
    <div className={clazz} onClick={onClick}>{label}</div>
  );
}

export default function PortfolioProperties({ properties, scenario, scenarios }) {
  const scenariosByPropertyId = groupBy(scenarios, s => s.propertyId);
  const primaryPropertyScenariosByPropertyId = mapValues(scenariosByPropertyId, propertyScenarios => find(propertyScenarios, s => s.scenarioId === scenario.id));
  const propertiesSortedByAddress = sortBy(properties, 'address');

  return (
    <div className="flex flex-col z-30 bg-secondary-500 divide-y-2 divide-gray-50 divide-opacity-40">
      {map(propertiesSortedByAddress, (property) => (
        <PropertyTab key={property.id} label={property.address} onClick={() => window.location = scenarioPath(primaryPropertyScenariosByPropertyId[property.id])} />
      ))}
    </div>
  );
}
