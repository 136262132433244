import { useDispatch, useSelector } from 'react-redux';
import { clearDealsModal } from 'actions/deals';
import { useDeleteTaskMutation } from 'redux/apiSlice';
import Button from 'components/Button';
import DashboardModal from '../DashboardModal';

export default function DeleteTaskModal() {
  const dispatch = useDispatch();
  const { task } = useSelector(state => state.deals.modal);
  const [deleteTaskMutation, { isLoading }] = useDeleteTaskMutation();
  const { name } = task;

  return (
    <DashboardModal
      title="Delete Task?"
      action={(
        <Button
          danger
          text="Delete"
          className="font-semibold border border-black border-opacity-12"
          shadow={false}
          isLoading={isLoading}
        />
      )}
      onSubmit={async (event) => {
        event.preventDefault();
        event.stopPropagation();
        // TODO: handle response
        await deleteTaskMutation(task);
        dispatch(clearDealsModal());
      }}
      onClose={() => dispatch(clearDealsModal())}
    >
      {'Are you sure you would like to delete the '}
      <b>{name}</b>
      {' task?'}
    </DashboardModal>
  );
}
