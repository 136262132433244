import { useState } from 'react';
import { omit, reject, some, uniq } from 'lodash';
import { ORG_DARWIN_WORKFLOW_TYPE, ORG_DEFAULT_WORKFLOW_TYPE } from 'components/constants';
import { ContentCopy } from 'components/icons';
import MarketSelect from './MarketSelect';
import Alert from '../Alert';
import Button from '../Button';
import { createOrganizationPath } from '../routes';
import { parseEventValue, snakeCaseKeys } from '../utils';
import WorkFlowType from './WorkflowType';
import PropertyType from './PropertyType';

export const WORKFLOW_TYPES = [
  { value: ORG_DARWIN_WORKFLOW_TYPE, label: 'Single-Family' },
  { value: ORG_DEFAULT_WORKFLOW_TYPE, label: 'Multifamily' },
];

const randomPassword = (length = 12) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export default function CreateOrganization({ markets: allMarkets }) {
  const [isSaving, setIsSaving] = useState(false);
  const [alert, setAlert] = useState(null);
  const [organization, setOrganization] = useState({ name: '', markets: [], propertyTypes: [], dealWorkflowTemplate: '' });
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [user, setUser] = useState({ email: '', password: randomPassword() });

  const handleCopyPassword = () => {
    navigator.clipboard.writeText(user.password).then(
      () => {
        window.alert('Password has been copied to clipboard');
      },
      (err) => {
        console.error('Async: Could not copy text: ', err);
      },
    );
  };

  const handleMarketSelection = (selection) => {
    let updatedMarkets;

    if (some(selectedMarkets, selection)) {
      updatedMarkets = [...selectedMarkets];
      updatedMarkets = reject(selectedMarkets, (m) => m.id === selection.id);
    } else {
      updatedMarkets = uniq([...selectedMarkets, selection]);
    }
    setSelectedMarkets(updatedMarkets);
  };

  const handlePropertyTypeSelection = (value) => {
    const index = organization.propertyTypes.indexOf(value);
    let updatedSelections;
    if (index !== -1) {
      updatedSelections = [...organization.propertyTypes];
      updatedSelections.splice(index, 1);
    } else {
      updatedSelections = uniq([...organization.propertyTypes, value]);
    }
    setOrganization({
      ...organization,
      propertyTypes: updatedSelections,
    });
  };

  const handleWorkflowTypeSelection = (value) => {
    setOrganization({
      ...organization,
      dealWorkflowTemplate: value,
    });
  };

  const createOrganization = () => {
    let propertyType;
    if (organization.propertyTypes.length === 2) {
      propertyType = 'Both';
    } else {
      propertyType = organization.propertyTypes.join('');
    }
    const markets = [];
    selectedMarkets.forEach(m => { markets.push(m.label); });

    setAlert(null);
    if (!organization.name) {
      setAlert({ type: 'warning', text: 'Organization name is required.' });
      return;
    }
    if (!markets.length) {
      setAlert({ type: 'warning', text: 'Market is required.' });
      return;
    }
    if (!organization.propertyTypes.length) {
      setAlert({ type: 'warning', text: 'Property type is required.' });
      return;
    }
    if (!organization.dealWorkflowTemplate) {
      setAlert({ type: 'warning', text: 'Workflow type is required.' });
      return;
    }
    if (user.email.length === 0) {
      setAlert({ type: 'warning', text: 'Email is required.' });
      return;
    }
    if (user.password.length === 0) {
      setAlert({ type: 'warning', text: 'Password is required.' });
      return;
    }

    setIsSaving(true);
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    fetch(createOrganizationPath(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
      body: JSON.stringify(snakeCaseKeys({
        organization: omit({ ...organization, markets, propertyType }, 'propertyTypes'),
        user,
      })),
    }).then(response => response.json()).then(json => {
      if (json.error) {
        throw new Error(json.error);
      } else {
        setIsSaving(false);
        setOrganization({ name: '', markets: [] });
        setUser({ email: '', password: randomPassword() });
        setAlert({ type: 'success', text: 'Organization Created!' });
      }
    }).catch(error => {
      console.error(error);
      setIsSaving(false);
      setAlert({ type: 'danger', text: `Creating Failed: ${error.message}` });
    });
  };

  const marketOptions = allMarkets.map((m, i) => ({ id: i, label: m }));
  return (
    <div className="w-96 p-8 bg-white rounded-xl shadow">
      <h1 className="mb-12 text-xl font-normal">Create Organization</h1>

      <div>
        <label htmlFor="name" className="text-xs text-primary-dark">Organization Name</label>
        <input type="text" name="name" className="w-full border border-primary-dark rounded-md px-2 py-3 text-sm outline-none" onChange={(e) => setOrganization({ ...organization, name: parseEventValue(e) })} />
      </div>

      <MarketSelect
        width="w-full"
        optionsWidth="w-full"
        options={marketOptions}
        selectedMarkets={selectedMarkets}
        onChange={handleMarketSelection}
      />

      <PropertyType
        organization={organization}
        onChange={handlePropertyTypeSelection}
      />

      <WorkFlowType
        label="Workflow Type"
        field="dealWorkflowTemplate"
        organization={organization}
        options={WORKFLOW_TYPES}
        onChange={handleWorkflowTypeSelection}
      />

      <div className="mt-6">
        <label htmlFor="email" className="text-xs text-primary-dark">First User Email</label>
        <input type="email" name="email" className="mt-2 w-full border border-primary-dark rounded-md px-2 py-3 text-sm outline-none" onChange={e => setUser({ ...user, email: parseEventValue(e) })} />
      </div>

      <div className="mt-6">
        <label htmlFor="password" className="text-xs text-primary-dark">First User Password</label>
        <div className="mt-4 flex justify-between text-light text-sm" name="password">
          {user.password}
          <div className="flex justify-between items-center cursor-pointer text-primary-dark font-medium" onClick={() => handleCopyPassword()}>
            <ContentCopy className="w-4 mr-2 text-primary-dark" />
            <span className="text-primary-dark">Copy</span>
          </div>
        </div>
      </div>

      {alert && <Alert className="mt-3" {...alert} />}
      <div className="mt-12 flex flex-row justify-end gap-x-4">
        <Button text="Create Organization" onClick={createOrganization} isLoading={isSaving} className="bg-primary-dark rounded-full text-sm" />
      </div>
    </div>
  );
}
