import { isEmpty } from 'lodash';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateDealMutation, useUpdateDealMutation } from 'redux/apiSlice';
import { showToast } from 'redux/toastSlice';
import Alert from 'components/Alert';
import { DEAL_STATE_DEAD } from 'components/constants';
import { DEAD_REASONS } from 'components/dashboard/MarkDealAsDeadModal';
import { FormField } from 'components/Form';
import { Undo, X } from 'components/icons';
import Modal from 'components/Modal';
import Button from 'components/shared/Button';
import Tooltip from 'components/shared/Tooltip';
import {
  TOAST_DEAL_KILLED_WITH_REMOVE_FROM_PIPELINE,
  TOAST_DEAL_KILLED_WITH_REVIVE_FROM_DEAD,
  TOAST_DEAL_REOPENED,
} from 'components/toast';
import { camelCaseKeys } from 'components/utils';
import { useUpdateDealStatusCache } from 'components/DealSourcing/dealSourcing';

const QUICK_PASS_REASON = ['quick_pass', 'Select a reason...', false];
const PLACEHOLDER_REASON = ['', 'Select a reason...', true];

function MarkDealAsDeadModal({
  createDealMutation,
  currentUser,
  dealName,
  dealId,
  homeModelId,
  subdivisionId,
  listingId,
  dealStatus,
  isCreatingDeal,
  isLoading,
  updateDealStatus,
  setShowDeadDealConfirmModal,
  updateDealMutation,
}) {
  const reasonOptions = [
    dealStatus ? PLACEHOLDER_REASON : QUICK_PASS_REASON,
    ...DEAD_REASONS,
  ];
  const [[firstReasonKey]] = reasonOptions;

  const [alert, setAlert] = useState(null);
  const [deadReason, setDeadReason] = useState(firstReasonKey);
  const dispatch = useDispatch();

  const handleUpdateDeal = async () => {
    setAlert(null);
    if (isEmpty(deadReason)) {
      setAlert({ type: 'danger', text: 'Please select reason.' });
      return;
    }
    if (!isLoading && !isCreatingDeal) {
      try {
        // if listing has not been added to pipeline create deal and mark it as dead
        if (!dealId) {
          const createDealResponse = await createDealMutation({
            listingId,
            subdivisionId,
            leadId: currentUser.id,
            name: dealName,
            deletedAt: new Date().toJSON(),
            deadReason,
          }).unwrap();
          setShowDeadDealConfirmModal(false);
          const { id: createdDealId, stage: pipelineStatus } = createDealResponse;
          const additionalToastInfo = { listingId, dealId: createdDealId, deletedAt: null, deadReason: null };
          dispatch(showToast({ ...TOAST_DEAL_KILLED_WITH_REMOVE_FROM_PIPELINE, additionalToastInfo }));
          if (listingId) {
            updateDealStatus(listingId, pipelineStatus, createdDealId);
          }
        } else {
          const updatedDeal = await updateDealMutation({ id: dealId, deletedAt: new Date().toJSON(), deadReason }).unwrap();
          setShowDeadDealConfirmModal(false);
          const { data: { attributes: { pipelineStatus } } } = camelCaseKeys(updatedDeal);
          const additionalToastInfo = { dealId, deletedAt: null, deadReason: null };
          dispatch(showToast({ ...TOAST_DEAL_KILLED_WITH_REVIVE_FROM_DEAD, additionalToastInfo }));
          updateDealStatus(listingId, pipelineStatus, dealId);
        }
      } catch (err) {
        console.error('Failed to mark deal dead: ', err);
        setAlert({ type: 'danger', text: 'Failed to mark deal dead' });
      }
    }
  };
  return (
    <Modal show showCloseAction={false}>
      <div className="w-90">
        <div className="flex flex-col space-y-1">
          <div className="text-2xl text-neutral-dark font-normal">
            Mark as dead
          </div>
          <div className="text-sm font-normal text-neutral-medium">
            Dead deals will be hidden from view in Deal Sourcing. You can view them again by changing the filter, or
            through the Pipeline page.
          </div>
        </div>
        <FormField
          label="Reason"
          value={deadReason}
          options={reasonOptions}
          type="select"
          padding="py-2 px-3 border border-black border-opacity-12"
          className="mt-6"
          onChange={(e) => setDeadReason(e.target.value)}
        />
        {alert && <Alert className="mt-6" {...alert} />}
        <div className="mt-6 flex gap-x-2 justify-end">
          <Button
            textOnly
            label="Cancel"
            className="font-medium text-sm"
            onClick={() => setShowDeadDealConfirmModal(false)}
          />
          <Button
            label={isLoading || isCreatingDeal ? 'Marking as Dead...' : 'Mark as Dead'}
            className="font-medium text-sm"
            onClick={handleUpdateDeal}
            isLoading={isLoading || isCreatingDeal}
            disabled={isLoading || isCreatingDeal}
          />
        </div>
      </div>
    </Modal>
  );
}

function ReviveDealModal({ dealId, listingId, isLoading, updateDealStatus, setShowReviveDealConfirmModal, updateDealMutation }) {
  const [alert, setAlert] = useState(null);
  const dispatch = useDispatch();

  const handleUpdateDeal = async () => {
    setAlert(null);
    if (!isLoading) {
      try {
        const updatedDeal = await updateDealMutation({ id: dealId, deletedAt: null, deadReason: null }).unwrap();
        setShowReviveDealConfirmModal(false);
        const { data: { attributes: { pipelineStatus } } } = camelCaseKeys(updatedDeal);
        if (listingId) {
          updateDealStatus(listingId, pipelineStatus, dealId);
        }
        dispatch(showToast({ ...TOAST_DEAL_REOPENED }));
      } catch (err) {
        console.error('Failed to mark deal Revive: ', err);
        setAlert('Failed to mark deal Revive');
      }
    }
  };

  return (
    <Modal show showCloseAction={false}>
      <div className="w-90">
        <div className="flex flex-col space-y-1">
          <div className="text-2xl text-neutral-dark font-normal">
            Reopen Deal
          </div>
          <div className="text-sm font-normal text-neutral-medium">
            Are you sure you would like to reopen this deal?
          </div>
        </div>
        {alert && <Alert className="mt-6" {...alert} />}
        <div className="mt-6 flex gap-x-2 justify-end">
          <Button
            textOnly
            label="Cancel"
            className="font-medium text-sm"
            onClick={() => setShowReviveDealConfirmModal(false)}
          />
          <Button
            label={isLoading ? 'Reopening deal...' : 'Reopen Deal'}
            className="font-medium text-sm"
            onClick={handleUpdateDeal}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
}

export default function KillReviveDealOverlay({ item, currentUser, portfolio }) {
  const { id, dealId, dealStatus, plan, subdivision } = item;

  const [updateDealMutation, { isLoading: updateDealIsLoading }] = useUpdateDealMutation();
  const [createDealMutation, { isLoading: isCreatingDeal }] = useCreateDealMutation();
  const updateDealStatus = useUpdateDealStatusCache(portfolio);

  const [showModal, setShowModal] = useState(false);
  const [toolTip, setToolTip] = useState(null);
  const ref = useRef();

  const onClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const itemIsRevivable = dealStatus && dealStatus === DEAL_STATE_DEAD;
  const KillReviveModal = itemIsRevivable ? ReviveDealModal : MarkDealAsDeadModal;
  const ButtonIcon = itemIsRevivable ? Undo : X;
  const tooltipText = itemIsRevivable ? 'Revive Deal' : 'Kill Deal';

  return (
    <>
      <div className="absolute top-2 right-4" ref={ref}>
        <div
          className="rounded-full hover:bg-gray-200 p-3 cursor-pointer"
          onClick={onClick}
          onMouseEnter={() => setToolTip({ target: ref.current, text: tooltipText })}
          onMouseLeave={() => setToolTip(null)}
        >
          <ButtonIcon className="w-5 h-5" />
        </div>
        <Tooltip toolTip={toolTip} />
      </div>
      {showModal && (
        <KillReviveModal
          createDealMutation={createDealMutation}
          currentUser={currentUser}
          homeModelId={plan && id}
          subdivisionId={subdivision?.id}
          dealId={dealId}
          listingId={plan ? null : id}
          dealName={item.address || item.plan}
          dealStatus={dealStatus}
          isCreatingDeal={isCreatingDeal}
          isLoading={updateDealIsLoading}
          updateDealStatus={updateDealStatus}
          setShowDeadDealConfirmModal={setShowModal}
          setShowReviveDealConfirmModal={setShowModal}
          updateDealMutation={updateDealMutation}
        />
      )}
    </>
  );
}
