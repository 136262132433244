import { useDispatch } from 'react-redux';
import { createTaskModal } from '../../../actions/deals';
import DateElement from '../../common/DateElement';
import RoundButton from '../../common/RoundButton';
import { CheckCircle, Event, ExclamationCircle, PlusCircle, X } from '../../icons';
import {
  MILESTONE_STATUS_COMPLETED,
  MILESTONE_STATUS_DEAD,
  MILESTONE_STATUS_IN_PROGRESS,
  MILESTONE_STATUS_OVERDUE,
  MILESTONE_STATUS_PENDING,
  TASK_DATE_FORMAT,
} from '../milestone';

export default function MilestoneHeading({ milestone }) {
  const dispatch = useDispatch();
  const { name, status, completedAt, dueDate } = milestone;

  let icon,
    dateValue,
    dateClassName;
  switch (status) {
    case MILESTONE_STATUS_COMPLETED: {
      dateValue = (
        <span>
          {'Completed '}
          <DateElement showRelative date={completedAt} dateFormat={TASK_DATE_FORMAT} />
        </span>
      );
      dateClassName = 'text-green-500';
      icon = <CheckCircle filled className="h-4 w-4 mr-0.5" />;
      break;
    }
    case MILESTONE_STATUS_DEAD: {
      dateValue = (
        <span>
          {'Dead '}
          {dueDate && <DateElement showRelative date={dueDate} dateFormat={TASK_DATE_FORMAT} />}
        </span>
      );
      dateClassName = 'text-gray-900 text-opacity-54';
      icon = <X className="h-4 w-4 mr-0.5" />;
      break;
    }
    case MILESTONE_STATUS_OVERDUE: {
      dateValue = (
        <span>
          {'Due '}
          <DateElement showRelative date={dueDate} dateFormat={TASK_DATE_FORMAT} />
        </span>
      );
      dateClassName = 'text-red-700';
      icon = <ExclamationCircle filled className="h-4 w-4 mr-0.5" />;
      break;
    }
    case MILESTONE_STATUS_IN_PROGRESS: {
      dateValue = (
        <span>
          {'Due '}
          <DateElement showRelative date={dueDate} dateFormat={TASK_DATE_FORMAT} />
        </span>
      );
      dateClassName = 'text-gray-900 text-opacity-54';
      icon = <Event className="h-auto w-4 mr-0.5" />;
      break;
    }
    case MILESTONE_STATUS_PENDING: {
      dateValue = 'No Due Date';
      dateClassName = 'text-gray-900 text-opacity-54';
      icon = <Event className="h-auto w-4 mr-0.5" />;
      break;
    }
    default: {
      console.error(`Unknown milestone status ${status}`);
      return null;
    }
  }

  return (
    <>
      <div className="flex items-center gap-x-4">
        <span className="flex-grow text-2xl leading-10 font-semibold">{name}</span>
        <RoundButton padding="p-2.5" onClick={() => dispatch(createTaskModal(milestone))}>
          <PlusCircle className="h-6 w-6 text-gray-700" />
        </RoundButton>
      </div>
      <div className={`h-4 flex items-center text-xs font-medium tracking-wider ${dateClassName}`}>
        {icon}
        {dateValue}
      </div>
    </>
  );
}
