import { useMemo } from 'react';
import { compact, mean, meanBy, round, sumBy } from 'lodash';
import { addWeeks } from 'date-fns';
import { formatCurrencyAbbreviated, formatPercentage } from 'components/utils';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import DisposTable from './DisposTable';
import { closingWithinFn, daysInContractFn, daysOnMarketFn, inContractFn, metricFunc } from './disposition.utils';

const metricsData = [
  { label: '# In Contract', statFunc: (dispos => dispos.length) },
  { label: 'Avg Contract Price', statFunc: (dispos => meanBy(dispos, 'attributes.contractPrice')), formatter: formatCurrencyAbbreviated },
  { label: 'In-Contract Avg Profit', statFunc: (dispos => meanBy(dispos, 'attributes.estimatedProfit')), formatter: formatCurrencyAbbreviated },
  { label: 'Avg Days in Contract', statFunc: ((dispos, asOfDate) => mean(compact(dispos.map(dispo => daysInContractFn(dispo, asOfDate))))), formatter: val => round(val, 1) },
  { label: '# Closing Within 7 Days', statFunc: ((dispos, asOfDate) => closingWithinFn(dispos, asOfDate, addWeeks(asOfDate, 1)).length) },
  { label: 'Avg DOM', statFunc: ((dispos, asOfDate) => mean(compact(dispos.map(dispo => daysOnMarketFn(dispo, asOfDate))))), formatter: val => round(val, 1) },
  { label: 'Avg # of Offers Per Property', statFunc: ((dispos) => mean(compact(dispos.map(dispo => dispo.attributes.numberOfOffers)))), formatter: val => round(val, 1) },
  { label: 'Total Profit', statFunc: ((dispos) => sumBy(dispos, 'attributes.estimatedProfit')), formatter: formatCurrencyAbbreviated },
  { label: 'Avg Offers as % of List Price', statFunc: ((dispos) => meanBy(dispos, 'attributes.offer_to_list_ratio')), formatter: formatPercentage },
];

export default function InContract({ asOfDate, lastWeekDate, lastMonthDate, filteredDispositions, oneWeekAgoFilteredDispositions, oneMonthAgoFilteredDispositions }) {
  const metrics = useMemo(
    () => metricsData.map(({ label, formatter, statFunc }) => metricFunc(
      label,
      statFunc,
      asOfDate,
      lastWeekDate,
      lastMonthDate,
      inContractFn(filteredDispositions, asOfDate),
      inContractFn(oneWeekAgoFilteredDispositions, lastWeekDate),
      inContractFn(oneMonthAgoFilteredDispositions, lastMonthDate),
      formatter
    )),
    [filteredDispositions],
  );

  return (
    <div className="flex flex-col gap-y-10">
      <ReturnMetricBoxes metrics={metrics} />
      <DisposTable filteredData={inContractFn(filteredDispositions, asOfDate)} tableType="In Contract" />
    </div>
  );
}
