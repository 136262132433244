import { useCallback } from 'react';
import { AssignToSlot } from 'components/shared/LayoutSlots';
import { useNodePath } from 'components/shared/TreeProvider/TreeProvider';
import { useClearUncommittedFilters, useFilterLabel } from './filterHooks';

/** @type {WeakMap<string[], number>} */
const filterMountCounter = new WeakMap();

/**
 * Restore filterState to committed filterState when the controls are not visible.
 * Reference counts are keyed by nodePath {@link useNodePath}
 * to account for filters with multiple {@link FilterControl}.
 *
 * @return {import('react').RefCallback<HTMLDivElement>}
 */
const useClearUncommittedFiltersOnHidden = () => {
  const clear = useClearUncommittedFilters();
  const nodePath = useNodePath();

  return useCallback((elem) => {
    let count = filterMountCounter.get(nodePath) ?? 0;
    count += elem === null ? -1 : 1;
    filterMountCounter.set(nodePath, count);

    if (count === 0) {
      clear();
    }
  }, [clear, nodePath]);
};

/**
 * @param {string} slotName
 * @param {import('react').ReactNode} children
 */
export default function FilterControl({ slotName, children }) {
  const label = useFilterLabel();
  const refCallback = useClearUncommittedFiltersOnHidden();

  // TODO: label should be linked to input
  return (
    <AssignToSlot slotName={slotName} portalKey={label}>
      <div ref={refCallback}>
        <label>{label}</label>
        {children}
      </div>
    </AssignToSlot>
  );
}
