import { memo } from 'react';
import { identity } from 'lodash';
import cx from 'classnames';
import {
  ACTIVE_STATUS,
  DEAL_STAGE_ABBREVIATIONS,
  DEAL_STATE_CLOSED,
  DEAL_STATE_DEAD,
  TRANSACTION_TYPES,
} from 'components/constants';
import { showEditTransactionInfoModal, submitTaskResponseModal } from 'actions/deals';
import { editDealModal } from 'actions/deal_navigation';
import { Check, Pencil, Trash } from 'components/icons';
import { hideImgOnError, formatCurrency, formatDate, formatPercentage, titleCase, determinePipelineStatus } from 'components/utils';
import { isTaskBlocked, transformTransactionInfo } from 'components/dashboard/milestone';
import { dataTableMeta } from 'components/shared/Table/table.helpers';
import { PERCENT_OR_CURRENCY_FIELDS } from 'components/deal/EditTransactionInfo';

const DEFAULT_DATE_FORMAT = 'MMM dd';
const TOGGLE_SECTION_DEAL = 'Deal';
const TOGGLE_SECTION_UNDERWRITING = 'Underwriting';
const TOGGLE_SECTION_TRANSACTION_GENERAL = 'Transaction Info - General';
const TOGGLE_SECTION_TRANSACTION_OFFER = 'Transaction Info - Offer';
const TOGGLE_SECTION_TRANSACTION_DATES = 'Transaction Info - Dates';
const TOGGLE_SECTION_TRANSACTION_PRICES = 'Transaction Info - Prices';
const TOGGLE_SECTION_TRANSACTION_FINANCIAL = 'Transaction Info - Financial';
const TOGGLE_SECTION_TRANSACTION_MLS = 'Transaction Info - MLS';
const TOGGLE_SECTION_TASK = 'Tasks';
const TOGGLE_SECTION_SYSTEM = 'System';
export const TOGGLE_SECTIONS = [
  TOGGLE_SECTION_DEAL,
  TOGGLE_SECTION_UNDERWRITING,
  TOGGLE_SECTION_TRANSACTION_GENERAL,
  TOGGLE_SECTION_TRANSACTION_OFFER,
  TOGGLE_SECTION_TRANSACTION_DATES,
  TOGGLE_SECTION_TRANSACTION_PRICES,
  TOGGLE_SECTION_TRANSACTION_FINANCIAL,
  TOGGLE_SECTION_TRANSACTION_MLS,
  TOGGLE_SECTION_TASK,
  TOGGLE_SECTION_SYSTEM,
];

// TODO: sort by milestone order
// currently this sorts milestones alphabetically
// and puts closed and dead at the end
// to sort by milestone order, it will need to consider the dealWorkflowTemplate
// for every deal in filteredDeals
const dealStageComparator = (leftDeal, rightDeal) => {
  const leftStage = leftDeal.stage;
  const rightStage = rightDeal.stage;

  if (leftStage === rightStage) {
    return 0;
  }

  if (leftStage === DEAL_STATE_DEAD) {
    return -1;
  }

  if (rightStage === DEAL_STATE_CLOSED || rightStage === DEAL_STATE_DEAD) {
    return 1;
  }

  if (leftStage === DEAL_STATE_CLOSED) {
    return rightStage === DEAL_STATE_DEAD ? 1 : -1;
  }

  return leftStage.toUpperCase() < rightStage.toUpperCase() ? 1 : -1;
};

function PropertyCell({ getValue, row: { original } }) {
  return (((getValue() || 1) > 1) ? `${getValue()} Units` : `${original.bed} bd / ${original.bath} ba`);
}

function TransactionNameCell({ row }) {
  return (
    <div className="pr-2 flex w-max text-tertiary hover:text-tertiary-lighter items-center">
      <div className="h-12 w-12">
        <img
          className="h-12 w-12 object-cover"
          alt="propertyPhoto"
          src={row.original.imageUrl}
          onError={hideImgOnError}
        />
      </div>
      <div className="flex flex-col ml-3">
        <div className="text-sm text-neutral-dark truncate">{titleCase(row.original.name)}</div>
        <div className="text-xs text-gray-500">{`${titleCase(row.original.city)}, ${row.original.state} ${row.original.zipCode}`}</div>
      </div>
    </div>
  );
}

function StatusBadge({ value, backgroundColor = 'bg-primary-light', textColor = 'text-primary-dark' }) {
  const clazz = cx(
    'text-xs w-max px-2 text-center py-px rounded-lg truncate',
    backgroundColor,
    textColor,
  );
  return <div className={clazz}>{value}</div>;
}

function DealStageCell({ getValue, row: { original: { transactionType } } }) {
  const pipelineStatus = determinePipelineStatus(getValue(), transactionType);
  return <StatusBadge value={DEAL_STAGE_ABBREVIATIONS[pipelineStatus] || pipelineStatus} />;
}

function MlsStatusCell({ getValue }) {
  const mlsStatus = getValue();
  const [textColor, backgroundColor] = mlsStatus === ACTIVE_STATUS ? ['text-success', 'bg-success-100'] : ['text-neutral-dark', 'bg-gray-300'];
  return <StatusBadge value={mlsStatus} backgroundColor={backgroundColor} textColor={textColor} />;
}

function OfferStageCell({ cell: { row: { original: { transactionInfo } } } }) {
  const offerDate = transactionInfo.offerDate.value;
  const offerStatus = transactionInfo.offerStatus.value;

  let value = 'Not Sent';
  if (offerStatus) {
    value = titleCase(offerStatus);
  } else if (offerDate) {
    value = 'Sent to Agent';
  }

  return <StatusBadge value={value} />;
}

function TransactionInfoCell({ deal, dispatch, fieldName, formatter, header }) {
  const { transactionInfo } = deal;
  const onClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(showEditTransactionInfoModal(deal, fieldName, header));
  };

  const fieldValue = transactionInfo[fieldName]?.value
  let buttonContents = <Pencil className="text-gray-500 h-4 mx-auto" />;
  if (fieldValue) {
    if (PERCENT_OR_CURRENCY_FIELDS.includes(fieldName)) {
      const typeFieldName = `${fieldName.slice(0, -5)}Type`;
      buttonContents = transactionInfo[typeFieldName].value ? formatPercentage(fieldValue / 100) : formatCurrency(fieldValue);
    } else {
      buttonContents = formatter(fieldValue);
    }
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className="flex items-center justify-center p-2 border rounded shadow hover:bg-gray-100 whitespace-nowrap"
    >
      {buttonContents}
    </button>
  );
}

const transactionInfoColumn = (fieldName, toggleSection, header, dispatch, formatter = identity, meta = {}) => ({
  id: fieldName,
  header,
  // coalesce to undefined to make sorting behavior function as intended
  accessorFn: (row) => row.transactionInfo[fieldName].value ?? undefined,
  sortUndefined: 'last',
  cell: memo(({ cell: { row: { original } } }) => (
    <TransactionInfoCell
      header={header}
      fieldName={fieldName}
      deal={original}
      dispatch={dispatch}
      formatter={formatter}
    />
  )),
  meta: { ...meta, toggleSection },
});

function TaskCell({ dispatch, tasks, taskType, transactionInfo, dateFormat }) {
  const task = tasks.find(t => t.taskType === taskType);
  if (!task) return 'N/A';

  if (isTaskBlocked(tasks, task, transformTransactionInfo(transactionInfo))) {
    return null;
  }
  if (task.completedAt) {
    return formatDate(task.completedAt, dateFormat || DEFAULT_DATE_FORMAT);
  }
  const onClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(submitTaskResponseModal(task));
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className="flex items-center justify-center p-2 border rounded shadow hover:bg-gray-100"
    >
      <Check className="w-4" />
    </button>
  );
}

const taskColumn = (taskType, header, dispatch, dateFormat) => ({
  id: taskType,
  header,
  cell: memo(({ cell: { row: { original: { tasks, transactionInfo } } } }) => (
    <TaskCell
      taskType={taskType}
      tasks={tasks}
      transactionInfo={transformTransactionInfo(transactionInfo)}
      dateFormat={dateFormat}
      dispatch={dispatch}
    />
  )),
  meta: { toggleSection: TOGGLE_SECTION_TASK },
});

const sharedGeneralCols = ({ usersById, transactionType }) => [
  {
    id: 'name',
    header: 'Deal',
    accessorKey: 'name',
    filter: 'includes',
    sortingFn: (rowA, rowB) => (rowA.original.name < rowB.original.name ? 1 : -1),
    cell: TransactionNameCell,
    enableHiding: false,
    meta: { noPadding: true, toggleSection: TOGGLE_SECTION_DEAL },
  },
  {
    accessorKey: 'bedBath',
    id: 'bedBath',
    header: 'Bed / Bath',
    cell: ({ getValue }) => `${getValue()[0]} bd / ${getValue()[1]} ba`,
    meta: { toggleSection: TOGGLE_SECTION_DEAL },
  },
  {
    accessorKey: 'units',
    id: 'units',
    header: 'Units',
    meta: { toggleSection: TOGGLE_SECTION_DEAL, ...dataTableMeta.textRight },
  },
  {
    accessorKey: 'portfolioName',
    id: 'portfolio',
    header: 'Portfolio',
    cell: memo(({ getValue }) => <div className="truncate">{getValue()}</div>),
    meta: { toggleSection: TOGGLE_SECTION_DEAL },
  },
  {
    id: 'market',
    accessorKey: 'market',
    header: 'Market',
    accessor: 'market',
    meta: { toggleSection: TOGGLE_SECTION_DEAL },
  },
  {
    id: 'lead',
    header: 'Lead',
    accessorKey: 'leadId',
    cell: ({ getValue }) => usersById[getValue()]?.fullName || usersById[getValue()]?.email || (<div className="text-gray-400">Unassigned</div>),
    meta: { toggleSection: TOGGLE_SECTION_DEAL },
  },
  {
    id: 'stage',
    accessorKey: 'stage',
    header: 'Deal Stage',
    sortingFn: (rowA, rowB) => dealStageComparator(rowA.original, rowB.original),
    cell: DealStageCell,
    meta: { toggleSection: TOGGLE_SECTION_DEAL },
  },
  {
    id: 'purchasePrice',
    header: 'Underwritten Price',
    accessorKey: 'purchasePrice',
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { toggleSection: TOGGLE_SECTION_UNDERWRITING, ...dataTableMeta.textRight },
  },
  {
    accessorKey: 'stabilizedYield',
    id: 'yield',
    header: 'Yield',
    cell: ({ getValue }) => formatPercentage(getValue(), 2),
    meta: { toggleSection: TOGGLE_SECTION_UNDERWRITING, ...dataTableMeta.textRight },
  },
  {
    accessorKey: 'status',
    id: 'mlsStatus',
    header: 'MLS Status',
    cell: memo(({ getValue }) => <MlsStatusCell getValue={getValue} transactionType={transactionType} />),
    meta: { toggleSection: TOGGLE_SECTION_DEAL },
  },
];

const endColumns = ({ dispatch, showMarkDealDeadModal, dateFormat }) => [
  {
    accessorKey: 'lastModifiedAt',
    id: 'lastModified',
    header: 'Last Modified',
    cell: memo(({ getValue }) => (
      <div className="flex flex-col text-right">
        <div className="text-neutral-dark uppercase">{formatDate(getValue(), dateFormat || DEFAULT_DATE_FORMAT)}</div>
        <div className="text-neutral-medium text-xs">{formatDate(getValue(), "HH:mm aaaaa'm'")}</div>
      </div>
    )),
    meta: { toggleSection: TOGGLE_SECTION_SYSTEM },
  },
  {
    id: 'menu',
    header: '',
    enableSorting: false,
    accessorFn: (row) => row,
    cell: memo(({ getValue }) => (
      <div className="relative">
        <div className="flex justify-center gap-x-1 items-center">
          <div className="flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 cursor-pointer" onClick={(event) => { event.stopPropagation(); event.preventDefault(); dispatch(editDealModal(getValue())); }}>
            <Pencil className="w-6 h-6 mx-auto" />
          </div>
          <div className="flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 cursor-pointer" onClick={(event) => { event.stopPropagation(); event.preventDefault(); showMarkDealDeadModal(getValue()); }}>
            <Trash className="w-6 h-6 mx-auto" />
          </div>
        </div>
      </div>
    )),
    enableHiding: false,
  },
];

const dateFormatter = (dateFormat) => (value) => formatDate(value, dateFormat);

export const getAcquisitionColumns = ({ dispatch, usersById, showMarkDealDeadModal, dateFormat, transactionType }) => [
  ...sharedGeneralCols({ dispatch, usersById, transactionType, showMarkDealDeadModal, dateFormat }),
  {
    id: 'rent',
    header: 'Rent',
    accessorKey: 'rent',
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: {
      ...dataTableMeta.textRight,
      toggleSection: TOGGLE_SECTION_UNDERWRITING,
    },
  },
  {
    id: 'offerStage',
    header: 'Offer Stage',
    cell: OfferStageCell,
    meta: {
      toggleSection: TOGGLE_SECTION_TRANSACTION_OFFER,
    },
  },
  transactionInfoColumn('clientName', TOGGLE_SECTION_TRANSACTION_GENERAL, 'Client Name', dispatch),
  transactionInfoColumn('clientEmail', TOGGLE_SECTION_TRANSACTION_GENERAL, 'Client Email', dispatch),
  transactionInfoColumn('entityName', TOGGLE_SECTION_TRANSACTION_GENERAL, 'Entity Name', dispatch),
  transactionInfoColumn('titleContact', TOGGLE_SECTION_TRANSACTION_GENERAL, 'Title Contact', dispatch),

  transactionInfoColumn('acceptanceDate', TOGGLE_SECTION_TRANSACTION_DATES, 'Acceptance Date', dispatch, dateFormatter(dateFormat)),
  transactionInfoColumn('inspectionDate', TOGGLE_SECTION_TRANSACTION_DATES, 'Inspection Date', dispatch, dateFormatter(dateFormat)),
  transactionInfoColumn('appraisalDate', TOGGLE_SECTION_TRANSACTION_DATES, 'Appraisal Date', dispatch, dateFormatter(dateFormat)),
  transactionInfoColumn('dueDiligenceEndDate', TOGGLE_SECTION_TRANSACTION_DATES, 'Due Diligence End Date', dispatch, dateFormatter(dateFormat)),
  transactionInfoColumn('closeOfEscrowDate', TOGGLE_SECTION_TRANSACTION_DATES, 'COE Date', dispatch, dateFormatter(dateFormat)),

  transactionInfoColumn('listPrice', TOGGLE_SECTION_TRANSACTION_PRICES, 'List Price', dispatch, formatCurrency),
  transactionInfoColumn('contractPrice', TOGGLE_SECTION_TRANSACTION_PRICES, 'Contract Price', dispatch, formatCurrency),
  transactionInfoColumn('closePrice', TOGGLE_SECTION_TRANSACTION_PRICES, 'Close Price', dispatch, formatCurrency),

  transactionInfoColumn('buyerBrokerCommissionValue', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Buyer Broker Commission', dispatch),
  transactionInfoColumn('commissionReferralValue', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Commission Referral', dispatch),
  transactionInfoColumn('earnestMoney', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Earnest Money', dispatch),
  transactionInfoColumn('financingContingencies', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Financing Contingencies', dispatch),
  transactionInfoColumn('loanType', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Loan Type', dispatch),
  transactionInfoColumn('repairConcessionsValue', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Repair Concessions', dispatch),
  transactionInfoColumn('sellerBrokerCommissionValue', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Seller Broker Commission', dispatch),
  transactionInfoColumn('sellerConcessionsValue', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Seller Concessions', dispatch),

  taskColumn('earnest_money_deposit_sent', 'EM Sent', dispatch, dateFormat),
  taskColumn('cda_sent', 'CDA Sent', dispatch, dateFormat),
  taskColumn('initial_walkthrough_complete', 'Initial Walkthrough', dispatch, dateFormat),
  taskColumn('final_walkthrough_complete', 'Final Walkthrough', dispatch, dateFormat),

  transactionInfoColumn('mlsId', TOGGLE_SECTION_TRANSACTION_MLS, 'MLS ID', dispatch),
  ...endColumns({ dispatch, usersById, showMarkDealDeadModal, dateFormat }),
];

export const getDispositionColumns = ({ dispatch, usersById, showMarkDealDeadModal, dateFormat }) => [
  ...sharedGeneralCols({ dispatch, usersById, showMarkDealDeadModal, dateFormat }),
  transactionInfoColumn('clientName', TOGGLE_SECTION_TRANSACTION_GENERAL, 'Client Name', dispatch),
  transactionInfoColumn('clientEmail', TOGGLE_SECTION_TRANSACTION_GENERAL, 'Client Email', dispatch),
  transactionInfoColumn('mechanicalLockboxCode', TOGGLE_SECTION_TRANSACTION_GENERAL, 'Lockbox Code', dispatch),
  transactionInfoColumn('occupancyStatus', TOGGLE_SECTION_TRANSACTION_GENERAL, 'Occupancy Status', dispatch),
  transactionInfoColumn('titleContact', TOGGLE_SECTION_TRANSACTION_GENERAL, 'Title Contact', dispatch),
  {
    id: 'showings',
    header: 'Showings',
    accessorKey: 'numberOfShowings',
    meta: { toggleSection: TOGGLE_SECTION_TRANSACTION_GENERAL, ...dataTableMeta.textRight },
  },
  {
    id: 'offers',
    header: 'Offers',
    accessorKey: 'offers',
    meta: { toggleSection: TOGGLE_SECTION_TRANSACTION_GENERAL, ...dataTableMeta.textRight },
  },
  {
    id: 'activeOffers',
    header: 'Active Offers',
    accessorKey: 'activeOffers',
    meta: { toggleSection: TOGGLE_SECTION_TRANSACTION_GENERAL, ...dataTableMeta.textRight },
  },
  transactionInfoColumn('assignedDate', TOGGLE_SECTION_TRANSACTION_DATES, 'Assigned Date', dispatch, dateFormatter(dateFormat)),
  transactionInfoColumn('listingDate', TOGGLE_SECTION_TRANSACTION_DATES, 'Listing Date', dispatch, dateFormatter(dateFormat)),
  transactionInfoColumn('acceptanceDate', TOGGLE_SECTION_TRANSACTION_DATES, 'Acceptance Date', dispatch, dateFormatter(dateFormat)),
  transactionInfoColumn('inspectionDate', TOGGLE_SECTION_TRANSACTION_DATES, 'Inspection Date', dispatch, dateFormatter(dateFormat)),
  transactionInfoColumn('appraisalDate', TOGGLE_SECTION_TRANSACTION_DATES, 'Appraisal Date', dispatch, dateFormatter(dateFormat)),
  transactionInfoColumn('dueDiligenceEndDate', TOGGLE_SECTION_TRANSACTION_DATES, 'Due Diligence End Date', dispatch, dateFormatter(dateFormat)),
  transactionInfoColumn('closeOfEscrowDate', TOGGLE_SECTION_TRANSACTION_DATES, 'COE Date', dispatch, dateFormatter(dateFormat)),

  transactionInfoColumn('proposedListPrice', TOGGLE_SECTION_TRANSACTION_PRICES, 'Proposed List Price', dispatch, formatCurrency),
  transactionInfoColumn('listPrice', TOGGLE_SECTION_TRANSACTION_PRICES, 'List Price', dispatch, formatCurrency),
  transactionInfoColumn('contractPrice', TOGGLE_SECTION_TRANSACTION_PRICES, 'Contract Price', dispatch, formatCurrency),
  transactionInfoColumn('closePrice', TOGGLE_SECTION_TRANSACTION_PRICES, 'Close Price', dispatch, formatCurrency),

  transactionInfoColumn('buyerBrokerCommissionValue', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Buyer Broker Commission', dispatch),
  transactionInfoColumn('commissionReferralValue', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Commission Referral', dispatch),
  transactionInfoColumn('earnestMoney', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Earnest Money', dispatch, formatCurrency),
  transactionInfoColumn('financingContingencies', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Financing Contingencies', dispatch),
  transactionInfoColumn('loanType', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Loan Type', dispatch),
  transactionInfoColumn('repairConcessionsValue', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Repair Concessions', dispatch),
  transactionInfoColumn('sellerBrokerCommissionValue', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Seller Broker Commission', dispatch),
  transactionInfoColumn('sellerConcessionsValue', TOGGLE_SECTION_TRANSACTION_FINANCIAL, 'Seller Concessions', dispatch),

  transactionInfoColumn('mlsId', TOGGLE_SECTION_TRANSACTION_MLS, 'MLS ID', dispatch),
  ...endColumns({ dispatch, usersById, showMarkDealDeadModal, dateFormat }),
];

export const getColumns = ({ transactionType, dispatch, usersById, showMarkDealDeadModal, dateFormat }) => ((transactionType === TRANSACTION_TYPES.acquisition)
  ? getAcquisitionColumns({ dispatch, usersById, showMarkDealDeadModal, dateFormat, transactionType })
  : getDispositionColumns({ dispatch, usersById, showMarkDealDeadModal, dateFormat, transactionType }));
