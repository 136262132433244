function Tooltip({ alignRight, text, width }) {
  return (
    <span className={`${width || 'w-64'} ${alignRight ? 'right-full' : 'left-full'} absolute z-50 shadow-lg invisible top-0 p-2 rounded-sm bg-white text-xs text-gray-700 text-left normal-case whitespace-pre-wrap tracking-normal group-hover:visible transition-all delay-300`}>{text}</span>
  );
}

export default function IncludeToolTip({ alignRight, children, tooltipText, width }) {
  return (
    <div className="group relative">
      <Tooltip alignRight={alignRight} text={tooltipText} width={width} />
      {children}
    </div>
  );
}
