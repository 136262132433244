import { CheckboxEmpty, CheckboxFilled } from 'components/icons';
import { ORG_SINGLE_FAMILY_TYPE, ORG_MULTI_FAMILY_TYPE } from 'components/constants';

export const PROPERTY_TYPES = [
  { value: ORG_SINGLE_FAMILY_TYPE, label: 'Single-Family' },
  { value: ORG_MULTI_FAMILY_TYPE, label: 'Multifamily' },
];

export default function PropertyType({ organization, onChange }) {
  return (
    <div className="mt-6">
      <span className="block text-xs text-primary-dark border-b mt-6 pb-2">Property Type</span>
      { PROPERTY_TYPES.map(w => (
        <div className="w-full flex justify-between items-center py-2 border-b cursor-pointer" value={w.value} key={w.value} onClick={() => onChange(w.value)}>
          <span className="text-sm font-light">{w.label}</span>
          { organization?.propertyTypes?.includes(w.value) ? <CheckboxFilled className="w-6" /> : <CheckboxEmpty className="w-6" />}
        </div>
      )) }
    </div>
  );
}
