import { X } from 'components/icons';

// Notes:
// - parent element must be relative for fixed positioning to work

export default function Alert(props) {
  const {
    inline = false, // for dev/testing purposes
    position = 'bottom',
    text1 = 'Alert',
    text2 = '',
    action,
    actionText = 'Action',
    closeable = true,
    onClose,
  } = props;

  const inlineClassNames = 'relative';
  const fixedClassNames = `fixed ${position}-5`;

  return (
    <div className={`flex justify-center items-center ${inline ? inlineClassNames : fixedClassNames} w-full p-4 z-10`}>
      <div className="w-3/5 p-4 flex justify-between items-center bg-neutral-medium shadow-lg rounded-md text-white">
        <div className="w-2/3 flex flex-col">
          <div>{ text1 }</div>
          <div>{ text2 }</div>
        </div>
        <div className="w-1/3 flex justify-end items-center">
          {
            action
            && (
              <div className="cursor-pointer mr-4" onClick={() => action()}>
                { actionText }
              </div>
            )
          }
          { closeable
            && (
            <div className="cursor-pointer" onClick={() => onClose()}>
              <X className="h-6" />
            </div>
            )}
        </div>
      </div>
    </div>
  );
}
