import { getMemoOptions } from '@tanstack/react-table';
import { throwingMemo } from '../table.helpers';
import createTableExtension from '../utils/createTableExtension';
import getVisibilityConfigurableFlatColumns from './getVisibilityConfigurableFlatColumns';

const getToggleSectionFlatColumns = createTableExtension(() => {
  /** @type {Map<string, () => import('@tanstack/react-table').Column[]>} */
  const cache = new Map();

  return (table, { toggleSection }) => {
    let memoized = cache.get(toggleSection);
    if (memoized === undefined) {
      memoized = throwingMemo(
        () => [getVisibilityConfigurableFlatColumns(table)],
        (columns) => (
          columns.filter((column) => (column.columnDef.meta?.toggleSection ?? null) === toggleSection)
        ),
        getMemoOptions(table.options, 'debugColumns', `getToggleSectionFlatColumns[${toggleSection}]`),
      );
      cache.set(toggleSection, memoized);
    }

    return memoized();
  };
});

export default getToggleSectionFlatColumns;
