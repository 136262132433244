import cx from 'classnames';
import { useState, useEffect, useRef } from 'react';
import { Listbox } from '@headlessui/react';
import { some } from 'lodash';
import { ArrowDropDown, CheckboxEmpty, CheckboxFilled } from '../icons';

const isSelected = (selectedOptions, option) => some(selectedOptions, option);

const makeListboxOptions = ({ options = [], selectedOptions = [], idx = 0, onChange: handleClick }) => (
  options.map((option, subIdx) => (
    Array.isArray(option) ? (
      makeListboxOptions({ options: option, selectedOptions, idx: idx + subIdx, onChange: handleClick })
    ) : (
      <Listbox.Option key={[option.name, idx].join('-')} value={option}>
        <div
          onClick={() => handleClick(option)}
          className={cx('flex items-center justify-between w-full py-2 pl-8 pr-4 text-sm text-gray-900 cursor-pointer select-none hover:bg-gray-100')}
        >
          <span>{option.name}</span>
          {isSelected(selectedOptions, option) ? <CheckboxFilled className="w-6 h-6 flex-none" /> : <CheckboxEmpty className="w-6 h-6 flex-none" />}
        </div>
      </Listbox.Option>
    )
  ))
);

function DefaultButtonLabel({ selectedOptions }) {
  return (
    <span className="text-gray-400">
      {selectedOptions?.length ? `${selectedOptions.length} option(s) selected` : 'Select Options'}
    </span>
  );
}

export default function MultiSelectDropdown({
  options,
  width,
  height,
  padding,
  optionsWidth,
  selectedOptions = [],
  onChange,
  label,
  labelClassName,
  ButtonLabel = DefaultButtonLabel,
  displaySelectedOptions = true,
  showClearButton = true,
  hideOnChange = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const componentRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [ref]);
  }

  // conditionally hide list when selectedOptions changes
  useEffect(() => {
    if (hideOnChange && selectedOptions) {
      setIsOpen(false);
    }
  }, [hideOnChange, selectedOptions, setIsOpen]);

  const clear = () => {
    onChange([]);
  };

  useOutsideAlerter(componentRef);
  const hasValue = selectedOptions.some(opt => !!opt);
  return (
    <div className="flex flex-col items-start justify-center" ref={componentRef}>
      <div className="w-full flex flex-row justify-between items-center empty:hidden">
        {label && <label className={labelClassName ?? 'font-medium text-sm mb-2'}>{label}</label>}
        {(showClearButton && hasValue) ? <span className="font-medium text-sm text-red-400 cursor-pointer" onClick={clear}>clear</span> : null }
      </div>
      <Listbox value={selectedOptions} className="relative w-full" as="div" by="id" multiple>
        <>
          <Listbox.Button className={`relative ${width} ${height || 'h-full'} flex justify-between text-left text-sm bg-white border rounded-md cursor-pointer focus:outline-none`}>
            <div className={`${padding ?? 'py-3 px-4'} w-full flex justify-between items-center truncate`} onClick={() => setIsOpen(prevIsOpen => !prevIsOpen)}>
              <ButtonLabel selectedOptions={selectedOptions} />
              <ArrowDropDown className="w-4 flex-none" />
            </div>
          </Listbox.Button>
          {isOpen && (
            <div className={`${optionsWidth || 'w-full'} absolute top-full z-20 py-1 mt-1 text-sm bg-white rounded-md shadow-xl`}>
              <Listbox.Options static className="max-h-60 overflow-auto overscroll-none">
                {options && (
                  makeListboxOptions({
                    options,
                    selectedOptions,
                    onChange,
                  })
                )}
              </Listbox.Options>
              {!hideOnChange && (
                <div className="w-full px-4 my-2 flex items-center justify-end">
                  <button
                    type="button"
                    className="px-6 py-2 bg-primary-dark text-white rounded-full text-center select-none"
                    onClick={() => setIsOpen(false)}
                  >
                    Done
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      </Listbox>
      {
        displaySelectedOptions && (
          <div className="mt-2 flex flex-wrap">
            {
              selectedOptions.map((opt, idx) => (
                <div
                  className="text-xs bg-primary-light text-neutral-dark m-1 px-2 py-2 font-medium rounded-lg"
                  key={[opt.value, idx].join('-')}
                >
                  {opt.name}
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
}
