import { createSelector } from '@reduxjs/toolkit';
import { identity, isEqualWith, isNil } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useDebugValue,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import getOrderConfigurableLeafColumns from './dataTableConfig/getOrderConfigurableLeafColumns';
import getOrderDraggableRange from './dataTableConfig/getOrderDraggableRange';
import getVisibilityConfigurableFlatColumns from './dataTableConfig/getVisibilityConfigurableFlatColumns';
import getToggleSectionFlatColumns from './dataTableConfig/getToggleSectionFlatColumns';
import getVisibilityConfigurableLeafHeaders from './dataTableConfig/getVisibilityConfigurableLeafHeaders';
import getVisibleOrderConfigurableLeafHeaders from './dataTableConfig/getVisibleOrderConfigurableLeafHeaders';
import { isColumnOrderDraggable, isColumnVisibilityToggleable } from './dataTableConfig/utils';
import {
  clearRowUpdates,
  getActivePreset,
  getActivePresetId,
  getActivePresetIdUpdater,
  getRowUpdates,
  getPredefinedPresets,
  getUpdatedRowData,
  setRowUpdateState,
} from './table.features';

/**
 * @typedef {{ table: import('@tanstack/react-table').Table, listeners: Set<(table: import('@tanstack/react-table').Table) => void> }} DataTableContextValue
 */

/**
 * @type {import('react').Context<undefined | import('react').MutableRefObject<DataTableContextValue>>}
 */
const DataTableContext = createContext(undefined);
DataTableContext.displayName = 'DataTableContext';

const useDataTableContext = () => {
  const value = useContext(DataTableContext);
  if (value === undefined) {
    throw new Error('This hook can only be used within DataTableProvider');
  }

  return value;
};

/**
 * @type {import('react').Context<[null | Element, import('react').Dispatch<import('react').SetStateAction<null | Element>>]>}
 */
const TableContainerContext = createContext(undefined);
TableContainerContext.displayName = 'TableContainerContext';

const useTableContainerContext = () => {
  const value = useContext(TableContainerContext);
  if (value === undefined) {
    throw new Error('This hook can only be used within TableContainerContext');
  }

  return value;
};

/**
 * @param {import('@tanstack/react-table').Table} table
 * @param {import('react').ReactNode} children
 */
export function DataTableProvider({ table, children }) {
  const tableRef = useRef({ table, listeners: new Set() });
  tableRef.current.table = table;

  // listen for changes in table.options and fire listener callbacks
  // the table object returned by useReactTable is stable and updated mutably
  // table.options includes the entire internal table state and is updated immutably
  useLayoutEffect(() => {
    tableRef.current.listeners.forEach((listener) => {
      try {
        listener(tableRef.current.table);
      } catch (e) {
        console.error(e);
      }
    });
  }, [table.options]);

  return (
    <DataTableContext.Provider value={tableRef}>
      {children}
    </DataTableContext.Provider>
  );
}

export function DataTableContainerProvider({ children }) {
  const [container, setContainer] = useState(null);
  const contextValue = useMemo(() => [container, setContainer], [container]);

  return (
    <TableContainerContext.Provider value={contextValue}>
      {children}
    </TableContainerContext.Provider>
  );
}

export function DataTablePortal({ children }) {
  const [container] = useTableContainerContext();
  return container === null ? null : createPortal(children, container);
}

export function DataTableContent() {
  const [, setContainer] = useTableContainerContext();

  return (
    <div ref={setContainer} className="contents" />
  );
}

/**
 * Returns true if the 2 arguments are equal or they're the same type and are both empty
 *
 * @param prev
 * @param next
 * @return {boolean}
 */
const sameValueOrEmpty = (prev, next) => {
  if (Object.is(prev, next)) {
    return true;
  }

  return isEqualWith(
    prev,
    next,
    (value, other, indexOrKey) => (
      indexOrKey === undefined ? undefined : false
    ),
  );
};

/**
 * @template T
 * @param {(table: import('@tanstack/react-table').Table) => T} selector
 * @param {(prev: T, next: T) => boolean} equalityCheck
 * @return {T | undefined}
 */
const useTableProperty = (selector, equalityCheck = sameValueOrEmpty) => {
  const { current: { table, listeners } } = useDataTableContext();
  const [value, setValue] = useState(() => selector(table));

  useEffect(() => {
    const callback = (t) => {
      if (!t) {
        setValue(undefined);
        return;
      }

      setValue((prevState) => {
        const updated = selector(t);
        return equalityCheck(prevState, updated) ? prevState : updated;
      });
    };
    listeners.add(callback);

    return () => { listeners.delete(callback); };
  }, [equalityCheck, listeners, selector]);

  return value;
};

/**
 * Note that table.getColumn returns a stable reference to the column object
 * only updates when the column definition changes
 *
 * @template T
 * @param {string} columnId
 * @param {(column: import('@tanstack/react-table').Column) => T} selector
 * @return {T}
 */
const useColumn = (columnId, selector) => {
  const tableSelector = useCallback((table) => {
    const column = table.getColumn(columnId);
    if (column === undefined) {
      return undefined;
    }

    return selector(column);
  }, [columnId, selector]);

  useDebugValue(columnId);
  return useTableProperty(tableSelector);
};

/**
 * Note that the table instance is a stable reference that updates mutably.
 * React to changes of specific properties of table with useTableProperty.
 *
 * @return {import('@tanstack/react-table').Table}
 */
const useTableInstance = () => useTableProperty(identity);

export const useColumnFilter = (columnId, clearFilterValue = undefined) => {
  // useMemo to have a cached selector per hook instance
  const memoizedSelector = useMemo(() => (
    createSelector(
      [
        ({ getIsFiltered }) => getIsFiltered(),
        ({ getFilterValue }) => getFilterValue(),
        ({ setFilterValue }) => setFilterValue,
        (_, resetValue) => resetValue,
        (_, _resetValue, clearValue) => clearValue,
      ],
      (isFiltered, filterValue, setFilterValue, resetValue, clearValue) => ({
        isFiltered,
        filterValue,
        setFilterValue,
        resetFilterValue: () => setFilterValue(structuredClone(resetValue)),
        clearFilterValue: () => setFilterValue(structuredClone(clearValue)),
      }),
    )
  ), []);

  const initialFilterValueSelector = useCallback((table) => (
    table.initialState.columnFilters.find(({ id }) => id === columnId)?.value
  ), [columnId]);
  const initialFilterValue = useTableProperty(initialFilterValueSelector);

  const selector = useCallback((column) => (
    // column is stable, spread column to get past equality check
    memoizedSelector({ ...column }, initialFilterValue, clearFilterValue)
  ), [clearFilterValue, initialFilterValue, memoizedSelector]);

  return useColumn(columnId, selector);
};

export const useSetColumnFilters = () => {
  const table = useTableInstance();

  // removes all filters
  const clearColumnFilters = useCallback(() => table.resetColumnFilters(true), [table]);
  // sets all filters to table.initialState
  const resetColumnFilters = useCallback(() => table.resetColumnFilters(false), [table]);

  return useMemo(() => ({
    setColumnFilters: table.setColumnFilters,
    clearColumnFilters,
    resetColumnFilters,
  }), [clearColumnFilters, resetColumnFilters, table.setColumnFilters]);
};

/** @param {import('@tanstack/react-table').Table} table */
const filteredSelectedRowsSelector = (table) => table.getFilteredSelectedRowModel().rows;
export const useFilteredSelectedRows = () => {
  return useTableProperty(filteredSelectedRowsSelector);
};

/** @param {import('@tanstack/react-table').Table} table */
const filteredRowsSelector = (table) => table.getRowModel().rows;
export const useFilteredRows = () => {
  return useTableProperty(filteredRowsSelector);
};

/** @param {import('@tanstack/react-table').Table} table */
const coreRowModelSelector = (table) => table.getCoreRowModel();
export const useCoreRowModel = () => useTableProperty(coreRowModelSelector);

export const useRowUpdatesForRow = (rowId) => {
  const selector = useCallback((table) => (
    getRowUpdates(table).get(rowId)
  ), [rowId]);
  return useTableProperty(selector);
};

/**
 * @template T
 * @param {string} rowId
 * @param {string} columnId
 * @return {[T, import('@tanstack/react-table').OnChangeFn<T>, boolean]}
 */
export const useUpdateRow = ({ rowId, columnId }) => {
  const table = useTableInstance();

  const setRowUpdate = useCallback((updater) => {
    setRowUpdateState(table, rowId, columnId, updater);
  }, [columnId, rowId, table]);

  const rowUpdates = useRowUpdatesForRow(rowId);
  const hasUpdate = !!rowUpdates?.has(columnId);
  const updatedValue = hasUpdate ? rowUpdates.get(columnId) : undefined;

  return useMemo(() => ([
    updatedValue,
    setRowUpdate,
    hasUpdate,
  ]), [hasUpdate, setRowUpdate, updatedValue]);
};

const isRowUpdatesNotEmptySelector = (table) => getRowUpdates(table).size > 0;
export const useIsTableEdited = () => useTableProperty(isRowUpdatesNotEmptySelector);

export const useClearRowUpdates = () => {
  const table = useTableInstance();
  return useCallback(() => clearRowUpdates(table), [table]);
};

export const useGetUpdatedRowData = () => {
  const table = useTableInstance();
  return useCallback(({ idColumn = 'id', rowModel = 'getCoreRowModel', rowSelector } = {}) => {
    const rowUpdates = getRowUpdates(table);

    if (!rowUpdates?.size) {
      return [];
    }

    return Array.from(rowUpdates.keys())
      // note that table.getRow doesn't work because it always searches all rows
      .map((rowId) => table[rowModel]().rowsById[rowId])
      .filter((row) => row !== undefined)
      .map((row) => getUpdatedRowData(row))
      .map(rowSelector ?? (([{ [idColumn]: id }, rowDelta]) => ({ [idColumn]: id, ...rowDelta })));
  }, [table]);
};

/**
 * @template T
 * @param {(a: ReturnType<typeof getRowUpdates>, { table: import('@tanstack/react-table').Table }) => T} rowUpdatesSelector
 */
export const useRowUpdates = (rowUpdatesSelector) => {
  /** @type {(table: import('@tanstack/react-table').Table) => T} */
  const selector = useCallback((table) => (
    rowUpdatesSelector(getRowUpdates(table), { table })
  ), [rowUpdatesSelector]);

  return useTableProperty(selector);
};

/** @param {import('@tanstack/react-table').Table} table */
const setColumnVisibilitySelector = (table) => table.setColumnVisibility;
export const useSetColumnVisibility = () => (
  useTableProperty(setColumnVisibilitySelector)
);

export const useInitialColumnVisibility = ({ columnId }) => {
  const selector = useCallback((table) => (
    // use getColumn to verify column existence
    table.initialState.columnVisibility?.[table.getColumn(columnId)?.id]
  ), [columnId]);

  return useTableProperty(selector);
};

export const useActivePresetId = () => {
  const activePresetId = useTableProperty(getActivePresetId);
  const setActivePreset = useTableProperty(getActivePresetIdUpdater);

  return useMemo(() => [activePresetId, setActivePreset], [activePresetId, setActivePreset]);
};

export const useActivePreset = () => useTableProperty(getActivePreset);

export const useConfigPresets = () => useTableProperty(getPredefinedPresets);

/** @param {import('@tanstack/react-table').Column} column */
const visibilitySelector = (column) => column.getIsVisible();
/** @param {import('@tanstack/react-table').Column} column */
const toggleVisibilitySelector = (column) => column.toggleVisibility;
export const useColumnVisibility = ({ columnId }) => {
  const isVisible = useColumn(columnId, visibilitySelector);
  const visibilityToggle = useColumn(columnId, toggleVisibilitySelector);

  return useMemo(() => [isVisible, visibilityToggle], [isVisible, visibilityToggle]);
};

export const useVisibilityConfigurableLeafHeaders = () => useTableProperty(getVisibilityConfigurableLeafHeaders);

export const useVisibilityConfigurableFlatColumns = () => useTableProperty(getVisibilityConfigurableFlatColumns);

export const useIsColumnVisibilityToggleable = ({ columnId }) => useColumn(columnId, isColumnVisibilityToggleable);

export const useToggleSectionColumns = ({ toggleSection }) => (
  useTableProperty(useCallback((table) => (
    getToggleSectionFlatColumns(table, { toggleSection })
  ), [toggleSection]))
);

export const useToggleSectionVisibility = ({ toggleSection }) => {
  const columns = useToggleSectionColumns({ toggleSection });

  const sectionSomeToggleableVisibleSelector = useCallback(() => (
    columns.filter(isColumnVisibilityToggleable).some((column) => column.getIsVisible())
  ), [columns]);
  const someToggleableVisible = useTableProperty(sectionSomeToggleableVisibleSelector);

  const sectionSomeVisibleSelector = useCallback(() => (
    columns.some((column) => column.getIsVisible())
  ), [columns]);
  const someVisible = useTableProperty(sectionSomeVisibleSelector);

  const sectionAllVisibleSelector = useCallback(() => (
    columns.every((column) => column.getIsVisible())
  ), [columns]);
  const allVisible = useTableProperty(sectionAllVisibleSelector);

  const setColumnVisibility = useSetColumnVisibility();
  const toggle = useCallback((value) => (
    setColumnVisibility((prev) => {
      const toggleableColumns = columns.filter((column) => column.getCanHide());

      let updateValue = value;
      if (isNil(updateValue)) {
        updateValue = !toggleableColumns.some((column) => column.getIsVisible());
      }

      return {
        ...prev,
        ...Object.fromEntries(toggleableColumns.map((column) => [column.id, updateValue])),
      };
    })
  ), [columns, setColumnVisibility]);

  return useMemo(() => (
    [{ someVisible, allVisible, someToggleableVisible }, toggle]
  ), [allVisible, someToggleableVisible, someVisible, toggle]);
};

export const useVisibleOrderConfigurableLeafHeaders = () => useTableProperty(getVisibleOrderConfigurableLeafHeaders);

export const useOrderConfigurableLeafColumns = () => useTableProperty(getOrderConfigurableLeafColumns);

export const useOrderDraggableRange = () => useTableProperty(getOrderDraggableRange);

export const useIsColumnOrderDraggable = ({ columnId }) => useColumn(columnId, isColumnOrderDraggable);

/** @param {import('@tanstack/react-table').Table} table */
const setColumnOrderSelector = (table) => table.setColumnOrder;
export const useSetColumnOrder = () => useTableProperty(setColumnOrderSelector);
