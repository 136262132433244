import cx from 'classnames';
import { forwardRef } from 'react';

function VirtualizedListInnerContainer({ style, className, children, ...props }, ref) {
  return (
    <div ref={ref} className={cx(className, 'divide-y')} style={style} {...props}>
      {children}
    </div>
  );
}

export default forwardRef(VirtualizedListInnerContainer);
