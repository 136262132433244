import { useMemo } from 'react';
import { compact, mean, meanBy, round } from 'lodash';
import { addWeeks } from 'date-fns';
import { formatCurrency, formatCurrencyAbbreviated, formatPercentage } from 'components/utils';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import OverallMarketSummary from './OverallMarketSummary';
import { activeFn, closingWithinFn, daysOnMarketFn, inContractFn, metricFunc, onMarketFn } from './disposition.utils';
import { MarketSummaryHeader } from '../Shared';

const metricsData = [
  { label: '# In Dispo', statFunc: ((dispos, asOfDate) => activeFn(dispos, asOfDate).length) },
  { label: '# On Market', statFunc: ((dispos, asOfDate) => onMarketFn(dispos, asOfDate).length) },
  { label: '# In Contract', statFunc: ((dispos, asOfDate) => inContractFn(dispos, asOfDate).length) },
  { label: 'Avg Profit', statFunc: ((dispos) => meanBy(dispos, 'attributes.estimatedProfit')), formatter: formatCurrency },
  { label: 'Avg ROI', statFunc: ((dispos) => meanBy(dispos, 'attributes.estimatedRoi')), formatter: formatCurrency },
  { label: 'Avg On Market List Price', statFunc: ((dispos, asOfDate) => meanBy(onMarketFn(dispos, asOfDate), 'attributes.listPrice')), formatter: formatCurrencyAbbreviated },
  { label: 'Avg DOM', statFunc: ((dispos, asOfDate) => mean(compact(dispos.map(dispo => daysOnMarketFn(dispo, asOfDate))))), formatter: val => round(val, 1) },
  { label: 'Avg # of Offers per Property', statFunc: ((dispos) => meanBy(dispos, 'attributes.numberOfOffers')), formatter: val => round(val, 1) },
  { label: '# Closing within 2 Weeks', statFunc: ((dispos, asOfDate) => closingWithinFn(dispos, asOfDate, addWeeks(asOfDate, 2)).length) },
  { label: 'Avg Offer Discount to List', statFunc: ((dispos) => Math.abs(meanBy(dispos, 'attributes.offerToListRatio'))), formatter: formatPercentage },
];

export default function Overall({ asOfDate, lastWeekDate, lastMonthDate, filteredDispositions, oneWeekAgoFilteredDispositions, oneMonthAgoFilteredDispositions }) {
  const metrics = useMemo(
    () => metricsData.map(({ label, formatter, statFunc }) => metricFunc(
      label,
      statFunc,
      asOfDate,
      lastWeekDate,
      lastMonthDate,
      filteredDispositions,
      oneWeekAgoFilteredDispositions,
      oneMonthAgoFilteredDispositions,
      formatter
    )),
    [filteredDispositions],
  );

  return (
    <div>
      <ReturnMetricBoxes metrics={metrics} />
      <MarketSummaryHeader />
      <OverallMarketSummary asOfDate={asOfDate} dispositions={filteredDispositions} />
    </div>
  );
}
