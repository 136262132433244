import { useState } from 'react';
import Button from 'components/shared/NewButton';
import Alert from 'components/Alert';
import EmailInputField from './EmailInputField';

function SendEmailForm({ onSend, isSending }) {
  const [subject, setSubject] = useState('');
  const [toEmails, setToEmails] = useState([]);
  const [ccEmails, setCcEmails] = useState([]);
  const [bccEmails, setBccEmails] = useState([]);
  const [alert, setAlert] = useState({ type: '', message: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!subject.trim()) {
      setAlert({ type: 'danger', message: 'Subject is required.' });
      return;
    }

    if (toEmails.length === 0) {
      setAlert({ type: 'danger', message: 'At least one "To" email is required.' });
      return;
    }

    const emailData = {
      subject: subject.trim(),
      to: toEmails,
      cc: ccEmails,
      bcc: bccEmails,
    };

    try {
      await onSend(emailData);
      setAlert({ type: 'success', message: 'Email sent successfully!' });
      // Optionally, reset the form
      setSubject('');
      setToEmails([]);
      setCcEmails([]);
      setBccEmails([]);
    } catch (error) {
      setAlert({
        type: 'danger',
        message: error?.data?.error || 'Failed to send email. Please try again.',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 space-y-4">
      {alert.message && <Alert type={alert.type} text={alert.message} />}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Subject
        </label>
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="w-full border border-gray-300 rounded p-2"
          placeholder="Enter email subject"
          required
        />
      </div>
      <EmailInputField
        label="To"
        emails={toEmails}
        setEmails={setToEmails}
      />
      <EmailInputField
        label="CC"
        emails={ccEmails}
        setEmails={setCcEmails}
      />
      <EmailInputField
        label="BCC"
        emails={bccEmails}
        setEmails={setBccEmails}
      />
      <div className="flex justify-end">
        <Button
          type="submit"
          filled
          label={isSending ? 'Sending...' : 'Send Email'}
          disabled={isSending}
        />
      </div>
    </form>
  );
}

export default SendEmailForm;
