import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, meanBy, sum } from 'lodash';
import { useUpdateScenarioMutation } from 'redux/apiSlice';
import { setShowCreateDealModal } from 'actions/deal_navigation';
import { resetPendingParams, setPendingParams, setBaseParams } from 'actions/model';
import { DEFAULT_SCENARIO_NAME, LAYOUT } from 'components/constants';
import { calcCashFlows, calcReturnMetrics } from 'components/dcf/dcf';
import { FormField, InfoField } from 'components/Form';
import { formatCurrency, formatEquityMultiple, formatPercentage, parseEventValue } from 'components/utils';
import { UnderwritingSimpleStat } from 'components/shared/metrics/UnderwritingStat';
import { Check, Undo, X } from 'components/icons';
import Button from 'components/shared/NewButton';

export default function UnderwritingDrawer({ context, dismiss }) {
  const { modelData } = context;
  const model = useSelector(state => state.model);
  const [updateScenarioMutation, { isLoading }] = useUpdateScenarioMutation();

  const dispatch = useDispatch();
  useEffect(() => {
    if (modelData) {
      dispatch(setBaseParams(modelData.model));
    }
  }, [dispatch, modelData]);

  const baseParams = model?.baseParams;
  const modelParams = model?.pendingParams;
  const paramsUnsaved = !isEqual(baseParams?.dcfParams, modelParams?.dcfParams);

  if (!modelParams) {
    return null;
  }

  const { dcfParams, scenario } = modelParams;
  const { listPrice, purchasePrice, units } = dcfParams;

  const onChange = (event) => {
    let param = event.target.name;
    let newValue = parseEventValue(event);
    if (['marketRent', 'turnBudget'].includes(param)) {
      newValue = [{ ...dcfParams.units[0], [param]: newValue }];
      param = 'units';
    } else if (param === 'annualHoa') {
      const expenseItems = [...dcfParams.expenseItems];
      param = 'expenseItems';
      const hoaItem = expenseItems.find(expenseItem => expenseItem.name === 'HOA Fee');
      const hoaItemIndex = expenseItems.indexOf(hoaItem);
      expenseItems.splice(hoaItemIndex, 1, { ...hoaItem, inputValue: newValue });
      newValue = expenseItems;
    }
    dispatch(setPendingParams({ param, newValue }));
  };

  const onSave = async () => {
    if (scenario) {
      // TODO: handle error
      await updateScenarioMutation({
        id: scenario.id,
        dealId: scenario.dealId,
        parameters: dcfParams,
      });
    } else {
      dispatch(setShowCreateDealModal(true));
    }
  };

  const cashFlows = calcCashFlows(dcfParams);
  const returnMetrics = calcReturnMetrics(cashFlows, dcfParams);
  const { stabilizedYield, unleveredEquityMultiple, unleveredIrr } = returnMetrics;

  const marketRent = meanBy(units, 'marketRent');
  const capExBudget = sum(cashFlows.capital.followOnCapitalExpenses) * -1;

  const returnMetricStats = [
    {
      label: 'Yield',
      value: stabilizedYield,
      formatter: (value) => formatPercentage(value, 2),
    },
    {
      label: 'IRR',
      value: unleveredIrr,
      formatter: (value) => formatPercentage(value, 1),
    },
    {
      label: 'Equity Multiple',
      value: unleveredEquityMultiple,
      formatter: formatEquityMultiple,
    },
  ];

  let percentOfListLabel = null;
  if (!listPrice) {
    // leave as null
  } else if (listPrice < purchasePrice) {
    percentOfListLabel = `↑${formatPercentage((purchasePrice / listPrice) - 1)} of List`;
  } else if (listPrice > purchasePrice) {
    percentOfListLabel = `↓${formatPercentage(1 - (purchasePrice / listPrice))} of List`;
  }

  return ReactDOM.createPortal(
    <div
      className="absolute flex-col w-128 bg-white overflow-auto cursor-default float-right border-l border-gray-200 shadow-lg z-30"
      style={{
        height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)`,
        right: LAYOUT.rightNavWidth,
        top: LAYOUT.dealHeaderHeight,
      }}
    >
      <div className="w-full flex justify-between items-center mb-6 border-b p-6">
        <div className="flex flex-col">
          <span className="text-xl">Underwriting</span>
        </div>
        <div className="flex items-center gap-x-4">
          <div className="cursor-pointer" onClick={() => dismiss()}><X className="w-6" /></div>
        </div>
      </div>
      <div className="px-6">
        <div className="mb-2 text-xs text-gray-400">{`${scenario?.name || DEFAULT_SCENARIO_NAME} assumptions`}</div>
        <div className="mb-6 grid grid-cols-3 divide-x border rounded shadow">
          {returnMetricStats.map(returnMetricStat => (
            <UnderwritingSimpleStat
              key={returnMetricStat.label}
              className="text-center px-6 py-2"
              {...returnMetricStat}
            />
          ))}
        </div>
        <div className="flex flex-col gap-y-4">
          <InfoField
            label="List Price"
            value={formatCurrency(listPrice)}
            valueClassName="py-1 px-2 text-right"
          />
          <div>
            <FormField
              label="Purchase Price"
              name="purchasePrice"
              type="currency"
              value={purchasePrice}
              onChange={onChange}
            />
            <div className="w-full mt-1 text-right pr-2 text-gray-500 text-sm">{percentOfListLabel}</div>
          </div>
          <FormField
            label="Rent"
            name="marketRent"
            type="currency"
            value={marketRent}
            onChange={onChange}
          />
          <FormField
            label="Cap Ex Budget"
            name="turnBudget"
            type="currency"
            value={capExBudget}
            onChange={onChange}
          />
        </div>
        <div className="mt-6 flex items-center justify-around gap-x-6">
          <Button textOnly disabled={!paramsUnsaved} leadingIcon={<Undo className="mr-4 w-6 text-primary-dark" />} label="Undo" onClick={() => dispatch(resetPendingParams())} />
          <Button filled disabled={!paramsUnsaved} leadingIcon={<Check className="mr-4 w-6" />} label={scenario ? 'Save Changes' : 'Create Deal'} onClick={onSave} isLoading={isLoading} />
        </div>
      </div>
    </div>,
    document.body,
  );
}
