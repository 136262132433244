import { useState } from 'react';
import { range, uniq } from 'lodash';
import { FormField } from '../Form';
import { PERCENT_TYPE } from '../Input';
import { PortfolioItemizedOtherIncomeTable } from '../dcf/ItemizedOtherIncomeTable';
import { arrayFieldOnChangeListener, parseEventValue } from '../utils';

export default function RevenueParameters({ parameters, onChange, canEditPortfolio }) {
  const {
    collectionLossRate,
    holdPeriod,
    incomeItems,
    rentGrowthRate,
    rentGrowthRates,
    vacancyRate,
    rentControlledGrowthRate,
  } = parameters;
  const [annualRentGrowthEntry, setAnnualRentGrowthEntry] = useState(uniq(rentGrowthRates).length > 1);

  // default incomeItems value for portfolios that don't have parameter
  if (!incomeItems) {
    // eslint-disable-next-line no-param-reassign
    parameters.incomeItems = [];
  }

  const onToggleAnnualRates = () => {
    if (annualRentGrowthEntry && (uniq(rentGrowthRates).length > 1)) {
      if (!window.confirm('You will lose the annual rates specified. Are you sure you would like to continue?')) {
        return;
      }

      onChange({
        target: {
          name: 'rentGrowthRate',
          value: rentGrowthRates[0],
        },
      });
    }
    setAnnualRentGrowthEntry(!annualRentGrowthEntry);
  };

  const onChangeRentGrowth = (event) => {
    if (annualRentGrowthEntry) {
      const updatedRates = Array.from(rentGrowthRates);
      updatedRates[0] = parseEventValue(event);
      onChange({
        target: {
          name: 'rentGrowthRates',
          value: updatedRates,
        },
      });
    } else {
      onChange(event);
    }
  };

  return (
    <>
      <FormField
        name="vacancyRate"
        value={vacancyRate}
        type={PERCENT_TYPE}
        min="0"
        max="100"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="collectionLossRate"
        value={collectionLossRate}
        type={PERCENT_TYPE}
        min="0"
        max="100"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        type="checkbox"
        label="Specify Annual Rent Growth Rates"
        value={annualRentGrowthEntry || ''}
        checked={annualRentGrowthEntry}
        onChange={onToggleAnnualRates}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="rentGrowthRate"
        label={annualRentGrowthEntry ? 'Rent Growth Rate Y1' : 'Rent Growth Rate'}
        value={annualRentGrowthEntry ? rentGrowthRates[0] : rentGrowthRate}
        type={PERCENT_TYPE}
        min="0"
        max="100"
        onChange={onChangeRentGrowth}
        disabled={!canEditPortfolio}
      />
      {annualRentGrowthEntry && range(1, holdPeriod + 1).map(year => (
        <FormField
          key={year}
          className="mt-6"
          name="rentGrowthRate"
          label={`Rent Growth Rate Y${year + 1}`}
          value={rentGrowthRates[year]}
          type={PERCENT_TYPE}
          min="0"
          max="100"
          onChange={arrayFieldOnChangeListener(onChange, rentGrowthRates, year)}
          disabled={!canEditPortfolio}
        />
      ))}
      <FormField
        className="mt-6"
        name="rentControlledGrowthRate"
        label="Rent Control Max Growth"
        value={rentControlledGrowthRate}
        type={PERCENT_TYPE}
        min="0"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <PortfolioItemizedOtherIncomeTable
        annualRentGrowthEntry={annualRentGrowthEntry}
        parameters={parameters}
        onChange={onChange}
        setAnnualRentGrowthEntry={setAnnualRentGrowthEntry}
        disabled={!canEditPortfolio}
      />
    </>
  );
}
