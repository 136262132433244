import cx from 'classnames';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useFetchSelfQuery } from 'redux/apiSlice';
import { useFetchHomeModelDealsQuery } from 'redux/homeModelApiSlice';
import ChipGroup from 'components/shared/ChipGroup';
import FilterChip from 'components/shared/newfilters/FilterChip';
import ReactTableColumnFilter from 'components/shared/newfilters/ReactTableColumnFilter';
import ReactTableFilter from 'components/shared/newfilters/ReactTableFilters';
import DataTable from 'components/shared/Table/DataTable';
import { DataTableContent, useFilteredRows } from 'components/shared/Table/DataTableContext';
import { CurrencyCell, DatetimeCell, RouteLinkCell } from 'components/shared/Table/Cells';
import { dataTableMeta } from 'components/shared/Table/table.helpers';
import HomeBuilderFilter from 'components/Import/NewBuild/HomeBuilderFilter';
import SubdivisionFilter from 'components/Import/NewBuild/SubdivisionFilter';
import SubdivisionMarketFilter from 'components/Import/NewBuild/SubdivisionMarketFilter';
import { formatInteger, naturalSortComparator } from 'components/utils';
import ComboboxFilter from 'components/shared/newfilters/ComboboxFilter';
import { LoadingIndicator } from 'components/icons';
import TestDealFilterChip from './TestDealFilterChip';


const TABLE_INITIAL_STATE = {
  columnFilters: [{ id: 'isNhimbleDeal', value: false }],
  columnVisibility: { isNhimbleDeal: false },
};

const columnHelper = createColumnHelper();

/**
  filterFn for matching the field value against the "name" attribute of the
  selected filter. Used when the filter values are objects
  e.g. the filter is a HomeBuilder object, and the row field value is just the HomeBuilder name
  @type {(parentColumn: string) => import('@tanstack/react-table').FilterFn}
*/
const createFieldNameMatchFilter = (row, columnId, filterValue) => (
  !filterValue || (row.getValue(columnId) === filterValue.name)
);

const createColumns = currentUser => ([
  columnHelper.display({ id: 'dealLink', cell: RouteLinkCell, meta: { linkPath: '/deals/:id' } }),
  columnHelper.accessor('name', { header: 'Name' }),
  columnHelper.accessor(deal => deal.organization.name, {
    id: 'organization',
    header: 'Organization',
    filterFn: 'equals',
  }),
  columnHelper.accessor(deal => deal.homeModels[0].homeBuilderName, {
    id: 'homeBuilder',
    header: 'Builder',
    filterFn: createFieldNameMatchFilter,
  }),
  columnHelper.accessor(deal => deal.homeModels[0].market, {
    id: 'market',
    header: 'Market',
    filterFn: 'equals',
  }),
  columnHelper.accessor(deal => deal.homeModels[0].subdivisionName, {
    id: 'subdivision',
    header: 'Subdivision',
    filterFn: createFieldNameMatchFilter,
  }),
  columnHelper.accessor('stage', {
    header: 'Stage',
    filterFn: 'equals',
  }),
  columnHelper.accessor('newBuildQuantity', {
    header: 'Quantity',
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor(deal => deal.primaryScenario.parameters.purchasePrice, {
    header: 'Offer Price',
    cell: CurrencyCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('updatedAt', {
    header: 'Updated At',
    cell: DatetimeCell,
    sortDescFirst: true,
  }),
  columnHelper.accessor(({ organizationId }) => organizationId === currentUser.organizationId, {
    id: 'isNhimbleDeal',
  }),
]);

function OfferTable({ children, currentUser }) {
  const { currentData, isFetching, isUninitialized } = useFetchHomeModelDealsQuery();
  const isLoading = (isUninitialized || isFetching) && !currentData;

  const columns = useMemo(() => createColumns(currentUser), [currentUser]);

  return (
    <DataTable
      columns={columns}
      data={currentData}
      isLoading={isLoading}
      initialState={TABLE_INITIAL_STATE}
    >
      {children}
    </DataTable>
  );
}

const comparator = naturalSortComparator();

const selectDealStages = ({ currentData, isFetching, isUninitialized }) => {
  const sortedData = currentData?.map(({ stage }) => stage);
  if (sortedData?.length) {
    sortedData.sort((left, right) => comparator(left, right));
  }

  return {
    currentData: sortedData ? uniq(sortedData) : null,
    isFetching: isFetching || isUninitialized,
  };
};

const selectOrganization = ({ currentData, isFetching, isUninitialized }) => {
  const sortedData = currentData?.map(({ organization: { name } }) => name);
  if (sortedData?.length) {
    sortedData.sort((left, right) => comparator(left, right));
  }

  return {
    currentData: sortedData ? uniq(sortedData) : null,
    isFetching: isFetching || isUninitialized,
  };
};

function DealStageFilter() {
  const { currentData, isFetching } = useFetchHomeModelDealsQuery(undefined, { selectFromResult: selectDealStages });

  return (
    <ComboboxFilter
      isLoading={isFetching}
      options={currentData}
    />
  );
}

function OrganizationFilter() {
  const { currentData, isFetching } = useFetchHomeModelDealsQuery(undefined, { selectFromResult: selectOrganization });

  return (
    <ComboboxFilter
      isLoading={isFetching}
      options={currentData}
    />
  );
}

const calculateStats = data => [{
  label: '# of Deals',
  value: formatInteger(data.length),
}];

function StatItem({ label, value }) {
  return (
    <div className="px-4 text-center">
      <div className="text-lg">{value || '-'}</div>
      <div className="text-xs font-light">{label}</div>
    </div>
  );
}

function SummaryStats({ className }) {
  const rows = useFilteredRows();
  const stats = useMemo(() => (rows ? calculateStats(rows.map(d => d.original)) : []), [rows]);

  return (
    <div className={cx('flex gap-x-4', className)}>
      {stats.map(stat => (
        <StatItem key={stat.label} {...stat} />
      ))}
    </div>
  );
}

export default function NewBuildOffers() {
  const { data: currentUser, isLoading } = useFetchSelfQuery();
  if (isLoading) {
    return <LoadingIndicator className="size-6 mx-auto" />;
  }

  return (
    <div className="px-6 py-8 bg-white h-full">
      <h1 className="text-2xl mb-8">Offers</h1>
      <OfferTable currentUser={currentUser}>
        <div className="flex justify-between items-center py-1.5 border-b">
          <div>
            <ReactTableFilter>
              <ChipGroup>
                <ReactTableColumnFilter
                  label="Organization"
                  columnId="organization"
                  displayValue="name"
                >
                  <FilterChip>
                    <OrganizationFilter />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Builder"
                  columnId="homeBuilder"
                  displayValue="name"
                >
                  <FilterChip>
                    <HomeBuilderFilter />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Market"
                  columnId="market"
                  displayValue="name"
                >
                  <FilterChip>
                    <SubdivisionMarketFilter />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Subdivision"
                  columnId="subdivision"
                  displayValue="name"
                >
                  <FilterChip>
                    <SubdivisionFilter />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Stage"
                  columnId="stage"
                  displayValue="name"
                >
                  <FilterChip>
                    <DealStageFilter />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter columnId="isNhimbleDeal">
                  <TestDealFilterChip />
                </ReactTableColumnFilter>
              </ChipGroup>
            </ReactTableFilter>
          </div>
          <SummaryStats />
        </div>
        <DataTableContent />
      </OfferTable>
    </div>
  );
}
