import { isEmpty, isNil, sum } from 'lodash';
import { calcMonthlyCashFlows } from '../finance';
import { PERCENT_TYPE } from '../Input';

export const ITEMIZED_INPUT_METHOD_EGR = 'percent_effective_gross_revenue';
export const ITEMIZED_INPUT_METHOD_Y1EGR = 'percent_year_1_effective_gross_revenue';
export const ITEMIZED_INPUT_METHOD_DPY = 'dollar_per_year';
export const ITEMIZED_INPUT_METHOD_DPYPU = 'dollar_per_year_per_unit';
export const ITEMIZED_INPUT_METHOD_DPM = 'dollar_per_month';
export const ITEMIZED_INPUT_METHOD_DPMPU = 'dollar_per_month_per_unit';
export const ITEMIZED_INPUT_METHOD_DPSFPY = 'dollar_per_square_foot_per_year';
export const ITEMIZED_INPUT_METHOD_DPSFPM = 'dollar_per_square_foot_per_month';
export const ITEMIZED_INPUT_METHOD_GR = 'percent_gross_rent';
export const ITEMIZED_INPUT_METHOD_Y1GR = 'percent_year_1_gross_rent';
export const ITEMIZED_INPUT_METHOD_DOLLAR = 'dollar_amount';
export const ITEMIZED_INPUT_METHOD_DPU = 'dollar_per_unit';
export const ITEMIZED_INPUT_METHOD_DPSF = 'dollar_per_square_foot';
export const ITEMIZED_INPUT_METHOD_TOTAL_COST = 'percent_total_cost';
export const ITEMIZED_INPUT_METHOD_TOTAL_FOLLOW_ON = 'percent_total_follow_on';
export const ITEMIZED_INPUT_METHOD_PRICE = 'percent_price';
export const ITEMIZED_INPUT_METHOD_EQUITY = 'percent_equity';

export const ITEMIZED_INPUT_METHODS = {
  [ITEMIZED_INPUT_METHOD_EGR]: {
    label: '% EGR',
    inputType: PERCENT_TYPE,
    func: ({ inputValue }, { effectiveGrossRevenue }) => effectiveGrossRevenue.map(egr => inputValue * egr),
    validateParams: (_, { effectiveGrossRevenue }) => !isEmpty(effectiveGrossRevenue),
    specifyGrowthRates: false,
  },
  [ITEMIZED_INPUT_METHOD_Y1EGR]: {
    label: '% Y1 EGR',
    inputType: PERCENT_TYPE,
    func: ({ growthRates, inputValue }, { effectiveGrossRevenue, holdPeriodInMonths }) => {
      const y1Expense = sum(effectiveGrossRevenue.slice(0, 12));
      return calcMonthlyCashFlows((inputValue * y1Expense) / 12, growthRates, holdPeriodInMonths);
    },
    validateParams: (_, { effectiveGrossRevenue }) => !isEmpty(effectiveGrossRevenue),
    specifyGrowthRates: true,
  },
  [ITEMIZED_INPUT_METHOD_DPY]: {
    label: '$ / Year',
    inputType: "number",
    func: ({ growthRates, inputValue }, { holdPeriodInMonths }) => calcMonthlyCashFlows(inputValue / 12, growthRates, holdPeriodInMonths),
    validateParams: () => true,
    specifyGrowthRates: true,
  },
  [ITEMIZED_INPUT_METHOD_DPYPU]: {
    label: '$ / Unit / Year',
    inputType: "number",
    func: ({ growthRates, inputValue }, { holdPeriodInMonths, numberOfUnits }) => (
      calcMonthlyCashFlows((inputValue * numberOfUnits) / 12, growthRates, holdPeriodInMonths)
    ),
    validateParams: () => true,
    specifyGrowthRates: true,
  },
  [ITEMIZED_INPUT_METHOD_DPM]: {
    label: '$ / Month',
    inputType: "number",
    func: ({ growthRates, inputValue }, { holdPeriodInMonths }) => calcMonthlyCashFlows(inputValue, growthRates, holdPeriodInMonths),
    validateParams: () => true,
    specifyGrowthRates: true,
  },
  [ITEMIZED_INPUT_METHOD_DPMPU]: {
    label: '$ / Unit / Month',
    inputType: "number",
    func: ({ growthRates, inputValue }, { holdPeriodInMonths, numberOfUnits }) => (
      calcMonthlyCashFlows(inputValue * numberOfUnits, growthRates, holdPeriodInMonths)
    ),
    validateParams: () => true,
    specifyGrowthRates: true,
  },
  [ITEMIZED_INPUT_METHOD_DPSFPY]: {
    label: '$ / Sq Ft / Year',
    inputType: "number",
    func: ({ growthRates, inputValue }, { holdPeriodInMonths, rsf }) => (
      calcMonthlyCashFlows((inputValue * rsf) / 12, growthRates, holdPeriodInMonths)
    ),
    validateParams: (_, { rsf }) => !isNil(rsf),
    specifyGrowthRates: true,
  },
  [ITEMIZED_INPUT_METHOD_DPSFPM]: {
    label: '$ / Sq Ft / Month',
    inputType: "number",
    func: ({ growthRates, inputValue }, { holdPeriodInMonths, rsf }) => (
      calcMonthlyCashFlows(inputValue * rsf, growthRates, holdPeriodInMonths)
    ),
    validateParams: (_, { rsf }) => !isNil(rsf),
    specifyGrowthRates: true,
  },
  [ITEMIZED_INPUT_METHOD_GR]: {
    label: '% Gross Rent',
    inputType: PERCENT_TYPE,
    func: ({ inputValue }, { grossRent }) => grossRent.map(gr => inputValue * gr),
    validateParams: (_, { grossRent }) => !isEmpty(grossRent),
    specifyGrowthRates: false,
  },
  [ITEMIZED_INPUT_METHOD_Y1GR]: {
    label: '% Y1 Gross Rent',
    inputType: PERCENT_TYPE,
    func: ({ growthRates, inputValue }, { grossRent, holdPeriodInMonths }) => {
      const y1Expense = sum(grossRent.slice(0, 12));
      return calcMonthlyCashFlows((inputValue * y1Expense) / 12, growthRates, holdPeriodInMonths);
    },
    validateParams: (_, { grossRent }) => !isEmpty(grossRent),
    specifyGrowthRates: true,
  },
  [ITEMIZED_INPUT_METHOD_DOLLAR]: {
    label: '$',
    inputType: "number",
    func: ({ inputValue }, { quantity }) => inputValue * quantity,
    validateParams: () => true,
    specifyGrowthRates: false,
  },
  [ITEMIZED_INPUT_METHOD_DPU]: {
    label: '$ / Unit',
    inputType: "number",
    func: ({ inputValue }, { numberOfUnits }) => inputValue * numberOfUnits,
    validateParams: () => true,
    specifyGrowthRates: false,
  },
  [ITEMIZED_INPUT_METHOD_DPSF]: {
    label: '$ / Sq Ft',
    inputType: "number",
    func: ({ inputValue }, { rsf }) => inputValue * rsf,
    validateParams: () => true,
    specifyGrowthRates: false,
  },
  [ITEMIZED_INPUT_METHOD_TOTAL_COST]: {
    label: '% Total Project Costs',
    inputType: PERCENT_TYPE,
    func: ({ inputValue }, { totalCapitalExpense }) => inputValue * totalCapitalExpense,
    validateParams: () => true,
    specifyGrowthRates: false,
  },
  [ITEMIZED_INPUT_METHOD_TOTAL_FOLLOW_ON]: {
    label: '% Total Follow-On Funded Costs',
    inputType: PERCENT_TYPE,
    func: ({ inputValue }, { totalFollowOnCapitalExpense }) => inputValue * totalFollowOnCapitalExpense,
    validateParams: () => true,
    specifyGrowthRates: false,
  },
  [ITEMIZED_INPUT_METHOD_PRICE]: {
    label: '% Purchase',
    inputType: PERCENT_TYPE,
    func: ({ inputValue }, { price }) => inputValue * price,
    validateParams: () => true,
    specifyGrowthRates: false,
  },
  [ITEMIZED_INPUT_METHOD_EQUITY]: {
    label: '% of Total Equity (Per Year)',
    inputType: PERCENT_TYPE,
    func: ({ inputValue }, { totalEquity, effectiveGrossRevenue }) => effectiveGrossRevenue.map(() => (totalEquity * inputValue) / 12),
    validateParams: () => true,
    specifyGrowthRates: false,
  },
};
