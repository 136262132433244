/* eslint-disable no-param-reassign */
import {
  cloneDeep,
  flatten,
  groupBy,
  last,
  map,
  parseInt,
  partial,
  partialRight,
  range,
  sortBy,
  sum,
  sumBy,
} from 'lodash';
import { ToggleWithLabels } from 'components/Toggle';
import Alert from 'components/Alert';
import { TH, TR } from '../../Table';
import { ASSESSED_VALUE_AS_PERCENT, assignAssessedValuesFromPercentIncreases, assessedValuePercentIncreases, assessedAsPercent, totalTaxesOwed } from '../tax';
import {
  dateFromString,
  formatCurrency,
  formatPercentage,
  parseEventValue,
} from '../../utils';
import Input, { PERCENT_TYPE } from '../../Input';
import { isItemized } from '../dcf';

const PERCENT_DIGITS = 3;
const PURCHASE_PERCENT_INPUT_NAME = 'purchasePercent';
const EXIT_PERCENT_INPUT_NAME = 'exitPercent';
const ASSESSED_VALUE_PERCENT_INCREASE_INPUT_NAME = 'assessedValuePercentIncrease';
const MARKET_VALUE_TOTAL_FIELD_NAME = 'marketValueTotal';

function HeaderRow({ futureTaxParameters, historicalTaxes, label }) {
  return (
    <TR
      label={label}
      data={new Array(historicalTaxes.length + futureTaxParameters.length).fill(null)}
      tdClassName="bg-gray-50 font-semibold"
    />
  );
}

function ContentRow({
  calc,
  editable,
  label,
  field,
  formatter,
  futureTaxParameters,
  inputType = 'number',
  historicalTaxes,
  onChange,
  onlyHistorical,
}) {
  const historicalValues = historicalTaxes.map(parameters => ((calc && !parameters.placeholder) ? calc(parameters) : parameters[field]));
  const futureValues = futureTaxParameters.map((annualParameters, index) => {
    if (onlyHistorical) {
      return null;
    }
    const value = calc ? calc(annualParameters) : annualParameters[field];

    return editable ? (
      <Input
        name={field}
        value={value || 0}
        type={inputType}
        width="w-28"
        onChange={partial(onChange, index)}
      />
    ) : (
      value
    );
  });
  const data = [...historicalValues, ...futureValues];

  return <TR label={label} data={data} formatter={formatter} />;
}

function AssessedPercentIncreaseRow({ assessedValue, purchasePrice, editable, futureTaxParameters, historicalTaxes, onChange }) {
  // IF: User has the “Align Assessed Value to Purchase / Exit” toggle set to “off” (i.e. user wants to use the most recent market_value_total)
  // AND: The “Assessor Total Market Value” in the ‘real_estate_taxes’ table (field name: 'market_value_total') = 0 or ‘null’
  // THEN: Use the Purchase Price (i.e. 100% of the Purchase Price) as the Year 1 “Total Market Value” (market_value_total), and grow that by the “tax growth rate” on a go forward basis for Years 2+
  const mostRecentMarketValue = assessedValue === 0 ? purchasePrice : historicalTaxes.filter(parameters => parameters.marketValueTotal)?.at(-1)?.marketValueTotal;
  const data = [
    ...new Array(historicalTaxes.length),
    ...futureTaxParameters.map((taxParameters, index) => {
      if (index === 0) {
        return (taxParameters.marketValueTotal - mostRecentMarketValue) / mostRecentMarketValue;
      }
      const prevMarketValueTotal = futureTaxParameters[index - 1].marketValueTotal;
      const percentIncreaseValue = (taxParameters.marketValueTotal - prevMarketValueTotal) / prevMarketValueTotal;
      return (editable && index !== (futureTaxParameters.length - 1)) ? (
        <Input
          name={ASSESSED_VALUE_PERCENT_INCREASE_INPUT_NAME}
          value={percentIncreaseValue}
          width="w-28"
          type={PERCENT_TYPE}
          onChange={partial(onChange, index)}
        />
      ) : percentIncreaseValue;
    }),
  ];

  return (
    <TR
      label="% Increase"
      data={data}
      formatter={partialRight(formatPercentage, PERCENT_DIGITS)}
    />
  );
}

function PurchaseExitRow({ exitPrice, futureTaxParameters, historicalTaxes, holdPeriod, purchasePrice }) {
  const purchaseSaleInSameYear = false;
  if (purchaseSaleInSameYear) {
    const purchaseData = new Array(historicalTaxes.length + futureTaxParameters.length).fill(null);
    purchaseData[historicalTaxes.length] = purchasePrice;
    const exitData = [...purchaseData];
    exitData[historicalTaxes.length] = exitPrice;

    return (
      <>
        <TR label="Purchase" data={purchaseData} formatter={formatCurrency} />
        <TR label="Exit" data={exitData} formatter={formatCurrency} />
      </>
    );
  } else {
    const data = new Array(historicalTaxes.length + futureTaxParameters.length).fill(null);
    data[historicalTaxes.length] = purchasePrice;
    data[data.length - (holdPeriod.length === futureTaxParameters ? 1 : 2)] = exitPrice;

    return (
      <TR label="Price" data={data} formatter={formatCurrency} />
    );
  }
}

function AssessedPercentRow({ editable, exitPrice, historicalTaxes, purchasePrice, futureTaxParameters, onChange }) {
  const purchasePercent = futureTaxParameters[0].marketValueTotal / purchasePrice;
  const exitPercent = futureTaxParameters.at(-1).marketValueTotal / exitPrice;

  const data = new Array(historicalTaxes.length + futureTaxParameters.length).fill(null);

  if (editable) {
    data[historicalTaxes.length] = (
      <Input
        name={PURCHASE_PERCENT_INPUT_NAME}
        value={purchasePercent}
        width="w-28"
        type={PERCENT_TYPE}
        onChange={partial(onChange, null)}
      />
    );
    data[data.length - 1] = (
      <Input
        name={EXIT_PERCENT_INPUT_NAME}
        value={exitPercent}
        width="w-28"
        type={PERCENT_TYPE}
        onChange={partial(onChange, null)}
      />
    );
  } else {
    data[historicalTaxes.length] = purchasePercent;
    data[data.length - 1] = exitPercent;
  }

  return (
    <TR label="Assessed % of Price" data={data} formatter={partialRight(formatPercentage, 2)} />
  );
}

export default function AnnualTaxes({
  assessorSiteUrl,
  cashFlows,
  closingDate,
  dcfParams,
  exitPrice,
  futureTaxParameters,
  holdPeriod,
  onChange,
  purchasePrice,
  taxes,
  setActiveParameterTab,
}) {
  const { assessedValue } = dcfParams;
  const taxesByFiscalYear = groupBy(taxes, tax => [tax.fiscalYear]);
  const combinedTaxes = flatten(map(taxesByFiscalYear, (tax) => {
    // take first tax as sample tax so that we can have full attributes/field of tax
    // and do the sum up

    const firstTax = tax[0];
    const summedBilled = sum(tax.map(t => parseInt(t.billed)));
    return {
      ...firstTax,
      assessedValueImprovements: sumBy(tax, 'assessedValueImprovements'),
      assessedValueLand: sumBy(tax, 'assessedValueLand'),
      assessedValueTotal: sumBy(tax, 'assessedValueTotal'),
      marketValueImprovements: sumBy(tax, 'marketValueImprovements'),
      marketValueLand: sumBy(tax, 'marketValueLand'),
      marketValueTotal: sumBy(tax, 'marketValueTotal'),
      billedRate: summedBilled / sumBy(tax, 'marketValueTotal'),
      billed: String(summedBilled),
    };
  }));

  const percentEntry = assessedAsPercent(dcfParams);
  const closingYear = dateFromString(closingDate).getFullYear();
  const historicalTaxes = sortBy(combinedTaxes, 'fiscalYear');
  if (historicalTaxes.length) {
    const mostRecentTaxDateYear = historicalTaxes.at(-1).fiscalYear;
    // fill in tax placeholder for missing years
    range(mostRecentTaxDateYear + 1, closingYear).forEach(year => {
      historicalTaxes.push({ fiscalYear: year, placeholder: true });
    });
  }

  const onTaxItemChange = (index, event) => {
    const updatedTaxItems = cloneDeep(futureTaxParameters);
    const field = event.target.name;
    const newValue = parseEventValue(event);
    if (field === MARKET_VALUE_TOTAL_FIELD_NAME) {
      updatedTaxItems.forEach((updatedTaxItem, taxItemIndex) => {
        if (taxItemIndex >= index) {
          updatedTaxItem[field] = newValue;
        }
      });
    } else if (field === ASSESSED_VALUE_PERCENT_INCREASE_INPUT_NAME) {
      const percentIncreases = assessedValuePercentIncreases(updatedTaxItems);
      percentIncreases[index] = newValue;
      assignAssessedValuesFromPercentIncreases(updatedTaxItems, percentIncreases);
    } else if (field === PURCHASE_PERCENT_INPUT_NAME) {
      const prevValues = updatedTaxItems.map(element => element.marketValueTotal);
      const percentageChangeArray = prevValues.map((value, i) => {
        if (i === 0) {
          return null;
        }
        const prevVal = prevValues[i - 1];
        return ((value - prevVal) / prevVal);
      }).filter(n => n !== undefined);

      updatedTaxItems.forEach(((parameter, idx) => {
        if (idx === 0) {
          parameter.marketValueTotal = newValue * purchasePrice;
        } else if (idx !== (updatedTaxItems.length - 1)) {
          const previousAssessedValue = updatedTaxItems[idx - 1].marketValueTotal;
          const percentageChange = percentageChangeArray[idx - 1];
          parameter.marketValueTotal = (previousAssessedValue * (1 + percentageChange));
        }
      }));
      assignAssessedValuesFromPercentIncreases(updatedTaxItems);
    } else if (field === EXIT_PERCENT_INPUT_NAME) {
      onChange({
        target: {
          name: 'assessedValueExitPercent',
          value: newValue,
        },
      });
      return;
    } else {
      updatedTaxItems[index][field] = newValue;
    }
    onChange({
      target: {
        name: 'futureTaxParameters',
        value: updatedTaxItems,
      },
    });
  };

  // this is kind of a hack to allow the toggle to change two DCF parameter fields in one event
  // which is not currently supported by the Model onChange architecture
  const togglePercentEntry = () => {
    if (percentEntry) {
      onChange({
        target: {
          name: ASSESSED_VALUE_AS_PERCENT,
          value: [null, null],
        },
      });
    } else {
      const currentAssessedPurchasePercent = futureTaxParameters[0][MARKET_VALUE_TOTAL_FIELD_NAME] / purchasePrice;
      const currentAssessedExitPercent = last(futureTaxParameters)[MARKET_VALUE_TOTAL_FIELD_NAME] / last(cashFlows.sale.price);
      onChange({
        target: {
          name: ASSESSED_VALUE_AS_PERCENT,
          value: [currentAssessedPurchasePercent, currentAssessedExitPercent],
        },
      });
    }
  };

  return (
    <>
      {!isItemized(dcfParams) && (
        <div className="absolute z-10 w-full h-full inset-0 bg-black bg-opacity-50 rounded">
          <Alert type="warning">
            Enable
            <button
              className="px-1.5 underline text-primary-500 hover:text-primary-400 cursor-pointer"
              onClick={() => setActiveParameterTab('expenses')}
              type="button"
            >
              itemized expenses
            </button>
            to allow customizing taxes
          </Alert>
        </div>
      )}
      <ToggleWithLabels
        className="ml-0 mb-4"
        label1="Input as $"
        label2="Input as %"
        checked={percentEntry}
        onClick={togglePercentEntry}
      />
      <section className="overflow-auto w-full">
        <table className="min-w-full divide-y divide-gray-200 bg-white">
          <thead className="bg-gray-50 border-b-2">
            <tr>
              <TH value="Calendar Year" />
              {historicalTaxes.map(taxParameters => (
                <TH key={taxParameters.fiscalYear} value={taxParameters.fiscalYear} />
              ))}
              {futureTaxParameters.map((_, index) => (
                <TH key={index} value={closingYear + index} />
              ))}
            </tr>
          </thead>
          <tbody>
            <HeaderRow
              futureTaxParameters={futureTaxParameters}
              historicalTaxes={historicalTaxes}
              label="Purchase / Exit"
            />
            <PurchaseExitRow
              exitPrice={exitPrice}
              futureTaxParameters={futureTaxParameters}
              historicalTaxes={historicalTaxes}
              holdPeriod={holdPeriod}
              purchasePrice={purchasePrice}
              dcfParams={dcfParams}
            />
            <AssessedPercentRow
              editable={percentEntry}
              exitPrice={exitPrice}
              historicalTaxes={historicalTaxes}
              futureTaxParameters={futureTaxParameters}
              onChange={onTaxItemChange}
              purchasePrice={purchasePrice}
            />
            <HeaderRow
              futureTaxParameters={futureTaxParameters}
              historicalTaxes={historicalTaxes}
              label="Market Value"
            />
            <ContentRow
              label="Market Value - Land"
              field="marketValueLand"
              onlyHistorical
              formatter={formatCurrency}
              futureTaxParameters={futureTaxParameters}
              historicalTaxes={historicalTaxes}
            />
            <ContentRow
              label="Market Value - Improvements"
              field="marketValueImprovements"
              onlyHistorical
              formatter={formatCurrency}
              futureTaxParameters={futureTaxParameters}
              historicalTaxes={historicalTaxes}
            />
            <ContentRow
              label="Total Taxable Value"
              field="marketValueTotal"
              editable={!percentEntry}
              formatter={formatCurrency}
              futureTaxParameters={futureTaxParameters}
              historicalTaxes={historicalTaxes}
              onChange={onTaxItemChange}
            />
            <AssessedPercentIncreaseRow
              futureTaxParameters={futureTaxParameters}
              historicalTaxes={historicalTaxes}
              editable={percentEntry}
              purchasePrice={purchasePrice}
              assessedValue={assessedValue}
              onChange={onTaxItemChange}
            />
            <HeaderRow
              futureTaxParameters={futureTaxParameters}
              historicalTaxes={historicalTaxes}
              label="Tax Paid"
            />
            <ContentRow
              label="Effective Rate"
              field="billedRate"
              inputType={PERCENT_TYPE}
              editable
              formatter={partialRight(formatPercentage, PERCENT_DIGITS)}
              futureTaxParameters={futureTaxParameters}
              historicalTaxes={historicalTaxes}
              onChange={onTaxItemChange}
            />
            <ContentRow
              label="Net Annual Taxes"
              calc={taxParameters => taxParameters.marketValueTotal * taxParameters.billedRate}
              formatter={formatCurrency}
              futureTaxParameters={futureTaxParameters}
              historicalTaxes={historicalTaxes}
            />
            <ContentRow
              label="Supplemental Assessments"
              field="specialAssessment"
              editable
              formatter={formatCurrency}
              futureTaxParameters={futureTaxParameters}
              historicalTaxes={historicalTaxes}
              onChange={onTaxItemChange}
            />
            <ContentRow
              label="Total Taxes Owed"
              calc={totalTaxesOwed}
              formatter={formatCurrency}
              futureTaxParameters={futureTaxParameters}
              historicalTaxes={historicalTaxes}
            />
            <ContentRow
              label="All-In Effective Rate"
              calc={taxParameters => totalTaxesOwed(taxParameters) / taxParameters.marketValueTotal}
              formatter={partialRight(formatPercentage, PERCENT_DIGITS)}
              futureTaxParameters={futureTaxParameters}
              historicalTaxes={historicalTaxes}
            />
          </tbody>
        </table>
      </section>
      {assessorSiteUrl && (
        <div className="mt-2">
          <a className="underline text-sm text-primary-dark pb-2" href={assessorSiteUrl} target="_blank" rel="noreferrer">Assessor Site</a>
        </div>
      )}
    </>
  );
}
