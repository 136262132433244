import { useState } from 'react';
import Button from 'components/shared/NewButton';
import { Chevron } from 'components/icons';
import cx from 'classnames';
import Alert from 'components/Alert';
import EmailTemplateEditor from 'components/Features/EmailTemplate/EmailTemplateEditor';
import { isNull } from 'lodash';
import TemplateList from './TemplateList';
import LoadingOverlay from './LoadingOverlay';
import useEmailTemplateManager from './useEmailTemplateManager';
import SendEmailForm from './SendEmailForm';

function Placeholder({ onComposeNew }) {
  return (
    <div className="flex flex-col items-center justify-center h-full p-6 bg-gray-50 border rounded-md text-center">
      <p className="text-lg font-medium text-gray-700 mb-4">
        No email template selected.
      </p>
      <p className="text-sm text-gray-600 mb-6">
        Select an email template from the list or compose a new one.
      </p>
      <Button
        filled
        label="Compose New Email"
        onClick={onComposeNew}
        className="text-sm px-4 py-2"
      />
    </div>
  );
}

function SidePanelHeader({ onComposeNew }) {
  return (
    <div className="flex justify-between items-center px-6 py-4 bg-gray-50 border-b">
      <h3 className="text-xl font-bold text-gray-800">Email Templates</h3>
      <Button
        filled
        label="Compose New Email"
        onClick={onComposeNew}
        className="text-sm px-4 py-2"
      />
    </div>
  );
}

function CollapseButton({ isCollapsed, toggleCollapse }) {
  return (
    <button
      type="button"
      onClick={toggleCollapse}
      className="flex items-center justify-center p-2 border-t bg-gray-100 hover:bg-gray-200 focus:outline-none mt-auto" // Added mt-auto
      aria-label={isCollapsed ? 'Expand Email Templates' : 'Collapse Email Templates'}
    >
      <Chevron direction={isCollapsed ? 'right' : 'left'} className="w-4 text-gray-600" />
    </button>
  );
}

function EmailForm({ currentUser, task = {} }) {
  const {
    emailTemplates,
    selectedTemplate,
    alert,
    isSaving,
    placeholders,
    handleComposeNew,
    handleSelectTemplate,
    handleSaveTemplate,
    handleSendEmail,
    isSendingEmail,
  } = useEmailTemplateManager(task);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSendEmailView, setIsSendEmailView] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const handleSendEmailClick = () => {
    setIsSendEmailView(true);
  };

  const handleBackToTemplate = () => {
    setIsSendEmailView(false);
  };

  return (
    <div className="flex h-auto relative">
      {/* Side Panel */}
      <div
        className={cx(
          'flex-shrink-0 bg-white border-r transition-all duration-300 ease-in-out',
          {
            'w-0 overflow-hidden': isCollapsed,
            'w-2/5 min-w-[300px]': !isCollapsed,
          },
        )}
      >
        <div className="flex flex-col h-full">
          <SidePanelHeader onComposeNew={handleComposeNew} />
          <TemplateList
            templates={emailTemplates}
            selectedTemplate={selectedTemplate}
            onSelect={handleSelectTemplate}
          />
          <CollapseButton isCollapsed={isCollapsed} toggleCollapse={toggleCollapse} />
        </div>
      </div>

      {/* Main Content */}
      <div
        className={cx(
          'flex-1 transition-all duration-300 ease-in-out relative',
          {
            'ml-0': isCollapsed,
            'ml-2/5': !isCollapsed,
          },
        )}
      >
        {/* Show Send email Button if email is saved */}
        {selectedTemplate?.id && (
          <div className="flex justify-end p-4 space-x-2 bg-gray-50 border-b">
            {!isSendEmailView ? (
              <Button
                filled
                label="Send Email"
                onClick={handleSendEmailClick}
                className="text-sm px-4 py-2"
              />
            ) : (
              <Button
                filled
                label="Back to Edit Template"
                onClick={handleBackToTemplate}
                className="text-sm px-4 py-2"
              />
            )}
          </div>
        )}

        {/* Alert Messages */}
        {alert.message && (
          <div className="px-6 py-4">
            <Alert type={alert.type} text={alert.message} />
          </div>
        )}

        {/* Loading Overlays */}
        {isSaving && <LoadingOverlay message="Saving..." />}
        {isSendingEmail && <LoadingOverlay message="Sending Email..." />}

        {/* Email Template Editor */}
        {selectedTemplate && !isSendEmailView && (
          <EmailTemplateEditor
            currentUser={currentUser}
            key={selectedTemplate.id}
            onSave={handleSaveTemplate}
            template={{ ...selectedTemplate, placeholders }}
            width={isCollapsed ? '900px' : '700px'}
          />
        )}

        {/* Send Email Form */}
        {isSendEmailView && (
          <SendEmailForm
            onSend={handleSendEmail}
            isSending={isSendingEmail}
          />
        )}
        {isNull(selectedTemplate) && <Placeholder onComposeNew={handleComposeNew} />}
      </div>

      {/* Expand Button when collapsed */}
      {isCollapsed && (
        <button
          type="button"
          onClick={toggleCollapse}
          className="absolute top-4 left-0 z-10 p-2 rounded-full bg-white border border-gray-300 hover:bg-gray-200 focus:outline-none"
          aria-label="Expand Email Templates"
        >
          <Chevron direction="right" className="w-4 text-gray-600" />
        </button>
      )}
    </div>
  );
}

export default EmailForm;
