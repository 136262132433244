import { isNumber } from 'lodash';

const MARKET_VALUE_TOTAL_FIELD_NAME = 'marketValueTotal';
export const ASSESSED_VALUE_AS_PERCENT = 'assessedValuePercents';

export const totalTaxesOwed = taxParameters => taxParameters.marketValueTotal * taxParameters.billedRate + (taxParameters.specialAssessment || 0);

export const assessedValuePercentIncreases = taxParameters => taxParameters.map((item, index) => {
  if (index === 0) {
    return null;
  }
  return (item[MARKET_VALUE_TOTAL_FIELD_NAME] / taxParameters[index - 1][MARKET_VALUE_TOTAL_FIELD_NAME]) - 1;
});

export const assignAssessedValuesFromPercentIncreases = (taxParameters, percentIncreases) => taxParameters.slice(1).forEach((item, idx) => {
  if (percentIncreases == null) {
    // eslint-disable-next-line no-param-reassign
    percentIncreases = assessedValuePercentIncreases(taxParameters);
  }
  // eslint-disable-next-line no-param-reassign
  item[MARKET_VALUE_TOTAL_FIELD_NAME] = taxParameters[idx][MARKET_VALUE_TOTAL_FIELD_NAME] * (1 + percentIncreases[idx + 1]);
});

export const assessedAsPercent = dcfParams => isNumber(dcfParams.assessedValuePurchasePercent) && isNumber(dcfParams.assessedValueExitPercent);
