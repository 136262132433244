import { newModel } from 'actions/model';
import { formatCurrency, formatInteger, formatNone, formatPercentage } from 'components/utils';
import { compact, forEach, isNaN, isNil, map, mean, orderBy, round, size, uniq } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const BASE_PORTFOLIO_SCENARIO = 'Base Portfolio';
const MULTI_FAMILY_ROW_HEADER = ['', 'BEDROOMS', 'BATHROOMS', 'RSF', 'COUNT', '', '$', '$PSF', '%DIFF', '', '$', '$PSF', '%DIFF'];
const SINGLE_FAMILY_ROW_HEADER = ['', 'BEDROOMS', 'BATHROOMS', 'RSF', 'COUNT', '', '$', '$PSF', '%DIFF'];

function RowHeaders({ isSingleFamily, subjectLength }) {
  const headers = (subjectLength === 0) || isSingleFamily ? SINGLE_FAMILY_ROW_HEADER : MULTI_FAMILY_ROW_HEADER;
  return (
    <div className="flex flex-col">
      {headers.map((header, index) => (
        <div
          style={{ width: '107px', height: '53px' }}
          key={`${header}-${index}`}
          className="text-xs text-left pl-4 pt-6 font-medium text-neutral-dark bg-primary-surface border-r border-b border-gray-200"
        >
          {header}
        </div>
      ))}
    </div>
  );
}

const blendedRent = (unitRent) => {
  const { values: { actualRent, currentAskingRent } } = unitRent;
  return actualRent > 0 ? actualRent : currentAskingRent;
};

const summaryData = (selectedUnitRents, rentRolls) => {
  const selectedBedrooms = orderBy(uniq(map(selectedUnitRents, (set) => set.original.numberOfBedrooms)));
  const subjectBedrooms = orderBy(uniq(map(rentRolls, (rr) => rr.bedrooms)));

  // SUBJECT DATA
  const subject = [];
  const marketRent = [];
  const inPlaceRent = [];
  forEach(subjectBedrooms, (subjectBedroom) => {
    const subjectColumn = [];
    const marketRentColumn = [];
    const inPlaceRentColumn = [];

    const avgBathroom = round(mean(rentRolls?.filter((rr) => rr.bedrooms === subjectBedroom).map((rr) => rr.bathrooms)), 2);
    const avgRsf = mean(rentRolls?.filter((rr) => rr.bedrooms === subjectBedroom).map((rr) => rr.rsf));
    const count = size(rentRolls?.filter((rr) => rr.bedrooms === subjectBedroom));
    const avgMarketRent = mean(rentRolls?.filter((rr) => rr.bedrooms === subjectBedroom).map((rr) => rr.marketRent));
    const avgInPlaceRent = mean(rentRolls?.filter((rr) => rr.bedrooms === subjectBedroom).map((rr) => rr.inPlaceRent));
    const marketRentPsf = (avgMarketRent / avgRsf) ? `$${(avgMarketRent / avgRsf).toFixed(2)} PSF` : '-';
    const inPlaceRentPsf = (avgInPlaceRent / avgRsf) ? `$${(avgInPlaceRent / avgRsf).toFixed(2)} PSF` : '-';
    let inPlaceDiff = (avgMarketRent - avgInPlaceRent) / avgInPlaceRent;
    inPlaceDiff = inPlaceDiff ? formatPercentage(inPlaceDiff, 2) : '-';

    if (isNil(subjectBedroom) || count === 0) return;

    subjectColumn.push([subjectBedroom === 0 ? 'studio' : subjectBedroom, formatNone], [avgBathroom, formatNone], [avgRsf, formatInteger], [count, formatInteger]);
    marketRentColumn.push([avgMarketRent, formatCurrency], [marketRentPsf, formatNone], ['-' / avgRsf, formatNone]);
    inPlaceRentColumn.push([avgInPlaceRent, formatCurrency], [inPlaceRentPsf, formatNone], [inPlaceDiff, formatNone]);

    subject.push(subjectColumn);
    marketRent.push(marketRentColumn);
    inPlaceRent.push(inPlaceRentColumn);
  });

  // COMPARABLE SET DATA
  const comparableSet = [];
  const blended = [];
  forEach(selectedBedrooms, (selectedBedroom) => {
    const comparableSetColumn = [];
    const blendedColumn = [];
    const avgBed = round(mean(compact(selectedUnitRents.filter(({ values }) => values.numberOfBedrooms === selectedBedroom).map(({ values }) => values.numberOfBathroomsTotal))), 2);
    const avgRsf = round(mean(compact(selectedUnitRents.filter(({ values }) => values.numberOfBedrooms === selectedBedroom).map(({ values }) => values.unitRsf))), 2);
    const count = size(selectedUnitRents.filter(({ values }) => values.numberOfBedrooms === selectedBedroom));
    const avgBlendedRent = round(mean(compact(selectedUnitRents.filter(({ values }) => values.numberOfBedrooms === selectedBedroom).map((unitRent) => blendedRent(unitRent)))), 2);
    const avgMarketRent = round(mean(rentRolls?.filter((rr) => rr.bedrooms === selectedBedroom).map((rr) => rr.marketRent)), 2);
    const blendedPsf = (avgBlendedRent / avgRsf) ? `$${(avgBlendedRent / avgRsf).toFixed(2)} PSF` : '-';
    const diff = (avgBlendedRent - avgMarketRent) / avgMarketRent;

    comparableSetColumn.push([selectedBedroom === 0 ? 'studio' : selectedBedroom, formatNone], [avgBed, formatNone], [avgRsf, formatInteger], [count, formatInteger]);
    blendedColumn.push([avgBlendedRent, formatCurrency], [blendedPsf, formatNone], [diff, formatPercentage]);

    comparableSet.push(comparableSetColumn);
    blended.push(blendedColumn);
  });

  return [subject, marketRent, inPlaceRent, comparableSet, blended];
};

function TableCell({ cell, cellWidth }) {
  const [value, format] = cell;
  const formattedValue = isNaN(value) || value === 0 ? '-' : format(value);

  return (
    <div style={{ width: `${cellWidth}px`, height: '53px' }} className="pr-4 pt-6 text-right text-xs font-medium text-neutral-dark border-b border-r border-gray-200">
      {formattedValue}
    </div>
  );
}

function TableColumn({ column, columnIndex, cellWidth }) {
  return column.map((cell, cellIndex) => <TableCell key={`table-one-${columnIndex}-${cellIndex}`} cell={cell} cellIndex={cellIndex} columnIndex={columnIndex} cellWidth={cellWidth} />);
}

function MultiFamilySummaryTab({ selectedUnitRents, subject }) {
  const dispatch = useDispatch();
  const { isSingleFamily } = subject;
  const { property_id: portfolioPropertyId } = useParams();
  const { baseParams: model } = useSelector((state) => state.model);
  const { currentItem } = useSelector((state) => state.navigation);

  let listing, id, propertyId, rentRolls;

  // TODO: do not rely on currentItem
  if (currentItem) {
    listing = currentItem?.listing.data.attributes;
    id = listing.id;
    propertyId = portfolioPropertyId || listing.property.id;
  }

  if (model) rentRolls = model?.dcfParams.units;

  useEffect(() => {
    if ((id || propertyId) && !model) {
      dispatch(newModel(id, propertyId));
    }
  }, [id]);

  const scenarioName = model && model.scenario ? model.scenario.name : BASE_PORTFOLIO_SCENARIO;
  const [subjectTable, marketRentTable, inPlaceRentTable, comparableSetTable, blendedTable] = summaryData(selectedUnitRents, rentRolls);
  const subjectCellWidth = subjectTable.length === 1 ? 162 : 81;
  const comparableSetCellWidth = comparableSetTable.length === 1 ? 162 : 81;

  return (
    <div className="flex border-l border-t border-gray-200">
      <RowHeaders isSingleFamily={isSingleFamily} subjectLength={subjectTable.length} />
      {/* Subject */}
      {subjectTable.length > 0 && (
      <div className="flex flex-col">
        <div style={{ height: '53px' }} className="text-neutral-dark text-base pt-4 font-medium bg-primary-surface text-center border-b border-r border-gray-200">
          Subject
        </div>
        <div className="flex">
          {subjectTable.map((column, columnIndex) => (
            <div key={`subjectTable-${columnIndex}`}>
              <TableColumn column={column} columnIndex={columnIndex} cellWidth={subjectCellWidth} />
            </div>
          ))}
        </div>
        <div style={{ height: '53px' }} className="pt-3 text-xs bg-primary-surface text-center border-b border-r border-gray-200 uppercase">
          {scenarioName}
          <br />
          MARKET RENT
        </div>
        <div className="flex">
          {marketRentTable.map((column, columnIndex) => (
            <div key={`marketRentTable-${columnIndex}`}>
              <TableColumn column={column} columnIndex={columnIndex} cellWidth={subjectCellWidth} />
            </div>
          ))}
        </div>
        {!isSingleFamily && (
        <>
          <div style={{ height: '53px' }} className="pt-6 text-xs bg-primary-surface text-center border-b border-r border-gray-200 uppercase">
            IN-PLACE RENT
          </div>

          <div className="flex">
            {inPlaceRentTable.map((column, columnIndex) => (
              <div key={`inPlaceRentTable-${columnIndex}`}>
                <TableColumn column={column} columnIndex={columnIndex} cellWidth={subjectCellWidth} />
              </div>
            ))}
          </div>
        </>
        )}
      </div>
      )}

      {/* Comparable Set */}
      {comparableSetTable.length > 0 && (
      <div className="flex flex-col">
        <div style={{ height: '53px' }} className="text-neutral-dark text-base pt-4 font-medium bg-primary-surface text-center border-b border-r border-gray-200">
          Comparable Set
        </div>
        <div className="flex">
          {comparableSetTable.map((column, columnIndex) => (
            <div key={`comparableSetTable-${columnIndex}`}>
              <TableColumn column={column} columnIndex={columnIndex} cellWidth={comparableSetCellWidth} />
            </div>
          ))}
        </div>
        <div style={{ height: '53px' }} className="pt-6 text-xs bg-primary-surface text-center border-b border-r border-gray-200 uppercase">
          BLENDED COMP RENT
        </div>
        <div className="flex">
          {blendedTable.map((column, columnIndex) => (
            <div key={`blendedTable-${columnIndex}`}>
              <TableColumn column={column} columnIndex={columnIndex} cellWidth={comparableSetCellWidth} />
            </div>
          ))}
        </div>
        <div className="flex-1 border-b border-r border-gray-200" />
      </div>
      )}
    </div>
  );
}

export default MultiFamilySummaryTab;
