import cx from 'classnames';
import { useConfigPresets } from '../DataTableContext';
import ColumnOrder from './ColumnOrder';
import PresetDropdown from './PresetDropdown';
import VisibilityGroups from './VisibilityGroups';

/**
 * @param {string} [className]
 */
export default function DataTableConfigPanelBody({ className }) {
  const multiplePresets = Object.keys(useConfigPresets()).length > 1;

  return (
    <div className={cx('flex flex-col gap-y-1', className)}>
      {multiplePresets && (
        <div className="flex flex-col">
          <div className="text-label-lg text-neutral-dark">Preset</div>
          <PresetDropdown width="w-full" optionsWidth="w-full" />
        </div>
      )}

      {/* the 2 children are equal width to prevent layout shift when data table columns are added/removed from ordering drag-n-drop */}
      <div className="grid grid-flow-col auto-cols-fr gap-x-1">
        <div className="flex flex-col">
          <div className="text-label-lg text-neutral-dark">Column Visibility</div>
          <VisibilityGroups />
        </div>

        <div className="flex flex-col">
          <div className="text-label-lg text-neutral-dark">Column Order</div>
          <ColumnOrder />
        </div>
      </div>
    </div>
  );
}
