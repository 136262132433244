import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addNewDealProperty, removeNewDealProperty, setAddressSearchModalIsOpen, updateNewDealProperty } from 'redux/newDealSlice';
import { v4 as uuidv4 } from 'uuid';
import { Combobox, Transition } from '@headlessui/react';
import { useAddressAutoCompleteQuery, useLazyParcelLookupQuery, useLazyPlaceDetailsQuery } from 'redux/addressApiSlice';
import Button from 'components/shared/NewButton';
import { ExclamationCircle, ExternalLink, LoadingIndicator, X } from 'components/icons';
import Modal from 'components/Modal';
import { parseEventValue } from 'components/utils';

// represents how many characters need to be entered into the input before it initiates the autocomplete
const MIN_INPUT_SEARCH_LENGTH = 6;
const MANUAL_ADDRESS_STRING = 'manual';
export const PARCEL_UNMATCHED_VALUE = 'unmatched';

// the Google AutoComplete API includes ", USA" in all address strings, so hide that as it is superfluous
const stripUsaFromString = (description) => description.replace(', USA', '');

function NewDealPropertyRow({ newDealProperty }) {
  const dispatch = useDispatch();
  const { id, oneLineAddress, parcel } = newDealProperty;

  let parcelMatchIcon;
  if (!parcel) {
    parcelMatchIcon = <LoadingIndicator className="w-6" />;
  } else if (parcel === PARCEL_UNMATCHED_VALUE) {
    parcelMatchIcon = <ExclamationCircle className="w-6 text-warning-500" />;
  } else {
    const { fipsApn } = parcel;
    parcelMatchIcon = (
      <Link className="cursor-pointer" to={`/properties/${fipsApn}/fips_apn`} target="_blank">
        <ExternalLink className="w-6 hover:text-gray-500" />
      </Link>
    );
  }

  const handleRemove = () => { dispatch(removeNewDealProperty(id)); };

  return (
    <div className="py-2 flex items-center justify-between">
      <X className="w-9 pr-3 hover:text-error-500 cursor-pointer" onClick={handleRemove} />
      <div className="grow">{oneLineAddress}</div>
      {parcelMatchIcon}
    </div>
  );
}

const parsePropertyInfoFromParcel = parcel => ({
  address: parcel.address,
  city: parcel.city,
  state: parcel.state,
  zipCode: parcel.zip,
  fipsApn: parcel.fipsApn,
  unitNumber: parcel.unitNumber,
  numberOfUnits: parcel.unitsCount,
  bedrooms: parcel.bedCount,
  bathrooms: parcel.bathCount,
  rsf: parcel.buildingSqFt,
  latitude: parcel.latitude,
  longitude: parcel.longitude,
});

const parsePropertyInfoFromAddressComponents = addressComponents => ({
  address: addressComponents.find(c => c.types.includes('route'))?.shortText,
  city: addressComponents.find(c => c.types.includes('locality'))?.shortText,
  state: addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.shortText,
  zipCode: addressComponents.find(c => c.types.includes('postal_code'))?.shortText,
  unitNumber: null,
});

export default function AddressSearchModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { newDealProperties } = useSelector(state => state.newDeal);
  const [addressSearchString, setAddressSearchString] = useState('');
  // sessionToken is used to reduce billing cost associated with using Google address autocomplete API
  // ref: https://developers.google.com/maps/documentation/places/web-service/autocomplete
  const [sessionToken, setSessionToken] = useState(uuidv4());

  const [lookupParcel] = useLazyParcelLookupQuery();
  const [fetchPlaceDetails] = useLazyPlaceDetailsQuery();
  const { data: autoCompleteResponse } = useAddressAutoCompleteQuery({ input: addressSearchString, sessionToken }, { skip: addressSearchString.length <= MIN_INPUT_SEARCH_LENGTH });
  const { predictions = [] } = autoCompleteResponse || {};

  const onSelectPrediction = async (placeId) => {
    if (placeId === MANUAL_ADDRESS_STRING) {
      // user is selecting the "manual" option and just wants to use their entered address
      dispatch(addNewDealProperty({
        id: addressSearchString,
        oneLineAddress: addressSearchString,
        parcel: PARCEL_UNMATCHED_VALUE,
      }));
      return;
    }
    const placeDetailResponse = await fetchPlaceDetails({ placeId, sessionToken });

    if (placeDetailResponse.isSuccess) {
      const newDealProperty = {
        ...placeDetailResponse.data,
        id: placeDetailResponse.data.formattedAddress,
        oneLineAddress: stripUsaFromString(placeDetailResponse.data.formattedAddress),
        property: parsePropertyInfoFromAddressComponents(placeDetailResponse.data.addressComponents),
      };
      dispatch(addNewDealProperty(newDealProperty));
      setSessionToken(uuidv4());
      setAddressSearchString('');

      const { oneLineAddress, location: { latitude, longitude } } = newDealProperty;
      lookupParcel({ address: oneLineAddress, latitude, longitude }).then(lookupParcelResponse => {
        if (lookupParcelResponse.isSuccess) {
          dispatch(updateNewDealProperty({
            id: newDealProperty.id,
            parcel: lookupParcelResponse.data,
            property: parsePropertyInfoFromParcel(lookupParcelResponse.data),
          }));
        } else if (lookupParcelResponse.error.status === 404) {
          // parcel could not be matched
          dispatch(updateNewDealProperty({
            id: newDealProperty.id,
            parcel: PARCEL_UNMATCHED_VALUE,
          }));
        } else {
          // TODO: handle unknown error
          console.error(lookupParcelResponse);
        }
      }).catch((err) => {
        console.error(err);
      });
    } else {
      // TODO: handle error
      console.error(placeDetailResponse);
    }
  };

  return (
    <Modal show showCloseAction={false}>
      <div className="w-128">
        <h3 className="text-2xl mb-8">Address Search</h3>
        <label className="text-gray-700 font-medium text-sm" htmlFor="addressSearchInput">Address</label>
        <Combobox as="div" className="mb-4" value={addressSearchString} onChange={onSelectPrediction}>
          <Combobox.Input className="w-full h-12 mt-2 py-1 px-2 border" onChange={(e) => setAddressSearchString(parseEventValue(e))} />
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Combobox.Options className="absolute z-10 py-1 mt-1 overflow-auto bg-white rounded-md shadow-lg cursor-pointer">
              {predictions.map((prediction) => (
                <Combobox.Option
                  key={prediction.placeId}
                  value={prediction.placeId}
                  className="text-sm select-none relative py-2 pl-10 pr-4 hover:bg-gray-100 text-gray-900"
                >
                  {stripUsaFromString(prediction.description)}
                </Combobox.Option>
              ))}
              {predictions.length ? (
                <Combobox.Option
                  value={MANUAL_ADDRESS_STRING}
                  className="text-sm select-none relative py-2 pl-10 pr-4 hover:bg-gray-100 text-gray-900 border-t"
                >
                  {addressSearchString}
                </Combobox.Option>
              ) : null}
            </Combobox.Options>
          </Transition>
        </Combobox>
        {newDealProperties.map((newDealProperty, index) => (
          <NewDealPropertyRow key={index} newDealProperty={newDealProperty} />
        ))}
        <div className="mt-12 flex gap-x-2 justify-end">
          <Button
            textOnly
            label="Cancel"
            className="font-medium text-sm"
            onClick={() => dispatch(setAddressSearchModalIsOpen(false))}
          />
          <Button
            filled
            label="Create Deal"
            className="font-medium text-sm"
            onClick={() => {
              dispatch(setAddressSearchModalIsOpen(false));
              return navigate('/deal_sourcing/create_deal');
            }}
            disabled={!newDealProperties.length}
          />
        </div>
      </div>
    </Modal>
  );
}
