/* eslint-disable no-nested-ternary */
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchPropertyManagementRecordIndexQuery } from 'redux/apiSlice';
import { LAYOUT } from 'components/constants';
import { Arrow, LoadingIndicator } from 'components/icons';
import { formatCurrency, formatCurrencyAbbreviated, formatPercentage, titleCase } from 'components/utils';
import Header from 'components/AssetManagement/Header';
import { flatMap, isEmpty, isNil, some, map, uniq } from 'lodash';
import DataTable from 'components/shared/Table/DataTable';
import useElementHeight from 'hooks/useElementHeight';
import Filters from './Filters';
import PropertyMap from './PropertyMap';

const COLUMNS = [{
  accessorKey: 'photoUrl',
  header: '',
  cell: ({ getValue }) => (
    <div className="h-24">
      <img className="h-full w-full object-cover" src={getValue()} loading="lazy" referrerPolicy="no-referrer" alt="Property" />
    </div>
  ),
  meta: { className: 'w-[10%]' },
}, {
  header: 'Address',
  accessorFn: row => row.property?.address,
  cell: ({ row, getValue }) => {
    const { city, state } = row.original.property;
    return (
      <div className="pl-4">
        <div>{titleCase(getValue())}</div>
        <div className="font-light">{`${titleCase(city)}, ${state}`}</div>
      </div>
    );
  },
  sortingFn: 'basic',
  meta: { className: 'w-[20%]' },
}, {
  header: 'Tags',
  accessorKey: 'tags',
  meta: { className: 'w-[10%]' },
}, {
  header: 'Summary',
  accessorFn: row => row.property?.numberOfUnits,
  cell: ({ row, getValue }) => {
    const { propertyBathroomsTotal, propertyBedroomsTotal } = row.original.property;
    if (getValue() > 1) {
      return `${getValue()} Units`;
    } else {
      return `${propertyBedroomsTotal} Bed · ${propertyBathroomsTotal} Bath`;
    }
  },
  meta: { className: 'w-[10%]' },
}, {
  header: 'Rent',
  accessorKey: 'inPlaceRent',
  cell: ({ row, getValue }) => {
    const inPlaceRent = getValue();
    const { marketRent } = row.original;
    const percentDiff = (inPlaceRent - marketRent) / marketRent;
    let diffIndicator;
    if (Math.round(inPlaceRent) === Math.round(marketRent)) {
      diffIndicator = 'At market';
    } else {
      diffIndicator = (
        <div className="flex items-center">
          <Arrow direction={percentDiff > 0 ? 'up' : 'down'} fill={percentDiff > 0 ? '#006D41' : '#BA1A1A'} className="w-4 mr-1" />
          <span>{`${formatPercentage(Math.abs(percentDiff))} market`}</span>
        </div>
      );
    }

    return (
      <div className="text-center">
        <div>{formatCurrency(inPlaceRent)}</div>
        <div className="mt-2 text-xs text-gray-500">{diffIndicator}</div>
      </div>
    );
  },
  meta: { className: 'w-[10%]' },
}, {
  header: 'Occupancy',
  accessorKey: 'occupancyRate',
  cell: ({ getValue }) => <div>{formatPercentage(getValue())}</div>,
  meta: { className: 'w-[10%]' },
}, {
  header: 'OpEx Ratio',
  accessorKey: 'opExRatio',
  cell: ({ getValue }) => <div>{formatPercentage(getValue())}</div>,
  meta: { className: 'w-[10%]' },
}, {
  header: 'Estimated Value',
  accessorKey: 'estimatedValue',
  cell: ({ row, getValue }) => {
    const estimatedValue = getValue();
    if (isNil(estimatedValue)) {
      return '-';
    }
    if (row.original.property.numberOfUnits > 1) {
      return (
        <>
          <div>{formatCurrencyAbbreviated(estimatedValue)}</div>
          <div className="mt-2 text-xs text-gray-500">{formatPercentage(row.original.exitCapRate)}</div>
        </>
      );
    } else {
      return <div>{formatCurrencyAbbreviated(estimatedValue)}</div>;
    }
  },
  meta: { className: 'w-[10%]' },
}, {
  header: 'Yield',
  accessorFn: row => row.returnMetrics.unleveredCashOnCash,
  cell: ({ row, getValue }) => {
    const actualYield = getValue();
    const underwrittenYield = row.original.underwrittenReturnMetrics.unleveredCashOnCash;
    return (
      <div className="text-center">
        <div>{formatPercentage(actualYield)}</div>
        <div className="mt-2 text-xs text-gray-500">{formatPercentage(underwrittenYield)}</div>
      </div>
    );
  },
  meta: { className: 'w-[10%]' },
}];

function PropertyTable({ propertyManagementRecords, setHoveredId }) {
  const navigate = useNavigate();

  const onRowMouseEnter = (row) => {
    setHoveredId(row.original.id);
  };

  const onRowMouseLeave = () => {
    setHoveredId(null);
  };

  const onRowClick = (row) => navigate(`/asset_management/${row.original.id}/summary`);

  const tableContainerRef = useRef();
  const tableHeight = useElementHeight(tableContainerRef);

  return (
    <div
      className="border-t"
      ref={tableContainerRef}
      style={{ height: `calc(100vh - ${LAYOUT.assetManagementHeaderHeight + LAYOUT.assetManagementFilterHeight}px)` }}
    >
      <DataTable
        columns={COLUMNS}
        data={propertyManagementRecords}
        onRowClick={onRowClick}
        onRowMouseEnter={onRowMouseEnter}
        onRowMouseLeave={onRowMouseLeave}
        tableHeight={tableHeight}
      />
    </div>
  );
}

export default function PropertyList() {
  const { data: propertyManagementRecords = [], isLoading } = useFetchPropertyManagementRecordIndexQuery();
  const [filters, setFilters] = useState({ name: '', markets: [], tags: [] });
  const [hoveredId, setHoveredId] = useState(null);
  const uniqueMarkets = map(uniq(map(propertyManagementRecords, ({ property: { market } }) => market)), (market) => ({ label: market, value: market }));
  const uniqueTags = uniq(flatMap(propertyManagementRecords, 'tags')).map(tag => ({ label: tag, value: tag }));
  const nameFilteredRecords = filters.name ? propertyManagementRecords.filter(record => record.property.address.toLowerCase().includes(filters.name.toLowerCase())) : propertyManagementRecords;
  const marketFilteredRecords = filters.markets?.length > 0 ? nameFilteredRecords.filter(record => filters.markets.includes(record.property.market)) : nameFilteredRecords;
  const filteredRecords = filters.tags?.length > 0 ? marketFilteredRecords.filter(record => some(record.tags, tag => filters.tags.includes(tag))) : marketFilteredRecords;

  if (isLoading) {
    return (
      <LoadingIndicator className="w-6 text-primary-500" />
    );
  }

  return (
    <div className="bg-gray-100 h-screen">
      <Header />
      <Filters
        filters={filters}
        propertyManagementRecords={filteredRecords}
        setFilters={setFilters}
        uniqueMarkets={uniqueMarkets}
        uniqueTags={uniqueTags}
      />
      {!isEmpty(filteredRecords) && !isLoading && (
      <div className="grid grid-cols-2">
        <PropertyTable
          filters={filters}
          propertyManagementRecords={filteredRecords}
          setFilters={setFilters}
          setHoveredId={setHoveredId}
        />
        <PropertyMap
          hoveredId={hoveredId}
          propertyManagementRecords={filteredRecords}
        />
      </div>
      )}
      {isEmpty(filteredRecords) && !isLoading && (
      <div className=" mt-12 text-center">
        <div className="text-2xl text-neutral-black">No Records Found</div>
        <div className="text-sm text-neutral-light mt-4">
          {filters.name?.length > 0 ? `No record were found for the search term '${filters.name}'` : 'No record were found for the active filters'}
        </div>
      </div>
      )}
    </div>
  );
}
