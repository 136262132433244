import { parseJSON, startOfDay, startOfToday } from 'date-fns';
import { isEmpty, isMatch, last, snakeCase, sortBy, maxBy } from 'lodash';

export const MILESTONE_STATUS_DEAD = 'DEAD';
export const MILESTONE_STATUS_COMPLETED = 'COMPLETED';
export const MILESTONE_STATUS_OVERDUE = 'OVERDUE';
export const MILESTONE_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const MILESTONE_STATUS_PENDING = 'PENDING';

export const TASK_DATE_FORMAT = 'MMM d';

const milestoneCompletedAt = (milestoneTasks) => {
  if (isEmpty(milestoneTasks) || milestoneTasks.some(task => !task.completedAt)) {
    return null;
  }

  return maxBy(milestoneTasks, 'completedAt')?.completedAt;
};

const milestoneDueDate = (milestoneTasks) => {
  if (isEmpty(milestoneTasks)) {
    return null;
  }

  // only consider incomplete tasks
  return maxBy(milestoneTasks, task => !task.completedAt && task.dueDate)?.dueDate;
};

export const milestoneMetadata = (milestone, tasks, isDead) => {
  const milestoneTasks = tasks.filter(task => task.milestoneId === milestone.id);
  const dueDate = milestoneDueDate(milestoneTasks);
  const completedAt = milestoneCompletedAt(milestoneTasks);
  const overdue = !completedAt && dueDate && (startOfToday() > startOfDay(parseJSON(dueDate)));

  let status;
  if (completedAt) {
    status = MILESTONE_STATUS_COMPLETED;
  } else if (isDead) {
    status = MILESTONE_STATUS_DEAD;
  } else if (overdue) {
    status = MILESTONE_STATUS_OVERDUE;
  } else if (dueDate) {
    status = MILESTONE_STATUS_IN_PROGRESS;
  } else {
    status = MILESTONE_STATUS_PENDING;
  }

  return {
    status,
    dueDate,
    completedAt,
  };
};

export const generateMilestoneSortOrder = (dealWorkflowTemplate) => (
  Object.fromEntries(dealWorkflowTemplate.milestones.map(({ defaultName }, index) => [defaultName, index]))
);

export const generateTaskSortOrder = (dealWorkflowTemplate, milestone) => (
  Object.fromEntries(dealWorkflowTemplate.milestones.find(ms => ms.defaultName === milestone.name).tasks.map(({ taskType }, index) => [taskType, index]))
);

// Find the last on going milestone, or the last milestone if every milestone has been completed
export const findCurrentMilestone = (milestones) => (
  milestones.find(milestone => !milestone.completedAt) ?? last(milestones)
);

export const getNextTask = (deal) => {
  const { dealWorkflowTemplate, milestones, tasks } = deal;
  const milestonesWithMetadata = milestones.map(milestone => ({
    ...milestone,
    ...milestoneMetadata(milestone, tasks, deal.deletedAt),
  }));
  const currentMilestone = findCurrentMilestone(milestonesWithMetadata);
  if (!currentMilestone) return null;
  const taskOrder = generateTaskSortOrder(dealWorkflowTemplate, currentMilestone);
  const milestoneTasks = sortBy(tasks.filter(task => task.milestoneId === currentMilestone.id), ({ taskType }) => taskOrder[taskType]);
  return milestoneTasks.filter(task => !task.completedAt)[0];
};

// The deal is considered closed if there doesn't exist a milestone such that it is not MILESTONE_STATUS_COMPLETED
export const isDealClosed = (milestones) => (
  milestones.every(({ status }) => status === MILESTONE_STATUS_COMPLETED)
);

// transforms the transactionInfo field of a deal to a form compatiable
// with using isMatch with blockers
export const transformTransactionInfo = (transactionInfo) => ({
  fields: Object.entries(transactionInfo).map(pair => ({ name: snakeCase(pair[0]), value: pair[1].value }))
});

export const isTaskBlocked = (tasks, { blockedBy }, transactionInfoFields) => {
  if (isEmpty(blockedBy)) {
    return false;
  }

  return !blockedBy.every((blocker) => tasks.some((task) => isMatch(transactionInfoFields, blocker) || (task.completedAt && isMatch(task, blocker))));
};
