import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { compact } from 'lodash';
import { SelectorIcon, Check } from 'components/icons';

export default function Dropdown({ buttonComponent, className, options, width, height, optionsWidth, placeholder, selectedItem, setSelectedItem, name = '' }) {
  return (
    <div className={`relative ${className || ''}`}>
      <Listbox value={selectedItem ?? null} onChange={setSelectedItem}>
        <Listbox.Button as={buttonComponent ? 'div' : 'button'} className={!buttonComponent ? `relative ${compact([width, height]).join(' ')} py-2 px-4 text-left text-sm bg-white border rounded-md cursor-pointer focus:outline-none` : ''}>
          {buttonComponent || (
            <div className="flex justify-between items-center truncate">
              { selectedItem ? selectedItem.name : (
                <span className="text-gray-400">
                  {placeholder || (
                    <>
                      Select
                      {' '}
                      {name || 'an option...'}
                    </>
                  )}
                </span>
              ) }
              <SelectorIcon />
            </div>
          )}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className={`${optionsWidth} absolute z-10 py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 cursor-pointer focus:outline-none`}>
            {options && options.map((option) => (
              <Listbox.Option
                key={option.id || option.name}
                value={option}
                as={Fragment}
                disabled={option.disabled}
                className="cursor-pointer text-sm select-none relative py-2 pl-10 pr-4 ui-active:bg-gray-100 text-gray-900"
              >
                <div className="flex justify-between items-center">
                  <span
                    className="ui-disabled:opacity-50 ui-disabled:cursor-not-allowed block truncate font-normal"
                  >
                    {option.name}
                  </span>
                  <span className="ui-selected:flex hidden absolute inset-y-0 left-0 items-center pl-3 text-gray-600">
                    <Check className="w-5 h-5" aria-hidden="true" />
                  </span>
                </div>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
}
