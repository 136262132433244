import { cloneDeep, map, partial, pullAt } from 'lodash';
import classNames from 'classnames';
import { TH, TR } from '../Table';
import {
  ACQUISITION_CLOSING_COST_INPUT_METHODS,
  acquisitionCostParams,
  calcAcquisitionCosts,
} from './dcf';
import Input from '../Input';
import Select from '../Select';
import { formatCurrency, formatPercentage, parseEventValue } from '../utils';
import { ITEMIZED_INPUT_METHOD_DOLLAR, ITEMIZED_INPUT_METHOD_PRICE } from './itemizedItem';

function AddCostItemRow({ onClick }) {
  const data = [
    <button
      type="button"
      className="bg-tertiary hover:bg-tertiary-lighter px-2 text-white rounded"
      onClick={onClick}
    >
      Add Cost Item
    </button>,
  ];

  return <TR center tdClassName="py-2" data={data} />;
}

function CostItemRow({
  dcfParams,
  costItem,
  borderBottom,
  onChange,
  onRemove,
  isModelParameter = false,
  disabled,
}) {
  const inputMethod = ACQUISITION_CLOSING_COST_INPUT_METHODS[costItem.inputMethod];

  const label = (
    <>
      <button
        type="button"
        className="bg-tertiary hover:bg-tertiary-lighter mr-2 px-2 text-white rounded"
        onClick={onRemove}
      >
        -
      </button>
      <Input
        name="name"
        value={costItem.name}
        type="text"
        onChange={onChange}
        disabled={disabled}
      />
    </>
  );

  const lineTotal = inputMethod.func(costItem, acquisitionCostParams(dcfParams));
  const formattedLineTotal = (isModelParameter || costItem.inputMethod === ITEMIZED_INPUT_METHOD_DOLLAR) ? formatCurrency(lineTotal) : 'n/a';

  const data = [
    <Select
      name="inputMethod"
      value={costItem.inputMethod}
      options={map(ACQUISITION_CLOSING_COST_INPUT_METHODS, (method, methodKey) => [methodKey, method.label])}
      onChange={onChange}
      width="w-56"
      disabled={disabled}
    />,
    <Input
      name="inputValue"
      value={costItem.inputValue}
      type={inputMethod.inputType}
      min="0"
      onChange={onChange}
      disabled={disabled}
    />,
    <div className="text-sm">{formattedLineTotal}</div>,
  ];

  if (isModelParameter) {
    const percentPurchase = costItem.inputMethod === ITEMIZED_INPUT_METHOD_PRICE ? costItem.inputValue : (lineTotal / dcfParams.purchasePrice);
    data.push(<div className="text-sm">{formatPercentage(percentPurchase)}</div>);
  }

  return <TR data={data} label={label} borderBottom={borderBottom} />;
}

function TotalCostRow({ dcfParams, isModelParameter = false }) {
  const totalAcquisitionCost = calcAcquisitionCosts(dcfParams);
  const data = [
    null,
    null,
    formatCurrency(totalAcquisitionCost),
  ];

  if (isModelParameter) {
    data.push(formatPercentage(totalAcquisitionCost / dcfParams.purchasePrice));
  }

  return <TR label="Total" data={data} />;
}

export default function ItemizedAcquisitionCostTable({
  dcfParams,
  onChange,
  isModelParameter = false,
  disabled
}) {
  const acquisitionCostItems = dcfParams.acquisitionCostItems ?? [];

  const addCostItem = () => {
    const updatedCostItems = cloneDeep(acquisitionCostItems);
    updatedCostItems.push({
      name: '',
      inputValue: 0,
      inputMethod: ITEMIZED_INPUT_METHOD_DOLLAR,
    });
    onChange({
      target: {
        name: 'acquisitionCostItems',
        value: updatedCostItems,
      },
    });
  };

  const onItemChange = (index, event) => {
    const updatedCostItems = cloneDeep(acquisitionCostItems);
    updatedCostItems[index][event.target.name] = parseEventValue(event);
    onChange({
      target: {
        name: 'acquisitionCostItems',
        value: updatedCostItems,
      },
    });
  };

  const onItemRemove = (index) => {
    const updatedCostItems = cloneDeep(acquisitionCostItems);
    pullAt(updatedCostItems, [index]);
    onChange({
      target: {
        name: 'acquisitionCostItems',
        value: updatedCostItems,
      },
    });
  };

  return (
    <table className="min-w-full divide-y divide-gray-200 bg-white">
      <thead className={classNames('border-b-2', { 'bg-gray-50': !isModelParameter })}>
        <tr>
          <TH value="Description" />
          <TH value="Input Methodology" />
          <TH value="Input Value" />
          <TH value="Total" />
          {isModelParameter && <TH value="% Purchase Price" />}
        </tr>
      </thead>
      <tbody>
        {acquisitionCostItems.map((costItem, index) => (
          <CostItemRow
            key={index}
            dcfParams={dcfParams}
            costItem={costItem}
            onChange={partial(onItemChange, index)}
            onRemove={partial(onItemRemove, index)}
            borderBottom={index === acquisitionCostItems.length - 1}
            isModelParameter={isModelParameter}
            disabled={disabled}
          />
        ))}
        <TotalCostRow dcfParams={dcfParams} isModelParameter={isModelParameter} />
        { !disabled && <AddCostItemRow onClick={addCostItem} />}
      </tbody>
    </table>
  );
}
