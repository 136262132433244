import Chip from 'components/shared/Chip';
import { useOnApply } from 'components/shared/newfilters/filterHooks';
import { useNodeState } from 'components/shared/TreeProvider/TreeProvider';

export default function InactiveFilterChip() {
  const [{ filterState: showInactive }] = useNodeState();
  const onApply = useOnApply();
  const label = showInactive ? 'Includes Inactive' : 'Show Inactive';

  return (
    <Chip
      selected={showInactive}
      label={label}
      onClick={() => onApply({ value: !showInactive })}
    />
  );
}
