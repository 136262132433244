/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, flatMap, isEmpty, isNil, merge, omit, pickBy, sortBy, uniq, capitalize } from 'lodash';
import {
  useFetchPortfoliosQuery,
  useFetchSelfQuery,
  useFetchUsersQuery,
  useUpdateDealMutation,
} from 'redux/apiSlice';
import {
  useFetchPipelineQuery,
  useFetchPipelineClosedQuery,
  useFetchPipelineDeadQuery,
  useFetchPipelineStatsQuery,
} from 'redux/pipelineApiSlice';
import { setAddressSearchModalIsOpen } from 'redux/newDealSlice';
import { DEAD_REASONS } from 'components/dashboard/MarkDealAsDeadModal';
import { FormField } from 'components/Form';
import Alert from 'components/Alert';
import Modal from 'components/Modal';
import Button from 'components/shared/NewButton';
import { Search } from 'components/icons';
import EmptyLoadingState from 'components/shared/EmptyLoadingState';
import EditDealModal from 'components/deal/EditDealModal';
import AddressSearchModal from 'components/DealSourcing/AddressSearchModal';
import {
  ALL_DEALS_STAGE,
  DEAL_STATE_CLOSED,
  DEAL_STATE_DEAD,
  INACTIVE_DEAL_STATES,
  LAYOUT,
  TRANSACTION_TYPES,
} from 'components/constants';
import { parseEventValue, snakeCaseKeys, titleCase } from 'components/utils';
import DealTable from 'components/pipeline/DealTable';
import Widgets from 'components/pipeline/Widgets';
import useElementHeight from 'hooks/useElementHeight';
import useLocalStorage from 'hooks/useLocalStorage';
import RadioInput from 'components/shared/RadioInput';
import SubmitTaskResponseModal from 'components/dashboard/TasksTab/SubmitTaskResponseModal';
import EditTransactionInfoModal from 'components/deal/EditTransactionInfoModal';

function MarkDealAsDeadModal({ deal, isLoading, setShowDeadDealConfirmModal, updateDealMutation }) {
  const [deadReason, setDeadReason] = useState(DEAD_REASONS[0][0]);
  const [alert, setAlert] = useState(null);

  const handleUpdateDeal = async () => {
    setAlert(null);
    if (!isLoading) {
      try {
        await updateDealMutation({ ...snakeCaseKeys({ id: deal.id, deletedAt: new Date().toJSON(), deadReason }) }).unwrap();
        setShowDeadDealConfirmModal(false);
      } catch (err) {
        console.error('Failed to mark deal dead: ', err);
        setAlert('Failed to mark deal dead');
      }
    }
  };

  return (
    <Modal show showCloseAction={false}>
      <div className="w-96">
        Are you sure you would like to mark this deal as dead?
        <FormField
          name="dead reason"
          value={deadReason}
          options={DEAD_REASONS.map(r => [r[0], r[1]])}
          type="select"
          padding="py-2 px-3"
          className="mt-6"
          onChange={(e) => setDeadReason(e.target.value)}
        />
        {alert && <Alert className="mt-6" {...alert} />}
        <div className="mt-6 flex gap-x-2 justify-end">
          <Button
            textOnly
            label="Cancel"
            onClick={() => setShowDeadDealConfirmModal(false)}
          />
          <Button
            filled
            label="Continue"
            onClick={handleUpdateDeal}
          />
        </div>
      </div>
    </Modal>
  );
}

function MarkDealAsWithdrawnModal({ deal, isLoading, setShowDeadDealConfirmModal, updateDealMutation }) {
  const [withdrawalReason, setWithdrawalReason] = useState(DEAD_REASONS[0][0]);
  const [withdrawalDate, setWithdrawalDate] = useState(null);
  const [alert, setAlert] = useState(null);

  const handleUpdateDeal = async () => {
    setAlert(null);
    if (!isLoading) {
      try {
        await updateDealMutation({ ...snakeCaseKeys({ id: deal.id, deadReason: withdrawalReason, deletedAt: withdrawalDate }) }).unwrap();
        setShowDeadDealConfirmModal(false);
      } catch (err) {
        console.error('Failed to mark deal withdrawn: ', err);
        setAlert('Failed to mark deal withdrawn');
      }
    }
  };

  return (
    <Modal show showCloseAction={false}>
      <div className="w-96">
        Are you sure you would like to mark this deal as withdrawn?
        <FormField
          name="withdraw reason"
          value={withdrawalReason}
          options={DEAD_REASONS.map(r => [r[0], r[1]])}
          type="select"
          padding="py-2 px-3"
          className="mt-6"
          onChange={(e) => setWithdrawalReason(e.target.value)}
        />
        <FormField
          name="withdraw date"
          value={withdrawalDate}
          type="date"
          padding="py-2 px-3"
          className="mt-6"
          onChange={(e) => setWithdrawalDate(parseEventValue(e))}
        />
        {alert && <Alert className="mt-6" {...alert} />}
        <div className="mt-6 flex gap-x-2 justify-end">
          <Button
            textOnly
            label="Cancel"
            onClick={() => setShowDeadDealConfirmModal(false)}
          />
          <Button
            disabled={!withdrawalDate || !withdrawalReason}
            filled
            label="Continue"
            onClick={handleUpdateDeal}
          />
        </div>
      </div>
    </Modal>
  );
}

const DEFAULT_DEAL_STAGE_CONFIG = {
  [TRANSACTION_TYPES.acquisition]: ALL_DEALS_STAGE,
  [TRANSACTION_TYPES.disposition]: ALL_DEALS_STAGE,
};
const LOCAL_STORAGE_PIPELINE_FILTERS_VERSION = 1;
const LOCAL_STORAGE_PIPELINE_TRANSACTION_TYPE_VERSION = 1;
const LOCAL_STORAGE_PIPELINE_DEAL_STAGE_CONFIG_VERSION = 1;

export default function Pipeline() {
  const dispatch = useDispatch();
  const modal = useSelector(state => state.deals.modal);
  const { deal: editableDeal, showEditDealModal } = useSelector(state => state.navigation.modal);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [showDeadDealConfirmModal, setShowDeadDealConfirmModal] = useState(null);
  const [filters, setFilters] = useLocalStorage('Pipeline.filters', {}, LOCAL_STORAGE_PIPELINE_FILTERS_VERSION);
  const [updateDealMutation, { isLoading: updateDealIsLoading }] = useUpdateDealMutation();
  const [transactionType, setTransactionType] = useLocalStorage('Pipeline.transactionType', TRANSACTION_TYPES.acquisition, LOCAL_STORAGE_PIPELINE_TRANSACTION_TYPE_VERSION);
  const [dealStageConfig, setDealStageConfig] = useLocalStorage('Pipeline.dealStageConfig', DEFAULT_DEAL_STAGE_CONFIG, LOCAL_STORAGE_PIPELINE_DEAL_STAGE_CONFIG_VERSION);
  const setDealStage = (dealStage) => setDealStageConfig(prevDealStageConfig => ({
    ...prevDealStageConfig,
    [transactionType]: dealStage,
  }));
  const dealStage = dealStageConfig[transactionType];
  const { addressSearchModalIsOpen } = useSelector(state => state.newDeal);

  const { data: users = [] } = useFetchUsersQuery();
  const usersById = users.reduce((agg, user) => ({ ...agg, [user.id]: user }), {});
  const { data: portfolios = [] } = useFetchPortfoliosQuery();
  const { currentData: rawDeals = [], isFetching: isPipelineActiveLoading } = useFetchPipelineQuery(transactionType);
  const { currentData: closedDeals = [], isFetching: isPipelineClosedLoading } = useFetchPipelineClosedQuery(transactionType, { skip: dealStage !== DEAL_STATE_CLOSED });
  const { currentData: deadDeals = [], isFetching: isPipelineDeadLoading } = useFetchPipelineDeadQuery(transactionType, { skip: dealStage !== DEAL_STATE_DEAD });
  const { currentData: pipelineStats = {} } = useFetchPipelineStatsQuery(transactionType);
  const { data: selfData, isLoading: isSelfLoading } = useFetchSelfQuery();
  const newBuildOnly = selfData?.organization?.config?.newBuildOnly;

  // temporarily necessarily to make sure all new build only users are configured to acquisitions
  useEffect(() => {
    if (newBuildOnly && (transactionType !== TRANSACTION_TYPES.acquisition)) {
      setTransactionType(TRANSACTION_TYPES.acquisition);
    }
  }, [newBuildOnly, setTransactionType, transactionType]);

  const applicableWorkflowTemplates = useMemo(() => {
    if (!selfData) return null;
    return selfData.organization.workflowTemplates.filter(template => template.workflowType === transactionType);
  }, [transactionType, selfData]);

  const stages = useMemo(() => {
    if (!applicableWorkflowTemplates) return INACTIVE_DEAL_STATES;
    const transactionStages = flatMap(applicableWorkflowTemplates, template => template.workflow.milestones.map(m => m.defaultName));
    return transactionStages.concat(...INACTIVE_DEAL_STATES);
  }, [applicableWorkflowTemplates]);

  const showMarkDealDeadModal = (deal) => {
    setSelectedDeal(deal);
    setShowDeadDealConfirmModal(!showDeadDealConfirmModal);
  };

  const allDeals = rawDeals.concat(deadDeals).concat(closedDeals);

  const uniqueClients = useMemo(() => sortBy(uniq(allDeals.map(deal => deal.transactionInfo.clientName.value)).map(clientName => ({ label: clientName || 'Unassigned', value: clientName }))), [allDeals]);
  const uniqueEntities = useMemo(() => sortBy(uniq(allDeals.map(deal => deal.transactionInfo.entityName.value)).map(entityName => ({ label: entityName || 'Unassigned', value: entityName }))), [allDeals]);
  const uniqueMarkets = useMemo(() => sortBy(uniq(allDeals.map(deal => deal.market)).map(market => ({ label: titleCase(market), value: titleCase(market) }))), [allDeals]);
  const uniqueLeads = useMemo(() => (!isEmpty(usersById) ? sortBy(uniq(allDeals.filter(deal => !isNil(deal.leadId)).map(deal => deal.leadId)), leadId => usersById[leadId]?.email).map(leadId => ({ label: (usersById[leadId]?.fullName || usersById[leadId]?.email), value: leadId.toString() })) : []), [allDeals, usersById]);
  const uniquePortfolios = useMemo(() => sortBy(uniq(portfolios.map(portfolio => ({ label: portfolio.name, value: portfolio.id })))), [portfolios]);

  const filteredDealsBeforeStage = useMemo(() => {
    const leadFilteredDeals = filters.leadIds?.length > 0 ? allDeals.filter(rawDeal => filters.leadIds.includes(rawDeal.leadId?.toString())) : allDeals;
    const marketFilteredDeals = filters.marketIds?.length > 0 ? leadFilteredDeals.filter(rawDeal => filters.marketIds.includes(rawDeal.market)) : leadFilteredDeals;
    const portfolioFilteredDeals = filters.portfolioIds?.length > 0 ? marketFilteredDeals.filter(rawDeal => filters.portfolioIds.includes(rawDeal.portfolioId)) : marketFilteredDeals;
    const clientFilteredDeals = filters.clients?.length > 0 ? portfolioFilteredDeals.filter(rawDeal => filters.clients.includes(rawDeal.transactionInfo.clientName.value)) : portfolioFilteredDeals;
    const entityFilteredDeals = filters.entities?.length > 0 ? clientFilteredDeals.filter(rawDeal => filters.entities.includes(rawDeal.transactionInfo.entityName.value)) : clientFilteredDeals;
    const nameFilteredDeals = filters.name ? entityFilteredDeals.filter(rawDeal => rawDeal.name.toLowerCase().includes(filters.name.toLowerCase())) : entityFilteredDeals;
    const activeSelectFilters = pickBy(omit(filters, ['stage', 'name', 'portfolioIds', 'marketIds', 'leadIds', 'clients', 'entities', 'stageIds']), value => !!value);
    return filter(nameFilteredDeals, activeSelectFilters);
  }, [filters.name, filters.marketIds, filters.leadIds, filters.portfolioIds, filters.clients, filters.entities, allDeals]);

  const filteredDeals = useMemo(
    () => ((dealStage !== ALL_DEALS_STAGE) ? filter(filteredDealsBeforeStage, { stage: dealStage }) : filter(filteredDealsBeforeStage, deal => !INACTIVE_DEAL_STATES.includes(deal.stage))),
    [dealStage, filteredDealsBeforeStage],
  );

  const dealTableLoading = ((dealStage !== DEAL_STATE_CLOSED) && (dealStage !== DEAL_STATE_DEAD) && isPipelineActiveLoading)
    || (dealStage === DEAL_STATE_DEAD && isPipelineDeadLoading)
    || (dealStage === DEAL_STATE_CLOSED && isPipelineClosedLoading);

  const pageContainerRef = useRef();
  const pageHeight = useElementHeight(pageContainerRef);

  if (isEmpty(portfolios) || isEmpty(users) || isSelfLoading) {
    return (
      <div className="w-full h-screen">
        <EmptyLoadingState />
      </div>
    );
  }

  return (
    <div ref={pageContainerRef} className="h-screen px-6 pt-8 bg-gray-100" style={{ width: `calc(100vw - ${LAYOUT.sidebarWidth}px)` }}>
      <div className="h-14">
        <div className="flex justify-between">
          <div className="text-2xl text-neutral-dark font-normal mt-5 mb-1">
            Pipeline
          </div>
          <div className="flex gap-x-8">
            {!newBuildOnly && (
              <div className="flex gap-x-4 items-center">
                {Object.keys(TRANSACTION_TYPES).map(type => (
                  <div className="flex items-center" key={type}>
                    <RadioInput
                      checked={transactionType === TRANSACTION_TYPES[type]}
                      id={transactionType}
                      onChange={() => { setTransactionType(type); }}
                    />
                    <label onClick={() => { setTransactionType(type); }} className="ml-1 text-sm text-gray-900 cursor-pointer">{capitalize(TRANSACTION_TYPES[type])}</label>
                  </div>
                ))}
              </div>
            )}
            <div className="flex items-center w-96">
              <span className="z-10 font-normal absolute text-center text-slate-300 rounded text-base items-center justify-center w-8 pl-3 py-2.5">
                <Search className="text-black w-6 h-6" />
              </span>
              <input
                value={filters.name}
                onChange={(e) => setFilters(merge({}, filters, { name: e.target.value }))}
                type="text"
                placeholder="Search for a deal"
                className="px-2 py-2.5 rounded text-sm outline-none focus:outline-none border border-slate-300 shadow-sm w-full pl-10"
              />
            </div>
            <div className="flex items-center">
              <Button
                filled
                label="Address Search"
                onClick={() => dispatch(setAddressSearchModalIsOpen(true))}
              />
              {addressSearchModalIsOpen && <AddressSearchModal />}
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-x-4 mb-5" />
      {dealTableLoading ? <EmptyLoadingState text={`Loading ${capitalize(transactionType)} Pipeline`} /> : (
        <>
          <Widgets
            deals={filteredDealsBeforeStage}
            deadDealsLoaded={deadDeals.length}
            closedDealsLoaded={closedDeals.length}
            dealStage={dealStage}
            loading={dealTableLoading}
            stages={stages}
            stats={pipelineStats}
            setDealStage={setDealStage}
            transactionType={transactionType}
          />
          {/*
            The key property is required on DealTable to make sure the component
            rebuilds when switching transaction types so that the useLocalStorage
            hook can read the proper state
          */}
          <DealTable
            key={transactionType}
            dealStage={dealStage}
            deals={filteredDeals}
            filters={filters}
            loading={dealTableLoading}
            searchTerm={filters.name}
            setFilters={setFilters}
            showMarkDealDeadModal={showMarkDealDeadModal}
            stages={stages}
            tableHeight={pageHeight - 255}
            transactionType={transactionType}
            uniqueClients={uniqueClients}
            uniqueEntities={uniqueEntities}
            uniqueLeads={uniqueLeads}
            uniqueMarkets={uniqueMarkets}
            uniquePortfolios={uniquePortfolios}
            user={selfData}
            usersById={usersById}
          />
        </>
      )}
      {modal.submitTaskResponse && <SubmitTaskResponseModal />}
      {modal.showEditTransactionInfoModal && (
        <EditTransactionInfoModal
          deal={modal.deal}
          header={modal.header}
          transactionFieldName={modal.transactionFieldName}
        />
      )}
      {showEditDealModal && <EditDealModal context={{ data: { deal: editableDeal } }} />}
      {showDeadDealConfirmModal && selectedDeal.transactionType === TRANSACTION_TYPES.acquisition && (
        <MarkDealAsDeadModal
          deal={selectedDeal}
          isLoading={updateDealIsLoading}
          setShowDeadDealConfirmModal={setShowDeadDealConfirmModal}
          updateDealMutation={updateDealMutation}
        />
      )}
      {showDeadDealConfirmModal && selectedDeal.transactionType === TRANSACTION_TYPES.disposition && (
        <MarkDealAsWithdrawnModal
          deal={selectedDeal}
          isLoading={updateDealIsLoading}
          setShowDeadDealConfirmModal={setShowDeadDealConfirmModal}
          updateDealMutation={updateDealMutation}
        />
      )}
    </div>
  );
}
