import { formatDate } from 'components/utils';
import { deliveryScheduleMetaKey } from './subdivision';

/**
 * @param {import('@tanstack/react-table').HeaderContext['column']} column
 */
export default function DeliveryScheduleHeader({ column }) {
  const { columnDef: { meta: { [deliveryScheduleMetaKey]: deliveryScheduleDate } } } = column;
  return formatDate(deliveryScheduleDate, 'MMM yyyy');
}
