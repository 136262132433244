/* eslint-disable react/button-has-type */
import classNames from 'classnames';
import { LoadingIndicator } from 'components/icons';

// need to add click/active color changes
export default function Button({ className, onClick, width = 'w-max', label, filled, tonal, outlined, textOnly, isLoading, leadingIcon, danger, disabled, small, type = 'button', value, form, ...props }) {
  const styles = classNames(
    className,
    'h-min flex font-medium justify-center items-center text-center focus:outline-none active:outline-none',
    width,
    {
      'py-2.5 rounded-full text-sm': !small,
      'py-1 px-2.5 rounded-lg text-xs': small,
      'px-6': !leadingIcon && !small,
      'px-4': leadingIcon && !small,
      'text-white': filled,
      'text-primary-dark': (textOnly || tonal || outlined) && !danger,
      'text-red-600': (textOnly || tonal || outlined) && danger,
      'bg-primary-dark hover:opacity-80 active:bg-primary-dark': filled && !danger && !tonal && !disabled,
      'bg-primary-light hover:bg-primary-darken active:bg-primary-darken': tonal && !disabled,
      'hover:bg-primary-light active:bg-primary-light focus:bg-primary-light': (textOnly || outlined) && !disabled && !danger,
      'hover:bg-red-100 active:bg-red-100 focus:bg-red-100': (textOnly || outlined) && !disabled && danger,
      'border border-primary-dark': outlined && !disabled && !danger,
      'border border-red-600': outlined && !disabled && danger,
      'text-gray-700 bg-gray-100': !textOnly && disabled,
      'bg-gray-300': filled && disabled,
      'bg-red-600 hover:bg-red-400 active:bg-red-800': filled && danger && !disabled,
      'cursor-not-allowed opacity-80 text-opacity-80 bg-opacity-80': disabled || isLoading,
    },
  );

  return (
    <button {...props} className={styles} type={type} onClick={onClick} disabled={disabled || isLoading} value={value} form={form}>
      {leadingIcon && (
        <div className="mr-2 w-4">
          {leadingIcon}
        </div>
      )}
      {isLoading ? (
        <>
          {label}
          <LoadingIndicator className="inline ml-2 h-5" />
        </>
      ) : label || ''}
    </button>
  );
}
