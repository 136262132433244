import GoogleMap from 'components/common/GoogleMap';
import GoogleMapUtils from 'components/common/GoogleMap/utils';
import { useEffect, useState } from 'react';

function InfoWindow({ id, photoUrl, address, propertyBathroomsTotal, propertyBedroomsTotal, numberOfUnits }) {
  const stopPropagation = (e) => e.stopPropagation();

  let summary = '';
  if (numberOfUnits > 1) {
    summary = `${numberOfUnits} Units`;
  } else {
    summary = `${propertyBedroomsTotal} Bed · ${propertyBathroomsTotal} Bath`;
  }

  useEffect(() => {
    const el = document.querySelector(`#property-management-records > .pmr[data-id='${id}']`);

    if (el) {
      el.querySelector('div').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });

      el.classList.add('bg-primary-50', 'animate-pulse');
      setTimeout(() => {
        el.classList.remove('bg-primary-50');
        el.classList.remove('animate-pulse');
      }, 3000);
    }
  }, []);

  return (
    <div onClick={stopPropagation} className="z-50 cursor -top-24 relative w-60 h-auto left-10 block bg-white border rounded overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
      <div className="rounded overflow-hidden shadow-lg h-full flex flex-col">
        <div className="relative group h-40">
          <img className="w-full h-full object-cover" src={photoUrl} alt="House" referrerPolicy="no-referrer" />
        </div>
        <div className="p-3">
          <div className="font-bold text-sm mb-2">{address}</div>
          <p className="text-gray-700 text-sm mt-1">{summary}</p>
        </div>
      </div>
    </div>
  );
}

function PropertyMap({ propertyManagementRecords, hoveredId }) {
  const [places, setPlaces] = useState();
  const [defaultCenter, setDefaultCenter] = useState();

  useEffect(() => {
    const mappedPlaces = propertyManagementRecords.map((pmr) => ({
      active: false,
      address: pmr.property.address,
      id: pmr.id,
      lat: Number(pmr.property.latitude),
      lng: Number(pmr.property.longitude),
      markerColor: 'red',
      numberOfUnits: pmr.property.numberOfUnits,
      photoUrl: pmr.photoUrl,
      propertyBathroomsTotal: pmr.property.propertyBedroomsTotal,
      propertyBedroomsTotal: pmr.property.propertyBedroomsTotal,
      showInfoWindow: false,
      subject: false,
      text: pmr.property.address,
    }));
    setPlaces(mappedPlaces);
    if (mappedPlaces.length > 0) {
      setDefaultCenter({ lat: mappedPlaces[0]?.lat, lng: mappedPlaces[0]?.lng });
    }
  }, [propertyManagementRecords]);

  return (
    <div className="w-full h-full">
      {places && defaultCenter && (
      <GoogleMap
        defaultCenter={defaultCenter}
        hoveredId={hoveredId}
        infoWindow={InfoWindow}
        mapType={GoogleMapUtils.MAP_TYPE_ROADMAP}
        places={places}
      />
      )}
    </div>
  );
}

export default PropertyMap;
