import { Children, cloneElement } from 'react';

export default function CollapsibleSection({ children, collapsed, indentation, nested = false }) {
  const childrenIndentation = indentation ? indentation + 1 : 1;
  if (nested) {
    return Children.map(children, child => child && cloneElement(child, { hidden: collapsed, indentation: childrenIndentation }));
  } else {
    return (
      <tbody className={collapsed ? 'hidden' : ''}>
        {Children.map(children, child => child && cloneElement(child, { indentation: childrenIndentation }))}
      </tbody>
    );
  }
}
