import { useState } from 'react';
import { get, isEmpty } from 'lodash';
import { formatCurrency, formatPercentage } from 'components/utils';
import Select from 'components/Select';
import ActualVsUnderwrittenChart from 'components/AssetManagement/ActualVsUnderwrittenChart';

const CHART_OPTIONS = [{
  label: 'Net Operating Income',
  path: 'netOperatingIncome',
  formatter: formatCurrency,
}, {
  label: 'Effective Gross Revenue',
  path: 'revenue.effectiveGrossRevenues',
  formatter: formatCurrency,
}, {
  label: 'Gross Revenue',
  path: 'revenue.grossRevenues',
  formatter: formatCurrency,
}, {
  label: 'Gross Rent',
  path: 'revenue.grossRents',
  formatter: formatCurrency,
}, {
  label: 'Other Income',
  path: 'revenue.otherIncomes',
  formatter: formatCurrency,
}, {
  label: 'Reimbursable Expenses',
  path: 'revenue.reimbursableExpenses',
  formatter: formatCurrency,
}, {
  label: 'Concessions',
  path: 'revenue.concessions',
  formatter: formatCurrency,
}, {
  label: 'Collection Losses',
  path: 'revenue.collectionLosses',
  formatter: formatCurrency,
}, {
  label: 'Total Operating Expenses',
  path: 'expenses.totalOperatingExpenses',
  formatter: formatCurrency,
}, {
  label: 'Controllable Expenses',
  path: 'expenses.controllableExpenses',
  formatter: formatCurrency,
}, {
  label: 'Non-Controllable Expenses',
  path: 'expenses.nonControllableExpenses',
  formatter: formatCurrency,
}, {
  label: 'Non-Controllable Expenses Before Taxes',
  path: 'expenses.nonControllableExpensesBeforeTaxes',
  formatter: formatCurrency,
}, {
  label: 'Taxes',
  path: 'expenses.taxes',
  formatter: formatCurrency,
}, {
  label: 'Below the Line Expenses',
  path: 'capital.belowTheLineExpenses',
  formatter: formatCurrency,
}, {
  label: 'Capital Projects',
  path: 'capital.followOnCapitalExpenses',
  formatter: formatCurrency,
}, {
  label: 'Occupancy',
  path: 'occupancy.physicalOccupancyRates',
  formatter: formatPercentage,
}];

export default function CashFlows({ propertyManagementRecord }) {
  const [chartContext, setChartContext] = useState(CHART_OPTIONS[0]);
  const { cashFlows, underwrittenCashFlows } = propertyManagementRecord;

  if (isEmpty(cashFlows)) {
    return (
      <div className="h-full p-6 bg-gray-100">
        <div className="bg-white p-3 rounded">
          No data available
        </div>
      </div>
    );
  }

  const selectOptions = CHART_OPTIONS.map(o => [o.path, o.label, false]);

  return (
    <div className="h-full p-6 bg-gray-100">
      <Select
        width="w-128"
        options={selectOptions}
        value={chartContext.path}
        onChange={e => setChartContext(CHART_OPTIONS.find(opt => e.target.value === opt.path))}
      />
      {get(cashFlows, chartContext.path) ? (
        <div className="mt-8 py-6 bg-white rounded">
          <ActualVsUnderwrittenChart
            cashFlows={cashFlows}
            underwrittenCashFlows={underwrittenCashFlows}
            path={chartContext.path}
            height={540}
            formatter={chartContext.formatter}
          />
        </div>
      ) : (
        <div className="mt-8 bg-white p-3 rounded">
          {`No data available for ${chartContext.label}`}
        </div>
      )}
    </div>
  );
}
