import { isNil } from 'lodash';

export const STORAGE_VERSION = 0;
export const FALLBACK_VISIBILITY = true;
export const FALLBACK_ORDER = Object.freeze([]);

export const EMPTY_PRESET = Object.freeze({
  name: 'Default',
  defaultVisibility: true,
  toggleSection: Object.freeze({}),
  visibility: Object.freeze({}),
  order: FALLBACK_ORDER,
});

/**
 * @param {string} tableId
 */
export const getDefaultPresetId = ({ tableId }) => `${tableId}.default`;

/**
 * @param {import('@tanstack/react-table').Column['id']} columnId
 * @param {string} presetId
 */
export const visibilityStorageKey = ({ columnId, presetId }) => (
  ['DataTableConfig', presetId, 'visibility', columnId].join('.')
);

/**
 * @param {string | null | undefined} toggleSection
 * @param {string} presetId
 */
export const visibilitySectionStorageKey = ({ toggleSection, presetId }) => (
  isNil(toggleSection) ? (
    ['DataTableConfig', presetId, 'toggleDefaultSection'].join('.')
  ) : (
    ['DataTableConfig', presetId, 'toggleSection', toggleSection].join('.')
  )
);

export const orderStorageKey = ({ presetId }) => (
  ['DataTableConfig', presetId, 'order'].join('.')
);

/**
 * @param {string} tableId
 */
export const activePresetStorageKey = ({ tableId }) => (
  ['DataTableConfig', 'activePreset', tableId].join('.')
);

/** @type {(column: import('@tanstack/react-table').Column) => boolean} */
const isTableConfigDisabled = (column) => column.columnDef.meta?.tableConfig === false;

/** @type {(column: import('@tanstack/react-table').Column) => string | boolean} */
export const getColumnVisibilityConfigurable = (column) => {
  if (isTableConfigDisabled(column)) {
    return false;
  }

  // note 'false' value for visibilityConfigurable should only be defined statically with table/column definition
  const { columnDef: { meta: { tableConfig: { visibilityConfigurable } = {} } = {} } } = column;
  if (visibilityConfigurable === false) {
    return false;
  }

  if (!column.getCanHide()) {
    return 'readonly';
  }

  return visibilityConfigurable ?? true;
};

/** @type {(column: import('@tanstack/react-table').Column) => boolean} */
export const isColumnVisibilityToggleable = (column) => getColumnVisibilityConfigurable(column) === true;

/** @type {(column: import('@tanstack/react-table').Column) => string | boolean} */
export const getColumnOrderConfigurable = (column) => {
  if (isTableConfigDisabled(column)) {
    return false;
  }

  const { columnDef: { meta: { tableConfig: { orderConfigurable } = {} } = {} } } = column;
  return orderConfigurable ?? true;
};

/** @type {(column: import('@tanstack/react-table').Column) => boolean} */
export const isColumnOrderDraggable = (column) => getColumnOrderConfigurable(column) === true;
