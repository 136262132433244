const GoogleMapUtils = {

  // use hybrid instead of pure satellite because we want to show roads
  MAP_TYPE_SATELLITE: 'hybrid',
  MAP_TYPE_ROADMAP: 'roadmap',

  getMapType: (type) => ({
    hybrid: google.maps.MapTypeId.HYBRID,
    roadmap: google.maps.MapTypeId.ROADMAP,
  })[type],

  shapes: [],

  shapeOptions: (extra = {}) => ({
    fillColor: '#000000',
    fillOpacity: 0,
    strokeColor: '#000000',
    strokeWeight: 3,
    clickable: false,
    editable: false,
    zIndex: 1,
    ...extra,
  }),

  // Calculate map bounds based on a list of places
  getMapBounds: (_map, maps, places) => {
    const bounds = new maps.LatLngBounds();
    places.forEach(({ lat, lng }) => {
      bounds.extend(new maps.LatLng(lat, lng));
    });
    return bounds;
  },

  // Add a one-time event listener to the map for the 'idle' event, then add a resize event to the window to update map bounds
  bindResizeListener: (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  },

  onDrawingComplete: (shape, map, maps, places, callback, drawingManager) => {
    const { overlay, type } = shape;
    let filteredPlaces = [];

    switch (type) {
      case 'circle':
        filteredPlaces = places.filter((place) => GoogleMapUtils.isInsideCircle(place, overlay));
        break;
      case 'polygon':
        filteredPlaces = places.filter((place) => GoogleMapUtils.isInsidePolygon(place, overlay));
        break;
      case 'rectangle':
        filteredPlaces = places.filter((place) => GoogleMapUtils.isInsideRectangle(place, overlay));
        break;
      default:
        console.error(`Drawing type ${type} unknow`);
    }

    // Store the overlay
    GoogleMapUtils.shapes.push(shape);

    // Call the callback with the filtered places
    callback(filteredPlaces, GoogleMapUtils.shapes, shape);

    // display clear btn if one is not present
    if (!drawingManager.clearBtn) {
      const clearBtn = GoogleMapUtils.createClearButton(map, callback);
      drawingManager.clearBtn = clearBtn;
    }

    // turn off drawingManager
    drawingManager.setDrawingMode(null);
  },

  isInsideCircle: (place, circle) => {
    const center = circle.getCenter();
    const radius = circle.getRadius();
    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(place.lat, place.lng),
      center,
    );
    return distance <= radius;
  },

  isInsidePolygon: (place, polygon) => google.maps.geometry.poly.containsLocation(
    new google.maps.LatLng(place.lat, place.lng),
    polygon,
  ),

  isInsideRectangle: (place, rectangle) => rectangle.getBounds().contains(
    new google.maps.LatLng(place.lat, place.lng),
  ),

  // Fit map to its bounds and bind the resize listener after the API is loaded
  apiIsLoaded: (map, maps, getPlaces, callback, setIsDrawing, setActiveId) => {
    try {
      const drawingManager = GoogleMapUtils.initializeDrawingManager(map);
      GoogleMapUtils.setupDrawingCompletionListener(drawingManager, map, maps, getPlaces, callback, setIsDrawing, setActiveId);
      GoogleMapUtils.fitMapToBounds(map, maps, getPlaces);
    } catch (error) {
      console.error('Error in apiIsLoaded:', error);
    }
  },

  initializeDrawingManager: (map) => {
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
        drawingModes: [
          google.maps.drawing.OverlayType.RECTANGLE,
          google.maps.drawing.OverlayType.POLYGON,
          google.maps.drawing.OverlayType.CIRCLE,
        ],
      },
      rectangleOptions: GoogleMapUtils.shapeOptions(),
      polygonOptions: GoogleMapUtils.shapeOptions(),
      circleOptions: GoogleMapUtils.shapeOptions({ editable: false }),
    });
    drawingManager.setMap(map);
    return drawingManager;
  },

  setupDrawingCompletionListener: (drawingManager, map, maps, getPlaces, callback, setIsDrawing, setActiveId) => {
    google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
      setIsDrawing(false);
      setTimeout(() => setActiveId(null), 0);
      const currentPlaces = getPlaces();
      GoogleMapUtils.onDrawingComplete(event, map, maps, currentPlaces, callback, drawingManager);
    });

    // Listen for changes in the drawing mode
    google.maps.event.addListener(drawingManager, 'drawingmode_changed', () => {
      const drawingMode = drawingManager.getDrawingMode();
      setActiveId(null);
      if (drawingMode === null) {
        setIsDrawing(false);
      } else {
        setIsDrawing(true);
      }
    });
  },

  fitMapToBounds: (map, maps, getPlaces) => {
    const currentPlaces = getPlaces();
    const bounds = GoogleMapUtils.getMapBounds(map, maps, currentPlaces);
    map.fitBounds(bounds);
    GoogleMapUtils.bindResizeListener(map, maps, bounds);
  },

  createClearButton: (map, callback) => {
    const controlDiv = document.createElement('div');

    const controlUI = document.createElement('button');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = 'none';
    controlUI.style.outline = 'none';
    controlUI.style.width = '58px';
    controlUI.style.height = '24px';
    controlUI.style.borderRadius = '2px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginRight = '10px';
    controlUI.style.marginLeft = '-4px';
    controlUI.style.marginTop = '5px';
    controlUI.title = 'Click to clear drawing filters';
    controlDiv.appendChild(controlUI);

    const controlText = document.createElement('div');

    controlText.style.color = 'rgb(25,25,25)';
    controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlText.style.fontSize = '16px';
    controlText.style.paddingLeft = '5px';
    controlText.style.paddingRight = '5px';
    controlText.innerHTML = 'Clear';
    controlUI.appendChild(controlText);

    controlUI.addEventListener('click', () => {
      GoogleMapUtils.shapes.forEach(({ overlay }) => {
        overlay.setMap(null);
      });
      // Reset the shapes array
      GoogleMapUtils.shapes = [];

      // remove clear btn
      controlDiv.remove();
      callback(null, null);
    });

    controlDiv.index = 1;
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(controlDiv);
    return controlDiv;
  },

  mapStyles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        { saturation: '100' },
        { lightness: '-14' },
      ],
    },
  ],

};

export default GoogleMapUtils;
