import { Route, Routes, useLocation } from 'react-router-dom';
import OrganizationHeader from 'components/organization/OrganizationHeader';
import OrganizationIndex from 'components/organization/OrganizationIndex';
import OrganizationNew from 'components/organization/OrganizationNew';
import OrganizationShow from 'components/organization/OrganizationShow';
import { useFetchOrganizationsQuery } from 'redux/organizationApiSlice';

export default function OrganizationBase() {
  const location = useLocation();
  const { data: organizations, isLoading } = useFetchOrganizationsQuery();

  const organizationId = (location.pathname.split('/').length === 3) && location.pathname.split('/')[2];
  const isNew = organizationId === 'new';
  const organization = organizationId && !isNew && organizations?.find(o => o.id.toString() === organizationId);

  return (
    <div className="flex flex-col h-screen bg-gray-100 overflow-y-auto">
      {isLoading ? null : (
        <>
          <OrganizationHeader organization={organization} isNew={isNew} />
          <Routes>
            <Route path="/" element={<OrganizationIndex organizations={organizations} />} />
            <Route path="/new" element={<OrganizationNew />} />
            <Route path="/:organizationId" element={<OrganizationShow organization={organization} />} />
          </Routes>
        </>
      )}
    </div>
  );
}
