import { partial, xor } from 'lodash';
import { useEffect, useId, useState } from 'react';
import Filter from './Filter';

export default function CheckboxFilter({ filters, setFilters, options, filterKey, label }) {
  const committedFilterState = filters[filterKey];
  const [selectedOptions, setSelectedOptions] = useState(committedFilterState ?? []);

  useEffect(() => {
    setSelectedOptions(committedFilterState ?? []);
  }, [committedFilterState]);

  const onApply = (close) => {
    setFilters((prev) => ({ ...prev, [filterKey]: selectedOptions }));
    close();
  };

  const onChange = (clickedOption) => {
    setSelectedOptions((prev) => xor(prev, [clickedOption]));
  };

  const onReset = () => {
    setFilters((prev) => ({ ...prev, [filterKey]: [] }));
  };

  const filtered = !!committedFilterState?.length;
  let filterLabel = label;
  if (filtered) {
    filterLabel = committedFilterState.length === 1 ? committedFilterState[0] : `${committedFilterState[0]}...`;
  }

  const id = useId();

  return (
    <Filter label={filterLabel} onApply={onApply} onReset={onReset} filtered={filtered} width="min-w-max">
      <div className="max-h-96 overflow-y-auto">
        <fieldset>
          {options.map(opt => (
            <div key={opt}>
              <input
                checked={selectedOptions.includes(opt)}
                className="mr-4 mb-6 cursor-pointer"
                onChange={partial(onChange, opt)}
                id={`${id}${encodeURIComponent(opt)}-checkbox`}
                name={`${opt}-checkbox`}
                type="checkbox"
              />
              <label className="font-light cursor-pointer" htmlFor={`${id}${encodeURIComponent(opt)}-checkbox`}>{opt}</label>
            </div>
          ))}
        </fieldset>
      </div>
    </Filter>
  );
}
