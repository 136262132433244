import { EXPENSE_METHOD_ITEMIZED, EXPENSE_METHOD_RATIO } from '../dcf/dcf';
import { FormField } from '../Form';
import { BelowTheLineExpenseTable, PortfolioItemizedExpenseTable } from '../dcf/ItemizedExpenseTable';
import { PERCENT_TYPE } from '../Input';
import PortfolioEditTabSectionLabel from '../portfolio/PortfolioEditTabSectionLabel';

const EXPENSE_METHOD_OPTIONS = [[EXPENSE_METHOD_RATIO, 'Expense Ratio'], [EXPENSE_METHOD_ITEMIZED, 'Itemized']];

export default function ExpensesParameters({ parameters, onChange, setActiveParameterTab, canEditPortfolio }) {
  const { expenseRatio, includeHoaFee } = parameters;
  const expenseMethod = parameters.expenseMethod ?? EXPENSE_METHOD_OPTIONS[0][0];

  return (
    <>
      <FormField
        name="expenseMethod"
        type="select"
        value={expenseMethod}
        options={EXPENSE_METHOD_OPTIONS}
        onChange={onChange}
        disabled={!canEditPortfolio}
      />

      {expenseMethod === EXPENSE_METHOD_ITEMIZED && (
        <>
          <FormField
            className="my-6"
            label="Automatically Include HOA Fee"
            name="includeHoaFee"
            type="checkbox"
            value={includeHoaFee}
            onChange={onChange}
            disabled={!canEditPortfolio}
          />
          <PortfolioItemizedExpenseTable parameters={parameters} onChange={onChange} setActiveParameterTab={setActiveParameterTab} canEditPortfolio={canEditPortfolio} />
        </>
      )}

      {expenseMethod === EXPENSE_METHOD_RATIO && (
        <>
          <FormField
            className="mt-3 max-w-sm"
            name="expenseRatio"
            type={PERCENT_TYPE}
            value={expenseRatio}
            min="0"
            max="100"
            onChange={onChange}
            disabled={!canEditPortfolio}
          />
          <PortfolioEditTabSectionLabel className="mt-6" text="Below the Line Expenses" />
          <table className="min-w-full divide-y divide-gray-200 bg-white">
            <BelowTheLineExpenseTable
              includeHeader
              dcfParams={parameters}
              onChange={onChange}
              canEditPortfolio={canEditPortfolio}
            />
          </table>
        </>
      )}
    </>
  );
}
