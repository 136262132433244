import cx from 'classnames';
import ReactDOM from 'react-dom';
import { X } from './icons';
import RoundButton from './common/RoundButton';

function ModalHeader({ title, onClose }) {
  return (
    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
      <h3 className="text-xl font-medium text-gray-900">
        {title}
      </h3>
      <RoundButton name="closeModalButton" padding="p-2 border border-neutral-dark border-opacity-12" onClick={onClose}>
        <X className="w-5" />
      </RoundButton>
    </div>
  );
}

export default function Modal({
  title,
  children,
  onClose,
  show,
  backgroundColor = 'bg-white',
  showHeader = true,
  size = 'small',
  placement = 'center',
  customHeader = null,
}) {
  const sizeClasses = {
    small: 'max-w-sm',
    medium: 'max-w-lg',
    large: 'max-w-4xl',
    extraLarge: 'max-w-7xl',
    fullScreen: 'w-full h-full',
  };

  return ReactDOM.createPortal(
    (
      <div
        className={cx(
          'fixed inset-0 z-50 flex bg-black bg-opacity-36',
          {
            'hidden': !show,
            'justify-start items-start': placement === 'topLeft',
            'justify-end items-start': placement === 'topRight',
            'justify-start items-end': placement === 'bottomLeft',
            'justify-end items-end': placement === 'bottomRight',
            'justify-start items-center': placement === 'leftCenter',
            'justify-end items-center': placement === 'rightCenter',
            'items-center justify-center': placement === 'center',
            'p-1 md:p-2': size !== 'fullScreen',
          },
        )}
        onMouseDown={onClose}
      >
        <div
          className={cx(
            'relative flex flex-col w-full max-h-full border border-black border-opacity-12',
            backgroundColor,
            sizeClasses[size],
            { 'm-1 rounded-xl': size !== 'fullScreen' },
          )}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          {showHeader && (
          <ModalHeader
            onClose={onClose}
            title={title}
          />
          )}
          {customHeader && customHeader}
          <div className={cx('w-full max-h-full overflow-y-auto', { 'px-6': size !== 'fullScreen' })}>
            {children}
          </div>
        </div>
      </div>
    ),
    document.body,
  );
}
