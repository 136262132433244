import { useDispatch, useSelector } from 'react-redux';
import { showSaveChangesModal } from 'actions/deal_navigation';
import Modal from 'components/Modal';
import Button from 'components/shared/NewButton';

export default function SaveChangesModal() {
  const dispatch = useDispatch();
  const { cancel, doNotSave, save } = useSelector(state => state.navigation.modal);

  const onCancel = () => {
    dispatch(showSaveChangesModal(false));
    cancel();
  };

  const onDoNotSave = () => {
    dispatch(showSaveChangesModal(false));
    doNotSave();
  };

  const onSave = () => {
    dispatch(showSaveChangesModal(false));
    save();
  };

  return (
    <Modal show showCloseAction={false}>
      <div className="mt-4 w-90 h-auto">
        <div className="text-2xl font-normal">Save changes?</div>
        <div className="mt-6 font-light">
          You have unsaved changes. Do you wish to save them before continuing?
        </div>
        <div className="mt-6 flex gap-x-2 justify-end">
          <Button
            textOnly
            label="Cancel"
            onClick={onCancel}
          />
          <Button
            textOnly
            label="Don't Save"
            onClick={onDoNotSave}
          />
          <Button
            filled
            label="Save"
            onClick={onSave}
          />
        </div>
      </div>
    </Modal>
  );
}
