import { Link } from 'react-router-dom';
import { LISTING_SOURCE_OFF_MARKET_MARKETPLACE, RESIDENTIAL_PROPERTY_TYPE } from 'components/constants';
import { Arrow } from 'components/icons';
import cx from 'classnames';
import {
  formatCurrency,
  formatInteger,
  formatListingSource,
} from 'components/utils';
import { isNil, lowerCase, omit, startCase, upperCase } from 'lodash';
import KillReviveDealOverlay from './KillReviveDealOverlay';
import ListingStatsGrid from './ListingStatsGrid';
import ListingUpdatedAt from './ListingUpdatedAt';
import PropertyPicture from './PropertyPicture';

const isSingleFamily = (item) => (item.propertyType === RESIDENTIAL_PROPERTY_TYPE);

function TopSection({ item }) {
  const { unparsedAddress, streetAddress = unparsedAddress, city, stateOrProvince, yearBuilt, postalCode, propertySubType } = item;

  return (
    <div>
      <div className="text-xs text-neutral-light font-medium tracking-wider">{`${propertySubType} • Built in ${yearBuilt ?? '-'}`}</div>
      <div className="mt-1">
        <div className="text-neutral-dark text-base truncate">
          <span className="font-semibold ">{startCase(lowerCase(streetAddress))}</span>
          <span className="font-normal">{`, ${startCase(lowerCase(city))}, ${upperCase(stateOrProvince)} ${postalCode}`}</span>
        </div>
      </div>
    </div>
  );
}

function PriceChange({ item }) {
  const { previousListPrice, listPrice } = item;
  const colorFill = listPrice < previousListPrice ? 'green' : 'red';
  const direction = listPrice < previousListPrice ? 'down' : 'up';
  const percentageChange = (((listPrice * 100) / previousListPrice) - 100).toFixed(2);

  return (
    <span className="px-3 py-0.5 text-xs rounded-3xl border flex align-middle whitespace-nowrap">
      <Arrow className="h-3.5 w-4 mr-0.5 flex-none" fill={colorFill} direction={direction} />
      {' '}
      {percentageChange}
      {' '}
      %
    </span>
  );
}

function PriceBadge({ label }) {
  return (<span className="px-3 py-0.5 text-xs rounded-3xl border whitespace-nowrap">{label}</span>);
}

function MiddleSection({ item }) {
  const {
    numberOfUnitsTotal,
    previousListPrice,
    listPrice,
    bedroomsTotal,
    bathroomsFull,
    bathroomsHalf,
    bathroomsTotalInteger = (bathroomsFull || bathroomsHalf) ? (bathroomsFull ?? 0 + bathroomsHalf ?? 0) : null,
    livingArea,
    buildingArea = livingArea,
    unitBreakdown,
  } = item;
  const pricePerUnit = listPrice / (numberOfUnitsTotal || 1);
  const pricePerSquareFoot = listPrice / buildingArea;

  let unitInformation = '';
  if (isSingleFamily(item)) {
    unitInformation = `${unitInformation} ${bedroomsTotal ?? '-'} Bed • ${bathroomsTotalInteger ?? '-'} Bath`;
  } else {
    unitInformation = numberOfUnitsTotal ? `${unitInformation} ${numberOfUnitsTotal ?? '-'} Units` : unitInformation;
    const unitBreakdownString = unitBreakdown && Object.entries(unitBreakdown).filter(([, v]) => !isNil(v)).map(([k, v]) => `${k}: ${v}`).join(' • ');
    if (unitBreakdownString) unitInformation = `${unitInformation} • ${unitBreakdownString}`;
  }
  if (buildingArea) unitInformation = `${unitInformation} • ${formatInteger(buildingArea)} SF`;

  const isPriceChanged = !isNil(previousListPrice) && previousListPrice !== listPrice;

  return (
    <div>
      <div className="flex">
        <div className="text-dark font-normal text-2xl">
          {formatCurrency(listPrice)}
        </div>
        <div className="flex mx-1 gap-x-1 items-center">
          { isPriceChanged && (<PriceChange item={item} />)}
          { pricePerUnit !== Infinity && !isSingleFamily(item) && (
            <PriceBadge label={`${formatCurrency(pricePerUnit)}/Unit`} />
          )}
          { pricePerSquareFoot !== Infinity && (
            <PriceBadge label={`${formatCurrency(pricePerSquareFoot)} PSF`} />
          )}
        </div>
      </div>
      <div className="text-sm text-neutral font-medium mt-2">{unitInformation}</div>
    </div>
  );
}

function BottomSection({ item, portfolio }) {
  const {
    source,
    listingId,
  } = item;

  return (
    <div className="w-full flex flex-col gap-y-3 flex-auto justify-end">
      <ListingStatsGrid item={item} portfolio={portfolio} singleFamily={isSingleFamily(item)} />
      <div className="flex gap-x-3 text-xs text-neutral-light font-medium whitespace-nowrap tracking-wider">
        {(source !== LISTING_SOURCE_OFF_MARKET_MARKETPLACE) && (
          <span className="uppercase">
            {`${formatListingSource(source)} #${listingId}`}
          </span>
        )}
        <ListingUpdatedAt item={item} portfolio={portfolio} />
      </div>
    </div>
  );
}

function ListingCard({ item: { id }, portfolio, children }) {
  // item class used by map to highlight a listing
  const className = 'item sticky left-0 group flex py-3 w-full overflow-x-auto bg-white';

  if (portfolio) {
    return (
      <Link
        className={cx(className, 'gap-x-4 px-4 hover:bg-gray-50')}
        data-id={id}
        to={`/listings/${id}`}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <div className={cx(className, 'gap-x-6 pr-6 pl-4')}>
        {children}
        <div className="my-3 border-r bg-white flex-1" />
      </div>
    );
  }
}

function ListingRow({ currentUser, item, style, onMouseEnter, onMouseLeave, portfolio }) {
  const gridTemplateCols = 'grid-cols-1';

  return (
    <div
      className={`grid ${gridTemplateCols} grid-rows-1 grid-flow-col gap-x-4 justify-items-center min-w-full bg-white`}
      style={omit(style, 'width')}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ListingCard item={item} portfolio={portfolio}>
        <PropertyPicture item={item} portfolio={portfolio} />
        <div className="flex flex-col gap-y-5 overflow-clip flex-1 w-0">
          <TopSection item={item} />
          <MiddleSection item={item} />
          <BottomSection item={item} portfolio={portfolio} />
        </div>
        {portfolio && <KillReviveDealOverlay item={item} currentUser={currentUser} portfolio={portfolio} />}
      </ListingCard>
    </div>
  );
}

export default ListingRow;
