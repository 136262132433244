import { Combobox, Transition } from '@headlessui/react';
import { Check, SelectorIcon } from 'components/icons';
import DelayedLoadingIndicator from 'components/shared/DelayedLoadingIndicator';
import { parseEventValue } from 'components/utils';
import { debounce, identity } from 'lodash';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

const normalizeStringForSearch = (input) => (
  input.normalize('NFKD').replace(/\p{Diacritic}/gu, '').toLowerCase()
);

export default function ComboboxDropdown({ value, onChange, options, keyBy, labelBy, queryBy = labelBy, isLoading }) {
  const [comboboxQuery, setComboboxQuery] = useState('');
  const debouncedSetQuery = useMemo(() => (
    debounce(
      (query) => { setComboboxQuery(query); },
      300,
      { leading: false, trailing: true },
    )
  ), []);

  // cancel debounced function on unmount
  useEffect(() => (
    () => { debouncedSetQuery.cancel(); }
  ), [debouncedSetQuery]);

  /** @type {import('react').ChangeEventHandler<HTMLInputElement>} */
  const inputOnChange = useCallback((evt) => {
    debouncedSetQuery(parseEventValue(evt));
  }, [debouncedSetQuery]);

  const resetComboboxQuery = useCallback(() => {
    debouncedSetQuery.cancel();
    setComboboxQuery('');
  }, [debouncedSetQuery]);

  const normalizedOptionValues = useMemo(() => (
    options
      ?.map(queryBy ? (({ [queryBy]: filterByValue }) => filterByValue) : identity)
      ?.map((val) => normalizeStringForSearch(val))
  ), [queryBy, options]);
  const filteredOptions = useMemo(() => {
    if (!comboboxQuery || !options?.length) {
      return options;
    }

    const queryNormalized = normalizeStringForSearch(comboboxQuery);
    return options?.filter((val, idx) => normalizedOptionValues[idx].includes(queryNormalized));
  }, [comboboxQuery, normalizedOptionValues, options]);

  const displayValue = useCallback((item) => (labelBy ? item?.[labelBy] : item), [labelBy]);

  // TODO: use constant for width, add option for full width
  // TODO: add option to absolute position dropdown

  return (
    // TODO: enable virtual options after switching to headlessui v2
    <Combobox nullable value={value ?? null} onChange={onChange} by={keyBy} disabled={isLoading}>
      <div className="relative w-80">
        <Combobox.Input
          className="border rounded-md w-full py-2 px-3 text-gray-900 focus:outline-none"
          displayValue={displayValue}
          onChange={inputOnChange}
          spellCheck="false"
          autoComplete="off"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 pr-3">
          {isLoading ? <DelayedLoadingIndicator className="size-4 text-gray-500" /> : <SelectorIcon />}
        </Combobox.Button>
      </div>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={resetComboboxQuery}
      >
        <Combobox.Options
          // without tabIndex, clicking on the scrollbar dismisses the dropdown when this combobox is nested inside a headlessui popover
          tabIndex={-1}
          className="border rounded-md mt-1 py-1 h-60 w-80 overflow-auto bg-white text-gray-200 [scrollbar-color:currentColor_transparent]"
        >
          {filteredOptions?.map((option) => (
            <Combobox.Option
              key={keyBy ? option[keyBy] : option}
              value={option}
              className="flex flex-row gap-x-2.5 select-none py-2 px-2.5 text-gray-900 ui-active:text-white ui-active:bg-primary-400"
            >
              <span className="flex-none flex items-center text-primary-400 ui-active:text-white invisible ui-selected:visible">
                <Check className="size-5" />
              </span>
              <div className="truncate font-normal ui-selected:font-medium">
                {labelBy ? option[labelBy] : option}
              </div>
            </Combobox.Option>
          ))}
          <div className="[&:not(:only-child)]:hidden text-gray-700 select-none px-3 py-2">
            Nothing found
          </div>
        </Combobox.Options>
      </Transition>
    </Combobox>
  );
}
