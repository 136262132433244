import { forwardRef } from 'react';
import classNames from 'classnames';
import { Warning, X } from 'components/icons';

function AlertChip({ label, leadingIcon, width = 'w-max' }, ref) {
  const clazz = classNames(
    'h-8 flex justify-center items-center text-center',
    'rounded-lg px-3 bg-yellow-100',
    'text-xs text-black text-opacity-80',
    'focus:outline-none active:outline-none select-none',
    width,
  );

  return (
    <div className={clazz}>
      {leadingIcon && (<Warning className="mr-2 w-4 fill-yellow-500"/>)}
      {label}
    </div>
  );
}

export default forwardRef(AlertChip);
