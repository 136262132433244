import { generatePath } from 'react-router-dom';
import { offMarketMarketplaceImportJobsApiPath } from 'components/routes';
import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { apiSlice } from './apiSlice';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      importOffMarketMarketplaceCsv: builder.mutation({
        query({ file }) {
          const formData = new FormData();
          formData.append('file', file);

          return {
            url: generatePath(offMarketMarketplaceImportJobsApiPath, { action: 'import_csv' }),
            method: 'POST',
            body: formData,
          };
        },
        transformResponse: (response) => camelCaseKeys(response, ['csvRows', 'userColumnOrder']),
      }),
      prepareOffMarketMarketplaceImport: builder.mutation({
        query({ listings }) {
          return {
            url: generatePath(offMarketMarketplaceImportJobsApiPath, { action: 'prepare' }),
            method: 'POST',
            body: snakeCaseKeys({ listings }, ['listings']),
          };
        },
        transformResponse: (response) => camelCaseKeys(response),
      }),
      commitOffMarketMarketplaceImport: builder.mutation({
        query({ id }) {
          return {
            url: generatePath(offMarketMarketplaceImportJobsApiPath, { id, action: 'commit' }),
            method: 'POST',
          };
        },
      }),
      fetchOffMarketMarketplaceImportJob: builder.query({
        query: ({ id }) => generatePath(offMarketMarketplaceImportJobsApiPath, { id }),
        transformResponse: (response) => camelCaseKeys(response, ['resultData']),
      }),
    };
  },
});

export const {
  useImportOffMarketMarketplaceCsvMutation,
  usePrepareOffMarketMarketplaceImportMutation,
  useCommitOffMarketMarketplaceImportMutation,
  useFetchOffMarketMarketplaceImportJobQuery,
} = extendedApiSlice;
