import cx from 'classnames';

export function UnderwritingStatMultiplicity({ value, multiplicity, formatter }) {
  return multiplicity !== 1 && (
    <div className="text-label-md text-neutral-medium whitespace-nowrap leading-none">
      {`×${multiplicity} = ${formatter(value * multiplicity)}`}
    </div>
  );
}

export function UnderwritingSimpleStat({ className, formatter, label, value, multiplicity, showMultiplicity = false }) {
  return (
    <div className={cx(className, 'grid row-span-2 grid-rows-subgrid items-start gap-y-2')}>
      <div className="text-xs text-gray-500 font-medium">{label}</div>
      <div className="flex flex-col justify-start self-start">
        {formatter(value)}
        {showMultiplicity && <UnderwritingStatMultiplicity value={value} multiplicity={multiplicity} formatter={formatter} />}
      </div>
    </div>
  );
}
