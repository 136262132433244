import { createColumnHelper } from '@tanstack/react-table';
import { EnumCell, InputCell, ZipCell } from 'components/shared/Table/Cells';
import { dataTableMeta, enableEditing } from 'components/shared/Table/table.helpers';

/** @type {import('@tanstack/react-table').TableOptions['defaultColumn']} */
export const NEW_BUILD_DEFAULT_COLUMN = {
  sortUndefined: 'last',
};

const columnHelper = createColumnHelper();

/** @type {(parentColumn: string) => import('@tanstack/react-table').FilterFn} */
const createNestedRowIdFilter = (parentColumn) => (
  (row, columnId, filterValue) => (
    !filterValue || ((parentColumn ? row.original[parentColumn] : row.original).id === filterValue.id)
  )
);

/**
 * @param {string} parentColumn
 * @param {string} selfColumn
 * @param {string} [sep]
 */
const joinParentColumn = (parentColumn, selfColumn, { sep } = {}) => (
  parentColumn ? `${parentColumn}${sep ?? '.'}${selfColumn}` : selfColumn
);

export const enabledColumn = (parentColumn) => (
  columnHelper.accessor(joinParentColumn(parentColumn, 'enabled'), {
    header: 'Published',
    id: 'enabled',
    cell: EnumCell,
    meta: {
      enumDisplayValues: { [true]: 'Yes', [false]: 'No' },
      ...(parentColumn === undefined ? enableEditing({ cell: InputCell, inputType: 'checkbox' }) : {}),
    },
  })
);

export const marketColumn = (parentColumn) => (
  columnHelper.accessor(joinParentColumn(parentColumn, 'market'), {
    header: 'Market',
    filterFn: 'equals',
    meta: { toggleSection: 'Location' },
  })
);

export const subdivisionNameColumn = (parentColumn) => (
  columnHelper.accessor(joinParentColumn(parentColumn, 'name'), {
    header: 'Community',
    filterFn: createNestedRowIdFilter(parentColumn),
    meta: {
      toggleSection: 'Location',
      ...(parentColumn === undefined ? enableEditing({ cell: InputCell, inputType: 'text' }) : {}),
    },
  })
);

export const cityColumn = (parentColumn) => (
  columnHelper.accessor(joinParentColumn(parentColumn, 'city'), {
    header: 'City',
    meta: {
      ...(parentColumn === undefined ? enableEditing({ cell: InputCell, inputType: 'text' }) : {}),
      toggleSection: 'Location',
    },
  })
);

export const stateColumn = (parentColumn) => (
  columnHelper.accessor(joinParentColumn(parentColumn, 'state'), {
    header: 'State',
    meta: {
      ...(parentColumn === undefined ? enableEditing({ cell: InputCell, inputType: 'text' }) : {}),
      toggleSection: 'Location',
    },
  })
);

export const zipColumn = (parentColumn) => (
  columnHelper.accessor(({ [parentColumn]: { zip: zipFromParent } = {}, zip }) => (
    Number.parseInt((parentColumn ? zipFromParent : zip).slice(0, 5), 10) || 0
  ), {
    id: joinParentColumn(parentColumn, 'zip', { sep: '_' }),
    header: 'ZIP',
    cell: ZipCell,
    meta: {
      ...dataTableMeta.textRight,
      ...(parentColumn === undefined ? enableEditing({ cell: InputCell, inputType: 'text' }) : {}),
      mutatorFn: (row, zip) => Object.assign(row, { zip }),
      toggleSection: 'Location',
    },
  })
);

export const homeBuilderNameColumn = (parentColumn) => (
  columnHelper.accessor(joinParentColumn(parentColumn, 'name'), {
    header: 'Builder',
    filterFn: createNestedRowIdFilter(parentColumn),
  })
);
