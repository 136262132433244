import cx from 'classnames';
import { DEAL_STATE_DEAD } from 'components/constants';
import { unreviewedStageSymbol } from './subdivision';

const dealStageLabelMap = {
  [unreviewedStageSymbol]: 'Unreviewed',
  [DEAL_STATE_DEAD]: 'Passed',
};

function Status({ unreviewed, stage }) {
  return (
    <div className={cx('max-w-[16ch] truncate', unreviewed && 'text-neutral-dark/20')}>
      {dealStageLabelMap[stage] ?? stage ?? '-'}
    </div>
  );
}

export default function HomeModelDealStatusCell({ getValue, row }) {
  const cellValue = getValue();
  const isUnreviewed = cellValue === unreviewedStageSymbol;
  const statusElem = <Status unreviewed={isUnreviewed} stage={cellValue} />;

  if (row.getValue('offerCreated')) {
    const qtyOfferedTotal = row.getValue('qtyOfferedTotal');
    return (
      <div className="inline-flex flex-col">
        {statusElem}
        <div className="text-[smaller]">{`${qtyOfferedTotal} ${qtyOfferedTotal === 1 ? 'unit' : 'units'}`}</div>
      </div>
    );
  }

  return statusElem;
}
