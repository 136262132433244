import { NumericFormat } from 'react-number-format';
import { formatPercentageInput } from 'components/Input';
import { isUndefined } from 'lodash';

const PERCENT_TYPE = 'percent';

function CustomInput({ className, max, min, name, onChange, value }) {
  return (
    <input
      className={className}
      max={max}
      min={min}
      name={name}
      onChange={onChange}
      value={value}
    />
  );
}

function NumberInput({ className, decimalScale = 2, max, min, name, onChange, prefix, suffix, thousandSeparator = ',', type, value }) {
  let formattedValue = value;
  if (formattedValue && type === PERCENT_TYPE) {
    const percentagePrecision = parseInt(decimalScale, 10) || 6;
    formattedValue = (value === '' ? '' : formatPercentageInput(value, percentagePrecision));
  }
  const keepRange = (values) => {
    const { floatValue } = values;
    return isUndefined(floatValue) || ((min ? floatValue >= min : true) && (max ? floatValue <= max : true));
  };

  return (
    <NumericFormat
      customInput={CustomInput}
      className={className}
      decimalScale={decimalScale}
      isAllowed={keepRange}
      max={max}
      min={min}
      name={name}
      onChange={onChange}
      suffix={suffix}
      prefix={prefix}
      thousandSeparator={thousandSeparator}
      value={formattedValue}
    />
  );
}

export default NumberInput;
