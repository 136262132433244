import { isEmpty, isNaN, omitBy, orderBy, slice } from 'lodash';
import { formattedLabel } from 'components/utils';
import { BED_TYPE, BedBathFilter as BedBathFilterComponent } from 'components/shared/filters/BedBathFilter';
import ChipFilter from 'components/shared/filters/ChipFilter';

export default function BedFilter({ filterKey, filterParams, onApply, onReset, setFilter }) {
  const bedValues = orderBy(omitBy(slice(filterParams, 1, filterParams.length), isNaN));
  const filtered = !isEmpty(bedValues);
  const label = formattedLabel('Bed', filterParams[0], bedValues);

  return (
    <ChipFilter
      filtered={filtered}
      label={label}
      filterKey={filterKey}
      filterParams={filterParams}
      onApply={onApply}
      onReset={onReset}
      setFilter={setFilter}
    >
      {({ filterState: [bedUseExact, ...bedFilter], setFilterState }) => (
        <BedBathFilterComponent
          type={BED_TYPE}
          useExact={bedUseExact}
          filter={bedFilter}
          setFilterState={setFilterState}
        />
      )}
    </ChipFilter>
  );
}
