import { forwardRef, isValidElement } from 'react';
import { isEmpty, remove } from 'lodash';
import { Menu as HeadlessMenu } from '@headlessui/react';
import classNames from 'classnames';
import { FilledChevron } from 'components/icons';

// NOTE: the forwardRef behavior for MenuButton and MenuItem is required by the headlesssui library
// ref: https://github.com/tailwindlabs/headlessui/issues/856

const MenuButton = forwardRef(({ open: isOpen, icon, text, minimal = false }, ref) => (
  <div ref={ref} className={`flex justify-between items-center cursor-pointer text-sm hover:bg-primary-hover text-ellipsis ${isOpen && 'border-primary-dark'} ${minimal ? 'p-3 hover:bg-primary-hover rounded-full' : 'px-4 py-1.5 border rounded-md'}`}>
    {icon}
    {text && <span className="pr-6">{text}</span>}
    {!minimal && <FilledChevron className="w-5" direction={isOpen ? 'up' : 'down'} />}
  </div>
));

export const MenuItem = forwardRef(({ className, compact, text, subtext, onClick, active, disabled, labelOnly, element = null, iconLeft = null, iconRight = null, ...props }, ref) => {
  const menuItemClassName = classNames(
    className,
    'flex items-center',
    {
      'bg-primary-hover': active && !labelOnly,
      'cursor-not-allowed text-gray-400': disabled && !labelOnly,
      'cursor-default': labelOnly,
      'p-4': compact,
      'px-6 py-4': !compact,
    },
  );

  const itemContents = element || (
    <div className="w-full flex justify-between items-center">
      <div className="flex justify-start items-center">
        {iconLeft && <div className="w-10">{iconLeft}</div>}
        {subtext ? (
          <div className="flex-col justify-between">
            <div>{text}</div>
            <div className="text-xs mt-1 text-gray-500">{subtext}</div>
          </div>
        ) : text}
      </div>
      {iconRight && <div className="w-10">{iconRight}</div>}
    </div>
  );

  return (
    <div ref={ref} className={menuItemClassName} onClick={onClick} {...props}>{itemContents}</div>
  );
});

export default function Menu({ className, icon = null, items, itemGroups = [], label, minimal = false, alignLeft = false }) {
  if (!itemGroups.length && items) {
    items.forEach(item => itemGroups.push([item]));
  }

  itemGroups.forEach(actionGroup => remove(actionGroup, isEmpty));
  remove(itemGroups, isEmpty);

  if (isEmpty(itemGroups)) {
    return null;
  }

  const itemsClass = classNames(
    className,
    'min-w-max mr-2 mt-2 absolute top-full right-0 z-30 divide-y divide-black divide-opacity-12 bg-white rounded shadow-2xl cursor-pointer select-none focus:outline-none',
    {
      'left-0': alignLeft,
      'right-0': !alignLeft,
    },
  );

  return (
    <div>
      <HeadlessMenu as="div" className="flex relative mr-4 text-sm">
        {({ open }) => (
          <>
            <HeadlessMenu.Button className={`cursor-pointer outline-none focus:outline-none ${minimal ? '' : 'px-2 py-1'}`}>
              <MenuButton open={open} icon={icon} text={label} minimal={minimal} />
            </HeadlessMenu.Button>
            <HeadlessMenu.Items className={itemsClass}>
              {itemGroups.map((itemGroup, groupIndex) => (
                <div key={groupIndex}>
                  {itemGroup.map((item, index) => (
                    <HeadlessMenu.Item key={index} disabled={item.disabled}>
                      {({ active }) => (
                        isValidElement(item)
                          ? <MenuItem>{item}</MenuItem>
                          : <MenuItem {...item} active={active} className={classNames('min-w-max', item.className)} disabled={item.disabled} />
                      )}
                    </HeadlessMenu.Item>
                  ))}
                </div>
              ))}
            </HeadlessMenu.Items>
          </>
        )}
      </HeadlessMenu>
    </div>
  );
}
