import { useParams } from 'react-router-dom';
import { useUpdatePortfolioPreferenceMutation } from '../../redux/apiSlice';
import { dealSourcingIndexPath } from '../routes';
import { LocationMarker } from '../icons';

export default function CreateDeal() {
  const { portfolioId: id } = useParams();
  const [updateCurrentPortfolio, { isSuccess: didUpdateCurrentPortfolio }] = useUpdatePortfolioPreferenceMutation();
  if (didUpdateCurrentPortfolio) {
    // deal sourcing page requires a browser navigation
    window.location = dealSourcingIndexPath;
  }

  return (
    <>
      <div className="flex h-screen/10 mb-8 justify-center items-center text-white">.</div>
      <div className="flex mb-6 justify-center items-center">
        <LocationMarker />
      </div>
      <div className="flex mb-4 justify-center items-center">
        <div className="m-auto">
          <div className="mb-2 text-xl text-center">Get Started</div>
          <div className="mb-2 text-sm text-center  opacity-50">Add deals to your portfolio to see them here</div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="justify-center bg-green-500 text-white py-3 px-5 rounded cursor-pointer" onClick={() => updateCurrentPortfolio({ id })}>
          <span className="text-base font-normal">Search Deals</span>
        </div>
      </div>
    </>
  );
}
