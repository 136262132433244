import { LAYOUT } from 'components/constants';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Button from 'components/shared/NewButton';
import { Plus } from 'components/icons';
import { setShowCreateDealModal } from 'actions/deal_navigation';
import DashboardAlertPopup from './DashboardAlertPopup';
import Documents from './Documents';
import TasksTab from './TasksTab';

function WorkflowPlaceholder() {
  const dispatch = useDispatch();

  return (
    <div className="grow flex justify-center mt-12">
      <div className="text-center">
        <div className="text-xl">No Workflow</div>
        <div className="text-xs text-gray-500 my-4">You must create a deal before using the Workflow</div>
        <div className="flex justify-center">
          <Button filled label="Create Deal" leadingIcon={<Plus className="w-4" />} onClick={() => dispatch(setShowCreateDealModal(true))} />
        </div>
      </div>
    </div>
  );
}

export default function Dashboard({ context }) {
  const { data } = context;
  const { deal } = data;

  if (!deal) {
    return (
      <WorkflowPlaceholder />
    );
  }

  // TODO: no-op everything in a dead deal

  return (
    <div className="grow flex flex-col" style={{ height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)` }}>
      <DashboardAlertPopup />
      <div className="w-full h-1 flex flex-1">
        <div className="flex-1 overflow-auto bg-gray-100">
          <Routes>
            <Route
              path="/milestones/*"
              element={<TasksTab context={context} />}
            />
            <Route
              path="/deal_attachments"
              element={<Documents context={context} />}
            />
            <Route path="/roster" />

            <Route path="*" element={<Navigate to="milestones" replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
