import Input from 'components/Input';

export default function Text({ defaultFilters, label, subText, onChange }) {
  return (
    <div className="w-full flex flex-col mb-6">
      <span className="font-medium text-sm">{ label }</span>
      <Input type="text" className="w-full my-2 px-2 py-3 border border-gray-300" onChange={onChange} value={defaultFilters.zipCodes ?? ''} />
      <span className="text-xs text-gray-600">{ subText }</span>
    </div>
  );
}
