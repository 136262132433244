import { isNil } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useFilteredRows, useInitialColumnVisibility, useSetColumnVisibility } from 'components/shared/Table/DataTableContext';

export default function HideEmptyColumn({ columnId, isEmpty = isNil }) {
  const rows = useFilteredRows();
  const setColumnVisibility = useSetColumnVisibility();
  const initialColumnVisibility = useInitialColumnVisibility({ columnId });

  const rowsReady = !!rows?.length;
  const skip = !rowsReady || initialColumnVisibility === false;

  const nonEmptyCount = useMemo(() => {
    if (skip) {
      return null;
    }

    return rows.filter(({ getValue }) => !isEmpty(getValue(columnId))).length;
  }, [columnId, isEmpty, rows, skip]);

  useEffect(() => {
    if (skip) {
      return;
    }

    setColumnVisibility((prev) => ({ ...prev, [columnId]: nonEmptyCount > 0 }));
  }, [columnId, nonEmptyCount, setColumnVisibility, skip]);

  return null;
}
