import { compact, isBoolean, isEmpty, isNil, isUndefined, orderBy } from 'lodash';
import { calcDaysOnMarket, formatDate, simpleDealStatus } from 'components/utils';

// return true if the item should be filtered OUT (i.e. not shown)
const basicFiltering = (type, filters, item, func = null) => {
  const filter = filters[type];
  // if filter does not apply, skip and pass item through
  if (isUndefined(filter)) {
    return false;
  }
  let pass = false;
  if (filter && (compact(filter).length || Object.keys(filter).length || isBoolean(filter))) {
    if (func) {
      pass = !func(filter, item);
    } else {
      pass = !((!isNil(filter[0]) ? item[type] >= filter[0] : true)
        && (!isNil(filter[1]) ? item[type] <= filter[1] : true));
    }
  }
  return pass;
};

const bedBathFiltering = (type, filters, item) => {
  if (!(type in filters)) {
    return false;
  }

  let allow = false;

  const filter = filters[type];
  const { useExact, selections } = filter;

  if (!selections?.length && !useExact) { // "ANY"
    allow = true;
  } else if (useExact) {
    if (selections?.includes(5)) {
      allow = item[type] >= 5;
    } else {
      allow = compact(selections).includes(item[type]);
    }
  } else {
    allow = ((!isNil(selections[0]) ? item[type] >= selections[0] : true)
      && (!isNil(selections[1]) ? item[type] <= selections[1] : true));
  }
  return !allow;
};

// TODO: update this to only consider filters relevant to current channel
export const applyFilters = (item, filters, currentDate) => {
  // toFilter returns true if we want to filter the item OUT of the list (i.e. not show up in the deal sourcing list)
  let toFilter = (isEmpty(filters.pipeline) && !isUndefined(item.dealStatus)) // Edge case if pipeline filters is empty show listing that are not in the pipeline
    || basicFiltering('yearBuilt', filters, item)
    || basicFiltering('numberOfUnitsTotal', filters, item)
    || basicFiltering('buildingArea', filters, item)
    || basicFiltering('propertySubType', filters, item, (propertySubTypes, item) => propertySubTypes?.includes(item.propertySubType))
    || basicFiltering('standardStatus', filters, item, (status, item) => status.includes(item.standardStatus))
    || basicFiltering('listingContractDate', filters, item)
    || basicFiltering('listPrice', filters, item, (price, item) => {
      const [priceMinFilter, priceMaxFilter] = price;
      // support both MLS listings with a single price value and home models with a priceMin and priceMax values
      const passPriceMinFilter = priceMinFilter ? (item.listPrice || item.priceMax) > priceMinFilter : true;
      const passPriceMaxFilter = priceMaxFilter ? (item.listPrice || item.priceMin) < priceMaxFilter : true;
      return passPriceMinFilter && passPriceMaxFilter;
    })
    || basicFiltering('pricePerUnit', filters, item)
    || basicFiltering('pricePerSquareFoot', filters, item)
    || bedBathFiltering('bathroomsTotalInteger', filters, item)
    || bedBathFiltering('bedroomsTotal', filters, item)
    || basicFiltering('daysOnMarket', filters, item, (daysOnMarket, item) => {
      const days = calcDaysOnMarket(item.listingContractDate);
      return (daysOnMarket[0] ? days >= daysOnMarket[0] : true)
        && (daysOnMarket[1] ? days <= daysOnMarket[1] : true);
    })
    || basicFiltering('excludesHomesWithPool', filters, item, (excludesHomesWithPool, item) => (excludesHomesWithPool ? item.poolFlag !== 'TRUE' : true))
    || basicFiltering('zipCodes', filters, item, (zipCodes, item) => zipCodes?.includes(item.postalCode))
    || basicFiltering('unleveredIrr', filters, item)
    || basicFiltering('leveredIrr', filters, item)
    || basicFiltering('leveredEquityMultiple', filters, item)
    || basicFiltering('unleveredEquityMultiple', filters, item)
    || basicFiltering('stabilizedYield', filters, item)
    || basicFiltering('grossStabilizedYield', filters, item)
    || basicFiltering('goingInCapRate', filters, item)
    || basicFiltering('y1Yield', filters, item)
    || basicFiltering('propertyType', filters, item, (propertyType, item) => !propertyType || (propertyType === item.propertyType))
    || basicFiltering('availability', filters, item, (availabilityRange, item) => {
      const [availabilityStart, availabilityEnd] = availabilityRange;
      const { futureDeliveries, numAvailable } = item;

      const startAvailableNow = availabilityStart && (formatDate(currentDate) >= availabilityStart);
      const endAvailableNow = availabilityEnd && (formatDate(currentDate) >= availabilityEnd);

      // if either start or end filter corresponds to available now, filter based on whether model has current availability
      if (startAvailableNow || endAvailableNow) {
        return numAvailable > 0;
      }

      let applicableDeliveries = futureDeliveries;

      // if there is non-now start or end filter, filter the future deliveries accordingly
      if (availabilityStart && !startAvailableNow) {
        applicableDeliveries = applicableDeliveries.filter(delivery => delivery[0] >= availabilityStart);
      }
      if (availabilityEnd && !endAvailableNow) {
        applicableDeliveries = applicableDeliveries.filter(delivery => delivery[0] <= availabilityEnd);
      }

      return applicableDeliveries.length > 0;
    })
    || basicFiltering('pipeline', filters, item, (pipeline, item) => pipeline.includes(simpleDealStatus(item, 'dealStatus')))
    || basicFiltering('homeModelBuilder', filters, item, (builderNames, { homeBuilder: { name } = {} }) => builderNames?.includes(name))
    || basicFiltering('homeModelSubdivision', filters, item, (subdivisionNames, { subdivision: { name } = {} }) => subdivisionNames?.includes(name))
    ;
  return !toFilter;
};

export const filterItems = (items, filters, currentDate) => items.filter(item => applyFilters(item, filters, currentDate));

export const sortItems = (items, sortBys) => {
  const sortKeysAndOrders = sortBys.map(s => s.split('-'));
  return orderBy(items, sortKeysAndOrders.map(([key]) => (item => item[key] ?? '')), sortKeysAndOrders.map(([, order]) => order));
};

// frontend filtering by shape using google geometry library
// only used for listed deals because off-market is filtered by geo on back-end
export const filterBySelectedArea = (items, filters) => {
  const { area, polygon, circle } = filters;

  if (area) {
    return items.filter(
      (item) => item.latitude >= area.south
        && item.latitude <= area.north
        && item.longitude >= area.west
        && item.longitude <= area.east,
    );
  }

  if (polygon && google.maps.geometry) {
    return items.filter(
      item => google.maps.geometry.poly.containsLocation(
        new google.maps.LatLng(item.latitude, item.longitude),
        new google.maps.Polygon({ paths: polygon }),
      ),
    );
  }
  if (circle && google.maps.geometry) {
    return items.filter(
      item => {
        const { radius, center } = circle;
        const itemPoint = new google.maps.LatLng(item.latitude, item.longitude);
        const centerPoint = new google.maps.LatLng(center.lat, center.lng);
        return (google.maps.geometry.spherical.computeDistanceBetween(itemPoint, centerPoint) <= radius);
      },
    );
  }
  return items;
};
