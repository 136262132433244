import { Fragment } from 'react';
import { Popover } from '@headlessui/react';
import { ArrowDropDown } from 'components/icons';
import Chip from 'components/shared/Chip';
import FilterPanel from 'components/shared/filters/FilterPanel';

export default function ChipFilter({ label, filtered, filterParams, onApply, onReset, children }) {
  const onCancel = (e) => {
    e.stopPropagation();
    onReset();
  };

  return (
    <Popover className="relative">
      <Popover.Button
        as={Chip}
        label={label}
        onCancel={onCancel}
        selected={filtered}
        trailingIcon={<ArrowDropDown />}
      />
      <Popover.Panel focus unmount as={Fragment}>
        {({ close }) => (
          <FilterPanel close={close} filterParams={filterParams} onApply={onApply} onReset={onReset}>
            {children}
          </FilterPanel>
        )}
      </Popover.Panel>
    </Popover>
  );
}
