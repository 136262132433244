import cx from 'classnames';
import { useEffect, useState } from 'react';
import { ACTIVE_STATUS, DEAL_STATE_DEAD } from 'components/constants';
import { Close, Folder, Home } from 'components/icons';
import Map from 'components/property/components/Map';
import MapModal from 'components/property/components/MapModal';
import { calcDaysOnMarket } from 'components/utils';
import { CenterOnAnchor, PunchHoleMarker } from 'components/DealSourcing/MarkerIcons';
import Badge from 'components/shared/Badge';
import { createStaticMapUrl } from 'components/staticmap';

function ImagePlaceholder() {
  return (
    <div className="rounded-lg h-full w-full object-cover bg-gray-200 flex items-center">
      <Home className="h-20 w-24 mx-auto opacity-12" />
    </div>
  );
}

function Image({ item }) {
  const { streetAddress, photoUrl } = item;

  return (
    <div className="relative h-full w-full">
      <img
        className="rounded-lg h-full w-full object-cover"
        loading="lazy"
        src={photoUrl}
        alt={streetAddress}
        referrerPolicy="no-referrer"
      />
    </div>
  );
}

function ListingStatusOverlay({ item }) {
  const { dealStatus, listingContractDate, standardStatus } = item;

  const daysOnMarket = calcDaysOnMarket(listingContractDate);
  const mlsStatusText = `${standardStatus} • ${daysOnMarket}${daysOnMarket === 1 ? ' Day' : ' Days'}`;
  const mlsStatusTextColor = standardStatus === ACTIVE_STATUS ? 'text-success' : 'text-neutral-dark';
  const mlsStatusBgColor = standardStatus === ACTIVE_STATUS ? 'bg-gainsboro' : 'bg-white';

  const PipelineIcon = dealStatus === DEAL_STATE_DEAD ? Close : Folder;
  const pipelineTextColor = dealStatus === DEAL_STATE_DEAD ? 'text-neutral-dark' : 'text-purplenavy';
  const pipelineBgColor = dealStatus === DEAL_STATE_DEAD ? 'bg-platinum' : 'bg-azureishwhite';

  return (
    <div className="absolute top-2 left-2 flex space-x-2 select-none pointer-events-none">
      <Badge label={mlsStatusText} className={cx('bg-opacity-95 shadow', mlsStatusTextColor, mlsStatusBgColor)} />
      {dealStatus && (
        <Badge className={`${pipelineTextColor} ${pipelineBgColor}`}>
          <div className="flex items-center gap-x-2">
            <PipelineIcon className="w-4 h-4" fill="currentColor" />
            <div>{dealStatus}</div>
          </div>
        </Badge>
      )}
    </div>
  );
}

function MapPopupOverlay({ item: { latitude, longitude, address, city, state, zipCode } }) {
  const [imgSrc, setImgSrc] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!latitude || !longitude) {
      return undefined;
    }

    const url = createStaticMapUrl({ latitude, longitude });

    // delay setting img src
    const imgSetTimeout = setTimeout(() => setImgSrc(url.toString()), 1000);
    return () => clearTimeout(imgSetTimeout);
  }, [latitude, longitude]);

  if (!latitude || !longitude) {
    return null;
  }

  return (
    <div className="absolute bottom-2 right-2 w-13 h-13 select-none cursor-pointer">
      <img
        className="object-cover h-full w-full rounded pointer-events-none before:absolute before:w-full before:h-full before:inset-0 before:rounded before:bg-gray-100"
        src={imgSrc}
        referrerPolicy="no-referrer"
        alt={address}
        decoding="async"
        loading="lazy"
      />
      <CenterOnAnchor className="text-primary-600 pointer-events-none">
        <PunchHoleMarker dim="24" fill="currentColor" strokeWidth="2.5" />
      </CenterOnAnchor>
      <div
        className="absolute inset-0 h-full w-full rounded hover:bg-neutral-dark hover:bg-opacity-12"
        onClick={() => setShowModal(true)}
      />
      {showModal && (
        <MapModal showMapModal setShowMapModal={setShowModal} address={{ address, city, state, zipCode }}>
          <Map data={{ map: { properties: [{ latitude, longitude }] } }} />
        </MapModal>
      )}
    </div>
  );
}

export default function PropertyPicture({ item, showMap = false }) {
  const { photoUrl } = item;

  return (
    <div className="relative w-80 flex-none">
      {photoUrl ? <Image item={item} /> : <ImagePlaceholder />}
      <ListingStatusOverlay item={item} />
      {showMap && <MapPopupOverlay item={item} />}
    </div>
  );
}
