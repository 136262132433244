import { identity } from 'lodash';

export const metricFunc = (label, statFunc, asOfDate, lastWeekDate, lastMonthDate, filteredAcquisitions, oneWeekAgoFilteredAcquisitions, oneMonthAgoFilteredAcquisitions, formatter = identity) => ({
  label,
  value: formatter(statFunc(filteredAcquisitions, asOfDate)),
  lastWeekValue: formatter(statFunc(oneWeekAgoFilteredAcquisitions, lastWeekDate)),
  lastMonthValue: formatter(statFunc(oneMonthAgoFilteredAcquisitions, lastMonthDate)),
});

export const getMarkerColorByOfferStatus = (deal) => {
  const statusColorMapping = {
    Accepted: 'green',
    Offered: 'blue',
    Rejected: 'red',
  };

  const defaultColor = 'grey';

  return statusColorMapping[deal.attributes.offerStatus] || defaultColor;
};
