import { useEffect, useState } from 'react';
import classNames from 'classnames';
import RoundButton from 'components/common/RoundButton';
import { X } from 'components/icons';
import Modal from '../../Modal';
import { formatAddress } from '../../utils';
import Map from './Map';

const TABS = ['Map', 'Street View'];

const defaultMapOptions = {
  fullscreenControl: false,
  zoomControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  keyboardShortcuts: false,
};

function setPanorama(element, property) {
  const ll = new google.maps.LatLng(property.latitude, property.longitude);
  const svs = new google.maps.StreetViewService();

  svs.getPanorama({ location: ll, preference: 'nearest' }, (data, status) => {
    const pos = data.location.latLng;
    const head = google.maps.geometry.spherical.computeHeading(pos, ll);
    const panorama = new google.maps.StreetViewPanorama(
      element,
      {
        pano: data.location.pano,
        pov: { heading: head, pitch: 0 },
        fullscreenControl: false,
      },
    );
  });
}

export default function MapModal({ showMapModal, setShowMapModal, property, type, children, defaultActiveTab }) {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || TABS[0]);

  useEffect(() => {
    if (property) {
      setPanorama(document.getElementById('street-view-modal'), property);
    }
  }, [activeTab]);

  return (
    <Modal
      show={showMapModal}
      backgroundColor="bg-transparent"
      buttonColor="text-white hover:text-gray-300"
      fullScreen
      onClose={() => setShowMapModal(false)}
      showCloseAction={false}
    >
      <div className="relative w-full h-full flex items-center justify-center z-5">
        <div className="w-8/12 h-5/6 bg-white flex flex-col items-center rounded-xl">
          <div className="flex justify-between w-full h-18 px-6 pt-4">
            <div className="text-neutral-dark text-xl font-normal">
              {property ? formatAddress(property) : <div />}
              {type && ` — ${type}`}
            </div>
            <RoundButton padding="h-10 w-10 border border-neutral-dark border-opacity-12" onClick={() => setShowMapModal(false)}>
              <X className="h-5 w-5 mx-auto" />
            </RoundButton>
          </div>
          {property && (
          <div className="w-full h-12 flex text-sm font-medium">
            {TABS.map((tab, index) => (
              <div
                key={index}
                className={classNames(
                  'h-full w-36 py-3 text-center cursor-pointer',
                  {
                    'border-b border-gray-200': activeTab !== tab,
                    'border-b-4 border-primary-dark': activeTab === tab,
                  },
                )}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
          )}
          <div className="w-full h-full rounded-b-xl">
            {activeTab === TABS[0]
              ? (children || <Map properties={[property]} options={defaultMapOptions} borderRadius="0.5rem" />)
              : <div className="w-full h-full" id="street-view-modal" />}
          </div>
        </div>
      </div>
    </Modal>
  );
}
