/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';
import { formatCurrency } from 'components/utils';
import dayjs from 'dayjs';
import DataTable from 'components/shared/Table/DataTable';
import { tdClassNameFn, theadClassName } from 'components/NhimbleDashboard/Shared';
import { AddressCell } from 'components/NhimbleDashboard/Lease/LeaseTable';

const getColumns = type => {
  // Define common columns
  const commonColumns = [
    {
      header: 'Address',
      cell: AddressCell,
    },
    {
      header: 'Market',
      accessorKey: 'attributes.property.market',
    },
    {
      header: 'Portfolio',
      accessorKey: 'attributes.portfolioName',
    },
  ];

  switch (type) {
    case 'In Contract':
      return [
        ...commonColumns,
        {
          header: 'In Contract Date',
          accessorFn: row => `${row.attributes.acceptanceDate ? dayjs(row.attributes.acceptanceDate).format('MM/DD/YYYY') : '-'}`,
        },
        {
          header: 'Closing Date',
          accessorFn: row => `${row.attributes.closingDate ? dayjs(row.attributes.closingDate).format('MM/DD/YYYY') : '-'}`,
        },
        {
          header: 'Contract Price',
          accessorFn: row => `${formatCurrency(row.attributes.contractPrice)}`,
        },
        {
          header: 'Estimated Proceeds ($)',
          accessorFn: row => `${formatCurrency(row.attributes.estimatedProceeds)}`,
        },
        {
          header: 'Estimated Profit ($)',
          accessorFn: row => `${formatCurrency(row.attributes.estimatedProfit)}`,
        },
        {
          header: 'ROI (%)',
          accessorFn: row => `${formatCurrency(row.attributes.estimatedRoi)}`,
        },
      ];
    case 'Closing 2 weeks':
      return [
        ...commonColumns,
        {
          header: 'In Contract Date',
          accessorFn: row => `${row.attributes.acceptanceDate ? dayjs(row.attributes.acceptanceDate).format('MM/DD/YYYY') : '-'}`,
        },
        {
          header: 'Closing Date',
          accessorFn: row => `${row.attributes.acceptanceDate ? dayjs(row.attributes.closingDate).format('MM/DD/YYYY') : '-'}`,
        },
        {
          header: 'Contract Price',
          accessorFn: row => `${formatCurrency(row.attributes.contractPrice)}`,
        },
        {
          header: 'Estimated Proceeds ($)',
          accessorFn: row => `${formatCurrency(row.attributes.estimatedProceeds)}`,
        },
        {
          header: 'Estimated Profit ($)',
          accessorFn: row => `${formatCurrency(row.attributes.estimatedProfit)}`,
        },
        {
          header: 'ROI (%)',
          accessorFn: row => `${formatCurrency(row.attributes.estimatedRoi)}`,
        },
      ];
    case 'Long DOM':
      return [
        ...commonColumns,
        {
          header: 'Sales Price',
          accessorFn: row => `${formatCurrency(row.attributes.currentListPrice)}`,
        },
        {
          header: 'Original Sales Price',
          accessorFn: row => `${formatCurrency(row.attributes.originalListPrice)}`,
        },
        {
          header: '# of Offers',
          accessorKey: 'attributes.numberOfOffers',
        },
        {
          header: '# of Showings',
          accessorKey: 'attributes.numberOfShowings',
        },
        {
          header: 'DOM',
          accessorKey: 'attributes.dom',
        },
      ];
    default:
      return commonColumns;
  }
};

const TABLE_META = { textAlign: 'center' };

export default function DisposTable({ filteredData, tableType }) {
  const columns = useMemo(() => getColumns(tableType), [tableType]);

  return (
    <div className="border">
      <DataTable
        columns={columns}
        data={filteredData}
        emptyStateComponent="There are no available properties matching your criteria"
        theadClassName={theadClassName}
        tdClassName={tdClassNameFn}
        meta={TABLE_META}
      />
    </div>
  );
}
