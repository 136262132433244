import { naturalSortComparator } from 'components/utils';
import ComboboxFilter from 'components/shared/newfilters/ComboboxFilter';
import { useFetchHomeBuildersQuery } from '../../../redux/homeBuilderApiSlice';

const homeBuilderNameComparator = naturalSortComparator();

const selectHomeBuilders = ({ currentData, isFetching, isUninitialized }) => {
  const sortedData = currentData?.map(({ id, name }) => ({ id, name }));
  if (sortedData?.length) {
    sortedData.sort(({ name: left }, { name: right }) => homeBuilderNameComparator(left, right));
  }

  return {
    currentData: sortedData ?? null,
    isFetching: isFetching || isUninitialized,
  };
};

export default function HomeBuilderFilter() {
  const { currentData: homeBuilders, isFetching } = useFetchHomeBuildersQuery(undefined, { selectFromResult: selectHomeBuilders });

  return (
    <ComboboxFilter
      isLoading={isFetching}
      options={homeBuilders}
      keyBy="id"
      labelBy="name"
    />
  );
}
