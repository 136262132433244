import { partial, identity, isEmpty, compact } from 'lodash';
import { parseEventValue, titleCase } from 'components/utils';
import Input from 'components/Input';
import ChipFilter from 'components/shared/filters/ChipFilter';

export default function RangeFilter({ type, label, filterKey, filterParams, onApply, onCancel, labelFormatter = identity, onReset, labelSuffix, setFilter }) {
  let filtered = filterParams[0] || filterParams[1];
  let formattedLabel;
  if (filtered) {
    let filteredLabel;
    if (filterParams[0] && filterParams[1]) {
      filteredLabel = `${labelFormatter(filterParams[0])} - ${labelFormatter(filterParams[1])}`;
    } else if (filterParams[0]) {
      filteredLabel = `${labelFormatter(filterParams[0])}+`;
    } else {
      filteredLabel = `Up to ${labelFormatter(filterParams[1])}`;
    }
    formattedLabel = `${filteredLabel}${labelSuffix}`;
  } else {
    formattedLabel = label || titleCase(label);
  }

  filtered = !isEmpty(compact(filterParams));

  return (
    <ChipFilter label={formattedLabel} filterKey={filterKey} filtered={filtered} filterParams={filterParams} onApply={onApply} onReset={onReset} setFilter={setFilter} handleCancel={onCancel}>
      {({ filterState: [rangeMin, rangeMax], setFilterState }) => {
        const onChange = (changeIndex, event) => {
          const parsedValue = parseEventValue(event) ?? '';
          setFilterState(prevState => {
            const updatedState = [...prevState];
            updatedState[changeIndex] = parsedValue;
            return updatedState;
          });
        };

        return (
          <div className="flex items-center gap-x-4.5">
            <Input
              onChange={partial(onChange, 0)}
              placeholder="No min"
              type={type}
              value={rangeMin}
              width="w-40"
            />
            &mdash;
            <Input
              onChange={partial(onChange, 1)}
              placeholder="No max"
              type={type}
              value={rangeMax}
              width="w-40"
            />
          </div>
        );
      }}
    </ChipFilter>
  );
}
