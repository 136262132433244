import { useCallback, useMemo, useState } from 'react';
import { isObject } from 'lodash';

export function getStorageValue(key, defaultValue, version, serializedValue = undefined) {
  const savedString = serializedValue ?? localStorage.getItem(key);
  const saved = JSON.parse(savedString);

  // If recalled value is null, not an object (to handle pre-version values) or
  // if the version does not match the current version, return the defaultValue
  if (!saved || !isObject(saved) || (saved.version !== version)) {
    return defaultValue;
  }
  return saved.value;
}

export const setStorageValue = (key, value, version) => {
  if (value === null) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, JSON.stringify({ version, value }));
  }
};

/**
 * A hook used to persist and recall a value using browser localStorage
 *
 * @param {string} key - Used as the unique key to store the value in localStorage
 * @param {any} defaultValue - An initial value returned if no value is stored
 * @param {number} version - An integer representing the current version of the value format.
 *                           Incrementing this value in the code will cause the hook to return
 *                           the defaultValue upon load instead of the previously stored value
 *                           which can be used to migrate the value structure.
 */
const useLocalStorage = (key, defaultValue, version) => {
  const [keyVersion, setKeyVersion] = useState({ key, version });
  const [value, setValue] = useState(() => getStorageValue(key, defaultValue, version));

  // when key or version changes, synchronously fetch and return the value for the new key/version
  let returnValue = value;
  if (key !== keyVersion.key || version !== keyVersion.version) {
    setKeyVersion({ key, version });
    const nextValue = getStorageValue(key, defaultValue, version);
    setValue(nextValue);

    returnValue = nextValue;
  }

  const setLocalStorageValue = useCallback((update) => {
    setValue((prev) => {
      const nextValue = typeof update === 'function' ? update(prev) : update;
      if (!Object.is(prev, nextValue)) {
        setStorageValue(key, nextValue, version);
      }

      return nextValue;
    });
  }, [key, version]);

  return [returnValue, setLocalStorageValue];
};

/**
 * A hook to read multiple keys from browser localStorage
 * @param {{ key: string, defaultValue: any, version: number }[]} keys
 */
export const useLocalStorageValues = (keys) => (
  useMemo(() => (
    Object.freeze(Object.fromEntries((keys ?? []).map(({ key, defaultValue, version }) => [key, getStorageValue(key, defaultValue, version)])))
  ), [keys])
);

export default useLocalStorage;
