import { pick, snakeCase } from 'lodash';
import { apiSlice } from 'redux/apiSlice';
import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { DEAL_CONTEXT_TAG, HOME_BUILDERS_TAG, HOME_MODEL_IMAGES_TAG, HOME_MODELS_TAG, SUBDIVISIONS_TAG } from './utils';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(subdivision) {
    return {
      fetchSubdivision: subdivision.query({
        query: (id) => ({ url: `/api/subdivisions/${id}` }),
        transformResponse: response => camelCaseKeys(response, 'info').data.attributes,
        providesTags: [SUBDIVISIONS_TAG, HOME_BUILDERS_TAG],
      }),
      fetchSubdivisions: subdivision.query({
        query: ({ include } = {}) => ({
          url: '/api/subdivisions',
          params: include?.map((v) => ['include[]', snakeCase(v)]),
        }),
        transformResponse: response => {
          const transformed = camelCaseKeys(response, 'info').data.map(obj => obj.attributes);
          // remove null values to be compatible with react-table's sortUndefined feature
          return JSON.parse(JSON.stringify(transformed, (_, v) => (v === null ? undefined : v)));
        },
        providesTags: (result, error, arg) => (
          [
            SUBDIVISIONS_TAG,
            ...(arg?.include?.includes('homeBuilder') ? [HOME_BUILDERS_TAG] : []),
            ...(arg?.include?.includes('homeModels') ? [HOME_MODELS_TAG, HOME_MODEL_IMAGES_TAG] : []),
          ]
        ),
      }),
      updateSubdivisions: subdivision.mutation({
        query: ({ subdivisions }) => ({
          url: '/api/subdivisions',
          method: 'POST',
          body: snakeCaseKeys({
            subdivisions: subdivisions.map((subdiv) => pick(
              subdiv,
              ['id', 'name', 'city', 'state', 'zip', 'enabled', 'annualHoa', 'taxRate', 'elementarySchoolRating', 'juniorHighSchoolRating', 'highSchoolSchoolRating', 'description', 'restrictions', 'amenities', 'internalNotes'],
            )),
          }),
        }),
        invalidatesTags: [SUBDIVISIONS_TAG],
      }),
      fetchSubdivisionScenarios: subdivision.query({
        query: ({ portfolio: { id: portfolioId }, subdivision: { id: subdivisionId } }) => ({
          url: `/api/subdivisions/${subdivisionId}/scenarios`,
          params: snakeCaseKeys({ portfolioId }),
        }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: [DEAL_CONTEXT_TAG],
      }),
    };
  },
});

export const {
  useFetchSubdivisionQuery,
  useFetchSubdivisionsQuery,
  useUpdateSubdivisionsMutation,
  useFetchSubdivisionScenariosQuery,
} = extendedApiSlice;
