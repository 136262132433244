import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearDealNavigationModal, generatePurchaseAgreementForDeal } from 'actions/deal_navigation';
import { getDocumentTemplates } from 'actions/document_templates';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { selectPurchaseAgreementGeneratedById } from 'components/DealConstruction/dealConstruction';
import Modal from 'components/Modal';

export default function GeneratePurchaseAgreementModal({ context }) {
  const { data, modelData } = context;
  const { deal, scenario: primaryScenario, property } = data;
  const dispatch = useDispatch();
  const { alert, saving } = useSelector(state => state.navigation.modal);
  const documentTemplates = useSelector(state => state.documentTemplates.data);
  const purchaseAgreementGenerated = useSelector(state => selectPurchaseAgreementGeneratedById(state, { id: primaryScenario?.id }));
  const [documentTemplateId, setDocumentTemplateId] = useState();

  const onClose = () => dispatch(clearDealNavigationModal());

  useEffect(() => dispatch(getDocumentTemplates()), []);

  const handleDocumentTemplateChange = (selectedItem) => {
    setDocumentTemplateId(selectedItem.id);
  };
  const scenario = modelData?.model?.scenario;

  const address = property?.address;
  return (
    <Modal show onClose={onClose}>
      <div className="w-96">
        <div className="w-56">
          <h1 className="mb-8 text-left text-2xl font-semibold">Generate Purchase Agreement</h1>
        </div>
        {purchaseAgreementGenerated ? (
          <p>
            Purchase Agreement has already been generated.
          </p>
        ) : (
          <>
            <span>
              Generating a Purchase Agreement will lock
              the current scenario
              from further modifications.
            </span>
            {alert && <Alert className="mt-3" {...alert} />}
            <div className="mt-8 mb-1 flex gap-x-4 font-semibold text-sm text-gray-500">
              TEMPLATE
            </div>
            <div className="flex gap-x-4">
              <div className="flex items-center">
                <Dropdown width="w-96" optionsWidth="w-96" height="h-12" options={documentTemplates} selectedItem={documentTemplates?.find((documentTemplate) => documentTemplate.id === documentTemplateId)} setSelectedItem={handleDocumentTemplateChange} />
              </div>
            </div>
            <div className="mt-8 flex gap-x-4">
              {documentTemplateId ? (
                <Button confirm className="font-semibold border border-black border-opacity-12" text="Generate" onClick={() => dispatch(generatePurchaseAgreementForDeal({ deal, scenario, documentTemplateId, address }))} isLoading={saving} />
              ) : (
                <Button confirm disabled className="font-semibold border border-black border-opacity-12" text="Generate" />
              )}
              <Button transparent text="Cancel" className="font-semibold text-black border border-black border-opacity-12" shadow={false} onClick={onClose} />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
