import { useCallback } from 'react';
import { useSetColumnFilters } from 'components/shared/Table/DataTableContext';
import Filter from './Filter';

export default function ReactTableFilter({ children }) {
  const { setColumnFilters, resetColumnFilters, clearColumnFilters } = useSetColumnFilters();

  const onApply = useCallback((_, filterStates) => {
    setColumnFilters(Object.entries(filterStates).map(([id, value]) => ({ id, value })));
  }, [setColumnFilters]);

  return (
    <Filter root onApply={onApply} onClear={clearColumnFilters} onReset={resetColumnFilters}>
      {children}
    </Filter>
  );
}
