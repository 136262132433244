/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from 'react';
import Alert from 'components/Alert';
import Input from 'components/Input';
import RoundButton from 'components/common/RoundButton';
import { Plus, X } from 'components/icons';
import { parseEventValue, splitAndCapitalize } from 'components/utils';
import Select from 'components/Select';
import Button from 'components/shared/NewButton';
import NumberInput from 'components/shared/NumberInput';
import { noop } from 'lodash';

const OTHER_LOAN_TYPE = 'other';

const LOAN_TYPE_OPTIONS = [
  ['select_loan_type', 'Select Loan Type', true],
  ['fha', 'FHA'],
  ['va', 'VA'],
  ['usda', 'Conventional'],
  ['conventional', 'Cash'],
  ['other', 'Other'],
];

const STATUS_OPTIONS = [
  ['received', 'Received'],
  ['presented_to_seller', 'Presented to Seller'],
  ['seller_counter', 'Seller Counter'],
  ['buyer_counter', 'Buyer Counter'],
  ['rejected', 'Rejected'],
  ['accepted', 'Accepted'],
  ['terminated', 'Terminated'],
  ['re_opened', 'Re-opened'],
];

function StatusSelect({ value, onChange }) {
  return (
    <SelectField
      id="status"
      label="Offer Status"
      options={STATUS_OPTIONS}
      value={value}
      onChange={onChange}
    />
  );
}

function TextInput({ id, label, onChange, value }) {
  return (
    <div className="flex flex-col gap-y-1">
      <label htmlFor={id} className="text-xs font-medium cursor-pointer">{label}</label>
      <Input
        className="w-full h-13 font-normal text-base text-neutral-dark border border-neutral-400 focus:border-neutral-600 outline-none rounded px-2"
        id={id}
        onChange={onChange}
        type="text"
        name={id}
        value={value || ''}
      />
    </div>
  );
}

function NumberInputField({ id, label, onChange, value, prefix = '', suffix = '' }) {
  return (
    <div className="flex flex-col gap-y-1">
      <label htmlFor={id} className="text-xs font-medium cursor-pointer">{label}</label>
      <NumberInput
        prefix={prefix}
        thousandSeparator=","
        className="w-full h-13 font-normal text-base text-neutral-dark border border-neutral-400 focus:border-neutral-600 outline-none rounded px-2"
        id={id}
        onChange={onChange}
        type="number"
        name={id}
        value={value || ''}
        suffix={suffix}
      />
    </div>
  );
}

function SelectField({ id, label, options, value, onChange }) {
  return (
    <div className="flex flex-col gap-y-1">
      <label htmlFor={id} className="text-xs font-medium cursor-pointer">{label}</label>
      <Select
        id={id}
        className="w-full font-normal text-base text-neutral-dark border border-neutral-400 focus:border-neutral-600 outline-none rounded px-2"
        onChange={onChange}
        name={id}
        options={options}
        value={value}
      />
    </div>
  );
}

function DateInputField({ id, label, value, onChange }) {
  return (
    <div className="flex flex-col gap-y-1">
      <label htmlFor={id} className="text-xs font-medium cursor-pointer">{label}</label>
      <Input
        type="date"
        id={id}
        name={id}
        className="w-full h-13 font-normal text-base text-neutral-dark border border-neutral-400 focus:border-neutral-600 outline-none rounded px-2"
        value={value ? new Date(value).toISOString().split('T')[0] : ''}
        onChange={onChange}
      />
    </div>
  );
}

function AttachmentsInput({ attachments, setAttachments }) {
  const handleAddAttachments = (e) => {
    const files = Array.from(e.target.files);
    setAttachments((prev) => [...prev, ...files]);
  };

  const handleRemoveAttachment = (index) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="flex flex-col gap-y-3">
      <label htmlFor="attachments" className="text-xs font-medium cursor-pointer">Attachments</label>
      <div className="flex items-center gap-2">
        <input
          type="file"
          name="attachments"
          id="attachments"
          multiple
          className="hidden"
          onChange={handleAddAttachments}
        />
        <label htmlFor="attachments" className="bg-blue-500 text-white px-4 py-2 rounded cursor-pointer flex items-center gap-2">
          <Plus className="w-4 h-4" />
          Add Files
        </label>
      </div>
      <div className="flex flex-wrap gap-2 mt-2">
        {attachments.map((file, index) => (
          <div key={index} className="flex items-center bg-gray-100 border border-gray-300 p-2 rounded">
            <span className="text-sm mr-2">{file.name}</span>
            <button
              type="button"
              className="text-red-500 hover:text-red-700"
              onClick={() => handleRemoveAttachment(index)}
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

function OfferForm({
  alert,
  includeAttachments = false,
  isLoading,
  offerData,
  onClose = noop,
  onSubmit,
  setAlert,
  setOfferData,
  showCloseAction = false,
  title = '',
}) {
  const [attachments, setAttachments] = useState([]);

  const handleOnChange = (event) => {
    const param = event.target.name;
    const newValue = event.target.type === 'file' ? event.target.files : parseEventValue(event);
    setOfferData((prevData) => ({ ...prevData, [param]: newValue }));
  };

  const handleSubmit = async () => {
    setAlert(null);

    // List of required fields based on offer status
    const requiredFields = [
      'buyerAgentEmail', 'price', 'closeOfEscrowDate', 'earnestMoneyDeposit',
      'financingContingencyDays', 'appraisalContingencyDays', 'loanType',
    ];

    // Dynamically add fields based on status
    if (offerData.status === 'rejected') requiredFields.push('rejectionReason');
    else if (offerData.status === 'terminated') requiredFields.push('terminationReason');
    else if (offerData.status === 're_opened') requiredFields.push('reOpenedReason');
    if (offerData.loanType === OTHER_LOAN_TYPE && !offerData.otherLoanType) requiredFields.push('otherLoanType');

    const missingFields = requiredFields.filter((field) => !offerData[field]);

    if (missingFields.length > 0) {
      setAlert({
        type: 'danger',
        text: `Please fill in all the required fields: ${missingFields
          .map((f) => splitAndCapitalize(f))
          .join(', ')}`,
      });
      return;
    }

    try {
      await onSubmit({ ...offerData, attachments });
    } catch (e) {
      console.error('Failed to save offer:', e);
      setAlert({
        type: 'danger',
        text: 'An error occurred while saving the offer. Please try again.',
      });
    }
  };

  return (
    <div className="w-full bg-white p-4 overflow-y-auto z-50 h-auto">
      {showCloseAction && (
        <div className="flex justify-between border-b border-gray-300">
          <h2 className="text-xl mb-4">{title}</h2>
          <RoundButton padding="h-7 w-7 border border-neutral-dark border-opacity-12" onClick={onClose}>
            <X className="h-4 w-4 mx-auto" />
          </RoundButton>
        </div>
      )}

      <div className="flex flex-col space-y-4 mt-4">
        {/* Conditional fields based on status */}
        {offerData.id && <StatusSelect value={offerData.status} onChange={handleOnChange} />}

        {offerData.status === 'rejected' && <TextInput id="rejectionReason" label="Rejection Reason" onChange={handleOnChange} value={offerData.rejectionReason} />}
        {offerData.status === 'terminated' && <TextInput id="terminationReason" label="Termination Reason" onChange={handleOnChange} value={offerData.terminationReason} />}
        {offerData.status === 're_opened' && <TextInput id="reOpenedReason" label="Re-opened Reason" onChange={handleOnChange} value={offerData.reOpenedReason} />}

        <TextInput id="buyerAgentName" label="Buyer Agent Name" onChange={handleOnChange} value={offerData.buyerAgentName} />
        <TextInput id="buyerAgentEmail" label="Buyer Agent Email" onChange={handleOnChange} value={offerData.buyerAgentEmail} />

        {includeAttachments && <AttachmentsInput attachments={attachments} setAttachments={setAttachments} />}

        <NumberInputField id="price" label="Offer Price" prefix="$" onChange={handleOnChange} value={offerData.price} />
        <DateInputField id="closeOfEscrowDate" label="Close of Escrow Date" onChange={handleOnChange} value={offerData.closeOfEscrowDate} />
        <NumberInputField id="earnestMoneyDeposit" label="Earnest Money Deposit" prefix="$" onChange={handleOnChange} value={offerData.earnestMoneyDeposit} />
        <NumberInputField id="concession" label="Concession" prefix="$" onChange={handleOnChange} value={offerData.concession} />
        <NumberInputField id="financingContingencyDays" label="Financing Contingency Days" onChange={handleOnChange} value={offerData.financingContingencyDays} />
        <NumberInputField id="appraisalContingencyDays" label="Appraisal Contingency Days" onChange={handleOnChange} value={offerData.appraisalContingencyDays} />

        <SelectField
          id="loanType"
          label="Loan Type"
          options={LOAN_TYPE_OPTIONS}
          value={offerData.loanType || 'select_loan_type'}
          onChange={handleOnChange}
        />

        {offerData.loanType === OTHER_LOAN_TYPE && <TextInput id="otherLoanType" label="Other Loan Type" onChange={handleOnChange} value={offerData.otherLoanType} />}

        <TextInput id="otherNotes" label="Other Notes" onChange={handleOnChange} value={offerData.otherNotes} />

        {alert && <Alert className="mb-6 py-3" {...alert} />}

        <Button
          filled
          label="Submit Offer"
          className="mx-auto items-center justify-center"
          onClick={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default OfferForm;
