import { getMemoOptions } from '@tanstack/react-table';
import { throwingMemo } from '../table.helpers';
import createTableExtension from '../utils/createTableExtension';
import { getColumnVisibilityConfigurable } from './utils';

/**
 * Ordered visible leaf headers
 *
 * @type {(table: import('@tanstack/react-table').Table) => import('@tanstack/react-table').Header[]}
 */
const getVisibleOrderConfigurableLeafHeaders = createTableExtension((table) => (
  throwingMemo(
    () => [table.getLeafHeaders()],
    (headers) => (
      headers.filter(({ column }) => {
        const orderConfigurable = getColumnVisibilityConfigurable(column);
        return orderConfigurable !== false && orderConfigurable !== 'hidden';
      })
    ),
    getMemoOptions(table.options, 'debugHeaders', 'getVisibilityConfigurableLeafHeaders'),
  )
));

export default getVisibleOrderConfigurableLeafHeaders;
