import { useState } from 'react';
import { compact, isEmpty, isNaN, isNil, sum } from 'lodash';
import { useFetchPropertyLocalQuery } from 'redux/apiSlice';
import { Arrow, Chevron, Home, LoadingIndicator, MultiFamily } from 'components/icons';
import { formatAddress, formatCurrency, formatInteger, formatPercentage, titleCase } from 'components/utils';
import { PropertyLayoutContainer } from 'components/property/PropertyLayout';
import Badge from 'components/shared/Badge';
import Map from 'components/property/components/Map';
import MapModal from 'components/property/components/MapModal';
import StreetView from 'components/property/components/StreetView';
import { pastMonthCashFlowPartial, trailingYearCashFlowsPartial } from 'components/AssetManagement/helpers';
import ActualVsUnderwrittenChart from 'components/AssetManagement/ActualVsUnderwrittenChart';
import ExpensePieChart from 'components/AssetManagement/ExpensePieChart';
import RentGrowthChart from 'components/AssetManagement/RentGrowthChart';

const DEFAULT_MAP_OPTIONS = {
  fullscreenControl: false,
  zoomControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  keyboardShortcuts: false,
};

function SummaryHeader({ property }) {
  return (
    <div className="grid grid-cols-4 border-b mb-6 pb-4">
      <div className="col-span-3 flex flex-col justify-center items-start">
        <div className="flex gap-x-2 mb-1">
          <span className="text-xs text-gray-500">{titleCase(property.market)}</span>
          <Chevron direction="right" className="w-3 text-gray-500" />
          <span className="text-xs text-gray-600">{`${titleCase(property.county)} County`}</span>
        </div>
        <div className="flex items-center gap-x-4 text-lg">
          {formatAddress(property)}
          {property.isSingleFamily ? (
            <div className="h-full text-xs border px-2 py-1 flex gap-x-1 justify-center items-center rounded-lg">
              <Home className="w-4" />
              <span className="ml-1 text-xs font-medium">SF</span>
            </div>
          ) : (
            <div className="h-full text-xs border px-2 py-1 flex gap-x-1 justify-center items-center rounded-lg">
              <MultiFamily className="w-4" />
              <span className="ml-1 text-xs font-medium">MF</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const arrowFill = (value, reverse) => {
  if (reverse) {
    return value > 0 ? '#BA1A1A' : '#006D41';
  } else {
    return value > 0 ? '#006D41' : '#BA1A1A';
  }
};

function SummaryStat({ label, value, formatter, underwrittenValue, reverse = false, showMonthly = true }) {
  const percentChange = underwrittenValue && ((value - underwrittenValue) / underwrittenValue);
  return (
    <div>
      <div className="text-xs text-gray-500 mb-1">{label}</div>
      <div className="flex">
        <div>{formatter(value)}</div>
        {((isNil(underwrittenValue) || percentChange === 0 || isNaN(percentChange)) ? null : (
          <div className="flex items-center ml-1.5 text-xs text-gray-700">
            <Arrow direction={percentChange > 0 ? 'up' : 'down'} fill={arrowFill(percentChange, reverse)} className="w-4" />
            {`${formatPercentage(Math.abs(percentChange))} UW`}
          </div>
        ))}
      </div>
      {showMonthly && <div className="text-gray-500 text-xs">{`${formatter(value / 12)} / month`}</div>}
    </div>
  );
}

function SummaryStats({ t12Actual, t12Underwritten }) {
  const { trailingYearEgr, trailingYearTotalOpEx, trailingYearNoi } = t12Actual;
  const { trailingYearUnderwrittenEgr, trailingYearUnderwrittenTotalOpEx, trailingYearUnderwrittenNoi } = t12Underwritten;

  const statData = [{
    label: 'NOI',
    value: trailingYearNoi,
    underwrittenValue: trailingYearUnderwrittenNoi,
    formatter: formatCurrency,
  }, {
    label: 'OpEx Ratio',
    value: trailingYearTotalOpEx / trailingYearEgr,
    underwrittenValue: trailingYearUnderwrittenTotalOpEx / trailingYearUnderwrittenEgr,
    formatter: formatPercentage,
    reverse: true,
    showMonthly: false,
  }, {
    label: 'Revenue',
    value: trailingYearEgr,
    underwrittenValue: trailingYearUnderwrittenEgr,
    formatter: formatCurrency,
  }, {
    label: 'Expenses',
    value: trailingYearTotalOpEx,
    underwrittenValue: trailingYearUnderwrittenTotalOpEx,
    formatter: formatCurrency,
    reverse: true,
  }];

  return (
    <div>
      <div className="text-lg mb-3">T12 Summary</div>
      <div className="grid grid-cols-4 gap-x-4">
        {statData.map(s => (
          <SummaryStat
            key={s.label}
            label={s.label}
            value={s.value}
            underwrittenValue={s.underwrittenValue}
            formatter={s.formatter}
            reverse={s.reverse}
            showMonthly={s.showMonthly}
          />
        ))}
      </div>
    </div>
  );
}

function RentRollTableSection({ label, units, valueFunc, className = '', span = 'col-span-1' }) {
  return (
    <div className={span}>
      <div className="mb-1 text-xs text-gray-500">{label}</div>
      {units.map(unit => (
        <div key={unit.sourceId} className={`text-sm ${className}`}>{valueFunc(unit)}</div>
      ))}
    </div>
  );
}

function RentRollTable({ units, compRents }) {
  const descriptionFunc = unit => compact([
    !isNil(unit.bedrooms) ? `${unit.bedrooms} bd` : null,
    !isNil(unit.bathrooms) ? `${unit.bathrooms} ba` : null,
    !isNil(unit.rsf) ? `${formatInteger(unit.rsf)} sqft` : null,
  ]).join(' · ');

  const marketRentFunc = unit => {
    if (isNil(unit.bedrooms)) {
      return null;
    } else if (unit.bedrooms === 0) {
      return compRents.studio;
    } else if (unit.bedrooms >= 4) {
      return compRents['4Br'];
    } else {
      return compRents[`${unit.bedrooms}Br`];
    }
  };
  const percentDiffFunc = unit => {
    const marketRent = marketRentFunc(unit);
    if (isNil(unit.inPlaceRent) || isNil(marketRent) || (unit.inPlaceRent === marketRent)) {
      return '-';
    }
    const percentDiff = (unit.inPlaceRent - marketRent) / marketRent;
    return (
      <div className="flex items-center">
        <Arrow direction={percentDiff > 0 ? 'up' : 'down'} fill={arrowFill(percentDiff, false)} className="w-4" />
        {formatPercentage(Math.abs(percentDiff))}
      </div>
    );
  };

  return (
    <div className="grid grid-cols-6 gap-x-4">
      <RentRollTableSection label="Unit Name" units={units} valueFunc={unit => unit.unitName || unit.number} />
      <RentRollTableSection
        label="Description"
        units={units}
        valueFunc={descriptionFunc}
        span="col-span-2"
      />
      <RentRollTableSection
        label="In-Place"
        units={units}
        valueFunc={unit => formatCurrency(unit.inPlaceRent)}
        className="text-right"
      />
      <RentRollTableSection
        label="Market"
        units={units}
        valueFunc={unit => formatCurrency(marketRentFunc(unit))}
        className="text-right"
      />
      <RentRollTableSection
        label="% Diff"
        units={units}
        valueFunc={percentDiffFunc}
        className="text-right"
      />
    </div>
  );
}

export default function Summary({ propertyManagementRecord }) {
  const [showMapModal, setShowMapModal] = useState(false);

  const { cashFlows, inPlaceRent, marketRent, occupancyRate, parcels, property, underwrittenCashFlows, units } = propertyManagementRecord;
  let fipsApn = null;
  if (parcels && parcels[0]) {
    const parcel = parcels[0];
    fipsApn = parcel.fipsApn || `${parcel.combinedFipsCode}_${parcel.apn}`;
  }

  const { data: localData, isLoading: isLoadingRentIndex } = useFetchPropertyLocalQuery({ fipsApn, propertyId: property?.id }, { skip: !fipsApn });

  // show placeholder if no cash flow data is available yet
  if (isEmpty(cashFlows)) {
    return (
      <PropertyLayoutContainer>
        <SummaryHeader property={property} />
        <div className="mb-8 grid grid-cols-2 gap-x-8">
          <div className="h-80 rounded-lg">
            <StreetView property={property} setShowMapModal={setShowMapModal} showMapModal={showMapModal} />
          </div>
          <div className="h-80 relative">
            <Map properties={[property]} options={DEFAULT_MAP_OPTIONS} borderRadius="0.5rem" />
            <MapModal setShowMapModal={setShowMapModal} showMapModal={showMapModal} property={property}>
              <Map properties={[property]} />
            </MapModal>
            <Badge label="Click to expand" className="cursor-pointer absolute bottom-5 right-5 bg-white border-2 shadow-lg" visible onClick={() => setShowMapModal(true)} />
          </div>
        </div>
        <div className="bg-white p-3 rounded">
          No data available
        </div>
      </PropertyLayoutContainer>
    );
  }

  const actualGrossRents = cashFlows.revenue.grossRents;
  const getTrailingYearActualCashFlows = trailingYearCashFlowsPartial(cashFlows);

  const t12Actual = {
    trailingYearEgr: sum(getTrailingYearActualCashFlows('revenue.effectiveGrossRevenues')),
    trailingYearTotalOpEx: sum(getTrailingYearActualCashFlows('expenses.totalOperatingExpenses')),
    trailingYearNoi: sum(getTrailingYearActualCashFlows('netOperatingIncome')),
  };

  const trailingYearControllable = sum(getTrailingYearActualCashFlows('expenses.controllableExpenses'));
  const trailingYearNonControllableBeforeTaxes = sum(getTrailingYearActualCashFlows('expenses.nonControllableExpensesBeforeTaxes'));
  const trailingYearTaxes = sum(getTrailingYearActualCashFlows('expenses.taxes'));

  let t12Underwritten,
    trailingYearUnderwrittenControllable,
    trailingYearUnderwrittenNonControllableBeforeTaxes,
    trailingYearUnderwrittenTaxes,
    underwrittenRent;

  if (!isEmpty(underwrittenCashFlows)) {
    const getUnderwrittenPastMonthValue = pastMonthCashFlowPartial(underwrittenCashFlows);
    const getTrailingYearUnderwrittenCashFlows = trailingYearCashFlowsPartial(underwrittenCashFlows);

    underwrittenRent = getUnderwrittenPastMonthValue('revenue.grossRents');

    t12Underwritten = {
      trailingYearUnderwrittenEgr: sum(getTrailingYearUnderwrittenCashFlows('revenue.effectiveGrossRevenues')),
      trailingYearUnderwrittenTotalOpEx: sum(getTrailingYearUnderwrittenCashFlows('expenses.totalOperatingExpenses')),
      trailingYearUnderwrittenNoi: sum(getTrailingYearUnderwrittenCashFlows('netOperatingIncome')),
    };

    trailingYearUnderwrittenControllable = sum(getTrailingYearUnderwrittenCashFlows('expenses.controllableExpenses'));
    trailingYearUnderwrittenNonControllableBeforeTaxes = sum(getTrailingYearUnderwrittenCashFlows('expenses.nonControllableExpensesBeforeTaxes'));
    trailingYearUnderwrittenTaxes = sum(getTrailingYearUnderwrittenCashFlows('expenses.taxes'));
  }

  return (
    <PropertyLayoutContainer>
      <SummaryHeader property={property} />
      <div className="mb-8 grid grid-cols-2 gap-x-8">
        <div className="h-80 rounded-lg">
          <StreetView property={property} setShowMapModal={setShowMapModal} showMapModal={showMapModal} />
        </div>
        <div className="h-80 relative">
          <Map properties={[property]} options={DEFAULT_MAP_OPTIONS} borderRadius="0.5rem" />
          <MapModal setShowMapModal={setShowMapModal} showMapModal={showMapModal} property={property}>
            <Map properties={[property]} />
          </MapModal>
          <Badge label="Click to expand" className="cursor-pointer absolute bottom-5 right-5 bg-white border-2 shadow-lg" visible onClick={() => setShowMapModal(true)} />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-8">
        <div>
          <div className="pb-3 border-b">
            <SummaryStats t12Actual={t12Actual} t12Underwritten={t12Underwritten || {}} />
          </div>
          {/* <div className="text-sm my-4">Rent</div> */}
          <div className="mt-3 pb-3 border-b grid grid-cols-4">
            <SummaryStat label="In-Place Rent" value={inPlaceRent} formatter={formatCurrency} underwrittenValue={underwrittenRent} showMonthly={false} />
            <SummaryStat label="Market Rent" value={marketRent} formatter={formatCurrency} showMonthly={false} />
            <SummaryStat label="Occupancy" value={occupancyRate} formatter={formatPercentage} showMonthly={false} />
          </div>
          <div className="mt-3 grid grid-cols-4">
            <SummaryStat
              label="Total Operating"
              value={t12Actual.trailingYearTotalOpEx}
              formatter={formatCurrency}
              underwrittenValue={t12Underwritten?.trailingYearUnderwrittenTotalOpEx}
              reverse
              showMonthly
            />
            <SummaryStat
              label="Controllable"
              value={trailingYearControllable}
              formatter={formatCurrency}
              underwrittenValue={trailingYearUnderwrittenControllable}
              reverse
              showMonthly
            />
            <SummaryStat
              label="Non-Controllable"
              value={trailingYearNonControllableBeforeTaxes}
              formatter={formatCurrency}
              underwrittenValue={trailingYearUnderwrittenNonControllableBeforeTaxes}
              reverse
              showMonthly
            />
            <SummaryStat
              label="Taxes"
              value={trailingYearTaxes}
              formatter={formatCurrency}
              underwrittenValue={trailingYearUnderwrittenTaxes}
              reverse
              showMonthly
            />
          </div>
        </div>
        <div>
          <div className="text-lg mb-8">Net Operating Income</div>
          <ActualVsUnderwrittenChart
            cashFlows={cashFlows}
            underwrittenCashFlows={underwrittenCashFlows}
            path="netOperatingIncome"
          />
        </div>
      </div>
      <div className="mt-8 grid grid-cols-2 gap-x-8">
        {(units.length > 1) && (
          <div>
            <div className="text-lg mb-8">Rent Roll</div>
            <RentRollTable units={units} compRents={property.calculated.compRents} />
          </div>
        )}
        <div>
          {isLoadingRentIndex ? (
            <LoadingIndicator className="w-8 text-blue-400" />
          ) : (
            (cashFlows && localData) && <RentGrowthChart dates={cashFlows.dates} grossRents={actualGrossRents} rentIndex={localData.rentIndex} />
          )}
        </div>

      </div>
      <div className="mt-8 grid grid-cols-2 gap-x-8">
        {cashFlows.items?.controllableItems && (
          <div>
            <div className="text-lg mb-8">Expenses</div>
            <ExpensePieChart
              actualCashFlows={cashFlows}
              getTrailingYearActualCashFlows={getTrailingYearActualCashFlows}
            />
          </div>
        )}
      </div>
    </PropertyLayoutContainer>
  );
}
