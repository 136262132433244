import { sumBy } from 'lodash';
import { TR } from './Table';
import { formatCurrency } from '../../utils';

export default function AggregateRow({ units }) {
  const bedrooms = sumBy(units, 'bedrooms');
  const bathrooms = sumBy(units, 'bathrooms');
  const rsf = sumBy(units, 'rsf');
  const inPlaceRent = sumBy(units, 'inPlaceRent');
  const marketRent = sumBy(units, 'marketRent');

  const data = [
    units.length || '-',
    bedrooms?.toFixed(1) || '-',
    bathrooms?.toFixed(1) || '-',
    rsf?.toFixed() || '-',
    formatCurrency(Math.round(inPlaceRent)),
    formatCurrency(Math.round(marketRent)),
    formatCurrency(Math.round(marketRent - inPlaceRent)),
  ];

  return (
    <TR data={data} />
  );
}
