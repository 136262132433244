import { differenceInDays, parseISO } from 'date-fns';
import { identity, meanBy, sumBy } from 'lodash';

// TODO: verify
const LONG_DOM = 30;

export const activeFn = (dispositions, asOfDate) => dispositions.filter(({ attributes: { closedAt, deletedAt } }) => (!closedAt || (parseISO(closedAt) > asOfDate)) && (!deletedAt || (parseISO(deletedAt) > asOfDate)));
export const onMarketFn = (dispositions, asOfDate) => activeFn(dispositions, asOfDate).filter(({ attributes: { listingDate, acceptanceDate } }) => {
  const isOnMarket = listingDate && (parseISO(listingDate) <= asOfDate);
  const inContract = acceptanceDate && (parseISO(acceptanceDate) <= asOfDate);
  return isOnMarket && !inContract;
});
export const inContractFn = (dispositions, asOfDate) => activeFn(dispositions, asOfDate).filter(({ attributes: { acceptanceDate, closingDate } }) => {
  const inContract = acceptanceDate && (parseISO(acceptanceDate) <= asOfDate);
  const closed = closingDate && (parseISO(closingDate) <= asOfDate);
  return inContract && !closed;
});
export const daysOnMarketFn = (disposition, asOfDate) => {
  const { attributes: { listingDate, acceptanceDate } } = disposition;
  if (!listingDate || (parseISO(listingDate) > asOfDate)) return null;
  return differenceInDays(acceptanceDate ? parseISO(acceptanceDate) : asOfDate, parseISO(listingDate));
};
export const daysInContractFn = (disposition, asOfDate) => {
  const { attributes: { acceptanceDate, closingDate } } = disposition;
  if (!acceptanceDate || (parseISO(acceptanceDate) > asOfDate)) return null;
  return differenceInDays(closingDate ? parseISO(closingDate) : asOfDate, parseISO(acceptanceDate));
};
export const closingWithinFn = (dispositions, asOfDate, referenceDate) => activeFn(dispositions, asOfDate).filter(({ attributes: { closingDate } }) => {
  if (!closingDate) return false;
  const closeDateWithinDate = parseISO(closingDate) <= referenceDate;
  const notClosed = parseISO(closingDate) >= asOfDate;
  return closeDateWithinDate && notClosed;
});
export const longDomFn = (dispositions, asOfDate) => activeFn(dispositions, asOfDate).filter(dispo => {
  const dom = daysOnMarketFn(dispo, asOfDate);
  return dom && (dom > LONG_DOM);
});

export const calculateAverageRoi = (dispositions) => {
  const estimatedProfitSum = sumBy(dispositions, 'attributes.estimatedProfit');
  const averageAcquisitionBasisSum = sumBy(dispositions, 'attributes.averageAcquisitionBasis');

  return estimatedProfitSum / averageAcquisitionBasisSum;
};

export const calculateAverageOfferToListRatio = (dispositions) => {
  const filteredData = dispositions.filter(dispo => dispo.attributes.offerToListRatio);
  const average = meanBy(filteredData, 'attributes.offerToListRatio');
  return 1 + average / 100;
};

export const metricFunc = (label, statFunc, asOfDate, lastWeekDate, lastMonthDate, filteredDispositions, oneWeekAgoFilteredDispositions, oneMonthAgoFilteredDispositions, formatter = identity) => ({
  label,
  value: formatter(statFunc(filteredDispositions, asOfDate)),
  lastWeekValue: formatter(statFunc(oneWeekAgoFilteredDispositions, lastWeekDate)),
  lastMonthValue: formatter(statFunc(oneMonthAgoFilteredDispositions, lastMonthDate)),
});
