import { cloneDeep, every, first, flatMap, meanBy } from 'lodash';
import { ACCOUNTING_METHOD_FOLLOW_ON } from './dcf';

export const standardizedUnit = (units) => {
  const firstUnit = first(units);

  return Object.assign(
    cloneDeep(firstUnit),
    {
      vacant: every(units, 'vacant'),
      rentControlled: every(units, 'rentControlled'),
      rsf: meanBy(units, 'rsf'),
      inPlaceRent: meanBy(units, 'inPlaceRent'),
      marketRent: meanBy(units, 'marketRent'),
      turnDowntime: meanBy(units, 'turnDowntime'),
      turnBudget: meanBy(units, 'turnBudget'),
      terminationCost: meanBy(units, 'terminationCost'),
      turnAccountingMethod: every(units, unit => unit.turnAccountingMethod === firstUnit.turnAccountingMethod) ? firstUnit.turnAccountingMethod : ACCOUNTING_METHOD_FOLLOW_ON,
    },
  );
};

export const standardizedUnits = (unitsByType) => (
  flatMap(unitsByType, unitsOfType => (
    unitsOfType.map(unit => Object.assign(cloneDeep(standardizedUnit(unitsOfType)), { number: unit.number }))
  ))
);
