import { isEmpty, slice } from 'lodash';
import { formattedLabel } from 'components/utils';
import { BATH_TYPE, BedBathFilter as BedBathFilterComponent } from 'components/shared/filters/BedBathFilter';
import ChipFilter from 'components/shared/filters/ChipFilter';

export default function BathFilter({ filterKey, filterParams, onApply, onReset, setFilter }) {
  const bathValues = slice(filterParams, 1, filterParams.length);
  const filtered = !isEmpty(bathValues);
  const label = formattedLabel('Bath', filterParams[0], bathValues);

  return (
    <ChipFilter
      label={label}
      filterKey={filterKey}
      filtered={filtered}
      filterParams={filterParams}
      onApply={onApply}
      onReset={onReset}
      setFilter={setFilter}
    >
      {({ filterState: [bathUseExact, ...bathFilter], setFilterState }) => (
        <BedBathFilterComponent
          type={BATH_TYPE}
          useExact={bathUseExact}
          filter={bathFilter}
          setFilterState={setFilterState}
        />
      )}
    </ChipFilter>
  );
}
