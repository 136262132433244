import { Link, Outlet, useParams } from 'react-router-dom';
import { Chevron } from 'components/icons';
import { titleCase } from 'components/utils';

export default function ImportBase() {
  const params = useParams();
  const path = params['*'];

  return (
    <div className="flex flex-col h-screen">
      <div className="flex items-center gap-x-3 h-20 px-6 border-b shadow">
        <Link className="cursor-pointer" to="/import">
          <div className="text-xl">Import</div>
        </Link>
        {path && (
          <>
            <Chevron direction="right" className="w-4 text-gray-500" />
            <div className="text-xl text-gray-700">{titleCase(path.split('/')[0])}</div>
          </>
        )}
      </div>
      <div className="bg-gray-100 flex-1 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
}
