import { useState } from 'react';
import { camelCaseKeys, formatCurrency, formatListingSource, formatPercentage } from 'components/utils';
import { isEmpty, isString, sortBy, upperCase } from 'lodash';
import { parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { listingPath } from 'components/routes';
import { ABBR_MONTHS } from 'components/constants';
import { LoadingIndicator } from 'components/icons';
import { useFetchListingDataQuery } from 'redux/apiSlice';
import PhotoModal from './PhotoModal';
import RecordGrid, { RecordGridCell, RecordGridColumn } from './RecordGrid';
import RecordModal from './RecordModal';
import RecordPhotos from './RecordPhotos';
import RecordSubheading from './RecordSubheading';

const formatSource = (listing) => `${formatListingSource(listing.source)} - #${upperCase(listing.sourceId?.toUpperCase())}`;

function Header({ listing }) {
  const date = parseISO(listing.closedOn || listing.updatedAt);
  const month = ABBR_MONTHS[date.getMonth() - 1];
  const day = date.getDay();
  const status = listing.standardStatus || listing.status;
  const price = listing.closePrice || listing.listPrice;

  return (
    <div className="w-full flex flex-row justify-between items-center">
      <div className="w-9/12 flex">
        <div className="flex flex-col rounded-xl bg-primary-100 text-primary-7000 px-4 py-2 justify-evenly items-center text-primary-600 font-normal tracking-widest">
          <span>{month}</span>
          <span className="text-xl">{day}</span>
        </div>
        <div className="flex flex-col justify-center items-start ml-4">
          <div className="flex items-baseline">
            <span className="font-medium text-lg text-black">Listing</span>
            <span className="pl-2 font-medium text-md text-black">{status && `(${status})`}</span>
          </div>
          <span className="font-light text-xs">{formatSource(listing)}</span>
        </div>
        <div className="w-3/12 ml-10 flex flex-row justify-start items-center">
          <div className="px-4 py-2 bg-gray-50 border rounded-xl font-medium text-sm text-black">{ formatCurrency(price) }</div>
          {/* TODO: add % price change */}
        </div>
      </div>
      <div className="w-3/12 flex justify-end items-center">
        <Link to={listingPath({ id: listing.listingModelId || listing.id })} target="_blank" className="px-6 cursor-pointer">
          <span className="text-sm">View Listing Page</span>
        </Link>
      </div>
    </div>
  );
}

function InfoGrid({ listing }) {
  const isSingleFamily = true; // TODO: replace with better logic

  // TODO: a lot of duplicate field access to handle data coming from model or data lake
  // we can clean this up once we move everything over to data lake listings

  return (
    <>
      <RecordGrid>
        <RecordGridColumn>
          <RecordGridCell label="Status" value={listing.status} />
          <RecordGridCell label="MLS Source" value={formatSource(listing)} />
          <RecordGridCell label="Type" value={listing.propertyType} />
          <RecordGridCell label="Cumulative Days on Market" value={listing.cumulativeDaysOnMarket} />
          <RecordGridCell label="Cancellation Date" value={listing.cancellationDate} />
          <RecordGridCell label="Closed Date" value={listing.closeDate || listing.closedOn} />
          <RecordGridCell label="Concessions Amount" value={listing.concessionsAmount} />
          <RecordGridCell label="Concessions Comments" value={listing.concessionsAmount} />
        </RecordGridColumn>
        <RecordGridColumn>
          <RecordGridCell label="Off Market Date" value={listing.offMarketTimestamp} />
          <RecordGridCell label="On Market Date" value={listing.onMarketDate || listing.listedOn} />
          <RecordGridCell label="Purchase Contract Date" value={listing.purchaseContractDate} />
          <RecordGridCell label="Subtype" value={listing.propertySubType} />
          <RecordGridCell label="Contingencies" value={listing.Contingency} />
          <RecordGridCell label="Disclosures" value={listing.disclosures} />
          <RecordGridCell label="Special Listing Conditions" value={listing.specialListingConditions} />
        </RecordGridColumn>
        <RecordGridColumn>
          <RecordGridCell label="Price Closing" value={formatCurrency(listing.closePrice)} />
          <RecordGridCell label="Price Listing" value={formatCurrency(listing.listPrice || listing.price)} />
          <RecordGridCell label="Price Previous Listing" value={formatCurrency(listing.previousListPrice)} />
          <RecordGridCell label="Original Price" value={formatCurrency(listing.originalListPrice)} />
          <RecordGridCell label="Price Listing Low" value={formatCurrency(listing.listPriceLow)} />
          <RecordGridCell label="Listing URL" value={listing.listingUrl} />
          <RecordGridCell label="Listing Terms" value={listing.listingTerms} />
        </RecordGridColumn>
      </RecordGrid>
      <RecordSubheading>Property Information</RecordSubheading>
      <RecordGrid>
        <RecordGridColumn>
          <RecordGridCell label="Number of Units" value={listing.numberOfUnitsTotal} />
          <RecordGridCell label="Leased Units" value={listing.numberOfUnitsLeased} />
          <RecordGridCell label="Rentable Building Area" value={listing.livingArea} />
          <RecordGridCell label="Additional APN Flag" value={listing.additionalParcelsYN} />
          <RecordGridCell label="Additional APN" value={listing.additionalParcelsDescription} />
        </RecordGridColumn>
        <RecordGridColumn>
          <RecordGridCell label="New Construction Flag" value={listing.newConstructionYN} />
          <RecordGridCell label="Property Condition" value={listing.propertyCondition} />
          <RecordGridCell label="Interior Features" value={listing.interiorFeatures} />
          <RecordGridCell label="Number of Buildings" value={listing.numberOfBuildings} />
        </RecordGridColumn>
      </RecordGrid>
      {isSingleFamily ? (
        <>
          <RecordSubheading>HOA</RecordSubheading>
          <RecordGrid>
            <RecordGridColumn>
              <RecordGridCell label="HOA Flag" value={listing.associationYN} />
              <RecordGridCell label="HOA Amenities" value={listing.associationAmenities} />
            </RecordGridColumn>
            <RecordGridColumn>
              <RecordGridCell label="HOA Name" value={listing.associationName} />
              <RecordGridCell label="HOA Phone" value={listing.associationPhone} />
              <RecordGridCell label="HOA Fee" value={listing.associationFee} />
              <RecordGridCell label="HOA Fee Frequency" value={listing.associationFeeFrequency} />
            </RecordGridColumn>
            <RecordGridColumn>
              <RecordGridCell label="HOA 2 Name" value={listing.associationName2} />
              <RecordGridCell label="HOA 2 Phone" value={listing.associationPhone2} />
              <RecordGridCell label="HOA 2 Fee" value={listing.associationFee2} />
              <RecordGridCell label="HOA 2 Fee Frequency" value={listing.associationFee2Frequency} />
            </RecordGridColumn>
          </RecordGrid>
        </>
      ) : (
        <>
          <RecordSubheading>Financials</RecordSubheading>
          <RecordGrid>
            <RecordGridColumn>
              <RecordGridCell label="Current Financing" value={listing.currentFinancing} />
              <RecordGridCell label="Cap Rate" value={listing.capRate} />
              <RecordGridCell label="Buyer Financing" value={listing.buyerFinancing} />
            </RecordGridColumn>
            <RecordGridColumn>
              <RecordGridCell label="Operating Expense Type" value={formatPercentage(listing.operatingExpenseTypes) || '-'} />
              <RecordGridCell label="Other Expense Types" value={listing.otherExpense} />
              <RecordGridCell label="Other Income Types" value={listing.incomeIncludes} />
            </RecordGridColumn>
            <RecordGridColumn>
              <RecordGridCell label="Owner Pays" value={listing.ownerPays} />
              <RecordGridCell label="Rent Includes" value={listing.rentIncludes} />
              <RecordGridCell label="Tenant Paid Services" value={listing.tenantPays} />
            </RecordGridColumn>
          </RecordGrid>
        </>
      )}
    </>
  );
}

export function ListingDetails({ listing }) {
  return (
    <div className="flex flex-col gap-y-4 pt-2">
      <RecordSubheading>Listing Details</RecordSubheading>
      <div className="flex justify-between items-start py-4 border-b">
        <div className="w-1/2 h-full pr-3 flex flex-col justify-start items-start">
          <span className="uppercase tracking-wide text-xs pb-2">Public Remarks</span>
          <p className="text-xs text-gray-600 leading-5">
            {listing.remarks}
          </p>
        </div>
        <div className="w-1/2 h-full pl-3 flex flex-col justify-start items-start">
          <span className="uppercase tracking-wide text-xs pb-2">Private Remarks</span>
          <p className="text-xs text-gray-600 leading-5">
            {listing.listingPrivateRemarks}
          </p>
        </div>
      </div>
      <InfoGrid listing={listing} />
    </div>
  );
}

// standardize photo data:
// this is required because we are getting the photo data
// in potentially different formats from the data lake and DB
// and different formats from different providers
const parsePhotos = (listing) => {
  const listingObj = { ...listing };
  if (listingObj.media === 'null') {
    listingObj.media = null;
  }
  let listingPhotos = listingObj.media || listingObj.photos || listingObj.details?.photos || [];
  if (isString(listingPhotos)) {
    try {
      listingPhotos = JSON.parse(listingPhotos);
      if (isString(listingPhotos)) {
        listingPhotos = JSON.parse(listingPhotos);
      }
    } catch (err) {
      listingPhotos = [];
    }
  }
  listingPhotos = camelCaseKeys(listingPhotos).map(photo => ({ ...photo, url: (photo.url || photo.mediaUrl || photo.mediaurl) }));
  if (listingPhotos[0]?.order) {
    listingPhotos = sortBy(listingPhotos, 'order');
  }
  listingPhotos = listingPhotos.filter(photo => !['pdf'].includes(photo.mediaType?.toLowerCase()));
  return listingPhotos;
};

export default function ListingModal({ listingId, onClose }) {
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [primaryPhotoIndex, setPrimaryPhotoIndex] = useState(0);
  const { data: listing = {}, isLoading } = useFetchListingDataQuery({ listingId });

  if (isLoading) {
    return <LoadingIndicator className="h-5" />;
  }

  if (isEmpty(listing)) {
    return <div>No listing info found</div>;
  }

  const photos = parsePhotos(listing);

  return (
    <>
      <RecordModal
        show
        onClose={onClose}
        header={<Header listing={listing} />}
      >
        <RecordPhotos
          photos={photos}
          setPrimaryPhotoIndex={setPrimaryPhotoIndex}
          setShowPhotoModal={setShowPhotoModal}
        />
        <ListingDetails listing={listing} />
      </RecordModal>
      {showPhotoModal && (
        <PhotoModal
          photos={photos}
          primaryPhotoIndex={primaryPhotoIndex}
          setPrimaryPhotoIndex={setPrimaryPhotoIndex}
          showModal={showPhotoModal}
          setShowModal={setShowPhotoModal}
        />
      )}
    </>
  );
}
