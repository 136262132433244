import { round } from 'lodash';
import DataTable from 'components/shared/Table/DataTable';
import { tdClassNameFn, theadClassName } from 'components/NhimbleDashboard/Shared';
import { formatCurrency, formatPercentage } from 'components/utils';

const COLUMNS = [
  {
    header: '',
    accessorKey: 'market',
    meta: { textAlign: 'left' },
  },
  {
    header: 'Count',
    accessorKey: 'count',
  },
  {
    header: 'Overall Occupancy',
    accessorFn: row => `${formatPercentage(Number(row.overallOccupancy), 2)}`,
  },
  {
    header: 'Stabilized Occupancy',
    accessorFn: row => `${formatPercentage(Number(row.stabilizedOccupancy), 2)}`,
  },
  {
    header: 'Available Units',
    accessorKey: 'availableUnits',
  },
  {
    header: 'Avg. Dom',
    accessorFn: row => `${round(Number(row.averageDaysOnMarket), 2)}`,
  },
  {
    header: '# Long Dom',
    accessorKey: 'numberOfLongDom',
  },
  {
    header: 'Avg. Rent',
    accessorFn: row => `${formatCurrency(Number(row.averageRent))}`,
  },
  {
    header: 'Closing Ratio',
    accessorFn: row => `${formatPercentage(Number(row.averageClosingratio), 2)}`,
  },
];

const TABLE_META = { textAlign: 'center' };

export default function OverallMarketSummary({ overallMarketData }) {
  return (
    <div className="border">
      <DataTable
        columns={COLUMNS}
        data={overallMarketData}
        emptyStateComponent="There are no available properties matching your criteria"
        theadClassName={theadClassName}
        tdClassName={tdClassNameFn}
        meta={TABLE_META}
      />
    </div>
  );
}
