import MultiFamilySummaryTab from './MultiFamilySummaryTab';
import SingleFamilySummaryTab from './SingleFamilySummaryTab';

function CompSummaryTab({ subject, selectedUnitRents }) {
  const { isSingleFamily } = subject;

  return (
    <div className="mx-auto">
      <div className="text-base font-medium mb-4 text-neutral-dark text-left">Comp Summary</div>
      {isSingleFamily
        ? <SingleFamilySummaryTab selectedUnitRents={selectedUnitRents} subject={subject} />
        : <MultiFamilySummaryTab selectedUnitRents={selectedUnitRents} subject={subject} />}
    </div>
  );
}

export default CompSummaryTab;
