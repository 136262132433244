import { isEmpty, partition, sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { clearDealsModal } from 'actions/deals';
import { TASK_ATTACHMENT_TYPE } from 'components/constants';
import DeleteTaskModal from './DeleteTaskModal';
import EditTaskModal from './EditTaskModal';
import MilestoneHeading from './MilestoneHeading';
import RemoveTaskAttachmentModal from './RemoveTaskAttachmentModal';
import TabPanel from '../TabPanel';
import SubmitTaskResponseModal from './SubmitTaskResponseModal';
import TaskCard from './TaskCard';
import UploadAttachmentModal from '../UploadAttachmentModal';
import { generateTaskSortOrder } from '../milestone';
import SendTaskEmailModal from '../TaskEmail/TaskEmailModal';

function TaskSection({ tasks, label, fade = false, usersById }) {
  if (isEmpty(tasks)) {
    return null;
  }

  return (
    <>
      {label && <div className="mb-4 mt-8 text-sm font-medium tracking-wider text-gray-700">{label}</div>}
      {tasks.map((task) => (
        <TaskCard
          key={task.id}
          task={task}
          fade={fade}
          usersById={usersById}
        />
      ))}
    </>
  );
}

export default function MilestoneTasks({ currentUser, deal, milestones, tasks, usersById }) {
  const dispatch = useDispatch();
  const modal = useSelector(state => state.deals.modal);
  const { milestoneId } = useParams();

  const milestone = milestones.find(({ id }) => id === Number(milestoneId));
  if (!milestone) {
    return null;
  }

  const taskOrder = generateTaskSortOrder(deal.dealWorkflowTemplate, milestone);
  const milestoneTasks = sortBy(tasks.filter(task => task.milestoneId === milestone.id), ({ taskType }) => taskOrder[taskType]);

  const [blockedTasks, unblockedTasks] = partition(milestoneTasks, 'isBlocked');

  return (
    <TabPanel heading={<MilestoneHeading milestone={milestone} />}>
      <TaskSection tasks={unblockedTasks.filter(({ completedAt }) => !completedAt)} usersById={usersById} />
      <TaskSection fade tasks={blockedTasks.filter(({ completedAt }) => !completedAt)} label="Blocked Tasks" usersById={usersById} />
      <TaskSection fade tasks={milestoneTasks.filter(({ completedAt }) => completedAt)} label="Completed Tasks" usersById={usersById} />

      {modal.updateTask && <EditTaskModal milestone={milestone} />}
      {modal.destroyTask && <DeleteTaskModal />}
      {modal.sendTaskEmail && <SendTaskEmailModal currentUser={currentUser} task={modal.task} />}
      {modal.submitTaskResponse && <SubmitTaskResponseModal />}
      {modal.createTaskAttachment && (
        <UploadAttachmentModal
          attachmentType={TASK_ATTACHMENT_TYPE}
          task={modal.task}
          onClose={() => dispatch(clearDealsModal())}
        />
      )}
      {modal.destroyTaskAttachment && <RemoveTaskAttachmentModal />}
    </TabPanel>
  );
}
