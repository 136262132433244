import { compact, groupBy, isArray, mapValues, mean, mergeWith, some, uniq } from 'lodash';
import { LAYOUT } from 'components/constants';
import { formatCurrency, formatInteger, metersSquaredToMilesSquared } from 'components/utils';
import DataTable from 'components/shared/Table/DataTable';
import { DemographicCell } from 'components/property/Neighborhood/DemographicsComparisonTable';
import { calcChange } from 'components/property/Neighborhood/neighborhood.utils';

const getColumns = markets => [
  {
    header: '',
    accessorKey: 'demographicLabel',
    enableSorting: false,
  },
  {
    header: 'Portfolio',
    accessorKey: 'portfolio',
    cell: DemographicCell,
    meta: { className: 'border-l', textAlign: 'center' },
    enableSorting: false,
  },
  ...compact(markets).sort().map(market => ({
    header: market,
    accessorKey: market,
    cell: DemographicCell,
    meta: { className: 'border-l', textAlign: 'center' },
    enableSorting: false,
  })),
];

const formatTableData = properties => {
  // some properties don't have census tract or zip-level demographic data, so filter those out
  const propertyLocalDemographicData = compact(properties.map(p => p.data.demographics?.censusTract || p.data.demographics?.zip));

  const portfolioDemographicValues = propertyLocalDemographicData.reduce(
    (acc, propertyDemographics) => mergeWith(acc, propertyDemographics, (accValue, propValue) => (accValue || []).concat([propValue])),
    {}
  );
  const portfolioDemographics = mapValues(portfolioDemographicValues, mean);

  const propertiesByMarket = groupBy(properties, 'market');
  const markets = Object.keys(propertiesByMarket);

  return [
    {
      demographicKey: 'householdCount',
      demographicLabel: 'Households / sq mi',
      portfolio: portfolioDemographics.householdCount / metersSquaredToMilesSquared(portfolioDemographics.area),
      formatter: formatInteger,
    },
    {
      demographicKey: 'totalPopulationCount',
      demographicLabel: 'Population / sq mi',
      portfolio: portfolioDemographics.totalPopulationCount / metersSquaredToMilesSquared(portfolioDemographics.area),
      formatter: formatInteger,
    },
    {
      demographicKey: ['totalPopulationCount', 'population5YearForecast'],
      demographicLabel: '5-Yr Proj Pop Growth',
      portfolio: calcChange(portfolioDemographics, 'totalPopulationCount', 'population5YearForecast'),
      isProjection: true,
    },
    {
      demographicKey: 'averageHouseholdIncome',
      demographicLabel: 'Avg Household Income',
      portfolio: portfolioDemographics.averageHouseholdIncome,
      formatter: formatCurrency,
    },
    {
      demographicKey: ['averageHouseholdIncome', 'averageHouseholdIncome5YearForecast'],
      demographicLabel: '5-Yr Proj Avg Inc Growth',
      portfolio: calcChange(portfolioDemographics, 'averageHouseholdIncome', 'averageHouseholdIncome5YearForecast'),
      isProjection: true,
    },
    {
      demographicKey: 'medianHouseholdIncome',
      demographicLabel: 'Median Household Income',
      portfolio: portfolioDemographics.medianHouseholdIncome,
      formatter: formatCurrency,
    },
    {
      demographicKey: ['medianHouseholdIncome', 'medianHouseholdIncome5YearForecast'],
      demographicLabel: '5-Yr Proj Median Inc Growth',
      portfolio: calcChange(portfolioDemographics, 'medianHouseholdIncome', 'medianHouseholdIncome5YearForecast'),
      isProjection: true,
    },
    {
      demographicKey: 'populationMedianAge',
      demographicLabel: 'Median Age',
      portfolio: portfolioDemographics.populationMedianAge,
      formatter: formatInteger,
    },
    {
      demographicKey: ['populationMedianAge', 'medianAge5YearForecast'],
      demographicLabel: '5-Yr Proj Med Age',
      portfolio: calcChange(portfolioDemographics, 'populationMedianAge', 'medianAge5YearForecast'),
      isProjection: true,
    },
    // NOTE: commenting out because Cherre data looks incorrect for these fields
    // {
    //   demographicKey: 'laborForceUnemploymentRate',
    //   demographicLabel: 'Unemployment Rate',
    //   portfolio: adjustedLaborForceUnemploymentRate(portfolioDemographics.laborForceUnemploymentRate),
    //   formatter: formatPercentage,
    // },
    // {
    //   demographicKey: 'laborForceUnemploymentRate5YearForecastCount',
    //   demographicLabel: '5-Yr Proj Unemployment Rate',
    //   portfolio: calcChange(portfolioDemographics, 'laborForceUnemploymentRate', 'laborForceUnemploymentRate5YearForecastCount'),
    //   isProjection: true,
    // },
    {
      demographicKey: 'crimeTotalRisk',
      demographicLabel: 'Crime Index',
      portfolio: portfolioDemographics.crimeTotalRisk,
      formatter: formatInteger,
    },
  ].map(colData => {
    markets.forEach(market => {
      const propertyFromMarket = propertiesByMarket[market][0];
      if (propertyFromMarket.data.demographics?.msa) {
        let demographicValue = isArray(colData.demographicKey)
          ? calcChange(propertyFromMarket.data.demographics.msa, colData.demographicKey[0], colData.demographicKey[1])
          : propertyFromMarket.data.demographics.msa[colData.demographicKey];
        if (['householdCount', 'totalPopulationCount'].includes(colData.demographicKey)) {
          // adjust to density metric
          demographicValue /= metersSquaredToMilesSquared(propertyFromMarket.data.demographics.msa.area);
        }
        Object.assign(colData, { [market]: demographicValue });
      }
    });
    return colData;
  });
};

export default function PortfolioDealDemographics({ context }) {
  const { data } = context;
  const { parcels, properties } = data;

  let demographicsContents;
  const demographicsAvailable = some(properties, p => p.data.demographics);

  if (demographicsAvailable) {
    const markets = uniq(properties.map(p => p.market));
    const tableData = formatTableData(properties, parcels);
    demographicsContents = (
      <>
        <div className="mb-6 text-lg">Demographics Comparison</div>
        <DataTable data={tableData} columns={getColumns(markets)} tdClassName="py-1" />
      </>
    );
  } else {
    demographicsContents = <div className="text-gray-500">No demographics data available</div>;
  }

  return (
    <div
      className="p-6 overflow-auto"
      style={{
        width: `calc(100vw - ${LAYOUT.rightNavWidth + LAYOUT.sidebarWidth + LAYOUT.portfolioSidebarWidth}px)`,
        height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)`,
      }}
    >
      <div className="p-6 bg-white rounded">{demographicsContents}</div>
    </div>
  );
}
