import cx from 'classnames';
import { identity, isArray, sortBy } from 'lodash';
import { useFetchHomeModelUnderwritingParametersQuery } from 'redux/homeModelApiSlice';
import { LoadingIndicator } from 'components/icons';
import { formatCurrency, formatPercentage, titleCase } from 'components/utils';

const formatPercentageToHundredths = value => formatPercentage(value, 2);

const EXPENSE_FORMAT_MAP = {
  dollar_per_year: formatCurrency,
  percent_effective_gross_revenue: formatPercentageToHundredths,
  dollar_per_month_per_unit: formatCurrency,
};
const formatExpenseItemValue = expenseItem => EXPENSE_FORMAT_MAP[expenseItem.inputMethod](expenseItem.inputValue);
const displayItems = expenseItems => expenseItems.map(item => [item.name, formatExpenseItemValue(item), item.inputMethod].join(' • ')).join('\n');
const ENTRY_EXIT_FIELDS = {
  acquisitionCostMethod: identity,
  acquisitionCostPercent: formatPercentageToHundredths,
  costOfSalePercent: formatPercentageToHundredths,
  exitCapRate: formatPercentageToHundredths,
  holdPeriod: identity,
  purchasePricePercentOfList: (_, parameters) => formatPercentage(parameters.haircut),
};
const REVENUE_FIELDS = {
  belowTheLineExpenseItems: displayItems,
  collectionLossRate: formatPercentageToHundredths,
  downtime: identity,
  incomeItems: displayItems,
  releasingCost: formatCurrency,
  renewalProbability: formatPercentage,
  rentGrowthRate: formatPercentageToHundredths,
  rollToMarket: (_, parameters) => parameters.units[0].rollToMarket,
  leaseTerm: (_, parameters) => parameters.units[0].leaseTerm,
  vacancyRate: value => formatPercentageToHundredths(value, 2),
};
const EXPENSE_FIELDS = {
  expenseItems: displayItems,
  inflationRate: formatPercentageToHundredths,
};
const FINANCING_FIELDS = {
  amoritization: identity,
  couponRate: formatPercentageToHundredths,
  interestOnly: identity,
  loanOriginationFeeRate: formatPercentageToHundredths,
  loanSizingMethod: identity,
  ltv: formatPercentage,
  taxExpenseGrowthTiming: identity,
  term: identity,
};

function ParameterSection({ label, fields, parameters }) {
  return (
    <div className="mb-6">
      <h2 className="mb-6 text-lg text-gray-700 font-medium capitalize">{label}</h2>
      {sortBy(Object.keys(fields)).map((field, index) => (
        <div key={field} className={cx('py-2 flex items-center justify-between', { 'bg-gray-100': index % 2 === 1 })}>
          <div className="text-sm">{`${titleCase(field)}:`}</div>
          <div className={cx('grow text-right whitespace-pre-line', { 'text-sm': isArray(parameters[field]) })}>
            {fields[field](parameters[field], parameters)}
          </div>
        </div>
      ))}
    </div>
  );
}

export default function NewBuildUnderwriting() {
  const { data: underwritingParameters, isLoading } = useFetchHomeModelUnderwritingParametersQuery();

  if (isLoading) {
    return <LoadingIndicator className="text-gray-500 w-7 h-7" />;
  }

  return (
    <div className="w-1/2 h-screen overflow-y-scroll p-6 bg-white">
      <h1 className="text-2xl mb-8">New Build Underwriting Parameters</h1>
      <ParameterSection label="Entry & Exit" parameters={underwritingParameters} fields={ENTRY_EXIT_FIELDS} />
      <ParameterSection label="Revenue" parameters={underwritingParameters} fields={REVENUE_FIELDS} />
      <ParameterSection label="Expense" parameters={underwritingParameters} fields={EXPENSE_FIELDS} />
      <ParameterSection label="Financing" parameters={underwritingParameters} fields={FINANCING_FIELDS} />
    </div>
  );
}
