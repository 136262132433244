export function DetailPaneNoData({ children }) {
  return (
    <div className="flex flex-col justify-center items-center w-full h-2/3">
      <div className="text-gray-400 text-nowrap">
        {children ?? 'Select a row to see details'}
      </div>
    </div>
  );
}

/**
 * @param {import('react').ReactNode} subheading
 * @param {import('react').ReactNode} children
 */
export function DetailPaneHeading({ subheading, children }) {
  return (
    <div className="flex flex-col">
      {/* add margin to avoid overlapping close button */}
      <div className="text-2xl mr-12 break-words">{children}</div>
      <div className="empty:hidden text-gray-700 font-light break-words">{subheading}</div>
    </div>
  );
}

/**
 * @param {import('react').ReactNode} children
 */
export function DetailFields({ children }) {
  return (
    <div className="flex flex-col overflow-auto size-full">
      {children}
    </div>
  );
}

/**
 * @param {import('react').ReactNode} label
 * @param {import('react').ReactNode} children
 */
export function DetailField({ label, children }) {
  return (
    <>
      <div className="mt-6 mb-2 font-medium text-gray-700 uppercase tracking-wide text-xs whitespace-pre-wrap">{label}</div>
      <div className="text-sm font-light whitespace-pre-wrap">{children ?? '—'}</div>
    </>
  );
}
