import cx from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CircleInfo } from 'components/icons';
import { formatDate } from 'components/utils';

export function FutureDeliveriesGridHeader({ className, children }) {
  return (
    <div
      className={cx(
        'grid grid-rows-subgrid grid-cols-subgrid text-label-md text-neutral-medium font-medium uppercase text-center',
        className,
      )}
    >
      {children}
    </div>
  );
}

export function FutureDeliveriesGridCell({ className, children }) {
  return (
    <div className={cx('grid grid-rows-subgrid grid-cols-subgrid text-body-md text-neutral-dark text-center', className)}>
      {children}
    </div>
  );
}

function HomeModelFutureDeliveriesPopover({ row }) {
  const popoverTriggerRef = useRef();
  const popoverTriggerObserver = useRef();
  const [isPopoverTriggerVisible, setIsPopoverTriggerVisible] = useState(true);

  const popoverObserver = useRef();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  /** @type {import('react').RefCallback<HTMLDivElement>} */
  const popoverTriggerRefCb = useCallback((elem) => {
    popoverTriggerRef.current = elem;

    if (!elem) {
      popoverTriggerObserver.current?.disconnect();
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      const [{ isIntersecting }] = entries;
      setIsPopoverTriggerVisible(isIntersecting);
    });
    popoverTriggerObserver.current = observer;
    observer.observe(elem);
  }, []);

  /** @type {import('react').RefCallback<HTMLDivElement>} */
  const popoverRefCb = useCallback((elem) => {
    if (!elem) {
      popoverObserver.current?.disconnect();
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      const [{ isIntersecting }] = entries;
      setIsPopoverVisible(isIntersecting);
    });
    popoverObserver.current = observer;
    observer.observe(elem);
  }, []);

  const [positionState, setPositionState] = useState({});
  useEffect(() => {
    if (isPopoverVisible) {
      let rafCancelHandle;

      const updateLoop = () => {
        const { left, top } = popoverTriggerRef.current.getBoundingClientRect();
        setPositionState((prev) => {
          if (prev.x !== left || prev.y !== top) {
            return { x: left, y: top };
          }

          return prev;
        });
        rafCancelHandle = requestAnimationFrame(updateLoop);
      };

      updateLoop();

      return () => {
        cancelAnimationFrame(rafCancelHandle);
        requestAnimationFrame(() => setPositionState({}));
      };
    }
  }, [isPopoverVisible]);

  /** @type {import('react').CSSProperties} */
  const popoverStyle = useMemo(() => {
    if (positionState.x === undefined) {
      return undefined;
    }

    return {
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 'unset',
      right: 'unset',
      transform: `translateX(${positionState.x}px) translateY(${positionState.y}px) translateX(-50%) translateY(-100%)`,
      maxWidth: 'unset',
      maxHeight: 'unset',
      overflow: 'unset',
    };
  }, [positionState.x, positionState.y]);

  return (
    <div
      ref={popoverTriggerRefCb}
      className={cx('relative z-10 hover:z-30 self-center', isPopoverTriggerVisible && 'group/popover')}
    >
      <CircleInfo className="size-5" />
      <div
        ref={popoverRefCb}
        className="absolute left-0 bottom-full z-30 pb-2 hidden group-hover/popover:block max-w-px max-h-px overflow-clip"
        style={popoverStyle}
      >
        <div className="px-3 py-2 bg-white rounded-lg shadow-md">
          <div className="grid grid-rows-2 grid-flow-col tabular-nums *:p-2">
            <FutureDeliveriesGridHeader className="border-b">Available Now</FutureDeliveriesGridHeader>
            <FutureDeliveriesGridCell>{row.getValue('numAvailable')}</FutureDeliveriesGridCell>

            {row.getValue('futureDeliveries').flatMap(([date, value]) => [
              <FutureDeliveriesGridHeader key={date} className="border-b">{formatDate(date, 'MMM yyyy')}</FutureDeliveriesGridHeader>,
              <FutureDeliveriesGridCell key={`${date}-value`}>{value}</FutureDeliveriesGridCell>,
            ])}

            <FutureDeliveriesGridHeader className="border-b border-l">Total</FutureDeliveriesGridHeader>
            <FutureDeliveriesGridCell className="border-l">{row.getValue('totalAvailable')}</FutureDeliveriesGridCell>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function HomeModelFutureDeliveriesCell({ getValue, row }) {
  const cellValue = getValue();

  return (
    <div className={cx('inline-flex flex-row gap-x-1', !cellValue && 'opacity-20')}>
      {cellValue}
      {cellValue ? <HomeModelFutureDeliveriesPopover row={row} /> : null}
    </div>
  );
}
