import { map, sum } from 'lodash';
import { LAYOUT } from 'components/constants';
import { formatCurrency, formatCurrencyAbbreviated, formatInteger, formatNone, formatPercentage } from 'components/utils';
import ModelScenarioSelector from 'components/dcf/ModelScenarioSelector';

function ModelInfo({ data }) {
  return (
    <div className="flex h-full items-center divide-x">
      {map(data, ({ header, value1Label, value2Label, value1, value2, formatter = formatNone }) => (
        <div key={header} className="text-center font-medium px-4">
          <div className="mb-1 text-neutral-medium text-sm">{header}</div>
          <div className="flex gap-x-2">
            <div className="flex flex-col mx-auto">
              <div className="mb-0.5 text-neutral-dark text-base">{formatter(value1)}</div>
              {value1Label && <div className="text-neutral-medium text-xs ">{value1Label}</div>}
            </div>
            {value2Label && (
              <div className="flex flex-col text-success">
                <div className="mb-0.5 text-base">{formatter(value2)}</div>
                <div className="text-xs ">{value2Label}</div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

function ModelOverview({ modelParams, property, returnMetrics }) {
  const { dcfParams } = modelParams;
  const rsf = sum(map(dcfParams.units, (unit) => unit.rsf));

  const {
    unleveredEquityMultiple,
    unleveredAverageCashOnCash,
    unleveredIrr,
    leveredEquityMultiple,
    leveredAverageCashOnCash,
    leveredIrr,
    stabilizedYield,
  } = returnMetrics;
  const formattedUnleveredEquityMultiple = Math.round(unleveredEquityMultiple * 100) / 100 > 0 ? `${unleveredEquityMultiple.toFixed(2)}x` : '-';
  const formattedLeveredEquityMultiple = Math.round(leveredEquityMultiple * 100) / 100 > 0 ? `${leveredEquityMultiple.toFixed(2)}x` : '-';

  const leftData = [];
  const rightData = [];

  leftData.push(
    {
      header: 'Price',
      value1Label: 'Asking',
      value2Label: 'Model',
      value1: dcfParams.listPrice,
      value2: dcfParams.purchasePrice,
      formatter: formatCurrencyAbbreviated,
    },
    {
      header: 'PSF',
      value1Label: 'Asking',
      value2Label: 'Model',
      value1: (dcfParams.listPrice / rsf),
      value2: (dcfParams.purchasePrice / rsf),
      formatter: formatCurrency,
    },
  );

  rightData.push(
    {
      header: 'IRR',
      value1Label: 'Unlevered',
      value2Label: 'Levered',
      value1: unleveredIrr,
      value2: leveredIrr,
      formatter: formatPercentage,
    },
    {
      header: 'Equity Multiple',
      value1Label: 'Unlevered',
      value2Label: 'Levered',
      value1: formattedUnleveredEquityMultiple,
      value2: formattedLeveredEquityMultiple,
    },
    {
      header: 'Avg Cash Yield',
      value1Label: 'Unlevered',
      value2Label: 'Levered',
      value1: formatPercentage(unleveredAverageCashOnCash, 2),
      value2: formatPercentage(leveredAverageCashOnCash, 2),
    },
    {
      header: 'Stab. Net Yield',
      value1: formatPercentage(stabilizedYield, 2),
    },
  );

  return (
    <div style={{ height: `${LAYOUT.modelOverviewHeight}px` }} className="w-full px-4 py-2 bg-white flex justify-between items-center border-b">
      <ModelScenarioSelector modelParams={modelParams} />
      <ModelInfo data={leftData} />
      <ModelInfo data={rightData} />
    </div>
  );
}

export default ModelOverview;
