import {
  createContext,
  useCallback,
  useContext,
  useDebugValue,
  useEffect,
  useId,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import useOnce from 'hooks/useOnce';

const contextDefaultValue = Object.freeze([]);
const LayoutSlotsContext = createContext(contextDefaultValue);
LayoutSlotsContext.displayName = 'LayoutSlotsContext';

const passthroughSlotDefinition = Object.freeze({
  Component: function Passthrough({ children }) { return children; },
  settings: { autoAssign: true },
});

export function PassthroughSlot({ children }) {
  const [parent, setParent] = useContext(LayoutSlotsContext);
  if (setParent === undefined) {
    throw new Error('Parent LayoutSlotContext not found.');
  }

  const contextValue = useMemo(() => (
    [{ ...parent, passthrough: passthroughSlotDefinition }, setParent]
  ), [parent, setParent]);

  return (
    <LayoutSlotsContext.Provider value={contextValue}>
      {children}
    </LayoutSlotsContext.Provider>
  );
}

/**
 * @param {string} from
 * @param {string} to
 * @param {import('react').ReactNode} children
 */
export function RenameSlot({ from, to, children }) {
  const [parent, setParent] = useContext(LayoutSlotsContext);
  if (setParent === undefined) {
    throw new Error('Parent LayoutSlotContext not found.');
  }

  const contextValue = useMemo(() => {
    const { [from]: renamedSlot, ...rest } = parent;
    const renamed = renamedSlot ? { [to]: renamedSlot, ...rest } : { ...rest };
    return [renamed, setParent];
  }, [parent, setParent, from, to]);

  return (
    <LayoutSlotsContext.Provider value={contextValue}>
      {children}
    </LayoutSlotsContext.Provider>
  );
}

/**
 * @param {string} slotName
 * @param {import('react').ReactNode} children
 * @param {any} [props]
 */
export function AssignToSlot({ slotName, children, ...props }) {
  const [layouts] = useContext(LayoutSlotsContext);

  let slots;
  if (slotName) {
    slots = Object.hasOwn(layouts, slotName) ? [slotName, layouts[slotName]] : [];
  } else {
    slots = Object.entries(layouts).filter(([, { settings: { autoAssign } }]) => autoAssign);
  }

  return slots.map(([key, { Component, props: defaultProps }]) => (
    <Component {...defaultProps} {...props} key={key}>
      {children}
    </Component>
  ));
}

function PortalNode({ portalContainer, portalKey, children }) {
  return portalContainer ? createPortal(children, portalContainer, portalKey) : null;
}

/**
 * @param {string} name
 * @param {boolean} [autoAssign]
 * @return {import('react').RefCallback<HTMLElement>}
 */
export const useCreatePortalSlot = (name, autoAssign = true) => {
  useDebugValue(name);
  const [, setLayout] = useContext(LayoutSlotsContext);

  /** @type {import('react').RefCallback<HTMLElement>} */
  const refCallback = useCallback((portalContainer) => {
    if (portalContainer) {
      setLayout((prev) => ({ ...prev, [name]: { Component: PortalNode, props: { portalContainer }, settings: { autoAssign } } }));
    } else {
      setLayout(({ [name]: _, ...rest }) => rest);
    }
  }, [autoAssign, name, setLayout]);

  return refCallback;
};

function InitialRenderNode({ mount, unmount, rerender, children }) {
  const id = useId();

  useState(() => {
    mount?.({ id, children });
  });

  useEffect(() => {
    rerender?.({ id, children });
  }, [id, children, rerender]);

  useEffect(() => (
    () => { unmount?.({ id }); }
  ), [id, unmount]);

  return null;
}

/**
 * Prefer {@link useCreatePortalSlot}.
 * Use this if the parent component is incompatible with its children being in a portal.
 *
 * @param {string} name
 * @param {boolean} [autoAssign]
 * @return {() => import('react').ReactNode[]}
 */
export const useCreateNonPortalSlot = (name, autoAssign = true) => {
  useDebugValue(name);

  const [layout, setLayout] = useContext(LayoutSlotsContext);
  const [container, setContainer] = useState({});

  // useState callback used as a 1-time initialization function
  useState(() => {
    // mutate state directly so the children element is available on initial render
    const mount = ({ id, children }) => {
      container[id] = children;
    };
    layout[name] = { Component: InitialRenderNode, props: { mount }, settings: { autoAssign } };
  });
  useLayoutEffect(() => {
    // clean up state mutation set via register
    setContainer({});
  }, []);

  const rerender = useCallback(({ id, children }) => {
    setContainer((prev) => ({ ...prev, [id]: children }));
  }, []);
  const unmount = useCallback(({ id }) => {
    setContainer(({ [id]: _, ...prev }) => ({ ...prev }));
  }, []);

  useEffect(() => {
    setLayout((prev) => ({
      ...prev,
      [name]: {
        Component: InitialRenderNode,
        props: { rerender, unmount },
        settings: { autoAssign },
      },
    }));
    return () => {
      setLayout(({ [name]: _, ...rest }) => rest);
    };
  }, [name, setLayout, rerender, unmount, autoAssign]);

  const once = useOnce();
  return useCallback(() => {
    const elements = Object.values(container).filter(Boolean);
    once(() => {
      if (elements.length === 0) {
        console.warn('Elements array is empty, considering adjusting order of components.');
      }
    });

    return elements;
  }, [container, once]);
};

/**
 * @param {boolean} [root]
 * @param {import('react').ReactNode} children
 */
export function LayoutSlotsProvider({ root = false, children }) {
  const [parentLayout] = useContext(LayoutSlotsContext);
  const [layout, setLayout] = useState({});

  const contextValue = useMemo(() => (
    [{ ...(root ? {} : parentLayout), ...layout }, setLayout]
  ), [layout, parentLayout, root]);

  return (
    <LayoutSlotsContext.Provider value={contextValue}>
      {children}
    </LayoutSlotsContext.Provider>
  );
}
