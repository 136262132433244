import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBlocker } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { every, isEqual, sortBy, uniq } from 'lodash';
import { setHoveredId } from 'redux/mapSlice';
import SaleCompSet from 'components/saleComps/SaleCompSet';
import {
  useCopySaleCompSetMutation,
  useFetchSaleCompsQuery,
  useFetchSaleCompSetsQuery,
  useUpdateSaleCompSetMutation,
} from 'redux/apiSlice';
import { setActiveSaleCompSetId, setAllComps, setSelectedSaleCompIds, setShowInfoId, setShowPhotoId } from 'redux/saleCompsSlice';
import { clearToast, showToast } from 'redux/toastSlice';
import { Chevron, Config, Fullscreen, LoadingIndicator } from 'components/icons';
import { setShowCreateDealModal, showSaveChangesModal } from 'actions/deal_navigation';
import { LAYOUT } from 'components/constants';
import { TOAST_CREATE_DEAL_CHANGES, TOAST_UNSAVED_CHANGES } from 'components/toast';
import { defaultActiveSaleComp, useInitialFilters, useSaleCompTable } from 'components/saleComps/saleComps';
import Map from 'components/saleComps/Map';
import useElementHeight from 'hooks/useElementHeight';
import FilterBar from 'components/saleComps/FilterBar';
import { RenderTable } from 'components/shared/Table/DataTable';
import { DataTableProvider } from 'components/shared/Table/DataTableContext';
import DataTableConfig from 'components/shared/Table/dataTableConfig/DataTableConfig';
import DataTableConfigPanelBody from 'components/shared/Table/dataTableConfig/DataTableConfigPanelBody';
import Chip from 'components/shared/Chip';
import CompPhotoModal from 'components/rentComps/CompPhotoModal';
import CompInfoModal from 'components/rentComps/CompInfoModal';
import MapModal from './components/MapModal';

const TABLE_ROW_HEIGHT = 40;
const TABLE_ID = 'saleComps';

function TableConfigButton() {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button as="div">
            <Chip
              preferLeadingIcon
              label="Config Table"
              focused={open}
              leadingIcon={<Config />}
            />
          </Popover.Button>
          <Popover.Panel className="absolute right-0 z-30 w-max max-h-96 overflow-auto bg-white rounded shadow">
            <DataTableConfigPanelBody className="p-2" />
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}

export default function SaleComps({ deal, listing, property, propertyManagementRecord }) {
  const [increaseRange, setIncreaseRange] = useState(false);
  const dealId = deal?.id;
  const propertyManagementRecordId = propertyManagementRecord?.id;

  const dispatch = useDispatch();
  const { activeSaleCompSetId, allComps: comps, selectedSaleCompIds, showInfoId, showPhotoId } = useSelector(state => state.saleComps);
  const [showMapModal, setShowMapModal] = useState(false);
  const [minimizeMap, setMinimizeMap] = useState(false);
  const [baseSelectedSaleComps, setBaseSelectedSaleComps] = useState(null);
  const initialFilters = useInitialFilters(property, increaseRange);
  const [columnFilters, setColumnFilters] = useState(initialFilters);
  const setFilter = (id, value) => {
    setColumnFilters(prevColumnFilters => prevColumnFilters.map(filter => (filter.id === id ? { id, value } : filter)));
  };
  const setDrawingFiltered = (shapes) => { setFilter('geo', shapes); };

  const { data: saleCompSets, isFetching: isFetchingSaleCompSets } = useFetchSaleCompSetsQuery({ dealId, propertyId: property?.id, propertyManagementRecordId }, { skip: !dealId && !propertyManagementRecordId });
  const { data: currentComps, isFetching: isFetchingSaleComps } = useFetchSaleCompsQuery({
    listingId: listing?.id,
    fipsApn: property?.fipsApn,
    increaseRange,
    latitude: property.latitude,
    longitude: property.longitude,
  });
  const [updateSaleCompSetMutation] = useUpdateSaleCompSetMutation();
  const [copySaleCompSetMutation] = useCopySaleCompSetMutation();

  // initialize activeSaleCompSetId
  useEffect(() => {
    if (saleCompSets && !activeSaleCompSetId) {
      dispatch(setActiveSaleCompSetId(saleCompSets.find(scs => scs.primary)?.id));
    }
  }, [saleCompSets]);

  const activeSaleCompSet = saleCompSets?.find(scs => scs.id === activeSaleCompSetId);

  const filterContext = useMemo(() => ({
    subdivisions: uniq(comps?.map(comp => comp.subdivision) || []),
    propertyTypes: uniq(comps?.map(comp => comp.propertySubType) || []),
    statuses: uniq(comps?.map(comp => comp.standardStatus) || []),
    subjectRsf: property.livingArea,
  }), [property, comps]);

  useEffect(() => {
    dispatch(setAllComps(activeSaleCompSet ? activeSaleCompSet.comps : currentComps));
  }, [activeSaleCompSet, currentComps]);

  const selectedComps = useMemo(() => {
    if (!comps || !selectedSaleCompIds) return null;
    return sortBy(comps.filter(comp => selectedSaleCompIds.includes(comp.id)), comp => comp.distance);
  }, [comps, selectedSaleCompIds]);

  useEffect(() => {
    if (comps) {
      if (activeSaleCompSetId && activeSaleCompSet) {
        setBaseSelectedSaleComps(activeSaleCompSet.selectedIds);
      } else {
        setBaseSelectedSaleComps(defaultActiveSaleComp(comps));
      }
    }
  }, [comps, activeSaleCompSetId, activeSaleCompSet]);

  // useMemo so that expensive isEqual calculation done in pendingChanges is only performed as necessary
  const pendingChanges = useMemo(
    () => selectedSaleCompIds && baseSelectedSaleComps && !isEqual(selectedSaleCompIds.toSorted(), baseSelectedSaleComps.toSorted()),
    [selectedSaleCompIds, baseSelectedSaleComps],
  );

  const onRowMouseEnter = (row) => {
    dispatch(setHoveredId(row.original.id));
  };

  const onRowMouseLeave = () => {
    dispatch(setHoveredId(null));
  };

  const rowPinning = useMemo(() => {
    if (!comps) return null;
    const tableDataIds = comps.map(d => d.id);
    return (selectedSaleCompIds && every(selectedSaleCompIds, id => tableDataIds.includes(id)))
      ? { top: ['subject', ...selectedSaleCompIds] } : {};
  }, [comps, selectedSaleCompIds]);

  const table = useSaleCompTable({
    columnFilters,
    setColumnFilters,
    comps,
    dispatch,
    listing,
    property,
    selectedSaleCompIds,
    setSelectedSaleCompIds,
    onRowMouseEnter,
    onRowMouseLeave,
    rowPinning,
    tableId: TABLE_ID,
  });

  const distanceFilterValue = columnFilters.find(filter => filter.id === 'distance')?.value;

  // if distance filter is expanded beyond defualt 1 mile, toggle to increased range to fetch more sale comps
  useEffect(() => {
    if (!increaseRange && distanceFilterValue && distanceFilterValue[1] && (distanceFilterValue[1] > 1)) {
      setIncreaseRange(true);
    }
  }, [distanceFilterValue]);

  useEffect(() => {
    if (baseSelectedSaleComps) {
      dispatch(setSelectedSaleCompIds(baseSelectedSaleComps));
    }
  }, [dispatch, baseSelectedSaleComps]);

  useEffect(() => {
    if (pendingChanges) {
      const onReset = () => dispatch(setSelectedSaleCompIds(baseSelectedSaleComps));
      if (activeSaleCompSet) {
        const onSave = async () => {
          // TODO: error handling
          await updateSaleCompSetMutation({
            id: activeSaleCompSet.id,
            selectedIds: selectedSaleCompIds,
          });
        };
        dispatch(showToast(TOAST_UNSAVED_CHANGES({ reset: onReset, save: onSave })));
      } else if (deal || propertyManagementRecord) {
        const onSave = async () => {
          const copiedSaleCompSet = await copySaleCompSetMutation({
            dealId: deal?.id,
            propertyId: property.id,
            propertyManagementRecordId: propertyManagementRecord?.id,
            selectedIds: selectedSaleCompIds,
          }).unwrap();
          dispatch(setActiveSaleCompSetId(copiedSaleCompSet.data.attributes.id));
        };
        dispatch(showToast(TOAST_UNSAVED_CHANGES({ reset: onReset, save: onSave })));
      } else {
        const onSave = () => dispatch(setShowCreateDealModal(true));
        dispatch(showToast(TOAST_CREATE_DEAL_CHANGES({ reset: onReset, save: onSave })));
      }
    } else {
      dispatch(clearToast());
    }
  }, [pendingChanges, selectedSaleCompIds]);

  const {
    state: blockerState,
    proceed,
    reset,
  } = useBlocker(pendingChanges);

  useEffect(() => {
    if (blockerState === 'blocked') {
      const onCancel = () => reset();
      const onDoNotSave = () => {
        dispatch(setSelectedSaleCompIds(baseSelectedSaleComps));
        dispatch(clearToast());
        proceed();
      };
      const onSave = async () => {
        // TODO: clean this up
        if (activeSaleCompSet) {
          await updateSaleCompSetMutation({
            id: activeSaleCompSet.id,
            selectedIds: selectedSaleCompIds,
          });
          proceed();
        } else if (deal) {
          await copySaleCompSetMutation({
            dealId: deal.id,
            propertyId: property.id,
            selectedIds: selectedSaleCompIds,
          });
          proceed();
        } else {
          reset();
          dispatch(setShowCreateDealModal(true));
        }
      };
      dispatch(showSaveChangesModal(true, onCancel, onDoNotSave, onSave));
    }
  }, [blockerState]);

  const contentRef = useRef();
  const contentHeight = useElementHeight(contentRef);
  const filterRef = useRef();
  const filterHeight = useElementHeight(filterRef);
  const tableHeight = contentHeight - filterHeight - LAYOUT.saleCompTopBarHeight;

  const topRows = table.getTopRows();
  const centerRows = table.getCenterRows();
  const filteredComps = useMemo(() => [...topRows, ...centerRows].map(row => row.original), [topRows, centerRows]);

  if (!comps) {
    return (
      <div className="w-8/12 mt-6 flex flex-col container" style={{ height: 'calc(100vh)' }}>
        <div className="flex mt-6 w-full justify-center">
          <LoadingIndicator className="w-8 text-blue-400" />
        </div>
      </div>
    );
  }

  const tableLoaded = selectedSaleCompIds && !isFetchingSaleComps && !isFetchingSaleCompSets;

  return (
    <div className="h-full" style={{ width: `calc(100vw - ${LAYOUT.rightNavWidth + LAYOUT.sidebarWidth}px)` }}>
      <div
        ref={contentRef}
        className="flex w-full"
        style={{ height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)` }}
      >
        <div className={minimizeMap ? 'w-full' : 'w-3/5'}>
          <SaleCompSet
            selectedComps={selectedComps}
            deal={deal}
            property={property}
            propertyManagementRecord={propertyManagementRecord}
            saleCompSets={saleCompSets}
            minimizeMap={minimizeMap}
            setMinimizeMap={setMinimizeMap}
          />
          <DataTableProvider table={table}>
            <div className="border-y" ref={filterRef}>
              <div className="bg-white z-10 flex flex-row items-stretch justify-between px-4 py-2">
                <div className="grow">
                  <FilterBar
                    filters={columnFilters}
                    filterContext={filterContext}
                    setFilter={setFilter}
                    setColumnFilters={setColumnFilters}
                  />
                </div>
                <div className="w-fit flex flex-col justify-between gap-y-2">
                  <div className="flex justify-end">
                    <DataTableConfig tableId={TABLE_ID}>
                      <TableConfigButton />
                    </DataTableConfig>
                  </div>
                  <div className="w-full flex justify-end whitespace-nowrap text-sm text-neutral-light">
                    {(table.getFilteredRowModel().rows.length !== comps.length)
                      ? `Filters Match ${table.getFilteredRowModel().rows.length} of ${comps.length} Comps`
                      : `${comps.length} Comps`}
                  </div>
                </div>
              </div>
            </div>
            <div className="overflow-y-auto">
              <RenderTable
                id="sale-comp-table"
                table={table}
                tableContainerClassName="overflow-x-scroll"
                tableHeight={tableHeight}
                trHeight={TABLE_ROW_HEIGHT}
                isLoading={!tableLoaded}
              />
            </div>
          </DataTableProvider>
        </div>
        {!minimizeMap && (
          <div className="w-2/5">
            <div className="w-full h-full relative">
              <Map
                property={property}
                filteredComps={filteredComps}
                setDrawingFiltered={setDrawingFiltered}
              />
              {showMapModal && (
                <MapModal
                  showMapModal={showMapModal}
                  setShowMapModal={setShowMapModal}
                  type="Sale Comp"
                  address={property}
                >
                  <Map
                    property={property}
                    filteredComps={filteredComps}
                    setDrawingFiltered={setDrawingFiltered}
                  />
                </MapModal>
              )}
              <Fullscreen
                className="absolute top-[5px] right-[50px] cursor-pointer bg-white h-10 w-10 hover:bg-gray-100"
                onClick={() => setShowMapModal(true)}
              />
              <Chevron
                direction="right"
                className="absolute top-[5px] right-[5px] cursor-pointer bg-white h-10 w-10 hover:bg-gray-100"
                onClick={() => setMinimizeMap(true)}
              />
            </div>
          </div>
        )}
      </div>
      {showPhotoId && (
        <CompPhotoModal
          comp={showPhotoId === 'subject' ? ({ media: property.pictures || property.photos }) : comps.find(comp => comp.id === showPhotoId)}
          dismiss={() => dispatch(setShowPhotoId(null))}
        />
      )}
      {showInfoId && (
        <CompInfoModal
          comp={comps.find(comp => comp.id === showInfoId)}
          dismiss={() => dispatch(setShowInfoId(null))}
        />
      )}
    </div>
  );
}

export function EmbeddedSaleComps({ context }) {
  const { data } = context;
  const { deal, listing, property } = data;

  return (
    <SaleComps deal={deal} listing={listing} property={property} />
  );
}
