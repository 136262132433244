import { useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import store from 'components/Apps/store';
import AssetManagementBase from 'routes/AssetManagementBase';
import DealBase from 'routes/DealBase';
import DealContext from 'components/DealBase/DealContext';
import DealImportSettingsForm from 'components/Import/Deal/SettingsForm';
import DealImportParcelMatchForm from 'components/Import/Deal/ParcelMatchForm';
import DealSourcingBase from 'components/DealSourcing/Base';
import Pipeline from 'components/pipeline/Pipeline';
import CreateDeal from 'components/DealSourcing/CreateDeal';
import PortfolioEdit from 'components/portfolio/PortfolioEdit';
import PortfolioShow from 'components/portfolio/PortfolioShow';
import PortfoliosList from 'components/portfolio/PortfoliosList';
import PropertyDetail from 'components/AssetManagement/PropertyDetail';
import PropertyList from 'components/AssetManagement/PropertyList';
import TaskList from 'components/task/TaskList';
import DashboardBase from 'components/NhimbleDashboard/DashboardBase';
import LeaseDashboard from 'components/NhimbleDashboard/Lease';
import DispositionDashboard from 'components/NhimbleDashboard/Disposition';
import Upload from 'components/NhimbleDashboard/Upload';
import SubdivisionBase from 'components/Subdivision/SubdivisionBase';
import SubdivisionList from 'components/Subdivision/SubdivisionList';
import { SubdivisionDetailOutletContainer as SubdivisionDetail } from 'components/Subdivision/SubdivisionDetail';
import ImportBase from 'components/Import/ImportBase';
import NewBuildImportFlow from 'components/Import/NewBuild/NewBuildImportFlow';
import AcquisitionDashboard from 'components/NhimbleDashboard/Acquisition';
import OfferDashboard from 'components/NhimbleDashboard/Offer';
import Geocode from 'components/Geocode/Base';
import NewHomeBuilder from 'components/HomeBuilder/NewHomeBuilder';
import UserEditForm from 'components/settings/UserEditForm';
import NewBuildInventoryLayout from 'components/Import/NewBuild/NewBuildInventoryLayout';
import NewBuildInventoryHomeModels from 'components/Import/NewBuild/NewBuildInventoryHomeModels';
import NewBuildInventorySubdivisions from 'components/Import/NewBuild/NewBuildInventorySubdivisions';
import NewBuildInventoryHomeBuilders from 'components/Import/NewBuild/NewBuildInventoryHomeBuilders';
import NewBuildOffers from 'components/NewBuild/NewBuildOffers';
import NewBuildUnderwriting from 'components/NewBuild/NewBuildUnderwriting';
import NewBuildBase from 'components/NewBuild/NewBuildBase';
import OrganizationBase from 'components/organization/OrganizationBase';
import DealLayout from 'components/DealBase/DealLayout';
import EmailTemplates from 'components/Features/EmailTemplate/Index';
import ExternalPage from 'components/deal/offer/ExternalPage';
import OffMarketImport from 'components/OffMarketMarketplace/OffMarketImport';
import OffMarketMarketplaceBase from 'components/OffMarketMarketplace/OffMarketMarketplaceBase';
import OffMarketInventory from 'components/OffMarketMarketplace/OffMarketInventory';
import OffMarketOffers from 'components/OffMarketMarketplace/OffMarketOffers';
import OffMarketDashboard from 'components/OffMarketMarketplace/OffMarketDashboard';

// The useLocation hook can only be accessed within the scope of a Router
//  so this component was created to get be able to leverage the useLocation
//  hook to pass location info to Google Analytics
function BaseRoute() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('set', 'page_path', location.pathname);
      window.gtag('event', 'page_view');
    }
  }, [location]);

  return <Outlet />;
}

function App(props) {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<BaseRoute />}>
        <Route path="/" element={<DealSourcingBase {...props} />} />

        <Route path="/deal_sourcing" element={<DealSourcingBase {...props} />} />
        <Route path="/deal_sourcing_multi" element={<DealSourcingBase enableMultiPortfolio {...props} />} />
        <Route path="/deal_sourcing/create_deal" element={<CreateDeal />} />

        <Route path="/pipeline" element={<Pipeline />} />

        <Route path="/dashboards/*" element={<DashboardBase />}>
          <Route index element={<OfferDashboard />} />
          <Route path="offers" element={<OfferDashboard />} />
          <Route path="acquisitions" element={<AcquisitionDashboard />} />
          <Route path="leasing" element={<LeaseDashboard />} />
          <Route path="dispositions" element={<DispositionDashboard />} />
          <Route path="upload" element={<Upload />} />
        </Route>

        <Route path="/tasks" element={<TaskList />} />

        <Route path="/portfolios/*">
          <Route index element={<PortfoliosList />} />
          <Route path="new" element={<PortfolioEdit newPortfolio />} />
          <Route path=":portfolioId">
            <Route index element={<PortfolioShow />} />
            <Route path="edit" element={<PortfolioEdit />} />
          </Route>
        </Route>

        <Route path="/asset_management/*" element={<AssetManagementBase />}>
          <Route index element={<PropertyList />} />
          <Route path=":id/*" element={<PropertyDetail />} />
        </Route>

        <Route path="/subdivisions/*" element={<SubdivisionBase />}>
          <Route index element={<SubdivisionList />} />
          <Route path=":id/*" element={<SubdivisionDetail />} />
        </Route>

        <Route path="/home_builders/new" element={<NewHomeBuilder />} />

        <Route path="/new_builds/*" element={<NewBuildBase />}>
          <Route path="inventory/*" element={<NewBuildInventoryLayout />}>
            <Route index element={<Navigate replace to="home_models" />} />
            <Route path="home_models" element={<NewBuildInventoryHomeModels />} />
            <Route path="subdivisions" element={<NewBuildInventorySubdivisions />} />
            <Route path="home_builders" element={<NewBuildInventoryHomeBuilders />} />
          </Route>
          <Route path="import" element={<NewBuildImportFlow />} />
          <Route path="offers" element={<NewBuildOffers />} />
          <Route path="underwriting" element={<NewBuildUnderwriting />} />
        </Route>

        <Route path="/import/*" element={<ImportBase />}>
          <Route path="deals" element={<DealImportSettingsForm />} />
          <Route path="deals/:dealImportJobId" element={<DealImportParcelMatchForm />} />
        </Route>

        <Route path="/geocode/*">
          <Route index element={<Geocode />} />
        </Route>

        <Route path="/organizations/*" element={<OrganizationBase />} />
        <Route path="/email_templates" element={<EmailTemplates />} />
        <Route path="/offer/:externalId" element={<ExternalPage />} />

        <Route path="/users/:userId" element={<UserEditForm />} />

        <Route path="/off_market_marketplace" element={<OffMarketMarketplaceBase />}>
          <Route index element={<Navigate replace to="dashboard" />} />
          <Route path="dashboard" element={<OffMarketDashboard />} />
          <Route path="inventory" element={<OffMarketInventory />} />
          <Route path="import" element={<OffMarketImport />} />
          <Route path="offers" element={<OffMarketOffers />} />
        </Route>

        <Route path="*" element={<DealBase />}>
          <Route element={<DealLayout />}>
            <Route path="properties/:id/*" element={<DealContext />} />
            <Route path="listings/:id/*" element={<DealContext />} />
            <Route path="deals/:id/*" element={<DealContext />} />
            <Route path="home_models/:id/*" element={<DealContext />} />
            <Route path="listings/:id/properties/:propertyId/*" element={<DealContext />} />
            <Route path="deals/:id/properties/:propertyId/*" element={<DealContext />} />
          </Route>
        </Route>
      </Route>,
    ),
  );

  return (
    <RollbarProvider instance={window.Rollbar}>
      <ErrorBoundary>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  );
}

export default function Base(props) {
  return (
    <App {...props} />
  );
}
