import { useEffect, useState } from 'react';
import { trim } from 'lodash';
import Input from 'components/Input';
import Filter from './Filter';

const zipCodeStringFromFilters = (filters) => filters.zipCodes?.join(',') || '';

export default function ZipCodeFilter({ filters, setFilters }) {
  const [zipCodeString, setZipCodeString] = useState(zipCodeStringFromFilters(filters));

  useEffect(() => {
    setZipCodeString(zipCodeStringFromFilters(filters));
  }, [filters.zipCodes]);

  const onApply = (close) => {
    setFilters({ ...filters, zipCodes: zipCodeString.split(',').map(trim) });
    close();
  };

  const onReset = () => {
    setFilters({ ...filters, zipCodes: [] });
  };

  const filtered = filters.zipCodes?.length;
  let label = 'Zip Code';
  if (filtered) {
    label = filters.zipCodes.length > 1 ? `${filters.zipCodes[0]}...` : filters.zipCodes[0];
  }

  return (
    <Filter label={label} onApply={onApply} onReset={onReset} filtered={filtered}>
      <Input
        onChange={(e) => setZipCodeString(e.target.value)}
        type="text"
        value={zipCodeString}
        width="w-full"
      />
      <div className="mt-2 text-sm text-gray-500 text-light">Separate multiple zip codes with a comma</div>
    </Filter>
  );
}
