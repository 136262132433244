import ComboboxDropdown from 'components/shared/ComboboxDropdown';
import FilterControl from './FilterControl';
import { useFilterInputState } from './useFilterInputState';

export default function ComboboxFilter({ ...props }) {
  const [filterState, setFilterState] = useFilterInputState();

  return (
    <FilterControl>
      <ComboboxDropdown
        {...props}
        value={filterState}
        onChange={setFilterState}
      />
    </FilterControl>
  );
}
