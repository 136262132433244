import { useEffect, useMemo, useState } from 'react';
import { addMonths, addYears, eachMonthOfInterval, startOfMonth } from 'date-fns';
import Dropdown from 'components/Dropdown';
import { formatDate } from 'components/utils';
import Filter from './Filter';

const MONTH_YEAR_FORMAT = 'MMM yy';

export default function AvailabilityFilter({ filters, setFilters }) {
  const [localFilter, setLocalFilter] = useState([...filters.availability]);

  const filterOptions = useMemo(() => {
    const currentDate = new Date();
    const monthOptions = eachMonthOfInterval({
      start: startOfMonth(currentDate),
      end: startOfMonth(addMonths(addYears(currentDate, 1), -1)),
    }).map(d => ({ id: formatDate(d), name: formatDate(d, MONTH_YEAR_FORMAT) }));
    monthOptions[0].name = 'Available Now';
    return monthOptions;
  }, []);

  useEffect(() => {
    setLocalFilter(filters.availability);
  }, [filters.availability]);

  const onApply = (close) => {
    setFilters({ ...filters, availability: localFilter });
    close();
  };

  const onReset = () => {
    setFilters({ ...filters, availability: [] });
  };

  const filtered = filters.availability[0] || filters.availability[1];
  let label = 'Availability';
  if (filtered) {
    label = filters.availability.map(d => d ? formatDate(d, MONTH_YEAR_FORMAT) : '').join(' - ');
  }

  return (
    <Filter label={label} onApply={onApply} onReset={onReset} filtered={filtered} width="w-128">
      <div className="flex justify-between gap-x-4">
        <div className="w-1/2">
          <label className="my-1 text-xs text-gray-500">Start</label>
          <Dropdown
            options={filterOptions}
            selectedItem={filterOptions.find(o => o.id === localFilter[0])}
            setSelectedItem={option => setLocalFilter([option.id, localFilter[1]])}
            width="w-full"
          />
        </div>
        <div className="w-1/2">
          <label className="my-1 text-xs text-gray-500">End</label>
          <Dropdown
            options={filterOptions}
            selectedItem={filterOptions.find(o => o.id === localFilter[1])}
            setSelectedItem={option => setLocalFilter([localFilter[0], option.id])}
            width="w-full"
          />
        </div>
      </div>
    </Filter>
  );
}
