import { createAction } from '@reduxjs/toolkit';
import axios from '../utils/axios';

export const setDocumentTemplates = createAction('document_templates/setDocumentTemplates');

export const getDocumentTemplates = () => (dispatch) => {
  axios.get('/document_templates')
    .then((response) => {
      dispatch(setDocumentTemplates(response.data));
    })
    .catch((error) => {
      console.error(error);
    });
};
