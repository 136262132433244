import { partial, isEmpty, compact } from 'lodash';
import { formatInteger, parseEventValue } from 'components/utils';
import Input from 'components/Input';
import ChipFilter from 'components/shared/filters/ChipFilter';

export default function RsfFilter({ subjectRsf, filterKey, filterParams, onApply, onCancel, onReset, setFilter }) {
  let filtered = filterParams[0] || filterParams[1];
  let formattedLabel;
  if (filtered) {
    let filteredLabel;
    if (filterParams[0] && filterParams[1]) {
      filteredLabel = `${formatInteger(filterParams[0])} - ${formatInteger(filterParams[1])}`;
    } else if (filterParams[0]) {
      filteredLabel = `${formatInteger(filterParams[0])}+`;
    } else {
      filteredLabel = `Up to ${formatInteger(filterParams[1])}`;
    }
    formattedLabel = `${filteredLabel}sqft`;
  } else {
    formattedLabel = 'RSF';
  }

  filtered = !isEmpty(compact(filterParams));

  return (
    <ChipFilter
      label={formattedLabel}
      filterKey={filterKey}
      filtered={filtered}
      filterParams={filterParams}
      onApply={onApply}
      onReset={onReset}
      setFilter={setFilter}
      handleCancel={onCancel}
    >
      {({ filterState: [rangeMin, rangeMax], setFilterState }) => {
        const onChange = (changeIndex, event) => {
          const parsedValue = parseEventValue(event) ?? '';
          setFilterState(prevState => {
            const updatedState = [...prevState];
            updatedState[changeIndex] = parsedValue;
            return updatedState;
          });
        };

        const onPercentChange = (changeIndex, event) => {
          const parsedValue = parseEventValue(event) ?? '';
          if (subjectRsf) {
            setFilterState(prevState => {
              const updatedState = [...prevState];
              updatedState[changeIndex] = (changeIndex === 0)
                ? ((1 - parsedValue) * subjectRsf)
                : ((1 + parsedValue) * subjectRsf);
              return updatedState;
            });
          }
        };

        return (
          <div>
            {subjectRsf && <div className="text-gray-600 text-sm mb-3">{`Subject Property RSF: ${formatInteger(subjectRsf)} sqft`}</div>}
            <div className="flex items-center gap-x-4.5">
              <Input
                onChange={partial(onChange, 0)}
                placeholder="No min"
                type="number"
                value={parseInt(rangeMin, 10)}
                width="w-40"
              />
              &mdash;
              <Input
                onChange={partial(onChange, 1)}
                placeholder="No max"
                type="number"
                value={parseInt(rangeMax, 10)}
                width="w-40"
              />
            </div>
            {subjectRsf && (
              <>
                <div className="text-gray-600 text-sm my-3">% Offset</div>
                <div className="flex items-center gap-x-4.5">
                  <Input
                    onChange={partial(onPercentChange, 0)}
                    placeholder="No min"
                    type="percent"
                    value={rangeMin && ((subjectRsf - rangeMin) / subjectRsf)}
                    width="w-40"
                  />
                  &mdash;
                  <Input
                    onChange={partial(onPercentChange, 1)}
                    placeholder="No max"
                    type="percent"
                    value={rangeMax && ((rangeMax - subjectRsf) / subjectRsf)}
                    width="w-40"
                  />
                </div>
              </>
            )}
          </div>
        );
      }}
    </ChipFilter>
  );
}
