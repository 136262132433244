import { setShowCreateDealModal } from 'actions/deal_navigation';
import Alert from 'components/Alert';
import { TRANSACTION_TYPES } from 'components/constants';
import { useUserOptions } from 'components/DealConstruction/dealConstruction';
import { InlineFormField } from 'components/Form';
import { LoadingIndicator } from 'components/icons';
import Modal from 'components/Modal';
import Button from 'components/shared/NewButton';
import RadioInput from 'components/shared/RadioInput';
import useWorkflowTemplateOptions from 'components/workflow/useWorkflowTemplateOptions';
import { capitalize, isNil, parseInt, partial } from 'lodash';
import { useId, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateDealMutation } from 'redux/apiSlice';

function LoadingWorkflowTemplates() {
  return (
    <div className="flex flex-col items-center justify-center mt-6">
      <div className="py-2">
        Loading Workflow Templates
        <LoadingIndicator className="inline ml-2 h-6" />
      </div>
    </div>
  );
}

const defaultDealName = ({ property, subdivision, homeModel }) => {
  if (homeModel) {
    return `${subdivision.name} - ${homeModel.plan}`;
  }

  return property.address ?? '';
};

export default function CreateDealModal({ context }) {
  const { data } = context;
  const { currentUser, homeModel, listing, property, parcels, subdivision } = data;
  const dispatch = useDispatch();
  const [transactionType, setTransactionType] = useState(homeModel ? TRANSACTION_TYPES.acquisition : null);
  const [createDealMutation, { isLoading: isCreating }] = useCreateDealMutation();
  const { pendingParams: model } = useSelector((state) => state.model);
  const { selectedRentCompIds } = useSelector(state => state.rentComps);
  const { selectedSaleCompIds } = useSelector(state => state.saleComps);
  const [name, setName] = useState(() => defaultDealName({ property, subdivision, homeModel }));
  const [leadId, setLeadId] = useState(currentUser.id);
  const [workflowTemplateId, setWorkflowTemplateId, workflowOptions] = useWorkflowTemplateOptions({ transactionType });
  const [alert, setAlert] = useState(null);
  const userOptions = useUserOptions();
  userOptions.unshift(['', 'Select a deal lead', true]);
  const id = useId();

  const createDeal = async (event) => {
    event.preventDefault();

    setAlert(null);
    if (!name) {
      setAlert({ type: 'warning', text: 'Name is required' });
      return;
    }

    if (!transactionType) {
      setAlert({ type: 'warning', text: 'Transaction type is required' });
      return;
    }

    if (!workflowTemplateId) {
      setAlert({ type: 'warning', text: 'Workflow template is required' });
      return;
    }

    const response = await createDealMutation({
      name,
      leadId,
      ...(homeModel ? { homeModelMultiplicities: { [homeModel.id]: 1 } } : {}),
      listingId: listing?.id,
      parcelIds: parcels.map(p => p.id || p.fipsApn),
      rentSelectedIds: selectedRentCompIds,
      salesSelectedIds: selectedSaleCompIds,
      parameters: model?.dcfParams,
      transactionType,
      workflowTemplateId,
    });

    if (response.error) {
      console.error(response.error);
      setAlert({ type: 'danger', text: 'Failed to create deal' });
    } else {
      dispatch(setShowCreateDealModal(false));
    }
  };

  const onNameChange = (e) => {
    setAlert(null);
    setName(e.target.value);
  };

  return (
    <Modal show onClose={() => dispatch(setShowCreateDealModal(false))}>
      <div className="max-h-240 w-160 min-w-max max-w-screen overflow-y-auto">
        <h1 className="mb-12 text-xl font-normal">{homeModel ? 'Start Underwriting' : 'Create Deal'}</h1>
        <InlineFormField name="name" value={name} type="text" onChange={onNameChange} />
        <InlineFormField
          className="mt-6"
          name="leadId"
          label="lead"
          value={leadId}
          type="select"
          options={userOptions}
          onChange={(e) => setLeadId(parseInt(e.target.value, 10))}
        />
        {isNil(workflowOptions) ? (
          <LoadingWorkflowTemplates />
        ) : (
          <>
            {/* Acquisition only for new build deals */}
            {!homeModel && (
              <div className="flex flex-row justify-center gap-x-4 mt-6">
                <label className="w-1/3 my-auto text-xs font-medium text-tertiary-darker text-right tracking-wide uppercase whitespace-pre-line">
                  Transaction Type
                </label>
                <div className="w-2/3">
                  <div className="flex gap-x-2">
                    {Object.keys(TRANSACTION_TYPES).map(tType => (
                      <div className="flex items-center" key={tType}>
                        <RadioInput
                          id={`${id}t${tType}`}
                          checked={transactionType === TRANSACTION_TYPES[tType]}
                          onChange={partial(setTransactionType, tType)}
                        />
                        <label
                          htmlFor={`${id}t${tType}`}
                          className="ml-1 text-sm text-gray-900 cursor-pointer"
                        >
                          {capitalize(TRANSACTION_TYPES[tType])}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <InlineFormField
              className="mt-6"
              disabled={!transactionType}
              name="workflowTemplateId"
              label="Workflow Template"
              value={workflowTemplateId}
              type="select"
              options={workflowOptions}
              onChange={(e) => setWorkflowTemplateId(parseInt(e.target.value, 10))}
            />
          </>
        )}
      </div>
      {alert && <Alert className="mt-4" {...alert} />}
      <div className="flex justify-end gap-x-4 mt-6">
        <Button label="Cancel" textOnly onClick={() => dispatch(setShowCreateDealModal(false))} />
        <Button label="Create" filled onClick={createDeal} isLoading={isCreating} />
      </div>
    </Modal>
  );
}
