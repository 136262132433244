import {
  ACCOUNTING_METHOD_CASH_FLOW,
  ACCOUNTING_METHOD_FOLLOW_ON,
  TURN_METHOD_ROLLOVER,
  TURN_METHOD_TERMINATE,
} from '../components/dcf/dcf';

export const ARRAY_FIELDS = [
  "collectionLossRate",
  "inflationRate",
  "rentGrowthRate",
  "taxExpenseGrowthRate",
  "vacancyRate",
];

export const HOLD_PERIOD_FIELD = "holdPeriod";
export const EXPENSE_ITEMS_FIELD = "expenseItems";
export const GROWTH_RATES_FIELD = "growthRates";
export const REIMBURSABLE_PERCENT_FIELD = "reimbursablePercent";

export const SECTION_HEADERS = [
  "_sales",
  "_accessedValue",
  "_taxRates",
  "_taxPaid",
  "_allInEffectiveTaxRate",
  "_year",
];

export const ACCOUNTING_METHOD_NAME_CASH_FLOW = 'Cash Flow';
export const ACCOUNTING_METHOD_NAME_FOLLOW_ON = 'Follow On';
