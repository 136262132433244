import Input from 'components/Input';
import { findIndex, isNaN, last, sum } from 'lodash';
import { addMonths, addYears } from 'date-fns';
import { useUpdateSaleCompSetMutation } from 'redux/apiSlice';
import NumberInput from 'components/shared/NumberInput';
import { HOA_FEE_EXPENSE_ITEM_NAME, MODELLED_INDIVIDUALLY } from 'components/constants';
import { formatCurrency, formatDate, formatPercentage, parseNumericInput } from 'components/utils';
import { calculatedCapRate, CALCULATED_EXIT_PRICE, CUSTOM_EXIT_PRICE, displayUnits, isExitPriceCustomized, isItemized } from './dcf';
import SFRSummaryOutput from './SFRSummaryOutput';

function FormField({ children, label }) {
  return (
    <div className="flex justify-between mb-6">
      <div className="mt-3">
        <label className="text-neutral-dark text-sm font-medium">{label}</label>
      </div>
      <div>
        {children}
      </div>
    </div>
  );
}

function HelperText({ text }) {
  return (
    <div className="text-sm text-gray-500 mt-1">
      {text}
    </div>
  );
}

function SFRSummaryInputs({ cashFlows, dcfParams, listing, onChange, primaryRentCompSet, primarySaleCompSet, property, customCompRent }) {
  const [updateSaleCompSetMutation] = useUpdateSaleCompSetMutation();

  const askingPrice = (listing?.modellingMethod === MODELLED_INDIVIDUALLY) ? dcfParams.listPrice : listing?.price;
  const { closingDate, customExitPrice, holdPeriod, ltOneYrHold, purchasePrice } = dcfParams;
  const percentOfAskingPrice = ((purchasePrice * 100) / askingPrice);
  const formattedPercentOfAskingPrice = percentOfAskingPrice % 1 !== 0 ? percentOfAskingPrice.toFixed(1) : percentOfAskingPrice;
  const customizedExitPrice = isExitPriceCustomized(dcfParams);
  const exitPrice = last(cashFlows.sale.price);
  const updatedExitCapRate = calculatedCapRate(cashFlows, dcfParams);
  const units = displayUnits(dcfParams);
  const { marketRent, rollToMarket, turnBudget } = Object(units[0]);
  const exitDate = ltOneYrHold ? addMonths(new Date(closingDate), holdPeriod) : addYears(new Date(closingDate), holdPeriod);
  const maxUnitRollMonth = ltOneYrHold ? holdPeriod : holdPeriod * 12;
  const { expenseItems } = dcfParams;
  const hoaFeeExpenseItem = expenseItems.find(ei => ei.name === HOA_FEE_EXPENSE_ITEM_NAME);
  const hoaFee = hoaFeeExpenseItem?.inputValue;

  const onChangeHoaFee = ({ target: { value } }) => {
    const newValue = parseNumericInput(value);
    const hoaExpenseItemIndex = findIndex(expenseItems, hoaFeeExpenseItem);
    const updatedExpenseItem = {
      ...hoaFeeExpenseItem,
      inputValue: isNaN(newValue) ? 0 : newValue,
    };
    onChange({ target: { name: 'expenseItems', value: expenseItems.toSpliced(hoaExpenseItemIndex, 1, updatedExpenseItem) } });
  };

  const onChangeArv = ({ target: { value } }) => {
    if (!primarySaleCompSet) {
      if (!dcfParams.deal?.id) {
        // call onChange with a dummy event to trigger the warning that deal needs to be saved
        onChange({ target: { name: 'arv', value: false }})
      }
      return;
    }
    const newArv = parseNumericInput(value);
    // TODO: handle error
    updateSaleCompSetMutation({
      id: primarySaleCompSet.id,
      compPrice: newArv,
    });
  }

  let compRent = null;
  if (primaryRentCompSet) {
    compRent = primaryRentCompSet.compPrice;
  } else if (customCompRent) {
    compRent = customCompRent;
  } else if (property?.calculated?.compRents) {
    compRent = Object.values(property.calculated.compRents)[0];
  }
  if (isNaN(compRent)) {
    compRent = null;
  }

  let compArv = null;
  if (primarySaleCompSet) {
    compArv = primarySaleCompSet.compPrice;
  }
  const { acquisition, capital, financing } = cashFlows;
  const acquisitionCost = -acquisition.cost[0];
  const originationFee = -financing.loanOriginationFees[0];
  const followOnCapitalProjects = -sum(capital.followOnCapitalExpenses);
  const arv = purchasePrice + acquisitionCost + originationFee + followOnCapitalProjects;
  let percentDiffOfCompArv;
  if (compArv) {
    percentDiffOfCompArv = (arv / compArv) - 1;
  }

  const handleInputChange = ({ target: { name, value } }) => {
    const updatedUnits = units.map(unit => ({ ...unit, [name]: parseNumericInput(value) }));
    onChange({ target: { name: 'units', value: updatedUnits } });
  };

  const handleHoldPeriodChange = (event) => {
    const { value, min, max } = event.target;
    let inputValue;

    if (Number(value) > Number(max)) {
      inputValue = Number(max);
    } else if (Number(value) < Number(min)) {
      inputValue = Number(min);
    } else {
      inputValue = Number(value);
    }
    onChange({
      target: {
        name: 'holdPeriod',
        value: inputValue,
      },
    });

    const tempMaxUnitRollMonth = ltOneYrHold ? inputValue : inputValue * 12;
    if (rollToMarket >= tempMaxUnitRollMonth) handleInputChange({ target: { name: 'rollToMarket', value: String(tempMaxUnitRollMonth) } });
  };

  const handleExitPriceType = () => {
    const exitPriceMethod = customizedExitPrice ? CALCULATED_EXIT_PRICE : CUSTOM_EXIT_PRICE;
    onChange({
      target: {
        name: 'exitPriceMethod',
        value: exitPriceMethod,
      },
    });
  };

  const handleToggle = () => {
    onChange({
      target: {
        name: 'ltOneYrHold',
        value: !ltOneYrHold,
      },
    });
    const tempMaxUnitRollMonth = !ltOneYrHold ? holdPeriod : holdPeriod * 12;
    if (rollToMarket >= tempMaxUnitRollMonth) handleInputChange({ target: { name: 'rollToMarket', value: String(tempMaxUnitRollMonth) } });
  };

  return (
    <div className="sticky top-0 flex flex-col h-auto bg-white p-6 rounded-md">
      <FormField label="Purchase Price">
        <NumberInput
          className="h-12 border border-gray-300 w-60 rounded-md pl-3"
          prefix="$"
          name="purchasePrice"
          onChange={onChange}
          type="number"
          thousandSeparator=","
          value={purchasePrice}
        />
        {askingPrice ? <HelperText text={`${formattedPercentOfAskingPrice}% of Asking Price`} /> : null}
      </FormField>
      <FormField label="All-In Cost">
        <div className="h-12 w-60 pl-3 flex items-center">
          <div className="text-gray-700">{formatCurrency(arv)}</div>
        </div>
        {compArv && (
          <HelperText text={`${formatPercentage(Math.abs(percentDiffOfCompArv))} ${(percentDiffOfCompArv >= 0) ? 'Above' : 'Below'} ARV`} />
        )}
      </FormField>
      <FormField label="ARV">
        <NumberInput
          className="h-12 border border-gray-300 w-60 rounded-md pl-3"
          prefix="$"
          name="arv"
          onChange={onChangeArv}
          type="number"
          thousandSeparator=","
          value={compArv}
        />
      </FormField>
      <hr className="mb-5" />
      <FormField label="Market Rent">
        <NumberInput
          className="h-12 border border-gray-300 w-60 rounded-md pl-3"
          prefix="$"
          name="marketRent"
          onChange={handleInputChange}
          type="number"
          thousandSeparator=","
          value={marketRent}
        />
        {compRent && (
          <HelperText text={`Comp Rent: ${formatCurrency(compRent)}`} />
        )}
      </FormField>
      {(hoaFeeExpenseItem && isItemized(dcfParams)) && (
        <FormField label="HOA Annual Fee">
          <NumberInput
            className="h-12 border border-gray-300 w-60 rounded-md pl-3"
            prefix="$"
            name="hoaFee"
            onChange={onChangeHoaFee}
            type="number"
            thousandSeparator=","
            value={hoaFee || ''}
          />
        </FormField>
      )}
      <FormField label="Renovation Budget">
        <NumberInput
          className="h-12 border border-gray-300 w-60 rounded-md pl-3"
          prefix="$"
          name="turnBudget"
          onChange={handleInputChange}
          type="number"
          thousandSeparator=","
          value={turnBudget}
        />
      </FormField>
      <hr className="mb-5" />
      <FormField label="Exit Value">
        <div className="flex gap-x-6 mb-2">
          <Input
            checked={!customizedExitPrice}
            className="cursor-pointer mr-2.5 w-5 h-5"
            id="capRate"
            label="Cap Rate"
            name="exitPriceType"
            onChange={handleExitPriceType}
            type="radio"
          />
          <Input
            checked={customizedExitPrice}
            id="price"
            className="cursor-pointer mr-2.5 w-5 h-5"
            label="Price"
            name="exitPriceType"
            onChange={handleExitPriceType}
            type="radio"
          />
        </div>
        {customizedExitPrice ? (
          <NumberInput
            className="h-12 border border-gray-300 w-60 rounded-md pl-3"
            prefix="$"
            name="customExitPrice"
            onChange={onChange}
            type="number"
            thousandSeparator=","
            value={customExitPrice || Math.ceil(exitPrice)}
          />
        ) : (
          <NumberInput
            className="h-12 border border-gray-300 w-60 rounded-md pl-3"
            suffix="%"
            name="exitCapRate"
            onChange={onChange}
            type="percent"
            thousandSeparator=","
            value={updatedExitCapRate}
          />
        )}
      </FormField>
      <FormField label="Closing Date">
        <Input
          className="h-12 border border-gray-300 w-60"
          name="closingDate"
          onChange={onChange}
          textAlign
          type="date"
          value={closingDate}
        />
        <HelperText text={`Exit Date: ${formatDate(exitDate, 'MM/dd/yyyy')}`} />
      </FormField>
      <FormField label="Hold Period">
        <NumberInput
          className="h-12 border border-gray-300 w-60 rounded-md pl-3"
          min={1}
          max={ltOneYrHold ? 11 : 50}
          name="holdPeriod"
          onChange={handleHoldPeriodChange}
          type="number"
          value={holdPeriod}
        />
        <div className="flex gap-x-6 mt-1">
          <Input
            checked={ltOneYrHold}
            className="cursor-pointer mr-2.5 w-5 h-5"
            id="months"
            label="Months"
            onChange={handleToggle}
            type="radio"
          />
          <Input
            checked={!ltOneYrHold}
            className="cursor-pointer mr-2.5 w-5 h-5"
            id="years"
            onChange={handleToggle}
            type="radio"
            label="Years"
          />
        </div>
      </FormField>
    </div>
  );
}

function SFRSummary(props) {
  return (
    <div className="w-full flex space-x-6 items-start bg-[#F5F5F5]">
      <SFRSummaryInputs {...props} />
      <SFRSummaryOutput {...props} />
    </div>
  );
}

export default SFRSummary;
