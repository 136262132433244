import { drop, isEmpty, isNil } from 'lodash';
import { endOfMonth, parse } from 'date-fns';
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatDate, formatPercentage } from 'components/utils';

const PRIMARY_COLOR = '#002D6F';
const SECONDARY_COLOR = '#34d399';
const GRID_COLOR = '#f5f5f5';

export default function RentGrowthChart({ dates, grossRents, rentIndex }) {
  const mostRelevantRentIndex = rentIndex?.find(ri => ri.yearOverYear.filter(yoy => yoy.value).length >= 12);
  const actualRentsYearOverYear = grossRents.slice(12).map((rent, index) => ({
    date: dates[index + 12],
    value: (rent - grossRents[index]) / grossRents[index],
  })).filter(yoy => Number.isFinite(yoy.value) && yoy.date);

  const rentIndexYearOverYearObj = mostRelevantRentIndex?.yearOverYear?.reduce((ret, pair) => {
    if (isNil(pair.value)) {
      return ret;
    }
    return {
      ...ret,
      [pair.date]: pair.value,
    };
  }, {});

  // drop first 24 to avoid unrepresenative rent growth changes due to vacancy / lease-up
  const data = drop(actualRentsYearOverYear, 24).filter(actual => parse(actual.date, 'yyyy-MM-dd', new Date()) < new Date()).map(actual => {
    const parsedDate = parse(actual.date, 'yyyy-MM-dd', new Date());
    return {
      Date: parsedDate,
      Actual: actual.value,
      RentIndex: rentIndexYearOverYearObj?.[formatDate(endOfMonth(parsedDate))],
    };
  });

  if (isEmpty(data)) {
    return null;
  }

  return (
    <>
      <div className="text-xs text-gray-500 mb-3">Rent Growth Comparison</div>
      <ResponsiveContainer width="90%" height={240}>
        <ComposedChart data={data} margin={{ right: 20, left: 20 }}>
          <XAxis dataKey="Date" minTickGap={90} tickLine={false} tickFormatter={value => formatDate(value, 'MMM yy')} />
          <YAxis
            axisLine={false}
            minTickGap={30}
            tickLine={{ stroke: GRID_COLOR }}
            tickFormatter={value => formatPercentage(value)}
          />
          <Tooltip
            formatter={(value, name) => [formatPercentage(value), name === 'Actual' ? 'Actual' : mostRelevantRentIndex.regionName]}
            labelFormatter={label => formatDate(label)}
          />
          <CartesianGrid stroke={GRID_COLOR} />
          <Line
            type="monotone"
            dataKey="Actual"
            stroke={PRIMARY_COLOR}
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="RentIndex"
            stroke={SECONDARY_COLOR}
            strokeWidth={2}
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
}
