import { useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, first, isEmpty, last } from 'lodash';
import classNames from 'classnames';
import Toast from 'components/shared/Toast';
import SaveChangesModal from 'components/modals/SaveChangesModal';
import { setSelectedDashboard } from 'reducers/dashboard/filters';
import { LAYOUT } from 'components/constants';
import Filters from './Filters';
import { DASHBOARD_NAVS } from './Shared';

function ModalDisplayer() {
  const { showSaveChangesModal } = useSelector(state => state.navigation.modal);
  return showSaveChangesModal ? <SaveChangesModal /> : null;
}

function DashboardTabLink({ tab, currentPath }) {
  const clazz = classNames(
    'w-full flex space-x-4 pl-4 h-14 py-4.5 tracking-wider text-sm font-medium text-center cursor-pointer hover:text-gray-700',
    {
      'bg-primary-dark bg-opacity-8 rounded-full text-gray-800': tab.path === currentPath,
      'text-gray-400 text-neutral-dark': tab.path !== currentPath,
    },
  );

  return (
    <Link to={tab.path} key={tab.label} className={clazz}>
      <span className="text-2xl ltr:mr-2 rtl:ml-2">{tab.icon}</span>
      <span>{tab.label}</span>
    </Link>
  );
}

export default function DashboardBase() {
  const { activeToast } = useSelector(state => state.toast);
  const location = useLocation();
  const currentPath = location.pathname.split('/')[2] || first(DASHBOARD_NAVS).path;
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentPath) {
      dispatch(setSelectedDashboard(capitalize(currentPath)));
    }
  }, [currentPath]);

  return (
    <div className="flex" style={{ height: '100vh', width: `calc(100vw - ${LAYOUT.sidebarWidth}px)` }}>
      <div className="flex flex-col gap-y-2 w-64 py-10 px-4">
        {DASHBOARD_NAVS.map(tab => (
          <DashboardTabLink key={tab.label} tab={tab} currentPath={currentPath} />
        ))}
      </div>
      <div className="w-full pt-6 px-6 flex flex-col space-y-4 overflow-hidden">
        {last(DASHBOARD_NAVS).path !== currentPath && <Filters />}
        <Outlet />
        <ModalDisplayer />
        {!isEmpty(activeToast) && <Toast {...activeToast} />}
      </div>
    </div>
  );
}
