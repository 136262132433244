import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeFilters: {
    market: null,
    portfolio: null,
    building: null,
    bed: [false],
    bath: [false],
    yearBuiltMin: null,
    yearBuiltMax: null,
    sqftMin: null,
    sqftMax: null,
    domMin: null,
    domMax: null,
  },
  asOfDate: new Date().toISOString(),
  selectedDashboard: 'Acquisitions',
  markets: [],
  portfolios: [],
};

export const dashboardSlice = createSlice({
  name: 'app/dashboard',
  initialState,
  reducers: {
    setAsOfDate: (state, action) => {
      state.asOfDate = action.payload;
    },
    setActiveFilters: (state, action) => {
      state.activeFilters = action.payload;
    },
    resetActiveFilters: (state) => {
      state.activeFilters = initialState.activeFilters;
    },
    setSelectedDashboard: (state, action) => {
      state.selectedDashboard = action.payload;
    },
    setMarkets: (state, action) => {
      state.markets = action.payload;
    },
    setPortfolios: (state, action) => {
      state.portfolios = action.payload;
    },
  },
});

export const {
  setAsOfDate,
  setActiveFilters,
  resetActiveFilters,
  setSelectedDashboard,
  setMarkets,
  setPortfolios,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
