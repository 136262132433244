import { useEffect, useState } from 'react';
import { uniq } from 'lodash';
import GoogleMap from 'components/common/GoogleMap';
import { formatCurrencyAbbreviated } from 'components/utils';

function StageLegend({ stageColors = {} }) {
  const stages = uniq(Object.keys(stageColors));
  return (
    <div className="border border-gary-600 absolute top-0 right-0 bg-white z-40 w-auto h-auto border-b rounded-b-lg border-md p-4 z-50">
      <div className="flex flex-col space-y-6">
        {
          stages.map(stage => (
            <div key={stage} className="flex space-x-2">
              <div className="h-5 w-5 rounded-full bg-black" style={{ backgroundColor: stageColors[stage] }} />
              <div className="font-semibold text-sm">{stage}</div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

function InfoWindow({ imageUrl, name, stage, id, purchasePrice, units }) {
  const stopPropagation = (e) => e.stopPropagation();

  useEffect(() => {
    const el = document.querySelector(`#portfolio-properties > .deal[data-id='${id}']`);

    if (el) {
      el.querySelector('div').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });

      el.classList.add('bg-primary-50', 'animate-pulse');
      setTimeout(() => {
        el.classList.remove('bg-primary-50');
        el.classList.remove('animate-pulse');
      }, 3000);
    }
  }, []);

  return (
    <div onClick={stopPropagation} className="z-50 cursor -top-24 relative w-60 h-auto left-10 block bg-white border rounded overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
      <div className="rounded overflow-hidden shadow-lg h-full flex flex-col">
        <div className="relative group h-40">
          <img className="w-full h-full object-cover" src={imageUrl} alt="House" referrerPolicy="no-referrer" />
        </div>
        <div className="p-3">
          <div className="font-bold text-sm mb-2">{name}</div>
          <div className="font-medium text-xs mb-2">{`${units} Units | ${formatCurrencyAbbreviated(purchasePrice)} Purchase Price`}</div>
          <p className="text-gray-700 text-sm mt-1">{stage}</p>
        </div>
      </div>
    </div>
  );
}

export default function DealMap({ deals = [], hoveredId }) {
  const [places, setPlaces] = useState();
  const [defaultCenter, setDefaultCenter] = useState();
  const [stageColors, setStageColors] = useState({});

  useEffect(() => {
    const mappedPlaces = deals.map((deal) => ({
      active: false,
      id: deal.id,
      imageUrl: deal.imageUrl,
      lat: Number(deal.latitude),
      lng: Number(deal.longitude),
      markerColor: deal.stageColor,
      stageColor: deal.stageColor,
      units: deal.units,
      name: deal.name,
      purchasePrice: deal.scenario.parameters.purchasePrice,
      showInfoWindow: false,
      stage: deal.stage,
      subject: false,
      text: deal.name,
    }));
    setPlaces(mappedPlaces);
    if (mappedPlaces.length > 0) {
      setDefaultCenter({ lat: mappedPlaces[0]?.lat, lng: mappedPlaces[0]?.lng });
    }
  }, [deals]);

  useEffect(() => {
    if (places) {
      const colors = {};
      places.forEach(place => {
        colors[place.stage] = place.stageColor;
      });
      setStageColors(colors);
    }
  }, [places]);

  return (
    <div className="w-full h-full z-0">
      {places && defaultCenter && (
      <GoogleMap
        defaultCenter={defaultCenter}
        hoveredId={hoveredId}
        infoWindow={InfoWindow}
        places={places}
        legendComponent={<StageLegend stageColors={stageColors} />}
      />
      )}
    </div>
  );
}
