import { useDispatch, useSelector } from 'react-redux';
import { clearDealNavigationModal, generateLoiForDeal } from 'actions/deal_navigation';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Modal from 'components/Modal';

export default function GenerateLoiModal() {
  const dispatch = useDispatch();
  const { deal, alert, saving } = useSelector(state => state.navigation.modal);

  const onClose = () => dispatch(clearDealNavigationModal());

  return (
    <Modal show onClose={onClose}>
      <div className="w-96">
        <h1 className="mb-8 mt-4 text-center text-xl font-normal">Generate LOI</h1>
        {alert && <Alert className="mt-3" {...alert} />}
        <div className="mt-12 flex flex-row justify-center gap-x-4">
          <Button text="Generate LOI" onClick={() => dispatch(generateLoiForDeal(deal))} isLoading={saving} />
          <Button cancel text="Cancel" onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
}
