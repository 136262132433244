import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { dismissDashboardAlert } from '../../actions/deals';
import Alert from '../Alert';
import { LoadingIndicator } from '../icons';

export default function DashboardAlertPopup() {
  const dispatch = useDispatch();
  const { alert, isSaving } = useSelector(state => state.deals.dashboard);

  if (!alert) {
    return null;
  }

  const { type, text, message } = alert;
  const unauthorizedError = type === 'unauthorized';

  return (
    <div
      className={cx(
        'fixed z-10 bottom-0 h-max max-h-screen w-screen flex text-sm pointer-events-none',
        {
          'justify-start': !unauthorizedError,
          'justify-center': unauthorizedError,
        },
      )}
    >
      <Alert
        wide
        type={type}
        onDismissClick={isSaving ? null : () => dispatch(dismissDashboardAlert())}
        className={cx('whitespace-pre-wrap w-max max-w-prose max-h-full my-20 mx-28 pointer-events-auto', { 'cursor-wait': isSaving })}
      >
        <div className="leading-6 overflow-y-auto h-full">
          {unauthorizedError && (
            <p>
              <b>Error.</b>
              {' You do not have permission to perform that action.'}
            </p>
          )}
          {unauthorizedError ? message : text}
        </div>
        {isSaving && <LoadingIndicator className="inline ml-2 h-4" />}
      </Alert>
    </div>
  );
}
