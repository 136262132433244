import { useDispatch, useSelector } from 'react-redux';
import { clearDealsModal } from 'actions/deals';
import { useDeleteTaskAttachmentMutation } from 'redux/apiSlice';
import Button from 'components/Button';
import DashboardModal from '../DashboardModal';

export default function RemoveTaskAttachmentModal() {
  const dispatch = useDispatch();
  const { task, attachment } = useSelector(state => state.deals.modal);
  const [deleteTaskAttachmentMutation, { isLoading }] = useDeleteTaskAttachmentMutation();

  return (
    <DashboardModal
      title="Remove Attachment?"
      action={(
        <Button
          danger
          text="Remove"
          className="font-semibold border border-black border-opacity-12"
          shadow={false}
          isLoading={isLoading}
        />
      )}
      onSubmit={async (event) => {
        event.preventDefault();
        event.stopPropagation();
        // TODO: handle response
        await deleteTaskAttachmentMutation({
          id: attachment.id,
          taskId: task.id,
          dealId: task.dealId,
        });
        dispatch(clearDealsModal());
      }}
      onClose={() => dispatch(clearDealsModal())}
    >
      {'Note, this will not delete the attachment, it can still be found under the '}
      <b>Documents</b>
      {' tab.'}
    </DashboardModal>
  );
}
