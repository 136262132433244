import { useState } from 'react';
import { groupBy, partial, sortBy } from 'lodash';
import { parseISO } from 'date-fns';
import { useFetchPropertyHistoryQuery } from 'redux/apiSlice';
import { HISTORY_RECORD_TYPES } from 'components/constants';
import { PropertyLayoutContainer } from 'components/property/PropertyLayout';
import { LoadingIndicator } from '../icons';
import HistoryYearGroup from './components/HistoryRows';
import HistoryModal from './components/HistoryModal';

function RecordTypeCheckBox({ checked, label, toggleChecked }) {
  return (
    <label className="flex flex-row items-center gap-x-4.5 w-max tracking-wide cursor-pointer select-none">
      <input
        type="checkbox"
        className="h-4.5 w-4.5 cursor-pointer accent-primary-dark"
        checked={checked}
        onChange={toggleChecked}
      />
      {label}
    </label>
  );
}

export default function History({ parcel }) {
  const [selectedModalItem, setSelectedModalItem] = useState(null);
  const [recordFilters, setRecordFilters] = useState({
    [HISTORY_RECORD_TYPES.LISTING]: true,
    [HISTORY_RECORD_TYPES.RECORDER]: true,
    [HISTORY_RECORD_TYPES.RENT_LISTING]: true,
    [HISTORY_RECORD_TYPES.VALUATION]: true,
  });

  const { data: history = [], isLoading: isHistoryLoading } = useFetchPropertyHistoryQuery(parcel?.fipsApn, { skip: !parcel?.fipsApn });

  if (isHistoryLoading) {
    return (
      <>
        <div className="text-xl">Property History</div>
        <div className="flex justify-center items-center py-6">
          <LoadingIndicator className="w-8 text-blue-400" />
        </div>
      </>
    );
  }

  const filteredHistory = history.filter(record => recordFilters[record.attributes.type]);
  const propertyHistoryGroups = Object.entries(groupBy(sortBy(filteredHistory, record => parseISO(record.attributes.date)).reverse(), event => parseISO(event.attributes.date).getFullYear()));

  const toggleRecordFilterChecked = (recordType) => setRecordFilters({
    ...recordFilters,
    [recordType]: !recordFilters[recordType],
  });

  return (
    <>
      <div className="text-xl">Property History</div>
      <div className="flex gap-x-8 py-8">
        <RecordTypeCheckBox label="Listings" checked={recordFilters[HISTORY_RECORD_TYPES.LISTING]} toggleChecked={partial(toggleRecordFilterChecked, HISTORY_RECORD_TYPES.LISTING)} />
        <RecordTypeCheckBox label="Recorder" checked={recordFilters[HISTORY_RECORD_TYPES.RECORDER]} toggleChecked={partial(toggleRecordFilterChecked, HISTORY_RECORD_TYPES.RECORDER)} />
        <RecordTypeCheckBox label="Rental Listing" checked={recordFilters[HISTORY_RECORD_TYPES.RENT_LISTING]} toggleChecked={partial(toggleRecordFilterChecked, HISTORY_RECORD_TYPES.RENT_LISTING)} />
        <RecordTypeCheckBox label="Valuation" checked={recordFilters[HISTORY_RECORD_TYPES.VALUATION]} toggleChecked={partial(toggleRecordFilterChecked, HISTORY_RECORD_TYPES.VALUATION)} />
      </div>
      <div className="overflow-auto rounded-xl">
        {propertyHistoryGroups.length ? sortBy(propertyHistoryGroups, pair => pair[0]).reverse().map(pair => (
          <HistoryYearGroup
            key={pair[0]}
            year={pair[0]}
            records={pair[1]}
            setSelectedModalItem={setSelectedModalItem}
          />
        )) : (
          <div className="w-full mx-auto rounded-xl border bg-white">
            <p className="p-6 text-gray-400">No historical information</p>
          </div>
        )}
      </div>
      {selectedModalItem && <HistoryModal item={selectedModalItem} onClose={() => setSelectedModalItem(null)} />}
    </>
  );
}

export function EmbeddedHistory({ context }) {
  const { data } = context;
  const { parcel } = data;

  return (
    <PropertyLayoutContainer>
      <History parcel={parcel} />
    </PropertyLayoutContainer>
  );
}
