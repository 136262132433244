import { ACCOUNTING_METHOD_CASH_FLOW, ACCOUNTING_METHOD_FOLLOW_ON } from './dcf';
import { ToggleWithLabels } from '../Toggle';
import { ACCOUNTING_METHOD_NAME_CASH_FLOW, ACCOUNTING_METHOD_NAME_FOLLOW_ON } from '../../constants/model';

export default function AccountingMethodToggle({ className, name, value, onChange, disabled = false }) {
  const checked = value !== ACCOUNTING_METHOD_CASH_FLOW;
  const onClick = () => onChange({
    target: {
      name,
      value: checked ? ACCOUNTING_METHOD_CASH_FLOW : ACCOUNTING_METHOD_FOLLOW_ON,
    },
  });

  return (
    <ToggleWithLabels
      className={`${className} inline-flex`}
      name={name}
      checked={checked}
      label1={ACCOUNTING_METHOD_NAME_CASH_FLOW}
      label2={ACCOUNTING_METHOD_NAME_FOLLOW_ON}
      onClick={onClick}
      disabled={disabled}
    />
  );
}
