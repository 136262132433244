import { useNavigate } from 'react-router-dom';
import { isFinite, isNil, meanBy, sumBy } from 'lodash';
import { EXPENSE_METHOD_RATIO } from './dcf';
import { inPlaceRentTooltip, turnoverCostTooltip } from './Tooltips';
import UnitsTable from './UnitsTable';
import Input, { PERCENT_TYPE } from '../Input';
import { TH, TR } from '../Table';
import { formatCurrency, formatPercentage } from '../utils';
import { newLeaseStartMonth } from './rent';
import IncludeToolTip from '../IncludeTooltip';

const COMBINED = 'combined';

function UnitInfoTableRow({ onChange, onRemove, unit, dcfParams, setActiveParameterTab, onUnitIncrease, onUnitDecrease }) {
  const isCombinedModelling = dcfParams.deal?.modellingMethod === COMBINED;
  const inPlaceRentPsf = unit.rsf ? formatCurrency(unit.inPlaceRent / unit.rsf, true) : '-';
  const marketRentPsf = unit.rsf ? formatCurrency(unit.marketRent / unit.rsf, true) : '-';
  const rentDiff = unit.marketRent - unit.inPlaceRent;
  const percentDiff = isFinite(rentDiff / unit.inPlaceRent) ? (rentDiff / unit.inPlaceRent) : null;
  const diffTextColor = rentDiff < 0 ? 'text-red-500' : 'text-green-700';
  const newLeaseStart = newLeaseStartMonth(unit);
  let rentControlDisabledReason = null;
  if (unit.vacant) {
    rentControlDisabledReason = 'Vacant units cannot be rent controlled';
  } else if (unit.turnBudget) {
    rentControlDisabledReason = 'Units being turned cannot be rent controlled';
  } else if (!unit.inPlaceRent) {
    rentControlDisabledReason = 'Enter in-place rent to enable rent control';
  }

  const data = [
    <div>
      <button
        type="button"
        className="bg-red-200 hover:bg-red-400 mx-2 px-2 text-white rounded"
        onClick={onRemove}
      >
        -
      </button>
    </div>,
    (isCombinedModelling && (<span className="text-neutral  text-xs">{unit.address}</span>)),
    (dcfParams.unitTypeEntry && <Input name="group" type="text" className="text-right" value={unit.group} onChange={onChange} width="w-28" disabled={!dcfParams.unitTypeEntry} />),
    (
      dcfParams.unitTypeEntry
        ? (
          <div className="w-24 flex flex-row justify-between align-center ml-2">
            <button type="submit" className="w-6 border rounded mx-2" onClick={onUnitDecrease}>-</button>
            {unit.number}
            <button type="submit" className="w-6 border rounded mx-2" onClick={onUnitIncrease}>+</button>
          </div>
        )
        : <Input name="number" type="text" value={unit.number} onChange={onChange} width="w-16" />
    ),
    <Input name="bedrooms" value={unit.bedrooms} onChange={onChange} min="0" width="w-16" />,
    <Input name="bathrooms" value={unit.bathrooms} onChange={onChange} min="0" width="w-16" />,
    <Input name="rsf" value={unit.rsf} onChange={onChange} min="0" width="w-24" />,
    <>
      <Input name="inPlaceRent" value={unit.inPlaceRent} onChange={onChange} min="0" width="w-24" disabled={unit.vacant} />
      <div className="text-xs text-right mt-1">{`${inPlaceRentPsf} PSF`}</div>
    </>,
    <>
      <Input name="marketRent" value={unit.marketRent} onChange={onChange} min="0" width="w-24" />
      <div className="text-xs text-right mt-1">{`${marketRentPsf} PSF`}</div>
    </>,
    <div className={`px-1 ${diffTextColor}`}>
      <div className="pt-1">{formatCurrency(rentDiff)}</div>
      <div className="text-xs mt-2">{formatPercentage(percentDiff)}</div>
    </div>,
    <Input name="vacant" type="checkbox" value={unit.vacant} onChange={onChange} />,
    <Input name="rollToMarket" value={unit.rollToMarket} onChange={onChange} min="0" width="w-16" />,
    <button
      type="button"
      className="px-2 float-right text-tertiary underline cursor-pointer hover:text-tertiary-lighter"
      onClick={() => setActiveParameterTab('capEx')}
    >
      {unit.turnBudget > 0 ? unit.turnDowntime : 'n/a'}
    </button>,
    <div className="px-2 text-right">{newLeaseStart}</div>,
    <Input name="leaseTerm" value={unit.leaseTerm} onChange={onChange} min="1" width="w-16" />,
    <Input name="concessions" value={unit.concessions} onChange={onChange} min="0" width="w-16" />,
    <Input name="downtime" value={unit.downtime} onChange={onChange} min="0" width="w-16" />,
    <Input name="releasingCost" value={unit.releasingCost} onChange={onChange} min="0" width="w-24" disabled={dcfParams.expenseMethod === EXPENSE_METHOD_RATIO} />,
    <Input
      name="renewalProbability"
      value={unit.renewalProbability}
      type={PERCENT_TYPE}
      min="0"
      max="100"
      width="w-full"
      onChange={onChange}
    />,
    rentControlDisabledReason ? (
      <IncludeToolTip alignRight width="w-max" tooltipText={rentControlDisabledReason}>
        <Input name="rentControlled" type="checkbox" value={unit.rentControlled} onChange={onChange} disabled />
      </IncludeToolTip>
    ) : (
      <Input name="rentControlled" type="checkbox" value={unit.rentControlled} onChange={onChange} />
    ),
  ];

  return (
    <TR center data={data} />
  );
}

function AggregateRow({ units }) {
  const bedrooms = sumBy(units, 'bedrooms');
  const bathrooms = sumBy(units, 'bathrooms');
  const rsf = sumBy(units, 'rsf');
  const vacant = false;
  const inPlaceRent = sumBy(units, 'inPlaceRent');
  const marketRent = sumBy(units, 'marketRent');
  const inPlaceRentPsf = formatCurrency(inPlaceRent / rsf, true);
  const marketRentPsf = formatCurrency(marketRent / rsf, true);
  const rentDiff = marketRent - inPlaceRent;
  const diffTextColor = rentDiff < 0 ? 'text-red-500' : 'text-green-700';
  const rollToMarket = meanBy(units, 'rollToMarket');
  const turnedUnits = units.filter(unit => unit.turnBudget > 0);
  const turnDowntime = turnedUnits.length > 0 ? Math.round(meanBy(units, 'turnDowntime')) : 'n/a';
  const newLeaseStart = meanBy(units, unit => unit.rollToMarket + (unit.turnBudget > 0 ? unit.turnDowntime : 0));
  const leaseTerm = meanBy(units, 'leaseTerm');
  const concessions = meanBy(units, 'concessions');
  const downtime = meanBy(units, 'downtime');
  const releasingCost = sumBy(units, 'releasingCost');
  const renewalProbability = meanBy(units, 'renewalProbability');

  const data = [
    null,
    null,
    null,
    units.length || '-',
    bedrooms?.toFixed(1) || '-',
    bathrooms?.toFixed(1) || '-',
    rsf?.toFixed() || '-',
    formatCurrency(Math.round(inPlaceRent)),
    formatCurrency(Math.round(marketRent)),
    <div className={`${diffTextColor}`}>{formatCurrency(Math.round(marketRent - inPlaceRent))}</div>,
    vacant,
    Math.round(rollToMarket),
    turnDowntime,
    Math.round(newLeaseStart),
    Math.round(leaseTerm),
    formatCurrency(marketRent * concessions),
    Math.round(downtime),
    formatCurrency(releasingCost),
    formatPercentage(renewalProbability),
    null,
  ];

  const percentDiffAggregate = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    <div className="text-xs">{`${inPlaceRentPsf} PSF`}</div>,
    <div className="text-xs">{`${marketRentPsf} PSF`}</div>,
    <div className={`text-xs px-1 ${diffTextColor}`}>{formatPercentage(Math.round(marketRent - inPlaceRent) / inPlaceRent)}</div>,
  ];

  return (
    <>
      <TR center data={data} />
      <TR center data={percentDiffAggregate} />
    </>
  );
}

export default function RentRoll({
  dcfParams,
  defaultParams,
  onChange,
  property,
  properties,
  setActiveParameterTab,
  paramsUnsaved,
  model,
}) {
  const isCombinedModelling = dcfParams.deal?.modellingMethod === COMBINED;
  const navigate = useNavigate();
  const { baseParams: { listing } } = model;
  const rentCompPath = isNil(listing) ? `/properties/${property.id}/rent_comps` : `/listings/${listing.id}/rent_comps`;

  const header = (
    <tr>
      <TH value="" />
      <TH value={isCombinedModelling ? 'Address' : ''} />
      <TH value={dcfParams.unitTypeEntry ? 'Group' : ''} />
      <TH value={dcfParams.unitTypeEntry ? '# of Units' : 'Unit Number'} />
      <TH value="Bedrooms" />
      <TH value="Bathrooms" />
      <TH value="RSF" />
      <TH value="In-Place Rent ($/month)" tooltip={property && { text: inPlaceRentTooltip(defaultParams.rentSource, property) }} />
      <TH value="Market Rent ($/month)" />
      <TH value="In-Place vs. Market Rent" />
      <TH value="Vacant" />
      <TH value="Unit Roll Month" />
      <TH value="Renovation Downtime (months)" />
      <TH value="New Lease Start Month" />
      <TH value="Lease Term (months)" />
      <TH value="Concessions (months)" />
      <TH value="Downtime (days)" />
      <TH value="Turnover Costs ($)" tooltip={{ alignRight: true, text: turnoverCostTooltip(dcfParams) }} />
      <TH value="Renewal Probability (%)" />
      <TH value="Rent Controlled" />
    </tr>
  );

  return (
    <>
      <UnitsTable
        property={property}
        properties={properties}
        dcfParams={dcfParams}
        onChange={onChange}
        header={header}
        UnitRow={UnitInfoTableRow}
        AggregateRow={AggregateRow}
        setActiveParameterTab={setActiveParameterTab}
        paramsUnsaved={paramsUnsaved}
        isRentRoll
      />
      <div className="flex">
        <button
          type="button"
          className="mt-3 ml-5 text-sm text-tertiary underline cursor-pointer hover:text-tertiary-lighter"
          onClick={() => navigate(rentCompPath)}
        >
          View Rent Comps
        </button>
        <button
          type="button"
          className="mt-3 ml-5 text-sm text-tertiary underline cursor-pointer hover:text-tertiary-lighter"
          onClick={() => setActiveParameterTab('revenue')}
        >
          Rent Controlled Settings
        </button>
      </div>
    </>
  );
}
