import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { apiSlice } from 'redux/apiSlice';

const DEAL_IMPORT_JOBS_TAG = 'DealImportJobs';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchDealImportJob: builder.query({
        query: (id) => ({ url: `/api/deal_import_jobs/${id}` }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: [DEAL_IMPORT_JOBS_TAG],
      }),
      fetchDealImportJobs: builder.query({
        query: '/api/deal_import_jobs',
        transformResponse: response => camelCaseKeys(response),
        providesTags: [DEAL_IMPORT_JOBS_TAG],
      }),
      postDealImportJob: builder.mutation({
        query: ({ file, options }) => {
          const formData = new FormData();
          formData.append('file', file);

          return ({
            url: `/api/deal_import_jobs?${new URLSearchParams(snakeCaseKeys(options)).toString()}`,
            method: 'POST',
            // NOTE: We need to specify headers as empty object or this request will fail.
            //       Apparently it is due to a bug in fetch library (which apiSlice uses)
            //       when sending form data even if you specify 'multipart/form-data'.
            headers: {},
            body: formData,
          });
        },
        transformResponse: response => camelCaseKeys(response),
        invalidatesTags: [DEAL_IMPORT_JOBS_TAG],
      }),
      updateDealImportJob: builder.mutation({
        query: dealImportJob => ({
          url: `/api/deal_import_jobs/${dealImportJob.id}`,
          method: 'PUT',
          body: snakeCaseKeys(dealImportJob),
        }),
        transformResponse: response => camelCaseKeys(response),
        invalidatesTags: [DEAL_IMPORT_JOBS_TAG],
      }),
    };
  },
});

export const {
  useFetchDealImportJobQuery,
  useFetchDealImportJobsQuery,
  usePostDealImportJobMutation,
  useUpdateDealImportJobMutation,
} = extendedApiSlice;
