import { useEffect, useRef } from 'react';

const usePreviousValue = (arg, initialValue = undefined) => {
  const valueRef = useRef(initialValue);
  const prevValue = valueRef.current;
  useEffect(() => {
    valueRef.current = arg;
  });

  return prevValue;
};

export default usePreviousValue;
