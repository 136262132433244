import { newModel } from 'actions/model';
import { formatCurrency, formatInteger, formatNone, formatPercentage } from 'components/utils';
import { compact, flatten, floor, forEach, isNaN, isNil, map, mean, orderBy, round, size, sum, uniq } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const BASE_PORTFOLIO_SCENARIO = 'Base Portfolio';
const SINGLE_FAMILY_ROW_HEADER = ['', 'BEDROOMS', 'BATHROOMS', 'RSF', 'COUNT', '', '$', '$PSF', '%DIFF'];

function RowHeaders() {
  return (
    <div className="flex flex-col">
      {SINGLE_FAMILY_ROW_HEADER.map((header, index) => (
        <div
          style={{ width: '107px', height: '53px' }}
          key={`${header}-${index}`}
          className="text-xs text-left pl-4 pt-6 font-medium text-neutral-dark bg-primary-surface border-r border-b border-gray-200"
        >
          {header}
        </div>
      ))}
    </div>
  );
}

const blendedRent = (unitRent) => {
  const { actualRent, currentAskingRent } = unitRent;
  return actualRent > 0 ? actualRent : currentAskingRent;
};

const summaryData = (selectedUnitRents, subjectProperty, rentRolls) => {
  const { numberOfBedrooms, numberOfBathroomsTotal, unitRsf } = subjectProperty;
  const subjectBedroom = numberOfBedrooms === 'studio' ? 0 : numberOfBedrooms;

  // SUBJECT DATA
  const subject = [];
  const marketRent = [];
  const avgMarketRent = mean(rentRolls?.filter((rr) => rr.bedrooms === numberOfBedrooms).map((rr) => rr.marketRent));
  const formattedPsf = (avgMarketRent / unitRsf) ? `$${(avgMarketRent / unitRsf).toFixed(2)} PSF` : '-';
  subject.push([[subjectBedroom, formatNone], [numberOfBathroomsTotal, formatNone], [unitRsf, formatInteger], ['-', formatInteger]]);
  marketRent.push([[avgMarketRent, formatCurrency], [formattedPsf, formatNone], ['-', formatNone]]);

  // COMPARABLE SET DATA
  const comparableSet = [];
  const blended = [];
  const selectedBedrooms = orderBy(uniq(compact(selectedUnitRents.map(unitRent => unitRent.numberOfBedrooms))), (key) => key, 'desc');
  const selectedBathrooms = orderBy(uniq(compact(selectedUnitRents.map(unitRent => unitRent.numberOfBathroomsTotal))), (key) => key, 'desc');
  const aggregatedBedBath = flatten(map(selectedBedrooms, (bed) => map(selectedBathrooms, (bath) => [bed, bath])));
  const aggregatedBedBathObject = [];
  forEach(aggregatedBedBath, ([bed, bath]) => {
    const count = size(selectedUnitRents.filter(unitRent => unitRent.numberOfBedrooms === bed && unitRent.numberOfBathroomsTotal === bath));
    if (count === 0) return;
    aggregatedBedBathObject.push({ bath, bed, count });
  });

  // make comparable set ordering by bedSumProduct
  const bedSumProductArray = [];
  forEach(selectedBedrooms, (bedroom) => {
    const currentBedroomObject = aggregatedBedBathObject.filter(({ bed }) => bed === bedroom);
    const sumCount = sum(currentBedroomObject.map(({ count }) => count));
    const bedSumProduct = sum(currentBedroomObject.map(({ bed, count }) => bed * count)) / sumCount;
    const bathSumProduct = sum(currentBedroomObject.map(({ bath, count }) => bath * count)) / sumCount;
    const averageRsf = floor(mean(selectedUnitRents.filter(unitRent => unitRent.numberOfBedrooms === bedroom && !isNil(unitRent.numberOfBathroomsTotal)).map(unitRent => unitRent.unitRsf)));

    bedSumProductArray.push({ bedroom, bedSumProduct, bathSumProduct, averageRsf, count: sumCount });
  });
  const orderedComparableSet = orderBy(bedSumProductArray, (key) => key.bedSumProduct, 'asc').map(obj => [obj.bedroom, obj.bedSumProduct, obj.bathSumProduct, obj.averageRsf, obj.count]);

  forEach(orderedComparableSet, ([bedroom, bedSumProduct, bathSumProduct, averageRsf, count]) => {
    comparableSet.push([
      [bedSumProduct, formatNone],
      [round(bathSumProduct, 2), formatNone],
      [averageRsf, formatInteger],
      [count, formatInteger],
    ]);

    const avgBlendedRent = mean(compact(selectedUnitRents.filter(unitRent => unitRent.numberOfBedrooms === bedroom).map((unitRent) => blendedRent(unitRent))));
    const psf = avgBlendedRent / averageRsf;
    const diff = (avgBlendedRent - avgMarketRent) / avgMarketRent;

    blended.push([
      [avgBlendedRent, formatCurrency],
      [`$${psf.toFixed(2)} PSF`, formatNone],
      [diff, formatPercentage],
    ]);
  });

  return [subject, marketRent, comparableSet, blended];
};

function TableCell({ cell, cellWidth }) {
  const [value, format] = cell;
  const formattedValue = isNaN(value) || value === 0 ? '-' : format(value);

  return (
    <div style={{ width: `${cellWidth}px`, height: '53px' }} className="pr-4 pt-6 text-right text-xs font-medium text-neutral-dark border-b border-r border-gray-200">
      {formattedValue}
    </div>
  );
}

function TableColumn({ column, columnIndex, cellWidth }) {
  return column.map((cell, cellIndex) => <TableCell key={`table-one-${columnIndex}-${cellIndex}`} cell={cell} cellIndex={cellIndex} columnIndex={columnIndex} cellWidth={cellWidth} />);
}

function SingleFamilySummaryTab({ selectedUnitRents, subject }) {
  const dispatch = useDispatch();
  const { property_id: portfolioPropertyId } = useParams();
  const { baseParams: model } = useSelector((state) => state.model);
  const { currentItem } = useSelector((state) => state.navigation);

  let listing, id, propertyId, rentRolls;

  // TODO: switch from using old item arch
  if (currentItem) {
    listing = currentItem?.listing.data.attributes;
    // eslint-disable-next-line prefer-destructuring
    id = listing.id;
    propertyId = portfolioPropertyId || listing.property.id;
  }

  if (model) rentRolls = model?.dcfParams.units;

  useEffect(() => {
    if ((id || propertyId) && !model) {
      dispatch(newModel(id, propertyId));
    }
  }, [id]);

  const scenarioName = model && model.scenario ? model.scenario.name : BASE_PORTFOLIO_SCENARIO;
  const [subjectTable, marketRentTable, comparableSetTable, blendedTable] = summaryData(selectedUnitRents, subject, rentRolls);
  const subjectCellWidth = subjectTable.length === 1 ? 162 : 81;
  const comparableSetCellWidth = comparableSetTable.length === 1 ? 162 : 81;

  return (
    <div className="flex border-l border-t border-gray-200">
      <RowHeaders />
      {/* Subject */}
      {subjectTable.length > 0 && (
      <div className="flex flex-col">
        <div style={{ height: '53px' }} className="text-neutral-dark text-base pt-4 font-medium bg-primary-surface text-center border-b border-r border-gray-200">
          Subject
        </div>
        <div className="flex">
          {subjectTable.map((column, columnIndex) => (
            <div key={`subjectTable-${columnIndex}`}>
              <TableColumn column={column} columnIndex={columnIndex} cellWidth={subjectCellWidth} />
            </div>
          ))}
        </div>
        <div style={{ height: '53px' }} className="pt-3 text-xs bg-primary-surface text-center border-b border-r border-gray-200 uppercase">
          {scenarioName}
          <br />
          MARKET RENT
        </div>
        <div className="flex">
          {marketRentTable.map((column, columnIndex) => (
            <div key={`marketRentTable-${columnIndex}`}>
              <TableColumn column={column} columnIndex={columnIndex} cellWidth={subjectCellWidth} />
            </div>
          ))}
        </div>
      </div>
      )}

      {/* Comparable Set */}
      {comparableSetTable.length > 0 && (
      <div className="flex flex-col">
        <div style={{ height: '53px' }} className="text-neutral-dark text-base pt-4 font-medium bg-primary-surface text-center border-b border-r border-gray-200">
          Comparable Set
        </div>
        <div className="flex">
          {comparableSetTable.map((column, columnIndex) => (
            <div key={`comparableSetTable-${columnIndex}`}>
              <TableColumn column={column} columnIndex={columnIndex} cellWidth={comparableSetCellWidth} />
            </div>
          ))}
        </div>
        <div style={{ height: '53px' }} className="pt-6 text-xs bg-primary-surface text-center border-b border-r border-gray-200 uppercase">
          BLENDED COMP RENT
        </div>
        <div className="flex">
          {blendedTable.map((column, columnIndex) => (
            <div key={`blendedTable-${columnIndex}`}>
              <TableColumn column={column} columnIndex={columnIndex} cellWidth={comparableSetCellWidth} />
            </div>
          ))}
        </div>
      </div>
      )}
    </div>
  );
}

export default SingleFamilySummaryTab;
