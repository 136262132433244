import { map, mean } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { filteredLeases } from 'selectors/lease';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import LeaseTable from './LeaseTable';

const VACANT = 'Vacant';
const ON_THE_MARKET = 'On the Market';

function LongDOM() {
  const leaseData = useSelector(filteredLeases);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const newData = leaseData.filter(lease => {
      const isOverAMonth = lease.attributes.dom > 30;
      const isVacant = lease.attributes.occupancyStatus === VACANT;
      const isOnMarket = lease.attributes.propertyStatus === ON_THE_MARKET;
      return isOverAMonth && isVacant && isOnMarket;
    });

    setFilteredData(newData);
  }, [leaseData]);

  const averageDOM = mean(map(filteredData, (lease) => lease.attributes.dom)).toFixed(2);

  const metrics = [
    { label: 'Long DOM Units', value: filteredData.length, valueClassNames: 'text-red-600 font-extrabold' },
    { label: 'Long DOM Avg', value: averageDOM },
  ];

  return (
    <div className="flex flex-col mt-4 h-full">
      <ReturnMetricBoxes metrics={metrics} />
      <div className="flex-1 mt-4 pb-20 overflow-y-auto mb-5">
        <LeaseTable filteredData={filteredData} tableType="Long DOM" />
      </div>
    </div>

  );
}

export default LongDOM;
