import { InputCell } from 'components/shared/Table/Cells';
import { deliveryScheduleMetaKey } from './subdivision';

export default function QtyEditCell({ row, column, getValue }) {
  const { columnDef: { meta: { [deliveryScheduleMetaKey]: deliveryScheduleDate } } } = column;

  const qtyAvailable = deliveryScheduleDate === 'now' ? (
    row.getValue('numAvailable')
  ) : (
    row.getValue('futureDeliveries').find(([date]) => deliveryScheduleDate === date)?.[1]
  );

  const dateAvailable = qtyAvailable > 0;
  const trailingLabel = dateAvailable ? `/ ${qtyAvailable}` : '–';
  const moreThanAvailable = getValue() && (getValue() > qtyAvailable);

  return (
    <div className="inline-flex items-center justify-end gap-x-[0.5ch]">
      {dateAvailable && (
        <div className="min-w-7">
          <InputCell
            row={row}
            column={column}
            getValue={getValue}
            max={qtyAvailable}
            error={moreThanAvailable}
          />
        </div>
      )}
      <div className="text-neutral-dark/75">{trailingLabel}</div>
    </div>
  );
}
