import Table from 'rc-table';
import { useState } from 'react';
import { addMonths, format, startOfMonth } from 'date-fns';
import { chunk, first, indexOf, isEmpty, last, mean, range, slice, sum, take } from 'lodash';
import { dateFromString, formatCurrency, formatDate, formatPercentage, sumArrays } from 'components/utils';
import { useSelector } from 'react-redux';
import { calcOpExRatios, holdPeriodInMonths, isItemized } from './dcf';
import { calcExpenseItemCashFlow } from './expense';
import { CustomExpandIcon } from './Output/CashFlow';
import renderCashFlowRow from './Output/CashFlows';

const NUMBER_OF_MONTHS = 12;

function SFRSummaryOutput({ cashFlows, dcfParams, returnMetrics }) {
  const [expandedRows, setExpandedRows] = useState([]);
  const { activeToast } = useSelector(state => state.toast);
  const { stabilizationMonth } = returnMetrics;
  const { closingDate, ltOneYrHold, units } = dcfParams;
  const expenseIsItemized = isItemized(dcfParams);
  const months = range(1, 13 + holdPeriodInMonths(dcfParams));
  const parsedClosingDate = dateFromString(closingDate);
  const dates = months.map(month => startOfMonth(addMonths(parsedClosingDate, month)));
  const annualizedDates = take(chunk(dates, NUMBER_OF_MONTHS).map(datesChunk => datesChunk[0]), 1);
  const [annualize, setAnnualize] = useState(!ltOneYrHold);
  const dateCols = (annualize ? annualizedDates : dates).map(date => formatDate(date));
  dateCols.unshift(formatDate(parsedClosingDate)); // add 0 col
  const stabilizationPeriods = stabilizationMonth ? `${format(first(slice(dates, (stabilizationMonth - 1), ((stabilizationMonth + NUMBER_OF_MONTHS) - 1))), 'MMM yy')} -  ${format(last(slice(dates, (stabilizationMonth - 1), ((stabilizationMonth + NUMBER_OF_MONTHS) - 1))), 'MMM yy')}` : '-';

  const separatorClassName = 'bg-gray-50 font-bold';

  const { economicOccupancyRates } = cashFlows.occupancy;
  const { turnoverCosts, taxes, controllableExpenses, nonControllableExpenses, totalOperatingExpenses } = cashFlows.expenses;
  const { grossPotentialRents, lossToLeases, rolloverVacancies, concessions, grossRents, reimbursableExpenses, otherIncomes, staticVacancies, collectionLosses, effectiveGrossRevenues, grossRevenues } = cashFlows.revenue;

  const firstYear = (annualizeFunc, formatter, values) => formatter(annualizeFunc(slice(values, (stabilizationMonth - 1), ((stabilizationMonth + NUMBER_OF_MONTHS) - 1))));

  function expenseChildren(controllable) {
    const childrenArr = [];
    dcfParams.expenseItems
      .filter((expenseItem) => expenseItem.controllable === controllable)
      .forEach((expenseItem) => childrenArr.push({ label: expenseItem.name, b: firstYear(sum, formatCurrency, calcExpenseItemCashFlow(expenseItem, dcfParams, effectiveGrossRevenues, grossRents)) }));
    return childrenArr;
  }

  const addRsf = units?.reduce((acc, v) => {
    acc[v.rsf] = (acc[v.rsf] || 0) + v.rsf;
    return acc;
  }, {});

  const totalRsf = Object.values(addRsf);
  const netOperatingIncome = sumArrays(effectiveGrossRevenues, totalOperatingExpenses.map(v => v * -1));
  const totalGrossRents = sumArrays(grossPotentialRents, lossToLeases, rolloverVacancies, concessions);
  const totalGrossRevenues = sumArrays(grossRents, reimbursableExpenses, otherIncomes);
  const totalEffectiveGrossRevenues = sumArrays(grossRevenues, staticVacancies, collectionLosses);

  const purchasePrice = cashFlows.acquisition.price[0] * -1;
  const acquisitionCost = cashFlows.acquisition.cost[0] * -1;
  const followOnCapitalProjects = sum(cashFlows.capital.followOnCapitalExpenses) * -1;
  const originationFee = cashFlows.financing.loanOriginationFees[0] * -1;
  const totalAcquisitionCapital = purchasePrice + acquisitionCost + originationFee;
  const totalUses = totalAcquisitionCapital + followOnCapitalProjects;

  /// Untrended Calcs ///
  const stablizedUnleveredBasisAmount = cashFlows.unleveredBasis.endingBasis[stabilizationMonth];
  const totalMarketRent = sum(units.map((unit) => unit.marketRent * 12));
  const stabEconomicOccArray = cashFlows.occupancy.economicOccupancyRates.slice(stabilizationMonth, stabilizationMonth + 12);
  const stabEconomicOccRate = mean(stabEconomicOccArray);
  const grossRentArray = cashFlows.revenue.grossRents;
  const otherIncomeArray = cashFlows.revenue.otherIncomes;
  const grossRentPercentage = otherIncomeArray.map((num, idx) => num / grossRentArray[idx]);
  const oiGrossRentPercentArray = grossRentPercentage.slice(stabilizationMonth, stabilizationMonth + 12);
  const oiGrossRentPercent = mean(oiGrossRentPercentArray);
  const untrendedRevenue = (totalMarketRent * stabEconomicOccRate) * (1 + oiGrossRentPercent);
  const opExRatio = calcOpExRatios(cashFlows);
  const stabOpExRatioArray = opExRatio.slice(stabilizationMonth, stabilizationMonth + 12);
  const stabOpExRatio = mean(stabOpExRatioArray);
  const untrendedNoi = untrendedRevenue * (1 - stabOpExRatio);
  const untrendedYoc = untrendedNoi / stablizedUnleveredBasisAmount;
  const totalPurchase = purchasePrice + acquisitionCost + originationFee;

  const rowData = [
    { label: 'Revenue', className: separatorClassName },
    { label: 'Average Economic Occupancy %', b: firstYear(mean, formatPercentage, economicOccupancyRates) },
    { label: 'Gross Potential Rent', b: firstYear(sum, formatCurrency, grossPotentialRents) },
    {
      label: 'Gross Rent',
      ...(indexOf(expandedRows, 'Gross Rent') === -1 ? { b: firstYear(sum, formatCurrency, grossRents) } : {}),
      children: [
        { label: 'Loss to Lease', b: firstYear(sum, formatCurrency, lossToLeases) },
        { label: 'Lease-Up / Downtime Vacancy', b: firstYear(sum, formatCurrency, rolloverVacancies) },
        { label: 'Concessions', b: firstYear(sum, formatCurrency, concessions) },
        { label: 'Total', b: firstYear(sum, formatCurrency, totalGrossRents), className: 'font-semibold -2 bg-gray-50 -slate-300' },
      ],
    },
    {
      label: 'Gross Revenue',
      ...(indexOf(expandedRows, 'Gross Revenue') === -1 ? { b: firstYear(sum, formatCurrency, grossRevenues) } : {}),
      children: [
        { label: 'Reimbursable Expenses', b: firstYear(sum, formatCurrency, reimbursableExpenses) },
        { label: 'Other Income', b: firstYear(sum, formatCurrency, otherIncomes) },
        { label: 'Total', b: firstYear(sum, formatCurrency, totalGrossRevenues), className: 'font-semibold -2 bg-gray-50 -slate-300' },

      ],
    },
    {
      label: 'Effective Gross Revenue',
      ...(indexOf(expandedRows, 'Effective Gross Revenue') === -1 ? { b: firstYear(sum, formatCurrency, effectiveGrossRevenues) } : {}),
      children: [
        { label: 'Static Vacancy', b: firstYear(sum, formatCurrency, staticVacancies) },
        { label: 'Collection Loss', b: firstYear(sum, formatCurrency, collectionLosses) },
        { label: 'Total', b: firstYear(sum, formatCurrency, totalEffectiveGrossRevenues), className: 'font-semibold -2 bg-gray-50 -slate-300' },
      ],
    },
    ...(expenseIsItemized ? [
      {
        label: 'Controllable Expenses',
        ...(indexOf(expandedRows, 'Controllable Expenses') === -1 ? { b: firstYear(sum, formatCurrency, controllableExpenses) } : {}),
        children: [
          ...expenseChildren(true),
          { label: 'Turnover Costs', b: firstYear(sum, formatCurrency, turnoverCosts) },
          { label: 'Total', b: firstYear(sum, formatCurrency, controllableExpenses), className: 'font-semibold -2 bg-gray-50 -slate-300' },
        ],
      },
      {
        label: 'Non-Controllable Expenses',
        ...(indexOf(expandedRows, 'Non-Controllable Expenses') === -1 ? { b: firstYear(sum, formatCurrency, nonControllableExpenses) } : {}),
        children: [
          ...expenseChildren(false),
          { label: 'Taxes', b: firstYear(sum, formatCurrency, taxes) },
          { label: 'Total', b: firstYear(sum, formatCurrency, nonControllableExpenses), className: 'font-semibold -2 bg-gray-50 -slate-300' },
        ],
      },
    ] : []),
    { label: 'Total Operating Expenses', b: firstYear(sum, formatCurrency, totalOperatingExpenses) },
    { label: 'Net Operating Income', b: firstYear(sum, formatCurrency, netOperatingIncome) },
    { label: 'Untrended Yield on Cost', b: formatPercentage(untrendedYoc, 2) },
    { label: 'Acquisition Capital', className: separatorClassName },
    {
      label: 'Purchase',
      ...(indexOf(expandedRows, 'Purchase') === -1 ? { a: formatCurrency(totalPurchase), b: formatPercentage(totalAcquisitionCapital / totalUses) } : {}),
      children: [
        { label: 'Purchase Price', a: formatCurrency(purchasePrice), b: formatPercentage(purchasePrice / totalUses) },
        { label: 'Acquisition Costs', a: formatCurrency(acquisitionCost), b: formatPercentage(acquisitionCost / totalUses) },
        { label: 'Acq. Origination Fee', a: formatCurrency(originationFee), b: formatPercentage(originationFee / totalUses) },
        { label: 'Total Purchase Price & Acquisition Cost', a: formatCurrency(totalAcquisitionCapital), b: formatPercentage(totalAcquisitionCapital / totalUses), className: 'font-semibold -2 bg-gray-50 -slate-300' },
      ],
    },
    { label: 'Total Capital Projects ', a: formatCurrency(followOnCapitalProjects), b: formatPercentage(followOnCapitalProjects / totalUses) },
    { label: 'Total Acquisition Capital', a: formatCurrency(totalAcquisitionCapital + followOnCapitalProjects), b: formatPercentage((totalAcquisitionCapital + followOnCapitalProjects) / totalUses), className: 'font-bold' },

    { label: 'CapEx', className: separatorClassName },
    { label: 'Total Capital Projects', b: formatCurrency(followOnCapitalProjects) },
    { label: 'CapEx % of Purchase Price', b: formatPercentage(followOnCapitalProjects / purchasePrice, 2) },
    { label: 'Total CapEx $PSF', b: formatCurrency(followOnCapitalProjects / totalRsf, 2) },
  ];

  const rows = rowData.map(rd => renderCashFlowRow(rd, annualize, dateCols));

  const columns = [
    {
      title: (
        <div className="h-18 flex pt-4 flex-col">
          <div className="text-neutral-dark text-sm font-bold">Stabilization Period Pro Forma Income Statement</div>
          <div className="text-neutral-medium text-xs font-medium">Stabilization Month + 12 months cash flows</div>
        </div>
      ),
      dataIndex: 'label',
      key: 'label',
      align: 'left',
      width: '25%',
      fixed: 'left',
      className: 'bg-gray-50 text-sm px-4 cursor-pointer bg-gray-50 text-neutral-dark border-gray-300 border-b border-r',
    },
    {
      dataIndex: 'a',
      key: 'a',
      width: '6%',
      align: 'right',
      render: (v) => <div>{v}</div>,
      className: 'bg-white h-[53px] px-4 text-sm text-neutral-dark border-gray-300 border-b',
    },
    {
      title: (
        <div className="h-full flex flex-col pt-6">
          <span className="text-neutral-dark text-sm font-bold">{stabilizationPeriods}</span>
        </div>
      ),
      dataIndex: 'b',
      key: 'b',
      width: '6%',
      align: 'right',
      render: (v) => <div>{v}</div>,
      className: 'bg-white h-[53px] px-4 text-sm text-neutral-dark border-gray-300 border-b',
    },
  ];

  return (
    <div className="grow h-full overflow-hidden rounded-lg border-l border-r border-t border-gray-300 " style={{ marginBottom: isEmpty(activeToast) ? '5px' : '65px' }}>
      <Table
        columns={columns}
        data={rows}
        indentSize={20}
        rowClassName={(record) => `${record.className} hover:bg-blue-50`}
        onHeaderRow={() => ({ className: 'h-16 text-sm text-gray-500 font-light bg-gray-50 sticky top-0' })}
        expandable={{ expandRowByClick: true, expandIcon: (props) => CustomExpandIcon(props), defaultExpandAllRows: false, onExpandedRowsChange: (r) => setExpandedRows(r) }}
      />
    </div>
  );
}

export default SFRSummaryOutput;
