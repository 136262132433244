import { useEffect, useMemo, useRef, useState } from 'react';
import { NotificationBell } from 'components/icons';
import { ButtonNavItem, NavItemIcon, NavItemLabel } from 'components/shared/NavigationRail';
import IndicatorBadge from 'components/shared/IndicatorBadge';
import NotificationsDrawer from './NotificationsDrawer';
import { useFetchNotificationsQuery } from './notification.apiSlice';

function useInfiniteScroll({ contentRef, isFetching, maxPage, setPage }) {
  useEffect(() => {
    const contentDiv = contentRef.current;
    if (!contentDiv) return;

    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = contentDiv;
      const isBottom = scrollTop + clientHeight >= scrollHeight - 50;
      if (isBottom && !isFetching && maxPage) {
        setPage(prevPage => Math.min(prevPage + 1, maxPage));
      }
    };

    contentDiv.addEventListener('scroll', handleScroll);
    return () => contentDiv.removeEventListener('scroll', handleScroll);
  }, [contentRef, isFetching, maxPage, setPage]);
}

function Notifications({ notificationCount, showNotification, setShowNotification }) {
  const [page, setPage] = useState(1);
  const contentRef = useRef(null);
  const { data, isFetching } = useFetchNotificationsQuery({ page }, { skip: !showNotification });

  useInfiniteScroll({ contentRef, isFetching, maxPage: data?.pagy?.pages, setPage });
  const unseenCount = useMemo(() => data?.pagy?.unseenCount ?? notificationCount, [data?.pagy]);

  return (
    <>
      <ButtonNavItem active={showNotification} onClick={() => setShowNotification(!showNotification)}>
        <NavItemIcon>
          <div className="relative size-6">
            <NotificationBell className="mx-auto" filled={showNotification} />
            <IndicatorBadge count={unseenCount} />
          </div>
        </NavItemIcon>
        <NavItemLabel>Notifications</NavItemLabel>
      </ButtonNavItem>
      {showNotification && (
        <NotificationsDrawer
          contentRef={contentRef}
          isFetching={isFetching}
          notifications={data?.notifications?.data || []}
          setShowNotification={setShowNotification}
        />
      )}
    </>
  );
}

export default Notifications;
