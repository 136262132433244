import { useState } from 'react';
import {
  cloneDeep,
  find,
  isArray,
  isNull,
  isNumber,
  map,
  partial,
  pullAt,
  range,
  reject,
  sortBy,
  sum,
  take,
} from 'lodash';
import {
  calcExpenseItemCashFlow,
  EXPENSE_INPUT_METHODS,
  EXPENSE_ITEMS,
  hasExpenseItemCashFlow,
} from './expense';
import Select from '../Select';
import Input from '../Input';
import { TH, TR } from '../Table';
import PerUnitContainer from './PerUnitContainer';
import { formatPercentage, parseEventValue, sumArrays } from '../utils';
import { annualizeMonthlyReturns } from '../finance';
import { calcBelowTheLineExpenses } from './capital';
import {
  ITEMIZED_INPUT_METHOD_DPMPU,
  ITEMIZED_INPUT_METHOD_DPY,
  ITEMIZED_INPUT_METHOD_DPYPU,
  ITEMIZED_INPUT_METHOD_EGR,
  ITEMIZED_INPUT_METHOD_Y1EGR,
  ITEMIZED_INPUT_METHOD_EQUITY,
} from './itemizedItem';

const EXPENSE_OTHER_OPTION = 'Other';

function tableWidth(holdPeriod, isModelParameter) {
  return holdPeriod + (isModelParameter ? 6 : 5);
}

function ExpenseItemRow({
  dcfParams,
  holdPeriod,
  egr,
  grossRent,
  expenseItem,
  numberOfUnits,
  onChange,
  onRemove,
  total,
  isModelParameter = false,
  borderBottom = false,
  nameEditable = false,
  totalEquity,
  canEditPortfolio,
  belowTheLine,
}) {
  const expenseInputMethod = EXPENSE_INPUT_METHODS[expenseItem.inputMethod];
  const totalPerMonth = sum(take(calcExpenseItemCashFlow(expenseItem, dcfParams, egr, grossRent, totalEquity), 12)) / 12;
  const expenseInputOptions = cloneDeep(EXPENSE_INPUT_METHODS);
  if (isArray(expenseItem.reimbursablePercent)) {
    delete expenseInputOptions[ITEMIZED_INPUT_METHOD_EGR];
    delete expenseInputOptions[ITEMIZED_INPUT_METHOD_Y1EGR];
  }
  if (!belowTheLine) {
    delete expenseInputOptions[ITEMIZED_INPUT_METHOD_EQUITY];
  }

  const label = (
    <>
      <button type="button" className="bg-tertiary hover:bg-tertiary-lighter mr-2 px-2 text-white rounded" onClick={onRemove}>-</button>
      {nameEditable ? <Input name="name" value={expenseItem.name} type="text" onChange={onChange} disabled={!canEditPortfolio} /> : expenseItem.name}
    </>
  );
  const data = [
    <Select
      name="inputMethod"
      value={expenseItem.inputMethod}
      options={map(expenseInputOptions, (eim, value) => [value, eim.label])}
      onChange={onChange}
      width="w-56"
      disabled={!canEditPortfolio}
    />,
    <Input
      name="inputValue"
      value={expenseItem.inputValue}
      type={expenseInputMethod.inputType}
      min="0"
      onChange={onChange}
      disabled={!canEditPortfolio}
    />,
  ];

  if (hasExpenseItemCashFlow(expenseItem, dcfParams, egr, grossRent)) {
    data.push(
      <PerUnitContainer value={totalPerMonth} numberOfUnits={numberOfUnits} />,
      <PerUnitContainer annual value={totalPerMonth} numberOfUnits={numberOfUnits} />,
    );
  } else {
    data.push('n/a', 'n/a');
  }

  if (isModelParameter) {
    data.push(formatPercentage(totalPerMonth / total));
  }

  const onArrayValueChange = (key, index, event) => {
    const updatedValue = cloneDeep(expenseItem[key]);
    updatedValue[index] = parseEventValue(event);
    onChange({
      target: {
        name: key,
        value: updatedValue,
      },
    });
  };

  if (expenseInputMethod.specifyGrowthRates) {
    data.push(null);
    expenseItem.growthRates.slice(0, dcfParams.ltOneYrHold ? 1 : holdPeriod).forEach((growthRate, index) => {
      data.push(
        <Input
          name="growthRates"
          value={growthRate}
          type="percent"
          min="0"
          width="w-16"
          onChange={partial(onArrayValueChange, 'growthRates', index)}
          disabled={!canEditPortfolio}
        />,
      );
    });
  }

  // pad data with null for rows without growth rates or reduced growth rates
  if (data.length < tableWidth(holdPeriod, isModelParameter)) {
    data.push(...new Array(tableWidth(holdPeriod, isModelParameter) - data.length).fill(null));
  }

  let reimbursableRow = null;
  if (isArray(expenseItem.reimbursablePercent)) {
    const reimbursableData = new Array(isModelParameter ? 6 : 5).fill(null)
      .concat(take(expenseItem.reimbursablePercent, holdPeriod + 1).map((reimbursablePercent, index) => (
        <Input
          className="text-xs"
          name="reimbursablePercent"
          value={reimbursablePercent}
          type="percent"
          min="0"
          width="w-16"
          padding="py-px px-2"
          onChange={partial(onArrayValueChange, 'reimbursablePercent', index)}
          disabled={!canEditPortfolio}
        />
      )));
    reimbursableData[1] = <div className="pl-3 text-left text-sm text-gray-500">% Reimbursable</div>;
    reimbursableRow = <TR data={reimbursableData} />;
  }

  return (
    <>
      <TR data={data} label={label} borderBottom={borderBottom} />
      {reimbursableRow}
    </>
  );
}

function UnitTurnoverRow({
  annualizedTurnoverCashFlow,
  holdPeriod,
  numberOfUnits,
  setActiveParameterTab,
  total,
  isModelParameter = false,
}) {
  const turnoverTab = isModelParameter ? 'rentRoll' : 'unit_turn';
  const monthlyTurnoverExpense = annualizedTurnoverCashFlow[0] / 12;
  const data = [
    <div className="text-tertiary text-center underline cursor-pointer hover:text-tertiary-lighter" onClick={() => setActiveParameterTab(turnoverTab)}>Rent Roll Inputs</div>,
    null,
  ];

  if (isModelParameter) {
    data.push(
      <PerUnitContainer value={monthlyTurnoverExpense} numberOfUnits={numberOfUnits} />,
      <PerUnitContainer annual value={monthlyTurnoverExpense} numberOfUnits={numberOfUnits} />,
      formatPercentage(monthlyTurnoverExpense / total),
      null,
      ...annualizedTurnoverCashFlow.slice(1).map(turnover => <PerUnitContainer value={turnover} numberOfUnits={numberOfUnits} />),

    );
  }
  data.push(...new Array(tableWidth(holdPeriod, isModelParameter) - data.length).fill(null));

  return (
    <TR
      borderBottom
      label="Turnover Costs"
      data={data}
    />
  );
}

function TaxExpenseRow({
  realEstateTaxes,
  numberOfUnits,
  setActiveParameterTab,
  total,
  isModelParameter = false,
}) {
  const data = [
    <div className="text-tertiary text-center underline cursor-pointer hover:text-tertiary-lighter" onClick={() => setActiveParameterTab('taxes')}>Tax Inputs</div>,
    null,
  ];

  if (isModelParameter) {
    const monthlyTaxExpense = realEstateTaxes[0] / 12;
    data.push(
      <PerUnitContainer value={monthlyTaxExpense} numberOfUnits={numberOfUnits} />,
      <PerUnitContainer annual value={monthlyTaxExpense} numberOfUnits={numberOfUnits} />,
      formatPercentage(monthlyTaxExpense / total),
      null,
      ...realEstateTaxes.slice(1).map(tax => <PerUnitContainer value={tax} numberOfUnits={numberOfUnits} />),
    );
  } else {
    data.push(...new Array(4).fill(null));
  }

  return (
    <TR
      borderBottom
      label="Real Estate Taxes"
      data={data}
    />
  );
}

function TotalRow({
  borderBottom,
  holdPeriod,
  label,
  numberOfUnits,
  showTotal,
  rowTotal,
  total,
  isModelParameter = false,
}) {
  const data = [
    null,
    null,
    <PerUnitContainer value={rowTotal} numberOfUnits={numberOfUnits} />,
    <PerUnitContainer annual value={rowTotal} numberOfUnits={numberOfUnits} />,
  ];

  if (isModelParameter && showTotal) {
    data.push(formatPercentage(rowTotal / total));
  }
  data.push(...new Array(tableWidth(holdPeriod, isModelParameter) - data.length).fill(null));

  return (
    <TR
      borderBottom={borderBottom}
      label={label}
      data={data}
    />
  );
}

function AddExpenseItemRow({
  addExpenseItem,
  controllable,
  defaultGrowthRates,
  expenseItems,
  holdPeriod,
  isModelParameter = false,
  canEditPortfolio,
}) {
  const [customExpenseName, setCustomExpenseName] = useState(null);
  const currentExpenseItemNames = expenseItems.map(item => item.name);
  const expenseItemOptions = sortBy(EXPENSE_ITEMS.map(item => Object.assign(item, {
    inputValue: 0,
    inputMethod: isModelParameter ? ITEMIZED_INPUT_METHOD_DPY : ITEMIZED_INPUT_METHOD_DPYPU,
    growthRates: defaultGrowthRates,
    reimbursablePercent: isNumber(item.reimbursablePercent) ? new Array(holdPeriod + 1).fill(item.reimbursablePercent) : item.reimbursablePercent,
  })), 'name');
  const options = reject(expenseItemOptions, item => item.controllable !== controllable || currentExpenseItemNames.includes(item.name)).map(item => [item.name, item.name]);
  options.unshift(['', 'Add Expense Item']);
  options.push([EXPENSE_OTHER_OPTION, EXPENSE_OTHER_OPTION]);

  const onChange = (e) => {
    const { value } = e.target;
    if (value === EXPENSE_OTHER_OPTION) {
      setCustomExpenseName('');
    } else {
      addExpenseItem(cloneDeep(find(expenseItemOptions, { name: e.target.value })));
    }
  };

  let label;
  if (isNull(customExpenseName)) {
    label = (
      <Select
        className="mx-2"
        name="expenseItem"
        defaultValue="-"
        options={options}
        onChange={onChange}
        width="w-60"
        disabled={!canEditPortfolio}
      />
    );
  } else {
    const customExpenseItem = {
      name: customExpenseName,
      inputValue: 0,
      inputMethod: isModelParameter ? ITEMIZED_INPUT_METHOD_DPY : ITEMIZED_INPUT_METHOD_DPYPU,
      growthRates: defaultGrowthRates,
      reimbursablePercent: null,
      controllable,
    };
    const addCustomExpense = () => {
      addExpenseItem(customExpenseItem);
      setCustomExpenseName(null);
    };
    label = (
      <>
        <button type="button" className="bg-tertiary hover:bg-tertiary-lighter mr-2 px-2 text-white rounded" onClick={addCustomExpense}>+</button>
        <Input
          name="expenseItem"
          value={customExpenseName}
          type="text"
          onChange={(e) => setCustomExpenseName(e.target.value)}
        />
      </>
    );
  }

  return (
    <TR
      data={new Array(holdPeriod + (isModelParameter ? 6 : 5)).fill(null)}
      label={label}
    />
  );
}

function ItemizedExpenseBody({
  dcfParams,
  expenses,
  capital,
  egr,
  grossRent,
  onChange,
  setActiveParameterTab,
  isModelParameter = false,
  totalEquity,
  canEditPortfolio,
}) {
  const { expenseItems, units, inflationRate, inflationRates } = dcfParams;
  const { turnoverCosts, taxes, controllableExpenses, nonControllableExpenses, totalOperatingExpenses } = expenses;
  const { belowTheLineExpenses } = capital;
  const numberOfUnits = isModelParameter ? units.length : 1;
  const dynamicHoldPeriod = dcfParams.ltOneYrHold ? 1 : dcfParams.holdPeriod;
  const holdPeriod = isModelParameter ? dynamicHoldPeriod : 1;
  const defaultGrowthRates = isModelParameter ? inflationRates : [inflationRate];

  const annualizedTurnoverCashFlow = isModelParameter && annualizeMonthlyReturns(turnoverCosts);
  const realEstateTaxes = isModelParameter && annualizeMonthlyReturns(taxes);
  const controllableTotals = annualizeMonthlyReturns(controllableExpenses);
  const totalNonControllableExpenses = annualizeMonthlyReturns(nonControllableExpenses);
  const total = annualizeMonthlyReturns(totalOperatingExpenses);
  const sumMonthlyTotal = total[0] / 12;

  const annualizedBelowTheLineExpenses = annualizeMonthlyReturns(belowTheLineExpenses.slice(1));
  const sumMonthlyOpExAndBelowTheLineTotal = (total[0] - annualizedBelowTheLineExpenses[0]) / 12;

  const onExpenseItemChange = (index, event) => {
    const updatedExpenseItems = cloneDeep(expenseItems);
    const field = event.target.name;
    const newValue = parseEventValue(event);
    updatedExpenseItems[index][field] = newValue;
    onChange({
      target: {
        name: 'expenseItems',
        value: updatedExpenseItems,
      },
    });
  };

  const addExpenseItem = (expenseItem) => {
    const updatedExpenseItems = cloneDeep(expenseItems);
    updatedExpenseItems.push(expenseItem);
    onChange({
      target: {
        name: 'expenseItems',
        value: updatedExpenseItems,
      },
    });
  };

  const removeExpenseItem = (index) => {
    const updatedExpenseItems = cloneDeep(expenseItems);
    pullAt(updatedExpenseItems, [index]);
    onChange({
      target: {
        name: 'expenseItems',
        value: updatedExpenseItems,
      },
    });
  };

  return (
    <>
      <tbody>
        {!isModelParameter && (<TR data={[]} label="Controllable Expenses" labelClassName="font-medium" />)}
        {expenseItems.map((ei, index) => [ei, index])
          .filter(eiPair => eiPair[0].controllable)
          .map(eiPair => (
            <ExpenseItemRow
              key={eiPair[1]}
              dcfParams={dcfParams}
              holdPeriod={holdPeriod}
              numberOfUnits={numberOfUnits}
              egr={egr}
              grossRent={grossRent}
              expenseItem={eiPair[0]}
              onChange={partial(onExpenseItemChange, eiPair[1])}
              onRemove={partial(removeExpenseItem, eiPair[1])}
              total={sumMonthlyTotal}
              isModelParameter={isModelParameter}
              totalEquity={totalEquity}
              canEditPortfolio={canEditPortfolio}
            />
          ))}
        <AddExpenseItemRow
          controllable
          defaultGrowthRates={defaultGrowthRates}
          expenseItems={expenseItems}
          holdPeriod={holdPeriod}
          addExpenseItem={addExpenseItem}
          isModelParameter={isModelParameter}
          canEditPortfolio={canEditPortfolio}
        />
        <UnitTurnoverRow
          annualizedTurnoverCashFlow={annualizedTurnoverCashFlow}
          holdPeriod={holdPeriod}
          numberOfUnits={numberOfUnits}
          setActiveParameterTab={setActiveParameterTab}
          total={sumMonthlyTotal}
          isModelParameter={isModelParameter}
        />
        {isModelParameter && (
          <TotalRow
            borderBottom
            label="Total Controllable"
            holdPeriod={holdPeriod}
            numberOfUnits={numberOfUnits}
            rowTotal={controllableTotals[0] / 12}
            showTotal={isModelParameter}
            total={sumMonthlyTotal}
            isModelParameter={isModelParameter}
          />
        )}
        {!isModelParameter && (<TR data={[]} label="Non-Controllable Expenses" labelClassName="font-medium" />)}
        {expenseItems.map((ei, index) => [ei, index])
          .filter(eiPair => !eiPair[0].controllable)
          .map(eiPair => (
            <ExpenseItemRow
              key={eiPair[1]}
              dcfParams={dcfParams}
              holdPeriod={holdPeriod}
              numberOfUnits={numberOfUnits}
              egr={egr}
              grossRent={grossRent}
              expenseItem={eiPair[0]}
              onChange={partial(onExpenseItemChange, eiPair[1])}
              onRemove={partial(removeExpenseItem, eiPair[1])}
              total={sumMonthlyTotal}
              isModelParameter={isModelParameter}
              totalEquity={totalEquity}
              canEditPortfolio={canEditPortfolio}
            />
          ))}
        <AddExpenseItemRow
          controllable={false}
          defaultGrowthRates={defaultGrowthRates}
          expenseItems={expenseItems}
          holdPeriod={holdPeriod}
          addExpenseItem={addExpenseItem}
          isModelParameter={isModelParameter}
          canEditPortfolio={canEditPortfolio}
        />
        <TaxExpenseRow
          realEstateTaxes={realEstateTaxes}
          numberOfUnits={numberOfUnits}
          setActiveParameterTab={setActiveParameterTab}
          total={sumMonthlyTotal}
          isModelParameter={isModelParameter}
        />
        {isModelParameter && (
          <>
            <TotalRow
              borderBottom
              label="Total Non-Controllable"
              holdPeriod={holdPeriod}
              numberOfUnits={numberOfUnits}
              showTotal={isModelParameter}
              rowTotal={totalNonControllableExpenses[0] / 12}
              total={sumMonthlyTotal}
              isModelParameter={isModelParameter}
            />
            <TotalRow
              borderBottom
              label="Total Operating Expenses"
              holdPeriod={holdPeriod}
              numberOfUnits={numberOfUnits}
              rowTotal={sumMonthlyTotal}
              isModelParameter={isModelParameter}
            />
          </>
        )}
      </tbody>
      <BelowTheLineExpenseTable
        borderBottom
        dcfParams={dcfParams}
        opExY1MonthlyTotal={sumMonthlyTotal}
        egr={egr}
        grossRent={grossRent}
        onChange={onChange}
        isModelParameter={isModelParameter}
        totalEquity={totalEquity}
        canEditPortfolio={canEditPortfolio}
      />
      {isModelParameter && (
        <tfoot>
          <TotalRow
            label="Total OpEx + Below the Line Expenses"
            holdPeriod={holdPeriod}
            numberOfUnits={numberOfUnits}
            rowTotal={sumMonthlyOpExAndBelowTheLineTotal}
            isModelParameter={isModelParameter}
          />
        </tfoot>
      )}
    </>
  );
}

function AddBelowTheLineExpenseItemRow({ holdPeriod, onClick, isModelParameter = false }) {
  const label = (
    <button
      type="button"
      className="bg-tertiary hover:bg-tertiary-lighter px-2 mx-2 text-white rounded"
      onClick={onClick}
    >
      Add Below the Line Expense Item
    </button>
  );

  return (
    <TR center label={label} data={new Array(tableWidth(holdPeriod, isModelParameter)).fill(null)} />
  );
}

function PortfolioHeader() {
  return (
    <thead className="bg-gray-50 border-b-2">
      <tr>
        <TH value="" />
        <TH value="" />
        <TH value="" />
        <TH value="Growth Rates" colSpan={4} />
      </tr>
      <tr>
        <TH value="Description" />
        <TH value="Input Methodology" />
        <TH value="Input Value" />
        <TH value="1" />
        <TH value="2+" />
        <TH value="" />
        <TH value="" />
      </tr>
    </thead>
  );
}

function ModelExpenseHeader({ holdPeriod, y1ExpenseRatio }) {
  return (
    <thead className="bg-gray-50 border-b-2">
      <tr>
        <TH value={`Y1 Expense Ratio: ${formatPercentage(y1ExpenseRatio)}`} />
        <TH value="" />
        <TH value="" />
        <TH value="" />
        <TH value="" />
        <TH value="" />
        <TH value="Growth Rates" colSpan={holdPeriod + 1} />
      </tr>
      <tr>
        <TH value="Description" />
        <TH value="Input Methodology" />
        <TH value="Input Value" />
        <TH value="$ / Month" />
        <TH value="$ / Year" />
        <TH value="% of Total" />
        {range(1, holdPeriod + 2).map(year => <TH key={year} value={year} />)}
      </tr>
    </thead>
  );
}

function ModelBelowTheLineExpenseHeader({ holdPeriod }) {
  return (
    <thead className="bg-gray-50 border-b-2">
      <tr>
        <TH value="" />
        <TH value="" />
        <TH value="" />
        <TH value="" />
        <TH value="" />
        <TH value="" />
        <TH value="Growth Rates" colSpan={holdPeriod + 1} />
      </tr>
      <tr>
        <TH value="Description" />
        <TH value="Input Methodology" />
        <TH value="Input Value" />
        <TH value="$ / Month" />
        <TH value="$ / Year" />
        <TH value="% of Total" />
        {range(1, holdPeriod + 2).map(year => <TH key={year} value={year} />)}
      </tr>
    </thead>
  );
}

export function BelowTheLineExpenseTable({
  dcfParams,
  onChange,
  opExY1MonthlyTotal = 0,
  egr = [],
  grossRent = [],
  includeHeader = false,
  isModelParameter = false,
  borderBottom = false,
  totalEquity,
  canEditPortfolio,
}) {
  const { inflationRate, inflationRates, units, belowTheLineExpenseItems } = dcfParams;
  const numberOfUnits = isModelParameter ? units.length : 1;
  const holdPeriod = isModelParameter ? dcfParams.holdPeriod : 1;
  const belowTheLineExpenseY1MonthlyTotal = annualizeMonthlyReturns(calcBelowTheLineExpenses(dcfParams, egr, grossRent, totalEquity).slice(1))[0] / 12;

  const onExpenseItemChange = (index, event) => {
    const updatedExpenseItems = cloneDeep(belowTheLineExpenseItems);
    const field = event.target.name;
    const newValue = parseEventValue(event);
    updatedExpenseItems[index][field] = newValue;
    onChange({
      target: {
        name: 'belowTheLineExpenseItems',
        value: updatedExpenseItems,
      },
    });
  };

  const removeExpenseItem = (index) => {
    const updatedExpenseItems = cloneDeep(belowTheLineExpenseItems);
    pullAt(updatedExpenseItems, [index]);
    onChange({
      target: {
        name: 'belowTheLineExpenseItems',
        value: updatedExpenseItems,
      },
    });
  };

  const defaultGrowthRates = isModelParameter ? inflationRates.slice(0, holdPeriod - 1) : [inflationRate];
  const addExpenseItem = () => {
    const updatedExpenseItems = cloneDeep(belowTheLineExpenseItems);
    updatedExpenseItems.push({
      name: '',
      inputValue: 0,
      inputMethod: ITEMIZED_INPUT_METHOD_DPMPU,
      growthRates: defaultGrowthRates,
      reimbursablePercent: null,
      controllable: null,
    });
    onChange({
      target: {
        name: 'belowTheLineExpenseItems',
        value: updatedExpenseItems,
      },
    });
  };

  return (
    <>
      { includeHeader && (isModelParameter ? <ModelBelowTheLineExpenseHeader holdPeriod={dcfParams.ltOneYrHold ? 1 : holdPeriod} /> : <PortfolioHeader />) }
      <tbody>
        {!isModelParameter && (<TR data={[]} label="Below the Line Expenses" labelClassName="font-medium" />)}
        {belowTheLineExpenseItems.map((ei, index) => (
          <ExpenseItemRow
            nameEditable
            key={index}
            dcfParams={dcfParams}
            holdPeriod={dcfParams.ltOneYrHold ? 1 : holdPeriod}
            numberOfUnits={numberOfUnits}
            egr={egr}
            grossRent={grossRent}
            expenseItem={ei}
            onChange={partial(onExpenseItemChange, index)}
            onRemove={partial(removeExpenseItem, index)}
            total={opExY1MonthlyTotal + belowTheLineExpenseY1MonthlyTotal}
            isModelParameter={isModelParameter}
            borderBottom={index === (belowTheLineExpenseItems.length - 1)}
            totalEquity={totalEquity}
            canEditPortfolio={canEditPortfolio}
            belowTheLine
          />
        ))}
        { canEditPortfolio && (
        <AddBelowTheLineExpenseItemRow
          holdPeriod={dcfParams.ltOneYrHold ? 1 : holdPeriod}
          onClick={addExpenseItem}
          isModelParameter={isModelParameter}
        />
        )}
        {isModelParameter && (
          <TotalRow
            borderBottom={borderBottom}
            label="Total Below the Line Expenses"
            holdPeriod={dcfParams.ltOneYrHold ? 1 : holdPeriod}
            numberOfUnits={numberOfUnits}
            rowTotal={belowTheLineExpenseY1MonthlyTotal}
            isModelParameter={isModelParameter}
          />
        )}
      </tbody>
    </>
  );
}

export function PortfolioItemizedExpenseTable({ parameters, setActiveParameterTab, onChange, canEditPortfolio }) {
  // there is no egr or gross rent when modifying portfolio parameters
  // [] is sufficient for this table
  const egr = [];
  const grossRent = [];
  const controllable = parameters.expenseItems
    .filter(expenseItem => expenseItem.controllable)
    .map(item => calcExpenseItemCashFlow(item, parameters, egr, grossRent));
  const nonControllable = parameters.expenseItems
    .filter(expenseItem => !expenseItem.controllable)
    .map(item => calcExpenseItemCashFlow(item, parameters, egr, grossRent));

  const controllableExpenses = sumArrays(...controllable);
  const nonControllableExpenses = sumArrays(...nonControllable);
  const totalOperatingExpenses = sumArrays(
    controllableExpenses,
    nonControllableExpenses,
  );

  const expenses = {
    controllableExpenses,
    nonControllableExpenses,
    totalOperatingExpenses,
  };

  const capital = { belowTheLineExpenses: calcBelowTheLineExpenses(parameters, egr, grossRent).map(cf => -cf) };

  return (
    <table className="min-w-full divide-y divide-gray-200 bg-white">
      <PortfolioHeader />
      <ItemizedExpenseBody
        dcfParams={parameters}
        expenses={expenses}
        capital={capital}
        egr={egr}
        grossRent={grossRent}
        onChange={onChange}
        setActiveParameterTab={setActiveParameterTab}
        canEditPortfolio={canEditPortfolio}
      />
    </table>
  );
}

export function ModelItemizedExpenseTable({ cashFlows, dcfParams, setActiveParameterTab, onChange, canEditPortfolio }) {
  const { revenue, expenses, capital, totalEquity } = cashFlows;
  const holdPeriod = dcfParams.ltOneYrHold ? 1 : dcfParams.holdPeriod;
  const { effectiveGrossRevenues: egr, grossRents } = revenue;
  const total = annualizeMonthlyReturns(expenses.totalOperatingExpenses);
  const y1Egr = annualizeMonthlyReturns(egr)[0];
  const y1TotalOpEx = total[0];
  const y1ExpenseRatio = y1TotalOpEx / y1Egr;

  return (
    <table className="min-w-full divide-y divide-gray-200 bg-white">
      <ModelExpenseHeader holdPeriod={holdPeriod} y1ExpenseRatio={y1ExpenseRatio} />
      <ItemizedExpenseBody
        isModelParameter
        expenses={expenses}
        capital={capital}
        dcfParams={dcfParams}
        egr={egr}
        grossRent={grossRents}
        onChange={onChange}
        setActiveParameterTab={setActiveParameterTab}
        isPortfolioParameter={false}
        totalEquity={totalEquity}
        canEditPortfolio={canEditPortfolio}
      />
    </table>
  );
}