import { dotProductBy, formatCurrency } from 'components/utils';
import MuteZero from './MuteZero';
import { getVisibleDeliveryScheduleColumnIds } from './subdivision';

/**
 * @param {import('@tanstack/react-table').HeaderContext['table']} table
 */
export default function PurchasePriceFooter({ table }) {
  const deliveryScheduleColIds = getVisibleDeliveryScheduleColumnIds({ table });
  const total = dotProductBy(
    table.getRowModel().flatRows,
    ({ getValue }) => getValue('offerBidPrice'),
    ({ getValue }) => (
      deliveryScheduleColIds
        .map((colId) => getValue(colId))
        .reduce((tot, curr) => tot + (curr || 0), 0)
    ),
  );

  return (
    // let this cell overflow to the left (expected to be empty)
    // to prevent this cell from resizing its column (and shifting layout).
    // different from the other footer totals, this cell is expected to be wider than all preceding rows
    <div className="inline-flex justify-end max-w-px">
      <MuteZero value={total} formatter={formatCurrency} />
    </div>
  );
}
