import cx from 'classnames';
import { LAYOUT } from 'components/constants';
import { Outlet } from 'react-router-dom';
import PropertySidebar from './PropertySidebar';

export function PropertyLayoutContainer({ children, className, py = 'py-8', px = 'px-20' }) {
  return (
    <div className={cx('w-full overflow-auto', className, px, py)}>
      {children}
    </div>
  );
}

export default function PropertyLayout({ context }) {
  return (
    <div className="relative w-0 flex-1 z-0 flex flex-row bg-gray-100" style={{ height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)` }}>
      <PropertySidebar context={context} />
      <Outlet context={context} />
    </div>
  );
}
