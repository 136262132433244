/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hoveredId: null,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setHoveredId: (state, { payload }) => {
      state.hoveredId = payload;
    },
  },
});

export const {
  setHoveredId,
} = mapSlice.actions;

export default mapSlice.reducer;
