import { useState } from 'react';
import { chunk, first, indexOf, isFinite, last, mean, range, zip } from 'lodash';
import { addMonths, startOfMonth } from 'date-fns';
import Table from 'rc-table';
import { ToggleWithLabels } from 'components/Toggle';
import { Chevron } from 'components/icons';
import { calcBelowTheLineExpenseItemCashFlow, calcExpenseItemCashFlow } from '../expense';
import { dateFromString, formatDate, formatMultiplier, formatPercentage, sumArrays } from '../../utils';
import renderCashFlowRow from './CashFlows';
import { holdPeriodInMonths, isItemized } from '../dcf';
import { calcDscr } from '../debt';

export function CustomExpandIcon(props) {
  const { record, expanded } = props;
  if (!record.children) { return ''; }
  const direction = expanded ? 'down' : 'right';
  return (
    <Chevron
      direction={direction}
      className="inline-block expand-row-icon w-4 cursor-pointer mr-2 font-semibold"
    />
  );
}

export default function CashFlow({ cashFlows, dcfParams, returnMetrics }) {
  const [annualize, setAnnualize] = useState(!dcfParams.ltOneYrHold);
  const [expandedRows, setExpandedRows] = useState([]);

  const { closingDate } = dcfParams;
  const expenseIsItemized = isItemized(dcfParams);
  const months = range(1, 13 + holdPeriodInMonths(dcfParams));
  const parsedClosingDate = dateFromString(closingDate);
  const dates = months.map(month => startOfMonth(addMonths(parsedClosingDate, month)));
  const annualizedDates = chunk(dates, 12).map(datesChunk => datesChunk[0]);

  const {
    grossPotentialRents,
    lossToLeases,
    rolloverVacancies,
    concessions,
    grossRents,
    reimbursableExpenses,
    otherIncomes,
    grossRevenues,
    staticVacancies,
    collectionLosses,
    effectiveGrossRevenues,
  } = cashFlows.revenue;

  const {
    economicOccupancyRates,
    physicalOccupancyRates,
  } = cashFlows.occupancy;

  const { leveredOperatingCashFlows, leveredCashFlows } = cashFlows;
  const leveredCashOnCash = returnMetrics.leveredCashOnCashRate;
  const { turnoverCosts, taxes, controllableExpenses, nonControllableExpenses, totalOperatingExpenses } = cashFlows.expenses;
  const { belowTheLineExpenses } = cashFlows.capital;
  const expenseRatios = zip(totalOperatingExpenses, effectiveGrossRevenues).map(pair => pair[0] / pair[1]);
  const netOperatingIncome = sumArrays(effectiveGrossRevenues, totalOperatingExpenses.map(v => v * -1));
  const cashFlowCapitalProjects = cashFlows.capital.cashFlowCapitalExpenses;
  const followOnCapitalProjects = cashFlows.capital.followOnCapitalExpenses;
  const freeOperatingCashFlow = cashFlows.unleveredOperatingCashFlows;
  const purchaseExitRowData = sumArrays(
    cashFlows.acquisition.price,
    cashFlows.sale.price,
  );
  // Remove last value of array if hold period < 1 //
  const unleveredCashFlow = cashFlows.unleveredCashFlows;
  const unleveredBeginningBalance = cashFlows.unleveredBasis.beginningBasis;
  const unleveredBasisDraw = cashFlows.unleveredBasis.draw;
  const unleveredEndingBalance = cashFlows.unleveredBasis.endingBasis;
  const unleveredCashOnCash = returnMetrics.unleveredCashOnCashRate;
  const acquisitionLoanPaymentRepaymentsRowData = cashFlows.financing.acquisitionLoanPaymentRepayments;
  const refinancingLoanPaymentRepaymentsRowData = cashFlows.financing.refinancingLoanPaymentRepayments;
  const originationFeeRowData = cashFlows.financing.loanOriginationFees;
  const futureFundingDraws = cashFlows.financing.futureFundingMonthlyDraws;
  // Remove last value of array if hold period < 1 //
  const totalLoanDrawsRepaymentsRowData = sumArrays(
    acquisitionLoanPaymentRepaymentsRowData,
    refinancingLoanPaymentRepaymentsRowData,
    originationFeeRowData,
    [0].concat(futureFundingDraws),
  );

  const acquisitionClosingCostRowData = sumArrays(
    cashFlows.acquisition.cost,
    cashFlows.sale.cost,
  );
  const { interestPayments, principalPayments } = cashFlows.financing;
  const totalDebtPayments = sumArrays(interestPayments, principalPayments);
  const leveredBeginningBalance = cashFlows.leveredBasis.beginningBasis;
  const leveredBasisDraw = cashFlows.leveredBasis.draw;
  const leveredEndingBalance = cashFlows.leveredBasis.endingBasis;

  function expenseChildren(controllable) {
    const childrenArr = [];
    dcfParams.expenseItems.filter(expenseItem => expenseItem.controllable === controllable)
      .forEach(expenseItem => (
        childrenArr.push({ label: expenseItem.name, data: calcExpenseItemCashFlow(expenseItem, dcfParams, effectiveGrossRevenues, grossRents) })
      ));
    return childrenArr;
  }

  function belowTheLineExpensesChildren() {
    const childrenArr = [];
    dcfParams.belowTheLineExpenseItems.forEach(expenseItem => (
      childrenArr.push({ label: expenseItem.name, data: calcBelowTheLineExpenseItemCashFlow(expenseItem, dcfParams, effectiveGrossRevenues, grossRents, cashFlows.totalEquity) })
    ));
    return [...childrenArr, { label: 'Total', data: belowTheLineExpenses, className: 'font-semibold border-b-2 bg-gray-50 border-slate-300' }];
  }

  const columns = [
    {
      title: !dcfParams.ltOneYrHold && <ToggleWithLabels className="self-center justify-center" label1="Annualized" label2="Monthly" checked={!annualize} onClick={() => setAnnualize(!annualize)} />,
      dataIndex: 'label',
      key: 'label',
      align: 'left',
      width: '300px',
      fixed: 'left',
      ellipsis: true,
      className: 'border bg-gray-50 text-sm px-4 cursor-pointer bg-gray-50',
    },
  ];

  const separatorClassName = 'bg-gray-50 border-gray-300 border-t-2 border-b-2 font-semibold';
  const rowData = [
    { label: 'Average Economic Occupancy %', data: economicOccupancyRates, formatter: (v) => formatPercentage(v, 1), annualizeFunction: mean },
    { label: 'Average Physical Occupancy %', data: physicalOccupancyRates, formatter: (v) => formatPercentage(v, 1), annualizeFunction: mean },
    { label: 'Revenue', className: separatorClassName },
    {
      label: 'Gross Potential Rent',
      data: grossPotentialRents,
      className: 'font-medium border-b-2 border-gray-300',
    },
    { label: 'Loss to Lease', data: lossToLeases, key: '3_0' },
    { label: 'Lease-Up / Downtime Vacancy', data: rolloverVacancies, key: '3_1' },
    { label: 'Concessions', data: concessions, key: '3_2', className: 'border-b-2' },
    {
      label: 'Gross Rent',
      data: grossRents,
      className: 'font-medium border-t-2 border-gray-300',
    },
    { label: 'Reimbursable Expenses', data: reimbursableExpenses },
    { label: 'Other Income', data: otherIncomes },
    {
      label: 'Gross Revenue',
      data: grossRevenues,
      className: 'font-medium border-t-2 border-gray-300',
    },
    { label: 'Static Vacancy', data: staticVacancies },
    { label: 'Collection Loss', data: collectionLosses },
    { label: 'Effective Gross Revenue', data: effectiveGrossRevenues, className: 'font-medium' },
    ...(expenseIsItemized ? [
      { label: 'Operating Expenses', className: separatorClassName },
      {
        label: 'Controllable Expenses',
        data: indexOf(expandedRows, 'Controllable Expenses') === -1 ? controllableExpenses : [],
        children: [...expenseChildren(true), { label: 'Turnover Costs', data: turnoverCosts }, { label: 'Total', data: controllableExpenses, className: 'font-semibold border-b-2 bg-gray-50 border-slate-300' }],
      },
      {
        label: 'Non-Controllable Expenses',
        data: indexOf(expandedRows, 'Non-Controllable Expenses') === -1 ? nonControllableExpenses : [],
        children: [...expenseChildren(false), { label: 'Taxes', data: taxes }, { label: 'Total', data: nonControllableExpenses, className: 'font-semibold border-b-2 bg-gray-50 border-slate-300' }],
      },
    ] : []),
    { label: 'Total Operating Expenses', data: totalOperatingExpenses, className: 'font-medium' },
    {
      label: 'Expense Ratio',
      data: expenseRatios,
      formatter: (ratio) => (isFinite(ratio) ? formatPercentage(ratio) : '-'),
      annualizeFunction: mean,
    },
    { label: 'Net Operating Income', data: netOperatingIncome, className: 'font-medium' },
    { label: 'Capital & Below the Line Expenses', className: separatorClassName },
    { label: 'Below the Line Expenses', data: indexOf(expandedRows, 'Below the Line Expenses') === -1 ? belowTheLineExpenses : [], children: belowTheLineExpensesChildren() },
    { label: 'Capital Projects (Cash Flow Funded)', data: cashFlowCapitalProjects },
    { label: 'Free Operating Cash Flow', data: freeOperatingCashFlow, className: 'border-b-2 border-gray-200' },
    { label: 'Purchase / Exit Price', data: purchaseExitRowData, showYear0: true },
    { label: 'Acquisition / Closing Costs', data: acquisitionClosingCostRowData, showYear0: true },
    { label: 'Capital Projects (Follow-on Funded)', data: followOnCapitalProjects, showYear0: true, className: 'border-b-2' },
    { label: 'Unlevered Cash Flow', data: unleveredCashFlow, showYear0: true, className: 'border-b-2 font-medium' },
    { label: 'Unlevered Basis Beginning Balance', data: unleveredBeginningBalance, showYear0: true, annualizeFunction: mean },
    { label: 'Unlevered Basis Draws', data: unleveredBasisDraw, showYear0: true },
    { label: 'Unlevered Basis Ending Balance', data: unleveredEndingBalance, annualizeFunction: mean, showYear0: true },
    { label: 'Unlevered Cash on Cash %', data: unleveredCashOnCash, formatter: (v) => formatPercentage(v, 2), className: 'border-b-2' },
    { label: 'Debt Service', className: separatorClassName },
    {
      label: 'Total Loan Draws / Repayment',
      data: indexOf(expandedRows, 'Total Loan Draws / Repayment') === -1 ? totalLoanDrawsRepaymentsRowData : [],
      showYear0: true,
      children: [
        { label: 'Acq. Loan Funding / Repayment', data: acquisitionLoanPaymentRepaymentsRowData, showYear0: true },
        { label: 'Refi Loan Funding / Repayment', data: refinancingLoanPaymentRepaymentsRowData, showYear0: true },
        { label: 'Origination Fee', data: originationFeeRowData, showYear0: true },
        { label: 'Future Funding', data: futureFundingDraws },
        { label: 'Total', data: totalLoanDrawsRepaymentsRowData, className: 'font-semibold border-b-2 bg-gray-50 border-slate-300' },
      ],
    },
    {
      label: 'Total Loan Payments',
      data: indexOf(expandedRows, 'Total Loan Payments') === -1 ? totalDebtPayments : [],
      children: [
        { label: 'Interest Paid', data: interestPayments },
        { label: 'Principal Paid', data: principalPayments },
        { label: 'Total', data: totalDebtPayments, className: 'font-semibold border-b-2 bg-gray-50 border-slate-300' },
      ],
    },
    { label: 'Levered Operating Cash Flow', data: leveredOperatingCashFlows, className: 'border-b-2' },
    { label: 'Levered Cash Flow', data: leveredCashFlows, showYear0: true, className: 'border-b-2 font-medium' },
    { label: 'Levered Basis Beginning Balance', data: leveredBeginningBalance, annualizeFunction: (monthlyValues) => first(monthlyValues), showYear0: true },
    { label: 'Levered Basis Draws', data: leveredBasisDraw, showYear0: true },
    { label: 'Levered Basis Ending Balance', data: leveredEndingBalance, showYear0: true, annualizeFunction: (monthlyValues) => last(monthlyValues) },
    { label: 'Levered Cash on Cash', data: leveredCashOnCash, formatter: (v) => formatPercentage(v, 2) },
  ];

  // only show DSCR on annual view
  if (annualize) {
    // hack to put DSCRs in a format that work with table structure
    const dscrArr = new Array(holdPeriodInMonths(dcfParams) - 1).fill(0);
    const annualDscrs = calcDscr(cashFlows);
    // eslint-disable-next-line no-return-assign
    annualDscrs.forEach((dscr, index) => dscrArr[index * 12] = dscr);
    rowData.splice(35, 0, {
      label: 'DSCR',
      data: dscrArr,
      annualizeFunction: (monthlyValues) => first(monthlyValues),
      formatter: (v) => formatMultiplier(v, 2),
      className: 'border-b-2',
    });
  }

  const dateCols = (annualize ? annualizedDates : dates).map(date => formatDate(date));
  dateCols.unshift(formatDate(parsedClosingDate)); // add 0 col
  const rows = rowData.map(rd => renderCashFlowRow(rd, annualize, dateCols));

  dateCols.forEach((date, index) => {
    columns.push(
      {
        title: (
          <div className="h-full flex flex-col items-center justify-evenly">
            <span className="text-gray-400 font-normal">{index}</span>
            <span className="text-gray-500 font-medium">{formatDate(date)}</span>
          </div>
        ),
        dataIndex: date,
        key: date,
        width: '150px',
        align: 'right',
        render: (v) => <small>{v}</small>,
        className: `border h-10 px-2 ${index === 0 && 'border border-r-2'}`,
      },
    );
  });

  return (
    <div>
      <Table
        className="w-max"
        columns={columns}
        data={rows}
        indentSize={20}
        rowClassName={(record) => `${record.className} hover:bg-blue-50`}
        onHeaderRow={() => ({ className: 'z-50 h-16 text-sm text-gray-500 font-light bg-gray-50 sticky top-0 shadow-md' })}
        expandable={{ expandRowByClick: true, expandIcon: (props) => CustomExpandIcon(props), defaultExpandAllRows: false, onExpandedRowsChange: (r) => setExpandedRows(r) }}
      />
    </div>
  );
}
