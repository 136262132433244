import { useMemo } from 'react';
import { generatePath, useOutletContext } from 'react-router-dom';
import { useIsInDealContext, useIsPortfolioDeal } from 'components/DealBase/hooks';
import { StretchedRouteLinkCell } from 'components/shared/Table/Cells';

const useHomeModelPath = ({ id }) => {
  const {
    id: dealId,
    path: pathFromOutletContext,
    data: {
      deal,
      property,
      properties = [],
    } = {},
  } = useOutletContext() ?? {};
  const isInDealContext = useIsInDealContext();
  const isPortfolioDeal = useIsPortfolioDeal();

  let linkPath = '/home_models/:id';
  if (isInDealContext && deal) {
    if (!isPortfolioDeal && property.homeModelId === id) {
      // if this home model is the current deal, go to the deal page
      linkPath = `/${pathFromOutletContext}/:dealId`;
    } else if (isPortfolioDeal) {
      // if this home model is covered by the current portfolio deal,
      // go to the home model's portfolio deal property page
      const homeModelPropertyId = properties.find(({ homeModelId }) => homeModelId === id)?.id;
      if (homeModelPropertyId) {
        linkPath = `/${pathFromOutletContext}/:dealId/properties/${homeModelPropertyId}`;
      }
    }
  }

  return generatePath(linkPath, { id, dealId });
};

/**
 * @param {import('@tanstack/react-table').Cell['getValue']} getValue
 * @param {import('@tanstack/react-table').Row} row
 * @param {import('@tanstack/react-table').Column} column
 */
export default function HomeModelLinkCell({ getValue, row, column }) {
  const homeModelPath = useHomeModelPath({ id: row.id });

  /** @type {import('@tanstack/react-table').Column} */
  const columnWithLinkPath = useMemo(() => {
    const updatedMeta = { ...column.columnDef.meta, linkPath: homeModelPath };
    return { ...column, columnDef: { ...column.columnDef, meta: updatedMeta } };
  }, [column, homeModelPath]);

  return (
    <StretchedRouteLinkCell
      getValue={getValue}
      row={row}
      column={columnWithLinkPath}
    />
  );
}
