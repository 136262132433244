import { some } from 'lodash';
import { parseEventValue } from 'components/utils';
import Input from 'components/Input';
import ChipFilter from 'components/shared/filters/ChipFilter';

function ScoreComponentInput({ label, filterKey, filterState, setFilterState }) {
  const onChange = (event) => {
    setFilterState(prevState => ({
      ...prevState,
      [filterKey]: parseFloat(parseEventValue(event)),
    }));
  };

  return (
    <div>
      <div className="mb-3 text-sm text-gray-600">{label}</div>
      <fieldset className="flex flex-col gap-y-1.5">
        <Input
          label="Green"
          onChange={onChange}
          type="radio"
          value={1.5}
          checked={filterState[filterKey] === 1.5}
          name={filterKey}
          width="w-40"
        />
        <Input
          label="Yellow"
          onChange={onChange}
          type="radio"
          value={0.5}
          checked={filterState[filterKey] === 0.5}
          name={filterKey}
          width="w-40"
        />
        <Input
          label="Red"
          onChange={onChange}
          type="radio"
          value={0}
          checked={filterState[filterKey] === 0}
          name={filterKey}
          width="w-40"
        />
      </fieldset>
    </div>
  );
}

export default function CompScoreFilter({ filters, setColumnFilters }) {
  const formattedLabel = 'Score';

  const onApply = (filterState) => setColumnFilters(prevFilters => {
    const compFilterKeys = Object.keys(filterState);
    const newFilters = [...prevFilters];
    newFilters.forEach(filter => {
      if (compFilterKeys.includes(filter.id)) {
        filter.value = filterState[filter.id];
      }
    });
    return newFilters;
  });

  const onReset = () => onApply({
    score: 0,
    propertyScore: 0,
    locationScore: 0,
    recencyScore: 0,
  });

  const filterParams = {
    score: filters.find(({ id }) => id === 'score').value,
    propertyScore: filters.find(({ id }) => id === 'propertyScore').value,
    locationScore: filters.find(({ id }) => id === 'locationScore').value,
    recencyScore: filters.find(({ id }) => id === 'recencyScore').value,
  };
  const filtered = some(Object.values(filterParams), val => val > 0);

  return (
    <ChipFilter label={formattedLabel} filtered={filtered} filterParams={filterParams} onApply={onApply} onReset={onReset}>
      {({ filterState, setFilterState }) => (
        <div className="flex flex-col gap-y-3">
          <ScoreComponentInput
            label="Overall"
            filterKey="score"
            filterState={filterState}
            setFilterState={setFilterState}
          />
          <ScoreComponentInput
            label="Property"
            filterKey="propertyScore"
            filterState={filterState}
            setFilterState={setFilterState}
          />
          <ScoreComponentInput
            label="Location"
            filterKey="locationScore"
            filterState={filterState}
            setFilterState={setFilterState}
          />
          <ScoreComponentInput
            label="Recency"
            filterKey="recencyScore"
            filterState={filterState}
            setFilterState={setFilterState}
          />
        </div>
      )}
    </ChipFilter>
  );
}
