import { useEffect, useMemo } from 'react';
import { useFilteredRows, useSetColumnVisibility } from 'components/shared/Table/DataTableContext';
import { deliveryScheduleColumnId, useHomeModelDeliveryDates } from './subdivision';

const deliveryDatesToVisibilityState = ({ deliveryDates, visible }) => (
  Object.fromEntries(deliveryDates.map((date) => [deliveryScheduleColumnId(date), visible]))
);

export default function HideEmptyDeliveryDates({ deliveryDates }) {
  const rows = useFilteredRows();
  const originalRows = useMemo(() => (
    rows?.map(({ original }) => original) ?? []
  ), [rows]);
  const filteredDeliveryDates = useHomeModelDeliveryDates({ homeModels: originalRows });

  const setColumnVisibility = useSetColumnVisibility();
  const rowsReady = !!rows?.length;
  useEffect(() => {
    if (!rowsReady) {
      return;
    }

    const visibility = {
      ...deliveryDatesToVisibilityState({ deliveryDates, visible: false }),
      ...deliveryDatesToVisibilityState({ deliveryDates: filteredDeliveryDates, visible: true }),
    };
    setColumnVisibility((prev) => ({ ...prev, ...visibility }));
  }, [rowsReady, deliveryDates, filteredDeliveryDates, setColumnVisibility]);

  return null;
}
