export default function BathsCell({ row, getValue }) {
  const cellValue = getValue();
  const halfBaths = row.getValue('bathroomsHalf');

  if (!halfBaths) {
    return cellValue;
  }

  if (halfBaths === 1) {
    return `${cellValue}.5`;
  }

  return (
    <div className="inline-block">
      {cellValue}
      <span className="text-neutral-dark/75 text-[smaller] align-baseline">{`+${halfBaths}`}</span>
    </div>
  );
}
