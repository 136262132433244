import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLeases } from 'reducers/dashboard/lease';
import { useFetchLeasesQuery } from 'redux/dashboardApiSlice';
import { ButtonTab, HorizontalTabs } from 'components/shared/Tabs';
import Overall from './Overall';
import AvailableUnits from './AvailableUnits';
import LongDOM from './LongDom';
import ReadyToList from './ReadyToList';
import Map from './Map';
import Historical from './Historical';

const LEASE_TABS = [
  { label: 'Overall', component: Overall },
  { label: 'Available Units', component: AvailableUnits },
  { label: 'Long DOM', component: LongDOM },
  { label: 'Ready to List - 2 Wks', component: ReadyToList },
  { label: 'Map', component: Map },
  { label: 'Historical', component: Historical },
];

function LeaseDashboard() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const ActiveComponent = LEASE_TABS[activeTabIndex].component;
  const dispatch = useDispatch();
  const { isLoading, data = [] } = useFetchLeasesQuery();

  useEffect(() => {
    if (!isLoading && data.data?.length > 0) {
      dispatch(setLeases(data.data));
    }
  }, [data, isLoading, dispatch]);

  return (
    <div className="flex flex-col mb-16 h-full">
      <HorizontalTabs>
        {LEASE_TABS.map(({ label }, index) => (
          <ButtonTab key={index} active={index === activeTabIndex} onClick={() => setActiveTabIndex(index)}>
            {label}
          </ButtonTab>
        ))}
      </HorizontalTabs>
      <div className="p-3 pb-12 overflow-y-auto">
        <ActiveComponent />
      </div>
    </div>
  );
}

export default LeaseDashboard;
