import { useMemo } from 'react';
import { sum, mean } from 'lodash';
import { formatCurrency, formatCurrencyAbbreviated, formatPercentage } from 'components/utils';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import AcquisitionTable from './AcquisitionTable';

const countNewConstruction = (deals = []) => deals.filter(deal => deal.attributes.newConstruction).length;
const sumPurchasePrice = (deals = []) => sum(deals.map(deal => deal.attributes.purchasePrice));
const averageNetYield = (deals = []) => mean(deals.map(deal => deal.attributes.netYield));
const averagePurchasePrice = (deals = []) => mean(deals.map(deal => deal.attributes.purchasePrice));
const averageUnderwritingRent = (deals = []) => mean(deals.map(deal => deal.attributes.underwritingRent));

export default function NewConstruction({ asOfDate, filteredAcquisitions }) {
  const filteredData = useMemo(() => filteredAcquisitions.filter(acquisition => {
    const { yearBuilt } = acquisition.attributes.property;
    return yearBuilt && (yearBuilt >= (asOfDate.getFullYear() - 1));
  }), [filteredAcquisitions]);

  const metrics = [
    { label: '# in Contract', value: countNewConstruction(filteredData), hideLookup: true },
    { label: '$ in Contract', value: formatCurrencyAbbreviated(sumPurchasePrice(filteredData)), hideLookup: true },
    { label: 'Avg. Yield', value: formatPercentage(averageNetYield(filteredData)), hideLookup: true },
    { label: 'Avg. Purchase Price', value: formatCurrencyAbbreviated(averagePurchasePrice(filteredData)), hideLookup: true },
    { label: 'Avg. Rent', value: formatCurrency(averageUnderwritingRent(filteredData)), hideLookup: true },
  ];

  return (
    <div>
      <ReturnMetricBoxes metrics={metrics} />
      <div className="mt-10">
        <AcquisitionTable filteredData={filteredData} />
      </div>
    </div>
  );
}
