import { range, uniq } from 'lodash';
import { holdPeriodInYears } from './dcf';
import { ModelItemizedOtherIncomeTable } from './ItemizedOtherIncomeTable';
import { InlineFormField } from '../Form';
import Input, { PERCENT_TYPE } from '../Input';
import { TH, TR } from '../Table';
import { arrayFieldOnChangeListener, parseEventValue } from '../utils';

export default function RevenueParameters({ annualRentGrowthEntry, dcfParams, cashFlows, onChange, setAnnualRentGrowthEntry }) {
  const {
    collectionLossRate,
    rentGrowthRate,
    rentGrowthRates,
    vacancyRate,
    rentControlledGrowthRate,
  } = dcfParams;

  const { revenue: { grossRents } } = cashFlows;

  const onChangeRentGrowth = (event) => {
    if (annualRentGrowthEntry) {
      const updatedRates = Array.from(rentGrowthRates);
      updatedRates[0] = parseEventValue(event);
      onChange({
        target: {
          name: 'rentGrowthRates',
          value: updatedRates,
        },
      });
    } else {
      onChange(event);
    }
  };

  const onToggleAnnualRates = () => {
    if (annualRentGrowthEntry && (uniq(rentGrowthRates).length > 1)) {
      if (!window.confirm('You will lose the annual rates specified. Are you sure you would like to continue?')) {
        return;
      }

      onChange({
        target: {
          name: 'rentGrowthRate',
          value: rentGrowthRates[0],
        },
      });
    }
    setAnnualRentGrowthEntry(!annualRentGrowthEntry);
  };

  const rentGrowthRowData = [
    <Input
      name="rentGrowthRate"
      value={annualRentGrowthEntry ? rentGrowthRates[0] : rentGrowthRate}
      type={PERCENT_TYPE}
      width="w-full"
      onChange={onChangeRentGrowth}
    />,
  ];
  range(1, holdPeriodInYears(dcfParams) + 1).forEach(year => {
    rentGrowthRowData.push(
      <Input
        name="rentGrowthRate"
        type="percent"
        disabled={!annualRentGrowthEntry}
        value={rentGrowthRates[year]}
        width="w-full"
        onChange={arrayFieldOnChangeListener(onChange, rentGrowthRates, year)}
      />,
    );
  });

  return (
    <div className="flex flex-col overflow-x-auto p-3 bg-gray-50">
      <div className="w-1/3">
        <InlineFormField
          className="mt-3"
          name="rentControlledGrowthRate"
          label="Rent Control Max Incr"
          value={rentControlledGrowthRate}
          type={PERCENT_TYPE}
          min="0"
          onChange={onChange}
        />
        <InlineFormField
          className="mt-3"
          name="vacancyRate"
          value={vacancyRate}
          type={PERCENT_TYPE}
          min="0"
          max="100"
          onChange={onChange}
        />
        <InlineFormField
          className="mt-3"
          name="collectionLossRate"
          value={collectionLossRate}
          type={PERCENT_TYPE}
          min="0"
          max="100"
          onChange={onChange}
        />
        <InlineFormField
          className="mt-3"
          type="checkbox"
          label="Specify Annual Rent Growth Rates"
          value={annualRentGrowthEntry || ''}
          checked={annualRentGrowthEntry}
          onChange={onToggleAnnualRates}
        />
      </div>
      <table className="w-max divide-y divide-gray-200 bg-white">
        <thead className="bg-gray-50 border-b-2">
          <tr>
            <TH value="Rent Growth (Year 1)" width="w-24" />
            {range(2, holdPeriodInYears(dcfParams) + 2).map(year => <TH key={year} value={`Year ${year}`} width="w-24" />)}
          </tr>
        </thead>
        <tbody>
          <TR data={rentGrowthRowData} />
        </tbody>
      </table>
      <ModelItemizedOtherIncomeTable
        annualRentGrowthEntry={annualRentGrowthEntry}
        dcfParams={dcfParams}
        grossRent={grossRents}
        onChange={onChange}
        setAnnualRentGrowthEntry={setAnnualRentGrowthEntry}
      />
    </div>
  );
}
