import { CHANNEL_OFF_MARKET_MARKETPLACE } from 'components/constants';
import { camelCaseKeys, coalesceListing, snakeCaseKeys, standardizeListing } from 'components/utils';
import { OFF_MARKET_LISTINGS_TAG } from './utils';
import { apiSlice } from './apiSlice';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchOffMarketMarketplaceListings: builder.query({
        query({ market }) {
          return {
            url: '/api/listings',
            params: { channel: CHANNEL_OFF_MARKET_MARKETPLACE, market },
          };
        },
        transformResponse: (response) => (
          camelCaseKeys(response).map(({ parcel, ...listing }) => (
            coalesceListing({ listing: standardizeListing(listing), parcel })
          ))
        ),
        providesTags: [OFF_MARKET_LISTINGS_TAG],
      }),
      updateOffMarketListings: builder.mutation({
        query: ({ listings }) => ({
          url: '/api/listings/off_market',
          method: 'POST',
          body: snakeCaseKeys({ listings }),
        }),
        invalidatesTags: [OFF_MARKET_LISTINGS_TAG],
      }),
    };
  },
});

export const {
  useFetchOffMarketMarketplaceListingsQuery,
  useUpdateOffMarketListingsMutation,
} = extendedApiSlice;
