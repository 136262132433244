import { useEffect } from 'react';
import { cloneDeep, last } from 'lodash';
import { ToggleWithLabels } from 'components/Toggle';
import { getAssessorSiteUrl } from 'utils/assessor';
import AnnualTaxes from './AnnualTaxes';
import SimpleTaxes from './SimpleTaxes';
import { assessedAsPercent } from '../tax';

export default function Taxes({
  cashFlows,
  dcfParams,
  onChange,
  parcels,
  setActiveParameterTab,
  taxes,
}) {
  const {
    closingDate,
    detailedTaxInput,
    futureTaxParameters,
    holdPeriod,
    purchasePrice,
  } = dcfParams;
  // TODO: better handle multi-parcel deals
  const parcel = parcels[0];
  const assessorSiteUrl = parcel && getAssessorSiteUrl(parcel);

  const handleToggle = () => {
    onChange({
      target: {
        name: 'detailedTaxInput',
        value: !detailedTaxInput,
      },
    });
  };

  // IF: User has the “Align Assessed Value to Purchase / Exit” toggle set to “off” (i.e. user wants to use the market_value_total from the 'real_estate_taxes' table for most recent fiscal year for Year 1 taxes)
  // AND: The “Assessor Total Market Value” in the ‘real_estate_taxes’ table (field name: 'market_value_total') = 0 or ‘null’
  // THEN: Use the Purchase Price (i.e. 100% of the Purchase Price) as the Year 1 “Total Market Value” (market_value_total), and grow that by the “tax growth rate” on a go forward basis for Years 2+
  useEffect(() => {
    const { assessedValue, taxExpenseGrowthRate } = dcfParams;
    const updatedTaxItems = cloneDeep(futureTaxParameters);
    if ((assessedValue === 0) && !assessedAsPercent(dcfParams)) {
      updatedTaxItems.forEach((_taxParameters, index) => {
        updatedTaxItems[index].marketValueTotal = index === 0 ? purchasePrice : (
          updatedTaxItems[index - 1].marketValueTotal + ((updatedTaxItems[index - 1].marketValueTotal * taxExpenseGrowthRate) / 100)
        );
        updatedTaxItems[index].billedRate = updatedTaxItems[index].billed / updatedTaxItems[index].marketValueTotal;
      });
      onChange({
        target: {
          name: 'futureTaxParameters',
          value: updatedTaxItems,
        },
      });
    }
  }, []);

  return (
    <div className="relative flex flex-col p-4 items-start max-w-min bg-white">
      <div className="flex justify-end">
        <ToggleWithLabels
          className="ml-0 mb-4"
          label1="Grow Year 1 Value"
          label2="Input Values Annually"
          checked={detailedTaxInput}
          onClick={handleToggle}
        />
      </div>
      {!detailedTaxInput ? (
        <SimpleTaxes
          assessorSiteUrl={assessorSiteUrl}
          cashFlows={cashFlows}
          dcfParams={dcfParams}
          onChange={onChange}
          setActiveParameterTab={setActiveParameterTab}
        />
      ) : (
        <AnnualTaxes
          assessorSiteUrl={assessorSiteUrl}
          cashFlows={cashFlows}
          closingDate={closingDate}
          dcfParams={dcfParams}
          exitPrice={last(cashFlows.sale.price)}
          futureTaxParameters={futureTaxParameters}
          holdPeriod={holdPeriod}
          onChange={onChange}
          purchasePrice={purchasePrice}
          taxes={taxes}
          setActiveParameterTab={setActiveParameterTab}
        />
      )}
    </div>
  );
}
