import { useState } from 'react';
import {
  useCreateEmailTemplateMutation,
  useFetchEmailTemplatesQuery,
  useFetchTaskPlaceholdersQuery,
  useSendTaskEmailMutation,
  useUpdateEmailTemplateMutation,
} from 'redux/emailTemplateApiSplice';
import useFilteredEmailTemplates from 'components/Features/EmailTemplate/useFilteredEmailTemplates';

function useEmailTemplateManager(task) {
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isSendingEmail, setIsSendingEmail] = useState(false); // New state for sending email

  // Fetch email templates
  const {
    data: emailTemplates = [],
    isLoading: isLoadingEmailTemplates,
    error: fetchEmailTemplatesError,
  } = useFetchEmailTemplatesQuery();

  // Filter templates by category 'task'
  const filteredEmailTemplates = useFilteredEmailTemplates(emailTemplates, 'task');

  // Fetch placeholders
  const { data: placeholders = [], isLoading: isLoadingTaskPlaceholders } = useFetchTaskPlaceholdersQuery(task, { skip: !task });

  // Mutation hooks
  const [createEmailTemplate, { isLoading: isCreating }] = useCreateEmailTemplateMutation();
  const [updateEmailTemplate, { isLoading: isUpdating }] = useUpdateEmailTemplateMutation();
  const [sendTaskEmail, { isLoading: isSendEmail }] = useSendTaskEmailMutation();

  const handleComposeNew = () => {
    setIsSendingEmail(false);
    setSelectedTemplate({ title: task.name, content: '' });
    setAlert({ type: '', message: '' });
  };

  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template);
    setAlert({ type: '', message: '' });
  };

  const handleSaveTemplate = async (emailTemplate) => {
    setAlert({ type: '', message: '' });
    try {
      if (emailTemplate.id) {
        // Update existing template
        await updateEmailTemplate(emailTemplate).unwrap();
        setAlert({ type: 'success', message: 'Email template updated successfully!' });
      } else {
        // Create new template
        const createdEmailTemplate = await createEmailTemplate({ ...emailTemplate, category: 'task' }).unwrap();
        setSelectedTemplate(createdEmailTemplate);
        setAlert({ type: 'success', message: 'Email template created successfully!' });
      }
    } catch (err) {
      const message = err?.data?.error
        || err?.data?.message
        || 'Failed to save email template. Please try again.';
      setAlert({ type: 'danger', message });
      console.error('Failed to save email template:', err);
    }
  };

  const handleSendEmail = async (emailData) => {
    setIsSendingEmail(true);
    try {
      const taskEmail = { ...emailData, emailTemplateId: selectedTemplate.id };
      await sendTaskEmail({ task, taskEmail }).unwrap();
      setIsSendingEmail(false);
    } catch (err) {
      const message = err?.data?.error
        || err?.data?.message
        || 'Failed to send email. Please try again.';
      setAlert({ type: 'danger', message });
      setIsSendingEmail(false);
      console.error('Failed to send email:', err);
      throw err; // Re-throw to handle in the form
    }
  };

  return {
    emailTemplates: filteredEmailTemplates,
    isLoading: isLoadingEmailTemplates || isLoadingTaskPlaceholders,
    fetchError: fetchEmailTemplatesError,
    selectedTemplate,
    alert,
    isSaving: isCreating || isUpdating,
    placeholders,
    handleComposeNew,
    handleSelectTemplate,
    handleSaveTemplate,
    handleSendEmail,
    isSendingEmail,
    setAlert,
  };
}

export default useEmailTemplateManager;
