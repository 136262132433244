/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import { compact, isNil, isEmpty, omitBy } from 'lodash';

import { calcDaysOnMarket } from 'components/utils';

const keysToCheck = [
  'bathrooms',
  'bedrooms',
  'daysOnMarket',
  'grossStabilizedYield',
  'leveredCashOnCash',
  'leveredIrr',
  'listPrice',
  'numberOfUnits',
  'pricePerSquareFoot',
  'propertyType',
  'rentableBuildingArea',
  'stabilizedYield',
  'unleveredCashOnCash',
  'unleveredIrr',
  'y1Yield',
  'yearBuilt',
  'zipCodes',
];

const matchesZipCodesFilter = (property, filters) => {
  const propertyZip = property.zipCode;
  return propertyZip && filters.zipCodes.includes(propertyZip);
};

const matchesPropertyTypeFilter = (propertyTypeFilter, listingPropertyType) => (propertyTypeFilter && listingPropertyType ? propertyTypeFilter === listingPropertyType : true);

const matchesBedBathFilter = (type, filters, item) => {
  const { selections, useExact } = filters[type];

  if (!selections?.length && !useExact) return true; // "ANY"

  if (useExact) {
    if (selections?.includes(5)) {
      return item[type] >= 5;
    } else {
      return compact(selections).includes(item[type]);
    }
  } else {
    return ((!isNil(selections[0]) ? item[type] >= selections[0] : true)
        && (!isNil(selections[1]) ? item[type] <= selections[1] : true));
  }
};

const matchesBedroomsFilter = (filters, property) => matchesBedBathFilter('bedrooms', filters, property);

const matchesBathroomsFilter = (filters, property) => matchesBedBathFilter('bathrooms', filters, property);

const matchesKeyRangeFilter = (key, filters, metrics) => {
  const filterRange = filters[key];
  const metricValue = metrics[key];

  if (metricValue !== null && typeof metricValue !== 'undefined') {
    const [min, max] = filterRange;
    if ((min !== null && metricValue <= min) || (max !== null && metricValue >= max)) {
      return false;
    }
  }
  return true;
};

const matchesFiltersForKeys = (keys, filters, metrics, property) => {
  for (const key of keys) {
    switch (key) {
      case 'zipCodes':
        if (!isEmpty(filters.zipCodes) && !matchesZipCodesFilter(property, filters)) {
          return false;
        }
        break;
      case 'propertyType':
        const buyBoxPropertyType = filters[key];
        const listingPropertyType = metrics[key];
        if (!matchesPropertyTypeFilter(buyBoxPropertyType, listingPropertyType)) {
          return false;
        }
        break;
      case 'bedrooms':
        if (!matchesBedroomsFilter(filters, property)) {
          return false;
        }
        break;
      case 'bathrooms':
        if (!matchesBathroomsFilter(filters, property)) {
          return false;
        }
        break;
      default:
        if (!matchesKeyRangeFilter(key, filters, metrics)) {
          return false;
        }
    }
  }
  return true;
};

export const matchesBuyBox = (property, scenario, portfolio) => {
  const returnMetrics = { ...scenario.returnMetrics };
  const defaultFilters = { ...portfolio.parameters.defaultFilters };

  // Matches Buy Box keys with Return Metrics keys
  defaultFilters.leveredCashOnCash = defaultFilters.leveredCashOnCashRate;
  defaultFilters.unleveredCashOnCash = defaultFilters.unleveredCashOnCashRate;
  defaultFilters.zipCodes = !isEmpty(compact(defaultFilters.zipCodes)) ? compact(defaultFilters.zipCodes) : null;
  defaultFilters.bathrooms = defaultFilters.bathroomsTotalInteger;
  defaultFilters.bedrooms = defaultFilters.bedroomsTotal;

  returnMetrics.listPrice = scenario.parameters?.listPrice;
  returnMetrics.daysOnMarket = calcDaysOnMarket(scenario.parameters.listing?.listedOn);
  returnMetrics.yearBuilt = property?.yearBuilt;
  returnMetrics.rentableBuildingArea = property?.rentableBuildingArea;
  returnMetrics.numberOfUnits = property?.numberOfUnits;
  returnMetrics.propertyType = scenario.parameters.listing?.propertyType;
  returnMetrics.pricePerSquareFoot = scenario.parameters?.listPrice / property?.rentableBuildingArea;

  const applicableFilters = omitBy(defaultFilters, isNil);

  return matchesFiltersForKeys(Object.keys(applicableFilters), applicableFilters, returnMetrics, property);
};

export const childScenarios = ({ scenarios, primaryOnly = false, parentScenarioId = undefined }) => (
  scenarios?.filter(({ scenarioId, primary }) => (
    (!primaryOnly || primary)
    && (isNil(parentScenarioId) || scenarioId === parentScenarioId)
    && !isNil(scenarioId)
  )) ?? []
);
