import { useState } from 'react';
import ListingEntry from './ListingEntry';
import PropertySearch from './PropertySearch';
import PropertyVerification from './PropertyVerification';
import { FormContainer } from '../Form';
import { Chevron } from '../icons';

const BreadcrumbItem = ({ label, onClick, state }) => {
  const handleOnClick = (e) => {
    if (state === 'completed') {
      onClick(e);
    }
  };
  const className = {
    current: 'text-black text-sm font-medium cursor-default',
    completed: 'text-tertiary text-sm cursor-pointer',
    pending: 'text-gray-500 text-sm cursor-default',
  }[state];
  return <p className={className} onClick={handleOnClick}>{label}</p>;
};

export default function NewListingForm() {
  const [property, setProperty] = useState(null);
  const [propertyVerified, setPropertyVerified] = useState(false);

  const onPropertySearch = () => {
    setProperty(null);
    setPropertyVerified(false);
  };

  const onPropertyVerficiation = () => {
    setPropertyVerified(false);
  };

  let contents = null;
  if (propertyVerified) {
    contents = <ListingEntry property={property} />
  } else if (property) {
    contents = <PropertyVerification property={property} setProperty={setProperty} setPropertyVerified={setPropertyVerified} />;
  } else {
    contents = <PropertySearch setProperty={setProperty} />;
  }

  return (
    <FormContainer>
      <div className="flex flex-row mb-6">
        <BreadcrumbItem label="Search Properties" onClick={onPropertySearch} state={property ? 'completed' : 'current'} />
        <Chevron direction="right" className="w-4 mx-2" />
        <BreadcrumbItem label="Verify Property Information" onClick={onPropertyVerficiation} state={propertyVerified ? 'completed' : (property ? 'current' : 'pending')} />
        <Chevron direction="right" className="w-4 mx-2" />
        <BreadcrumbItem label="Enter Listing" state={propertyVerified ? 'current' : 'pending'} />
      </div>
      {contents}
    </FormContainer>
  );
}
