/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useMemo, useState } from 'react';
import { filter, isEmpty, isNaN, isNil, merge, omit, parseInt, pickBy, sortBy, uniq, capitalize } from 'lodash';
import { isValid } from 'date-fns';
import {
  useFetchPortfoliosQuery,
  useFetchSelfQuery,
  useFetchUsersQuery,
} from 'redux/apiSlice';
import { useFetchPipelineTasksQuery } from 'redux/pipelineApiSlice';
import EmptyLoadingState from 'components/shared/EmptyLoadingState';
import UpcomingTaskTable from 'components/task/UpcomingTaskTable';
import { Search } from 'components/icons';
import {
  DEAL_STATE_CLOSED,
  DEAL_STATE_DEAD,
  INACTIVE_DEAL_STATES,
  TRANSACTION_TYPES,
} from 'components/constants';
import { titleCase } from 'components/utils';
import RadioInput from 'components/shared/RadioInput';

// TODO: sort by milestone order
// currently this sorts milestones alphabetically
// and puts closed and dead at the end
// to sort by milestone order, it will need to consider the dealWorkflowTemplate
// for every deal in filteredDeals
const dealStageComparator = (leftStage, rightStage) => {
  if (leftStage === rightStage) {
    return 0;
  }

  if (leftStage === DEAL_STATE_DEAD) {
    return -1;
  }

  if (rightStage === DEAL_STATE_CLOSED || rightStage === DEAL_STATE_DEAD) {
    return 1;
  }

  if (leftStage === DEAL_STATE_CLOSED) {
    return rightStage === DEAL_STATE_DEAD ? 1 : -1;
  }

  return leftStage < rightStage ? 1 : -1;
};

export default function TaskList() {
  const [filters, setFilters] = useState({});
  const [currentUserPrefiltered, setCurrentUserPrefiltered] = useState(false);
  const [transactionType, setTransactionType] = useState(TRANSACTION_TYPES.acquisition);

  const { data: tasks = [], isFetching: isTasksFetching } = useFetchPipelineTasksQuery(transactionType);

  const { data: users = [] } = useFetchUsersQuery();
  const usersById = users.reduce((agg, user) => ({ ...agg, [user.id]: user }), {});

  const { data: portfolios = [] } = useFetchPortfoliosQuery();

  const { data: currentUser = {} } = useFetchSelfQuery();

  const uniqueStages = useMemo(() => sortBy(uniq(tasks.map(task => task.stage)).map(stage => ({ label: titleCase(stage), value: titleCase(stage) })), dealStageComparator), [tasks]);
  const uniqueUsers = useMemo(() => sortBy(uniq(tasks.filter(task => !isNil(task.userId)).map(task => task.userId)), userId => usersById[userId]?.email).map(userId => ({ label: usersById[userId]?.fullName || usersById[userId]?.email, value: userId.toString() }), 'label'), [tasks, usersById]);
  const uniquePortfolios = useMemo(() => sortBy(uniq(portfolios.map(portfolio => ({ label: portfolio.name, value: portfolio.id }))), 'label'), [portfolios]);

  useEffect(() => {
    const userExists = uniqueUsers.find(lead => lead.value === currentUser.id?.toString());
    if (userExists && !isEmpty(currentUser) && !currentUserPrefiltered) {
      setFilters({ ...filters, userIds: [currentUser.id.toString()] });
      setCurrentUserPrefiltered(true);
    }
  }, [currentUser, currentUserPrefiltered, filters, uniqueUsers]);

  const filteredTasks = useMemo(() => {
    const dueDateFilteredTasks = filters.dueDate?.length > 0 ? tasks.filter(task => (isValid(new Date(filters.dueDate[0])) ? new Date(task.dueDate) >= new Date(filters.dueDate[0]) : true) && (isValid(new Date(filters.dueDate[1])) ? new Date(task.dueDate) <= new Date(filters.dueDate[1]) : true)) : tasks;
    const daysLeftFilteredTasks = filters.daysLeft?.length > 0 ? dueDateFilteredTasks.filter(task => (!isNaN(parseInt(filters.daysLeft[0])) ? task.daysLeft >= parseInt(filters.daysLeft[0]) : true) && (!isNaN(parseInt(filters.daysLeft[1])) ? task.daysLeft <= parseInt(filters.daysLeft[1]) : true)) : dueDateFilteredTasks;
    const stageFilteredTasks = filters.stageIds?.length > 0 ? daysLeftFilteredTasks.filter(task => filters.stageIds.includes(task.stage)) : daysLeftFilteredTasks;
    const portfolioFilteredTasks = filters.portfolioIds?.length > 0 ? stageFilteredTasks.filter(task => filters.portfolioIds.includes(task.portfolioId)) : stageFilteredTasks;
    const userFilteredTasks = filters.userIds?.length > 0 ? portfolioFilteredTasks.filter(task => filters.userIds.includes(task.userId?.toString()) || (filters.userIds.includes(0) && isNil(task.userId))) : portfolioFilteredTasks;
    const marketFilteredTasks = filters.marketIds?.length > 0 ? userFilteredTasks.filter(task => filters.marketIds.includes(task.market)) : userFilteredTasks;
    const nameFilteredTasks = filters.name ? marketFilteredTasks.filter(task => task.name.toLowerCase().includes(filters.name.toLowerCase()) || task.dealName.toLowerCase().includes(filters.name.toLowerCase())) : marketFilteredTasks;
    const activeSelectFilters = pickBy(omit(filters, ['name', 'daysLeft', 'dueDate', 'portfolioIds', 'marketIds', 'userIds', 'stageIds']), value => !!value);
    return filter(nameFilteredTasks, activeSelectFilters);
  }, [filters, tasks]);

  if (isEmpty(portfolios) || isEmpty(users)) {
    return (
      <div className="w-full h-screen">
        <EmptyLoadingState />
      </div>
    );
  }

  return (
    <div className="h-screen w-full px-6 pt-8 bg-gray-100">
      <div className="h-14">
        <div className="flex justify-between">
          <div className="text-2xl text-neutral-dark font-normal mt-5 mb-1">
            Tasks
          </div>
          <div className="flex gap-x-4">
            <div className="w-1/3 my-auto">
              <div className="flex gap-x-2">
                {Object.keys(TRANSACTION_TYPES).map(type => (
                  <div className="flex items-center" key={type}>
                    <RadioInput
                      checked={transactionType === TRANSACTION_TYPES[type]}
                      id={transactionType}
                      onChange={() => { setTransactionType(type); }}
                    />
                    <label onClick={() => { setTransactionType(type); }} className="ml-1 text-sm text-gray-900 cursor-pointer">{capitalize(TRANSACTION_TYPES[type])}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="relative flex w-96">
              <span className="z-10 font-normal text-center text-slate-300 absolute rounded text-base items-center justify-center w-8 pl-3 py-3.5">
                <Search className="text-black w-6 h-6" />
              </span>
              <input
                value={filters.name}
                onChange={(e) => setFilters(merge({}, filters, { name: e.target.value }))}
                type="text"
                placeholder="Search for Deal or Task"
                className="px-3 py-3.5 relative rounded text-sm outline-none focus:outline-none border border-slate-300 shadow-sm w-full pl-10"
              />
            </div>
          </div>
        </div>
      </div>
      {isTasksFetching ? <EmptyLoadingState text={`Loading ${capitalize(transactionType)} Tasks.`} /> : (
        !INACTIVE_DEAL_STATES.includes(filters.stage) && (
        <UpcomingTaskTable
          filters={filters}
          setFilters={setFilters}
          tasks={filteredTasks}
          loading={isTasksFetching}
          usersById={usersById}
          dealStageComparator={dealStageComparator}
          uniquePortfolios={uniquePortfolios}
          uniqueUsers={uniqueUsers}
          uniqueStages={uniqueStages}
          searchTerm={filters.name}
        />
        ))}
    </div>
  );
}
