import { useLocation, useOutletContext, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setShowCreateDealModal, showRenameScenarioModal } from 'actions/deal_navigation';
import Menu from 'components/shared/Menu';
import { ContentCopy, Pencil, Plus, StarFilled, StarOutlined, TrashOutline, Lock } from 'components/icons';
import { DEFAULT_SCENARIO_NAME, MODELLED_INDIVIDUALLY } from 'components/constants';
import { useCreateScenarioMutation, useUpdateScenarioMutation, useDeleteScenarioMutation } from 'redux/apiSlice';

export default function ModelScenarioSelector({ modelParams }) {
  const { propertyId } = useOutletContext();

  const dispatch = useDispatch();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const { scenario, scenarios, deal: dealData } = modelParams;
  const { data: deal } = dealData;
  const [createScenarioMutation] = useCreateScenarioMutation();
  const [updateScenarioMutation] = useUpdateScenarioMutation();
  const [deleteScenarioMutation] = useDeleteScenarioMutation();
  // TODO: handle loading & error states

  const relevantScenarios = deal?.attributes?.modellingMethod === MODELLED_INDIVIDUALLY
    ? scenarios.filter(s => s.propertyId === parseInt(propertyId, 10))
    : scenarios;

  const duplicateScenario = async () => {
    const response = await createScenarioMutation({
      id: null,
      dealId: deal.id,
      duplicateScenarioId: scenario?.id,
      name: `${scenario?.name || DEFAULT_SCENARIO_NAME} (Copy)`,
      parameters: scenario ? null : modelParams.dcfParams,
      propertyId,
    }).unwrap();
    // TODO: Figure out better solution than switching window.location.
    //       With current logic, using a front-end routing using react-router
    //       results in data loading bugs
    window.location = `${location.pathname}?scenarioId=${response.id}`;
  };

  const menuItemGroups = [];
  if (deal?.id) {
    const scenarioItems = [{
      text: 'Change scenario...',
      className: 'text-xs text-gray-500',
      labelOnly: true,
    }, ...relevantScenarios.map(s => ({
      text: s.name,
      onClick: () => { setSearchParams({ scenarioId: s.id }); },
      className: s.id === scenario?.id ? 'bg-primary-focus' : '',
      iconLeft: s.primary ? <StarFilled /> : <div />, // empty div to get consistent left padding
      iconRight: s.purchaseAgreementGeneratedAt && <Lock />,
    })), {
      text: DEFAULT_SCENARIO_NAME,
      onClick: () => { setSearchParams({ scenarioId: 'defaults' }); },
      className: scenario?.id ? '' : 'bg-primary-focus',
      iconLeft: <div />, // empty div to get consistent left padding
    }];
    menuItemGroups.push(scenarioItems);

    const togglePrimary = () => {
      if (scenario) {
        updateScenarioMutation({ id: scenario.id, dealId: scenario.dealId, primary: true });
      }
    };

    const scenarioActions = [{
      text: 'Scenario Actions',
      className: 'text-xs text-gray-500',
      labelOnly: true,
    }, {
      text: 'Rename Scenario',
      onClick: () => { dispatch(showRenameScenarioModal(true, scenario)); },
      iconLeft: <Pencil />,
      disabled: !scenario,
    }, {
      text: 'Duplicate Scenario',
      onClick: duplicateScenario,
      iconLeft: <ContentCopy />,
    }, {
      text: 'Make Primary Scenario',
      iconLeft: scenario?.primary ? <StarFilled className="fill-gray-200 opacity-20" /> : <StarOutlined />,
      disabled: scenario?.primary || !scenario,
      onClick: togglePrimary,
    }];

    menuItemGroups.push(scenarioActions);

    const deleteScenario = async () => {
      if (scenario) {
        const response = await deleteScenarioMutation({ id: scenario.id });
        if (response.error) {
          console.error('error deleting scenario', response.error);
        } else {
          setSearchParams({ scenarioId: relevantScenarios.filter(s => s.id !== scenario.id)[0]?.id });
        }
      }
    };

    menuItemGroups.push([{
      text: 'Delete Scenario',
      onClick: () => deleteScenario(),
      iconLeft: <TrashOutline className={scenario?.primary ? 'fill-gray-200 opacity-20' : ''} />,
      disabled: scenario?.primary || !scenario,
    }]);
  } else {
    menuItemGroups.push([{
      text: 'Create a deal to edit scenarios',
      className: 'text-xs text-gray-500',
      labelOnly: true,
    }]);

    menuItemGroups.push([{
      text: 'Create Deal',
      onClick: () => dispatch(setShowCreateDealModal(true)),
      iconLeft: <Plus className="w-4" />,
    }]);
  }

  return (
    <Menu
      className="w-80"
      label={scenario?.name || DEFAULT_SCENARIO_NAME}
      itemGroups={menuItemGroups}
      icon={scenario?.primary ? <StarFilled className="mr-2 w-6 opacity-40" /> : null}
      alignLeft
    />
  );
}
