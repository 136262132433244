import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useFetchDealContextQuery, useFetchModelQuery } from 'redux/apiSlice';
import { MODELLED_COMBINED } from 'components/constants';
import { LoadingIndicator } from 'components/icons';
import Toast from 'components/shared/Toast';
import { camelCaseKeys, coalesceListing, coalesceProperty, standardizeListing } from 'components/utils';
import { dealContextSymbol } from 'components/DealBase/hooks';

export default function DealBase() {
  const { id, propertyId } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const path = location.pathname.match(/^\/([a-z_]*).*$/)[1];
  const { currentData: data } = useFetchDealContextQuery({ id, type: path });
  const { scenarioId } = camelCaseKeys(Object.fromEntries(searchParams));
  const { activeToast } = useSelector(state => state.toast);
  const { currentData: modelData } = useFetchModelQuery({ id, type: path, scenarioId, propertyId });

  // TODO: consider better UI For loading state and passing down more granular loading info
  if (!data || !modelData) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <LoadingIndicator className="size-10 text-primary-500" />
      </div>
    );
  }

  let property = null;

  const { listing, properties, parcels, deal, homeModels } = data;

  // redirect to deal if deal exists and not on deal page
  if (deal && !location.pathname.includes('deals')) {
    const urlParts = location.pathname.split('/');
    urlParts.splice(1, 2, 'deals', deal.id);
    return <Navigate to={urlParts.join('/')} replace />;
  }
  // redirect to listing if on property page but an active listing exists
  if (listing && location.pathname.includes('properties') && !location.pathname.includes('deals')) {
    const urlParts = location.pathname.split('/');
    urlParts.splice(1, 2, 'listings', listing.id);
    return <Navigate to={urlParts.join('/')} replace />;
  }

  const standardizedListing = standardizeListing(listing);
  property = (properties.length === 1) ? properties[0] : properties.find(p => p.id.toString() === propertyId);

  // property can be null here if it is a portfolio listing and user is not on a specific property page
  if (property) {
    const parcel = parcels.length === 1 ? parcels[0] : parcels.find(p => p.apn === property.apnMin);

    const modelUnits = modelData.model.dcfParams.units;
    // NOTE: for combined modeled deals, we're selecting units by whether the address matches
    //       the property record, which is a fragile approach if we ever end up updating
    //       how we handle property addresses
    const applicableUnits = (propertyId && deal && (deal.modellingMethod === MODELLED_COMBINED))
      ? modelUnits.filter(unit => unit.address === property.address) : modelUnits;

    property = coalesceProperty(property, parcel, standardizedListing, applicableUnits);
  }

  const parcel = parcels.find(p => p.apn === property?.apnMin) || parcels[0];
  const homeModel = homeModels?.find(({ id: hmId }) => hmId === property?.homeModelId);

  const contextListing = listing?.offMarketMarketplace && property ? (
    coalesceListing({ listing: standardizedListing, parcel })
  ) : (
    standardizedListing
  );

  const context = {
    id,
    path,
    propertyId,
    scenarioId,
    data: { ...data, listing: contextListing, parcel, property, homeModel },
    modelData,
    [dealContextSymbol]: true,
  };

  return (
    <div>
      <Outlet context={context} />
      {!isEmpty(activeToast) && <Toast {...activeToast} /> }
    </div>
  );
}
