import ReactDOM from 'react-dom';
import { LAYOUT } from 'components/constants';
import { groupById } from 'components/utils';
import { cloneDeep } from 'lodash';
import Button from 'components/shared/NewButton';
import { Plus, X } from 'components/icons';
import { setShowCreateDealModal } from 'actions/deal_navigation';
import { useDispatch } from 'react-redux';
import ActivityFeed from '../dashboard/ActivityFeed';

const ActivityDrawer = ({ context, setShowActivities }) => {
  const dispatch = useDispatch();
  let { deal } = context.data;
  deal = cloneDeep(deal);
  const usersById = groupById(context.data.users);
  const dealEmails = deal ? deal.dealEmails.data.map(de => de.attributes) : [];

  return ReactDOM.createPortal(
    <div
      className="absolute flex-col w-96 bg-white overflow-x-hidden cursor-default float-right border-l border-gray-200 shadow-lg z-30"
      style={{
        height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)`,
        right: LAYOUT.rightNavWidth,
        top: LAYOUT.dealHeaderHeight,
      }}
    >
      {deal ? (
        <ActivityFeed
          deal={deal}
          dealEmails={dealEmails}
          setShowActivities={setShowActivities}
          tasks={deal?.tasks}
          usersById={usersById}
        />
      ) : (
        <>
          <div className="w-full flex justify-between items-center mb-6 border-b p-6">
            <div className="flex flex-col">
              <span className="text-xl">Activity</span>
            </div>
            <div className="flex items-center gap-x-4">
              <div className="cursor-pointer" onClick={() => setShowActivities(false)} name="closeNotesDrawerButton"><X className="w-6" /></div>
            </div>
          </div>
          <div className="flex justify-center mt-12">
            <div className="text-center">
              <div className="text-xl">No Activities</div>
              <div className="text-xs text-gray-500 my-4">You must create a deal to track deal activities</div>
              <div className="flex justify-center">
                <Button filled label="Create Deal" leadingIcon={<Plus className="w-4" />} onClick={() => dispatch(setShowCreateDealModal(true))} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>,
    document.body,
  );
};

export default ActivityDrawer;
