import { chunk, sortBy } from 'lodash';
import { HISTORY_RECORD_TYPES } from 'components/constants';
import { X } from 'components/icons';
import Modal from 'components/Modal';
import { titleCase } from 'components/utils';
import RoundButton from 'components/common/RoundButton';
import ListingModal from 'components/property/components/ListingModal';
import CompPhotoModal from 'components/rentComps/CompPhotoModal';
import RecordGrid, { RecordGridCell, RecordGridColumn } from 'components/property/components/RecordGrid';

const RECODER_MODAL_COLUMN_COUNT = 2;

function RecorderModal({ item, onClose }) {
  const { details, recorderDocumentType } = item.attributes;
  const detailList = sortBy(Object.entries(details), pair => pair[0]);
  const columnCount = Math.ceil(detailList.length / RECODER_MODAL_COLUMN_COUNT);
  const detailListChunks = chunk(detailList, columnCount);

  return (
    <Modal
      fullScreen
      show
      backgroundColor="bg-transparent"
      onClose={onClose}
      showCloseAction={false}
    >
      <div className="flex w-full h-full p-6 items-center justify-center" onClick={onClose}>
        <div
          className="flex flex-col w-8/12 h-min max-h-full bg-white rounded-xl divide-y divide-neutral-dark divide-opacity-12"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-row w-full items-center px-6 py-5">
            <div className="flex-grow font-medium text-lg text-black">
              {titleCase(recorderDocumentType)}
            </div>
            <RoundButton padding="p-2 border border-neutral-dark border-opacity-12" onClick={onClose}>
              <X className="w-7" />
            </RoundButton>
          </div>
          <div className="p-6 overflow-auto overscroll-contain">
            <RecordGrid columns={RECODER_MODAL_COLUMN_COUNT}>
              {detailListChunks.map((detailListChunk, index) => (
                <RecordGridColumn key={index}>
                  {detailListChunk.map(detailPair => (
                    <RecordGridCell key={detailPair[0]} label={titleCase(detailPair[0])} value={detailPair[1]} />
                  ))}
                </RecordGridColumn>
              ))}
            </RecordGrid>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function ValuationModal({ item, onClose }) {
  const { details } = item.attributes;
  const detailList = sortBy(Object.entries(details), pair => pair[0]);
  const columnCount = Math.ceil(detailList.length / RECODER_MODAL_COLUMN_COUNT);
  const detailListChunks = chunk(detailList, columnCount);

  return (
    <Modal
      fullScreen
      show
      backgroundColor="bg-transparent"
      onClose={onClose}
      showCloseAction={false}
    >
      <div className="flex w-full h-full p-6 items-center justify-center" onClick={onClose}>
        <div
          className="flex flex-col w-8/12 h-min max-h-full bg-white rounded-xl divide-y divide-neutral-dark divide-opacity-12"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-row w-full items-center px-6 py-5">
            <div className="flex-grow font-medium text-lg text-black">Valuation</div>
            <RoundButton padding="p-2 border border-neutral-dark border-opacity-12" onClick={onClose}>
              <X className="w-7" />
            </RoundButton>
          </div>
          <div className="p-6 overflow-auto overscroll-contain">
            <RecordGrid columns={RECODER_MODAL_COLUMN_COUNT}>
              {detailListChunks.map((detailListChunk, index) => (
                <RecordGridColumn key={index}>
                  {detailListChunk.map(detailPair => (
                    <RecordGridCell key={detailPair[0]} label={titleCase(detailPair[0])} value={detailPair[1]} />
                  ))}
                </RecordGridColumn>
              ))}
            </RecordGrid>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default function HistoryModal({ item, onClose }) {
  const itemType = item.attributes.type;

  if (itemType === HISTORY_RECORD_TYPES.RENT_LISTING) {
    return <CompPhotoModal comp={item.attributes.details} dismiss={onClose} />;
  } else if (itemType === HISTORY_RECORD_TYPES.LISTING) {
    return <ListingModal listingId={item.id} onClose={onClose} />;
  } else if (itemType === HISTORY_RECORD_TYPES.RECORDER) {
    return <RecorderModal item={item} onClose={onClose} />;
  } else if (itemType === HISTORY_RECORD_TYPES.VALUATION) {
    return <ValuationModal item={item} onClose={onClose} />;
  } else {
    throw new Error(`Unsupport history record type: ${itemType}`);
  }
}
