import { isNil } from 'lodash';
import { Arrow } from 'components/icons';
import { formatCurrency, formatDate } from 'components/utils';

function DateTimeSpan({ date, shortDate = false }) {
  const displayFormat = shortDate ? 'MM/dd/yy' : 'PPPp';
  return (
    <span title={formatDate(date, 'PPPp')}>{formatDate(date, displayFormat) || '-'}</span>
  );
}

export default function ListingUpdatedAt({
  item: {
    listingStatusChangeTimestamp,
    previousListPrice,
    listPrice,
    priceChangeTimestamp,
    updatedAt,
  },
  portfolio,
}) {
  const isPriceChanged = !isNil(previousListPrice) && previousListPrice !== listPrice;
  if (isPriceChanged) {
    const direction = listPrice < previousListPrice ? 'down' : 'up';

    return (
      <div className="flex">
        Price Change: (
        <Arrow className="h-3.5 w-4" direction={direction} />
        {' '}
        {formatCurrency(previousListPrice)}
        )
        <span className="ml-0.5">
          {'on: '}
          <DateTimeSpan date={priceChangeTimestamp} shortDate={!portfolio} />
        </span>
      </div>
    );
  }

  if (listingStatusChangeTimestamp) {
    return (
      <span>
        {'Status Changed: '}
        <DateTimeSpan date={listingStatusChangeTimestamp} shortDate={!portfolio} />
      </span>
    );
  }

  return (
    <span>
      {'Updated: '}
      <DateTimeSpan date={updatedAt} shortDate={!portfolio} />
    </span>
  );
}
