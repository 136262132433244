import { isNil } from 'lodash';
import { formatHourAgo } from 'components/utils';

export const formatMention = (text) => {
  // Updated regex to match the new format "@[Name](email)"
  const mentionRegex = /@\[([^\]]+)\]\(([^\)]+)\)/g;
  const parts = [];
  let match;

  // While there is a match in the string
  while ((match = mentionRegex.exec(text)) !== null) {
    // Push the preceding text if any
    if (match.index > 0) parts.push(text.substring(0, match.index));
    // Push the match part - email as a mailto link
    parts.push({ name: match[1], email: match[2] });
    // Remove the processed part from the text
    text = text.substring(match.index + match[0].length);
  }
  // Push any remaining text
  if (text) parts.push(text);

  return parts.map((part, index) => {
    if (typeof part === 'object') {
      return (
        <span key={index} className="text-blue-600 cursor-pointer" onClick={() => window.location = `mailto:${part.email}`}>
          @
          {part.name}
        </span>
      );
    }
    return part; // Regular text
  });
};

export default function MentionedInDealNotification({ notification }) {
  const { dealId, dealStage, taskOwnershipUpdatedAt, taskWhodunnitName, seenAt, dealNote, property: { photoUrl, address } } = notification.attributes;

  return (
    <div className={`mb-3 p-4 cursor-pointer border border-gray-300 rounded-lg ${isNil(seenAt) ? 'bg-neutral-surface-light' : 'hover:bg-indigo-50/75'}`} onClick={() => window.location = `/deals/${dealId}`}>
      <div className="flex gap-x-4">
        <img className="h-14 w-14 rounded-lg object-cover" src={photoUrl} alt="" />
        <div className="flex flex-col">
          <div className="text-neutral-dark font-medium text-xl">
            Mentioned In Note
          </div>
          <div className="mt-2 flex h-4 items-center">
            <span className="text-neutral-medium text-xs">
              {formatMention(dealNote)}
            </span>
          </div>
          <div className="mt-3 h-6 flex gap-x-2 items-center ">
            <div className="text-neutral-dark font-medium text-base ">{address}</div>
            <div className="px-3 py-px text-neutral-medium font-medium text-xs rounded-lg border border-gray-300">{dealStage}</div>
          </div>
          <div className="mt-3 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-vxs ">{`Mentioned by ${taskWhodunnitName} • ${formatHourAgo(taskOwnershipUpdatedAt)}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
