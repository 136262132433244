import classNames from 'classnames';
import { ExclamationCircle } from 'components/icons';

import { FormField, InfoField } from 'components/Form';
import CapExParameters from 'components/parameters/CapExParameters';
import DebtParameters from 'components/parameters/DebtParameters';
import RevenueParameters from 'components/parameters/RevenueParameters';
import ExpensesParameters from 'components/parameters/ExpensesParameters';
import waterfallParameters from 'components/parameters/WaterfallParameters';
import BuyBox from 'components/portfolio/Menu/BuyBox';
import EntryExitParameters from 'components/portfolio/Menu/EntryExitParameters';
import GeneralParameters from 'components/portfolio/Menu/GeneralParameters';
import RentCompFilters from 'components/portfolio/Menu/RentCompFilters';
import TaxParameters from 'components/portfolio/Menu/TaxParameters';
import UnitTurnParameters from 'components/portfolio/Menu/UnitTurnParameters';

export const MENU_ITEMS = [
  { label: 'General', value: 'general', component: GeneralParameters },
  { label: 'Buy-Box', value: 'buy_box', component: BuyBox },
  { label: 'Entry & Exit', value: 'entry_exit', component: EntryExitParameters },
  { label: 'Revenue', value: 'revenue', component: RevenueParameters },
  { label: 'Rent Roll', value: 'unit_turn', component: UnitTurnParameters },
  { label: 'Expenses', value: 'expenses', component: ExpensesParameters },
  { label: 'Taxes', value: 'taxes', component: TaxParameters },
  { label: 'CapEx', value: 'capEx', component: CapExParameters },
  { label: 'Financing', value: 'financing', component: DebtParameters },
  { label: 'Waterfall', value: 'waterfall', component: waterfallParameters(FormField, InfoField, 'mt-6') },
  { label: 'Rent Comp Filters', value: 'rent_comp_filters', component: RentCompFilters },
];

function MenuItem({ item, currentTab, onClick, hasError }) {
  const { label, value } = item;
  return (
    <div
      className={classNames('flex px-5 py-3 my-2 text-sm cursor-pointer rounded-full', { 'font-medium bg-primary-light': currentTab === value })}
      onClick={onClick}
    >
      {hasError && <ExclamationCircle className="w-5 h-5 mr-3.5" fillColor="#FF0000" />}
      {label}
    </div>
  );
}

export default function Menu({ currentTab, setCurrentTab, invalidTabs }) {
  return (
    <div className="px-6 py-2">
      {MENU_ITEMS.map((m) => (
        <MenuItem
          item={m}
          key={m.value}
          currentTab={currentTab}
          hasError={invalidTabs.includes(m.value)}
          onClick={() => setCurrentTab(m.value)}
        />
      ))}
    </div>
  );
}
