import { isValidElement } from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';

export function TH({ colSpan, value, width, paddingRight, paddingRightLg }) {
  const className = classNames(
    'relative pl-4 py-3 text-right text-xs font-medium',
    width,
    { 'pr-6': paddingRight },
    { 'pr-44': paddingRightLg },
  );
  return <th className={className} colSpan={colSpan || '1'}>{value}</th>;
}

export function TR({ data, formatter }) {
  return (
    <tr className="font-bold">
      {data.map((value, index) => (
        <TD
          key={index}
          className={classNames( index > 3 ? 'text-right pr-20' : 'text-right')}
          contents={(isValidElement(value) || isNil(value) || !formatter) ? value : formatter(value)}
        />
      ))}
    </tr>
  );
}

export function TD({ className, padding, contents }) {
  const clazz = classNames(
    'text-sm',
    padding || (isValidElement(contents) ? 'py-1' : 'py-2'),
    className,
  );

  return (
    <td className={clazz}>{contents}</td>
  );
}

