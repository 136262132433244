import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { DEAL_NOTIFICATIONS, NOTIFICATION_TABS, TASK_NOTIFICATIONS } from './notification.constant';

dayjs.extend(isSameOrAfter);

/* eslint-disable import/prefer-default-export */
export const groupedNotifications = (activeTab, notifications = []) => {
  const today = dayjs().startOf('day');
  const sevenDaysAgo = dayjs().subtract(7, 'day');

  // Filter notifications based on the active tab
  let filteredNotifications = notifications; // All Tab
  if (activeTab === NOTIFICATION_TABS[1]) { // Deal Tab
    filteredNotifications = notifications.filter(notification => DEAL_NOTIFICATIONS.includes(notification.attributes.notificationType));
  } else if (activeTab === 'Tasks') { // Task Tab
    filteredNotifications = notifications.filter(notification => TASK_NOTIFICATIONS.includes(notification.attributes.notificationType));
  }

  const todaysNotifications = filteredNotifications.filter(notification => dayjs(notification.attributes.createdAt).isSameOrAfter(today));
  const pastSevenDaysNotifications = filteredNotifications.filter(notification => dayjs(notification.attributes.createdAt).isAfter(sevenDaysAgo) && dayjs(notification.attributes.createdAt).isBefore(today));
  const earlierNotifications = filteredNotifications.filter(notification => dayjs(notification.attributes.createdAt).isBefore(sevenDaysAgo));

  return {
    todays: todaysNotifications,
    pastSevenDays: pastSevenDaysNotifications,
    earlier: earlierNotifications,
    empty: isEmpty(todaysNotifications) && isEmpty(pastSevenDaysNotifications) && isEmpty(earlierNotifications),
  };
};
