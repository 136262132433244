import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { capitalize, every, isEmpty, isNil, partial } from 'lodash';
import { useCreateDealMutation } from 'redux/apiSlice';
import { clearNewDealProperties, setAddressSearchModalIsOpen, updateNewDealProperty } from 'redux/newDealSlice';
import { PARCEL_UNMATCHED_VALUE } from 'components/DealSourcing/AddressSearchModal';
import Alert from 'components/Alert';
import Button from 'components/shared/NewButton';
import { formatInteger, parseEventValue, titleCase } from 'components/utils';
import { Check, ExclamationCircle } from 'components/icons';
import { FormField } from 'components/Form';
import { useUserOptions } from 'components/DealConstruction/dealConstruction';
import { MODELLED_COMBINED, MODELLED_INDIVIDUALLY, TRANSACTION_TYPES } from 'components/constants';
import RadioInput from 'components/shared/RadioInput';
import useWorkflowTemplateOptions from 'components/workflow/useWorkflowTemplateOptions';

function NewDealPropertyRow({ isSelected, newDealProperty, setSelectedPropertyId }) {
  const { oneLineAddress, verified } = newDealProperty;

  const rowClassName = cx('flex justify-between p-3 hover:bg-blue-100 rounded cursor-pointer', { 'bg-blue-50': isSelected });

  return (
    <div className={rowClassName} onClick={() => setSelectedPropertyId(newDealProperty.id)}>
      <div>{oneLineAddress}</div>
      {verified ? <Check className="w-6 text-success-500" /> : <ExclamationCircle className="w-6" />}
    </div>
  );
}

const REQUIRED_FIELDS = [
  'address',
  'city',
  'state',
  'zipCode',
  'bedrooms',
  'bathrooms',
  'latitude',
  'rsf',
  'longitude',
];
const EMPTY_PROPERTY = {
  address: null,
  bathrooms: null,
  bedrooms: null,
  inPlaceRent: null,
  latitude: null,
  rsf: null,
  longitude: null,
  marketRent: null,
};

const isPortfolioDeal = (newDealProperties) => newDealProperties?.length > 1;

function ParcelList(props) {
  const {
    alert,
    canContinue,
    createDeal,
    isCreating,
    newDealProperties,
    selectedPropertyId,
    setSelectedPropertyId,
    setVerificationComplete,
    verificationComplete,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBack = () => {
    if (verificationComplete) {
      setVerificationComplete(false);
    } else {
      navigate(-1);
      dispatch(setAddressSearchModalIsOpen(true));
    }
  };

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="grow space-y-2">
        {newDealProperties.map(newDealProperty => (
          <NewDealPropertyRow
            key={newDealProperty.id}
            isSelected={newDealProperty.id === selectedPropertyId}
            newDealProperty={newDealProperty}
            setSelectedPropertyId={setSelectedPropertyId}
          />
        ))}
      </div>
      <div>
        {alert && <Alert className="mb-6 py-3" {...alert} />}
        <div className="flex justify-around">
          <Button textOnly label="Back" onClick={handleBack} />
          {verificationComplete ? (
            <Button filled isLoading={isCreating} disabled={!canContinue} label="Create Deal" onClick={createDeal} />
          ) : (
            <Button filled disabled={!canContinue} label="Continue" onClick={() => setVerificationComplete(true)} />
          )}
        </div>
      </div>
    </div>
  );
}

function ParcelDetail({ parcel }) {
  const {
    address,
    bathCount,
    bedCount,
    buildingSqFt,
    city,
    propertyUseStandardizedCode,
    state,
    zip,
  } = parcel;

  return (
    <div className="space-y-1">
      <div>{`${address}, ${city}, ${state} ${zip}`}</div>
      <div>{propertyUseStandardizedCode}</div>
      <div>{`${bedCount} bd • ${bathCount} ba • ${formatInteger(buildingSqFt)} sqft`}</div>
    </div>
  );
}

const validatePropertyInfo = (property, setAlert, onVerify) => {
  const missingFields = REQUIRED_FIELDS.filter(field => isNil(property[field]) || (property[field] === ''));
  if (missingFields.length) {
    const formattedMissingFields = missingFields.map(titleCase).sort();
    setAlert({ type: 'warning', text: `${formattedMissingFields.join(', ')} fields are required` });
    return;
  }
  const { rsf } = property;
  if (!(rsf > 0)) {
    setAlert({ type: 'warning', text: 'RSF field must be a positive number' });
    return;
  }

  // if info passes validation, call onVerify callback
  onVerify();
};

function PropertyDetail({ newDealProperty, newDealProperties, setSelectedPropertyId }) {
  const [alert, setAlert] = useState(null);
  const dispatch = useDispatch();
  const { id, oneLineAddress, parcel, verified } = newDealProperty;
  const [property, setProperty] = useState({ ...EMPTY_PROPERTY });
  const {
    address,
    bathrooms,
    bedrooms,
    city,
    inPlaceRent,
    marketRent,
    latitude,
    longitude,
    rsf,
    state,
    zipCode,
  } = property;

  useEffect(() => {
    if (newDealProperty) {
      setProperty({ ...EMPTY_PROPERTY, ...newDealProperty.property });
    }
  }, [newDealProperty]);

  const onPropertyInfoChange = (e) => {
    const field = e.target.name;
    const newVal = parseEventValue(e);
    setProperty(prevProperty => ({
      ...prevProperty,
      [field]: newVal,
    }));
  };

  const onUpdate = () => {
    dispatch(updateNewDealProperty({ id, property, verified: true }));
    const currentIndex = newDealProperties.findIndex(p => p.id === newDealProperty.id);
    if (currentIndex < (newDealProperties.length - 1)) {
      setSelectedPropertyId(newDealProperties[currentIndex + 1].id);
    }
  };

  const requiresAddress = !parcel || (parcel === PARCEL_UNMATCHED_VALUE);

  return (
    <>
      <h3 className="text-lg">Property Details</h3>
      <div className="w-128 overflow-y-auto" style={{ height: 'calc(100vh - 300px)' }}>
        <div className="font-medium text-sm mt-8 mb-2 text-gray-700">Search Address</div>
        <div>{oneLineAddress}</div>
        <div className="font-medium text-sm mt-8 mb-2 text-gray-700">Assessor</div>
        {(parcel && parcel !== PARCEL_UNMATCHED_VALUE) ? (
          <ParcelDetail parcel={parcel} />
        ) : (
          <div className="text-gray-500">Unable to match to parcel record</div>
        )}
        <div className="font-medium text-sm mt-8 mb-2 text-gray-700">Property</div>
        {requiresAddress && (
          <>
            <FormField className="w-full mt-6" name="address" value={address} type="text" onChange={onPropertyInfoChange} />
            <div className="mt-6 flex justify-between space-x-2">
              <FormField className="grow" name="city" value={city} type="text" onChange={onPropertyInfoChange} />
              <FormField className="grow" name="state" value={state} type="text" onChange={onPropertyInfoChange} />
              <FormField className="grow" name="zipCode" value={zipCode} type="text" onChange={onPropertyInfoChange} />
            </div>
            <div className="mt-6 flex justify-between space-x-2">
              <FormField className="grow" name="latitude" value={latitude} type="number" onChange={onPropertyInfoChange} min="0" />
              <FormField className="grow" name="longitude" value={longitude} type="number" onChange={onPropertyInfoChange} max="0" />
            </div>
          </>
        )}
        <div className="mt-6 flex justify-between space-x-2">
          <FormField className="grow" name="bedrooms" value={bedrooms} type="number" onChange={onPropertyInfoChange} min="0" />
          <FormField className="grow" name="bathrooms" value={bathrooms} type="number" onChange={onPropertyInfoChange} min="0" />
          <FormField className="grow" name="rsf" value={rsf} type="number" onChange={onPropertyInfoChange} min="0" />
        </div>
        <div className="mt-6 flex justify-between space-x-2">
          <FormField className="grow" name="inPlaceRent" value={inPlaceRent} type="number" onChange={onPropertyInfoChange} min="0" />
          <FormField className="grow" name="marketRent" value={marketRent} type="number" onChange={onPropertyInfoChange} max="0" />
        </div>
        {alert && <Alert className="w-full my-3 py-3" {...alert} />}
        <div className="mt-12 w-full mx-auto">
          <Button
            filled={verified}
            outlined={!verified}
            label={verified ? 'Update' : 'Verify'}
            onClick={() => validatePropertyInfo(property, setAlert, onUpdate)}
          />
        </div>
      </div>
    </>
  );
}

function DealInfo({ dealInfo, newDealProperties, setDealInfo }) {
  const dispatch = useDispatch();
  const { name, price, leadId, modellingMethod, transactionType } = dealInfo;
  const [workflowTemplateId, setWorkflowTemplateId, workflowOptions] = useWorkflowTemplateOptions({ transactionType });
  useEffect(() => {
    if (dealInfo.workflowTemplateId !== workflowTemplateId) {
      setDealInfo((prev) => ({ ...prev, workflowTemplateId }));
    }
  }, [dealInfo.workflowTemplateId, setDealInfo, workflowTemplateId]);

  const userOptions = [
    ['', 'Select Lead', false],
    ...useUserOptions(),
  ];
  const modelingOptions = [
    ['', 'Select Modelling', false],
    [MODELLED_INDIVIDUALLY, 'Individual', false],
    [MODELLED_COMBINED, 'Combined', false],
  ];

  useEffect(() => {
    if (!isPortfolioDeal(newDealProperties)) {
      setDealInfo({ ...dealInfo, name: newDealProperties[0].property.address });
    }
  }, [newDealProperties]);

  const onChange = (e) => {
    const fieldName = e.target.name;
    const newVal = parseEventValue(e);
    setDealInfo(prevDealInfo => ({
      ...prevDealInfo,
      [fieldName]: newVal,
    }));
  };

  const handleTransactionTypeChange = (tType) => {
    setDealInfo(prevDealInfo => ({
      ...prevDealInfo,
      transactionType: tType,
    }));
  };

  const onWorkflowTemplateChange = (evt) => {
    setWorkflowTemplateId(parseInt(evt.target.value, 10));
  };

  const onPropertyPriceChange = (e) => {
    const propertyId = e.target.name;
    const newPrice = parseEventValue(e);
    const newDealProperty = newDealProperties.find(ndp => ndp.id === propertyId);
    dispatch(updateNewDealProperty({ id: propertyId, property: { ...newDealProperty.property, price: newPrice } }));
  };

  let priceInput = null;
  if ((newDealProperties.length === 1) || (modellingMethod === MODELLED_COMBINED)) {
    priceInput = (
      <FormField
        className="grow mt-4"
        name="price"
        onChange={onChange}
        type="number"
        value={price}
        required={dealInfo.transactionType === TRANSACTION_TYPES.acquisition}
      />
    );
  } else if (modellingMethod === MODELLED_INDIVIDUALLY) {
    priceInput = (
      <>
        {newDealProperties.map(ndp => (
          <Fragment key={ndp.id}>
            <FormField
              min="0"
              label={`Price - ${ndp.oneLineAddress}`}
              className="grow mt-4"
              name={ndp.id}
              onChange={onPropertyPriceChange}
              type="number"
              value={ndp.property.price}
              required={dealInfo.transactionType === TRANSACTION_TYPES.acquisition}
            />
          </Fragment>
        ))}
      </>
    );
  }

  return (
    <>
      <h3 className="text-lg">Deal Information</h3>
      <div className="w-128 overflow-y-auto" style={{ height: 'calc(100vh - 300px)' }}>
        <FormField
          className="grow mt-4"
          name="name"
          onChange={onChange}
          type="text"
          value={name}
          required
        />
        {priceInput}
        <FormField
          className="grow mt-4"
          name="leadId"
          onChange={onChange}
          options={userOptions}
          type="select"
          value={leadId}
          defaultValue=""
          required
        />
        {isPortfolioDeal(newDealProperties) && (
          <FormField
            className="grow mt-4"
            name="modellingMethod"
            onChange={onChange}
            options={modelingOptions}
            type="select"
            value={modellingMethod}
            required
          />
        )}
        <div className="font-medium text-sm mt-8 mb-2 text-gray-700">Transaction Type</div>
        <div className="flex items-center gap-x-8">
          {Object.keys(TRANSACTION_TYPES).map(tType => (
            <label className="flex items-center text-sm text-gray-900 cursor-pointer">
              <RadioInput
                id={tType}
                checked={transactionType === TRANSACTION_TYPES[tType]}
                className="mr-3"
                onChange={partial(handleTransactionTypeChange, tType)}
              />
              {capitalize(TRANSACTION_TYPES[tType])}
            </label>
          ))}
        </div>
        <FormField
          className="grow mt-4"
          name="transactionWorkflow"
          onChange={onWorkflowTemplateChange}
          options={workflowOptions || []}
          type="select"
          value={workflowTemplateId}
          defaultValue=""
          disabled={!transactionType}
          required
        />
      </div>
    </>
  );
}

export default function CreateDeal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { newDealProperties } = useSelector(state => state.newDeal);
  const [verificationComplete, setVerificationComplete] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState(newDealProperties[0]?.id);
  const [dealInfo, setDealInfo] = useState({
    name: '',
    price: null,
    leadId: null,
    modellingMethod: null,
    workflowTemplateId: null,
    transactionType: null,
  });
  const [alert, setAlert] = useState(null);
  const selectedProperty = newDealProperties.find(p => p.id === selectedPropertyId);

  const [createDealMutation, { isLoading: isCreating, isSuccess }] = useCreateDealMutation();
  const createDeal = async () => {
    const response = await createDealMutation({
      ...dealInfo,
      properties: newDealProperties.map(ndp => ndp.property),
    });

    if (response.error) {
      // TODO: handle error
      console.error(response.error);
      setAlert({ type: 'danger', text: 'Failed to create deal' });
    } else {
      dispatch(clearNewDealProperties());
      navigate(`/deals/${response.data.id}`);
    }
  };

  useEffect(() => {
    // this is to handle if user refreshes the page on the create_deal page
    if (isEmpty(newDealProperties) && !isSuccess) {
      navigate(-1);
    }
  }, [newDealProperties, isSuccess]);

  const dealInfoFormComplete = useMemo(() => {
    if (isPortfolioDeal(newDealProperties)) {
      // TODO: Make Price Optional for Portfolio Deal When the transaction type 'disposition'
      if (!dealInfo.modellingMethod) {
        return false;
      } else if (dealInfo.modellingMethod === MODELLED_COMBINED) {
        return dealInfo.name && dealInfo.leadId && dealInfo.price && dealInfo.workflowTemplateId;
      } else if (dealInfo.modellingMethod === MODELLED_INDIVIDUALLY) {
        return dealInfo.name && dealInfo.leadId && dealInfo.workflowTemplateId && every(newDealProperties, ndp => ndp.property.price);
      }
    } else {
      const dealPriceRequired = dealInfo.transactionType === TRANSACTION_TYPES.disposition ? true : dealInfo.price;
      return dealInfo.name && dealInfo.leadId && dealInfo.workflowTemplateId && dealPriceRequired;
    }
  }, [dealInfo, newDealProperties]);

  if (isEmpty(newDealProperties)) {
    return null;
  }

  const canContinue = verificationComplete ? dealInfoFormComplete : every(newDealProperties, 'verified');

  return (
    <div className="h-screen p-16 bg-gray-100">
      <div className="w-full flex flex-col p-6 rounded bg-white">
        <h1 className="text-2xl">{verificationComplete ? 'Create Deal' : 'Verify Property Information'}</h1>
        <div className="grow flex pt-12">
          <div className="w-128 pr-6">
            <ParcelList
              alert={alert}
              canContinue={canContinue}
              createDeal={createDeal}
              isCreating={isCreating}
              newDealProperties={newDealProperties}
              selectedPropertyId={selectedPropertyId}
              setSelectedPropertyId={setSelectedPropertyId}
              setVerificationComplete={setVerificationComplete}
              verificationComplete={verificationComplete}
            />
          </div>
          <div className="h-full pl-6 border-l overflow-x-auto">
            {verificationComplete ? (
              <DealInfo
                createDeal={createDeal}
                dealInfo={dealInfo}
                newDealProperties={newDealProperties}
                setVerificationComplete={setVerificationComplete}
                setDealInfo={setDealInfo}
              />
            ) : (
              <PropertyDetail
                newDealProperty={selectedProperty}
                newDealProperties={newDealProperties}
                setSelectedPropertyId={setSelectedPropertyId}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
