import { Link } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { formatCurrency, formatDate, formatInteger, formatPercentage } from 'components/utils';
import Badge from 'components/shared/Badge';
import { ABBR_MONTHS, DEAL_STATE_CLOSED, DEAL_STATE_DEAD } from 'components/constants';
import classNames from 'classnames';
import { HOME_MODEL_ID_PREFIX } from '../dealSourcing';

export const NEW_BUILD_ROW_HEIGHT = 224;
const PIPELINE_STATUS_BADGE_COLOR = {
  [DEAL_STATE_CLOSED]: 'bg-green-100',
  [DEAL_STATE_DEAD]: 'bg-gray-300',
};

const badgeColor = (dealStatus) => PIPELINE_STATUS_BADGE_COLOR[dealStatus] || 'bg-white';

export default function NewBuildRow({ item, currentDate, style, onMouseEnter, onMouseLeave, availabilityFilter }) {
  const {
    id,
    asOfDate,
    bathroomsTotalInteger,
    bedroomsTotal,
    dealId,
    dealStatus,
    futureDeliveries,
    homeBuilder,
    homeType,
    imageUrl,
    marketRent,
    numAvailable,
    plan,
    priceMax,
    priceMin,
    sqft,
    subdivision,
    grossStabilizedYield,
    leveredIrr,
    stabilizedYield,
    unleveredIrr,
  } = item;

  const {
    id: subdivisionId,
    name: subdivisionName,
    city,
    state,
    zip,
  } = subdivision;

  const {
    name: builderName,
  } = homeBuilder;

  let priceString = null;
  let psfString = null;
  if (priceMin) {
    if (priceMin === priceMax) {
      priceString = formatCurrency(priceMin);
      psfString = `${formatCurrency(priceMin / sqft)} PSF`;
    } else {
      priceString = `${formatCurrency(priceMin)} - ${formatCurrency(priceMax)}`;
      psfString = `${formatCurrency(priceMin / sqft)} - ${formatCurrency(priceMax / sqft)} PSF`
    }
  }

  const [availabilityStartFilter, availabilityEndEnd] = availabilityFilter;

  let availabilityString;
  let applicableDeliveries = futureDeliveries;
  if (availabilityStartFilter) {
    applicableDeliveries = applicableDeliveries.filter(delivery => delivery[0] >= availabilityStartFilter);
  }
  if (availabilityEndEnd) {
    applicableDeliveries = applicableDeliveries.filter(delivery => delivery[0] <= availabilityEndEnd);
  }

  // take into account current availability filter to show relevant availability info
  if ((numAvailable > 0) && (!availabilityStartFilter || (availabilityStartFilter <= formatDate(currentDate)))) {
    availabilityString = `${numAvailable} Available Now`;
  } else if (applicableDeliveries.length) {
    const nextAvailable = applicableDeliveries[0];
    const availabilityDate = parseISO(nextAvailable[0]);
    availabilityString = `${nextAvailable[1]} Available ${ABBR_MONTHS[availabilityDate.getMonth()]}`;
  } else {
    availabilityString = 'None Available';
  }

  return (
    <div
      className="item"
      data-id={`${HOME_MODEL_ID_PREFIX}${subdivisionId}`}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link to={dealId ? `/deals/${dealId}` : `/home_models/${id}`}>
        <div className="flex gap-x-4 w-full h-56 px-4 py-3 hover:bg-honeycomb-50 cursor-pointer">
          <div className="relative flex-none w-72 h-full">
            <img
              className="w-full h-full object-cover rounded-lg"
              src={imageUrl}
              loading="lazy"
              alt="builder"
              referrerPolicy="no-referrer"
            />
            {dealStatus && <Badge className={classNames('absolute top-2 left-2 bg-opacity-95 shadow', badgeColor(dealStatus))} label={dealStatus} />}
          </div>
          <div className="w-0 flex-1 flex flex-col justify-between overflow-clip">
            <div>
              <div className="text-xs text-gray-500 font-medium tracking-wider">{`${homeType} • ${availabilityString}`}</div>
              <div className="mt-1 truncate">
                <span className="font-semibold ">{plan}</span>
                <span className="font-normal">{` at ${subdivisionName}, ${city}, ${state} ${zip}`}</span>
              </div>
              <div className="mt-4 flex items-center gap-x-4">
                <div className="font-normal text-2xl">{priceString}</div>
                <div className="px-3 py-0.5 text-xs rounded-3xl border whitespace-nowrap">{psfString}</div>
              </div>
              <div className="text-sm font-medium mt-1">{`${bedroomsTotal} Bed • ${bathroomsTotalInteger} Bath • ${formatInteger(sqft)} SF`}</div>
            </div>
            <div className="flex-grow flex flex-col justify-end">
              <div className="flex divide-x items-center justify-between">
                <div className="flex-1 px-3 text-center whitespace-nowrap">
                  <div className="text-sm font-medium text-black">{`${formatPercentage(stabilizedYield, 2)} • ${formatPercentage(grossStabilizedYield, 2)}`}</div>
                  <div className="text-xs font-medium tracking-wide">Stab. Yield • Gross Yield</div>
                </div>
                <div className="flex-1 px-3 text-center whitespace-nowrap">
                  <div className="text-sm font-medium text-black">{`${formatPercentage(unleveredIrr, 2)} • ${formatPercentage(leveredIrr, 2)}`}</div>
                  <div className="text-xs font-medium tracking-wide">Unlevered • Levered IRR</div>
                </div>
                <div className="flex-1 px-3 text-center whitespace-nowrap">
                  <div className="text-sm font-medium text-black">{formatCurrency(marketRent)}</div>
                  <div className="text-xs font-medium tracking-wide">Market Rent Avg.</div>
                </div>
              </div>
              <div className="flex mt-3 gap-x-3 text-gray-500 text-xs font-medium whitespace-nowrap tracking-wider">
                <span className="uppercase">{builderName}</span>
                <span>{`Updated: ${formatDate(asOfDate)}`}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
