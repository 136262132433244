import { createColumnHelper } from '@tanstack/react-table';
import { ExternalLinkCell, InputCell, RouteLinkCell } from 'components/shared/Table/Cells';
import ExternalUrl from 'components/shared/ExternalUrl';
import { DataTableContent, useFilteredSelectedRows } from 'components/shared/Table/DataTableContext';
import { enableEditing, selectRowOnClick } from 'components/shared/Table/table.helpers';
import DataTable from 'components/shared/Table/DataTable';
import { useFetchHomeBuildersQuery } from '../../../redux/homeBuilderApiSlice';
import { homeBuilderNameColumn, NEW_BUILD_DEFAULT_COLUMN } from './newBuildInventory';
import { DetailField, DetailFields, DetailPaneHeading, DetailPaneNoData } from './NewBuildInventoryDetail';
import NewBuildInventorySideNav, { SideNavRail, useCurrentSideNavPanel } from './NewBuildInventorySideNav';
import SideNavPaneContainer from './SideNavPaneContainer';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.display({ id: 'homeBuilderLink', cell: RouteLinkCell, meta: { linkPath: '/home_builders/:id' } }),
  // TODO: link to home model page with filter set to current home builder
  // TODO: link to subdivision page with filter set to current home builder
  homeBuilderNameColumn(),
  columnHelper.accessor('website', { header: 'Website', cell: ExternalLinkCell }),
  columnHelper.accessor('internalNotes', {
    header: 'Internal Notes',
    meta: {
      className: 'max-w-60 truncate text-ellipsis',
      ...enableEditing({ cell: InputCell, inputType: 'text-area', rows: 3 }),
    },
  }),
];

function HomeBuilderDetailPane() {
  const [row] = useFilteredSelectedRows() ?? [];
  const {
    original: {
      name,
      website,
      description,
    } = {},
  } = row ?? {};

  if (useCurrentSideNavPanel() !== 'detail') {
    return null;
  }

  return row ? (
    <>
      <DetailPaneHeading>
        {name}
      </DetailPaneHeading>

      <DetailFields>
        <DetailField label="Website">
          {website && (
            <ExternalUrl href={website} className="max-w-full truncate">
              {website}
            </ExternalUrl>
          )}
        </DetailField>
        <DetailField label="Description">{description}</DetailField>
      </DetailFields>
    </>
  ) : (
    <DetailPaneNoData />
  );
}

function HomeBuilderTable({ children }) {
  const { currentData, isFetching, isUninitialized } = useFetchHomeBuildersQuery();

  return (
    <DataTable
      columns={columns}
      data={currentData}
      defaultColumn={NEW_BUILD_DEFAULT_COLUMN}
      isLoading={isUninitialized || isFetching}
      tableContainerClassName="flex-1 whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0"
      onRowClick={selectRowOnClick}
      enableMultiRowSelection={false}
    >
      {children}
    </DataTable>
  );
}

export default function NewBuildInventoryHomeBuilders() {
  return (
    <HomeBuilderTable>
      <div className="flex flex-row h-0 flex-1">
        <DataTableContent />
        <NewBuildInventorySideNav>
          <SideNavPaneContainer>
            <HomeBuilderDetailPane />
          </SideNavPaneContainer>
          <SideNavRail page="homeBuilder" />
        </NewBuildInventorySideNav>
      </div>
    </HomeBuilderTable>
  );
}
