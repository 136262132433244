import { FormField } from 'components/Form';
import { PERCENT_TYPE } from '../../Input';

export default function UnitTurnParameters({ parameters, onChange, canEditPortfolio }) {
  const {
    concessions,
    downtime,
    initiallyVacant,
    leaseTerm,
    releasingCost,
    renewalProbability,
    rentControlled,
  } = parameters;

  return (
    <>
      <FormField
        type="checkbox"
        name="initiallyVacant"
        label="Default all Single Family to Vacant at acquisition"
        value={initiallyVacant ?? false}
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="leaseTerm"
        label="Lease Term (months)"
        value={leaseTerm}
        type="number"
        min="1"
        unit="months"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="concessions"
        label="Concessions (months)"
        value={concessions}
        type="number"
        min="0"
        unit="months"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="downtime"
        label="Downtime (days)"
        value={downtime}
        type="number"
        min="0"
        unit="days"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="releasingCost"
        label="Turnover Cost ($/unit)"
        value={releasingCost}
        type="number"
        min="0"
        unit="$/unit"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="renewalProbability"
        value={renewalProbability}
        type={PERCENT_TYPE}
        min="0"
        max="100"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        type="checkbox"
        name="rentControlled"
        value={rentControlled ?? false}
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
    </>
  );
}
