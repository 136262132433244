import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckboxEmpty, CheckboxFilled, FilledChevron, X } from 'components/icons';
import cx from 'classnames';
import { identity, includes, isEmpty, isNumber, omit, size, xor } from 'lodash';
import { Menu } from '@headlessui/react';
import Input from 'components/Input';
import { hideImgOnError, titleCase } from 'components/utils';
import { endOfDay, endOfWeek, format } from 'date-fns';
import { useBuildTable, RenderTable } from 'components/shared/Table/DataTable';
import useElementHeight from 'hooks/useElementHeight';
import { workflowPath } from 'components/routes';

function NameCell({ row }) {
  return (
    <div
      className="flex items-center"
    >
      <div className="h-12 w-12">
        <img
          className="h-12 w-12 object-cover"
          alt="propertyPhoto"
          src={row.original.imageUrl}
          onError={hideImgOnError}
        />
      </div>
      <div className="ml-3">
        <div className="text-sm">{row.original.dealName}</div>
      </div>
    </div>
  );
}

function StatusCell({ getValue }) {
  return (
    <div className="text-vxs w-max px-3 text-center py-px rounded-lg text-primary-dark font-medium bg-primary-light">{getValue()}</div>
  );
}

function DaysLeftCell({ getValue }) {
  return (
    <div className={cx(
      'mx-6 h-8 min-w-[39px] w-fit p-1.5 text-center rounded-lg items-center',
      { 'bg-error-100 text-error': isNumber(getValue()) && getValue() <= 2 },
    )}
    >
      { getValue() || '-'}
    </div>
  );
}

function MultiSelectDropdown({ filters, setFilters, localFilters, setLocalFilters, name, filterKey, selectOptions }) {
  return (
    <Menu as="div">
      {({ open }) => (
        <>
          <Menu.Button
            className={cx(
              'cursor-pointer flex mt-0.5 h-8 text-xs font-medium justify-center items-center text-center rounded-lg px-3 py-1.5 border outline-neutral-light select-none hover:bg-blue-200',
              { 'bg-blue-100': !isEmpty(filters[filterKey]) || open },
            )}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            { isEmpty(filters[filterKey]) ? name : (filters[filterKey]?.length === 1 ? selectOptions.find(op => op.value === filters[filterKey][0]).label : `${filters[filterKey]?.length} ${name}`)}
            <div className="h-full items-center flex space-x-2">
              {!isEmpty(filters[filterKey]) ? (
                <X
                  className="ml-4 w-4 h-4 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    setLocalFilters(omit(localFilters, [filterKey]));
                    setFilters(omit(filters, [filterKey]));
                  }}
                />
              ) : (<FilledChevron className="w-5" direction={open ? 'up' : 'down'} />)}
            </div>
          </Menu.Button>
          <Menu.Items className="absolute w-92 mt-0.5 rounded-lg bg-white border z-30 shadow-lg">
            <div
              className="w-92 flex p-4 items-center justify-between cursor-pointer text-base select-none relative font-normal text-[#1C1B1F] border-b"
              onClick={() => setLocalFilters({ ...localFilters, [filterKey]: selectOptions.length === localFilters[filterKey]?.length ? [] : selectOptions.map(({ value }) => value) })}
            >
              <span className="pr-24">{`All ${name}`}</span>
              { selectOptions.length === localFilters[filterKey]?.length ? <CheckboxFilled className="w-8" /> : <CheckboxEmpty className="w-8" /> }
            </div>
            {selectOptions.map(({ label, value }, index) => (
              <div
                key={index}
                className="w-92 h-14 flex items-center justify-between cursor-pointer text-base select-none relative py-6 px-4 font-normal text-[#1C1B1F]"
                onClick={() => setLocalFilters({ ...localFilters, [filterKey]: xor(localFilters[filterKey] || [], [value]) })}
              >
                <span className="pr-24">{label}</span>
                { includes(localFilters[filterKey], value) ? <CheckboxFilled className="w-8" /> : <CheckboxEmpty className="w-8" /> }
              </div>
            ))}
            <Menu.Item as="div" className="h-12 mt-4 p-4 flex justify-between">
              <div />
              <div className="flex h-full item-center items-end">
                <div
                  className="mx-2 px-4 py-2 text-primary-dark text-sm cursor-pointer font-medium"
                  onClick={() => {
                    setLocalFilters(omit(localFilters, [filterKey]));
                    setFilters(omit(filters, [filterKey]));
                  }}
                >
                  Reset
                </div>
                <div
                  className="flex h-10 w-20 items-center px-5 text-sm rounded-full bg-primary-dark text-white cursor-pointer font-medium"
                  onClick={() => {
                    setFilters(localFilters);
                  }}
                >
                  Apply
                </div>
              </div>
            </Menu.Item>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}

function RangeFilter({ filterKey, filters, label, labelFormatter = identity, labelSuffix = '', setFilters, type }) {
  const [range, setRange] = useState(filters[filterKey] || []);

  const filtered = filters[filterKey]?.[0] || filters[filterKey]?.[1];
  let formattedLabel;
  if (filtered) {
    let filteredLabel;
    if (filters[filterKey][0] && filters[filterKey][1]) {
      filteredLabel = `${labelFormatter(filters[filterKey][0])} - ${labelFormatter(filters[filterKey][1])}`;
    } else if (filters[filterKey][0]) {
      filteredLabel = `${labelFormatter(filters[filterKey][0])}+`;
    } else {
      filteredLabel = `Up to ${labelFormatter(filters[filterKey][1])}`;
    }
    formattedLabel = `${filteredLabel}${labelSuffix}`;
  } else {
    formattedLabel = label || titleCase(filterKey);
  }

  return (
    <Menu as="div">
      {({ open }) => (
        <>
          <Menu.Button
            className={cx(
              'cursor-pointer flex mt-0.5 h-8 text-xs font-medium justify-center items-center text-center rounded-lg px-3 py-1.5 border outline-neutral-light select-none hover:bg-blue-200',
              { 'bg-blue-100': !isEmpty(filters[filterKey]) || open },
            )}
          >
            {formattedLabel}
            <div className="h-full items-center flex space-x-2">
              {!isEmpty(filters[filterKey]) ? (
                <X
                  className="ml-4 w-4 h-4 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    setRange([]);
                    setFilters(omit(filters, [filterKey]));
                  }}
                />
              ) : (<FilledChevron className="w-5" direction={open ? 'up' : 'down'} />)}
            </div>
          </Menu.Button>
          <Menu.Items className="absolute w-120 mt-0.5 rounded-lg bg-white border z-30 shadow-lg">
            <div className="flex space-x-2 justify-between items-center p-4">
              <Input
                onChange={(e) => setRange([e.target.value, range[1]])}
                placeholder="No min"
                type={type}
                value={range[0]}
                className="px-3 py-3.5 relative rounded text-sm outline-none focus:outline-none border border-slate-300 shadow-sm w-full pl-10"
              />
              <div>-</div>
              <Input
                onChange={(e) => setRange([range[0], e.target.value])}
                placeholder="No max"
                type={type}
                value={range[1]}
                className="px-3 py-3.5 relative rounded text-sm outline-none focus:outline-none border border-slate-300 shadow-sm w-full pl-10"
              />
            </div>
            <Menu.Item as="div" className="h-12 mt-4 p-4 flex justify-between">
              <div />
              <div className="flex h-full item-center items-end">
                <div
                  className="mx-2 px-4 py-2 text-primary-dark text-sm cursor-pointer font-medium"
                  onClick={() => {
                    setRange([]);
                    setFilters(omit(filters, [filterKey]));
                  }}
                >
                  Reset
                </div>
                <div
                  className="flex h-10 w-20 items-center px-5 text-sm rounded-full bg-primary-dark text-white cursor-pointer font-medium"
                  onClick={() => {
                    setFilters({ ...filters, [filterKey]: range });
                  }}
                >
                  Apply
                </div>
              </div>
            </Menu.Item>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}

function LastModifiedCell({ getValue }) {
  return (
    <div className="pl-5 h-full flex flex-col text-right items-center">
      <div className="text-neutral-dark text-sm uppercase">{format(new Date(getValue()), 'MMM dd')}</div>
      <div className="text-neutral-medium text-xs">{format(new Date(getValue()), "HH:mm aaaaa'm'")}</div>
    </div>
  );
}

export default function UpcomingTaskTable({ searchTerm, filters, setFilters, tasks, loading, usersById, dealStageComparator, uniquePortfolios, uniqueUsers, uniqueStages }) {
  const navigate = useNavigate();
  const [localFilters, setLocalFilters] = useState(filters);
  const upcomingTaskData = useMemo(() => tasks, [tasks]);
  const upcomingTaskColumns = useMemo(
    () => [
      {
        header: 'Deal',
        accessorKey: 'dealName',
        sortingFn: (rowA, rowB) => (rowA.original.dealName < rowB.original.dealName ? 1 : -1),
        cell: NameCell,
      },
      {
        header: 'Portfolio',
        accessorKey: 'portfolioName',
      },
      {
        header: 'Task',
        accessorKey: 'name',
      },
      {
        header: 'Owner',
        accessorFn: (row) => usersById[row.userId]?.fullName || usersById[row.userId]?.email || 'Unassigned',
      },
      {
        header: 'Deal Stage',
        accessorKey: 'stage',
        id: 'stage',
        sortingFn: (rowA, rowB) => dealStageComparator(rowA.original.stage, rowB.original.stage),
        cell: StatusCell,
      },
      {
        header: 'Due',
        accessorKey: 'dueDate',
        cell: ({ getValue }) => (getValue() ? format(new Date(getValue()), 'MMM dd') : '-'),
        meta: { className: 'uppercase' },
      },
      {
        header: 'Days Left',
        accessorKey: 'daysLeft',
        sortingFn: 'basic',
        cell: DaysLeftCell,
      },
      {
        header: 'Last Modified',
        accessorKey: 'updatedAt',
        cell: LastModifiedCell,
      },
    ],
    [dealStageComparator, usersById],
  );

  const options = [
    { name: 'Portfolios', filterKey: 'portfolioIds', selectOptions: uniquePortfolios },
    { name: 'Owners', filterKey: 'userIds', selectOptions: [{ label: 'Unassigned', value: 0 }, ...uniqueUsers] },
    { name: 'Stages', filterKey: 'stageIds', selectOptions: uniqueStages },
  ];
  const onRowClick = (row) => navigate(workflowPath({ id: row.original.dealId }));
  const table = useBuildTable({
    columns: upcomingTaskColumns,
    data: upcomingTaskData,
    initialState: { sorting: [{ id: 'updatedAt', desc: true }] || [] },
    onRowClick,
  });
  const { getRowModel } = table;

  const overdueCount = size(getRowModel().rows.filter(({ original: { daysLeft } }) => daysLeft < 0));
  const dueThisWeekCount = size(getRowModel().rows.filter(({ original: { dueDate } }) => new Date(dueDate) > endOfDay(new Date()) && new Date(dueDate) < endOfWeek(endOfDay(new Date()))));

  const tableContainerRef = useRef();
  const tableHeight = useElementHeight(tableContainerRef);

  return (
    <div className="mt-6 border border-gray-200 bg-white rounded" style={{ width: 'calc(100vw - 126px)' }}>
      <div className="h-12 px-4 flex justify-between">
        <div className="h-full items-center flex space-x-2 py-0">
          {options.map((optionObject, index) => (
            <div className="" key={index}>
              <MultiSelectDropdown
                {...optionObject}
                filters={filters}
                setFilters={setFilters}
                localFilters={localFilters}
                setLocalFilters={setLocalFilters}
              />
            </div>
          ))}
          <RangeFilter type="number" filterKey="daysLeft" filters={filters} label="Days Left" labelSuffix="Days" setFilters={setFilters} />
          <RangeFilter type="date" filterKey="dueDate" filters={filters} label="Due Date" setFilters={setFilters} />
        </div>
        <div className="py-3">
          <p className="text-neutral-medium font-base">
            <b>{size(getRowModel().rows)}</b>
            {' '}
            Tasks ·
            {' '}
            <b>{overdueCount}</b>
            {' '}
            overdue ·
            {' '}
            <b>{dueThisWeekCount}</b>
            {' '}
            due this week
          </p>
        </div>
      </div>
      <div
        className="border-t"
        ref={tableContainerRef}
        style={{ maxHeight: 'calc(100vh - 185px)' }}
      >
        <RenderTable
          table={table}
          tableHeight={tableHeight}
          isLoading={loading}
          emptyStateComponent={searchTerm?.length > 0 ? `No tasks were found for the search term '${searchTerm}'` : 'No tasks were found for the active filters'}
        />
      </div>
    </div>
  );
}
