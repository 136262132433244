import cx from 'classnames';

/**
 * @param {HTMLAnchorElement['href']} href
 * @param {string} width
 * @param {string} className
 * @param {import('react').ReactNode} children
 */
export default function ExternalUrl({ href, width, className, children }) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer nofollow"
      className={cx(
        'block underline text-tertiary hover:text-tertiary-lighter active:text-tertiary-lighter focus:text-tertiary-lighter',
        width ?? 'w-max',
        className,
      )}
    >
      {children}
    </a>
  );
}
