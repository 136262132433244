import { useEffect, useState } from 'react';
import { isEmpty, remove } from 'lodash';
import Filter from 'components/DealSourcing/Filters/Filter';
import { BedBathButton } from 'components/shared/filters/BedBathFilter';

export default function BedBathFilter({ filters, setFilters }) {
  const [bedFilter, setBedFilter] = useState({});
  const [bedUseExact, setBedUseExact] = useState(filters.bedroomsTotal?.useExact || false);
  const [bathFilter, setBathFilter] = useState({});
  const [bathUseExact, setBathUseExact] = useState(filters.bathroomsTotalInteger?.useExact || false);

  useEffect(() => {
    setBedFilter({
      useExact: filters.bedroomsTotal?.useExact,
      selections: filters.bedroomsTotal?.selections || [],
    });

    setBathFilter({
      useExact: filters.bathroomsTotalInteger?.useExact,
      selections: filters.bathroomsTotalInteger?.selections || [],
    });
  }, [filters.bathroomsTotalInteger, filters.bedroomsTotal]);

  const onApply = (close) => {
    setFilters({ ...filters, bathroomsTotalInteger: bathFilter, bedroomsTotal: bedFilter });
    close();
  };

  const onReset = () => {
    setFilters({ ...filters, bathroomsTotalInteger: {}, bedroomsTotal: {} });
  };

  const filtered = !isEmpty(filters.bedroomsTotal?.selections) || !isEmpty(filters.bathroomsTotalInteger?.selections);

  // TODO:
  // - show multi-selection values within filter button/chip
  const label = 'Bed/Bath';

  function onBedSelection(e) {
    const updatedBedFilter = { ...bedFilter };
    const { selections } = updatedBedFilter;
    if (selections?.includes(e)) {
      remove(selections, (n) => n === e);
    } else {
      selections.push(e);
    }
    setBedFilter({ useExact: bedUseExact, selections: selections.sort() });
  }

  function onBathSelection(e) {
    const updatedBathFilter = { ...bathFilter };
    const { selections } = updatedBathFilter;
    if (selections?.includes(e)) {
      remove(selections, (n) => n === e);
    } else {
      selections.push(e);
    }
    setBathFilter({ useExact: bathUseExact, selections: selections.sort() });
  }

  return (
    <Filter label={label} onApply={onApply} onReset={onReset} filtered={filtered}>
      <div className="mb-3">Beds</div>
      <div className="flex w-full mb-3">
        {bedUseExact ? (
          <>
            <BedBathButton label="Studio" active={bedFilter.selections?.includes(0)} onClick={() => onBedSelection(0)} width="w-1/5" />
            <BedBathButton label="1" active={bedFilter.selections?.includes(1)} onClick={() => onBedSelection(1)} width="w-1/5" />
            <BedBathButton label="2" active={bedFilter.selections?.includes(2)} onClick={() => onBedSelection(2)} width="w-1/5" />
            <BedBathButton label="3" active={bedFilter.selections?.includes(3)} onClick={() => onBedSelection(3)} width="w-1/5" />
            <BedBathButton label="4" active={bedFilter.selections?.includes(4)} onClick={() => onBedSelection(4)} width="w-1/5" />
            <BedBathButton label="5+" active={bedFilter.selections?.includes(5)} onClick={() => onBedSelection(5)} lastButton width="w-1/5" />
          </>
        ) : (
          <>
            <BedBathButton label="Any" active={isEmpty(bedFilter.selections)} onClick={() => setBedFilter({ ...bedFilter, selections: [] })} width="w-1/6" />
            <BedBathButton label="Studio+" active={bedFilter.selections ? bedFilter.selections[0] === 0 : false} onClick={() => setBedFilter({ ...bedFilter, selections: [0, null] })} width="w-1/6" />
            <BedBathButton label="1+" active={bedFilter.selections ? bedFilter.selections[0] === 1 : false} onClick={() => setBedFilter({ ...bedFilter, selections: [1, null] })} width="w-1/6" />
            <BedBathButton label="2+" active={bedFilter.selections ? bedFilter.selections[0] === 2 : false} onClick={() => setBedFilter({ ...bedFilter, selections: [2, null] })} width="w-1/6" />
            <BedBathButton label="3+" active={bedFilter.selections ? bedFilter.selections[0] === 3 : false} onClick={() => setBedFilter({ ...bedFilter, selections: [3, null] })} width="w-1/6" />
            <BedBathButton label="4+" active={bedFilter.selections ? bedFilter.selections[0] === 4 : false} onClick={() => setBedFilter({ ...bedFilter, selections: [4, null] })} width="w-1/5" />
            <BedBathButton label="5+" active={bedFilter.selections ? bedFilter.selections[0] === 5 : false} onClick={() => setBedFilter({ ...bedFilter, selections: [5, null] })} lastButton width="w-1/5" />
          </>
        )}
      </div>
      <div>
        <input
          checked={bedUseExact}
          className="mr-4"
          id="bedroom-checkbox"
          onChange={() => { setBedUseExact(!bedUseExact); setBedFilter({ useExact: bedUseExact, selections: [] }); }}
          type="checkbox"
        />
        <label className="text-gray-600" htmlFor="bedroom-checkbox">Use exact match</label>
      </div>
      <div className="mt-6 mb-3">Baths</div>
      <div className="flex w-full mb-3">
        {bathUseExact ? (
          <>
            <BedBathButton label="1" active={bathFilter.selections?.includes(1)} onClick={() => onBathSelection(1)} width="w-1/4" />
            <BedBathButton label="2" active={bathFilter.selections?.includes(2)} onClick={() => onBathSelection(2)} width="w-1/4" />
            <BedBathButton label="3" active={bathFilter.selections?.includes(3)} onClick={() => onBathSelection(3)} width="w-1/4" />
            <BedBathButton label="4" active={bathFilter.selections?.includes(4)} onClick={() => onBathSelection(4)} width="w-1/4" />
            <BedBathButton label="5+" active={bathFilter.selections?.includes(5)} onClick={() => onBathSelection(5)} lastButton width="w-1/4" />
          </>
        ) : (
          <>
            <BedBathButton label="Any" active={isEmpty(bathFilter.selections)} onClick={() => setBathFilter({ ...bathFilter, selections: [] })} width="w-1/5" />
            <BedBathButton label="1+" active={bathFilter.selections ? bathFilter.selections[0] === 1 : false} onClick={() => setBathFilter({ ...bathFilter, selections: [1, null] })} width="w-1/5" />
            <BedBathButton label="2+" active={bathFilter.selections ? bathFilter.selections[0] === 2 : false} onClick={() => setBathFilter({ ...bathFilter, selections: [2, null] })} width="w-1/5" />
            <BedBathButton label="3+" active={bathFilter.selections ? bathFilter.selections[0] === 3 : false} onClick={() => setBathFilter({ ...bathFilter, selections: [3, null] })} width="w-1/5" />
            <BedBathButton label="4+" active={bathFilter.selections ? bathFilter.selections[0] === 4 : false} onClick={() => setBathFilter({ ...bathFilter, selections: [4, null] })} lastButton width="w-1/5" />
            <BedBathButton label="5+" active={bathFilter.selections ? bathFilter.selections[0] === 5 : false} onClick={() => setBathFilter({ ...bathFilter, selections: [5, null] })} lastButton width="w-1/5" />
          </>
        )}
      </div>
      <div>
        <input
          checked={bathUseExact}
          className="mr-4"
          id="bathroom-checkbox"
          onChange={() => { setBathUseExact(!bathUseExact); setBathFilter({ useExact: bathUseExact, selections: [] }); }}
          type="checkbox"
        />
        <label className="text-gray-600" htmlFor="bathroom-checkbox">Use exact match</label>
      </div>
    </Filter>
  );
}
