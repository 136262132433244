import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { LoadingIndicator } from 'components/icons';

const DEFAULT_DELAY = 500;

/**
 * @param {string} [className]
 * @param {number} [delayMs]
 * @param [Icon]
 */
export default function DelayedLoadingIndicator({ className, delayMs = DEFAULT_DELAY, Icon = LoadingIndicator }) {
  // delayMs should not be changed
  const delayRef = useRef(delayMs ?? DEFAULT_DELAY);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handle = setTimeout(() => {
      setIsVisible(true);
    }, delayRef.current);

    return () => {
      clearTimeout(handle);
    };
  }, []);

  // use invisible to retain space, it is more likely than not for the loading indicator to eventually be displayed
  const clazz = cx(className, !isVisible && 'invisible');
  return <Icon className={clazz} />;
}
