import { Link, useNavigate } from 'react-router-dom';
import { useFetchPermissionsQuery } from 'redux/apiSlice';
import { Chevron, Folder, Pencil, Plus } from 'components/icons';
import { newPortfolioPath, portfolioEditPath } from 'components/routes';
import Button from 'components/shared/NewButton';
import PortfolioStat from 'components/portfolio/PortfolioStat';

export default function PortfolioHeader({ canCreatePortfolio, editing, hasChanges, portfolio, onCreate, onReset, onUpdate, stats }) {
  const navigate = useNavigate();
  const isNewPortfolio = portfolio && !portfolio.id;

  const permissionKey = isNewPortfolio ? 'canCreatePortfolio' : 'canEditPortfolio';
  const { currentData: { [permissionKey]: canMutate = false } = {} } = useFetchPermissionsQuery(null, { skip: !editing });

  const onSubmit = () => {
    if (isNewPortfolio) {
      return onCreate();
    }
    return onUpdate();
  };

  return (
    <div className="p-6 bg-white border border-b shadow">
      <div className="flex justify-between items-center">
        <div className="basis-1/3 text-xl">
          <div className="flex items-center gap-x-4">
            <div className="w-12 h-12 flex justify-center items-center rounded-xl bg-primary-light">
              <Folder className="w-6 fill-primary-dark" filled />
            </div>
            <Link className="cursor-pointer" to="/portfolios">
              <div className="text-xl">Portfolios</div>
            </Link>
            {portfolio && (
              <>
                <Chevron direction="right" className="w-4 text-gray-500" />
                <Link className="flex cursor-pointer items-center gap-x-4" to={`/portfolios/${portfolio.id}`}>
                  <div className="flex flex-col">
                    <div>{portfolio.name}</div>
                    <p className="text-xs text-gray-500">{portfolio.client || (portfolio.markets.length === 1 ? '1 market' : `${portfolio.markets.length} markets`)}</p>
                  </div>
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="basis-1/3 justify-center flex flex-row">
          {stats && stats.map((stat, index) => (
            // eslint-disable-next-line no-nested-ternary
            <div key={index} className={`text-center ${index === 0 ? 'pr-4 border-r border-gray-100' : (index === stats.length - 1) ? 'px-4 border-none' : ' border-r border-gray-100 px-4 flex flex-col items-center justify-evenly'}`}>
              <PortfolioStat key={stat.label} label={stat.label} value={stat.value} />
            </div>
          ))}
        </div>
        <div className="basis-1/3 flex justify-end">
          {canCreatePortfolio && (
            <Button filled label="Add Portfolio" leadingIcon={<Plus className="w-4" />} onClick={() => navigate(newPortfolioPath)} />
          )}
          {(portfolio && !editing) && (
            <Button filled label="Edit Portfolio" leadingIcon={<Pencil className="w-4" />} onClick={() => navigate(portfolioEditPath(portfolio))} />
          )}
          {(portfolio && canMutate && (hasChanges || isNewPortfolio)) && (
            <div className="flex gap-x-6">
              {!isNewPortfolio && <Button tonal label="Reset Changes" onClick={onReset} />}
              <Button filled label={isNewPortfolio ? 'Create Portfolio' : 'Save Changes'} onClick={onSubmit} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
