import { useEffect, useState } from 'react';
import { svgDataUrl } from '../utils';
import { CancelMarker, GenericMarker, MixedUseMarker, MultiFamilyMarker, SingleFamilyMarker } from './MarkerIcons';

const MARKER_BOUNDING_DIM = {
  sm: 24,
  md: 36,
  lg: 48,
};

export const MARKER_PIN_HEIGHT = {
  sm: 20,
  md: 30,
  lg: 40,
};

// The rendered stroke width will be half of this
// we want inner stroke but svg only supports center stroke,
// so half of the stroke width will be clipped away
const MARKER_STROKE_WIDTH = {
  sm: 2,
  md: 2,
  lg: 4,
};

const MARKER_ANCHOR_Y_OFFSET = Object.fromEntries(['sm', 'md', 'lg'].map(size => (
  [size, (MARKER_BOUNDING_DIM[size] - MARKER_PIN_HEIGHT[size]) / 2]
)));

const PIN_RED = '#d85140';
const PIN_GREEN = '#34a853';
const PIN_LIGHT_BLUE = '#12b5cb';
const PIN_SLATE_BLUE = '#7986cb';
const PIN_GREY = '#78909c';

const makeMarker = ({ Marker, size, fill }) => {
  const dim = MARKER_BOUNDING_DIM[size];
  if (!window.google) return null;
  return {
    url: svgDataUrl(Marker({
      fill,
      dim,
      strokeWidth: MARKER_STROKE_WIDTH[size],
    })),
    size: new window.google.maps.Size(dim, dim),
    scaledSize: new window.google.maps.Size(dim, dim),
    anchor: new window.google.maps.Point(dim / 2, dim - MARKER_ANCHOR_Y_OFFSET[size]),
  };
};

const makeFocusedMarker = ({ Marker, size = 'md', fill = PIN_RED }) => (
  makeMarker({ Marker, size, fill })
);

export const markerIconOptions = makeMarker({ Marker: GenericMarker, size: 'sm', fill: PIN_GREEN });
export const offMarketIconOptions = makeMarker({ Marker: GenericMarker, size: 'sm', fill: PIN_GREY });
export const deadIconOptions = makeMarker({ Marker: CancelMarker, size: 'md', fill: PIN_GREY });
export const focusedDeadIconOptions = makeFocusedMarker({ Marker: CancelMarker });
export const focusedIconOptions = makeFocusedMarker({ Marker: GenericMarker });

export const pipelinePropertyIconOptions = {
  SF: makeMarker({ Marker: SingleFamilyMarker, size: 'md', fill: PIN_LIGHT_BLUE }),
  MF: makeMarker({ Marker: MultiFamilyMarker, size: 'md', fill: PIN_LIGHT_BLUE }),
  MU: makeMarker({ Marker: MixedUseMarker, size: 'md', fill: PIN_LIGHT_BLUE }),
};
export const closedPropertyIconOptions = {
  SF: makeMarker({ Marker: SingleFamilyMarker, size: 'md', fill: PIN_SLATE_BLUE }),
  MF: makeMarker({ Marker: MultiFamilyMarker, size: 'md', fill: PIN_SLATE_BLUE }),
  MU: makeMarker({ Marker: MixedUseMarker, size: 'md', fill: PIN_SLATE_BLUE }),
};
export const focusedPropertyIconOptions = {
  SF: makeFocusedMarker({ Marker: SingleFamilyMarker }),
  MF: makeFocusedMarker({ Marker: MultiFamilyMarker }),
  MU: makeFocusedMarker({ Marker: MixedUseMarker }),
};

export const useRenderMarkers = ({ markers, pixelRatio = Math.ceil(window.devicePixelRatio) * 2 }) => {
  const [renderState, setRenderState] = useState({ markersReady: false });

  useEffect(() => {
    setRenderState({ markersReady: false });

    const renderIconsAsync = async () => {
      const renderedMarkers = await Promise.all(Object.entries(markers).map(async ([key, marker]) => {
        const renderedWidth = marker.size.width * pixelRatio;
        const renderedHeight = marker.size.height * pixelRatio;

        const canvas = document.createElement('canvas');
        canvas.width = renderedWidth;
        canvas.height = renderedHeight;

        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = false;
        ctx.scale(pixelRatio, pixelRatio);

        const img = new Image(marker.size.width, marker.size.height);
        const renderPromise = new Promise(resolve => {
          img.addEventListener('load', () => {
            ctx.drawImage(img, 0, 0);
            resolve(canvas.toDataURL('image/png'));
          });
        });
        img.src = marker.url;

        const url = await renderPromise;
        return [
          key,
          {
            ...marker,
            url,
            size: new google.maps.Size(renderedWidth, renderedHeight),
          },
        ];
      }));

      setRenderState({
        markersReady: true,
        renderedMarkers: Object.fromEntries(renderedMarkers),
      });
    };

    renderIconsAsync();
  }, [markers, pixelRatio]);

  return renderState;
};
