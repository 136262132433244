import { last } from 'lodash';
import { isItemized } from 'components/dcf/dcf';
import Alert from 'components/Alert';
import { PERCENT_TYPE } from 'components/Input';
import { TAX_GROWTH_TIMING_OPTIONS } from 'components/dcf/expense';
import { ToggleWithLabels } from 'components/Toggle';
import { InlineFormField } from '../../Form';
import { ASSESSED_VALUE_AS_PERCENT, assessedAsPercent } from '../tax';

export default function SimpleTaxes({
  assessorSiteUrl,
  cashFlows,
  dcfParams,
  onChange,
  setActiveParameterTab,
}) {
  const {
    assessedValue,
    assessedValueExitPercent,
    assessedValuePurchasePercent,
    effectiveTaxRate,
    futureTaxParameters,
    purchasePrice,
    taxExpenseGrowthRate,
    taxExpenseGrowthTiming,
  } = dcfParams;

  const percentEntry = assessedAsPercent(dcfParams);

  // this is kind of a hack to allow the toggle to change two DCF parameter fields in one event
  // which is not currently supported by the Model onChange architecture
  const togglePercentEntry = () => {
    if (percentEntry) {
      onChange({
        target: {
          name: ASSESSED_VALUE_AS_PERCENT,
          value: [null, null],
        },
      });
    } else {
      const currentAssessedPurchasePercent = assessedValue / purchasePrice;
      const finalYearAssessedValue = assessedValue * ((1 + taxExpenseGrowthRate) ** (futureTaxParameters.length));
      const currentAssessedExitPercent = finalYearAssessedValue / last(cashFlows.sale.price);
      onChange({
        target: {
          name: ASSESSED_VALUE_AS_PERCENT,
          value: [currentAssessedPurchasePercent, currentAssessedExitPercent],
        },
      });
    }
  };

  return (
    <div className="w-120">
      {!isItemized(dcfParams) && (
        <div className="absolute z-10 w-full h-full inset-0 bg-black bg-opacity-50 rounded">
          <Alert type="warning">
            Enable
            <button
              className="px-1.5 underline text-primary-500 hover:text-primary-400 cursor-pointer"
              onClick={() => setActiveParameterTab('expenses')}
              type="button"
            >
              itemized expenses
            </button>
            to allow customizing taxes
          </Alert>
        </div>
      )}
      <ToggleWithLabels
        className="w-120 ml-0 mb-4"
        label1="Grow Initial Tax Assessment Market Value"
        label2="Align Tax Assessment Market Value to Purchase / Exit"
        checked={percentEntry}
        onClick={togglePercentEntry}
      />
      {percentEntry ? (
        <>
          <InlineFormField
            className="mt-3"
            name="assessedValuePurchasePercent"
            label="Initial Assessed Value as % of Purchase"
            value={assessedValuePurchasePercent || ''}
            type={PERCENT_TYPE}
            min="0"
            onChange={onChange}
          />
          <InlineFormField
            className="mt-3"
            name="assessedValueExitPercent"
            label="Exit Assessed Value as % of Exit"
            value={assessedValueExitPercent || ''}
            type={PERCENT_TYPE}
            min="0"
            onChange={onChange}
          />
        </>
      ) : (
        <InlineFormField
          label="Year 1 Tax Assessment Market Value"
          name="assessedValue"
          value={assessedValue}
          type="number"
          min="0"
          onChange={onChange}
        />
      )}
      <InlineFormField
        className="mt-3"
        name="effectiveTaxRate"
        label="Tax Rate"
        value={effectiveTaxRate}
        type={PERCENT_TYPE}
        min="0"
        onChange={onChange}
      />
      <InlineFormField
        className="mt-3"
        name="taxExpenseGrowthRate"
        label="Tax Assessment Market Value Growth Rate"
        value={taxExpenseGrowthRate || ''}
        type={PERCENT_TYPE}
        min="0"
        onChange={onChange}
      />
      <InlineFormField
        className="mt-3"
        name="taxExpenseGrowthTiming"
        value={taxExpenseGrowthTiming}
        type="select"
        options={TAX_GROWTH_TIMING_OPTIONS}
        onChange={onChange}
      />
      {assessorSiteUrl && (
        <div className="mt-2">
          <a className="underline text-sm text-primary-dark pb-2" href={assessorSiteUrl} target="_blank" rel="noreferrer">Assessor Site</a>
        </div>
      )}
    </div>
  );
}
