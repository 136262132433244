import { createColumnHelper } from '@tanstack/react-table';
import { isArray, isObject } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import cx from 'classnames';
import DataTable from 'components/shared/Table/DataTable';
import { Check, X } from 'components/icons';
import { titleCase } from 'components/utils';
import Button from 'components/shared/NewButton';
import Alert from 'components/Alert';
import { useConfirmHomeModelCsvMutation } from '../../../redux/homeModelApiSlice';

const STATUS_DISPLAY_NAME = {
  new: 'New',
  modified: 'Updated',
  unmodified: 'Unmodified',
};

const columnHelper = createColumnHelper();

function LineNumberCell({ getValue }) {
  const val = getValue();
  return typeof val === 'string' ? val.split(',').join('\n') : val;
}

function StatusCell({ getValue }) {
  return STATUS_DISPLAY_NAME[getValue()];
}

function ValidationResultCell({ getValue }) {
  const val = getValue();
  return val ? (
    <Check className="text-success-300 h-5 w-auto" />
  ) : (
    <X className="text-error-300 h-5 w-auto" />
  );
}

function ResultCell({ getValue }) {
  const { data, errors } = getValue() ?? {};
  if (errors) {
    return errors.join('\n');
  }

  if (isArray(data)) {
    if (data.length && isObject(data[0])) {
      return data.map(o => Object.values(o).join(', ')).join('\n');
    } else {
      return data.join('\n');
    }
  }

  return data ?? null;
}

/**
 * @type {import('@tanstack/react-table').ColumnDefBase['columns']}
 */
const RESULT_METADATA_COLUMNS = [
  columnHelper.accessor('_valid', {
    header: null,
    cell: ValidationResultCell,
  }),
  columnHelper.accessor('_line_number', {
    header: 'Line',
    invertSorting: true,
    sortDescFirst: true,
    cell: LineNumberCell,
  }),
  columnHelper.accessor('_status', {
    header: 'Status',
    cell: StatusCell,
  }),
];

const COL_META_MAP = {
  'Property Address': { className: 'block w-64' },
  'Delivery Date': { className: 'block w-32' },
};

const useModelColumns = ({ model, userColumnOrder }) => (
  useMemo(() => model.length && [
    ...RESULT_METADATA_COLUMNS,
    ...userColumnOrder
      .filter((col) => Object.hasOwn(model[0], col))
      .map((col) => columnHelper.accessor(col, { cell: ResultCell, meta: COL_META_MAP[col] })),
  ], [model, userColumnOrder])
);

const useResultModels = ({ results }) => (
  useMemo(() => {
    const homeModels = [];
    const subdivisions = [];

    results.forEach(({ homeModel, subdivision }) => {
      if (homeModel) {
        homeModels.push({ ...homeModel, id: homeModel.id.data });
      }

      if (subdivision) {
        subdivisions.push({ ...subdivision, id: subdivision.id.data });
      }
    });

    return { homeModels, subdivisions };
  }, [results])
);

const tdClassName = (column, row) => {
  const val = row.getValue(column.id);
  return Object.hasOwn(val, 'errors') ? 'ring-4 ring-error-300 ring-inset' : null;
};

function ModelTable({ model, name, userColumnOrder }) {
  const columns = useModelColumns({ model, userColumnOrder });
  const [errorRowCount, totalRowCount] = useMemo(() => (
    model.reduce(([err, total], row) => (
      [
        // eslint-disable-next-line dot-notation
        row['_valid'] ? err : err + 1,
        // eslint-disable-next-line dot-notation
        total + (typeof row['_line_number'] === 'string' ? row['_line_number'].split(',').length : 1),
      ]
    ), [0, 0])
  ), [model]);

  return (
    <>
      <div className="inline-flex items-center mt-2">
        <span className="font-semibold">{name}</span>
        <div className="inline-flex items-center h-full ml-3.5 text-xs">
          {totalRowCount - errorRowCount}
          <Check className="text-success-300 h-3/4 w-auto mr-0.5" />
          {errorRowCount}
          <X className="text-error-300 h-3/4 w-auto" />
        </div>
      </div>
      <DataTable
        columns={columns}
        data={model}
        tableContainerClassName={cx('mb-6 tabular-nums whitespace-pre-wrap', { 'flex-1': name === 'Home Models' })}
        tdClassName={tdClassName}
      />
    </>
  );
}

export default function ImportStatus({ importData, setImportData, setUploadSuccessAlert, csvUploadOptions: { homeBuilderId } = {} }) {
  const { userColumnOrder, batchId, inventoryZeroedOut } = importData;
  const { homeModels, subdivisions } = useResultModels(importData);
  const reload = useCallback(() => {
    setImportData(null);
  }, [setImportData]);

  const inventoryWasZeroedOut = Object.keys(inventoryZeroedOut).length;

  const [
    confirmTrigger,
    {
      isLoading: confirmIsLoading,
      isError: confirmIsError,
      isSuccess: confirmIsSuccess,
      error: confirmError,
    },
  ] = useConfirmHomeModelCsvMutation();
  const confirm = useCallback(async () => {
    confirmTrigger({ batchId, homeBuilderId });
  }, [batchId, confirmTrigger, homeBuilderId]);

  useEffect(() => {
    if (confirmIsSuccess) {
      setUploadSuccessAlert({ text: 'CSV uploaded successfully' });
    }
  }, [confirmIsSuccess, setUploadSuccessAlert]);

  const canContinue = !!batchId;

  return (
    <div className="flex flex-col w-full p-6 bg-white rounded h-full">
      <div className="flex flex-row items-center mb-4 gap-x-2">
        <div className="text-lg text-gray-900">New Build Import Results</div>
        <Button className="ml-auto" filled={!canContinue} outlined={canContinue} label="Re-upload" onClick={reload} />
        {canContinue && (
          <Button
            filled
            isLoading={confirmIsLoading}
            label={confirmIsError ? 'Retry' : 'Confirm'}
            onClick={confirm}
          />
        )}
      </div>

      {confirmIsError && <Alert className="whitespace-pre-line" type="danger" text={confirmError.data?.error} />}
      <ModelTable name="Subdivisions" model={subdivisions} userColumnOrder={userColumnOrder} />
      <ModelTable name="Home Models" model={homeModels} userColumnOrder={userColumnOrder} />
      {inventoryWasZeroedOut && (
        <div className="mt-6">
          <div className="font-semibold mb-3">Inventory not included in CSV that will be removed</div>
            {Object.keys(inventoryZeroedOut).map(subdivision => (
              <div key={subdivision} className="flex gap-x-3 my-3">
                <div>{`${titleCase(subdivision)}:`}</div>
                <div>{inventoryZeroedOut[subdivision].map(homeModel => homeModel.plan).join(', ')}</div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
