import { Table } from 'docx';
import { cloneDeep, first, groupBy, map, uniq } from 'lodash';
import { formatCurrency } from '../../utils';
import { HEADER_STYLE, makeTableRow, tableHeader, tableProperties } from './util';

export default function buildRentRoll(dcfParams) {
  const { units, unitTypeEntry } = dcfParams;
  const unitsByType = groupBy(units, unit => [unit.groupId, unit.group, unit.bedrooms, unit.bathrooms]);
  const unitTypeAggregates = map(
    unitsByType,
    unitsOfType => {
      const address = uniq(unitsOfType.map(unit => unit.address))[0];
      return Object.assign(cloneDeep(first(unitsOfType)), { number: unitsOfType.length, address });
    },
  );

  const formatVacant = (unit) => (unit.vacant ? 'Y' : 'N');
  const formatUnitName = (unit) => (
    unit.number ?? (unit.bedrooms === 0 ? 'Studio' : `${unit.bedrooms} Bed / ${unit.bathrooms} Bath`)
  );

  const displayUnits = unitTypeEntry ? unitTypeAggregates : units;

  return new Table({
    ...tableProperties,
    rows: [
      tableHeader('Rent Roll Summary', 9, true),
      makeTableRow('Address', 1, ['Unit Name', 'Beds', 'Baths', 'Avg RSF', 'In-Place Rent', 'Market Rent', 'Vacant ?', 'Budget ($/Unit)'], 1, HEADER_STYLE),
      ...displayUnits.map((unit) => (
        makeTableRow(unit.address, 1, [
          formatUnitName(unit),
          unit.bedrooms,
          unit.bathrooms,
          unit.rsf?.toFixed() ?? '-',
          formatCurrency(unit.inPlaceRent),
          formatCurrency(unit.marketRent),
          formatVacant(unit),
          formatCurrency(unit.turnBudget ?? 0),
        ], 1)
      )),
    ],
  });
}
