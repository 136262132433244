import { isEmpty, remove } from 'lodash';
import { Menu as HeadlessMenu } from '@headlessui/react';
import classNames from 'classnames';

export function MenuItem({ className, text, onClick, active, ...props }) {
  // props is needed to pass down props added by headlessui
  return (
    <div className={classNames('px-6 py-4', className, { 'bg-gray-100': active })} onClick={onClick} {...props}>{text}</div>
  );
}

export default function Menu({ button, items, itemGroups = [], width = 'w-44' }) {
  if (!itemGroups.length && items) {
    items.forEach(item => itemGroups.push([item]));
  }

  itemGroups.forEach(actionGroup => remove(actionGroup, isEmpty));
  remove(itemGroups, isEmpty);

  if (isEmpty(itemGroups)) {
    return null;
  }

  return (
    <div>
      <HeadlessMenu as="div" className="flex relative">
        <HeadlessMenu.Button className="px-2 py-1 cursor-pointer outline-none focus:outline-none">{button}</HeadlessMenu.Button>
        <HeadlessMenu.Items className={classNames('mt-2 absolute top-full right-0 z-10 divide-y divide-black divide-opacity-12 bg-white text-tertiary rounded shadow-2xl cursor-pointer select-none focus:outline-none', width)}>
          {itemGroups.map((itemGroup, groupIndex) => (
            <div key={groupIndex}>
              {itemGroup.map((item, index) => (
                <HeadlessMenu.Item key={index}>
                  {({ active }) => <MenuItem {...item} active={active} />}
                </HeadlessMenu.Item>
              ))}
            </div>
          ))}
        </HeadlessMenu.Items>
      </HeadlessMenu>
    </div>
  );
}
