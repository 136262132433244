import { useState, useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';

// Register the image resize module
Quill.register('modules/imageResize', ImageResize);

/**
 * PlaceholderMentionDropdown Component
 * Renders the dropdown list for placeholder mentions.
 */
function PlaceholderMentionDropdown({
  position,
  query,
  placeholders,
  selectedIndex,
  onSelect,
  onQueryChange,
}) {
  const mentionRef = useRef(null);

  return (
    <div
      className="absolute z-10"
      style={{ top: position.top, left: position.left }}
    >
      <ul
        ref={mentionRef}
        className="bg-white border border-gray-300 rounded shadow-lg w-64 max-h-60 overflow-y-auto"
        role="listbox"
        aria-activedescendant={`placeholder-${selectedIndex}`}
      >
        <li className="px-4 py-2 border-b border-gray-200">
          <input
            type="text"
            value={query}
            onChange={(e) => onQueryChange(e.target.value)}
            placeholder="Search placeholders..."
            className="w-full px-2 py-1 border border-gray-300 rounded"
          />
        </li>
        {placeholders.length > 0 ? (
          placeholders.map((placeholder, index) => (
            <li
              key={placeholder}
              id={`placeholder-${index}`}
              role="option"
              aria-selected={index === selectedIndex}
              className={`px-4 py-2 cursor-pointer ${
                index === selectedIndex ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
              }`}
              onMouseDown={(e) => {
                e.preventDefault();
                onSelect(placeholder);
              }}
            >
              {placeholder}
            </li>
          ))
        ) : (
          <li className="px-4 py-2 text-gray-500">No placeholders found.</li>
        )}
      </ul>
    </div>
  );
}

/**
 * Modules Configuration
 * Defines the modules options for ReactQuill.
 */
const getModules = () => ({
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    [{ align: [] }],
    ['clean'],
  ],
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize'],
  },
});

/**
 * RichTextEditor Component
 * Reusable rich text editor with placeholder mention functionality.
 */
function RichTextEditor({
  value,
  onChange,
  placeholders = [],
  bounds,
  style,
}) {
  const [quillRef, setQuillRef] = useState(null);
  const [isMentionActive, setIsMentionActive] = useState(false);
  const [mentionQuery, setMentionQuery] = useState('');
  const [filteredPlaceholders, setFilteredPlaceholders] = useState([]);
  const [selectedMentionIndex, setSelectedMentionIndex] = useState(0);
  const [mentionPosition, setMentionPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (!quillRef) return;

    const handleTextChange = (delta, oldDelta, source) => {
      if (source !== 'user') return;

      let insertedText = '';
      delta.ops.forEach((op) => {
        if (op.insert) {
          insertedText += op.insert;
        }
      });

      if (insertedText.includes('[')) {
        const range = quillRef.getSelection();
        if (!range) return;

        // Get the content of the editor before the cursor position
        const textBeforeCursor = quillRef.getText(0, range.index);
        const triggerIndex = textBeforeCursor.lastIndexOf('[');

        if (triggerIndex !== -1 && range.index - triggerIndex <= 20) {
          const query = textBeforeCursor.slice(triggerIndex + 1, range.index);
          if (/^\w*$/.test(query)) {
            setIsMentionActive(true);
            setMentionQuery(query);
            const filtered = placeholders.filter((ph) => ph.toLowerCase().includes(query.toLowerCase()));
            setFilteredPlaceholders(filtered);
            setSelectedMentionIndex(0);

            // Get the correct position for the dropdown
            const bounds = quillRef.getBounds(range.index);
            setMentionPosition({
              top: bounds.bottom + 5, // Add some spacing
              left: bounds.left,
            });
            return;
          }
        }
      }

      // Deactivate mention if conditions not met
      setIsMentionActive(false);
      setMentionQuery('');
      setFilteredPlaceholders([]);
    };

    const handleKeyDown = (e) => {
      if (!isMentionActive) return;

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedMentionIndex((prev) => (prev < filteredPlaceholders.length - 1 ? prev + 1 : prev));
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedMentionIndex((prev) => (prev > 0 ? prev - 1 : 0));
      } else if (e.key === 'Enter') {
        e.preventDefault();
        if (filteredPlaceholders.length > 0) {
          selectMention(filteredPlaceholders[selectedMentionIndex]);
        }
      } else if (e.key === 'Escape') {
        e.preventDefault();
        setIsMentionActive(false);
      }
    };

    quillRef.on('text-change', handleTextChange);
    quillRef.root.addEventListener('keydown', handleKeyDown);

    // Cleanup
    return () => {
      quillRef.off('text-change', handleTextChange);
      quillRef.root.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    quillRef,
    isMentionActive,
    mentionQuery,
    filteredPlaceholders,
    selectedMentionIndex,
    placeholders,
  ]);


  const selectMention = (placeholder) => {
    const range = quillRef.getSelection();
    if (!range) return;

    const [line, offset] = quillRef.getLine(range.index);
    const text = line?.domNode?.innerText || '';
    const triggerIndex = text.lastIndexOf('[');
    if (triggerIndex === -1) return;

    const start = range.index - (text.length - triggerIndex);
    quillRef.deleteText(start, range.index - start, 'user');
    quillRef.insertText(start, `[${placeholder}]`, 'user');
    quillRef.setSelection(start + placeholder.length + 2, 0, 'user');
    setIsMentionActive(false);
    setMentionQuery('');
    setFilteredPlaceholders([]);
  };

  return (
    <div className="relative">
      <ReactQuill
        onChange={onChange}
        value={value}
        modules={getModules()}
        ref={(el) => {
          if (el) {
            setQuillRef(el.getEditor());
          }
        }}
        bounds={bounds}
        placeholder="Email body..."
        style={style}
      />
      {isMentionActive && (
        <PlaceholderMentionDropdown
          position={mentionPosition}
          query={mentionQuery}
          placeholders={filteredPlaceholders}
          selectedIndex={selectedMentionIndex}
          onSelect={selectMention}
          onQueryChange={(query) => {
            setMentionQuery(query);
            const filtered = placeholders.filter((ph) => ph.toLowerCase().includes(query.toLowerCase()));
            setFilteredPlaceholders(filtered);
            setSelectedMentionIndex(0);
          }}
        />
      )}
    </div>
  );
}

export default RichTextEditor;
