import { useEffect, useState } from 'react';
import Modal from 'components/NewModal';
import RoundButton from 'components/common/RoundButton';
import { ContentCopy, LoadingIndicator, Plus, X } from 'components/icons';
import { useFetchOffersQuery } from 'redux/offerApiSlice';
import Button from 'components/shared/NewButton';
import { formatCurrency } from 'components/utils';
import { meanBy } from 'lodash';
import OfferTable from './OfferTable';
import DeleteOfferModal, { CreateOfferModal, EditOfferModal } from './OfferActions';
import OfferAttachmentModal from './OfferAttachmentModal';

function StatItem({ label, value }) {
  return (
    <div className="px-4 text-center">
      <div className="text-lg">{value || '-'}</div>
      <div className="text-xs font-light">{label}</div>
    </div>
  );
}

function OfferHeader({ deal, onAdd, onClose, stats }) {
  const { externalOfferLink } = deal;

  const handleCopyExternalLink = () => {
    navigator.clipboard.writeText(externalOfferLink)
      .then(() => {
        alert('Link to the external offer page has been copied to the clipboard.');
      })
      .catch(err => {
        console.error('Failed to copy the link: ', err);
      });
  };

  return (
    <div className="flex justify-between p-4 border-b border-gray-300">
      <div className="mb-4 text-left text-2xl font-normal">Offers Summary</div>
      <div className="flex gap-x-4 mr-12">
        {stats.map((stat, index) => (
          <StatItem key={index} label={stat.label} value={stat.value} />
        ))}

        <div className="flex gap-x-2 items-center">
          <Button filled label="Add Offer" onClick={onAdd} className="bg-primary-600 h-4" leadingIcon={<Plus className="w-4" />} />
          <Button
            filled
            label="External Offer Page"
            onClick={() => window.open(externalOfferLink, '_blank')}
            className="bg-gray-300 text-gray-700 hover:bg-gray-400"
            leadingIcon={<ContentCopy className="w-4 h-4" />}
          />
          <RoundButton padding="p-4" onClick={handleCopyExternalLink}>
            <ContentCopy className="text-gray-700 h-4 w-4" />
          </RoundButton>
        </div>
      </div>
      <RoundButton name="outputModalButton" padding="p-2 border border-neutral-dark border-opacity-12" onClick={onClose}>
        <X className="w-8" />
      </RoundButton>
    </div>
  );
}

export default function OfferModal({ context, setShowOffers, showOffers }) {
  const [offerData, setOfferData] = useState({});
  const [modalState, setModalState] = useState({
    create: false,
    delete: false,
    edit: false,
    attachment: false,
  });

  const { data } = context;
  const { deal } = data;
  const { data: offers = [], isLoading } = useFetchOffersQuery({ dealId: deal.id }, { skip: !deal.id });

  useEffect(() => {
    if (offers.length > 0) {
      const updatedOffer = offers.find(offer => offer.id === offerData.id);
      if (updatedOffer) {
        setOfferData(updatedOffer);
      }
    }
  }, [offers, offerData.id]);

  const handleRowClick = (row) => {
    setOfferData(row.original || row);
    setModalState({ ...modalState, edit: true });
  };

  const handleDeleteOffer = (row) => {
    setOfferData(row);
    setModalState({ ...modalState, delete: true });
  };

  const handleAddNewOffer = () => {
    setOfferData({});
    setModalState({ ...modalState, create: true });
  };

  const handleCloseModal = (type) => {
    setModalState({ ...modalState, [type]: false });
  };

  const handleOfferAttachment = (row) => {
    setOfferData(row);
    setModalState({ ...modalState, attachment: true });
  };

  const stats = [
    { label: '# of Offers', value: offers.length },
    { label: 'Avg. Offer Price', value: formatCurrency(meanBy(offers, 'price'), 2) },
    { label: 'Avg. EMD', value: formatCurrency(meanBy(offers, 'earnestMoneyDeposit'), 2) },
    { label: 'Avg. Concession', value: formatCurrency(meanBy(offers, 'concession'), 2) },
  ];

  return (
    <>
      <Modal
        showHeader={false}
        size="extraLarge"
        onClose={setShowOffers}
        customHeader={<OfferHeader deal={deal} onAdd={handleAddNewOffer} stats={stats} onClose={setShowOffers} />}
        show={showOffers}
      >
        {isLoading ? <LoadingIndicator className="w-6 text-primary-500 mx-auto" /> : (
          <div className="px-6 pt-6">
            <OfferTable
              offers={offers}
              onRowClick={handleRowClick}
              onDelete={handleDeleteOffer}
              onEdit={handleRowClick}
              onAdd={handleAddNewOffer}
              onAttachment={handleOfferAttachment}
            />
          </div>
        )}

      </Modal>
      {modalState.delete && (
        <DeleteOfferModal
          offerData={offerData}
          onClose={() => handleCloseModal('delete')}
        />
      )}
      {modalState.edit && (
        <EditOfferModal
          offerData={offerData}
          onClose={() => handleCloseModal('edit')}
        />
      )}
      {modalState.create && (
        <CreateOfferModal
          offerData={offerData}
          onClose={() => handleCloseModal('create')}
        />
      )}
      {modalState.attachment && (
        <OfferAttachmentModal
          offerData={offerData}
          onClose={() => handleCloseModal('attachment')}
        />
      )}
    </>
  );
}
