import { apiSlice } from 'redux/apiSlice';
import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { DEAL_CONTEXT_TAG } from './utils';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      updateScenarios: builder.mutation({
        query: ({ dealId, scenarios }) => ({
          url: `/api/deals/${dealId}/update_scenarios`,
          method: 'PUT',
          body: snakeCaseKeys({ scenarios }),
        }),
        transformResponse: response => camelCaseKeys(response),
        invalidatesTags: [DEAL_CONTEXT_TAG, 'Model'],
      }),
    };
  },
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useUpdateScenariosMutation,
} = extendedApiSlice;
