import { useMemo } from 'react';
import { isNil, map, omit } from 'lodash';
import GoogleMap from 'components/common/GoogleMap';
import { determineTextColor } from 'components/utils';
import { getMarkerColorByOfferStatus } from './offer.utils';
import { OfferStatusLegend } from '../Shared/MapLegends';

function InfoWindow({ address, bath, bed, dealName, photo, portfolioName, sqft, stage, stageColor }) {
  const stopPropagation = (e) => e.stopPropagation();
  const backgroundColorName = stageColor;
  const textColor = determineTextColor(backgroundColorName);

  return (
    <div onClick={stopPropagation} className="z-50 cursor -top-24 relative w-60 h-auto left-10 block bg-white border rounded overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
      <div className="rounded overflow-hidden shadow-lg h-full flex flex-col">
        <div className="relative group h-40">
          <img className="w-full h-full object-cover" src={photo} alt="House" referrerPolicy="no-referrer" />
        </div>
        <div className="p-3">
          <div className="font-bold text-sm mb-0.5">{dealName}</div>
          <div className="flex space-x-2 mb-4">
            <div className="font-normal text-xs mt-1">{portfolioName}</div>
            <div className="h-auto border border-gray-200 font-bold w-auto px-2 py-1 rounded-full" style={{ backgroundColor: backgroundColorName, color: textColor }}>
              {stage}
            </div>
          </div>
          <p className="text-gray-700 text-xs">
            {`${bed} Beds | ${bath} Baths | ${sqft} Sq.Ft`}
          </p>
          <p className="text-gray-700 text-sm mt-1">{address}</p>
        </div>
      </div>
    </div>
  );
}

export default function Map({ filteredOffers }) {
  const places = useMemo(() => {
    const offersWithGeo = filteredOffers.filter(offer => {
      const hasLatLong = !isNil(offer.attributes.property.latitude) && !isNil(offer.attributes.property.latitude);
      return hasLatLong;
    });

    return map(offersWithGeo, (offer) => ({
      ...omit(offer.attributes, ['property']),
      ...offer.attributes.property,
      active: false,
      id: offer.id,
      lat: Number(offer.attributes.property?.latitude || offer.latitude),
      lng: Number(offer.attributes.property?.longitude || offer.longitude),
      showInfoWindow: false,
      subject: false,
      text: offer.attributes.property?.address || offer.address,
      markerColor: getMarkerColorByOfferStatus(offer),
    }));
  }, [filteredOffers]);

  const defaultCenter = places && {lat: places[0]?.lat, lng: places[0]?.lng};

  return (
    <div className="w-full h-full outline-none">
      {places && defaultCenter && (
        <GoogleMap
          defaultCenter={defaultCenter}
          hoveredId={null}
          infoWindow={InfoWindow}
          legendComponent={<OfferStatusLegend />}
          places={places}
        />
      )}
    </div>
  );
}
