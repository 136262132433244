import { useState } from 'react';
import { first, isNumber, last, range, sum, zipWith } from 'lodash';
import { addMonths, addYears, startOfMonth } from 'date-fns';
import CollapsibleSection from './CollapsibleSection';
import TableHeader from './TableHeader';
import { monthlyCashFlowDates } from './dcf';
import {
  calcAdditions,
  calcCashForSplits,
  calcCumulativeOutstandingEquity,
  calcLpSplits,
  calcReturnOfCapitalReturns,
  calcReturns,
} from './waterfall';
import { TR } from '../Table';
import {
  annualizeMonthlyReturns,
  irr,
} from '../finance';
import {
  arrayMax,
  dateFromString,
  formatCurrency,
  formatDate,
  formatPercentage,
  sumArrays,
} from '../utils';


const RETURN_ROWS = [
  [
    ['LP Beginning of Period', 'cashBeginning'],
    ['LP Contributions', 'additions'],
    ['LP Accruals', 'accrual'],
    ['LP Prior Distributions', 'returnOfCapitalPlusPriorDistributions'],
    ['LP Hurdle Distributions', 'distributions'],
    ['LP End of Period', 'cashEnd'],
  ],
  [
    ['LP Cash Flow', 'cashLp'],
    ['GP Cash Flow', 'cashGp'],
  ],
  [
    ['Total Remaining Cash Flow', 'cashSplits'],
  ],
];

const ANNUALIZATION_FUNC = {
  'cashBeginning': first,
  'cashEnd': last,
};

function WaterfallRow({ borderBottom, collapsed, data, hidden, indentation, irr, label, setCollapsed, years }) {
  data = data ? data.map(v => isNumber(v) ? formatCurrency(v) : v) : new Array(years.length + 1).fill(null);
  data.unshift(irr && formatPercentage(irr));
  return (
    <TR
      borderBottom={borderBottom}
      borders={[0, 1, 2]}
      collapsed={collapsed}
      collapsible={true}
      data={data}
      hidden={hidden}
      indentation={indentation}
      label={label}
      onClick={() => setCollapsed && setCollapsed(!collapsed)} />
  );
}

function ReturnsTableSection({ data, irrs, sectionLabel, years, defaultCollapsed = true }) {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const sections = RETURN_ROWS
    .map(rows => rows
      .filter(([, key]) => data.hasOwnProperty(key))
      .map(([label, key]) => {
        let irr = null;
        if (irrs && key === 'cashLp') {
          irr = irrs[0];
        } else if (irrs && key === 'cashGp') {
          irr = irrs[1];
        }
        return <WaterfallRow key={key} label={label} data={data[key]} irr={irr} format={false} />
      })
    )
    .filter(rows => rows.length > 0)
    .map((rows, index) => <CollapsibleSection key={index} collapsed={collapsed} children={rows} />);

  return (
    <>
      <tbody>
        <WaterfallRow label={sectionLabel} years={years} setCollapsed={setCollapsed} collapsed={collapsed} />
      </tbody>

      {sections}
    </>
  );
}

function formatDataForTable(data) {
  data = {...data};

  Object.entries(data).forEach(([key, value]) => {
    const annualizedValues = [
      value[0],
      // default to summing the monthly values unless specified in ANNUALIZATION_FUNC
      ...annualizeMonthlyReturns(value.slice(1), ANNUALIZATION_FUNC[key] || sum),
    ];

    data[key] = annualizedValues.map(elem => formatCurrency(elem));
  });

  return data;
}

function calcIrrs(cashLp, cashGp, dates) {
  try {
    return [
      irr(cashLp, dates),
      irr(cashGp, dates),
    ];
  } catch (err) {
    console.error(err);
    return ['n/a', 'n/a'];
  }
}

// pre-prends month 0 and annualizes rest of array
const annualizeMonthlyArray = (monthlyArray) => [monthlyArray[0]].concat(annualizeMonthlyReturns(monthlyArray.slice(1)));

export default function Waterfalls({ cashFlows, dcfParams }) {
  const [returnOfCapitalSectionCollapsed, setReturnOfCapitalSectionCollapsed] = useState(false);
  const [contributionsCollapsed, setContributionsCollapsed] = useState(false);
  const [returnOfCapitalCollapsed, setReturnOfCapitalCollapsed] = useState(false);
  const [thereafterCollapsed, setThereafterCollapsed] = useState(false);
  const [totalCollapsed, setTotalCollapsed] = useState(false);

  const years = dcfParams.ltOneYrHold ? range(1, 2) : range(1, dcfParams.holdPeriod + 1);
  const parsedClosingDate = dateFromString(dcfParams.closingDate);
  const dates = years.map(year => startOfMonth(addYears(addMonths(parsedClosingDate, 1), year)));
  const cashFlowDates = monthlyCashFlowDates(dcfParams);

  const leveredPurchase = cashFlows.acquisition.price[0] + cashFlows.acquisition.cost[0];

  const { leveredCashFlows } = cashFlows;

  const equityContribution = leveredCashFlows.slice(1).map(cf => cf < 0 ? cf : 0);
  equityContribution.unshift(leveredPurchase);
  const equityReturn = leveredCashFlows.slice(1).map(cf => cf > 0 ? cf : 0);
  equityReturn.unshift(null);

  const cumulativeOutstandingEquity = calcCumulativeOutstandingEquity(leveredCashFlows);
  const returnOfCapitalReturns = calcReturnOfCapitalReturns(leveredCashFlows, cumulativeOutstandingEquity);

  const lpShares = calcLpSplits(dcfParams.ownershipShareLp, dcfParams.hurdlePromotes);
  const initialLpSplit = lpShares[0];
  const hurdleIrr = [0, ...dcfParams.hurdleIrrs];

  const additions = calcAdditions(leveredCashFlows, initialLpSplit);
  const gpAdditions = additions.map(a => a / initialLpSplit * (1 - initialLpSplit));
  const cashSplitsInitial = arrayMax(leveredCashFlows, 0);

  let cumulativeReturnOfCapitalAndDistributions = new Array(leveredCashFlows.length).fill(0);
  let cashLp = [];
  let cashGp = [];
  const previousCashFlows = [];
  let cashSplits = cashSplitsInitial;
  const tableSectionsData = [];
  lpShares.forEach((lpShare, index) => {
    const [cashBeginning, accrual, hurdleDistribution, cashEnd] = calcReturns(
      cashSplits,
      additions,
      cumulativeReturnOfCapitalAndDistributions,
      lpShare,
      hurdleIrr[index],
      cashFlowDates,
    );

    if (index == 0) {
      cashLp = sumArrays(additions, hurdleDistribution).map(c => c * -1);
      cashGp = sumArrays(gpAdditions, hurdleDistribution.map(hd => hd / lpShare * (1 - lpShare))).map(c => c * -1);
    } else {
      cashLp = sumArrays(previousCashFlows[index - 1][0], hurdleDistribution.map(hd => hd * -1));
      cashGp = sumArrays(previousCashFlows[index - 1][1], hurdleDistribution.map(hd => -1 * hd / lpShares[index - 1] * (1 - lpShares[index - 1])));
    }
    previousCashFlows.push([cashLp, cashGp]);
    cashSplits = calcCashForSplits(cashSplitsInitial, cashLp, cashGp);
    const irrs = index > 0 && calcIrrs(cashLp, cashGp, cashFlowDates);

    const hurdleData = {
      cashBeginning: cashBeginning,
      additions: additions,
      distributions: hurdleDistribution,
      cashEnd: cashEnd,
      cashLp: cashLp,
      cashGp: cashGp,
      cashSplits: cashSplits,
      accrual: accrual,
      returnOfCapitalPlusPriorDistributions: cumulativeReturnOfCapitalAndDistributions,
    };

    const sectionLabel = index > 1 ? `Hurdle ${index} (${formatPercentage(hurdleIrr[index], 0)})` : (index === 0 ? 'Return of Capital' : `Hurdle 1 - Preferred Return (${formatPercentage(hurdleIrr[index], 0)})`);
    tableSectionsData.push({
      label: sectionLabel,
      irrs: irrs,
      data: formatDataForTable(hurdleData),
    });

    cumulativeReturnOfCapitalAndDistributions = zipWith(cumulativeReturnOfCapitalAndDistributions, hurdleDistribution, (...args) => sum(args));
  });

  const thereafterLpShare = lpShares[lpShares.length - 1];
  const thereafterGpShare = 1 - thereafterLpShare;

  const thereafterCashLp = cashSplits.map(value => value * thereafterLpShare);
  const thereafterCashGp = cashSplits.map(value => value * thereafterGpShare);

  const totalCashLp = zipWith(cashLp, thereafterCashLp, (...args) => sum(args));
  const totalCashGp = zipWith(cashGp, thereafterCashGp, (...args) => sum(args));
  const totalIrrs = calcIrrs(totalCashLp, totalCashGp, cashFlowDates);

  return (
    <table className="min-w-full divide-y divide-gray-200 bg-white">
      <thead className="bg-gray-50 border-b-2">
        <tr>
          <TableHeader border={true} />
          <TableHeader border={true} />
          <TableHeader value="0" border={true} />
          {years.map(year => <TableHeader key={year} value={year} />)}
        </tr>
        <tr>
          <TableHeader border={true} />
          <TableHeader value="IRR" border={true} />
          <TableHeader value={formatDate(parsedClosingDate)} border={true} />
          {dates.map(date => <TableHeader key={formatDate(date)} value={formatDate(date)} />)}
        </tr>
      </thead>

      <tbody>
        <WaterfallRow label="Equity Contribution" data={annualizeMonthlyArray(equityContribution)} />
        <WaterfallRow label="Equity Return" data={annualizeMonthlyArray(equityReturn)} borderBottom />
        <WaterfallRow label="Net Cash Flow" data={annualizeMonthlyArray(leveredCashFlows)} borderBottom />
        <WaterfallRow label="Return of Capital" years={years} setCollapsed={setReturnOfCapitalSectionCollapsed} collapsed={returnOfCapitalSectionCollapsed} />
      </tbody>
      <CollapsibleSection collapsed={returnOfCapitalSectionCollapsed}>
        <WaterfallRow label="Cumulative Equity Outstanding" data={[cumulativeOutstandingEquity[0], ...annualizeMonthlyReturns(cumulativeOutstandingEquity.slice(1), last)]} />
        <WaterfallRow label="Contributions" collapsed={contributionsCollapsed} setCollapsed={setContributionsCollapsed} data={annualizeMonthlyArray(equityContribution)} />
        <WaterfallRow label="LP Contributions" hidden={contributionsCollapsed} data={annualizeMonthlyArray(equityContribution).map(v => v * lpShares[0])} />
        <WaterfallRow label="GP Contributions" hidden={contributionsCollapsed} data={annualizeMonthlyArray(equityContribution).map(v => v * (1 - lpShares[0]))} />
        <WaterfallRow label="Return of Capital" collapsed={returnOfCapitalCollapsed} setCollapsed={setReturnOfCapitalCollapsed} data={annualizeMonthlyArray(returnOfCapitalReturns)} />
        <WaterfallRow label="LP Return of Capital" hidden={returnOfCapitalCollapsed} data={annualizeMonthlyReturns(returnOfCapitalReturns).map(v => v * lpShares[0])} />
        <WaterfallRow label="GP Return of Capital" hidden={returnOfCapitalCollapsed} data={annualizeMonthlyReturns(returnOfCapitalReturns).map(v => v * (1 - lpShares[0]))} />
        <WaterfallRow label="Remaining Cash Flow" format={false} data={tableSectionsData[0].data.cashSplits} />
      </CollapsibleSection>

      {tableSectionsData.slice(1).map((tableSectionData, index) => (
        <ReturnsTableSection
          key={index}
          sectionLabel={tableSectionData.label}
          years={years}
          irrs={tableSectionData.irrs}
          data={tableSectionData.data} />
      ))}

      <tbody>
        <WaterfallRow label="Thereafter" years={years} setCollapsed={setThereafterCollapsed} collapsed={thereafterCollapsed} data={[...annualizeMonthlyReturns(cashSplits)]} />
      </tbody>
      <CollapsibleSection collapsed={false}>
        <WaterfallRow label="LP Cash Flow" hidden={thereafterCollapsed} data={[thereafterCashLp[0], ...annualizeMonthlyReturns(thereafterCashLp.slice(1))]} />
        <WaterfallRow label="GP Cash Flow" hidden={thereafterCollapsed} data={[thereafterCashGp[0], ...annualizeMonthlyReturns(thereafterCashGp.slice(1))]} />
      </CollapsibleSection>

      <tbody>
        <WaterfallRow label="Total" years={years} setCollapsed={setTotalCollapsed} collapsed={totalCollapsed} />
      </tbody>
      <CollapsibleSection collapsed={false} irr={0}>
        <WaterfallRow label="LP Cash Flow" hidden={totalCollapsed} irr={totalIrrs[0]} data={[totalCashLp[0], ...annualizeMonthlyReturns(totalCashLp.slice(1))]} />
        <WaterfallRow label="GP Cash Flow" hidden={totalCollapsed} irr={totalIrrs[1]} data={[totalCashGp[0], ...annualizeMonthlyReturns(totalCashGp.slice(1))]} />
      </CollapsibleSection>
    </table>
  );
}
