import dashboardFilter from './dashboard/filters';
import deals from './deals';
import documentTemplates from './document_templates';
import global from './global';
import lease from './dashboard/lease';
import map from '../redux/mapSlice';
import model from './model';
import navigation from './deal_navigation';
import newDeal from '../redux/newDealSlice';
import rentComps from '../redux/rentCompsSlice';
import saleComps from '../redux/saleCompsSlice';
import toast from '../redux/toastSlice';

export default {
  dashboardFilter,
  deals,
  documentTemplates,
  global,
  lease,
  map,
  model,
  navigation,
  newDeal,
  rentComps,
  saleComps,
  toast,
};
