import { titleCase } from 'components/utils';
import Documents from 'components/dashboard/Documents';
import Button from 'components/shared/NewButton';
import { setShowCreateDealModal } from 'actions/deal_navigation';
import { useDispatch } from 'react-redux';
import RoundButton from '../common/RoundButton';
import { X, Plus } from '../icons';
import Modal from '../Modal';

export default function DocumentsModal({ context, setShowDocuments, showDocuments }) {
  const dispatch = useDispatch();
  const { modelData, data: { deal } } = context;

  return (
    <Modal
      fullScreen
      show={showDocuments}
      backgroundColor="bg-transparent"
      onClose={() => setShowDocuments(false)}
      showCloseAction={false}
    >
      <div className="relative w-full h-full flex items-center justify-center z-5">
        <div className="w-8/12 h-5/6 bg-white flex flex-col items-center rounded-xl">
          <div className="flex justify-between w-full h-18 px-6 pt-4">
            <div className="text-neutral-dark text-xl font-normal">
              {titleCase(modelData.model.property.address)}
            </div>
            <RoundButton padding="h-10 w-10 border border-neutral-dark border-opacity-12" onClick={() => setShowDocuments(false)}>
              <X className="h-5 w-5 mx-auto" />
            </RoundButton>
          </div>
          <div className="w-full h-full rounded-b-xl border-t">
            {!deal && (
            <div className="flex justify-center mt-12">
              <div className="text-center">
                <div className="text-xl">No Documents</div>
                <div className="text-xs text-gray-500 my-4">You must create a deal to track deal documents</div>
                <div className="flex justify-center">
                  <Button filled label="Create Deal" leadingIcon={<Plus className="w-4" />} onClick={() => dispatch(setShowCreateDealModal(true))} />
                </div>
              </div>
            </div>
            )}
            {deal && <Documents context={context} />}
          </div>
        </div>
      </div>
    </Modal>
  );
}
