import { apiSlice } from 'redux/apiSlice';
import { camelCaseKeys } from 'components/utils';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      addressAutoComplete: builder.query({
        query: ({ input, sessionToken }) => ({ url: `/api/address_autocomplete?input=${input}&sessiontoken=${sessionToken}` }),
        transformResponse: response => camelCaseKeys(response),
      }),
      parcelLookup: builder.query({
        query: ({ address, latitude, longitude }) => ({ url: `/api/parcel_lookup/${address}?latitude=${latitude}&longitude=${longitude}` }),
        transformResponse: response => camelCaseKeys(response),
      }),
      placeDetails: builder.query({
        query: ({ placeId, sessionToken }) => ({ url: `/api/place_details?place_id=${placeId}&sessiontoken=${sessionToken}` }),
        transformResponse: response => camelCaseKeys(response),
      }),
    };
  },
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useAddressAutoCompleteQuery,
  useLazyParcelLookupQuery,
  useLazyPlaceDetailsQuery,
} = extendedApiSlice;
