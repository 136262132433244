import { useSelector } from 'react-redux';
import { flow, mean } from 'lodash';
import { formatCurrency, formatInteger } from 'components/utils';

const summaryFunc = flow(
  (collection, key) => (
    collection
      .map(({ [key]: value }) => value)
      .filter(value => value !== null)
  ),
  collection => [
    collection.length ? Math.min(...collection) : null,
    collection.length ? Math.max(...collection) : null,
    collection.length ? mean(collection) : null,
  ],
);

function ShortSummaryItem({ children, label, value }) {
  if (children) return children;
  return (
    <div className="flex flex-col items-center gap-y-0.5 my-2 px-4">
      <div className="text-xl">{value ?? '-'}</div>
      <div className="text-neutral-light font-medium text-xs tracking-wide">{label}</div>
    </div>
  );
}

export default function ShortSummary({ activeRentCompSet }) {
  const { allComps, selectedRentCompIds } = useSelector(state => state.rentComps);
  const rentComps = (!allComps || !selectedRentCompIds) ? [] : allComps.filter(comp => selectedRentCompIds.includes(comp.id));

  const rentCompsWithEffectiveRent = rentComps.map(rentComp => {
    const { buildingArea, closePrice, listPrice } = rentComp;
    const effectiveRent = closePrice || listPrice;
    return {
      ...rentComp,
      effectiveRent,
      effectiveRentPsf: buildingArea && (effectiveRent / buildingArea),
    };
  });

  const [, , meanRent] = summaryFunc(rentCompsWithEffectiveRent, 'effectiveRent');

  return (
    <div className="flex flex-row justify-end flex-grow divide-x">
      <ShortSummaryItem label="Selected Comps" value={formatInteger(rentComps.length)} />
      <ShortSummaryItem label="Avg Rent" value={formatCurrency(meanRent)} />
      <ShortSummaryItem label="Underwritten Rent" value={activeRentCompSet ? formatCurrency(activeRentCompSet.compPrice) : '-'} />
    </div>
  );
}
