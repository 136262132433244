/**
 * @template T
 * @template {any[]} TArgs
 *
 * @param {(table: import('@tanstack/react-table').Table) => (table: import('@tanstack/react-table').Table, ...args: TArgs) => T} onCreate
 * @return {(table: import('@tanstack/react-table').Table, ...args: TArgs) => T}
 */
const createTableExtension = (onCreate) => {
  /** @type {WeakMap<import('@tanstack/react-table').Table, Function>}  */
  const cache = new WeakMap();

  return (table, ...args) => {
    let cached = cache.get(table);
    if (cached === undefined) {
      cached = onCreate(table);
      cache.set(table, cached);
    }

    return cached(table, ...args);
  };
};

export default createTableExtension;
