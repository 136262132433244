import { useState } from 'react';
import { FormField } from 'components/Form';
import { parseEventValue, snakeCaseKeys } from 'components/utils';
import Alert from 'components/Alert';
import Button from 'components/Button';
import { usersPasswordPath } from 'components/routes';

function EditPasswordForm({ resetPasswordToken }) {
  const [alert, setAlert] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setconfirmPassword] = useState('');

  const [isUpdating, setIsUpdating] = useState(false);

  const recoverPassword = () => {
    setAlert(null);
    if (isUpdating || (!password)) {
      return;
    }
    setIsUpdating(true);

    const csrfToken = document.querySelector('[name=csrf-token]').content;
    fetch(usersPasswordPath, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
      body: JSON.stringify(snakeCaseKeys({ resetPasswordToken, password, confirmPassword })),
    }).then(async response => {
      const { message } = await response.json();
      if (response.ok) {
        setAlert({ type: 'success', text: message });
        setPassword('');
        setconfirmPassword('');
      } else {
        setAlert({ type: 'danger', text: message });
      }
      setIsUpdating(false);
    }).catch(error => {
      console.error(error);
      setIsUpdating(false);
      setAlert({ type: 'danger', text: `Password Update Failed: ${error.message}` });
    });
  };

  return (
    <div className="w-96 p-8 bg-white rounded shadow">
      <h1 className="mb-12 text-xl font-normal">Update Password</h1>
      <FormField
        name="New Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(parseEventValue(e))}
      />
      <FormField
        className="mt-6"
        name="confirm password"
        type="password"
        value={confirmPassword}
        onChange={(e) => setconfirmPassword(parseEventValue(e))}
      />

      {alert && (
      <Alert className="mt-3" {...alert}>
        <p>
          {`${alert.text} `}
          {alert.type === 'success' && (
          <a
            href="/"
            className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            {' '}
            Login
          </a>
          )}
        </p>
      </Alert>
      )}
      <div className="mt-12 flex flex-row justify-center gap-x-4">
        <Button text="Update my password" onClick={recoverPassword} isLoading={isUpdating} />
      </div>
    </div>
  );
}

export default EditPasswordForm;
