/* eslint-disable react/no-unescaped-entities */
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useInitialFilters } from 'components/rentComps/rentComps';
import { ArrowBack, Config } from 'components/icons';
import Input from 'components/Input';
import Chip from 'components/shared/Chip';
import Button from 'components/shared/Button';
import { BATH_TYPE, BED_TYPE, BedBathFilter } from 'components/shared/filters/BedBathFilter';
import { LAYOUT } from 'components/constants';
import { compact, forEach, isEmpty, isEqual, slice, without } from 'lodash';
import { FormField } from 'components/Form';
import { dateFromString, parseEventValue } from 'components/utils';

function FilterSubSection({ children, label, helperText }) {
  return (
    <>
      {label && <div className="mt-4">{label}</div>}
      {children}
      {helperText && <div className="text-neutral-medium text-xs font-normal">{helperText}</div>}
    </>
  );
}

function AdvancedFiltersModal({ filters, setFilter, setShowAdvancedFilters, filterContext }) {
  const [filterState, setFilterState] = useState(filters);
  const initialFilters = useInitialFilters();
  const { statuses } = filterContext;

  const [minActualRent, maxActualRent] = filterState.find(({ id }) => id === 'actualRent').value;
  const [minCurrentAskingRent, maxCurrentAskingRent] = filterState.find(({ id }) => id === 'currentAskingRent').value;
  const maxDistance = filterState.find(({ id }) => id === 'distance').value[1];
  const [minNumberOfUnits, maxNumberOfUnits] = filterState.find(({ id }) => id === 'numberOfUnits').value;
  const [minEffectiveLastSeenAt, maxEffectiveLastSeenAt] = filterState.find(({ id }) => id === 'effectiveLastSeenAt').value;
  const [minDaysOnMarket, maxDaysOnMarket] = filterState.find(({ id }) => id === 'daysOnMarket').value;
  const [minYearBuilt, maxYearBuilt] = filterState.find(({ id }) => id === 'yearBuilt').value;
  const [minUnitRsf, maxUnitRsf] = filterState.find(({ id }) => id === 'unitRsf').value;
  const zipCode = filterState.find(({ id }) => id === 'zipCode').value;
  const resolvedStatuses = filterState.find(({ id }) => id === 'resolvedStatus').value;
  const [bedUseExact, ...bedFilter] = filterState.find(({ id }) => id === 'numberOfBedrooms').value;
  const [bathUseExact, ...bathFilter] = filterState.find(({ id }) => id === 'numberOfBathroomsTotal').value;

  const rangeChange = (filterIndex, filter, filterKey) => {
    let currentState = [...filterState.find(({ id }) => id === filterKey).value];
    currentState[filterIndex] = '';
    if (!isEmpty(filter)) {
      switch (filterKey) {
        case 'effectiveLastSeenAt':
          currentState[filterIndex] = dateFromString(filter);
          break;
        case 'zipCode':
          currentState = filter;
          break;
        default:
          currentState[filterIndex] = Number(filter);
      }
    }
    setFilterState(filterState.map(state => (state.id === filterKey ? { ...state, value: currentState } : state)));
  };

  const setBedBathFilterState = (filterKey, newValueFunc) => {
    const currentState = [...filterState.find(({ id }) => id === filterKey).value];
    const newValue = newValueFunc(currentState);
    setFilterState(filterState.map(state => (state.id === filterKey ? { ...state, value: newValue } : state)));
  };

  const resolvedStatusChange = (status) => {
    let currentState = [...filterState.find(({ id }) => id === 'resolvedStatus').value];
    if (currentState.includes(status)) {
      currentState = without(currentState, status);
    } else {
      currentState = [...currentState, status];
    }
    setFilterState(filterState.map(state => (state.id === 'resolvedStatus' ? { ...state, value: currentState } : state)));
  };

  const distanceChange = (value) => {
    setFilterState(filterState.map(state => (state.id === 'distance' ? { ...state, value } : state)));
  };

  const onDone = () => {
    forEach(filterState, ({ id, value }) => setFilter(id, value));
    setShowAdvancedFilters(false);
  };

  const resetAll = () => {
    forEach(filterState, ({ id }) => setFilter(id, initialFilters[id]));
    setFilterState((filterState.map(({ id }) => ({ id, value: initialFilters[id] }))));
  };

  return ReactDOM.createPortal(
    <div
      style={{ width: `${LAYOUT.rentCompAllFiltersWidth}px` }}
      className="absolute h-full ml-20 top-0 bg-white z-40 border-t border-r border-gray-200 drop-shadow-2xl shadow-xl"
    >
      <div className="flex justify-between items-center px-4 py-3 border-b h-16">
        <div className="flex items-center gap-x-4">
          <ArrowBack className="w-6 cursor-pointer" onClick={() => setShowAdvancedFilters(false)} />
          <h1 className="text-neutral-dark text-xl font-normal">Filters</h1>
        </div>
        <div className="flex items-center gap-x-6">
          <Button label="Reset All" className="text-neutral-dark font-medium text-sm" textOnly onClick={resetAll} />
          <Button label="Done" onClick={onDone} />
        </div>
      </div>

      <div
        className="p-6 overflow-y-auto"
        style={{ height: 'calc(100% - 64px)', width: `${LAYOUT.rentCompAllFiltersWidth}px` }}
      >
        <FilterSubSection label="Beds">
          <BedBathFilter
            type={BED_TYPE}
            useExact={bedUseExact}
            filter={bedFilter}
            setFilterState={(newValue) => setBedBathFilterState('numberOfBedrooms', newValue)}
          />
        </FilterSubSection>

        <FilterSubSection label="Baths">
          <BedBathFilter
            type={BATH_TYPE}
            useExact={bathUseExact}
            filter={bathFilter}
            setFilterState={(newValue) => setBedBathFilterState('numberOfBathroomsTotal', newValue)}
          />
        </FilterSubSection>

        <FilterSubSection label="Distance (mi)">
          <Input
            onChange={(e) => distanceChange([null, parseEventValue(e)])}
            type="range"
            width="w-full"
            value={maxDistance}
            min="0.1"
            max="2"
            step="0.1"
          />
          <div className="flex justify-between">
            <div>0.1 mi</div>
            <div>2 mi</div>
          </div>
        </FilterSubSection>

        <FilterSubSection label="Unit Count">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minNumberOfUnits}
              type="number"
              placeholder="No min"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(0, e.target.value, 'numberOfUnits')}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxNumberOfUnits}
              type="number"
              placeholder="No Max"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(1, e.target.value, 'numberOfUnits')}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="RSF">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minUnitRsf}
              type="number"
              placeholder="No min"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(0, e.target.value, 'unitRsf')}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxUnitRsf}
              type="number"
              placeholder="No Max"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(1, e.target.value, 'unitRsf')}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="List Price">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minCurrentAskingRent}
              type="number"
              placeholder="No min $"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(0, e.target.value, 'currentAskingRent')}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxCurrentAskingRent}
              type="number"
              placeholder="No Max $"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(1, e.target.value, 'currentAskingRent')}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Close Price">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minActualRent}
              type="number"
              placeholder="No min $"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(0, e.target.value, 'actualRent')}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxActualRent}
              type="number"
              placeholder="No Max $"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(1, e.target.value, 'actualRent')}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Zip Code" helperText="Separate multiple zip codes with a comma">
          <div className="flex">
            <FormField
              value={zipCode}
              type="text"
              placeholder="Zip code"
              className="w-160"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 "
              onChange={(e) => rangeChange(1, e.target.value, 'zipCode')}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Status">
          <div className="flex space-x-2">
            {statuses.map((status, index) => (
              <Chip
                key={index}
                leadingIcon={resolvedStatuses.includes(status)}
                label={status}
                onClick={() => resolvedStatusChange(status)}
                selected={resolvedStatuses.includes(status)}
              />
            ))}
          </div>
        </FilterSubSection>

        <FilterSubSection label="Close Date">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              type="date"
              placeholder="MM/DD/YYYY"
              value={minEffectiveLastSeenAt}
              className="w-160"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(0, e.target.value, 'effectiveLastSeenAt')}
            />
            <div className="mt-1 pl-7 w-2.5">-</div>
            <FormField
              type="date"
              placeholder="MM/DD/YYYY"
              value={maxEffectiveLastSeenAt}
              className="w-160"
              padding="ml-10 py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(1, e.target.value, 'effectiveLastSeenAt')}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Days on Market (DoM)">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minDaysOnMarket}
              type="number"
              placeholder="No min"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(0, e.target.value, 'daysOnMarket')}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxDaysOnMarket}
              type="number"
              placeholder="No Max"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(1, e.target.value, 'daysOnMarket')}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Year Built">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minYearBuilt}
              type="number"
              placeholder="No min"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(0, e.target.value, 'yearBuilt')}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxYearBuilt}
              type="number"
              placeholder="No Max"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(1, e.target.value, 'yearBuilt')}
            />
          </div>
        </FilterSubSection>
      </div>
    </div>,
    document.body,
  );
}

export default function AllFilters({ filters, setFilter, filterContext }) {
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  let activeFilters = 0;
  forEach(filters, ({ id, value }) => {
    if (['numberOfBedrooms', 'numberOfBathroomsTotal'].includes(id)) {
      if (!isEmpty(slice(value, 1))) activeFilters += 1;
    } else if (id === 'distance') {
      if (!isEqual(value, [null, 1])) activeFilters += 1;
    } else if (!isEmpty(compact(value))) activeFilters += 1;
  });

  return (
    <>
      <Chip
        label={`All Filters${activeFilters ? ` • ${activeFilters}` : ''}`}
        leadingIcon={<Config />}
        onClick={() => setShowAdvancedFilters(true)}
        preferLeadingIcon
        selected={activeFilters}
      />
      {showAdvancedFilters ? (
        <AdvancedFiltersModal
          filters={filters}
          setFilter={setFilter}
          setShowAdvancedFilters={setShowAdvancedFilters}
          showAdvancedFilters={showAdvancedFilters}
          filterContext={filterContext}
        />
      ) : null}
    </>
  );
}
