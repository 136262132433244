import { useState } from 'react';
import { identity } from 'lodash';
import Input from 'components/Input';
import { titleCase } from 'components/utils';
import Filter from './Filter';

export default function RangeFilter({ filterKey, filters, label, labelFormatter = identity, labelSuffix = '', setFilters }) {
  const [range, setRange] = useState(filters[filterKey] || []);

  const onApply = (close) => {
    setFilters({ ...filters, [filterKey]: range });
    close();
  };

  const onReset = () => {
    setRange([]);
    setFilters({ ...filters, [filterKey]: [] });
  };

  const filtered = filters[filterKey]?.[0] || filters[filterKey]?.[1];
  let formattedLabel;
  if (filtered) {
    let filteredLabel;
    if (filters[filterKey][0] && filters[filterKey][1]) {
      filteredLabel = `${labelFormatter(filters[filterKey][0])}-${labelFormatter(filters[filterKey][1])}`;
    } else if (filters[filterKey][0]) {
      filteredLabel = `${labelFormatter(filters[filterKey][0])}+`;
    } else {
      filteredLabel = `Up to ${labelFormatter(filters[filterKey][1])}`;
    }
    formattedLabel = `${filteredLabel}${labelSuffix}`;
  } else {
    formattedLabel = label || titleCase(filterKey);
  }

  return (
    <Filter
      label={formattedLabel}
      onApply={onApply}
      onReset={onReset}
      filtered={filtered}
    >
      <div className="flex justify-between items-center">
        <Input
          onChange={(e) => setRange([e.target.value, range[1]])}
          placeholder="No min"
          type="number"
          value={range[0]}
          width="w-2/5"
        />
        &mdash;
        <Input
          onChange={(e) => setRange([range[0], e.target.value])}
          placeholder="No max"
          type="number"
          value={range[1]}
          width="w-2/5"
        />
      </div>
    </Filter>
  );
}
