import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, sortBy, uniq } from 'lodash';
import { parseISO, subMonths, subWeeks } from 'date-fns';
import { useFetchAOffersQuery } from 'redux/dashboardApiSlice';
import { setMarkets, setPortfolios } from 'reducers/dashboard/filters';
import { LoadingIndicator } from 'components/icons';
import { ButtonTab, HorizontalTabs } from 'components/shared/Tabs';
import Overall from './Overall';
import { FILTERS_AND_TABS_HEIGHT, NoDataMessage } from '../Shared';
import OfferExpirations from './OfferExpirations';
import Historical from './Historical';
import Map from './Map';
import { selectFilteredOffers } from '../Shared/selectors';

const OFFER_TABS = [
  { label: 'Overall', component: Overall },
  { label: 'Offer Expirations', component: OfferExpirations },
  { label: 'Map', component: Map },
  { label: 'Historical', component: Historical },
];

export default function OfferDashboard() {
  const dispatch = useDispatch();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const ActiveComponent = OFFER_TABS[activeTabIndex].component;
  const { asOfDate: asOfDateString, activeFilters: filters } = useSelector(state => state.dashboardFilter);
  const asOfDate = parseISO(asOfDateString);
  const lastWeekDate = subWeeks(asOfDate, 1);
  const lastMonthDate = subMonths(asOfDate, 1);

  const {
    offers,
    filteredOffers,
    oneWeekAgoFilteredOffers,
    oneMonthAgoFilteredOffers,
    isLoading,
  } = useFetchAOffersQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      offers: result.data?.data,
      filteredOffers: selectFilteredOffers(result, filters, asOfDate),
      oneWeekAgoFilteredOffers: selectFilteredOffers(result, filters, lastWeekDate),
      oneMonthAgoFilteredOffers: selectFilteredOffers(result, filters, lastMonthDate),
    }),
  });

  useEffect(() => {
    if (offers) {
      const markets = sortBy(uniq(offers.map(offer => offer.attributes.property.market)));
      dispatch(setMarkets(markets));
      const portfolios = sortBy(uniq(offers.map(offer => offer.attributes.portfolioName)));
      dispatch(setPortfolios(portfolios));
    }
  }, [offers]);

  if (isLoading) {
    return <LoadingIndicator className="w-6 text-primary-500 mx-auto" />;
  }

  if (isEmpty(offers)) {
    return <NoDataMessage />;
  }

  return (
    <div className="flex flex-col mb-16 h-full">
      <HorizontalTabs>
        {OFFER_TABS.map(({ label }, index) => (
          <ButtonTab key={index} active={index === activeTabIndex} onClick={() => setActiveTabIndex(index)}>
            {label}
          </ButtonTab>
        ))}
      </HorizontalTabs>
      <div className="p-3 pb-12 overflow-y-auto" style={{ height: `calc(100vh - ${FILTERS_AND_TABS_HEIGHT}px)` }}>
        <ActiveComponent
          asOfDate={asOfDate}
          lastWeekDate={lastWeekDate}
          lastMonthDate={lastMonthDate}
          filteredOffers={filteredOffers}
          oneWeekAgoFilteredOffers={oneWeekAgoFilteredOffers}
          oneMonthAgoFilteredOffers={oneMonthAgoFilteredOffers}
        />
      </div>
    </div>
  );
}
