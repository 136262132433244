import { useEffect, useState } from 'react';
import ImportStatus from 'components/Import/NewBuild/ImportStatus';
import SettingsForm from 'components/Import/NewBuild/SettingsForm';

export default function NewBuildImportFlow() {
  const [importData, setImportData] = useState(null);
  const [csvUploadOptions, setCsvUploadOptions] = useState({
    homeBuilderId: null,
    clearSubdivisionInventory: false,
  });
  const [uploadSuccessAlert, setUploadSuccessAlert] = useState(null);

  useEffect(() => {
    setUploadSuccessAlert(null);
  }, [csvUploadOptions]);

  return (
    <div className="p-16 h-full">
      {(importData && !uploadSuccessAlert) ? (
        <ImportStatus
          importData={importData}
          setImportData={setImportData}
          csvUploadOptions={csvUploadOptions}
          setUploadSuccessAlert={setUploadSuccessAlert}
        />
      ) : (
        <SettingsForm
          csvUploadOptions={csvUploadOptions}
          setCsvUploadOptions={setCsvUploadOptions}
          setImportData={setImportData}
          uploadSuccessAlert={uploadSuccessAlert}
          setUploadSuccessAlert={setUploadSuccessAlert}
        />
      )}
    </div>
  );
}
