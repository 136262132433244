import cx from 'classnames';
import { StrictMode } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

function NavTab({ label, path }) {
  return (
    <NavLink
      to={path}
      className={cx(
        'text-label-md font-semibold text-neutral-dark',
        'content-center h-14 px-6 rounded-full',
        // :hover twice to increase CSS specificity
        'hover:hover:text-gray-700',
        'aria-[current="page"]:bg-primary-dark/8 aria-[current="page"]:text-gray-800',
      )}
    >
      {label}
    </NavLink>
  );
}

export default function OffMarketMarketplaceBase() {
  return (
    <StrictMode>
      <div className="flex h-container w-container overflow-clip bg-gray-100">
        <div className="flex flex-col gap-y-0.5 w-52 h-full py-16 px-4 flex-none">
          <NavTab label="Dashboard" path="dashboard" />
          <NavTab label="Inventory" path="inventory" />
          <NavTab label="Import" path="import" />
          <NavTab label="Offers" path="offers" />
        </div>
        <div className="flex-1 [container-type:size]">
          <Outlet />
        </div>
      </div>
    </StrictMode>
  );
}
