import { Table } from 'docx';
import { addMonths, startOfMonth } from 'date-fns';
import { chunk, mean, range, sum, take } from 'lodash';
import { annualizeMonthlyReturns } from 'components/finance';
import { dateFromString, formatCurrency, formatDate, formatPercentage, sumArrays } from 'components/utils';
import { calcBelowTheLineExpenseItemCashFlow } from 'components/dcf/expense';
import { BOLD_STYLE, DEFAULT_STYLE, makeTableRow, tableHeader, tableProperties, UNDERLINE_STYLE } from './util';

const CUSTOM_STYLE = { ...DEFAULT_STYLE, size: 14 };

/* eslint-disable no-param-reassign */
export function formatCashFlowRow(annualizedDates, data, annualizeFunction = sum) {
  if (data.length % 12 === 0) {
    // add year 0 value for consistency
    data = [0, ...data];
  }
  data = [data[0], ...annualizeMonthlyReturns(data.slice(1), data.length, annualizeFunction)];

  if (data.length <= annualizedDates.length) {
    const difference = data.length === annualizedDates.length ? 1 : annualizedDates.length - data.length;
    const placeholders = Array(difference).fill(0);
    data = [...data, ...placeholders];
  }
  return data;
}
/* eslint-enable no-param-reassign */

const getBelowTheLineExpenseItemData = (annualizedDates, expenseItem, dcfParams, effectiveGrossRevenues, grossRent) => {
  const calculatedBelowTheLineExpense = calcBelowTheLineExpenseItemCashFlow(expenseItem, dcfParams, effectiveGrossRevenues, grossRent);
  return formatCashFlowRow(annualizedDates, calculatedBelowTheLineExpense);
};

export default function buildCashFlow(cashFlows, dcfParams) {
  const { closingDate, holdPeriod, ltOneYrHold } = dcfParams;
  const months = ltOneYrHold ? (range(1, (holdPeriod + 12) + 1)) : (range(1, (holdPeriod + 1) * 12 + 1));
  const parsedClosingDate = dateFromString(closingDate);
  const dates = months.map(month => startOfMonth(addMonths(parsedClosingDate, month)));
  const annualizedDates = chunk(dates, 12).map(datesChunk => datesChunk[0]);

  const { totalOperatingExpenses } = cashFlows.expenses;
  const { belowTheLineExpenses } = cashFlows.capital;

  const cashFlowCapitalProjects = cashFlows.capital.cashFlowCapitalExpenses;
  const followOnCapitalProjects = cashFlows.capital.followOnCapitalExpenses;

  const purchaseExitRow = sumArrays(cashFlows.acquisition.price, cashFlows.sale.price);

  const acquisitionClosingCostRow = sumArrays(cashFlows.acquisition.cost, cashFlows.sale.cost);

  const originationFeeRow = cashFlows.financing.loanOriginationFees;
  const futureFundingDraws = cashFlows.financing.futureFundingMonthlyDraws;
  const acquisitionLoanPaymentRepaymentsRow = cashFlows.financing.acquisitionLoanPaymentRepayments;
  const refinancingLoanPaymentRepaymentsRowData = cashFlows.financing.refinancingLoanPaymentRepayments;
  const totalLoanDrawsRepaymentsRow = sumArrays(acquisitionLoanPaymentRepaymentsRow, refinancingLoanPaymentRepaymentsRowData, originationFeeRow, [0].concat(futureFundingDraws));
  const { interestPayments, principalPayments } = cashFlows.financing;
  const totalDebtPayments = ltOneYrHold ? take(sumArrays(interestPayments, principalPayments), holdPeriod + 1) : sumArrays(interestPayments, principalPayments);
  const totalFinancingCashFlow = sumArrays(totalLoanDrawsRepaymentsRow, [0].concat(totalDebtPayments));

  const { economicOccupancyRates, physicalOccupancyRates } = cashFlows.occupancy;

  // calculate revenues
  const { grossPotentialRents, lossToLeases, rolloverVacancies, concessions, grossRents, reimbursableExpenses, otherIncomes, grossRevenues, staticVacancies, collectionLosses, effectiveGrossRevenues } = cashFlows.revenue;

  const economicOccupancyRatesData = formatCashFlowRow(annualizedDates, economicOccupancyRates, mean);
  const physicalOccupancyRatesData = formatCashFlowRow(annualizedDates, physicalOccupancyRates, mean);
  const grossPotentialRentsData = formatCashFlowRow(annualizedDates, grossPotentialRents);
  const lossToLeasesData = formatCashFlowRow(annualizedDates, lossToLeases);
  const rolloverVacanciesData = formatCashFlowRow(annualizedDates, rolloverVacancies);
  const concessionsData = formatCashFlowRow(annualizedDates, concessions);
  const grossRentsData = formatCashFlowRow(annualizedDates, grossRents);
  const reimbursableExpensesData = formatCashFlowRow(annualizedDates, reimbursableExpenses);
  const otherIncomesData = formatCashFlowRow(annualizedDates, otherIncomes);
  const grossRevenuesData = formatCashFlowRow(annualizedDates, grossRevenues);
  const staticVacanciesData = formatCashFlowRow(annualizedDates, staticVacancies);
  const collectionLossesData = formatCashFlowRow(annualizedDates, collectionLosses);
  const effectiveGrossRevenuesData = formatCashFlowRow(annualizedDates, effectiveGrossRevenues);
  const totalOperatingExpensesData = formatCashFlowRow(annualizedDates, totalOperatingExpenses);
  const expenseRatios = effectiveGrossRevenues.map((egr, index) => totalOperatingExpenses[index] / egr);
  const expenseRatioData = formatCashFlowRow(annualizedDates, expenseRatios, mean);
  const netOperatingIncomeData = formatCashFlowRow(annualizedDates, sumArrays(effectiveGrossRevenues, totalOperatingExpenses.map(v => v * -1)));
  const belowTheLineExpensesData = formatCashFlowRow(annualizedDates, belowTheLineExpenses);
  const cashFlowCapitalProjectsData = formatCashFlowRow(annualizedDates, cashFlowCapitalProjects);
  const freeOperatingCashFlowData = formatCashFlowRow(annualizedDates, cashFlows.unleveredOperatingCashFlows);
  const purchaseExitRowData = formatCashFlowRow(annualizedDates, purchaseExitRow);
  const acquisitionClosingCostRowData = formatCashFlowRow(annualizedDates, acquisitionClosingCostRow);
  const followOnCapitalProjectsData = formatCashFlowRow(annualizedDates, followOnCapitalProjects);
  const unleveredCashFlowsData = formatCashFlowRow(annualizedDates, cashFlows.unleveredCashFlows);
  const leveredCashFlowsData = formatCashFlowRow(annualizedDates, cashFlows.leveredCashFlows);
  const totalFinancingCashFlowData = formatCashFlowRow(annualizedDates, totalFinancingCashFlow);
  const totalLoanDrawsRepaymentsRowData = formatCashFlowRow(annualizedDates, totalLoanDrawsRepaymentsRow);
  const acquisitionLoanPaymentRepaymentsRowData = formatCashFlowRow(annualizedDates, acquisitionLoanPaymentRepaymentsRow);
  const originationFeeRowData = formatCashFlowRow(annualizedDates, originationFeeRow);
  const futureFundingDrawsData = formatCashFlowRow(annualizedDates, futureFundingDraws);
  const totalDebtPaymentsData = formatCashFlowRow(annualizedDates, totalDebtPayments);
  const interestPaymentsData = formatCashFlowRow(annualizedDates, interestPayments);
  const principalPaymentsData = formatCashFlowRow(annualizedDates, principalPayments);

  return new Table({
    ...tableProperties,
    rows: [
      tableHeader('Cash Flows', holdPeriod + 6, true),
      makeTableRow('', 4, ['0', ...annualizedDates.map((date, index) => (index + 1))], null, { ...UNDERLINE_STYLE, ...BOLD_STYLE, ...CUSTOM_STYLE }),
      makeTableRow('', 4, [formatDate(parsedClosingDate, 'MM-dd-yyyy'), ...annualizedDates.map((date) => (formatDate(date, 'MM-dd-yyyy')))], null, { ...UNDERLINE_STYLE, ...BOLD_STYLE, ...CUSTOM_STYLE }),
      makeTableRow('Average Economic Occupancy %', 4, [...economicOccupancyRatesData.map(val => formatPercentage(val))], 1, CUSTOM_STYLE),
      makeTableRow('Average Physical Occupancy %', 4, [...physicalOccupancyRatesData.map(val => formatPercentage(val))], 1, CUSTOM_STYLE),
      makeTableRow('Revenue', holdPeriod, [null], 6, { ...BOLD_STYLE, ...UNDERLINE_STYLE, ...CUSTOM_STYLE }, true),
      makeTableRow('Gross Potential Rent', 4, [...grossPotentialRentsData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('  Loss to Lease', 4, [...lossToLeasesData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('  Lease-Up / Downtime Vacancy', 4, [...rolloverVacanciesData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('  Concessions', 4, [...concessionsData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('Gross Rent', 4, [...grossRentsData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('  Reimbursable Expenses', 4, [...reimbursableExpensesData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('  Other Income', 4, [...otherIncomesData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('Gross Revenue', 4, [...grossRevenuesData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('  Static Vacancy', 4, [...staticVacanciesData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('  Collection Loss', 4, [...collectionLossesData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('Effective Gross Revenue', 4, [...effectiveGrossRevenuesData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('Operating Expenses', holdPeriod, [null], 6, { ...BOLD_STYLE, ...UNDERLINE_STYLE, ...CUSTOM_STYLE }, true),
      makeTableRow('Total Operating Expenses', 4, [...totalOperatingExpensesData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('Expense Ratio', 4, [...expenseRatioData.map(val => formatPercentage(val))], 1, CUSTOM_STYLE),
      makeTableRow('Net Operating Income', 4, [...netOperatingIncomeData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('Capital and Below the Line Expenses', holdPeriod, [null], 6, { ...BOLD_STYLE, ...UNDERLINE_STYLE, ...CUSTOM_STYLE }, true),
      ...dcfParams.belowTheLineExpenseItems.map((expenseItem) => (
        makeTableRow(expenseItem.name, 4, [...getBelowTheLineExpenseItemData(annualizedDates, expenseItem, dcfParams, effectiveGrossRevenues, grossRents).map(val => formatCurrency(val))], 1, CUSTOM_STYLE)
      )),
      makeTableRow('Total Below the Line Expenses', 4, [...belowTheLineExpensesData.map(val => formatCurrency(-1 * val))], 1, CUSTOM_STYLE),
      makeTableRow('Capital Projects (Cash Flow Funded)', 4, [...cashFlowCapitalProjectsData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('Free Operating Cash Flow', 4, [...freeOperatingCashFlowData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('Purchase / Exit Price', 4, [...purchaseExitRowData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('Acquisition / Closing Costs', 4, [...acquisitionClosingCostRowData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('Capital Projects (Follow-on Funded)', 4, [...followOnCapitalProjectsData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('Unlevered Cash Flow', 4, [...unleveredCashFlowsData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('Debt Service', holdPeriod, [null], 6, { ...BOLD_STYLE, ...UNDERLINE_STYLE, ...CUSTOM_STYLE }, true),
      makeTableRow('Total Financing Cash Flows', 4, [...totalFinancingCashFlowData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('  Total Loan Draws / Repayment', 4, [...totalLoanDrawsRepaymentsRowData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('    Acquisition Loan Funding / Repayment', 4, [...acquisitionLoanPaymentRepaymentsRowData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('    Origination Fee', 4, [...originationFeeRowData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('    Future Funding', 4, [...futureFundingDrawsData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('  Total Loan Payments', 4, [...totalDebtPaymentsData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('    Interest Paid', 4, [...interestPaymentsData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('    Principal Paid', 4, [...principalPaymentsData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
      makeTableRow('  Levered Cash Flow', 4, [...leveredCashFlowsData.map(val => formatCurrency(val))], 1, CUSTOM_STYLE),
    ],
  });
}
