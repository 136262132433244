import { useMemo } from 'react';
import { sum, mean } from 'lodash';
import { addWeeks, parseISO } from 'date-fns';
import { formatCurrency, formatCurrencyAbbreviated, formatPercentage } from 'components/utils';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import AcquisitionTable from './AcquisitionTable';

const sumPurchasePrice = (deals = []) => sum(deals.map(deal => deal.attributes.purchasePrice));
const averageNetYield = (deals = []) => mean(deals.map(deal => deal.attributes.netYield));
const averagePurchasePrice = (deals = []) => mean(deals.map(deal => deal.attributes.purchasePrice));
const averageUnderwritingRent = (deals = []) => mean(deals.map(deal => deal.attributes.underwritingRent));

const closingInTwoWeekFunc = (acquisitions, asOfDate) => acquisitions.filter(({ attributes: { closingDate, closedAt } }) => {
  const isClosed = closedAt && (parseISO(closedAt) <= asOfDate);
  const closingDateTimestamp = parseISO(closingDate);
  return (closingDateTimestamp >= asOfDate) && (closingDateTimestamp <= addWeeks(asOfDate, 1)) && !isClosed;
});

function ClosingInTwoWeeks({ asOfDate, filteredAcquisitions }) {
  const filteredData = useMemo(() => closingInTwoWeekFunc(filteredAcquisitions, asOfDate), [filteredAcquisitions]);

  const metrics = [
    { label: '# Closing', value: filteredData.length, hideLookup: true },
    { label: '$ Closing', value: formatCurrencyAbbreviated(sumPurchasePrice(filteredData)), hideLookup: true },
    { label: 'Avg. Yield', value: formatPercentage(averageNetYield(filteredData)), hideLookup: true },
    { label: 'Avg. Purchase Price', value: formatCurrencyAbbreviated(averagePurchasePrice(filteredData)), hideLookup: true },
    { label: 'Avg. Rent', value: formatCurrency(averageUnderwritingRent(filteredData)), hideLookup: true },
  ];

  return (
    <div>
      <ReturnMetricBoxes metrics={metrics} />
      <div className="mt-10">
        <AcquisitionTable filteredData={filteredData} />
      </div>
    </div>
  );
}

export default ClosingInTwoWeeks;
