export const TOAST_SUCCESS = 'success';
export const TOAST_WARNING = 'warning';

export const ADD_TO_PORTFOLIO = 'ADD_TO_PORTFOLIO';
export const CREATE_DEAL = 'CREATE_DEAL';
export const CREATE_SCENARIO = 'CREATE_SCENARIO';
export const RESET_CHANGES = 'RESET_CHANGES';
export const RESET_MODEL_CHANGES = 'RESET_MODEL_CHANGES';
export const REMOVE_FROM_PIPELINE = 'REMOVE_FROM_PIPELINE';
export const REVIVE_FROM_DEAD = 'REVIVE_FROM_DEAD';
export const SAVE_MODEL_CHANGES = 'SAVE_MODEL_CHANGES';

export const TOAST_RENT_COMP_SET_DELETED = {
  type: TOAST_SUCCESS,
  message: 'Comp set deleted',
  hasTimeouts: true,
  actions: [],
};

export const TOAST_RENT_COMP_SET_SAVED = {
  type: TOAST_SUCCESS,
  message: 'Comp set saved',
  hasTimeouts: true,
  actions: [],
};

export const TOAST_RENT_COMP_SET_CREATED = {
  type: TOAST_SUCCESS,
  message: 'Comp set created',
  hasTimeouts: true,
  actions: [],
};

export const TOAST_DEAL_KILLED_WITH_REMOVE_FROM_PIPELINE = {
  type: TOAST_SUCCESS,
  message: 'Deal killed',
  hasTimeouts: true,
  actions: [
    {
      label: 'Undo',
      key: REMOVE_FROM_PIPELINE,
    },
  ],
};

export const TOAST_DEAL_KILLED_WITH_REVIVE_FROM_DEAD = {
  type: TOAST_SUCCESS,
  message: 'Deal killed',
  hasTimeouts: true,
  actions: [
    {
      label: 'Undo',
      key: REVIVE_FROM_DEAD,
    },
  ],
};

export const TOAST_DEAL_REOPENED = {
  type: TOAST_SUCCESS,
  message: 'Deal reopned',
  hasTimeouts: true,
  actions: [],
};

export const TOAST_SCENARIO_MODEL_CHANGES = ({ reset, save }) => ({
  type: TOAST_WARNING,
  message: 'Unsaved scenario changes',
  showIcon: false,
  actions: [{
    label: 'Save',
    func: save,
  }, {
    label: 'Reset',
    func: reset,
  }],
});

export const TOAST_DEFAULT_MODEL_CHANGES = ({ reset, save }) => ({
  type: TOAST_WARNING,
  message: 'Create a scenario to save changes',
  showIcon: false,
  actions: [{
    label: 'Save as new scenario',
    func: save,
  }, {
    label: 'Reset',
    func: reset,
  }],
});

export const TOAST_CREATE_MODEL_CHANGES = ({ reset, save }) => ({
  type: TOAST_WARNING,
  message: 'Create a deal to save changes',
  showIcon: false,
  actions: [{
    label: 'Create Deal',
    func: save,
  }, {
    label: 'Reset',
    func: reset,
  }],
});

export const TOAST_UNSAVED_CHANGES = ({ reset, save }) => ({
  type: TOAST_WARNING,
  message: 'You have unsaved changes',
  showIcon: false,
  actions: [{
    label: 'Save',
    func: save,
  }, {
    label: 'Reset',
    func: reset,
  }],
});

export const TOAST_CREATE_DEAL_CHANGES = ({ reset, save }) => ({
  type: TOAST_WARNING,
  message: 'Create a deal to save changes',
  showIcon: false,
  actions: [{
    label: 'Create Deal',
    func: save,
  }, {
    label: 'Reset',
    func: reset,
  }],
});

export const TOAST_OFFER_MESSAGE = (message) => ({
  type: TOAST_WARNING,
  message,
  hasTimeouts: true,
  showIcon: false,
  actions: [],
});
