import { isEmpty, isNil } from 'lodash';
import { createStaticMapUrl } from 'components/staticmap';
import { getCsrfToken } from 'utils/axios';
import { imageRunFromUrl } from './util';

const MAP_WIDTH_INCHES = 3.75;
const MAP_SIZE_WIDTH = 660;
const MAP_SIZE_HEIGHT = 410;

export default async (properties, abortSignal) => {
  const latlng = properties
    .filter(({ latitude, longitude }) => !isNil(latitude) && !isNil(longitude))
    .map(({ latitude, longitude }) => `${latitude},${longitude}`);

  if (isEmpty(latlng)) {
    // no location data
    return null;
  }

  const url = createStaticMapUrl({ width: MAP_SIZE_WIDTH, height: MAP_SIZE_HEIGHT });
  url.searchParams.set('visible', latlng.join('|'));
  // color is primary-500
  latlng.forEach(pos => url.searchParams.append('markers[]', `scale:2|color:0x0EA5E9|${pos}`));

  if (latlng.length === 1) {
    // do not set zoom if there is more than 1 property
    // so all markers will fit in map
    url.searchParams.set('zoom', '15');
  } else {
    url.searchParams.delete('zoom');
  }

  // eslint-disable-next-line no-return-await
  return await imageRunFromUrl(url.toString(), MAP_WIDTH_INCHES, MAP_SIZE_WIDTH / MAP_SIZE_HEIGHT, { signal: abortSignal, headers: { 'X-CSRF-Token': getCsrfToken() } });
};
