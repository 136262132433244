import { NavLink, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { some } from 'lodash';
import classNames from 'classnames';
import { useFetchDealContextQuery, useFetchPropertyManagementRecordQuery } from 'redux/apiSlice';
import { LAYOUT } from 'components/constants';
import { LoadingIndicator } from 'components/icons';
import CashFlows from 'components/AssetManagement/CashFlows';
import Header from 'components/AssetManagement/Header';
import Summary from 'components/AssetManagement/Summary';
import History from 'components/property/History';
import Neighborhood from 'components/property/Neighborhood';
import AssessorDetails from 'components/property/AssessorDetails';
import RentComps from 'components/property/RentComps';
import SaleComps from 'components/property/SaleComps';
import { PropertyLayoutContainer } from 'components/property/PropertyLayout';

const LEFT_TABS = [{
  label: 'Summary',
  path: 'summary',
}, {
  label: 'Cash Flows',
  path: 'cash_flows',
}, {
  label: 'Neighborhood',
  path: 'neighborhood',
}, {
  label: 'History',
  path: 'history',
}, {
  label: 'Property Details',
  path: 'property_details',
}];

const linkClassName = (active) => classNames(
  'hover:text-gray-700',
  'flex h-14 items-center',
  'tracking-wider text-xs font-semibold',
  'cursor-pointer',
  {
    'text-gray-700 pl-8 border-l-8 border-primary-500': active,
    'text-gray-400 pl-10': !active,
  },
);

function LeftTab({ label, path }) {
  return (
    <NavLink end to={path}>
      {({ isActive }) => (
        <div className={linkClassName(isActive)}>{label}</div>
      )}
    </NavLink>
  );
}

function LeftNav({ propertyManagementRecord }) {
  return (
    <div className="h-full min-w-max pr-4 bg-white border-r z-10">
      {LEFT_TABS.map((tab) => (
        <LeftTab
          key={tab.path}
          label={tab.label}
          path={`/asset_management/${propertyManagementRecord.id}/${tab.path}`}
        />
      ))}
    </div>
  );
}

function NeighborhoodContainer({ property }) {
  return (
    <PropertyLayoutContainer>
      <div className="flex flex-col bg-white">
        <Neighborhood property={property} />
      </div>
    </PropertyLayoutContainer>
  );
}

function HistoryContainer({ propertyId }) {
  return (
    <PropertyLayoutContainer>
      <div className="flex flex-col bg-white">
        <History propertyId={propertyId} />
      </div>
    </PropertyLayoutContainer>
  );
}

function AssessorDetailsContainer({ propertyId }) {
  const { data, isLoading } = useFetchDealContextQuery({ id: propertyId, type: 'properties' });

  if (isLoading) {
    return (
      <LoadingIndicator className="w-6 text-primary-500" />
    );
  }

  const { owners, parcels, properties, taxes } = data;

  return (
    <PropertyLayoutContainer>
      <div className="flex flex-col bg-white">
        <AssessorDetails owners={owners} parcels={parcels} property={properties[0]} taxes={taxes} />
      </div>
    </PropertyLayoutContainer>
  );
}

export default function PropertyDetail() {
  const { id } = useParams();
  const location = useLocation();

  const { data: propertyManagementRecord, isLoading } = useFetchPropertyManagementRecordQuery(id);

  if (isLoading) {
    return (
      <LoadingIndicator className="w-6 text-primary-500" />
    );
  }

  const {
    parcels,
    property,
    propertyId,
    units,
  } = propertyManagementRecord;

  const leftNavVisiblePaths = LEFT_TABS.map(tab => tab.path);
  const showLeftNav = some(leftNavVisiblePaths, path => location.pathname.includes(path));

  return (
    <>
      <Header property={property} propertyManagementRecord={propertyManagementRecord} />
      <div className="w-full flex flex-row" style={{ height: `calc(100vh - ${LAYOUT.assetManagementHeaderHeight + LAYOUT.assetManagementNavTabsHeight}px)` }}>
        {showLeftNav && (
          <LeftNav propertyManagementRecord={propertyManagementRecord} />
        )}
        <div className="w-full overflow-auto">
          <Routes>
            <Route path="/summary" element={<Summary propertyManagementRecord={propertyManagementRecord} />} />
            <Route path="/cash_flows" element={<CashFlows propertyManagementRecord={propertyManagementRecord} />} />
            <Route path="/neighborhood" element={<NeighborhoodContainer property={property} />} />
            <Route path="/history" element={<HistoryContainer propertyId={propertyId} />} />
            <Route path="/property_details" element={<AssessorDetailsContainer propertyId={propertyId} />} />
            <Route path="/rent_comps" element={<RentComps parcels={parcels} property={property} units={units} propertyManagementRecord={propertyManagementRecord} />} />
            <Route path="/sale_comps" element={<SaleComps property={property} propertyManagementRecord={propertyManagementRecord} />} />
          </Routes>
        </div>
      </div>
    </>
  );
}
