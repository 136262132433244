import { getMemoOptions } from '@tanstack/react-table';
import { throwingMemo } from '../table.helpers';
import createTableExtension from '../utils/createTableExtension';
import { getColumnOrderConfigurable } from './utils';

/** @type {(table: import('@tanstack/react-table').Table) => import('@tanstack/react-table').Column[]} */
const getOrderConfigurableLeafColumns = createTableExtension((table) => (
  throwingMemo(
    () => [table.getAllLeafColumns()],
    (columns) => (
      columns.filter((column) => getColumnOrderConfigurable(column) !== false)
    ),
    getMemoOptions(table.options, 'debugColumns', 'getOrderConfigurableLeafColumns'),
  )
));

export default getOrderConfigurableLeafColumns;
