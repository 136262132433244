import { createReducer } from '@reduxjs/toolkit';
import { setDocumentTemplates } from '../actions/document_templates';
import { camelCaseKeys } from '../components/utils';

const data = createReducer({}, (builder) => {
  builder
    .addCase(setDocumentTemplates, (state, { payload }) => {
      state.data = camelCaseKeys(payload);
    })
    .addDefaultCase(() => {});
});

export default data;
