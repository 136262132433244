import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import useResizeObserver from 'hooks/useResizeObserver';

const useMax = (value) => {
  const max = useRef(value ?? 0);
  max.current = Math.max(max.current, value);
  return max.current;
};

function NonDecreasingWidth({ Component, style, ...props }, ref) {
  const innerRef = useRef();
  useImperativeHandle(ref, () => innerRef.current, []);

  const [{ inlineSize: width }, resizeRefCb] = useResizeObserver({ observeInlineSize: true });
  useImperativeHandle(resizeRefCb, () => innerRef.current, []);

  const maxWidth = useMax(width);
  /** @type {import('react').CSSProperties} */
  const nonDecreasingWidthStyle = useMemo(() => ({
    ...(style ?? {}),
    minWidth: `${maxWidth}px`,
  }), [maxWidth, style]);

  return <Component {...props} ref={innerRef} style={nonDecreasingWidthStyle} />;
}

export default forwardRef(NonDecreasingWidth);
