import OffMarketOfferFilters from './OffMarketOfferFilters';
import OffMarketOfferSummaryStats from './OffMarketOfferSummaryStats';

export default function OffMarketOfferTableHeader() {
  return (
    <div className="flex justify-between items-center py-1.5 px-3">
      <OffMarketOfferFilters />
      <OffMarketOfferSummaryStats className="grow justify-center" />
    </div>
  );
}
