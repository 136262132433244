/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import cx from 'classnames';
import { Menu } from '@headlessui/react';
import { CheckboxEmpty, CheckboxFilled, FilledChevron, Search, X } from 'components/icons';
import { includes, isEmpty, merge, omit, size, sumBy, xor } from 'lodash';
import { formatCurrencyAbbreviated, formatInteger, formatPercentage } from 'components/utils';

function MultiSelectDropdown({ filters, setFilters, localFilters, setLocalFilters, name, filterKey, selectOptions }) {
  return (
    <Menu as="div">
      {({ open }) => (
        <>
          <Menu.Button
            className={cx(
              'cursor-pointer flex mt-0.5 h-10 text-xs font-medium justify-center items-center text-center rounded-lg px-3 py-1.5 border outline-neutral-light select-none hover:bg-blue-200',
              { 'bg-blue-100': !isEmpty(filters[filterKey]) || open },
            )}
          >
            { isEmpty(filters[filterKey]) ? name : (filters[filterKey]?.length === 1 ? selectOptions.find(op => op.value === filters[filterKey][0]).label : `${filters[filterKey]?.length} ${name}`)}
            <div className="h-full items-center flex space-x-2">
              {!isEmpty(filters[filterKey]) ? (
                <X
                  className="ml-4 w-4 h-4 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    setLocalFilters(omit(localFilters, [filterKey]));
                    setFilters(omit(filters, [filterKey]));
                  }}
                />
              ) : (<FilledChevron className="w-5" direction={open ? 'up' : 'down'} />)}
            </div>
          </Menu.Button>
          <Menu.Items className="absolute w-92 mt-0.5 rounded-lg bg-white border z-30 shadow-lg">
            <div
              className="w-92 flex p-4 items-center justify-between cursor-pointer text-base select-none relative font-normal text-[#1C1B1F] border-b"
              onClick={() => setLocalFilters({ ...localFilters, [filterKey]: selectOptions.length === localFilters[filterKey]?.length ? [] : selectOptions.map(({ value }) => value) })}
            >
              <span className="pr-24">
                {`All ${name}`}
              </span>
              { selectOptions.length === localFilters[filterKey]?.length ? <CheckboxFilled className="w-8" /> : <CheckboxEmpty className="w-8" /> }
            </div>
            {selectOptions.map(({ label, value }, index) => (
              <div
                key={index}
                className="w-92 h-14 flex items-center justify-between cursor-pointer text-base select-none relative py-6 px-4 font-normal text-[#1C1B1F]"
                onClick={() => setLocalFilters({ ...localFilters, [filterKey]: xor(localFilters[filterKey] || [], [value]) })}
              >
                <span className="pr-24">{label}</span>
                { includes(localFilters[filterKey], value) ? <CheckboxFilled className="w-8" /> : <CheckboxEmpty className="w-8" /> }
              </div>
            ))}
            <Menu.Item as="div" className="h-12 mt-4 p-4 flex justify-between">
              <div />
              <div className="flex h-full item-center items-end">
                <div
                  className="mx-2 px-4 py-2  font-medium text-primary-dark text-sm cursor-pointer font-medium"
                  onClick={() => {
                    setLocalFilters(omit(localFilters, [filterKey]));
                    setFilters(omit(filters, [filterKey]));
                  }}
                >
                  Reset
                </div>
                <div
                  className="flex h-10 w-20 items-center px-5 text-sm rounded-full bg-primary-dark text-white cursor-pointer font-medium"
                  onClick={() => {
                    setFilters(localFilters);
                  }}
                >
                  Apply
                </div>
              </div>
            </Menu.Item>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}

function AssetStat({ label, value }) {
  return (
    <div className="text-center px-4 flex flex-col items-center justify-evenly">
      <div className="text-lg">{value}</div>
      <div className="text-xs font-light text-center">{label}</div>
    </div>
  );
}

function Filters({ filters, setFilters, uniqueMarkets, uniqueTags, propertyManagementRecords }) {
  const [localFilters, setLocalFilters] = useState(filters);

  const currentAssetValue = sumBy(propertyManagementRecords, pmr => (pmr.estimatedValue || 0));
  const totalYield = (currentAssetValue > 0)
    ? sumBy(propertyManagementRecords, pmr => (pmr.estimatedValue || 0) * (pmr.returnMetrics.unleveredCashOnCash || 0)) / currentAssetValue
    : null;
  const stats = [{
    label: 'Properties',
    value: formatInteger(size(propertyManagementRecords)),
  }, {
    label: 'Units',
    value: formatInteger(sumBy(propertyManagementRecords, pmr => pmr.units.length)),
  }, {
    label: 'Current Asset Value',
    value: formatCurrencyAbbreviated(currentAssetValue),
  }, {
    label: 'Yield',
    value: totalYield ? formatPercentage(totalYield) : '-',
  }];

  return (
    <div className="px-20 py-2 w-full bg-white flex items-center justify-between shadow">
      <div className="flex flex-row gap-x-4">
        <MultiSelectDropdown
          filterKey="markets"
          filters={filters}
          localFilters={localFilters}
          name="Market"
          selectOptions={uniqueMarkets}
          setFilters={setFilters}
          setLocalFilters={setLocalFilters}
        />
        <MultiSelectDropdown
          filterKey="tags"
          filters={filters}
          localFilters={localFilters}
          name="Tags"
          selectOptions={uniqueTags}
          setFilters={setFilters}
          setLocalFilters={setLocalFilters}
        />
      </div>
      {propertyManagementRecords && (
        <div className="basis-1/3 justify-center flex flex-row">
          {stats.map(stat => <AssetStat key={stat.label} label={stat.label} value={stat.value} />)}
        </div>
      )}
      <div className="relative flex w-96">
        <span className="z-20 font-normal absolute text-center text-slate-300 absolute rounded text-base items-center justify-center w-8 pl-3 py-3.5">
          <Search className="text-black w-6 h-6" />
        </span>
        <input
          value={filters.name || ''}
          onChange={(e) => setFilters(merge({}, filters, { name: e.target.value }))}
          type="text"
          placeholder="Search for a property"
          className="text-base px-3 py-3.5 relative rounded text-sm outline-none focus:outline-none border border-slate-300 shadow-sm w-full pl-10"
        />
      </div>
    </div>
  );
}

export default Filters;
