import { getMemoOptions } from '@tanstack/react-table';
import { throwingMemo } from '../table.helpers';
import createTableExtension from '../utils/createTableExtension';
import { getColumnOrderConfigurable, isColumnOrderDraggable } from './utils';

/** @type {(table: import('@tanstack/react-table').Table) => [start: number, end: number]}  */
const getOrderDraggableRange = createTableExtension((table) => (
  throwingMemo(
    // use flat instead of leaf columns for performance. flat columns are unordered
    () => [table.getAllFlatColumns()],
    (columns) => {
      let start = 0;
      let end = columns.length - 1;

      const configurableLeafColumns = columns.filter((column) => (
        column.columns.length === 0 && getColumnOrderConfigurable(column) !== false
      ));

      if (!isColumnOrderDraggable(configurableLeafColumns[0])) {
        start = configurableLeafColumns.findIndex((col) => isColumnOrderDraggable(col));
      }

      if (!isColumnOrderDraggable(configurableLeafColumns.at(-1))) {
        end = configurableLeafColumns.findLastIndex((col) => isColumnOrderDraggable(col));
      }

      return Object.freeze([start, end]);
    },
    getMemoOptions(table.options, 'debugColumns', 'getOrderDraggableRange'),
  )
));

export default getOrderDraggableRange;
