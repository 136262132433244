/* eslint-disable no-unused-expressions */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBlocker } from 'react-router-dom';
import { setActiveRentCompSetId, setBaseSelectedRentComps, setSelectedRentCompIds } from 'redux/rentCompsSlice';
import { cloneDeep, isEqual, merge, sortBy } from 'lodash';
import {
  useCopyRentCompSetMutation,
  useUpdateRentCompSetMutation,
} from 'redux/apiSlice';
import { clearToast, showToast } from 'redux/toastSlice';
import { setShowCreateDealModal, showSaveChangesModal } from 'actions/deal_navigation';
import { rentCompSetsInitialFilters, useInitialFilters } from 'components/rentComps/rentComps';
import CompSetSelector from 'components/rentComps/CompSetSelector';
import RentCompTable from 'components/rentComps/RentCompTable';
import { LAYOUT } from 'components/constants';
import { TOAST_CREATE_DEAL_CHANGES, TOAST_UNSAVED_CHANGES } from 'components/toast';
import RentCompsMap from 'components/rentComps/RentCompsMap';
import CompInfoModal from 'components/rentComps/CompInfoModal';
import CompPhotoModal from 'components/rentComps/CompPhotoModal';
import MapModal from 'components/property/components/MapModal';

export default function RentComps(props) {
  const {
    deal,
    portfolio,
    property,
    propertyManagementRecord,
  } = props;
  const dispatch = useDispatch();
  const { activeRentCompSetId, baseSelectedRentComps, selectedRentCompIds } = useSelector(state => state.rentComps);

  const [showPhoto, setShowPhoto] = useState(null);
  const [showInfo, setShowInfo] = useState(null);
  const [showMapModal, setShowMapModal] = useState(false);
  const [minimizeMap, setMinimizeMap] = useState(false);
  const [updateRentCompSetMutation] = useUpdateRentCompSetMutation();
  const [copyRentCompSetMutation] = useCopyRentCompSetMutation();

  const initialFilters = useInitialFilters(property);
  const defaultFilters = useMemo(() => merge({}, cloneDeep(initialFilters), portfolio?.rentCompFilters || {}), [initialFilters, portfolio]);
  const [columnFilters, setColumnFilters] = useState(rentCompSetsInitialFilters(defaultFilters, property));
  const setFilter = (id, value) => {
    setColumnFilters(prevColumnFilters => prevColumnFilters.map(filter => (filter.id === id ? { id, value } : filter)));
  };


  const pendingChanges = useMemo(
    () => baseSelectedRentComps && selectedRentCompIds && !isEqual(sortBy(baseSelectedRentComps), sortBy(selectedRentCompIds)),
    [baseSelectedRentComps, selectedRentCompIds],
  );

  const resetSelected = useCallback(() => {
    dispatch(setSelectedRentCompIds(baseSelectedRentComps));
  }, [dispatch, baseSelectedRentComps]);

  useEffect(() => {
    if (pendingChanges) {
      if (activeRentCompSetId) {
        const onSave = async () => {
          // TODO: error handling
          await updateRentCompSetMutation({
            id: activeRentCompSetId,
            selectedIds: selectedRentCompIds,
          });
          dispatch(setBaseSelectedRentComps(selectedRentCompIds));
        };
        dispatch(showToast(TOAST_UNSAVED_CHANGES({ reset: resetSelected, save: onSave })));
      } else if (deal || propertyManagementRecord) {
        const onSave = async () => {
          // TODO: error handling
          const copiedRentCompSet = await copyRentCompSetMutation({
            id: activeRentCompSetId,
            dealId: deal?.id,
            propertyId: property.id,
            propertyManagementRecordId: propertyManagementRecord?.id || null,
            selectedIds: selectedRentCompIds,
          }).unwrap();
          dispatch(setActiveRentCompSetId(copiedRentCompSet.data.attributes.id));
        };
        dispatch(showToast(TOAST_UNSAVED_CHANGES({ reset: resetSelected, save: onSave })));
      } else {
        const onSave = () => dispatch(setShowCreateDealModal(true));
        dispatch(showToast(TOAST_CREATE_DEAL_CHANGES({ reset: resetSelected, save: onSave })));
      }
    } else {
      dispatch(clearToast());
    }
  }, [activeRentCompSetId, pendingChanges, selectedRentCompIds]);

  const {
    state: blockerState,
    proceed,
    reset,
  } = useBlocker(pendingChanges);

  useEffect(() => {
    if (blockerState === 'blocked') {
      const onCancel = () => reset();
      const onDoNotSave = () => {
        resetSelected();
        dispatch(clearToast());
        proceed();
      };
      const onSave = async () => {
        if (activeRentCompSetId) {
          await updateRentCompSetMutation({
            id: activeRentCompSetId,
            selectedIds: selectedRentCompIds,
          });
          proceed();
        } else if (deal || propertyManagementRecord) {
          await copyRentCompSetMutation({
            id: activeRentCompSetId,
            dealId: deal?.id,
            propertyId: property.id,
            propertyManagementRecordId: propertyManagementRecord?.id || null,
            selectedIds: selectedRentCompIds,
          });
          proceed();
        } else {
          reset();
          dispatch(setShowCreateDealModal(true));
        }
      };
      dispatch(showSaveChangesModal(true, onCancel, onDoNotSave, onSave));
    }
  }, [blockerState]);

  const contentRef = useRef();
  const setDrawingFiltered = (shapes) => { setFilter('geo', shapes); };

  return (
    <>
      <div className="h-full" style={{ width: `calc(100vw - ${LAYOUT.rightNavWidth + LAYOUT.sidebarWidth}px)` }}>
        <div
          ref={contentRef}
          className="flex w-full"
          style={{ height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)` }}
        >
          <div className={minimizeMap ? 'w-full' : 'w-3/5'}>
            <CompSetSelector
              {...props}
              minimizeMap={minimizeMap}
              setMinimizeMap={setMinimizeMap}
            />
            <RentCompTable
              columnFilters={columnFilters}
              contentRef={contentRef}
              setColumnFilters={setColumnFilters}
              setShowPhoto={setShowPhoto}
              setShowInfo={setShowInfo}
              setFilter={setFilter}
              {...props}
            />
          </div>
          {!minimizeMap && (
            <div className="w-2/5">
              <div className="w-full h-full">
                <RentCompsMap
                  property={property}
                  setShowMapModal={setShowMapModal}
                  setMinimizeMap={setMinimizeMap}
                  setDrawingFiltered={setDrawingFiltered}
                />
                {showMapModal && (
                  <MapModal
                    showMapModal={showMapModal}
                    setShowMapModal={setShowMapModal}
                    type="Rent Comps"
                    property={property}
                    setDrawingFiltered={setDrawingFiltered}
                  >
                    <RentCompsMap expanded property={property} />
                  </MapModal>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {showPhoto && <CompPhotoModal comp={showPhoto} dismiss={() => setShowPhoto(null)} />}
      {showInfo && <CompInfoModal comp={showInfo} dismiss={() => setShowInfo(null)} isLease />}
    </>
  );
}

export function EmbeddedRentComps({ context }) {
  const { data, modelData } = context;
  const { deal, homeModel, listing, parcels, property } = data;

  return (
    <RentComps
      deal={deal}
      homeModel={homeModel}
      listing={listing}
      modelData={modelData}
      parcel={parcels[0]}
      portfolio={modelData.model.dcfParams.portfolio}
      property={property}
      units={modelData.model.dcfParams.units}
    />
  );
}
