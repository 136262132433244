export default function Statistic({ label, value, secondaryValue, secondary = false }) {
  return (
    <div className={`text-center shadow rounded ${secondary ? 'bg-secondary-500' : 'bg-tertiary'}`}>
      <div className="text-white text-xs">
        <div className="px-2">{label}</div>
        <div className={`bg-white px-7 py-3 pb-${secondaryValue ? 0 : 3}`}>
          <div className={`text-lg ${secondary ? 'text-secondary-500' : 'text-tertiary'}`}>
            {value}
          </div>
        </div>
      </div>
      {secondaryValue && (
        <div className={`bg-white text-sm ${secondary ? 'text-secondary-500' : 'text-tertiary'} pb-3`}>
          {secondaryValue}
        </div>
      )}
    </div>
  );
}
