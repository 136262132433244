import { rentCompsPath } from '../routes';

const IN_PLACE_RENT_SOURCE = {
  UNIT_LEVEL: 'unit_level',
  UNIT_TYPE: 'unit_type',
  FINANCIALS: 'financials',
  COMPS: 'comps',
  DEFAULT: 'default',
};

export const inPlaceRentTooltip = (inPlaceRentSource, property) => {
  if (inPlaceRentSource == IN_PLACE_RENT_SOURCE.UNIT_LEVEL) {
    return 'Default rent values based on MLS unit-level rent roll';
  } else if (inPlaceRentSource == IN_PLACE_RENT_SOURCE.UNIT_TYPE) {
    return 'Default rent values based on MLS rent roll';
  } else if (inPlaceRentSource == IN_PLACE_RENT_SOURCE.FINANCIALS) {
    return 'Rent calculated by dividing MLS gross income by number of units';
  } else if (inPlaceRentSource == IN_PLACE_RENT_SOURCE.COMPS) {
    const text = "Default rent values calculated from rent comps\n\n";
    return (
      <span>{text}<a className="text-tertiary underline" href={rentCompsPath(property)} target="_blank">View Comps</a></span>
    );
  } else if (inPlaceRentSource == IN_PLACE_RENT_SOURCE.DEFAULT) {
    return 'No rent information available, so using default value';
  } else {
    throw 'Unsupported inPlaceRentSource';
  }
};

export const turnoverCostTooltip = (dcfParams) => {
  if (dcfParams.expenseMethod === 'expense_ratio') {
    return 'Turnover cost is not available when expenses are calculated by expense ratio'
  } else {
    return null;
  }
}
