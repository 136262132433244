import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearDealsModal } from 'actions/deals';
import { useUpdateDealMutation } from 'redux/apiSlice';
import Modal from 'components/Modal';
import Alert from 'components/Alert';
import Button from 'components/shared/NewButton';
import { parseEventValue } from 'components/utils';
import { TransactionInputField } from './EditTransactionInfo';

const cleanTransactionInfo = (info) => Object.fromEntries(
  Object.entries(info).map(([key, { value }]) => [key, value]),
);

export default function EditTransactionInfoModal({ deal, header, transactionFieldName }) {
  const { transactionInfo } = deal;
  const [localTransactionInfo, setLocalTransactionInfo] = useState(cleanTransactionInfo(transactionInfo));
  const dispatch = useDispatch();
  const clearModal = () => dispatch(clearDealsModal());
  const [updateDealMutation, { error, isError, isLoading: isSaving }] = useUpdateDealMutation();

  const onSubmit = async () => {
    const response = await updateDealMutation({ id: deal.id, transactionType: deal.transactionType, showPipelineAttributes: true, ...localTransactionInfo });

    if (response.error) {
      console.error(response.error);
    } else {
      clearModal();
    }
  };
  const onChange = event => {
    const fieldName = event.target.name;
    const newVal = parseEventValue(event);
    setLocalTransactionInfo({
      ...localTransactionInfo,
      [fieldName]: newVal,
    });
  };

  return (
    <Modal show title={`Edit ${header}`} onClose={clearModal}>
      <div className="w-96">
        <TransactionInputField
          name={transactionFieldName}
          onChange={onChange}
          localTransactionInfo={localTransactionInfo}
          type={transactionInfo[transactionFieldName].type}
        />
        {isError && <Alert className="mt-6" type="danger" text={error} />}
        <div className="mt-8 flex justify-around">
          <Button filled isLoading={isSaving} label="Update" onClick={onSubmit} />
          <Button outlined label="Cancel" onClick={clearModal} />
        </div>
      </div>
    </Modal>
  );
}
