import { useEffect, useState } from 'react';
import Input from 'components/Input';
import { isEmpty, isNil, isNull, isString } from 'lodash';
import { formatRangeInput } from 'components/utils';

export default function MinMaxSelector({range, resetRangeRef, onChange}) {
  const [from, setFrom] = useState(isNull(range[0]) ? '' : range[0]);
  const [to, setTo] = useState(isNull(range[1]) ? '' : range[1]);

  useEffect(() => {
    resetRangeRef.current = resetRange
  }, [])

  const resetRange = () => {
    setFrom()
    setTo()
  }

  const handleBlur = (e) => {
    if (isNil(from) && isNil(to)) {
      onChange(null, null);
      return;
    };

    onChange(formatRangeInput(from), formatRangeInput(to));
  };

  return (
    <div className="flex flex-row justify-between items-center">
        <Input value={from} className="text-xs" type="number" name="fromInput" width="w-20" onChange={e => setFrom(e.target.value)} onBlur={handleBlur} />
        <span>-</span>
        <Input value={to} className="text-xs" type="number" name="toInput" width="w-20" onChange={e => setTo(e.target.value)} onBlur={handleBlur} />
    </div>
  );
}
