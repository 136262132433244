import { AlignmentType, Paragraph, Table, TableCell, TableRow, TextRun, UnderlineType } from 'docx';
import { range } from 'lodash';
import { annualizeMonthlyReturns } from '../../finance';
import { formatPercentage } from '../../utils';
import { cellMargin, tableCellRightBorder, tableCellTopBorder, tableHeader, tableProperties } from './util';

const YEAR_ROW_STYLE = {
  bold: true,
  italics: true,
  underline: { type: UnderlineType.SINGLE },
};

// size unit is in 1/2 pt
// see http://officeopenxml.com/WPtextFormatting.php
const DEFAULT_CELL_STYLE = { size: 18 };

const calcGrowthRates = (data) => (
  data.map((curr, index, array) => (index === 0 ? '-' : formatPercentage((curr - array[index - 1]) / array[index - 1])))
);

const makeTableRow = ({ rowHeader, holdPeriod, cells, rowHeaderStyle = {}, cellStyles = [] }) => (
  new TableRow({
    children: [
      new TableCell({
        borders: { ...tableCellTopBorder, ...tableCellRightBorder },
        margins: cellMargin,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: rowHeader,
                ...DEFAULT_CELL_STYLE,
                ...rowHeaderStyle,
              }),
            ],
            alignment: AlignmentType.RIGHT,
            spacing: { after: 100 },
          })],
      }),
      ...range(0, holdPeriod + 1).map((index) => (
        new TableCell({
          borders: { ...tableCellTopBorder, ...(index + 1 === holdPeriod + 1 ? null : tableCellRightBorder) },
          margins: cellMargin,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: cells[index],
                  ...DEFAULT_CELL_STYLE,
                  ...cellStyles[index],
                }),
              ],
              alignment: AlignmentType.RIGHT,
              spacing: { after: 100 },
            })],
        }))),
    ],
  })
);

export default function buildGrowthRates(dcfParams, cashFlows) {
  const { holdPeriod, rentGrowthRates } = dcfParams;
  const {
    revenue: { grossRents },
    expenses: {
      controllableExpenses,
      nonControllableExpenses,
      totalOperatingExpenses,
    },
  } = cashFlows;

  return new Table({
    ...tableProperties,
    rows: [
      tableHeader('Growth Rates', holdPeriod + 2, true),
      makeTableRow({
        holdPeriod,
        rowHeader: 'Year',
        rowHeaderStyle: YEAR_ROW_STYLE,
        cells: range(1, holdPeriod + 2),
        cellStyles: new Array(holdPeriod + 2).fill(YEAR_ROW_STYLE),
      }),
      makeTableRow({
        holdPeriod,
        rowHeader: 'Market Rent',
        cells: rentGrowthRates.map(rate => formatPercentage(rate)),
      }),
      makeTableRow({
        holdPeriod,
        rowHeader: 'Avg Gross Rent',
        cells: calcGrowthRates(annualizeMonthlyReturns(grossRents)),
      }),
      makeTableRow({
        holdPeriod,
        rowHeader: 'Avg Controllable Expenses',
        cells: calcGrowthRates(annualizeMonthlyReturns(controllableExpenses)),
      }),
      makeTableRow({
        holdPeriod,
        rowHeader: 'Avg Non-Controllable Expenses',
        cells: calcGrowthRates(annualizeMonthlyReturns(nonControllableExpenses)),
      }),
      makeTableRow({
        holdPeriod,
        rowHeader: 'Avg Expenses',
        cells: calcGrowthRates(annualizeMonthlyReturns(totalOperatingExpenses)),
      }),
    ],
  });
}
