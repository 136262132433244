import Alert from 'components/shared/Alert';

export default function Alerts() {
  const testAction = () => {
    alert('Alert: Action');
  };
  const testClose = () => {
    alert('Alert: Close');
  };

  return (
    <div className="grid grid-cols-2">
      <div className="flex flex-col">
        <Alert inline text1="Simple Alert" closeable={false} />
        <Alert inline text1="Alert with action" closeable={false} action={testAction} />
        <Alert inline text1="Two-line alert" text2="without action" closeable={false} />
        <Alert inline text1="Two-line alert" text2="with action" action={testAction} closeable={false} />
      </div>
      <div className="relative flex flex-col">
        <Alert inline text1="Alert with close" closeable onClose={testClose} />
        <Alert inline text1="Alert with action and close" closeable action={testAction} onClose={testClose} />
        <Alert inline text1="Two-line alert" text2="with close" closeable onClose={testClose} />
        <Alert inline text1="Two-line alert" text2="with action and close" closeable onClose={testClose} action={testAction} />
      </div>
      <Alert text1="Fixed two-line alert" text2="with action and close" closeable onClose={testClose} action={testAction} />
    </div>
  );
}
