import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, sortBy, uniq } from 'lodash';
import { parseISO, subMonths, subWeeks } from 'date-fns';
import { useFetchDispositionsQuery } from 'redux/dashboardApiSlice';
import { setMarkets, setPortfolios } from 'reducers/dashboard/filters';
import { LoadingIndicator } from 'components/icons';
import { ButtonTab, HorizontalTabs } from 'components/shared/Tabs';
import ClosingInTwoWeeks from './ClosingInTwoWeeks';
import Historical from './Historical';
import InContract from './InContract';
import LongDOM from './LongDOM';
import Map from './Map';
import Overall from './Overall';
import { NoDataMessage } from '../Shared';
import { selectFilteredDispositions } from '../Shared/selectors';

const TABS = [
  { label: 'Overall', component: Overall },
  { label: 'In Contract', component: InContract },
  { label: 'Long DOM', component: LongDOM },
  { label: 'Closing - 2 Wks', component: ClosingInTwoWeeks },
  { label: 'Map', component: Map },
  { label: 'Historical', component: Historical },
];
const FILTERS_AND_TABS_HEIGHT = 24 + 38 + 49 + 16 + 44;

export default function DispositionDashboard() {
  const dispatch = useDispatch();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const ActiveComponent = TABS[activeTabIndex].component;
  const { asOfDate: asOfDateString, activeFilters: filters } = useSelector(state => state.dashboardFilter);
  const asOfDate = parseISO(asOfDateString);
  const lastWeekDate = subWeeks(asOfDate, 1);
  const lastMonthDate = subMonths(asOfDate, 1);

  const {
    dispositions,
    filteredDispositions,
    oneWeekAgoFilteredDispositions,
    oneMonthAgoFilteredDispositions,
    isLoading,
  } = useFetchDispositionsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      dispositions: result.data?.data,
      filteredDispositions: selectFilteredDispositions(result, filters, asOfDate),
      oneWeekAgoFilteredDispositions: selectFilteredDispositions(result, filters, lastWeekDate),
      oneMonthAgoFilteredDispositions: selectFilteredDispositions(result, filters, lastMonthDate),
    }),
  });

  useEffect(() => {
    if (dispositions) {
      const markets = sortBy(uniq(dispositions.map(acquisition => acquisition.attributes.property.market)));
      dispatch(setMarkets(markets));
      const portfolios = sortBy(uniq(dispositions.map(acquisition => acquisition.attributes.portfolioName)));
      dispatch(setPortfolios(portfolios));
    }
  }, [dispositions]);

  if (isLoading) {
    return <LoadingIndicator className="mt-16 h-12" />;
  }

  if (isEmpty(dispositions)) {
    return <NoDataMessage />;
  }

  return (
    <div className="flex flex-col mb-16 h-full">
      <HorizontalTabs>
        {TABS.map(({ label }, index) => (
          <ButtonTab key={index} active={index === activeTabIndex} onClick={() => setActiveTabIndex(index)}>
            {label}
          </ButtonTab>
        ))}
      </HorizontalTabs>
      <div className="p-3 pb-12 overflow-y-auto" style={{ height: `calc(100vh - ${FILTERS_AND_TABS_HEIGHT}px)` }}>
        <ActiveComponent
          asOfDate={asOfDate}
          lastWeekDate={lastWeekDate}
          lastMonthDate={lastMonthDate}
          filteredDispositions={filteredDispositions}
          oneWeekAgoFilteredDispositions={oneWeekAgoFilteredDispositions}
          oneMonthAgoFilteredDispositions={oneMonthAgoFilteredDispositions}
        />
      </div>
    </div>
  );
}
