import { useCallback, useId, useMemo } from 'react';
import { useOnApply, useOnClear, useOnReset } from './filterHooks';

const clearBtnSubmitValue = 'clear';
export const useFilterForm = (callback, { formId: formIdProp } = {}) => {
  const id = useId();

  const onApply = useOnApply();
  const onReset = useOnReset();
  const onClear = useOnClear();

  /** @type {import('react').EventHandler<import('react').SyntheticEvent<HTMLFormElement, SubmitEvent>>} */
  const onFormSubmit = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    const submitter = evt.nativeEvent.submitter?.value;
    if (submitter === clearBtnSubmitValue) {
      onClear();
    } else {
      onApply();
    }

    callback?.();
  }, [callback, onApply, onClear]);

  /** @type {import('react').FormEventHandler<HTMLFormElement>} */
  const onFormReset = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    onReset();
    callback?.();
  }, [callback, onReset]);

  return useMemo(() => {
    const generatedFormId = `${id}form`;
    const formId = formIdProp ?? generatedFormId;

    /** @type {import('react').ButtonHTMLAttributes<HTMLButtonElement>} */
    const applyButtonProps = Object.freeze({ form: formId, type: 'submit', value: 'submit' });
    /** @type {import('react').ButtonHTMLAttributes<HTMLButtonElement>} */
    const resetButtonProps = Object.freeze({ form: formId, type: 'reset' });
    /** @type {import('react').ButtonHTMLAttributes<HTMLButtonElement>} */
    const clearButtonProps = Object.freeze({ form: formId, type: 'submit', value: clearBtnSubmitValue });

    /** @type {import('react').FormHTMLAttributes<HTMLFormElement>} */
    const formProps = Object.freeze({ id: formId, onSubmit: onFormSubmit, onReset: onFormReset });

    return {
      formProps,
      applyButtonProps,
      resetButtonProps,
      clearButtonProps,
      onFormSubmit,
      onFormReset,
    };
  }, [formIdProp, id, onFormReset, onFormSubmit]);
};
