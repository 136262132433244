import { closestCenter, DndContext, DragOverlay, useDndMonitor } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Dialog } from '@headlessui/react';
import { nanoid } from '@reduxjs/toolkit';
import cx from 'classnames';
import { noop, sortBy } from 'lodash';
import { forwardRef, startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import FileUploadArea from 'components/FileUploadArea';
import { DragIndicator, LoadingIndicator, Plus, Trash, X } from 'components/icons';
import Button from 'components/shared/NewButton';
import { useFilteredSelectedRows } from 'components/shared/Table/DataTableContext';
import { fileSize, stopEventPropagation } from 'components/utils';
import { useFetchImageQuery } from '../../../redux/imagesApiSlice';
import {
  useCreateHomeModelImagesMutation,
  useDeleteHomeModelImageMutation,
  useFetchHomeModelImagesQuery,
  useInvalidateHomeModelImages,
  useUpdateHomeModelImagesMutation,
} from '../../../redux/homeModelImagesApiSlice';

// eslint-disable-next-line prefer-arrow-callback -- use function to preserve function name
const Row = forwardRef(function Row({ className, children, ...props }, ref) {
  return (
    <li
      {...props}
      ref={ref}
      className={cx(
        'group/row grid col-span-full grid-cols-subgrid grid-flow-col items-center gap-x-1 pl-0.5 pr-1 py-1 border rounded select-none',
        className,
      )}
    >
      {children}
    </li>
  );
});

function Photo({ image: { id, url, imageProcessing }, onProcessingComplete }) {
  const [isPolling, setIsPolling] = useState(imageProcessing);
  const { currentData } = useFetchImageQuery({ id }, {
    skip: !isPolling,
    // refetch every 10s
    pollingInterval: 10_000,
  });

  useEffect(() => {
    if (currentData?.imageProcessing === false) {
      // stop polling once image processing is complete
      setIsPolling(false);
      onProcessingComplete?.();
    }
  }, [currentData?.imageProcessing, onProcessingComplete]);

  const isProcessing = isPolling ? (currentData?.imageProcessing ?? imageProcessing) : false;

  return (
    <>
      <div className={cx('size-20 rounded overflow-clip', isProcessing && 'animate-tw-pulse bg-gray-200')}>
        {!isProcessing && (
          <img
            src={url}
            alt=""
            className="size-full object-cover object-center bg-white"
          />
        )}
      </div>
      <div className="text-neutral-dark text-label-lg">
        {isProcessing && 'Processing…'}
      </div>
    </>
  );
}

function HomeModelImage({ image: { id, homeModelId, image } }) {
  // invalidate cache to re-fetch home models table with updated displayable image count
  const invalidateHomeModelImages = useInvalidateHomeModelImages();

  const [triggerDelete, { isLoading }] = useDeleteHomeModelImageMutation();
  const onDelete = useCallback(() => {
    triggerDelete({ id, homeModelId });
  }, [id, homeModelId, triggerDelete]);

  return (
    <>
      <DragIndicator className="h-6 w-auto fill-current text-neutral-dark/75 group-aria-disabled/row:invisible group-aria-disabled/row:w-0 overflow-clip" />
      <Photo image={image} onProcessingComplete={invalidateHomeModelImages} />
      <div className="justify-self-end">
        <DeleteImageButton id={id} onDelete={onDelete} isLoading={isLoading} />
      </div>
    </>
  );
}

function DeleteImageButton({ onDelete, isLoading }) {
  const removeListenerRef = useRef();
  useEffect(() => (() => { removeListenerRef.current?.(); }), []);

  const [confirm, setConfirm] = useState(false);
  const onDeleteClick = useCallback((evt) => {
    setConfirm(true);

    const deleteBtn = evt.currentTarget;
    const listenerAbortController = new AbortController();
    removeListenerRef.current = () => { listenerAbortController.abort(); };

    // register a listener to cancel deletion when clicked outside of this button
    document.addEventListener('pointerdown', (pointerEvt) => {
      if (!pointerEvt.composedPath()
        .includes(deleteBtn)) {
        setConfirm(false);
        // note that the user could pointerdown within the button but pointerup elsewhere, which doesn't trigger onClick
        listenerAbortController.abort();
      }
    }, { capture: true, passive: true, signal: listenerAbortController.signal });
  }, []);

  return (
    <Button
      filled={confirm}
      outlined={!confirm}
      danger
      small
      leadingIcon={<Trash className="size-full" />}
      label={confirm ? 'Confirm' : 'Delete'}
      isLoading={isLoading}
      onClick={confirm ? onDelete : onDeleteClick}
      // capture and stop propagation to prevent dnd-kit from dragging
      onPointerDownCapture={stopEventPropagation}
    />
  );
}

function SortablePhoto({ image, children }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    active,
  } = useSortable({ id: image.id, data: { image } });

  /** @type {import('react').CSSProperties} */
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Row
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
      className={cx(
        'bg-white cursor-grab aria-disabled:cursor-[unset] [&:not([aria-disabled="true"])]:hover:bg-primary-hover',
        isDragging && 'invisible',
      )}
      inert={(active !== null) ? '' : undefined}
    >
      {children}
    </Row>
  );
}

/**
 * @param {import('@dnd-kit/core').DragOverlayProps} props
 */
function ActiveItemOverlay({ ...props }) {
  const [activeItem, setActiveItem] = useState();

  /** @type {import('@dnd-kit/core').DndMonitorListener}  */
  const listener = useMemo(() => ({
    onDragStart({ active }) {
      setActiveItem(active);
    },
    onDragEnd() {
      setActiveItem(null);
    },
  }), []);
  useDndMonitor(listener);

  return createPortal(
    <DragOverlay {...props}>
      {activeItem ? (
        <Row className="cursor-grabbing bg-primary-hover">
          <HomeModelImage image={activeItem.data.current.image} />
        </Row>
      ) : null}
    </DragOverlay>,
    document.body,
  );
}

function ImageUploadModal({ toggleModal, isLoading, onUpload }) {
  const [files, setFiles] = useState([]);

  const onFileUpload = useCallback((newFiles) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      newFiles
        // use name, size, and mtime as a heuristic to check for duplicated files
        .filter((newFile) => !updatedFiles.some(([otherFile]) => (
          newFile.name === otherFile.name
          && newFile.size === otherFile.size
          && newFile.lastModified === otherFile.lastModified
        )))
        .forEach((file) => updatedFiles.push([file, nanoid()]));

      return updatedFiles;
    });
  }, []);

  const onFileRemove = useCallback((evt) => {
    const idx = parseInt(evt.currentTarget.value, 10);
    setFiles((prevFiles) => prevFiles.toSpliced(idx, 1));
  }, []);

  const uploadOnClick = () => onUpload?.(files.map(([file]) => file));

  return (
    <Dialog open onClose={isLoading ? noop : toggleModal}>
      <div className="fixed inset-0 z-50 bg-black/25" />
      <div className="fixed inset-0 z-50 p-4 content-center overflow-clip">
        <Dialog.Panel className="relative flex flex-col h-max max-h-full w-max max-w-full rounded-2xl mx-auto py-6 *:px-6 bg-white">
          <div className="sticky inset-x-0 top-0 flex flex-row gap-x-3 pb-6 text-xl bg-inherit cursor-default">
            <Dialog.Title>Upload Photos</Dialog.Title>
          </div>

          <div className="flex flex-col gap-y-3 pb-px h-0 flex-1 overflow-auto" inert={isLoading ? '' : undefined}>
            {files.map(([file, fileId], index) => (
              <div key={fileId} className="w-full flex justify-between items-center gap-x-2 text-sm">
                <div className="flex-grow truncate max-w-prose" title={file.name}>{file.name}</div>
                <div className="text-gray-500">{fileSize(file.size)}</div>
                <button type="button" value={index} onClick={onFileRemove} className="focus:outline-none">
                  <X className="w-5 text-red-500 hover:text-red-400" />
                </button>
              </div>
            ))}
            <FileUploadArea accept="image/*" onFileUpload={onFileUpload} />
          </div>

          <div className="flex flex-row gap-x-2 justify-end pt-6 border-t mt-auto">
            <Button filled label="Upload" isLoading={isLoading} disabled={!files.length} onClick={uploadOnClick} />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

function AddHomeModelImage({ homeModelId, isListReady, className }) {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowUploadModal((prev) => !prev);
  }, []);

  const [triggerCreate, { isUninitialized, isLoading, isSuccess, reset }] = useCreateHomeModelImagesMutation();
  const onUpload = useCallback((files) => {
    triggerCreate({ homeModelId, files });
  }, [triggerCreate, homeModelId]);

  // include isListReady to wait for parent to finish re-fetching after a successful upload before dismissing modal
  const isUploading = !isUninitialized && (isLoading || !isListReady);
  if (isSuccess && !isUploading) {
    setShowUploadModal(false);
  }

  // reset hook after dismissing modal
  if (!showUploadModal && !isUninitialized) {
    reset();
  }

  return (
    <>
      <Plus className="h-5 w-auto text-neutral-dark justify-self-center" />
      <button
        type="button"
        className={cx(
          'h-20 text-neutral-dark text-label-lg justify-self-start col-start-2 col-span-full',
          String.raw`[.group\/row:has(&)]:relative after:absolute after:inset-0 [.group\/row:has(&:hover)]:bg-primary-hover`,
          className,
        )}
        onClick={toggleModal}
      >
        Upload Photos
      </button>

      {showUploadModal && (
        <ImageUploadModal
          toggleModal={toggleModal}
          isLoading={isUploading}
          onUpload={onUpload}
        />
      )}
    </>
  );
}

export default function HomeModelPhotosOrder({ homeModelId }) {
  const [row] = useFilteredSelectedRows();
  const { currentData: images, isFetching } = useFetchHomeModelImagesQuery({ homeModelId });

  const [order, setOrder] = useState(() => images?.map(({ id }) => id) ?? []);
  useEffect(() => {
    if (images?.length) {
      setOrder(images.map(({ id }) => id));
    } else {
      setOrder([]);
    }
  }, [images]);

  const [triggerUpdate] = useUpdateHomeModelImagesMutation();

  /** @type {import('@dnd-kit/core').DndContextProps['onDragEnd']} */
  const onDragEnd = useCallback(({ active, over }) => {
    if (!over || active.id === over.id) {
      return;
    }

    setOrder((prevOlder) => {
      const oldIndex = prevOlder.indexOf(active.id);
      const newIndex = prevOlder.indexOf(over.id);

      const updatedOrder = arrayMove(prevOlder, oldIndex, newIndex);
      startTransition(() => triggerUpdate({ homeModelId, homeModelImageIds: updatedOrder }));
      return updatedOrder;
    });
  }, [homeModelId, triggerUpdate]);

  const displayOrder = sortBy(images, (photo) => {
    const orderIdx = order.indexOf(photo.id);
    // sort by order, if the element is not in order, then put at the end
    // lodash sortBy is stable so those elements at the end should be sorted by their original server-side sorted order
    return orderIdx === -1 ? Number.POSITIVE_INFINITY : orderIdx;
  });

  // this is a temporary measure to disable adding/re-ordering photos
  // for rows that have not been migrated to the new home model images model
  // TODO: remove after completing migration
  if (row.getValue('imagesCount') > 0 && images?.length === 0) {
    return 'Photos function disabled, pending migration.';
  }

  const gridClassName = 'grid grid-cols-[auto_auto_1fr_auto] auto-rows-fr gap-y-1 bg-white';
  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
    >
      <ol className={cx(gridClassName, 'aria-pressed:cursor-grabbing')}>
        {(isFetching && !images) && (
          <Row>
            <LoadingIndicator className="h-5 w-auto text-neutral-dark justify-self-center" />
            <div className="text-neutral-dark text-label-lg col-start-2 col-span-full">
              Loading
            </div>
          </Row>
        )}
        <SortableContext
          disabled={images?.length === 1}
          items={displayOrder}
          strategy={verticalListSortingStrategy}
        >
          {displayOrder.map((image) => (
            <SortablePhoto key={image.id} image={image}>
              <HomeModelImage image={image} />
            </SortablePhoto>
          ))}
        </SortableContext>
        <Row>
          <AddHomeModelImage homeModelId={homeModelId} isListReady={!isFetching} />
        </Row>
      </ol>
      <ActiveItemOverlay wrapperElement="ul" className={gridClassName} />
    </DndContext>
  );
}
