import { forwardRef, useState } from 'react';
import Button from 'components/shared/Button';

function FilterPanel({ close, filterParams, onApply, onReset, children, ...props }, ref) {
  const [filterState, setFilterState] = useState(filterParams);
  const ChildComponent = children;

  return (
    <div {...props} ref={ref} className="absolute left-0 z-30 bg-white rounded px-4 pt-6 pb-3 border border-gray-300 shadow-lg">
      <form
        onSubmit={event => {
          event.stopPropagation();
          event.preventDefault();
          onApply(filterState);
          close(document.body);
        }}
        onReset={event => {
          event.stopPropagation();
          event.preventDefault();
          onReset();
          close(document.body);
        }}
      >
        <ChildComponent filterState={filterState} setFilterState={setFilterState} />
        <div className="flex justify-end mt-6 gap-x-6">
          <Button textOnly type="reset" label="Reset" />
          <Button type="submit" label="Apply" />
        </div>
      </form>
    </div>
  );
}

export default forwardRef(FilterPanel);
