import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useCreateDealNoteMutation, useFetchUsersQuery } from 'redux/apiSlice';
import { MentionsInput, Mention } from 'react-mentions';
import { clearDealNavigationModal } from '../../actions/deal_navigation';
import Button from '../shared/Button';
import defaultStyle from './defaultStyle';
import Modal from '../Modal';
import { parseEventValue } from '../utils';

export default function DealNotesModal({ context }) {
  const dispatch = useDispatch();
  const [body, setBody] = useState();

  const { modelData, data: { currentUser, deal } } = context;
  const dealId = deal?.id;
  const scenario = modelData?.model?.scenario;

  const [createDealNoteMutation, { isLoading: isCreating }] = useCreateDealNoteMutation();
  const { data: users = [] } = useFetchUsersQuery();
  const userOptions = users.filter(user => user.id !== currentUser.id).map(({ email, fullName }) => ({ id: email, display: fullName || email }));

  const handleClose = () => dispatch(clearDealNavigationModal());

  const onDealNoteChange = (event) => {
    const newValue = parseEventValue(event);
    setBody(newValue);
  };

  const handleCreateDealNote = async () => {
    const response = await createDealNoteMutation({ body, dealId, scenarioId: scenario.id });
    if (response.data) { handleClose(); }
  };

  return (
    <Modal show onClose={handleClose}>
      <div className="w-96">
        <div className="w-56">
          <h1 className="mb-4 text-left text-2xl font-normal">Add Note</h1>
        </div>
        <span className="mb-5 w-24">
          Notes are visible to anyone in your organization.
        </span>
        <MentionsInput
          onChange={onDealNoteChange}
          placeholder={"Mention people using '@'"}
          style={defaultStyle}
          value={body || ''}
        >
          <Mention style={{ backgroundColor: '#cee4e5' }} data={userOptions} />
        </MentionsInput>

        <div className="mt-8 flex gap-x-4 justify-end ...">
          <Button
            label="Cancel"
            textOnly
            onClick={handleClose}
          />
          <Button
            label="Add Note"
            onClick={handleCreateDealNote}
            disabled={isEmpty(body)}
            isLoading={isCreating}
          />
        </div>
      </div>
    </Modal>
  );
}
