import cx from 'classnames';
import { useCallback, useEffect } from 'react';
import Alert from 'components/Alert';
import { Check, Pencil, Refresh } from 'components/icons';
import Button from 'components/shared/NewButton';
import { useClearRowUpdates, useGetUpdatedRowData, useIsTableEdited } from 'components/shared/Table/DataTableContext';

export default function TableEditControls({
  isEditMode,
  setIsEditMode,
  isUpdating,
  updateSucceeded,
  updateFailed,
  updateError,
  onEditToggle,
  onSave,
  className,
}) {
  const getUpdatedRows = useGetUpdatedRowData();
  const save = useCallback(() => {
    const updates = getUpdatedRows();
    onSave?.(updates);
  }, [getUpdatedRows, onSave]);

  const toggleEdit = useCallback(() => {
    onEditToggle?.();
    setIsEditMode((prev) => !prev);
  }, [onEditToggle, setIsEditMode]);

  const clearChanges = useClearRowUpdates();
  const cancel = useCallback(() => {
    clearChanges();
    toggleEdit();
  }, [clearChanges, toggleEdit]);

  useEffect(() => {
    if (updateSucceeded) {
      clearChanges();
      setIsEditMode(false);
    }
  }, [clearChanges, setIsEditMode, updateSucceeded]);

  const hasChanged = useIsTableEdited();
  return (
    <div className={cx('flex flex-row gap-x-1', className)}>
      {!isEditMode && (
        <Button
          filled
          label="Edit"
          leadingIcon={<Pencil />}
          onClick={toggleEdit}
        />
      )}

      {isEditMode && (
        <>
          <Button
            filled
            danger
            label="Cancel"
            disabled={isUpdating}
            onClick={cancel}
          />
          <Button
            filled
            label={updateFailed ? 'Retry' : 'Save'}
            leadingIcon={updateFailed ? <Refresh /> : <Check />}
            disabled={!hasChanged}
            onClick={save}
            isLoading={isUpdating}
          />
        </>
      )}

      {updateFailed && (
        <Alert className="whitespace-pre-line" type="danger" text={updateError} />
      )}
    </div>
  );
}
