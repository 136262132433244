import { Outlet, useOutletContext } from 'react-router-dom';
import SummarySidebar from 'components/PortfolioDeal/SummarySidebar';
import { LAYOUT } from 'components/constants';

export default function PortfolioLayout() {
  const context = useOutletContext();

  return (
    <div className="flex w-0 flex-1 z-0 bg-gray-100" style={{ height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)` }}>
      <div className="flex-none">
        <SummarySidebar />
      </div>
      <Outlet context={context} />
    </div>
  );
}
