import axios from 'axios';

export const getCsrfToken = () => document.querySelector('[name=csrf-token]')?.content;

function axiosRailsFix() {
  return axios.create({
    headers: {
      common: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    },
  });
}

export default axiosRailsFix();
