import Badge from '../shared/Badge';

export default function StatusCell({ getValue }) {
  return (
    <Badge
      label={getValue()}
      className="mx-auto border font-normal bg-white select-none"
      width="w-max"
    />
  );
}
