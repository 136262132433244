import { useState } from 'react';
import Alert from '../Alert';
import { FormField } from '../Form';
import { parseEventValue, camelCaseKeys, snakeCaseKeys } from '../utils';
import { createListingsPath, listingPath } from '../routes';
import { cloneDeep, merge } from 'lodash';

const LISTING_SOURCE = 'manual';

export default function ListingEntry({ property, listing, setNewListing, handleStepChange, currentStep }) {
  const [listingData, setListingData] = useState(camelCaseKeys(listing));
  const [alert, setAlert] = useState(null);

  const onChange = (e) => {
    setAlert(null);
    const field = e.target.name;
    const value = parseEventValue(e);
    const updatedListing = cloneDeep(listingData);
    updatedListing[field] = value;
    setListingData(updatedListing);
    handleStepChange ? setNewListing(updatedListing) : null
  };

  const onSave = () => {
    const csrfToken = document.querySelector('[name=csrf-token]').content;

    if (!handleStepChange && !listingData.listedOn || !handleStepChange && !listingData.price) {
      setAlert({ type: 'warning', text: 'Price and listed on date are required' });
      return;
    }

    if (!handleStepChange) {
      fetch(createListingsPath, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
        },
        body: JSON.stringify(snakeCaseKeys(merge(listingData, {
          propertyId: property.id,
          source: LISTING_SOURCE,
          sourceId: property.id, // TODO: sourceId needs to be unique
        }))),
      }).then(response => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      }).then(json => {
        // TODO: handle error states
        handleStepChange ? handleStepChange(5) && setNewListing(json)
        : window.location = listingPath(json);
      }).catch(error => {
        setAlert({text: `Error: ${error.message}`, type: 'danger'})
      });
    } else {
      handleStepChange(5)
      setNewListing(listingData)
    }
  };

  return (
    <>
      <h1 className="mt-8 text-lg">{`Enter listing information for ${property.address}`}</h1>
      <h2 className="mt-8 mb-4 text-sm font-bold">Listing Information</h2>
      <div className="md:container md:mx-auto">
        <FormField className="mt-8" name="listAgent" label="Broker Name" value={listingData.listAgent} type="text" onChange={onChange} />
        <FormField className="mt-8" name="listAgentFirm" label="Broker Firm" value={listingData.listAgentFirm} type="text" onChange={onChange} />
        <FormField className="mt-8" name="listAgentEmail" label="Broker Email" value={listingData.listAgentEmail} type="text" onChange={onChange} />
        <FormField className="mt-8" name="listAgentPhone" label="Broker Phone" value={listingData.listAgentPhone} type="text" onChange={onChange} />
        <FormField className="mt-8" label="Asking Price" name="price" value={listingData.price || listing.price} min="0" onChange={onChange} />
        <FormField className="mt-8" type="date" label="Listed On" name="listedOn" value={listingData.listedOn || listing.listedOn} onChange={onChange} />
      </div>
      <div className="mt-8 flex flex-row justify-center gap-x-4">
        {
          handleStepChange ?
            <button
            className="px-4 py-2 text-gray border-2 border-gray-100 rounded	m-2"
            onClick={() => handleStepChange(currentStep - 1)}
            >
              Back
            </button>
            : null
        }
        <button
          className="px-4 py-2 text-gray border-2 border-gray-100 rounded	m-2"
          onClick={onSave}
        >
          { handleStepChange ? 'Next' : 'Save'}
        </button>
      </div>
      {alert && <Alert className="mt-6" {...alert} />}
    </>
  );
}
