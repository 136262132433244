import { parseISO, startOfDay } from 'date-fns';
import { groupBy, orderBy, toPairs } from 'lodash';
import EmailLink from '../../common/EmailLink';
import RoundButton from '../../common/RoundButton';
import { ContentCopy, X } from '../../icons';
import { formatDate } from '../../utils';
import { FEED_SECTION_DATE_FORMAT, isEmail } from './activityFeed';
import FeedSection from './FeedSection';

export default function Feed({ deal, dealEmails, setShowActivities, tasks, usersById }) {
  const completedTasks = tasks.filter(task => task.completedAt);
  const activityItems = completedTasks.concat(dealEmails);
  activityItems.forEach(item => Object.assign(item, { occurredAt: isEmail(item) ? item.received : item.completedAt }));
  const sortedItems = orderBy(activityItems, ['occurredAt'], ['desc']);
  const groupedItems = groupBy(sortedItems, ({ occurredAt }) => formatDate(startOfDay(parseISO(occurredAt)), FEED_SECTION_DATE_FORMAT));
  const groupedItemPairs = toPairs(groupedItems);

  return (
    <div className="flex flex-col w-96 h-full p-6 bg-white overflow-x-hidden cursor-default">
      <div className="flex flex-col gap-y-1 mb-2">
        <div className="w-full flex justify-between">
          <div className="text-2xl font-semibold leading-10 text-gray-900">Activity</div>
          <div className="cursor-pointer" onClick={() => setShowActivities(false)}><X className="w-6" /></div>
        </div>
        <div className="text-black text-opacity-54 text-sm leading-6">For deal specific email activity tracking CC:</div>
        <div className="flex items-center">
          <EmailLink address={deal.email} className="text-black text-opacity-90" />
          <RoundButton padding="p-2.5" onClick={() => navigator.clipboard.writeText(deal.email)}>
            <ContentCopy className="text-gray-700 h-4 w-4" />
          </RoundButton>
        </div>
      </div>
      {groupedItemPairs.length === 0 ? (
        <div className="py-6 text-xs font-medium tracking-wider text-gray-900 text-opacity-54">No Activity</div>
      ) : (
        <div className="flex flex-col divide-y">
          {
            groupedItemPairs.map((pair) => (
              <FeedSection
                key={pair[0]}
                date={pair[0]}
                items={pair[1]}
                usersById={usersById}
              />
            ))
          }
        </div>
      )}
    </div>
  );
}
