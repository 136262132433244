import { isNull } from 'lodash';
import {
  isRefinancing,
  LOAN_SIZING_METHODS,
  LOAN_SIZING_METHOD_DEBT_YIELD,
  LOAN_SIZING_METHOD_DSCR,
  LOAN_SIZING_METHOD_LTC,
  LOAN_SIZING_METHOD_LTV,
  LOAN_SIZING_METHOD_MANUAL,
} from '../dcf/debt';
import { FormField, InfoField } from '../Form';
import { PERCENT_TYPE } from '../Input';
import PortfolioEditTabSectionLabel from '../portfolio/PortfolioEditTabSectionLabel';

export default function DebtParameters({ parameters, onChange, canEditPortfolio }) {
  const {
    amoritization,
    couponRate,
    dscr,
    debtYield,
    holdPeriod,
    interestOnly,
    loanOriginationFeeRate,
    loanSize,
    loanSizingMethod,
    ltc,
    ltcFutureFundingDraw,
    ltcIncludeAcquisitionCost,
    ltv,
    refinancingAmoritization,
    refinancingCapRate,
    refinancingCouponRate,
    refinancingDscr,
    refinancingDebtYield,
    refinancingInterestOnly,
    refinancingOriginationFeeRate,
    refinancingLoanSize,
    refinancingSizingMethod,
    refinancingLtv,
    refinancingTerm,
    term,
  } = parameters;

  const isRefinanced = isRefinancing(parameters);

  const onCheckRefinance = () => {
    onChange({
      target: {
        name: 'refinancingSizingMethod',
        value: isRefinanced ? null : 'ltv',
      },
    });
  };

  let loanSizingParameterField;
  if (loanSizingMethod === LOAN_SIZING_METHOD_LTC) {
    const isFutureFunding = !isNull(ltcFutureFundingDraw);
    const onCheckFutureFunding = () => {
      onChange({
        target: {
          name: 'ltcFutureFundingDraw',
          value: isFutureFunding ? null : 1,
        },
      });
    };
    loanSizingParameterField = (
      <>
        <FormField
          className="mt-6"
          name="ltc"
          type={PERCENT_TYPE}
          value={ltc}
          min="0"
          max="100"
          onChange={onChange}
          disabled={!canEditPortfolio}
        />
        <FormField
          className="mt-6"
          name="ltcIncludeAcquisitionCost"
          type="checkbox"
          label="Include Acquisition Cost"
          value={ltcIncludeAcquisitionCost ?? false}
          onChange={onChange}
          disabled={!canEditPortfolio}
        />
        <FormField
          className="mt-6"
          type="checkbox"
          label="Future Funding"
          value={isFutureFunding || ''}
          checked={isFutureFunding}
          onChange={onCheckFutureFunding}
          disabled={!canEditPortfolio}
        />
        {isFutureFunding && (
          <FormField
            className="mt-6"
            name="ltcFutureFundingDraw"
            label="Future Funding Draw (Months)"
            type="number"
            value={ltcFutureFundingDraw}
            min="1"
            onChange={onChange}
            disabled={!canEditPortfolio}
          />
        )}
      </>
    );
  } else if (loanSizingMethod === LOAN_SIZING_METHOD_LTV) {
    loanSizingParameterField = (
      <FormField
        className="mt-6"
        name="ltv"
        type={PERCENT_TYPE}
        value={ltv}
        min="0"
        max="100"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
    );
  } else if (loanSizingMethod === LOAN_SIZING_METHOD_DEBT_YIELD) {
    loanSizingParameterField = (
      <FormField
        className="mt-6"
        name="debtYield"
        type={PERCENT_TYPE}
        value={debtYield}
        min="1"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
    );
  } else if (loanSizingMethod === LOAN_SIZING_METHOD_DSCR) {
    loanSizingParameterField = (
      <FormField
        className="mt-6"
        name="dscr"
        type="number"
        value={dscr}
        min="1"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
    );
  } else if (loanSizingMethod === LOAN_SIZING_METHOD_MANUAL) {
    loanSizingParameterField = (
      <FormField
        className="mt-6"
        name="loanSize"
        type="number"
        value={loanSize}
        min="0"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
    );
  } else {
    throw new Error(`Unsupported loan sizing method: ${loanSizingMethod}`);
  }

  let refinancingLoanSizingParameterField;
  if (isRefinanced) {
    if (refinancingSizingMethod === LOAN_SIZING_METHOD_LTV) {
      refinancingLoanSizingParameterField = (
        <>
          <FormField
            className="mt-6"
            name="refinancingLtv"
            label="LTV"
            type={PERCENT_TYPE}
            value={refinancingLtv}
            min="0"
            max="100"
            onChange={onChange}
            disabled={!canEditPortfolio}
          />
          <FormField
            className="mt-6"
            name="refinancingCapRate"
            type={PERCENT_TYPE}
            value={refinancingCapRate}
            min="0"
            max="100"
            onChange={onChange}
            disabled={!canEditPortfolio}
          />
        </>
      );
    } else if (refinancingSizingMethod === LOAN_SIZING_METHOD_DEBT_YIELD) {
      refinancingLoanSizingParameterField = (
        <FormField
          className="mt-6"
          name="refinancingDebtYield"
          label="Debt Yield"
          type={PERCENT_TYPE}
          value={refinancingDebtYield}
          min="1"
          onChange={onChange}
          disabled={!canEditPortfolio}
        />
      );
    } else if (refinancingSizingMethod === LOAN_SIZING_METHOD_DSCR) {
      refinancingLoanSizingParameterField = (
        <FormField
          className="mt-6"
          name="refinancingDscr"
          label="DSCR"
          type="number"
          value={refinancingDscr}
          min="1"
          onChange={onChange}
          disabled={!canEditPortfolio}
        />
      );
    } else if (refinancingSizingMethod === LOAN_SIZING_METHOD_MANUAL) {
      refinancingLoanSizingParameterField = (
        <FormField
          className="mt-6"
          name="refinancingLoanSize"
          label="Loan Size"
          type="number"
          value={refinancingLoanSize}
          min="0"
          onChange={onChange}
          disabled={!canEditPortfolio}
        />
      );
    } else {
      throw new Error(`Unsupported refinancing loan sizing method: ${refinancingSizingMethod}`);
    }
  }

  return (
    <>
      <FormField
        name="loanSizingMethod"
        type="select"
        value={loanSizingMethod}
        options={LOAN_SIZING_METHODS}
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      {loanSizingParameterField}
      <FormField
        className="mt-6"
        name="couponRate"
        type={PERCENT_TYPE}
        value={couponRate}
        min="0"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <InfoField
        className="mt-6"
        label="Hold Period (years)"
        value={holdPeriod}
        valueClassName="text-right pr-2"
      />
      <FormField
        className="mt-6"
        name="term"
        label="Term (months)"
        type="number"
        value={term}
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="amoritization"
        label="Amoritization (months)"
        type="number"
        value={amoritization}
        min={term}
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="interestOnly"
        label="Interest Only (months)"
        type="number"
        value={interestOnly}
        min="0"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="loanOriginationFeeRate"
        label="Loan Origination Fee Rate"
        type={PERCENT_TYPE}
        value={loanOriginationFeeRate}
        min="0"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <PortfolioEditTabSectionLabel text="Refinance" className="mt-6" />
      <FormField
        type="checkbox"
        label=""
        value={isRefinanced}
        checked={isRefinanced}
        onChange={onCheckRefinance}
        disabled={!canEditPortfolio}
      />
      {isRefinanced && (
        <>
          <FormField
            className="mt-6"
            name="refinancingSizingMethod"
            type="select"
            value={refinancingSizingMethod}
            options={LOAN_SIZING_METHODS.slice(1)}
            onChange={onChange}
            disabled={!canEditPortfolio}
          />
          {refinancingLoanSizingParameterField}
          <FormField
            className="mt-6"
            name="refinancingCouponRate"
            type={PERCENT_TYPE}
            value={refinancingCouponRate}
            min="0"
            onChange={onChange}
            disabled={!canEditPortfolio}
          />
          <FormField
            className="mt-6"
            name="refinancingTerm"
            label="Refinancing Term (months)"
            type="number"
            value={refinancingTerm}
            onChange={onChange}
            disabled={!canEditPortfolio}
          />
          <FormField
            className="mt-6"
            name="refinancingAmoritization"
            label="Refinancing Amoritization (months)"
            type="number"
            value={refinancingAmoritization}
            min={refinancingTerm}
            onChange={onChange}
            disabled={!canEditPortfolio}
          />
          <FormField
            className="mt-6"
            name="refinancingInterestOnly"
            label="Refinancing Interest Only (months)"
            type="number"
            value={refinancingInterestOnly}
            min="0"
            onChange={onChange}
            disabled={!canEditPortfolio}
          />
          <FormField
            className="mt-6"
            name="refinancingOriginationFeeRate"
            label="Refinancing Origination Fee Rate"
            type={PERCENT_TYPE}
            value={refinancingOriginationFeeRate}
            min="0"
            onChange={onChange}
            disabled={!canEditPortfolio}
          />
        </>
      )}
    </>
  );
}
