import { useSelector } from 'react-redux';
import DealNotesModal from 'components/dashboard/DealNotesModal';
import GenerateDealSummaryModal from 'components/dashboard/GenerateDealSummaryModal';
import GenerateLoiModal from 'components/dashboard/GenerateLoiModal';
import GeneratePurchaseAgreementModal from 'components/dashboard/GeneratePurchaseAgreementModal';
import MarkDealAsDeadModal from 'components/dashboard/MarkDealAsDeadModal';
import MarkDealAsWithdrawnModal from 'components/dashboard/MarkDealAsWithdrawnModal';
import ReopenDealModal from 'components/dashboard/ReopenDealModal';
import CreateDealModal from 'components/deal/CreateDealModal';
import EditDealModal from 'components/deal/EditDealModal';
import NewBuildOfferModal from 'components/deal/NewBuildOfferModal';
import RenameScenarioModal from 'components/deal/RenameScenarioModal';
import SaveChangesModal from 'components/modals/SaveChangesModal';
import CopyDealModal from 'components/portfolio/CopyDealModal';
import SwitchPortfolioModal from 'components/portfolio/SwitchPortfolioModal';
import DownloadModelModal from 'components/property/DownloadModelModal';
import PageBanner from 'components/shared/PageBanner';
import { useOutletContext } from 'react-router-dom';

export default function ModalDisplayer() {
  const context = useOutletContext();

  const {
    copyDealModal,
    showCreateDealModal,
    showDownloadModelModal,
    showEditDealModal,
    showGenerateDealSummaryModal,
    showGenerateLoiModal,
    showGeneratePurchaseAgreementModal,
    showMarkAsDeadModal,
    showMarkAsWithdrawnModal,
    showRenameScenarioModal,
    showReopenDealModal,
    showSaveChangesModal,
    showDealNotesModal,
    switchPortfolioModal,
    showNewBuildOfferModal,
  } = useSelector(state => state.navigation.modal);

  const { banner } = useSelector(state => state.global);

  if (showCreateDealModal) {
    return <CreateDealModal context={context} />;
  }
  if (showEditDealModal) {
    return <EditDealModal context={context} />;
  }
  if (showGenerateDealSummaryModal) {
    return <GenerateDealSummaryModal context={context} />;
  }
  if (showDealNotesModal) {
    return <DealNotesModal context={context} />;
  }
  if (showGenerateLoiModal) {
    return <GenerateLoiModal context={context} />;
  }
  if (showGeneratePurchaseAgreementModal) {
    return <GeneratePurchaseAgreementModal context={context} />;
  }
  if (showDownloadModelModal) {
    return <DownloadModelModal context={context} />;
  }
  if (showMarkAsDeadModal) {
    return <MarkDealAsDeadModal context={context} />;
  }
  if (showMarkAsWithdrawnModal) {
    return <MarkDealAsWithdrawnModal context={context} />;
  }
  if (showRenameScenarioModal) {
    return <RenameScenarioModal context={context} />;
  }
  if (showReopenDealModal) {
    return <ReopenDealModal context={context} />;
  }
  if (showSaveChangesModal) {
    return <SaveChangesModal context={context} />;
  }
  if (copyDealModal) {
    return <CopyDealModal context={context} />;
  }
  if (switchPortfolioModal) {
    return <SwitchPortfolioModal context={context} />;
  }
  if (showNewBuildOfferModal) {
    return <NewBuildOfferModal context={context} />;
  }
  if (banner?.show) {
    return (<PageBanner context={context} />);
  }

  return null;
}
