import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { apiSlice } from 'redux/apiSlice';
import { homeModelImagesApiPath } from 'components/routes';
import { HOME_MODEL_IMAGES_TAG } from './utils';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchHomeModelImages: builder.query({
        query: ({ homeModelId }) => generatePath(homeModelImagesApiPath, { homeModelId }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: [HOME_MODEL_IMAGES_TAG],
      }),
      fetchHomeModelImage: builder.query({
        query: ({ id, homeModelId }) => generatePath(homeModelImagesApiPath, { id, homeModelId }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: (result, error, arg) => [{ type: HOME_MODEL_IMAGES_TAG, id: arg.id }],
      }),
      createHomeModelImages: builder.mutation({
        query: ({ homeModelId, files }) => {
          const formData = new FormData();
          files.forEach((file, index) => formData.append(`files[${index}]`, file));

          return ({
            url: generatePath(homeModelImagesApiPath, { homeModelId }),
            method: 'POST',
            body: formData,
          });
        },
        invalidatesTags: [HOME_MODEL_IMAGES_TAG],
      }),
      updateHomeModelImages: builder.mutation({
        query: ({ homeModelImageIds, homeModelId }) => ({
          url: generatePath(homeModelImagesApiPath, { homeModelId }),
          method: 'PUT',
          body: snakeCaseKeys({ homeModelImageIds }),
        }),
        invalidatesTags: [HOME_MODEL_IMAGES_TAG],
      }),
      deleteHomeModelImage: builder.mutation({
        query: ({ id, homeModelId }) => ({
          url: generatePath(homeModelImagesApiPath, { id, homeModelId }),
          method: 'DELETE',
        }),
        invalidatesTags: [HOME_MODEL_IMAGES_TAG],
      }),
    };
  },
});

export const useInvalidateHomeModelImages = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(extendedApiSlice.util.invalidateTags([HOME_MODEL_IMAGES_TAG]));
  }, [dispatch]);
};

export const {
  useFetchHomeModelImagesQuery,
  useFetchHomeModelImageQuery,
  useCreateHomeModelImagesMutation,
  useUpdateHomeModelImagesMutation,
  useDeleteHomeModelImageMutation,
} = extendedApiSlice;
