import { isNil } from 'lodash';

// TODO: generate signed static map url server-side
export const createStaticMapUrl = ({ latitude, longitude, precision = 3, width = 96, height = 96, scale = 2, zoom = 8 }) => {
  const url = new URL('/api/staticmap', window.location);
  url.searchParams.set('size', `${width}x${height}`);
  url.searchParams.set('scale', `${scale}`);
  url.searchParams.set('zoom', `${zoom}`);
  url.searchParams.append('style[]', 'feature:poi|visibility:off');
  url.searchParams.append('style[]', 'feature:all|element:labels|visibility:off');

  if (!isNil(latitude) && !isNil(longitude)) {
    // reduce lat/lng precision to improve browser cache hit rate
    const lat = Number.parseFloat(latitude).toFixed(precision);
    const lng = Number.parseFloat(longitude).toFixed(precision);
    const latlng = `${lat}5,${lng}5`;

    url.searchParams.set('center', latlng);
  }

  return url;
};
