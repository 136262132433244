import ReactDOM from 'react-dom';
import { FormField } from 'components/Form';
import { noop } from 'lodash';
import { ExclamationCircle } from 'components/icons';
import Select from 'components/Select';
import { TAX_GROWTH_TIMING_OPTIONS } from 'components/dcf/expense';
import InfoPopover from 'components/portfolio/Menu/InfoPopover';

// temporary toast until we reduxize the portfolio forms
function Toast({ children }) {
  return ReactDOM.createPortal(
    <div
      className="fixed left-0 right-0 mx-auto bottom-4 w-max h-14 bg-neutral-medium text-base text-center text-white rounded-xl py-4 px-4 z-30 flex justify-between"
    >
      {children}
    </div>,
    document.body,
  );
}

export default function TaxParameters({ parameters, onChange, canEditPortfolio, validationErrors }) {
  const { assessedValuePurchasePercent, assessedValueExitPercent, defaultEffectiveTaxRate, taxExpenseGrowthTiming, taxExpenseGrowthRate, useAssessorTaxRate } = parameters;
  const alignTaxesToPurchase = assessedValuePurchasePercent && assessedValueExitPercent;

  const taxesInfo = 'The Taxes parameters are utilized in the platform’s initial valuation. Users may elect to supply their own assumptions to determine the Year 1 Real Estate Taxes utilized in the valuation, or leverage the platform’s data pulled from each County’s Tax Assessor.';
  const medianEffectiveTaxRateLink = 'https://truliv-public.s3.us-west-1.amazonaws.com/uploads/Market+Median+Tax+Rates.xlsx';

  const onToggleAlignTaxesToPurchase = () => {
    const updatedParameters = {
      ...parameters,
      assessedValuePurchasePercent: alignTaxesToPurchase ? null : 1,
      assessedValueExitPercent: alignTaxesToPurchase ? null : 1,
    };
    onChange({
      target: {
        name: 'parameters',
        value: updatedParameters,
      },
    });
  };

  const onToggleSpecifyTaxRate = ({ target: { value } }) => {
    onChange({
      target: {
        name: 'useAssessorTaxRate',
        value,
      },
    });
  };

  return (
    <div>
      {validationErrors.defaultEffectiveTaxRate && (
      <Toast>
        <div className="flex gap-x-16">
          <div className="flex">
            <ExclamationCircle className="w-5 h-5 mr-5" fillColor="#FFFFFF" />
            <span className="text-base font-normal">
              Error: Missing Default Effective Tax Rate
            </span>
          </div>
          <div className="flex gap-x-8 font-medium text-sm text-white">
            <div className="cursor-pointer" onClick={noop}>
              Review
            </div>
          </div>
        </div>
      </Toast>
      )}
      <div className="w-128 text-neutral-dark text-sm font-normal">
        {taxesInfo}
      </div>
      <a className="text-tertiary underline text-sm font-normal mt-2" href={medianEffectiveTaxRateLink} target="_blank" rel="noreferrer">View Median Effective Tax Rates by Market</a>
      <div className="w-128 mt-6 p-6 bg-white rounded-lg border border-gray-300">
        <div className="text-black text-base font-medium">Tax Assessment Market Value</div>
        <hr className="mt-3 mb-8 h-px bg-gray-200 border-0" />
        <div className="flex justify-between">
          <div className="flex items-center">
            <div className="text-neutral-dark font-medium text-sm mr-2">Align Assessed Value to Purchase/Exit</div>
            <InfoPopover title="Align Assessed Value to Purchase / Exit Price" detail="Use this toggle to determine the Tax Assessment Market Value utilized in the initial valuation. When “off”, the model will utilize the last year’s Market Value from the Tax Assessor, grown forward at the rate below. When “on”, the property’s Purchase Price and Exit Price are multiplied by the inputted percentages and utilized to determine the Year 1 Tax Assessment Market Value in the valuation, grown thereafter at the inputted rate below. " />
          </div>
          <FormField
            className="w-12"
            type="checkbox"
            value={alignTaxesToPurchase || ''}
            checked={alignTaxesToPurchase}
            onChange={onToggleAlignTaxesToPurchase}
            disabled={!canEditPortfolio}
          />
        </div>
        <div className="mt-11 mb-12 flex flex-col gap-y-8">
          <FormField
            border
            disabled={!alignTaxesToPurchase}
            label="Initial Assessed Value as % of Purchase"
            name="assessedValuePurchasePercent"
            onChange={onChange}
            padding="p-4 text-base focus:outline-none w-full"
            textAlign
            type="percent"
            value={assessedValuePurchasePercent}
          />
          <FormField
            border
            disabled={!alignTaxesToPurchase}
            label="Exit Assessed Value as % of Exit"
            name="assessedValueExitPercent"
            onChange={onChange}
            padding="p-4 text-base focus:outline-none w-full"
            textAlign
            type="percent"
            value={assessedValueExitPercent}
          />
        </div>
        <div className="text-black text-base font-medium">Tax Assessment Effective Rate</div>
        <hr className="mt-3 mb-8 h-px bg-gray-200 border-0" />
        <div className="mb-10 flex justify-between">
          <div className="flex items-center">
            <div className="text-neutral-dark font-medium text-sm mr-2">Use Assessor Tax Rate</div>
            <InfoPopover title="Use Assessor Tax Rate" detail="Use this toggle to select a methodology for determining the Effective Tax Rate used in underwriting. When the toggle is “on”, the platform will calculate each property’s Effective Tax Rate by dividing last year’s tax bill  by the “Market Value” from each county’s Tax Assessor. This rate is then used to calculate the Year 1 Real Estate Taxes, using the methodology for Market Assessed Value in the section above. For properties where there may be an exemption that produces a discounted or null tax bill, the “Default Effective Tax Rate” will ensure the rate used will not be incorrect. When the toggle is “off”, the specified “Default Effective Tax Rate” will override the Tax Assessor data and be used for all properties." />
          </div>
          <FormField
            className="w-12"
            type="checkbox"
            value={useAssessorTaxRate}
            checked={useAssessorTaxRate}
            onChange={onToggleSpecifyTaxRate}
          />
        </div>
        <FormField
          border
          label="Default Effective Tax Rate"
          name="defaultEffectiveTaxRate"
          onChange={onChange}
          padding="p-4 text-base focus:outline-none w-full"
          textAlign
          type="percent"
          value={defaultEffectiveTaxRate}
          errorMessage={validationErrors.defaultEffectiveTaxRate}
          helperText="Required: Used if Assessor Tax Rate is turned off or unavailable"
        />
        <div className="flex mt-12 items-center w-60">
          <div className="text-black text-base font-medium">Tax Assessment Growth</div>
          <InfoPopover title="Tax Expense Growth Timing" detail="Calendar will grow taxes annually in January, regardless of when the deal closes. Close Date will grow the taxes annually in the same month of the “Closing Date” utilized in the underwriting model." />
        </div>
        <hr className="mt-3 mb-8 h-px bg-gray-200 border-0" />
        <div className="text-neutral-dark font-medium text-sm mb-1">Tax Expense Growth Timing</div>
        <div className="flex flex-col gap-y-8">
          <Select
            width="w-full"
            height="h-14"
            name="taxExpenseGrowthTiming"
            value={taxExpenseGrowthTiming ?? ''}
            options={TAX_GROWTH_TIMING_OPTIONS}
            onChange={onChange}
          />
          <FormField
            border
            label="Assessed Value Growth Rate"
            name="taxExpenseGrowthRate"
            onChange={onChange}
            padding="p-4 text-base focus:outline-none w-full"
            textAlign
            type="percent"
            value={taxExpenseGrowthRate}
          />
        </div>
      </div>
    </div>
  );
}
