import cx from 'classnames';
import { isNil, partial, xor } from 'lodash';

export const BED_TYPE = 'bed';
export const BATH_TYPE = 'bath';
const BED_OPTIONS = [0, 1, 2, 3, 4, 5, 6];
const BATH_OPTIONS = [1, 2, 3, 4];

export function BedBathButton({ active, label, lastButton, onClick, width }) {
  const clazz = cx(
    'flex justify-center py-2.5 px-3 text-sm text-gray-600 hover:bg-primary-100 active:bg-primary-200 active:outline-none focus:outline-none',
    width,
    {
      'border-t border-b border-l': !active,
      'border-r': lastButton && !active,
      'bg-primary-100 border-2 border-primary-300': active,
    },
  );
  return (
    <button className={clazz} type="button" onClick={onClick}>
      {label}
    </button>
  );
}

function UseExactMatch({ checked, onChange, className }) {
  return (
    <label className={cx('flex flex-row items-center gap-x-4.5 w-max text-gray-700 tracking-wide cursor-pointer select-none', className)}>
      <input
        type="checkbox"
        className="h-4.5 w-4.5 cursor-pointer"
        checked={checked}
        onChange={onChange}
      />
      Use exact match
    </label>
  );
}

export const onChange = (setFilterState, toggleExact, value) => {
  setFilterState(prevState => {
    const useExact = prevState[0];
    const prevFilterValue = prevState[1]
    let updatedState = [...prevState];
    if (toggleExact) {
      updatedState = [!useExact, prevFilterValue];
    } else {
      let updatedFilter;
      if (useExact) {
        // If useExact toggle the selected option
        updatedFilter = [useExact, ...xor(prevState.slice(1), [value])];
      } else {
        // If not, set selected option as the only option
        updatedFilter = isNil(value) ? [useExact] : [useExact, value];
      }

      updatedState = updatedFilter;
    }

    return updatedState;
  });
};

export function BedBathFilter({ type, useExact, filter, setFilterState }) {
  return (
    <>
      <div className="grid grid-rows-1 grid-flow-col auto-cols-fr w-max mb-3">
        {!useExact && (
          <BedBathButton
            label="Any"
            active={!filter.length}
            onClick={partial(onChange, setFilterState, false, null)}
          />
        )}
        {((type === BED_TYPE) ? BED_OPTIONS : BATH_OPTIONS).map((bedBath, index, arr) => (
          <BedBathButton
            key={index}
            label={`${bedBath}${(useExact ? '' : '+')}`}
            lastButton={index === arr.length - 1}
            active={filter.includes(bedBath)}
            onClick={partial(onChange, setFilterState, false, bedBath)}
          />
        ))}
      </div>
      <UseExactMatch
        className="mb-6"
        checked={useExact}
        onChange={partial(onChange, setFilterState, true, null)}
      />
    </>
  );
}
