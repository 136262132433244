import cx from 'classnames';
import { Link, NavLink } from 'react-router-dom';

export function HorizontalTabs({ children }) {
  return (
    <div className="flex flex-row flex-wrap gap-2 px-4 pt-1 bg-white whitespace-nowrap text-center text-sm tracking-wide border-b border-gray-200">
      {children}
    </div>
  );
}

/**
 * @param {import('react-router-dom').NavLinkProps['to']} to
 * @param {import('react-router-dom').NavLinkProps['end']} [end]
 * @param {import('react-router-dom').NavLinkProps['caseSensitive']} [caseSensitive]
 * @param {import('react').ReactNode} children
 */
export function NavTab({ to, end = false, caseSensitive = false, children }) {
  return (
    <BaseTab Component={NavLink} to={to} end={end} caseSensitive={caseSensitive}>
      {children}
    </BaseTab>
  );
}

/**
 * @param {boolean} active
 * @param {import('react-router-dom').LinkProps['to']} to
 * @param {import('react').ReactNode} children
 */
export function LinkTab({ active, to, children }) {
  return (
    <BaseTab Component={Link} to={to} className={active ? 'active' : null}>
      {children}
    </BaseTab>
  );
}

/**
 * @param {boolean} active
 * @param {import('react').MouseEventHandler<HTMLButtonElement>} onClick
 * @param {import('react').ReactNode} children
 */
export function ButtonTab({ active, onClick, children }) {
  return (
    <BaseTab Component="button" type="button" onClick={onClick} className={active ? 'active' : null}>
      {children}
    </BaseTab>
  );
}

/**
 * @param Component
 * @param {string} [className]
 * @param {import('react').ReactNode} children
 * @param props
 */
function BaseTab({ Component, className, children, ...props }) {
  return (
    <Component {...props} className={cx('basis-32 px-4.5 rounded-t-md group hover:bg-primary-hover', className)}>
      <div className="relative w-max mx-auto pb-4 pt-2.5">
        <div>{children}</div>
        <div className="hidden group-[.active]:block absolute -inset-x-0.5 bottom-0 h-1 rounded-t-full bg-primary-dark" />
      </div>
    </Component>
  );
}
