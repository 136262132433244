import { useMemo } from 'react';
import { formatCurrency, formatInteger, metersSquaredToMilesSquared } from 'components/utils';

export const calcChange = (data, currentValueKey, projValueKey) => (data[projValueKey] - data[currentValueKey]) / data[currentValueKey];

export function organizeDemographicsDataForComparison(data) {
  const {
    block: blockData,
    censusTract: censusTractData,
    zip: zipCodeData,
    msa: msaData,
  } = data;

  const organizedData = [
    {
      demographicKey: 'Households',
      zip: zipCodeData?.householdCount,
      msa: msaData?.householdCount,
      block: blockData?.householdCount,
      censusTract: censusTractData?.householdCount,
      formatter: formatInteger,
    },
    {
      demographicKey: 'Households / sq mi',
      zip: zipCodeData?.householdCount / metersSquaredToMilesSquared(zipCodeData?.area),
      msa: msaData?.householdCount / metersSquaredToMilesSquared(msaData?.area),
      censusTract: censusTractData?.householdCount ? (censusTractData.householdCount / metersSquaredToMilesSquared(censusTractData.area)) : null,
      block: blockData?.householdCount ? (blockData.householdCount / metersSquaredToMilesSquared(blockData.area)) : null,
      formatter: formatInteger,
    },
    {
      demographicKey: 'Population',
      zip: zipCodeData?.totalPopulationCount,
      msa: msaData?.totalPopulationCount,
      censusTract: censusTractData?.totalPopulationCount,
      block: blockData?.totalPopulationCount,
      formatter: formatInteger,
    },
    {
      demographicKey: 'Population / sq mi',
      zip: zipCodeData?.totalPopulationCount / metersSquaredToMilesSquared(zipCodeData?.area),
      msa: msaData?.totalPopulationCount / metersSquaredToMilesSquared(msaData?.area),
      censusTract: censusTractData?.totalPopulationCount ? (censusTractData.totalPopulationCount / metersSquaredToMilesSquared(censusTractData.area)) : null,
      block: blockData?.totalPopulationCount ? (blockData.totalPopulationCount / metersSquaredToMilesSquared(blockData.area)) : null,
      formatter: formatInteger,
    },
    {
      demographicKey: '5-Yr Proj Pop Growth',
      zip: zipCodeData && calcChange(zipCodeData, 'totalPopulationCount', 'population5YearForecast'),
      msa: msaData && calcChange(msaData, 'totalPopulationCount', 'population5YearForecast'),
      censusTract: censusTractData && calcChange(censusTractData, 'totalPopulationCount', 'population5YearForecast'),
      block: blockData && calcChange(blockData, 'totalPopulationCount', 'population5YearForecast'),
      isProjection: true,
    },
    {
      demographicKey: 'Avg Household Income',
      zip: zipCodeData?.averageHouseholdIncome,
      msa: msaData?.averageHouseholdIncome,
      censusTract: censusTractData?.averageHouseholdIncome,
      block: blockData?.averageHouseholdIncome,
      formatter: formatCurrency,
    },
    {
      demographicKey: '5-Yr Proj Avg Inc Growth',
      zip: zipCodeData && calcChange(zipCodeData, 'averageHouseholdIncome', 'averageHouseholdIncome5YearForecast'),
      msa: msaData && calcChange(msaData, 'averageHouseholdIncome', 'averageHouseholdIncome5YearForecast'),
      censusTract: censusTractData && calcChange(censusTractData, 'averageHouseholdIncome', 'averageHouseholdIncome5YearForecast'),
      block: blockData && calcChange(blockData, 'averageHouseholdIncome', 'averageHouseholdIncome5YearForecast'),
      isProjection: true,
    },
    {
      demographicKey: 'Median Household Income',
      zip: zipCodeData?.medianHouseholdIncome,
      msa: msaData?.medianHouseholdIncome,
      censusTract: censusTractData?.medianHouseholdIncome,
      block: blockData?.medianHouseholdIncome,
      formatter: formatCurrency,
    },
    {
      demographicKey: '5-Yr Proj Median Inc Growth',
      zip: zipCodeData && calcChange(zipCodeData, 'medianHouseholdIncome', 'medianHouseholdIncome5YearForecast'),
      msa: msaData && calcChange(msaData, 'medianHouseholdIncome', 'medianHouseholdIncome5YearForecast'),
      censusTract: censusTractData && calcChange(censusTractData, 'medianHouseholdIncome', 'medianHouseholdIncome5YearForecast'),
      block: blockData && calcChange(blockData, 'medianHouseholdIncome', 'medianHouseholdIncome5YearForecast'),
      isProjection: true,
    },
    {
      demographicKey: 'Median Age',
      zip: zipCodeData?.populationMedianAge,
      msa: msaData?.populationMedianAge,
      censusTract: censusTractData?.populationMedianAge,
      block: blockData?.populationMedianAge,
    },
    {
      demographicKey: '5-Yr Proj Med Age',
      zip: zipCodeData && calcChange(zipCodeData, 'populationMedianAge', 'medianAge5YearForecast'),
      msa: msaData && calcChange(msaData, 'populationMedianAge', 'medianAge5YearForecast'),
      censusTract: censusTractData && calcChange(censusTractData, 'populationMedianAge', 'medianAge5YearForecast'),
      block: blockData && calcChange(blockData, 'populationMedianAge', 'medianAge5YearForecast'),
      isProjection: true,
    },
    // NOTE: commenting out because Cherre data looks incorrect for these fields
    // {
    //   demographicKey: 'Unemployment Rate',
    //   zip: adjustedLaborForceUnemploymentRate(zipCodeData?.laborForceUnemploymentRate),
    //   msa: adjustedLaborForceUnemploymentRate(msaData?.laborForceUnemploymentRate),
    //   censusTract: censusTractData && adjustedLaborForceUnemploymentRate(censusTractData.laborForceUnemploymentRate),
    //   formatter: formatPercentage,
    // },
    // {
    //   demographicKey: '5-Yr Proj Unemployment Rate',
    //   zip: calcChange(zipCodeData, 'laborForceUnemploymentRate', 'laborForceUnemploymentRate5YearForecastCount'),
    //   msa: calcChange(msaData, 'laborForceUnemploymentRate', 'laborForceUnemploymentRate5YearForecastCount'),
    //   censusTract: censusTractData && calcChange(censusTractData, 'laborForceUnemploymentRate', 'laborForceUnemploymentRate5YearForecastCount'),
    //   isProjection: true,
    // },
    {
      demographicKey: 'Crime Index',
      zip: zipCodeData?.crimeTotalRisk,
      msa: msaData?.crimeTotalRisk,
      censusTract: censusTractData?.crimeTotalRisk,
      block: blockData?.crimeTotalRisk,
    },
  ];

  return organizedData;
}

export const useOrganizedDemographicsData = (data) => (
  useMemo(() => organizeDemographicsDataForComparison(data), [data])
);
