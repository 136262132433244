import { useMemo } from 'react';
import { compact, identity, mean, meanBy, sumBy } from 'lodash';
import { addWeeks, parseISO } from 'date-fns';
import { formatCurrency, formatCurrencyAbbreviated, formatPercentage } from 'components/utils';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import OverallMarketSummary from './OverallMarketSummary';

const inDiligenceFunc = (acquisitions, asOfDate) => acquisitions.filter(({ attributes: { inDiligenceAt, inClosingAt } }) => {
  const enteredDiligence = inDiligenceAt && (parseISO(inDiligenceAt) <= asOfDate);
  const leftDiligence = inClosingAt && (parseISO(inClosingAt) <= asOfDate);
  return enteredDiligence && !leftDiligence;
});

const inClosingFunc = (acquisitions, asOfDate) => acquisitions.filter(({ attributes: { closedAt, inClosingAt } }) => {
  const enteredClosing = inClosingAt && (parseISO(inClosingAt) <= asOfDate);
  const leftClosing = closedAt && (parseISO(closedAt) <= asOfDate);
  return enteredClosing && !leftClosing;
});

const closingInOneWeekFunc = (acquisitions, asOfDate) => acquisitions.filter(({ attributes: { closingDate, closedAt } }) => {
  const isClosed = closedAt && (parseISO(closedAt) <= asOfDate);
  const closingDateTimestamp = parseISO(closingDate);
  return (closingDateTimestamp >= asOfDate) && (closingDateTimestamp <= addWeeks(asOfDate, 1)) && !isClosed;
});

const metricsData = [
  { label: '# of Properties in Contract', statFunc: (acquisitions => acquisitions.length) },
  { label: '# in Contract - Diligence', statFunc: (acquisitions, asOfDate) => inDiligenceFunc(acquisitions, asOfDate).length },
  { label: '# in Contract - Hard', statFunc: (acquisitions, asOfDate) => inClosingFunc(acquisitions, asOfDate).length },
  { label: '# Closing This Week', statFunc: (acquisitions, asOfDate) => closingInOneWeekFunc(acquisitions, asOfDate).length },
  { label: '$ in Contract', statFunc: (acquisitions => sumBy(acquisitions, 'attributes.purchasePrice')), formatter: formatCurrencyAbbreviated },
  { label: '$ in Contract - Diligence', statFunc: (acquisitions, asOfDate) => sumBy(inDiligenceFunc(acquisitions, asOfDate), 'attributes.purchasePrice'), formatter: formatCurrencyAbbreviated },
  { label: '$ in Contract - Hard', statFunc: (acquisitions, asOfDate) => sumBy(inClosingFunc(acquisitions, asOfDate), 'attributes.purchasePrice'), formatter: formatCurrencyAbbreviated },
  { label: 'Avg. Purchase Price', statFunc: (acquisitions => meanBy(acquisitions, 'attributes.purchasePrice')), formatter: formatCurrencyAbbreviated },
  { label: 'Avg. UW Rent', statFunc: (acquisitions => meanBy(acquisitions, 'attributes.underwritingRent')), formatter: formatCurrency },
  { label: 'Estimated ARV', statFunc: (acquisitions => mean(compact(acquisitions.map(a => a.attributes.arv)))), formatter: formatCurrencyAbbreviated },
  { label: 'Estimated Basis', statFunc: (acquisitions => mean(compact(acquisitions.map(a => a.attributes.acquisitionBasis)))), formatter: formatCurrencyAbbreviated },
  { label: 'Estimated Built-in Equity', statFunc: (acquisitions => mean(compact(acquisitions.map(({ attributes: { arv, acquisitionBasis } }) => (arv && acquisitionBasis) && ((arv / acquisitionBasis) - 1))))), formatter: formatPercentage },
  { label: 'Honeycomb Market Value', statFunc: (acquisitions => mean(compact(acquisitions.map(a => a.attributes.honeycombMarketValue)))), formatter: formatCurrencyAbbreviated },
  { label: 'Honeycomb Rent', statFunc: (acquisitions => mean(compact(acquisitions.map(a => a.attributes.honeycombRent)))), formatter: formatCurrency },
]

const metricFunc = (label, statFunc, asOfDate, lastWeekDate, lastMonthDate, filteredAcquisitions, oneWeekAgoFilteredAcquisitions, oneMonthAgoFilteredAcquisitions, formatter = identity) => ({
  label,
  value: formatter(statFunc(filteredAcquisitions, asOfDate)),
  lastWeekValue: formatter(statFunc(oneWeekAgoFilteredAcquisitions, lastWeekDate)),
  lastMonthValue: formatter(statFunc(oneMonthAgoFilteredAcquisitions, lastMonthDate)),
});

export default function Overall({ asOfDate, lastWeekDate, lastMonthDate, filteredAcquisitions, oneWeekAgoFilteredAcquisitions, oneMonthAgoFilteredAcquisitions }) {
  const metrics = useMemo(
    () => metricsData.map(({ label, formatter, statFunc }) => metricFunc(label, statFunc, asOfDate, lastWeekDate, lastMonthDate, filteredAcquisitions, oneWeekAgoFilteredAcquisitions, oneMonthAgoFilteredAcquisitions, formatter)),
    [filteredAcquisitions],
  );

  return (
    <div>
      <ReturnMetricBoxes metrics={metrics} />
      <h2 className="text-lg leading-6 font-medium text-gray-900 mt-10 mb-4">Overall - Market Summary</h2>
      <OverallMarketSummary acquisitions={filteredAcquisitions} />
    </div>
  );
}
