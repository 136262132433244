import { createReducer } from '@reduxjs/toolkit';
import {
  clearDealNavigationModal,
  copyDealModal,
  downloadModelForScenario,
  editDealModal,
  generateDealSummaryForModel,
  generateLoiForDeal,
  generatePurchaseAgreementForDeal,
  markAsDeadModal,
  markAsWithdrawnModal,
  reopenDealModal,
  setModelParameterWarning,
  showDownloadModelModal,
  showGenerateDealSummaryModal,
  showGenerateLoiModal,
  showGeneratePurchaseAgreementModal,
  showRenameScenarioModal,
  showSaveChangesModal,
  showDealNotesModal,
  switchPortfolioModal,
  newBuildOfferModal,
} from 'actions/deal_navigation';

const initialState = {
  apiDomain: '',
  currentItem: null,
  currentScenario: null,
  showModelOutput: false,
  showDealActivity: false,
  hidePurchaseAgreementDownloadedMessage: false,
  hideLOIDownloadedMessage: false,
};

const modalInitialState = {};

const MODAL_ACTIONS = [
  copyDealModal,
  editDealModal,
  markAsDeadModal,
  markAsWithdrawnModal,
  reopenDealModal,
  setModelParameterWarning,
  'setShowCreateDealModal',
  showDownloadModelModal,
  showGenerateDealSummaryModal,
  showGenerateLoiModal,
  showGeneratePurchaseAgreementModal,
  showRenameScenarioModal,
  showSaveChangesModal,
  showDealNotesModal,
  switchPortfolioModal,
  newBuildOfferModal,
];

const MODAL_ASYNC_THUNKS = [
  generateDealSummaryForModel,
  generateLoiForDeal,
  generatePurchaseAgreementForDeal,
  downloadModelForScenario,
];

const modal = createReducer(modalInitialState, builder => {
  /* eslint-disable no-param-reassign */
  MODAL_ACTIONS.forEach(action => builder.addCase(action, (_, { payload }) => payload));

  MODAL_ASYNC_THUNKS.forEach(action => {
    builder
      .addCase(action.fulfilled, () => modalInitialState)
      .addCase(action.rejected, (state, { payload, error }) => {
        state.saving = false;
        if (error?.name === 'unauthorized') {
          state.alert = { type: 'unauthorized', text: error.message };
        } else {
          state.alert = { type: 'danger', text: payload ?? error.message };
        }
      });
  });

  builder
    .addCase(generateDealSummaryForModel.pending, state => {
      state.saving = true;
      state.alert = { type: 'info', text: 'Preparing deal summary' };
    })
    .addCase(generateLoiForDeal.pending, state => {
      state.saving = true;
      state.alert = { type: 'info', text: 'Preparing LOI' };
    })
    .addCase(generatePurchaseAgreementForDeal.pending, state => {
      state.saving = true;
      state.alert = { type: 'info', text: 'Preparing Purchase Agreement' };
    })
    .addCase(downloadModelForScenario.pending, state => {
      state.saving = true;
      state.alert = { type: 'info', text: 'Preparing model' };
    })

    .addCase(clearDealNavigationModal, () => modalInitialState)
    .addDefaultCase(() => {});
  /* eslint-enable no-param-reassign */
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'setHidePurchaseAgreementDownloadedMessage':
      return { ...state, hidePurchaseAgreementDownloadedMessage: action.payload.hidePurchaseAgreementDownloadedMessage };
    case 'setHideLOIDownloadedMessage':
      return { ...state, hideLOIDownloadedMessage: action.payload.hideLOIDownloadedMessage };
    case 'setSaving':
      return { ...state, saving: action.payload.saving };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
const navigation = (state = initialState, action) => ({
  ...rootReducer(state, action),
  modal: modal(state.modal, action),
});

export default navigation;
