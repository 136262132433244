import { useState } from 'react';
import {
  cloneDeep,
  first,
  last,
  groupBy,
  isFinite,
  map,
  partial,
  pullAt,
  pullAll,
  uniq,
  size,
} from 'lodash';
import Modal from 'components/Modal';
import { FormField } from 'components/Form';
import Button from 'components/Button';
import ChangeToMultifamilyModal from 'components/modals/ChangeToMultifamilyModal';
import { ToggleWithLabels } from '../Toggle';
import { TR } from '../Table';
import { parseEventValue } from '../utils';
import { standardizedUnits } from './units';

const COMBINED = 'combined';

function AddUnitItemRow({ onClick, units }) {
  const [showChangeToMultifamilyModal, setShowChangeToMultifamilyModal] = useState(false);

  const handleAddUnit = () => {
    if (size(units) === 1) setShowChangeToMultifamilyModal(true);
    else onClick();
  };

  const onCancel = () => setShowChangeToMultifamilyModal(false);

  const data = [
    <button
      type="button"
      className="bg-secondary-300 hover:bg-secondary-400 mx-2 my-2 px-2 pb-0.5 text-white rounded"
      onClick={handleAddUnit}
    >
      +
    </button>,
  ];

  return (
    <>
      {showChangeToMultifamilyModal && <ChangeToMultifamilyModal onContinue={onClick} onCancel={onCancel} />}
      <TR center data={data} />
    </>
  );
}

function RandomId() {
  return Math.random().toString(36).slice(2);
}

function SelectPropertyModal({ properties, property, setProperty, callAddUnit, setShowSelectPropertyModal }) {
  return (
    <Modal show showCloseAction={false}>
      <div className="w-96">
        <h3 className="text-2xl">Select Property</h3>
        <p className="text-sm mt-4">
          You need to select the property for the Combined Modelling
        </p>
        <FormField
          name="property"
          value={property}
          options={properties}
          type="select"
          padding="py-2 px-3 border border-black border-opacity-12"
          className="mt-6"
          onChange={(e) => setProperty(e.target.value)}
        />
        <div className="flow-root mt-4">
          <div className="float-right">
            <Button
              transparent
              text="Cancel"
              className="font-medium text-sm"
              shadow={false}
              onClick={() => setShowSelectPropertyModal(false)}
              submit={false}
            />
            <Button
              text="Continue"
              className="font-medium text-sm"
              onClick={callAddUnit}
              shadow={false}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

const defaultUnitItem = (property) => ({
  number: 1,
  address: property.address,
  rentGrowthRate: 0,
  releasingCost: 0,
  turnDowntime: 0,
});

export default function UnitsTable({
  dcfParams,
  onChange,
  header,
  UnitRow,
  AggregateRow,
  setActiveParameterTab,
  isRentRoll = false,
  paramsUnsaved,
  renovationToggle,
  properties,
}) {
  const isCombinedModelling = dcfParams.deal?.modellingMethod === COMBINED;
  const [showSelectPropertyModal, setShowSelectPropertyModal] = useState(false);
  const [property, setProperty] = useState(null);
  const { units, unitTypeEntry } = dcfParams;
  const unitsByType = groupBy(units, unit => [unit.address, unit.groupId, unit.group, unit.bedrooms, unit.bathrooms]);
  const unitTypeAggregates = map(
    unitsByType,
    unitsOfType => {
      const address = uniq(unitsOfType.map(unit => unit.address))[0];
      return Object.assign(cloneDeep(first(unitsOfType)), { number: unitsOfType.length, address });
    },
  );

  const onUnitTypeEntryChange = () => {
    if (paramsUnsaved
      && !window.confirm('Switching unit entry type will reset the current unit information. Would you like to continue?')) {
      return;
    }

    // ? need to refactor and increase speed
    if (!unitTypeEntry) {
      const updatedUnitGroups = Object.values(unitsByType).map(group => {
        const groupId = RandomId();
        // group.forEach(unit => { unit.groupId = groupId; });
        return group.map(unit => ({ ...unit, groupId }));
      });
      const updatedUnitsByType = groupBy(updatedUnitGroups.flat(), unit => [unit.groupId, unit.group, unit.bedrooms, unit.bathrooms]);
      setTimeout(() => {
        onChange({
          target: {
            name: 'units',
            value: standardizedUnits(updatedUnitsByType),
          },
        });
      }, 200);
    }
    setTimeout(() => {
      onChange({
        target: {
          name: 'unitTypeEntry',
          value: !unitTypeEntry,
        },
      });
    }, 400);
  };

  const addUnitItem = () => {
    const updatedUnitItems = cloneDeep(units);
    let lastUnitItem = updatedUnitItems[updatedUnitItems.length - 1];
    if (isCombinedModelling) {
      const updatedUnitItemsByAddress = updatedUnitItems.filter(updatedUnitItem => updatedUnitItem.address === property);
      lastUnitItem = updatedUnitItemsByAddress[updatedUnitItemsByAddress.length - 1];
    }
    const clonedItem = lastUnitItem ? { ...lastUnitItem, number: lastUnitItem.number + 1 } : defaultUnitItem(dcfParams.property);
    updatedUnitItems.push(clonedItem);
    onChange({
      target: {
        name: 'units',
        value: updatedUnitItems,
      },
    });
  };

  const removeUnitItem = (index) => {
    const updatedUnitItems = cloneDeep(units);
    if (unitTypeEntry) {
      const matchedItems = updatedUnitItems.filter(unit => (unit.groupId === unitTypeAggregates[index].groupId && unit.group === unitTypeAggregates[index].group));
      pullAll(updatedUnitItems, matchedItems);
    } else {
      pullAt(updatedUnitItems, [index]);
    }
    onChange({
      target: {
        name: 'units',
        value: updatedUnitItems,
      },
    });
  };

  const splitUnitRow = () => {
    const updatedUnitItems = cloneDeep(units);
    let lastUnitItem = updatedUnitItems[updatedUnitItems.length - 1];
    if (isCombinedModelling) {
      const updatedUnitItemsByAddress = updatedUnitItems.filter(updatedUnitItem => updatedUnitItem.address === property);
      lastUnitItem = updatedUnitItemsByAddress[updatedUnitItemsByAddress.length - 1];
    }
    const clonedItem = { ...lastUnitItem, group: '', bedrooms: 0, number: lastUnitItem.number + 1, bathrooms: 0, rsf: 0, groupId: RandomId() };
    updatedUnitItems.push(clonedItem);
    onChange({
      target: {
        name: 'units',
        value: updatedUnitItems,
      },
    });
  };

  const onUnitChange = (index, event) => {
    const updatedUnits = cloneDeep(units);
    const field = event.target.name;
    let newValue = parseEventValue(event);

    const changeSet = {};

    if (field === 'rollToMarket' && (!isFinite(newValue) || newValue < 0)) {
      newValue = 0;
    } else if (field === 'vacant' && newValue === true) {
      changeSet.rentControlled = false;
      changeSet.inPlaceRent = 0;
    } else if (field === 'turnBudget') {
      if (newValue > 0) {
        changeSet.rentControlled = false;
      } else {
        changeSet.turnDowntime = 0;
      }
    } else if (field === 'inPlaceRent' && newValue === 0) {
      changeSet.rentControlled = false;
    }

    changeSet[field] = newValue;

    if (unitTypeEntry) {
      const unitTypeGroups = cloneDeep(unitTypeAggregates);
      updatedUnits
        .filter(unit => (unit.groupId === unitTypeGroups[index].groupId && unit.group === unitTypeGroups[index].group))
        .forEach(unit => Object.assign(unit, changeSet));
    } else {
      Object.assign(updatedUnits[index], changeSet);
    }
    onChange({
      target: {
        name: 'units',
        value: updatedUnits,
      },
    });
  };

  const onUnitDecrease = (index) => {
    const updatedUnits = cloneDeep(units);
    const unitTypeGroups = cloneDeep(unitTypeAggregates);
    const matchedUnits = updatedUnits.filter(unit => (unit.groupId === unitTypeGroups[index].groupId && unit.group === unitTypeGroups[index].group));
    matchedUnits.forEach(unit => Object.assign(unit, { index: updatedUnits.indexOf(unit) }));
    updatedUnits.splice(last(matchedUnits).index, 1);

    onChange({
      target: {
        name: 'units',
        value: updatedUnits,
      },
    });
  };

  const onUnitIncrease = (index) => {
    const updatedUnits = cloneDeep(units);
    const unitTypeGroups = cloneDeep(unitTypeAggregates);
    const matchedUnits = updatedUnits.filter(unit => (unit.groupId === unitTypeGroups[index].groupId && unit.group === unitTypeGroups[index].group));
    matchedUnits.forEach(unit => Object.assign(unit, { index: updatedUnits.indexOf(unit) }));
    updatedUnits.splice(last(matchedUnits).index, 0, { ...last(matchedUnits), number: '' });

    onChange({
      target: {
        name: 'units',
        value: updatedUnits,
      },
    });
  };

  const selectProperty = () => {
    if (isCombinedModelling) {
      setShowSelectPropertyModal(true);
    }
  };

  const callAddUnit = () => {
    setShowSelectPropertyModal(false);
    const func = isRentRoll && !unitTypeEntry ? addUnitItem : splitUnitRow;
    func();
  };

  const displayUnits = unitTypeEntry ? unitTypeAggregates : units;

  return (
    <>
      { showSelectPropertyModal && (
      <SelectPropertyModal
        callAddUnit={callAddUnit}
        property={property}
        properties={properties?.map(({ address }) => [address, address])}
        setProperty={setProperty}
        setShowSelectPropertyModal={setShowSelectPropertyModal}
      />
      )}
      {(isRentRoll && units.length > 1) && (
        <ToggleWithLabels
          className="ml-5"
          label1="Unit"
          label2="Group"
          checked={unitTypeEntry}
          onClick={onUnitTypeEntryChange}
        />
      )}
      <table className=" mx-auto divide-y-2 divide-gray-200 border-b-2 bg-white">
        <thead className="bg-gray-50">
          {header}
        </thead>
        <tbody>
          {displayUnits.map((unit, index) => (
            <UnitRow
              key={index}
              unit={unit}
              onChange={partial(onUnitChange, index)}
              onRemove={partial(removeUnitItem, index)}
              onUnitDecrease={partial(onUnitDecrease, index)}
              onUnitIncrease={partial(onUnitIncrease, index)}
              unitTypeEntry={unitTypeEntry}
              dcfParams={dcfParams}
              setActiveParameterTab={setActiveParameterTab}
              renovationToggle={renovationToggle}
            />
          ))}
          {
            isRentRoll && <AddUnitItemRow units={units} onClick={isCombinedModelling ? selectProperty : (isRentRoll && !unitTypeEntry ? addUnitItem : splitUnitRow)} />
          }
        </tbody>
        {displayUnits.length > 0 && (
          <tfoot>
            <AggregateRow units={units} />
          </tfoot>
        )}
      </table>
    </>
  );
}