import { last, sortBy, sum, sumBy } from 'lodash';
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { formatCurrency, titleCase } from 'components/utils';

const PRIMARY_COLOR = '#002D6F';
const SECONDARY_COLOR = '#34d399';
const COLORS = [PRIMARY_COLOR, SECONDARY_COLOR, '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;
const PERCENT_THRESHOLD = 0.03;

export default function ExpensePieChart({ actualCashFlows, getTrailingYearActualCashFlows }) {
  const paths = [];
  Object.keys(actualCashFlows.items.controllableItems).forEach(key => paths.push(`items.controllableItems.${key}`));
  Object.keys(actualCashFlows.items.nonControllableItems).forEach(key => paths.push(`items.nonControllableItems.${key}`));

  const data = sortBy(paths.map(path => ({
    name: path,
    value: sum(getTrailingYearActualCashFlows(path)),
  })), 'value');

  const total = sumBy(data, 'value');

  const renderLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
  }) => {
    const percent = data[index].value / total;
    if (percent < PERCENT_THRESHOLD) {
      return null;
    }
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        className="text-xs"
        x={x}
        y={y}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {titleCase(last(data[index].name.split('.')))}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={240}>
      <PieChart data={data} margin={{ top: 20, left: 100, bottom: 20, right: 100 }}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          dataKey="value"
          label={renderLabel}
          labelLine={false}
          isAnimationActive={false}
        >
          {data.map((_, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value, name) => [formatCurrency(value), titleCase(last(name.split('.')))]} />
      </PieChart>
    </ResponsiveContainer>
  );
}
