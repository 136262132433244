import cx from 'classnames';

export default function ListingStat({ value, label, loading = false }) {
  return (
    <div className="flex-1 px-3 text-center whitespace-nowrap">
      <div className={cx('text-sm font-medium text-black', { invisible: loading })}>{value ?? '-'}</div>
      <div className="text-xs text-neutral font-medium tracking-wide">{label}</div>
    </div>
  );
}
