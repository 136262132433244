import { useEffect, useRef } from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

export default function TabPanel({ heading, width, children, className }) {
  const panelRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    panelRef.current?.scroll(0, 0);
  }, [location.pathname]);

  return (
    <div ref={panelRef} className="w-min h-full pl-16 py-4 overflow-auto">
      <div className={cx('my-4', width ?? 'w-200')}>{heading}</div>
      <div className={cx(width ?? 'w-200', className)}>
        {children}
      </div>
    </div>
  );
}
