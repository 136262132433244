import cx from 'classnames';
import { Arrow } from 'components/icons';
import { formatPercentage } from 'components/utils';

export default function PercentChange({ value }) {
  const sign = Math.sign(value);

  return (
    <div
      className={cx(
        'flex gap-x-1 justify-center items-center rounded-lg px-1.5 py-1',
        {
          'bg-success-100': sign === 1,
          'bg-error-100': sign === -1,
          'bg-neutral-100': sign === 0,
        },
      )}
    >
      {(Number.isFinite(sign) && sign !== 0) && (
        <Arrow
          direction={sign === 1 ? 'up' : 'down'}
          className={cx('w-4 fill-current', { 'text-success-500': sign === 1, 'text-error-500': sign === -1 })}
        />
      )}
      <div>{formatPercentage(Math.abs(value))}</div>
    </div>
  );
}
