import { partial } from 'lodash';
import AllFilters from 'components/saleComps/AllFilters';
import { DEFAULT_SALE_COMP_FILTERS, useFilterComponents } from 'components/saleComps/saleComps';

export default function FilterBar({ filters, filterContext, setFilter, setColumnFilters }) {
  return (
    <div className="flex flex-wrap gap-2 items-center">
      {useFilterComponents(filterContext).map(([FilterComponent, filterKey, props], index) => (
        <FilterComponent
          {...props}
          key={`${filterKey}-${index}`}
          filters={filters}
          filterParams={filters.find(({ id }) => id === filterKey)?.value}
          onApply={partial(setFilter, filterKey)}
          onReset={() => setFilter(filterKey, DEFAULT_SALE_COMP_FILTERS[filterKey])}
          filterKey={filterKey}
          setFilter={setFilter}
          setColumnFilters={setColumnFilters}
        />
      ))}
      <AllFilters filters={filters} setFilter={setFilter} />
    </div>
  );
}
