import { identity } from 'lodash';
import { useCallback } from 'react';

/**
 * @template T
 * @param {(T) => string} [formatter]
 * @param {string} [suffix]
 * @return {function([T, T]=): string}
 */
const useRangeDisplayValue = ({ formatter = identity, suffix = '' } = {}) => (
  useCallback(([rangeMin, rangeMax] = []) => {
    let filteredLabel;
    if (rangeMin && rangeMax) {
      filteredLabel = `${formatter(rangeMin)} - ${formatter(rangeMax)}`;
    } else if (rangeMin) {
      filteredLabel = `${formatter(rangeMin)}+`;
    } else {
      filteredLabel = `Up to ${formatter(rangeMax)}`;
    }
    return `${filteredLabel}${suffix}`;
  }, [formatter, suffix])
);

export default useRangeDisplayValue;
