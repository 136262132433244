import { Card } from 'components/common/Card';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { filteredLeases } from 'selectors/lease';
import CanvasJSReact from '@canvasjs/react-charts';

const { CanvasJSChart } = CanvasJSReact;

function Historical() {
  const [dataPoints, setDataPoints] = useState([]);
  const leaseData = useSelector(filteredLeases);

  useEffect(() => {
    fetch('https://canvasjs.com/data/gallery/react/nifty-stock-price.json')
      .then(response => response.json())
      .then(data => {
        const points = data.map(dp => ({
          x: new Date(dp.x),
          y: dp.y,
        }));
        setDataPoints(points);
      });
  }, []);

  const options = {
    theme: 'light2',
    data: [
      {
        type: 'line',
        xValueFormatString: 'MMM YYYY',
        yValueFormatString: '#,##0.00',
        dataPoints,
      },
    ],
  };

  const charts = [
    { header: 'Occupancy' },
    { header: 'Avg. DOM' },
    { header: 'Closing Ratio' },
    { header: 'Stabilized Occupancy' },
    { header: 'Rent Growth - New Leases' },
    { header: 'Avg. Days to List' },
    { header: '# of Properties - DOM > 30' },
    { header: 'Open Applications' },
    { header: 'Available Units' },
  ];

  if (leaseData.length === 0) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-center border border-gray-300 rounded-lg p-4 max-w-md mx-auto">
          <p className="text-lg font-semibold text-gray-700">
            No Data Available
          </p>
          <p className="text-gray-500">
            There are no available properties matching your criteria.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col mt-4 h-full">
      <div className="flex-1 mt-4 pb-20 overflow-y-auto mb-5 px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-x-3 gap-y-6">
          {charts.map((chart, index) => (
            <Card header={chart.header} key={index}>
              <CanvasJSChart options={options} />
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Historical;
