import { annualizeMonthlyReturns } from 'components/finance';
import { formatCurrency } from 'components/utils';
import { sum } from 'lodash';

const randomKey = () => (Math.random() + 1).toString(36).substring(7);

const prepareRowData = (rowData, annualize, referenceDates) => {
  const {
    annualizeFunction = sum,
    data: rawData,
    formatter = formatCurrency,
    showYear0,
  } = rowData;
  let data = rawData;

  if (!data) {
    return;
  }

  const holdPeriodInMonths = annualize ? (referenceDates.length - 1) * 12 : referenceDates.length - 13;

  // add a null first value to all data rows that don't contain a month 0 value
  if ((data.length !== (holdPeriodInMonths + 1)) && (data.length !== (holdPeriodInMonths - 11))) {
    data = [null, ...data];
  }

  // if viewing cashflows annualized, apply annualize function to each year of data
  if (annualize) {
    data = [
      data[0],
      ...annualizeMonthlyReturns(data.slice(1), holdPeriodInMonths, annualizeFunction),
    ];
  }

  // format data values
  data.forEach((v, index) => {
    // eslint-disable-next-line no-param-reassign
    rowData[referenceDates[index]] = (index === 0 && !showYear0) ? null : formatter(v);
  });
};

export default function renderCashFlowRow(rowData, annualize, referenceDates) {
  const modifiedRowData = { ...rowData };
  modifiedRowData.key = modifiedRowData.label;

  prepareRowData(modifiedRowData, annualize, referenceDates);

  modifiedRowData.children?.forEach(child => {
    // eslint-disable-next-line no-param-reassign
    child.key = randomKey();

    prepareRowData(child, annualize, referenceDates);

    child.children?.forEach(subChild => {
      // eslint-disable-next-line no-param-reassign
      subChild.key = randomKey();

      prepareRowData(subChild, annualize, referenceDates);
    });
  });

  return modifiedRowData;
}
