import { useEffect, useState } from 'react';
import { Fullscreen } from 'components/icons';
import MapModal from './MapModal';

function setPanorama(element, property) {
  const ll = new google.maps.LatLng(property.latitude, property.longitude);
  const svs = new google.maps.StreetViewService();

  svs.getPanorama({ location: ll, preference: 'nearest' }, (data, status) => {
    const pos = data.location.latLng;
    const head = google.maps.geometry.spherical.computeHeading(pos, ll);
    const panorama = new google.maps.StreetViewPanorama(
      element,
      {
        pano: data.location.pano,
        pov: { heading: head, pitch: 0 },
        fullscreenControl: false,
      },
    );
  });
}

export default function StreetView({ property, setShowMapModal, showMapModal }) {
  const [showStreetViewModal, setShowStreetViewModal] = useState(false);

  useEffect(() => {
    setPanorama(document.getElementById('street-view'), property);
  }, []);

  const closeMapModal = () => {
    setShowMapModal(false);
    setShowStreetViewModal(false);
  };

  return (
    <div className="rounded-lg cursor-pointer relative h-full w-full">
      <div className="w-full h-full rounded-lg" id="street-view" />
      <Fullscreen
        className="z-30 absolute top-4 right-2 cursor-pointer bg-white h-6 w-6"
        onClick={() => {
          setShowMapModal(true);
          setShowStreetViewModal(true);
        }}
      />
      {(showMapModal && showStreetViewModal) && <MapModal defaultActiveTab="Street View" setShowMapModal={closeMapModal} showMapModal={showMapModal} property={property} />}
    </div>
  );
}
