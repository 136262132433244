import { useState } from 'react';
import { FormField } from 'components/Form';
import { parseEventValue } from 'components/utils';
import Alert from 'components/Alert';
import Button from 'components/shared/NewButton';
import { usersPasswordPath } from 'components/routes';
import LogoPath from 'assets/full_logo_white.png'

export default function NewPasswordForm() {
  const [alert, setAlert] = useState(null);
  const [email, setEmail] = useState('');
  const [isRecovering, setIsRecovering] = useState(false);

  const recoverPassword = () => {
    setAlert(null);
    if (isRecovering || (!email)) {
      return;
    }
    setIsRecovering(true);

    const csrfToken = document.querySelector('[name=csrf-token]').content;
    fetch(usersPasswordPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
      body: JSON.stringify({ email }),
    }).then(response => response.json()).then(json => {
      setAlert({ type: 'info', text: json.message });
      setIsRecovering(false);
      setEmail('');
    }).catch(error => {
      console.error(error);
      setIsRecovering(false);
      setAlert({ type: 'danger', text: `Password Reset Failed: ${error.message}` });
    });
  };

  return (
    <div className="w-96 bg-white rounded shadow">
      <div className="w-full bg-black py-3">
        <img
          src={LogoPath}
          alt="logo"
          className="flex items-center h-12"
        />
      </div>
      <div className="p-8">
        <p className="mb-6 text-gray-900 text-body-lg">Enter the email of your Honeycomb user account to reset your password</p>
        <FormField
          name="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(parseEventValue(e))}
        />
        {alert && <Alert className="mt-3" {...alert} />}
        <div className="mt-12 flex flex-row justify-between">
          <Button outlined label="Back" onClick={() => window.location.href = '/'} />
          <Button filled label="Send Reset Link" onClick={recoverPassword} isLoading={isRecovering} />
        </div>
      </div>
    </div>
  );
}
