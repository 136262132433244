import { useState } from 'react';
import classNames from 'classnames';

function HoverInfo({ data, show = false }) {
  if (!show) return null;
  return (
    <div className="absolute top-full mt-2 px-4 py-2 bg-neutral-medium rounded-lg text-white shadow-lg w-max cursor-default before:absolute before:inset-0 before:-top-2">
      { data.map(d => (
        <div key={d.label} className="grid grid-cols-2">
          { d.label && <div className="text-xs font-semibold justify-self-start">{`${d.label}: `}</div> }
          <div className="text-xs font-normal justify-self-end">{d.value}</div>
        </div>
      )) }
    </div>
  );
}

export default function HoverChip({ label, hoverInfo, onFocus, width = 'w-max', color = 'neutral' }) {
  const [show, setShow] = useState(false);

  const baseColor = {
    green: 'bg-green-500 hover:bg-green-400',
    red: 'text-error bg-error-100 hover:bg-error-400',
    blue: 'bg-primary-focus hover:bg-primary-light',
    neutral: 'bg-neutral-focus hover:bg-neutral-light border',
  }[color];

  const clazz = classNames(
    'relative flex text-xs cursor-pointer justify-center items-center text-center rounded-lg px-3 py-2 focus:outline-none active:outline-none select-none z-50',
    width,
    baseColor,
  );

  return (
    <div className={clazz} onMouseOver={() => setShow(true)} onMouseLeave={() => setShow(false)} onFocus={onFocus}>
      {label}
      <HoverInfo data={hoverInfo} show={show} />
    </div>
  );
}
