import cx from 'classnames';

export default function RadioInput({ className, checked, id, onChange }) {
  return (
    <input
      checked={checked}
      id={id}
      onChange={onChange}
      type="radio"
      className={cx(className, 'w-4 h-4 cursor-pointer')}
    />
  );
}
