import { dotProductBy, formatCurrency } from 'components/utils';
import MuteZero from './MuteZero';
import { getVisibleDeliveryScheduleColumnIds } from './subdivision';

export default function OfferRentFooter({ table, column }) {
  const qtyCols = getVisibleDeliveryScheduleColumnIds({ table });

  const total = dotProductBy(
    table.getRowModel().flatRows,
    ({ getValue }) => qtyCols.reduce((tot, colId) => tot + (getValue(colId) || 0), 0),
    ({ getValue }) => getValue(column.id),
  );
  return <MuteZero value={total} formatter={formatCurrency} />;
}
