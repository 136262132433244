import Input from 'components/Input';
import { parseEventValue } from 'components/utils';
import { compact } from 'lodash';

export default function Range({ value, label, onChange, type = 'number' }) {
  function handleRangeChange(event) {
    const { name } = event.target;

    const parsedVal = parseEventValue(event);

    const updatedRange = [...(value ?? [null, null])];
    if (name === 'min') {
      updatedRange[0] = parsedVal;
    } else if (name === 'max') {
      updatedRange[1] = parsedVal;
    }

    if (compact(updatedRange).length) {
      onChange(updatedRange);
    }
  }

  const clear = () => {
    onChange([]);
  };

  const hasValue = compact(value).length;
  return (
    <div className="w-full flex flex-col mb-6">
      <div className="w-full flex flex-row justify-between items-center">
        <span className="font-medium text-sm">{ label }</span>
        { hasValue ? <span className="font-medium text-sm text-red-400 cursor-pointer" onClick={clear}>clear</span> : '' }
      </div>
      <div className="w-full mt-2 flex flex-wrap justify-between items-center">
        <Input
          className="px-2 py-3 outline-none"
          border
          onChange={(e) => handleRangeChange(e)}
          placeholder="No min"
          name="min"
          type={type}
          value={value?.[0] ?? ''}
          width="w-2/5"
        />
        -
        <Input
          className="px-2 py-3 outline-none"
          border
          onChange={(e) => handleRangeChange(e)}
          placeholder="No max"
          name="max"
          type={type}
          value={value?.[1] ?? ''}
          width="w-2/5"
        />
      </div>
    </div>
  );
}
