import { useLayoutEffect } from 'react';
import useResizeObserver from 'hooks/useResizeObserver';

export default function useElementHeight(elementRef) {
  if (!elementRef) {
    throw new Error('elementRef is required');
  }
  const [{ blockSize: height }, refCallback] = useResizeObserver({ observeBlockSize: true });

  // TODO: pass refCallback to caller directly, if elementRef is modified outside of a render cycle useLayoutEffect won't be triggered
  //       https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  useLayoutEffect(() => {
    refCallback(elementRef.current);
  }, [elementRef.current]);

  return height;
}
