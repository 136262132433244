import { useNodeState, useSubtree } from 'components/shared/TreeProvider/TreeProvider';
import { get, isFunction } from 'lodash';
import { useCallback, useMemo } from 'react';

export const useFilterLabel = () => {
  const [{ label }] = useNodeState();
  return label;
};

export const useFilterId = () => {
  const [{ filterId }] = useNodeState();
  return filterId;
};

export const useFilterIsSelected = () => {
  const [{ selected }] = useNodeState();
  return selected;
};

export const useFilterSubtreeStates = () => {
  const { flatNodes } = useSubtree();

  return useMemo(() => {
    let cache;

    return () => {
      if (cache !== undefined) {
        return cache;
      }

      cache = Object.fromEntries(
        Object
          .entries(flatNodes())
          .filter(([, { filterId }]) => filterId !== undefined)
          .map(([key, { filterState: value }]) => [key, value]),
      );
      return cache;
    };
  }, [flatNodes]);
};

/**
 * @return {string[]}
 */
export const useSelectedFilters = () => {
  const { flatNodes } = useSubtree();

  return useMemo(() => (
    Object
      .entries(flatNodes())
      .filter(([, { selected, filterId }]) => selected && (filterId !== undefined))
      .map(([, { filterId }]) => filterId)
  ), [flatNodes]);
};

const noValueSymbol = Symbol('');

/**
 * @return {function({ value?: any }?): void}
 */
export const useOnApply = () => {
  const [{ onApply, filterState }] = useNodeState();
  const subtreeFilterStates = useFilterSubtreeStates();

  return useCallback(({ value } = { value: noValueSymbol }) => {
    const stateToApply = value === noValueSymbol ? filterState : value;
    onApply?.(stateToApply, subtreeFilterStates());
  }, [onApply, filterState, subtreeFilterStates]);
};

export const useOnClear = () => {
  const [{ onClear }] = useNodeState();
  const subtreeFilterStates = useFilterSubtreeStates();

  return useCallback(() => {
    onClear?.(Object.keys(subtreeFilterStates()));
  }, [onClear, subtreeFilterStates]);
};

export const useOnReset = () => {
  const [{ onReset }] = useNodeState();
  const subtreeFilterStates = useFilterSubtreeStates();

  return useCallback(() => {
    onReset?.(Object.keys(subtreeFilterStates()));
  }, [onReset, subtreeFilterStates]);
};

export const useClearUncommittedFilters = () => {
  const [, setNodeState] = useNodeState();
  return useCallback(() => {
    setNodeState((prev) => {
      if (prev === undefined) {
        return prev;
      }

      const { filterState, initialFilterState } = prev;
      if (Object.is(filterState, initialFilterState)) {
        return prev;
      }

      return {
        ...prev,
        filterState: initialFilterState,
      };
    });
  }, [setNodeState]);
};

export const useFilterDisplayValue = () => {
  const [{ displayValue, staticDisplayValue, initialFilterState }] = useNodeState();

  const fallbackDisplayValue = staticDisplayValue ?? initialFilterState;
  if (displayValue === undefined) {
    return fallbackDisplayValue;
  }

  if (isFunction(displayValue)) {
    return displayValue(initialFilterState);
  }

  return get(initialFilterState, displayValue, fallbackDisplayValue);
};
