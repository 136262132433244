/* eslint-disable consistent-return */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Packer } from 'docx';
import { calcCashFlows } from 'components/dcf/dcf';
import DealSummaryCreator from 'components/deal/summary-report';
import {
  generateLoiPath,
  generatePurchaseAgreementPath,
  scenarioExportPath,
} from 'components/routes';
import { downloadBlob, downloadableFileName, snakeCaseKeys } from 'components/utils';
import axios from 'utils/axios';

export const setHidePurchaseAgreementDownloadedMessage = () => ({
  type: 'setHidePurchaseAgreementDownloadedMessage',
  payload: { hidePurchaseAgreementDownloadedMessage: true },
});

export const setHideLOIDownloadedMessage = () => ({
  type: 'setHideLOIDownloadedMessage',
  payload: { hideLOIDownloadedMessage: true },
});

export const setShowCreateDealModal = (show = false) => ({
  type: 'setShowCreateDealModal',
  payload: { showCreateDealModal: show },
});

export const showSaveChangesModal = createAction(
  'navigation/saveChangesModal',
  (show, cancel, doNotSave, save) => ({ payload: { showSaveChangesModal: show, cancel, doNotSave, save } }),
);

export const showRenameScenarioModal = createAction(
  'navigation/renameScenarioModal',
  (show, scenario) => ({ payload: { showRenameScenarioModal: show, scenario } }),
);

export const showDealNotesModal = createAction(
  'navigation/dealNotesModal',
  (show, dealId, scenarioId) => ({ payload: { showDealNotesModal: show, dealId, scenarioId } }),
);

export const showGenerateDealSummaryModal = createAction(
  'navigation/showGenerateDealSummaryModal',
  (dealId, scenarioId) => ({ payload: { showGenerateDealSummaryModal: true, dealId, scenarioId } }),
);

export const showGenerateLoiModal = createAction(
  'navigation/generateLoiModal',
  (deal) => ({ payload: { showGenerateLoiModal: true, deal } }),
);

export const showGeneratePurchaseAgreementModal = createAction(
  'navigation/generatePurchaseAgreementModal',
  (dealId, scenarioId) => ({ payload: { showGeneratePurchaseAgreementModal: true, dealId, scenarioId } }),
);

export const showDownloadModelModal = createAction(
  'navigation/downloadModelModal',
  (deal, scenarioId) => ({ payload: { showDownloadModelModal: true, deal, scenarioId } }),
);

export const editDealModal = createAction('navigation/editDealModal', deal => ({ payload: { showEditDealModal: true, deal } }));

export const markAsDeadModal = createAction(
  'navigation/markAsDeadModal',
  () => ({ payload: { showMarkAsDeadModal: true } }),
);

export const markAsWithdrawnModal = createAction(
  'navigation/markAsWithdrawnModal',
  () => ({ payload: { showMarkAsWithdrawnModal: true } }),
);

export const reopenDealModal = createAction(
  'navigation/reopenDealModal',
  () => ({ payload: { showReopenDealModal: true } }),
);

export const copyDealModal = createAction(
  'navigation/copyDealModal',
  () => ({ payload: { copyDealModal: true } }),
);

export const switchPortfolioModal = createAction(
  'navigation/switchPortfolioModal',
  deal => ({ payload: { switchPortfolioModal: true, deal } }),
);

export const newBuildOfferModal = createAction('navigation/createNewBuildOfferModal', () => ({ payload: { showNewBuildOfferModal: true } }));

export const clearDealNavigationModal = createAction('navigation/clearDealNavigationModal');

export const setModelParameterWarning = createAction(
  'navigation/modelParameterWarning',
  (show) => ({ payload: { showModelParameterWarning: show } }),
);

export const setSaving = (isSaving = false) => ({
  type: 'setSaving',
  payload: { saving: isSaving },
});

export const generateDealSummaryForModel = createAsyncThunk(
  'navigation/generateDealSummaryForModel',
  async (args, { signal }) => {
    const { dcfParams, deal, listing, property, properties, scenario } = args;
    let dealSummary;
    try {
      const frontEndCashFlows = calcCashFlows(dcfParams);
      const creator = new DealSummaryCreator(
        frontEndCashFlows,
        dcfParams,
        deal,
        listing,
        property,
        properties,
        scenario,
        signal,
      );
      dealSummary = await creator.create();
    } catch (e) {
      console.error(e);
      throw e;
    }

    const docxBlob = await Packer.toBlob(await dealSummary);

    if (!signal.aborted) {
      const dealName = deal.data.attributes.name;
      const fileName = downloadableFileName(dealName, 'Deal Summary', 'docx');
      downloadBlob(docxBlob, document, fileName);
    }
  },
);

export const generateLoiForDeal = createAsyncThunk(
  'navigation/generateLoiById',
  async (deal, { signal, rejectWithValue }) => {
    let loi;
    try {
      loi = await axios.get(generateLoiPath(deal), {
        responseType: 'blob',
        signal,
      });
    } catch (e) {
      console.error(e);

      if (e.response) {
        const { error } = JSON.parse(await e.response.data.text());
        return rejectWithValue(`${e.response.status} ${error}`);
      }

      throw e;
    }
    const filename = downloadableFileName(deal.name, 'LOI', 'docx');
    downloadBlob(loi.data, document, filename);
  },
  {
    condition: (_, { getState }) => {
      const { navigation: { modal: { isSaving } } } = getState();
      return !isSaving;
    },
  },
);

export const generatePurchaseAgreementForDeal = createAsyncThunk(
  'navigation/generatePurchaseAgreementForDeal',
  async (args, { signal, rejectWithValue }) => {
    const { deal, scenario, documentTemplateId, address } = args;

    let purchaseAgreement;
    try {
      purchaseAgreement = await axios.get(generatePurchaseAgreementPath(deal.id, scenario.id), {
        params: snakeCaseKeys({ documentTemplateId }),
        responseType: 'blob',
        signal,
      });
    } catch (e) {
      console.error(e);

      if (e.response) {
        const { error } = JSON.parse(await e.response.data.text());
        return rejectWithValue(`${e.response.status} ${error}`);
      }

      throw e;
    }
    downloadBlob(
      purchaseAgreement.data,
      document,
      downloadableFileName(address, 'PurchaseAgreement', 'pdf'),
    );
  },
  {
    condition: (_, { getState }) => {
      const { navigation: { modal: { isSaving } } } = getState();
      return !isSaving;
    },
  },
);

export const downloadModelForScenario = createAsyncThunk(
  'navigation/downloadModelForScenario',
  async (args, { signal, rejectWithValue }) => {
    const { deal, scenario } = args;
    let modelXlsx;
    try {
      modelXlsx = await axios.get(scenarioExportPath(scenario), {
        responseType: 'blob',
        signal,
      });
    } catch (e) {
      console.error(e);

      if (e.response) {
        const { error } = JSON.parse(await e.response.data.text());
        return rejectWithValue(`${e.response.status} ${error}`);
      }

      throw e;
    }

    if (!signal.aborted) {
      const fileName = downloadableFileName(`${deal.name} ${scenario.name}`, 'Model', 'xlsx');
      downloadBlob(modelXlsx.data, document, fileName);
    }
  },
  {
    condition: (_, { getState }) => {
      const { navigation: { modal: { isSaving } } } = getState();
      return !isSaving;
    },
  },
);
