import { Dialog, Transition } from '@headlessui/react';
import { useCallback, useRef } from 'react';
import { useCreatePortalSlot } from 'components/shared/LayoutSlots';
import Button from 'components/shared/NewButton';
import { ArrowBack } from 'components/icons';

import { useFilterForm } from './useFilterForm';

/**
 * @param {boolean} show
 * @param {() => void} close
 */
export default function AllFiltersModal({ show, close }) {
  const initialFocusRef = useRef({
    focus() {
      // TODO: initialFocus should focus first filter
    },
  });

  const refCallback = useCreatePortalSlot('sideSheet');

  const {
    formProps,
    applyButtonProps,
    resetButtonProps,
    clearButtonProps,
  } = useFilterForm(close);

  return (
    <Transition
      as={Dialog}
      appear
      show={show}
      onClose={close}
      className="z-50"
      initialFocus={initialFocusRef}
    >
      <Transition.Child
        as="div"
        enter="ease-out duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed inset-0 bg-black/25"
      />

      <div className="fixed inset-0 z-50 overflow-clip">
        <Transition.Child
          as={Dialog.Panel}
          enter="ease-out duration-75 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="ease-in duration-150 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
          className="relative flex flex-col h-full w-128 rounded-l-2xl ml-auto pt-6 *:pr-6 *:pl-4 bg-white"
        >
          {/* TODO: use grid area */}
          <div className="sticky inset-x-0 top-0 flex flex-row gap-x-3 pb-6 text-xl bg-inherit">
            <button type="button" aria-label="Back" onClick={close}>
              <ArrowBack className="h-full w-auto" />
            </button>
            <Dialog.Title>All Filters</Dialog.Title>
          </div>

          <form
            {...formProps}
            ref={refCallback}
            className="flex flex-col gap-y-2 overflow-auto"
          />

          <div className="flex flex-row gap-x-3 py-6 border-t mt-auto">
            <Button
              {...clearButtonProps}
              textOnly
              outlined
              label="Clear All"
            />
            <Button
              {...resetButtonProps}
              textOnly
              outlined
              label="Reset All"
            />
            <Button
              {...applyButtonProps}
              tonal
              label="Done"
              className="ml-auto"
            />
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
}
