import { Fragment } from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { Check, Circle, Event, EventAvailable, Exclamation, X } from '../icons';
import { formatDate } from '../utils';
import {
  MILESTONE_STATUS_COMPLETED,
  MILESTONE_STATUS_DEAD,
  MILESTONE_STATUS_OVERDUE,
  MILESTONE_STATUS_IN_PROGRESS,
  TASK_DATE_FORMAT,
  MILESTONE_STATUS_PENDING,
  isDealClosed,
} from './milestone';

function Stage({ name, dateValue, icon, status, clickable = false, active = false }) {
  let iconClassName, dateClassName;
  switch (status) {
    case MILESTONE_STATUS_COMPLETED: {
      dateClassName = 'text-green-500';
      iconClassName = 'text-green-500';
      break;
    }
    case MILESTONE_STATUS_OVERDUE: {
      dateClassName = 'text-red-700';
      iconClassName = 'text-red-700';
      break;
    }
    case MILESTONE_STATUS_DEAD:
    case MILESTONE_STATUS_IN_PROGRESS:
    case MILESTONE_STATUS_PENDING: {
      dateClassName = 'text-gray-900 text-opacity-54';
      iconClassName = 'text-gray-900';
      break;
    }
    default: {
      console.error(`Unknown milestone status ${status}`);
      return null;
    }
  }

  return (
    <div
      className={cx(
        'flex flex-col justify-between items-center text-xs',
        {
          'group cursor-pointer': clickable,
          'cursor-default': !clickable,
        },
      )}
    >
      <div
        className={cx(
          'rounded-full py-1 px-4 w-14 h-8 mb-1',
          iconClassName,
          {
            'bg-primary bg-opacity-12': clickable && active,
            'group-hover:bg-gray-100': clickable && !active,
          },
        )}
      >
        {icon}
      </div>
      <div className="mb-2 text-gray-900 font-medium tracking-wider">{name}</div>
      <div className={cx('tracking-wider h-4', dateClassName)}>{dateValue}</div>
    </div>
  );
}

function MilestoneStage({ milestone, active }) {
  const { name, dueDate, completedAt, status } = milestone;

  let dateValue, icon;
  switch (status) {
    case MILESTONE_STATUS_COMPLETED: {
      dateValue = `Completed ${formatDate(completedAt, TASK_DATE_FORMAT)}`;
      icon = <Check className="h-6 w-6" />;
      break;
    }
    case MILESTONE_STATUS_DEAD: {
      dateValue = 'Dead';
      icon = <X />;
      break;
    }
    case MILESTONE_STATUS_OVERDUE: {
      dateValue = `Due ${formatDate(dueDate, TASK_DATE_FORMAT)}`;
      icon = <Exclamation className="h-4 my-1 mx-auto" />;
      break;
    }
    case MILESTONE_STATUS_IN_PROGRESS: {
      dateValue = `Due ${formatDate(dueDate, TASK_DATE_FORMAT)}`;
      icon = <Circle className="m-1.5 stroke-2" />;
      break;
    }
    case MILESTONE_STATUS_PENDING: {
      dateValue = 'No Due Date';
      icon = <Circle className="m-1.5 stroke-2" />;
      break;
    }
    default: {
      console.error(`Unknown milestone status ${status}`);
      return null;
    }
  }

  return (
    <Stage
      clickable
      name={name}
      dateValue={dateValue}
      icon={icon}
      status={status}
      active={active}
    />
  );
}

function ClosingStage({ milestones, deal }) {
  const { closingDate } = deal;
  const closed = isDealClosed(milestones);

  let dateValue, icon;
  if (!closingDate) {
    dateValue = 'No Closing Date';
    icon = <Event className="h-6 w-6" />;
  } else if (closed) {
    dateValue = `Closed ${formatDate(closingDate, TASK_DATE_FORMAT)}`;
    icon = <EventAvailable className="h-6 w-6" />;
  } else {
    dateValue = formatDate(closingDate, TASK_DATE_FORMAT);
    icon = <Event className="h-6 w-6" />;
  }

  return (
    <Stage
      name="Closing"
      dateValue={dateValue}
      icon={icon}
      status={closed ? MILESTONE_STATUS_COMPLETED : MILESTONE_STATUS_IN_PROGRESS}
    />
  );
}

function TimelineSegment({ prevMilestone }) {
  const { completedAt: prevMilestoneCompletedAt } = prevMilestone;
  const color = !prevMilestoneCompletedAt ? 'bg-gray-200' : 'bg-black';
  return <div className={cx('h-px flex-grow-1', color)} />;
}

export default function Timeline({ img, milestones, className, deal }) {
  return (
    <div className={cx('h-28 flex items-center justify-between px-6 gap-x-6 bg-white border-b select-none', className)}>
      <img className="w-20 h-20 object-cover" src={img} alt="property" referrerPolicy="no-referrer" />
      {milestones.map((milestone) => (
        <Fragment key={milestone.id}>
          <NavLink to={`${milestone.id}`}>
            {({ isActive }) => (
              <MilestoneStage milestone={milestone} active={isActive} />
            )}
          </NavLink>
          <TimelineSegment prevMilestone={milestone} />
        </Fragment>
      ))}
      <ClosingStage milestones={milestones} deal={deal} />
    </div>
  );
}
