import { NavLink, useOutletContext } from 'react-router-dom';
import classNames from 'classnames';

const tabs = (base, isHomeModel) => ([
  ['Overview', (id) => `/${base}/${id}`],
  // NOTE: temporarily commenting out until we improve the reliability of the sensitivities and demographics behavior
  // ['Demographics', (id) => `/${base}/${id}/demographics`],
  // ['Sensitivities', (id) => `/${base}/${id}/sensitivities`],
  ...(isHomeModel ? [['Subdivision', (id) => `/${base}/${id}/subdivision`]] : []),
]);

const linkClassName = (active) => classNames(
  'hover:text-gray-700',
  'flex h-14 items-center',
  'tracking-wider text-xs font-semibold',
  'cursor-pointer',
  {
    'text-gray-700 pl-8 border-l-8 border-primary-500': active,
    'text-gray-400 pl-10': !active,
  },
);

function ParameterTab({ label, path }) {
  return (
    <NavLink end to={path}>
      {({ isActive }) => (
        <div className={linkClassName(isActive)}>{label}</div>
      )}
    </NavLink>
  );
}

function SummarySidebar() {
  const { id, path, data: { subdivision } } = useOutletContext();

  return (
    <div className="h-full w-40 bg-white border-r z-10">
      {
        tabs(path, !!subdivision).map((tab) => (
          <ParameterTab
            key={tab[0]}
            label={tab[0]}
            path={tab[1](id)}
          />
        ))
      }
    </div>
  );
}

export default SummarySidebar;
