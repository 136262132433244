import Dropdown from 'components/Dropdown';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFetchPortfoliosQuery, useUpdateDealMutation } from 'redux/apiSlice';
import { snakeCaseKeys } from 'components/utils';
import { sortBy } from 'lodash';
import { resetBanner, setBanner } from 'reducers/global';
import { clearDealNavigationModal } from 'actions/deal_navigation';
import Alert from 'components/Alert';
import Button from 'components/shared/NewButton';
import DashboardModal from 'components/dashboard/DashboardModal';

export default function SwitchPortfolioModal({ context }) {
  const { data } = context;
  const { deal, portfolio: currentPortfolio } = data;

  const [selectedPortfolio, setSelectedPortfolio] = useState(currentPortfolio || deal?.portfolio || {});
  const { data: unsortedPortfolios = [] } = useFetchPortfoliosQuery();

  const portfolios = sortBy(unsortedPortfolios.map(portfolio => ({
    ...portfolio,
    name: `${portfolio.name}${(portfolio.id === selectedPortfolio?.id) ? ' (Current)' : ''}`,
    disabled: portfolio.id === selectedPortfolio?.id,
  })), p => [!p.disabled, p.name]);

  const [alert, setAlert] = useState(null);
  const [updateDealMutation, { isLoading: updateDealIsLoading }] = useUpdateDealMutation();

  const dispatch = useDispatch();
  const onClose = () => dispatch(clearDealNavigationModal());

  const updateDeal = async (updateBody) => {
    setAlert(null);
    try {
      await updateDealMutation({ ...snakeCaseKeys(updateBody) }).unwrap();
      dispatch(setBanner({ show: true, text: 'Deal moved successfully', type: 'success' }));
      setTimeout(() => { dispatch(resetBanner()); }, 2500);
      onClose();
    } catch (err) {
      console.error('Failed to update deal: ', err);
      setAlert('Failed to update deal');
      dispatch(setBanner({ show: true, text: 'Deal move has failed', type: 'error' }));
    }
  };

  return (
    <DashboardModal
      reverse
      title="Transfer to Different Portfolio"
      className="py-10"
      action={(
        <Button
          filled
          label="Move Deal"
          isLoading={updateDealIsLoading}
          onClick={() => updateDeal({ id: deal.id, portfolioId: selectedPortfolio.id })}
        />
      )}
      onClose={onClose}
    >
      <p>All deal information will be maintained</p>
      <div className="overflow-show my-4">
        <span className="text-xs">Destination Portfolio</span>
        {(portfolios?.length > 0) && (
          <Dropdown
            width="w-full py-4 my-2"
            optionsWidth="w-full"
            options={portfolios}
            selectedItem={selectedPortfolio}
            setSelectedItem={(p) => setSelectedPortfolio(p)}
          />
        )}
      </div>
      {alert && <Alert {...alert} />}
    </DashboardModal>
  );
}
