import { formatPercentage } from 'components/utils';
import dayjs from 'dayjs';
import { compact, isEmpty, isNaN, map, mean, sortBy, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import { filteredLeases } from 'selectors/lease';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import OverallMarketSummary from './OverallMarketSummary';

const OCCUPIED = 'Occupied';
const VACANT = 'Vacant';
const STABILIZED = true;
const ON_THE_MARKET = 'On the Market';
const TWO_WEEKS_DAYS = 14;
const NUMBER_OF_DAYS_IN_MONTH = 30;

const calculateOverallOccupancy = (data, market) => {
  const occupiedCount = data.filter(lease => {
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    return matchesMarket && lease.attributes.occupancyStatus === OCCUPIED;
  }).length;

  const occupancyStatusCount = data.filter(lease => {
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    return matchesMarket && lease.attributes.occupancyStatus;
  }).length;

  return occupancyStatusCount > 0 ? parseFloat(((occupiedCount / occupancyStatusCount))) : 0;
};

const calculateStabilizedOccupancy = (data, market) => {
  const occupiedAndStabilizedCount = data.filter(lease => {
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    return matchesMarket && lease.attributes.occupancyStatus === OCCUPIED && lease.attributes.stabilized === STABILIZED;
  }).length;

  const stabilizedCount = data.filter(lease => {
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    return matchesMarket && lease.attributes.stabilized === STABILIZED;
  }).length;

  return stabilizedCount > 0 ? parseFloat(((occupiedAndStabilizedCount / stabilizedCount))) : 0;
};

const calculateAvailableUnits = (data, market) => {
  const filtered = data.filter(lease => {
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    return matchesMarket && lease.attributes.occupancyStatus === VACANT && lease.attributes.propertyStatus === ON_THE_MARKET;
  });
  return filtered.length;
};

const calculateAverageDaysOnMarket = (data, market) => {
  const filtered = data.filter(lease => {
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    return matchesMarket && lease.attributes.occupancyStatus === VACANT && lease.attributes.propertyStatus === ON_THE_MARKET;
  });
  const mapped = map(filtered, (lease) => lease.attributes.dom);
  return mean(mapped).toFixed(2);
};

const calculateAverageRent = (data, market) => {
  const filtered = data.filter(lease => {
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    return matchesMarket;
  });
  const mapped = compact(map(filtered, (lease) => lease.attributes.listRent));
  return mean(mapped).toFixed(2);
};

const calculateTotalNumberOfOpenApplications = (data, market) => {
  const filtered = data.filter(lease => {
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    return matchesMarket && lease.attributes.occupancyStatus === VACANT && lease.attributes.propertyStatus === ON_THE_MARKET;
  });
  return filtered.reduce((acc, lease) => acc + lease.attributes.numberOfOpenApplications, 0);
};

const calculateReadyToListInTwoWeeks = (data, market) => {
  const today = dayjs();
  const twoWeeksFromNow = dayjs().add(TWO_WEEKS_DAYS, 'day');

  const filtered = data.filter((lease) => {
    const marketingDate = dayjs(lease.attributes.readyForMarketingDate);
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    return matchesMarket && (marketingDate.isAfter(today) || marketingDate.isSame(today)) && marketingDate.isBefore(twoWeeksFromNow);
  });

  return filtered.length;
};

const countVacantPropertiesOnMarketOverAMonth = (leaseData, market) => {
  const filtered = leaseData.filter((lease) => {
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    const isVacant = lease.attributes.occupancyStatus === VACANT;
    const isOnMarket = lease.attributes.propertyStatus === ON_THE_MARKET;
    const isOverAMonth = lease.attributes.dom > NUMBER_OF_DAYS_IN_MONTH;

    return matchesMarket && isVacant && isOnMarket && isOverAMonth;
  });

  return filtered.length;
};

const calculateAverageRentGrowth = (leaseData, market) => {
  const filtered = leaseData.filter((lease) => {
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    const isVacant = lease.attributes.occupancyStatus === VACANT;
    const isOnMarket = lease.attributes.propertyStatus === ON_THE_MARKET;
    const isGreaterThanZero = lease.attributes.priorLeaseRent > 0;

    return matchesMarket && isVacant && isOnMarket && isGreaterThanZero;
  });

  const rentGrowths = map(filtered, (lease) => lease.attributes.rentGrowth);
  return mean(rentGrowths) / 100;
};

const calculateAverageDaysToList = (leaseData, market) => {
  const filtered = leaseData.filter((lease) => {
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    const isVacant = lease.attributes.occupancyStatus === VACANT;
    const isOnMarket = lease.attributes.propertyStatus === ON_THE_MARKET;

    return matchesMarket && isVacant && isOnMarket;
  });

  const rentGrowths = map(filtered, (lease) => lease.attributes.daysToList);
  return mean(rentGrowths).toFixed(2);
};

const calculateAverageClosingRation = (leaseData, market) => {
  const currentMonth = dayjs().month() + 1;
  const currentYear = dayjs().year();
  const filtered = leaseData.filter((lease) => {
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    const monthMatches = lease.attributes.leaseYear === currentYear;
    const yearMatches = lease.attributes.leaseStartMonth === currentMonth;
    return matchesMarket && monthMatches && yearMatches;
  });
  const closingRatios = filtered.map((lease) => lease.attributes.closingRatio);
  return mean(closingRatios);
};

const countLongDom = (leaseData, market) => {
  const filtered = leaseData.filter((lease) => {
    const matchesMarket = market ? lease.attributes.property.market === market : true;
    const isOverAMonth = lease.attributes.dom > 30;
    const isVacant = lease.attributes.occupancyStatus === VACANT;
    const isOnMarket = lease.attributes.propertyStatus === ON_THE_MARKET;
    return matchesMarket && isOverAMonth && isVacant && isOnMarket;
  });
  return filtered.length;
};

function Overall() {
  const { data } = useSelector(state => state.lease);
  const filterData = useSelector(filteredLeases);
  const markets = sortBy(uniq(filterData.map(lease => lease.attributes.property.market)));

  const overallOccupancy = calculateOverallOccupancy(filterData);
  const averageClosingratio = calculateAverageClosingRation(filterData);
  const stabilizedOccupancy = calculateStabilizedOccupancy(filterData);
  const availableUnits = calculateAvailableUnits(filterData);
  const averageDaysOnMarket = calculateAverageDaysOnMarket(filterData);
  const totalNumberOfOpenApplications = calculateTotalNumberOfOpenApplications(filterData);
  const readyToListInTwoWeeks = calculateReadyToListInTwoWeeks(filterData);
  const totalLeaseOverAMonth = countVacantPropertiesOnMarketOverAMonth(filterData);
  const averageRentGrowth = calculateAverageRentGrowth(filterData);
  const averageDaysToList = calculateAverageDaysToList(filterData);

  const leaseMetrics = [
    { label: 'Overall Occupancy', value: formatPercentage(overallOccupancy, 2) },
    { label: 'Available Unit', value: availableUnits },
    { label: 'Avg. DOM', value: isNaN(averageDaysOnMarket) ? '-' : averageDaysOnMarket },
    { label: 'Closing Ratio', value: formatPercentage(averageClosingratio, 2) },
    { label: 'Open Applications', value: totalNumberOfOpenApplications },
    { label: 'Stabilized Occupancy', value: formatPercentage(stabilizedOccupancy, 2) },
    { label: 'Ready to List in 2 Weeks', value: readyToListInTwoWeeks },
    { label: '# of Properties DOM > 30', value: totalLeaseOverAMonth, valueClassNames: 'text-red-600 font-extrabold' },
    { label: 'Rent Growth', value: formatPercentage(averageRentGrowth, 2) },
    { label: 'Avg. Days to List', value: isNaN(averageDaysToList) ? '-' : averageDaysToList },
  ];

  const overallMarketData = [
    {
      market: 'Overall',
      count: filterData.length,
      overallOccupancy: calculateOverallOccupancy(filterData),
      stabilizedOccupancy: calculateStabilizedOccupancy(filterData),
      availableUnits: calculateAvailableUnits(filterData),
      averageDaysOnMarket: calculateAverageDaysOnMarket(filterData),
      numberOfLongDom: countLongDom(filterData),
      averageRent: calculateAverageRent(filterData),
      averageClosingratio: calculateAverageClosingRation(filterData),
    },
  ];

  markets.forEach(market => {
    overallMarketData.push(
      {
        market,
        count: filterData.length,
        overallOccupancy: calculateOverallOccupancy(filterData, market),
        stabilizedOccupancy: calculateStabilizedOccupancy(filterData, market),
        availableUnits: calculateAvailableUnits(filterData, market),
        averageDaysOnMarket: calculateAverageDaysOnMarket(filterData, market),
        numberOfLongDom: countLongDom(filterData, market),
        averageRent: calculateAverageRent(filterData, market),
        averageClosingratio: calculateAverageClosingRation(filterData, market),
      },
    );
  });

  if (isEmpty(data)) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-center border border-gray-300 rounded-lg p-4 max-w-md mx-auto">
          <p className="text-lg font-semibold text-gray-700">No Data Available</p>
          <p className="text-gray-500">Please upload leases to see your dashboard</p>
        </div>
      </div>
    );
  }
  return (
    <div className="mx-auto px-4 py-3">
      <ReturnMetricBoxes metrics={leaseMetrics} />
      <h2 className="text-lg leading-6 font-medium text-gray-900 mt-10 mb-4">Overall - Market Summary</h2>
      <OverallMarketSummary overallMarketData={overallMarketData} />
    </div>
  );
}

export default Overall;
