import { Link, useLocation } from 'react-router-dom';
import { Chevron } from 'components/icons';
import { formatInteger, titleCase } from 'components/utils';
import { HorizontalTabs, LinkTab } from 'components/shared/Tabs';

function PropertyBreadCrumb({ property, propertyManagementRecordId }) {
  const {
    address,
    bathrooms,
    bedrooms,
    isSingleFamily,
    livingArea,
    numberOfUnits,
    photos,
    yearBuilt,
  } = property;

  const propertyInfo = [];
  if (isSingleFamily) {
    propertyInfo.push(`${bedrooms} Bed`);
    propertyInfo.push('·');
    propertyInfo.push(`${bathrooms} Bath`);
  } else {
    propertyInfo.push(`${numberOfUnits} Units`);
  }
  if (livingArea) {
    propertyInfo.push('·');
    propertyInfo.push(`${formatInteger(livingArea)} Sq Ft.`);
  }
  if (yearBuilt) {
    propertyInfo.push('·');
    propertyInfo.push(`Built ${yearBuilt}`);
  }

  return (
    <Link to={`/asset_management/${propertyManagementRecordId}`}>
      <div className="flex gap-x-4 cursor-pointer">
        {photos.length > 0 && (
          <div className="w-12 h-12">
            <img src={photos[0]?.url} alt="listing" referrerPolicy="no-referrer" className="w-full h-full object-cover rounded-xl" />
          </div>
        )}
        <div className="h-12 flex flex-col justify-center">
          <div className="flex gap-x-4 mb-1">
            <span>{titleCase(address)}</span>
          </div>
          <div className="flex gap-x-2 text-xs text-gray-500">
            {propertyInfo.map((info, index) => (<span key={index}>{info}</span>))}
          </div>
        </div>
      </div>
    </Link>
  );
}

function TopNav({ propertyManagementRecordId }) {
  const location = useLocation();

  // TODO: typicaly NavLink active functionality doesn't work due to nested routes
  const isRentComps = location.pathname.includes('rent_comps');
  const isSaleComps = location.pathname.includes('sale_comps');
  const isProperty = !isRentComps && !isSaleComps;

  const basePath = `/asset_management/${propertyManagementRecordId}`;

  return (
    <HorizontalTabs>
      <LinkTab to={`${basePath}/summary`} active={isProperty}>Property</LinkTab>
      <LinkTab to={`${basePath}/rent_comps`} active={isRentComps}>Rent Comps</LinkTab>
      <LinkTab to={`${basePath}/sale_comps`} active={isSaleComps}>Sale Comps</LinkTab>
    </HorizontalTabs>
  );
}

export default function Header({ property, propertyManagementRecord }) {
  return (
    <div className="bg-white border border-b shadow">
      <div className="h-20 flex gap-x-4 items-center p-3">
        <Link to="/asset_management" className="text-xl pl-3 py-3 cursor-pointer">Asset Management</Link>
        {propertyManagementRecord && (
          <>
            <Chevron direction="right" className="w-4 text-gray-500" />
            <PropertyBreadCrumb property={property} propertyManagementRecordId={propertyManagementRecord.id} />
          </>
        )}
      </div>
      {propertyManagementRecord && (
        <TopNav propertyManagementRecordId={propertyManagementRecord.id} />
      )}
    </div>
  );
}
