import { partial } from 'lodash';
import { splitCamelCase } from 'components/utils';
import ChipFilter from 'components/shared/filters/ChipFilter';

const visibilityLabel = (hideSelected, hideUnselected) => {
  let label;
  if (hideSelected && hideUnselected) label = 'Hide All';
  else if (hideSelected && !hideUnselected) label = 'Hide Selected';
  else if (!hideSelected && hideUnselected) label = 'Hide Unselected';
  else label = 'Visibility';
  return label;
};

export default function VisibilityFilter({ filterKey, filterParams, onApply, onReset, setFilter }) {
  const hideSelected = filterParams[0][1];
  const hideUnselected = filterParams[1][1];
  const text = visibilityLabel(hideSelected, hideUnselected);
  const filtered = text !== 'Visibility';

  return (
    <ChipFilter label={text} filtered={filtered} filterKey={filterKey} filterParams={filterParams} onApply={onApply} onReset={onReset} setFilter={setFilter}>
      {({ filterState, setFilterState }) => {
        const onChange = (toggledIndex) => {
          setFilterState(prevState => {
            const [label, enabled] = prevState[toggledIndex];
            const updatedState = [...prevState];
            updatedState[toggledIndex] = [label, !enabled];
            return updatedState;
          });
        };

        return (
          <fieldset className="space-y-8 w-72">
            {filterState.map(([label, enabled], index) => (
              <label
                key={label}
                className="flex flex-row items-center gap-x-4.5 w-max text-gray-700 tracking-wide cursor-pointer select-none"
              >
                <input
                  type="checkbox"
                  className="h-4.5 w-4.5 cursor-pointer"
                  checked={enabled}
                  onChange={partial(onChange, index)}
                />
                {splitCamelCase(label)}
              </label>
            ))}
          </fieldset>
        );
      }}
    </ChipFilter>
  );
}
