/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';

const isBedFiltered = (acquisition, bedFilter) => {
  const { bed } = acquisition.attributes.property;

  if (bedFilter.length === 1) {
    return true;
  }

  const useExactMatch = bedFilter[0];
  return useExactMatch ? bedFilter.includes(bed) : bed >= bedFilter[1];
};

const isBathFiltered = (acquisition, bathFilter) => {
  const { bath } = acquisition.attributes.property;

  if (bathFilter.length === 1) {
    return true;
  }

  const useExactMatch = bathFilter[0];
  return useExactMatch ? bathFilter.includes(bath) : bath >= bathFilter[1];
};

const EMPTY_ARRAY = [];

const applyPropertyFilters = (data, filters) => {
  const { market, portfolio, building, bed, bath, yearBuiltMin, yearBuiltMax, sqftMin, sqftMax, domMin, domMax } = filters;

  return data.filter(record => {
    const isBuildingInFiltered = building ? record.attributes?.property?.building === building : true;
    const isMarketFiltered = market ? record.attributes.property.market === market : true;
    const isPortfolioFiltered = portfolio ? record.attributes.portfolioName === portfolio : true;
    const isYearBuiltWithinRange = (!yearBuiltMin || record.attributes.property.yearBuilt >= yearBuiltMin)
                && (!yearBuiltMax || record.attributes.property.yearBuilt <= yearBuiltMax);
    const isSquareFootWithinRange = (!sqftMin || record.attributes.property.sqft >= sqftMin)
                && (!sqftMax || record.attributes.property.sqft <= sqftMax);
    const isDomWithinRange = (!domMin || record.attributes.dom >= domMin)
                && (!domMax || record.attributes.dom <= domMax);

    return isBathFiltered(record, bath) && isBedFiltered(record, bed) && isBuildingInFiltered && isMarketFiltered && isPortfolioFiltered && isYearBuiltWithinRange && isSquareFootWithinRange && isDomWithinRange;
  });
}

const applyAsOfDateToAcquisitions = (data, asOfDate) => data.filter(acquisition => {
  const isNotClosed = (!acquisition.attributes.closedAt || (parseISO(acquisition.attributes.closedAt) >= asOfDate));
  const isInContract = (acquisition.attributes.inDiligenceAt && (parseISO(acquisition.attributes.inDiligenceAt) <= asOfDate));

  return isInContract && isNotClosed;
});

export const selectFilteredAcquisitions = createSelector(
  [res => res.data?.data, (_, filters) => filters, (_, _filters, asOfDate) => asOfDate],
  (data, filters, asOfDate) => {
    if (!data) return EMPTY_ARRAY;

    const propertyFilteredData = applyPropertyFilters(data, filters);
    return applyAsOfDateToAcquisitions(propertyFilteredData, asOfDate);
  },
);

export const selectFilteredOffers = createSelector(
  [res => res.data?.data, (_, filters) => filters, (_, _filters, asOfDate) => asOfDate],
  (data, filters) => {
    if (!data) return EMPTY_ARRAY;

    return applyPropertyFilters(data, filters);
  },
);

export const selectFilteredDispositions = createSelector(
  [res => res.data?.data, (_, filters) => filters, (_, _filters, asOfDate) => asOfDate],
  (data, filters) => {
    if (!data) return EMPTY_ARRAY;

    return applyPropertyFilters(data, filters);
  },
);
