import Modal from 'components/Modal';
import Button from 'components/shared/NewButton';

export default function ChangeToMultifamilyModal({ onCancel, onContinue }) {
  const handleContinue = () => {
    onContinue();
    onCancel();
  };

  return (
    <Modal show showCloseAction={false}>
      <div className="mt-4 w-90 h-auto">
        <div className="text-2xl font-normal">Change to Multifamily?</div>
        <div className="mt-6 font-light">
          Adding a unit will change the model to function as a &#34;Multifamily
          Property&#34;, and you will lose access to the SFR Summary tab.
        </div>
        <div className="mt-6 flex gap-x-2 justify-end">
          <Button textOnly label="Cancel" onClick={onCancel} />
          <Button filled label="Continue" onClick={handleContinue} />
        </div>
      </div>
    </Modal>
  );
}
