import { forwardRef } from 'react';
import classNames from 'classnames';
import { Check, X } from 'components/icons';

function Chip({ disabled, label, leadingIcon, onCancel, onClick, preferLeadingIcon, trailingIcon, selected, focused, width = 'w-max', className, children, ...props }, ref) {
  const clazz = classNames(
    'flex h-9 text-xs font-medium justify-center items-center text-center rounded-lg px-3 py-1.5 border focus:outline-none active:outline-none select-none',
    width,
    {
      'text-gray-800 border-gray-800 border-opacity-50 cursor-pointer': !disabled,
      // use aria-expanded in addition to ui-open, so chips within dialog/popover that are unrelated to the dialog/popover's state don't match
      'hover:bg-gray-100 focus:bg-gray-200 ui-open:aria-expanded:bg-gray-200': !selected && !disabled && !focused,
      'bg-blue-100 hover:bg-blue-200 focus:bg-blue-200 border-blue-200': selected && !disabled,
      'text-gray-300 border-gray-300 cursor-not-allowed': disabled,
      'bg-gray-200': !selected && !disabled && focused,
    },
    className,
  );

  return (
    <button
      {...props}
      className={clazz}
      disabled={disabled}
      onClick={onClick}
      type="button"
      ref={ref}
    >
      {leadingIcon && (
        <div className="mr-2 w-4 empty:hidden">
          {(selected && !trailingIcon && !preferLeadingIcon) ? <Check /> : leadingIcon}
        </div>
      )}
      {label || ''}
      {children}
      {trailingIcon && (
        <div className="ml-2 w-4 empty:hidden">
          {selected ? <X onClick={onCancel} /> : trailingIcon}
        </div>
      )}
    </button>
  );
}

export default forwardRef(Chip);
