import ChipFilter from 'components/shared/filters/ChipFilter';

const LAND_USE_LABELS = {
  SF: 'Single Family',
  MF: 'Multi Family',
};

function LandUseCheckbox({ landUse, filterState, setFilterState }) {
  const onChange = () => {
    setFilterState(prevState => {
      if (prevState.includes(landUse)) {
        return prevState.filter(s => s !== landUse);
      } else {
        return prevState.concat(landUse);
      }
    });
  };

  return (
    <label className="flex flex-row items-center gap-x-4.5 w-max text-gray-700 tracking-wide cursor-pointer select-none">
      <input
        type="checkbox"
        className="h-4.5 w-4.5 cursor-pointer"
        checked={filterState.includes(landUse)}
        onChange={onChange}
      />
      {LAND_USE_LABELS[landUse]}
    </label>
  );
}

export default function LandUseFilter({ filterKey, filterParams, onApply, onReset, setFilter }) {
  const text = filterParams.length === 0 ? 'Single + Multi Family' : filterParams.map(label => LAND_USE_LABELS[label]).join(' + ');
  const filtered = filterParams.length === 1;

  return (
    <ChipFilter label={text} filtered={filtered} filterKey={filterKey} filterParams={filterParams} onApply={onApply} onReset={onReset} setFilter={setFilter}>
      {({ filterState, setFilterState }) => (
        <fieldset className="space-y-8 w-72">
          <LandUseCheckbox landUse="SF" filterState={filterState} setFilterState={setFilterState} />
          <LandUseCheckbox landUse="MF" filterState={filterState} setFilterState={setFilterState} />
        </fieldset>
      )}
    </ChipFilter>
  );
}
