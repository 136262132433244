import { useMemo } from 'react';
import { sortBy } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { matchPath, useLocation } from 'react-router-dom';
import { useFetchUsersQuery } from 'redux/apiSlice';
import { DEAL_DASHBOARD_ROUTE, LISTINGS_ROUTE, MODEL_ROUTE, MODEL_SCENARIO_ROUTE, PROPERTIES_ROUTE } from '../routes';

export const PROPERTY_TAB = 0;
export const MODEL_TAB = 1;
export const DASHBOARD_TAB = 2;

export const useCurrentDealTab = () => {
  const { pathname } = useLocation();

  return useMemo(() => {
    if (matchPath({ path: LISTINGS_ROUTE, caseSensitive: false, end: false }, pathname)
      || matchPath({ path: PROPERTIES_ROUTE, caseSensitive: false, end: false }, pathname)
    ) {
      return PROPERTY_TAB;
    }

    if (matchPath({ path: MODEL_SCENARIO_ROUTE, caseSensitive: false, end: false }, pathname)
      || matchPath({ path: MODEL_ROUTE, caseSensitive: false, end: false }, pathname)
    ) {
      return MODEL_TAB;
    }

    if (matchPath({ path: DEAL_DASHBOARD_ROUTE, caseSensitive: false, end: false }, pathname)) {
      return DASHBOARD_TAB;
    }

    return null;
  }, [pathname]);
};

export const selectScenarioById = createSelector(
  [
    (state) => state.scenarios?.data?.scenarios,
    (_, { id }) => id,
  ],
  (scenarios, scenarioId) => scenarios?.find(scenario => scenario.id === scenarioId),
);

export const selectLoiGeneratedById = createSelector(
  [selectScenarioById],
  (scenario) => scenario?.loiGeneratedAt,
);

export const selectPurchaseAgreementGeneratedById = createSelector(
  [selectScenarioById],
  (scenario) => scenario?.purchaseAgreementGeneratedAt,
);

export const selectCurrentPropertyId = state => (
  state.navigation?.currentItem?.listing?.data?.attributes?.property?.id
);

export const useUserOptions = ({ organizationId = null, includeInactive = false, includeUsers = [] } = {}) => {
  const { data: users = [] } = useFetchUsersQuery(organizationId);

  // remove inactive users unless includeInactive is true, or the user's id is in includeUsers array
  const filteredUsers = users.filter(({ id, deletedAt }) => (includeInactive || !deletedAt) || includeUsers?.includes(id));
  // option is enabled when the user is active or includeInactive is true
  const options = filteredUsers.map(({ id, email, deletedAt }) => [id, email, !includeInactive && !!deletedAt]);

  return sortBy(options, ([, email]) => email);
};
