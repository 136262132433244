import { useMemo } from 'react';
import { calcCashFlows, calcReturnMetrics } from 'components/dcf/dcf';
import { formatPercentage } from 'components/utils';
import { dcfParamsWithInput } from './subdivision';

export default function OfferYieldCell({ row }) {
  const purchasePrice = row.getValue('offerBidPrice');
  const marketRent = row.getValue('offerUnderwritingRent');
  const dcfParams = row.getValue('offerDcfParams');

  const updatedDcfParams = useMemo(() => (
    dcfParamsWithInput({ dcfParams, purchasePrice, marketRent, multiplicity: 1 })
  ), [dcfParams, marketRent, purchasePrice]);

  const { stabilizedYield } = useMemo(() => (
    calcReturnMetrics(calcCashFlows(updatedDcfParams), updatedDcfParams, { skipIrr: true })
  ), [updatedDcfParams]);
  return formatPercentage(stabilizedYield);
}
