import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import { CircleInfo } from 'components/icons';

export default function InfoPopover({ title, detail }) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className={classNames('focus:outline-none active:outline-none w-10 h-10 items-center rounded-full', open ? 'bg-gray-200' : '')}>
            {' '}
            <CircleInfo className="mx-auto h-5 w-5" />
          </Popover.Button>
          <Popover.Panel className="absolute mt-1 left-0 z-30 w-90 h-auto p-4 bg-white rounded-lg shadow-lg">
            <div className="mb-2">{title}</div>
            <div className="text-neutral-medium text-sm leading-relaxed">{detail}</div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
