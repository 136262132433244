import { useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { LocationIcon } from 'components/icons';
import { isNull } from 'lodash';
import GoogleMapUtils from './utils';

const DEFAULT_ZOOM = 11;

function NodeMarker({ infoWindow, isHovered, place, $hover }) {
  const isHoveredState = isHovered || $hover;
  let fillColor = '#9ca3af'; // Gray for default

  if (place.markerColor) {
    fillColor = place.markerColor; // Give priority for the place marker
  } else if (place.subject) {
    fillColor = '#ef4444'; // Red for subject
  } else if (isHoveredState) {
    fillColor = '#34d399'; // Green for Hover
  } else if (place.active) {
    fillColor = '#0ea5e9'; // Blue for active
  }

  const markerClass = `absolute h-10 w-10 cursor-pointer z-10 transition transform -translate-y-full ${isHoveredState ? 'scale-150 -translate-x-[0.9375rem]' : '-translate-x-1/2'}`;

  return (
    <>
      <LocationIcon
        className={markerClass}
        markerColor={fillColor}
      />
      {(place.showInfoWindow && !place.subject) && infoWindow(place)}
    </>
  );
}

const options = (mapType) => ({
  styles: GoogleMapUtils.mapStyles,
  clickableIcons: false,
  mapTypeControl: true,
  fullscreenControl: false,
  mapTypeControlOptions: {
    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
    position: google.maps.ControlPosition.BOTTOM_LEFT,
  },
  scaleControl: false,
  rotateControl: false,
  mapTypeId: mapType ? GoogleMapUtils.getMapType(mapType) : google.maps.MapTypeId.HYBRID,
});

function GoogleMap(props) {
  const {
    defaultCenter,
    defaultZoom = DEFAULT_ZOOM,
    hoveredId,
    infoWindow,
    legendComponent,
    mapOptions,
    mapType = GoogleMapUtils.MAP_TYPE_SATELLITE,
    places,
    setDrawingFiltered,
  } = props;

  const [activeId, setActiveId] = useState(null);
  const [initialPlaces, setInitialPlaces] = useState(places);
  const [placesToDisplay, setPlacesToDisplay] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);

  const mapRef = useRef(null);
  const placesToDisplayRef = useRef(placesToDisplay);
  const initialPlacesRef = useRef(initialPlaces);

  useEffect(() => {
    initialPlacesRef.current = initialPlaces;
  }, [initialPlaces]);

  useEffect(() => {
    placesToDisplayRef.current = placesToDisplay;
  }, [placesToDisplay]);

  // Returns the current places to display
  const getCurrentPlaces = () => placesToDisplayRef.current;

  // Initializes the map with the provided places
  useEffect(() => {
    setActiveId(null);
    setInitialPlaces(places);
    setPlacesToDisplay(places);
  }, [places]);

  // Toggles map interactions (zoom, drag, etc.)
  const toggleMapInteractions = (enable) => {
    mapRef.current?.setOptions({
      scrollwheel: enable,
      draggable: enable,
      disableDoubleClickZoom: !enable,
      gestureHandling: enable ? 'auto' : 'none',
    });
  };

  // Handles marker click events
  const onMarkerClick = (id) => {
    if (!isDrawing) {
      setActiveId(id);
      toggleMapInteractions(false); // Disable interactions when a marker is clicked
    }
  };

  // Handles map click events
  const onMapClick = () => {
    setPlacesToDisplay((prevPlaces) => prevPlaces.map((place) => ({
      ...place,
      showInfoWindow: false,
    })));
    toggleMapInteractions(true); // Enable interactions when map is clicked
  };

  // Updates the display of markers based on the active marker
  const handleActivePlace = () => {
    setPlacesToDisplay((prevPlaces) => prevPlaces?.map((place) => ({
      ...place,
      showInfoWindow: String(place.id) === String(activeId) ? !place.showInfoWindow : false,
    })));
  };

  // Called when the Google Maps API is loaded
  const handleApiLoaded = (map, maps) => {
    mapRef.current = map;
    toggleMapInteractions(true);

    GoogleMapUtils.apiIsLoaded(map, maps, getCurrentPlaces, (newFilteredPlaces, shapes) => {
      const updatedPlaces = isNull(newFilteredPlaces) ? initialPlacesRef.current : newFilteredPlaces;
      setPlacesToDisplay(updatedPlaces);
      if (setDrawingFiltered) {
        // notify the calling(parent) component about the newly filtered places
        setDrawingFiltered(shapes);
      }
      GoogleMapUtils.fitMapToBounds(map, maps, getCurrentPlaces);
    }, setIsDrawing, setActiveId);
  };

  // Update active place on activeId change
  useEffect(() => {
    handleActivePlace();
  }, [activeId]);

  return (
    <div className="relative h-full w-full">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GOOGLE_API_KEY }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        options={mapOptions || options(mapType)}
        onChildClick={onMarkerClick}
        onClick={onMapClick}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        yesIWantToUseGoogleMapApiInternals
      >
        {placesToDisplay && placesToDisplay.map((place) => (
          <NodeMarker
            infoWindow={infoWindow}
            key={place.id}
            lat={place.lat}
            lng={place.lng}
            place={place}
            isHovered={hoveredId === place.id}
          />
        ))}
      </GoogleMapReact>
      {legendComponent && legendComponent}
    </div>

  );
}

export default GoogleMap;
