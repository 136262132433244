import classNames from 'classnames';
import { LoadingIndicator } from 'components/icons';

export default function Button({ className, danger, disabled, isLoading, label, leadingIcon, onClick, textOnly, outlined, filled = !danger && !textOnly && !outlined, width = 'w-max', type = 'button' }) {
  const clazz = classNames(
    className,
    'flex text-sm font-medium justify-center items-center text-center py-2.5 rounded-lg focus:outline-none active:outline-none',
    width,
    {
      'px-6': !textOnly && !leadingIcon,
      'px-4': !textOnly && leadingIcon,
      'px-3': textOnly,
      'bg-primary-500 text-white hover:bg-primary-600 focus:bg-primary-600 active:bg-primary-700': filled && !disabled,
      'text-gray-700 hover:bg-gray-100 focus:bg-gray-100 active:bg-gray-200': textOnly && !disabled,
      'text-neutral-dark border border-neutral-medium hover:bg-gray-100 focus:bg-gray-200 active:bg-gray-200': outlined && !disabled,
      'text-gray-700 bg-gray-100 cursor-not-allowed opacity-80 text-opacity-80 bg-opacity-80': disabled,
      'bg-gray-300': filled && disabled,
      'bg-red-600 text-white': danger && !disabled,

    },
  );

  return (
    <button
      className={clazz}
      disabled={disabled || isLoading}
      onClick={onClick}
      type={type}
    >
      {leadingIcon && (
        <div className="mr-2 w-4">
          {leadingIcon}
        </div>
      )}
      {isLoading ? (
        <>
          {label}
          <LoadingIndicator className="inline ml-2 h-5" />
        </>
      ) : label || ''}
    </button>
  );
}
