import { useDispatch } from 'react-redux';
import { clearDealNavigationModal } from 'actions/deal_navigation';
import Modal from 'components/Modal';
import EditTransactionInfo from './EditTransactionInfo';

export default function EditDealModal({ context }) {
  const { data } = context;
  const { deal } = data;

  const dispatch = useDispatch();
  const onClose = () => dispatch(clearDealNavigationModal());

  return (
    <Modal show title={`Edit ${deal.name}`} onClose={onClose}>
      <div className="flex flex-col w-128 mt-6 overflow-y-auto">
        <EditTransactionInfo deal={deal} onClose={onClose} />
      </div>
    </Modal>
  );
}
