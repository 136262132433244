import { AlignmentType, Paragraph, Table, TableCell, TableRow, WidthType } from 'docx';
import { formatInteger } from '../../utils';
import { cellMargin, makeExternalHyperlink, tableCellRightBorder, tableCellTopBorder, tableHeader } from './util';

export default function buildDealSummary(dcfParams, deal, property, listing) {
  const { data: { attributes: { address, market, name } } } = deal;
  const { units } = dcfParams;

  const dealSummaryData = {
    'Address': { text: address },
    'Market': { text: market },
    'Submarket': { text: '' },
    'Units': { text: units.length.toString() },
    'RSF': { text: formatInteger(units.reduce((sum, unit) => sum + (unit.rsf ?? 0), 0)) },
    'Year Built': { text: units.length > 1 ? 'N/A' : property.yearBuilt?.toString() },
    'Source': { text: listing.source },
    'Business Plan': { text: '' },
    'Honeycomb Deal Link': { children: [makeExternalHyperlink(name, window.location.toString())] },
  };

  const tableRows = Object.getOwnPropertyNames(dealSummaryData).map((key) => (
    new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph(key)],
          margins: cellMargin,
          borders: { ...tableCellTopBorder, ...tableCellRightBorder },
        }),
        new TableCell({
          children: [new Paragraph({
            ...dealSummaryData[key],
            alignment: AlignmentType.RIGHT,
          })],
          margins: cellMargin,
          borders: tableCellTopBorder,
        }),
      ],
    })
  ));

  return new Table({
    width: { size: 100, type: WidthType.PERCENTAGE },
    rows: [
      tableHeader('Deal Summary', 2),
      ...tableRows,
    ],
  });
}
