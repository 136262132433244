import { uniq } from 'lodash';
import { naturalSortComparator } from 'components/utils';
import ComboboxFilter from 'components/shared/newfilters/ComboboxFilter';
import { useFetchSubdivisionsQuery } from '../../../redux/subdivisionApiSlice';

const comparator = naturalSortComparator();

const selectMarkets = ({ currentData, isFetching, isUninitialized }) => {
  const sortedData = currentData?.map(({ market }) => market);
  if (sortedData?.length) {
    sortedData.sort((left, right) => comparator(left, right));
  }

  return {
    currentData: sortedData ? uniq(sortedData) : null,
    isFetching: isFetching || isUninitialized,
  };
};

export default function SubdivisionMarketFilter() {
  const { currentData: markets, isFetching } = useFetchSubdivisionsQuery(undefined, { selectFromResult: selectMarkets });

  return (
    <ComboboxFilter
      isLoading={isFetching}
      options={markets}
    />
  );
}
