import { Check, ExclamationCircle } from 'components/icons';
import { useSelector } from 'react-redux';

export default function PageBanner() {
  const {
    position, // top, bottom
    type, // error, success
    text, // sucess or error text
  } = useSelector((state) => state.global.banner);

  let icon;
  if (type === 'error') {
    icon = <ExclamationCircle className="w-6 text-white" />;
  } else if (type === 'success') {
    icon = <Check className="w-6 text-white" />;
  }

  return (
    <div className={`flex justify-center items-center fixed ${position}-5 w-full p-4 z-10`}>
      <div className="min-w-max p-4 flex justify-start items-center bg-zinc-800 shadow-lg rounded-md text-white">
        <div className="pr-4">{ icon }</div>
        <div className="pr-2">{ text }</div>
      </div>
    </div>
  );
}
