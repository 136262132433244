import { Fragment, useState } from 'react';
import { Listbox } from '@headlessui/react';
import { some } from 'lodash';
import { ArrowDropDown, CheckboxEmpty, CheckboxFilled } from '../icons';

export default function MarketSelect({ buttonComponent, options, width, height, optionsWidth, selectedMarkets, onChange }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative mt-6 flex flex-col items-start justify-center">
      <span className="block mb-1 text-xs text-primary-dark">Markets</span>
      <Listbox value={selectedMarkets} onChange={onChange} className="w-full" as="div" by="id" multiple>
        <>
          <Listbox.Button open={isOpen} className={!buttonComponent ? `relative ${width} ${height} py-3 px-4 text-left text-sm bg-white border border-primary-dark rounded-md cursor-pointer focus:outline-none` : ''}>
            {buttonComponent || (
              <div className="flex justify-between items-center truncate" onClick={() => setIsOpen(!isOpen)}>
                <span className="text-gray-400">{selectedMarkets.length ? `${selectedMarkets.length} markets selected` : 'Select Markets'}</span>
                <ArrowDropDown className="w-4" />
              </div>
            )}
          </Listbox.Button>
          {isOpen && (
          <Listbox.Options static className={`${optionsWidth} absolute top-16 z-20 py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-xl cursor-pointer`}>
            {options && options.map((option) => (
              <Listbox.Option
                open={isOpen}
                key={option.id}
                value={option}
                as={Fragment}
                disabled={option.disabled}
                className="w-full flex items-center justify-between cursor-pointer text-sm select-none relative py-2 pl-8 pr-4 hover:bg-gray-100 text-gray-900"
              >
                <div className="flex justify-between items-center border-b border-gray-100">
                  <span>{option.label}</span>
                  {some(selectedMarkets, option) ? <CheckboxFilled className="w-8" /> : <CheckboxEmpty className="w-8" />}
                </div>
              </Listbox.Option>
            ))}
            <div className="w-full px-4 my-2 flex items-center justify-end">
              <div className="px-6 py-2 bg-primary-dark text-white rounded-full text-center" onClick={() => setIsOpen(false)}>Done</div>
            </div>
          </Listbox.Options>
          )}
        </>
      </Listbox>
      <div className="mt-2 flex flex-wrap">
        {
          selectedMarkets.map(m => (
            <div className="text-xs bg-primary-light text-neutral-dark m-1 px-2 py-2 font-medium rounded-lg" key={m.id}>{m.label}</div>
          ))
        }
      </div>
    </div>
  );
}
