import { useLayoutEffect, useRef } from 'react';

// ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate_state_checkboxes
/**
 *
 * @param {HTMLInputElement['indeterminate']} [indeterminate]
 * @param {undefined} [defaultChecked]
 * @param {import('react').ComponentPropsWithoutRef<'input'>} props
 * @return {import('react').ReactNode}
 */
export default function IndeterminateCheckbox({ indeterminate = false, defaultChecked = undefined, ...props }) {
  if (defaultChecked !== undefined) {
    // if this component is uncontrolled,
    // html indeterminate property cannot be synced to indeterminate component property on render
    console.error('This component cannot be uncontrolled.');
  }

  /** @type {import('react').RefObject<import('react').ElementRef<'input'>>} */
  const checkboxRef = useRef(null);

  // dependency array omitted to run callback on every render.
  // the browser can modify the indeterminate state,
  // so it needs to run on every time to keep it in sync with the prop
  useLayoutEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  });

  return <input {...props} ref={checkboxRef} type="checkbox" />;
}
