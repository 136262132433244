import { useMemo } from 'react';
import { compact, groupBy, mean, meanBy, round } from 'lodash';
import DataTable from 'components/shared/Table/DataTable';
import { tdClassNameFn, theadClassName } from 'components/NhimbleDashboard/Shared';
import { formatCurrencyAbbreviated, formatPercentage } from 'components/utils';
import { daysOnMarketFn, inContractFn, longDomFn, onMarketFn } from './disposition.utils';

const COLUMNS = [
  {
    header: '',
    accessorKey: 'market',
    meta: { textAlign: 'left' },
  },
  {
    header: '# in Dispo',
    accessorKey: 'numberInDispo',
  },
  {
    header: '# on the Market',
    accessorKey: 'numberOnTheMarket',
  },
  {
    header: '# in-Contract',
    accessorKey: 'numberInContract',
  },
  {
    header: 'Avg. DOM',
    accessorFn: ({ averageDaysOnMarket }) => `${averageDaysOnMarket ? round(averageDaysOnMarket, 1) : '-'}`,
  },
  {
    header: '# Long DOM',
    accessorKey: 'numberOfLongDom',
  },
  {
    header: 'Avg. ROI (%)',
    accessorFn: row => `${formatPercentage(Number(row.averageRoi))}`,
  },
  {
    header: 'Avg Offers as % of List Price',
    accessorFn: row => `${formatPercentage(row.averageOfferToListRatio)}`,
  },
  {
    header: 'Avg On Market List Price',
    accessorFn: row => `${formatCurrencyAbbreviated(Number(row.averageOnMarketListPrice))}`,
  },
];

const TABLE_META = { textAlign: 'center' };

const parseRowData = ({ asOfDate, dispositions, market }) => ({
  market,
  numberInDispo: dispositions.length,
  numberOnTheMarket: onMarketFn(dispositions, asOfDate).length,
  numberInContract: inContractFn(dispositions, asOfDate).length,
  averageDaysOnMarket: mean(compact(dispositions.map(dispo => daysOnMarketFn(dispo, asOfDate)))),
  numberOfLongDom: longDomFn(dispositions, asOfDate).length,
  averageRoi: 0,
  averageOfferToListRatio: 0,
  averageOnMarketListPrice: meanBy(onMarketFn(dispositions, asOfDate), 'attributes.currentListPrice'),
});

export default function OverallMarketSummary({ asOfDate, dispositions }) {
  const tableData = useMemo(() => {
    const disposByMarket = Object.entries(groupBy(dispositions, 'attributes.property.market'));
    disposByMarket.unshift(['Overall', dispositions]);
    return disposByMarket.map(pair => parseRowData({ market: pair[0], dispositions: pair[1], asOfDate }));
  }, [dispositions]);

  return (
    <div className="border">
      <DataTable
        columns={COLUMNS}
        data={tableData}
        emptyStateComponent="There are no available properties matching your criteria"
        theadClassName={theadClassName}
        tdClassName={tdClassNameFn}
        meta={TABLE_META}
      />
    </div>
  );
}
