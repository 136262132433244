import { memo } from 'react';
import classNames from 'classnames';
import DataTable from 'components/shared/Table/DataTable';
import { formatCurrency } from 'components/utils';
import { format } from 'date-fns';
import { Paperclip, Pencil, SendEmailIcon, Trash } from 'components/icons';
import { capitalize, noop } from 'lodash';
import { useSendOfferEmailMutation } from 'redux/offerApiSlice';

const OTHER_LOAN_TYPE = 'other';

const formatDate = (dateString) => format(new Date(dateString), 'MMM dd, yyyy HH:mm');

const OfferStatusCell = memo(({ status }) => {
  const badgeClass = classNames('m-1 px-2 rounded-lg py-1 font-bold', {
    'bg-green-500 text-white': status === 'accepted',
    'bg-yellow-500 text-white': status === 'pending',
    'bg-red-500 text-white': status === 'rejected',
    'bg-blue-500 text-white': status === 'presented_to_seller',
    'bg-orange-500 text-white': status === 'seller_counter',
    'bg-purple-500 text-white': status === 'buyer_counter',
    'bg-gray-600 text-white': status === 'terminated',
    'bg-teal-500 text-white': status === 're_opened',
    'bg-gray-400 text-white': ![
      'accepted',
      'pending',
      'rejected',
      'presented_to_seller',
      'seller_counter',
      'buyer_counter',
      'terminated',
      're_opened',
    ].includes(status),
  });
  const formattedStatus = status.split('_').join(' ');
  return <span className={badgeClass}>{capitalize(formattedStatus)}</span>;
});

const LoanTypeCell = memo(({ loanType, otherLoanType }) => {
  const value = loanType === OTHER_LOAN_TYPE ? otherLoanType : loanType;
  return <div>{value}</div>;
});

const PriceCell = memo(({ value }) => <div>{formatCurrency(value)}</div>);

const DateCell = memo(({ value }) => <div>{formatDate(value)}</div>);

const MenuCell = memo(({ offer, onEdit, onDelete, onAttachment }) => {
  const attachmentCount = offer.attachments?.length || 0;
  const [sendEmail] = useSendOfferEmailMutation();

  return (
    <div className="relative">
      <div className="flex justify-center gap-x-1 items-center">
        <div
          className="flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 hover:text-blue-400 cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            sendEmail(offer);
          }}
        >
          <SendEmailIcon className="w-6 h-6 mx-auto" />
        </div>
        <div
          className="relative flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 hover:text-blue-400 cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onAttachment(offer);
          }}
        >
          <Paperclip className="w-6 h-6 mx-auto" />
          {attachmentCount > 0 && (
            <span className="absolute -top-1 left-4 inline-flex items-center justify-center px-1.5 py-1 text-xs font-bold leading-none text-primary-700 bg-primary-200 rounded-full">
              {attachmentCount}
            </span>
          )}
        </div>
        <div
          className="flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 hover:text-blue-400 cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onEdit(offer);
          }}
        >
          <Pencil className="w-6 h-6 mx-auto" />
        </div>
        <div
          className="flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 hover:text-red-700 cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onDelete(offer);
          }}
        >
          <Trash className="w-6 h-6 mx-auto" />
        </div>
      </div>
    </div>
  );
});

const columns = (onEdit, onDelete, onAttachment) => [
  {
    id: 'buyerName',
    header: 'Buyer Agent Name',
    accessorKey: 'buyerAgentName',
  },
  {
    id: 'buyerEmail',
    header: 'Buyer Agent Email',
    accessorKey: 'buyerAgentEmail',
  },
  {
    id: 'status',
    header: 'Offer Status',
    accessorKey: 'status',
    cell: memo(({ getValue }) => <OfferStatusCell status={getValue()} />),
  },
  {
    id: 'statusUpdatedAt',
    header: 'Status Updated At',
    accessorKey: 'statusUpdatedAt',
    cell: ({ getValue }) => {
      const statusUpdatedAt = getValue();
      return statusUpdatedAt ? <DateCell value={statusUpdatedAt} /> : <div>N/A</div>;
    },
  },
  {
    id: 'price',
    header: 'Offer Price',
    accessorKey: 'price',
    cell: ({ getValue }) => <PriceCell value={getValue()} />,
  },
  {
    id: 'counterPrice',
    header: 'Counter Price',
    accessorKey: 'counterPrice',
    cell: ({ getValue }) => <PriceCell value={getValue()} />,
  },
  {
    id: 'emd',
    header: 'Earnest Money Deposit (EMD)',
    accessorKey: 'earnestMoneyDeposit',
    cell: ({ getValue }) => <PriceCell value={getValue()} />,
  },
  {
    id: 'financingContingencyDays',
    header: 'Financing Contingency Days',
    accessorKey: 'financingContingencyDays',
  },
  {
    id: 'appraisalContingencyDays',
    header: 'Appraisal Contingency Days',
    accessorKey: 'appraisalContingencyDays',
  },
  {
    id: 'concession',
    header: 'Concession',
    accessorKey: 'concession',
  },
  {
    id: 'loanType',
    header: 'Loan Type',
    accessorKey: 'loanType',
    cell: memo(({ row }) => (
      <LoanTypeCell
        loanType={row.original.loanType}
        otherLoanType={row.original.otherLoanType}
      />
    )),
  },
  {
    id: 'coe',
    header: 'Close of Escrow (COE)',
    accessorKey: 'closeOfEscrowDate',
    cell: ({ getValue }) => <DateCell value={getValue()} />,
  },
  {
    id: 'menu',
    header: '',
    enableSorting: false,
    accessorFn: (row) => row,
    cell: memo(({ getValue }) => {
      const offer = getValue();
      return <MenuCell offer={offer} onEdit={onEdit} onDelete={onDelete} onAttachment={onAttachment} />;
    }),
    enableHiding: false,
  },
];

export default function OfferTable({ offers, onRowClick, onEdit, onDelete, onAttachment = noop }) {
  return (
    <DataTable
      columns={columns(onEdit, onDelete, onAttachment)}
      data={offers}
      tableContainerClassName="flex-1 whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0"
      enableMultiRowSelection={false}
      onRowClick={onRowClick}
      tdClassName="p-1.5"
    />
  );
}
