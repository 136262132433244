import ChipGroup from 'components/shared/ChipGroup';
import AllFiltersChip from 'components/shared/newfilters/AllFiltersChip';
import FilterChip from 'components/shared/newfilters/FilterChip';
import ReactTableColumnFilter from 'components/shared/newfilters/ReactTableColumnFilter';
import ReactTableColumnUniqueValuesComboboxFilter from 'components/shared/newfilters/ReactTableColumnUniqueValuesComboboxFilter';
import ReactTableFilter from 'components/shared/newfilters/ReactTableFilters';

export default function OffMarketInventoryFilters() {
  return (
    <ReactTableFilter>
      <ChipGroup>
        <ReactTableColumnFilter
          label="Market"
          columnId="market"
        >
          <FilterChip>
            <ReactTableColumnUniqueValuesComboboxFilter />
          </FilterChip>
        </ReactTableColumnFilter>
        <ReactTableColumnFilter
          label="Status"
          columnId="status"
        >
          <FilterChip>
            <ReactTableColumnUniqueValuesComboboxFilter />
          </FilterChip>
        </ReactTableColumnFilter>
        <AllFiltersChip />
      </ChipGroup>
    </ReactTableFilter>
  );
}
