import { forwardRef } from 'react';
import classNames from 'classnames';

function Badge({ className, children, label, onClick, visible = true, width = 'w-auto', px = 'px-4', py = 'py-1', hoverText = null }, ref) {
  const clazz = classNames(
    'rounded-full text-xs tracking-wide',
    px,
    py,
    width,
    className,
    {
      hidden: !visible,
      'cursor-pointer': hoverText,
    },
  );
  if (!children && !label) return null;
  return (
    <div
      className={clazz}
      onClick={onClick}
      title={hoverText}
      ref={ref}
    >
      {children || label || '' }
    </div>
  );
}


export default forwardRef(Badge);
