import { LoadingIndicator } from 'components/icons';

function LoadingOverlay({ message }) {
  return (
    <div className="absolute inset-0 bg-white bg-opacity-75 flex justify-center items-center z-10">
      <LoadingIndicator className="h-6 w-6 mr-2" />
      <span className="text-gray-700">{message}</span>
    </div>
  );
}

export default LoadingOverlay;
