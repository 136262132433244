import {
  Paragraph,
  Table,
  TableCell,
  TableRow,
} from 'docx';
import { chunk, isNil } from 'lodash';
import { scenarioPicturesPath } from '../../routes';
import {
  cellMargin,
  imageRunFromDataUri,
  tableCellRightBorder,
  tableCellTopBorder,
  tableHeader,
  tableProperties,
} from './util';

const IMAGE_WIDTH = 3.75;

export default async function buildPictures(scenario, abortSignal) {
  const csrfToken = document.querySelector('[name=csrf-token]').content;
  const pictures = await fetch(scenarioPicturesPath(scenario), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken,
    },
    signal: abortSignal,
  })
    .then(res => res.json())
    .catch(error => console.error(error));

  const images = await Promise.all(
    Object.values(pictures).filter(({ data }) => !isNil(data)).map(({ data }) => imageRunFromDataUri(data, IMAGE_WIDTH)),
  );

  return new Table({
    ...tableProperties,
    rows: [
      tableHeader('Property Photos', 2, true),
      ...chunk(images, 2).map(row => (
        new TableRow({
          children: [
            new TableCell({
              margins: cellMargin,
              borders: { ...tableCellTopBorder, ...tableCellRightBorder },
              children: [
                new Paragraph({ children: [row[0]] }),
              ],
            }),
            new TableCell({
              margins: cellMargin,
              borders: { ...tableCellTopBorder },
              children: [
                new Paragraph({ children: [row[1]] }),
              ],
            }),
          ],
        })
      )),
    ],
  });
}
