import { createSlice } from '@reduxjs/toolkit';

export const initialState = { data: [] };

export const leaseSlice = createSlice({
  name: 'app/lease',
  initialState,
  reducers: {
    setLeases: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setLeases } = leaseSlice.actions;

export default leaseSlice.reducer;
