import { useMemo } from 'react';
import { meanBy, sumBy } from 'lodash';
import { addWeeks } from 'date-fns';
import { formatCurrencyAbbreviated } from 'components/utils';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import DisposTable from './DisposTable';
import { closingWithinFn, metricFunc } from './disposition.utils';

const metricsData = [
  { label: '# of Closings', statFunc: (dispos => dispos.length) },
  { label: 'Avg Contract Price', statFunc: (dispos => meanBy(dispos, 'attributes.contractPrice')), formatter: formatCurrencyAbbreviated },
  { label: 'Avg Expected Profit', statFunc: (dispos => meanBy(dispos, 'attributes.estimatedProfit')), formatter: formatCurrencyAbbreviated },
  { label: 'Avg Expected ROI', statFunc: (dispos => meanBy(dispos, 'attributes.estimatedRoi')), formatter: formatCurrencyAbbreviated },
  { label: 'Total Expected Profit', statFunc: (dispos => sumBy(dispos, 'attributes.estimatedProfit')), formatter: formatCurrencyAbbreviated },
];

export default function ClosingInTwoWeeks({ asOfDate, lastWeekDate, lastMonthDate, filteredDispositions, oneWeekAgoFilteredDispositions, oneMonthAgoFilteredDispositions }) {
  const metrics = useMemo(
    () => metricsData.map(({ label, formatter, statFunc }) => metricFunc(
      label,
      statFunc,
      asOfDate,
      lastWeekDate,
      lastMonthDate,
      closingWithinFn(filteredDispositions, asOfDate, addWeeks(asOfDate, 2)),
      closingWithinFn(oneWeekAgoFilteredDispositions, lastWeekDate, addWeeks(lastWeekDate, 2)),
      closingWithinFn(oneMonthAgoFilteredDispositions, lastMonthDate, addWeeks(lastMonthDate, 2)),
      formatter
    )),
    [filteredDispositions],
  );

  return (
    <div className="flex flex-col gap-y-10">
      <ReturnMetricBoxes metrics={metrics} />
      <DisposTable
        filteredData={closingWithinFn(filteredDispositions, asOfDate, addWeeks(asOfDate, 2))}
        tableType="Closing in 2 Weeks"
      />
    </div>
  );
}
