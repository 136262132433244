import { Link, Outlet, useParams } from 'react-router-dom';
import { useFetchSubdivisionQuery, useFetchSubdivisionsQuery } from 'redux/subdivisionApiSlice';
import { Chevron } from 'components/icons';

export default function SubdivisionBase() {
  const { id } = useParams();
  const { isLoading: isloadingSubdivisions, data: subdivisions = [] } = useFetchSubdivisionsQuery({ include: ['properties', 'homeModels', 'homeBuilder'] });
  // query this separately so that we can include demographics / geoms on just the single subdivision
  const { isLoading: isLoadingSubdivision, data: subdivision } = useFetchSubdivisionQuery(id, { skip: !id });
  const isLoading = isloadingSubdivisions || isLoadingSubdivision;

  return (
    <>
      <div className="flex items-center gap-x-3 h-20 px-6 border-b shadow">
        <Link className="cursor-pointer" to="/subdivisions">
          <div className="text-xl">Subdivisions</div>
        </Link>
        {id && (
          <>
            <Chevron direction="right" className="w-4 text-gray-500" />
            <div className="text-xl text-gray-700">{subdivision?.name}</div>
          </>
        )}
      </div>
      <div className="bg-gray-100" style={{ height: 'calc(100vh - 80px)' }}>
        <Outlet context={{ isLoading, subdivision, subdivisions }} />
      </div>
    </>
  );
}
