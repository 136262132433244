import { differenceInCalendarDays, isValid, parseISO } from 'date-fns';
import { isFunction, isString } from 'lodash';
import { formatDate } from '../utils';

const formatRelativeDate = ({ diff, cutoff, pastDateSuffix = true, futureDatePrefix = true}) => {
  if (Math.abs(diff) > cutoff) {
    return null;
  }

  if (diff < -1) {
    return `${-diff} days${pastDateSuffix ? ' ago' : ''}`;
  }

  if (diff > 1) {
    return `${futureDatePrefix ? 'in ' : ''}${diff} days`;
  }

  switch (diff) {
    case -1: {
      return 'yesterday';
    }
    case 0: {
      return 'today';
    }
    case 1: {
      return 'tomorrow';
    }
    default: {
      throw new Error('Unreachable');
    }
  }
};

export default function DateElement({
  date,
  dateFormat,
  showRelative = false,
  showDate = false,
  relativeDateCutoff = 7,
  props,
}) {
  const parsedDate = isString(date) ? parseISO(date) : date;

  if (!isValid(parsedDate)) {
    console.error('Invalid date');
    return null;
  }

  let formattedRelativeDate = null;
  let relativeDifference;
  if (showRelative) {
    relativeDifference = differenceInCalendarDays(date, new Date());
    formattedRelativeDate = formatRelativeDate({
      diff: relativeDifference,
      cutoff: relativeDateCutoff,
      futureDatePrefix: !showDate,
    });
  }

  if (formattedRelativeDate && showDate) {
    formattedRelativeDate = `(${formattedRelativeDate})`;
  }

  const formattedDate = dateFormat ? formatDate(parsedDate, dateFormat) : formatDate(parsedDate);

  const dateValue = [];
  if (showDate || !formattedRelativeDate) {
    dateValue.push(formattedDate);
  }

  if (showRelative && formattedRelativeDate) {
    dateValue.push(formattedRelativeDate);
  }

  return (
    <time
      dateTime={parsedDate.toISOString()}
      {...(isFunction(props) ? props(relativeDifference) : props)}
    >
      {dateValue.join(' ')}
    </time>
  );
}
