import { groupBy } from 'lodash';
import classNames from 'classnames';
import { ALL_DEALS_STAGE, DEAL_STATE_CLOSED, DEAL_STATE_DEAD, INACTIVE_DEAL_STATES } from '../constants';
import StatusButton from './StatusButton';

const statusCount = (stage, stats, stagedDeals, deadDealsLoaded, closedDealsLoaded) => {
  if (stage === DEAL_STATE_DEAD && !deadDealsLoaded) {
    return stats.dead;
  }
  if (stage === DEAL_STATE_CLOSED && !closedDealsLoaded) {
    return stats.closed;
  }
  const deals = stagedDeals;
  return deals[stage]?.length;
};

export default function Widgets({ deals, deadDealsLoaded, closedDealsLoaded, dealStage, setDealStage, stages, stats, transactionType }) {
  const stagedDeals = groupBy(deals, 'stage');
  const isAllActive = dealStage === ALL_DEALS_STAGE;
  const activeDeals = deals.filter(deal => !INACTIVE_DEAL_STATES.includes(deal.stage));

  return (
    <div className="h-12 w-full grid grid-flow-col gap-0.5 text-sm font-medium bg-white">
      <div
        className={classNames(
          'h-full w-full py-3 text-center cursor-pointer grid place-items-center',
          {
            'border-b border-gray-200': !isAllActive,
            'border-b-4 border-primary-dark': isAllActive,
          },
        )}
        onClick={() => setDealStage(ALL_DEALS_STAGE)}
      >
        {`All Active · ${activeDeals.length || 0}`}
      </div>
      {stages.map((stage) => (
        <StatusButton
          count={statusCount(stage, stats, stagedDeals, deadDealsLoaded, closedDealsLoaded)}
          buttonActive={stage === dealStage}
          dealStage={dealStage}
          setDealStage={setDealStage}
          key={stage}
          title={stage}
          transactionType={transactionType}
        />
      ))}
    </div>
  );
}
