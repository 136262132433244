// have to spell out entire class name so Tailwind compiles it
const COLUMN_CLASS = {
  2: 'grid-cols-2',
  3: 'grid-cols-3',
};

export function RecordGridCell({ className = '', label, value, valuePlaceholder = '-' }) {
  return (
    <div className="py-2 flex justify-between items-center gap-x-2">
      <span className="w-1/2 uppercase tracking-wider text-xs text-neutral-medium font-medium">{label}</span>
      <span className={`text-sm font-medium text-right text-neutral-dark ${className}`}>{value ?? valuePlaceholder}</span>
    </div>
  );
}

export function RecordGridColumn({ children }) {
  return (
    <div className="flex flex-col divide-y divide-neutral-dark divide-opacity-12">
      {children}
    </div>
  );
}

export default function RecordGrid({ children, columns = 3 }) {
  return (
    <div className={`grid gap-x-12 ${COLUMN_CLASS[columns]}`}>
      {children}
    </div>
  );
}
