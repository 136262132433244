import { Outlet, useOutletContext } from 'react-router-dom';
import RightNav from 'components/RightNav/Index';
import ModalDisplayer from './ModalDisplayer';
import Navigation from './Navigation';

export default function DealLayout() {
  const context = useOutletContext();

  return (
    <>
      <Navigation />
      <div className="w-full flex">
        <Outlet context={context} />
        <div className="flex-none">
          <RightNav />
        </div>
      </div>
      <ModalDisplayer />
    </>
  );
}
