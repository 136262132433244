/* eslint-disable no-param-reassign */
import { cloneDeep, find, get, isEmpty, isEqual, isNil, times } from 'lodash';
import { TAX_GROWTH_TIMING_CALENDAR } from 'components/dcf/expense';
import {
  ASSESSED_VALUE_AS_PERCENT,
  assignAssessedValuesFromPercentIncreases,
  assessedValuePercentIncreases,
} from 'components/dcf/tax';
import {
  calcExitPrice,
  CALCULATED_EXIT_PRICE,
} from 'components/dcf/dcf';
import {
  cashFlowStartDate,
  dateFromString,
  resizeArray,
} from 'components/utils';

const PURCHASE_PRICE_FIELD = 'purchasePrice';
const HOLD_PERIOD_FIELD = 'holdPeriod';
const EXPENSE_ITEMS_FIELD = 'expenseItems';
const BELOW_THE_LINE_EXPENSE_ITEMS_FIELD = 'belowTheLineExpenseItems';
const INCOME_ITEMS_FIELD = 'incomeItems';
const GROWTH_RATES_FIELD = 'growthRates';
const REIMBURSABLE_PERCENT_FIELD = 'reimbursablePercent';
const FUTURE_TAX_PARAMETERS_FIELD = 'futureTaxParameters';
const TAX_EXPENSE_GROWTH_TIMING = 'taxExpenseGrowthTiming';
const TAX_EXPENSE_GROWTH_RATE_FIELD = 'taxExpenseGrowthRate';
const CLOSING_DATE = 'closingDate';
const ALIGN_TAXES_TO_PURCHASE_FIELD = 'alignTaxesToPurchase';
const MARKET_VALUE_TOTAL_FIELD_NAME = 'marketValueTotal';
const ASSESSED_VALUE_PURCHASE_PERCENT_FIELD = 'assessedValuePurchasePercent';
const EXIT_PRICE_METHOD_FIELD = 'exitPriceMethod';
const CUSTOM_EXIT_PRICE_FIELD = 'customExitPrice';
const LT_ONE_YR_HOLD = 'ltOneYrHold';

const ARRAY_FIELDS = [
  'collectionLossRate',
  'inflationRate',
  'rentGrowthRate',
  'taxExpenseGrowthRate',
  'vacancyRate',
  'rentControlledGrowthRate',
];
const CALENDAR_ALIGNED_ELIGIBLE_FIELDS = [
  FUTURE_TAX_PARAMETERS_FIELD,
  'taxExpenseGrowthRates',
];
const arraysToMatchHoldPeriod = (dcfParams) => [
  ...ARRAY_FIELDS.map((field) => `${field}s`),
  FUTURE_TAX_PARAMETERS_FIELD,
  ...times(dcfParams[EXPENSE_ITEMS_FIELD].length, (index) => `${EXPENSE_ITEMS_FIELD}[${index}].${GROWTH_RATES_FIELD}`),
  ...dcfParams[EXPENSE_ITEMS_FIELD].flatMap((item, index) => (isNil(item.reimbursablePercent) ? [] : [`${EXPENSE_ITEMS_FIELD}[${index}].${REIMBURSABLE_PERCENT_FIELD}`])),
  ...times(dcfParams[INCOME_ITEMS_FIELD].length, (index) => `${INCOME_ITEMS_FIELD}[${index}].${GROWTH_RATES_FIELD}`),
  ...times(dcfParams[BELOW_THE_LINE_EXPENSE_ITEMS_FIELD].length, (index) => `${BELOW_THE_LINE_EXPENSE_ITEMS_FIELD}[${index}].${GROWTH_RATES_FIELD}`),
];

const intitialState = {
  baseParams: null,
  pendingParams: false,
};

const updateModelParameters = (prevState, param, newValue) => {
  const prevParams = prevState.pendingParams;
  const updatedParams = cloneDeep(prevParams);
  updatedParams.dcfParams[param] = newValue;
  const prevValue = prevParams.dcfParams[param];

  // if adjusting holdPeriod, update size of array parameters accordingly
  if (param === HOLD_PERIOD_FIELD || param === LT_ONE_YR_HOLD) {
    let lengthDiff;
    if (param === LT_ONE_YR_HOLD) {
      // user hit the less than 1 year hold toggle
      const holdPeriod = updatedParams.dcfParams[HOLD_PERIOD_FIELD];
      if (updatedParams.dcfParams[LT_ONE_YR_HOLD]) {
        // changing to a monthly hold
        lengthDiff = 1 - holdPeriod;
      } else {
        // changing to a yearly hold
        lengthDiff = holdPeriod - 1;
      }
    } else if (updatedParams.dcfParams[LT_ONE_YR_HOLD]) {
      // hold period is in changing and hold period is in months
      lengthDiff = 0;
    } else {
      // hold period is in changing and hold period is in years
      lengthDiff = newValue - prevValue;
    }

    // note that not all arrays have the same length
    arraysToMatchHoldPeriod(updatedParams.dcfParams)
      .map((dcfParamPath) => get(updatedParams.dcfParams, dcfParamPath))
      .forEach((array) => resizeArray(array, array.length + lengthDiff));

    // if we are switching from <= 1 year hold, to multi-year hold
    //  because below the line expense items do not extend to hold + 1,
    //  we need to set a default growth rate because previously there was no growth rate
    updatedParams.dcfParams.belowTheLineExpenseItems.forEach(expenseItem => {
      expenseItem.growthRates = expenseItem.growthRates.map(rate => rate || 0);
    });
  }

  // if taxes are based on purchase/exit, make sure tax basis stays aligned
  if (param === PURCHASE_PRICE_FIELD && updatedParams.dcfParams[ALIGN_TAXES_TO_PURCHASE_FIELD]) {
    const prevValues = updatedParams.dcfParams[FUTURE_TAX_PARAMETERS_FIELD].map(element => element.marketValueTotal);
    const percentageChangeArray = prevValues.map((value, index) => {
      if (index === 0) {
        return;
      }
      const prevVal = prevValues[index - 1];
      return ((value - prevVal) / prevVal);
    }).filter(n => n !== undefined);

    updatedParams.dcfParams[FUTURE_TAX_PARAMETERS_FIELD].forEach(((parameter, index) => {
      if (index === 0) {
        if (newValue > 0) {
          parameter.marketValueTotal = newValue * updatedParams.dcfParams.assessedValuePurchasePercent;
        } else {
          parameter.marketValueTotal = updatedParams.dcfParams[FUTURE_TAX_PARAMETERS_FIELD][0].marketValueTotal * updatedParams.dcfParams.assessedValuePurchasePercent;
        }
      } else if (index !== (updatedParams.dcfParams[FUTURE_TAX_PARAMETERS_FIELD].length - 1)) {
        const previousAssessedValue = updatedParams.dcfParams[FUTURE_TAX_PARAMETERS_FIELD][index - 1].marketValueTotal;
        const percentageChange = percentageChangeArray[index - 1];
        parameter.marketValueTotal = (previousAssessedValue * (1 + percentageChange));
      }
    }));
  }

  if (ARRAY_FIELDS.includes(param)) {
    updatedParams.dcfParams[`${param}s`].fill(newValue);
  } else if (ARRAY_FIELDS.includes(param.substring(0, param.length - 1))) {
    // keep individual parameter aligned with associated array parameter
    // required to make sure rentGrowthRate stays aligned with rentGrowthRates
    updatedParams.dcfParams[param.substring(0, param.length - 1)] = newValue[0];
  }

  // If we're switching between calendar and deal aligned tax expenses
  // and that the start date is not in January
  // then the arrays in CALENDAR_ALIGNED_ELIGIBLE_FIELDS need to be resized
  if (param === TAX_EXPENSE_GROWTH_TIMING
      && newValue !== prevValue
      && cashFlowStartDate(updatedParams.dcfParams).getMonth() > 0) {
    const lengthDiff = newValue === TAX_GROWTH_TIMING_CALENDAR ? 1 : -1;
    CALENDAR_ALIGNED_ELIGIBLE_FIELDS
      .map((field) => get(updatedParams.dcfParams, field))
      .filter((array) => !isEmpty(array))
      .forEach((array) => resizeArray(array, array.length + lengthDiff));
  }

  // If tax expense is aligned to calendar and that closing date is being changed
  // check if the cash flow start date is moving to/from January
  // If so, fields in CALENDAR_ALIGNED_ELIGIBLE_FIELDS need to be resized
  if (param === CLOSING_DATE
      && updatedParams.dcfParams.taxExpenseGrowthTiming === TAX_GROWTH_TIMING_CALENDAR
      && newValue.getMonth() !== dateFromString(prevValue).getMonth()
      && (cashFlowStartDate(updatedParams.dcfParams).getMonth() === 0 || cashFlowStartDate(prevParams.dcfParams).getMonth() === 0)) {
    // If the new date is in January, shrink the array by 1
    // Otherwise, grow the array by 1
    const lengthDiff = cashFlowStartDate(updatedParams.dcfParams).getMonth() === 0 ? -1 : 1;
    CALENDAR_ALIGNED_ELIGIBLE_FIELDS
      .map((field) => get(updatedParams.dcfParams, field))
      .filter((array) => !isEmpty(array))
      .forEach((array) => resizeArray(array, array.length + lengthDiff));
  }

  // make sure the futureTaxParameters field stays aligned with changes to other tax-related parameters
  if (param === ASSESSED_VALUE_PURCHASE_PERCENT_FIELD) {
    const taxParameters = updatedParams.dcfParams[FUTURE_TAX_PARAMETERS_FIELD];
    const currentPercentIncreases = assessedValuePercentIncreases(taxParameters);
    taxParameters[0][MARKET_VALUE_TOTAL_FIELD_NAME] = newValue * updatedParams.dcfParams[PURCHASE_PRICE_FIELD];
    assignAssessedValuesFromPercentIncreases(taxParameters, currentPercentIncreases);
  } else if (param === TAX_EXPENSE_GROWTH_RATE_FIELD) {
    const taxParameters = updatedParams.dcfParams[FUTURE_TAX_PARAMETERS_FIELD];
    const percentIncreases = taxParameters.map(() => newValue);
    assignAssessedValuesFromPercentIncreases(taxParameters, percentIncreases);
  } else if (param === ASSESSED_VALUE_AS_PERCENT) {
    updatedParams.dcfParams.assessedValuePurchasePercent = newValue[0];
    updatedParams.dcfParams.assessedValueExitPercent = newValue[1];
    if (isNil(newValue[0]) && isNil(newValue[1])) {
      // if switching back to using assessed value, default it to assessor value
      updatedParams.dcfParams.assessedValue = updatedParams.dcfParams.assessorAssessedValue;
    }
  }

  if (param === FUTURE_TAX_PARAMETERS_FIELD && updatedParams.dcfParams[ALIGN_TAXES_TO_PURCHASE_FIELD]) {
    updatedParams.dcfParams.assessedValuePurchasePercent = updatedParams.dcfParams[FUTURE_TAX_PARAMETERS_FIELD][0].marketValueTotal / updatedParams.dcfParams[PURCHASE_PRICE_FIELD];
  }

  // if taxes are aligned to exit price, make sure exit year tax basis stays aligned with exit price
  if (updatedParams.dcfParams.assessedValueExitPercent) {
    const exitPrice = calcExitPrice(updatedParams.dcfParams);
    const exitAssessedValue = exitPrice * updatedParams.dcfParams.assessedValueExitPercent;
    // WARNING: this can introduce a rounding difference compared to the initial value calculated by the back-end
    //  which results in the model's paramsUnsaved logic to mistakenly think a change has been made
    // TODO: we could investigate roudning this value to match the back-end calc to avoid this
    updatedParams.dcfParams[FUTURE_TAX_PARAMETERS_FIELD][updatedParams.dcfParams[FUTURE_TAX_PARAMETERS_FIELD].length - 1].marketValueTotal = exitAssessedValue;
  }

  if (param === EXIT_PRICE_METHOD_FIELD && newValue === CALCULATED_EXIT_PRICE) {
    updatedParams.dcfParams[CUSTOM_EXIT_PRICE_FIELD] = null;
  }

  const paramsUnsaved = !isEqual(prevState.baseParams, updatedParams);
  localStorage.setItem('paramsUnsaved', paramsUnsaved);

  return { ...prevState, pendingParams: updatedParams };
};

const resetPendingParams = (state) => {
  localStorage.removeItem('paramsUnsaved');
  return { ...state, pendingParams: state.baseParams };
};

// eslint-disable-next-line default-param-last
const model = (state = intitialState, action) => {
  switch (action.type) {
    case 'setBaseParams':
      return { ...state, baseParams: action.payload, pendingParams: action.payload };
    case 'setPendingParams':
      return updateModelParameters(state, action.payload.param, action.payload.newValue);
    case 'resetPendingParams':
      return resetPendingParams(state);
    case 'addModelNote':
      return { ...state, pendingParams: { ...state.pendingParams, notes: [...state.pendingParams.notes, action.payload] } };
    default:
      return state;
  }
};

export default model;
