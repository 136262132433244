import cx from 'classnames';

export default function IndicatorBadge({ className, count = 1, small = false, maxScale = 4, ...props }) {
  if (!count || count < 0) {
    return null;
  }

  const label = count > (10 ** maxScale - 1) ? `${10 ** (maxScale - 1) - 1}+` : count;

  return (
    <div
      {...props}
      className={cx(
        'absolute flex items-center justify-center text-vxs font-bold text-white bg-red-500 rounded-full cursor-default',
        {
          'px-1 h-[1lh] min-w-[1lh] left-full bottom-full -ml-3 -mb-3.5': !small,
          'size-1.5 right-0 top-0': small,
        },
      )}
    >
      {!small ? label : undefined}
    </div>
  );
}
