import { getMemoOptions } from '@tanstack/react-table';
import { throwingMemo } from '../table.helpers';
import createTableExtension from '../utils/createTableExtension';
import { getColumnVisibilityConfigurable } from './utils';

/**
 * @type {(table: import('@tanstack/react-table').Table) => import('@tanstack/react-table').Column[]}
 */
const getVisibilityConfigurableFlatColumns = createTableExtension((table) => (
  throwingMemo(
    () => [table.getAllFlatColumns()],
    (flatColumns) => (
      flatColumns.filter((column) => getColumnVisibilityConfigurable(column) !== false)
    ),
    getMemoOptions(table.options, 'debugColumns', 'getConfigurableFlatColumns'),
  )
));

export default getVisibilityConfigurableFlatColumns;
