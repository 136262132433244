import { camelCaseKeys, coalesceListing, standardizeListing } from 'components/utils';
import { apiSlice } from './apiSlice';
import { OFF_MARKET_LISTINGS_TAG } from './utils';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchOffMarketMarketplaceDashboardStatistics: builder.query({
        query: () => '/api/off_market_marketplace/dashboard',
        transformResponse: (response) => camelCaseKeys(response),
      }),
      fetchOffMarketMarketplaceInventory: builder.query({
        query: () => '/api/off_market_marketplace/listings',
        transformResponse: (response) => camelCaseKeys(response).data.map(({ attributes: listing }) =>
          coalesceListing({ listing: standardizeListing(listing), parcel: listing.parcel })),
        providesTags: [OFF_MARKET_LISTINGS_TAG],
      }),
      fetchOffMarketMarketplaceOffers: builder.query({
        query: () => '/api/off_market_marketplace/offers',
        transformResponse: (response) => camelCaseKeys(response).data.map(d => d.attributes),
      }),
    };
  },
});

export const {
  useFetchOffMarketMarketplaceDashboardStatisticsQuery,
  useFetchOffMarketMarketplaceInventoryQuery,
  useFetchOffMarketMarketplaceOffersQuery,
} = extendedApiSlice;
