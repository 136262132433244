import { uniq } from 'lodash';
import { LocationIconWithText } from 'components/icons';

function DOMLegend() {
  return (
    <div className="border border-gary-600 absolute top-0 right-0 bg-white z-40 w-auto h-auto border-b rounded-b-lg border-md p-6 z-50">
      <div className="flex flex-col space-y-6">
        <LocationIconWithText markerColor="#0C4599" homeColor="#fff" text="DOM Less Than 80" textClassName="text-sm" />
        <LocationIconWithText markerColor="red" homeColor="#fff" text="DOM Greater Than 80" textClassName="text-sm" />
      </div>
    </div>
  );
}

function OfferStatusLegend() {
  return (
    <div className="border border-gary-600 absolute top-0 right-0 bg-white z-40 w-auto h-auto border-b rounded-b-lg border-md p-6 z-50">
      <div className="flex flex-col space-y-6">
        <LocationIconWithText markerColor="red" homeColor="#fff" text="Rejected Offers" textClassName="text-sm" />
        <LocationIconWithText markerColor="green" homeColor="#fff" text="Accepted Offers" textClassName="text-sm" />
        <LocationIconWithText markerColor="blue" homeColor="#fff" text="Offered" textClassName="text-sm" />
        <LocationIconWithText markerColor="grey" homeColor="#fff" text="Other Status" textClassName="text-sm" />
      </div>
    </div>
  );
}

function StageLegend({ stageColors = {} }) {
  const stages = uniq(Object.keys(stageColors));
  return (
    <div className="border border-gary-600 absolute top-0 right-0 bg-white z-40 w-auto h-auto border-b rounded-b-lg border-md p-4 z-50">
      <div className="flex flex-col space-y-6">
        {
            stages.map(stage => (
              <div key={stage} className="flex space-x-2">
                <div className="h-5 w-5 rounded-full bg-black" style={{ backgroundColor: stageColors[stage] }} />
                <div className="font-semibold text-sm">{stage}</div>
              </div>
            ))
          }
      </div>
    </div>
  );
}

export { OfferStatusLegend, DOMLegend, StageLegend };
