import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { isEmpty, sortBy } from 'lodash';
import { setShowCreateDealModal, showDealNotesModal } from 'actions/deal_navigation';
import { LAYOUT } from 'components/constants';
import Button from 'components/shared/NewButton';
import { useFetchDealNotesQuery } from 'redux/apiSlice';
import { LoadingIndicator, Plus, X } from '../icons';
import { formatTimeZone } from '../utils';
import { formatMention } from 'components/Features/Notification/MentionedInDealNotification';

function DealNotesDrawer({ context, setShowNotes }) {
  const dispatch = useDispatch();
  const { data: { deal, users } } = context;
  const dealId = deal?.id;

  const { data: notes = [], isLoading } = useFetchDealNotesQuery(({ dealId }), { skip: !dealId });

  if (!deal) {
    return (
      <div
        className="absolute flex-col w-128 bg-white overflow-auto cursor-default float-right border-l border-gray-200 shadow-lg z-30"
        style={{
          height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)`,
          right: LAYOUT.rightNavWidth,
          top: LAYOUT.dealHeaderHeight,
        }}
      >
        <div className="w-full flex justify-between items-center mb-6 border-b p-6">
          <div className="flex flex-col">
            <span className="text-xl">Notes</span>
          </div>
          <div className="flex items-center gap-x-4">
            <Button outlined leadingIcon={<Plus />} label="Add Note" className="cursor-pointer" onClick={() => dispatch(setShowCreateDealModal(true))} />
            <div className="cursor-pointer" onClick={() => setShowNotes(false)} name="closeNotesDrawerButton"><X className="w-6" /></div>
          </div>
        </div>
        <div className="flex justify-center mt-12">
          <div className="text-center">
            <div className="text-xl">No Notes</div>
            <div className="text-xs text-gray-500 my-4">You must create a deal to track deal notes</div>
            <div className="flex justify-center">
              <Button filled label="Create Deal" leadingIcon={<Plus className="w-4" />} onClick={() => dispatch(setShowCreateDealModal(true))} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return ReactDOM.createPortal(
    <div
      className="absolute flex-col w-128 bg-white overflow-auto cursor-default float-right border-l border-gray-200 shadow-lg z-30"
      style={{
        height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)`,
        right: LAYOUT.rightNavWidth,
        top: LAYOUT.dealHeaderHeight,
      }}
    >
      <div className="w-full flex justify-between items-center mb-6 border-b p-6">
        <div className="flex flex-col">
          <span className="text-xl">Notes</span>
        </div>
        <div className="flex items-center gap-x-4">
          <Button outlined leadingIcon={<Plus />} label="Add Note" className="cursor-pointer" onClick={() => dispatch(showDealNotesModal(true, dealId))} />
          <div className="cursor-pointer" onClick={() => setShowNotes(false)} name="closeNotesDrawerButton"><X className="w-6" /></div>
        </div>
      </div>
      {isLoading && (
        <div className="text-center mt-12">
          <LoadingIndicator className="inline h-6" />
          <div className="text-sm text-neutral-light mt-4">Loading notes...</div>
        </div>
      )}
      {notes?.length > 0 && (
        sortBy(notes, 'createdAt').reverse().map((note, key) => (
          <div key={key} className="mt-2 mb-3 px-6">
            <div className="grid grid-rows-1 grid-flow-col gap-4 mb-2">
              <div className="text-xs font-normal text-gray-900 text-left">{users.find(user => user.id === note?.noteGeneratedById)?.email || ''}</div>
              <div className="text-xs font-normal text-gray-900 text-right">{formatTimeZone(note.createdAt)}</div>
            </div>
            <div className="text-sm font-normal text-gray-900 text-left mb-4">{formatMention(note.body)}</div>
            <hr className="border-1 mt-3 border-gray-200" />
          </div>
        ))
      )}
      {!isLoading && isEmpty(notes) && (
      <div className="mt-11 text-center text-gray-900">
        <div className="text-2xl font-normal">No Notes</div>
        <div className="mt-5 text-sm font-normal">Your organizations notes will appear here</div>
      </div>
      )}
    </div>,
    document.body,
  );
}

export default DealNotesDrawer;
