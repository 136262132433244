import { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { Card } from 'components/common/Card';

const { CanvasJSChart } = CanvasJSReact;

function Historical() {
  const [dataPoints, setDataPoints] = useState([]);

  useEffect(() => {
    fetch('https://canvasjs.com/data/gallery/react/nifty-stock-price.json')
      .then(response => response.json())
      .then(data => {
        const points = data.map(dp => ({
          x: new Date(dp.x),
          y: dp.y,
        }));
        setDataPoints(points);
      });
  }, []);

  const options = {
    theme: 'light2',
    data: [{
      type: 'line',
      xValueFormatString: 'MMM YYYY',
      yValueFormatString: '#,##0.00',
      dataPoints,
    }],
  };

  const charts = [
    { header: 'Occupancy' },
    { header: 'Avg. DOM' },
    { header: 'Closing Ratio' },
    { header: 'Stabilized Occupancy' },
    { header: 'Rent Growth - New Leases' },
    { header: 'Avg. Days to List' },
    { header: '# of Properties - DOM > 30' },
    { header: 'Open Applications' },
    { header: 'Available Units' },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-x-3 gap-y-6">
      {charts.map((chart, index) => (
        <Card header={chart.header} key={index}>
          <CanvasJSChart options={options} />
        </Card>
      ))}
    </div>
  );
}

export default Historical;
