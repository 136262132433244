import { useState } from 'react';

function EmailTag({ email, onRemove }) {
  return (
    <div className="flex items-center bg-blue-100 text-blue-800 px-2 py-1 rounded-full mr-2 mb-2">
      <span>{email}</span>
      <button
        type="button"
        onClick={() => onRemove(email)}
        className="ml-1 text-blue-500 hover:text-blue-700 focus:outline-none"
        aria-label={`Remove ${email}`}
      >
        &times;
      </button>
    </div>
  );
}

function EmailInputField({ label, emails, setEmails }) {
  const [inputValue, setInputValue] = useState('');

  const addEmails = (value) => {
    const newEmails = value
      .split(/[, ]+/)
      .map((email) => email.trim())
      .filter((email) => email.length > 0 && !emails.includes(email));

    // Simple email regex for validation
    const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = newEmails.filter((email) => !validEmailRegex.test(email));

    if (invalidEmails.length > 0) {
      alert(`Invalid email addresses: ${invalidEmails.join(', ')}`);
    }

    const filteredEmails = newEmails.filter((email) => validEmailRegex.test(email));

    setEmails([...emails, ...filteredEmails]);
  };

  const handleKeyDown = (e) => {
    if (['Enter', ',', ' '].includes(e.key)) {
      e.preventDefault();
      if (inputValue.trim() !== '') {
        addEmails(inputValue);
        setInputValue('');
      }
    }
  };

  const handleBlur = () => {
    if (inputValue.trim() !== '') {
      addEmails(inputValue);
      setInputValue('');
    }
  };

  const handleRemove = (email) => {
    setEmails(emails.filter((e) => e !== email));
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div className="flex flex-wrap border border-gray-300 rounded p-2">
        {emails.map((email) => (
          <EmailTag key={email} email={email} onRemove={handleRemove} />
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          className="flex-1 outline-none p-1"
          placeholder={`Add ${label.toLowerCase()}...`}
        />
      </div>
    </div>
  );
}

export default EmailInputField;
