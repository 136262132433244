import { constant, fill, isEmpty, pick, sum, sumBy, times } from 'lodash';
import { differenceInMonths, startOfMonth } from 'date-fns';
import { calcBelowTheLineExpenseItemCashFlow } from './expense';
import { dateFromString, sumArrays } from '../utils';
import { ACCOUNTING_METHOD_FOLLOW_ON, ACCOUNTING_METHODS, holdPeriodInMonths } from './dcf';
import {
  ITEMIZED_INPUT_METHOD_DOLLAR,
  ITEMIZED_INPUT_METHOD_DPSF,
  ITEMIZED_INPUT_METHOD_DPU,
  ITEMIZED_INPUT_METHOD_TOTAL_COST,
  ITEMIZED_INPUT_METHOD_TOTAL_FOLLOW_ON,
  ITEMIZED_INPUT_METHODS,
} from './itemizedItem';

export const CAPITAL_INPUT_METHODS = pick(
  ITEMIZED_INPUT_METHODS,
  ITEMIZED_INPUT_METHOD_DOLLAR,
  ITEMIZED_INPUT_METHOD_DPU,
  ITEMIZED_INPUT_METHOD_DPSF,
);

export const CAPITAL_FEES_INPUT_METHODS = pick(
  ITEMIZED_INPUT_METHODS,
  ITEMIZED_INPUT_METHOD_TOTAL_COST,
  ITEMIZED_INPUT_METHOD_TOTAL_FOLLOW_ON,
);

const ALL_CAPITAL_INPUT_METHODS = {
  ...CAPITAL_INPUT_METHODS,
  ...CAPITAL_FEES_INPUT_METHODS,
};

const zeroMonthlyArray = (dcfParams) => times(holdPeriodInMonths(dcfParams) + 1, constant(0));

export const calcBelowTheLineExpenses = (dcfParams, egr, grossRent, totalEquity) => {
  const { belowTheLineExpenseItems } = dcfParams;
  if (isEmpty(belowTheLineExpenseItems)) {
    return zeroMonthlyArray(dcfParams);
  }

  const belowTheLineExpenses = belowTheLineExpenseItems.map(item => calcBelowTheLineExpenseItemCashFlow(item, dcfParams, egr, grossRent, totalEquity));
  const totalBelowTheLineExpenses = sumArrays(...belowTheLineExpenses);
  totalBelowTheLineExpenses.unshift(0);

  return totalBelowTheLineExpenses;
};

export const calcUnitTurnExpenses = (unit, dcfParams) => {
  const expenses = zeroMonthlyArray(dcfParams);
  if (unit.turnBudget) {
    const averageMonthlyCost = unit.turnBudget / (unit.turnDowntime || 1);
    fill(expenses, averageMonthlyCost, unit.rollToMarket, unit.rollToMarket + (unit.turnDowntime || 1));
  }
  return expenses;
};

export const calcTerminationExpenses = (unit, dcfParams) => {
  const expenses = zeroMonthlyArray(dcfParams);
  if (unit.terminationCost) {
    const averageMonthlyCost = unit.terminationCost / (unit.turnDowntime || 1);
    fill(expenses, averageMonthlyCost, unit.rollToMarket, unit.rollToMarket + (unit.turnDowntime || 1));
  }
  return expenses;
};

const getStartMonthNum = (item, closingDate) => (
  differenceInMonths(startOfMonth(dateFromString(item.startDate)), startOfMonth(dateFromString(closingDate)))
);

export const calcCapitalItemTotalBudget = (item, dcfParams, totalCapitalExpense = 0, totalFollowOnCapitalExpense = 0) => {
  if (!item.enabled) {
    return 0;
  }
  const { units } = dcfParams;

  const capitalCostParams = {
    quantity: item.quantity,
    numberOfUnits: units?.length || 0,
    rsf: units?.reduce((total, unit) => total + (unit.rsf || 0), 0) || 0,
    totalCapitalExpense,
    totalFollowOnCapitalExpense,
  };

  return ALL_CAPITAL_INPUT_METHODS[item.inputMethod].func({ inputValue: item.unitCost }, capitalCostParams);
};

const calcMonthlyCapitalItemExpense = (item, dcfParams, totalCapitalExpense = 0, totalFollowOnCapitalExpense = 0) => {
  const { closingDate } = dcfParams;

  const expenses = zeroMonthlyArray(dcfParams);
  const startMonthNum = getStartMonthNum(item, closingDate);
  const averageMonthlyCost = calcCapitalItemTotalBudget(item, dcfParams, totalCapitalExpense, totalFollowOnCapitalExpense) / (item.duration || 1);
  return fill(expenses, averageMonthlyCost, startMonthNum, startMonthNum + item.duration);
};

const calcMonthlyTurnExpenses = (accountingMethod, dcfParams) => {
  const { units } = dcfParams;

  const filteredUnits = units.filter(unit => unit.turnAccountingMethod === accountingMethod);
  if (filteredUnits.length === 0) {
    return [zeroMonthlyArray(dcfParams)];
  }

  return filteredUnits.map(unit => sumArrays(calcUnitTurnExpenses(unit, dcfParams), calcTerminationExpenses(unit, dcfParams)));
};

// TODO: check for not-false to handle that this is a new field and existing items/fees
//       will have the value be null and we want to default those to true
export const enabledCapitalFees = (dcfParams) => dcfParams.capitalFees.filter(item => item.enabled !== false);
export const enabledCapitalItems = (dcfParams) => dcfParams.capitalItems.filter(item => item.enabled !== false);

function calcMonthlyOtherCapitalExpenses(accountingMethod, dcfParams) {
  const filteredCapitalItems = enabledCapitalItems(dcfParams).filter(item => item.accountingMethod === accountingMethod);
  if (filteredCapitalItems.length === 0) {
    return [zeroMonthlyArray(dcfParams)];
  }

  return filteredCapitalItems.map(item => calcMonthlyCapitalItemExpense(item, dcfParams));
}

const calcMonthlyCapitalFeesAndCosts = (accountingMethod, dcfParams, totalCapitalExpense, totalFollowOnCapitalExpense) => {
  const filteredFees = enabledCapitalFees(dcfParams).filter(item => item.accountingMethod === accountingMethod);
  if (filteredFees.length === 0) {
    return [zeroMonthlyArray(dcfParams)];
  }

  return filteredFees.map(item => calcMonthlyCapitalItemExpense(item, dcfParams, totalCapitalExpense, totalFollowOnCapitalExpense));
};

export const calcMonthlyConstructionExpenses = (dcfParams) => (
  Object.fromEntries(ACCOUNTING_METHODS.map(method => (
    [
      method,
      sumArrays(
        ...calcMonthlyTurnExpenses(method, dcfParams),
        ...calcMonthlyOtherCapitalExpenses(method, dcfParams),
      ),
    ]
  )))
);

export const calcMonthlyCapitalProjects = (dcfParams) => {
  const monthlyTotal = calcMonthlyConstructionExpenses(dcfParams);

  const followOnTotal = sum(monthlyTotal[ACCOUNTING_METHOD_FOLLOW_ON]);
  const total = sumBy(Object.values(monthlyTotal), sum);
  // eslint-disable-next-line no-return-assign
  ACCOUNTING_METHODS.forEach(method => (
    monthlyTotal[method] = sumArrays(
      monthlyTotal[method],
      ...calcMonthlyCapitalFeesAndCosts(method, dcfParams, total, followOnTotal),
    )
  ));

  return monthlyTotal;
};
