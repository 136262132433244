import { useDispatch, useSelector } from 'react-redux';
import { clearDealNavigationModal, generateDealSummaryForModel } from 'actions/deal_navigation';
import Alert from 'components/Alert';
import Button from 'components/Button';
import DashboardModal from 'components/dashboard/DashboardModal';

export default function GenerateDealSummaryModal({ context }) {
  const { modelData } = context;
  const { alert, saving } = useSelector(state => state.navigation.modal);
  const dispatch = useDispatch();
  const { model } = modelData;

  const onClose = () => dispatch(clearDealNavigationModal());

  return (
    <DashboardModal
      title="Deal Summary"
      action={(
        <Button
          confirm
          text="Generate"
          className="font-semibold border border-black border-opacity-12"
          shadow={false}
          submit={false}
          isLoading={saving}
          onClick={() => dispatch(generateDealSummaryForModel(model))}
        />
      )}
      loading={!model}
      onClose={onClose}
    >
      {alert && <Alert {...alert} />}
    </DashboardModal>
  );
}
