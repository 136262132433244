import { useState, useEffect } from 'react';
import { useFetchEmailTemplatesQuery, useUpdateEmailTemplateMutation } from 'redux/emailTemplateApiSplice';
import Alert from 'components/Alert';
import { titleCase } from 'components/utils';
import { InformationIcon, LoadingIndicator } from 'components/icons';
import { useFetchSelfQuery } from 'redux/apiSlice';
import EmailTemplateEditor from './EmailTemplateEditor';
import useFilteredEmailTemplates from './useFilteredEmailTemplates';

function EmailTemplates() {
  const { data: currentUser, isLoading: isLoadingUser } = useFetchSelfQuery();
  const { data: templates = [], error: fetchError, isLoading: isLoadingEmailTemplates } = useFetchEmailTemplatesQuery();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [updateEmailTemplate, { error: updateError }] = useUpdateEmailTemplateMutation();
  const [alert, setAlert] = useState(null);
  const filteredEmailTemplates = useFilteredEmailTemplates(templates, 'offer');

  useEffect(() => {
    if (fetchError) {
      setAlert({ type: 'error', message: `Error fetching templates: ${fetchError.message}` });
    }
  }, [fetchError]);

  useEffect(() => {
    if (updateError) {
      setAlert({ type: 'error', message: `Error updating template: ${updateError.message}` });
    }
  }, [updateError]);

  const saveTemplate = async (updatedContent) => {
    try {
      if (selectedTemplate) {
        const data = await updateEmailTemplate({ id: selectedTemplate.id, content: updatedContent });
        if (!data.error) {
          setAlert({ type: 'success', text: 'Template updated successfully' });
        } else setAlert({ type: 'danger', text: 'Error updating template' });
      }
    } catch (error) {
      setAlert({ type: 'error', text: `Error saving template: ${error.message}` });
    }
  };

  if (isLoadingUser || isLoadingEmailTemplates) {
    return (
      <LoadingIndicator className="w-6 text-primary-500" />
    );
  }

  return (
    <div className="h-screen w-full flex">
      <div className="grid grid-cols-4 gap-4 w-full h-screen">
        <div className="col-span-1 h-full w-full  bg-gray-100 p-4 overflow-y-auto transition-transform duration-500">
          <h1 className="text-xl font-bold mb-4">Email Templates</h1>
          <div className="space-y-2">
            {filteredEmailTemplates.map(template => (
              <div
                key={template.id}
                onClick={() => setSelectedTemplate({ ...template, placeholders: {} })}
                className="cursor-pointer p-2 bg-white shadow-md rounded hover:bg-gray-200"
              >
                {titleCase(template.title)}
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-3 h-full w-full p-4 transition-transform duration-500 ">

          {selectedTemplate ? (
            <>
              <EmailTemplateEditor key={selectedTemplate.id} template={selectedTemplate} onSave={saveTemplate} currentUser={currentUser} />
              {alert && <Alert className="mt-4" {...alert} />}
            </>
          ) : (
            <div className="flex flex-col items-center text-center text-gray-500 mt-20">
              <div className="flex items-center p-4 mb-4 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800">
                <InformationIcon />
                <div>
                  Click one of the templates to edit
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmailTemplates;
