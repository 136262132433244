import { Dialog } from '@headlessui/react';
import cx from 'classnames';
import { parseInt } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { clearDealNavigationModal } from 'actions/deal_navigation';
import Button from 'components/shared/NewButton';
import SubdivisionHomeModelsTable from 'components/Subdivision/SubdivisionHomeModelsTable';
import { useGetUpdatedRowData, useRowUpdates } from 'components/shared/Table/DataTableContext';
import { generatePath, useNavigate, useOutletContext } from 'react-router-dom';
import { getUpdatedRowData } from 'components/shared/Table/table.features';
import { dealSourcingIndexPath, pipelinePath } from 'components/routes';
import Alert from 'components/Alert';
import { useUserOptions } from 'components/DealConstruction/dealConstruction';
import { InlineFormField } from 'components/Form';
import { naturalSortComparator } from 'components/utils';
import { deliveryScheduleMetaKey } from 'components/Subdivision/subdivision';
import { useCreateDealMutation } from '../../redux/apiSlice';
import { useFetchSubdivisionQuery } from '../../redux/subdivisionApiSlice';

const STEP_SELECT_HM = Symbol('');
const STEP_DEAL_PARAMS = Symbol('');
const STEP_CONFIRM = Symbol('');
const STEP_SUCCESS = Symbol('');

const NEXT_STEP = Object.freeze({
  [STEP_SELECT_HM]: STEP_DEAL_PARAMS,
  [STEP_DEAL_PARAMS]: STEP_CONFIRM,
  [STEP_CONFIRM]: STEP_SUCCESS,
});
const PREV_STEP = Object.freeze({
  [STEP_DEAL_PARAMS]: STEP_SELECT_HM,
  [STEP_CONFIRM]: STEP_DEAL_PARAMS,
});

const useClose = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(clearDealNavigationModal()), [dispatch]);
};

const useNavigateToDeal = ({ id }) => {
  const navigate = useNavigate();
  return useCallback(() => {
    navigate(generatePath('/deals/:id', { id }));
  }, [id, navigate]);
};

const useSubmitOffer = () => {
  const [createDealMutation, mutationResult] = useCreateDealMutation();

  const submit = useCallback(({ dealId, dealName, dealLeadId, getUpdatedRows }) => {
    // rows are only the visible rows
    const rows = getUpdatedRows({
      rowModel: 'getRowModel',
      rowSelector: ([{ id, offerBidPrice, offerUnderwritingRent }, rowDelta]) => ({
        id,
        // need to submit price/rent whether they were edited or not
        // to make sure the combined deal uses the prices shown to the user
        offerBidPrice,
        offerUnderwritingRent,
        ...rowDelta,
      }),
    }).filter(({ deliverySchedule }) => deliverySchedule && Object.values(deliverySchedule).some((value) => Number.isFinite(value)));

    const homeModelMultiplicities = Object.fromEntries(
      rows.map(({ id, deliverySchedule }) => (
        [id, Object.values(deliverySchedule ?? {}).reduce((tot, curr) => tot + (curr || 0), 0)]
      )),
    );
    const homeModelDeliverySchedule = Object.fromEntries(
      rows.map(({ id, deliverySchedule: { now: _now, ...rest } }) => (
        [id, Object.entries(rest).filter(([, value]) => Number.isFinite(value))]
      )),
    );

    const homeModelPrices = Object.fromEntries(rows.map(({ id, offerBidPrice }) => [id, offerBidPrice]));
    const homeModelRents = Object.fromEntries(rows.map(({ id, offerUnderwritingRent }) => [id, offerUnderwritingRent]));

    createDealMutation({
      name: dealName,
      leadId: dealLeadId,
      newBuildOffer: true,
      referenceDealId: dealId,
      homeModelMultiplicities,
      homeModelDeliverySchedule,
      homeModelPrices,
      homeModelRents,
    });
  }, [createDealMutation]);

  return useMemo(() => [submit, mutationResult], [submit, mutationResult]);
};

const useIsTableFilled = () => {
  // check if any visible home model has quantity
  const tableEditedSelector = useCallback((updates, { table }) => (
    updates.size > 0 && (
      table
        .getRowModel()
        .rows
        .some((row) => {
          if (!updates.has(row.id)) {
            return false;
          }

          const [, { deliverySchedule } = {}] = getUpdatedRowData(table.getRowModel().rowsById[row.id]);
          return Object.values(deliverySchedule ?? {}).some((qty) => Number.isFinite(qty) && qty > 0);
        })
    )
  ), []);
  return useRowUpdates(tableEditedSelector);
};

const useValidateRows = () => {
  // check that qty entered for each row is no greater than that is available
  const tableEditedSelector = useCallback((updates, { table }) => (
    updates.size > 0 && (
      table
        .getRowModel()
        .rows
        .some((row) => {
          if (!updates.has(row.id)) {
            return false;
          }

          return table.getAllFlatColumns().some(({
            id: columnId,
            columnDef: { meta: { [deliveryScheduleMetaKey]: deliveryScheduleDate } = {} },
          }) => {
            if (deliveryScheduleDate === undefined) {
              return false;
            }

            const qtyAvailable = deliveryScheduleDate === 'now' ? (
              row.getValue('numAvailable')
            ) : (
              row.getValue('futureDeliveries').find(([date]) => deliveryScheduleDate === date)?.[1]
            );
            const offerQty = row.getValue(columnId);
            return offerQty && (offerQty > (qtyAvailable ?? 0));
          });
        })
    )
  ), []);
  return useRowUpdates(tableEditedSelector);
};

const useSelectedHomeModelCount = () => {
  // count how many visible home models have non-zero quantity
  const tableEditedSelector = useCallback((updates, { table }) => (
    updates.size > 0 ? (
      table
        .getRowModel()
        .rows
        .filter((row) => {
          if (!updates.has(row.id)) {
            return false;
          }

          const [, { deliverySchedule } = {}] = getUpdatedRowData(table.getRowModel().rowsById[row.id]);
          return Object.values(deliverySchedule ?? {}).some((qty) => Number.isFinite(qty) && qty > 0);
        }, 0)
        .map((row) => row.getValue('plan'))
        .toSorted(naturalSortComparator)
    ) : []
  ), []);
  return useRowUpdates(tableEditedSelector);
};

function InPortal({ container, children }) {
  if (!container) {
    return null;
  }

  return createPortal(children, container);
}

function DealParams({ deal, setDeal, className }) {
  const userOptions = useUserOptions();
  const onNameChange = useCallback((evt) => {
    setDeal((prev) => ({ ...prev, name: evt.target.value }));
  }, [setDeal]);
  const onLeadChange = useCallback((evt) => {
    setDeal((prev) => ({ ...prev, leadId: parseInt(evt.target.value, 10) }));
  }, [setDeal]);

  return (
    <div className={cx('flex flex-col w-160', className)}>
      <InlineFormField className="[&_input]:truncate" name="name" value={deal.name} type="text" onChange={onNameChange} />
      <InlineFormField
        className="mt-6"
        name="leadId"
        label="lead"
        value={deal.leadId}
        type="select"
        options={userOptions}
        onChange={onLeadChange}
      />
    </div>
  );
}

function SetDefaultDealName({ subdivisionName, setDeal }) {
  const homeModelNames = useSelectedHomeModelCount();
  const homeModelNamesJoined = homeModelNames.join(homeModelNames.length === 2 ? ' and ' : ', ');

  useEffect(() => {
    setDeal((prev) => ({ ...prev, name: `${subdivisionName} (${homeModelNamesJoined})` }));
  }, [homeModelNamesJoined, setDeal, subdivisionName]);

  return null;
}

function ContinueBtn({ forward }) {
  const isFilled = useIsTableFilled();
  const isInvalid = useValidateRows();

  return <Button filled label="Continue" onClick={forward} disabled={!isFilled || isInvalid} />;
}

function SubmitBtn({ submit, disabled, dealName, dealLeadId, ...props }) {
  const { data: { deal: { id: dealId } } } = useOutletContext();
  const isFilled = useIsTableFilled();
  const getUpdatedRows = useGetUpdatedRowData();
  const onClick = useCallback(() => (
    submit({ dealId, dealName, dealLeadId, getUpdatedRows })
  ), [dealId, dealName, dealLeadId, submit, getUpdatedRows]);

  return <Button {...props} filled label="Offer" onClick={onClick} disabled={disabled || !isFilled} />;
}

function ModalBodyText({ className, children }) {
  return (
    <div className={cx('text-body-md text-neutral-dark', className)}>
      {children}
    </div>
  );
}

function SuccessStepActions({ navigateToCreatedOffer, onClose }) {
  const navigate = useNavigate();
  const onSourcingClick = useCallback(() => {
    onClose();
    // deal sourcing page requires a browser navigation
    window.location = dealSourcingIndexPath;
  }, [onClose]);

  const onPipelineClick = useCallback(() => {
    onClose();
    navigate(pipelinePath);
  }, [onClose, navigate]);

  const onOfferClick = useCallback(() => {
    onClose();
    navigateToCreatedOffer();
  }, [onClose, navigateToCreatedOffer]);

  return (
    <>
      <Button outlined label="Sourcing" onClick={onSourcingClick} />
      <Button outlined label="Pipeline" onClick={onPipelineClick} />
      <Button filled label="Offer Overview" onClick={onOfferClick} />
    </>
  );
}

export default function NewBuildOfferModal() {
  const { data: { subdivision: { id: subdivisionId, name: subdivisionName }, currentUser } } = useOutletContext();
  const { currentData: subdivision } = useFetchSubdivisionQuery(subdivisionId);
  const [modalButtons, setModalButtons] = useState();

  const [step, setStep] = useState(STEP_SELECT_HM);
  const hasPrevStep = Object.hasOwn(PREV_STEP, step);
  const hasNextStep = Object.hasOwn(NEXT_STEP, step);
  const back = useCallback(() => setStep((prev) => PREV_STEP[prev]), []);
  const forward = useCallback(() => setStep((prev) => NEXT_STEP[prev]), []);

  const [
    submit,
    {
      isLoading: isCreating,
      isSuccess,
      isError,
      data: { id: createdOfferDealId } = {},
    },
  ] = useSubmitOffer();
  useEffect(() => {
    if (isSuccess) {
      forward();
    }
  }, [forward, isSuccess]);

  const navigateToCreatedOffer = useNavigateToDeal({ id: createdOfferDealId });
  const close = useClose();
  const onClose = useCallback(() => {
    if (isCreating) {
      return;
    }

    close();
    if (step === STEP_SUCCESS) {
      navigateToCreatedOffer();
    }
  }, [isCreating, close, step, navigateToCreatedOffer]);

  const [deal, setDeal] = useState(() => ({ name: subdivisionName, leadId: currentUser.id }));

  return (
    <Dialog open onClose={onClose} className="z-50">
      <div className="fixed inset-0 bg-black/25" />

      <div className="fixed inset-0 z-50 p-4 content-center overflow-clip">
        <Dialog.Panel className="relative flex flex-col h-max max-h-full w-max max-w-full rounded-2xl mx-auto py-6 *:px-6 bg-white">
          <div className="sticky inset-x-0 top-0 flex flex-row gap-x-3 pb-6 text-xl bg-inherit cursor-default">
            <Dialog.Title>Make Offer</Dialog.Title>
          </div>

          <div className="flex flex-col h-0 flex-1 overflow-auto">
            {isError && <Alert type="danger">Failed to submit offer</Alert>}
            <SubdivisionHomeModelsTable
              offerTable
              subdivision={subdivision}
              homeModels={subdivision.homeModels}
              // use hidden instead of unmounting to preserve table state
              className={cx('h-0 flex-1', { hidden: step !== STEP_SELECT_HM })}
            >
              <InPortal container={modalButtons}>
                {(step === STEP_SELECT_HM || step === STEP_DEAL_PARAMS) && <ContinueBtn forward={forward} />}
                {step === STEP_CONFIRM && (
                  <SubmitBtn
                    dealName={deal.name}
                    dealLeadId={deal.leadId}
                    submit={submit}
                    isLoading={isCreating}
                    disabled={isSuccess}
                  />
                )}
              </InPortal>
              <SetDefaultDealName subdivisionName={subdivisionName} setDeal={setDeal} />
            </SubdivisionHomeModelsTable>

            {step === STEP_DEAL_PARAMS && <DealParams deal={deal} setDeal={setDeal} className="pb-2" />}

            {step === STEP_CONFIRM && (
              <ModalBodyText className="pb-2 w-[80ch]">
                Nhimble will contact the builder and submit your offer.
              </ModalBodyText>
            )}

            {step === STEP_SUCCESS && (
              <ModalBodyText className="pb-2">
                Offer submitted successfully.
              </ModalBodyText>
            )}
          </div>

          <div className="flex flex-row gap-x-2 justify-end pt-6 border-t mt-auto">
            {hasNextStep && (
              <Button
                textOnly
                disabled={isCreating}
                label={hasPrevStep ? 'Back' : 'Cancel'}
                onClick={hasPrevStep ? back : onClose}
              />
            )}
            {step === STEP_SUCCESS && <SuccessStepActions onClose={onClose} navigateToCreatedOffer={navigateToCreatedOffer} />}
            <div className="contents" ref={setModalButtons} />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
