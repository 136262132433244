import { capitalize } from 'lodash';

function Header({ label }) {
  return (
    <div className="text-2xl text-gray-700 mb-6">{label}</div>
  );
}

const COLORS = ['honeycomb', 'gray', 'success', 'warning', 'error'];
const COLOR_SCALE = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

export default function Design() {
  return (
    <>
      <Header label="Typography" />
      <div className="my-3 text-gray-600">Font Size</div>
      <div className="flex items-center gap-x-6">
        <span className="text-3xl">text-3xl</span>
        <span className="text-2xl">text-2xl</span>
        <span className="text-xl">text-xl</span>
        <span className="text-lg">text-lg</span>
        <span className="text-base">text-base</span>
        <span className="text-sm">text-sm</span>
        <span className="text-xs">text-xs</span>
      </div>
      <div className="my-3 text-gray-600">Font Weight</div>
      <div className="flex items-center gap-x-6 mb-12">
        <span className="font-extrabold">font-extrabold</span>
        <span className="font-bold">font-bold</span>
        <span className="font-semibold">font-semibold</span>
        <span className="font-medium">font-medium</span>
        <span className="font-normal">font-normal</span>
        <span className="font-light">font-light</span>
        <span className="font-extralight">font-extralight</span>
      </div>
      <Header label="Colors" />
      {COLORS.map(color => (
        <div key={color}>
          <div className="my-3 text-gray-600">{capitalize(color)}</div>
          <div className="flex gap-x-6">
            {COLOR_SCALE.map(scaleValue => (
              <div key={scaleValue}>
                <div className={`w-10 h-10 rounded bg-${color}-${scaleValue}`} />
                <div className="mt-2 text-sm text-center text-gray-600">{scaleValue}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

/*
  NOTE: Keep these class name strings in this comment to make sure the classes get compiled.
        This is so we can programatically refer to them below.
  bg-honeycomb-50
  bg-honeycomb-100
  bg-honeycomb-200
  bg-honeycomb-300
  bg-honeycomb-400
  bg-honeycomb-500
  bg-honeycomb-600
  bg-honeycomb-700
  bg-honeycomb-800
  bg-honeycomb-900

  bg-gray-50
  bg-gray-100
  bg-gray-200
  bg-gray-300
  bg-gray-400
  bg-gray-500
  bg-gray-600
  bg-gray-700
  bg-gray-800
  bg-gray-900

  bg-success-50
  bg-success-100
  bg-success-200
  bg-success-300
  bg-success-400
  bg-success-500
  bg-success-600
  bg-success-700
  bg-success-800
  bg-success-900

  bg-warning-50
  bg-warning-100
  bg-warning-200
  bg-warning-300
  bg-warning-400
  bg-warning-500
  bg-warning-600
  bg-warning-700
  bg-warning-800
  bg-warning-900

  bg-error-50
  bg-error-100
  bg-error-200
  bg-error-300
  bg-error-400
  bg-error-500
  bg-error-600
  bg-error-700
  bg-error-800
  bg-error-900
*/