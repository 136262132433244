import { LAYOUT } from 'components/constants';
import RoundButton from '../common/RoundButton';
import { X } from '../icons';
import Modal from '../Modal';

function Header({ onClose, children }) {
  return (
    <div className="flex flex-row w-full px-6 pt-6 pb-2">
      <div className="flex-grow">
        {children}
      </div>
      <div name="outputModalButton" className="h-10 align-top">
        <RoundButton name="outputModalButton" padding="p-2 border border-neutral-dark border-opacity-12" onClick={onClose}>
          <X className="w-7" />
        </RoundButton>
      </div>
    </div>
  );
}

export default function OutputModal({ header, onClose, children, show = false }) {
  return (
    <Modal
      fullScreen
      show={show}
      backgroundColor="bg-transparent"
      onClose={onClose}
      showCloseAction={false}
    >
      <div className="flex w-full h-full p-6 items-center justify-center" onClick={onClose}>
        <div
          className="flex flex-col w-8/12 h-min max-h-full bg-white rounded-t-xl divide-y divide-neutral-dark divide-opacity-12"
          onClick={e => e.stopPropagation()}
        >
          <Header onClose={onClose}>
            {header}
          </Header>
          <div className="pb-2 overflow-auto overscroll-contain" style={{ height: `calc(100vh - ${LAYOUT.outputModalHeaderHeight}px)` }}>
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
}
