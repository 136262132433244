import { isFunction } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNodeState } from 'components/shared/TreeProvider/TreeProvider';

export const useFilterInputState = () => {
  // TODO: each filter control/slot should have independent filterState

  const [{ filterState, initialFilterState }, setNodeState] = useNodeState();
  const [inputState, setInputState] = useState(initialFilterState);

  const setFilterState = useCallback((value) => {
    setInputState(value);
    // TODO: useTransition?
    setNodeState((prev) => {
      const updatedFilterState = isFunction(value) ? value(inputState) : value;
      if (Object.is(prev.filterState, updatedFilterState)) {
        return prev;
      }

      return { ...prev, filterState: updatedFilterState };
    });
  }, [inputState, setNodeState]);

  // sync external filterState changes to inputState
  useEffect(() => {
    setInputState(initialFilterState);
  }, [initialFilterState]);

  return useMemo(() => (
    [inputState, setFilterState]
  ), [inputState, setFilterState]);
};
