import {
  Document,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  PageBreak,
  PageOrientation,
  convertInchesToTwip,
  WidthType,
} from 'docx';
import buildMap from './buildMap';
import buildDealSummary from './deal-summary';
import { buildSourceAndUseTitle, buildSourcesAndUses } from './sources-and-uses';
import {
  cellMargin,
  PAGE_HEIGHT,
  PAGE_WIDTH,
  pageMargin,
  tableProperties,
  DEFAULT_CELL_WIDTH,
  buildHeader,
  NO_MARGIN,
} from './util';
import buildEntryExitSummary from './entry-exit';
import buildReturnSnapshot from './return-snapshot';
import buildGrowthRates from './growth-rates';
import buildRentRoll from './rent-roll';
import buildCashFlow from './cash-flow';
import buildPictures from './buildPictures';

export default class DealSummaryCreator {
  cashFlows = null;

  dcfParams = null;

  deal = null;

  listing = null;

  property = null;

  properties = null;

  scenario = null;

  abortSignal = null;

  pageProperties = {
    properties: {
      page: {
        margin: pageMargin,
        size: {
          orientation: PageOrientation.PORTRAIT,
          width: PAGE_WIDTH,
          height: PAGE_HEIGHT,
        },
      },
    },
    creator: 'Honeycomb',
    title: 'Deal Summary',
    subject: '',
    description: 'Deal Summary',
    lastModifiedBy: 'Honeycomb',
  };

  constructor(cashFlows, dcfParams, deal, listing, property, properties, scenario, abortSignal = null) {
    this.cashFlows = cashFlows;
    this.dcfParams = dcfParams;
    this.deal = deal;
    this.listing = listing;
    this.property = property;
    this.properties = properties;
    this.scenario = scenario;
    this.abortSignal = abortSignal;
  }

  async create() {
    const pageHeader = buildHeader(this.deal);
    const dealSummaryTable = buildDealSummary(this.dcfParams, this.deal, this.property, this.listing);
    const entryExitSummaryTable = buildEntryExitSummary(this.cashFlows, this.dcfParams);
    const returnSnapshotTable = buildReturnSnapshot(this.cashFlows, this.dcfParams);
    const sourceAndUseTitle = buildSourceAndUseTitle();
    const { sourcesTable, usesTable } = buildSourcesAndUses(this.cashFlows);
    const growthRatesTable = buildGrowthRates(this.dcfParams, this.cashFlows);
    const rentRollTable = buildRentRoll(this.dcfParams);
    const cashFlowTable = buildCashFlow(this.cashFlows, this.dcfParams);
    const photosTable = await buildPictures(this.scenario, this.abortSignal);
    const map = await buildMap(this.properties, this.abortSignal);

    return new Document({
      sections: [
        {
          headers: { default: pageHeader },
          children: [
            new Table({
              ...tableProperties,
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      ...DEFAULT_CELL_WIDTH,
                      margins: cellMargin,
                      children: [dealSummaryTable],
                    }),
                    map && new TableCell({
                      ...DEFAULT_CELL_WIDTH,
                      margins: cellMargin,
                      children: map ? [new Paragraph({ children: [map] })] : [],
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({ ...DEFAULT_CELL_WIDTH, margins: cellMargin, children: [entryExitSummaryTable] }),
                    new TableCell({ ...DEFAULT_CELL_WIDTH, margins: cellMargin, children: [returnSnapshotTable] }),
                  ],
                }),
                new TableRow({ children: [sourceAndUseTitle] }),
                new TableRow({
                  children: [
                    new TableCell({
                      margins: { ...NO_MARGIN, type: WidthType.DXA, right: convertInchesToTwip(0.04) },
                      children: [sourcesTable],
                    }),
                    new TableCell({
                      margins: { ...NO_MARGIN, type: WidthType.DXA, left: convertInchesToTwip(0.04) },
                      children: [usesTable],
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      columnSpan: 2,
                      margins: { ...cellMargin, top: convertInchesToTwip(0.1) },
                      ...DEFAULT_CELL_WIDTH,
                      children: [growthRatesTable],
                    }),
                  ],
                }),
              ],
            }),
            new Paragraph({ children: [new PageBreak()] }),
            new Table({
              ...tableProperties,
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      columnSpan: 2,
                      margins: cellMargin,
                      children: [rentRollTable],
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      columnSpan: 2,
                      margins: cellMargin,
                      children: [cashFlowTable],
                    }),
                  ],
                }),
              ],
            }),
            new Paragraph({ children: [new PageBreak()] }),
            photosTable,
          ],
          ...this.pageProperties,
        },
      ],
    });
  }
}
