import { range, sum, zipWith, take, mean } from 'lodash';
import { addMonths, addYears, startOfMonth } from 'date-fns';
import { calcOpExRatios, calculatedCapRate, monthlyCashFlowDates } from './dcf';
import {
  calcAdditions,
  calcCashForSplits,
  calcLpSplits,
  calcReturns,
} from './waterfall';
import { equityMultiple, irr } from '../finance';
import { arrayMax, dateFromString, formatCurrency, formatDate, formatPercentage, sumArrays } from '../utils';

function TopReturns({ items }) {
  return (
    <div className="border rounded-lg w-full mx-4 p-4 mt-2">
      <div className="grid grid-cols-4 divide-x flex-row">
        {items.map(item => (
          <div key={item} className="w-full grid place-items-center">
            <div className="text-gray-600 text-base">{item[0]}</div>
            <div className="grid grid-cols-2 flex-row my-1">
              <div className="text-xl text-center mx-3">{item[1]}</div>
              <div className="text-xl text-center mx-3">{item[2]}</div>
            </div>
            <div className="grid grid-cols-2 flex-row">
              <div className="text-xs text-gray-600 text-center mx-3">{item[3]}</div>
              <div className="text-xs text-gray-600 text-center mx-3">{item[4]}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function SummaryCard({ label, items, width, columns, textSize }) {
  return (
    <div className={`${width} mx-4 overflow-y-auto relative rounded-md align-top h-fit`}>
      <div className="h-12 px-3 pt-3 text-base text-white bg-truliv text-center align-middle">
        {label}
      </div>
      <table className="w-full h-fit table-auto">
        <tbody>
          {items.map((item, index) => (
            <tr key={item} className="h-12 border">
              <td className="px-3 text-left text-gray-600 text-xs uppercase">{item[0]}</td>
              {[...Array(columns)].map((e, i) => (
                <td key={i} className={`text-right px-3 py-1 ${textSize && index === 0 ? textSize : 'text-sm'}`}>{item[i + 1]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function calcIrrs(cashLp, cashGp, dates) {
  try {
    return [
      irr(cashLp, dates),
      irr(cashGp, dates),
    ];
  } catch (err) {
    console.error(err);
    return ['n/a', 'n/a'];
  }
}

function calculatePartnershipReturns(leveredPurchase, leveredCashFlows, ownershipShareLp, hurdlePromotes, hurdleIrrs, cashFlowDates) {
  const equityContribution = leveredCashFlows.slice(1).map(cf => (cf < 0 ? cf : 0));
  equityContribution.unshift(leveredPurchase);
  const equityReturn = leveredCashFlows.slice(1).map(cf => (cf > 0 ? cf : 0));
  equityReturn.unshift(null);

  const lpShares = calcLpSplits(ownershipShareLp, hurdlePromotes);
  const initialLpSplit = lpShares[0];
  const hurdleIrr = [0, ...hurdleIrrs];

  const additions = calcAdditions(leveredCashFlows, initialLpSplit);
  const gpAdditions = additions.map(a => (a / initialLpSplit) * (1 - initialLpSplit));
  const cashSplitsInitial = arrayMax(leveredCashFlows, 0);

  let cumulativeReturnOfCapitalAndDistributions = new Array(leveredCashFlows.length).fill(0);
  let cashLp = [];
  let cashGp = [];
  const previousCashFlows = [];
  let cashSplits = cashSplitsInitial;
  lpShares.forEach((lpShare, index) => {
    const [, , hurdleDistribution] = calcReturns(
      cashSplits,
      additions,
      cumulativeReturnOfCapitalAndDistributions,
      lpShare,
      hurdleIrr[index],
      cashFlowDates,
    );

    if (index === 0) {
      cashLp = sumArrays(additions, hurdleDistribution).map(c => c * -1);
      cashGp = sumArrays(gpAdditions, hurdleDistribution.map(hd => (hd / lpShare) * (1 - lpShare))).map(c => c * -1);
    } else {
      cashLp = sumArrays(previousCashFlows[index - 1][0], hurdleDistribution.map(hd => hd * -1));
      cashGp = sumArrays(previousCashFlows[index - 1][1], hurdleDistribution.map(hd => -1 * (hd / lpShares[index - 1]) * (1 - lpShares[index - 1])));
    }
    previousCashFlows.push([cashLp, cashGp]);
    cashSplits = calcCashForSplits(cashSplitsInitial, cashLp, cashGp);

    cumulativeReturnOfCapitalAndDistributions = zipWith(cumulativeReturnOfCapitalAndDistributions, hurdleDistribution, (...args) => sum(args));
  });

  const thereafterLpShare = lpShares[lpShares.length - 1];
  const thereafterGpShare = 1 - thereafterLpShare;

  const thereafterCashLp = cashSplits.map(value => value * thereafterLpShare);
  const thereafterCashGp = cashSplits.map(value => value * thereafterGpShare);

  const totalCashLp = zipWith(cashLp, thereafterCashLp, (...args) => sum(args));
  const totalCashGp = zipWith(cashGp, thereafterCashGp, (...args) => sum(args));
  const totalIrrs = calcIrrs(totalCashLp, totalCashGp, cashFlowDates);
  return [
    totalIrrs[0],
    totalIrrs[1],
    totalCashLp,
    totalCashGp,
  ];
}

export default function Summary({ cashFlows, dcfParams, returnMetrics }) {
  const {
    amoritization,
    closingDate,
    couponRate,
    holdPeriod,
    hurdleIrrs,
    hurdlePromotes,
    interestOnly,
    ownershipShareLp,
    purchasePrice,
    term,
    units,
    ltOneYrHold,
  } = dcfParams;

  const {
    unleveredEquityMultiple,
    annualizedUnleveredCashOnCash,
    unleveredAverageCashOnCash,
    unleveredIrr,
    leveredEquityMultiple,
    annualizedLeveredCashOnCash,
    leveredAverageCashOnCash,
    leveredIrr,
    stabilizationMonth,
    stabilizedYield,
    grossStabilizedYield,
    currentStabilizedYield,
    totalProfits,
  } = returnMetrics;

  const updatedExitCapRate = calculatedCapRate(cashFlows, dcfParams);
  const saleDate = ltOneYrHold ? (formatDate(startOfMonth(addMonths(dateFromString(closingDate), holdPeriod)))) : (formatDate(startOfMonth(addYears(addMonths(dateFromString(closingDate), 1), holdPeriod))));
  const grossSalePrice = cashFlows.sale.price[cashFlows.sale.price.length - 1];
  const acquisitionProceeds = cashFlows.financing.acquisitionLoanPaymentRepayments[0] + sum(cashFlows.financing.futureFundingMonthlyDraws);
  const allInterestPayments = cashFlows.financing.interestPayments;
  const allPrincipalPayments = cashFlows.financing.principalPayments;
  const totalPayments = allInterestPayments.map((num, index) => (num + allPrincipalPayments[index]) * -1);
  const maxMonthlyPayment = Math.max(...totalPayments);
  const leveredPurchase = cashFlows.acquisition.price[0] + cashFlows.acquisition.cost[0];
  const { leveredCashFlows } = cashFlows;
  const addRsf = units?.reduce((acc, v) => {
    acc[v.rsf] = (acc[v.rsf] || 0) + v.rsf;
    return acc;
  }, {});

  const leveredCashFlowsStabilized = take(cashFlows.leveredOperatingCashFlows, stabilizationMonth - 1);
  const totalDecifitCashFlows = sum(leveredCashFlowsStabilized.filter(number => number < 0));
  const totalRsf = Object.values(addRsf);
  const totalLtcAcquisitionCost = dcfParams.ltcIncludeAcquisitionCost ? -1 * sum(cashFlows.acquisition.cost.filter(cf => cf < 0)) : 0;
  const totalLeveredAcquisitionCost = -1 * sum(leveredCashFlows.filter(cf => cf < 0));
  const cashFlowDates = monthlyCashFlowDates(dcfParams);
  const followOnCapitalProjects = sum(cashFlows.capital.followOnCapitalExpenses) * -1;

  /// Untrended Calcs ///
  const stablizedUnleveredBasisAmount = cashFlows.unleveredBasis.endingBasis[stabilizationMonth];
  const totalMarketRent = sum(units.map((unit) => unit.marketRent * 12));
  const stabEconomicOccArray = cashFlows.occupancy.economicOccupancyRates.slice(stabilizationMonth, stabilizationMonth + 12);
  const stabEconomicOccRate = mean(stabEconomicOccArray);
  const grossRentArray = cashFlows.revenue.grossRents;
  const otherIncomeArray = cashFlows.revenue.otherIncomes;
  const grossRentPercentage = otherIncomeArray.map((num, idx) => num / grossRentArray[idx]);
  const oiGrossRentPercentArray = grossRentPercentage.slice(stabilizationMonth, stabilizationMonth + 12);
  const oiGrossRentPercent = mean(oiGrossRentPercentArray);
  const untrendedRevenue = (totalMarketRent * stabEconomicOccRate) * (1 + oiGrossRentPercent);
  const opExRatio = calcOpExRatios(cashFlows);
  const stabOpExRatioArray = opExRatio.slice(stabilizationMonth, stabilizationMonth + 12);
  const stabOpExRatio = mean(stabOpExRatioArray);
  const untrendedNoi = untrendedRevenue * (1 - stabOpExRatio);
  const untrendedYoc = untrendedNoi / stablizedUnleveredBasisAmount;

  const [
    lpIrr,
    gpIrr,
    lpCash,
    gpCash,
  ] = calculatePartnershipReturns(
    leveredPurchase,
    leveredCashFlows,
    ownershipShareLp,
    hurdlePromotes,
    hurdleIrrs,
    cashFlowDates,
  );

  const topReturns = [
    ['IRR', formatPercentage(unleveredIrr), formatPercentage(leveredIrr), 'Unlevered', 'Levered'],
    ['Equity Multiple', Math.round(unleveredEquityMultiple * 100) / 100 > 0 ? `${unleveredEquityMultiple.toFixed(2)}x` : '-', Math.round(leveredEquityMultiple * 100) / 100 > 0 ? `${leveredEquityMultiple.toFixed(2)}x` : '-', 'Unlevered', 'Levered'],
    ['Avg Cash Yield', formatPercentage(unleveredAverageCashOnCash, 2), formatPercentage(leveredAverageCashOnCash, 2), 'Unlevered', 'Levered'],
    ['Stabilized Yield', formatPercentage(stabilizedYield, 2), formatPercentage(grossStabilizedYield, 2), 'Net', 'Gross'],
  ];
  const annualCashYield = [
    [''].concat(range(1, holdPeriod + 1)),
    ['Unlevered'].concat(range(0, holdPeriod).map((index) => formatPercentage(annualizedUnleveredCashOnCash[index], 2))),
    ['Levered'].concat(range(0, holdPeriod).map((index) => formatPercentage(annualizedLeveredCashOnCash[index], 2))),
  ];
  const entrySummary = [
    [ltOneYrHold ? 'Hold Period (months)' : 'Hold Period', holdPeriod],
    ['Closing Date', formatDate(closingDate)],
    ['Purchase Price', formatCurrency(purchasePrice)],
    ['$ / Unit', formatCurrency(purchasePrice / units.length)],
  ];
  const exitSummary = [
    ['Exit Cap Rate', formatPercentage(updatedExitCapRate, 2)],
    ['Sale Date', formatDate(saleDate)],
    ['Gross Sale', formatCurrency(grossSalePrice)],
    ['$ / Unit', formatCurrency(grossSalePrice / units.length)],
  ];
  const financing = [
    ['Proceeds', formatCurrency(acquisitionProceeds)],
    ['Rate', formatPercentage(couponRate, 2)],
    ['Term', term],
    ['Amoritization', amoritization],
    ['Interest Only', interestOnly],
    ['Debt Constant', formatPercentage(((maxMonthlyPayment) * 12) / acquisitionProceeds, 2)],
    ['LTV', formatPercentage(acquisitionProceeds / purchasePrice)],
    ['LTC', formatPercentage(acquisitionProceeds / (followOnCapitalProjects + purchasePrice + totalLtcAcquisitionCost))],
  ];
  const capEx = [
    ['Total Capital Projects', formatCurrency(followOnCapitalProjects)],
    ['CapEx % of Purchase Price', formatPercentage(followOnCapitalProjects / purchasePrice, 2)],
    ['Total CapEx $PSF', formatCurrency(followOnCapitalProjects / totalRsf, 2)],
    ['Total CapEx $/Unit', formatCurrency(followOnCapitalProjects / units.length)],
  ];
  const additionalReturns = [
    ['Total Profits', formatCurrency(totalProfits)],
    ['Pre-Stabilization Operating CF Shortfall', formatCurrency(totalDecifitCashFlows)],
    ['Untrended Yield-on-Cost', formatPercentage(untrendedYoc, 2)],
    ['Stabilized Gross Yield', formatPercentage(grossStabilizedYield, 2)],
    ['Untrended Gross Yield', formatPercentage(currentStabilizedYield, 2)],
    ['Stabilization Month', stabilizationMonth],
  ];
  const ownershipShare = [
    ['', '$', '%'],
    ['Limited Partner', formatCurrency(ownershipShareLp * totalLeveredAcquisitionCost), formatPercentage(ownershipShareLp)],
    ['General Partner', formatCurrency((1 - ownershipShareLp) * totalLeveredAcquisitionCost), formatPercentage(1 - ownershipShareLp)],
  ];
  const partnerhipReturns = [
    ['', 'LP', 'GP'],
    ['IRR', formatPercentage(lpIrr), formatPercentage(gpIrr)],
    ['Equity Multiple', equityMultiple(lpCash).toFixed(2), equityMultiple(gpCash).toFixed(2)],
  ];

  return (
    <div className="w-full bg-white pb-12 px-3">
      <div className="mt-4 flex">
        <TopReturns items={topReturns} />
      </div>
      <div className="mt-8 flex">
        <SummaryCard label="Annual Cash Yield" items={annualCashYield} width="w-full" columns={ltOneYrHold ? 1 : holdPeriod} textSize="text-xs" />
      </div>
      <div className="mt-8 flex">
        <SummaryCard label="Entry Summary" items={entrySummary} width="w-1/2" columns={1} />
        <SummaryCard label="Exit Summary" items={exitSummary} width="w-1/2" columns={1} />
      </div>
      <div className="mt-8 flex">
        <SummaryCard label="Financing" items={financing} width="w-1/3" columns={1} />
        <SummaryCard label="Capex" items={capEx} width="w-1/3" columns={1} />
        <SummaryCard label="Additional Return Metrics" items={additionalReturns} width="w-1/3" columns={1} />
      </div>
      <div className="mx-4 mt-8 flex border-b">
        <div className="text-xl mb-2">
          Partnership Returns
        </div>
      </div>
      <div className="mt-8 flex">
        <SummaryCard label="Ownership Share" items={ownershipShare} width="w-1/2" columns={2} />
        <SummaryCard label="Returns" items={partnerhipReturns} width="w-1/2" columns={2} />
      </div>
    </div>
  );
}
