import { useState } from 'react';
import Alert from 'components/Alert';
import Button from 'components/shared/NewButton';
import { FormField } from 'components/Form';
import { sessionPath } from 'components/routes';
import { parseEventValue } from 'components/utils';
import LogoPath from 'assets/full_logo_white.png'

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [alert, setAlert] = useState(null);

  // clear any cache before login
  localStorage.clear();

  const login = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setAlert(null);
    if (isSaving || (!email && !password)) {
      return;
    }
    setIsSaving(true);

    const csrfToken = document.querySelector('[name=csrf-token]').content;
    fetch(sessionPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
      body: JSON.stringify({ user: { email, password } }),
    }).then(response => {
      if (response.status === 200 && response.redirected) {
        window.location.href = response.url;
      } else if (response.status === 401) {
        response.json().then(json => {
          setIsSaving(false);
          setAlert({ type: 'danger', text: json.message });
        });
      } else {
        throw new Error('Error');
      }
    }).catch(error => {
      console.error(error);
      setIsSaving(false);
      setAlert({ type: 'danger', text: `Login Failed: ${error.message}` });
    });
  };

  return (
    <form className="w-96 bg-white rounded shadow" onSubmit={login}>
      <div className="w-full bg-black py-3">
        <img
          src={LogoPath}
          alt="logo"
          className="flex items-center h-12"
        />
      </div>
      <div className="p-8">
        <FormField
          name="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(parseEventValue(e))}
        />
        <FormField
          className="mt-6 pb-6"
          name="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(parseEventValue(e))}
        />

        {alert && <Alert className="mt-3" {...alert} />}
        <div className="mt-6">
          <Button width="w-full" label="Log in" type="submit" filled isLoading={isSaving} />
        </div>

        <div className="mt-6 text-sm">
          <a href="/users/password/new" className="text-primary-dark hover:text-primary">Forgot password?</a>
        </div>
      </div>
    </form>
  );
}
