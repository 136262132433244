import { LISTING_SOURCE_MANUAL } from 'components/constants';

const ACTIVE_COLOR = 'bg-success-100 text-success';
const INACTIVE_COLOR = 'bg-gray-100  text-gray-700';

const LISTING_BADGE_COLORS = {
  'Active': ACTIVE_COLOR,
  'Closed': INACTIVE_COLOR,
  'Active Under Contract': INACTIVE_COLOR,
  'Coming Soon': INACTIVE_COLOR,
  'Expired': INACTIVE_COLOR,
  'Pending': INACTIVE_COLOR,
  'null': INACTIVE_COLOR,
};

export default function ListingStatusBadge({ listing }) {
  const isOffMarket = listing.source === LISTING_SOURCE_MANUAL;
  return <div className={`flex items-center text-xs py-1 px-2 rounded-lg font-medium ${isOffMarket ? INACTIVE_COLOR : LISTING_BADGE_COLORS[listing.status]}`}>{isOffMarket ? 'Off Market' : `MLS: ${listing.status}`}</div>;
}
