import { buildHeaderGroups, getMemoOptions } from '@tanstack/react-table';
import { throwingMemo } from '../table.helpers';
import createTableExtension from './createTableExtension';

/**
 * Similar to getLeafHeaders but unordered and includes columns not visible
 *
 * @type {(table: import('@tanstack/react-table').Table) => import('@tanstack/react-table').Header[]}
 */
const getUnorderedLeafHeaders = createTableExtension((table) => (
  throwingMemo(
    () => [table.getAllColumns()],
    (allColumns) => (
      buildHeaderGroups(allColumns, allColumns, table)
        .map((headerGroup) => headerGroup.headers)
        .flat()
        .filter((header) => !header.subHeaders?.length)
    ),
    getMemoOptions(table.options, 'debugHeaders', 'getUnorderedLeafHeaders'),
  )
));

export default getUnorderedLeafHeaders;
