import { useDispatch } from 'react-redux';
import { useUpdateDealMutation } from 'redux/apiSlice';
import { clearDealNavigationModal } from 'actions/deal_navigation';
import Button from 'components/Button';
import DashboardModal from './DashboardModal';

export default function ReopenDealModal({ context }) {
  const { data } = context;
  const { deal } = data;
  const dispatch = useDispatch();
  const [updateDealMutation, { isLoading }] = useUpdateDealMutation();

  return (
    <DashboardModal
      title="Reopen Deal?"
      action={(
        <Button
          danger
          text="Reopen"
          className="font-semibold border border-black border-opacity-12"
          shadow={false}
          isLoading={isLoading}
        />
      )}
      onSubmit={async (event) => {
        event.preventDefault();
        event.stopPropagation();
        // TODO: handle if errors
        await updateDealMutation({ id: deal.id, deletedAt: null });
        dispatch(clearDealNavigationModal());
      }}
      onClose={() => dispatch(clearDealNavigationModal())}
    >
      Are you sure you would like to reopen this deal?
    </DashboardModal>
  );
}
