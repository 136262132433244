import { Outlet } from 'react-router-dom';
import { HorizontalTabs, NavTab } from 'components/shared/Tabs';

export default function NewBuildInventoryLayout() {
  return (
    <div className="flex flex-col gap-y-1 h-full bg-white">
      <HorizontalTabs>
        <NavTab to="home_models">Home Models</NavTab>
        <NavTab to="subdivisions">Subdivisions</NavTab>
        <NavTab to="home_builders">Builders</NavTab>
      </HorizontalTabs>
      <div className="flex flex-col h-0 flex-1 overflow-clip">
        <Outlet />
      </div>
    </div>
  );
}
