import { createNextState } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/query';
import { useIsInDealContext } from 'components/DealBase/hooks';
import { isNil, maxBy, sumBy } from 'lodash';
import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { calcCashFlows, calcReturnMetrics, calcReturnMetricsOfIndividualScenarios } from 'components/dcf/dcf';
import { weightedMeanBy } from 'components/utils';
import { useFetchSelfQuery } from '../../redux/apiSlice';
import { useFetchHomeModelsPipelineStatusQuery, useFetchHomeModelValuationsQuery } from '../../redux/homeModelApiSlice';
import { useFetchSubdivisionScenariosQuery } from '../../redux/subdivisionApiSlice';

export const unreviewedStageSymbol = Symbol('');
export const deliveryScheduleMetaKey = Symbol('');

const finiteOrUndefined = (value) => (Number.isFinite(value) ? value : undefined);

const processScenarios = ({ scenarios: allScenarios }) => {
  const scenariosWithOffer = allScenarios.filter(({ deal: { offerCreatedAt } = {} }) => !isNil(offerCreatedAt));
  const offerCreated = !!scenariosWithOffer.length;
  // if an offer has been made on this home model, only consider the scenarios with an offer
  const scenarios = offerCreated ? scenariosWithOffer : allScenarios;

  let returnMetrics = {};
  if (scenarios.length === 1) {
    returnMetrics = scenarios[0].returnMetrics ?? calcReturnMetrics(calcCashFlows(scenarios[0].parameters), scenarios[0].parameters, { skipIrr: true });
  } else if (scenarios.length > 1) {
    returnMetrics = calcReturnMetricsOfIndividualScenarios(scenarios, null, { skipIrr: true });
  }

  const meanPurchasePrice = weightedMeanBy(scenariosWithOffer, 'parameters.purchasePrice', 'parameters.multiplicity');
  const meanMarketRent = weightedMeanBy(scenarios, ({ parameters: { units } }) => sumBy(units, 'marketRent'), 'parameters.multiplicity');
  const qtyOfferedTotal = offerCreated ? sumBy(scenariosWithOffer, 'parameters.multiplicity') : undefined;

  return {
    returnMetrics,
    meanPurchasePrice: finiteOrUndefined(meanPurchasePrice),
    meanMarketRent: finiteOrUndefined(meanMarketRent),
    offerCreated,
    qtyOfferedTotal,
  };
};

const usePortfolioId = () => {
  const isInDealContext = useIsInDealContext();
  const {
    data: {
      portfolio: { id: dealContextPortfolioId } = {},
    } = {},
  } = useOutletContext() ?? {};
  const {
    currentData: {
      currentPortfolio: { id: currentPortfolioId } = {},
    } = {},
  } = useFetchSelfQuery(undefined, { skip: isInDealContext });

  return isInDealContext ? dealContextPortfolioId : currentPortfolioId;
};

const useDealModel = () => {
  const isInDealContext = useIsInDealContext();
  const {
    modelData: { model: { dcfParams } = {} } = {},
    homeModel: { id: homeModelId } = {},
  } = useOutletContext() ?? {};

  return isInDealContext ? { homeModelId, dcfParams } : null;
};

export const useEnrichedHomeModels = ({ homeModels, subdivisionId }) => {
  const portfolioId = usePortfolioId();

  const {
    currentData: valuations,
    isFetching: isFetchingValuations,
    isUninitialized: isValuationsUninitialized,
  } = useFetchHomeModelValuationsQuery(portfolioId ?? skipToken);

  const {
    currentData: pipelineStatus,
    isFetching: isFetchingPipelineStatus,
    isUninitialized: isPipelineStatusUninitialized,
  } = useFetchHomeModelsPipelineStatusQuery({
    portfolio: { id: portfolioId },
    subdivision: { id: subdivisionId },
  }, { skip: isNil(portfolioId) || isNil(subdivisionId) });

  const {
    currentData: subdivisionScenarios,
    isFetching: isFetchingSubdivisionScenarios,
    isUninitialized: isSubdivisionScenariosUninitialized,
  } = useFetchSubdivisionScenariosQuery({
    portfolio: { id: portfolioId },
    subdivision: { id: subdivisionId },
  }, { skip: isNil(portfolioId) || isNil(subdivisionId) });

  const dealModel = useDealModel();
  const dealHomeModelId = dealModel?.homeModelId;
  const dealDcfParams = dealModel?.dcfParams;
  const dealPurchasePrice = dealDcfParams?.purchasePrice;
  const dealMarketRent = sumBy(dealDcfParams?.units ?? [], 'marketRent');

  const isValuationsReady = !isFetchingValuations && !isValuationsUninitialized;
  const isPipelineStatusReady = !isFetchingPipelineStatus && !isPipelineStatusUninitialized;
  const subdivisionScenariosReady = !isFetchingSubdivisionScenarios && !isSubdivisionScenariosUninitialized;
  const allDataReady = isValuationsReady && isPipelineStatusReady && subdivisionScenariosReady;

  const enrichedHomeModels = useMemo(() => {
    if (!allDataReady) {
      return homeModels;
    }

    const valuationsMap = Object.fromEntries(valuations.map((hmValuations) => [hmValuations.homeModelId, hmValuations]));

    return homeModels.map((hm) => {
      const scenarios = subdivisionScenarios[hm.id] ?? [];
      const propsFromScenarios = processScenarios({ scenarios });

      const latestScenario = maxBy(scenarios, 'updatedAt');
      const offerBidPrice = dealHomeModelId === hm.id ? (
        dealPurchasePrice
      ) : (
        Math.trunc(latestScenario.parameters.purchasePrice)
      );

      const offerUnderwritingRent = dealHomeModelId === hm.id ? (
        dealMarketRent
      ) : (
        Math.trunc(sumBy(latestScenario.parameters.units, 'marketRent'))
      );

      const offerDcfParams = dealHomeModelId === hm.id ? dealDcfParams : latestScenario.parameters;

      return {
        ...hm,
        ...propsFromScenarios,
        stage: pipelineStatus[hm.id]?.stage ?? unreviewedStageSymbol,
        stabilizedYield: valuationsMap[hm.id]?.returnMetrics?.stabilizedYield,
        offerBidPrice,
        offerUnderwritingRent,
        offerDcfParams,
      };
    });
  }, [allDataReady, valuations, homeModels, subdivisionScenarios, dealHomeModelId, dealPurchasePrice, dealMarketRent, dealDcfParams, pipelineStatus]);

  return useMemo(() => [enrichedHomeModels, allDataReady], [allDataReady, enrichedHomeModels]);
};

export const useHomeModelDeliveryDates = ({ homeModels }) => (
  useMemo(() => (
    Array.from(new Set(homeModels.flatMap(({ futureDeliveries }) => futureDeliveries.map(([date]) => date))))
      .toSorted()
  ), [homeModels])
);

/** @type {(date: string) => string} */
export const deliveryScheduleColumnId = (date) => `deliverySchedule[${date}]`;

/**
 * @param {import('@tanstack/react-table').Table} table
 */
export const getVisibleDeliveryScheduleColumnIds = ({ table }) => (
  table
    .getVisibleFlatColumns()
    .filter(({ columnDef: { meta = {} } }) => Object.hasOwn(meta, deliveryScheduleMetaKey))
    .map(({ id }) => id)
);

export const dcfParamsWithInput = ({ dcfParams, purchasePrice, marketRent, multiplicity }) => (
  createNextState(dcfParams, (draft) => {
    Object.assign(draft, { purchasePrice, multiplicity });
    draft.units.forEach((unit, idx) => Object.assign(unit, { marketRent: idx === 0 ? marketRent : 0 }));
  })
);
