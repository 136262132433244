import DataTableConfigPanelBody from 'components/shared/Table/dataTableConfig/DataTableConfigPanelBody';
import { DetailPaneHeading } from './NewBuildInventoryDetail';
import { useCurrentSideNavPanel } from './NewBuildInventorySideNav';

export default function DataTableConfigPane() {
  if (useCurrentSideNavPanel() !== 'config') {
    return null;
  }

  return (
    <>
      <DetailPaneHeading>Config</DetailPaneHeading>
      <DataTableConfigPanelBody className="w-max min-w-full overflow-auto" />
    </>
  );
}
