/* eslint-disable react/no-unescaped-entities */
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useInitialFilters } from 'components/saleComps/saleComps';
import { ArrowBack, Config } from 'components/icons';
import Input from 'components/Input';
import Chip from 'components/shared/Chip';
import Button from 'components/shared/Button';
import { BATH_TYPE, BED_TYPE, BedBathFilter } from 'components/shared/filters/BedBathFilter';
import { LAYOUT, UNIT_RENTS_SOURCES } from 'components/constants';
import { compact, forEach, isEmpty, isEqual, slice, xor } from 'lodash';
import { FormField } from 'components/Form';
import { dateFromString, parseEventValue } from 'components/utils';

function FilterSubSection({ children, label, helperText }) {
  return (
    <>
      {label && <div className="mt-4">{label}</div>}
      {children}
      {helperText && <div className="text-neutral-medium text-xs font-normal">{helperText}</div>}
    </>
  );
}

function AdvancedFiltersModal({ filters, setFilter, setShowAdvancedFilters }) {
  const [filterState, setFilterState] = useState(filters);
  const initialFilters = useInitialFilters();

  const [minSalePrice, maxSalePrice] = filterState.find(({ id }) => id === 'closePrice').value;
  const [minAskingPrice, maxAskingPrice] = filterState.find(({ id }) => id === 'listPrice').value;
  const maxDistance = filterState.find(({ id }) => id === 'distance').value;
  const [minTotalUnits, maxTotalUnits] = filterState.find(({ id }) => id === 'numberOfUnitsTotal').value;
  const [minDate, maxDate] = filterState.find(({ id }) => id === 'referenceDate').value;
  const [minYearBuilt, maxYearBuilt] = filterState.find(({ id }) => id === 'yearBuilt').value;
  const [minRsf, maxRsf] = filterState.find(({ id }) => id === 'buildingArea').value;
  const zipCode = filterState.find(({ id }) => id === 'zipCode').value;
  const sourceNames = filterState.find(({ id }) => id === 'source').value;
  const [bedUseExact, ...bedFilter] = filterState.find(({ id }) => id === 'bedroomsTotal').value;
  const [bathUseExact, ...bathFilter] = filterState.find(({ id }) => id === 'bathroomsTotalInteger').value;

  const setBedBathFilterState = (filterKey, newValueFunc) => {
    const currentState = [...filterState.find(({ id }) => id === filterKey).value];
    const newValue = newValueFunc(currentState);
    setFilterState(filterState.map(state => (state.id === filterKey ? { ...state, value: newValue } : state)));
  };

  const rangeChange = (filterIndex, filter, filterKey) => {
    let currentState = [...filterState.find(({ id }) => id === filterKey).value];
    currentState[filterIndex] = '';
    if (!isEmpty(filter)) {
      switch (filterKey) {
        case 'saleDate':
          currentState[filterIndex] = dateFromString(filter);
          break;
        case 'zipCode':
          currentState = filter;
          break;
        default:
          currentState[filterIndex] = Number(filter);
      }
    }
    setFilterState(filterState.map(state => (state.id === filterKey ? { ...state, value: currentState } : state)));
  };

  const sourceNameChange = (source) => {
    let currentState = [...filterState.find(({ id }) => id === 'source').value];
    const updatedFilter = [...xor(currentState, [source])];
    currentState = updatedFilter;
    setFilterState(filterState.map(state => (state.id === 'source' ? { ...state, value: currentState } : state)));
  };

  const distanceChange = (value) => {
    setFilterState(filterState.map(state => (state.id === 'distance' ? { ...state, value } : state)));
  };

  const onDone = () => {
    forEach(filterState, ({ id, value }) => setFilter(id, value));
    setShowAdvancedFilters(false);
  };

  const resetAll = () => {
    forEach(filterState, ({ id }) => setFilter(id, initialFilters.find(filter => filter.id === id).value));
    setFilterState(initialFilters);
  };

  return ReactDOM.createPortal(
    <div
      style={{ width: `${LAYOUT.rentCompAllFiltersWidth}px` }}
      className="absolute h-full ml-20 top-0 bg-white z-40 border-t border-r border-gray-200 shadow-xl"
    >
      <div className="flex justify-between items-center px-4 py-3 border-b h-16">
        <div className="flex items-center gap-x-4">
          <ArrowBack className="w-6 cursor-pointer" onClick={() => setShowAdvancedFilters(false)} />
          <h1 className="text-neutral-dark text-xl font-normal">Filters</h1>
        </div>
        <div className="flex items-center gap-x-6">
          <Button label="Reset All" className="text-neutral-dark font-medium text-sm" textOnly onClick={resetAll} />
          <Button label="Done" onClick={onDone} />
        </div>
      </div>

      <div
        className="p-6 overflow-y-auto overflow-x-hidden"
        style={{ height: 'calc(100% - 64px)', width: `${LAYOUT.rentCompAllFiltersWidth}px` }}
      >
        <FilterSubSection label="Beds">
          <BedBathFilter
            type={BED_TYPE}
            useExact={bedUseExact}
            filter={bedFilter}
            setFilterState={(newValue) => setBedBathFilterState('bedroomsTotal', newValue)}
          />
        </FilterSubSection>

        <FilterSubSection label="Baths">
          <BedBathFilter
            type={BATH_TYPE}
            useExact={bathUseExact}
            filter={bathFilter}
            setFilterState={(newValue) => setBedBathFilterState('bathroomsTotalInteger', newValue)}
          />
        </FilterSubSection>

        <FilterSubSection label="Distance (mi)">
          <Input
            onChange={(e) => distanceChange(parseEventValue(e))}
            type="range"
            width="w-full"
            value={maxDistance}
            min="0.1"
            max="2"
            step="0.1"
          />
          <div className="flex justify-between">
            <div>0.1 mi</div>
            <div>2 mi</div>
          </div>
        </FilterSubSection>

        <FilterSubSection label="Unit count">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minTotalUnits}
              type="number"
              placeholder="No min"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(0, e.target.value, 'numberOfUnitsTotal')}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxTotalUnits}
              type="number"
              placeholder="No Max"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(1, e.target.value, 'numberOfUnitsTotal')}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="RSF">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minRsf}
              type="number"
              placeholder="No min"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(0, e.target.value, 'buildingArea')}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxRsf}
              type="number"
              placeholder="No Max"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(1, e.target.value, 'buildingArea')}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Asking Price">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minAskingPrice}
              type="number"
              placeholder="No min $"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(0, e.target.value, 'listPrice')}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxAskingPrice}
              type="number"
              placeholder="No Max $"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(1, e.target.value, 'listPrice')}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Close Price">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minSalePrice}
              type="number"
              placeholder="No min $"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(0, e.target.value, 'closePrice')}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxSalePrice}
              type="number"
              placeholder="No Max $"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(1, e.target.value, 'closePrice')}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Zip Code" helperText="Separate multiple zip codes with a comma">
          <div className="flex">
            <FormField
              value={zipCode}
              type="text"
              placeholder="Zip code"
              className="w-160"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 "
              onChange={(e) => rangeChange(1, e.target.value, 'zipCode')}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Source">
          <div className="flex space-x-2">
            {UNIT_RENTS_SOURCES.map((source, index) => (
              <Chip
                key={index}
                leadingIcon={sourceNames.includes(source)}
                label={source}
                onClick={() => sourceNameChange(source)}
                selected={sourceNames.includes(source)}
              />
            ))}
          </div>
        </FilterSubSection>

        <FilterSubSection label="Date">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              type="date"
              placeholder="MM/DD/YYYY"
              value={minDate}
              className="w-160"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(0, e.target.value, 'referenceDate')}
            />
            <div className="mt-1 pl-7 w-2.5">-</div>
            <FormField
              type="date"
              placeholder="MM/DD/YYYY"
              value={maxDate}
              className="w-160"
              padding="ml-10 py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(1, e.target.value, 'referenceDate')}
            />
          </div>
        </FilterSubSection>

        <FilterSubSection label="Year Built">
          <div className="flex justify-between items-center space-x-2">
            <FormField
              value={minYearBuilt}
              type="number"
              placeholder="No min"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(0, e.target.value, 'yearBuilt')}
            />
            <div className="mt-1 w-2.5">-</div>
            <FormField
              value={maxYearBuilt}
              type="number"
              placeholder="No Max"
              padding="py-2 px-3 border border-black border-opacity-12 h-12 w-60"
              onChange={(e) => rangeChange(1, e.target.value, 'yearBuilt')}
            />
          </div>
        </FilterSubSection>
      </div>
    </div>,
    document.body,
  );
}

export default function AllFilters({ filters, setFilter }) {
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  let activeFilters = 0;
  forEach(filters, ({ id, value }) => {
    if (['resolvedStatus', 'visibilityStatus'].includes(id)) {
      const resolvedStatusValue = value.map(([, status]) => status);
      if (!isEmpty(compact(resolvedStatusValue))) activeFilters += 1;
    } else if (id === 'distance') {
      if (!isEqual(value, [null, 1])) activeFilters += 1;
    } else if (['bedroomsTotal', 'bathroomsTotalInteger'].includes(id)) {
      if (!isEmpty(slice(value, 1))) activeFilters += 1;
    } else if (!isEmpty(compact(value))) activeFilters += 1;
  });

  return (
    <>
      <Chip
        label={`All Filters${activeFilters ? ` • ${activeFilters}` : ''}`}
        leadingIcon={<Config />}
        onClick={() => setShowAdvancedFilters(true)}
        preferLeadingIcon
        selected={activeFilters}
      />
      {showAdvancedFilters ? (
        <AdvancedFiltersModal
          filters={filters}
          setFilter={setFilter}
          setShowAdvancedFilters={setShowAdvancedFilters}
        />
      ) : null}
    </>
  );
}
