import FileUploadArea from 'components/FileUploadArea';
import { Download, InformationIcon } from 'components/icons';
import Select from 'components/Select';
import Button from 'components/shared/Button';
import { lowerCase } from 'lodash';
import { useState } from 'react';
import { useUploadCSVMutation } from 'redux/dashboardApiSlice';

const CSV_HEADERS = {
  leases: [
    'address', 'city', 'state', 'apn', 'zip', 'year_built', 'entity', 'market',
    'unit', 'building', 'bed', 'bath', 'sqft', 'lease_sign_date', 'lease_rent',
    'prior_lease_rent', 'lease_start_date', 'prior_lease_start_date', 'lease_end_date',
    'prior_lease_end_date', 'listing_date', 'underwritten_rent', 'list_rent',
    'original_list_rent', 'number_of_applications', 'number_of_open_applications',
    'number_of_showings', 'purchase_date', 'date_last_vacant', 'ready_for_marketing_date',
    'stabilized', 'occupancy_status', 'property_status',
  ],
};

function Upload() {
  const [importType, setImportType] = useState('');
  const [uploadError, setUploadError] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadCSV, { isLoading }] = useUploadCSVMutation();

  const generateCSVContent = (headers) => `${headers.join(',')}\r\n`;

  const getFileHeaders = async (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      const lines = content.split('\n');
      const headers = lines[0].trim().split(',');
      resolve(headers);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsText(file);
  });

  const compareHeaders = (expectedHeaders, fileHeaders) => {
    const normalizedExpectedHeaders = expectedHeaders.map(header => header.toLowerCase());
    const normalizedFileHeaders = fileHeaders.map(header => header.toLowerCase());

    return normalizedExpectedHeaders.every(expectedHeader => normalizedFileHeaders.includes(expectedHeader));
  };
  const handleDownloadTemplate = () => {
    if (!importType) {
      setUploadError('Please select an import type to download the template.');
      return;
    }

    const headers = CSV_HEADERS[lowerCase(importType)];
    if (headers) {
      const csvContent = generateCSVContent(headers);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${importType}_template.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setUploadError('Headers not found for the selected import type.');
    }
  };

  const handleUpload = async () => {
    setFileUploaded(false);
    setUploadError('');
    if (!selectedFile) {
      setUploadError('No file selected to upload.');
      return;
    }

    if (!importType) {
      setUploadError('Select import type');
      return;
    }

    // Check if headers match
    const expectedHeaders = CSV_HEADERS[lowerCase(importType)];
    const fileHeaders = await getFileHeaders(selectedFile);

    if (!compareHeaders(expectedHeaders, fileHeaders)) {
      setUploadError('CSV file headers do not match the expected headers.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('type', lowerCase(importType));

    try {
      await uploadCSV(formData).unwrap();
      setFileUploaded(true);
      setImportType('');
      setUploadError('');
      setSelectedFile(null);
    } catch (error) {
      setUploadError(error.data.error);
      setFileUploaded(false);
    }
  };

  const handleFileChange = async (e) => {
    setFileUploaded(false);
    setUploadError('');
    setSelectedFile(null);
    const file = e[0];

    if (!file) {
      return; // No file selected
    }

    if (e.length !== 1) {
      const errorMsg = 'Only a single file can be uploaded at a time.';
      setSelectedFile(null);
      setUploadError(errorMsg);
      return;
    }

    if (file.type !== 'text/csv') {
      const errorMsg = 'The uploaded file must be a CSV (Comma Separated Values) file.';
      setSelectedFile(null);
      setUploadError(errorMsg);
      return;
    }

    setSelectedFile(file);
  };

  return (
    <div className="mt-8">
      <div className="flex space-x-4 w-full">
        <Select
          value={importType}
          name="upload-type"
          className="w-60"
          options={[['', 'Select Upload Type'], ['Leases', 'Leases']]}
          onChange={(e) => setImportType(e.target.value)}
        />
        {importType && (
          <Button
            onClick={handleDownloadTemplate}
            leadingIcon={<Download />}
            label={`Download ${importType} Template`}
          />
        )}
      </div>
      {importType && (
        <>
          <div className="grid grid-cols-12 gap-2 my-4">
            <div className="col-span-6 flex flex-col gap-y-4">
              <FileUploadArea onFileUpload={handleFileChange} />
              {uploadError && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">Error: </strong>
                <span className="block sm:inline">{uploadError}</span>
              </div>
              )}
              {fileUploaded && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">Success: </strong>
                File uploaded successfully
              </div>
              )}
              {selectedFile && (
                <div className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3 rounded" role="alert">
                  <InformationIcon />
                  <p>
                    {`Selected File: ${selectedFile.name}`}
                  </p>
                </div>
              )}
            </div>
          </div>
          <Button
            disabled={isLoading}
            filled
            isLoading={isLoading}
            label={isLoading ? 'Importing' : 'Import CSV'}
            onClick={handleUpload}
          />
        </>
      )}
    </div>
  );
}

export default Upload;
