import { Fragment, useState } from 'react';
import { cloneDeep, isEmpty, matches } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { clearDealsModal } from 'actions/deals';
import { useUpdateTaskMutation } from 'redux/apiSlice';
import Button from 'components/Button';
import { FormField } from 'components/Form';
import { parseEventValue } from 'components/utils';
import DashboardModal from '../DashboardModal';

const checkDependencies = (taskFields, dependencies) => {
  if (isEmpty(dependencies)) {
    return true;
  }

  return dependencies.some((requirement) => taskFields.some(matches(requirement)));
};

export default function SubmitTaskResponseModal() {
  const dispatch = useDispatch();
  const { task } = useSelector(state => state.deals.modal);
  const [fields, setFields] = useState(task.fields);
  const [updateTaskMutation] = useUpdateTaskMutation();

  const onTaskFieldsChange = (event) => {
    const param = event.target.name;
    const newValue = parseEventValue(event);

    setFields(prevValue => {
      const updatedFields = cloneDeep(prevValue);
      updatedFields.find(({ name }) => name === param).value = newValue;

      return updatedFields;
    });
  };

  // TODO: clear unreachable values

  const disabled = !!task.completedAt;

  return (
    <DashboardModal
      title={task.name}
      action={!disabled && (
        <Button
          confirm
          text="Submit"
          className="font-semibold border border-black border-opacity-12"
          shadow={false}
        />
      )}
      onClose={() => dispatch(clearDealsModal())}
      onSubmit={async (event) => {
        event.stopPropagation();
        event.preventDefault();

        // TODO: handle response
        await updateTaskMutation({
          id: task.id,
          dealId: task.dealId,
          completedAt: new Date(),
          canSpecifyCompletedAt: task.canUpdateCompletedAt,
          fields,
        });
        dispatch(clearDealsModal());
      }}
    >
      <div className="flex flex-col gap-y-8">
        {fields.map((field, _index, array) => (
          checkDependencies(array, field.dependencies) && (
            <Fragment key={field.name}>
              {
                field.inputType !== 'info' && (
                  <FormField
                    required={field.required !== false}
                    disabled={disabled}
                    name={field.name}
                    label={<span>{field.label}</span>}
                    value={field.value ?? ''}
                    type={field.inputType}
                    {...field.inputProps}
                    textAlign="text-left"
                    padding="py-2 px-6"
                    onChange={onTaskFieldsChange}
                  />
                )
              }
              {
                field.hint && (
                  <div className="pt-1 -mt-8 text-sm leading-6 text-black text-opacity-54">
                    {field.hint}
                  </div>
                )
              }
            </Fragment>
          )
        ))}
      </div>
    </DashboardModal>
  );
}
