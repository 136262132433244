import { isArray, isNil, last, pick, range, sum, zipWith } from 'lodash';
import { HOA_FEE_EXPENSE_ITEM_NAME } from 'components/constants';
import { calcAboveTheLineCashFlows, calcExitPrice, EXPENSE_METHOD_RATIO, holdPeriodInMonths } from './dcf';
import { calcMonthlyCashFlows, compoundInterest } from '../finance';
import { cashFlowStartDate, sumArrays } from '../utils';
import { isNewLeaseStart, newLeaseStartMonth } from './rent';
import {
  ITEMIZED_INPUT_METHOD_DPM,
  ITEMIZED_INPUT_METHOD_DPMPU,
  ITEMIZED_INPUT_METHOD_DPSFPM,
  ITEMIZED_INPUT_METHOD_DPSFPY,
  ITEMIZED_INPUT_METHOD_DPY,
  ITEMIZED_INPUT_METHOD_DPYPU,
  ITEMIZED_INPUT_METHOD_EGR,
  ITEMIZED_INPUT_METHOD_GR,
  ITEMIZED_INPUT_METHOD_Y1EGR,
  ITEMIZED_INPUT_METHOD_Y1GR,
  ITEMIZED_INPUT_METHODS,
  ITEMIZED_INPUT_METHOD_EQUITY,
} from './itemizedItem';

export const TAX_GROWTH_TIMING_DEAL = 'deal';
export const TAX_GROWTH_TIMING_CALENDAR = 'calendar';
export const TAX_GROWTH_TIMING_OPTIONS = [
  [TAX_GROWTH_TIMING_DEAL, 'Close Date'],
  [TAX_GROWTH_TIMING_CALENDAR, 'Calendar'],
];

export const EXPENSE_ITEMS = [
  { controllable: true, name: 'Accounting Fees', reimbursablePercent: null },
  { controllable: true, name: 'Legal and Professional Fees', reimbursablePercent: null },
  { controllable: true, name: 'Leasing Fee', reimbursablePercent: null },
  { controllable: true, name: 'Marketing', reimbursablePercent: null },
  { controllable: true, name: 'Administrative', reimbursablePercent: null },
  { controllable: true, name: 'Insurance Admin Fee', reimbursablePercent: null },
  { controllable: true, name: 'Pest / Extermination', reimbursablePercent: null },
  { controllable: true, name: 'Repair & Maintenance', reimbursablePercent: null },
  { controllable: true, name: 'Inspections', reimbursablePercent: null },
  { controllable: true, name: 'Landscaping / Snow Removal', reimbursablePercent: null },
  { controllable: true, name: 'Other Contracted Services', reimbursablePercent: null },
  { controllable: true, name: 'Cable / Internet', reimbursablePercent: 0 },
  { controllable: true, name: 'Trash / Refuse Service', reimbursablePercent: 0 },
  { controllable: true, name: 'Utilities', reimbursablePercent: 0 },
  { controllable: true, name: 'Water / Sewer', reimbursablePercent: 0 },
  { controllable: true, name: 'Water', reimbursablePercent: 0 },
  { controllable: true, name: 'Electric', reimbursablePercent: 0 },
  { controllable: true, name: 'Gas', reimbursablePercent: 0 },
  { controllable: true, name: 'Electric & Gas', reimbursablePercent: 0 },
  { controllable: false, name: 'Management Fee', reimbursablePercent: null },
  { controllable: false, name: 'Asset Management Fee', reimbursablePercent: null },
  { controllable: false, name: 'Supplemental / Other Property Taxes', reimbursablePercent: null },
  { controllable: false, name: 'Property Insurance', reimbursablePercent: null },
  { controllable: false, name: HOA_FEE_EXPENSE_ITEM_NAME, reimbursablePercent: null },
];

export const EXPENSE_INPUT_METHODS = pick(ITEMIZED_INPUT_METHODS, [
  ITEMIZED_INPUT_METHOD_EGR,
  ITEMIZED_INPUT_METHOD_Y1EGR,
  ITEMIZED_INPUT_METHOD_DPY,
  ITEMIZED_INPUT_METHOD_DPYPU,
  ITEMIZED_INPUT_METHOD_DPM,
  ITEMIZED_INPUT_METHOD_DPMPU,
  ITEMIZED_INPUT_METHOD_DPSFPY,
  ITEMIZED_INPUT_METHOD_DPSFPM,
  ITEMIZED_INPUT_METHOD_GR,
  ITEMIZED_INPUT_METHOD_Y1GR,
  ITEMIZED_INPUT_METHOD_EQUITY,
]);

const expenseParams = (dcfParams, egr, grossRent, totalEquity) => ({
  effectiveGrossRevenue: egr,
  grossRent,
  holdPeriodInMonths: holdPeriodInMonths(dcfParams),
  numberOfUnits: dcfParams.units?.length ?? 1,
  rsf: dcfParams.units?.reduce((total, unit) => total + (unit.rsf || 0), 0) ?? null,
  totalEquity,
});

export const calcExpenseItemCashFlow = (expenseItem, dcfParams, egr, grossRent, totalEquity) => (
  EXPENSE_INPUT_METHODS[expenseItem.inputMethod].func(expenseItem, expenseParams(dcfParams, egr, grossRent, totalEquity))
);

export const hasExpenseItemCashFlow = (expenseItem, dcfParams, egr, grossRent) => (
  EXPENSE_INPUT_METHODS[expenseItem.inputMethod].validateParams(expenseItem, expenseParams(dcfParams, egr, grossRent))
);

export const calcBelowTheLineExpenseItemCashFlow = (expenseItem, dcfParams, egr, grossRent, totalEquity) => (
  // egr and gr contain 1 more year of data than what's needed for below the line expenses
  calcExpenseItemCashFlow(expenseItem, dcfParams, egr.slice(0, holdPeriodInMonths(dcfParams)), grossRent.slice(0, holdPeriodInMonths(dcfParams)), totalEquity)
);

export const calcTurnoverCosts = (dcfParams) => {
  const monthlyExpensesByUnit = dcfParams.units.map(unit => {
    const compoundedCost = calcMonthlyCashFlows(unit.releasingCost, dcfParams.inflationRates, holdPeriodInMonths(dcfParams));
    return compoundedCost.map((releasingCost, index) => {
      const month = index + 1;

      if ((month <= newLeaseStartMonth(unit) && (unit.vacant || !!unit.turnBudget)) || !isNewLeaseStart(unit, month)) {
        return 0;
      }

      return (1 - unit.renewalProbability) * releasingCost;
    });
  });
  return sumArrays(...monthlyExpensesByUnit);
};

export const calcTotalControllableExpenses = (dcfParams, egr, grossRent) => {
  const expenseItemCosts = dcfParams.expenseItems
    .filter(expenseItem => expenseItem.controllable)
    .map(item => calcExpenseItemCashFlow(item, dcfParams, egr, grossRent));
  const turnoverExpenses = calcTurnoverCosts(dcfParams);
  return sumArrays(...expenseItemCosts, turnoverExpenses);
};

const calcInitialRealEstateTax = (dcfParams) => {
  const taxableValue = dcfParams.assessedValuePurchasePercent ? (dcfParams.purchasePrice * dcfParams.assessedValuePurchasePercent) : dcfParams.assessedValue;
  return taxableValue * dcfParams.effectiveTaxRate;
};

export const calcHoldPlusOneTaxRate = (dcfParams) => {
  const { effectiveTaxRate, detailedTaxInput, futureTaxParameters } = dcfParams;
  return detailedTaxInput ? last(futureTaxParameters).billedRate : effectiveTaxRate;
};

export const calcTaxes = (dcfParams, cashFlows) => {
  let alignTaxesToCalenderYear, monthlyTaxPerYear;
  if (dcfParams.detailedTaxInput) {
    // always use calendar year timing for detailed tax inputs
    alignTaxesToCalenderYear = true;
    monthlyTaxPerYear = dcfParams.futureTaxParameters.map(taxParameters => ((taxParameters.marketValueTotal * taxParameters.billedRate) + taxParameters.specialAssessment) / 12);
  } else {
    alignTaxesToCalenderYear = dcfParams.taxExpenseGrowthTiming === TAX_GROWTH_TIMING_CALENDAR;
    const initialReTax = calcInitialRealEstateTax(dcfParams);
    const initialMonthlyRealEstateTax = initialReTax / 12;
    monthlyTaxPerYear = compoundInterest(initialMonthlyRealEstateTax, dcfParams.taxExpenseGrowthRates);
    if (dcfParams.assessedValueExitPercent) {
      if (isNil(cashFlows)) {
        // eslint-disable-next-line no-param-reassign
        cashFlows = calcAboveTheLineCashFlows(dcfParams);
      }
      const exitPrice = calcExitPrice(dcfParams, cashFlows);
      const exitYearTaxRate = calcHoldPlusOneTaxRate(dcfParams);
      const holdPlusOneReTax = exitPrice * dcfParams.assessedValueExitPercent * exitYearTaxRate;
      monthlyTaxPerYear[monthlyTaxPerYear.length - 1] = holdPlusOneReTax / 12;
    }
  }

  const months = 12 + holdPeriodInMonths(dcfParams);
  const startingMonth = cashFlowStartDate(dcfParams).getMonth();

  return range(months).map(month => {
    const calendarMonth = month + startingMonth;
    const rateIndex = alignTaxesToCalenderYear ? Math.floor(calendarMonth / 12) : Math.floor(month / 12);
    return monthlyTaxPerYear[rateIndex];
  });
};

export const calcTotalNonControllableExpensesBeforeTaxes = (dcfParams, egr, grossRent) => {
  const expenseItemCosts = dcfParams.expenseItems
    .filter(expenseItem => !expenseItem.controllable)
    .map(item => calcExpenseItemCashFlow(item, dcfParams, egr, grossRent));
  return sumArrays(...expenseItemCosts);
};

export const calcTotalNonControllableExpenses = (dcfParams, egr, grossRent) => (
  sumArrays(calcTotalNonControllableExpensesBeforeTaxes(dcfParams, egr, grossRent), calcTaxes(dcfParams))
);

export const calcReimbursableExpenses = (dcfParams, physicalOccupancyRates) => {
  let reimbursableExpenses;

  if (dcfParams.expenseMethod === EXPENSE_METHOD_RATIO) {
    reimbursableExpenses = null;
  } else {
    reimbursableExpenses = dcfParams.expenseItems
      .filter(item => isArray(item.reimbursablePercent))
      .map(item => {
        if (item.inputMethod === ITEMIZED_INPUT_METHOD_EGR) {
          // invalid state due to causing circular logic
          throw new Error('reimbursable expense cannot use a revenue-based input method');
        }
        return calcExpenseItemCashFlow(item, dcfParams).map((expense, month) => expense * item.reimbursablePercent[Math.trunc(month / 12)]);
      });
  }

  if (reimbursableExpenses === null || reimbursableExpenses.length === 0) {
    return Array(holdPeriodInMonths(dcfParams) + 12).fill(0);
  }

  return zipWith(physicalOccupancyRates, ...reimbursableExpenses, (occupancyRate, ...expenses) => sum(expenses) * occupancyRate);
};

export const calcExpenseRatioBasedExpenses = (dcfParams, egr) => egr.map(value => value * dcfParams.expenseRatio);

export const calcTotalOperatingExpenses = (dcfParams, egr, grossRent) => {
  if (dcfParams.expenseMethod === EXPENSE_METHOD_RATIO) {
    return calcExpenseRatioBasedExpenses(dcfParams, egr);
  }

  return sumArrays(
    calcTotalControllableExpenses(dcfParams, egr, grossRent),
    calcTotalNonControllableExpenses(dcfParams, egr, grossRent),
  );
};
