import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from 'redux/apiSlice';
import reducers from 'reducers';

export default configureStore({
  reducer: {
    ...reducers,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    immutableCheck: { warnAfter: 512 },
    serializableCheck: {
      warnAfter: 512,
      // these are to avoid serialization errors because functions are passed to them
      ignoredActions: ['navigation/saveChangesModal', 'toast/showToast'],
      ignoredPaths: ['toast.activeToast.actions'],
    },
  }).concat(
    apiSlice.middleware,
  ),
});
