import cx from 'classnames';
import { LoadingIndicator } from 'components/icons';

export function KeyValueTable({ loading, className, children, ...props }) {
  return (
    <div
      {...props}
      className={cx(
        'relative group grid grid-cols-[repeat(2,max-content)] justify-between px-4 py-2 overflow-clip border rounded-xl tabular-nums',
        'aria-busy:before:absolute aria-busy:before:inset-0 aria-busy:before:bg-black/8',
        className,
      )}
      role="table"
      aria-busy={!!loading}
      inert={loading ? '' : undefined}
    >
      {loading && (
        <div className="absolute left-1/2 top-1/3 -translate-x-1/2 -translate-y-1/2">
          <LoadingIndicator className="w-8 text-gray-500" />
        </div>
      )}
      {children}
    </div>
  );
}

export function KeyValueTitle({ children, ...props }) {
  return (
    <div {...props} className="min-h-10 mb-3 col-span-full text-body-lg text-neutral-dark">
      {children}
    </div>
  );
}

export function KeyValueSubtitle({ children, ...props }) {
  return (
    <div {...props} className="text-label-md text-neutral-light empty:hidden">
      {children}
    </div>
  );
}

export function KeyValueTableRow({ children, ...props }) {
  return (
    <div {...props} className="grid col-span-full grid-cols-subgrid items-center gap-x-1 py-2 border-b last:border-b-0" role="row">
      {children}
    </div>
  );
}

export function KeyValueTableRowHeader({ children, ...props }) {
  return (
    <div {...props} className="text-label-md font-medium text-gray-500 uppercase" role="rowheader">
      {children}
    </div>
  );
}

export function KeyValueTableData({ children, ...props }) {
  return (
    <div {...props} className="text-right justify-self-end group-aria-busy:invisible" role="cell">
      {children}
    </div>
  );
}
