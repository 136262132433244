import { Fragment } from 'react';
import { clone, last, min, range, round } from 'lodash';
import { formatPercentage, parseEventValue } from '../utils';
import Button from '../Button';
import { calcLpSplits } from '../dcf/waterfall';
import { PERCENT_TYPE } from '../Input';

const HURDLE_MIN_STEP = 0.01;

export default function waterfallParameters(FormFieldComponent, InfoFieldComponent, margin) {
  return ({ parameters, onChange }) => {
    const { hurdleIrrs, hurdlePromotes, ownershipShareLp } = parameters;
    const lpSplits = calcLpSplits(ownershipShareLp, hurdlePromotes);

    const addHurdle = () => {
      const updatedHurdlePromotes = clone(hurdlePromotes);
      updatedHurdlePromotes.push(min([1, round(hurdlePromotes[hurdlePromotes.length - 1] + HURDLE_MIN_STEP, 2)]));
      const updatedHurdleIrrs = clone(hurdleIrrs);
      updatedHurdleIrrs.push(round(hurdleIrrs[hurdleIrrs.length - 1] + HURDLE_MIN_STEP, 2));
      onChange({
        target: {
          name: 'hurdlePromotes',
          value: updatedHurdlePromotes,
        },
      });
      onChange({
        target: {
          name: 'hurdleIrrs',
          value: updatedHurdleIrrs,
        },
      });
    };

    const removeHurdle = () => {
      const updatedHurdlePromotes = clone(hurdlePromotes);
      updatedHurdlePromotes.pop();
      const updatedHurdleIrrs = clone(hurdleIrrs);
      updatedHurdleIrrs.pop();
      onChange({
        target: {
          name: 'hurdlePromotes',
          value: updatedHurdlePromotes,
        },
      });
      onChange({
        target: {
          name: 'hurdleIrrs',
          value: updatedHurdleIrrs,
        },
      });
    };

    const hurdleParamOnChange = (hurdleIndex) => (event) => {
      const name = `${event.target.name}s`;
      const updatedParam = Array.from(parameters[name]);
      updatedParam[hurdleIndex] = parseEventValue(event);

      onChange({
        target: {
          name,
          value: updatedParam,
        },
      });
    };

    return (
      <>
        <FormFieldComponent
          name="ownershipShareLp"
          value={ownershipShareLp}
          label="LP Equity"
          type={PERCENT_TYPE}
          min="0"
          max="100"
          onChange={onChange}
        />
        <FormFieldComponent
          className={margin}
          name="hurdleIrr"
          label="Preferred Return IRR"
          value={hurdleIrrs[0]}
          type={PERCENT_TYPE}
          min="0"
          max={(hurdleIrrs[1] ?? 1) * 100}
          onChange={hurdleParamOnChange(0)}
        />
        <InfoFieldComponent
          className={margin}
          value={`${formatPercentage(lpSplits[0])} LP / ${formatPercentage(1 - lpSplits[0])} GP`}
          valueClassName="text-sm text-gray-500"
        />
        {range(1, hurdlePromotes.length).map((index) => (
          <Fragment key={index}>
            <FormFieldComponent
              className={margin}
              name="hurdlePromote"
              label={`Hurdle ${index + 1} Promote`}
              value={hurdlePromotes[index - 1]}
              type={PERCENT_TYPE}
              min={(hurdlePromotes[index - 2] ?? 0.01) * 100}
              max={(hurdlePromotes[index] ?? 1) * 100}
              onChange={hurdleParamOnChange(index - 1)}
            />
            <FormFieldComponent
              className={margin}
              name="hurdleIrr"
              label={`Hurdle ${index + 1} IRR`}
              value={hurdleIrrs[index]}
              type={PERCENT_TYPE}
              min={hurdleIrrs[index - 1] * 100}
              max={(hurdleIrrs[index + 1] ?? 1) * 100}
              onChange={hurdleParamOnChange(index)}
            />
            <InfoFieldComponent
              className={margin}
              value={`${formatPercentage(lpSplits[index])} LP / ${formatPercentage(1 - lpSplits[index])} GP`}
              valueClassName="text-sm text-gray-500"
            />
          </Fragment>
        ))}
        <div className={`w-full flex flex-row justify-center gap-x-4 ${margin}`}>
          <Button text="Add Hurdle" small onClick={addHurdle} />
          {(hurdlePromotes.length > 1) && <Button text="Remove Hurdle" small cancel onClick={removeHurdle} />}
        </div>
        <FormFieldComponent
          className={margin}
          name="hurdlePromote"
          label="Thereafter Promote"
          value={last(hurdlePromotes)}
          type={PERCENT_TYPE}
          min={(hurdlePromotes[hurdlePromotes.length - 2] ?? 0) * 100}
          max="100"
          onChange={hurdleParamOnChange(hurdlePromotes.length - 1)}
        />
        <InfoFieldComponent
          className={margin}
          value={`${formatPercentage(last(lpSplits))} LP / ${formatPercentage(1 - last(lpSplits))} GP`}
          valueClassName="text-sm text-gray-500"
        />
      </>
    );
  };
}
