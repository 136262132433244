import { sum, mean } from 'lodash';
import { formatCurrency, formatCurrencyAbbreviated } from 'components/utils';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import CapitalNeedsChart from './CapitalNeedsChart';

const sumPurchasePrice = (deals = []) => sum(deals.map(deal => deal.attributes.purchasePrice));
const averagePurchasePrice = (deals = []) => mean(deals.map(deal => deal.attributes.purchasePrice));
const averageUnderwritingRent = (deals = []) => mean(deals.map(deal => deal.attributes.underwritingRent));

export default function CapitalNeeds({ filteredAcquisitions }) {
  const metrics = [
    { label: '# Closing', value: filteredAcquisitions.length, hideLookup: true },
    { label: '$ in Contract', value: formatCurrencyAbbreviated(sumPurchasePrice(filteredAcquisitions)), hideLookup: true },
    { label: 'EMD Hard ($)', value: '-', hideLookup: true },
    { label: 'Avg. Purchase Price', value: formatCurrencyAbbreviated(averagePurchasePrice(filteredAcquisitions)), hideLookup: true },
    { label: 'Avg. Rent', value: formatCurrency(averageUnderwritingRent(filteredAcquisitions)), hideLookup: true },
  ];

  return (
    <div className="flex flex-col gap-y-4">
      <ReturnMetricBoxes metrics={metrics} />
      {filteredAcquisitions.length ? <CapitalNeedsChart deals={filteredAcquisitions} /> : null}
    </div>
  );
}
