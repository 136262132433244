import { useMemo } from 'react';
import { compact, mean, meanBy, round, sumBy } from 'lodash';
import { formatCurrencyAbbreviated } from 'components/utils';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import DisposTable from './DisposTable';
import { daysOnMarketFn, longDomFn, metricFunc } from './disposition.utils';

const metricsData = [
  { label: '# of Long DOM', statFunc: (dispos => dispos.length) },
  { label: 'Avg List Price', statFunc: (dispos => meanBy(dispos, 'attributes.currentListPrice')), formatter: formatCurrencyAbbreviated },
  { label: 'Avg Expected Profit', statFunc: (dispos => meanBy(dispos, 'attributes.estimatedProfit')), formatter: formatCurrencyAbbreviated },
  { label: 'Avg ROI', statFunc: (dispos => meanBy(dispos, 'attributes.estimatedRoi')), formatter: formatCurrencyAbbreviated },
  { label: 'Avg DOM', statFunc: ((dispos, asOfDate) => mean(compact(dispos.map(dispo => daysOnMarketFn(dispo, asOfDate))))), formatter: val => round(val, 1) },
  { label: 'Avg # of Offers per Property', statFunc: (dispos => meanBy(dispos, 'attributes.numberOfOffers')), formatter: val => round(val, 1) },
  { label: 'Total Expected Profit', statFunc: (dispos => sumBy(dispos, 'attributes.estimatedProfit')), formatter: formatCurrencyAbbreviated },
];

export default function LongDOM({ asOfDate, lastWeekDate, lastMonthDate, filteredDispositions, oneWeekAgoFilteredDispositions, oneMonthAgoFilteredDispositions }) {
  const metrics = useMemo(
    () => metricsData.map(({ label, formatter, statFunc }) => metricFunc(
      label,
      statFunc,
      asOfDate,
      lastWeekDate,
      lastMonthDate,
      longDomFn(filteredDispositions, asOfDate),
      longDomFn(oneWeekAgoFilteredDispositions, lastWeekDate),
      longDomFn(oneMonthAgoFilteredDispositions, lastMonthDate),
      formatter
    )),
    [filteredDispositions],
  );

  return (
    <div className="flex flex-col gap-y-10">
      <ReturnMetricBoxes metrics={metrics} />
      <DisposTable filteredData={longDomFn(filteredDispositions, asOfDate)} tableType="Long DOM" />
    </div>
  );
}
