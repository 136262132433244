import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormField } from 'components/Form';
import { parseEventValue } from 'components/utils';
import Alert from 'components/Alert';
import Button from 'components/shared/NewButton';
import { useCreateHomeBuilderMutation } from 'redux/homeBuilderApiSlice';

export default function NewHomeBuilder() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [alert, setAlert] = useState(null);
  const [createHomeBuilder, { isLoading }] = useCreateHomeBuilderMutation();

  const onChange = (e) => {
    setAlert(null);
    const value = parseEventValue(e);
    if (e.target.name === 'name') {
      setName(value);
    } else {
      setWebsite(value);
    }
  }

  const onSubmit = async () => {
    setAlert(null);

    if (!name.length) {
      setAlert({ type: 'warning', text: 'Name is a required field' });
      return;
    }
    if (!website.length) {
      setAlert({ type: 'warning', text: 'Website is a required field' });
      return;
    }
    const response = await createHomeBuilder({ name, website });
    if (response.error) {
      setAlert({ type: 'danger', text: response.error.error });
    } else {
      navigate('/new_builds/import');
    }
  }

  return (
    <div className="w-full h-screen py-24 bg-gray-100">
      <div className="p-6 mx-auto w-128 bg-white rounded">
        <div className="text-lg">New Home Builder</div>
        <FormField
          className="mt-12"
          name="name"
          label="Name"
          value={name}
          type="text"
          onChange={onChange}
        />
        <FormField
          className="mt-8"
          name="website"
          label="Website"
          value={website}
          type="text"
          onChange={onChange}
        />
        {alert && <Alert className="mt-8" {...alert} />}
        <div className="mt-12 flex justify-center gap-x-6">
          <Button
            outlined
            label="Back"
            onClick={() => navigate('/new_builds/import')}
          />
          <Button
            filled
            label="Create Home Builder"
            onClick={onSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
