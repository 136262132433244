import { useState } from 'react';
import { useCreateDealAttachmentMutation, useCreateTaskAttachmentMutation } from 'redux/apiSlice';
import { DEAL_ATTACHMENT_TYPE, TASK_ATTACHMENT_TYPE } from 'components/constants';
import { X } from 'components/icons';
import { fileSize } from 'components/utils';
import Button from '../Button';
import FileUploadArea from '../FileUploadArea';
import DashboardModal from './DashboardModal';

export default function UploadAttachmentModal({ attachmentType, deal, task, onClose }) {
  const [createDealAttachmentMutation, { isLoading: isUpdatingDealAttachment }] = useCreateDealAttachmentMutation();
  const [createTaskAttachmentMutation, { isLoading: isUpdatingTaskAttachment }] = useCreateTaskAttachmentMutation();
  const [files, setFiles] = useState([]);
  const isLoading = isUpdatingDealAttachment || isUpdatingTaskAttachment;

  const onFileUpload = (newFiles) => {
    setFiles([...files, ...newFiles]);
  };

  const onFileRemoveClick = (index) => (
    () => {
      const updatedFileList = [...files];
      updatedFileList.splice(index, 1);
      setFiles(updatedFileList);
    }
  );

  const onSubmit = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (files.length === 0) {
      return;
    }

    if (attachmentType === DEAL_ATTACHMENT_TYPE) {
      await createDealAttachmentMutation({ dealId: deal.id, files });
    } else if (attachmentType === TASK_ATTACHMENT_TYPE) {
      await createTaskAttachmentMutation({ dealId: task.dealId, taskId: task.id, files });
    } else {
      throw new Error(`Unsupported attachment type: ${attachmentType}`);
    }
    onClose();
  };

  return (
    <DashboardModal
      title="Upload Document"
      action={(
        <Button
          confirm
          text="Upload"
          isLoading={isLoading}
          className="font-semibold border border-black border-opacity-12"
          shadow={false}
        />
      )}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <div className="space-y-3">
        {(files.length === 0) && <FileUploadArea onFileUpload={onFileUpload} />}
        {files.map((file, index) => (
          <div key={file.name} className="w-full flex justify-between items-center gap-x-2 text-sm">
            <div className="flex-grow truncate">{file.name}</div>
            <div className="text-gray-500">{fileSize(file.size)}</div>
            <button type="button" onClick={onFileRemoveClick(index)} className="focus:outline-none">
              <X className="w-5 text-red-500 hover:text-red-400" />
            </button>
          </div>
        ))}
      </div>
    </DashboardModal>
  );
}
