import { useMemo } from 'react';
import { isNil, map } from 'lodash';
import GoogleMap from 'components/common/GoogleMap';
import GoogleMapUtils from 'components/common/GoogleMap/utils';
import { DOMLegend } from '../Shared/MapLegends';

function InfoWindow({ photo, dom, bed, bath, sqft, address }) {
  const stopPropagation = e => e.stopPropagation();

  return (
    <div
      onClick={stopPropagation}
      className="z-50 cursor -top-24 relative w-60 h-auto left-10 block bg-white border rounded overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out"
    >
      <div className="rounded overflow-hidden shadow-lg h-full flex flex-col">
        <div className="relative group h-40">
          <img className="w-full h-full object-cover" src={photo} alt="House" referrerPolicy="no-referrer" />
        </div>
        <div className="p-3">
          <div className="font-bold text-sm mb-2">{`${dom} DOM`}</div>
          <p className="text-gray-700 text-xs">{`${bed} Beds | ${bath} Baths | ${sqft} Sq.Ft`}</p>
          <p className="text-gray-700 text-sm mt-1">{address}</p>
        </div>
      </div>
    </div>
  );
}

export default function Map({ filteredDispositions }) {
  const places = useMemo(() => {
    const disposWithLatLng = filteredDispositions.filter(dispo => {
      const hasLatLong = !isNil(dispo.attributes.property.latitude) && !isNil(dispo.attributes.property.latitude);
      return hasLatLong;
    });

    return map(disposWithLatLng, dispo => ({
      ...dispo.attributes,
      ...dispo.attributes.property,
      active: false,
      id: dispo.id,
      lat: Number(dispo.attributes.property?.latitude || dispo.latitude),
      lng: Number(dispo.attributes.property?.longitude || dispo.longitude),
      showInfoWindow: false,
      subject: false,
      text: dispo.attributes.property?.address || dispo.address,
      markerColor: dispo.attributes.dom < 100 ? '#0C4599' : 'red',
    }));
  }, [filteredDispositions]);

  const defaultCenter = places?.length && { lat: places[0]?.lat, lng: places[0]?.lng };

  return (
    <div className="w-full h-full outline-none">
      {places && defaultCenter && (
        <GoogleMap
          defaultCenter={defaultCenter}
          hoveredId={null}
          infoWindow={InfoWindow}
          legendComponent={<DOMLegend />}
          places={places}
          mapType={GoogleMapUtils.MAP_TYPE_ROADMAP}
        />
      )}
    </div>
  );
}
