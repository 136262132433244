import classNames from 'classnames';
import { LoadingIndicator } from './icons';

export default function Button({ cancel, className, danger, disabled, flex, isLoading, link, onClick, primary, secondary, small, text, shadow = true, confirm, transparent, submit = true }) {
  const clazz = classNames(

    small ? 'px-4 py-1 text-sm' : 'px-4 py-2',
    className,
    'focus:outline-none',
    {
      'rounded': !link,
      'text-white': !link && !transparent,
      'bg-white underline': link,
      'flex flex-row justify-center': flex,
      'bg-tertiary hover:bg-tertiary-lighter active:bg-tertiary-darker': !cancel && !danger && !primary && !secondary && !link && !confirm && !transparent,
      'bg-gray-400 hover:bg-gray-300 active:bg-gray-500': cancel && !link,
      'bg-red-500 hover:bg-red-400 active:bg-red-600': danger && !link,
      'bg-primary-500 hover:bg-primary-400 active:bg-primary-600': primary && !link,
      'bg-secondary-400 hover:bg-secondary-300 active:bg-secondary-500': secondary && !link,
      'bg-emerald-500 hover:bg-emerald-400 active:bg-emerald-600': confirm && !link,
      'bg-transparent hover:bg-gray-50 active:bg-gray-200': transparent && !link,
      'text-tertiary hover:text-tertiary-lighter active:text-tertiary-darker': !cancel && !danger && !secondary && link,
      'text-gray-400 hover:text-gray-300 active:text-gray-500': cancel && link,
      'text-red-500 hover:text-red-400 active:text-red-600': danger && link,
      'text-secondary-400 hover:text-secondary-300 active:text-secondary-500': secondary && link,
      'text-emerald-500 hover:text-emerald-400 active:text-emerald-600': confirm && link,
      'bg-primary hover:bg-primary-500 active:bg-primary-600': primary && !link,
      'cursor-not-allowed': disabled || isLoading,
      'shadow': !link && shadow,
    },
  );

  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={clazz}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <>
          {text}
          <LoadingIndicator className={`inline ml-2 ${small ? 'h-5' : 'h-6'}`} />
        </>
      ) : text}
    </button>
  );
}
