import { useDispatch } from 'react-redux';
import { Gear } from 'components/icons';
import {
  copyDealModal,
  editDealModal,
  markAsDeadModal,
  markAsWithdrawnModal,
  reopenDealModal,
  switchPortfolioModal,
} from 'actions/deal_navigation';
import Menu from 'components/shared/Menu';
import { TRANSACTION_TYPES } from 'components/constants';

export default function DealActionMenu({ deal }) {
  const dispatch = useDispatch();

  const handleEditInformation = () => dispatch(editDealModal(deal));
  const handleMoveToNewPortfolio = () => dispatch(switchPortfolioModal(deal));
  const handleCopyDeal = () => dispatch(copyDealModal());
  const handleReopenDeal = () => dispatch(reopenDealModal());
  const handleMarkAsDead = () => dispatch(markAsDeadModal());
  const handleMarkAsWithdrawn = () => dispatch(markAsWithdrawnModal());

  let statusAction;
  if (deal.deletedAt) {
    statusAction = { text: 'Reopen Deal', onClick: handleReopenDeal };
  } else if (deal.transactionType === TRANSACTION_TYPES.acquisition) {
    statusAction = { text: 'Mark as Dead', onClick: handleMarkAsDead };
  } else {
    statusAction = { text: 'Mark as Withdrawn', onClick: handleMarkAsWithdrawn };
  }

  const menuItems = [
    [{ text: 'Edit Information', onClick: handleEditInformation }],
    [{ text: 'Assign to Different Portfolio', onClick: handleMoveToNewPortfolio }],
    [{ text: 'Copy Deal', onClick: handleCopyDeal }],
    [statusAction],
  ];

  return (
    <Menu
      itemGroups={menuItems}
      icon={<Gear />}
      minimal
    />
  );
}
