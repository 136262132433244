import { useState } from 'react';
import { CheckCircle, Mail } from 'components/icons';
import { userDisplay } from 'components/utils';
import { isEmail } from 'components/dashboard/ActivityFeed/activityFeed';
import ActivityModal from 'components/dashboard/ActivityFeed/ActivityModal';

export default function ActivityCard({ item, usersById }) {
  const [showModal, setShowModal] = useState(false);

  let icon,
    title;
  const detail = [];

  if (isEmail(item)) {
    const { subject, from, attachments } = item;
    icon = <Mail filled className="h-6 w-6 mr-2 inline text-black text-opacity-36" />;
    title = subject;
    detail.push(from);
    if (attachments?.length) {
      detail.push(`${attachments.length} Attachment${attachments.length > 1 ? 's' : ''}`);
    }
  } else {
    const { name, completedById } = item;
    icon = <CheckCircle filled className="h-6 w-6 mr-2 inline text-black text-opacity-36" />;
    title = name;
    detail.push(`Completed by ${userDisplay(usersById[completedById])}`);
  }

  return (
    <div
      className="flex flex-col gap-y-1.5 p-2 rounded-lg hover:bg-black hover:bg-opacity-8 cursor-pointer select-none"
      onClick={() => setShowModal(true)}
    >
      <div className="flex items-center text-gray-900 font-medium">
        {icon}
        <span className="truncate">{title}</span>
      </div>
      {detail.map((detailLine, index) => (
        <div key={index} className="text-black text-opacity-54 text-sm truncate">{detailLine}</div>
      ))}

      {showModal && (
        <ActivityModal
          item={item}
          title={title}
          onClose={() => setShowModal(false)}
          usersById={usersById}
        />
      )}
    </div>
  );
}
