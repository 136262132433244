import ActivityCard from './ActivityCard';
import { isEmail } from './activityFeed';

export default function FeedSection({ date, items, usersById }) {
  return (
    <div className="flex flex-col py-6 gap-y-4">
      <div className="text-xs font-medium tracking-wider text-gray-900 text-opacity-54">{date}</div>
      {items.map((item) => <ActivityCard key={`${isEmail(item)}${item.id}`} item={item} usersById={usersById} />)}
    </div>
  );
}
