import { Chevron } from 'components/icons';

function HomeModelCountBadge({ count }) {
  return (
    <div className="w-min flex justify-center items-center px-2 py-1 border rounded-lg">
      <div className="text-xs text-gray-700 whitespace-pre">{`${count} ${(count === 1 ? 'model' : 'models')}`}</div>
    </div>
  );
}

export default function HomeModelInfoWindow({ item }) {
  if (!item) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-x-2">
        <div className="text-xs text-gray-600">{item.market}</div>
        <Chevron direction="right" className="w-3 text-gray-500" />
        <div className="text-xs text-gray-600">{`${item.county} County`}</div>
        <Chevron direction="right" className="w-3 text-gray-500" />
        <div className="text-xs text-gray-600">{item.homeBuilder.name}</div>
      </div>
      <div>{`${item.name}, ${item.city}, ${item.state} ${item.zip}`}</div>
      <HomeModelCountBadge count={item.homeModelsCount} />
    </div>
  );
}
