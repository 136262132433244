import { Link } from 'react-router-dom';
import { propertyPath } from 'components/routes';
import { titleCase } from 'components/utils';

export default function AddressCell({ getValue, row: { original: { id, address, property, unitNumber } } }) {
  let propertyAddress = getValue() ? titleCase(getValue()) : address;

  // a lot of property addresses include the unit name, so remove it from the property address
  //  since we are showing the unit info already
  if (unitNumber && propertyAddress.includes(unitNumber)) {
    if ((unitNumber.includes('#') || unitNumber.includes('Apt') || unitNumber.includes('Unit'))) {
      propertyAddress = propertyAddress.replace(unitNumber, '').trim();
    }
    propertyAddress = propertyAddress.replace(`Apt ${unitNumber}`, '').replace(`Unit ${unitNumber}`, '').replace(`#${unitNumber}`, '');
  }
  // unit number equaling nan is a quirk from the Thinknum data set
  // TODO: we should fix the unit number = nan upstream in the data processing
  const displayValue = (unitNumber && (unitNumber !== 'nan')) ? `${propertyAddress}\n${unitNumber}` : propertyAddress;

  if (getValue('isProperty')) {
    return displayValue;
  }

  if (property?.id) {
    return (
      <Link
        className="w-max text-tertiary underline cursor-pointer whitespace-pre-line"
        to={propertyPath(property)}
      >
        {displayValue}
        <div className="hidden">{id}</div>
      </Link>
    );
  }

  return (
    <>
      {displayValue}
      <div className="hidden">{id}</div>
    </>
  );
}
