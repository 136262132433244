import { useDispatch, useSelector } from 'react-redux';
import RangeFilter from 'components/DealSourcing/Filters/RangeFilter';
import BedFilter from 'components/shared/filters/BedFilter';
import BathFilter from 'components/shared/filters/BathFilter';
import { resetActiveFilters, setActiveFilters, setAsOfDate } from 'reducers/dashboard/filters';
import Dropdown from 'components/Dropdown';
import Button from 'components/shared/NewButton';
import Input from 'components/Input';
import { X } from 'components/icons';
import { some, isEqual, isNil } from 'lodash';

const datePart = dateObj => dateObj.toISOString().split('T')[0];

function Filters() {
  const dispatch = useDispatch();
  const { activeFilters, asOfDate, markets, portfolios } = useSelector(state => state.dashboardFilter);

  const resetFilters = () => {
    dispatch(resetActiveFilters());
  };

  const isFiltered = some(Object.values(activeFilters), val => !isNil(val) && !isEqual(val, [false]));
  const marketOptions = markets.map(market => ({ name: market }))
  const portfolioOptions = portfolios.map(portfolio => ({ name: portfolio }))

  return (
    <div className="flex justify-between pb-3 border-b">
      <div>
        <div className="flex gap-x-4 mb-3">
          <Dropdown
            width="w-60"
            placeholder="Filter by market"
            options={marketOptions}
            optionsWidth="w-full"
            setSelectedItem={marketOpt => dispatch(setActiveFilters({ ...activeFilters, market: marketOpt.name }))}
            selectedItem={marketOptions.find(opt => opt.name === activeFilters.market)}
          />
          <Dropdown
            width="w-60"
            placeholder="Filter by portfolio"
            options={portfolioOptions}
            optionsWidth="w-full"
            setSelectedItem={portfolioOpt => dispatch(setActiveFilters({ ...activeFilters, portfolio: portfolioOpt.name }))}
            selectedItem={portfolioOptions.find(opt => opt.name === activeFilters.market)}
          />
        </div>
        <div className="flex items-center gap-x-4">
          <RangeFilter
            type="number"
            filterKey="yearBuilt"
            filters={{ yearBuilt: [activeFilters.yearBuiltMin, activeFilters.yearBuiltMax] }}
            label="Year Built"
            setFilters={(values) => {
              dispatch(setActiveFilters({ ...activeFilters, yearBuiltMin: Number(values.yearBuilt[0]), yearBuiltMax: Number(values.yearBuilt[1]) }));
            }}
          />
          <RangeFilter
            type="number"
            filterKey="sqFt"
            filters={{ sqFt: [activeFilters.sqftMin, activeFilters.sqftMax] }}
            label="Sq Ft"
            setFilters={(values) => {
              dispatch(setActiveFilters({ ...activeFilters, sqftMin: Number(values.sqFt[0]), sqftMax: Number(values.sqFt[1]) }));
            }}
          />
          <RangeFilter
            type="number"
            filterKey="dom"
            filters={{ dom: [activeFilters.domMin, activeFilters.domMax] }}
            label="DOM"
            setFilters={(values) => {
              dispatch(setActiveFilters({ ...activeFilters, domMin: Number(values.dom[0]), domMax: Number(values.dom[1]) }));
            }}
          />
          <BedFilter
            filterKey="bed"
            filterParams={activeFilters.bed}
            onApply={(bed) => dispatch(setActiveFilters({ ...activeFilters, bed }))}
            onReset={() => dispatch(setActiveFilters({ ...activeFilters, bed: [false] }))}
          />
          <BathFilter
            filterKey="bath"
            filterParams={activeFilters.bath}
            onApply={(bath) => dispatch(setActiveFilters({ ...activeFilters, bath }))}
            onReset={() => dispatch(setActiveFilters({ ...activeFilters, bath: [false] }))}
          />
          {isFiltered && (
            <Button
              className="h-9 border-gray-800 border-opacity-50"
              label="Clear Filters"
              onClick={resetFilters}
              outlined
              leadingIcon={<X />}
            />
          )}
        </div>
      </div>
      <div>
        <div className="font-medium text-xs mb-2 text-gray-500">As Of Date</div>
        <Input
          name="asOfDate"
          type="date"
          placeholder="Select As Of Date"
          value={asOfDate}
          onChange={(e) => dispatch(setAsOfDate(e.target.value))}
        />
        {(datePart(new Date(asOfDate)) !== datePart(new Date())) ? (
          <div className="flex justify-end">
            <div
              className="font-medium text-xs mt-2 text-gray-500 cursor-pointer hover:text-gray-400"
              onClick={() => dispatch(setAsOfDate(new Date().toISOString()))}
            >
              Reset
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Filters;
