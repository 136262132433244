import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import Toast from 'components/shared/Toast';
import SaveChangesModal from 'components/modals/SaveChangesModal';

function ModalDisplayer() {
  const { showSaveChangesModal } = useSelector(state => state.navigation.modal);

  if (showSaveChangesModal) {
    return <SaveChangesModal />;
  }

  return null;
}

export default function AssetManagementBase() {
  const { activeToast } = useSelector(state => state.toast);

  return (
    <div>
      <Outlet />
      <ModalDisplayer />
      {!isEmpty(activeToast) && <Toast {...activeToast} /> }
    </div>
  );
}
