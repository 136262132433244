import { Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { X } from 'components/icons';
import Button from 'components/shared/NewButton';
import { useCloseSideNav, useCurrentSideNavPanel } from './NewBuildInventorySideNav';

export default function SideNavPaneContainer({ children }) {
  const show = useCurrentSideNavPanel() !== '';
  const close = useCloseSideNav();

  const [leave, setLeave] = useState(false);
  const doLeave = useCallback(() => { setLeave(true); }, []);
  const afterLeave = useCallback(() => {
    close();
  }, [close]);

  // user clicked on a nav button while leaving, cancel leave
  useEffect(() => {
    if (show) {
      setLeave(false);
    }
  }, [show, children]);

  return (
    <Transition
      as={Fragment}
      show={!leave && show}
      unmount={false}
      enter="transition-[transform,opacity,flex-basis,min-width] ease-in-out duration-75 transform overflow-clip"
      enterFrom="translate-x-full opacity-0 !basis-0 !px-0 !mx-0 !min-w-0"
      enterTo="translate-x-0 opacity-100 basis-1/3"
      leave="transition-[transform,opacity,flex-basis,min-width] ease-in-out duration-150 transform overflow-clip"
      leaveFrom="translate-x-0 opacity-100 basis-1/3"
      leaveTo="translate-x-full opacity-0 !basis-0 !px-0 !mx-0 !min-w-0"
      afterLeave={afterLeave}
    >
      <aside className="basis-1/3 relative flex flex-col min-w-[25%] w-0 max-w-max flex-initial shadow rounded-lg p-2 group">
        {children}
        {!leave && <Button textOnly onClick={doLeave} className="absolute top-2 right-0" label={<X className="size-4" />} />}
      </aside>
    </Transition>
  );
}
