import { useEffect, useRef, useState } from 'react';
import Modal from 'components/NewModal';
import iconLogo from 'assets/icon_logo.png';

import { generateEmailHtml } from './generateEmailHtml';

export default function EmailTemplatePreviewModal({ currentUser, emailTemplate, handleClose, placeholders }) {
  const iframeRef = useRef(null);
  const [iframeHeight, setIframeHeight] = useState(400);
  const { organization: { logoUrl } } = currentUser;
  const logoImageUrl = logoUrl || iconLogo;

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow && emailTemplate.content) {
      const htmlContent = generateEmailHtml(emailTemplate, placeholders, logoImageUrl);

      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(htmlContent);
      doc.close();

      const updateHeight = () => {
        const newHeight = doc.body.scrollHeight;
        setIframeHeight(newHeight);
      };

      // Wait for the content to render
      iframe.onload = updateHeight;
    }
  }, [emailTemplate, logoImageUrl, placeholders]);

  return (
    <Modal
      title={`${emailTemplate.title} Preview`}
      size="extraLarge"
      onClose={handleClose}
      show
    >
      <iframe
        title="Email Preview"
        ref={iframeRef}
        style={{
          width: '100%',
          height: `${iframeHeight}px`,
          border: 'none',
          overflow: 'hidden',
        }}
      />
    </Modal>
  );
}
