import { memo, useMemo, useState } from 'react';
import DataTable from 'components/shared/Table/DataTable';
import Input from 'components/Input';
import { Pencil } from 'components/icons';
import Modal from 'components/Modal';
import Button from 'components/shared/NewButton';
import { naturalSortComparator } from 'components/utils';
import { useEditUserMutation, useSendInviteEmailMutation } from 'redux/organizationApiSlice';
import { CHANNEL_LABELS } from 'components/constants';

const useColumns = (setEditUser) => useMemo(
  () => [{
    header: 'Email',
    accessorKey: 'email',
  }, {
    header: 'Name',
    accessorKey: 'fullName',
  }, {
    header: 'Role',
    accessorKey: 'role',
  }, {
    header: 'Last Activity',
    accessorKey: 'lastActivityAt',
  }, {
    header: 'Reset Password Sent At',
    accessorKey: 'resetPasswordEmailSentAt',
  }, {
    header: 'Deleted At',
    accessorKey: 'deletedAt',
  }, {
    id: 'menu',
    header: '',
    enableSorting: false,
    accessorFn: (row) => row,
    cell: memo(({ getValue }) => (
      <div className="relative">
        <div className="flex justify-center gap-x-1 items-center">
          <div className="flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 cursor-pointer" onClick={(event) => { event.stopPropagation(); event.preventDefault(); setEditUser(getValue()); }}>
            <Pencil className="w-6 h-6 mx-auto" />
          </div>
        </div>
      </div>
    )),
  }],
  [setEditUser],
);

function EditUserModal({ user, setEditUser }) {
  const [deletedAt, setDeletedAt] = useState(user.deletedAt);
  const [editUserMutation, { isLoading: isUpdating }] = useEditUserMutation();
  const [sendInviteEmailMutation, { isLoading: isSending }] = useSendInviteEmailMutation();
  const { email } = user;

  const onUpdateUser = async () => {
    const response = await editUserMutation({ id: user.id, deletedAt });
    // TODO: handle error
    if (response.data) {
      setEditUser(null);
    }
  };
  const onSendInvite = async () => {
    await sendInviteEmailMutation({ id: user.id });
    setEditUser(null);
  };

  return (
    <Modal title={`Edit User - ${email}`} show onClose={() => setEditUser(null)}>
      <div className="w-96 pt-12">
        <label htmlFor="deletedAt" className="font-medium text-sm">Deleted At</label>
        <Input
          name="deletedAt"
          onChange={(e) => setDeletedAt(e.target.value)}
          type="date"
          value={deletedAt}
          className="h-12 mt-2 mb-6 border"
          padding="py-1 px-2"
          width="w-full"
        />
        <div className="mt-12 flex justify-between">
          <Button outlined label="Re-send Invite Email" onClick={onSendInvite} isLoading={isSending} />
          <Button filled label="Update User" onClick={onUpdateUser} isLoading={isUpdating} />
        </div>
      </div>
    </Modal>
  );
}

export default function OrganizationShow({ organization }) {
  const [editUser, setEditUser] = useState(null);
  const { channels, markets, excludedHomeBuilders, users } = organization;

  return (
    <div className="p-6">
      <div className="p-4 bg-white rounded">
        <div className="text-lg mb-12">Organization Info</div>
        <label htmlFor="channels" className="font-medium text-sm">Channels</label>
        <div className="mt-3 mb-6">{channels.map((channelId) => CHANNEL_LABELS[channelId] ?? channelId).join(', ')}</div>
        <label htmlFor="markets" className="font-medium text-sm">Markets</label>
        <div className="mt-3 mb-6">{markets.join(', ')}</div>
        <label className="font-medium text-sm">Excluded Builders</label>
        <div className="mt-3 mb-6">{excludedHomeBuilders.map(({ name }) => name).toSorted(naturalSortComparator()).join(', ')}</div>
        <label htmlFor="users" className="font-medium text-sm">Users</label>
        <div className="my-3">
          <DataTable
            tableContainerClassName="max-h-full"
            data={users}
            columns={useColumns(setEditUser)}
          />
        </div>
      </div>
      {editUser && <EditUserModal user={editUser} setEditUser={setEditUser} />}
    </div>
  );
}
