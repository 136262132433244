import { isEmpty } from 'lodash';
import ReactDOM from 'react-dom';

export default function Tooltip({ toolTip }) {
  if (isEmpty(toolTip)) return null;

  const { target, text } = toolTip;
  const { left, top } = target.getBoundingClientRect();
  const coords = { left, top: top + 50 };
  // TODO: Make this components to accept tooltip positions like TOP, LEFT BOTTOM, RIGHT

  return ReactDOM.createPortal(
    <div style={{ ...coords }} className="absolute z-30 w-auto bg-neutral-medium rounded-lg py-1.5 px-3 text-xs font-medium text-white">
      {text}
    </div>,
    document.body,
  );
}
