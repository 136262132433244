import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { compact, mapValues } from 'lodash';
import { dealSourcingPropertiesPath, portfolioPreferencePath, valuationsPath } from 'components/routes';
import { camelCaseKeys, coalesceProperty, snakeCaseKeys } from 'components/utils';
import {
  DEAL_CONTEXT_TAG,
  HOME_BUILDERS_TAG,
  HOME_MODELS_TAG,
  HOME_MODEL_IMAGES_TAG,
  IMAGES_TAG,
  OFF_MARKET_LISTINGS_TAG,
  SUBDIVISIONS_TAG,
} from './utils';

export const updatePipelineCache = (dispatch, apiSlice, endpoint, deal, updatedDeal) => dispatch(
  apiSlice.util.updateQueryData(endpoint, deal.transactionType, (draft) => {
    const updatedDealIndex = draft.findIndex((d) => d.id === deal.id);
    if (updatedDealIndex !== -1) {
      draft[updatedDealIndex] = { ...draft[updatedDealIndex], ...updatedDeal };
    }
  }),
);

export const blobResponseQuery = {
  transformResponse: response => URL.createObjectURL(response),
  onQueryStarted: (_, { getCacheEntry }) => {
    // revoke object url when query arg is updated
    const { data: prevData } = getCacheEntry();
    if (prevData) {
      URL.revokeObjectURL(prevData);
    }
  },
  onCacheEntryAdded: async (_, { cacheDataLoaded, cacheEntryRemoved }) => {
    // revoke object url when cache is evicted
    const { data } = await cacheDataLoaded.catch(() => ({}));
    if (data) {
      await cacheEntryRemoved;
      URL.revokeObjectURL(data);
    }
  },
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
    prepareHeaders(headers) {
      const csrfToken = document.querySelector('[name=csrf-token]').content;
      headers.set('X-CSRF-TOKEN', csrfToken);
      headers.set('Accept', 'application/json');

      return headers;
    },
  }),
  // TODO: make these constants to avoid typos
  tagTypes: [
    'acquisitions',
    'CurrentUser',
    DEAL_CONTEXT_TAG,
    'DealImportJobs',
    'DealNotes',
    'DealsByPortfolio',
    'dispos',
    'EmailTemplates',
    'fetchDealByExternalId',
    HOME_BUILDERS_TAG,
    HOME_MODELS_TAG,
    HOME_MODEL_IMAGES_TAG,
    IMAGES_TAG,
    'HomeModelValuations',
    'leases',
    'MarkNotificationsAsSeen',
    'Model',
    'Notifications',
    'offers',
    OFF_MARKET_LISTINGS_TAG,
    'Organizations',
    'PipelineClosedDeals',
    'PipelineDeadDeals',
    'PipelineDeals',
    'PipelineStats',
    'PipelineStatus',
    'PipelineTasks',
    'RentCompSets',
    'SaleComps',
    'SaleCompSets',
    SUBDIVISIONS_TAG,
    'upload',
  ],
  endpoints(builder) {
    return {
      fetchPortfolios: builder.query({
        query() {
          return '/api/portfolios';
        },
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchPortfolio: builder.query({
        query: ({ id }) => `/api/portfolios/${id}`,
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchSelf: builder.query({
        query() {
          return '/api/users/self';
        },
        providesTags: ['CurrentUser'],
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchUsers: builder.query({
        query(organizationId) {
          return `/api/users${organizationId ? `?organization_id=${organizationId}` : ''}`;
        },
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchPropertyHistory: builder.query({
        query: (fipsApn) => `/api/properties/${fipsApn}/history`,
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchPropertyLocal: builder.query({
        query: ({ fipsApn, propertyId, latitude, longitude }) => (fipsApn
          ? `/api/properties/local?fips_apn=${fipsApn}${propertyId ? `&property_id=${propertyId}` : ''}`
          : `/api/properties/local?latitude=${latitude}&longitude=${longitude}`),
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchRentComps: builder.query({
        query: ({ activeRentCompSetId, fipsApn, increaseRange, homeModelId, listingId, unit }) => {
          if (activeRentCompSetId) {
            return `/api/properties/${fipsApn}/rent_comps/sets/${activeRentCompSetId}`;
          } else if (listingId) {
            return `/api/properties/rent_comps?listing_id=${listingId}${increaseRange ? '&increase_range=1' : ''}`;
          } else if (fipsApn) {
            let path;
            if (unit) {
              path = `/api/properties/${fipsApn}/rent_comps?bedrooms=${unit[0]}&bathrooms=${unit[1]}&rsf=${unit[2]}`;
            } else {
              path = `/api/properties/${fipsApn}/rent_comps`;
            }
            return `${path}${increaseRange ? '&increase_range=1' : ''}`;
          } else {
            return `/api/properties/rent_comps?home_model_id=${homeModelId}${increaseRange ? '&increase_range=1' : ''}`;
          }
        },
        transformResponse: response => camelCaseKeys(response),
        providesTags: ['RentCompSets'],
      }),
      fetchRentCompSets: builder.query({
        query: ({ dealId, propertyId, propertyManagementRecordId }) => ({
          url: 'api/rent_comp_sets',
          params: snakeCaseKeys({ dealId, propertyId, propertyManagementRecordId }),
        }),
        transformResponse: response => camelCaseKeys(response.data.map(obj => obj.attributes)),
        providesTags: ['RentCompSets'],
      }),
      fetchDealSourcingListings: builder.query({
        query: ({ market }) => ({
          url: '/deal_sourcing/listings',
          params: { market },
        }),
        transformResponse: async listings => {
          if (!listings?.data) {
            return [];
          }
          return camelCaseKeys(listings.data);
        },
      }),
      fetchDealSourcingOffMarketProperties: builder.query({
        query: ({ market, area, circle, polygon }) => ({ url: dealSourcingPropertiesPath({ market, area, circle, polygon }) }),
        transformResponse: response => camelCaseKeys(response).map(parcel => ({
          ...parcel,
          id: parcel.fipsApn,
          propertyId: parcel.fipsApn,
          numberOfUnitsTotal: parcel.unitsCount,
          propertyType: parcel.propertyUseStandardizedCode,
        })),
      }),
      fetchDealSourcingValuations: builder.query({
        // portfolioId as argument to key cache by portfolioId
        // even though it is not used in the query
        // eslint-disable-next-line no-unused-vars
        query: (portfolioId) => valuationsPath,
        transformResponse: response => (
          camelCaseKeys(response).reduce(
            (acc, { dlListingId, returnMetrics }) => Object.assign(acc, { [dlListingId]: { ...returnMetrics } }),
            {},
          )
        ),
      }),
      fetchDealNotes: builder.query({
        query: ({ dealId }) => `/api/deals/${dealId}/notes`,
        providesTags: ['DealNotes'],
        transformResponse: response => camelCaseKeys(response),
      }),
      createDealNote: builder.mutation({
        query: note => ({
          url: `/api/deals/${note.dealId}/create_notes`,
          method: 'POST',
          body: snakeCaseKeys(note),
        }),
        invalidatesTags: ['DealNotes'],
      }),
      createDeal: builder.mutation({
        query: deal => ({
          url: '/api/deals',
          method: 'POST',
          body: snakeCaseKeys(deal),
        }),
        transformResponse: response => {
          // required for packs/clear_filters functionality to work properly
          localStorage.removeItem('paramsUnsaved');
          return camelCaseKeys(response);
        },
        invalidatesTags: [DEAL_CONTEXT_TAG, 'Model'],
      }),
      copyDeal: builder.mutation({
        query: (dealId) => ({
          url: `/api/deals/${dealId}/copy`,
          method: 'POST',
        }),
      }),
      updateDeal: builder.mutation({
        query: (deal) => ({
          url: `/api/deals/${deal.id}`,
          method: 'PUT',
          body: snakeCaseKeys(deal),
        }),
        transformResponse: (response) => camelCaseKeys(response),
        onQueryStarted: async (deal, { dispatch, queryFulfilled }) => {
          // Optimistically update the caches
          const patchResult = updatePipelineCache(dispatch, apiSlice, 'fetchPipeline', deal, deal);
          const patchResultClosedDeals = updatePipelineCache(dispatch, apiSlice, 'fetchPipelineClosed', deal, deal);
          const patchResultDeadDeals = updatePipelineCache(dispatch, apiSlice, 'fetchPipelineDead', deal, deal);

          try {
            // Wait for the mutation to complete and get the response
            const { data: updatedDeal } = await queryFulfilled;

            // Update the caches with the server response
            updatePipelineCache(dispatch, apiSlice, 'fetchPipeline', deal, updatedDeal);
            updatePipelineCache(dispatch, apiSlice, 'fetchPipelineClosed', deal, updatedDeal);
            updatePipelineCache(dispatch, apiSlice, 'fetchPipelineDead', deal, updatedDeal);
          } catch {
            // Revert the optimistic updates if the mutation fails
            patchResult.undo();
            patchResultClosedDeals.undo();
            patchResultDeadDeals.undo();
          }
        },
        invalidatesTags: [
          DEAL_CONTEXT_TAG,
          'DealsByPortfolio',
          'PipelineStats',
          'PipelineTasks',
        ],
      }),
      fetchDealImportTemplateCsv: builder.query({
        query: (options) => ({
          url: `/api/deal_import_jobs/download_import_template?${new URLSearchParams(snakeCaseKeys(options)).toString()}`,
          responseHandler: response => (response.ok ? response.blob() : response.json()),
        }),
        ...blobResponseQuery,
      }),
      updateScenario: builder.mutation({
        query: scenario => ({
          url: `/api/deals/${scenario.dealId}/scenarios/${scenario.id}`,
          method: 'PUT',
          body: snakeCaseKeys(scenario),
        }),
        transformResponse: response => {
          // required for packs/clear_filters functionality to work properly
          localStorage.removeItem('paramsUnsaved');
          return camelCaseKeys(response);
        },
        invalidatesTags: ['Model'],
      }),
      createScenario: builder.mutation({
        query: scenario => ({
          url: `/api/deals/${scenario.dealId}/scenarios`,
          method: 'POST',
          body: snakeCaseKeys(scenario),
        }),
        transformResponse: response => {
          // required for packs/clear_filters functionality to work properly
          localStorage.removeItem('paramsUnsaved');
          return camelCaseKeys(response);
        },
        invalidatesTags: ['Model'],
      }),
      deleteScenario: builder.mutation({
        query: scenario => ({
          url: `/api/scenarios/${scenario.id}`,
          method: 'DELETE',
          body: snakeCaseKeys(scenario),
        }),
        invalidatesTags: [
          'PipelineDeals',
          'PipelineClosedDeals',
          'PipelineDeadDeals',
          'PipelineStats',
          'PipelineTasks',
          'Model',
        ],
      }),
      deleteDeal: builder.mutation({
        query: deal => ({
          url: `/deals/${deal.id}`,
          method: 'DELETE',
          body: snakeCaseKeys(deal),
        }),
        invalidatesTags: [
          'PipelineDeals',
          'PipelineClosedDeals',
          'PipelineDeadDeals',
          'PipelineStats',
          'PipelineTasks',
        ],
      }),
      fetchDealStatuses: builder.query({
        query(portfolioId) {
          return `/deal_sourcing/deal_statuses/${portfolioId}`;
        },
        transformResponse: response => mapValues(response, camelCaseKeys),
        providesTags: ['PipelineStatus'],
      }),
      createRentCompSet: builder.mutation({
        query: rentCompSet => ({
          url: '/api/rent_comp_sets',
          method: 'POST',
          body: snakeCaseKeys(rentCompSet),
        }),
        invalidatesTags: ['RentCompSets'],
      }),
      updateRentCompSet: builder.mutation({
        query: rentCompSet => ({
          url: `/api/rent_comp_sets/${rentCompSet.id}`,
          method: 'PUT',
          body: snakeCaseKeys(rentCompSet),
        }),
        invalidatesTags: ['RentCompSets'],
      }),
      copyRentCompSet: builder.mutation({
        query: rentCompSet => ({
          url: `/api/rent_comp_sets/${rentCompSet.id}/copy`,
          method: 'POST',
          body: snakeCaseKeys(rentCompSet),
        }),
        invalidatesTags: ['RentCompSets'],
      }),
      deleteRentCompSet: builder.mutation({
        query: rentCompSet => ({
          url: `/api/rent_comp_sets/${rentCompSet.id}`,
          method: 'DELETE',
          body: snakeCaseKeys(rentCompSet),
        }),
        invalidatesTags: ['RentCompSets'],
      }),
      rentCompsCensusTractMetric: builder.query({
        query: ({ selectedPropertyIds }) => ({
          url: '/api/census_tracts/rent_comps_census_tracts_metrics',
          params: selectedPropertyIds.map(id => ['property_id[]', id]),
        }),
      }),
      fetchListingData: builder.query({
        query({ listingId, isLease }) {
          return `/api/listings/${listingId}/data${isLease ? '?is_lease=true' : ''}`;
        },
        transformResponse: response => camelCaseKeys(response),
      }),
      // saleComps Api
      fetchSaleComps: builder.query({
        query: ({ fipsApn, increaseRange, homeModelId, listingId }) => {
          if (listingId) {
            return `/api/properties/sale_comps?listing_id=${listingId}${increaseRange ? '&increase_range=1' : ''}`;
          } else if (fipsApn) {
            return `/api/properties/${fipsApn}/sale_comps${increaseRange ? '&increase_range=1' : ''}`;
          } else {
            return `/api/properties/sale_comps?home_model_id=${homeModelId}${increaseRange ? '&increase_range=1' : ''}`;
          }
        },
        transformResponse: response => camelCaseKeys(response),
        providesTags: ['SaleComps'],
      }),
      fetchSaleCompSets: builder.query({
        query: ({ dealId, propertyId, propertyManagementRecordId }) => ({
          url: 'api/sale_comp_sets',
          params: snakeCaseKeys({ dealId, propertyId, propertyManagementRecordId }),
        }),
        transformResponse: response => camelCaseKeys(response.data.map(obj => obj.attributes)),
        providesTags: ['SaleCompSets'],
      }),
      copySaleCompSet: builder.mutation({
        query: saleCompSet => ({
          url: `/api/sale_comp_sets/${saleCompSet.id}/copy`,
          method: 'POST',
          body: snakeCaseKeys(saleCompSet),
        }),
        invalidatesTags: ['SaleCompSets'],
      }),
      deleteSaleCompSet: builder.mutation({
        query: saleCompSet => ({
          url: `/api/sale_comp_sets/${saleCompSet.id}`,
          method: 'DELETE',
          body: snakeCaseKeys(saleCompSet),
        }),
        invalidatesTags: ['SaleCompSets'],
      }),
      updateSaleCompSet: builder.mutation({
        query: saleCompSet => ({
          url: `/api/sale_comp_sets/${saleCompSet.id}`,
          method: 'PUT',
          body: snakeCaseKeys(saleCompSet),
        }),
        invalidatesTags: ['SaleCompSets'],
      }),
      fetchDealContext: builder.query({
        query: ({ id, type }) => ({ url: `/api/deals/context/${type}/${id}` }),
        transformResponse: response => {
          // TODO: should we handle this server side?
          const camelResponse = camelCaseKeys(response);
          const { deal, listing } = camelResponse;
          if (listing) {
            camelResponse.listing = listing.data ? listing.data.attributes : listing;
          }
          camelResponse.deal = deal?.data ? deal.data.attributes : null;
          return camelResponse;
        },
        providesTags: [DEAL_CONTEXT_TAG],
      }),
      fetchModel: builder.query({
        query: ({ id, type, propertyId, scenarioId }) => {
          let path = `/api/deals/context/${type}/${id}/model`;
          if (scenarioId || propertyId) {
            const queryParams = [propertyId && `property_id=${propertyId}`, scenarioId && `scenario_id=${scenarioId}`];
            path = `${path}?${compact(queryParams).join('&')}`;
          }
          return { url: path };
        },
        transformResponse: response => camelCaseKeys(response),
        providesTags: ['Model'],
      }),
      fetchDeals: builder.query({
        query: ({ id: portfolioId }) => `/api/portfolios/${portfolioId}/deals`,
        transformResponse: response => camelCaseKeys(response.data).map(({ attributes }) => attributes),
        providesTags: ['DealsByPortfolio'],
      }),
      fetchScenario: builder.query({
        query: ({ id, dealId }) => `/api/deals/${dealId}/scenarios/${id}`,
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchPermissions: builder.query({
        query: () => '/api/users/self/permissions',
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchPortfolioSummary: builder.query({
        query: () => '/api/portfolios/summary',
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchListingDataLake: builder.query({
        query: id => ({ url: `api/data/listings/${id}` }),
        transformResponse: response => camelCaseKeys(response),
      }),
      createTask: builder.mutation({
        query: task => ({
          url: `/api/deals/${task.dealId}/tasks`,
          method: 'POST',
          body: snakeCaseKeys(task),
        }),
        transformResponse: response => camelCaseKeys(response),
        invalidatesTags: [DEAL_CONTEXT_TAG],
      }),
      updateTask: builder.mutation({
        query: task => ({
          url: `/api/deals/${task.dealId}/tasks/${task.id}`,
          method: 'PUT',
          body: snakeCaseKeys(task),
        }),
        transformResponse: response => camelCaseKeys(response),
        invalidatesTags: [
          DEAL_CONTEXT_TAG,
          'PipelineDeals',
          'PipelineStats',
        ],
      }),
      deleteTask: builder.mutation({
        query: task => ({
          url: `/api/deals/${task.dealId}/tasks/${task.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: [DEAL_CONTEXT_TAG],
      }),
      createTaskAttachment: builder.mutation({
        query: ({ dealId, taskId, files }) => {
          const formData = new FormData();
          files.forEach((file, index) => formData.append(`files[${index}]`, file));

          return ({
            url: `/api/deals/${dealId}/tasks/${taskId}/task_attachments`,
            method: 'POST',
            // NOTE: We need to specify headers as empty object or this request will fail.
            //       Apparently it is due to a bug in fetch library (which apiSlice uses)
            //       when sending form data even if you specify 'multipart/form-data'.
            headers: {},
            body: formData,
          });
        },
        invalidatesTags: [DEAL_CONTEXT_TAG],
      }),
      deleteTaskAttachment: builder.mutation({
        query: taskAttachment => ({
          url: `/api/deals/${taskAttachment.dealId}/tasks/${taskAttachment.taskId}/task_attachments/${taskAttachment.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: [DEAL_CONTEXT_TAG],
      }),
      createDealAttachment: builder.mutation({
        query: ({ dealId, files }) => {
          const formData = new FormData();
          files.forEach((file, index) => formData.append(`files[${index}]`, file));

          return ({
            url: `/api/deals/${dealId}/deal_attachments`,
            method: 'POST',
            // NOTE: We need to specify headers as empty object or this request will fail.
            //       Apparently it is due to a bug in fetch library (which apiSlice uses)
            //       when sending form data even if you specify 'multipart/form-data'.
            headers: {},
            body: formData,
          });
        },
        invalidatesTags: [DEAL_CONTEXT_TAG],
      }),
      deleteDealAttachment: builder.mutation({
        query: dealAttachment => ({
          url: `/api/deals/${dealAttachment.dealId}/deal_attachments/${dealAttachment.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: [DEAL_CONTEXT_TAG],
      }),
      updateDealAttachment: builder.mutation({
        query: dealAttachment => ({
          url: `/api/deals/${dealAttachment.dealId}/deal_attachments/${dealAttachment.id}`,
          method: 'PUT',
          body: snakeCaseKeys(dealAttachment),
        }),
        invalidatesTags: [DEAL_CONTEXT_TAG],
      }),
      fetchPropertyManagementRecordIndex: builder.query({
        query: () => 'api/property_management_records',
        transformResponse: response => camelCaseKeys(response.data.map(obj => obj.attributes)),
      }),
      fetchPropertyManagementRecord: builder.query({
        query: (id) => `api/property_management_records/${id}`,
        transformResponse: response => {
          const resp = camelCaseKeys(response.data.attributes);
          const { property, parcels } = resp;
          return {
            ...resp,
            property: coalesceProperty(property, parcels[0]),
          };
        },
      }),
      fetchCustomCompRents: builder.query({
        query: ({ id }) => `api/portfolios/${id}/custom_comp_rents`,
        transformResponse: response => (
          camelCaseKeys(response).reduce(
            (acc, { dlListingId, rent, score }) => Object.assign(acc, { [dlListingId]: { rent, score } }),
            {},
          )
        ),
      }),
      uploadGeocodeBatchCsv: builder.mutation({
        query: ({ file }) => {
          const formData = new FormData();
          formData.append('file', file);

          return ({
            url: '/api/geocode/batch',
            method: 'POST',
            // NOTE: We need to specify headers as empty object or this request will fail.
            //       Apparently it is due to a bug in fetch library (which apiSlice uses)
            //       when sending form data even if you specify 'multipart/form-data'.
            headers: {},
            body: formData,
            responseHandler: response => (response.ok ? response.blob() : response.json()),
          });
        },
        ...blobResponseQuery,
      }),
      fetchWorkflowTemplates: builder.query({
        query: () => 'api/workflow_templates',
        transformResponse: response => camelCaseKeys(response),
      }),
      updatePortfolioPreference: builder.mutation({
        query({ id }) {
          return {
            url: portfolioPreferencePath,
            method: 'POST',
            body: snakeCaseKeys({ portfolioId: id }),
          };
        },
        invalidatesTags: [DEAL_CONTEXT_TAG, 'CurrentUser'],
      }),
    };
  },
});

export const {
  useFetchSaleCompsQuery,
  useFetchSaleCompSetsQuery,
  useRentCompsCensusTractMetricQuery,
  useFetchDealStatusesQuery,
  useFetchPortfoliosQuery,
  useFetchPortfolioQuery,
  useFetchPropertyHistoryQuery,
  useFetchPropertyLocalQuery,
  useFetchSelfQuery,
  useFetchUsersQuery,
  useFetchRentCompsQuery,
  useFetchRentCompSetsQuery,
  useFetchDealSourcingListingsQuery,
  useFetchDealSourcingOffMarketPropertiesQuery,
  useFetchDealSourcingValuationsQuery,
  useFetchDealNotesQuery,
  useFetchDealImportTemplateCsvQuery,
  useCreateDealNoteMutation,
  useCreateDealMutation,
  useCopyDealMutation,
  useUpdateDealMutation,
  useDeleteDealMutation,
  useLazyFetchDealImportTemplateCsvQuery,
  useCreateScenarioMutation,
  useUpdateScenarioMutation,
  useDeleteScenarioMutation,
  useCreateRentCompSetMutation,
  useUpdateRentCompSetMutation,
  useCopyRentCompSetMutation,
  useDeleteRentCompSetMutation,
  useFetchListingDataQuery,
  useFetchListingDataLakeQuery,
  usePrefetch,
  useFetchDealContextQuery,
  useFetchModelQuery,
  useFetchDealsQuery,
  useFetchScenarioQuery,
  useFetchPermissionsQuery,
  useFetchPortfolioSummaryQuery,
  useFetchWorkflowTemplatesQuery,
  useCopySaleCompSetMutation,
  useDeleteSaleCompSetMutation,
  useUpdateSaleCompSetMutation,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  useCreateTaskAttachmentMutation,
  useDeleteTaskAttachmentMutation,
  useCreateDealAttachmentMutation,
  useDeleteDealAttachmentMutation,
  useUpdateDealAttachmentMutation,
  useFetchPropertyManagementRecordIndexQuery,
  useFetchPropertyManagementRecordQuery,
  useFetchCustomCompRentsQuery,
  useUploadGeocodeBatchCsvMutation,
  useUpdatePortfolioPreferenceMutation,
} = apiSlice;
