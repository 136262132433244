/* eslint-disable react/no-danger */
import { useRef } from 'react';
import cx from 'classnames';
import { format, parseISO } from 'date-fns';
import DOMPurify from 'dompurify';
import RoundButton from 'components/common/RoundButton';
import { LAYOUT } from 'components/constants';
import { X } from 'components/icons';
import Modal from 'components/Modal';
import { InfoField } from '../../Form';
import { formatDate, linkify, userDisplay } from '../../utils';
import DashboardModal from '../DashboardModal';
import { FEED_DATETIME_FORMAT, isEmail } from './activityFeed';

function EmailDetail({ email }) {
  const { recipients, body, attachments, from, received } = email;
  const formattedRecievedDate = format(parseISO(received), 'EEE, dd/MM/yyyy HH:mm');
  const attachmenstRef = useRef(null);
  const showAttachments = () => attachmenstRef.current.scrollIntoView();
  const sanitizedBody = DOMPurify.sanitize(linkify(body));

  return (
    <>
      <div className="text-xs font-medium text-neutral-dark">{formattedRecievedDate}</div>
      <div className="text-base font-medium text-neutral-dark mt-2">{`From: ${from}`}</div>
      <div className="text-sm font-normal text-neutral-dark mt-2">{`To: ${recipients.join(', ')}`}</div>

      {!!attachments?.length && (
        <div className="text-neutral-light text-xs font-medium mt-2 cursor-pointer" onClick={showAttachments}>
          {`${attachments.length} Attachments`}
        </div>
      )}
      <div
        className={`mt-4 border-t border-gray-200 pt-4 whitespace-pre-wrap text-black ${body ? 'text-opacity-90' : 'text-opacity-54'}`}
        dangerouslySetInnerHTML={{ __html: sanitizedBody || '(No Content)' }}
      />

      {!!attachments?.length && (
        <>
          <div className="mt-8 pb-2 border-b border-gray-200 text-neutral-dark text-base font-medium">{`${attachments.length} Attachments`}</div>
          <div className="flex flex-col items-start" ref={attachmenstRef}>
            {attachments.map(({ id, filename, url, canDownload }) => (canDownload ? (
              <a
                key={id}
                href={url}
                target="_blank"
                rel="noreferrer"
                title={filename}
                className="mt-2 text-base max-w-full truncate text-primary hover:text-primary-500 active:text-primary-600"
              >
                {filename}
              </a>
            ) : (
              <div key={id} title={filename} className="mt-2 text-base max-w-full truncate text-black text-opacity-90 cursor-not-allowed">
                {filename}
              </div>
            )))}
          </div>
        </>
      )}
    </>
  );
}

function TaskDetail({ task, usersById }) {
  const { dueDate, completedById, userId } = task;

  return (
    <>
      <InfoField label="Completed By" value={userDisplay(usersById[completedById])} valueClassName="text-black text-opacity-90" />
      <InfoField label="Assigned To" value={userDisplay(usersById[userId])} valueClassName="text-black text-opacity-90" />
      <InfoField
        label="Due"
        value={dueDate ? formatDate(dueDate, FEED_DATETIME_FORMAT) : 'No Due Date'}
        valueClassName={cx('text-black', dueDate ? 'text-opacity-90' : 'text-opacity-54')}
      />
    </>
  );
}

function WorkflowEmailPreviewModal({ item, title, onClose }) {
  return (
    <Modal show fullScreen backgroundColor="bg-transparent" onClose={onClose}>
      <div className="flex w-full h-full p-6 items-center justify-center" onClick={onClose}>
        <div
          className="flex flex-col h-min max-h-full bg-white rounded-xl divide-y divide-neutral-dark divide-opacity-12"
          style={{ width: `${LAYOUT.workflowEmailPreviewModalWidth}px` }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex px-6 h-18 items-center justify-between">
            <div className="text-2xl text-neutral-dark font-normal">{title}</div>
            <RoundButton padding="p-2 border border-neutral-dark border-opacity-12" onClick={onClose}>
              <X className="w-7" />
            </RoundButton>
          </div>

          <div className="px-6 pt-8 pb-6 overflow-auto overscroll-contain break-all">
            <EmailDetail email={item} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default function ActivityModal({ item, title, onClose, usersById }) {
  if (isEmail(item)) return <WorkflowEmailPreviewModal item={item} title={title} onClose={onClose} />;

  return (
    <DashboardModal
      title={(
        <>
          {title}
          <div className="mt-1 leading-none font-medium text-xs text-gray-900 text-opacity-54">
            {formatDate(item.occurredAt, FEED_DATETIME_FORMAT)}
          </div>
        </>
      )}
      onClose={onClose}
    >
      <div className="flex flex-col gap-y-8">
        <TaskDetail task={item} usersById={usersById} />
      </div>
    </DashboardModal>
  );
}
