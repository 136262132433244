import { createNextState } from '@reduxjs/toolkit';
import { functionalUpdate, getMemoOptions, makeStateUpdater } from '@tanstack/react-table';
import { isPlainObject } from 'lodash/fp';
import { throwingMemo } from '../table.helpers';
import createTableExtension from '../utils/createTableExtension';

/**
 * @typedef {Map<string, Map<string, any>>} RowUpdateState
 */

const stateSymbol = Symbol('');

/**
 * @param {import('@tanstack/react-table').Table} table
 * @return {RowUpdateState}
 */
export const getRowUpdates = (table) => table.getState()[stateSymbol];

/** @type {(table: import('@tanstack/react-table').Table, update: import('@tanstack/react-table').Updater<RowUpdateState>) => void} */
const setUpdatesState = createTableExtension((table) => {
  const updater = makeStateUpdater(stateSymbol, table);
  return (_, update) => updater(update);
});

export const setRowUpdateState = (table, rowId, columnId, updater) => {
  setUpdatesState(table, (prev) => {
    const copied = new Map(prev);
    const rowMap = copied.get(rowId);
    const rowCopied = rowMap === undefined ? new Map() : new Map(rowMap);

    const prevUpdateValue = rowCopied.get(columnId);
    const updatedValue = functionalUpdate(updater, prevUpdateValue);
    rowCopied.set(columnId, updatedValue);
    copied.set(rowId, rowCopied);

    return copied;
  });
};

export const clearRowUpdates = (table) => {
  setUpdatesState(table, new Map());
};

/** @type {WeakMap<import('@tanstack/react-table').Row, import('@tanstack/react-table').Row['getValue']>} */
const getValueRawCache = new WeakMap();
/**
 * @param {import('@tanstack/react-table').Row} row
 * @param {string} columnId
 */
export const getOriginalValue = (row, columnId) => getValueRawCache.get(row)?.(columnId);

/** @type {WeakMap<import('@tanstack/react-table').Row, () => unknown>} */
const updatedOriginalRowGetterCache = new WeakMap();
/**
 * @param {import('@tanstack/react-table').Row} row
 */
export const getUpdatedRowData = (row) => updatedOriginalRowGetterCache.get(row)?.();

/**
 * @type {WeakMap<import('@tanstack/react-table').Column[], string[]>}
 */
const columnOrderCache = new WeakMap();
/**
 *
 * @param {import('@tanstack/react-table').Table} table
 * @param {import('@tanstack/react-table').Column[]} columns
 * @return {string[]}
 */
const getOrderedColumnIds = (table, columns) => {
  let columnOrder = columnOrderCache.get(columns);
  if (columnOrder === undefined) {
    const columnIdxMap = new Map(table.options.columns.map(({ id }, idx) => [id, idx]));

    // sort columns by the order they appear in the column definition array
    columnOrder = columns.map(({ id }) => id).sort((columnIdA, columnIdB) => {
      const orderA = columnIdxMap.get(columnIdA) ?? -1;
      const orderB = columnIdxMap.get(columnIdB) ?? -1;

      if (orderA < 0 && orderB < 0) {
        return 0;
      }

      if (orderA < 0) {
        return 1;
      }

      if (orderB < 0) {
        return -1;
      }

      return orderA - orderB;
    });
    columnOrderCache.set(columns, columnOrder);
  }

  return columnOrder;
};

/**
 * @template T
 * @param {T} originalRow
 * @param {Map<string, any>} rowUpdate
 * @param {import('@tanstack/react-table').Column[]} allColumns
 * @param {import('@tanstack/react-table').Table} table
 * @return {T}
 */
const createUpdatedOriginalRow = (originalRow, rowUpdate, allColumns, table) => {
  const errorHolder = [];

  const changes = [];
  const update = createNextState(originalRow, (draft) => {
    // immer swallows recipe callback exceptions so this needs to be wrapped in try-catch
    try {
      const orderedRowUpdateColumns = getOrderedColumnIds(table, allColumns).filter((columnId) => rowUpdate.has(columnId));

      orderedRowUpdateColumns.forEach((columnId) => {
        const value = rowUpdate.get(columnId);

        const column = table.getColumn(columnId);
        const {
          columnDef: {
            meta: { mutatorFn } = {},
            accessorKey,
          },
        } = column;
        if (mutatorFn) {
          // TODO: it is possible that 2 mutatorFn could be mutually incompatible
          //       there should be an option to provide a callback that receives all changes to a row and produces a new row
          //       but column mutators are called sequentially in column definition order so this limitation could be worked around
          mutatorFn(draft, value, column);
        } else if (accessorKey) {
          const parts = accessorKey.split('_');
          if (parts.length === 1) {
            // eslint-disable-next-line no-param-reassign
            draft[parts[0]] = value;
          } else {
            // accessorKey identifies a nested property
            // traverse and modify

            let property = draft;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < parts.length - 1; i++) {
              property = property?.[parts[i]];
            }
            if (property) {
              property[parts[parts.length - 1]] = value;
            }
          }
        } else {
          throw new Error('An editable column must specify either mutatorFn or accessorKey');
        }
      });
    } catch (e) {
      errorHolder[0] = e;
    }
  }, (patches) => patches.forEach((patch) => changes.push(patch)));

  if (errorHolder.length) {
    throw errorHolder[0];
  }

  if (update === undefined) {
    throw new Error('createNextState return value cannot be undefined');
  }

  let rowDelta;
  if (isPlainObject(update)) {
    const replaceEntireRow = changes.some(({ op, path }) => path.length === 0 && op === 'replace');
    if (!replaceEntireRow) {
      // take the first path element of each patch
      // consider the top-level key changed if a nested key changed
      const changedKeys = new Set(changes.map(({ path: [firstPath] }) => firstPath));
      rowDelta = {};
      Array.from(changedKeys).forEach((key) => Object.assign(rowDelta, { [key]: update[key] }));
    }
  }

  return [update, rowDelta ?? update];
};

/**
 * @type {import('@tanstack/react-table').TableFeature}
 */
export const editFeature = Object.freeze({
  getInitialState(state) {
    return {
      /** @type {RowUpdateState} */
      [stateSymbol]: new Map(),
      ...(state ?? {}),
    };
  },
  createColumn(column) {
    const { columnDef: { accessorKey, accessorFn } } = column;
    if (!!accessorKey && !!accessorFn) {
      // avoid potential ambiguity of accessorKey accessorFn mismatch
      throw new Error(`Specify none or exactly one of accessorKey or accessorFn. (column id: ${column.id})`);
    }
  },
  createRow(row, table) {
    const getValueRaw = row.getValue;
    const getUniqueValueRaw = row.getUniqueValues;
    const getGroupingValueRaw = row.getGroupingValue;

    getValueRawCache.set(row, getValueRaw);

    // memoize here to get a memoized instance per row
    const getUpdatedOriginalRow = throwingMemo(
      () => [row.original, getRowUpdates(table).get(row.id), table.getAllColumns(), table],
      createUpdatedOriginalRow,
      getMemoOptions(table.options, 'debugRows', 'editFeature.getUpdatedOriginalRow'),
    );
    updatedOriginalRowGetterCache.set(row, getUpdatedOriginalRow);

    /** @type {WeakMap<WeakKey, Map<string, any>>} */
    const accessorFnValueCache = new WeakMap();

    /**
     * @type {Partial<import('@tanstack/react-table').Row>}
     */
    const attributes = {
      getValue(columnId) {
        // fast-path
        // row not modified
        if (getRowUpdates(table).size === 0 || !getRowUpdates(table).has(row.id)) {
          return getValueRaw(columnId);
        }

        if (row.getIsGrouped()) {
          console.warn('ColumnGrouping feature is currently incompatible with data editing');
        }

        // if this column has been modified return the modified value
        // otherwise, merge updates with row.original (if necessary) and call this column's accessorFn
        // if there is no accessorFn fall back to getValueRaw
        // updating the entire row.original object is necessary because the accessorFn might access a column that has been modified

        const rowUpdate = getRowUpdates(table).get(row.id);
        // check membership with has in case there's an undefined-valued entry
        if (rowUpdate.has(columnId)) {
          const updatedValue = rowUpdate.get(columnId);
          const rawValue = getValueRaw(columnId);

          // remove value from edit state if the value is the same as the raw value
          // TODO: allow custom equality check?
          if (Object.is(rawValue, updatedValue)) {
            // eslint-disable-next-line no-underscore-dangle
            table._queue(() => {
              setUpdatesState(table, (prev) => {
                const copied = new Map(prev);
                const rowCopied = new Map(copied.get(row.id));
                rowCopied.delete(columnId);
                if (rowCopied.size === 0) {
                  copied.delete(row.id);
                } else {
                  copied.set(row.id, rowCopied);
                }

                return copied;
              });
            });

            return rawValue;
          }

          return updatedValue;
        } else {
          // this row has updates but not on this column
          // the value still needs to be recalculated in case this column's accessorFn depends on a column with updates

          const { accessorFn } = table.getColumn(columnId);
          if (accessorFn === undefined) {
            return getValueRaw(columnId);
          }

          const [updatedOriginalRow] = getUpdatedOriginalRow();
          let valueCache = accessorFnValueCache.get(updatedOriginalRow);
          if (valueCache === undefined) {
            valueCache = new Map();
            accessorFnValueCache.set(updatedOriginalRow, valueCache);
          }

          let updatedValue;
          // valueCache keyed by columnId to mirror stock @tanstack/table behavior
          // valueCache should not be updated if only accessorFn changed
          if (!valueCache.has(columnId)) {
            updatedValue = accessorFn(updatedOriginalRow, row.index);
            valueCache.set(columnId, updatedValue);
          }

          return updatedValue ?? valueCache.get(columnId);
        }
      },
      getUniqueValues(columnId) {
        if (getRowUpdates(table).size > 0 && getRowUpdates(table).has(row.id)) {
          console.warn('getUniqueValue is currently incompatible with data editing');
        }

        return getUniqueValueRaw(columnId);
      },
      getGroupingValue(columnId) {
        if (getRowUpdates(table).size > 0 && getRowUpdates(table).has(row.id)) {
          console.warn('getGroupingValue is currently incompatible with data editing');
        }

        return getGroupingValueRaw(columnId);
      },
    };

    Object.assign(row, attributes);
  },
});
