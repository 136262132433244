import { Map } from 'components/icons';
import IndicatorBadge from 'components/shared/IndicatorBadge';

function BadgeIcon({ children }) {
  return (
    <div className="relative size-16 border border-black border-dashed">
      <Map className="h-full w-auto fill-black" />
      {children}
    </div>
  );
}

export default function IndicatorBadges() {
  return (
    <div className="w-max grid grid-cols-2 gap-4">
      <div>Small Badge</div>
      <BadgeIcon>
        <IndicatorBadge small />
      </BadgeIcon>

      <div>Large Badge (1 digit)</div>
      <BadgeIcon>
        <IndicatorBadge count={1} />
      </BadgeIcon>

      <div>Large Badge</div>
      <BadgeIcon>
        <IndicatorBadge count={1234} />
      </BadgeIcon>

      <div>Large Badge (max length)</div>
      <BadgeIcon>
        <IndicatorBadge count={Number.POSITIVE_INFINITY} />
      </BadgeIcon>
    </div>
  );
}
