/* eslint-disable no-param-reassign */
import { createElement, useEffect, useState } from 'react';
import { cloneDeep, get, isNil, last } from 'lodash';
import { useFetchPermissionsQuery } from 'redux/apiSlice';
import Alert from 'components/Alert';
import { parseEventValue } from 'components/utils';
import { MENU_ITEMS } from 'components/portfolio/Menu/Menu';

const TOP_LEVEL_FIELDS = ['name', 'channels', 'client', 'markets', 'parameters'];
const ARRAY_FIELDS = ['rentGrowthRate'];
const HOLD_PERIOD_FIELD = 'holdPeriod';
const ARRAYS_TO_MATCH_HOLD_PERIOD = ARRAY_FIELDS.map(field => `${field}s`);

function ParameterContainer({ alert, children }) {
  return (
    <div className="py-4 overflow-auto relative">
      {alert && <Alert className="w-1/2 mt-6 fixed top-1 z-50" {...alert} />}
      {children}
    </div>
  );
}

export default function PortfolioForm(props) {
  const {
    portfolio,
    setPortfolio,
    currentTab,
    alert,
    setAlert,
    validatePortfolioParams,
    basePortfolio,
    validationErrors,
  } = props;

  const { currentData: { canEditPortfolio } = {} } = useFetchPermissionsQuery();
  const [unitRenovationEntry, setUnitRenovationEntry] = useState(true);

  useEffect(() => {
    if (!isNil(canEditPortfolio) && !canEditPortfolio) {
      // Vanilla JS: Disable all inputs if the user can not update portfolio.
      const modelInputsDiv = document.getElementById('portfolio-form');
      const inputs = modelInputsDiv?.querySelectorAll('input, select, button');
      inputs?.forEach((input) => {
        input.style.cursor = 'not-allowed';
        input.disabled = true;
      });

      // Edge Case: The label is responsible for converting checkboxes into switches
      const switchLabels = modelInputsDiv?.querySelectorAll('.toggle-label');
      switchLabels?.forEach((label) => {
        label.style.cursor = 'not-allowed';
        label.onclick = (event) => event.stopPropagation();
      });
    }
  }, [canEditPortfolio]);

  const onChange = event => {
    const param = event.target.name;
    const newValue = parseEventValue(event);

    setPortfolio(prevPortfolio => {
      const updatedPortfolio = cloneDeep(prevPortfolio);
      if (TOP_LEVEL_FIELDS.includes(param)) {
        updatedPortfolio[param] = newValue;
      } else {
        updatedPortfolio.parameters[param] = newValue;

        // if adjusting holdPeriod, update size of array parameters accordingly
        if (param === HOLD_PERIOD_FIELD) {
          ARRAYS_TO_MATCH_HOLD_PERIOD.forEach(path => {
            // calculate the array length difference using hold period
            // note that not all arrays have the same length
            const lengthDiff = newValue - prevPortfolio.parameters[param];

            get(updatedPortfolio.parameters, path).length += lengthDiff;
            if (lengthDiff > 0) {
              // when expanding the array, fill the expanded space with the last element
              const originalParam = get(prevPortfolio.parameters, path);
              get(updatedPortfolio.parameters, path).fill(
                last(originalParam),
                originalParam.length,
              );
            }
          });
        }

        if (ARRAY_FIELDS.includes(param)) {
          updatedPortfolio.parameters[`${param}s`].fill(newValue);
        } else if (
          ARRAY_FIELDS.includes(param.substring(0, param.length - 1))
        ) {
          // keep individual parameter aligned with associated array parameter
          // required to make sure rentGrowthRate stays aligned with rentGrowthRates
          updatedPortfolio.parameters[param.substring(0, param.length - 1)] = newValue[0];
        }
      }
      setAlert(validatePortfolioParams(updatedPortfolio));
      return updatedPortfolio;
    });
  };

  const renderTab = tab => createElement(MENU_ITEMS.find(mi => mi.value === tab).component, {
    ...portfolio,
    onChange,
    unitRenovationEntry,
    setUnitRenovationEntry,
    canEditPortfolio,
    defaultFilters: portfolio.parameters?.defaultFilters ?? {},
    basePortfolio,
    portfolio,
    setPortfolio,
    validationErrors,
  });

  return (
    <div>
      <ParameterContainer alert={alert}>
        <div id="portfolio-form">
          {renderTab(currentTab)}
        </div>
      </ParameterContainer>
    </div>
  );
}
