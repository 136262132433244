import { RadioGroup } from '@headlessui/react';
import { CheckboxEmpty, CheckboxFilled } from 'components/icons';

export default function WorkflowType({ label, organization, field, options, onChange }) {
  return (
    <div className="w-full flex-flex-col">
      <span className="block text-xs text-primary-dark border-b mt-6 pb-2">{label}</span>
      <RadioGroup value={organization[field]} onChange={onChange}>
        {
        options.map(opt => (
          <RadioGroup.Option value={opt.value} key={opt.value}>
            {({ checked }) => (
              <div className="flex items-center justify-between py-2 border-b cursor-pointer">
                <span className="text-sm font-light">{opt.label}</span>
                { checked ? <CheckboxFilled className="w-8" /> : <CheckboxEmpty className="w-8" /> }
              </div>

            )}
          </RadioGroup.Option>
        ))
      }
      </RadioGroup>
    </div>
  );
}
