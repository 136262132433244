import { formatCurrency, formatPercentage } from 'components/utils';
import {
  AlignmentType,
  convertInchesToTwip,
  HeightRule,
  Paragraph,
  Table,
  TableBorders,
  TableCell,
  TableRow,
  TextRun,
  UnderlineType,
  WidthType,
} from 'docx';
import { sum } from 'lodash';
import { cellMargin, DEFAULT_BORDER_STYLE, NO_MARGIN } from './util';

const TABLE_ROW_HEIGHT = {
  rule: HeightRule.EXACT,
  value: convertInchesToTwip(0.18),
};

const COMMON_TABLE_PROPS = {
  width: {
    size: 100,
    type: WidthType.PERCENTAGE,
  },
  columnWidths: [3, 1, 1],
  margins: NO_MARGIN,
};

const tableLabelRow = (label) => (
  new TableRow({
    tableHeader: true,
    children: [
      new TableCell({
        columnSpan: 3,
        children: [
          new Paragraph({
            text: {
              text: label,
              size: 16,
            },
            alignment: AlignmentType.CENTER,
          }),
        ],
        shading: { fill: '839cb5' }, // this is tailwind tertiary lighter
        borders: { bottom: DEFAULT_BORDER_STYLE },
      }),
    ],
  })
);

const textRun = (text, bold = false, underline = false) => (
  new TextRun({
    text: text ?? '',
    size: 18,
    bold,
    underline: underline ? { type: UnderlineType.SINGLE } : null,
  })
);

const tableRow = ({
  rowHeader,
  dollarValue,
  percentValue,
  bold = false,
  underline = false,
  rowProps = {},
  cellProps = {},
} = {}) => (
  new TableRow({
    height: TABLE_ROW_HEIGHT,
    children: [
      new TableCell({
        width: {
          size: 60,
          type: WidthType.PERCENTAGE,
        },
        margins: cellMargin,
        children: rowHeader ? [new Paragraph({ children: [textRun(rowHeader, bold, underline)] })] : [],
        ...cellProps,
      }),
      new TableCell({
        width: {
          size: 20,
          type: WidthType.PERCENTAGE,
        },
        margins: cellMargin,
        children: [
          new Paragraph({
            children: [textRun(dollarValue, bold, underline)],
            alignment: AlignmentType.RIGHT,
          })],
        ...cellProps,
      }),
      new TableCell({
        width: {
          size: 20,
          type: WidthType.PERCENTAGE,
        },
        margins: cellMargin,
        children: [
          new Paragraph({
            children: [textRun(percentValue, bold, underline)],
            alignment: AlignmentType.RIGHT,
          })],
        ...cellProps,
      }),
    ],
    ...rowProps,
  })
);

const tableHeader = (label = null) => (
  tableRow({
    rowHeader: label,
    dollarValue: '$',
    percentValue: '%',
    bold: true,
    underline: true,
  })
);

export const buildSourceAndUseTitle = () => (
  new TableCell({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    columnSpan: 2,
    children: [new Paragraph({ text: 'Sources & Uses', alignment: AlignmentType.CENTER })],
    margins: NO_MARGIN,
    shading: {
      fill: '09336b',
      color: 'FFFFFF',
    },
  })
);

export const buildSourcesAndUses = (cashFlows) => {
  const { acquisition, financing, capital } = cashFlows;

  const acquisitionLoan = financing.acquisitionLoanPaymentRepayments[0];
  const purchasePrice = -acquisition.price[0];
  const acquisitionCost = -acquisition.cost[0];
  const followOnCapitalProjects = -sum(capital.followOnCapitalExpenses);
  const originationFee = -financing.loanOriginationFees[0];

  const totalFinancing = acquisitionLoan + sum(financing.futureFundingMonthlyDraws);
  const totalAcquisitionCapital = purchasePrice + acquisitionCost + originationFee;
  const totalUses = totalAcquisitionCapital + followOnCapitalProjects;
  const totalEquityContribution = totalUses - totalFinancing;
  const totalSources = totalEquityContribution + totalFinancing;

  const sourcesTable = new Table({
    ...COMMON_TABLE_PROPS,
    borders: { ...TableBorders.NONE, right: DEFAULT_BORDER_STYLE },
    rows: [
      tableLabelRow('Sources'),
      tableHeader(),
      tableRow({
        rowHeader: 'Total Equity',
        dollarValue: formatCurrency(totalEquityContribution),
        percentValue: formatPercentage(totalEquityContribution / totalSources),
      }),
      tableRow({
        rowHeader: 'Total Financing',
        dollarValue: formatCurrency(totalFinancing),
        percentValue: formatPercentage(totalFinancing / totalSources),
      }),
      // 3 empty rows to align sources to uses
      tableRow(),
      tableRow(),
      tableRow(),

      tableRow({
        rowHeader: 'Total Sources',
        dollarValue: formatCurrency(totalSources),
        percentValue: formatPercentage((totalEquityContribution + totalFinancing) / totalSources),
        cellProps: { borders: { top: DEFAULT_BORDER_STYLE } },
      }),
    ],
  });

  const usesTable = new Table({
    ...COMMON_TABLE_PROPS,
    borders: { ...TableBorders.NONE, left: DEFAULT_BORDER_STYLE },
    rows: [
      tableLabelRow('Uses'),
      tableHeader('Acquisition'),
      tableRow({
        rowHeader: 'Purchase Price',
        dollarValue: formatCurrency(purchasePrice),
        percentValue: formatPercentage(purchasePrice / totalUses),
      }),
      tableRow({
        rowHeader: 'Acq. Costs',
        dollarValue: formatCurrency(acquisitionCost),
        percentValue: formatPercentage(acquisitionCost / totalUses),
      }),
      tableRow({
        rowHeader: 'Acq. Origination Fees',
        dollarValue: formatCurrency(originationFee),
        percentValue: formatPercentage(originationFee / totalUses),
      }),

      tableRow({
        rowHeader: 'Total Acq. Capital',
        dollarValue: formatCurrency(totalAcquisitionCapital),
        percentValue: formatPercentage(totalAcquisitionCapital / totalUses),
        cellProps: { borders: { top: DEFAULT_BORDER_STYLE } },
      }),
      tableRow({
        rowHeader: 'Follow-On Capital Projects',
        dollarValue: formatCurrency(followOnCapitalProjects),
        percentValue: formatPercentage(followOnCapitalProjects / totalUses),
      }),

      tableRow({
        rowHeader: 'Total Uses',
        dollarValue: formatCurrency(totalUses),
        percentValue: formatPercentage((totalAcquisitionCapital + followOnCapitalProjects) / totalUses),
        cellProps: { borders: { top: DEFAULT_BORDER_STYLE } },
      }),
    ],
  });

  return { sourcesTable, usesTable };
};
