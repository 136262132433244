import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import Button from 'components/shared/NewButton';
import { setShowCreateDealModal } from 'actions/deal_navigation';
import { LAYOUT } from 'components/constants';
import { Plus, X } from 'components/icons';
import EditTransactionInfo from 'components/deal/EditTransactionInfo';

function DealOverviewDrawer({ context, showDealOverview }) {
  const dispatch = useDispatch();
  const { data } = context;
  const { deal } = data;

  if (!data.deal) {
    return (
      <div
        className="absolute flex-col w-128 bg-white overflow-auto cursor-default float-right border-l border-gray-200 shadow-lg z-30"
        style={{
          height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)`,
          right: LAYOUT.rightNavWidth,
          top: LAYOUT.dealHeaderHeight,
        }}
      >
        <div className="w-full flex justify-between items-center mb-6 border-b p-6">
          <div className="flex flex-col">
            <span className="text-xl">Transaction Info</span>
          </div>
          <div className="flex items-center gap-x-4">
            <div className="cursor-pointer" onClick={() => showDealOverview(false)} name="closeOverviewDrawerButton"><X className="w-6" /></div>
          </div>
        </div>
        <div className="flex justify-center mt-12">
          <div className="text-center">
            <div className="text-xl">No Info</div>
            <div className="text-xs text-gray-500 my-4">You must create a deal to see overview of the deal</div>
            <div className="flex justify-center">
              <Button filled label="Create Deal" leadingIcon={<Plus className="w-4" />} onClick={() => dispatch(setShowCreateDealModal(true))} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return ReactDOM.createPortal(
    <div
      className="absolute flex-col w-128 bg-white overflow-auto cursor-default float-right border-l border-gray-200 shadow-lg z-30"
      style={{
        height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)`,
        right: LAYOUT.rightNavWidth,
        top: LAYOUT.dealHeaderHeight,
      }}
    >
      <div className="w-full flex justify-between items-center mb-6 border-b p-6">
        <div className="flex flex-col">
          <span className="text-xl">Transaction Info</span>
        </div>
        <div className="flex items-center gap-x-4">
          <div className="cursor-pointer" onClick={() => showDealOverview(false)} name="closeOverviewDrawerButton"><X className="w-6" /></div>
        </div>
      </div>
      <div className="w-full p-4">
        <EditTransactionInfo
          deal={deal}
          onClose={() => showDealOverview(false)}
        />
      </div>
    </div>,
    document.body,
  );
}

export default DealOverviewDrawer;
