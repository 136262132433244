import { compact } from 'lodash';
import DataTable from 'components/shared/Table/DataTable';
import PercentChange from 'components/shared/PercentChange';
import { organizeDemographicsDataForComparison } from './neighborhood.utils';

function DemographicChange({ getValue }) {
  return (
    <PercentChange value={getValue()} />
  );
}

export function DemographicCell({ column, row, getValue }) {
  const alignmentClass = column.columnDef.meta.textAlign === 'center' ? 'justify-center' : 'justify-end';

  if (row.original.isProjection) {
    return (
      <div className={`flex ${alignmentClass}`}>
        <DemographicChange getValue={getValue} />
      </div>
    );
  } else if (getValue()) {
    return row.original.formatter ? row.original.formatter(getValue()) : getValue();
  } else {
    return '-';
  }
}

const getColumns = (demographics, property) => compact([
  {
    header: 'Demographic Key',
    accessorKey: 'demographicKey',
    enableSorting: false,
  },
  demographics.block && {
    header: 'Block',
    accessorKey: 'block',
    cell: DemographicCell,
    meta: { textAlign: 'right' },
    enableSorting: false,
  },
  demographics.censusTract && {
    header: 'Census Tract',
    accessorKey: 'censusTract',
    cell: DemographicCell,
    meta: { textAlign: 'right' },
    enableSorting: false,
  },
  demographics.zip && {
    header: property?.zipCode || 'Zip Code',
    accessorKey: 'zip',
    cell: DemographicCell,
    meta: { textAlign: 'right' },
    enableSorting: false,
  },
  demographics.msa && {
    header: property?.market || 'MSA',
    accessorKey: 'msa',
    cell: DemographicCell,
    meta: { textAlign: 'right' },
    enableSorting: false,
  },
]);

export default function DemographicsComparisonTable({ demographics, property }) {
  const data = organizeDemographicsDataForComparison(demographics);

  return (
    <div className="border">
      <DataTable
        columns={getColumns(demographics, property)}
        data={data}
        tdClassName="py-1.5 whitespace-nowrap border text-sm"
      />
    </div>
  );
}
