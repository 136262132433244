const ThemedTable = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  tableClassName = '',
  headerClassName = '',
  className = '',
  style = {},
  theme
}) => (
  <div className={className} style={style}>
    <table {...getTableProps({ className: tableClassName })}>
      <thead>
        {headerGroups?.map(headerGroups => (
          <tr {...headerGroups.getHeaderGroupProps({
            className: headerClassName
          })}>
            {headerGroups.headers?.map(column => (
              <th
                {...column.getHeaderProps(
                  column &&
                  column.getSortByToggleProps &&
                  column.getSortByToggleProps({
                    title: 'Toggle sort direction',
                  })
                )}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows?.map(row => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps({
              className: theme.row
            })}>
              {row.cells?.map(cell => (
                <td
                  {...cell.getCellProps({
                    className: theme[cell.column.id]
                  })}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
);

export default ThemedTable;
