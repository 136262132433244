import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormField } from 'components/Form';
import { useUpdateDealMutation } from 'redux/apiSlice';
import { clearDealNavigationModal } from 'actions/deal_navigation';
import Button from 'components/shared/NewButton';
import { parseEventValue } from 'components/utils';
import Alert from '../Alert';
import DashboardModal from './DashboardModal';
import { DEAD_REASONS } from './MarkDealAsDeadModal';

export default function MarkDealAsWithdrawnModal({ context }) {
  const [withdrawalReason, setWithdrawalReason] = useState(DEAD_REASONS[0][0]);
  const [withdrawalDate, setWithdrawalDate] = useState(null);
  const [alert, setAlert] = useState(null);

  const [updateDealMutation, { isLoading: isUpdating }] = useUpdateDealMutation();

  const { data } = context;
  const { deal } = data;

  const dispatch = useDispatch();
  const passOnDeal = async () => {
    const response = await updateDealMutation({ id: deal.id, deadReason: withdrawalReason, deletedAt: withdrawalDate });

    if (response.error) {
      console.error(response.error);
      setAlert({ type: 'danger', text: 'There was an error updating the deal' });
    } else {
      dispatch(clearDealNavigationModal());
    }
  };

  return (
    <DashboardModal
      title="Mark as Withdrawn?"
      action={(
        <Button
          danger
          filled
          label="Confirm"
          type="submit"
          isLoading={isUpdating}
        />
      )}
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
        passOnDeal();
      }}
      onClose={() => dispatch(clearDealNavigationModal())}
    >
      Are you sure you would like to mark this deal as withdrawn?

      <FormField
        name="withdraw reason"
        value={withdrawalReason}
        options={DEAD_REASONS.map(r => [r[0], r[1]])}
        type="select"
        padding="py-2 px-3"
        className="mt-6"
        onChange={(e) => setWithdrawalReason(e.target.value)}
      />
      <FormField
        name="withdraw date"
        value={withdrawalDate}
        type="date"
        padding="py-2 px-3"
        className="mt-6"
        onChange={(e) => setWithdrawalDate(parseEventValue(e))}
      />
      {alert && <Alert className="mt-6" {...alert} />}
    </DashboardModal>
  );
}
