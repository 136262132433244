import { InputCell } from 'components/shared/Table/Cells';
import { formatPercentage } from 'components/utils';

export default function OfferBidInputCell({ getValue, row, ...rest }) {
  const bidPrice = getValue();
  const listPrice = row.getValue('priceMin');
  const discountPct = (listPrice - bidPrice) / listPrice;

  return (
    <div className="relative [td:has(>&)]:py-4">
      <InputCell {...rest} row={row} getValue={getValue} />
      <div className="absolute right-0 text-label-md text-neutral-dark/75">
        {`${discountPct > 0 ? '↓' : '↑'}${formatPercentage(Math.abs(discountPct), 0)} of List`}
      </div>
    </div>
  );
}
