import { useDispatch, useSelector } from 'react-redux';
import { isNil, sortBy } from 'lodash';
import { setShowCreateDealModal } from 'actions/deal_navigation';
import { useCopyRentCompSetMutation, useUpdateRentCompSetMutation } from 'redux/apiSlice';
import { setActiveRentCompSetId } from 'redux/rentCompsSlice';
import Menu from 'components/shared/Menu';
import { ContentCopy, LockFilled, Pencil, Plus, StarFilled, StarOutlined, TrashOutline } from 'components/icons';
import { CURRENT_COMP_SET } from 'components/rentComps/rentComps';
import { DEFAULT_COMP_SET_NAME } from 'components/constants';
import { formatDate } from 'components/utils';

export default function RentCompSetSelector(props) {
  const {
    activeRentCompSet,
    deal,
    property,
    propertyManagementRecord,
    rentCompSets,
    setShowDeleteModal,
    setShowEditModal,
  } = props;
  const dispatch = useDispatch();
  const { selectedRentCompIds } = useSelector(state => state.rentComps);
  const [copyRentCompSetMutation] = useCopyRentCompSetMutation();
  const [updateRentCompSetMutation] = useUpdateRentCompSetMutation();

  const activeSetIsPrimary = activeRentCompSet?.primary;
  const activeSetIsLocked = isNil(activeRentCompSet);

  const menuItemGroups = [];
  if (deal?.id || propertyManagementRecord?.id) {
    const compSetItems = [{
      text: 'Change comp set...',
      className: 'text-xs text-gray-500',
      labelOnly: true,
    }, ...sortBy(rentCompSets, 'name').map(rcs => ({
      text: rcs.name,
      subtext: `${rcs.userName} · ${formatDate(rcs.createdAt)}`,
      onClick: () => { dispatch(setActiveRentCompSetId(rcs.id)); },
      className: rcs.id === activeRentCompSet?.id ? 'bg-primary-focus' : '',
      iconLeft: rcs.primary ? <StarFilled /> : <div />, // empty div to get consistent left padding
    })), {
      text: DEFAULT_COMP_SET_NAME,
      onClick: () => { dispatch(setActiveRentCompSetId(CURRENT_COMP_SET.id)); },
      className: activeRentCompSet?.id ? '' : 'bg-primary-focus',
      iconLeft: <LockFilled className="w-5 h-5 opacity-40" />,
    }];
    menuItemGroups.push(compSetItems);

    const duplicateCompSet = async () => {
      const copiedRentCompSet = await copyRentCompSetMutation({
        dealId: deal?.id,
        id: activeRentCompSet?.id,
        propertyId: property.id,
        propertyManagementRecordId: propertyManagementRecord?.id || null,
        selectedIds: selectedRentCompIds,
      }).unwrap();
      dispatch(setActiveRentCompSetId(copiedRentCompSet.data.attributes.id));
    };

    const compSetActions = [{
      text: 'Comp Set Actions',
      className: 'text-xs text-gray-500',
      labelOnly: true,
    }, {
      text: 'Edit Comp Set',
      onClick: () => { setShowEditModal(true); },
      iconLeft: <Pencil />,
      disabled: !activeRentCompSet,
    }, {
      text: 'Duplicate Comp Set',
      onClick: duplicateCompSet,
      iconLeft: <ContentCopy />,
    }, {
      text: 'Make Primary Comp Set',
      iconLeft: activeSetIsPrimary ? <StarFilled className="fill-gray-200 opacity-20" /> : <StarOutlined />,
      disabled: activeSetIsPrimary || !activeRentCompSet,
      onClick: () => { updateRentCompSetMutation({ id: activeRentCompSet?.id, primary: true }); },
    }];
    menuItemGroups.push(compSetActions);

    menuItemGroups.push([{
      text: 'Delete Comp Set',
      onClick: () => { setShowDeleteModal(true); },
      iconLeft: <TrashOutline className={activeSetIsPrimary ? 'fill-gray-200 opacity-20' : ''} />,
      disabled: activeSetIsPrimary || !activeRentCompSet,
    }]);
  } else {
    menuItemGroups.push([{
      text: 'Create a deal to edit comp sets',
      className: 'text-xs text-gray-500',
      labelOnly: true,
    }]);

    menuItemGroups.push([{
      text: 'Create Deal',
      onClick: () => dispatch(setShowCreateDealModal(true)),
      iconLeft: <Plus className="w-4" />,
    }]);
  }

  return (
    <Menu
      className="w-80"
      label={activeRentCompSet?.name || DEFAULT_COMP_SET_NAME}
      itemGroups={menuItemGroups}
      // eslint-disable-next-line no-nested-ternary
      icon={activeSetIsPrimary ? <StarFilled className="mr-2 w-6 opacity-40" /> : (activeSetIsLocked ? <LockFilled className="mr-2 w-5 h-5 opacity-40" /> : null)}
      alignLeft
    />
  );
}
