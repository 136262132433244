import { partial } from 'lodash';
import ChipFilter from 'components/shared/filters/ChipFilter';

export default function StatusFilter({ filterParams, onApply, onReset }) {
  return (
    <ChipFilter label="Status" filterParams={filterParams} onApply={onApply} onReset={onReset}>
      {({ filterState, setFilterState }) => {
        const onChange = (toggledIndex) => {
          setFilterState(prevState => {
            const [label, enabled] = prevState[toggledIndex];
            const updatedState = [...prevState];
            updatedState[toggledIndex] = [label, !enabled];
            return updatedState;
          });
        };

        return (
          <fieldset className="space-y-8">
            {filterState.map(([label, enabled], index) => (
              <label
                key={label}
                className="flex flex-row items-center gap-x-4.5 w-max text-gray-700 tracking-wide cursor-pointer select-none"
              >
                <input
                  type="checkbox"
                  className="h-4.5 w-4.5 cursor-pointer"
                  checked={enabled}
                  onChange={partial(onChange, index)}
                />
                {label}
              </label>
            ))}
          </fieldset>
        );
      }}
    </ChipFilter>
  );
}
