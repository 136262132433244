import { identity } from 'lodash';
import cx from 'classnames';
import { Check, Warning } from 'components/icons';
import { formatInteger, titleCase } from 'components/utils';
import FloatingTooltip from 'components/shared/FloatingTooltip';

const DATA_VALIDATION_FORMATTING = {
  buildingArea: { formatter: (value) => `${formatInteger(value)} sqft` },
};

function DataValidationField({ field, validationObj }) {
  return (
    <>
      <div className="text-sm text-gray-600">{DATA_VALIDATION_FORMATTING[field]?.label || titleCase(field)}</div>
      <div className={cx('text-right text-gray-900', DATA_VALIDATION_FORMATTING[field]?.className)}>
        {(DATA_VALIDATION_FORMATTING[field]?.formatter || identity)(validationObj[field].property)}
      </div>
      <div className={cx('text-right text-gray-900', DATA_VALIDATION_FORMATTING[field]?.className)}>
        {(DATA_VALIDATION_FORMATTING[field]?.formatter || identity)(validationObj[field].assessor)}
      </div>
    </>
  );
}

function WarningContents({ validationObj, warningFields }) {
  return (
    <div className="grid grid-cols-3 gap-x-4 gap-y-2">
      <div />
      <div className="text-sm text-gray-600">Listing</div>
      <div className="text-sm text-gray-600">Assessor</div>
      {warningFields.map(field => (
        <DataValidationField
          key={field}
          field={field}
          validationObj={validationObj}
        />
      ))}
    </div>
  );
}

export default function DataValidationCell({ getValue }) {
  const validationObj = getValue();
  const warningFields = Object.keys(validationObj).filter(key => !validationObj[key].match)

  const tooltipContents = <WarningContents validationObj={validationObj} warningFields={warningFields} />;

  return (
    <div className="flex justify-center">
      {warningFields.length ? (
        <FloatingTooltip contents={tooltipContents}>
          <Warning className="fill-warning-500 h-5 w-auto" />
        </FloatingTooltip>
      ) : (
        <Check className="text-success-500 h-5 w-auto" />
      )}
    </div>
  );
}
