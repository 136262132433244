import { useCallback, useEffect } from 'react';
import { useUpdateOffMarketListingsMutation } from 'redux/listingApiSlice';
import { useClearRowUpdates, useGetUpdatedRowData, useIsTableEdited } from 'components/shared/Table/DataTableContext';
import { Check, Pencil, Refresh } from 'components/icons';
import Button from 'components/shared/NewButton';
import OffMarketInventoryFilters from './OffMarketInventoryFilters';
import OffMarketInventorySummaryStats from './OffMarketInventorySummaryStats';

export default function OffMarketInventoryTableHeader({ isEditMode, setIsEditMode }) {
  const hasChanged = useIsTableEdited();
  const clearChanges = useClearRowUpdates();

  const getUpdatedRows = useGetUpdatedRowData();
  const [
    updateListings,
    {
      isLoading: isUpdating,
      isSuccess,
      isError,
      error,
      reset: resetMutation,
    },
  ] = useUpdateOffMarketListingsMutation();

  if (error) {
    console.error(error);
  }

  const onEditToggle = useCallback(() => {
    resetMutation();
  }, [resetMutation]);

  const toggleEdit = useCallback(() => {
    onEditToggle?.();
    setIsEditMode((prev) => !prev);
  }, [onEditToggle, setIsEditMode]);

  const onCancel = useCallback(() => {
    clearChanges();
    toggleEdit();
  }, [clearChanges, toggleEdit]);

  useEffect(() => {
    if (isSuccess) {
      clearChanges();
      setIsEditMode(false);
    }
  }, [clearChanges, setIsEditMode, isSuccess]);

  const onSave = useCallback(() => {
    updateListings({ listings: getUpdatedRows() });
  }, [getUpdatedRows, updateListings]);

  return (
    <div className="flex justify-between items-center py-1.5 px-3">
      <OffMarketInventoryFilters />
      <OffMarketInventorySummaryStats className="grow justify-center" />
      {isEditMode ? (
        <div className="flex justify-between gap-x-6">
          <Button
            filled
            danger
            label="Cancel"
            className="justify-self-end"
            onClick={onCancel}
          />
          <Button
            filled
            label={isError ? 'Retry' : 'Save'}
            leadingIcon={isError ? <Refresh /> : <Check />}
            disabled={!hasChanged}
            onClick={onSave}
            isLoading={isUpdating}
          />
        </div>
      ) : (
        <Button
          filled
          label="Edit"
          leadingIcon={<Pencil />}
          className="justify-self-end"
          onClick={toggleEdit}
        />
      )}
    </div>
  );
}
