const { camelCase } = require('lodash');

// these are pulled directly from listing.yml from data repo
// with a few fields removed that are covered in other areas of the listing detail view
// TODO: rooms field does not display well, we should format and show that separately
const standardizedFieldNames = [
  'AboveGradeFinishedArea',
  'AboveGradeFinishedAreaSource',
  'AboveGradeFinishedAreaUnits',
  'AccessCode',
  'AccessibilityFeatures',
  'AccessibleElevatorInstalled',
  'AdditionalParcelsDescription',
  'AdditionalParcelsYN',
  'AlternatePrices',
  'AnchorsCoTenants',
  'Appliances',
  'ArchitecturalStyle',
  'AssociationAmenities',
  'AssociationFee',
  'AssociationFee2',
  'AssociationFee2Frequency',
  'AssociationFeeFrequency',
  'AssociationFeeIncludes',
  'AssociationName',
  'AssociationName2',
  'AssociationPhone',
  'AssociationPhone2',
  'AssociationYN',
  'AttachedGarageYN',
  'AvailabilityDate',
  'Basement',
  'BasementYN',
  'BathroomsFull',
  'BathroomsHalf',
  'BathroomsOneQuarter',
  'BathroomsPartial',
  'BathroomsThreeQuarter',
  'BathroomsTotalInteger',
  'BedroomsPossible',
  'BedroomsTotal',
  'BelowGradeFinishedArea',
  'BelowGradeFinishedAreaSource',
  'BelowGradeFinishedAreaUnits',
  'BodyType',
  'BrokerName',
  'BuilderModel',
  'BuilderName',
  'BuildingAreaSource',
  'BuildingAreaTotal',
  'BuildingAreaUnits',
  'BuildingFeatures',
  'BuildingName',
  'BusinessName',
  'BusinessType',
  'BuyerAgent',
  'BuyerAgentAOR',
  'BuyerAgentDesignation',
  'BuyerAgentDirectPhone',
  'BuyerAgentEmail',
  'BuyerAgentFax',
  'BuyerAgentFirstName',
  'BuyerAgentFullName',
  'BuyerAgentHomePhone',
  'BuyerAgentKey',
  'BuyerAgentLastName',
  'BuyerAgentMiddleName',
  'BuyerAgentMlsId',
  'BuyerAgentMobilePhone',
  'BuyerAgentNamePrefix',
  'BuyerAgentNameSuffix',
  'BuyerAgentOfficePhone',
  'BuyerAgentOfficePhoneExt',
  'BuyerAgentPager',
  'BuyerAgentPreferredPhone',
  'BuyerAgentPreferredPhoneExt',
  'BuyerAgentStateLicense',
  'BuyerAgentTollFreePhone',
  'BuyerAgentURL',
  'BuyerAgentVoiceMail',
  'BuyerAgentVoiceMailExt',
  'BuyerBrokerageCompensation',
  'BuyerFinancing',
  'BuyerOffice',
  'BuyerOfficeAOR',
  'BuyerOfficeEmail',
  'BuyerOfficeFax',
  'BuyerOfficeKey',
  'BuyerOfficeMlsId',
  'BuyerOfficeName',
  'BuyerOfficePhone',
  'BuyerOfficePhoneExt',
  'BuyerOfficeURL',
  'BuyerTeam',
  'BuyerTeamKey',
  'BuyerTeamName',
  'CableTvExpense',
  'CancellationDate',
  'CapRate',
  'CarportSpaces',
  'CarportYN',
  'CarrierRoute',
  'City',
  'CityRegion',
  'CloseDate',
  'ClosePrice',
  'CoBuyerAgent',
  'CoBuyerAgentAOR',
  'CoBuyerAgentDesignation',
  'CoBuyerAgentDirectPhone',
  'CoBuyerAgentEmail',
  'CoBuyerAgentFax',
  'CoBuyerAgentFirstName',
  'CoBuyerAgentFullName',
  'CoBuyerAgentHomePhone',
  'CoBuyerAgentKey',
  'CoBuyerAgentLastName',
  'CoBuyerAgentMiddleName',
  'CoBuyerAgentMlsId',
  'CoBuyerAgentMobilePhone',
  'CoBuyerAgentNamePrefix',
  'CoBuyerAgentNameSuffix',
  'CoBuyerAgentOfficePhone',
  'CoBuyerAgentOfficePhoneExt',
  'CoBuyerAgentPager',
  'CoBuyerAgentPreferredPhone',
  'CoBuyerAgentPreferredPhoneExt',
  'CoBuyerAgentStateLicense',
  'CoBuyerAgentTollFreePhone',
  'CoBuyerAgentURL',
  'CoBuyerAgentVoiceMail',
  'CoBuyerAgentVoiceMailExt',
  'CoBuyerOffice',
  'CoBuyerOfficeAOR',
  'CoBuyerOfficeEmail',
  'CoBuyerOfficeFax',
  'CoBuyerOfficeKey',
  'CoBuyerOfficeMlsId',
  'CoBuyerOfficeName',
  'CoBuyerOfficePhone',
  'CoBuyerOfficePhoneExt',
  'CoBuyerOfficeURL',
  'CoListAgent',
  'CoListAgentAOR',
  'CoListAgentDesignation',
  'CoListAgentDirectPhone',
  'CoListAgentEmail',
  'CoListAgentFax',
  'CoListAgentFirstName',
  'CoListAgentFullName',
  'CoListAgentHomePhone',
  'CoListAgentKey',
  'CoListAgentLastName',
  'CoListAgentMiddleName',
  'CoListAgentMlsId',
  'CoListAgentMobilePhone',
  'CoListAgentNamePrefix',
  'CoListAgentNameSuffix',
  'CoListAgentOfficePhone',
  'CoListAgentOfficePhoneExt',
  'CoListAgentPager',
  'CoListAgentPreferredPhone',
  'CoListAgentPreferredPhoneExt',
  'CoListAgentStateLicense',
  'CoListAgentTollFreePhone',
  'CoListAgentURL',
  'CoListAgentVoiceMail',
  'CoListAgentVoiceMailExt',
  'CoListOffice',
  'CoListOfficeAOR',
  'CoListOfficeEmail',
  'CoListOfficeFax',
  'CoListOfficeKey',
  'CoListOfficeMlsId',
  'CoListOfficeName',
  'CoListOfficePhone',
  'CoListOfficePhoneExt',
  'CoListOfficeURL',
  'CommonInterest',
  'CommonWalls',
  'CommunityFeatures',
  'Concessions',
  'ConcessionsAmount',
  'ConcessionsComments',
  'ConstructionMaterials',
  'ContinentRegion',
  'Contingency',
  'ContingentDate',
  'ContractStatusChangeDate',
  'Cooling',
  'CoolingYN',
  'CopyrightNotice',
  'Country',
  'CountryRegion',
  'CountyOrParish',
  'CoveredSpaces',
  'CropsIncludedYN',
  'CrossStreet',
  'CultivatedArea',
  'CumulativeDaysOnMarket',
  'CurrentFinancing',
  'CurrentUse',
  'DaysOnMarket',
  'DevelopmentStatus',
  'DirectionFaces',
  'Directions',
  'Disclaimer',
  'Disclosures',
  'DistanceToBusComments',
  'DistanceToBusNumeric',
  'DistanceToBusUnits',
  'DistanceToElectricComments',
  'DistanceToElectricNumeric',
  'DistanceToElectricUnits',
  'DistanceToFreewayComments',
  'DistanceToFreewayNumeric',
  'DistanceToFreewayUnits',
  'DistanceToGasComments',
  'DistanceToGasNumeric',
  'DistanceToGasUnits',
  'DistanceToPhoneServiceComments',
  'DistanceToPhoneServiceNumeric',
  'DistanceToPhoneServiceUnits',
  'DistanceToPlaceofWorshipComments',
  'DistanceToPlaceofWorshipNumeric',
  'DistanceToPlaceofWorshipUnits',
  'DistanceToSchoolBusComments',
  'DistanceToSchoolBusNumeric',
  'DistanceToSchoolBusUnits',
  'DistanceToSchoolsComments',
  'DistanceToSchoolsNumeric',
  'DistanceToSchoolsUnits',
  'DistanceToSewerComments',
  'DistanceToSewerNumeric',
  'DistanceToSewerUnits',
  'DistanceToShoppingComments',
  'DistanceToShoppingNumeric',
  'DistanceToShoppingUnits',
  'DistanceToStreetComments',
  'DistanceToStreetNumeric',
  'DistanceToStreetUnits',
  'DistanceToWaterComments',
  'DistanceToWaterNumeric',
  'DistanceToWaterUnits',
  'Dock',
  'DocumentsAvailable',
  'DocumentsChangeTimestamp',
  'DocumentsCount',
  'DOH1',
  'DOH2',
  'DOH3',
  'DoorFeatures',
  'Electric',
  'ElectricExpense',
  'ElectricOnPropertyYN',
  'ElementarySchool',
  'ElementarySchoolDistrict',
  'Elevation',
  'ElevationUnits',
  'EntryLevel',
  'EntryLocation',
  'Exclusions',
  'ExistingLeaseType',
  'Expenses',
  'ExpirationDate',
  'ExteriorFeatures',
  'FarmCreditServiceInclYN',
  'FarmLandAreaSource',
  'FarmLandAreaUnits',
  'Fencing',
  'FinancialDataSource',
  'FireplaceFeatures',
  'FireplacesTotal',
  'FireplaceYN',
  'Flooring',
  'FoundationArea',
  'FoundationDetails',
  'FranchiseAffiliation',
  'FrontageLength',
  'FrontageType',
  'FuelExpense',
  'Furnished',
  'FurnitureReplacementExpense',
  'GarageSpaces',
  'GarageYN',
  'GardenerExpense',
  'GrazingPermitsBlmYN',
  'GrazingPermitsForestServiceYN',
  'GrazingPermitsPrivateYN',
  'GreenBuildingVerification',
  'GreenBuildingVerificationType',
  'GreenEnergyEfficient',
  'GreenEnergyGeneration',
  'GreenIndoorAirQuality',
  'GreenLocation',
  'GreenSustainability',
  'GreenWaterConservation',
  'GrossIncome',
  'GrossScheduledIncome',
  'HabitableResidenceYN',
  'Heating',
  'HeatingYN',
  'HighSchool',
  'HighSchoolDistrict',
  'HistoryTransactional',
  'HomeWarrantyYN',
  'HorseAmenities',
  'HorseYN',
  'HoursDaysOfOperation',
  'HoursDaysOfOperationDescription',
  'Inclusions',
  'IncomeIncludes',
  'InsuranceExpense',
  'InteriorFeatures',
  'InternetAddressDisplayYN',
  'InternetAutomatedValuationDisplayYN',
  'InternetConsumerCommentYN',
  'InternetEntireListingDisplayYN',
  'IrrigationSource',
  'IrrigationWaterRightsAcres',
  'IrrigationWaterRightsYN',
  'LaborInformation',
  'LandLeaseAmount',
  'LandLeaseAmountFrequency',
  'LandLeaseExpirationDate',
  'LandLeaseYN',
  'Latitude',
  'LaundryFeatures',
  'LeadRoutingEmail',
  'LeasableArea',
  'LeasableAreaUnits',
  'LeaseAmount',
  'LeaseAmountFrequency',
  'LeaseAssignableYN',
  'LeaseConsideredYN',
  'LeaseExpiration',
  'LeaseRenewalCompensation',
  'LeaseRenewalOptionYN',
  'LeaseTerm',
  'Levels',
  'License1',
  'License2',
  'License3',
  'LicensesExpense',
  'ListAgent',
  'ListAgentAOR',
  'ListAgentDesignation',
  'ListAgentDirectPhone',
  'ListAgentEmail',
  'ListAgentFax',
  'ListAgentFirstName',
  'ListAgentFullName',
  'ListAgentHomePhone',
  'ListAgentKey',
  'ListAgentLastName',
  'ListAgentMiddleName',
  'ListAgentMlsId',
  'ListAgentMobilePhone',
  'ListAgentNamePrefix',
  'ListAgentNameSuffix',
  'ListAgentOfficePhone',
  'ListAgentOfficePhoneExt',
  'ListAgentPager',
  'ListAgentPreferredPhone',
  'ListAgentPreferredPhoneExt',
  'ListAgentStateLicense',
  'ListAgentTollFreePhone',
  'ListAgentURL',
  'ListAgentVoiceMail',
  'ListAgentVoiceMailExt',
  'ListAOR',
  'ListBrokerageCity',
  'ListBrokerageCountry',
  'ListBrokerageEmail',
  'ListBrokerageName',
  'ListBrokeragePhone',
  'ListBrokeragePostalCode',
  'ListBrokerageStateOrProvince',
  'ListBrokerageUnparsedAddress',
  'ListingAgreement',
  'ListingContractDate',
  'ListingId',
  'ListingKey',
  'ListingService',
  'ListingTerms',
  'ListingTitle',
  'ListingURL',
  'ListOffice',
  'ListOfficeAddress1',
  'ListOfficeAOR',
  'ListOfficeCity',
  'ListOfficeCountry',
  'ListOfficeEmail',
  'ListOfficeFax',
  'ListOfficeKey',
  'ListOfficeMlsId',
  'ListOfficeName',
  'ListOfficePhone',
  'ListOfficePhoneExt',
  'ListOfficePostalCode',
  'ListOfficeStateOrProvince',
  'ListOfficeURL',
  'ListPrice',
  'ListPriceCurrencyType',
  'ListPriceLow',
  'ListTeam',
  'ListTeamKey',
  'ListTeamName',
  'LivingArea',
  'LivingAreaSource',
  'LivingAreaUnits',
  'LockBoxLocation',
  'LockBoxSerialNumber',
  'LockBoxType',
  'Longitude',
  'LotDimensionsSource',
  'LotFeatures',
  'LotSizeAcres',
  'LotSizeArea',
  'LotSizeDimensions',
  'LotSizeSource',
  'LotSizeSquareFeet',
  'LotSizeUnits',
  'MainLevelBathrooms',
  'MainLevelBedrooms',
  'MaintenanceExpense',
  'MajorChangeTimestamp',
  'MajorChangeType',
  'Make',
  'ManagerExpense',
  'MapCoordinate',
  'MapCoordinateSource',
  'MapURL',
  'MiddleOrJuniorSchool',
  'MiddleOrJuniorSchoolDistrict',
  'MLSAreaMajor',
  'MLSAreaMinor',
  'MLSModificationTimestamp',
  'MlsStatus',
  'MobileDimUnits',
  'MobileHomeRemainsYN',
  'MobileLength',
  'MobileWidth',
  'Model',
  'ModificationTimestamp',
  'Neighborhoods',
  'NetOperatingIncome',
  'NewConstructionYN',
  'NewTaxesExpense',
  'NumberOfBuildings',
  'NumberOfFullTimeEmployees',
  'NumberOfLots',
  'NumberOfPads',
  'NumberOfPartTimeEmployees',
  'NumberOfSeparateElectricMeters',
  'NumberOfSeparateGasMeters',
  'NumberOfSeparateWaterMeters',
  'NumberOfUnitsInCommunity',
  'NumberOfUnitsLeased',
  'NumberOfUnitsMoMo',
  'NumberOfUnitsTotal',
  'NumberOfUnitsVacant',
  'OccupantName',
  'OccupantPhone',
  'OccupantType',
  'OffMarketDate',
  'OffMarketTimestamp',
  'OnMarketDate',
  'OnMarketTimestamp',
  'OpenHouse',
  'OpenParkingSpaces',
  'OpenParkingYN',
  'OperatingExpense',
  'OperatingExpenseIncludes',
  'OriginalEntryTimestamp',
  'OriginalListPrice',
  'OriginatingSystem',
  'OriginatingSystemID',
  'OriginatingSystemKey',
  'OriginatingSystemName',
  'OtherEquipment',
  'OtherExpense',
  'OtherParking',
  'OtherStructures',
  'OwnerName',
  'OwnerPays',
  'OwnerPhone',
  'Ownership',
  'OwnershipType',
  'ParcelNumber',
  'ParkingFeatures',
  'ParkingTotal',
  'ParkManagerName',
  'ParkManagerPhone',
  'ParkName',
  'PastureArea',
  'PatioAndPorchFeatures',
  'PendingTimestamp',
  'PestControlExpense',
  'PetsAllowed',
  'PhotosChangeTimestamp',
  'PhotosCount',
  'PoolExpense',
  'PoolFeatures',
  'PoolPrivateYN',
  'Possession',
  'PossibleUse',
  'PostalCity',
  'PostalCode',
  'PostalCodePlus4',
  'PowerProduction',
  'PowerProductionType',
  'PreviousListPrice',
  'PriceChangeTimestamp',
  'PrivateOfficeRemarks',
  'ProfessionalManagementExpense',
  'PropertyAttachedYN',
  'PropertyCondition',
  'PropertySubType',
  'PropertyType',
  'PublicSurveyRange',
  'PublicSurveySection',
  'PublicSurveyTownship',
  'PurchaseContractDate',
  'RangeArea',
  'RentControlYN',
  'RentIncludes',
  'RoadFrontageType',
  'RoadResponsibility',
  'RoadSurfaceType',
  'Roof',
  'RoomsTotal',
  'RoomType',
  'RVParkingDimensions',
  'SeatingCapacity',
  'SecurityFeatures',
  'SeniorCommunityYN',
  'SerialU',
  'SerialX',
  'SerialXX',
  'Sewer',
  'ShowingAdvanceNotice',
  'ShowingAttendedYN',
  'ShowingContactName',
  'ShowingContactPhone',
  'ShowingContactPhoneExt',
  'ShowingContactType',
  'ShowingDays',
  'ShowingEndTime',
  'ShowingInstructions',
  'ShowingRequirements',
  'ShowingStartTime',
  'SignOnPropertyYN',
  'Skirt',
  'SocialMedia',
  'SourceSystem',
  'SourceSystemID',
  'SourceSystemKey',
  'SourceSystemModificationTimestamp',
  'SourceSystemName',
  'SpaFeatures',
  'SpaYN',
  'SpecialLicenses',
  'SpecialListingConditions',
  'StandardStatus',
  'StateOrProvince',
  'StateRegion',
  'StatusChangeTimestamp',
  'Stories',
  'StoriesTotal',
  'StreetAdditionalInfo',
  'StreetDirPrefix',
  'StreetDirSuffix',
  'StreetName',
  'StreetNumber',
  'StreetNumberNumeric',
  'StreetSuffix',
  'StreetSuffixModifier',
  'StructureType',
  'SubAgencyCompensation',
  'SubAgencyCompensationType',
  'SubdivisionName',
  'SuppliesExpense',
  'SyndicateTo',
  'SyndicationRemarks',
  'TaxAnnualAmount',
  'TaxAssessedValue',
  'TaxBlock',
  'TaxBookNumber',
  'Taxes',
  'TaxLegalDescription',
  'TaxLot',
  'TaxMapNumber',
  'TaxOtherAnnualAssessmentAmount',
  'TaxParcelLetter',
  'TaxStatusCurrent',
  'TaxTract',
  'TaxYear',
  'TenantPays',
  'Topography',
  'TotalActualRent',
  'Township',
  'TransactionBrokerCompensation',
  'TransactionBrokerCompensationType',
  'TrashExpense',
  'UnitNumber',
  'UnitsFurnished',
  'UnitTypes',
  'UnitTypeType',
  'UniversalPropertyId',
  'UniversalPropertySubId',
  'UnparsedAddress',
  'Utilities',
  'VacancyAllowance',
  'VacancyAllowanceRate',
  'Vegetation',
  'VideosChangeTimestamp',
  'VideosCount',
  'View',
  'ViewYN',
  'VirtualTourURLBranded',
  'VirtualTourURLUnbranded',
  'WalkScore',
  'WaterBodyName',
  'WaterfrontFeatures',
  'WaterfrontYN',
  'WaterSewerExpense',
  'WaterSource',
  'WindowFeatures',
  'WithdrawnDate',
  'WoodedArea',
  'WorkmansCompensationExpense',
  'YearBuilt',
  'YearBuiltDetails',
  'YearBuiltEffective',
  'YearBuiltSource',
  'YearEstablished',
  'YearsCurrentOwner',
  'Zoning',
  'ZoningDescription',
].map(camelCase);

export default standardizedFieldNames;
