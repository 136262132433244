export default function Disclaimer({ listing }) {
  if (!listing || (listing.source !== 'fmls')) {
    return null;
  }

  return (
    <div className="py-6 mt-12 border-t text-center text-gray-500">
      Accuracy of all information deemed reliable but not guaranteed and should be independently verified. ©2024 FMLS
    </div>
  );
}
