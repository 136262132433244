import { useEffect, useRef, useState } from 'react';

export default function ExpandableText({ text }) {
  const contentRef = useRef(null)
  const [isClamped, setIsClamped] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    if (!contentRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setIsClamped(contentRef.current.scrollHeight > contentRef.current.clientHeight);
    });
    resizeObserver.observe(contentRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const onClick = () => { setIsExpanded(!isExpanded); };

  return (
    <div>
      <span ref={contentRef} className={isExpanded ? 'line-clamp-none' : 'line-clamp-3'}>{text}</span>
      <div className="w-full flex justify-end">
        {(isClamped || isExpanded) && (
          <span className="cursor-pointer text-primary-dark hover:underline hover:text-primary-600" onClick={onClick}>
            {isClamped ? 'Show full description' : 'Truncate description'}
          </span>
        )}
      </div>
    </div>
  );
}
