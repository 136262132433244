import { get, orderBy, sum } from 'lodash';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatCurrency, formatDate, titleCase } from 'components/utils';

const PRIMARY_COLOR = '#002D6F';
const SECONDARY_COLOR = '#34d399';
const COLORS = [PRIMARY_COLOR, SECONDARY_COLOR, '#FFBB28', '#FF8042'];
const GRID_COLOR = '#f5f5f5';
const DATE_KEY = 'Date';

const ITEMIZED_FIELDS = {
  'capital.belowTheLineExpenses': 'items.belowTheLineItems',
  'capital.followOnCapitalExpenses': 'items.capitalExpenseItems',
  'expenses.controllableExpenses': 'items.controllableItems',
  'expenses.nonControllableExpenses': 'items.nonControllableItems',
  'revenue.otherIncomes': 'items.incomeItems',
  'revenue.reimbursableExpenses': 'items.reimbursableItems',
};

export default function ActualVsUnderwrittenChart({ cashFlows, underwrittenCashFlows, path, formatter = formatCurrency, height = 240 }) {
  const itemizedValues = get(cashFlows, ITEMIZED_FIELDS[path]);

  const data = cashFlows.dates.map((date, index) => {
    const underwrittenIndex = underwrittenCashFlows.dates?.indexOf(date);
    const d = {
      [DATE_KEY]: formatDate(date, 'MMM yy'),
      Underwritten: underwrittenIndex ? get(underwrittenCashFlows, path)?.[underwrittenIndex] : null,
    };

    if (itemizedValues) {
      Object.entries(itemizedValues).forEach(entry => {
        if (entry[1][index] !== 0) {
          d[entry[0]] = entry[1][index];
        }
      });
    } else {
      d.Actual = get(cashFlows, path)[index];
    }

    return d;
  });

  return (
    <ResponsiveContainer className="mx-auto" width="90%" height={height}>
      <ComposedChart data={data} margin={{ right: 20, left: 20 }}>
        <XAxis dataKey="Date" minTickGap={90} tickLine={false} />
        <YAxis
          axisLine={false}
          interval="preserveEnd"
          minTickGap={30}
          tickLine={{ stroke: GRID_COLOR }}
          tickFormatter={value => formatter(value)}
        />
        <Tooltip formatter={(value, name) => ([name !== DATE_KEY ? formatter(value) : value, titleCase(name)])} />
        <CartesianGrid stroke={GRID_COLOR} />
        {itemizedValues ? (
          orderBy(Object.keys(itemizedValues), itemKey => sum(itemizedValues[itemKey].map(Math.abs)), 'desc').map((itemKey, index) => (
            <Bar
              key={itemKey}
              dataKey={itemKey}
              stackId={1}
              barSize={20}
              fill={COLORS[index % COLORS.length]}
            />
          ))
        ) : (
          <Bar dataKey="Actual" barSize={20} fill={PRIMARY_COLOR} />
        )}
        <Line
          type="monotone"
          dataKey="Underwritten"
          stroke={SECONDARY_COLOR}
          strokeWidth={2}
          dot={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
