import { isObject } from 'lodash';
import SectionLabel from 'components/dcf/SectionLabel';
import CapitalProjectsTable, { CapitalFeesAndCostsTable } from '../dcf/CapitalProjectsTable';
import PortfolioEditTabSectionLabel from '../portfolio/PortfolioEditTabSectionLabel';
import { FormField } from '../Form';
import { parseEventValue } from '../utils';
import AccountingMethodToggle from '../dcf/AccountingMethodToggle';

const UNIT_TYPES = [
  ['Studio', '0'],
  ['1-BR', '1'],
  ['2-BR', '2'],
  ['3-BR', '3'],
  ['4-BR+', '4'],
];

export default function CapExParameters({ parameters, onChange, unitRenovationEntry, setUnitRenovationEntry, canEditPortfolio }) {
  let { turnBudget } = parameters;
  const {
    rollToMarket,
    turnAccountingMethod,
    turnDowntime,
    terminationCost,
  } = parameters;

  // initial turnBudget parameter if it is not set yet
  if (!isObject(turnBudget)) {
    turnBudget = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    };
  }

  const onTurnBudgetChange = (e) => {
    const updatedTurnBudget = parseEventValue(e);
    onChange({
      target: {
        name: 'turnBudget',
        value: {
          ...turnBudget,
          [e.target.name]: updatedTurnBudget,
        },
      },
    });
  };

  const handleUnitRenovationToggle = (value) => {
    setUnitRenovationEntry(!unitRenovationEntry);
    if (!value) {
      const updatedParams = [
        { name: 'turnBudget', value: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 } },
        { name: 'rollToMarket', value: null },
        { name: 'turnDowntime', value: null },
        { name: 'terminationCost', value: 0 },
      ];
      updatedParams.forEach(target => {
        onChange({ target });
      });
    }
  };

  return (
    <>
      <SectionLabel className="mt-4" text="Unit Renovations" toggle toggleValue={unitRenovationEntry} setToggleValue={handleUnitRenovationToggle} canEditPortfolio={canEditPortfolio} />
      <div className="flex justify-between gap-x-16 mt-6 container">
        <div className="flex-grow">
          <FormField name="">
            <AccountingMethodToggle
              className="py-1"
              name="turnAccountingMethod"
              value={turnAccountingMethod}
              onChange={onChange}
              disabled={!unitRenovationEntry || !canEditPortfolio}
            />
          </FormField>
          <FormField
            className="mt-6"
            name="rollToMarket"
            value={rollToMarket ?? ''}
            label="Unit Roll Month"
            type="number"
            min="0"
            onChange={onChange}
            disabled={!unitRenovationEntry || !canEditPortfolio}
          />
          <FormField
            className="mt-6"
            name="turnDowntime"
            value={turnDowntime ?? ''}
            label="Renovation Downtime (months)"
            type="number"
            min="0"
            onChange={onChange}
            disabled={!unitRenovationEntry || !canEditPortfolio}
          />
          <FormField
            className="mt-6"
            name="terminationCost"
            value={terminationCost ?? ''}
            label="Termination Fee ($/Unit)"
            type="number"
            min="0"
            onChange={onChange}
            disabled={!unitRenovationEntry || !canEditPortfolio}
          />
        </div>
        <div className="flex-grow">
          <div className="block mb-1 text-xs text-tertiary-darker font-medium tracking-wide uppercase flex flex-row justify-between">
            Renovation Budget ($/Unit)
          </div>
          {UNIT_TYPES.map(unitType => (
            <FormField
              key={unitType}
              className="mt-6"
              name={unitType[1]}
              value={turnBudget[unitType[1]]}
              label={unitType[0]}
              type="number"
              min="0"
              onChange={onTurnBudgetChange}
              disabled={!unitRenovationEntry || !canEditPortfolio}
            />
          ))}
        </div>
      </div>
      <PortfolioEditTabSectionLabel className="mt-6" text="Other Capital Projects" />
      <CapitalProjectsTable canEditPortfolio={canEditPortfolio} dcfParams={parameters} onChange={onChange} />
      <PortfolioEditTabSectionLabel className="mt-6" text="Project Fees & Costs" />
      <CapitalFeesAndCostsTable canEditPortfolio={canEditPortfolio} dcfParams={parameters} onChange={onChange} />
    </>
  );
}
