import { createElement, useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import classNames from 'classnames';
import OutputModal from 'components/dcf/OutputModal';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import { DEFAULT_SCENARIO_NAME, MODELLED_INDIVIDUALLY } from 'components/constants';
import {
  calcCashFlows,
  calcCashFlowsOfIndividualScenarios,
  calcReturnMetrics,
  calcReturnMetricsOfIndividualScenarios,
} from 'components/dcf/dcf';
import { setBaseParams } from 'actions/model';
import Summary from 'components/dcf/Summary';
import CashFlow from 'components/dcf/Output/CashFlow';
import Financing from 'components/dcf/Financing';
import Waterfalls from 'components/dcf/Waterfalls';
import Sources from 'components/dcf/Sources';
import { childScenarios } from 'components/PortfolioDeal/portfolio-deal.utils';

const OUTPUT_TABS = {
  summary: ['Summary', Summary],
  cashFlow: ['Cash Flow', CashFlow],
  financing: ['Loan Schedule', Financing],
  waterfall: ['Waterfall', Waterfalls],
  output: ['Sources/Uses', Sources],
};

function Header({ label, tabs }) {
  return (
    <>
      <div className="text-xl mb-4 mt-3">
        {label}
      </div>
      <div className="flex justify-start bg-white">
        {tabs}
      </div>
    </>
  );
}

function OutputTab({ active, label, onClick }) {
  const clazz = classNames(
    'hover:bg-gray-100',
    'flex items-center text-center align-middle',
    'tracking-wider text-sm font-medium',
    'cursor-pointer',
    'h-10 p-4 mt-4 mr-6',
    'align-bottom',
    'rounded-md',
    {
      'bg-blue-50 text-blue-400': active,
      'text-tertiary pb-4': !active,
    },
  );
  return (
    <div className={clazz} onClick={onClick}>{label}</div>
  );
}

function FinancialOutputModal({ setShowOutput, showOutput }) {
  const { propertyId, data: { deal }, modelData } = useOutletContext();
  const [activeParameterTab] = useState('general');
  const [activeOutputTab, setActiveOutputTab] = useState(Object.keys(OUTPUT_TABS)[0]);
  const dispatch = useDispatch();

  const model = useSelector(state => state.model);
  const modelParams = model?.pendingParams;
  const { scenario, scenarios } = modelData.model;

  useEffect(() => {
    // call setBaseParams if no model data is loaded yet
    // this is necessary if user is opening up financials tab without having visited the Model tab
    if (modelData && !modelParams) {
      dispatch(setBaseParams(modelData.model));
    }
  }, [modelData]);

  const renderOutputTab = (cashFlows, returnMetrics, tab) => createElement(OUTPUT_TABS[tab][1], {
    ...modelParams,
    cashFlows,
    returnMetrics,
    activeParameterTab,
    setActiveOutputTab,
  });

  const [cashFlows, returnMetrics] = useMemo(() => {
    if (!modelParams) {
      return [null, null];
    } else if (deal?.modellingMethod === MODELLED_INDIVIDUALLY && !propertyId) {
      // not on an individual property page - so calculate portfolio cashflows / metrics
      const primaryScenarios = childScenarios({ scenarios, primaryOnly: true });
      const cfs = calcCashFlowsOfIndividualScenarios(primaryScenarios);
      const rms = calcReturnMetricsOfIndividualScenarios(primaryScenarios, cfs);
      return [cfs, rms];
    } else {
      const cfs = calcCashFlows(modelParams.dcfParams);
      return [cfs, calcReturnMetrics(cfs, modelParams.dcfParams)];
    }
  }, [modelParams, scenarios]);

  if (!modelParams) return null;

  return (
    <OutputModal
      show={showOutput}
      onClose={() => setShowOutput(false)}
      header={(
        <Header
          label={scenario ? scenario.name : DEFAULT_SCENARIO_NAME}
            // eslint-disable-next-line react/no-unstable-nested-components
          tabs={map(OUTPUT_TABS, (info, key) => (
            <OutputTab
              key={key}
              active={key === activeOutputTab}
              label={info[0]}
              onClick={() => setActiveOutputTab(key)}
            />
          ))}
        />
        )}
    >
      <div className="max-h-screen">
        {renderOutputTab(cashFlows, returnMetrics, activeOutputTab)}
      </div>
    </OutputModal>
  );
}

export default FinancialOutputModal;
