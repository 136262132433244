/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortBy, isNil } from 'lodash';
import {
  useFetchPortfoliosQuery,
  useLazyFetchDealImportTemplateCsvQuery,
} from 'redux/apiSlice';
import { usePostDealImportJobMutation } from 'redux/dealImportJobApiSlice';
import { compactObj, downloadUrl, parseEventValue } from 'components/utils';
import Button from 'components/shared/NewButton';
import InfoPopover from 'components/shared/InfoPopover';
import NumberInput from 'components/shared/NumberInput';
import Alert from 'components/Alert';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import { TRANSACTION_TYPES } from 'components/constants';
import RadioInput from 'components/shared/RadioInput';
import EmptyLoadingState from 'components/shared/EmptyLoadingState';
import useWorkflowTemplateOptions from '../../workflow/useWorkflowTemplateOptions';

export const PARCEL_MATCHING_ADDRESS = 'address';
const PARCEL_MATCHING_PARCEL = 'parcel';
export const PARCEL_MATCHING_NEW_BUILD = 'new_build';
const PORTFOLIO_DEAL_COMBINED = 'portfolio_combined';
const PORTFOLIO_DEAL_INDIVIDUAL = 'portfolio_individual';
const CSV_TEMPLATE_FILENAME = 'deal_import_template.csv';

const IMPORT_TYPE_OPTIONS = [{
  id: 'individual_deals',
  name: 'Individual Deals',
}, {
  id: PORTFOLIO_DEAL_INDIVIDUAL,
  name: 'Portfolio Deal - Individually Modeled',
}, {
  id: PORTFOLIO_DEAL_COMBINED,
  name: 'Portfolio Deal - Combined Model',
}];

export default function SettingsForm() {
  const navigate = useNavigate();
  const [csvUploadOptions, setCsvUploadOptions] = useState({
    dealName: null,
    importType: null,
    parcelMatching: PARCEL_MATCHING_ADDRESS,
    portfolioId: null,
    purchasePrice: null,
    transactionType: TRANSACTION_TYPES.acquisition,
    workflowTemplateId: null,
  });
  const [uploadError, setUploadError] = useState(null);

  const { data: portfolios } = useFetchPortfoliosQuery();
  const [workflowTemplateId, setWorkflowTemplateId, workflowOptions] = useWorkflowTemplateOptions(csvUploadOptions);
  const workflowDropdownOptions = useMemo(() => (
    workflowOptions?.map(([id, name, disabled]) => ({ id, name, disabled }))
  ), [workflowOptions]);
  if (csvUploadOptions.workflowTemplateId !== workflowTemplateId) {
    setCsvUploadOptions((prev) => ({ ...prev, workflowTemplateId }));
  }

  const promiseRef = useRef(null);
  const fileUploadRef = useRef(null);

  // TODO: handle errors
  const [fetchTemplate, { data: csvObjectUrl, error: templateError }] = useLazyFetchDealImportTemplateCsvQuery();
  const [postImportDataJob, { isLoading: isUpdatingDealCsv }] = usePostDealImportJobMutation();

  // TODO check if we need this
  useEffect(() => {
    if (csvObjectUrl) {
      downloadUrl(csvObjectUrl, window.document, CSV_TEMPLATE_FILENAME, false);
    }
  }, [csvObjectUrl]);

  const downloadTemplate = useCallback(
    () => {
      if (csvObjectUrl) {
        downloadUrl(csvObjectUrl, window.document, CSV_TEMPLATE_FILENAME, false);
      } else {
        promiseRef.current = fetchTemplate(csvUploadOptions, true);
      }
    },
    [fetchTemplate, csvObjectUrl, csvUploadOptions],
  );

  const handleFileChange = async (e) => {
    setUploadError(null);
    const files = Array.from(e.target.files);
    if (files.length !== 1) {
      const errorMsg = 'May only upload a single CSV file at a time';
      console.error(errorMsg);
      setUploadError(errorMsg);
      return;
    }
    if (files[0].type !== 'text/csv') {
      const errorMsg = 'Upload file must have a .csv file extension';
      console.error(errorMsg);
      setUploadError(errorMsg);
      return;
    }
    const csvFile = files[0];
    const response = await postImportDataJob({ file: csvFile, options: compactObj(csvUploadOptions) });

    if (response.error) {
      console.error(response);
      setUploadError(response.error.data.error);
    } else {
      navigate(`/import/deals/${response.data.id}`);
    }
  };

  const alert = (templateError || uploadError) ? {
    text: (templateError?.data?.error || templateError?.error) || uploadError,
    type: 'danger',
  } : null;

  if (isNil(workflowOptions)) return <EmptyLoadingState text="Loading Workflow Templates" />;

  return (
    <div className="w-128 mt-12 mx-auto p-6 bg-white rounded">
      <div className="mb-6 text-lg text-gray-900">Deal Import Settings</div>
      <label className="font-medium text-sm text-gray-900">Portfolio</label>
      <div>
        <Dropdown
          width="w-full"
          className="mt-2 mb-8"
          optionsWidth="w-full"
          options={sortBy(portfolios, 'name')}
          selectedItem={portfolios?.find(p => p.id === csvUploadOptions.portfolioId)}
          setSelectedItem={portfolio => setCsvUploadOptions({ ...csvUploadOptions, portfolioId: portfolio.id })}
        />
      </div>
      <div className="flex flex-row items-center">
        <label className="font-medium text-sm text-gray-900">Parcel Matching</label>
        <InfoPopover className="ml-2">
          <div className="gap-y-2 text-sm text-gray-900">
            <div className="mb-4 text-xs">Specify how properties will be matched to an assessor parcel record</div>
            <div className="mb-2 font-medium">Street Address</div>
            <div className="mb-4">Specify the property&apos;s address, city, state and zip code</div>
            <div className="mb-2 font-medium">Parcel Number</div>
            <div className="mb-4">
              {'Specify the parcel number and 5-digit county '}
              <a className="text-primary-dark" target="_blank" rel="noreferrer" href="https://transition.fcc.gov/oet/info/maps/census/fips/fips.txt">FIPS code</a>
            </div>
            <div className="mb-2 font-medium">New Build</div>
            <div>Specify the property&apos;s address, city, state and zip code but Honeycomb will not attempt to look-up parcel information because addresses are assumed to not yet be parcel-mapped</div>
          </div>
        </InfoPopover>
      </div>
      <fieldset>
        <div className="flex items-center my-4">
          <RadioInput
            checked={csvUploadOptions.parcelMatching === PARCEL_MATCHING_ADDRESS}
            id="street-address-input"
            onChange={() => { setUploadError(null); setCsvUploadOptions({ ...csvUploadOptions, parcelMatching: PARCEL_MATCHING_ADDRESS }); }}
          />
          <label htmlFor="street-address-input" className="pl-2 text-sm text-gray-900 cursor-pointer">Street Address</label>
        </div>
        <div className="flex items-center my-4">
          <RadioInput
            checked={csvUploadOptions.parcelMatching === PARCEL_MATCHING_PARCEL}
            id="parcel-number-input"
            onChange={() => { setUploadError(null); setCsvUploadOptions({ ...csvUploadOptions, parcelMatching: PARCEL_MATCHING_PARCEL }); }}
          />
          <label htmlFor="parcel-number-input" className="pl-2 text-sm text-gray-900 cursor-pointer">Parcel Number</label>
        </div>
        <div className="flex items-center mb-8">
          <RadioInput
            checked={csvUploadOptions.parcelMatching === PARCEL_MATCHING_NEW_BUILD}
            id="new-build-input"
            onChange={() => { setUploadError(null); setCsvUploadOptions({ ...csvUploadOptions, parcelMatching: PARCEL_MATCHING_NEW_BUILD }); }}
          />
          <label htmlFor="new-build-input" className="pl-2 text-sm text-gray-900 cursor-pointer">New Build</label>
        </div>
      </fieldset>
      {/* Temporarily commenting out until we implement rest of dispo functionality */}
      {/* <div className="flex flex-row items-center">
        <label className="font-medium text-sm text-gray-900">Transaction Type</label>
        <InfoPopover className="ml-2">
          <div className="gap-y-2 text-sm text-gray-900">
            <div className="mb-4 text-xs">Specify how you want to view the transaction workflow and dashboard</div>
            <div className="mb-2 font-medium">Acquisition</div>
            <div className="mb-4">Buyer will be able to see the acquisition dashboard and pipeline</div>
            <div className="mb-2 font-medium">Disposition</div>
            <div className="mb-4">Seller will be able to see the disposition dashboard and pipeline</div>
          </div>
        </InfoPopover>
      </div> */}
      {/* <div className="flex flex-col gap-y-2 mb-6 mt-3">
        <div className="flex gap-x-2">
          {Object.keys(TRANSACTION_TYPES).map(transactionType => (
            <div className="flex items-center" key={transactionType}>
              <RadioInput
                checked={csvUploadOptions.transactionType === transactionType}
                id={transactionType}
                onChange={() => setCsvUploadOptions({ ...csvUploadOptions, transactionType })}
              />
              <label onClick={() => setCsvUploadOptions({ ...csvUploadOptions, transactionType })} className="ml-1 text-sm text-gray-900 cursor-pointer">{capitalize(transactionType)}</label>
            </div>
          ))}
        </div>
      </div> */}
      <label className="font-medium text-sm text-gray-900">Workflow Template</label>
      <Dropdown
        width="w-full"
        className="mt-2 mb-8"
        optionsWidth="w-full"
        options={workflowDropdownOptions}
        selectedItem={workflowDropdownOptions?.find(({ id }) => id === workflowTemplateId)}
        setSelectedItem={({ id }) => setWorkflowTemplateId(id)}
      />
      {/* <div className="flex flex-col gap-y-2 mb-8">
        <div className="flex flex-row items-center">
          <label className="font-medium text-sm text-gray-900">Offer Information</label>
          <InfoPopover className="ml-2">
            <div className="gap-y-2 text-sm text-gray-900">
              <div className="mb-4 text-xs">Specify additional columns related to deal offers & offers negotation.</div>
              <div className="mb-2 font-medium">Offer Status</div>
              <div className="mb-4">
                Must be one of:
                {' '}
                <span className="font-bold">Rejected, Offered, Accepted</span>
              </div>
              <div className="mb-2 font-medium">Date Offer</div>
              <div className="mb-4">
                The date on which the offer was made.
              </div>

              <div className="mb-2 font-medium">Date Offer Expiration</div>
              <div className="mb-4">
                The expiration date of the offer, after which the offer is no longer valid.
              </div>

              <div className="mb-2 font-medium">Offer Price</div>
              <div>
                The price offered for the deal. This should be specified in the appropriate currency.
              </div>
            </div>
          </InfoPopover>
        </div>
        <div className="flex gap-x cursor-pointer" onClick={() => setCsvUploadOptions({ ...csvUploadOptions, includeOfferInformation: !csvUploadOptions.includeOfferInformation })}>
          {csvUploadOptions.includeOfferInformation ? <CheckboxFilled className="w-8 -ml-2" /> : <CheckboxEmpty className="w-8 -ml-2" />}
          <span className="text-base font-medium">Include Offer Information</span>
        </div>
      </div> */}
      <div className="flex flex-row items-center">
        <label className="font-medium text-sm text-gray-900">Import Type</label>
        <InfoPopover className="ml-2">
          <div className="gap-y-2 text-sm text-gray-900">
            <div className="mb-4 text-xs">Specify how the properties supplied in the CSV will be converted into deals</div>
            <div className="mb-2 font-medium">Individual Deals</div>
            <div className="mb-4">A deal will be created for each property</div>
            <div className="mb-2 font-medium">Portfolio Deal - Individually Modeled</div>
            <div className="mb-4">A single deal will be created that includes all properties and each property will be modeled individually</div>
            <div className="mb-2 font-medium">Portfolio Deal - Combined Model</div>
            <div>A single deal will be created that includes all properties under one model</div>
          </div>
        </InfoPopover>
      </div>
      <div>
        <Dropdown
          width="w-full"
          className="mt-2 mb-8"
          optionsWidth="w-full"
          options={IMPORT_TYPE_OPTIONS}
          selectedItem={IMPORT_TYPE_OPTIONS.find(ito => ito.id === csvUploadOptions.importType)}
          setSelectedItem={importTypeOption => { setUploadError(null); setCsvUploadOptions({ ...csvUploadOptions, importType: importTypeOption.id }); }}
        />
      </div>
      {(csvUploadOptions.importType === PORTFOLIO_DEAL_COMBINED || csvUploadOptions.importType === PORTFOLIO_DEAL_INDIVIDUAL) && (
        <>
          <label className="font-medium text-sm text-gray-900">Deal Name</label>
          <Input
            type="text"
            className="mt-2 mb-8 border rounded-md focus:outline-none"
            padding="py-2 px-4"
            width="w-full"
            value={csvUploadOptions.dealName}
            onChange={e => setCsvUploadOptions({ ...csvUploadOptions, dealName: parseEventValue(e) })}
          />
        </>
      )}
      {(csvUploadOptions.importType === PORTFOLIO_DEAL_COMBINED) && (
        <>
          <label className="font-medium text-sm text-gray-900">Purchase Price</label>
          <NumberInput
            className="w-full mt-2 mb-8 py-2 px-4 text-right border rounded-md focus:outline-none"
            decimalScale={0}
            value={csvUploadOptions.purchasePrice}
            prefix="$"
            onChange={e => setCsvUploadOptions({ ...csvUploadOptions, purchasePrice: parseEventValue(e) })}
          />
        </>
      )}
      {alert && <Alert className="whitespace-pre-line" {...alert} />}
      <div className="mt-12 flex justify-between items-center">
        <Button
          outlined
          disabled={!csvUploadOptions.importType || !csvUploadOptions.workflowTemplateId}
          label="Download CSV Template"
          onClick={downloadTemplate}
        />
        <Button
          filled
          disabled={!csvUploadOptions.portfolioId || !csvUploadOptions.importType || !csvUploadOptions.workflowTemplateId}
          label="Upload CSV"
          isLoading={isUpdatingDealCsv}
          onClick={(e) => {
            e.preventDefault();
            fileUploadRef.current.click();
          }}
        />
        <input
          id="fileUpload"
          type="file"
          className="hidden"
          ref={fileUploadRef}
          onChange={handleFileChange}
          // eslint-disable-next-line no-return-assign
          onClick={(e) => e.target.value = null}
        />
      </div>
    </div>
  );
}
