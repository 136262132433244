/* eslint-disable react/jsx-boolean-value */
import { identity, isEmpty, map, pick, pickBy, startCase, sum, merge } from 'lodash';
import { EXPENSE_METHOD_ITEMIZED, EXPENSE_METHOD_RATIO, isItemized } from './dcf';
import { ModelItemizedExpenseTable } from './ItemizedExpenseTable';
import RatioExpenseTable from './RatioExpenseTable';
import IncludeTooltip from '../IncludeTooltip';
import { ToggleWithLabels } from '../Toggle';
import { formatCurrency } from '../utils';

function ExpenseInfoField({ bold, border, children, label, perUnitValue, tooltip, value }) {
  let contents;
  if (perUnitValue) {
    contents = (
      <div className="flex gap-x-2">
        <div className="p-1 text-base text-gray-500">{`${perUnitValue} / Unit`}</div>
        <div className="p-1 text-base text-gray-900">{value}</div>
      </div>
    );
  } else if (value) {
    contents = <div className="p-1 text-base text-gray-900">{value}</div>;
  } else {
    contents = children;
  }
  let labelContents;
  if (tooltip) {
    labelContents = (
      <IncludeTooltip tooltipText={tooltip}>{label}</IncludeTooltip>
    );
  } else {
    labelContents = label;
  }

  return (
    <div className={`flex flex-row justify-between place-items-center px-4 py-1 ${border || ''}`}>
      <div className={`text-sm text-gray-500 ${bold ? 'font-bold' : 'font-medium'}`}>{labelContents}</div>
      {contents}
    </div>
  );
}

const IN_PLACE_EXPENSE_ITEMS = [
  'expenseCable',
  'expenseContractServices',
  'expenseElectric',
  'expenseFurnitureReplacement',
  'expenseGas',
  'expenseGasElectric',
  'expenseGeneralAdministrative',
  'expenseInsurance',
  'expenseLandLease',
  'expenseLandscaping',
  'expenseLeasingCommission',
  'expenseLegal',
  'expenseMiscellaneous',
  'expenseOnsiteManager',
  'expensePayroll',
  'expensePestControls',
  'expensePool',
  'expensePropertyManagementFee',
  'expensePropertyTax',
  'expenseRepairsMaintenance',
  'expenseTotalUtilities',
  'expenseTrash',
  'expenseWaterSewer',
  'associationFee',
  'associationFee2',
];

function InPlaceExpenses({ financials, listing }) {
  const inPlaceFinancials = pickBy(pick(financials, IN_PLACE_EXPENSE_ITEMS), identity);
  const inPlaceHoa = pickBy(pick(listing?.details || {}, IN_PLACE_EXPENSE_ITEMS), identity);
  const inPlaceExpenses = merge(inPlaceFinancials, inPlaceHoa);
  const expenseSum = sum(Object.values(inPlaceExpenses));

  const totalOperatingExpenses = financials?.expenseTotalOperatings || (expenseSum > 0 ? expenseSum : null);
  if (totalOperatingExpenses && (totalOperatingExpenses > expenseSum) && !isEmpty(inPlaceExpenses)) {
    // if there is itemized expense data and it does not match the total, group the difference into an 'Other' category
    inPlaceExpenses.expenseMiscellaneous = totalOperatingExpenses - expenseSum;
  }

  return (
    <>
      <ExpenseInfoField label="In-Place Expenses (based on MLS data)" bold="true" />
      {!totalOperatingExpenses && <ExpenseInfoField label="No expense data" />}
      {map(inPlaceExpenses, (value, key) => <ExpenseInfoField key={key} label={startCase(key)} value={formatCurrency(value)} />)}
      {totalOperatingExpenses && <ExpenseInfoField label="Total" value={formatCurrency(totalOperatingExpenses)} border="border-t-2" />}
    </>
  );
}

export default function Expenses({ cashFlows, dcfParams, financials, onChange, setActiveParameterTab, listing }) {
  const onCheckItemized = () => {
    onChange({
      target: {
        name: 'expenseMethod',
        value: isItemized(dcfParams) ? EXPENSE_METHOD_RATIO : EXPENSE_METHOD_ITEMIZED,
      },
    });
  };

  return (
    <div className="bg-white">
      <div className="overflow-x-auto pt-2 bg-gray-50">
        <ToggleWithLabels className="ml-5" label1="Ratio" label2="Itemized" checked={isItemized(dcfParams)} onClick={onCheckItemized} />
        {!isItemized(dcfParams)
          ? (
            <RatioExpenseTable
              cashFlows={cashFlows}
              dcfParams={dcfParams}
              onChange={onChange}
            />
          ) : (
            <ModelItemizedExpenseTable
              cashFlows={cashFlows}
              dcfParams={dcfParams}
              setActiveParameterTab={setActiveParameterTab}
              onChange={onChange}
              canEditPortfolio={true}
            />
          )}
      </div>
      <div className="px-6 py-6 w-96">
        <InPlaceExpenses financials={financials} listing={listing} />
      </div>
    </div>
  );
}
