export const HOME_MODELS_TAG = 'HomeModels';

export const SUBDIVISIONS_TAG = 'subdivisions';

export const HOME_BUILDERS_TAG = 'HomeBuilders';

export const HOME_MODEL_IMAGES_TAG = 'HomeModelImages';

export const IMAGES_TAG = 'Images';

export const OFFERS_TAG = 'Offers';

export const DEAL_CONTEXT_TAG = 'DealContext';

export const OFF_MARKET_LISTINGS_TAG = 'OffMarketListings';
