import { useState, forwardRef } from 'react';
import { sortBy, union, without } from 'lodash';
import IndeterminateCheckbox from 'components/shared/IndeterminateCheckbox';
import Modal from 'components/Modal';
import { TOGGLE_SECTIONS } from 'components/pipeline/PipelineColumns';
import {
  closestCenter,
  DndContext,
  DragOverlay,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

function ToggleSection({ label, columns, columnNameMap, columnVisibility, setColumnVisibility, toggleColumnsVisibility }) {
  const checkedColumns = columns.filter(({ id }) => columnVisibility[id]).length;
  const allChecked = checkedColumns === columns.length;
  const isIndeterminate = (checkedColumns > 0) && !allChecked;

  const onToggleSection = () => {
    const columnIds = columns.map(c => c.id);
    toggleColumnsVisibility(columnIds, !allChecked);
  };

  return (
    <div>
      <div className="py-3 flex justify-between items-center gap-x-4">
        <div className="text-xs text-gray-500 font-medium uppercase">{label}</div>
        <IndeterminateCheckbox
          indeterminate={isIndeterminate}
          checked={checkedColumns > 0}
          onChange={onToggleSection}
        />
      </div>
      {columns.map(({ id: columnId }) => (
        <div
          key={columnId}
          className="flex justify-between cursor-pointer py-2 hover:bg-gray-50"
          onClick={() => setColumnVisibility(columnId)}
        >
          <div className="text-sm">{columnNameMap[columnId]}</div>
          <input type="checkbox" checked={columnVisibility[columnId] || false} readOnly />
        </div>
      ))}
    </div>
  );
}

export const Item = forwardRef(({ column, ...props }, ref) => (
  <div className="p-3 border rounded hover:bg-gray-100" {...props} ref={ref}>{column.header}</div>
));

export function SortableItem({ column }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: column.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Item column={column} ref={setNodeRef} style={style} {...attributes} {...listeners} />
  );
}

function RearrangeColumnsSection({ columns, columnVisibility, columnOrder, setColumnOrder }) {
  const visibleColumns = sortBy(
    columns.filter(column => columnVisibility[column.id]),
    column => columnOrder.indexOf(column.id),
  ).filter(column => column.id !== 'menu'); // do not allow sorting menu field so it stays at end

  const [activeId, setActiveId] = useState(null);

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setColumnOrder(previousColumnOrder => {
        const oldIndex = previousColumnOrder.indexOf(active.id);
        const newIndex = previousColumnOrder.indexOf(over.id);

        return arrayMove(previousColumnOrder, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  };

  return (
    <div>
      <div className="text-sm text-gray-700">Column Order</div>
      <div className="overflow-y-scroll pr-6" style={{ height: 'calc(100% - 20px)' }}>
        <DndContext
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={visibleColumns}
            strategy={verticalListSortingStrategy}
          >
            {visibleColumns.map(column => <SortableItem key={column.id} column={column} />)}
          </SortableContext>
          <DragOverlay>
            {activeId ? <Item column={columns.find(column => column.id === activeId)} /> : null}
          </DragOverlay>
        </DndContext>
      </div>
    </div>
  );
}

export default function PipelineConfigModal(props) {
  const {
    dealStage,
    setShowPipelineConfigModal,
    columns,
    columnVisibility,
    setColumnVisibility,
    columnOrder,
    setColumnOrder,
    pipelineTableConfig,
    updatePipelineConfig,
  } = props;

  const toggleableColumns = columns.filter(c => c.enableHiding !== false);
  const columnNameMap = toggleableColumns.reduce((map, c) => ({ ...map, [c.id]: c.header }), {});

  const toggleColumnsVisibility = (columnIds, visible) => {
    const prevVisibleColumns = pipelineTableConfig[dealStage]?.visibleColumns || [];
    const visibleColArray = visible ? union(prevVisibleColumns, columnIds) : without(prevVisibleColumns, ...columnIds);
    updatePipelineConfig('visibleColumns', visibleColArray);
  };

  return (
    <Modal title="Configure Pipeline" show onClose={() => setShowPipelineConfigModal(false)}>
      <div className="w-max" style={{ height: 'calc(100vh - 136px)' }}>
        <div className="w-full h-full flex">
          <div>
            <div className="text-sm text-gray-700">Toggle Column Visibility</div>
            <div className="overflow-y-scroll pr-6" style={{ height: 'calc(100% - 20px)' }}>
              {TOGGLE_SECTIONS.map((toggleSection, index) => (
                <ToggleSection
                  key={toggleSection}
                  index={index}
                  label={toggleSection}
                  columns={toggleableColumns.filter(c => c.meta?.toggleSection === toggleSection)}
                  columnNameMap={columnNameMap}
                  columnVisibility={columnVisibility}
                  setColumnVisibility={setColumnVisibility}
                  toggleColumnsVisibility={toggleColumnsVisibility}
                />
              ))}
            </div>
          </div>
          <RearrangeColumnsSection
            columns={columns}
            columnVisibility={columnVisibility}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
          />
        </div>
      </div>
    </Modal>
  );
}
