/* eslint-disable react/jsx-no-comment-textnodes */
import { useState } from 'react';
import { capitalize } from 'lodash';
import Alert from 'components/Alert';
import Button from 'components/shared/NewButton';
import { parseEventValue, splitCamelCase } from 'components/utils';
import { FormField } from 'components/Form';
import { useUpdateDealMutation } from 'redux/apiSlice';
import { useUserOptions } from 'components/DealConstruction/dealConstruction';
import { ACQUISITION } from 'components/constants';

// describes how the form fields are grouped / ordered in the UI
// NOTE: These have to be kept in sync with the TRANSACTION_INFO_FIELDS in models/deal
const ACQUISITION_FORM_STRUCTURE = [
  ['General', [
    'clientName',
    'clientEmail',
    'entityName',
    'titleContact',
  ]],
  ['Offer', [
    'offerExpirationDate',
    'offerDate',
    'offerPrice',
    'offerResponseDate',
    'offerStatus',
  ]],
  ['Dates', [
    'acceptanceDate',
    'inspectionDate',
    'appraisalDate',
    'dueDiligenceEndDate',
    'closeOfEscrowDate',
  ]],
  ['Prices', [
    'listPrice',
    'contractPrice',
    'closePrice',
  ]],
  ['Financial', [
    'buyerBrokerCommissionValue',
    'commissionDescription',
    'commissionReferralValue',
    'earnestMoney',
    'financingContingencies',
    'loanType',
    'repairConcessionsValue',
    'sellerBrokerCommissionValue',
    'sellerConcessionsValue',
  ]],
];

const DISPOSITION_FORM_STRUCTURE = [
  ['General', [
    'clientName',
    'clientEmail',
    'entityName',
    'mechanicalLockboxCode',
    'occupancyStatus',
    'titleContact',
  ]],
  ['Dates', [
    'assignedDate',
    'listingDate',
    'acceptanceDate',
    'inspectionDate',
    'appraisalDate',
    'dueDiligenceEndDate',
    'closeOfEscrowDate',
  ]],
  ['Prices', [
    'proposedListPrice',
    'listPrice',
    'contractPrice',
    'closePrice',
  ]],
  ['Financial', [
    'buyerBrokerCommissionValue',
    'commissionDescription',
    'commissionReferralValue',
    'earnestMoney',
    'financingContingencies',
    'loanType',
    'repairConcessionsValue',
    'sellerBrokerCommissionValue',
    'sellerConcessionsValue',
  ]],
  ['MLS', [
    'mlsId',
    'mlsPublicRemarks',
    'mlsPrivateRemarks',
  ]],
];

export const PERCENT_OR_CURRENCY_FIELDS = [
  'buyerBrokerCommissionValue',
  'commissionReferralValue',
  'repairConcessionsValue',
  'sellerBrokerCommissionValue',
  'sellerConcessionsValue',
];

const SELECT_FIELD_OPTIONS = {
  loanType: [
    ['select_loan_type', 'Select Loan Type', true],
    ['fha', 'FHA'],
    ['va', 'VA'],
    ['usda', 'USDA'],
    ['conventional', 'Conventional'],
    ['cash', 'Cash'],
    ['other', 'Other'],
  ],
  occupancyStatus: [
    ['vacant', 'Vacant'],
    ['owner_occupied', 'Owner Occupied'],
    ['tenant_occupied', 'Tenant Occupied'],
    ['unauthorized_occupied', 'Occupied - Unauthorized'],
    ['eviction_occupied', 'Occupied - Eviction'],
  ],
};

const CURRENCY_FIELDS = [
  'closePrice',
  'contractPrice',
  'listPrice',
  'proposedListPrice',
];

const INPUT_TYPE_MAP = {
  boolean: 'checkbox',
  currency: 'currency',
  date: 'date',
  float: 'number',
  integer: 'number',
  password: 'password',
};

export function TransactionInputField({ name, onChange, localTransactionInfo, type }) {
  const fieldType = CURRENCY_FIELDS.includes(name) ? 'currency' : type;

  // Check if the current field is a select field and render a dropdown if it is
  if (SELECT_FIELD_OPTIONS[name]) {
    return (
      <FormField
        className="grow mt-3"
        label={capitalize(splitCamelCase(name))}
        name={name}
        type="select"
        options={SELECT_FIELD_OPTIONS[name]}
        value={localTransactionInfo[name]}
        onChange={onChange}
      />
    );
  }

  if (PERCENT_OR_CURRENCY_FIELDS.includes(name)) {
    // for percent_or_currency fields, show toggle for $/% as well as input field
    const baseName = name.substring(0, name.length - 5);
    const typeFieldName = `${baseName}Type`;
    return (
      <>
        <div className="mt-3 mb-1 text-xs font-medium text-tertiary-darker tracking-wide uppercase">
          {capitalize(splitCamelCase(baseName))}
        </div>
        <div className="flex items-center gap-x-2">
          <FormField
            label=" "
            name={typeFieldName}
            toggleLabels={['$', '%']}
            onChange={onChange}
            type="checkbox"
            value={localTransactionInfo[typeFieldName]}
          />
          <FormField
            className="grow"
            label=" "
            name={name}
            onChange={onChange}
            type={fieldType}
            value={localTransactionInfo[name]}
          />
        </div>
      </>
    );
  }

  return (
    <FormField
      className="grow mt-3"
      label={capitalize(splitCamelCase(name))}
      name={name}
      onChange={onChange}
      type={INPUT_TYPE_MAP[fieldType] || 'text'}
      value={localTransactionInfo[name]}
    />
  );
}

function TransactionFormSection({ label, fields, onChange, localTransactionInfo, transactionInfo }) {
  return (
    <div className="mt-3">
      <div className="my-6 text-sm text-gray-600 font-medium">{label}</div>
      {fields.map(field => (
        <TransactionInputField
          key={field}
          name={field}
          type={transactionInfo[field].type}
          onChange={onChange}
          localTransactionInfo={localTransactionInfo}
        />
      ))}
    </div>
  );
}

const cleanTransactionInfo = (info) => Object.fromEntries(
  Object.entries(info).map(([key, { value }]) => [key, value]),
);

export default function EditTransactionInfo({ deal, onClose = null }) {
  const [baseDeal, setBaseDeal] = useState(deal);
  const { transactionInfo, transactionType } = baseDeal;
  const [alert, setAlert] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [localTransactionInfo, setLocalTransactionInfo] = useState({
    ...cleanTransactionInfo(transactionInfo),
    name: baseDeal.name,
  });
  const [updateDealMutation] = useUpdateDealMutation();
  const userOptions = useUserOptions({ includeUsers: [baseDeal?.leadId] });
  userOptions.unshift(['', '', true]);

  const onChange = (event) => {
    const fieldName = event.target.name;
    const newVal = parseEventValue(event);
    setLocalTransactionInfo({
      ...localTransactionInfo,
      [fieldName]: newVal,
    });
  };

  const onCancel = () => {
    if (onClose) {
      onClose();
    } else {
      setLocalTransactionInfo({ ...cleanTransactionInfo(transactionInfo), name: baseDeal.name });
    }
  };

  const onSubmit = async () => {
    setIsSaving(true);
    setAlert(null);

    const response = await updateDealMutation({ id: baseDeal.id, ...localTransactionInfo });
    setIsSaving(false);

    if (response.error) {
      console.error(response.error);
      setAlert({ type: 'danger', text: 'There was an error updating the transaction info' });
    } else {
      setBaseDeal(response.data.data.attributes);
      setAlert({ type: 'success', text: 'Deal information has been updated.' });
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col">
        <FormField
          className="grow mt-2"
          label="Deal Name"
          name="name"
          onChange={onChange}
          type="text"
          value={localTransactionInfo?.name || ''}
        />
        <FormField
          className="grow mt-2"
          name="leadId"
          label="Lead"
          type="select"
          options={userOptions}
          value={localTransactionInfo?.leadId ? localTransactionInfo.leadId : baseDeal.leadId}
          onChange={onChange}
        />
        {(transactionType === ACQUISITION ? ACQUISITION_FORM_STRUCTURE : DISPOSITION_FORM_STRUCTURE).map(section => (
          <TransactionFormSection
            key={section[0]}
            label={section[0]}
            fields={section[1]}
            onChange={onChange}
            localTransactionInfo={localTransactionInfo}
            transactionInfo={transactionInfo}
          />
        ))}
        {alert && <Alert className="mt-6" {...alert} />}
        <div className="mt-8 flex justify-around">
          <Button filled isLoading={isSaving} label="Update" onClick={onSubmit} />
          <Button outlined label="Cancel" onClick={onCancel} />
        </div>
      </div>
    </div>
  );
}
