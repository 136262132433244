import { sum } from 'lodash';
import { formatCurrency, formatPercentage } from '../utils';
import { calcSourcesAndUses } from 'components/dcf/dcf';

function SectionLabel({ label }) {
  return (
    <div className="mb-4 px-6 py-2 text-sm text-gray-700 uppercase tracking-wider bg-gray-100 text-left">
      {label}
    </div>
  );
}

function SectionHeader({ label }) {
  return (
    <div className="flex flex-row gap-x-4 mb-2 justify-start text-sm font-medium pb-2">
      <div className="w-2/5 text-xs text-gray-500 uppercase tracking-wide underline px-6">{label}</div>
      <div className="flex justify-end w-3/5">
        <div className="w-24 text-xs text-gray-500 uppercase tracking-wide underline text-right">$</div>
        <div className="w-24 text-xs text-gray-500 uppercase tracking-wide underline text-right px-6">%</div>
      </div>
    </div>
  );
}

function SummaryItem({ label, value, isTotal = false, border = false }) {
  return (
    <div className={border ? 'flex flex-row gap-x-4 text-sm border-t mt-4' : 'flex flex-row gap-x-4 pt-2 mb-1 text-sm'}>
      <div className={isTotal ? 'w-3/5 text-gray-500 font-medium text-left px-6 my-4' : 'w-2/5 text-gray-400 text-left px-6'}>{label}</div>
      <div className={isTotal ? 'w-2/5 my-4' : 'w-3/5'}>{value}</div>
    </div>
  );
}

function MetricRow({ label, unlevered, levered, isTotal, border }) {
  return (
    <SummaryItem
      label={label}
      value={(
        <div className="flex justify-end">
          <div className="w-24 text-sm text-gray-700 text-right">{unlevered}</div>
          <div className="w-24 text-sm text-gray-700 text-right pr-6">{levered}</div>
        </div>
      )}
      isTotal={isTotal}
      border={border}
    />
  );
}

export default function Sources({ cashFlows }) {
  const {
    purchasePrice,
    acquisitionCost,
    followOnCapitalProjects,
    originationFee,
    totalFinancing,
    totalAcquisitionCapital,
    totalUses,
    totalEquity,
    totalSources,
  } = calcSourcesAndUses(cashFlows);

  return (
    <div className="w-full bg-white pb-12">
      <SectionLabel label="Capital Stack / Sources & Uses (Acquisition)" />
      <div className="flex border-b">
        <div className="w-1/2 border-r flex flex-col justify-between">
          <div>
            <SectionHeader label="Sources" />
            <MetricRow label="Total Equity" unlevered={formatCurrency(totalEquity)} levered={formatPercentage(totalEquity / totalSources)} />
            <MetricRow label="Total Financing" unlevered={formatCurrency(totalFinancing)} levered={formatPercentage(totalFinancing / totalSources)} />
          </div>
          <MetricRow label="Total Sources" unlevered={formatCurrency(totalSources)} levered={formatPercentage((totalFinancing / totalSources) + (totalEquity / totalSources))} isTotal border />
        </div>
        <div className="w-1/2 flex flex-col justify-between">
          <div>
            <SectionHeader label="Uses" />
            <MetricRow label="Purchase Price" unlevered={formatCurrency(purchasePrice)} levered={formatPercentage(purchasePrice / totalUses)} />
            <MetricRow label="Acquisition Costs" unlevered={formatCurrency(acquisitionCost)} levered={formatPercentage(acquisitionCost / totalUses)} />
            <MetricRow label="Acq. Origination Fees" unlevered={formatCurrency(originationFee)} levered={formatPercentage(originationFee / totalUses)} />
            <hr className="mt-4" />
            <MetricRow label="Total Acquisition Capital" unlevered={formatCurrency(totalAcquisitionCapital)} levered={formatPercentage(totalAcquisitionCapital / totalUses)} isTotal />
            <MetricRow label="Follow On Capital Projects" unlevered={formatCurrency(followOnCapitalProjects)} levered={formatPercentage(followOnCapitalProjects / totalUses)} />
          </div>
          <MetricRow label="Total Uses" unlevered={formatCurrency(totalUses)} levered={formatPercentage((totalAcquisitionCapital / totalUses) + (followOnCapitalProjects / totalUses))} isTotal border />
        </div>
      </div>
    </div>
  );
}
