import Chip from 'components/shared/Chip';
import { Chevron, Pencil } from 'components/icons';

export default function Chips() {
  return (
    <div className="grid grid-rows-3 grid-cols-4 grid-flow-row gap-4 mb-6">
      <Chip label="Default" />
      <Chip label="Default" trailingIcon={<Chevron direction="down" />} />
      <Chip label="Default" leadingIcon={<Pencil />} />
      <Chip label="Default" leadingIcon={<Pencil />} trailingIcon={<Chevron direction="down" />} />
      <Chip label="Selected" selected />
      <Chip label="Selected" selected trailingIcon={<Chevron direction="down" />} />
      <Chip label="Selected" selected leadingIcon={<Pencil />} />
      <Chip label="Selected" selected leadingIcon={<Pencil />} trailingIcon={<Chevron direction="down" />} />
      <Chip label="Disabled" disabled />
      <Chip label="Disabled" disabled trailingIcon={<Chevron direction="down" />} />
      <Chip label="Disabled" disabled leadingIcon={<Pencil />} />
      <Chip label="Disabled" disabled leadingIcon={<Pencil />} trailingIcon={<Chevron direction="down" />} />
    </div>
  );
}
