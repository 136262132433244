import { closestCenter, DndContext, DragOverlay, useDndMonitor } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { flexRender } from '@tanstack/react-table';
import cx from 'classnames';
import { sortBy } from 'lodash';
import { forwardRef, startTransition, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import useLocalStorage from 'hooks/useLocalStorage';
import { DragIndicator } from 'components/icons';
import {
  useActivePresetId,
  useIsColumnOrderDraggable,
  useOrderConfigurableLeafColumns,
  useOrderDraggableRange,
  useSetColumnOrder,
  useVisibleOrderConfigurableLeafHeaders,
} from '../DataTableContext';
import { orderStorageKey, STORAGE_VERSION } from './utils';

const Item = forwardRef(({ column, className, children, ...props }, ref) => (
  <li
    {...props}
    ref={ref}
    className={cx(
      'group flex items-center gap-x-0.5 px-0.5 py-1 text-neutral-dark text-body-md font-medium select-none border rounded hover:bg-primary-hover',
      'aria-disabled:cursor-not-allowed aria-disabled:bg-neutral-100',
      className,
    )}
  >
    <DragIndicator className="h-4 w-auto fill-current text-neutral-dark/75 group-aria-disabled:invisible" />
    <div className="inline-flex">
      {children}
    </div>
  </li>
));

/**
 * @param {import('@dnd-kit/core').UniqueIdentifier} id
 * @param {import('@tanstack/react-table').Header} header
 * @param {import('react').ReactNode} children
 * @param {import('react').ComponentPropsWithoutRef<typeof Item>} props
 */
function SortableItem({ id, header, children, ...props }) {
  const disabled = !useIsColumnOrderDraggable({ columnId: header.column.id });
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    active,
  } = useSortable({ id, disabled, data: { header } });

  /** @type {import('react').CSSProperties} */
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Item
      {...props}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      column={header.column}
      style={style}
      className={cx(!disabled && 'bg-white cursor-grab', isDragging && 'invisible')}
      inert={(active !== null) ? '' : undefined}
    >
      {children}
    </Item>
  );
}

/**
 * @param {import('@dnd-kit/core').DragOverlayProps} props
 */
function ActiveItemOverlay({ ...props }) {
  const [activeItem, setActiveItem] = useState();

  /** @type {import('@dnd-kit/core').DndMonitorListener}  */
  const listener = useMemo(() => ({
    onDragStart({ active }) {
      setActiveItem(active);
    },
    onDragEnd() {
      setActiveItem(null);
    },
  }), []);
  useDndMonitor(listener);

  return createPortal(
    <DragOverlay {...props}>
      {activeItem ? (
        <Item className="cursor-grabbing bg-primary-hover" column={activeItem.data.current.header.column}>
          {flexRender(
            activeItem.data.current.header.column.columnDef.header,
            activeItem.data.current.header.getContext(),
          )}
        </Item>
      ) : null}
    </DragOverlay>,
    document.body,
  );
}

export default function ColumnOrder() {
  const orderConfigurableLeafColumns = useOrderConfigurableLeafColumns();
  const setColumnOrder = useSetColumnOrder();
  const [draggableStart, draggableEnd] = useOrderDraggableRange();

  const [presetId] = useActivePresetId();
  const [storedOrder, setStoredOrder] = useLocalStorage(orderStorageKey({ presetId }), null, STORAGE_VERSION);
  const currOrder = useMemo(() => (
    storedOrder?.length ? storedOrder : orderConfigurableLeafColumns.map(({ id }) => id)
  ), [storedOrder, orderConfigurableLeafColumns]);

  /** @type {import('@dnd-kit/core').DndContextProps['onDragEnd']} */
  const handleDragEnd = useCallback(({ active, over }) => {
    // not sure why but over is sometimes null
    if (!over) {
      return;
    }

    // when dragged to first/last, force the item to the first/last available idx
    const idxOverride = {
      [active.data.current.sortable.items[0]]: 0,
      [active.data.current.sortable.items.at(-1)]: Number.POSITIVE_INFINITY,
    };

    const oldIndex = currOrder.indexOf(active.data.current.header.column.id);

    const newIndex = Math.max(
      draggableStart,
      Math.min(
        draggableEnd,
        idxOverride[over.id] ?? currOrder.indexOf(over.data.current.header.column.id),
      ),
    );

    // check index instead of active/over id
    // to cover the case where the visible order is unchanged but the actual order is different
    if (oldIndex === newIndex) {
      return;
    }

    const updatedOrder = arrayMove(currOrder, oldIndex, newIndex);
    setStoredOrder(updatedOrder);
    startTransition(() => {
      setColumnOrder(updatedOrder);
    });
  }, [draggableStart, draggableEnd, currOrder, setColumnOrder, setStoredOrder]);

  const headers = useVisibleOrderConfigurableLeafHeaders();
  const displayOrder = storedOrder?.length ? sortBy(headers, (header) => currOrder.indexOf(header.column.id)) : headers;

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <ol className="flex flex-col aria-pressed:cursor-grabbing">
        <SortableContext
          items={displayOrder}
          strategy={verticalListSortingStrategy}
        >
          {displayOrder.map((header) => (
            <SortableItem key={header.id} id={header.id} header={header}>
              {flexRender(header.column.columnDef.header, header.getContext())}
            </SortableItem>
          ))}
        </SortableContext>
        <ActiveItemOverlay wrapperElement="ul" className="bg-white" />
      </ol>
    </DndContext>
  );
}
