import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchScenarioQuery } from 'redux/apiSlice';
import { clearDealNavigationModal, downloadModelForScenario } from 'actions/deal_navigation';
import Alert from 'components/Alert';
import Button from 'components/Button';
import DashboardModal from 'components/dashboard/DashboardModal';

export default function DownloadModelModal() {
  const dispatch = useDispatch();
  const { scenarioId, deal, alert, saving } = useSelector(state => state.navigation.modal);
  const [doDownload, setDoDownload] = useState(false);
  const { data: scenario } = useFetchScenarioQuery({ id: scenarioId, dealId: deal.id });

  useEffect(() => {
    if (doDownload) {
      const promise = dispatch(downloadModelForScenario({ deal, scenario }));
      return () => {
        promise.abort();
      };
    }

    return () => {};
  }, [doDownload]);
  const onClose = () => dispatch(clearDealNavigationModal());

  return (
    <DashboardModal
      title="Download Model"
      action={(
        <Button
          confirm
          text="Download"
          className="font-semibold border border-black border-opacity-12"
          shadow={false}
          submit={false}
          isLoading={saving}
          onClick={() => setDoDownload(true)}
        />
      )}
      onClose={onClose}
    >
      {alert && <Alert {...alert} />}
    </DashboardModal>
  );
}
