import { formatDate, formatInteger, titleCase } from 'components/utils';

const parcelTypes = {
  SF: 'Single Family',
  MF: 'Multi Family',
  MU: 'Multi Purpose',
  CO: 'Commercial',
  OT: 'Other',
};

function OffMarketRow({ item, style, selectedItem, setSelectedItem }) {
  const {
    id,
    address,
    bathCount,
    bedCount,
    buildingSqFt,
    city,
    lastSaleDate,
    lotSizeSqFt,
    ownerAssessor,
    ownerUnmask,
    state,
    trulivParcelCode,
    unitsCount,
    yearBuilt,
    yearBuiltEffective,
  } = item;

  let ownerType = 'Unknown'
  if (ownerAssessor?.[0]?.ownerType) {
    ownerType = ownerAssessor[0].ownerType;
  } else if (ownerUnmask?.[0]?.ownerType) {
    if (ownerUnmask[0].ownerType === 'P') {
      ownerType = 'Individual';
    } else if (ownerUnmask[0].ownerType === 'I') {
      ownerType = 'Institution';
    }
  }

  let unitValue = unitsCount || '';
  if (trulivParcelCode === 'SF') {
    unitValue = `${bedCount || '-'} bd / ${bathCount || '-'} ba`
  }

  return (
    <div data-id={id} className={`item w-full flex items-center p-2 text-xs text-gray-600 hover:bg-blue-50 cursor-pointer ${id === selectedItem?.id ? 'bg-blue-50' : 'bg-white'}`} style={style} onClick={() => setSelectedItem(item)}>
      <div data-name="address" className="w-3/12 mx-2 flex flex-col justify-start items-start font-normal text-xs">
        <p>{ titleCase(address) }</p>
        <p>{`${titleCase(city)}, ${state}`}</p>
      </div>
      <div data-name="type" className="w-2/12 mx-2 flex justify-start items-center">{parcelTypes[trulivParcelCode]}</div>
      <div data-name="numUnits" className="w-1/12 mx-2 flex justify-end items-center">{unitValue}</div>
      <div data-name="rsf" className="w-1/12 flex flex-col justify-center items-end font-normal mx-2 text-right">
        <p>{ formatInteger(buildingSqFt) || ''}</p>
        <p className="text-xs">{ (buildingSqFt && lotSizeSqFt) ? `${(buildingSqFt / lotSizeSqFt).toFixed(2)}x` : ''}</p>
      </div>
      <div className="w-1/12 mx-2 flex flex-col justify-center items-end font-normal">
        <p>{yearBuilt || ''}</p>
        <p>{yearBuiltEffective || ''}</p>
      </div>
      <div data-name="lastTransfer" className="w-2/12 mx-2 flex justify-start items-center">{ lastSaleDate ? formatDate(lastSaleDate, 'MM/d/yyyy') : '' }</div>
      <div data-name="ownerType" className="w-2/12 mx-2 flex justify-start items-center">{titleCase(ownerType)}</div>
    </div>
  );
}

export default OffMarketRow;
