import { createColumnHelper } from '@tanstack/react-table';
import cx from 'classnames';
import { DEAL_STATE_DEAD } from 'components/constants';
import DelayedLoadingIndicator from 'components/shared/DelayedLoadingIndicator';
import BooleanFilterChip from 'components/shared/newfilters/BooleanFilterChip';
import FilterToggle from 'components/shared/newfilters/FilterToggle';
import ReactTableColumnFilter from 'components/shared/newfilters/ReactTableColumnFilter';
import ReactTableFilter from 'components/shared/newfilters/ReactTableFilters';
import { CurrencyCell, EnumCell, InputCell, IntegerCell, PercentCell } from 'components/shared/Table/Cells';
import DataTable from 'components/shared/Table/DataTable';
import { DataTableContent } from 'components/shared/Table/DataTableContext';
import { dataTableMeta, enableEditing } from 'components/shared/Table/table.helpers';
import { isNil, set } from 'lodash';
import { useMemo } from 'react';
import BathsCell from './BathsCell';
import DeliveryScheduleHeader from './DeliveryScheduleHeader';
import HideEmptyColumn from './HideEmptyColumn';
import HideEmptyDeliveryDates from './HideEmptyDeliveryDates';
import HomeModelDealStatusCell from './HomeModelDealStatusCell';
import HomeModelFutureDeliveriesCell from './HomeModelFutureDeliveriesCell';
import HomeModelLinkCell from './HomeModelLinkCell';
import HomeModelThumbnailCell from './HomeModelThumbnailCell';
import MuteZero from './MuteZero';
import OfferBidInputCell from './OfferBidInputCell';
import OfferRentFooter from './OfferRentFooter';
import OfferYieldCell from './OfferYieldCell';
import OfferYieldFooter from './OfferYieldFooter';
import PurchasePriceFooter from './PurchasePriceFooter';
import QtyEditCell from './QtyEditCell';
import { deliveryScheduleColumnId, deliveryScheduleMetaKey, unreviewedStageSymbol, useEnrichedHomeModels, useHomeModelDeliveryDates } from './subdivision';
import TotalQtyCell from './TotalQtyCell';
import TotalQtyFooter from './TotalQtyFooter';

const columnHelper = createColumnHelper();

const homeModelsTableHiddenColumns = [
  columnHelper.accessor('bathroomsHalf', {}),
  columnHelper.accessor('futureDeliveries', {}),
  columnHelper.accessor('totalAvailable', {}),
  columnHelper.accessor(({ stage }) => isNil(stage) || stage === DEAL_STATE_DEAD, { id: 'isDead' }),
  columnHelper.accessor(({ offerCreated }) => isNil(offerCreated) || offerCreated === false, { id: 'offerNotCreated' }),
  columnHelper.accessor('offerCreated', {}),
  columnHelper.accessor('qtyOfferedTotal', {}),
  columnHelper.accessor('offerDcfParams', {}),
];
const hiddenColumnIds = Object.freeze(['bathroomsHalf', 'futureDeliveries', 'totalAvailable', 'isDead', 'offerNotCreated', 'offerCreated', 'qtyOfferedTotal', 'offerDcfParams']);
const hiddenColumnVisibilityInitState = Object.freeze(Object.fromEntries(hiddenColumnIds.map((colId) => [colId, false])));

const deliverySchedulePlaceholderColumnId = deliveryScheduleColumnId('placeholder');
const deliveryScheduleNowColumnId = deliveryScheduleColumnId('now');

const deliveryScheduleMutator = (row, value, { columnDef: { meta: { [deliveryScheduleMetaKey]: date } } }) => {
  set(row, ['deliverySchedule', date], value);
};

const homeModelsOfferTableColumns = [
  columnHelper.accessor('offerBidPrice', {
    header: 'Price',
    footer: PurchasePriceFooter,
    cell: CurrencyCell,
    meta: {
      // set min-w to prevent input box dollar sign from covering digits
      className: 'min-w-[14ch]',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: OfferBidInputCell, inputType: 'currency', min: 0 }),
    },
  }),
  columnHelper.accessor('offerUnderwritingRent', {
    header: 'UW Rent',
    footer: OfferRentFooter,
    cell: CurrencyCell,
    meta: {
      // set min-w to prevent input box dollar sign from covering digits
      className: 'min-w-[8ch]',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'currency', min: 0 }),
    },
  }),
  // TODO: make sortable
  columnHelper.display({
    id: 'offerYield',
    header: 'Yield',
    footer: OfferYieldFooter,
    cell: OfferYieldCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor(() => undefined, {
    id: deliveryScheduleNowColumnId,
    header: 'Qty (now)',
    footer: TotalQtyFooter,
    meta: {
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: QtyEditCell, inputType: 'number', min: 0, placeholder: 0 }),
      [deliveryScheduleMetaKey]: 'now',
      mutatorFn: deliveryScheduleMutator,
    },
  }),
  columnHelper.display({
    id: deliverySchedulePlaceholderColumnId,
    header: DeliveryScheduleHeader,
    footer: TotalQtyFooter,
    meta: {
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: QtyEditCell, inputType: 'number', min: 0, placeholder: 0 }),
      mutatorFn: deliveryScheduleMutator,
    },
  }),
  // TODO: make sortable
  columnHelper.display({
    id: 'totalQuantity',
    header: 'Total',
    footer: TotalQtyFooter,
    cell: TotalQtyCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor(({ stage }) => !isNil(stage) && stage !== unreviewedStageSymbol, {
    id: 'hasUnderwritten',
    header: 'Underwritten',
    cell: EnumCell,
    meta: {
      ...dataTableMeta.textRight,
      enumDisplayValues: {
        [true]: 'Yes',
        [false]: <span className="text-neutral-dark/20">No</span>,
      },
    },
  }),
];

const homeModelsTableColumns = [
  columnHelper.accessor('plan', {
    id: 'hmLink',
    header: null,
    cell: HomeModelLinkCell,
    enableSorting: false,
    // TODO: DataTable should probably provide an option to render a zero-width header
    meta: { thClassName: 'sr-only' },
  }),
  columnHelper.accessor(({ images }) => images[0]?.url, {
    id: 'images.0.url',
    cell: HomeModelThumbnailCell,
    header: null,
    enableSorting: false,
  }),
  columnHelper.accessor('plan', { header: 'Home Model' }),
  columnHelper.accessor('bedrooms', {
    header: 'Beds',
    meta: { ...dataTableMeta.textRight },
  }),
  // TODO: sorting should tie-break with half baths
  columnHelper.accessor('bathroomsFull', {
    header: 'Baths',
    cell: BathsCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('sqft', {
    header: 'Sq Ft',
    cell: IntegerCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('priceMin', {
    header: 'List Price',
    cell: CurrencyCell,
    meta: {
      ...dataTableMeta.textRight,
      // break into lines to make the table narrower
      thClassName: 'whitespace-normal',
    },
  }),
  columnHelper.accessor('meanPurchasePrice', {
    header: 'Purchase Price',
    cell: CurrencyCell,
    sortUndefined: 'last',
    meta: {
      ...dataTableMeta.textRight,
      // break into lines to make the table narrower
      thClassName: 'whitespace-normal',
    },
  }),
  columnHelper.accessor(({ marketRent, meanMarketRent }) => meanMarketRent ?? marketRent, {
    id: 'marketRent',
    header: 'UW Rent',
    cell: CurrencyCell,
    meta: { ...dataTableMeta.textRight },
  }),
  // TODO: offer table need to show yield for the current deal
  columnHelper.accessor(({ stabilizedYield: defaultStabilizedYield, returnMetrics: { stabilizedYield } }) => (
    stabilizedYield ?? defaultStabilizedYield
  ), {
    id: 'stabilizedYield',
    header: 'Yield',
    cell: PercentCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('numAvailable', {
    header: 'Available Now',
    cell: ({ getValue }) => <MuteZero value={getValue()} />,
    meta: {
      ...dataTableMeta.textRight,
      // break into lines to make the table narrower
      thClassName: 'whitespace-normal',
    },
  }),
  columnHelper.accessor(({ futureDeliveries }) => futureDeliveries.reduce((agg, [, count]) => count + agg, 0), {
    id: 'futureDeliveriesTotal',
    header: 'Future Deliveries',
    cell: HomeModelFutureDeliveriesCell,
    meta: {
      ...dataTableMeta.textRight,
      // break into lines to make the table narrower
      thClassName: 'whitespace-normal',
    },
  }),
  columnHelper.accessor('stage', {
    header: 'Status',
    cell: HomeModelDealStatusCell,
    enableSorting: false,
    meta: { ...dataTableMeta.textRight },
  }),
  ...homeModelsOfferTableColumns,
  ...homeModelsTableHiddenColumns,
];

const totalAvailablePositiveFilter = Object.freeze([1, Number.POSITIVE_INFINITY]);

/** @type {import('@tanstack/react-table').InitialTableState} */
const homeModelsTableInitState = {
  sorting: [{ id: 'plan', desc: false }],
  columnFilters: [{ id: 'totalAvailable', value: totalAvailablePositiveFilter }],
  columnVisibility: {
    offerBidPrice: false,
    offerYield: false,
    offerUnderwritingRent: false,
    [deliveryScheduleNowColumnId]: false,
    [deliverySchedulePlaceholderColumnId]: false,
    hasUnderwritten: false,
    totalQuantity: false,
    ...hiddenColumnVisibilityInitState,
  },
};

/** @type {import('@tanstack/react-table').InitialTableState} */
const homeModelsOfferTableInitState = {
  sorting: [{ id: 'hasUnderwritten', desc: true }, { id: 'plan', desc: false }],
  columnFilters: [
    { id: 'totalAvailable', value: totalAvailablePositiveFilter },
    { id: 'hasUnderwritten', value: true },
    { id: 'isDead', value: false },
    { id: 'offerNotCreated', value: true },
  ],
  columnVisibility: {
    hmLink: false,
    marketRent: false,
    stabilizedYield: false,
    priceMin: false,
    meanPurchasePrice: false,
    numAvailable: false,
    futureDeliveriesTotal: false,
    stage: false,
    ...hiddenColumnVisibilityInitState,
  },
};

export default function SubdivisionHomeModelsTable({
  homeModels,
  subdivision: { id: subdivisionId },
  className,
  offerTable = false,
  loading = false,
  saving = false,
  children,
}) {
  const [enrichedHomeModels, allDataReady] = useEnrichedHomeModels({ homeModels, subdivisionId });

  const deliveryDates = useHomeModelDeliveryDates({ homeModels });
  const columns = useMemo(() => {
    if (!offerTable) {
      return homeModelsTableColumns;
    }

    // replace placeholder column with columns for specific delivery dates
    const placeholderColIdx = homeModelsTableColumns.findIndex(({ id }) => id === deliverySchedulePlaceholderColumnId);
    const deliverySchedulePlaceholderColumn = homeModelsTableColumns[placeholderColIdx];

    return homeModelsTableColumns.toSpliced(
      placeholderColIdx,
      1,
      ...deliveryDates.map((date) => (
        columnHelper.accessor(() => undefined, {
          ...deliverySchedulePlaceholderColumn,
          ...{
            id: deliveryScheduleColumnId(date),
            meta: {
              ...deliverySchedulePlaceholderColumn.meta,
              [deliveryScheduleMetaKey]: date,
            },
          },
        })
      )),
    );
  }, [deliveryDates, offerTable]);

  const inert = !allDataReady || loading || saving;

  return (
    <DataTable
      columns={columns}
      data={enrichedHomeModels}
      // only show loading when home models have not loaded at all
      isLoading={loading}
      initialState={offerTable ? homeModelsOfferTableInitState : homeModelsTableInitState}
      enableEditing={offerTable}
      tableContainerClassName="whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0"
    >
      {offerTable && <HideEmptyDeliveryDates deliveryDates={deliveryDates} />}
      <HideEmptyColumn columnId="meanPurchasePrice" />

      <div
        inert={inert ? '' : undefined}
        className={cx('flex flex-col gap-y-2', className)}
      >
        <div className="flex flex-row gap-x-2 justify-end">
          <ReactTableFilter>
            {!offerTable && (
              <ReactTableColumnFilter
                label="Hide Unavailable"
                staticDisplayValue="Available Models Only"
                columnId="totalAvailable"
              >
                <BooleanFilterChip>
                  <FilterToggle value={totalAvailablePositiveFilter} />
                </BooleanFilterChip>
              </ReactTableColumnFilter>
            )}

            <ReactTableColumnFilter
              label="Hide Unreviewed"
              staticDisplayValue="Underwritten Models Only"
              columnId="hasUnderwritten"
            >
              <BooleanFilterChip>
                <FilterToggle />
              </BooleanFilterChip>
            </ReactTableColumnFilter>

            <ReactTableColumnFilter
              label="Hide Offered"
              staticDisplayValue="Unoffered Models Only"
              columnId="offerNotCreated"
            >
              <BooleanFilterChip>
                <FilterToggle />
              </BooleanFilterChip>
            </ReactTableColumnFilter>
          </ReactTableFilter>
        </div>

        <div className="relative flex h-0 flex-1 rounded overflow-clip">
          {/* Show loading indicator if data is partially available */}
          {/* Most columns are available immediately but pipeline status and valuation are fetched separately */}
          {/* DataTable's loading indicator doesn't work well with data already in the table */}
          {(!allDataReady || saving) && (
            <>
              <DelayedLoadingIndicator Icon="div" className="absolute inset-0 z-50 bg-black/8" />
              <div className="absolute left-1/2 top-0 -translate-x-1/2 translate-y-12 z-50">
                <DelayedLoadingIndicator className="w-10 text-gray-500" />
              </div>
            </>
          )}
          <DataTableContent />
        </div>
        {children}
      </div>
    </DataTable>
  );
}
