/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
import { ceil, compact, flatten, floor, forEach, isNaN, isNil, map, max, mean, min, orderBy, round, size, slice, sum, uniq } from 'lodash';
import { formatCurrency, formatInteger, formatNone, formatPercentage } from 'components/utils';
import { UNIT_RENT_ACTIVE_STATUS, UNIT_RENT_CLOSED_STATUS, UNIT_RENT_INACTIVE_STATUS } from 'components/constants';

const BED_CELL_WIDTH = 63;
const BATHS_CELL_WIDTH = 72;
const COUNT_CELL_WIDTH = 49;
const CELL_VALUE_WIDTH = 92;
const ASKING_VS_ACTUAL_WIDTH = 80;
const LEFT_BORDER = true;
const SUMMARY_CELL = true;
const SOURCE_EVERY_STATE = 'EveryState';
const SOURCE_ZILLOW = 'Zillow';

const tableOneHeader = [['BEDS', formatNone, BED_CELL_WIDTH], ['BATHS', formatNone, BATHS_CELL_WIDTH], ['#', formatNone, COUNT_CELL_WIDTH, LEFT_BORDER], ['AVG RSF', formatNone, CELL_VALUE_WIDTH], ['MIN', formatNone, CELL_VALUE_WIDTH], ['AVG', formatNone, CELL_VALUE_WIDTH], ['MAX', formatNone, CELL_VALUE_WIDTH], ['#', formatNone, COUNT_CELL_WIDTH, LEFT_BORDER], ['AVG RSF', formatNone, CELL_VALUE_WIDTH], ['MIN', formatNone, CELL_VALUE_WIDTH], ['AVG', formatNone, CELL_VALUE_WIDTH], ['MAX', formatNone, CELL_VALUE_WIDTH], ['#', formatNone, COUNT_CELL_WIDTH, LEFT_BORDER], ['AVG RSF', formatNone, CELL_VALUE_WIDTH], ['MIN', formatNone, CELL_VALUE_WIDTH], ['AVG', formatNone, CELL_VALUE_WIDTH], ['MAX', formatNone, CELL_VALUE_WIDTH]];
const tableTwoHeader = [['BEDS', formatNone, BED_CELL_WIDTH], ['BATHS', formatNone, BATHS_CELL_WIDTH], ['Avg', formatNone, ASKING_VS_ACTUAL_WIDTH, LEFT_BORDER], ['%', formatNone, ASKING_VS_ACTUAL_WIDTH], ['Active', formatNone, CELL_VALUE_WIDTH, LEFT_BORDER], ['Closed', formatNone, CELL_VALUE_WIDTH], ['Inactive', formatNone, CELL_VALUE_WIDTH], ['Zillow', formatNone, CELL_VALUE_WIDTH, LEFT_BORDER], ['MLS', formatNone, CELL_VALUE_WIDTH], ['OTHER', formatNone, CELL_VALUE_WIDTH], ['MIN', formatNone, CELL_VALUE_WIDTH, LEFT_BORDER], ['AVG', formatNone, CELL_VALUE_WIDTH], ['MAX', formatNone, CELL_VALUE_WIDTH], ['MIN', formatNone, CELL_VALUE_WIDTH, LEFT_BORDER], ['AVG', formatNone, CELL_VALUE_WIDTH], ['MAX', formatNone, CELL_VALUE_WIDTH]];

const getBlendedRent = (unitRent) => {
  const { actualRent, currentAskingRent } = unitRent;
  return actualRent > 0 ? actualRent : currentAskingRent;
};

const rentCompDetailData = (selectedUnitRents) => {
  let dataOne = [];
  let dataTwo = [];
  let comparableSets = selectedUnitRents;
  comparableSets = comparableSets.map(unitRent => ({ ...unitRent, blendedRent: getBlendedRent(unitRent) }));

  const bedrooms = uniq(compact(comparableSets.map(set => set.numberOfBedrooms)));
  const bathrooms = uniq(comparableSets.map(set => set.numberOfBathroomsTotal));
  const aggregatedBedBath = flatten(map(bedrooms, (bedroom) => map(bathrooms, (bathroom) => [bedroom, bathroom])));

  forEach(aggregatedBedBath, ([numberOfBedrooms, numberOfBathroomsTotal]) => {
    const tableOne = [];
    const tableTwo = [];
    tableOne.push([numberOfBedrooms, formatNone, BED_CELL_WIDTH], [numberOfBathroomsTotal, formatNone, BATHS_CELL_WIDTH]);
    tableTwo.push([numberOfBedrooms, formatNone, BED_CELL_WIDTH], [numberOfBathroomsTotal, formatNone, BATHS_CELL_WIDTH]);

    const aggregatedComparableSets = comparableSets.filter(set => set.numberOfBedrooms === numberOfBedrooms && set.numberOfBathroomsTotal === numberOfBathroomsTotal);
    if (!size(aggregatedComparableSets)) return; // skip if count of aggregatedBedBath is empty

    // Asking Rent Comps
    const avgAskingRsf = ceil(mean(compact(aggregatedComparableSets.map(({ unitRsf }) => unitRsf))));
    const minAskingRent = min(compact(aggregatedComparableSets.map(({ currentAskingRent }) => currentAskingRent)));
    const avgAskingRent = mean(compact(aggregatedComparableSets.map(({ currentAskingRent }) => currentAskingRent)));
    const maxAskingRent = max(compact(aggregatedComparableSets.map(({ currentAskingRent }) => currentAskingRent)));
    tableOne.push(
      [size(aggregatedComparableSets), formatNone, COUNT_CELL_WIDTH, LEFT_BORDER],
      [avgAskingRsf, formatInteger, CELL_VALUE_WIDTH],
      [minAskingRent, formatCurrency, CELL_VALUE_WIDTH],
      [avgAskingRent, formatCurrency, CELL_VALUE_WIDTH],
      [maxAskingRent, formatCurrency, CELL_VALUE_WIDTH],
    );

    // Actual Rent Comps
    const comparableSetsWithActualRents = aggregatedComparableSets.filter(({ actualRent }) => !isNil(actualRent));
    const avgActualRsf = floor(mean(compact(comparableSetsWithActualRents.map(({ unitRsf }) => unitRsf))));
    const minActualRent = floor(min(compact(comparableSetsWithActualRents.map(({ actualRent }) => actualRent))));
    const avgActualRent = floor(mean(compact(comparableSetsWithActualRents.map(({ actualRent }) => actualRent))));
    const maxActualRent = floor(max(compact(comparableSetsWithActualRents.map(({ actualRent }) => actualRent))));
    tableOne.push(
      [size(comparableSetsWithActualRents), formatNone, COUNT_CELL_WIDTH, LEFT_BORDER],
      [avgActualRsf, formatInteger, CELL_VALUE_WIDTH],
      [minActualRent, formatCurrency, CELL_VALUE_WIDTH],
      [avgActualRent, formatCurrency, CELL_VALUE_WIDTH],
      [maxActualRent, formatCurrency, CELL_VALUE_WIDTH],
    );

    // Blended Rent Comps
    const comparableSetsWithBlendedRents = aggregatedComparableSets.filter(({ blendedRent }) => !isNil(blendedRent));
    const avgBlendedRsf = floor(mean(compact(comparableSetsWithBlendedRents.map(({ unitRsf }) => unitRsf))));
    const minBlended = floor(min(compact(comparableSetsWithBlendedRents.map(({ blendedRent }) => blendedRent))));
    const avgBlended = floor(mean(compact(comparableSetsWithBlendedRents.map(({ blendedRent }) => blendedRent))));
    const maxBlended = floor(max(compact(comparableSetsWithBlendedRents.map(({ blendedRent }) => blendedRent))));
    tableOne.push(
      [size(comparableSetsWithBlendedRents), formatNone, COUNT_CELL_WIDTH, LEFT_BORDER],
      [avgBlendedRsf, formatInteger, CELL_VALUE_WIDTH],
      [minBlended, formatCurrency, CELL_VALUE_WIDTH],
      [avgBlended, formatCurrency, CELL_VALUE_WIDTH],
      [maxBlended, formatCurrency, CELL_VALUE_WIDTH],
    );
    dataOne.push(tableOne);
    // tableOne ends here

    // tableTwo starts here
    // Asking vs Actual Rent
    const avg = avgActualRent > 0 ? avgActualRent - avgAskingRent : NaN;
    const percent = avg / avgActualRent;
    tableTwo.push(
      [avg, formatCurrency, ASKING_VS_ACTUAL_WIDTH, LEFT_BORDER],
      [percent, formatPercentage, ASKING_VS_ACTUAL_WIDTH],
    );

    // Rent Comp Status
    const activeCount = size(aggregatedComparableSets.filter(({ resolvedStatus }) => resolvedStatus === UNIT_RENT_ACTIVE_STATUS));
    const closedCount = size(aggregatedComparableSets.filter(({ resolvedStatus }) => resolvedStatus === UNIT_RENT_CLOSED_STATUS));
    const inactiveCount = size(aggregatedComparableSets.filter(({ resolvedStatus }) => resolvedStatus === UNIT_RENT_INACTIVE_STATUS));
    tableTwo.push(
      [activeCount, formatInteger, CELL_VALUE_WIDTH, LEFT_BORDER],
      [closedCount, formatInteger, CELL_VALUE_WIDTH],
      [inactiveCount, formatInteger, CELL_VALUE_WIDTH],
    );

    // Source
    const zillowCount = size(aggregatedComparableSets.filter(({ sourceName }) => sourceName === SOURCE_ZILLOW));
    const mlsCount = size(aggregatedComparableSets.filter(({ sourceName }) => sourceName === SOURCE_EVERY_STATE));
    const otherCount = size(aggregatedComparableSets.filter(({ sourceName }) => sourceName !== SOURCE_EVERY_STATE && sourceName !== SOURCE_ZILLOW));
    tableTwo.push(
      [zillowCount, formatInteger, CELL_VALUE_WIDTH, LEFT_BORDER],
      [mlsCount, formatInteger, CELL_VALUE_WIDTH],
      [otherCount, formatInteger, CELL_VALUE_WIDTH],
    );

    // Days on Market
    const minDaysOnMarket = NaN;
    const avgDaysOnMarket = NaN;
    const maxDaysOnMarket = NaN;
    tableTwo.push(
      [minDaysOnMarket, formatInteger, CELL_VALUE_WIDTH, LEFT_BORDER],
      [avgDaysOnMarket, formatInteger, CELL_VALUE_WIDTH],
      [maxDaysOnMarket, formatInteger, CELL_VALUE_WIDTH],
    );

    // Distance
    const minDistance = min(compact(aggregatedComparableSets.map(({ distance }) => distance)), 2).toFixed(2);
    const avgDistance = mean(compact(aggregatedComparableSets.map(({ distance }) => distance)), 2).toFixed(2);
    const maxDistance = max(compact(aggregatedComparableSets.map(({ distance }) => distance)), 2).toFixed(2);
    tableTwo.push(
      [minDistance, formatInteger, CELL_VALUE_WIDTH, LEFT_BORDER],
      [avgDistance, formatInteger, CELL_VALUE_WIDTH],
      [maxDistance, formatInteger, CELL_VALUE_WIDTH],
    );
    dataTwo.push(tableTwo);
  });

  dataOne = orderBy(dataOne, ([[bed], [bath]]) => [bed, bath]);
  dataTwo = orderBy(dataTwo, ([[bed], [bath]]) => [bed, bath]);

  dataOne.unshift(tableOneHeader);
  dataTwo.unshift(tableTwoHeader);

  // Calculate Summary
  const tableOneRows = slice(dataOne, 1, dataOne.length);
  const tableOneSummaryRow = [];

  const t1numberOfBedroomsAvg = round(mean(selectedUnitRents.map(unitRent => unitRent.numberOfBedrooms)), 2);
  const t1numberOfBathroomsTotalAvg = round(mean(compact(selectedUnitRents.map(unitRent => unitRent.numberOfBathroomsTotal))), 2);
  tableOneSummaryRow.push(
    [t1numberOfBedroomsAvg, formatInteger, BED_CELL_WIDTH, null, SUMMARY_CELL],
    [t1numberOfBathroomsTotalAvg, formatInteger, BATHS_CELL_WIDTH, null, SUMMARY_CELL],
  );

  // Asking Rent Comps Summary
  const countAskingRsfSummary = sum(tableOneRows.map(row => row[2][0]));
  const avgAskingRsfSummary = floor(mean(compact(tableOneRows.map(row => row[3][0]))));
  const minAskingRentSummary = floor(sum(tableOneRows.map(row => row[2][0] * row[4][0])) / countAskingRsfSummary);
  const avgAskingRentSummary = floor(sum(tableOneRows.map(row => row[2][0] * row[5][0])) / countAskingRsfSummary);
  const maxAskingRentSummary = floor(sum(tableOneRows.map(row => row[2][0] * row[6][0])) / countAskingRsfSummary);
  tableOneSummaryRow.push(
    [countAskingRsfSummary, formatInteger, COUNT_CELL_WIDTH, LEFT_BORDER, SUMMARY_CELL],
    [avgAskingRsfSummary, formatInteger, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
    [minAskingRentSummary, formatCurrency, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
    [avgAskingRentSummary, formatCurrency, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
    [maxAskingRentSummary, formatCurrency, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
  );

  // Actual Rent Comps Summary
  const countActualRsfSummary = sum(tableOneRows.map(row => row[7][0]));
  const avgActualRsfSummary = floor(mean(compact(tableOneRows.map(row => row[8][0]))));
  const minActualRentSummary = floor(sum(compact(flatten(tableOneRows.map(row => row[7][0] * row[9][0])))) / countActualRsfSummary);
  const avgActualRentSummary = floor(sum(compact(flatten(tableOneRows.map(row => row[7][0] * row[10][0])))) / countActualRsfSummary);
  const maxActualRentSummary = floor(sum(compact(flatten(tableOneRows.map(row => row[7][0] * row[11][0])))) / countActualRsfSummary);
  tableOneSummaryRow.push(
    [countActualRsfSummary, formatInteger, COUNT_CELL_WIDTH, LEFT_BORDER, SUMMARY_CELL],
    [avgActualRsfSummary || NaN, formatInteger, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
    [minActualRentSummary || NaN, formatCurrency, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
    [avgActualRentSummary || NaN, formatCurrency, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
    [maxActualRentSummary || NaN, formatCurrency, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
  );

  // Blended Rent Comps Summary
  const countBlendedSummary = sum(tableOneRows.map(row => row[12][0]));
  const minBlendedSummary = floor(sum(tableOneRows.map(row => row[2][0] * row[14][0])) / countAskingRsfSummary);
  const avgBlendedSummary = floor(sum(tableOneRows.map(row => row[2][0] * row[15][0])) / countAskingRsfSummary);
  const maxBlendedSummary = floor(sum(tableOneRows.map(row => row[2][0] * row[16][0])) / countAskingRsfSummary);
  tableOneSummaryRow.push(
    [countBlendedSummary, formatInteger, COUNT_CELL_WIDTH, LEFT_BORDER, SUMMARY_CELL],
    [avgAskingRsfSummary, formatInteger, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
    [minBlendedSummary, formatCurrency, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
    [avgBlendedSummary, formatCurrency, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
    [maxBlendedSummary, formatCurrency, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
  );

  // Table Two Summary row
  const tableTwoRows = slice(dataTwo, 1, dataOne.length);
  const tableTwoSummaryRow = [];

  const t2numberOfBedroomsAvg = round(mean(tableTwoRows.map(row => row[0][0])), 2);
  const t2numberOfBathroomsTotalAvg = round(mean(compact(tableTwoRows.map(row => row[1][0]))), 2);
  tableTwoSummaryRow.push(
    [t2numberOfBedroomsAvg, formatInteger, BED_CELL_WIDTH, null, SUMMARY_CELL],
    [t2numberOfBathroomsTotalAvg, formatInteger, BATHS_CELL_WIDTH, null, SUMMARY_CELL],
  );

  // Asking vs Actual Rent
  const avgSummary = round(mean(compact(tableTwoRows.map(row => row[2][0]))), 2) === 0 ? NaN : round(mean(compact(tableTwoRows.map(row => row[2][0]))), 2);
  const percentSummary = avgSummary / avgAskingRentSummary;
  tableTwoSummaryRow.push(
    [avgSummary, formatCurrency, ASKING_VS_ACTUAL_WIDTH, LEFT_BORDER, SUMMARY_CELL],
    [percentSummary, formatPercentage, ASKING_VS_ACTUAL_WIDTH, null, SUMMARY_CELL],
  );

  // Rent Comp Status Summary
  const ActiveCountSummary = sum(tableTwoRows.map(row => row[4][0]));
  const closedCountSummary = sum(tableTwoRows.map(row => row[0][5]));
  const otherCountCount = sum(tableTwoRows.map(row => row[6][0]));
  tableTwoSummaryRow.push(
    [ActiveCountSummary, formatInteger, CELL_VALUE_WIDTH, LEFT_BORDER, SUMMARY_CELL],
    [closedCountSummary, formatInteger, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
    [otherCountCount, formatInteger, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
  );

  // Source Summary
  const zillowCount = sum(tableTwoRows.map(row => row[7][0]));
  const mlsCount = sum(tableTwoRows.map(row => row[0][8]));
  const otherCount = sum(tableTwoRows.map(row => row[9][0]));
  tableTwoSummaryRow.push(
    [zillowCount, formatInteger, CELL_VALUE_WIDTH, LEFT_BORDER, SUMMARY_CELL],
    [mlsCount, formatInteger, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
    [otherCount, formatInteger, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
  );

  // TODO: Find Days On Market Summary
  const minDaysOnMarketSummary = NaN;
  const avgDaysOnMarketSummary = NaN;
  const maxDaysOnMarketSummary = NaN;
  tableTwoSummaryRow.push(
    [minDaysOnMarketSummary, formatInteger, CELL_VALUE_WIDTH, LEFT_BORDER, SUMMARY_CELL],
    [avgDaysOnMarketSummary, formatInteger, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
    [maxDaysOnMarketSummary, formatInteger, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
  );

  const minDistanceSummary = round(mean(compact(tableTwoRows.map(row => row[13][0])), 2));
  const avgDistanceSummary = round(mean(compact(tableTwoRows.map(row => row[14][0])), 2));
  const maxDistanceSummary = round(mean(compact(tableTwoRows.map(row => row[15][0])), 2));
  tableTwoSummaryRow.push(
    [minDistanceSummary, formatInteger, CELL_VALUE_WIDTH, LEFT_BORDER, SUMMARY_CELL],
    [avgDistanceSummary, formatInteger, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
    [maxDistanceSummary, formatInteger, CELL_VALUE_WIDTH, null, SUMMARY_CELL],
  );

  dataOne.push(tableOneSummaryRow);
  dataTwo.push(tableTwoSummaryRow);
  return [dataOne, dataTwo];
};

function TableCell({ cell, rowIndex }) {
  let [value, format, cellWidth, leftBorder, summaryCell] = cell;
  value = rowIndex === 0 ? value : (isNaN(value) || value === 0 ? '-' : format(value));
  leftBorder = `${leftBorder ? 'border-l-2 border-gray-200' : ''}`;
  summaryCell = summaryCell ? 'bg-gray-100 text-neutral-medium' : 'bg-white';
  const textColor = rowIndex === 0 ? 'text-neutral-medium' : 'text-neutral-dark';
  return (
    <div
      style={{ width: `${cellWidth}px`, height: '53px' }}
      className={`pt-4 pr-4 text-right text-sm font-medium ${textColor} ${leftBorder} ${summaryCell}`}
    >
      {value}
    </div>
  );
}

function TableRow({ row, rowIndex }) {
  return row.map((cell, cellIndex) => <TableCell key={`table-one-${rowIndex}-${cellIndex}`} cell={cell} cellIndex={cellIndex} rowIndex={rowIndex} />);
}

function RentCompDetailTab({ selectedUnitRents }) {
  const [tableOne, tableTwo] = rentCompDetailData(selectedUnitRents);
  return (
    <div className="mx-auto my-6" style={{ maxWidth: '1390px' }}>
      <div className="border border-gray-200 bg-primary-surface rounded-lg">
        <div className="flex text-neutral-dark text-base font-medium">
          <div style={{ width: '136px', height: '61px' }} className="py-4.5 border-r border-gray-200">Unit Mix</div>
          <div style={{ width: '417px' }} className="py-4.5 border-r border-gray-200">Asking Rent Comps</div>
          <div style={{ width: '417px' }} className="py-4.5 border-r border-gray-200">Actual Rent Comps</div>
          <div style={{ width: '418px' }} className="py-4.5 ">Blended Rent Comps</div>
        </div>
        {
          tableOne.map((row, rowIndex) => (
            <div key={`table-one-${rowIndex}`} className="flex border-t border-gray-200">
              <TableRow row={row} rowIndex={rowIndex} />
            </div>
          ))
        }
      </div>
      <div className="mt-6 border-2 border-gray-200 bg-primary-surface rounded-lg">
        <div className="flex text-neutral-dark text-base font-medium">
          <div style={{ width: '135px', height: '61px' }} className="py-4.5 border-r border-gray-200">Unit Mix</div>
          <div style={{ width: '159px' }} className="py-4.5 border-r border-gray-200">Asking vs Actual</div>
          <div style={{ width: '274px' }} className="py-4.5 border-r border-gray-200">Rent Comp Status</div>
          <div style={{ width: '274px' }} className="py-4.5 border-r border-gray-200">Source</div>
          <div style={{ width: '274px' }} className="py-4.5 border-r border-gray-200">Days on Market</div>
          <div style={{ width: '274px' }} className="py-4.5 mx-auto">Distance (mi)</div>
        </div>
        {
          tableTwo.map((row, rowIndex) => (
            <div key={`table-two-${rowIndex}`} className="flex border-t border-gray-200">
              <TableRow row={row} rowIndex={rowIndex} />
            </div>
          ))
        }
      </div>

    </div>
  );
}
export default RentCompDetailTab;
