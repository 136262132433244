import { Link, useNavigate } from 'react-router-dom';
import { Chevron, Folder, Plus } from 'components/icons';
import Button from 'components/shared/NewButton';

// TODO: we can refactor to share code with other header components like PortfolioHeader
export default function OrganizationHeader({ organization, isNew }) {
  const navigate = useNavigate();

  return (
    <div className="p-6 bg-white border border-b shadow">
      <div className="flex justify-between items-center">
        <div className="basis-1/3 text-xl">
          <div className="flex items-center gap-x-4">
            <div className="w-12 h-12 flex justify-center items-center rounded-xl bg-primary-light">
              <Folder className="w-6 fill-primary-dark" filled />
            </div>
            <Link className="cursor-pointer" to="/organizations">
              <div className="text-xl">Organizations</div>
            </Link>
            {isNew && (
              <>
                <Chevron direction="right" className="w-4 text-gray-500" />
                <div className="flex flex-col">
                  <div>New</div>
                </div>
              </>
            )}
            {organization && (
              <>
                <Chevron direction="right" className="w-4 text-gray-500" />
                <Link className="flex cursor-pointer items-center gap-x-4" to={`/organizations/${organization.id}`}>
                  <div className="flex flex-col">
                    <div>{organization.name}</div>
                  </div>
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="basis-1/3 justify-center flex flex-row" />
        <div className="basis-1/3 flex justify-end">
          {(!organization && !isNew) && (
            <Button filled label="Add Organization" leadingIcon={<Plus className="w-4" />} onClick={() => navigate('/organizations/new')} />
          )}
        </div>
      </div>
    </div>
  );
}
