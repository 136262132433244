import { Table } from 'docx';
import { range } from 'lodash';
import { calcReturnMetrics } from 'components/dcf/dcf';
import { formatPercentage } from 'components/utils';
import { BOLD_STYLE, DEFAULT_STYLE, HEADER_STYLE, makeTableRow, tableHeader, tableProperties, UNDERLINE_STYLE } from './util';

export default function buildReturnSnapshot(cashFlows, dcfParams) {
  const { holdPeriod } = dcfParams;
  const {
    annualizedLeveredCashOnCash,
    annualizedUnleveredCashOnCash,
    leveredAverageCashOnCash,
    leveredEquityMultiple,
    leveredIrr,
    unleveredAverageCashOnCash,
    unleveredEquityMultiple,
    unleveredIrr,
  } = calcReturnMetrics(cashFlows, dcfParams);

  return new Table({
    ...tableProperties,
    rows: [
      tableHeader('Return Snapshot', holdPeriod + 2),
      makeTableRow('Cash on Cash', 2, [null], holdPeriod, HEADER_STYLE),
      makeTableRow('Year', 2, [...range(0, holdPeriod).map(val => val + 1)], 1, { ...BOLD_STYLE, ...UNDERLINE_STYLE }),
      makeTableRow('Unlevered', 2, [...range(0, holdPeriod).map(val => formatPercentage(annualizedUnleveredCashOnCash[val]))], 1, DEFAULT_STYLE),
      makeTableRow('Levered', 2, [...range(0, holdPeriod).map(val => formatPercentage(annualizedLeveredCashOnCash[val]))], 1, DEFAULT_STYLE),
      makeTableRow('Unlevered Average Cash-on-Cash:', holdPeriod - 1, [formatPercentage(unleveredAverageCashOnCash), null], 2, DEFAULT_STYLE),
      makeTableRow('Levered Average Cash-on-Cash:', holdPeriod - 1, [formatPercentage(leveredAverageCashOnCash), null], 2, DEFAULT_STYLE),
      makeTableRow('Unlevered IRR:', holdPeriod - 1, [formatPercentage(unleveredIrr), null], 2, DEFAULT_STYLE),
      makeTableRow('Levered IRR:', holdPeriod - 1, [formatPercentage(leveredIrr), null], 2, DEFAULT_STYLE),
      makeTableRow('Unlevered Equity Multiple:', holdPeriod - 1, [unleveredEquityMultiple.toFixed(2), null], 2, DEFAULT_STYLE),
      makeTableRow('Levered Equity Multiple:', holdPeriod - 1, [leveredEquityMultiple.toFixed(2), null], 2, DEFAULT_STYLE),
    ],
  });
}
