import { first, last, sum, takeRight } from 'lodash';
import { add } from 'date-fns';
import Input, { PERCENT_TYPE } from 'components/Input';
import { MODELLED_INDIVIDUALLY } from 'components/constants';
import {
  ACQUISITION_METHOD_ITEMIZED,
  ACQUISITION_METHOD_PERCENT,
  CALCULATED_EXIT_PRICE,
  CUSTOM_EXIT_PRICE,
  EXPENSE_METHOD_ITEMIZED,
  calculatedCapRate,
  isAcquisitionCostItemized,
  isExitPriceCustomized,
} from './dcf';
import { calcHoldPlusOneTaxRate } from './expense';
import ItemizedAcquisitionCostTable from './ItemizedAcquisitionCostTable';
import SectionLabel from './SectionLabel';
import { InlineFormField, InlineInfoField } from '../Form';
import { dateFromString, formatCurrency, formatPercentage, sumArrays, formatDate } from '../utils';
import { ToggleWithLabels } from '../Toggle';

export default function GeneralTab({ cashFlows, dcfParams, listing, onChange }) {
  const exitPrice = last(cashFlows.sale.price);
  const itemizedAcquisitionCost = isAcquisitionCostItemized(dcfParams);
  const customizedExitPrice = isExitPriceCustomized(dcfParams);
  let askingPrice = listing?.price;

  if (listing?.modellingMethod === MODELLED_INDIVIDUALLY) {
    askingPrice = dcfParams.listPrice;
  }

  const {
    acquisitionCostPercent,
    assessedValueExitPercent,
    closingDate,
    costOfSalePercent,
    customExitPrice,
    expenseMethod,
    holdPeriod,
    inflationRate,
    purchasePrice,
    units,
    ltOneYrHold,
  } = dcfParams;

  //  Show different contextual information within the parameters UI
  //  depending on how the exit price is being calculated
  const holdPlusOneNoi = sum(takeRight(cashFlows.netOperatingIncome, 12));
  const exitDate = add(dateFromString(closingDate), ltOneYrHold ? ({ months: holdPeriod }) : ({ years: holdPeriod }));
  const updatedExitCapRate = calculatedCapRate(cashFlows, dcfParams);
  let exitPriceCalculationSection;
  if (expenseMethod !== EXPENSE_METHOD_ITEMIZED) {
    exitPriceCalculationSection = (
      <InlineInfoField className="mt-3" label="Hold Plus 1 NOI" justify="justify-between">
        <div className="text-sm text-gray-500">
          {formatCurrency(holdPlusOneNoi / units.length, 2)}
          {' '}
          / Unit
        </div>
        <div className="text-base text-gray-900">{formatCurrency(holdPlusOneNoi, 2)}</div>
      </InlineInfoField>
    );
  } else if (assessedValueExitPercent) {
    const holdPlusOneEgr = sum(takeRight(cashFlows.revenue.effectiveGrossRevenues, 12));
    const holdPlusOneExpensesBeforeTaxes = sum(takeRight(sumArrays(
      cashFlows.expenses.totalOperatingExpenses.map(v => v * -1),
      cashFlows.expenses.taxes,
    ), 12));
    const holdPlusOneTaxRate = calcHoldPlusOneTaxRate(dcfParams);
    exitPriceCalculationSection = (
      <>
        <InlineInfoField className="mt-3" label="Hold Plus 1 EGR" justify="justify-between">
          <div className="text-sm text-gray-500">
            {formatCurrency(holdPlusOneEgr / units.length, 2)}
            {' '}
            / Unit
          </div>
          <div className="text-base text-gray-900">{formatCurrency(holdPlusOneEgr, 2)}</div>
        </InlineInfoField>
        <InlineInfoField className="mt-3" label="Hold Plus 1 Expenses Before Taxes" justify="justify-between">
          <div className="text-sm text-gray-500">
            {formatCurrency(holdPlusOneExpensesBeforeTaxes / units.length, 2)}
            {' '}
            / Unit
          </div>
          <div className="text-base text-gray-900">{formatCurrency(holdPlusOneExpensesBeforeTaxes, 2)}</div>
        </InlineInfoField>
        <InlineInfoField className="mt-3" label="Hold Plus 1 Tax Rate" justify="justify-between">
          <div />
          <div className="text-base text-gray-900">{formatPercentage(holdPlusOneTaxRate, 4)}</div>
        </InlineInfoField>
      </>
    );
  } else {
    const holdPlusOneTaxes = sum(takeRight(cashFlows.expenses.taxes, 12));
    const finalMonthlyTax = last(cashFlows.expenses.taxes);
    const holdPlusOneNoiAdjustment = holdPlusOneTaxes - (finalMonthlyTax * 12);
    const adjustedHoldPlusOneNoi = holdPlusOneNoi + holdPlusOneNoiAdjustment;
    exitPriceCalculationSection = (
      <>
        <InlineInfoField className="mt-3" label="Hold Plus 1 NOI" justify="justify-between">
          <div className="text-sm text-gray-500">
            {formatCurrency(holdPlusOneNoi / units.length, 2)}
            {' '}
            / Unit
          </div>
          <div className="text-base text-gray-900">{formatCurrency(holdPlusOneNoi, 2)}</div>
        </InlineInfoField>
        <InlineInfoField className="mt-3" label="Tax Adjustment" justify="justify-between">
          <div className="text-sm text-gray-500">
            {formatCurrency(holdPlusOneNoiAdjustment / units.length, 2)}
            {' '}
            / Unit
          </div>
          <div className="text-base text-gray-900">{formatCurrency(holdPlusOneNoiAdjustment, 2)}</div>
        </InlineInfoField>
        <InlineInfoField className="mt-3" label="Adjusted Hold Plus 1 NOI" justify="justify-between">
          <div className="text-sm text-gray-500">
            {formatCurrency(adjustedHoldPlusOneNoi / units.length, 2)}
            {' '}
            / Unit
          </div>
          <div className="text-base text-gray-900">{formatCurrency(adjustedHoldPlusOneNoi, 2)}</div>
        </InlineInfoField>
      </>
    );
  }
  const onCheckItemized = () => {
    onChange({
      target: {
        name: 'acquisitionCostMethod',
        value: itemizedAcquisitionCost ? ACQUISITION_METHOD_PERCENT : ACQUISITION_METHOD_ITEMIZED,
      },
    });
  };

  const onCheckExitPrice = () => {
    const exitPriceMethod = customizedExitPrice ? CALCULATED_EXIT_PRICE : CUSTOM_EXIT_PRICE;
    onChange({
      target: {
        name: 'exitPriceMethod',
        value: exitPriceMethod,
      },
    });
  };

  const handleToggle = () => {
    onChange({
      target: {
        name: 'ltOneYrHold',
        value: !ltOneYrHold,
      },
    });
  };

  /// This handles min and max input limits for the hold period.
  /// HTML min/max inputs don't work when values are inputed outside that range ///
  const handleMinMax = event => {
    const { value, max, min } = event.target;
    let inputValue;

    if (Number(value) > Number(max)) {
      inputValue = Number(max);
    } else if (Number(value) < Number(min)) {
      inputValue = Number(min);
    } else {
      inputValue = Number(value);
    }
    onChange({
      target: {
        name: 'holdPeriod',
        value: inputValue,
      },
    });
  };

  const percentOfAskingPrice = ((purchasePrice * 100) / askingPrice);
  const formattedPercentOfAskingPrice = percentOfAskingPrice % 1 !== 0 ? percentOfAskingPrice.toFixed(1) : percentOfAskingPrice;

  return (
    <div className="flex flex-row overflow-x-auto max-w-min bg-white">
      <div className="flex flex-col w-120 p-3">
        <div className="flex ml-12">
          <ToggleWithLabels
            className="mt-3"
            label1="< 1 Year Hold"
            checked={ltOneYrHold}
            onClick={handleToggle}
          />
        </div>
        <InlineFormField
          className="mt-3"
          name="holdPeriod"
          label={ltOneYrHold ? 'Hold Period (months)' : 'Hold Period'}
          value={holdPeriod}
          type="number"
          min="1"
          max={ltOneYrHold ? 11 : 50}
          onChange={handleMinMax}
        />
        <InlineFormField
          className="mt-3"
          name="closingDate"
          type="date"
          value={closingDate}
          onChange={onChange}
        />
        <InlineInfoField className="mt-3" label="Exit Date" justify="justify-end">
          <div className="text-base text-gray-900">{formatDate(exitDate, 'M/dd/yyyy') || ''}</div>
        </InlineInfoField>
        {(askingPrice || false) && (
          <InlineInfoField className="mt-3" label="Asking Price" justify="justify-between">
            <div className="text-sm text-gray-500">
              {askingPrice ? formatCurrency(askingPrice / units.length) : null}
              &nbsp;/ Unit
            </div>
            <div className="text-base text-gray-900">{formatCurrency(askingPrice)}</div>
          </InlineInfoField>
        )}
        <InlineInfoField className="mt-3" label="Purchase Price" justify="justify-between">
          <div className="text-sm text-gray-500 whitespace-nowrap">
            {`${formattedPercentOfAskingPrice}% of Asking Price`}
          </div>
          <Input
            width="w-0"
            className="mt-1 flex-1"
            name="purchasePrice"
            value={purchasePrice}
            type="number"
            min="1"
            onChange={onChange}
          />
        </InlineInfoField>
        {itemizedAcquisitionCost ? (
          <InlineInfoField className="mt-3" label="Acquisition Cost" justify="justify-end">
            <div className="text-base text-gray-900">{formatCurrency(-first(cashFlows.acquisition.cost))}</div>
          </InlineInfoField>
        ) : (
          <InlineFormField
            className="mt-3"
            name="acquisitionCostPercent"
            label="Acquisition Cost"
            value={acquisitionCostPercent}
            type={PERCENT_TYPE}
            min="0"
            onChange={onChange}
          />
        )}
        <div className="flex justify-end">
          <ToggleWithLabels
            className="mt-3"
            label1="% Purchase Price"
            label2="Itemized"
            checked={itemizedAcquisitionCost}
            onClick={onCheckItemized}
          />
        </div>
        {exitPriceCalculationSection}
        <InlineFormField
          className="mt-3"
          name="exitCapRate"
          value={updatedExitCapRate}
          type={PERCENT_TYPE}
          precision={2}
          min="0.05"
          onChange={onChange}
          disabled={customizedExitPrice}
        />
        <div className="flex justify-end">
          <ToggleWithLabels
            className="mt-3"
            label1="Calculated"
            label2="Custom"
            checked={customizedExitPrice}
            onClick={onCheckExitPrice}
          />
        </div>
        {customizedExitPrice
          ? (
            <InlineFormField
              className="mt-3"
              name="customExitPrice"
              label="Exit Price"
              value={customExitPrice || Math.ceil(exitPrice)}
              type="number"
              min="1"
              onChange={onChange}
            />
          )
          : (
            <InlineInfoField className="mt-3" label="Exit Price" justify="justify-between">
              <div className="text-sm text-gray-500">
                {formatCurrency(exitPrice / units.length)}
            &nbsp;/ Unit
              </div>
              <div className="text-base text-gray-900">{formatCurrency(exitPrice)}</div>
            </InlineInfoField>
          )}
        <InlineFormField
          className="mt-3"
          name="costOfSalePercent"
          label="Cost of Sale"
          value={costOfSalePercent}
          type={PERCENT_TYPE}
          min="0"
          onChange={onChange}
        />
        <InlineFormField
          className="mt-3"
          name="inflationRate"
          value={inflationRate}
          type="percent"
          min="0"
          max="100"
          onChange={onChange}
        />
      </div>
      {itemizedAcquisitionCost && (
        <div className="flex flex-col p-3">
          <SectionLabel text="Itemized Acquisition Costs" />
          <ItemizedAcquisitionCostTable
            isModelParameter
            dcfParams={dcfParams}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
}
