import { camelCaseKeys } from 'components/utils';
import { apiSlice } from 'redux/apiSlice';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      uploadCSV: builder.mutation({
        query: formData => ({
          url: '/api/dashboards/import',
          method: 'POST',
          body: formData,
        }),
        providesTags: ['upload'],
        transformResponse: response => camelCaseKeys(response),
        invalidatesTags: ['dispos', 'leases'],
      }),
      fetchDispositions: builder.query({
        query: () => ({ url: '/api/dashboards/dispositions' }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: ['dispos'],
      }),
      fetchAcquisitions: builder.query({
        query: () => ({ url: '/api/dashboards/acquisitions' }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: ['acquisitions'],
      }),
      fetchLeases: builder.query({
        query: () => ({ url: '/api/dashboards/leases' }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: ['leases'],
      }),
      fetchAOffers: builder.query({
        query: () => ({ url: '/api/dashboards/offers' }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: ['offers'],
      }),
    };
  },
});

export const {
  useFetchAcquisitionsQuery,
  useFetchAOffersQuery,
  useFetchDispositionsQuery,
  useFetchLeasesQuery,
  usePrefetch,
  useUploadCSVMutation,
} = extendedApiSlice;
