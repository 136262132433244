import { calcReturnMetricsOfIndividualScenarios } from 'components/dcf/dcf';
import { formatPercentage } from 'components/utils';
import MuteZero from './MuteZero';
import { dcfParamsWithInput, getVisibleDeliveryScheduleColumnIds } from './subdivision';

export default function OfferYieldFooter({ table }) {
  const qtyCols = getVisibleDeliveryScheduleColumnIds({ table });

  const { flatRows } = table.getRowModel();
  const hmQty = flatRows.map(({ getValue }) => qtyCols.reduce((tot, colId) => tot + (getValue(colId) || 0), 0));

  const dcf = flatRows
    .flatMap(({ getValue }, idx) => {
      if (!hmQty[idx]) {
        return [];
      }

      return [
        {
          parameters: dcfParamsWithInput({
            dcfParams: getValue('offerDcfParams'),
            purchasePrice: getValue('offerBidPrice'),
            marketRent: getValue('offerUnderwritingRent'),
            multiplicity: hmQty[idx],
          }),
        },
      ];
    });

  const { stabilizedYield } = !dcf.length ? {} : calcReturnMetricsOfIndividualScenarios(dcf, null, { skipIrr: true });
  return <MuteZero value={stabilizedYield} formatter={formatPercentage} />;
}
