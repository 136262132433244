/* eslint-disable quote-props */
const ASSESSOR_URL = {
  '06037': (apn) => `https://portal.assessor.lacounty.gov/parceldetail/${apn}`,
  '06073': (apn) => 'https://assr.parcelquest.com/Home/Index', // no way to pull up specific property
  '13013': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=635&LayerID=11218&PageTypeID=4&PageID=4616&KeyValue=${apn.substring(0, 5)}++${apn.substring(5)}`,
  '13015': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=791&LayerID=14444&PageTypeID=4&PageID=7425&KeyValue=${apn.substring(0, 5)}-${apn.substring(5, 9)}-${apn.substring(9)}`,
  '13035': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=922&LayerID=17901&PageTypeID=4&PageID=7996&KeyValue=${apn.substring(0, 5)}-${apn.substring(5, 8)}-${apn.substring(8)}`,
  '13045': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=663&LayerID=15076&PageTypeID=4&PageID=6801&KeyValue=${apn.substring(0, 3)}++++${apn.substring(3)}`,
  '13057': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=992&LayerID=20191&PageTypeID=4&PageID=10493&KeyValue=${apn.substring(0, 6)}+++++${apn.substring(6)}`,
  '13063': (apn) => 'https://publicaccess.claytoncountyga.gov/search/CommonSearch.aspx?mode=REALPROP', // no way to pull up specific
  '13067': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=1051&LayerID=23951&PageTypeID=4&PageID=9969&KeyValue=${apn}`,
  '13077': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=704&LayerID=11412&PageTypeID=4&PageID=4878&KeyValue=${apn.substring(0, 4)}++++++${apn.substring(4)}`,
  '13085': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=676&LayerID=11636&PageTypeID=4&PageID=5339&KeyValue=${apn.substring(0, 3)}++++${apn.substring(3)}`,
  '13089': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=994&LayerID=20256&PageTypeID=4&PageID=8824&KeyValue=${apn.substring(0, 2)}+${apn.substring(2, 5)}+${apn.substring(5, 7)}+${apn.substring(7)}`,
  '13097': (apn) => 'https://qpublic.schneidercorp.com/Application.aspx?AppID=988&LayerID=20162&PageTypeID=2&PageID=8760', // looks like their search is broken
  '13113': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=942&LayerID=18406&PageTypeID=4&PageID=8206&KeyValue=${apn}`,
  '13117': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=1027&LayerID=21667&PageTypeID=4&PageID=9230&KeyValue=${apn.substring(0, 3)}+++${apn.substring(3)}`,
  '13121': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=936&LayerID=18251&PageTypeID=4&PageID=8156&KeyValue=${apn.substring(0, 2)}+${apn.substring(2)}`,
  '13135': (apn) => `https://gwinnettassessor.manatron.com/IWantTo/PropertyGISSearch/PropertyDetail.aspx?p=R${apn.substring(0, 4)} ${apn.substring(4)}`,
  '13143': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=744&LayerID=11781&PageTypeID=4&PageID=5503&KeyValue=${apn.substring(0, 4)}+++${apn.substring(4)}`,
  '13149': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=701&LayerID=11409&PageTypeID=4&PageID=4864&KeyValue=${apn.substring(0, 4)}+++${apn.substring(4, 8)}+${apn.substring(8)}`,
  '13151': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=1035&LayerID=22139&PageTypeID=4&PageID=9368&KeyValue=${apn}`,
  '13159': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=699&LayerID=11404&PageTypeID=4&PageID=4856&KeyValue=${apn.substring(0, 3)}++++${apn.substring(3, 6)}+${apn.substring(6)}`,
  '13171': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=749&LayerID=11785&PageTypeID=4&PageID=5523&KeyValue=${apn.substring(0, 3)}++++${apn.substring(3, 6)}+${apn.substring(6)}`,
  '13199': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=775&LayerID=11811&PageTypeID=4&PageID=5653&KeyValue=${apn.substring(0, 3)}++++${apn.substring(3, 6)}+${apn.substring(6)}`,
  '13217': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=794&LayerID=11825&PageTypeID=4&PageID=5723&KeyValue=${apn}`,
  '13223': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=689&LayerID=11378&PageTypeID=4&PageID=4802&KeyValue=${apn.substring(0, 3)}.${apn.substring(3, 4)}.${apn.substring(4, 5)}.${apn.substring(5, 8)}.${apn.substring(8)}`,
  '13227': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=627&LayerID=11193&PageTypeID=4&PageID=4592&KeyValue=${apn.substring(0, 3)}++++${apn.substring(3)}`,
  '13231': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=759&LayerID=11795&PageTypeID=4&PageID=5573&KeyValue=${apn.substring(0, 3)}++++${apn.substring(3)}`,
  '13247': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=694&LayerID=11394&PageTypeID=4&PageID=4834&KeyValue=${apn}`,
  '13255': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=766&LayerID=11802&PageTypeID=4&PageID=5608&KeyValue=${apn}`,
  '13297': (apn) => `https://qpublic.schneidercorp.com/Application.aspx?AppID=628&LayerID=11921&PageTypeID=4&PageID=5798&KeyValue=${apn}`,
  '39049': (apn) => `https://audr-apps.franklincountyohio.gov/redir/Link/Parcel/${apn.substring(0, 3)}-${apn.substring(3, 9)}-${apn.substring(9)}`,
  '39061': (apn) => `https://wedge1.hcauditor.org/view/re/${apn}/${new Date().getFullYear()}/summary`,
  '47037': (apn, parcel) => `http://www.padctn.org/prc/property/${parseInt(parcel.accountNumber, 10)}/card/1`,
  '47043': (apn) => `https://assessment.cot.tn.gov/TPAD/Parcel/Details?&parcelId=${apn.substring(3, 6)}    ${apn.substring(6)} 000&jur=${apn.substring(0, 3)}&parcelKey=${apn.substring(0, 6)}    ${apn.substring(6)} 000`,
  '47187': () => 'https://inigo.williamson-tn.org/property_search/', // no way to pull up specific property
  '48085': (apn, parcel) => `https://www.collincad.org/propertysearch?prop=${parcel.accountNumber}`,
  '48113': (apn) => `https://www.dallasact.com/act_webdev/dallas/showdetail2.jsp?can=${apn}`,
  '48439': (apn) => `https://taxonline.tarrantcounty.com/TaxPayer/Accounts/AccountDetails?taxAccountNumber=${apn.padStart(11, '0')}`,
};

// eslint-disable-next-line import/prefer-default-export
export const getAssessorSiteUrl = (parcel) => {
  const { apn, fipsCode } = parcel;
  const siteUrlFunc = ASSESSOR_URL[fipsCode];
  return siteUrlFunc ? siteUrlFunc(apn, parcel) : null;
};
