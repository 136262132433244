/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const CURRENT_COMP_SET = { id: 0, label: 'Current Comps' };

const initialState = {
  activeSaleCompSetId: CURRENT_COMP_SET.id,
  allComps: null,
  selectedSaleCompIds: null,
  showPhotoId: null,
  showInfoId: null,
};

const saleCompsSlice = createSlice({
  name: 'saleComps',
  initialState,
  reducers: {
    setActiveSaleCompSetId: (state, { payload }) => {
      state.activeSaleCompSetId = payload;
      state.allComps = null;
      state.selectedSaleCompIds = null;
    },
    setAllComps: (state, { payload }) => {
      state.allComps = payload;
    },
    setSelectedSaleCompIds: (state, { payload }) => {
      state.selectedSaleCompIds = payload;
    },
    setShowInfoId: (state, { payload }) => {
      state.showPhotoId = null;
      state.showInfoId = payload;
    },
    setShowPhotoId: (state, { payload }) => {
      state.showInfoId = null;
      state.showPhotoId = payload;
    },
  },
});

export const {
  setActiveSaleCompSetId,
  setAllComps,
  setSelectedSaleCompIds,
  setShowInfoId,
  setShowPhotoId,
} = saleCompsSlice.actions;

export default saleCompsSlice.reducer;
