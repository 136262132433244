export default function StatChip({ title, backgroundColor, data }) {
  return (
    <div className="flex gap-x-2 px-4 py-2 items-center border rounded-md bg-white">
      <div className={`p-4 text-white rounded-md text-base ${backgroundColor}`}>{title}</div>
      <div className="flex flex-col gap-y-1">
        {
          data.map(({ label, value }) => (
            <div key={label} className="flex gap-x-4 justify-between items-center">
              <span className="text-neutral text-base font-medium">{value}</span>
              <span className="text-neutral text-base">{label}</span>
            </div>
          ))
        }
      </div>
    </div>
  );
}
