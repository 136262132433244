import { camelCase, chunk, isNil, map, range, sum, take } from 'lodash';
import { formatCurrency, formatPercentage } from 'components/utils';
import { calcMonthlyCapitalProjects } from './capital';
import { ACCOUNTING_METHOD_FOLLOW_ON, calcAcquisitionCosts, holdPeriodInMonths } from './dcf';
import { annualizeMonthlyReturns, pmt, pv } from '../finance';

export const LOAN_SIZING_METHOD_LTC = 'ltc';
export const LOAN_SIZING_METHOD_LTV = 'ltv';
export const LOAN_SIZING_METHOD_DEBT_YIELD = 'debt_yield';
export const LOAN_SIZING_METHOD_DSCR = 'dscr';
export const LOAN_SIZING_METHOD_MANUAL = 'manual';
export const LOAN_SIZING_METHODS = [
  [LOAN_SIZING_METHOD_LTC, 'LTC'],
  [LOAN_SIZING_METHOD_LTV, 'LTV'],
  [LOAN_SIZING_METHOD_DEBT_YIELD, 'Debt Yield'],
  [LOAN_SIZING_METHOD_DSCR, 'DSCR'],
  [LOAN_SIZING_METHOD_MANUAL, 'Manual'],
];
export const LOAN_VALUATION_METHOD_PURCHASE = 'purchase_price';
export const LOAN_VALUATION_METHOD_NOI = 'noi';
export const LOAN_VALUATION_METHOD_MANUAL = 'manual';
export const LOAN_VALUATION_METHODS = [
  [LOAN_VALUATION_METHOD_PURCHASE, 'Purchase Price'],
  [LOAN_VALUATION_METHOD_NOI, 'Net Operating Income'],
  [LOAN_VALUATION_METHOD_MANUAL, 'Manual'],
];

export const LOAN_SIZING_PARAM_KEY = {
  [LOAN_SIZING_METHOD_LTC]: LOAN_SIZING_METHOD_LTC,
  [LOAN_SIZING_METHOD_LTV]: LOAN_SIZING_METHOD_LTV,
  [LOAN_SIZING_METHOD_DEBT_YIELD]: camelCase(LOAN_SIZING_METHOD_DEBT_YIELD),
  [LOAN_SIZING_METHOD_DSCR]: LOAN_SIZING_METHOD_DSCR,
  [LOAN_SIZING_METHOD_MANUAL]: 'loanSize',
};
export const LOAN_SIZING_FORMATTING_METHOD = {
  [LOAN_SIZING_METHOD_LTC]: formatPercentage,
  [LOAN_SIZING_METHOD_LTV]: formatPercentage,
  [LOAN_SIZING_METHOD_DEBT_YIELD]: formatPercentage,
  [LOAN_SIZING_METHOD_DSCR]: formatPercentage,
  [LOAN_SIZING_METHOD_MANUAL]: formatCurrency,
};

export const isRefinancing = (dcfParams) => !isNil(dcfParams.refinancingSizingMethod);

export const parseValuationMethod = (dcfParams, refinancing) => (
  refinancing ? (dcfParams.refinancingValuationMethod || LOAN_VALUATION_METHOD_NOI) : (dcfParams.loanValuationMethod || LOAN_VALUATION_METHOD_PURCHASE)
);

export const parseRefinancingParams = (dcfParams) => {
  const loanSizingYear = Math.round(dcfParams.term / 12) + 1;

  return {
    amoritization: dcfParams.refinancingAmoritization,
    couponRate: dcfParams.refinancingCouponRate,
    debtYield: dcfParams.refinancingDebtYield,
    dscr: dcfParams.refinancingDscr,
    holdPeriod: dcfParams.holdPeriod,
    interestOnly: dcfParams.refinancingInterestOnly,
    loanCapRate: dcfParams.refinancingCapRate,
    loanOriginationFeeRate: dcfParams.refinancingOriginationFeeRate,
    loanSize: dcfParams.refinancingLoanSize,
    loanSizingMethod: dcfParams.refinancingSizingMethod,
    loanSizingYear,
    loanValuation: dcfParams.refinancingValuation,
    loanValuationMethod: dcfParams.refinancingValuationMethod,
    ltv: dcfParams.refinancingLtv,
    term: dcfParams.refinancingTerm,
  };
};

export const calcLoanValuation = (annualNOIs, dcfParams, refinancing) => {
  const loanParams = refinancing ? parseRefinancingParams(dcfParams) : dcfParams;
  const valuationMethod = parseValuationMethod(dcfParams, refinancing);

  switch (valuationMethod) {
    case LOAN_VALUATION_METHOD_PURCHASE:
      return loanParams.purchasePrice;
    case LOAN_VALUATION_METHOD_NOI:
      return annualNOIs[loanParams.loanSizingYear - 1] / loanParams.loanCapRate;
    case LOAN_VALUATION_METHOD_MANUAL:
      return loanParams.loanValuation;
    default:
      throw new Error(`Unsupported valuation method: ${valuationMethod}`);
  }
};

export const ltcBasedProceeds = (loanValuation, dcfParams) => {
  let cost = loanValuation + sum(calcMonthlyCapitalProjects(dcfParams)[ACCOUNTING_METHOD_FOLLOW_ON]);

  if (dcfParams.ltcIncludeAcquisitionCost) {
    cost += calcAcquisitionCosts(dcfParams);
  }

  return cost * dcfParams.ltc;
};

export const calcFutureFundingMonthlyDraws = (dcfParams) => {
  const monthlyDraws = new Array(holdPeriodInMonths(dcfParams)).fill(0);
  if (dcfParams.ltcFutureFundingDraw) {
    const totalFollowOnCapital = dcfParams.ltc * sum(calcMonthlyCapitalProjects(dcfParams)[ACCOUNTING_METHOD_FOLLOW_ON]);
    const monthlyDrawAmount = totalFollowOnCapital / dcfParams.ltcFutureFundingDraw;
    monthlyDraws.fill(monthlyDrawAmount, 0, dcfParams.ltcFutureFundingDraw);
  }
  return monthlyDraws;
};

export const calcDebtYield = (annualNOIs, proceeds) => annualNOIs.map(noi => noi / proceeds);

export const calcDscr = (cashFlows) => {
  const { interestPayments, principalPayments } = cashFlows.financing;
  const annualNOIs = annualizeMonthlyReturns(cashFlows.netOperatingIncome);
  const loanPayments = interestPayments.map((intPayment, index) => -1 * (intPayment + principalPayments[index]));
  const annualLoanPayments = map(chunk(loanPayments, 12), sum);
  return annualLoanPayments.map((loanPayment, index) => annualNOIs[index] / loanPayment);
};

export const debtYieldBasedProceeds = (annualNOIs, debtYield, loanSizingYear) => {
  const loanSizingNOI = annualNOIs[loanSizingYear - 1];
  return loanSizingNOI / debtYield;
};

export const monthlyRate = (couponRate) => ((1 + couponRate) ** (1 / 12)) - 1;

export const dscrBasedProceeds = (annualNOIs, amoritization, couponRate, dscr, loanSizingYear) => {
  const loanSizingNOI = annualNOIs[loanSizingYear - 1];
  const monthlyPayment = loanSizingNOI / dscr / 12;
  return pv(monthlyRate(couponRate), amoritization, monthlyPayment);
};

export const calcLoanProceeds = (annualNOIs, dcfParams, refinancing) => {
  const loanParams = refinancing ? parseRefinancingParams(dcfParams) : dcfParams;
  const calculatedLoanValuation = calcLoanValuation(annualNOIs, dcfParams, refinancing);

  switch (loanParams.loanSizingMethod) {
    case LOAN_SIZING_METHOD_LTC:
      return ltcBasedProceeds(calculatedLoanValuation, loanParams);
    case LOAN_SIZING_METHOD_LTV:
      return calculatedLoanValuation * loanParams.ltv;
    case LOAN_SIZING_METHOD_DEBT_YIELD:
      return debtYieldBasedProceeds(annualNOIs, loanParams.debtYield, loanParams.loanSizingYear);
    case LOAN_SIZING_METHOD_DSCR:
      return dscrBasedProceeds(annualNOIs, loanParams.amoritization, loanParams.couponRate, loanParams.dscr, loanParams.loanSizingYear);
    case LOAN_SIZING_METHOD_MANUAL:
      return loanParams.loanSize;
    default:
      throw new Error(`Unsupported loan sizing method: ${loanParams.loanSizingMethod}`);
  }
};

export const calcLoanOriginationFee = (loanProceeds, refinancing, dcfParams) => {
  if (refinancing) {
    return loanProceeds * dcfParams.refinancingOriginationFeeRate;
  }
  return loanProceeds * dcfParams.loanOriginationFeeRate;
};

export const calcMonthlyOriginationFees = (annualNOIs, dcfParams) => {
  const monthlyFees = new Array(holdPeriodInMonths(dcfParams)).fill(0);
  const initialLoanProceeds = calcLoanProceeds(annualNOIs, dcfParams, false);
  const initialOriginationFee = calcLoanOriginationFee(initialLoanProceeds, false, dcfParams);
  monthlyFees.unshift(initialOriginationFee);
  if (isRefinancing(dcfParams)) {
    const refinancingLoanProceeds = calcLoanProceeds(annualNOIs, dcfParams, true);
    const refinancingOriginationFee = calcLoanOriginationFee(refinancingLoanProceeds, true, dcfParams);
    monthlyFees[dcfParams.term] = refinancingOriginationFee;
  }
  return monthlyFees;
};

export const createMonthlyLoanSchedule = (proceeds, couponRate, amoritization, term, interestOnly, additionalDraws) => {
  const monthlyPayment = pmt(monthlyRate(couponRate), amoritization, proceeds);
  const currentMonthlyRate = monthlyRate(couponRate);

  let endingBalance = proceeds - sum(additionalDraws);
  return range(1, term + 1).map(t => {
    const beginningBalance = endingBalance;
    const interestPaid = beginningBalance * currentMonthlyRate;
    const principalPaid = t <= interestOnly ? 0 : monthlyPayment - interestPaid;
    endingBalance = beginningBalance + additionalDraws[t - 1] - principalPaid;
    return [interestPaid, principalPaid];
  });
};

export const calcMonthlyLoanSchedule = (annualNOIs, dcfParams) => {
  const initialProceeds = calcLoanProceeds(annualNOIs, dcfParams, false);
  const { couponRate, amoritization, term, interestOnly } = dcfParams;
  const additionalDraws = calcFutureFundingMonthlyDraws(dcfParams);
  let monthlyPayments = createMonthlyLoanSchedule(initialProceeds, couponRate, amoritization, term, interestOnly, additionalDraws);

  if (isRefinancing(dcfParams)) {
    const refinancingProceeds = calcLoanProceeds(annualNOIs, dcfParams, true);
    const refinancingParams = parseRefinancingParams(dcfParams);
    const refinancingMonthlyPayments = createMonthlyLoanSchedule(
      refinancingProceeds,
      refinancingParams.couponRate,
      refinancingParams.amoritization,
      refinancingParams.term,
      refinancingParams.interestOnly,
      new Array(refinancingParams.term).fill(0),
    );
    monthlyPayments = monthlyPayments.concat(refinancingMonthlyPayments);
  }

  // only consider payments within the hold period
  const maxMonths = holdPeriodInMonths(dcfParams);
  if (monthlyPayments.length < maxMonths) {
    monthlyPayments = monthlyPayments.concat(new Array(maxMonths - monthlyPayments.length).fill([0, 0]));
  } else if (monthlyPayments.length > maxMonths) {
    monthlyPayments = take(monthlyPayments, maxMonths);
  }

  return monthlyPayments;
};

export const calculateLoanRepayment = (annualNOIs, dcfParams, refinancing) => {
  const proceeds = calcLoanProceeds(annualNOIs, dcfParams, refinancing);
  const loanParams = refinancing ? parseRefinancingParams(dcfParams) : dcfParams;
  const { couponRate, amoritization, term, interestOnly } = loanParams;
  const additionalDraws = refinancing ? new Array(term).fill(0) : calcFutureFundingMonthlyDraws(dcfParams);
  const loanPayments = createMonthlyLoanSchedule(proceeds, couponRate, amoritization, term, interestOnly, additionalDraws);
  const principalPayments = loanPayments.map((payments) => payments[1]);

  if (refinancing) {
    // only consider principal payments within hold period
    const monthsWithinholdPeriod = Math.min(dcfParams.refinancingTerm, holdPeriodInMonths(dcfParams) - dcfParams.term);
    const totalPrincipalPayments = sum(take(principalPayments, monthsWithinholdPeriod));
    return proceeds - totalPrincipalPayments;
  }

  // only consider principal payments within hold period
  const monthsWithinholdPeriod = Math.min(dcfParams.term, holdPeriodInMonths(dcfParams));
  const totalPrincipalPayments = sum(take(principalPayments, monthsWithinholdPeriod));
  return proceeds - totalPrincipalPayments;
};

// TODO: confusing function name
export const calcMonthlyPaymentRepayment = (annualNOIs, dcfParams, refinancing) => {
  const monthlyPayments = new Array(holdPeriodInMonths(dcfParams)).fill(0);

  if (refinancing) {
    if (isRefinancing(dcfParams)) {
      const refinancingProceeds = calcLoanProceeds(annualNOIs, dcfParams, true);
      monthlyPayments[dcfParams.term] = refinancingProceeds;
      monthlyPayments[Math.min(dcfParams.term + dcfParams.refinancingTerm, holdPeriodInMonths(dcfParams))] = -1 * calculateLoanRepayment(annualNOIs, dcfParams, true);
    }
  } else {
    monthlyPayments.unshift(calcLoanProceeds(annualNOIs, dcfParams, false) - sum(calcFutureFundingMonthlyDraws(dcfParams)));
    monthlyPayments[Math.min(dcfParams.term, holdPeriodInMonths(dcfParams))] = -1 * calculateLoanRepayment(annualNOIs, dcfParams, false);
  }

  return monthlyPayments;
};
