import { first, isFinite, isNull, sum } from 'lodash';
import {
  calcDebtYield,
  calcDscr,
  calcLoanValuation,
  LOAN_SIZING_METHOD_DEBT_YIELD,
  LOAN_SIZING_METHOD_DSCR,
  LOAN_SIZING_METHOD_LTC,
  LOAN_SIZING_METHOD_LTV,
  LOAN_SIZING_METHOD_MANUAL,
  LOAN_SIZING_METHODS,
  LOAN_VALUATION_METHOD_PURCHASE,
  LOAN_VALUATION_METHOD_NOI,
  LOAN_VALUATION_METHOD_MANUAL,
  LOAN_VALUATION_METHODS,
  parseRefinancingParams,
  parseValuationMethod,
} from './debt';
import { InlineFormField, InlineInfoField } from '../Form';
import { PERCENT_TYPE } from '../Input';
import { annualizeMonthlyReturns } from '../finance';
import { formatCurrency, formatMultiplier, formatPercentage } from '../utils';

function LoanValuationParameters({ annualizedNOIs, dcfParams, loanValuation, onChange, refinancing }) {
  const loanParams = refinancing ? parseRefinancingParams(dcfParams) : dcfParams;
  const loanValuationMethod = parseValuationMethod(dcfParams, refinancing);
  const { loanCapRate, loanSizingYear } = loanParams;

  let loanSizingParameters;
  if (loanValuationMethod === LOAN_VALUATION_METHOD_PURCHASE) {
    loanSizingParameters = (
      <InlineInfoField label="Valuation" value={formatCurrency(loanValuation)} className="mt-3" />
    );
  } else if (loanValuationMethod === LOAN_VALUATION_METHOD_NOI) {
    loanSizingParameters = (
      <>
        {refinancing ? (
          <InlineInfoField label="Valuation Year" className="mt-3" justify="justify-end" value={loanSizingYear} />
        ) : (
          <InlineFormField
            className="mt-3"
            name={refinancing ? 'refinancingValuationYear' : 'loanSizingYear'}
            type="number"
            value={loanSizingYear}
            min="1"
            onChange={onChange}
          />
        )}
        <InlineInfoField
          label="Net Operating Income"
          value={loanSizingYear > 0 ? formatCurrency(annualizedNOIs[loanSizingYear - 1]) : '-'}
          className="mt-3"
        />
        <InlineFormField
          className="mt-3"
          name={refinancing ? 'refinancingCapRate' : 'loanCapRate'}
          type={PERCENT_TYPE}
          value={loanCapRate}
          onChange={onChange}
        />
        <InlineInfoField label="Valuation" className="mt-3" value={formatCurrency(loanValuation)} />
      </>
    );
  } else if (loanValuationMethod === LOAN_VALUATION_METHOD_MANUAL) {
    loanSizingParameters = (
      <InlineFormField
        className="mt-3"
        name={refinancing ? 'refinancingValuation' : 'loanValuation'}
        type="number"
        value={loanValuation}
        onChange={onChange}
      />
    );
  } else {
    throw new Error(`Unsupported loan valuation method: ${loanValuationMethod}`);
  }

  return (
    <>
      <InlineFormField
        className="mt-3"
        name={refinancing ? 'refinancingValuationMethod' : 'loanValuationMethod'}
        type="select"
        value={loanValuationMethod}
        options={refinancing ? LOAN_VALUATION_METHODS.slice(1) : LOAN_VALUATION_METHODS}
        onChange={onChange}
      />
      {loanSizingParameters}
    </>
  );
}

function LoanSizingParameters({ acquisitionCost, annualizedNOIs, dcfParams, onChange, refinancing, totalFollowOnCapital }) {
  const loanParams = refinancing ? parseRefinancingParams(dcfParams) : dcfParams;
  const {
    debtYield,
    dscr,
    holdPeriod,
    loanSize,
    loanSizingMethod,
    loanSizingYear,
    ltc,
    ltcFutureFundingDraw,
    ltcIncludeAcquisitionCost,
    ltv,
  } = loanParams;

  let loanSizingParameters;
  if (loanSizingMethod === LOAN_SIZING_METHOD_LTC) {
    const isFutureFunding = !isNull(ltcFutureFundingDraw);
    const onCheckFutureFunding = () => {
      onChange({
        target: {
          name: 'ltcFutureFundingDraw',
          value: isFutureFunding ? null : 1,
        },
      });
    };

    loanSizingParameters = (
      <>
        <InlineFormField
          className="mt-3"
          name="ltc"
          type={PERCENT_TYPE}
          value={ltc}
          min="0"
          max="100"
          onChange={onChange}
        />
        <InlineFormField
          className="mt-3"
          name="ltcIncludeAcquisitionCost"
          label="Include Acquisition Cost"
          type="checkbox"
          value={ltcIncludeAcquisitionCost ?? false}
          onChange={onChange}
        />
        {ltcIncludeAcquisitionCost && (
          <InlineInfoField
            className="mt-3"
            label="Acquisition Cost"
            value={formatCurrency(acquisitionCost)}
          />
        )}
        <InlineInfoField label="Follow On Capital Projects" className="mt-3" value={formatCurrency(totalFollowOnCapital)} />
        <InlineFormField
          className="mt-3"
          type="checkbox"
          label="Future Funding"
          value={isFutureFunding || ''}
          checked={isFutureFunding}
          onChange={onCheckFutureFunding}
        />
        {isFutureFunding && (
          <InlineFormField
            className="mt-3"
            name="ltcFutureFundingDraw"
            label="Future Funding Draw (Months)"
            type="number"
            value={ltcFutureFundingDraw}
            min="1"
            onChange={onChange}
          />
        )}
      </>
    );
  } else if (loanSizingMethod === LOAN_SIZING_METHOD_LTV) {
    loanSizingParameters = (
      <InlineFormField
        className="mt-3"
        name={refinancing ? 'refinancingLtv' : 'ltv'}
        type={PERCENT_TYPE}
        value={ltv}
        min="0"
        max="100"
        onChange={onChange}
      />
    );
  } else if (loanSizingMethod === LOAN_SIZING_METHOD_DEBT_YIELD) {
    loanSizingParameters = (
      <>
        <InlineFormField
          className="mt-3"
          name={refinancing ? 'refinancingDebtYield' : 'debtYield'}
          type={PERCENT_TYPE}
          value={debtYield}
          min="1"
          onChange={onChange}
        />
        {!refinancing && (
          <InlineFormField
            className="mt-3"
            name="loanSizingYear"
            type="number"
            value={loanSizingYear}
            min="1"
            max={holdPeriod}
            onChange={onChange}
          />
        )}
        <InlineInfoField label="NOI" className="mt-3" value={formatCurrency(annualizedNOIs[loanSizingYear - 1])} />
      </>
    );
  } else if (loanSizingMethod === LOAN_SIZING_METHOD_DSCR) {
    loanSizingParameters = (
      <>
        <InlineFormField
          className="mt-3"
          name={refinancing ? 'refinancingDscr' : 'dscr'}
          type="number"
          value={dscr}
          min="1"
          onChange={onChange}
        />
        {!refinancing && (
          <InlineFormField
            className="mt-3"
            name="loanSizingYear"
            type="number"
            value={loanSizingYear}
            min="1"
            max={holdPeriod}
            onChange={onChange}
          />
        )}
        <InlineInfoField label="NOI" value={formatCurrency(annualizedNOIs[loanSizingYear - 1])} className="mt-3" />
      </>
    );
  } else if (loanSizingMethod === LOAN_SIZING_METHOD_MANUAL) {
    loanSizingParameters = (
      <InlineFormField
        className="mt-3"
        name={refinancing ? 'refinancingLoanSize' : 'loanSize'}
        type="number"
        value={loanSize}
        min="0"
        onChange={onChange}
      />
    );
  } else {
    throw new Error(`Unsupported loan sizing method: ${loanSizingMethod}`);
  }
  return (
    <>
      <InlineFormField
        className="mt-3"
        name={refinancing ? 'refinancingSizingMethod' : 'loanSizingMethod'}
        type="select"
        value={loanSizingMethod}
        options={refinancing ? LOAN_SIZING_METHODS.slice(1) : LOAN_SIZING_METHODS}
        onChange={onChange}
      />
      {loanSizingParameters}
    </>
  );
}

function LoanParameters(props) {
  const {
    acquisitionCost,
    annualizedNOIs,
    cashFlows,
    dcfParams,
    isRefinanced,
    onChange,
    refinancing,
    totalFollowOnCapital,
  } = props;
  const loanParams = refinancing ? parseRefinancingParams(dcfParams) : dcfParams;
  const {
    amoritization,
    couponRate,
    interestOnly,
    loanOriginationFeeRate,
    loanSizingMethod,
    term,
  } = loanParams;
  const loanValuation = calcLoanValuation(annualizedNOIs, dcfParams, refinancing);

  const onCheckRefinance = () => {
    onChange({
      target: {
        name: 'refinancingSizingMethod',
        value: isRefinanced ? null : 'ltv',
      },
    });
  };

  const requiresValuation = [LOAN_SIZING_METHOD_LTC, LOAN_SIZING_METHOD_LTV].includes(loanSizingMethod);
  let loanProceeds;
  if (refinancing) {
    loanProceeds = cashFlows.financing.refinancingLoanPaymentRepayments[dcfParams.term];
  } else {
    loanProceeds = cashFlows.financing.acquisitionLoanPaymentRepayments[0] + sum(cashFlows.financing.futureFundingMonthlyDraws);
  }

  return (
    <div className="flex flex-col w-120 p-3 bg-white">
      <InlineInfoField className="mt-3" justify="justify-center">
        <div className="text-sm text-gray-700 uppercase tracking-wide">{refinancing ? 'Refinancing' : 'Acquisition'}</div>
      </InlineInfoField>
      {!refinancing && (
        <InlineFormField
          className="mt-3"
          type="checkbox"
          label="Refinance"
          value={isRefinanced || ''}
          checked={isRefinanced}
          onChange={onCheckRefinance}
        />
      )}
      <InlineInfoField
        className={refinancing ? 'mt-12' : 'mt-3'}
        label="Loan Proceeds"
        value={formatCurrency(isFinite(loanProceeds) ? loanProceeds : null)}
      />
      <LoanSizingParameters
        acquisitionCost={acquisitionCost}
        annualizedNOIs={annualizedNOIs}
        dcfParams={dcfParams}
        onChange={onChange}
        refinancing={refinancing}
        totalFollowOnCapital={totalFollowOnCapital}
      />
      {requiresValuation && (
        <LoanValuationParameters
          annualizedNOIs={annualizedNOIs}
          dcfParams={dcfParams}
          loanValuation={loanValuation}
          onChange={onChange}
          refinancing={refinancing}
        />
      )}
      <InlineFormField
        className="mt-3"
        name={refinancing ? 'refinancingCouponRate' : 'couponRate'}
        type={PERCENT_TYPE}
        value={couponRate}
        min="0"
        onChange={onChange}
      />
      <InlineFormField
        className="mt-3"
        name={refinancing ? 'refinancingTerm' : 'term'}
        label="Term (months)"
        type="number"
        value={term}
        onChange={onChange}
      />
      <InlineFormField
        className="mt-3"
        name={refinancing ? 'refinancingAmoritization' : 'amoritization'}
        label="Amoritization (months)"
        type="number"
        value={amoritization}
        min={term}
        onChange={onChange}
      />
      <InlineFormField
        className="mt-3"
        name={refinancing ? 'refinancingInterestOnly' : 'interestOnly'}
        label="Interest Only (months)"
        type="number"
        value={interestOnly}
        min="0"
        onChange={onChange}
      />
      <InlineFormField
        className="mt-3"
        name={refinancing ? 'refinancingOriginationFeeRate' : 'loanOriginationFeeRate'}
        label="Loan Origination Fee Rate"
        type={PERCENT_TYPE}
        value={loanOriginationFeeRate}
        min="0"
        onChange={onChange}
      />
    </div>
  );
}

export default function DebtParameters({ cashFlows, dcfParams, onChange }) {
  const isRefinanced = !isNull(dcfParams.refinancingSizingMethod);
  const monthlyNetOperatingIncome = cashFlows.netOperatingIncome;
  const annualizedNOIs = annualizeMonthlyReturns(monthlyNetOperatingIncome);
  const originalLoanProceeds = cashFlows.financing.acquisitionLoanPaymentRepayments[0] + sum(cashFlows.financing.futureFundingMonthlyDraws);
  const originalLoanValuation = calcLoanValuation(annualizedNOIs, dcfParams, false);
  const totalFollowOnCapital = sum(cashFlows.capital.followOnCapitalExpenses.map(val => -val));
  const acquisitionCost = -first(cashFlows.acquisition.cost);
  const ltcBasis = originalLoanValuation + totalFollowOnCapital + (dcfParams.ltcIncludeAcquisitionCost ? acquisitionCost : 0);

  return (
    <div className="flex flex-row">
      <LoanParameters
        acquisitionCost={acquisitionCost}
        annualizedNOIs={annualizedNOIs}
        cashFlows={cashFlows}
        dcfParams={dcfParams}
        isRefinanced={isRefinanced}
        onChange={onChange}
        refinancing={false}
        totalFollowOnCapital={totalFollowOnCapital}
      />
      {isRefinanced && (
        <LoanParameters
          annualizedNOIs={annualizedNOIs}
          cashFlows={cashFlows}
          dcfParams={dcfParams}
          isRefinanced={isRefinanced}
          onChange={onChange}
          refinancing
        />
      )}
      <div className="p-3 pt-16 bg-white">
        <InlineInfoField
          className="mt-3"
          label="LTV"
          value={formatPercentage(originalLoanProceeds / originalLoanValuation)}
        />
        <InlineInfoField
          className="mt-3"
          label="LTC"
          value={formatPercentage(originalLoanProceeds / ltcBasis)}
        />
        <InlineInfoField
          className="mt-3"
          label="Year 1 DSCR"
          value={formatMultiplier(calcDscr(cashFlows)[0])}
        />
        <InlineInfoField
          className="mt-3"
          label="Year 1 Debt Yield"
          value={formatPercentage(calcDebtYield(annualizedNOIs, originalLoanProceeds)[0])}
        />
      </div>
    </div>
  );
}
