import { partial, take } from 'lodash';
import Input from 'components/Input';
import ChipFilter from 'components/shared/filters/ChipFilter';

export default function ListFilter({ label, filterKey, filterParams, onApply, onReset, setFilter, values }) {
  const formattedLabel = filterParams.length
    ? `${take(filterParams, 3).join(', ')}${filterParams.length > 3 ? '...' : ''}`
    : label;

  return (
    <ChipFilter filtered={filterParams.length} label={formattedLabel} filterKey={filterKey} filterParams={filterParams} onApply={onApply} onReset={onReset} setFilter={setFilter}>
      {({ filterState, setFilterState }) => {
        const onChange = (value) => {
          setFilterState(prevState => {
            if (prevState.includes(value)) {
              return [...prevState.filter(v => v !== value)];
            } else {
              return [...prevState, value];
            }
          });
        };

        return (
          <div className="w-96 max-h-96 overflow-y-scroll">
            {values.toSorted().map(value => (
              <div key={value} className="mb-3">
                <label className="cursor-pointer" htmlFor={value}>
                  <Input
                    toggleClassName="mr-3"
                    type="checkbox"
                    name={value}
                    value={filterState.includes(value)}
                    onChange={partial(onChange, value)}
                  />
                  {value}
                </label>
              </div>
            ))}
          </div>
        );
      }}
    </ChipFilter>
  );
}
