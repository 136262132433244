import { Table } from 'docx';
import { addMonths, startOfMonth } from 'date-fns';
import { chunk, range, sum, sumBy } from 'lodash';
import { dateFromString, formatCurrency, formatDate, formatPercentage, sumArrays } from 'components/utils';
import { annualizeMonthlyReturns } from 'components/finance';
import { calcAcquisitionCosts } from 'components/dcf/dcf';
import { BOLD_STYLE, HEADER_STYLE, makeTableRow, tableHeader, tableProperties, UNDERLINE_STYLE } from './util';
import { formatCashFlowRow } from './cash-flow';

function calcEntryCapRate(cashFlows, dcfParams) {
  const { purchasePrice } = dcfParams;
  const { effectiveGrossRevenues } = cashFlows.revenue;
  const { totalOperatingExpenses } = cashFlows.expenses;

  let netOperatingIncome = sumArrays(effectiveGrossRevenues, totalOperatingExpenses.map(v => v * -1));
  if (netOperatingIncome.length % 12 === 0) {
    // add year 0 value for consistency
    netOperatingIncome = [0, ...netOperatingIncome];
  }
  // Pluck first year NOI
  netOperatingIncome = annualizeMonthlyReturns(netOperatingIncome.slice(1), netOperatingIncome.length, sum)[0];
  return netOperatingIncome / purchasePrice;
}

export default function buildEntryExitSummary(cashFlows, dcfParams) {
  const { closingDate, exitCapRate, holdPeriod, purchasePrice, units } = dcfParams;
  const entryCapRate = calcEntryCapRate(cashFlows, dcfParams);
  const grossSalePrice = cashFlows.sale.price[cashFlows.sale.price.length - 1];
  const { costOfSalePercent } = dcfParams.portfolio.parameters;
  const costOfSaleOfGrossPrice = (grossSalePrice * costOfSalePercent);
  const costOfSaleOfPricePerUnit = ((grossSalePrice / units.length) * costOfSalePercent);
  const costOfSaleOfPSF = ((grossSalePrice / sumBy(dcfParams.units, 'rsf')) * costOfSalePercent);

  const followOnCapitalProjects = cashFlows.capital.followOnCapitalExpenses[0] * -1;
  const totalCapitalization = calcAcquisitionCosts(dcfParams) + purchasePrice + followOnCapitalProjects;
  const totalCapitalizationPerUnit = totalCapitalization / units.length;
  const totalCapitalizationPerPSF = totalCapitalization / sumBy(dcfParams.units, 'rsf');

  const months = range(1, (holdPeriod + 1) * 12 + 1);
  const parsedClosingDate = dateFromString(closingDate);
  const dates = months.map(month => startOfMonth(addMonths(parsedClosingDate, month)));
  const annualizedDates = chunk(dates, 12).map(datesChunk => datesChunk[0]);

  const originationFeeRow = cashFlows.financing.loanOriginationFees;
  const futureFundingDraws = cashFlows.financing.futureFundingMonthlyDraws;
  const acquisitionLoanPaymentRepaymentsRow = cashFlows.financing.acquisitionLoanPaymentRepayments;
  const refinancingLoanPaymentRepaymentsRowData = cashFlows.financing.refinancingLoanPaymentRepayments;
  const totalLoanDrawsRepaymentsRow = sumArrays(acquisitionLoanPaymentRepaymentsRow, refinancingLoanPaymentRepaymentsRowData, originationFeeRow, [0].concat(futureFundingDraws));

  const totalLoanDrawsRepaymentsRowData = formatCashFlowRow(annualizedDates, totalLoanDrawsRepaymentsRow);
  const netLoanPayoff = totalLoanDrawsRepaymentsRowData[totalLoanDrawsRepaymentsRowData.length - 2];
  const netLoanPayoffPerUnit = netLoanPayoff / units.length;
  const netLoanPayoffPerPSF = totalLoanDrawsRepaymentsRowData[totalLoanDrawsRepaymentsRowData.length - 2] / sumBy(dcfParams.units, 'rsf');
  const finalNetSaleProceed = grossSalePrice - costOfSaleOfGrossPrice - Math.abs(netLoanPayoff);

  return new Table({
    ...tableProperties,
    rows: [
      tableHeader('Entry / Exit Summary ($ in 000s)', 8),
      makeTableRow('Hold Period / Closing:', 2, [`${holdPeriod} Years / ${formatDate(closingDate, 'MM-dd-yyyy')}`], 6),
      makeTableRow('Entry / Exit Cap Rate:', 2, [`${formatPercentage(entryCapRate)} / ${formatPercentage(exitCapRate)}`], 6),
      makeTableRow('Entry', 2, ['$', '$/Unit', '$PSF'], 2, HEADER_STYLE),
      makeTableRow('Total Purchase Price', 2, [formatCurrency(purchasePrice), formatCurrency(purchasePrice / units.length), formatCurrency(dcfParams.purchasePrice / sumBy(dcfParams.units, 'rsf'))], 2),
      makeTableRow('Total Capitalization', 2, [formatCurrency(totalCapitalization), formatCurrency(totalCapitalizationPerUnit), formatCurrency(totalCapitalizationPerPSF)], 2, BOLD_STYLE),
      makeTableRow('Exit', 2, [null], 6, HEADER_STYLE),
      makeTableRow('Gross Sales Price', 2, [formatCurrency(grossSalePrice), formatCurrency(grossSalePrice / units.length), formatCurrency(grossSalePrice / sumBy(dcfParams.units, 'rsf'))], 2),
      makeTableRow('Cost of Sale', 2, [formatCurrency(costOfSaleOfGrossPrice), formatCurrency(costOfSaleOfPricePerUnit), formatCurrency(costOfSaleOfPSF)], 2, UNDERLINE_STYLE),
      makeTableRow('Net Sale Proceeds', 2, [formatCurrency(grossSalePrice - costOfSaleOfGrossPrice), formatCurrency((grossSalePrice / units.length) - costOfSaleOfPricePerUnit), formatCurrency((grossSalePrice / sumBy(dcfParams.units, 'rsf')) - costOfSaleOfPSF)], 2, BOLD_STYLE),
      makeTableRow('Loan Payoff', 2, [formatCurrency(netLoanPayoff), formatCurrency(netLoanPayoffPerUnit), formatCurrency(netLoanPayoffPerPSF)], 2, UNDERLINE_STYLE),
      makeTableRow('Net Sale Proceeds', 2, [formatCurrency(finalNetSaleProceed), formatCurrency(finalNetSaleProceed / units.length), formatCurrency(finalNetSaleProceed / sumBy(dcfParams.units, 'rsf'))], 2, BOLD_STYLE),
    ],
  });
}
