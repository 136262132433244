import { useMemo } from 'react';
import { formatCurrency } from 'components/utils';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import DataTable from 'components/shared/Table/DataTable';
import { tdClassNameFn, theadClassName } from 'components/NhimbleDashboard/Shared';

export function AddressCell({ row: { original } }) {
  return (
    <Link to={`/properties/${original.attributes.property.id}`} className="flex w-max">
      <div className="h-9 w-9">
        <img
          className="h-9 w-9 rounded-lg object-cover"
          alt="propertyPhoto"
          src={original.attributes.property.photo}
        />
      </div>
      <div className="flex flex-col ml-3 text-left">
        <div className="text-sm">{original.attributes.property.address}</div>
        <div className="text-xs text-gray-500">
          {`${original.attributes.property.city}, ${original.attributes.property.state}, ${original.attributes.property.zip}`}
        </div>
      </div>
    </Link>
  );
}

const getColumns = (type) => {
  const commonColumns = [
    {
      header: 'Address',
      accessorKey: 'Address',
      cell: AddressCell,
    },
    {
      header: 'Market',
      accessorKey: 'attributes.property.market',
    },
    {
      header: 'Entity',
      accessorKey: 'attributes.property.entity',
    },
    {
      header: 'Year Built',
      accessorKey: 'attributes.property.yearBuilt',
    },
    {
      header: 'Bed',
      accessorKey: 'attributes.property.bed',
    },
    {
      header: 'Bath',
      accessorKey: 'attributes.property.bath',
    },
    {
      header: 'List Rent',
      accessorFn: row => `${formatCurrency(row.attributes.listRent)}`,
    },
  ];

  switch (type) {
    case 'Available Units':
      return [
        ...commonColumns,
        {
          header: 'DOM',
          accessorKey: 'attributes.dom',
        },
        {
          header: '# of Showings',
          accessorKey: 'attributes.numberOfShowings',
        },
        {
          header: '# of Applications',
          accessorKey: 'attributes.numberOfApplications',
        },
        {
          header: '# of Open Applications',
          accessorKey: 'attributes.numberOfOpenApplications',
        },
      ];
    case 'Long DOM':
      return [
        ...commonColumns,
        {
          header: 'DOM',
          accessorKey: 'attributes.dom',
        },
        {
          header: '# of Showings',
          accessorKey: 'attributes.numberOfShowings',
        },
        {
          header: '# of Applications',
          accessorKey: 'attributes.numberOfApplications',
        },
        {
          header: '# of Open Applications',
          accessorKey: 'attributes.numberOfOpenApplications',
        },
      ];
    case 'Ready to List':
      return [
        ...commonColumns,
        {
          header: 'Date Last Vacant',
          accessorFn: row => `${row.attributes.dateLastVacant ? dayjs(row.attributes.dateLastVacant).format('MM/DD/YYYY') : '-'}`,
        },
        {
          header: 'Ready for Marketing Date',
          accessorFn: row => `${row.attributes.readyForMarketingDate ? dayjs(row.attributes.readyForMarketingDate).format('MM/DD/YYYY') : '-'}`,
        },
        {
          header: 'Days to List',
          accessorFn: row => `${row.attributes.daysToList}`,
        },
      ];
    default:
      return commonColumns;
  }
};

const TABLE_META = { textAlign: 'center' };

export default function LeaseTable({ filteredData, tableType }) {
  const columns = useMemo(() => getColumns(tableType), [tableType]);

  return (
    <div className="border">
      <DataTable
        columns={columns}
        data={filteredData}
        emptyStateComponent="There are no available properties matching your criteria"
        theadClassName={theadClassName}
        tdClassName={tdClassNameFn}
        meta={TABLE_META}
      />
    </div>
  );
}
