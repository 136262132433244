import { formatAddress, formatCurrency, formatDate, formatInteger, titleCase } from 'components/utils';
import { Link } from 'react-router-dom';
import Button from 'components/shared/NewButton';

const PARCEL_TYPES_MAP = {
  SF: 'Single Family',
  MF: 'Multi Family',
  MU: 'Multi Purpose',
  CO: 'Commercial',
  OT: 'Other',
};

const UNMASKED_OWNER_TYPE_MAP = {
  E: 'Education Institution',
  G: 'Government',
  I: 'Investor',
  L: 'LLC',
  P: 'Person',
}

function AttributeRow({ label, value }) {
  return (
    <div className="w-full py-1 flex flex-row justify-between items-center text-xs text-gray-500 border-b">
      <span>{ label }</span>
      <span>{ value || '' }</span>
    </div>
  );
}

function Statistic({ label, value, className }) {
  return (
    <div className={`px-4 border-r border-gray-200 flex flex-col items-center justify-evenly ${className}`}>
      <div className="text-lg">{ value || '-' }</div>
      <div className="text-xs font-light">{ label }</div>
    </div>
  );
}

function UnitMix({ item }) {
  const {
    bedCount,
    bathCount,
    unitsCount,
    trulivParcelCode,
  } = item;

  const isSingleFamily = trulivParcelCode === 'SF';

  if (isSingleFamily) {
    return (
      <div className="flex">
        <Statistic label="Bed" value={bedCount} />
        <Statistic label="Bath" value={bathCount} className="border-none" />
      </div>
    );
  }

  return (
    <Statistic label="Units" value={unitsCount} className="pl-0" />
  );
}

export default function HighlightedProperty({ item }) {
  const {
    buildingSqFt,
    effectiveYearBuilt,
    id,
    isOwnerOccupied,
    lastSaleAmount,
    lastSaleDate,
    lastSaleDocumentType,
    lotSizeSqFt,
    ownerAssessor,
    ownerUnmask,
    propertyUseStandardizedCode,
    situsCounty,
    storiesCount,
    trulivParcelCode,
    yearBuilt,
  } = item;

  const assessorOwner = ownerAssessor?.[0];
  const unmaskedOwner = ownerUnmask?.[0];

  return (
    <div className="w-full bg-gray-50">
      <div className="text-base whitespace-pre-wrap border-b p-4">
        <div>{formatAddress(item)}</div>
        <div className="text-xs text-gray-500 font-light">{`Off-Market • ${PARCEL_TYPES_MAP[trulivParcelCode] || ''} • ${situsCounty}`}</div>
      </div>
      <div className="w-full px-2 flex flex-row">
        <div className="w-1/2 p-2">
          <AttributeRow label="RSF/FAR" value={(buildingSqFt && lotSizeSqFt) ? `${formatInteger(buildingSqFt)} / ${(buildingSqFt / lotSizeSqFt).toFixed(2)}x` : `${formatInteger(buildingSqFt)} / - `} />
          <AttributeRow label="Property Type" value={propertyUseStandardizedCode} />
          <AttributeRow label="Floors" value={storiesCount} />
          <AttributeRow label={`${effectiveYearBuilt ? 'Eff. Year Built' : 'Year Built'}`} value={effectiveYearBuilt || yearBuilt} />
          <AttributeRow label="Last Sale" value={lastSaleDate ? formatDate(lastSaleDate, 'MM/d/yyyy') : ''} />
          <AttributeRow label="Last Sale Price" value={formatCurrency(lastSaleAmount)} />
          <AttributeRow label="Last Sale Type" value={lastSaleDocumentType} />
        </div>
        <div className="w-1/2 p-2">
          <AttributeRow label="Assessor Owner" value={titleCase(assessorOwner?.ownerName)} />
          <AttributeRow label="Owner Type" value={titleCase(assessorOwner?.ownerType)} />
          <AttributeRow label="Owner Occupied" value={isOwnerOccupied ? 'True' : 'False'} />
          <AttributeRow label="Owner Trust" value={titleCase(assessorOwner?.ownerTrustTypeCode)} />
          <AttributeRow label="Unmasked Owner" value={titleCase(unmaskedOwner?.ownerName)} />
          <AttributeRow label="Unmasked Owner Entity Count" value={unmaskedOwner?.occurrencesCount} />
          <AttributeRow label="Unmasked Owner Type" value={UNMASKED_OWNER_TYPE_MAP[unmaskedOwner?.ownerType]} />
        </div>
      </div>
      <div className="flex flex-row justify-between items-center p-2">
        <UnitMix item={item} />
        <Link to={`/properties/${id}/fips_apn`} target="_blank" className={`${!id && 'disabled cursor-not-allowed pointer-events-none'}`}>
          <Button label="View Property" disabled={!id} filled />
        </Link>
      </div>
    </div>
  );
}
