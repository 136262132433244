import { compact, identity, isNil } from 'lodash';
import { useId } from 'react';
import { useOrganizedDemographicsData } from 'components/property/Neighborhood/neighborhood.utils';
import {
  KeyValueSubtitle,
  KeyValueTable,
  KeyValueTableData,
  KeyValueTableRow,
  KeyValueTableRowHeader,
  KeyValueTitle,
} from 'components/shared/KeyValueTable';
import PercentChange from 'components/shared/PercentChange';
import { useFetchPropertyLocalQuery } from '../../redux/apiSlice';

function NonProjectionCell({ children, ...props }) {
  return (
    // horizontal padding must match that of projection cells
    <div {...props} className="px-1.5">{children}</div>
  );
}

const DEMOGRAPHIC_DIM_LABEL = {
  censusTract: 'Census Tract',
  zip: 'Zip Code',
  msa: 'MSA',
};

export default function SubdivisionAreaDetailsTable({ averageSchoolRating, demographics: subdivisionDemographics, latitude, longitude, className }) {
  const { currentData: { demographics: demographicsData } = {}, isFetching } = useFetchPropertyLocalQuery({ longitude, latitude }, { skip: subdivisionDemographics && compact(Object.values(subdivisionDemographics)).length });
  const demographicsRaw = subdivisionDemographics || demographicsData;
  const demographics = useOrganizedDemographicsData(demographicsRaw ?? {});
  // pick first non-null key from ordered preferences of demo dimension
  const demographicsDim = ['censusTract', 'zip', 'msa'].find((dim) => !isNil(demographicsRaw?.[dim]));
  const demographicAreaName = demographicsRaw?.[demographicsDim]?.name;
  const demographicVintage = demographicsRaw?.[demographicsDim]?.vintage;

  const id = useId();

  return (
    <KeyValueTable className={className} loading={isFetching} aria-labelledby={`${id}title`}>
      <KeyValueTitle id={`${id}title`}>
        {`Area Details${demographicAreaName ? `: ${DEMOGRAPHIC_DIM_LABEL[demographicsDim]} - ${demographicAreaName}` : ''}`}
        {demographicVintage && <KeyValueSubtitle>{`Source: US Census Bureau ${demographicVintage.substring(0, 4)}`}</KeyValueSubtitle>}
      </KeyValueTitle>
      <KeyValueTableRow key="School Rating">
        <KeyValueTableRowHeader>Great Schools Rating</KeyValueTableRowHeader>
        <KeyValueTableData>
          <NonProjectionCell>{averageSchoolRating?.toFixed(1) ?? '-'}</NonProjectionCell>
        </KeyValueTableData>
      </KeyValueTableRow>
      {demographics.map(({ demographicKey, [demographicsDim]: value, formatter = identity, isProjection }) => (
        <KeyValueTableRow key={demographicKey}>
          <KeyValueTableRowHeader>{demographicKey}</KeyValueTableRowHeader>
          <KeyValueTableData>
            {isProjection ? (
              <PercentChange value={value} />
            ) : (
              <NonProjectionCell>{formatter(value) ?? '-'}</NonProjectionCell>
            )}
          </KeyValueTableData>
        </KeyValueTableRow>
      ))}
    </KeyValueTable>
  );
}
