import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCopyDealMutation } from 'redux/apiSlice';
import { clearDealNavigationModal } from 'actions/deal_navigation';
import Alert from 'components/Alert';
import Button from 'components/shared/NewButton';
import DashboardModal from 'components/dashboard/DashboardModal';

export default function CopyDealModal({ context }) {
  const dealId = context.data.deal.id;
  const navigate = useNavigate();
  const [alert, setAlert] = useState(null);
  const [copyDealMutation, { isLoading }] = useCopyDealMutation();

  const dispatch = useDispatch();
  const onClose = () => dispatch(clearDealNavigationModal());

  const copyDeal = async () => {
    setAlert(null);
    try {
      const response = await copyDealMutation(dealId).unwrap();
      navigate(`/deals/${response.id}`);
      onClose();
    } catch (err) {
      console.error('Failed to copy deal: ', err);
      setAlert({ text: 'Failed to copy deal', type: 'danger' });
    }
  };

  return (
    <DashboardModal
      reverse
      title="Copy Deal"
      className="py-10"
      action={(
        <Button
          filled
          label="Copy"
          isLoading={isLoading}
          onClick={() => copyDeal()}
        />
      )}
      onClose={onClose}
    >
      <p>Creates a copy of the deal. Transaction info and tasks of copied deal will be reset. This will not affect the current deal.</p>
      {alert && <Alert className="mt-3" {...alert} />}
    </DashboardModal>
  );
}
