import { formatCurrencyAbbreviated } from 'components/utils';
import { INACTIVE_DEAL_STATES } from 'components/constants';

export default function SummaryWidget({ title, data: { count, value, units }, dealStage, taskCount, taskListElId }) {
  const summaryLabel = `${title || 'All Active'} Deals`;
  return (
    <>
      <div className="font-semibold text-xl">{summaryLabel}</div>
      <div className="flex opacity-50">
        <div className="mr-4" onClick={() => document.getElementById(taskListElId).scrollIntoView(true)}>
          <span className="font-semibold">{count}</span>
          &nbsp;deals
        </div>
        <div className="mr-4" onClick={() => document.getElementById(taskListElId).scrollIntoView(true)}>
          <span className="font-semibold">{units}</span>
          &nbsp;units
        </div>
        <div className="mr-4" onClick={() => document.getElementById(taskListElId).scrollIntoView(true)}>
          <span className="font-semibold">{formatCurrencyAbbreviated(value)}</span>
          &nbsp;value
        </div>
        {(!INACTIVE_DEAL_STATES.includes(dealStage)) && (
          <div className="underline cursor-pointer" onClick={() => document.getElementById(taskListElId).scrollIntoView(true)}>
            <span className="font-semibold">{taskCount}</span>
            &nbsp;Upcoming Tasks
          </div>
        )}
      </div>
    </>
  );
}
