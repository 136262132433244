document.addEventListener('DOMContentLoaded', () => {
  const FORBIDDEN_SEGMENTS = ['organizations', 'portfolios', 'pipeline', 'users', 'sidekiq'];
  const DEAL_WORKFLOW_SEGMENTS = ['deal_sourcing', 'listings', 'deals'];
  const HOME_PAGE_HASH = '#home';
  const currentSegment = window.location.pathname.split('/')[1];
  const pageHash = window.location.hash;

  const updateFilters = () => {
    localStorage.removeItem('paramsUnsaved');
    const openTabs = JSON.parse(localStorage.getItem('openTabs'));
    // true => segment is part of DEAL_WORKFLOW_SEGMENTS
    // false => segment is not part of DEAL_WORKFLOW_SEGMENTS
    const mappedSegments = Object.keys(openTabs).map(segment => (openTabs[segment] > 0 && DEAL_WORKFLOW_SEGMENTS.includes(segment)));

    // if mappedSegments includes true there is an opened tabs which has deal sourcing workflow
    if (mappedSegments.includes(true)) return;
    // if mappedSegments has no true there is no opened tabs which has deal sourcing workflow
    if (FORBIDDEN_SEGMENTS.includes(currentSegment) || pageHash === HOME_PAGE_HASH) {
      localStorage.removeItem('filters');
    }
  };

  window.addEventListener('load', () => {
    const openTabs = localStorage.getItem('openTabs') ? JSON.parse(localStorage.getItem('openTabs')) : {};
    if (openTabs[currentSegment] >= 0) {
      openTabs[currentSegment] += 1;
    }
    if (Object.keys(openTabs).length === 0 || openTabs[currentSegment] === undefined) {
      openTabs[currentSegment] = 1;
    }
    localStorage.setItem('openTabs', JSON.stringify(openTabs));
    updateFilters();
  });
  window.addEventListener('unload', () => {
    const updatedTabs = JSON.parse(localStorage.getItem('openTabs'));
    if (updatedTabs[currentSegment] > 0) {
      updatedTabs[currentSegment] -= 1;
      localStorage.setItem('openTabs', JSON.stringify(updatedTabs));
      updateFilters();
    }
  });

  window.addEventListener('beforeunload', (ev) => {
    const openTabs = JSON.parse(localStorage.getItem('openTabs'));
    const paramsUnsaved = JSON.parse(localStorage.getItem('paramsUnsaved'));
    const mappedSegments = Object.keys(openTabs).map(segment => (openTabs[segment] > 0 && DEAL_WORKFLOW_SEGMENTS.includes(segment)));
    if (mappedSegments.includes(true) && paramsUnsaved) {
      // these days all major browsers don't support custom message in the beforeunload popup.
      // There is no new way to do this. below message is to support old browsers
      // eslint-disable-next-line no-param-reassign
      ev.returnValue = 'There are unsaved changes to the model parameters, are you sure you would like to leave?';
    }
  });
}, false);
