import { partial } from 'lodash';
import AllFilters from 'components/rentComps/AllFilters';
import { useFilterComponents, useInitialFilters } from 'components/rentComps/rentComps';

export default function FilterBar({ filters, filterContext, setFilter, setColumnFilters }) {
  const emptyFilters = useInitialFilters();

  return (
    <div className="flex flex-wrap gap-2 items-center">
      {useFilterComponents(filterContext).map(([FilterComponent, filterKey, props], index) => (
        <FilterComponent
          {...props}
          key={`${filterKey}-${index}`}
          filters={filters}
          filterParams={filters.find(({ id }) => id === filterKey).value}
          onApply={partial(setFilter, filterKey)}
          onReset={() => setFilter(filterKey, emptyFilters[filterKey])}
          filterKey={filterKey}
          setFilter={setFilter}
          setColumnFilters={setColumnFilters}
        />
      ))}
      <AllFilters
        filters={filters}
        setFilter={setFilter}
        filterContext={filterContext}
      />
    </div>
  );
}
