import { forwardRef } from 'react';
import cx from 'classnames';

// TODO: make customizable
const CELL_PADDING = 'px-1.5';

// tableHeight is a required prop if you want sticky header functionality
// as the table needs a defined height to leverage "position: sticky"
const Table = forwardRef(({ containerClassName, className, tableHeight, onScroll, style, ...props }, ref) => (
  <div className={cx('w-full overflow-x-auto bg-white [&_:where(*)]:tabular-nums', containerClassName)} style={tableHeight ? { maxHeight: tableHeight, ...style } : style} onScroll={onScroll} ref={ref}>
    <table
      className={cx('w-full text-sm', className)}
      {...props}
    />
  </div>
));
Table.displayName = 'Table';

const TableHeader = forwardRef(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cx('sticky top-0 z-20 bg-white shadow-sm [&_tr]:border-b', className)}
    {...props}
  />
));
TableHeader.displayName = 'TableHeader';

const TableBody = forwardRef(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cx('[&_tr:last-child]:border-0', className)}
    {...props}
  />
));
TableBody.displayName = 'TableBody';

const TableFooter = forwardRef(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cx(
      'sticky bottom-0 z-20 bg-white bg-gray/50 font-medium [&_tr]:border-t last:[&>tr]:border-b-0',
      className,
    )}
    {...props}
  />
));
TableFooter.displayName = 'TableFooter';

const TableRow = forwardRef(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cx(
      'transition-colors group data-[state=selected]:bg-primary-focus',
      className,
      { 'cursor-pointer': props.onClick },
    )}
    {...props}
  />
));
TableRow.displayName = 'TableRow';

const TableHead = forwardRef(({ noPadding, className, ...props }, ref) => (
  <th
    ref={ref}
    className={cx(
      'h-10 align-middle font-medium [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px] select-none',
      noPadding ? 'p-0' : CELL_PADDING,
      className,
    )}
    {...props}
  />
));
TableHead.displayName = 'TableHead';

const TableCell = forwardRef(({ noPadding, className, whitespace = 'whitespace-nowrap', tfoot = false, ...props }, ref) => (
  <td
    ref={ref}
    className={cx(
      'align-middle border-b whitespace-nowrap',
      // use translucent background to blend with tr background
      !tfoot && 'group-hover:bg-gray-200/25 [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
      tfoot && 'h-10 font-medium',
      noPadding ? 'p-0' : CELL_PADDING,
      whitespace,
      className,
    )}
    {...props}
  />
));
TableCell.displayName = 'TableCell';

const TableCaption = forwardRef(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cx('mt-4 text-sm', className)}
    {...props}
  />
));
TableCaption.displayName = 'TableCaption';

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};
