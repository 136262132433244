import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'components/NewModal';
import { clearDealsModal } from 'actions/deals';
import { ButtonTab, HorizontalTabs } from 'components/shared/Tabs';
import TaskEmails from './TaskEmails';
import EmailForm from './EmailForm';

const TABS = [
  { label: 'Templates', component: EmailForm },
  { label: 'Outbox', component: TaskEmails },
];

function TaskEmailModal({ currentUser, task = {} }) {
  const dispatch = useDispatch();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const ActiveComponent = TABS[activeTabIndex].component;

  const handleCloseModal = () => {
    dispatch(clearDealsModal());
  };

  return (
    <Modal showHeader={false} size="extraLarge" onClose={handleCloseModal} show>
      <HorizontalTabs>
        {TABS.map(({ label }, index) => (
          <ButtonTab key={index} active={index === activeTabIndex} onClick={() => setActiveTabIndex(index)}>
            {label}
          </ButtonTab>
        ))}
      </HorizontalTabs>
      <ActiveComponent currentUser={currentUser} task={task} />
    </Modal>
  );
}

export default TaskEmailModal;
