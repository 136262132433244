import { useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { clearDealsModal } from 'actions/deals';
import { useCreateTaskMutation, useUpdateTaskMutation } from 'redux/apiSlice';
import Button from 'components/Button';
import { FormField } from 'components/Form';
import { parseEventValue } from 'components/utils';
import { useUserOptions } from '../../DealConstruction/dealConstruction';
import DashboardModal from '../DashboardModal';

export default function EditTaskModal({ milestone }) {
  const dispatch = useDispatch();
  const { task: baseTask, isNew } = useSelector(state => state.deals.modal);
  const [task, setTask] = useState(baseTask);
  const [createTaskMutation] = useCreateTaskMutation();
  const [updateTaskMutation] = useUpdateTaskMutation();
  const userOptions = useUserOptions({ organizationId: baseTask.organizationId, includeUsers: [baseTask.userId] });

  const { name, dueDate, completedAt, notes, userId, fields } = task;

  const onTaskChange = (event) => {
    const param = event.target.name;
    const newValue = parseEventValue(event);

    const updatedTask = cloneDeep(task);
    updatedTask[param] = newValue;
    setTask(updatedTask);
  };

  userOptions.unshift(['', '']);

  const modalTitle = isNew ? `Add Task to ${milestone.name}` : `Update Task: ${name}`;

  return (
    <DashboardModal
      title={modalTitle}
      action={(
        <Button
          confirm
          text={`${isNew ? 'Add' : 'Update'} Task`}
          className="font-semibold border border-black border-opacity-12"
          shadow={false}
        />
      )}
      onClose={() => dispatch(clearDealsModal())}
      onSubmit={async (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (isNew) {
          // TODO: handle response
          await createTaskMutation({ ...task, milestoneId: milestone.id });
          dispatch(clearDealsModal());
        } else {
          // TODO: handle response
          await updateTaskMutation(task);
          dispatch(clearDealsModal());
        }
      }}
    >
      <div className="flex flex-col gap-y-8">
        <FormField
          required
          name="name"
          label="Task Name"
          value={name ?? ''}
          type="text"
          padding="py-2 px-3 border border-black border-opacity-12"
          onChange={onTaskChange}
        />

        <FormField
          name="userId"
          label="Assigned To"
          value={userId ?? ''}
          type="select"
          options={userOptions}
          padding="py-2 px-3 border border-black border-opacity-12"
          onChange={onTaskChange}
        />

        <FormField
          name="dueDate"
          value={dueDate}
          type="date"
          textAlign="text-left"
          padding="py-2 px-3 border border-black border-opacity-12"
          onChange={onTaskChange}
        />

        {/* Completed At is only editable when it's not a new task and it doesn't have a form */}
        {!isNew && isEmpty(fields) && (
          <FormField
            name="completedAt"
            value={completedAt}
            type="date"
            textAlign="text-left"
            padding="py-2 px-3 border border-black border-opacity-12"
            onChange={onTaskChange}
          />
        )}
        <FormField
          name="notes"
          value={notes}
          type="text-area"
          textAlign="text-left"
          padding="py-2 px-3 border border-black border-opacity-12"
          onChange={onTaskChange}
        />
      </div>
    </DashboardModal>
  );
}
