import Button from 'components/shared/NewButton';
import { LoadingIndicator } from 'components/icons';
import Modal from 'components/Modal';

export default function DashboardModal({ title, action, children, onClose, onSubmit, loading = false, reverse = false }) {
  const ModalBody = (action && onSubmit && !loading) ? 'form' : 'div';

  return (
    <Modal show title={title} onClose={onClose}>
      <ModalBody className="flex flex-col w-96 mt-6 overflow-y-auto" onSubmit={action ? onSubmit : undefined}>
        {loading ? (
          <div className="flex h-36 w-full justify-center items-center">
            <LoadingIndicator className="w-8 text-blue-400" />
          </div>
        ) : (
          <>
            <div>
              {children}
            </div>
            {action && (
              <div className={`mt-8 flex gap-x-4 ${reverse && 'flex-row-reverse'}`}>
                {action}
                <Button
                  textOnly
                  label="Cancel"
                  onClick={onClose}
                />
              </div>
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
}
