import { LAYOUT } from './constants';
import LogoPath from 'assets/full_logo_white.png'

function UnauthenticatedNavigation() {
  return (
    <div
      className="bg-black"
      style={{
        height: `${LAYOUT.unauthenticatedNavbarHeight}px`,
        padding: '1vw 3vw',
      }}
    >
      <div className="flex items-center h-full w-60">
        <a href="https://thehoneycomb.ai">
          <img
            src={LogoPath}
            alt="logo"
            className=""
          />
        </a>
      </div>
    </div>
  );
}

export default UnauthenticatedNavigation;
