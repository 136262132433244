import { formatCurrency } from 'components/utils';
import { map, mean, size } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { filteredLeases } from 'selectors/lease';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import LeaseTable from './LeaseTable';

const VACANT = 'Vacant';
const ON_THE_MARKET = 'On the Market';

function AvailableUnits() {
  const { market, entity, building } = useSelector(state => state.dashboardFilter.activeFilters);
  const leaseData = useSelector(filteredLeases);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const newData = leaseData.filter(lease => {
      const isVacant = lease.attributes.occupancyStatus === VACANT;
      const isOnMarket = lease.attributes.propertyStatus === ON_THE_MARKET;
      return isVacant && isOnMarket;
    });

    setFilteredData(newData);
  }, [leaseData]);

  const averageDOM = mean(map(filteredData, (lease) => lease.attributes.dom)).toFixed(2);

  const underListPercentage = useMemo(() => {
    const underOriginalList = leaseData.filter(lease => {
      const isUnderOriginalList = lease.attributes.underOriginalList;
      const isOnMarket = lease.attributes.propertyStatus === ON_THE_MARKET;
      const isMarketFiltered = market ? lease.attributes?.property?.market === market : true;
      const isEntityFiltered = entity ? lease.attributes?.property?.entity === entity : true;
      const isBuildingInFiltered = building ? lease.attributes?.property?.building === building : true;
      return isBuildingInFiltered && isUnderOriginalList && isOnMarket && isMarketFiltered && isEntityFiltered;
    });
    return ((size(underOriginalList) / filteredData.length) * 100).toFixed(2);
  }, [leaseData, market, entity, building, filteredData.length]);

  const metrics = [
    { label: 'Available Units', value: filteredData.length },
    { label: 'Avg. DOM', value: averageDOM },
    { label: '% Under Original List Price', value: formatCurrency(underListPercentage, 2) },
  ];

  return (
    <div className="flex flex-col mt-4 h-full">
      <ReturnMetricBoxes metrics={metrics} />
      <div className="flex-1 mt-4 pb-20 overflow-y-auto mb-5">
        <LeaseTable filteredData={filteredData} tableType="Available Units" />
      </div>
    </div>
  );
}

export default AvailableUnits;
