import { useEffect, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import classNames from 'classnames';
import Input from '../../Input';
import { LoadingIndicator } from '../../icons';
import { listingPath, propertyUpdatePath, searchPropertiesPath } from '../../routes';
import { camelCaseKeys, parseEventValue } from '../../utils';

const searchAddress = (address, setSearchResults, setLoading) => {
  if (!address) {
    return;
  }
  setLoading(true);
  const csrfToken = document.querySelector('[name=csrf-token]').content;
  fetch(searchPropertiesPath, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken,
    },
    body: JSON.stringify({ address }),
  })
    .then(response => response.json())
    .then(properties => {
      setLoading(false);
      setSearchResults(properties.map(property => camelCaseKeys(property)));
    });
};

const searchAddressDebounced = debounce(
  searchAddress,
  300,
  { leading: false, trailing: true },
);

export default function PropertySearch() {
  const [address, setAddress] = useState('');
  const [searchResultsHidden, setSearchResultsHidden] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChange = event => {
    setAddress(parseEventValue(event));
  };

  useEffect(
    () => searchAddressDebounced(address, setSearchResults, setLoading),
    [address],
  );

  const onBlur = event => {
    // do not toggle visibility when focus is shifted to a child node
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setSearchResultsHidden(true);
    }
  };

  let matchingProperties = null;
  if (address) {
    if (loading) {
      matchingProperties = <div className="flex py-2 justify-center"><LoadingIndicator className="w-6" /></div>;
    } else if (isEmpty(searchResults)) {
      matchingProperties = <div className="py-3 px-2 text-xs text-gray-500">No Results Found</div>;
    } else {
      matchingProperties = (
        <ul className="py-1">
          {searchResults.map((property) => {
            let link = propertyUpdatePath(property);
            const propertyListings = property.listings;
            propertyListings.forEach(listing => {
              if (!listing.closedOn) {
                link = listingPath(listing);
              }
            });
            return (
              <li className="text-xs cursor-pointer hover:underline hover:bg-blue-50" key={property.id}>
                <a
                  className="flex flex-col justify-between p-2"
                  href={link}
                >
                  <span>{property.address}</span>
                  <span className="text-xs text-gray-500">{`${property.city}, ${property.state}`}</span>
                </a>
              </li>
            );
          })}
        </ul>
      );
    }
  }

  return (
    <div
      className="relative w-full max-w-xs h-8"
      onFocus={() => setSearchResultsHidden(false)}
      onBlur={onBlur}
    >
      <Input
        type="text"
        name="address"
        value={address}
        placeholder="Search by address"
        onChange={onChange}
        width="w-full"
        className="h-full focus:outline-none text-xs"
      />
      <div className={classNames({ hidden: searchResultsHidden }, 'origin-top-left absolute z-50 left-0 w-full rounded-md shadow-lg bg-white focus:outline-none')}>
        {matchingProperties}
      </div>
    </div>
  );
}
