import { useState } from 'react';
import Input from '../Input';
import Alert from '../Alert';
import { LoadingIndicator, Search } from '../icons';
import { listingPath, propertyPath, searchPropertiesPath } from '../routes';
import { camelCaseKeys } from '../utils';
import { find, isEmpty, isNull, partial } from 'lodash';

const activeListing = (property) => find(property.listings, { closedOn: null });

function PropertySearchResults({ onPropertySelect, properties }) {
  if (isNull(properties)) {
    return null;
  }
  if (isEmpty(properties)) {
    return (
      <Alert className="my-2" type="warning" text="No properties matched the search" />
    );
  }
  return (
    <div>
      <label className="block mb-2 text-sm text-gray-500 font-medium">Search results</label>
      <ul>
        {properties.map(property => (
          <li className="flex flex-row justify-between cursor-pointer my-1 p-2 shadow text-sm hover:bg-blue-50" key={property.id} onClick={partial(onPropertySelect, property)}>
            <div>
              <p>{property.address}</p>
              <p className="text-xs text-gray-500">{`${property.city}, ${property.state}`}</p>
            </div>
            {activeListing(property) && <p className="my-auto pr-8 text-lg italic text-tertiary">Listed</p>}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default function PropertySearch({ setProperty }) {
  const [isSearching, setIsSearching] = useState(false);
  const [address, setAddress] = useState('');
  const [properties, setProperties] = useState(null);
  const [alert, setAlert] = useState(null);

  const onPropertySelect = (property) => {
    const listing = activeListing(property);
    if (listing) {
      window.location = listingPath(listing);
    } else {
      setAddress('');
      setProperties(null);
      const csrfToken = document.querySelector('[name=csrf-token]').content;
      fetch(propertyPath(property), {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
        }
      }).then(response => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      }).then(property => {
        setProperty(camelCaseKeys(property));
      }).catch(error => {
        setAlert({text: `Error: ${error.message}`, type: 'danger'})
      });
    }
  };

  const searchProperties = () => {
    if (!address || isSearching) {
      return;
    }
    setAlert(null);
    setProperties(null);
    setIsSearching(true);

    const csrfToken = document.querySelector('[name=csrf-token]').content;

    fetch(searchPropertiesPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
      body: JSON.stringify({address: address}),
    }).then(response => {
      setIsSearching(false);
      if (response.status !== 200) {
        throw new Error(response.statusText);
      } else {
        return response.json();
      }
    }).then(properties => {
      setProperties(properties.map(property => camelCaseKeys(property)));
    }).catch(error => {
      setAlert({text: `Error: ${error.message}`, type: 'danger'})
    });
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      searchProperties();
    }
  };

  return (
    <>
      <h1 className="mt-8 text-lg">Select the property that is being listed</h1>
      <label className="block mt-12 mb-2 text-sm text-gray-500 font-medium" htmlFor="address">Enter the street address of the property</label>
      <div className="flex flex-row mb-8">
        <Input type="text" name="address" value={address} onChange={(e) => setAddress(e.target.value)} onKeyPress={onKeyPress} width="w-96" />
        <button
          className="w-10 ml-2 p-2 rounded shadow bg-tertiary text-white hover:bg-tertiary-lighter active:bg-tertiary-darker focus:outline-none"
          onClick={searchProperties}
        >
          {isSearching ? <LoadingIndicator /> : <Search />}
        </button>
      </div>
      {alert && <Alert className="mt-4" {...alert} />}
      <PropertySearchResults onPropertySelect={onPropertySelect} properties={properties} />
    </>
  );
}
