import { Popover } from '@headlessui/react';
import { noop } from 'lodash';
import Button from 'components/shared/Button';
import { ArrowDropDown } from 'components/icons';
import Chip from 'components/shared/Chip';

export default function Filter({ children, filtered, label, onApply, onReset, width = 'w-96' }) {
  const onCancel = (e) => {
    e.stopPropagation();
    onReset(noop);
  };

  return (
    <Popover className="relative">
      <Popover.Button as="div">
        <Chip
          label={label}
          onCancel={onCancel}
          selected={filtered}
          trailingIcon={<ArrowDropDown />}
        />
      </Popover.Button>
      <Popover.Panel className="absolute z-10">
        {({ close }) => (
          <div className={`${width} bg-white border border-gray-200 rounded px-4 pt-6 pb-3 shadow`}>
            {children}
            <div className="flex justify-end mt-6 gap-x-4">
              <Button label="Reset" textOnly onClick={() => onReset(close)} />
              <Button label="Apply" onClick={() => onApply(close)} />
            </div>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
}
