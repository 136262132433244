/* eslint-disable prefer-destructuring */
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { createColumnHelper } from '@tanstack/react-table';
import { subDays } from 'date-fns';
import { cloneDeep, compact, every, isEmpty, isNil, isNumber, range, reverse, sortBy } from 'lodash';
import { formatCurrency, formatDate, formatInteger, formatListingSource, parseArrayString } from 'components/utils';
import BathFilter from 'components/shared/filters/BathFilter';
import BedFilter from 'components/shared/filters/BedFilter';
import CompScoreFilter from 'components/shared/filters/CompScoreFilter';
import ListFilter from 'components/shared/filters/ListFilter';
import RangeFilter from 'components/shared/filters/RangeFilter';
import RsfFilter from 'components/shared/filters/RsfFilter';
import SliderFilter from 'components/shared/filters/SliderFilter';
import { CompAddressToggleCell, LocationCompScoreCell, OverallCompScoreCell, PropertyCompScoreCell, RecencyCompScoreCell } from 'components/shared/Table/Cells';
import StatusCell from 'components/rentComps/StatusCell';
import {
  bedBathFilter,
  rentMapFilter,
  sourceNameFilter,
  zipCodeFilter,
} from 'components/shared/Table/table.filterFns';
import { dataTableMeta, tableConfigMeta } from 'components/shared/Table/table.helpers';
import { OFFSET_FILTERS } from 'components/portfolio/Menu/RentCompFilters';

export const CURRENT_COMP_SET = { id: 0, text: 'Current Comps' };

const EMPTY_UNIT_RENTS = { unitRents: [] };

const DEFAULT_ACTIVE_COMPS = 5;
const MINIMUM_COMP_SCORE = 2.0;

export const useInitialFilters = (property) => useMemo(() => ({
  actualRent: [null, null],
  currentAskingRent: [null, null],
  daysOnMarket: [null, null],
  distance: [null, 1],
  effectiveLastSeenAt: [null, null],
  geo: null,
  numberOfBathroomsTotal: [false],
  numberOfBedrooms: [false],
  numberOfUnits: [],
  // TODO: may need to be more general if subject is multi-family
  propertyType: property ? [property.propertySubType] : [],
  score: 0.5,
  propertyScore: 0.5,
  locationScore: 0.5,
  recencyScore: 0.5,
  resolvedStatus: [],
  sourceName: [],
  subdivision: [],
  unitRsf: [null, null],
  yearBuilt: [null, null],
  zipCode: '',
}), [property]);

const effectiveLastSeenAtFilterFn = (row, columnId, filterValue) => {
  // if filter value is empty (or all nulls), do not apply filter
  if (isEmpty(compact(filterValue))) return true;

  // if there is no CloseDate (i.e. it is active), do not filter it out
  if (!row.getValue(columnId)) return true;
  const rowTime = new Date(row.getValue(columnId));
  const startTime = filterValue[0]?.getTime();
  const endTime = filterValue[1]?.getTime();
  if ((startTime && (startTime > rowTime)) || (endTime && (endTime < rowTime))) {
    return false;
  }
};

const inFilterListFn = (row, columnId, filterValue) => {
  if (isEmpty(filterValue)) return true;
  return filterValue.includes(row.getValue(columnId));
};

const compScoreFn = (row, columnId, filterValue) => {
  if (filterValue === 0) return true;
  return row.getValue(columnId) >= filterValue;
};

const columnHelper = createColumnHelper();

export const rentCompTableColumns = (onPhotoClick, onInfoClick, selectedIdsObj, toggleSelected) => compact([
  {
    id: 'isSubject',
    accessorFn: ({ isSubject }) => !!isSubject,
    meta: { tableConfig: false },
  },
  columnHelper.accessor('geo', {
    filterFn: rentMapFilter,
    meta: { tableConfig: false },
  }),
  {
    header: 'Address',
    cell: (props) => (
      <CompAddressToggleCell
        {...props}
        active={selectedIdsObj[props.row.original.id]}
        toggleActive={() => toggleSelected(props.row.original.id)}
        onPhotoClick={onPhotoClick}
        onInfoClick={onInfoClick}
      />
    ),
    accessorKey: 'streetAddress',
    enableHiding: false,
    meta: { thClassName: 'pl-18', ...tableConfigMeta({ order: 'readonly' }) }
  },
  {
    id: 'city',
    header: 'City',
    accessorKey: 'city',
  },
  {
    id: 'state',
    header: 'State',
    accessorKey: 'stateOrProvince',
    cell: ({ getValue }) => getValue()?.toUpperCase(),
  },
  {
    id: 'zipCode',
    header: 'Zip Code',
    accessorKey: 'postalCode',
    filterFn: zipCodeFilter,
  },
  {
    id: 'score',
    header: 'Score',
    accessorFn: ({ compScores }) => compScores?.overall,
    cell: OverallCompScoreCell,
    sortingFn: 'basic',
    meta: { ...dataTableMeta.textCenter },
    filterFn: compScoreFn,
  },
  {
    id: 'propertyScore',
    header: 'Property Score',
    accessorFn: ({ compScores }) => compScores?.property?.[0],
    cell: PropertyCompScoreCell,
    sortingFn: 'basic',
    meta: { ...dataTableMeta.textCenter },
    filterFn: compScoreFn,
  },
  {
    id: 'locationScore',
    header: 'Location Score',
    accessorFn: ({ compScores }) => compScores?.location?.[0],
    cell: LocationCompScoreCell,
    sortingFn: 'basic',
    meta: { ...dataTableMeta.textCenter },
    filterFn: compScoreFn,
  },
  {
    id: 'recencyScore',
    header: 'Recency Score',
    accessorFn: ({ compScores }) => compScores?.recency?.[0],
    cell: RecencyCompScoreCell,
    sortingFn: 'basic',
    meta: { ...dataTableMeta.textCenter },
    filterFn: compScoreFn,
  },
  {
    id: 'distance',
    header: 'Distance',
    cell: ({ getValue }) => getValue()?.toFixed(2),
    accessorKey: 'distance',
    sortingFn: 'basic',
    filterFn: 'inNumberRange',
    sortDescFirst: false,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'subdivision',
    header: 'Subdivision',
    accessorKey: 'subdivision',
    filterFn: inFilterListFn,
  },
  {
    id: 'phase',
    header: 'Phase',
    accessorKey: 'phase',
  },
  {
    id: 'numberOfBedrooms',
    header: 'Bed',
    cell: ({ getValue }) => (getValue() === 0 ? 'Studio' : formatInteger(getValue())),
    accessorKey: 'bedroomsTotal',
    filterFn: bedBathFilter,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'numberOfBathroomsTotal',
    header: 'Bath',
    cell: ({ getValue }) => (getValue() ?? '-'),
    accessorKey: 'bathrooms',
    filterFn: bedBathFilter,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'unitRsf',
    header: 'RSF',
    cell: ({ getValue }) => formatInteger(getValue()),
    accessorKey: 'buildingArea',
    sortDescFirst: true,
    filterFn: 'inNumberRange',
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'currentAskingRent',
    header: 'List Price',
    cell: ({ getValue }) => formatCurrency(getValue()),
    accessorKey: 'listPrice',
    filterFn: 'inNumberRange',
    sortDescFirst: true,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'actualRent',
    header: 'Close Price',
    accessorKey: 'closePrice',
    cell: ({ getValue }) => formatCurrency(getValue()),
    filterFn: 'inNumberRange',
    sortDescFirst: true,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'resolvedStatus',
    header: 'Status',
    cell: StatusCell,
    accessorKey: 'standardStatus',
    filterFn: inFilterListFn,
  },
  {
    id: 'effectiveLastSeenAt',
    header: 'Close Date',
    cell: ({ getValue }) => formatDate(getValue(), 'MMM d, yyyy'),
    accessorKey: 'closeDate',
    sortingFn: 'datetime',
    sortDescFirst: true,
    filterFn: effectiveLastSeenAtFilterFn,
  },
  {
    header: 'DOM',
    cell: ({ getValue }) => formatInteger(getValue()),
    accessorKey: 'daysOnMarket',
    filterFn: 'inNumberRange',
    sortDescFirst: false,
    meta: { ...dataTableMeta.textRight },
  },
  {
    header: 'CDOM',
    cell: ({ getValue }) => formatInteger(getValue()),
    accessorKey: 'cumulativeDaysOnMarket',
    filterFn: 'inNumberRange',
    sortDescFirst: false,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'yearBuilt',
    header: 'Built',
    cell: ({ getValue }) => getValue() ?? '-',
    accessorKey: 'yearBuilt',
    sortDescFirst: true,
    filterFn: 'inNumberRange',
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'numberOfUnits',
    header: '# Units',
    cell: ({ getValue }) => formatInteger(getValue()),
    accessorKey: 'numberOfUnits',
    sortDescFirst: true,
    filterFn: 'inNumberRange',
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'propertyType',
    header: 'Property Type',
    accessorKey: 'propertySubType',
    filterFn: inFilterListFn,
  },
  {
    id: 'lotSize',
    header: 'Lot Size',
    accessorKey: 'lotSize',
    cell: ({ getValue }) => formatInteger(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'garageSpaces',
    header: 'Garage Spaces',
    accessorKey: 'garageSpaces',
    cell: ({ getValue }) => formatInteger(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'pool',
    header: 'Pool',
    accessorKey: 'poolFeatures',
    cell: ({ getValue }) => parseArrayString(getValue()),
  },
  {
    id: 'sourceName',
    header: 'Source',
    cell: ({ getValue }) => formatListingSource(getValue()),
    accessorKey: 'source',
    filterFn: sourceNameFilter,
  },
]);

export const RENT_COMP_TABLE_INITIAL_STATE = {
  sorting: [
    { id: 'score', desc: true },
  ],
};

export const useFilterComponents = ({ propertyTypes, subdivisions, statuses, subjectRsf }) => useMemo(() => [
  [CompScoreFilter, 'score', { name: 'score', label: 'Score' }],
  [SliderFilter, 'distance', { label: 'Distance', labelSuffix: 'mi', min: 0.1, max: 2, step: 0.1 }],
  [BedFilter, 'numberOfBedrooms'],
  [BathFilter, 'numberOfBathroomsTotal'],
  [RsfFilter, 'unitRsf', { subjectRsf }],
  [RangeFilter, 'yearBuilt', { type: 'number', label: 'Year Built', labelSuffix: '' }],
  [ListFilter, 'resolvedStatus', { label: 'Status', values: statuses }],
  [RangeFilter, 'effectiveLastSeenAt', { type: 'date', labelSuffix: '', label: 'Close Date', labelFormatter: (date) => formatDate(date, 'MM/dd/yyyy') }],
  [ListFilter, 'subdivision', { label: 'Subdivision', values: subdivisions }],
  [ListFilter, 'propertyType', { label: 'Property Type', values: propertyTypes }],
], [propertyTypes, subdivisions, statuses, subjectRsf]);

export const getDefaultComps = (property, rows) => {
  const unitRents = rows.map(r => r.original);
  // select 5 highest scores that aren't from subject property and are same SF/MF
  const compedFipsApns = [];
  const defaultRentCompIds = [];

  const filteredRents = unitRents.filter(unitRent => {
    const { compScores, fipsApn } = unitRent;
    const meetsMinScore = every([compScores.overall, compScores.location[0], compScores.property[0], compScores.recency[0]], score => score >= MINIMUM_COMP_SCORE);
    const isNotSubjectProperty = (!property.fipsApn || (fipsApn !== property.fipsApn));
    return meetsMinScore && isNotSubjectProperty;
  });

  reverse(sortBy(filteredRents, r => r.compScores.overall)).forEach(unitRent => {
    const { id, isSubject } = unitRent;
    const unitRentPropertyId = unitRent.fipsApn || unitRent.streetAddress; // use address as a fall-back ID for non parcel-matched rents
    if (isSubject) {
      return;
    }

    const rentCompFromSameProperty = compedFipsApns.includes(unitRentPropertyId);

    if (!rentCompFromSameProperty && (defaultRentCompIds.length < DEFAULT_ACTIVE_COMPS)) {
      defaultRentCompIds.push(id);
      compedFipsApns.push(unitRentPropertyId);
    }
  });

  return defaultRentCompIds;
};

export const makePropertyRow = (property, unitMix) => {
  let propertyFields = {};
  if (property.isSingleFamily) {
    propertyFields = {
      bathrooms: property.bathrooms,
      bedroomsTotal: property.bedrooms,
      buildingArea: property.livingArea,
    };
  } else if (isNil(unitMix)) {
    propertyFields = {
      bedroomsTotal: 0,
      bathrooms: 0,
      buildingArea: 0,
    };
  } else {
    propertyFields = {
      bedroomsTotal: unitMix[0],
      bathrooms: unitMix[1],
      buildingArea: unitMix[2],
    };
  }

  return {
    ...property,
    ...propertyFields,
    isSubject: true,
    id: 'subject',
    streetAddress: property.address,
    distance: null,
    media: property.pictures,
  };
};

export const rentCompsSelector = createSelector(
  [
    ({ currentData }) => currentData,
    ({ comps }) => comps,
  ],
  (currentData, comps) => {
    if (!currentData) {
      return EMPTY_UNIT_RENTS;
    }
    const { rentComps: defaultRentComps, selectedIds } = currentData;
    const rentComps = cloneDeep(comps || defaultRentComps);

    return {
      rentComps,
      selectedIds,
    };
  },
);

const OFFSET_FILTER_TRANSFORMS = {
  bathroomOffset: ['numberOfBathroomsTotal', 'propertyBathroomsTotal', 'bedBath'],
  bedroomOffset: ['numberOfBedrooms', 'propertyBedroomsTotal', 'bedBath'],
  effectiveLastSeenAtOffset: ['effectiveLastSeenAt', null, 'daysAgo'],
  rsfPercentOffset: ['unitRsf', 'livingArea', 'percent'],
  yearBuiltOffset: ['yearBuilt', 'yearBuilt', 'value'],
};

const convertOffsetFilter = (filterKey, filterValue, property) => {
  const transformSettings = OFFSET_FILTER_TRANSFORMS[filterKey];
  const transformedFilterKey = transformSettings[0];
  const propertyValue = property[transformSettings[1]];
  const [offsetMin, offsetMax] = filterValue;

  let transformedFilterValue;

  if (transformSettings[2] === 'bedBath') {
    // set transformed filter using bed/bath filter format
    if (isNil(offsetMin) && isNil(offsetMax)) {
      transformedFilterValue = [false];
    } else if (isNumber(offsetMax)) {
      // if there is max offset, we have to use exact selection
      const min = Math.max(0, isNil(offsetMin) ? 0 : (propertyValue - offsetMin));
      transformedFilterValue = [true, ...range(min, propertyValue + offsetMax + 1)];
    } else {
      transformedFilterValue = [false, Math.max(propertyValue - offsetMin, 1)];
    }
  } else if (transformSettings[2] === 'value') {
    if (!propertyValue) {
      // if value is not available on property, do not apply filter
      transformedFilterValue = [null, null];
    } else {
      // set transformed filter based on percent diff
      transformedFilterValue = [
        isNumber(offsetMin) ? Math.max(0, (propertyValue - offsetMin)) : null,
        isNumber(offsetMax) ? (propertyValue + offsetMax) : null,
      ];
    }
  } else if (transformSettings[2] === 'percent') {
    if (!propertyValue) {
      // if value is not available on property, do not apply filter
      transformedFilterValue = [null, null];
    } else {
      // set transformed filter based on percent diff
      transformedFilterValue = [
        isNumber(offsetMin) ? Math.max(0, (propertyValue - (offsetMin * propertyValue))) : null,
        isNumber(offsetMax) ? (propertyValue + (offsetMax * propertyValue)) : null,
      ];
    }
  } else if (transformSettings[2] === 'daysAgo') {
    transformedFilterValue = [
      isNumber(offsetMin) ? subDays(new Date(), offsetMin) : null,
      isNumber(offsetMax) ? subDays(new Date(), offsetMax) : null,
    ];
  }

  return {
    id: transformedFilterKey,
    value: transformedFilterValue,
  };
};

export const rentCompSetsInitialFilters = (initialFilters, property) => {
  // if initial ilters include offset filters, remove the corresonding default filter
  OFFSET_FILTERS.forEach(offsetFilter => {
    if (Object.keys(initialFilters).includes(offsetFilter)) {
      // eslint-disable-next-line no-param-reassign
      delete initialFilters[OFFSET_FILTER_TRANSFORMS[offsetFilter][0]];
    }
  });

  return Object.entries(initialFilters).map(([key, value]) => {
    if (OFFSET_FILTERS.includes(key)) {
      return convertOffsetFilter(key, value, property);
    } else {
      return { id: key, value };
    }
  });
};
