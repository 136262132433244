import { memo } from '@tanstack/react-table';

const enableEditingMetaSymbol = Symbol('');

export const dataTableMeta = Object.freeze({
  enableEditing: Object.freeze({ [enableEditingMetaSymbol]: true }),
  disableTableConfig: Object.freeze({ tableConfig: false }),
  textLeft: Object.freeze({ textAlign: 'left' }),
  textCenter: Object.freeze({ textAlign: 'center' }),
  textRight: Object.freeze({ textAlign: 'right' }),
});

export const enableEditing = ({ cell, inputType, precision, min, max, rows, cols, placeholder, impliedChanges }) => ({
  ...dataTableMeta.enableEditing,
  editCell: cell,
  editInputType: inputType,
  editInputPrecision: precision,
  editInputMin: min,
  editInputMax: max,
  editInputRows: rows,
  editInputCols: cols,
  editInputPlaceholder: placeholder,
  editImpliedChanges: impliedChanges,
});

export const enableConfigPresets = ({ presets }) => ({ configPresets: presets });

/**
 * Control if a column can be configured.
 * false -- column cannot be configured
 * 'readonly' -- config will be applied but cannot be modified by the user (greyed out)
 * 'hidden' -- config will be applied, column will be hidden from the config panel
 *
 * @param {boolean | 'readonly'} [visibility]
 * @param {boolean | 'readonly' | 'hidden'} [order]
 */
export const tableConfigMeta = ({ visibility, order } = {}) => ({
  tableConfig: {
    ...(visibility !== undefined ? { visibilityConfigurable: visibility } : {}),
    ...(order !== undefined ? { orderConfigurable: order } : {}),
  },
});

/**
 * @type {import('@tanstack/react-table').TableOptions['getRowId']}
 */
export const rowIdFromId = (originalRow, index, parent) => {
  const currRowId = originalRow?.id ?? index;
  return parent ? `${parent.id}.${currRowId}` : currRowId;
};

/**
 * @type {({ column: import('@tanstack/react-table').Column }) => string}
 */
export const getTextAlignClass = ({ column, defaultTextAlign }) => {
  const { columnDef: { meta: { textAlign } = {} } } = column;
  const alignment = textAlign ?? defaultTextAlign;

  if (alignment === 'right') {
    return 'text-right';
  } else if (alignment === 'center') {
    return 'text-center';
  } else {
    return 'text-left';
  }
};

/**
 * @type {(row: import('@tanstack/react-table').Row) => void}
 */
export const selectRowOnClick = (row) => {
  if (!row.getIsSelected()) {
    row.toggleSelected(true);
  }
};

export const isEditingEnabled = (meta) => !!meta?.[enableEditingMetaSymbol];

/**
 * @param {import('@tanstack/react-table').Column} column
 * @param {import('@tanstack/react-table').Table} table
 */
export const getCell = ({ column, table }) => {
  const {
    columnDef: {
      cell,
      meta: columnMeta,
    },
  } = column;

  if (isEditingEnabled(table.options.meta) && isEditingEnabled(columnMeta)) {
    return columnMeta.editCell ?? cell;
  }

  return cell;
};

/**
 * Wrap @tanstack/react-table's memo function so that exceptions are saved and rethrown
 * Without this wrapper, the memoized function would return undefined
 * on the second invocation if the first invocation threw an exception (and dependencies didn't change).
 *
 * @type {import('@tanstack/react-table').memo}
 */
export const throwingMemo = (getDeps, fn, opts) => {
  const fnErrorHolder = [];
  const wrappedFn = (...fnArgs) => {
    try {
      fnErrorHolder.length = 0;
      return fn(...fnArgs);
    } catch (e) {
      fnErrorHolder[0] = e;
      throw e;
    }
  };
  const memoized = memo(getDeps, wrappedFn, opts);

  return (...memoizedArgs) => {
    if (fnErrorHolder.length) {
      throw fnErrorHolder[0];
    }

    return memoized(...memoizedArgs);
  };
};
