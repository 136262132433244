import { FormField } from 'components/Form';
import { ACQUISITION_METHOD_ITEMIZED, ACQUISITION_METHOD_PERCENT, isAcquisitionCostItemized } from 'components/dcf/dcf';
import ItemizedAcquisitionCostTable from 'components/dcf/ItemizedAcquisitionCostTable';
import { PERCENT_TYPE } from 'components/Input';

export default function EntryExitParameters({ parameters, onChange, canEditPortfolio }) {
  const {
    acquisitionCostMethod,
    acquisitionCostPercent,
    costOfSalePercent,
    exitCapRate,
    haircut,
    holdPeriod,
    inflationRate,
    ltOneYrHold,
  } = parameters;

  const itemizedAcquisitionCost = isAcquisitionCostItemized(parameters);

  return (
    <>
      <FormField
        name="holdPeriod"
        label={`Hold Period ${ltOneYrHold ? '(months)' : '(years)'}`}
        value={holdPeriod}
        type="number"
        min="1"
        max={ltOneYrHold ? 11 : 20}
        unit="years"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="exitCapRate"
        value={exitCapRate}
        type={PERCENT_TYPE}
        min="0.05"
        precision="2"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="haircut"
        value={haircut}
        label="Listed Deal — Asking Price Haircut"
        type={PERCENT_TYPE}
        min="0"
        max="100"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="inflationRate"
        value={inflationRate}
        type={PERCENT_TYPE}
        min="0"
        max="100"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      <FormField
        className="mt-6"
        name="acquisitionCostMethod"
        type="select"
        value={acquisitionCostMethod ?? ''}
        options={[[ACQUISITION_METHOD_PERCENT, '% Purchase'], [ACQUISITION_METHOD_ITEMIZED, 'Itemized']]}
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
      {itemizedAcquisitionCost ? (
        <ItemizedAcquisitionCostTable dcfParams={parameters} onChange={onChange} disabled={!canEditPortfolio} />
      ) : (
        <FormField
          className="mt-6"
          name="acquisitionCostPercent"
          label="Acquisition Cost"
          value={acquisitionCostPercent}
          type={PERCENT_TYPE}
          min="0"
          onChange={onChange}
          disabled={!canEditPortfolio}
        />
      )}
      <FormField
        className="mt-6"
        name="costOfSalePercent"
        label="Closing Cost (% of Exit Price)"
        value={costOfSalePercent}
        type={PERCENT_TYPE}
        min="0"
        max="100"
        onChange={onChange}
        disabled={!canEditPortfolio}
      />
    </>
  );
}
