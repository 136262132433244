import { formatCurrency } from '../utils';

export default function PerUnitContainer({ annual, value, numberOfUnits }) {
  return (
    <>
      <div className="text-sm">{formatCurrency(annual ? value * 12 : value)}</div>
      <div className="text-xs text-gray-400">{`${formatCurrency((annual ? value * 12 : value) / numberOfUnits)} / Unit`}</div>
    </>
  );
}
