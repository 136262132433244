import { useState } from 'react';
import Button from 'components/shared/NewButton';
import Input from 'components/Input';
import { capitalize } from 'lodash';
import EmailTemplatePreviewModal from './EmailTemplatePreviewModal';
import RichTextEditor from './RichTextEditor'; // Import the new RichTextEditor component

function EmailTemplateEditor({ currentUser, template, onSave, width = '800px' }) {
  const [localTemplate, setLocalTemplate] = useState(template);
  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false);

  const handleSave = () => {
    onSave(localTemplate);
  };

  return (
    <div className="w-auto flex flex-col gap-y-10 p-4 mx-auto relative" style={{ maxWidth: width }}>
      <h2 className="text-2xl font-bold mb-4">{capitalize(localTemplate.title)}</h2>
      <div>
        <label htmlFor="title" className="text-xs font-medium cursor-pointer">
          Title
        </label>
        <Input
          disabled={template.category === 'offer'}
          className="w-full h-13 font-normal text-base text-neutral-dark border border-neutral-400 focus:border-neutral-600 outline-none rounded px-2"
          id="title"
          onChange={(e) => setLocalTemplate({ ...localTemplate, title: e.target.value })}
          type="text"
          name="title"
          value={localTemplate.title}
        />
      </div>
      <div>
        <label htmlFor="content" className="text-xs font-medium cursor-pointer">
          Body
        </label>
        <RichTextEditor
          value={localTemplate.content}
          onChange={(content) => setLocalTemplate({ ...localTemplate, content })}
          placeholders={Object.keys(template.placeholders)}
          bounds="#root"
          style={{ height: '200px', width: '100%' }}
        />
      </div>
      <div className="flex gap-x-2 mt-12">
        <Button filled label="Save Template" onClick={handleSave} />
        <Button outlined label="Preview Template" onClick={() => setShowEmailPreviewModal(!showEmailPreviewModal)} />
      </div>
      {showEmailPreviewModal && (
        <EmailTemplatePreviewModal
          placeholders={template.placeholders}
          currentUser={currentUser}
          emailTemplate={localTemplate}
          handleClose={() => setShowEmailPreviewModal(!showEmailPreviewModal)}
        />
      )}
    </div>
  );
}

export default EmailTemplateEditor;
