import { useEffect, useMemo, useState } from 'react';
import { pull } from 'lodash';
import { GENERALIZED_PIPELINE_STATUS } from 'components/constants';
import Filter from './Filter';
import Chip from 'components/shared/Chip';
import { titleCase } from 'components/utils';
import { defaultFilters } from '../dealSourcing';

function PipelineFilterChip({ pipelineFilter, setPipelineFilter, pipelineStatus }) {
  const selected = pipelineFilter.includes(pipelineStatus);
  const onClick = () => {
    const updatedPipelineFilter = selected ? pull(pipelineFilter, pipelineStatus) : pipelineFilter.concat([pipelineStatus]);
    setPipelineFilter([...updatedPipelineFilter]);
  };
  return (
    <Chip label={`Show ${titleCase(pipelineStatus)}`} leadingIcon={selected} onClick={onClick} selected={selected} />
  );
}

export default function PipelineFilter({ channel, filters, setFilters }) {
  const [pipelineLocalFilter, setPipelineLocalFilter] = useState([...filters.pipeline]);
  const defaultPipelineFilter = useMemo(() => defaultFilters(channel).pipeline, [channel]);

  useEffect(() => {
    setPipelineLocalFilter([...filters.pipeline]);
  }, [filters.pipeline]);

  const onApply = (close) => {
    setFilters({ ...filters, pipeline: pipelineLocalFilter });
    close();
  };

  const onReset = () => {
    setFilters({ ...filters, pipeline: defaultPipelineFilter });
  };

  const filtered = filters.pipeline !== defaultPipelineFilter;
  let label = 'Pipeline';
  if (filtered) {
    label = filters.pipeline.join(', ');
  }

  return (
    <Filter label={label} onApply={onApply} onReset={onReset} filtered={filtered} width="min-w-max">
      <div className="flex gap-x-4">
        {GENERALIZED_PIPELINE_STATUS.map(pipelineStatus => (
          <PipelineFilterChip
            key={pipelineStatus}
            pipelineStatus={pipelineStatus}
            pipelineFilter={pipelineLocalFilter}
            setPipelineFilter={setPipelineLocalFilter}
          />
        ))}
      </div>
    </Filter>
  );
}
