import { apiSlice } from 'redux/apiSlice';
import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { DEAL_CONTEXT_TAG, OFFERS_TAG } from './utils';

const offerApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchOffers: builder.query({
        query: ({ dealId = null }) => ({ url: `/api/offers?deal_id=${dealId}` }),
        transformResponse: response => camelCaseKeys(response).data.map(o => o.attributes),
        providesTags: [OFFERS_TAG],
      }),
      createOffer: builder.mutation({
        query: offer => ({
          url: '/api/offers',
          method: 'POST',
          body: snakeCaseKeys(offer),
        }),
        invalidatesTags: [OFFERS_TAG],
      }),
      updateOffer: builder.mutation({
        query: offer => ({
          url: `/api/offers/${offer.id}`,
          method: 'PATCH',
          body: snakeCaseKeys(offer),
        }),
        invalidatesTags: [DEAL_CONTEXT_TAG, OFFERS_TAG],
      }),
      deleteOffer: builder.mutation({
        query: offerId => ({ url: `/api/offers/${offerId}`, method: 'DELETE' }),
        invalidatesTags: [OFFERS_TAG],
      }),
      fetchDealByExternalId: builder.query({
        query: (externalId) => ({ url: `/api/offers/${externalId}` }),
        transformResponse: response => camelCaseKeys(response),
      }),
      submitExternalOffer: builder.mutation({
        query: (formData) => ({
          url: `/api/offers/${formData.get('external_id')}/submit_offer`,
          method: 'POST',
          body: formData,
        }),
      }),
      createOfferAttachment: builder.mutation({
        query: ({ offerId, files }) => {
          const formData = new FormData();
          files.forEach((file, index) => formData.append(`files[${index}]`, file));

          return ({
            url: `/api/offers/${offerId}/offer_attachments`,
            method: 'POST',
            headers: {},
            body: formData,
          });
        },
        invalidatesTags: [OFFERS_TAG],
      }),
      deleteOfferAttachment: builder.mutation({
        query: offerAttachment => ({
          url: `/api/offers/${offerAttachment.offerId}/offer_attachments/${offerAttachment.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: [OFFERS_TAG],
      }),
      sendOfferEmail: builder.mutation({
        query: offer => ({
          url: `/api/offers/${offer.id}/send_email`,
          method: 'POST',
        }),
        invalidatesTags: [OFFERS_TAG],
      }),
    };
  },
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useFetchOffersQuery,
  useCreateOfferMutation,
  useUpdateOfferMutation,
  useDeleteOfferMutation,
  useFetchDealByExternalIdQuery,
  useSubmitExternalOfferMutation,
  useCreateOfferAttachmentMutation,
  useDeleteOfferAttachmentMutation,
  useSendOfferEmailMutation,
} = offerApiSlice;
