import cx from 'classnames';
import ReactDOM from 'react-dom';
import { X } from './icons';

export default function Modal({
  title,
  children,
  onClose,
  show,
  fullScreen = false,
  backgroundColor = 'bg-white',
  buttonColor = 'text-gray-900 hover:text-gray-600',
  showCloseAction = true,
}) {
  return ReactDOM.createPortal(
    (
      <div
        className={cx(
          'fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-36',
          {
            'hidden': !show,
            'py-6': !fullScreen,
          },
        )}
        onMouseDown={onClose}
      >
        <div
          className={cx(
            'relative flex flex-col',
            backgroundColor,
            fullScreen ? 'w-screen h-screen' : 'p-6 max-h-full w-min rounded-xl border border-black border-opacity-12',
          )}
          onMouseDown={e => e.stopPropagation()}
          onClick={e => e.stopPropagation()}
        >
          {showCloseAction && (
            <button
              type="button"
              className={cx('absolute focus:outline-none w-6 h-6 top-6 right-6 z-10', buttonColor)}
              onClick={onClose}
            >
              <X />
            </button>
          )}
          {title && (
            <div className="text-2xl font-semibold text-gray-900 leading-10 pr-6">
              {title}
            </div>
          )}
          {children}
        </div>
      </div>
    ),
    document.body,
  );
}
