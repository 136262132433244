import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { Check, Warning } from 'components/icons';
import {
  TOAST_SUCCESS,
  TOAST_WARNING,
} from 'components/toast';
import { clearToast } from 'redux/toastSlice';

const TOAST_TIMEOUT_SECONDS = 5;
const TOAST_TIMEOUT = TOAST_TIMEOUT_SECONDS * 1000;

export default function Toast({ showChildren, hasTimeouts = false, children, type, message, actions = [], showIcon = true }) {
  const dispatch = useDispatch();
  const timeoutIdRef = useRef(null);

  const clearExistingTimeout = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  }, []);

  const startTimeout = useCallback(() => {
    if (hasTimeouts) {
      timeoutIdRef.current = setTimeout(() => {
        dispatch(clearToast());
      }, TOAST_TIMEOUT);
    }
  }, [dispatch, hasTimeouts]);

  useEffect(() => {
    startTimeout();

    return () => {
      clearExistingTimeout();
    };
  }, [startTimeout, clearExistingTimeout]);

  const onMouseEnter = () => {
    clearExistingTimeout();
  };

  const onMouseLeave = () => {
    startTimeout();
  };

  const containerClassName = classNames(
    'absolute left-0 right-0 mx-auto bottom-4',
    'flex justify-between',
    'w-max h-14 z-30',
    'py-4 px-4',
    'bg-neutral-medium rounded-xl',
    'text-base text-center text-white',
  );

  return ReactDOM.createPortal(
    <div
      className={containerClassName}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ zIndex: 9999 }}
    >
      {showChildren ? children : (
        <>
          <div className="flex space-x-2 mr-10">
            {showIcon && type === TOAST_SUCCESS && (<Check className="w-6 h-6" />)}
            {showIcon && type === TOAST_WARNING && (<Warning className="w-6 h-6 fill-white" />)}
            <span>
              {message}
            </span>
          </div>
          <span>
            {actions.map(({ func, label }) => (
              <span className="text-white cursor-pointer ml-3" key={label} onClick={func}>
                {label}
              </span>
            ))}
          </span>
        </>
      )}
    </div>,
    document.body,
  );
}
