import { useRef, useState } from 'react';

/**
 * @template T
 * @return {(func: () => T) => (T | undefined)}
 */
const useOnce = () => {
  const ran = useRef(false);

  return useState(() => (
    (func) => {
      if (ran.current) {
        return undefined;
      }
      ran.current = true;
      return func?.();
    }
  ))[0];
};

export default useOnce;
