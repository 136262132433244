export const TH_CLASSNAME = `
  p-0
  text-xs
  text-center
  text-gray-500
  font-medium
  uppercase
  tracking-wider
`;

export const TD_CLASSNAME = `
  px-3
  text-base
  font-normal
  text-gray-500
  whitespace-nowrap
`;

export const TD_LINK_CLASSNAME = `
  text-sm
  text-gray-500
  block
  px-4
  py-2
  cursor-pointer
`;

export const TD_TEXT_CLASSNAME = 'text-center text-tertiary';
export const TD_NUMBER_CLASSNAME = 'text-xs text-gray-400';
export const TD_DATA_CLASSNAME = 'text-center text-secondary-500';
export const EDIT_PORTFOLIO_TEXT = 'Edit Portfolio';
export const DELETE_PORTFOLIO_TEXT = 'Delete Portfolio';
export const DASH = '-';

export const THEME_PORTFOLIO = {
  row: `
    relative
    flex
    items-center
    justify-between
    min-w-full
    pt-8
    mb-4
    rounded-md
    shadow-lg
  `,
  col1: `
    absolute
    block
    min-w-full
    top-0
    m-0
    bg-primary
    text-center
    text-white
    rounded-tl-md
    rounded-tr-md
  `,
  col2: `
    pl-10
    py-3
    text-sm
    font-normal
    text-gray-500
    whitespace-pre-wrap
    text-left
  `,
  col3: `
    px-3
    py-3
    text-sm
    font-normal
    text-gray-500
    whitespace-pre-wrap
    text-left
  `,
  col4: `
    px-3
    py-4
    text-sm
    font-normal
    text-tertiary
    underline
    whitespace-nowrap
    text-center
  `,
  col5: TD_CLASSNAME,
  col6: 'px-3 py-4 text-center',
  col7: 'px-3 py-4 text-center',
  col8: TD_CLASSNAME,
  col9: TD_CLASSNAME,
  col10: `
    pr-10
    py-4
    text-sm
    font-normal
    text-tertiary
    whitespace-nowrap
    cursor-price
    text-center
  `
};
