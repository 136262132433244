import { combineReducers, createReducer } from '@reduxjs/toolkit';
import {
  clearDealsModal,
  updateTaskModal,
  destroyTaskModal,
  createTaskAttachmentModal,
  destroyTaskAttachmentModal,
  dismissDashboardAlert,
  showEditTransactionInfoModal,
  submitTaskResponseModal,
  sendTaskEmailModal,
} from '../actions/deals';

// actions that bring up a modal on the dashboard
const DASHBOARD_MODAL_ACTIONS = [
  updateTaskModal,
  showEditTransactionInfoModal,
  submitTaskResponseModal,
  destroyTaskModal,
  createTaskAttachmentModal,
  destroyTaskAttachmentModal,
  sendTaskEmailModal,
];

const modalInitialState = {
  isSaving: false,
  alert: null,
};
const dashboardInitialState = {
  isSaving: false,
  alert: null,
};

const reinitializeModalReducer = () => modalInitialState;

const dashboard = createReducer(dashboardInitialState, (builder) => {
  /* eslint-disable no-param-reassign */
  builder
    .addCase(dismissDashboardAlert, (state) => {
      state.alert = null;
    })
    .addDefaultCase(() => {});
  /* eslint-enable no-param-reassign */
});

const modal = createReducer(modalInitialState, (builder) => {
  /* eslint-disable no-param-reassign */
  DASHBOARD_MODAL_ACTIONS.forEach(modalAction => {
    builder.addCase(modalAction, (_, { payload }) => payload);
  });

  builder
    .addCase(clearDealsModal, reinitializeModalReducer)
    .addDefaultCase(() => {});
  /* eslint-enable no-param-reassign */
});

export default combineReducers({
  dashboard,
  modal,
});
