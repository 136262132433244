import { formatPercentage } from '../utils';
import { isNumber } from 'lodash';

export default function HurdleReturnsShare({ dcfParams, hurdleIndex }) {
  if (!isNumber(dcfParams.hurdlePromotes[hurdleIndex])) {
    return null;
  }

  const promote = Math.max(dcfParams.hurdlePromotes[hurdleIndex], 1 - dcfParams.ownershipShareLp);

  return (
    <div className="flex flex-col p-1 text-base text-gray-500">
      <span>{formatPercentage(1 - promote)} LP</span>
      <span>{formatPercentage(promote)} GP</span>
    </div>
  );
}
