import { Link, useOutletContext } from 'react-router-dom';

function SubdivisionRow({ subdivision }) {
  return (
    <Link className="block w-full bg-white hover:bg-honeycomb-50 rounded" to={`/subdivisions/${subdivision.id}`}>
      <div className="p-4">
        <div className="text-lg">{subdivision.name}</div>
        <div className="text-sm text-gray-700 font-light">{subdivision.builder?.name}</div>
      </div>
    </Link>
  );
}

export default function SubdivisionList() {
  const { subdivisions } = useOutletContext();

  return (
    <div className="h-full p-16">
      <div className="max-h-full min-h-fit w-128 mx-auto overflow-y-auto bg-white divide-y rounded">
        {subdivisions.map(subdivision => (
          <SubdivisionRow key={subdivision.id} subdivision={subdivision} />
        ))}
      </div>
    </div>
  );
}
