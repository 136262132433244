import { useMemo, useState, useTransition } from 'react';
import { isEqual, partial, range, sortBy } from 'lodash';
import { addMonths, startOfMonth } from 'date-fns';
import { ModalEditCell } from 'components/shared/Table/Cells';
import { useUpdateRow } from 'components/shared/Table/DataTableContext';
import { formatDate, parseEventValue } from 'components/utils';
import Button from 'components/shared/NewButton';
import Input from 'components/Input';
import Select from 'components/Select';

function InventoryAddressInputRow({ address, deliveryDate, available, onChangeAvailable, onRemove }) {
  return (
    <div className="grid grid-cols-8 gap-x-3">
      <div className="col-span-4">{address}</div>
      <div className="col-span-2">{formatDate(deliveryDate, 'MMM yyyy')}</div>
      <div className="col-span-1">
        <Input type="checkbox" value={available} onChange={onChangeAvailable} />
      </div>
      <div className="col-span-1">
        <Button small outlined danger label="Remove" onClick={onRemove} />
      </div>
    </div>
  );
}

function AddInventoryAddressRow({ onAddAddress }) {
  const currentDate = startOfMonth(new Date());
  const deliveryDates = range(-5, 12).map(i => formatDate(addMonths(currentDate, i)));
  const dateOptions = [['', 'Select Delivery Month', true], ...deliveryDates.map(date => [date, formatDate(date, 'MMM yyyy')])];
  const [address, setAddress] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState('');
  const [available, setAvailable] = useState(true);
  const onAdd = () => {
    onAddAddress(address, deliveryDate, available);
    setAddress(null);
    setAvailable(true);
    setDeliveryDate('');
  };
  const onDateChange = e => setDeliveryDate(parseEventValue(e));

  return (
    <div className="flex justify-between items-center gap-x-3">
      <Input
        type="text"
        value={address}
        placeholder="Enter Property Address"
        onChange={e => setAddress(parseEventValue(e))}
      />
      <Select
        value={deliveryDate}
        options={dateOptions}
        onChange={onDateChange}
        width="w-36"
      />
      <Input type="checkbox" value={available} onChange={() => setAvailable(!available)} />
      <Button small outlined danger label="Add" onClick={onAdd} disabled={!address || !deliveryDate} />
    </div>
  );
}

function EditModalBody({ value, setUpdate, onClose }) {
  const [editedValue, setEditedValue] = useState(value);
  const isChanged = useMemo(() => !isEqual(value, editedValue), [value, editedValue]);
  const [, startTransition] = useTransition();
  const onSubmit = () => {
    startTransition(() => setUpdate(editedValue));
    onClose();
  };
  const onRemove = index => setEditedValue(prevValue => prevValue.toSpliced(index, 1));

  const onChangeAvailable = (index, event) => setEditedValue(prevValue => {
    const updatedValue = parseEventValue(event);
    // handle user inputting decimals by rounding down to integer
    const newValue = [...prevValue];
    newValue[index] = { ...newValue[index], available: updatedValue };
    return newValue;
  });

  const onAddAddress = (address, deliveryDate, available) => setEditedValue(prevValue => {
    const newValue = [...prevValue];
    newValue.push({ address, deliveryDate, available });
    return sortBy(newValue, (invAddress => invAddress.address));
  });

  return (
    <div className="w-128">
      <div className="mb-6 font-medium">Update Property Addresses</div>
      <div className="grid grid-cols-8 mb-3 text-sm text-gray-500 font-medium">
        <div className="col-span-4">Address</div>
        <div className="col-span-2">Delivery Date</div>
        <div className="col-span-1">Available</div>
      </div>
      <div className="flex flex-col gap-y-2 pb-3 border-b">
        {editedValue.length ? editedValue.map(({ address, deliveryDate, available }, index) => (
          <InventoryAddressInputRow
            key={address}
            index={index}
            address={address}
            deliveryDate={deliveryDate}
            available={available}
            onChangeAvailable={partial(onChangeAvailable, index)}
            onRemove={partial(onRemove, index)}
          />
        )) : <div className="text-gray-500">No address info</div>}
      </div>
      <div className="mt-3">
        <AddInventoryAddressRow onAddAddress={onAddAddress} editedValue={editedValue} />
      </div>
      <div className="mt-6 flex justify-between">
        <Button textOnly label="Cancel" onClick={onClose} />
        <Button filled label="Submit" onClick={onSubmit} disabled={!isChanged} />
      </div>
    </div>
  );
}

export function InventoryAddressCell({ getValue }) {
  return (
    <div className="flex justify-end gap-x-1">
      <div>{getValue().filter(ia => ia.available).length}</div>
      <div className="text-gray-400">{`/ ${getValue().length}`}</div>
    </div>
  );
}

export function EditInventoryAddressCell({ getValue, row, column }) {
  const value = getValue();
  const [, setUpdate] = useUpdateRow({ rowId: row.id, columnId: column.id });

  return (
    <ModalEditCell displayValue={<InventoryAddressCell getValue={getValue} />}>
      {(onClose) => <EditModalBody value={value} setUpdate={setUpdate} onClose={onClose} />}
    </ModalEditCell>
  );
}
