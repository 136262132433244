import cx from 'classnames';
import { format, formatDistanceToNow, isToday } from 'date-fns';

function TemplateList({ templates, selectedTemplate, onSelect }) {
  const formatLastEdited = (date) => {
    const parsedDate = new Date(date);

    // If the date is less than a day ago, show "X minutes/hours ago"
    if (isToday(parsedDate)) {
      return formatDistanceToNow(parsedDate, { addSuffix: true });
    }

    // Otherwise, show the full date and time
    return format(parsedDate, "d MMM 'at' p"); // Example: "17 May at 2:00 PM"
  };

  if (templates.length === 0) {
    return (
      <ul className="flex-1 p-4 space-y-2 overflow-y-auto">
        <li className="text-gray-500 text-lg">No templates available.</li>
      </ul>
    );
  }

  return (
    <ul className="flex-1 p-4 space-y-2 overflow-y-auto">
      {templates.map((template) => (
        <li
          key={template.id}
          className={cx(
            'p-4 rounded-lg cursor-pointer transition-all duration-200',
            {
              'bg-gray-300 text-gray-900 shadow-lg': selectedTemplate?.id === template.id, // Subtle selected background
              'hover:bg-gray-100 bg-white text-gray-800 shadow-sm': selectedTemplate?.id !== template.id,
            },
          )}
          onClick={() => onSelect(template)}
        >
          <h3 className="text-lg font-semibold">{template.title}</h3>
          <p className="text-sm text-gray-600">Last edited: {formatLastEdited(template.updatedAt)}</p>
        </li>
      ))}
    </ul>
  );
}

export default TemplateList;
