import { Home } from 'components/icons';

export default function HomeModelThumbnailCell({ getValue }) {
  const cellValue = getValue();

  return (
    <div className="size-9 my-2 border rounded overflow-clip bg-neutral-100">
      {cellValue ? (
        <img
          src={cellValue}
          alt="home model"
          referrerPolicy="no-referrer"
          loading="lazy"
          decoding="async"
          className="size-full object-cover"
        />
      ) : (
        <div className="flex items-center justify-center size-full">
          <Home className="w-3/5 h-auto fill-neutral-medium" />
        </div>
      )}
    </div>
  );
}
