import { useMemo } from 'react';
import { parseISO, subWeeks, subMonths } from 'date-fns';
import { isEmpty, mean, meanBy, round, sumBy } from 'lodash';
import { formatCurrency, formatCurrencyAbbreviated, formatInteger, formatPercentage } from 'components/utils';
import { OFFER_STATUS_ACCEPTED } from 'components/constants';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import { metricFunc } from './offer.utils';

const outstandingOfferFunc = (offers, asOfDate) => offers.filter(({ attributes: { offerResponseDate } }) => !offerResponseDate || (parseISO(offerResponseDate) > asOfDate));

const offerHasResponseFunc = (offers) => offers.filter(({ attributes: { offerStatus } }) => offerStatus);

// TODO: how should we incorporate asOfDate?
const conversionRateFunc = (offers) => {
  const offersWithResponse = offerHasResponseFunc(offers);
  if (isEmpty(offersWithResponse)) {
    return null;
  }
  const acceptedOffers = offersWithResponse.filter(({ attributes: { offerStatus } }) => offerStatus === OFFER_STATUS_ACCEPTED);
  return acceptedOffers.length / offersWithResponse.length;
}

const offeredPastWeekFunc = (offers, asOfDate) => offers.filter(({ attributes: { offerDate } }) => {
  if (!offerDate) return false;
  const parsedOfferDate = parseISO(offerDate);
  return (parsedOfferDate >= subWeeks(asOfDate, 1)) && (parsedOfferDate <= asOfDate)
});

const discountToListFunc = (offers) => {
  const sentOffersWithPrices = offers.filter(({ attributes: { offerPrice, listPrice } }) => offerPrice && listPrice);
  return mean(sentOffersWithPrices.map(({ attributes: { offerPrice, listPrice } }) => 1 - (offerPrice / listPrice)));
};

const offerPsfFunc = (offers) => meanBy(offers, ({ attributes: { offerPrice, property: { sqft } } }) => offerPrice / sqft);
const rentOfferFunc = (offers) => meanBy(offers, ({ attributes: { offerPrice, underwritingRent } }) => underwritingRent / offerPrice);

const offerRateFunc = (offers, asOfDate, fromDate) => offers.filter(({ attributes: { offerDate } }) => {
  if (!offerDate) return false;
  const offerDateObj = parseISO(offerDate);
  const hasBeenOffered = offerDateObj <= asOfDate;
  const offeredAfterDate = offerDateObj >= fromDate;
  return hasBeenOffered && offeredAfterDate;
});

const metricsData = [
  { label: 'Outstanding Offers', statFunc: (offers, asOfDate) => outstandingOfferFunc(offers, asOfDate).length },
  { label: 'Total Value of Offers', statFunc: (offers => sumBy(offers, 'attributes.offerPrice')), formatter: formatCurrencyAbbreviated },
  { label: 'Offer Conversion Rate', statFunc: conversionRateFunc, formatter: formatPercentage },
  { label: 'Avg. UW Rent', statFunc: (offers => meanBy(offers, 'attributes.underwritingRent')), formatter: formatCurrency },
  { label: 'Avg. Beds', statFunc: (offers => meanBy(offers, 'attributes.property.bed')), formatter: val => (val ? round(val, 2) : null) },
  { label: 'Offers Sent - Last 7 Days', statFunc: (offers, asOfDate) => offeredPastWeekFunc(offers, asOfDate).length },
  { label: 'Avg. Offer Price', statFunc: offers => meanBy(offers, 'attributes.offerPrice'), formatter: formatCurrencyAbbreviated },
  { label: 'Avg. Discount to List Price - Offer', statFunc: discountToListFunc, formatter: formatPercentage },
  { label: 'Avg. Sqft', statFunc: (offers => meanBy(offers, 'attributes.property.sqft')), formatter: formatInteger },
  { label: 'Avg. Baths', statFunc: (offers => meanBy(offers, 'attributes.property.bath')), formatter: val => (val ? round(val, 2) : null) },
  { label: 'Weekly Offer Rate', statFunc: ((offers, asOfDate) => offerRateFunc(offers, asOfDate, subWeeks(asOfDate, 1)).length) },
  { label: 'Monthly Offer Rate', statFunc: ((offers, asOfDate) => offerRateFunc(offers, asOfDate, subMonths(asOfDate, 1)).length) },
  { label: 'Avg. Offer $ per Sqft', statFunc: offerPsfFunc, formatter: formatCurrency },
  { label: 'Avg. Rent / Price', statFunc: rentOfferFunc, formatter: formatPercentage },
  { label: 'Avg. Vintage', statFunc: (offers => meanBy(offers, 'attributes.property.yearBuilt')), formatter: val => (val ? round(val) : null) },
];

export default function Overall({ asOfDate, lastWeekDate, lastMonthDate, filteredOffers, oneWeekAgoFilteredOffers, oneMonthAgoFilteredOffers }) {
  const metrics = useMemo(
    () => metricsData.map(({ label, formatter, statFunc }) => metricFunc(label, statFunc, asOfDate, lastWeekDate, lastMonthDate, filteredOffers, oneWeekAgoFilteredOffers, oneMonthAgoFilteredOffers, formatter)),
    [filteredOffers],
  );

  return (
    <div>
      <ReturnMetricBoxes metrics={metrics} />
    </div>
  );
}
