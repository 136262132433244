import cx from 'classnames';

export default function MetricCard(props) {
  const {
    mainLabel,
    firstLabel,
    firstValue,
    secondLabel,
    secondValue,
    useColors,
    shadow,
  } = props;

  const values = [
    [firstLabel, firstValue, 'bg-secondary-50 text-secondary-500'],
  ];

  if (secondLabel && secondValue) {
    values.push([secondLabel, secondValue, 'bg-tertiary-lightest text-tertiary']);
  }

  return (
    <div className={cx('flex flex-col flex-auto rounded', { shadow })}>
      <div className="pb-0.5 text-center text-gray-600 text-sm font-medium">{mainLabel}</div>
      <div className="flex flex-row flex-auto text-center">
        {values.map(([label, value, color], index) => (
          <div key={index} className={cx('flex flex-col justify-center px-3 py-1', { [color]: useColors, 'w-96': useColors })}>
            {label && <div className="text-gray-500 text-xs">{label}</div>}
            <div className="text-xs">{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function Card({ header, children }) {
  return (
    <div className="max-w- w-full border border-gray-200 rounded overflow-hidden shadow-lg bg-white">
      {header && (
        <div className="px-6 py-4 border-b">
          <div className="font-bold text-lg mb-2">{header}</div>
        </div>
      )}
      <div className="px-6 py-4">
        {children}
      </div>
    </div>
  );
}
