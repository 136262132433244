import Range from 'components/portfolio/Filters/Range';
import BedBath from 'components/portfolio/Filters/BedBath';
import { isEqual, find, xor } from 'lodash';
import Dropdown from 'components/Dropdown';
import {
  RESIDENTIAL_PROPERTY_TYPE,
  RESIDENTIAL_INCOME_PROPERTY_TYPE,
  PROPERTY_TYPES,
} from 'components/constants';
import MultiSelectDropdown from 'components/shared/MultiSelectDropdown';
import Input from 'components/Input';

export const propertyTypes = [
  { name: 'All', value: null },
  { name: 'Residential', value: RESIDENTIAL_PROPERTY_TYPE },
  { name: 'Residential Income', value: RESIDENTIAL_INCOME_PROPERTY_TYPE },
];

const propertySubTypes = PROPERTY_TYPES.map((t) => ({ name: t, value: t }));

export function PoolFlagFilter({ value, onChange }) {
  return (
    <div>
      <div className="text-neutral-dark text-sm font-medium mb-5">Swimming Pool</div>
      <input
        checked={value}
        className="mr-3"
        id="pool-flag-checkbox"
        onChange={onChange}
        type="checkbox"
      />
      <label className="text-neutral-medium text-base" htmlFor="pool-flag-checkbox">Exclude homes with a pool</label>
    </div>
  );
}

export default function BuyBox({ defaultFilters, onChange }) {
  const {
    numberOfUnits,
    yearBuilt,
    rentableBuildingArea,
    zipCodes,
    listPrice,
    pricePerSquareFoot,
    daysOnMarket,
    unleveredIrr,
    leveredIrr,
    y1Yield,
    stabilizedYield,
    grossStabilizedYield,
    unleveredCashOnCashRate,
    leveredCashOnCashRate,
    propertyType,
    excludesHomesWithPool,
    propertySubType = [],
  } = defaultFilters;

  const setDefaultFilters = (updatedDefaultFilters) => {
    if (!isEqual(defaultFilters, updatedDefaultFilters)) {
      onChange({ target: { name: 'defaultFilters', value: updatedDefaultFilters } });
    }
  };

  const setPropertySubTypes = ({ value }) => {
    if (value) {
      setDefaultFilters({ ...defaultFilters, propertySubType: xor(defaultFilters.propertySubType, [value]) });
    } else {
      setDefaultFilters({ ...defaultFilters, propertySubType: [] });
    }
  };

  const zipCodeStringFromFilters = (zipCodesString) => zipCodesString?.join(',') || '';
  return (
    <div className="flex flex-col">
      <p className="text-sm w-1/2">
        The
        {' '}
        <b> Buy-Box </b>
        {' '}
        sets default filters for this portfolio used on the Deal Sourcing page. The filters on Deal Sourcing will remain fully editable.
        {' '}
        <b>All inputs are optional.</b>
      </p>
      <div className="w-full flex mt-8">
        <div className="w-1/2 mr-2 flex flex-col">
          <div className="text-lg">Property and Listing Information</div>
          <div className="p-6 mt-4 bg-white border rounded-xl">
            <div className="flex pt-4 flex-col justify-between items-center">
              <div className="w-full mb-6 flex flex-col">
                <span className="font-medium text-sm mb-2">Listing Type</span>
                <Dropdown width="w-full" options={propertyTypes} optionsWidth="w-full" selectedItem={find(propertyTypes, { value: propertyType })} setSelectedItem={(e) => setDefaultFilters({ ...defaultFilters, propertyType: e.value })} />
              </div>
              <div className="w-full mb-6 flex flex-col">
                <MultiSelectDropdown
                  label="Property Type"
                  options={propertySubTypes}
                  width="w-full"
                  selectedOptions={propertySubType.map((t) => ({ name: t, value: t }))}
                  onChange={(e) => setPropertySubTypes(e)}
                />
              </div>
              <Range label="List Price" onChange={(range) => setDefaultFilters({ ...defaultFilters, listPrice: range })} value={listPrice} />
              <Range label="Days on Market" onChange={(range) => setDefaultFilters({ ...defaultFilters, daysOnMarket: range })} value={daysOnMarket} />
              <Range label="Year Built" onChange={(range) => setDefaultFilters({ ...defaultFilters, yearBuilt: range })} value={yearBuilt} />
              <Range label="Square Footage" onChange={(range) => setDefaultFilters({ ...defaultFilters, rentableBuildingArea: range })} value={rentableBuildingArea} />
              <div className="w-full flex flex-col mb-6">
                <span className="font-medium text-sm mb-2">Zip Codes</span>
                <Input
                  onChange={(e) => setDefaultFilters({ ...defaultFilters, zipCodes: e.target.value.split(',').map(zip => zip.trim()) })}
                  type="text"
                  value={zipCodeStringFromFilters(zipCodes)}
                  width="w-full h-12 py-1 px-2 border"
                />
              </div>
              <BedBath filters={defaultFilters} setFilters={setDefaultFilters} />
              <div className="w-full mb-10 mt-11 flex flex-col">
                <PoolFlagFilter value={excludesHomesWithPool} onChange={() => setDefaultFilters({ ...defaultFilters, excludesHomesWithPool: !excludesHomesWithPool })} />
              </div>
              <Range label="Unit Count" onChange={(range) => setDefaultFilters({ ...defaultFilters, numberOfUnits: range })} value={numberOfUnits} />
              <Range label="List Price per Square Foot" onChange={(range) => setDefaultFilters({ ...defaultFilters, pricePerSquareFoot: range })} value={pricePerSquareFoot} />
            </div>
          </div>
        </div>
        <div className="w-1/2 ml-2 flex flex-col">
          <div className="text-lg">Return Metrics</div>
          <div className="p-6 mt-4 bg-white border rounded-xl">
            <div className="flex pt-4 flex-col justify-between items-center">
              <Range label="Unlevered IRR" onChange={(range) => setDefaultFilters({ ...defaultFilters, unleveredIrr: range })} value={unleveredIrr} type="percent" />
              <Range label="Levered IRR" onChange={(range) => setDefaultFilters({ ...defaultFilters, leveredIrr: range })} value={leveredIrr} type="percent" />
              <Range label="Year 1 Cash Yield" onChange={(range) => setDefaultFilters({ ...defaultFilters, y1Yield: range })} value={y1Yield} />
              <Range label="Stabilized Net Yield" onChange={(range) => setDefaultFilters({ ...defaultFilters, stabilizedYield: range })} value={stabilizedYield} type="percent" />
              <Range label="Stabilized Gross Yield" onChange={(range) => setDefaultFilters({ ...defaultFilters, grossStabilizedYield: range })} value={grossStabilizedYield} type="percent" />
              <Range label="Average Unlevered Cash Yield" onChange={(range) => setDefaultFilters({ ...defaultFilters, unleveredCashOnCashRate: range })} value={unleveredCashOnCashRate} type="percent" />
              <Range label="Average Levered Cash Yield " onChange={(range) => setDefaultFilters({ ...defaultFilters, leveredCashOnCashRate: range })} value={leveredCashOnCashRate} type="percent" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
