const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const replacePlaceholders = (content, placeholders) => {
  let updatedContent = content;

  Object.keys(placeholders).forEach((key) => {
    const placeholderKey = `\\[${escapeRegExp(key)}\\]`;
    const value = placeholders[key] || '';

    updatedContent = updatedContent.replace(new RegExp(placeholderKey, 'g'), value);
  });

  return updatedContent;
};

export const generateEmailHtml = (emailTemplate, placeholders, logoUrl) => {
  const updatedEmailContent = replacePlaceholders(emailTemplate.content, placeholders);

  return `<!doctype html>
    <html lang="en" xmlns:v="urn:schemas-microsoft-com:vml">
      <head>
        <meta charset="utf-8">
        <meta name="x-apple-disable-message-reformatting">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta name="format-detection" content="telephone=no, date=no, address=no, email=no, url=no">
        <meta name="color-scheme" content="light dark">
        <meta name="supported-color-schemes" content="light dark">
        <!--[if mso]>
          <noscript>
            <xml>
              <o:officedocumentsettings xmlns:o="urn:schemas-microsoft-com:office:office">
                <o:pixelsperinch>96</o:pixelsperinch>
              </o:officedocumentsettings>
            </xml>
          </noscript>
          <style>
            a, div, h1, h2, h3, h4, h5, h6, p, td, th {
              font-family: "Segoe UI", sans-serif;
              mso-line-height-rule: exactly;
            }
          </style>
        <![endif]-->
        <title>Notification</title>
        <style>
          .ql-editor s { text-decoration: line-through; }
          .ql-align-center { text-align: center; }
          .ql-align-right { text-align: right; }
          .ql-align-justify { text-align: justify; }
          .ql-editor a { color: #00f; text-decoration: none; }
          .ql-editor img { max-width: 100%; display: block; margin: 0 auto; }
          .ql-editor ol, .ql-editor ul { padding-left: 20px; margin: 0; list-style-position: inside; }
          .ql-editor blockquote { border-left: 4px solid #ccc; margin: 0; padding-left: 16px; }
          .ql-size-small { font-size: .75em; }
          .ql-size-large { font-size: 1.5em; }
          .ql-size-huge { font-size: 2.5em; }
          .hover-important-text-decoration-underline:hover { text-decoration: underline !important; }
          @media (max-width: 600px) {
            .sm-my-8 { margin-top: 32px !important; margin-bottom: 32px !important; }
            .sm-px-4 { padding-left: 16px !important; padding-right: 16px !important; }
            .sm-px-6 { padding-left: 24px !important; padding-right: 24px !important; }
            .sm-leading-8 { line-height: 32px !important; }
          }
        </style>
      </head>
      <body style="margin:0; width:100%; height:100%; background-color:#f8fafc; padding:0; -webkit-font-smoothing:antialiased; word-break:break-word;">
        <div aria-label="Notification" aria-roledescription="email" lang="en" role="article" style="width: 100%; height: 100%;">
          <div class="sm-px-4" style="background-color:#f8fafc; font-family:ui-sans-serif, system-ui, -apple-system, 'Segoe UI', sans-serif; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
            <table cellpadding="0" cellspacing="0" role="none" align="center" style="width: 100%; max-width: 600px;">
              <tr>
                <td style="border-radius:4px; background-color:#fff; padding:48px; font-size:16px; color:#334155; box-shadow:0 1px 2px 0 rgba(0,0,0,.05);">
                  <!-- Logo -->
                  <div style="text-align: center; margin-bottom: 20px;">
                    <img src="${logoUrl}" alt="Organization Logo" style="width: 200px; height: 100px; object-fit: contain;" />
                  </div>
                  <!-- Email Content -->
                  <div style="text-align: left;">
                    ${updatedEmailContent}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </body>
    </html>`;
};
