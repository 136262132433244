import { naturalSortComparator } from 'components/utils';
import ComboboxFilter from 'components/shared/newfilters/ComboboxFilter';
import { useFetchSubdivisionsQuery } from '../../../redux/subdivisionApiSlice';

const comparator = naturalSortComparator();

const selectSubdivisions = ({ currentData, isFetching, isUninitialized }) => {
  const sortedData = currentData?.map(({ id, name }) => ({ id, name }));
  if (sortedData?.length) {
    sortedData.sort(({ name: left }, { name: right }) => comparator(left, right));
  }

  return {
    currentData: sortedData ?? null,
    isFetching: isFetching || isUninitialized,
  };
};

export default function SubdivisionFilter() {
  const { currentData: subdivisions, isFetching } = useFetchSubdivisionsQuery(undefined, { selectFromResult: selectSubdivisions });

  return (
    <ComboboxFilter
      isLoading={isFetching}
      options={subdivisions}
      keyBy="id"
      labelBy="name"
    />
  );
}
