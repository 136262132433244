export const TH_CLASSNAME = `
  p-0
  text-xs
  text-center
  text-gray-500
  font-medium
  uppercase
  tracking-wider
`;

export const TD_CLASSNAME = `
  px-3
  text-base
  font-normal
  text-gray-500
  whitespace-nowrap
`;

export const TD_LINK_CLASSNAME = `
  text-sm
  text-gray-500
  block
  px-4
  py-2
  cursor-pointer
`;

export const TD_TEXT_CLASSNAME = 'text-center text-tertiary';
export const TD_NUMBER_CLASSNAME = 'text-xs text-gray-400';
export const TD_DATA_CLASSNAME = 'text-center text-secondary-500';
export const NO_DATA_TEXT = 'No active deals';
export const PER_UNIT_TEXT = '/ Unit';
export const PSF_TEXT = 'PSF';
export const EDIT_UNDERWRITING_TEXT = 'Edit Underwriting';
export const DELETE_DEAL_TEXT = 'Delete Deal';
export const DASH = '-';

export const THEME_DEFAULT = {
  row: '',
  col1: `
    pl-10
    py-3
    text-sm
    font-normal
    text-gray-500
    whitespace-pre-wrap
    text-left
  `,
  col2: `
    px-3
    py-3
    text-sm
    font-normal
    text-gray-500
    whitespace-pre-wrap
    text-left
  `,
  col3: `
    px-3
    py-4
    text-sm
    font-normal
    text-tertiary
    underline
    whitespace-nowrap
    text-center
  `,
  col4: TD_CLASSNAME,
  col5: 'px-3 py-4 text-center',
  col6: 'px-3 py-4 text-center',
  col7: TD_CLASSNAME,
  col8: TD_CLASSNAME,
  col9: TD_CLASSNAME,
  col10: `
    pr-10
    py-4
    text-sm
    font-normal
    text-tertiary
    whitespace-nowrap
    cursor-price
    text-center
  `
};
