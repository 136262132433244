import { subtract, sum, unzip, zipWith } from 'lodash';
import { holdPeriodInMonths } from './dcf';
import { calcMonthlyGrowthRates, compoundInterest } from '../finance';

export const newLeaseStartMonth = (unit) => unit.rollToMarket + (unit.turnBudget ? unit.turnDowntime : 0);

const isBeingTurned = (unit, month) => (
  unit.turnBudget && (month >= unit.rollToMarket) && (month < newLeaseStartMonth(unit))
);

export const isNewLeaseStart = (unit, month) => {
  const startingMonth = newLeaseStartMonth(unit);
  return (month >= startingMonth) && (month - startingMonth) % unit.leaseTerm === 0;
};

const isDowntime = (unit, month) => (unit.vacant && month < newLeaseStartMonth(unit)) || isBeingTurned(unit, month);

const calcGrossPotentialUnitRent = (unit, dcfParams) => {
  const monthlyRates = calcMonthlyGrowthRates(dcfParams.rentGrowthRates, holdPeriodInMonths(dcfParams));
  return compoundInterest(unit.marketRent, monthlyRates).slice(1);
};

const calcUnitInPlaceRent = (unit, dcfParams, grossPotentialRent) => {
  let inPlaceRent;

  if (unit.rentControlled) {
    if (unit.vacant) {
      inPlaceRent = new Array(grossPotentialRent.length).fill(0);
    } else {
      let currRent = unit.inPlaceRent;
      let currRentControlledMax = unit.inPlaceRent;

      inPlaceRent = grossPotentialRent.map((potentialRent, index) => {
        const month = index + 1;
        if (isNewLeaseStart(unit, month)) {
          currRentControlledMax *= (1 + dcfParams.rentControlledGrowthRates[Math.floor(index / 12)]);
          currRent = (currRentControlledMax * unit.renewalProbability) + (potentialRent * (1 - unit.renewalProbability));
        }

        return currRent;
      });
    }
  } else {
    let currRent = unit.inPlaceRent;
    inPlaceRent = grossPotentialRent.map((potentialRent, index) => {
      const month = index + 1;
      if (isDowntime(unit, month) || isNewLeaseStart(unit, month)) {
        currRent = potentialRent;
      }

      return currRent;
    });
  }

  return inPlaceRent;
};

const calcUnitRolloverVacancy = (unit, dcfParams) => {
  // TODO: re-calculating gross potential rent is inefficient
  const grossPotentialRent = calcGrossPotentialUnitRent(unit, dcfParams);

  return grossPotentialRent.map((gpr, index) => {
    const month = index + 1;
    if (isDowntime(unit, month)) {
      return gpr;
    }

    if (month === newLeaseStartMonth(unit) && (unit.vacant || !!unit.turnBudget)) {
      return 0;
    }

    if (isNewLeaseStart(unit, month)) {
      // divide by 30 to get percent of month
      return (1 - unit.renewalProbability) * gpr * (unit.downtime / 30);
    }
    return 0;
  });
};

const calcUnitLossToLease = (unit, dcfParams) => {
  // TODO: re-calculating gross potential rent is inefficient
  const grossPotentialRent = calcGrossPotentialUnitRent(unit, dcfParams);
  return zipWith(grossPotentialRent, calcUnitInPlaceRent(unit, dcfParams, grossPotentialRent), subtract);
};

const calcUnitConcessions = (unit, dcfParams) => {
  const months = holdPeriodInMonths(dcfParams);
  // TODO: re-calculating gross potential rent is inefficient
  const grossPotentialRent = calcGrossPotentialUnitRent(unit, dcfParams);
  let concessions;
  if (unit.concessions === 0) {
    concessions = new Array(months + 12).fill(0);
  } else {
    concessions = grossPotentialRent.map((potentialRent, index) => {
      const month = index + 1;
      if (month === newLeaseStartMonth(unit) && (unit.vacant || !!unit.turnBudget)) {
        return potentialRent * unit.concessions;
      } else if (isNewLeaseStart(unit, month)) {
        return (1 - unit.renewalProbability) * potentialRent * unit.concessions;
      } else {
        return 0;
      }
    });
  }
  return concessions;
};

const calculateUnitRents = (unit, dcfParams) => {
  const grossPotentialRent = calcGrossPotentialUnitRent(unit, dcfParams);
  const rolloverVacancy = calcUnitRolloverVacancy(unit, dcfParams);
  const lossToLease = calcUnitLossToLease(unit, dcfParams);
  const concessions = calcUnitConcessions(unit, dcfParams);

  return grossPotentialRent.map((potentialRent, index) => {
    return potentialRent - rolloverVacancy[index] - lossToLease[index] - concessions[index];
  });
};

const calculateUnitPhysicalOccupancyRates = (unit, dcfParams) => {
  const grossPotentialRent = calcGrossPotentialUnitRent(unit, dcfParams);
  const rolloverVacancy = calcUnitRolloverVacancy(unit, dcfParams);

  return zipWith(grossPotentialRent, rolloverVacancy, (gpr, rollover) => (gpr + rollover) / gpr);
};

const unzipUnitCalculation = (unitFunc, dcfParams) => {
  return unzip(dcfParams.units.map(unit => unitFunc(unit, dcfParams))).map(unitAmounts => sum(unitAmounts));
};

export const calcGrossPotentialRents = dcfParams => {
  return unzipUnitCalculation(calcGrossPotentialUnitRent, dcfParams);
};

export const calcRolloverVacancies = dcfParams => {
  return unzipUnitCalculation(calcUnitRolloverVacancy, dcfParams).map(value => value * -1);
};

export const calcLossToLeases = dcfParams => {
  return unzipUnitCalculation(calcUnitLossToLease, dcfParams).map(value => value * -1);
};

export const calcConcessions = dcfParams => {
  return unzipUnitCalculation(calcUnitConcessions, dcfParams).map(value => value * -1);
};

export const calcGrossRent = (dcfParams) => {
  return unzipUnitCalculation(calculateUnitRents, dcfParams);
};

export const calcPhysicalOccupancyRates = (dcfParams) => {
  return unzipUnitCalculation(calculateUnitPhysicalOccupancyRates, dcfParams);
};
