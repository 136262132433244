import { ToggleWithLabels } from '../Toggle';

export default function SectionLabel({ className, text, toggle = false, toggleValue = false, setToggleValue, disabled }) {
  return (
    <div className="flex flex-row justify-between items-center">
      <label className={`${className || ''} block mb-2 text-gray-500 text-sm font-bold tracking-wide uppercase`} htmlFor="/">{text}</label>
      {
        toggle
        && (
        <ToggleWithLabels
          className="ml-5"
          checked={toggleValue}
          onClick={() => setToggleValue(!toggleValue)}
          disabled={disabled}
        />
        )
      }
    </div>
  );
}
