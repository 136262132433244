import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showRenameScenarioModal } from 'actions/deal_navigation';
import { useUpdateScenarioMutation } from 'redux/apiSlice';
import Input from 'components/Input';
import Alert from 'components/Alert';
import Button from 'components/shared/NewButton';
import Modal from 'components/Modal';

export default function RenameScenarioModal() {
  const dispatch = useDispatch();
  const { scenario } = useSelector(state => state.navigation.modal);
  const [scenarioName, setScenarioName] = useState(scenario.name);

  const [updateScenarioMutation, { error, isLoading: isSaving }] = useUpdateScenarioMutation();
  if (error) {
    console.error(error);
  }

  const onDismiss = () => dispatch(showRenameScenarioModal(false));
  const onSave = async () => {
    const response = await updateScenarioMutation({
      id: scenario.id,
      dealId: scenario.dealId,
      name: scenarioName,
    });
    if (response.data) {
      onDismiss();
    }
  };

  return (
    <Modal show onClose={onDismiss}>
      <div className="w-96">
        <h1 className="mb-12 text-xl font-normal">Rename Scenario</h1>
        <Input
          name="name"
          value={scenarioName}
          type="text"
          width="w-full"
          padding="px-2 py-3"
          className="border border-gray-300"
          onChange={(e) => setScenarioName(e.target.value)}
        />
        {error && <Alert type="danger" text="Failed to update scenario" className="mt-4" />}
        <div className="flex justify-end gap-x-4 mt-6">
          <Button label="Cancel" textOnly onClick={onDismiss} />
          <Button label="Save" filled isLoading={isSaving} onClick={onSave} />
        </div>
      </div>
    </Modal>
  );
}
