import Input from 'components/Input';
import { parseEventValue } from 'components/utils';
import ChipFilter from 'components/shared/filters/ChipFilter';

export default function SliderFilter({ label, filterKey, filterParams, labelSuffix, min, max, onApply, onCancel, onReset, setFilter, step }) {
  const filtered = filterParams[1] !== 1;
  const formattedLabel = filtered ? `Up to ${filterParams[1]}${labelSuffix}` : label;

  return (
    <ChipFilter label={formattedLabel} filterKey={filterKey} filtered={filtered} filterParams={filterParams} onApply={onApply} onReset={onReset} setFilter={setFilter} handleCancel={onCancel}>
      {({ filterState: value, setFilterState }) => {
        const onChange = (event) => {
          const parsedValue = parseEventValue(event) ?? '';
          setFilterState([null, parsedValue]);
        };

        return (
          <>
            <div className="mb-2">{`Radius: ${value[1]} mi`}</div>
            <Input
              className="focus:outline-none"
              onChange={onChange}
              type="range"
              value={value[1]}
              min={min}
              max={max}
              step={step}
              width="w-40"
            />
            <div className="flex justify-between text-sm">
              <div>{`${min}${labelSuffix}`}</div>
              <div>{`${max}${labelSuffix}`}</div>
            </div>
          </>
        );
      }}
    </ChipFilter>
  );
}
