import { cloneDeep, map, partial, pullAt, sum, sumBy } from 'lodash';
import { addYears } from 'date-fns';
import Toggle from 'components/Toggle';
import { TH, TR } from '../Table';
import { dateFromString, formatCurrency, formatDate, parseEventValue } from '../utils';
import { ACCOUNTING_METHOD_FOLLOW_ON } from './dcf';
import Input from '../Input';
import {
  calcCapitalItemTotalBudget,
  calcMonthlyConstructionExpenses,
  CAPITAL_FEES_INPUT_METHODS,
  CAPITAL_INPUT_METHODS,
} from './capital';
import Select from '../Select';
import { ITEMIZED_INPUT_METHOD_DOLLAR } from './itemizedItem';

function CapitalItemRow({
  item,
  minDate,
  inputMethods,
  calcItemTotalBudget,
  dcfParams,
  onChange,
  isModelParameter = false,
  disabled,
}) {
  const { holdPeriod } = dcfParams;
  const capitalInputMethod = inputMethods[item.inputMethod];

  // enabled toggle onChange value is due to enabled being a new flag and having to handle existing undefined values
  const data = [
    <Toggle
      className="px-2"
      name="enabled"
      checked={item.enabled !== false}
      onClick={() => onChange({ target: { name: 'enabled', value: (item.enabled === false ? true : false) } })}
      disabled={disabled}
    />,
    <Input name="label" value={item.label} type="text" onChange={onChange} width="w-48" disabled={disabled} />,
    <Input name="description" value={item.description} type="text" onChange={onChange} width="w-48" disabled={disabled} />,
    <Select
      name="inputMethod"
      value={item.inputMethod}
      options={map(inputMethods, (method, key) => [key, method.label])}
      onChange={onChange}
      width="w-28"
      disabled={disabled}
    />,
    item.inputMethod === ITEMIZED_INPUT_METHOD_DOLLAR ? (
      <Input name="quantity" value={item.quantity} type="number" min="1" onChange={onChange} width="w-16" disabled={disabled} />
    ) : (
      <Input name="quantity" value="" type="number" width="w-16" disabled />
    ),
    <Input name="unitCost" value={item.unitCost} type={capitalInputMethod.inputType} min="0" onChange={onChange} width="w-24" disabled={disabled} />,
    isModelParameter ? (
      <Input
        required
        name="startDate"
        value={item.startDate || ''}
        type="date"
        min={formatDate(minDate)}
        max={formatDate(addYears(minDate, holdPeriod))}
        onChange={onChange}
        disabled={disabled}
      />
    ) : (
      <Input
        required
        name="startDate"
        value={item.startDate}
        type="number"
        min="0"
        onChange={onChange}
        width="w-16"
        disabled={disabled}
      />
    ),
    <Input name="duration" value={item.duration} type="number" min="1" onChange={onChange} width="w-16" disabled={disabled} />,
  ];

  if (isModelParameter || item.inputMethod === ITEMIZED_INPUT_METHOD_DOLLAR) {
    data.push(formatCurrency(calcItemTotalBudget(item)));
  } else {
    data.push('n/a');
  }

  return <TR center data={data} />;
}

function AddCapitalItemRow({ onClick, label }) {
  const data = new Array(9).fill(null);
  data[0] = (
    <button
      type="button"
      className="bg-tertiary hover:bg-tertiary-lighter px-2 text-white rounded"
      onClick={onClick}
    >
      {label}
    </button>
  );

  return <TR center tdClassName="py-2" data={data} />;
}

function CapitalItemAggregateRow({ items, calcItemTotalBudget }) {
  const data = [
    'Total',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    formatCurrency(items.reduce((total, item) => total + calcItemTotalBudget(item), 0)),
  ];

  return <TR center className="border-t" data={data} />;
}

function CapitalItemsTable({
  dcfParams,
  capitalItemsKey,
  addCapitalItemLabel,
  inputMethods,
  calcItemTotalBudget,
  onChange,
  isModelParameter = false,
  canEditPortfolio,
}) {
  const { closingDate } = dcfParams;
  const minDate = isModelParameter ? dateFromString(closingDate) : 0;
  const capitalItems = dcfParams[capitalItemsKey] ?? [];

  const addCapitalItem = () => {
    const updatedCapitalItems = cloneDeep(capitalItems);
    updatedCapitalItems.push({
      accountingMethod: ACCOUNTING_METHOD_FOLLOW_ON,
      enabled: true,
      description: '',
      duration: 1,
      inputMethod: ITEMIZED_INPUT_METHOD_DOLLAR,
      label: '',
      quantity: 1,
      startDate: minDate,
      unitCost: 0,
    });
    onChange({
      target: {
        name: capitalItemsKey,
        value: updatedCapitalItems,
      },
    });
  };

  const removeCapitalItem = (index) => {
    const updatedCapitalItems = cloneDeep(capitalItems);
    pullAt(updatedCapitalItems, [index]);
    onChange({
      target: {
        name: capitalItemsKey,
        value: updatedCapitalItems,
      },
    });
  };

  const onCapitalItemChange = (index, event) => {
    const updatedCapitalItems = cloneDeep(capitalItems);
    const field = event.target.name;
    const newValue = parseEventValue(event);
    updatedCapitalItems[index][field] = newValue;

    onChange({
      target: {
        name: capitalItemsKey,
        value: updatedCapitalItems,
      },
    });
  };

  // prevent editing if its using in the portfolio parameters context and user does not have permission to edit
  const preventEditing = !isModelParameter && !canEditPortfolio;

  return (
    <table className="w-full divide-y divide-gray-200 border-b-2 bg-white">
      <thead className="bg-gray-50 border-b-2">
        <tr>
          <TH value="Default Enabled" />
          <TH value="Category" />
          <TH value="Description" />
          <TH value="Input Method" />
          <TH value="Quantity" />
          <TH value="Unit Cost" />
          <TH value={isModelParameter ? 'Start Date' : 'Start Month'} />
          <TH value="Duration (mo)" />
          <TH value="Total Budget" />
        </tr>
      </thead>
      <tbody>
        {capitalItems.map((item, index) => (
          <CapitalItemRow
            isModelParameter={isModelParameter}
            key={index}
            minDate={minDate}
            dcfParams={dcfParams}
            item={item}
            inputMethods={inputMethods}
            calcItemTotalBudget={calcItemTotalBudget}
            onChange={partial(onCapitalItemChange, index)}
            onRemove={partial(removeCapitalItem, index)}
            disabled={preventEditing}
          />
        ))}
        { !preventEditing && <AddCapitalItemRow onClick={addCapitalItem} label={addCapitalItemLabel} />}
        <CapitalItemAggregateRow items={capitalItems} calcItemTotalBudget={calcItemTotalBudget} />
      </tbody>
    </table>
  );
}

export default function CapitalProjectsTable({
  dcfParams,
  onChange,
  isModelParameter = false,
  canEditPortfolio,
}) {
  return (
    <CapitalItemsTable
      dcfParams={dcfParams}
      onChange={onChange}
      capitalItemsKey="capitalItems"
      addCapitalItemLabel="Add Capital Project"
      inputMethods={CAPITAL_INPUT_METHODS}
      calcItemTotalBudget={item => calcCapitalItemTotalBudget(item, dcfParams)}
      isModelParameter={isModelParameter}
      canEditPortfolio={canEditPortfolio}
    />
  );
}

export function CapitalFeesAndCostsTable({
  dcfParams,
  onChange,
  isModelParameter = false,
  canEditPortfolio,
}) {
  // these 2 cannot be calculated for portfolio parameters
  let totalCapitalExpense = 0;
  let totalFollowOnCapitalExpense = 0;

  if (isModelParameter) {
    const constructionExpenses = calcMonthlyConstructionExpenses(dcfParams);
    totalFollowOnCapitalExpense = sum(constructionExpenses[ACCOUNTING_METHOD_FOLLOW_ON]);
    totalCapitalExpense = sumBy(Object.values(constructionExpenses), sum);
  }

  return (
    <CapitalItemsTable
      dcfParams={dcfParams}
      onChange={onChange}
      capitalItemsKey="capitalFees"
      addCapitalItemLabel="Add Item"
      inputMethods={{ ...CAPITAL_FEES_INPUT_METHODS, ...CAPITAL_INPUT_METHODS }}
      calcItemTotalBudget={item => calcCapitalItemTotalBudget(item, dcfParams, totalCapitalExpense, totalFollowOnCapitalExpense)}
      isModelParameter={isModelParameter}
      canEditPortfolio={canEditPortfolio}
    />
  );
}
