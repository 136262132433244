import { get, partial } from 'lodash';

export const getLastYearOfCashFlows = (cashFlows, index, path) => {
  const lineItemCashFlows = get(cashFlows, path);
  return lineItemCashFlows.slice(Math.max(index - 12, 0), index + 1);
};

export const mostRecentActualIndex = (cashFlows) => cashFlows.dates.length - 1;

export const trailingYearCashFlowsPartial = (cashFlows) => (
  partial(getLastYearOfCashFlows, cashFlows, mostRecentActualIndex(cashFlows))
);

export const pastMonthCashFlowPartial = (cashFlows) => {
  const getPastMonthValue = (cfs, monthIndex, path) => get(cfs, path)[monthIndex];
  return partial(getPastMonthValue, cashFlows, mostRecentActualIndex(cashFlows));
};
