import { useCallback } from 'react';
import { useFilterIsSelected, useOnApply, useOnClear } from './filterHooks';

/**
 * @param {any} value
 * @param {import('react').ReactNode} children
 */
export default function FilterToggle({ value = true }) {
  const selected = useFilterIsSelected();
  const onApply = useOnApply();
  const onClear = useOnClear();

  /** @type {import('react').MouseEventHandler<HTMLDivElement>} */
  const onClick = useCallback(() => {
    if (!selected) {
      onApply({ value });
    } else {
      onClear();
    }
  }, [onApply, onClear, selected, value]);

  return (
    <div className="absolute inset-0" onClick={onClick} />
  );
}
