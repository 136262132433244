import { useState } from 'react';
import { useFetchSelfQuery } from 'redux/apiSlice';
import Alert from 'components/Alert';
import Button from 'components/shared/NewButton';
import { FormField } from 'components/Form';
import { formatDate, parseEventValue, snakeCaseKeys } from 'components/utils';
import { LoadingIndicator } from 'components/icons';

const DATE_FORMATS = [
  ['MMM dd', formatDate(new Date(), 'MMM dd')],
  ['M/d', formatDate(new Date(), 'M/d')],
  ['M/d/y', formatDate(new Date(), 'M/d/y')],
];

export default function UserEditFormContainer() {
  const { data: user, isLoading } = useFetchSelfQuery();
  const [showPasswordSettings, setShowPasswordSettings] = useState(false);

  return (
    <div className="min-h-screen bg-gray-100">
      {isLoading ? (
        <LoadingIndicator className="w-6 text-gray-400" />
      ) : (
        <div className="pt-16 flex justify-center">
          {showPasswordSettings ? (
            <UserPasswordEditForm user={user} setShowPasswordSettings={setShowPasswordSettings} />
          ) : (
            <UserSettingsEditForm user={user} setShowPasswordSettings={setShowPasswordSettings} />
          )}
        </div>
      )}
    </div>
  );
}

function UserSettingsEditForm({ user, setShowPasswordSettings }) {
  const { settings } = user;
  const { pipelineDateFormat } = settings;

  const [defaultDateFormat, setDefaultDateFormat] = useState(pipelineDateFormat);
  const [isSaving, setIsSaving] = useState(false);
  const [alert, setAlert] = useState(null);

  const updateSettings = () => {
    setAlert(null);
    if (isSaving || (pipelineDateFormat === defaultDateFormat)) {
      return;
    }
    setIsSaving(true);

    // TODO: replace with apiSlice version
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    fetch(`/api/users/${user.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
      body: JSON.stringify({
        ...snakeCaseKeys({ pipelineDateFormat: defaultDateFormat }),
      }),
    }).then(response => response.json()).then(json => {
      if (json.error) {
        throw new Error(json.error);
      } else {
        setIsSaving(false);
        setAlert({ type: 'success', text: 'Settings updated' });
      }
    }).catch(error => {
      console.error(error);
      setIsSaving(false);
      setAlert({ type: 'danger', text: `Update Failed: ${error.message}` });
    });
  };

  return (
    <div className="w-96 p-8 bg-white rounded shadow">
      <h1 className="mb-12 text-xl font-normal">Edit User Settings</h1>
      <FormField
        className="mt-6"
        label="Date Format"
        type="select"
        options={DATE_FORMATS}
        value={defaultDateFormat}
        onChange={(e) => setDefaultDateFormat(parseEventValue(e))}
      />
      {alert && <Alert className="mt-3" {...alert} />}
      <div className="mt-12 flex justify-between gap-x-4">
        <Button filled label="Update" onClick={updateSettings} isLoading={isSaving} />
        <Button textOnly label="Change Password" onClick={() => setShowPasswordSettings(true)} />
      </div>
    </div>
  );
}

function UserPasswordEditForm({ user, setShowPasswordSettings }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [alert, setAlert] = useState(null);

  const updateSettings = () => {
    setAlert(null);
    if (newPassword && passwordConfirmation && (newPassword !== passwordConfirmation)) {
      setAlert({ type: 'warning', text: 'Password confirmation does not match' });
      return;
    }
    if (isSaving || !newPassword) {
      return;
    }
    setIsSaving(true);

    // TODO: replace with apiSlice version
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    fetch(`/api/users/${user.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
      body: JSON.stringify({
        ...snakeCaseKeys({
          currentPassword,
          password: newPassword,
        }),
      }),
    }).then(response => response.json()).then(json => {
      if (json.error) {
        throw new Error(json.error);
      } else {
        setIsSaving(false);
        setAlert({ type: 'success', text: 'Settings updated' });
      }
    }).catch(error => {
      console.error(error);
      setIsSaving(false);
      setAlert({ type: 'danger', text: `Update Failed: ${error.message}` });
    });
  };

  return (
    <div className="w-96 p-8 bg-white rounded shadow">
      <h1 className="mb-12 text-xl font-normal">Edit User Settings</h1>
      <FormField
        name="Current Password"
        type="password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(parseEventValue(e))}
      />
      <FormField
        className="mt-6"
        name="New Password"
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(parseEventValue(e))}
      />
      <FormField
        className="mt-6"
        label="Confirm New Password"
        type="password"
        value={passwordConfirmation}
        onChange={(e) => setPasswordConfirmation(parseEventValue(e))}
      />
      {alert && <Alert className="mt-3" {...alert} />}
      <div className="mt-12 flex justify-between gap-x-4">
        <Button filled label="Update Password" onClick={updateSettings} isLoading={isSaving} />
        <Button textOnly label="Back" onClick={() => setShowPasswordSettings(false)} />
      </div>
    </div>
  );
}
