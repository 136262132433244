import { useMemo } from 'react';
import { LayoutSlotsProvider } from 'components/shared/LayoutSlots';
import TreeProvider from 'components/shared/TreeProvider/TreeProvider';

/**
 * @template T
 *
 * @param {boolean} [root]
 * @param {string} [filterId]
 * @param {import('react').ReactNode} [label]
 * @param {import('lodash').PropertyPath | (function(T): import('react').ReactNode)} [displayValue]
 * @param {import('react').ReactNode} [staticDisplayValue]
 * @param {boolean} [selected]
 * @param {T} [filterState]
 * @param {(filterState: T, subtreeState: Record<string, any>) => void} [onApply]
 * @param {(filterIds: string[]) => void} [onReset]
 * @param {(filterIds: string[]) => void} [onClear]
 * @param {import('react').ReactNode} children
 */
export default function Filter({
  root = false,
  filterId,
  label,
  displayValue,
  staticDisplayValue,
  selected,
  filterState,
  onApply,
  onReset,
  onClear,
  children,
}) {
  const value = useMemo(() => ({
    filterId,
    label,
    displayValue,
    staticDisplayValue,
    selected,
    filterState,
    initialFilterState: filterState,
    onApply,
    onReset,
    onClear,
  }), [filterId, label, displayValue, staticDisplayValue, selected, filterState, onApply, onReset, onClear]);

  return (
    <TreeProvider root={root} nodeId={filterId} value={value}>
      <LayoutSlotsProvider root={root}>
        {children}
      </LayoutSlotsProvider>
    </TreeProvider>
  );
}
