import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  banner: {
    show: false,
    text: '',
    position: 'bottom',
    type: '',
  },
};

const globalSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    resetBanner: state => {
      state.banner = { ...initialState.banner };
    },
    setBanner: (state, action) => {
      state.banner = { ...state.banner, ...action.payload };
    },
  },
});

export const { resetBanner, setBanner } = globalSlice.actions;
export default globalSlice.reducer;
