import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, formatInteger, formatMultiplier, formatPercentage, snakeCaseKeys } from 'components/utils';
import { Trash } from 'components/icons';
import { dealPath } from 'components/routes';
import { FormField } from 'components/Form';
import Modal from 'components/Modal';
import Alert from 'components/Alert';
import Button from 'components/shared/Button';
import { DEAD_REASONS } from 'components/dashboard/MarkDealAsDeadModal';
import { useUpdateDealMutation } from 'redux/apiSlice';
import DataTable from 'components/shared/Table/DataTable';
import useElementHeight from 'hooks/useElementHeight';

function MarkDealAsDeadModal({ deal, setShowMarkDealAsDealModal }) {
  const [updateDealMutation, { isLoading }] = useUpdateDealMutation();
  const [deadReason, setDeadReason] = useState(DEAD_REASONS[0][0]);
  const [alert, setAlert] = useState(null);

  const handleUpdateDeal = async () => {
    setAlert(null);
    if (!isLoading) {
      try {
        await updateDealMutation({ ...snakeCaseKeys({ id: deal.id, deletedAt: new Date().toJSON(), deadReason }) }).unwrap();
        setShowMarkDealAsDealModal(null);
      } catch (err) {
        console.error('Failed to mark deal dead: ', err);
        setAlert('Failed to mark deal dead');
      }
    }
  };

  return (
    <Modal show showCloseAction={false}>
      <div className="w-96">
        Are you sure you would like to mark this deal as dead?

        <FormField
          name="dead reason"
          value={deadReason}
          options={DEAD_REASONS.map(r => [r[0], r[1]])}
          type="select"
          padding="py-2 px-3 border border-black border-opacity-12"
          className="mt-6"
          onChange={(e) => setDeadReason(e.target.value)}
        />
        {alert && <Alert className="mt-6" {...alert} />}
        <div className="mt-6 flex gap-x-2 justify-end">
          <Button
            textOnly
            label="Cancel"
            className="font-medium text-sm"
            onClick={() => setShowMarkDealAsDealModal(null)}
          />
          <Button
            label="Continue"
            className="font-medium text-sm"
            onClick={handleUpdateDeal}
          />
        </div>
      </div>
    </Modal>
  );
}

export default function Table({ deals, setHoveredId, tableHeightRef }) {
  const navigate = useNavigate();
  const [deal, setShowMarkDealAsDealModal] = useState(null);
  const tableHeight = useElementHeight(tableHeightRef);

  const nameCell = useCallback(({ cell }) => {
    const { name, city, state, zip, imageUrl } = cell.row.original;
    return (
      <div className="flex gap-x-2">
        <div className="h-9 w-9">
          <img className="w-full h-full rounded-lg object-cover" src={imageUrl} alt="" />
        </div>
        <div className="text-xs font-meduim flex flex-col">
          <div>{name}</div>
          <div className="mt-1 text-vxs text-gray-400">{`${city}, ${state} ${zip}`}</div>
        </div>
      </div>
    );
  }, [deals]);

  const textCell = useCallback(({ cell }) => (
    <div className="text-xs font-medium">
      {cell.getValue()}
    </div>
  ), [deals]);

  const statusCell = useCallback(({ cell }) => (
    <div className="text-vxs w-max py-px rounded-lg text-primary-dark font-medium bg-primary-light">
      {cell.getValue()}
    </div>
  ), [deals]);

  const rsfCell = useCallback(({ cell }) => {
    const { livingAreaSf, units } = cell.row.original;
    const calculatedSqFtPerUnit = Math.floor(parseFloat(
      livingAreaSf / units,
    ));
    return (
      <div className="flex flex-col">
        <div className="text-xs font-medium">
          {calculatedSqFtPerUnit === 0 ? '-' : formatInteger(calculatedSqFtPerUnit)}
        </div>
        {units > 1 && (
          <div className="text-vxs text-gray-400 font-medium">
            {`${formatInteger(Math.floor(calculatedSqFtPerUnit / units))} / unit`}
          </div>
        )}
      </div>
    );
  }, [deals]);

  const purchasePriceCell = useCallback(({ cell }) => {
    const { livingAreaSf, purchasePrice, units } = cell.row.original;
    const calculatedSqFtPerUnit = livingAreaSf / units;
    return (
      <div className="flex flex-col">
        <div className="text-xs font-medium">
          {formatCurrency(purchasePrice)}
        </div>
        {units > 1 && (
          <div className="text-vxs text-gray-400 font-medium">
            {`${formatInteger(Math.floor(purchasePrice / units))} / unit`}
          </div>
        )}
        <div className="text-vxs text-gray-400 font-medium">
          {`${formatInteger(Math.floor(purchasePrice / calculatedSqFtPerUnit))} PSF`}
        </div>
      </div>
    );
  }, [deals]);

  const actionCell = useCallback(({ cell }) => {
    const { canDelete, id } = cell.row.original;

    return (
      <div className="flex justify-center">
        {canDelete && (
          <div
            className="w-10 h-10 rounded-full hover:bg-gray-300 py-2.5 cursor-pointer"
            onClick={(e) => { e.stopPropagation(); setShowMarkDealAsDealModal({ id }); }}
          >
            <Trash className="w-4.5 h-4.5 mx-auto" />
          </div>
        )}
      </div>
    );
  }, [deals]);

  const returnMetricsCell = useCallback(({ cell }) => {
    const { header } = cell.column.columnDef;
    const { returnMetrics: { unleveredIrr, leveredIrr, unleveredEquityMultiple, leveredEquityMultiple, unleveredCashOnCash, leveredCashOnCash } } = cell.row.original.scenario;
    let firstValue = null;
    let secondValue = null;
    if (header === 'IRR') {
      firstValue = formatPercentage(unleveredIrr);
      secondValue = formatPercentage(leveredIrr);
    }
    if (header === 'Equity Multiple') {
      firstValue = formatMultiplier(unleveredEquityMultiple);
      secondValue = formatMultiplier(leveredEquityMultiple);
    }
    if (header === 'Cash Yield') {
      firstValue = formatPercentage(unleveredCashOnCash);
      secondValue = formatPercentage(leveredCashOnCash);
    }
    return (
      <div>
        <div>{firstValue}</div>
        <div className="text-sm text-gray-500">{secondValue}</div>
      </div>
    );
  }, [deals]);

  const columns = useMemo(() => [
    {
      header: 'Name',
      meta: { className: 'pl-2 w-[24%]' },
      accessorKey: 'name',
      cell: nameCell,
      sortingFn: 'text',
    },
    {
      header: 'Deal Stage',
      accessorKey: 'stage',
      id: 'stage',
      cell: statusCell,
      meta: { className: 'w-[12%]', textAlign: 'center' },
    },
    {
      header: '# of Units',
      accessorKey: 'units',
      meta: { className: 'w-[9%]' },
      cell: textCell,
    },
    {
      header: 'RSF',
      accessorKey: 'livingAreaSf',
      meta: { className: 'w-[8%]' },
      cell: rsfCell,
    },
    {
      header: 'Purchase Price',
      accessorKey: 'purchasePrice',
      meta: { className: 'w-[12%]' },
      cell: purchasePriceCell,
    },
    {
      header: 'IRR',
      accessorKey: 'scenario.returnMetrics.unleveredIrr',
      meta: { className: 'w-[9%]', textAlign: 'right' },
      cell: returnMetricsCell,
    },
    {
      header: 'Equity Multiple',
      accessorKey: 'scenario.returnMetrics.unleveredEquityMultiple',
      meta: { className: 'w-[9%]', textAlign: 'right' },
      cell: returnMetricsCell,
    },
    {
      header: 'Cash Yield',
      accessorKey: 'scenario.returnMetrics.unleveredCashOnCash',
      meta: { className: 'w-[9%]', textAlign: 'right' },
      cell: returnMetricsCell,
    },
    {
      header: '',
      id: 'menu',
      meta: { className: 'w-[8%]', textAlign: 'center' },
      cell: actionCell,
    },
  ], [deals]);

  const onRowMouseEnter = (row) => {
    setHoveredId(row.original.id);
  };

  const onRowMouseLeave = () => {
    setHoveredId(null);
  };

  return (
    <>
      {deal && <MarkDealAsDeadModal deal={deal} setShowMarkDealAsDealModal={setShowMarkDealAsDealModal} /> }
      <DataTable
        columns={columns}
        data={deals}
        onRowClick={row => navigate(dealPath({ id: row.original.id }))}
        tableHeight={tableHeight}
        onRowMouseEnter={onRowMouseEnter}
        onRowMouseLeave={onRowMouseLeave}
      />
    </>
  );
}
