import { useCallback, useMemo, useState } from 'react';
import { DEAL_ATTACHMENT_TYPE } from 'components/constants';
import Button from 'components/shared/Button';
import { useDeleteDealAttachmentMutation } from 'redux/apiSlice';
import { Download, X } from 'components/icons';
import DataTable from 'components/shared/Table/DataTable';
import UploadAttachmentModal from './UploadAttachmentModal';

export default function Documents({ context }) {
  const { deal } = context.data;
  const [showUploadModal, setShowUploadModal] = useState(false);

  const renderDocumentActions = useCallback(({ cell }) => {
    const { canDownload, canDestroy, id, dealId, url } = cell.row.original;
    const [deleteDealAttachmentMutation] = useDeleteDealAttachmentMutation();
    const deleteAttachment = () => { deleteDealAttachmentMutation({ id, dealId }); };

    return (
      <div className="flex gap-x-2">
        {canDownload && (
        <a className="flex justify-center items-center" download href={url}>
          <Download className="w-5 text-primary hover:text-primary-300 cursor-pointer" />
        </a>
        )}
        {canDestroy && (
        <div className="flex justify-center items-center" onClick={deleteAttachment}>
          <X className="w-5 text-red-500 hover:text-red-400 cursor-pointer" />
        </div>
        )}
      </div>
    );
  }, [deal.dealAttachments]);

  const columns = useMemo(() => [
    {
      header: 'Name',
      accessorKey: 'filename',
      meta: { className: 'py-3' },
    },
    {
      header: 'Type',
      accessorKey: 'tag',
    },
    {
      header: 'Uploaded By',
      accessorKey: 'uploadedBy',
    },
    {
      header: 'Uploaded At',
      accessorKey: 'createdAt',
    },
    {
      header: '',
      id: 'download',
      cell: renderDocumentActions,
    },
  ], [deal.dealAttachments]);

  return (
    <div className="px-2">
      <DataTable
        columns={columns}
        data={deal.dealAttachments}
      />
      {deal.canCreateDealAttachment && (
        <div
          className="w-full py-6 px-3"
        >
          <Button
            label="Upload Documents"
            className="font-medium text-sm mx-auto"
            onClick={() => setShowUploadModal(true)}
          />
        </div>

      )}
      {showUploadModal && (
        <UploadAttachmentModal
          attachmentType={DEAL_ATTACHMENT_TYPE}
          deal={deal}
          onClose={() => setShowUploadModal(false)}
        />
      )}
    </div>
  );
}
