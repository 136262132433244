import cx from 'classnames';
import { compact, mean } from 'lodash';
import { useMemo } from 'react';
import { useFilteredRows } from 'components/shared/Table/DataTableContext';
import { formatCurrencyAbbreviated, formatInteger } from 'components/utils';

// TODO: refactor into a shared component
function StatItem({ label, value }) {
  return (
    <div className="px-4 py-1 text-center bg-white rounded shadow">
      <div className="text-lg whitespace-nowrap">{value ?? '-'}</div>
      <div className="text-xs font-light whitespace-nowrap">{label}</div>
    </div>
  );
}

const calculateStats = rows => [
  {
    label: '# of Offers',
    value: formatInteger(rows.length),
  },
];

export default function OffMarketOfferSummaryStats({ className }) {
  const rows = useFilteredRows();
  const stats = useMemo(() => (rows?.length ? calculateStats(rows) : null), [rows]);

  return (
    <div className={cx('flex gap-x-4', className)}>
      {stats?.map(stat => (
        <StatItem key={stat.label} {...stat} />
      ))}
    </div>
  );
}
