import { useEffect, useRef, useState } from 'react';
import { useUploadGeocodeBatchCsvMutation } from 'redux/apiSlice';
import Alert from 'components/Alert';
import Button from 'components/shared/NewButton';
import { downloadCsv, downloadUrl } from 'components/utils';

const GEOCODE_CSV_TEMPLATE = [['Unique ID', 'Street address', 'City', 'State', 'ZIP']];
const GEOCODE_RESULT_FILENAME = 'geocode_results.csv';

export default function Base() {
  const [uploadError, setUploadError] = useState(null);

  const fileUploadRef = useRef(null);

  // TODO: handle errors
  const [importGeocodeBatch, { data: csvObjectUrl, isLoading: isImportingCsv }] = useUploadGeocodeBatchCsvMutation();

  useEffect(() => {
    if (csvObjectUrl) {
      downloadUrl(csvObjectUrl, window.document, GEOCODE_RESULT_FILENAME, false);
    }
  }, [csvObjectUrl]);

  const handleFileChange = async (e) => {
    setUploadError(null);

    const files = Array.from(e.target.files);
    if (files.length !== 1) {
      const errorMsg = 'May only upload a single CSV file at a time';
      console.error(errorMsg);
      setUploadError(errorMsg);
      return;
    }
    if (files[0].type !== 'text/csv') {
      const errorMsg = 'Upload file must have a .csv file extension';
      console.error(errorMsg);
      setUploadError(errorMsg);
      return;
    }

    importGeocodeBatch({ file: files[0] });
  };

  return (
    <>
      <div className="flex items-center gap-x-3 h-20 px-6 border-b shadow">
        <div className="text-xl">Geocode</div>
      </div>
      <div className="bg-gray-100 overflow-y-auto" style={{ height: `calc(100vh - 80px)` }}>
        <div className="mt-16 w-128 mx-auto p-6 bg-white rounded">
          <div className="mb-6 text-lg text-gray-900">Geocode Address</div>
          {uploadError && <Alert type="error" text={uploadError} />}
          <div className="mt-12 flex justify-between items-center">
            <Button
              outlined
              label="Download CSV Template"
              onClick={() => downloadCsv(GEOCODE_CSV_TEMPLATE, document, 'geocode_template.csv')}
            />
            <Button
              filled
              label="Upload CSV"
              isLoading={isImportingCsv}
              onClick={(e) => {
                e.preventDefault();
                fileUploadRef.current.click();
              }}
            />
            <input
              id="fileUpload"
              type="file"
              className="hidden"
              ref={fileUploadRef}
              onChange={handleFileChange}
              // eslint-disable-next-line no-return-assign
              onClick={(e) => e.target.value = null}
            />
          </div>
        </div>
      </div>
    </>
  );
}
