import { useRef, useState } from 'react';
import { groupBy, isEmpty, sumBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { LAYOUT } from 'components/constants';
import { LoadingIndicator } from 'components/icons';
import { useFetchDealsQuery, useFetchPortfolioQuery } from 'redux/apiSlice';
import CreateDeal from 'components/portfolio/CreateDeal';
import { formatCurrencyAbbreviated } from 'components/utils';
import PortfolioHeader from 'components/portfolio/PortfolioHeader';
import Table from './Table';
import DealMap from './DealMap';

export default function PortfolioShow() {
  const { portfolioId } = useParams();
  const [hoveredId, setHoveredId] = useState(null);
  const { data: portfolio, isLoading: isPortfolioLoading } = useFetchPortfolioQuery({ id: portfolioId });
  const { data: deals = [], isLoading: areDealsLoading } = useFetchDealsQuery({ id: portfolioId });
  const tableHeightRef = useRef();

  const isLoading = isPortfolioLoading || areDealsLoading;

  const nonDeadDeals = deals.filter(deal => !deal.deletedAt);
  const dealsGroupedByActive = groupBy(nonDeadDeals, deal => !!deal.closedAt);
  const activeDeals = dealsGroupedByActive.false || [];
  const closedDeals = dealsGroupedByActive.true || [];

  const stats = [{
    label: 'Active Deal Value',
    value: formatCurrencyAbbreviated(sumBy(activeDeals, 'purchasePrice')),
  }, {
    label: 'Active Deals',
    value: activeDeals.length,
  }, {
    label: 'Closed Deals',
    value: closedDeals.length,
  }];

  return (
    <div className="bg-gray-100">
      <PortfolioHeader portfolio={portfolio} stats={stats} />

      <div ref={tableHeightRef} style={{ height: `calc(100vh - ${LAYOUT.portfolioHeaderHeight}px)` }}>
        {isLoading && (
          <div className="text-center mt-12">
            <LoadingIndicator className="inline h-6" />
            <div className="text-sm text-neutral-light mt-4">Loading deals...</div>
          </div>
        )}
        {(!isLoading && isEmpty(nonDeadDeals)) && (
          <CreateDeal />
        )}
        {(!isLoading && !isEmpty(nonDeadDeals)) && (
          <div className="h-full w-full grid grid-cols-2">
            <div className="overflow-y-auto">
              <Table deals={nonDeadDeals} setHoveredId={setHoveredId} tableHeightRef={tableHeightRef} />
            </div>
            <DealMap deals={nonDeadDeals} hoveredId={hoveredId} />
          </div>
        )}
      </div>
    </div>
  );
}
