import { makeStateUpdater } from '@tanstack/react-table';
import { EMPTY_PRESET } from '../dataTableConfig/utils';
import createTableExtension from '../utils/createTableExtension';

const activePresetStateKey = Symbol('');

export const getActivePresetId = (table) => table.getState()[activePresetStateKey];

/** @type {(table: import('@tanstack/react-table').Table) => import('@tanstack/react-table').OnChangeFn<string>} */
export const getActivePresetIdUpdater = createTableExtension((table) => {
  const updater = makeStateUpdater(activePresetStateKey, table);
  return () => updater;
});

const NO_PREDEFINED_PRESETS = Object.freeze({});
export const getPredefinedPresets = (table) => table.options.meta?.configPresets ?? NO_PREDEFINED_PRESETS;

export const getActivePreset = createTableExtension(() => {
  const dynamicPresets = new Map();

  return (table) => {
    const activeId = getActivePresetId(table);
    const configPresets = getPredefinedPresets(table);

    let preset;
    if (Object.hasOwn(configPresets, activeId)) {
      preset = configPresets[activeId];
    } else {
      if (dynamicPresets.has(activeId)) {
        preset = dynamicPresets.get(activeId);
      } else {
        preset = Object.freeze({ ...EMPTY_PRESET, id: activeId });
        dynamicPresets.set(activeId, preset);
      }
    }

    if (preset.id !== activeId) {
      console.warn(`Malformed preset: preset with id ${preset.id} stored under key ${activeId}`);
    }

    return preset;
  };
});

/**
 * @type {import('@tanstack/react-table').TableFeature}
 */
export const configFeature = Object.freeze({
  getInitialState(state) {
    return {
      [activePresetStateKey]: null,
      ...(state ?? {}),
    };
  },
});
