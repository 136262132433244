const compare = (a, b) => (a > b ? 1 : b > a ? -1 : 0);
const extract = (element, col) => element.original[col].props.sort;

export const sortByInt = col => (a, b) => {
  const elementA = extract(a, col);
  const elementB = extract(b, col);

  return compare(
    parseInt(!elementA ? 0 : elementA, 10),
    parseInt(!elementB ? 0 : elementB, 10)
  );
};

export const sortByFloat = col => (a, b) => {
  const elementA = extract(a, col);
  const elementB = extract(b, col);

  return compare(
    parseFloat(!elementA ? 0 : elementA),
    parseFloat(!elementB ? 0 : elementB)
  );
};

export const sortByString = col => (a, b) =>
  compare(
    `${extract(a, col)}`.replace(/[^a-z0-9]/gi, '').toLowerCase(),
    `${extract(b, col)}`.replace(/[^a-z0-9]/gi, '').toLowerCase()
  );
