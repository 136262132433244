import cx from 'classnames';

export default function EmailLink({ address, className, children }) {
  return (
    <a
      href={`mailto:${address}`}
      target="_blank"
      rel="noreferrer"
      className={cx('cursor-pointer hover:underline', className)}
    >
      {children ?? address}
    </a>
  );
}
