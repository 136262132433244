import Modal from 'components/NewModal';
import { useState } from 'react';
import classNames from 'classnames';
import DashboardModal from 'components/dashboard/DashboardModal';
import Alert from 'components/Alert';
import { formatCurrency } from 'components/utils';
import { TOAST_OFFER_MESSAGE } from 'components/toast';
import { showToast } from 'redux/toastSlice';
import { useDispatch } from 'react-redux';
import Button from 'components/shared/NewButton';
import { useCreateOfferMutation, useDeleteOfferMutation, useUpdateOfferMutation } from 'redux/offerApiSlice';
import { useParams } from 'react-router-dom';
import { parseInt } from 'lodash';
import OfferForm from './OfferForm';

export function EditOfferModal({ offerData: offerToEdit, onClose }) {
  const [alert, setAlert] = useState(null);
  const dispatch = useDispatch();
  const [offerData, setOfferData] = useState(offerToEdit);
  const [updateOffer, { isLoading: isUpdatingOffer }] = useUpdateOfferMutation();

  const handleSubmit = async (data) => {
    try {
      await updateOffer(data).unwrap();
      dispatch(showToast(TOAST_OFFER_MESSAGE('Offer updated successfully!')));
      onClose();
    } catch (error) {
      setAlert({ type: 'danger', text: 'Failed to update offer' });
    }
  };

  return (
    <Modal title={`Edit Offer ${offerToEdit.buyerAgentName}`} size="medium" onClose={onClose} show>
      <OfferForm
        alert={alert}
        isLoading={isUpdatingOffer}
        offerData={offerData}
        onSubmit={handleSubmit}
        setAlert={setAlert}
        setOfferData={setOfferData}
        showCloseAction={false}
      />
    </Modal>
  );
}

export function CreateOfferModal({ offerData: offerToCreate, onClose }) {
  const [alert, setAlert] = useState(null);
  const [offerData, setOfferData] = useState(offerToCreate);
  const dispatch = useDispatch();
  const params = useParams();
  const [createOffer, { isLoading: isCreateOffer }] = useCreateOfferMutation();

  const handleSubmit = async (data) => {
    try {
      const updatedDealOffer = { ...data, dealId: parseInt(params.id) };
      await createOffer(updatedDealOffer).unwrap();
      dispatch(showToast(TOAST_OFFER_MESSAGE('Offer created successfully!')));
      onClose();
    } catch (error) {
      setAlert({ type: 'danger', text: 'Failed to created offer' });
    }
  };

  return (
    <Modal title="Add New Offer" size="medium" onClose={onClose} show>
      <OfferForm
        alert={alert}
        isLoading={isCreateOffer}
        offerData={offerData}
        onSubmit={handleSubmit}
        setAlert={setAlert}
        setOfferData={setOfferData}
        showCloseAction={false}
      />
    </Modal>
  );
}

function OfferStatus({ status }) {
  const badgeClass = classNames('px-2 rounded-lg font-bold', {
    'bg-green-500 text-white': status === 'accepted',
    'bg-yellow-500 text-white': status === 'pending',
    'bg-red-500 text-white': status === 'rejected',
    'bg-gray-500 text-white': !['accepted', 'pending', 'rejected'].includes(status),
  });

  return <span className={badgeClass}>{status}</span>;
}

export default function DeleteOfferModal({ offerData, onClose }) {
  const [alert, setAlert] = useState(null);
  const [deleteOffer, { isLoading: isDeletingOffer }] = useDeleteOfferMutation();

  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      await deleteOffer(offerData.id).unwrap();
      dispatch(showToast(TOAST_OFFER_MESSAGE('Offer deleted successfully!')));
      onClose();
    } catch (error) {
      setAlert({ type: 'danger', text: 'Failed to delete offer' });
    }
  };

  return (
    <DashboardModal
      title={`Delete ${offerData.buyerAgentName.split(' ')[0]}'s Offer?`}
      action={(
        <Button
          danger
          filled
          label="Confirm"
          type="submit"
          isLoading={isDeletingOffer}
        />
      )}
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
        handleDelete();
      }}
      onClose={onClose}
    >
      <p>Are you sure you would like to delete this offer?</p>
      <ul className="list-disc pl-5 space-y-2 mt-4">
        <li className="flex">
          <span className="font-bold w-32">Buyer Agent Name:</span>
          <span>{offerData.buyerAgentName}</span>
        </li>
        <li className="flex">
          <span className="font-bold w-32">Offer Price:</span>
          <span>{formatCurrency(offerData.price)}</span>
        </li>
        <li className="flex">
          <span className="font-bold w-32">Offer Status:</span>
          <OfferStatus status={offerData.status} />
        </li>
      </ul>
      {alert && <Alert className="mt-6" {...alert} />}
    </DashboardModal>
  );
}
