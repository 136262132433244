import { isEmpty, remove } from 'lodash';
import classNames from 'classnames';

export function BedBathButton({ active, label, lastButton, onClick, width }) {
  const clazz = classNames(
    'flex justify-center py-2.5 px-3 text-sm text-gray-600 hover:bg-primary-light active:bg-primary-light active:outline-none focus:outline-none',
    width,
    {
      'border-t border-b border-l': !active,
      'border-r': lastButton && !active,
      'bg-primary-light border-2 border-primary-dark': active,
    },
  );
  return (
    <button className={clazz} type="button" onClick={onClick}>
      {label}
    </button>
  );
}

export default function BedBath({ filters, setFilters }) {
  const bedFilter = filters.bedroomsTotal ?? {};
  const bedUseExact = filters.bedroomsTotal?.useExact ?? false;
  const bathFilter = filters.bathroomsTotalInteger ?? {};
  const bathUseExact = filters.bathroomsTotalInteger?.useExact ?? false;

  function onBedSelection(e) {
    if (bedUseExact) {
      const updatedBedFilter = { ...bedFilter };
      const { selections } = updatedBedFilter;
      const updatedSelections = [...(selections ?? [])];
      if (selections?.includes(e)) {
        remove(updatedSelections, (n) => n === e);
      } else {
        updatedSelections.push(e);
      }
      setFilters({ ...filters, bedroomsTotal: { useExact: bedUseExact, selections: updatedSelections.sort() } });
    } else if (!e) {
      setFilters({ ...filters, bedroomsTotal: { useExact: bedUseExact, selections: [] } });
    } else {
      setFilters({ ...filters, bedroomsTotal: { useExact: bedUseExact, selections: [e, null] } });
    }
  }

  function onBathSelection(e) {
    if (bathUseExact) {
      const updatedBathFilter = { ...bathFilter };
      const { selections } = updatedBathFilter;
      const updatedSelections = [...(selections ?? [])];
      if (selections?.includes(e)) {
        remove(updatedSelections, (n) => n === e);
      } else {
        updatedSelections.push(e);
      }
      setFilters({ ...filters, bathroomsTotalInteger: { useExact: bathUseExact, selections: updatedSelections.sort() } });
    } else if (!e) {
      setFilters({ ...filters, bathroomsTotalInteger: { useExact: bathUseExact, selections: [] } });
    } else {
      setFilters({ ...filters, bathroomsTotalInteger: { useExact: bathUseExact, selections: [e, null] } });
    }
  }

  return (
    <div className="w-full mb-6">
      <div className="mb-3">Beds</div>
      <div className="flex w-full mb-3">
        {bedUseExact ? (
          <>
            <BedBathButton label="Studio" active={bedFilter.selections?.includes(0)} onClick={() => onBedSelection(0)} width="w-1/5" />
            <BedBathButton label="1" active={bedFilter.selections?.includes(1)} onClick={() => onBedSelection(1)} width="w-1/5" />
            <BedBathButton label="2" active={bedFilter.selections?.includes(2)} onClick={() => onBedSelection(2)} width="w-1/5" />
            <BedBathButton label="3" active={bedFilter.selections?.includes(3)} onClick={() => onBedSelection(3)} width="w-1/5" />
            <BedBathButton label="4" active={bedFilter.selections?.includes(4)} onClick={() => onBedSelection(4)} width="w-1/5" />
            <BedBathButton label="5+" active={bedFilter.selections?.includes(5)} onClick={() => onBedSelection(5)} lastButton width="w-1/5" />
          </>
        ) : (
          <>
            <BedBathButton label="Any" active={isEmpty(bedFilter.selections)} onClick={() => onBedSelection(false)} width="w-1/6" />
            <BedBathButton label="Studio+" active={bedFilter.selections ? bedFilter.selections[0] === 0 : false} onClick={() => onBedSelection(0)} width="w-1/6" />
            <BedBathButton label="1+" active={bedFilter.selections ? bedFilter.selections[0] === 1 : false} onClick={() => onBedSelection(1)} width="w-1/6" />
            <BedBathButton label="2+" active={bedFilter.selections ? bedFilter.selections[0] === 2 : false} onClick={() => onBedSelection(2)} width="w-1/6" />
            <BedBathButton label="3+" active={bedFilter.selections ? bedFilter.selections[0] === 3 : false} onClick={() => onBedSelection(3)} width="w-1/6" />
            <BedBathButton label="4+" active={bedFilter.selections ? bedFilter.selections[0] === 4 : false} onClick={() => onBedSelection(4)} width="w-1/5" />
            <BedBathButton label="5+" active={bedFilter.selections ? bedFilter.selections[0] === 5 : false} onClick={() => onBedSelection(5)} lastButton width="w-1/5" />
          </>
        )}
      </div>
      <div>
        <input
          checked={bedUseExact}
          className="mr-4"
          id="bedroom-checkbox"
          onChange={() => { setFilters({ ...filters, bedroomsTotal: { useExact: !bedUseExact, selections: [] } }); }}
          type="checkbox"
        />
        <label className="text-gray-600" htmlFor="bedroom-checkbox">Use exact match</label>
      </div>
      <div className="mt-6 mb-3">Baths</div>
      <div className="flex w-full mb-3">
        {bathUseExact ? (
          <>
            <BedBathButton label="1" active={bathFilter.selections?.includes(1)} onClick={() => onBathSelection(1)} width="w-1/4" />
            <BedBathButton label="2" active={bathFilter.selections?.includes(2)} onClick={() => onBathSelection(2)} width="w-1/4" />
            <BedBathButton label="3" active={bathFilter.selections?.includes(3)} onClick={() => onBathSelection(3)} width="w-1/4" />
            <BedBathButton label="4" active={bathFilter.selections?.includes(4)} onClick={() => onBathSelection(4)} width="w-1/4" />
            <BedBathButton label="5+" active={bathFilter.selections?.includes(5)} onClick={() => onBathSelection(5)} lastButton width="w-1/4" />
          </>
        ) : (
          <>
            <BedBathButton label="Any" active={isEmpty(bathFilter.selections)} onClick={() => onBathSelection(false)} width="w-1/5" />
            <BedBathButton label="1+" active={bathFilter.selections ? bathFilter.selections[0] === 1 : false} onClick={() => onBathSelection(1)} width="w-1/5" />
            <BedBathButton label="2+" active={bathFilter.selections ? bathFilter.selections[0] === 2 : false} onClick={() => onBathSelection(2)} width="w-1/5" />
            <BedBathButton label="3+" active={bathFilter.selections ? bathFilter.selections[0] === 3 : false} onClick={() => onBathSelection(3)} width="w-1/5" />
            <BedBathButton label="4+" active={bathFilter.selections ? bathFilter.selections[0] === 4 : false} onClick={() => onBathSelection(4)} lastButton width="w-1/5" />
            <BedBathButton label="5+" active={bathFilter.selections ? bathFilter.selections[0] === 5 : false} onClick={() => onBathSelection(5)} lastButton width="w-1/5" />
          </>
        )}
      </div>
      <div>
        <input
          checked={bathUseExact}
          className="mr-4"
          id="bathroom-checkbox"
          onChange={() => { setFilters({ ...filters, bathroomsTotalInteger: { useExact: !bathUseExact, selections: [] } }); }}
          type="checkbox"
        />
        <label className="text-gray-600" htmlFor="bathroom-checkbox">Use exact match</label>
      </div>
    </div>
  );
}
