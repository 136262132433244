import { clearDealNavigationModal } from 'actions/deal_navigation';
import { FormField } from 'components/Form';
import Button from 'components/shared/NewButton';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateDealMutation, useUpdateDealMutation } from 'redux/apiSlice';
import Alert from '../Alert';
import DashboardModal from './DashboardModal';

export const DEAD_REASONS = [
  ['location', 'Location'],
  ['price', 'Price'],
  ['property_condition', 'Property Condition'],
  ['other_offer_accepted', 'Other Offer Accepted'],
  ['higher_bid', 'Higher Bid'],
  ['lack_of_comps', 'Lack Of Comps'],
  ['pulled', 'Pulled From Market'],
  ['no_response', 'No Response'],
  ['other', 'Other'],
];

export default function MarkDealAsDeadModal({ context }) {
  const [deadReason, setDeadReason] = useState(DEAD_REASONS[0][0]);
  const [alert, setAlert] = useState(null);

  const [updateDealMutation, { isLoading: isUpdating }] = useUpdateDealMutation();
  const [createDealMutation, { isLoading: isCreating }] = useCreateDealMutation();

  const { data } = context;
  const { listing, property, portfolio, deal, subdivision } = data;

  const dispatch = useDispatch();
  const passOnDeal = async () => {
    let response;
    if (deal?.id) {
      response = await updateDealMutation({ id: deal.id, deadReason, deletedAt: new Date() });
    } else {
      response = await createDealMutation({ listingId: listing?.id, name: property?.address, portfolioId: portfolio?.id, deadReason });
    }
    if (response.error) {
      console.error(response.error);
      setAlert({ type: 'danger', text: 'There was an error updating the deal' });
    } else {
      dispatch(clearDealNavigationModal());
    }
  };

  return (
    <DashboardModal
      title={subdivision ? 'Pass on Model?' : 'Mark as Dead?'}
      action={(
        <Button
          danger
          filled
          label="Confirm"
          type="submit"
          isLoading={isCreating || isUpdating}
        />
      )}
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
        passOnDeal();
      }}
      onClose={() => dispatch(clearDealNavigationModal())}
    >
      {subdivision ? (
        'Are you sure you would like to pass on this model?'
      ) : (
        'Are you sure you would like to mark this deal as dead?'
      )}

      <FormField
        name="dead reason"
        value={deadReason}
        options={DEAD_REASONS.map(r => [r[0], r[1]])}
        type="select"
        padding="py-2 px-3"
        className="mt-6"
        onChange={(e) => setDeadReason(e.target.value)}
      />
      {alert && <Alert className="mt-6" {...alert} />}
    </DashboardModal>
  );
}
