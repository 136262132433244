import { getMemoOptions } from '@tanstack/react-table';
import { throwingMemo } from '../table.helpers';
import createTableExtension from '../utils/createTableExtension';
import getUnorderedLeafHeaders from '../utils/getUnorderedLeafHeaders';
import { getColumnVisibilityConfigurable } from './utils';

/**
 * @type {(table: import('@tanstack/react-table').Table) => import('@tanstack/react-table').Header[]}
 */
const getVisibilityConfigurableLeafHeaders = createTableExtension((table) => (
  throwingMemo(
    () => [getUnorderedLeafHeaders(table)],
    (headers) => (
      headers.filter(({ column }) => getColumnVisibilityConfigurable(column) !== false)
    ),
    getMemoOptions(table.options, 'debugHeaders', 'getConfigurableFlatColumns'),
  )
));

export default getVisibilityConfigurableLeafHeaders;
