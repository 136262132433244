import { apiSlice } from 'redux/apiSlice';
import { camelCaseKeys, snakeCaseKeys } from 'components/utils';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchOrganizations: builder.query({
        query: () => ({ url: '/api/organizations' }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: ['Organizations'],
      }),
      createOrganization: builder.mutation({
        query: organization => ({
          url: '/api/organizations',
          method: 'POST',
          body: snakeCaseKeys(organization),
        }),
        invalidatesTags: ['Organizations'],
      }),
      editUser: builder.mutation({
        query: user => ({
          url: `/api/users/${user.id}`,
          method: 'PUT',
          body: snakeCaseKeys(user),
        }),
        invalidatesTags: ['Organizations'],
      }),
      sendInviteEmail: builder.mutation({
        query: user => ({
          url: `/api/users/${user.id}/send_invite_email`,
          method: 'POST',
          body: {},
        }),
        invalidatesTags: ['Organizations'],
      }),
    };
  },
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useCreateOrganizationMutation,
  useEditUserMutation,
  useFetchOrganizationsQuery,
  useSendInviteEmailMutation,
} = extendedApiSlice;
