import MuteZero from './MuteZero';
import { deliveryScheduleMetaKey } from './subdivision';

export default function TotalQtyCell({ row }) {
  const total = row
    .getVisibleCells()
    .filter(({ column: { columnDef: { meta = {} } } }) => Object.hasOwn(meta, deliveryScheduleMetaKey))
    .map(({ getValue }) => getValue())
    .reduce((tot, curr) => tot + (curr || 0), 0);

  return <MuteZero value={total} />;
}
