import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFetchWorkflowTemplatesQuery } from 'redux/apiSlice';
import { naturalSortComparator } from 'components/utils';

const WORKFLOW_TEMPLATE_PLACEHOLDER_OPT = Object.freeze(['', 'Select a workflow template', true]);
const WORKFLOW_TEMPLATE_TXN_PLACEHOLDER_OPTS = Object.freeze([Object.freeze(['', 'Select a transaction type', true])]);

const nameComparator = naturalSortComparator();

const useMakeOptions = ({ workflowTemplates, transactionType }) => (
  useMemo(() => {
    if (isNil(workflowTemplates)) {
      return null;
    }

    if (isNil(transactionType)) {
      return WORKFLOW_TEMPLATE_TXN_PLACEHOLDER_OPTS;
    }

    const workflowOptions = workflowTemplates
      .filter(({ workflowType }) => workflowType === transactionType)
      .toSorted(({ name: left }, { name: right }) => nameComparator(left, right))
      .map(({ id, name }) => Object.freeze([id, name]));

    workflowOptions.unshift(WORKFLOW_TEMPLATE_PLACEHOLDER_OPT);
    return Object.freeze(workflowOptions);
  }, [transactionType, workflowTemplates])
);

const useWorkflowTemplateOptions = ({ transactionType }) => {
  const { currentData: workflowTemplates } = useFetchWorkflowTemplatesQuery();
  const [workflowTemplateId, setWorkflowTemplateId] = useState(undefined);

  useEffect(() => {
    if (!isNil(transactionType) && (workflowTemplateId === undefined || workflowTemplates?.find(({ id }) => id === workflowTemplateId).workflowType !== transactionType)) {
      setWorkflowTemplateId(workflowTemplates?.find(({ workflowType }) => workflowType === transactionType)?.id);
    }
  }, [transactionType, workflowTemplateId, workflowTemplates]);

  const options = useMakeOptions({ workflowTemplates, transactionType });
  return [workflowTemplateId, setWorkflowTemplateId, options];
};

export default useWorkflowTemplateOptions;
