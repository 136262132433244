import { useNavigate } from 'react-router-dom';
import { useFetchOffMarketMarketplaceDashboardStatisticsQuery } from 'redux/offMarketMarketplaceApiSlice';
import { LoadingIndicator } from 'components/icons';
import { DashboardCard } from 'components/NhimbleDashboard/Shared/ReturnMetricBoxes';

function SectionHeader({ label }) {
  return <h2 className="text-2xl text-gray-700">{label}</h2>;
}

function Section({ label, subHeader, children }) {
  return (
    <>
      <SectionHeader label={label} />
      <div className="py-12">
        {subHeader && <h3 className="mb-4 text-title-md text-gray-500">{subHeader}</h3>}
        {children}
      </div>
    </>
  );
}

export default function OffMarketDashboard() {
  const navigate = useNavigate();
  const { data, isLoading } = useFetchOffMarketMarketplaceDashboardStatisticsQuery();

  if (isLoading) {
    return <LoadingIndicator className="text-gray-500 size-8" />;
  }

  const {
    all: { activeListings, activeOffers, closedListings },
    recent: { newListings, expiredListings, offers },
  } = data;

  const navigateToInventory = () => navigate('/off_market_marketplace/inventory');
  const navigateToOffers = () => navigate('/off_market_marketplace/offers');
  // const navigateToImport = () => navigate('/off_market_marketplace/import');

  return (
    <div className="py-16 px-8 flex flex-col h-container w-container">
      <Section label="Recent Activity" subHeader="Past Week">
        <div className="flex gap-x-6">
          <DashboardCard label="New Listings" value={newListings} width="w-60" onClick={navigateToInventory} />
          <DashboardCard label="Expired Listings" value={expiredListings} width="w-60" onClick={navigateToInventory} />
          <DashboardCard label="Offers" value={offers} width="w-60" onClick={navigateToOffers} />
        </div>
      </Section>
      <Section label="Listings">
        <div className="flex gap-x-6">
          <DashboardCard label="Active Listings" value={activeListings} width="w-60" onClick={navigateToInventory} />
          <DashboardCard label="Active Offers" value={activeOffers} width="w-60" onClick={navigateToOffers} />
          <DashboardCard label="Closed Listings" value={closedListings} width="w-60" />
        </div>
      </Section>
    </div>
  );
}
