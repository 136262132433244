import { compact, every, identity, isFunction, partial, partialRight, sortBy, sum, sumBy } from 'lodash';
import { LAYOUT, MODELLED_INDIVIDUALLY } from 'components/constants';
import {
  dotProductBy,
  formatCurrency,
  formatCurrencyAbbreviated,
  formatEquityMultiple,
  formatPercentage,
} from 'components/utils';
import { childScenarios } from './portfolio-deal.utils';

const SENSITIVITY_INCREMENTS = 4;

const getValue = (formatter, sensitivityContext, metricKey, returnMetrics, price) => {
  if (isFunction(metricKey)) {
    return formatter(metricKey(price, returnMetrics, sensitivityContext));
  } else {
    return formatter(returnMetrics[metricKey]);
  }
};

const renderRow = ({ metricKey, label, sensitizedPrices, returnMetrics, sensitivities, sensitivityContext, formatter = identity }) => {
  const partialGetValue = partial(getValue, formatter, sensitivityContext);
  return (
    <tr key={metricKey}>
      <td className="border-r">{label}</td>
      {sensitizedPrices.slice(0, SENSITIVITY_INCREMENTS).map(price => (
        <td key={price} className="px-2 py-1 text-right">{partialGetValue(metricKey, sensitivities[price], price)}</td>
      ))}
      <td className="px-2 py-1 text-right bg-gray-50">{partialGetValue(metricKey, returnMetrics, sensitivityContext.purchasePrice)}</td>
      {sensitizedPrices.slice(SENSITIVITY_INCREMENTS).map(price => (
        <td key={price} className="px-2 py-1 text-right">{partialGetValue(metricKey, sensitivities[price], price)}</td>
      ))}
    </tr>
  );
};

const getBasicMetrics = (hasMarketPrice) => compact([
  {
    metricKey: (price, _, { listPrice }) => (price / listPrice) - 1,
    label: 'Discount to Ask',
    formatter: formatPercentage,
  },
  hasMarketPrice && {
    metricKey: (price, _, { marketPrice }) => (price / marketPrice) - 1,
    label: 'Discount to Market',
    formatter: formatPercentage,
  },
  hasMarketPrice && {
    metricKey: (_, returnMetrics, { marketPrice }) => (marketPrice / returnMetrics.unleveredBasis[0]) - 1,
    label: 'Built-in Equity',
    formatter: formatPercentage,
  },
  {
    metricKey: (_, returnMetrics) => returnMetrics.unleveredBasis[0],
    label: 'Acquisition Cost',
    formatter: partialRight(formatCurrencyAbbreviated, 2),
  },
  {
    metricKey: (_, returnMetrics, { numberOfUnits }) => returnMetrics.unleveredBasis[0] / numberOfUnits,
    label: 'Cost per Unit',
    formatter: partialRight(formatCurrencyAbbreviated, 0),
  },
  {
    metricKey: (_, returnMetrics, { totalRsf }) => returnMetrics.unleveredBasis[0] / totalRsf,
    label: 'Cost per Sq Ft',
    formatter: formatCurrency,
  },
  {
    metricKey: (price, _, { inPlaceRent }) => (inPlaceRent * 12) / price,
    label: 'Seller Gross Yield (In-Place Rents)',
    formatter: formatPercentage,
  },
  {
    metricKey: (price, _, { marketRent }) => (marketRent * 12) / price,
    label: 'Gross Yield (Market Rents)',
    formatter: formatPercentage,
  },
]);
const RETURNS_METRICS = [
  {
    metricKey: 'stabilizedYield',
    label: 'Stabilized Yield',
    formatter: formatPercentage,
  },
  {
    metricKey: 'unleveredCashOnCash',
    label: 'Unlevered Cash-on-Cash',
    formatter: formatPercentage,
  },
  {
    metricKey: 'leveredCashOnCash',
    label: 'Levered Cash-on-Cash',
    formatter: formatPercentage,
  },
  {
    metricKey: 'unleveredIrr',
    label: 'Unlevered IRR',
    formatter: formatPercentage,
  },
  {
    metricKey: 'leveredIrr',
    label: 'Levered IRR',
    formatter: formatPercentage,
  },
  {
    metricKey: 'unleveredEquityMultiple',
    label: 'Unlevered Equity Multiple',
    formatter: formatEquityMultiple,
  },
  {
    metricKey: 'leveredEquityMultiple',
    label: 'Levered Equity Multiple',
    formatter: formatEquityMultiple,
  },
];

export default function PortfolioDealSensitivities({ context }) {
  const { data: { deal, properties }, modelData: { model } } = context;
  const { scenario, scenarios } = model;
  const { parameters: { purchasePrice }, returnMetrics, sensitivities } = scenario;
  const sensitizedPrices = sortBy(Object.keys(sensitivities), p => parseInt(p, 10));

  let avm = null;
  if (every(properties, p => p.data?.avm)) {
    avm = sumBy(properties, 'data.avm.estimatedValueAmount');
  }
  let numberOfUnits = scenario.parameters.units.length;
  let totalRsf = sumBy(scenario.parameters.units, 'rsf');
  let inPlaceRent = sumBy(scenario.parameters.units, 'inPlaceRent');
  let marketRent = sumBy(scenario.parameters.units, 'marketRent');

  let listPrice = scenario?.parameters?.listPrice || 1;
  const isIndividuallyModelled = deal.modellingMethod === MODELLED_INDIVIDUALLY;
  if (isIndividuallyModelled) {
    const individualScenarios = childScenarios({ scenarios, primaryOnly: true });
    listPrice = dotProductBy(individualScenarios, 'parameters.listPrice', ({ parameters: { multiplicity } }) => multiplicity ?? 1);
    numberOfUnits = dotProductBy(individualScenarios, 'parameters.units.length', ({ parameters: { multiplicity } }) => multiplicity ?? 1);
    totalRsf = dotProductBy(individualScenarios, ({ parameters: { units } }) => sumBy(units, 'rsf'), ({ parameters: { multiplicity } }) => multiplicity ?? 1);
    inPlaceRent = dotProductBy(individualScenarios, ({ parameters: { units } }) => sumBy(units, 'inPlaceRent'), ({ parameters: { multiplicity } }) => multiplicity ?? 1);
    marketRent = dotProductBy(individualScenarios, ({ parameters: { units } }) => sumBy(units, 'marketRent'), ({ parameters: { multiplicity } }) => multiplicity ?? 1);
  }

  // we're setting market price to AVM as we are more likely to have that value
  // than a sale comp value for properties outside our current markets
  const marketPrice = avm;
  const sensitivityContext = {
    inPlaceRent,
    listPrice,
    marketPrice,
    marketRent,
    numberOfUnits,
    purchasePrice,
    totalRsf,
  };
  const acquisitionCost = returnMetrics.unleveredBasis[0];

  return (
    <div
      className="p-6 overflow-auto"
      style={{
        width: `calc(100vw - ${LAYOUT.rightNavWidth + LAYOUT.sidebarWidth + LAYOUT.portfolioSidebarWidth}px)`,
        height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)`,
      }}
    >
      <div className="p-6 bg-white rounded">
        <div className="text-lg mb-6">Sensitivities</div>
        <div className="mb-3">{`Purchase Price: ${formatCurrency(purchasePrice)}`}</div>
        <div className="mb-3">{`Acquisition Cost: ${formatCurrency(acquisitionCost)}`}</div>
        <div className="mb-3">{`Market Price: ${marketPrice ? formatCurrency(marketPrice) : 'N/A'}`}</div>
        <table className="w-full">
          <thead className="h-10 border-b">
            <tr>
              <td className="border-r" />
              {sensitizedPrices.slice(0, SENSITIVITY_INCREMENTS).map(price => <td key={price} className="text-center">{formatCurrency(price)}</td>)}
              <td className="bg-gray-50 text-center">{formatCurrency(purchasePrice)}</td>
              {sensitizedPrices.slice(SENSITIVITY_INCREMENTS).map(price => <td key={price} className="text-center">{formatCurrency(price)}</td>)}
            </tr>
          </thead>
          <tbody>
            {getBasicMetrics(marketPrice).map(metric => renderRow({ ...metric, sensitizedPrices, returnMetrics, sensitivities, sensitivityContext }))}
            <tr className="bg-gray-50">
              <td className="py-1 border-b border-r font-medium">Returns</td>
              <td className="border-b" colSpan={Object.keys(sensitivities).length + 1} />
            </tr>
            {RETURNS_METRICS.map(metric => renderRow({ ...metric, sensitizedPrices, returnMetrics, sensitivities, sensitivityContext }))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
