/* eslint-disable react/button-has-type */
import { useEffect, useMemo, useState } from 'react';
import GoogleMap from 'components/common/GoogleMap';
import { includes, isEmpty, isUndefined, map, xor } from 'lodash';
import { formatCurrency, formatInteger } from 'components/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRentCompIds } from 'redux/rentCompsSlice';
import { makePropertyRow } from 'components/rentComps/rentComps';
import { Chevron, Fullscreen } from 'components/icons';

function InfoWindow(rentComp) {
  const dispatch = useDispatch();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { selectedRentCompIds } = useSelector(state => state.rentComps);
  const {
    active,
    bathroomsTotalInteger,
    bedroomsTotal,
    buildingArea,
    closePrice,
    id,
    isSubject,
    listPrice,
    media,
    placeholderImg,
    streetAddress,
  } = rentComp;

  const buttonLabel = active ? 'Remove' : 'Add';
  const price = closePrice || listPrice;

  const images = (isUndefined(media) || isEmpty(media))
    ? [placeholderImg]
    : map(media, p => (p.MediaURL || p.mediaurl || p.url));

  useEffect(() => {
    const el = document.querySelector(`#rent-comp-table tr[data-id='${id}']`);
    if (el) {
      el.querySelector('div').scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });

      el.classList.add('bg-primary-50', 'animate-pulse');
      setTimeout(() => {
        el.classList.remove('bg-primary-50');
        el.classList.remove('animate-pulse');
      }, 3000);
    }
  }, [id]);

  const handleUpdateSelectedComps = () => dispatch(setSelectedRentCompIds(xor(selectedRentCompIds, [id])));

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  if (isSubject) return null;

  return (
    <div onClick={e => e.stopPropagation()} className="z-50 cursor -top-24 relative w-60 h-auto left-10 block max-w-sm bg-white border rounded overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
      <div className="max-w-sm rounded overflow-hidden shadow-lg h-full flex flex-col">
        <div className="relative group h-40">
          <img className="w-full h-full object-cover" src={images[currentImageIndex]} alt="House" referrerPolicy="no-referrer" />
          <button
            onClick={handleUpdateSelectedComps}
            className="absolute top-0 right-0 m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {buttonLabel}
          </button>
          {media?.length ? (
            <>
              <div className="absolute bottom-1 left-1 bg-black bg-opacity-50 text-white px-2 py-1 rounded hidden group-hover:block">
                {currentImageIndex + 1}
                {' '}
                of
                {' '}
                {images.length}
              </div>
              <button className="absolute top-1/2 left-0 text-white bg-black bg-opacity-50 px-2 py-1 hidden group-hover:block" onClick={prevImage}>&lt;</button>
              <button className="absolute top-1/2 right-0 text-white bg-black bg-opacity-50 px-2 py-1 hidden group-hover:block" onClick={nextImage}>&gt;</button>
            </>
          ) : null}
        </div>
        <div className="p-3">
          <div className="font-bold text-sm mb-2">{formatCurrency(price)}</div>
          <p className="text-gray-700 text-xs">
            {bedroomsTotal}
            {' '}
            Beds |
            {' '}
            {bathroomsTotalInteger}
            {' '}
            Baths |
            {' '}
            {formatInteger(buildingArea)}
            {' '}
            Sq.Ft
          </p>
          <p className="text-gray-700 text-sm mt-1">{streetAddress}</p>
        </div>
      </div>
    </div>
  );
}

export default function RentCompsMap({ expanded, property, setDrawingFiltered, setMinimizeMap, setShowMapModal }) {
  const { allComps, filteredRentCompIds, selectedRentCompIds } = useSelector(state => state.rentComps);
  const { hoveredId } = useSelector(state => state.map);

  const places = useMemo(() => {
    if (!allComps || !filteredRentCompIds || !selectedRentCompIds) return null;
    const mappedComps = allComps.filter(({ id }) => ((id === 'subject') || filteredRentCompIds.includes(id) || selectedRentCompIds.includes(id)));
    mappedComps.push(makePropertyRow(property));
    const formattedPlaces = mappedComps.map(rentComp => ({
      active: includes(selectedRentCompIds, rentComp.id),
      lat: rentComp.latitude,
      lng: rentComp.longitude,
      subject: rentComp.isSubject,
      ...rentComp,
    }));
    // order places so that selected and subject property markers are on top
    formattedPlaces.sort((a, b) => {
      if (a.subject) return 1;
      if (b.subject) return -1;
      if (a.active && !b.active) return 1;
      if (!a.active && b.active) return -1;
      return b.score - a.score;
    });
    return formattedPlaces;
  }, [allComps, filteredRentCompIds, selectedRentCompIds]);

  if (!places) {
    // TODO: better loading state?
    return <div className="w-full h-full bg-gray-100" />;
  }

  const defaultCenter = {
    lat: Number(property.latitude),
    lng: Number(property.longitude),
  };

  return (
    <div className="w-full h-full relative">
      <GoogleMap
        defaultCenter={defaultCenter}
        infoWindow={InfoWindow}
        hoveredId={hoveredId}
        places={places}
        setDrawingFiltered={setDrawingFiltered}
      />
      {!expanded && (
        <>
          <Fullscreen
            className="absolute top-[5px] right-[50px] cursor-pointer bg-white h-10 w-10 hover:bg-gray-100"
            onClick={() => setShowMapModal(true)}
          />
          <Chevron
            direction="right"
            className="absolute top-[5px] right-[5px] cursor-pointer bg-white h-10 w-10 hover:bg-gray-100"
            onClick={() => setMinimizeMap(true)}
          />
        </>
      )}
    </div>
  );
}
