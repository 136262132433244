import { cloneDeep, sum, sumBy } from 'lodash';
import SectionLabel from './SectionLabel';
import UnitsTable from './UnitsTable';
import CapitalProjectsTable, { CapitalFeesAndCostsTable } from './CapitalProjectsTable';
import AccountingMethodToggle from './AccountingMethodToggle';
import Input from '../Input';
import { TH, TR } from '../Table';
import { formatCurrency } from '../utils';
import { calcCapitalItemTotalBudget, calcMonthlyConstructionExpenses, enabledCapitalFees, enabledCapitalItems } from './capital';
import { ACCOUNTING_METHOD_FOLLOW_ON } from './dcf';

function UnitInfoTableRow({ onChange, unit, renovationToggle }) {
  const data = [
    <div className="p-2 text-left">{unit.number}</div>,
    <div className="p-2 text-right">{unit.bedrooms}</div>,
    <div className="p-2 text-right">{unit.bathrooms}</div>,
    <div className="p-2 text-right">{unit.rsf}</div>,
    <div className="p-2 text-right">{unit.rollToMarket}</div>,
    <AccountingMethodToggle
      className="p-2"
      name="turnAccountingMethod"
      value={unit.turnAccountingMethod}
      onChange={onChange}
      disabled={!renovationToggle}
    />,
    <Input name="turnDowntime" value={unit.turnDowntime} onChange={onChange} min="0" width="w-16" disabled={!unit.turnBudget || !renovationToggle} />,
    <Input name="turnBudget" value={unit.turnBudget} onChange={onChange} min="0" width="w-24" disabled={!renovationToggle} />,
    <Input name="terminationCost" value={unit.terminationCost} min="0" onChange={onChange} width="w-24" disabled={!renovationToggle} />,
  ];

  return (
    <TR center data={data} />
  );
}

function UnitInfoAggregateRow({ units }) {
  const data = [
    'Total',
    null,
    null,
    null,
    null,
    null,
    null,
    formatCurrency(sum(units.map(unit => unit.turnBudget || 0))),
    formatCurrency(sum(units.map(unit => unit.terminationCost || 0))),
  ];
  return (
    <TR className="border-t" data={data} />
  );
}

export default function CapEx({ dcfParams, onChange, unitTypeEntry, setUnitTypeEntry, unitRenovationEntry, setUnitRenovationEntry }) {
  const constructionExpenses = calcMonthlyConstructionExpenses(dcfParams);
  const totalFollowOnCapitalExpense = sum(constructionExpenses[ACCOUNTING_METHOD_FOLLOW_ON]);
  const totalCapitalExpense = sumBy(Object.values(constructionExpenses), sum);
  const totalConstructionBudget = sum(dcfParams.units.map(unit => unit.turnBudget)) + (sum(enabledCapitalItems(dcfParams).map(item => calcCapitalItemTotalBudget(item, dcfParams))));
  const totalProjectCosts = sum(enabledCapitalFees(dcfParams).map(item => calcCapitalItemTotalBudget(item, dcfParams, totalCapitalExpense, totalFollowOnCapitalExpense)));

  const unitsTableHeader = (
    <tr>
      <TH value={unitTypeEntry ? '# of Units' : 'Unit Number'} />
      <TH value="Bedrooms" />
      <TH value="Bathrooms" />
      <TH value="RSF" />
      <TH value="Unit Roll Month" />
      <TH value="Cash Flow / Follow-On Capital" />
      <TH value="Renovation Downtime (months)" />
      <TH value="Renovation Budget ($)" />
      <TH value="Termination Fee ($)" />
    </tr>
  );

  const handleUnitRenovationToggle = (value) => {
    setUnitRenovationEntry(!unitRenovationEntry);
    if (!value) {
      const updatedUnits = cloneDeep(dcfParams.units);
      updatedUnits
        .forEach(unit => Object.assign(unit, { turnBudget: 0, terminationCost: 0 }));
      const syntheticEvent = { target: { name: 'units', value: updatedUnits } };
      onChange(syntheticEvent);
    }
  };

  return (
    <div className="overflow-x-auto bg-gray-50">
      <SectionLabel className="mt-4 pl-4" text="Unit Renovations" toggle toggleValue={unitRenovationEntry} setToggleValue={handleUnitRenovationToggle} />
      <UnitsTable
        dcfParams={dcfParams}
        onChange={onChange}
        unitTypeEntry={unitTypeEntry}
        setUnitTypeEntry={setUnitTypeEntry}
        header={unitsTableHeader}
        UnitRow={UnitInfoTableRow}
        AggregateRow={UnitInfoAggregateRow}
        renovationToggle={unitRenovationEntry}
      />
      <SectionLabel className="mt-12 pl-4" text="Other Capital Projects" />
      <CapitalProjectsTable isModelParameter dcfParams={dcfParams} onChange={onChange} />
      <div className="p-4 text-right text-sm">
        Total Construction Budget:
        <span className="ml-4 text-gray-400">{formatCurrency(totalConstructionBudget)}</span>
      </div>
      <hr />
      <SectionLabel className="mt-12 pl-4" text="Project Fees & Costs" />
      <CapitalFeesAndCostsTable isModelParameter dcfParams={dcfParams} onChange={onChange} />
      <div className="p-4 text-right text-sm">
        Total Project Costs:
        <span className="ml-4 text-gray-400">{formatCurrency(totalProjectCosts + totalConstructionBudget)}</span>
      </div>
    </div>
  );
}
