import cx from 'classnames';
import { AcquisitionIcon, OfferIcon, RealEstateIcon, SecurityGateIcon, UploadIcon } from 'components/icons';

/* eslint-disable import/prefer-default-export */
export const FILTERS_AND_TABS_HEIGHT = 24 + 38 + 49 + 16 + 44;

export const DASHBOARD_NAVS = [
  { path: 'offers', label: 'Offers', icon: <OfferIcon /> },
  { path: 'acquisitions', label: 'Acquisitions', icon: <AcquisitionIcon /> },
  { path: 'leasing', label: 'Leasing', icon: <SecurityGateIcon /> },
  { path: 'dispositions', label: 'Dispositions', icon: <RealEstateIcon /> },
  { path: 'upload', label: 'Upload', icon: <UploadIcon /> },
];

// Sub-component for no data message
export function NoDataMessage() {
  return (
    <div className="flex items-center justify-center h-64">
      <div className="text-center border border-gray-300 rounded-lg p-4 max-w-md mx-auto">
        <p className="text-lg font-semibold text-gray-700">No Data Available</p>
      </div>
    </div>
  );
}

// Sub-component for market summary header
export function MarketSummaryHeader() {
  return (
    <h2 className="text-lg leading-6 font-medium text-gray-900 mt-10 mb-4">Overall - Market Summary</h2>
  );
}

// temporarily removing custom styling until we resolve production issue of gradient not being applied correctly
export const theadClassName = ''; //'bg-gradient-to-r from-gray-600 to-gray-800 text-yellow-300 shadow-md';
export const tdClassNameFn = (column) => cx('whitespace-nowrap border text-sm', column.id === 'market' && 'font-semibold');
