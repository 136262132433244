import { apiSlice } from 'redux/apiSlice';
import { camelCaseKeys } from 'components/utils';

const pipelineApiSlice = apiSlice.injectEndpoints({
  endpoints(pipeline) {
    return {
      fetchPipeline: pipeline.query({
        query(transactionType) {
          return `/api/pipeline?transaction_type=${transactionType}`;
        },
        providesTags: ['PipelineDeals'],
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchPipelineClosed: pipeline.query({
        query(transactionType) {
          return `/api/pipeline/closed?transaction_type=${transactionType}`;
        },
        providesTags: ['PipelineClosedDeals'],
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchPipelineDead: pipeline.query({
        query(transactionType) {
          return `/api/pipeline/dead?transaction_type=${transactionType}`;
        },
        providesTags: ['PipelineDeadDeals'],
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchPipelineStats: pipeline.query({
        query(transactionType) {
          return `/api/pipeline/stats?transaction_type=${transactionType}`;
        },
        providesTags: ['PipelineStats'],
        transformResponse: response => camelCaseKeys(response),
      }),
      fetchPipelineTasks: pipeline.query({
        query(transactionType) {
          return `/api/pipeline/tasks?transaction_type=${transactionType}`;
        },
        providesTags: ['PipelineTasks'],
        transformResponse: response => camelCaseKeys(response),
      }),
    };
  },
});

export const {
  useFetchPipelineQuery,
  useFetchPipelineClosedQuery,
  useFetchPipelineDeadQuery,
  useFetchPipelineStatsQuery,
  useFetchPipelineTasksQuery,
} = pipelineApiSlice;
