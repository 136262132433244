import useLocalStorage, { useLocalStorageValues } from 'hooks/useLocalStorage';
import { isEqual, isNil } from 'lodash';
import { useLayoutEffect, useMemo } from 'react';
import {
  useActivePreset,
  useActivePresetId,
  useOrderConfigurableLeafColumns,
  useOrderDraggableRange,
  useSetColumnOrder,
  useSetColumnVisibility,
  useVisibilityConfigurableFlatColumns,
} from '../DataTableContext';
import {
  activePresetStorageKey,
  EMPTY_PRESET,
  FALLBACK_VISIBILITY,
  getDefaultPresetId,
  orderStorageKey,
  STORAGE_VERSION,
  visibilitySectionStorageKey,
  visibilityStorageKey,
} from './utils';

/**
 * @param {string} tableId
 */
const useApplyActivePreset = ({ tableId }) => {
  const defaultPresetId = getDefaultPresetId({ tableId });
  const [presetId, setPresetId] = useLocalStorage(activePresetStorageKey({ tableId }), defaultPresetId, STORAGE_VERSION);

  const [activePresetId, setActivePresetId] = useActivePresetId();
  useLayoutEffect(() => {
    setActivePresetId(presetId);
  }, [presetId, setActivePresetId]);

  if (presetId !== activePresetId && activePresetId) {
    setPresetId(activePresetId);
  }
};

const useApplyVisibilityConfig = () => {
  const preset = useActivePreset();
  const presetId = preset.id;

  const configurableColumns = useVisibilityConfigurableFlatColumns();

  const [localStorageKeys, columnToLocalStorageKeys] = useMemo(() => {
    const storageLookups = [];
    /** @type {Map<string | null, string>} */
    const sectionKeys = new Map();
    /** @type {Map<string, [string, string]>} */
    const columnIdToKeys = new Map();

    configurableColumns.forEach(({ id, columnDef: { meta: { toggleSection } = {} } }) => {
      let sectionKey = sectionKeys.get(toggleSection ?? null);
      if (sectionKey === undefined) {
        sectionKey = visibilitySectionStorageKey({ toggleSection, presetId });
        sectionKeys.set(toggleSection ?? null, sectionKey);
      }

      const key = visibilityStorageKey({ columnId: id, presetId });
      storageLookups.push({ key, defaultValue: null, version: STORAGE_VERSION });
      columnIdToKeys.set(id, [sectionKey, key]);
    });

    Array
      .from(sectionKeys.values())
      .forEach((key) => storageLookups.push({ key, defaultValue: null, version: STORAGE_VERSION }));
    return [storageLookups, columnIdToKeys];
  }, [configurableColumns, presetId]);

  const storedValues = useLocalStorageValues(localStorageKeys);
  const setColumnVisibility = useSetColumnVisibility();

  useLayoutEffect(() => {
    setColumnVisibility((prev) => ({
      ...prev,
      ...Object.fromEntries(configurableColumns.map(({ id, columnDef: { meta: { toggleSection } = {} } }) => {
        // search from the end to find the most specific visibility preference
        // and set column visibility to that
        const storageKey = columnToLocalStorageKeys.get(id).findLast((key) => typeof storedValues[key] === 'boolean');
        const visible = storedValues[storageKey]
          ?? preset.visibility[id]
          ?? preset.toggleSection[toggleSection]
          ?? preset.defaultVisibility
          ?? FALLBACK_VISIBILITY;

        return [id, visible];
      })),
    }));
  }, [preset, storedValues, configurableColumns, columnToLocalStorageKeys, setColumnVisibility]);
};

const useApplyOrderConfig = () => {
  const preset = useActivePreset();
  const presetId = preset.id;

  const defaultOrder = preset.order ?? EMPTY_PRESET.order;
  const [order, setOrder] = useLocalStorage(orderStorageKey({ presetId }), defaultOrder, STORAGE_VERSION);

  const columns = useOrderConfigurableLeafColumns();
  const [draggableRangeStart, draggableRangeEnd] = useOrderDraggableRange();

  useLayoutEffect(() => {
    // re-calculate column order given column definition order of non-orderable columns.
    // 1. the columns that are not order-able can change, and the order of those columns can also change
    // 2. order columns that are not covered by the existing order but should not be placed at the end

    if (!order.length) {
      return;
    }

    if (draggableRangeStart < 0) {
      // no draggable column, remove column order
      setOrder(EMPTY_PRESET.order);
    } else {
      const orderedSet = new Set();
      columns.slice(0, draggableRangeStart).forEach(({ id }) => orderedSet.add(id));
      order.forEach((id) => orderedSet.add(id));
      columns.forEach(({ id }) => orderedSet.add(id));
      columns.slice(draggableRangeEnd + 1).forEach(({ id }) => {
        orderedSet.delete(id);
        orderedSet.add(id);
      });

      const updatedOrder = Array.from(orderedSet);
      if (!isEqual(order, updatedOrder)) {
        // not using setState callback because callback is unreliable when storage key changes
        setOrder(updatedOrder);
      }
    }
  }, [columns, draggableRangeStart, draggableRangeEnd, setOrder, order]);

  const setColumnOrder = useSetColumnOrder();
  useLayoutEffect(() => {
    if (!order.length) {
      setColumnOrder((prev) => (!prev.length ? prev : order));
      return;
    }

    setColumnOrder(order);
  }, [order, setColumnOrder]);
};

function LoadPreset({ tableId, children }) {
  useApplyActivePreset({ tableId });
  const [activePresetId] = useActivePresetId();

  return isNil(activePresetId) ? null : children;
}

function LoadConfig() {
  useApplyVisibilityConfig();
  useApplyOrderConfig();
}

export default function DataTableConfig({ tableId, children }) {
  return (
    <LoadPreset tableId={tableId}>
      <LoadConfig />
      {children}
    </LoadPreset>
  );
}
