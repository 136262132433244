import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { apiSlice } from 'redux/apiSlice';
import { uniqBy, keys } from 'lodash';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchNotifications: builder.query({
        query: ({ page = 1 }) => ({ url: `/api/notifications?page=${page}` }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: (result, error, { page }) => [{ type: 'Notifications', id: page }],
        onQueryStarted: async ({ page }, { dispatch, queryFulfilled, getState }) => {
          try {
            const { data } = await queryFulfilled;
            const fetchKeys = keys(getState().api.queries);
            let notifications = [];

            fetchKeys.forEach(element => {
              if (getState().api.queries[element].data?.notifications) {
                notifications.push(...getState().api.queries[element].data.notifications.data);
              }
            });
            notifications = uniqBy(notifications, (notification) => notification.attributes);
            dispatch(apiSlice.util.updateQueryData('fetchNotifications', { page }, () => ({ notifications: { data: notifications }, pagy: data.pagy })));
          } catch (error) {
            console.error('Error fetching notifications:', error);
          }
        },
      }),
    };
  },
});

export const { useFetchNotificationsQuery, useMarkNotificationsAsSeenMutation } = extendedApiSlice;
