import { Popover } from '@headlessui/react';
import { noop } from 'lodash';
import { Fragment, useCallback, useRef } from 'react';
import { ArrowDropDown } from 'components/icons';
import { useCreateNonPortalSlot } from 'components/shared/LayoutSlots';
import Button from 'components/shared/NewButton';
import FilterChipButton from './FilterChipButton';
import { useFilterForm } from './useFilterForm';

export default function FilterChip({ children }) {
  const renderElements = useCreateNonPortalSlot('popover');

  const closeRef = useRef(noop);
  const {
    formProps,
    applyButtonProps,
    resetButtonProps,
  } = useFilterForm(useCallback(() => closeRef.current(), []));

  return (
    <>
      {children}
      <Popover className="relative min-w-20">
        <Popover.Button
          as={FilterChipButton}
          trailingIcon={<ArrowDropDown />}
          width="w-full"
        />
        {/* TODO: transition */}
        <Popover.Panel
          {...formProps}
          focus
          unmount
          as={Fragment}
        >
          {({ close }) => {
            // TODO: replace with useClose hook in headlessui v2
            closeRef.current = close;
            return (
              <form className="absolute left-0 mt-1 z-30 bg-white rounded-lg px-4 pt-6 pb-3 border border-gray-300 shadow-lg">
                <div
                  // hide first label when there is only 1 filter
                  className="contents first-of-type:[&>label]:only-of-type:*:hidden"
                >
                  {renderElements()}
                </div>
                <menu className="flex justify-end mt-6 gap-x-6">
                  <li><Button {...resetButtonProps} textOnly label="Reset" /></li>
                  <li><Button {...applyButtonProps} tonal label="Apply" /></li>
                </menu>
              </form>
            );
          }}
        </Popover.Panel>
      </Popover>
    </>
  );
}
