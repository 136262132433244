import { useDispatch } from 'react-redux';
import {
  showDownloadModelModal,
  showGenerateDealSummaryModal,
  showGenerateLoiModal,
  showGeneratePurchaseAgreementModal,
} from 'actions/deal_navigation';
import Menu from 'components/shared/Menu';

export default function DownloadMenu({ deal, modelData }) {
  const dispatch = useDispatch();

  const { id: dealId, scenarioId, canGenerateLoi, canGeneratePurchaseAgreement } = deal;
  const modelScenarioId = modelData?.model?.scenario?.id;

  const menuItems = [
    [
      dealId && {
        text: 'Deal Summary',
        onClick: () => dispatch(showGenerateDealSummaryModal(dealId, scenarioId)),
      },
      dealId && canGeneratePurchaseAgreement && {
        text: 'Purchase Agreement',
        onClick: () => dispatch(showGeneratePurchaseAgreementModal(dealId, scenarioId)),
      },
      dealId && canGenerateLoi && {
        text: 'LOI',
        onClick: () => dispatch(showGenerateLoiModal(deal)),
      },
      dealId && {
        text: 'Model',
        onClick: () => dispatch(showDownloadModelModal(deal, modelScenarioId || scenarioId)),
      },
    ],
  ];

  return (
    <Menu
      itemGroups={menuItems}
      label="Download"
    />
  );
}
