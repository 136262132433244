import { isEmpty, uniqBy } from 'lodash';
import { PhotoLibrary } from 'components/icons';
import { hideImgOnError } from 'components/utils';

const PHOTOS_THRESHOLD = 5;

export default function RecordPhotos({ photos, setShowPhotoModal, setPrimaryPhotoIndex }) {
  if (isEmpty(photos)) {
    return null;
  }

  const handlePhotoSelection = (index) => {
    setPrimaryPhotoIndex(index);
    setShowPhotoModal(true);
  };
  const uniquePhotos = uniqBy(photos, 'url');

  return (
    <div className="w-full h-48 grid grid-cols-6 grid-rows-1 gap-6 py-6">
      {
        uniquePhotos.slice(0, PHOTOS_THRESHOLD).map((photo, index) => (
          <div className="col-span-1 relative w-auto cursor-pointer" onClick={() => handlePhotoSelection(index)} key={photo.url}>
            <img src={photo.url} className="w-full object-cover object-center h-full" alt={photo.url} onError={hideImgOnError} referrerPolicy="no-referrer" />
          </div>
        ))
      }
      {(uniquePhotos.length > PHOTOS_THRESHOLD) && (
        <div
          className="flex flex-row justify-center items-center gap-x-2 border border-gray-200 bg-white cursor-pointer hover:border-blue-400 hover:text-blue-400"
          onClick={() => handlePhotoSelection(PHOTOS_THRESHOLD)}
        >
          <span className="text-2xl">
            +
            {uniquePhotos.length - PHOTOS_THRESHOLD}
          </span>
          <PhotoLibrary className="w-6 h-auto" />
        </div>
      )}
    </div>
  );
}
