/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

// Base selectors
const selectLeaseData = state => state.lease.data;
const selectActiveFilters = state => state.dashboardFilter.activeFilters;

const isBedFiltered = (lease, bedFilter) => {
  const { bed } = lease.attributes.property;

  if (bedFilter.length === 1) {
    return true;
  }

  const useExactMatch = bedFilter[0];
  return useExactMatch ? bedFilter.includes(bed) : bed >= bedFilter[1];
};

const isBathFiltered = (lease, bathFilter) => {
  const { bath } = lease.attributes.property;

  if (bathFilter.length === 1) {
    return true;
  }

  const useExactMatch = bathFilter[0];
  return useExactMatch ? bathFilter.includes(bath) : bath >= bathFilter[1];
};

// Memoized selector for filtered lease
export const filteredLeases = createSelector(
  [selectLeaseData, selectActiveFilters],
  (data, { market, entity, building, bed: bedFilter, bath: bathFilter, yearBuiltMin, yearBuiltMax, sqftMin, sqftMax, domMin, domMax, asOfDate }) => data.filter(lease => {
    const isBuildingInFiltered = building ? lease.attributes?.property?.building === building : true;
    const isMarketFiltered = market ? lease.attributes.property.market === market : true;
    const isEntityFiltered = entity ? lease.attributes.property.entity === entity : true;
    const isYearBuiltWithinRange = (!yearBuiltMin || lease.attributes.property.yearBuilt >= yearBuiltMin)
                && (!yearBuiltMax || lease.attributes.property.yearBuilt <= yearBuiltMax);
    const isSquareFootWithinRange = (!sqftMin || lease.attributes.property.sqft >= sqftMin)
                && (!sqftMax || lease.attributes.property.sqft <= sqftMax);
    const isDomWithinRange = (!domMin || lease.attributes.dom >= domMin)
                && (!domMax || lease.attributes.dom <= domMax);

    return isBedFiltered(lease, bedFilter) && isBathFiltered(lease, bathFilter) && isBuildingInFiltered && isMarketFiltered && isEntityFiltered && isYearBuiltWithinRange && isSquareFootWithinRange && isDomWithinRange;
  }),
);
