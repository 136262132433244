import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { filteredLeases } from 'selectors/lease';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import LeaseTable from './LeaseTable';

const TWO_WEEKS_DAYS = 14;

const today = dayjs();
const twoWeeksFromNow = dayjs().add(TWO_WEEKS_DAYS, 'day');

function ReadyToList() {
  const leaseData = useSelector(filteredLeases);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const newData = leaseData.filter(lease => {
      const marketingDate = dayjs(lease.attributes.readyForMarketingDate);
      const isReadyToListInTwoWeeks = (marketingDate.isAfter(today) || marketingDate.isSame(today)) && marketingDate.isBefore(twoWeeksFromNow);
      return isReadyToListInTwoWeeks;
    });

    setFilteredData(newData);
  }, [leaseData]);

  const metrics = [
    { label: '# of Units', value: filteredData.length },
  ];

  return (
    <div className="flex flex-col mt-4 h-full">
      <ReturnMetricBoxes metrics={metrics} />
      <div className="flex-1 mt-4 pb-20 overflow-y-auto mb-5">
        <LeaseTable filteredData={filteredData} tableType="Ready to List" />
      </div>
    </div>
  );
}

export default ReadyToList;
