import { useState, useMemo } from 'react';
import {
  ActivityFilled,
  ActivityOutlined,
  DocumentsFilled,
  DocumentsOutlined,
  FinancialsFilled,
  FinancialsOutlined,
  InformationIcon,
  NotesFilled,
  NotesOutlined,
  OfferIcon,
  Pencil,
} from 'components/icons';
import { LAYOUT, TRANSACTION_TYPES } from 'components/constants';
import NavigationRail, { ButtonNavItem, NavItemIcon, NavItemLabel } from 'components/shared/NavigationRail';
import { useOutletContext } from 'react-router-dom';
import OffersModal from 'components/deal/offer/OffersModal';
import FinancialOutputModal from './FinancialOutputModal';
import DocumentsModal from './DocumentsModal';
import ActivityDrawer from './ActivityDrawer';
import DealNotesDrawer from './DealNotesDrawer';
import DealOverviewDrawer from './DealOverviewDrawer';
import UnderwritingDrawer from './UnderwritingDrawer';

function NavItem({ label, activeIcon, inactiveIcon, isActive, onClick }) {
  return (
    <ButtonNavItem active={isActive} onClick={onClick}>
      <NavItemIcon>{isActive ? activeIcon : inactiveIcon}</NavItemIcon>
      <NavItemLabel>{label}</NavItemLabel>
    </ButtonNavItem>
  );
}

function RightNav() {
  const context = useOutletContext();
  const { data } = context;
  const { deal } = data;
  const dispositionTransaction = deal?.transactionType === TRANSACTION_TYPES.disposition;
  const [show, setShow] = useState(null);

  const handleNavClick = (name) => {
    setShow(show === name ? null : name);
  };

  const componentMap = useMemo(
    () => ({
      underwriting: <UnderwritingDrawer context={context} dismiss={() => setShow(null)} showOutput />,
      financials: <FinancialOutputModal setShowOutput={() => setShow(null)} showOutput />,
      notes: <DealNotesDrawer context={context} setShowNotes={() => setShow(null)} />,
      documents: <DocumentsModal context={context} setShowDocuments={() => setShow(null)} showDocuments />,
      activity: <ActivityDrawer context={context} setShowActivities={() => setShow(null)} showActivities />,
      overview: <DealOverviewDrawer context={context} showDealOverview={() => setShow(null)} />,
      ...(dispositionTransaction && { offers: <OffersModal context={context} setShowOffers={() => setShow(null)} showOffers /> }),
    }),
    [dispositionTransaction, context],
  );

  const navItems = [
    { label: 'Underwriting', name: 'underwriting', activeIcon: <Pencil className="size-8" />, inactiveIcon: <Pencil className="size-8" /> },
    { label: 'Financials', name: 'financials', activeIcon: <FinancialsFilled />, inactiveIcon: <FinancialsOutlined /> },
    { label: 'Transaction Info', name: 'overview', activeIcon: <InformationIcon />, inactiveIcon: <InformationIcon /> },
    { label: 'Notes', name: 'notes', activeIcon: <NotesFilled />, inactiveIcon: <NotesOutlined /> },
    { label: 'Activity', name: 'activity', activeIcon: <ActivityFilled />, inactiveIcon: <ActivityOutlined /> },
    { label: 'Documents', name: 'documents', activeIcon: <DocumentsFilled />, inactiveIcon: <DocumentsOutlined /> },
    dispositionTransaction && { label: 'Offers', name: 'offers', activeIcon: <OfferIcon />, inactiveIcon: <OfferIcon /> },
  ].filter(Boolean);

  return (
    <>
      {componentMap[show]}
      <NavigationRail className="z-20 pt-6 border-l border-gray-200 box-border" style={{ height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)` }}>
        {navItems.map(({ label, name, activeIcon, inactiveIcon }) => (
          <NavItem
            key={name}
            label={label}
            name={name}
            activeIcon={activeIcon}
            inactiveIcon={inactiveIcon}
            isActive={show === name}
            onClick={() => handleNavClick(name)}
          />
        ))}
      </NavigationRail>
    </>
  );
}

export default RightNav;
