import { parseISO } from 'date-fns';
import { cloneDeep, isString, sortBy } from 'lodash';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LoadingIndicator } from 'components/icons';
import { groupById } from 'components/utils';
import {
  findCurrentMilestone,
  generateMilestoneSortOrder,
  isTaskBlocked,
  milestoneMetadata,
  transformTransactionInfo,
} from '../milestone';
import Timeline from '../Timeline';
import MilestoneTasks from './MilestoneTasks';

export default function Milestones({ context }) {
  let { deal } = context.data;
  const { currentUser } = context.data;
  const usersById = groupById(context.data.users);

  // TODO: update logic below to not require cloneDeep
  deal = cloneDeep(deal);

  if (!deal) {
    return (
      <div className="flex mt-12 w-full justify-center">
        <LoadingIndicator className="w-8 text-blue-400" />
      </div>
    );
  }

  const {
    dealWorkflowTemplate,
    deletedAt,
    img,
    milestones,
    tasks,
    transactionInfo,
  } = deal;

  const milestoneOrder = generateMilestoneSortOrder(dealWorkflowTemplate);
  deal.milestones = sortBy(milestones, ({ name }) => milestoneOrder[name]);

  const dealEmails = deal.dealEmails.data.map(de => de.attributes);
  const deadDeal = !!deletedAt;
  const transactionInfoFields = transformTransactionInfo(transactionInfo);

  tasks.forEach(task => {
    Object.assign(task, {
      dueDate: isString(task.dueDate) ? parseISO(task.dueDate) : task.dueDate,
      completedAt: isString(task.completedAt) ? parseISO(task.completedAt) : task.completedAt,
      attachments: task.attachments && sortBy(task.attachments, ({ filename }) => filename.toLowerCase()),
      isBlocked: isTaskBlocked(tasks, task, transactionInfoFields),
    });
  });
  milestones.forEach(milestone => Object.assign(milestone, milestoneMetadata(milestone, tasks, deadDeal)));
  dealEmails.forEach(email => {
    Object.assign(email, {
      received: isString(email.received) ? parseISO(email.received) : email.received,
      attachments: email.attachments && sortBy(email.attachments, ({ filename }) => filename.toLowerCase()),
    });
  });

  return (
    <div className="grid grid-cols-2 grid-rows-1-min h-full">
      <Timeline
        img={img}
        milestones={milestones}
        className="col-span-full"
        deal={deal}
      />
      <Routes>
        <Route
          path="/:milestoneId"
          element={(
            <MilestoneTasks
              currentUser={currentUser}
              deal={deal}
              milestones={milestones}
              tasks={tasks}
              usersById={usersById}
            />
          )}
        />

        <Route
          path="*"
          element={<Navigate replace to={`${findCurrentMilestone(milestones)?.id}`} />}
        />
      </Routes>
    </div>
  );
}
