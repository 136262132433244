import { includes, pull, sortBy, uniq } from 'lodash';
import { useFetchSelfQuery } from 'redux/apiSlice';
import Input from 'components/Input';
import { CHANNEL_LABELS } from 'components/constants';
import CheckboxList from 'components/shared/CheckboxList';

export default function GeneralParameters({ portfolio, onChange, canEditPortfolio }) {
  const { channels, client, markets, name } = portfolio;
  const { currentData: { organization: { channels: availableChannels = [], markets: availableMarkets = [] } = {} } = {} } = useFetchSelfQuery();
  const availableMarketsSorted = sortBy(availableMarkets);

  const handleChannelSelection = (selection) => {
    let updatedChannels;
    if (includes(channels, selection)) {
      updatedChannels = pull([...channels], selection);
    } else {
      updatedChannels = uniq([...channels, selection]);
    }
    onChange({ target: { name: 'channels', value: updatedChannels } });
  };

  const handleMarketSelection = (selection) => {
    let updatedMarkets;
    if (includes(markets, selection)) {
      updatedMarkets = pull([...markets], selection);
    } else {
      updatedMarkets = uniq([...markets, selection]);
    }
    onChange({ target: { name: 'markets', value: updatedMarkets } });
  };

  const allChannels = availableChannels.map(channel => ({ label: CHANNEL_LABELS[channel], value: channel }));

  return (
    <div className="grid grid-cols-2 gap-x-8">
      <div>
        <div className="p-6 mt-4 bg-white border rounded-xl">
          <label htmlFor="name" className="font-medium text-sm">Portfolio Name</label>
          <Input
            name="name"
            onChange={onChange}
            type="text"
            value={name}
            className="h-12 mt-2 mb-6 border"
            padding="py-1 px-2"
            width="w-full"
            disabled={!canEditPortfolio}
          />
          <label htmlFor="client" className="font-medium text-sm">Client</label>
          <Input
            name="client"
            onChange={onChange}
            type="text"
            value={client}
            className="h-12 mt-2 border"
            padding="py-1 px-2"
            width="w-full"
            disabled={!canEditPortfolio}
          />
        </div>
        <div className="mt-6 text-lg">Channels</div>
        <p className="mt-2 text-sm">Select the sourcing channels associated with this portfolio.</p>
        <CheckboxList
          className="flex flex-col p-6 mt-8 rounded-2xl bg-white border"
          allItems={allChannels}
          selectedItems={channels}
          handleItemSelect={handleChannelSelection}
        />
      </div>
      <div>
        <div className="text-lg">Markets</div>
        <p className="mt-2 text-sm">Select the markets associated with this portfolio. Only the selected markets will be available on Deal Sourcing.</p>
        <CheckboxList
          className="flex flex-col p-6 mt-8 rounded-2xl bg-white border"
          allItems={availableMarketsSorted.map(market => ({ label: market, value: market }))}
          selectedItems={markets}
          handleItemSelect={handleMarketSelection}
        />
      </div>
    </div>
  );
}
