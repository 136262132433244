import classNames from 'classnames';
import { determinePipelineStatus } from 'components/utils';

export default function StatusButton({ count, title, setDealStage, buttonActive, transactionType }) {
  return (
    <div
      className={classNames(
        'h-full w-full py-3 text-center cursor-pointer grid place-items-center',
        {
          'border-b border-gray-200': !buttonActive,
          'border-b-4 border-primary-dark': buttonActive,
        },
      )}
      onClick={() => { setDealStage(title); }}
    >
      {`${determinePipelineStatus(title, transactionType)} · ${count || 0}`}
    </div>
  );
}
