import { includes } from 'lodash';
import cx from 'classnames';
import { CheckboxEmpty, CheckboxFilled } from 'components/icons';

function OptionRow({ item, selectedItems, onClick }) {
  return (
    <div
      className="flex items-center justify-between cursor-pointer text-sm select-none relative py-2 px-0 font-light text-gray-900"
      onClick={() => onClick(item.value)}
    >
      <span className="pr-24">{item.label}</span>
      { includes(selectedItems, item.value) ? <CheckboxFilled className="w-8" /> : <CheckboxEmpty className="w-8" /> }
    </div>
  );
}

export default function CheckboxList({ className = '', allItems, selectedItems, handleItemSelect }) {
  return (
    <div className={cx(className, 'divide-y')}>
      {allItems.map(item => (
        <OptionRow
          key={item.value}
          item={item}
          selectedItems={selectedItems}
          onClick={handleItemSelect}
        />
      ))}
    </div>
  )
}
