import { useCallback, useMemo, useState, useTransition } from 'react';
import Dropdown from 'components/Dropdown';
import { useActivePresetId, useConfigPresets } from '../DataTableContext';

const usePresetOptions = () => {
  const presets = useConfigPresets();
  return useMemo(() => (
    Object.values(presets)
      .map(({ id, name }) => ({ id, name }))
  ), [presets]);
};

/**
 * @param {import('react').ComponentPropsWithoutRef<typeof Dropdown>} props
 */
export default function PresetDropdown({ ...props }) {
  const [activePresetId, setActivePresetId] = useActivePresetId();
  const [inputPresetId, setInputPresetId] = useState();

  const [isPending, startTransition] = useTransition();
  const displayPresetId = isPending ? inputPresetId : activePresetId;

  const presetOptions = usePresetOptions();
  const selectedItem = presetOptions.find(({ id }) => id === displayPresetId);
  const setSelectedItem = useCallback(({ id }) => {
    setInputPresetId(id);
    startTransition(() => { setActivePresetId(id); });
  }, [setActivePresetId]);

  return (
    <Dropdown
      {...props}
      options={presetOptions}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
    />
  );
}
