import cx from 'classnames';
import {
  ACTIVE_STATUS,
  CHANNEL_LABELS,
  CHANNEL_LISTED,
  CHANNEL_NEW_BUILD,
  CHANNEL_OFF_MARKET,
  CHANNEL_OFF_MARKET_MARKETPLACE,
} from 'components/constants';
import Dropdown from 'components/Dropdown';
import { Check, CheckboxEmpty, CheckboxFilled, Config } from 'components/icons';
import Chip from 'components/shared/Chip';
import { formatCurrencyAbbreviated, formatInteger } from 'components/utils';
import { difference, filter, isArray, isEmpty, isNil, keys, omit, sortBy } from 'lodash';
import { forwardRef, useEffect, useMemo } from 'react';
import { useUpdatePortfolioPreferenceMutation } from '../../../redux/apiSlice';
import { DEFAULT_FILTERS, isGeometryFilterUsed } from '../dealSourcing';
import AvailabilityFilter from '../Filters/AvailabilityFilter';
import BedBathFilter from '../Filters/BedBathFilter';
import CheckboxFilter from '../Filters/CheckboxFilter';
import RangeFilter from '../Filters/RangeFilter';
import ZipCodeFilter from '../Filters/ZipCodeFilter';

const OFF_MARKET_FILTER_KEYS = ['area', 'excludesHomesWithPool', 'numberOfUnits', 'rentableBuildingArea', 'yearBuilt', 'zipCodes'];
const MULTI_PORTFOLIO_OPTION = { name: 'Multi-Portfolio Sourcing', id: 'multi-portfolio' };
MULTI_PORTFOLIO_OPTION.displayName = { [true]: 'Single-Portfolio Sourcing', [false]: MULTI_PORTFOLIO_OPTION.name };

const CHANNEL_OPTIONS = Object.entries(CHANNEL_LABELS).map(([id, name]) => ({ id, name }));

const filterCountIgnoredFields = ['bounds', 'area', 'circle', 'polygon'];

const clearGeoFilters = prevFilters => {
  if (!isGeometryFilterUsed(prevFilters) && isEmpty(prevFilters.zipCodes) && isEmpty(prevFilters.propertySubType)) {
    return prevFilters;
  }

  return {
    ...omit(prevFilters, 'area', 'circle', 'polygon'),
    zipCodes: [],
    propertySubType: [],
  };
};

function AllFiltersChip({ filters, setShowAdvancedFilters, settings }) {
  const filtersKeys = settings.channel === CHANNEL_OFF_MARKET ? OFF_MARKET_FILTER_KEYS : keys(filters);

  // TODO: this should be able to just leverage the default filters logic and not require specifying logic for individual filters
  const activeAdvancedFilters = filter(filtersKeys, key => {
    if (filterCountIgnoredFields.includes(key)) return false;

    if (key === 'pipeline') {
      if (Object.hasOwn(DEFAULT_FILTERS[settings.channel], key)) {
        return difference(filters[key], DEFAULT_FILTERS[settings.channel][key]).length !== 0;
      }
    }
    if (key === 'standardStatus') {
      return !(filters.standardStatus.length === 1 && filters.standardStatus.includes(ACTIVE_STATUS));
    }
    if (key === 'propertyType') {
      return filters.propertyType;
    }
    if (key === 'excludesHomesWithPool') {
      return filters.excludesHomesWithPool;
    }
    if (isArray(filters[key])) {
      return !isNil(filters[key][0] || filters[key][1]);
    }
    if (key === 'bathroomsTotalInteger' || key === 'bedroomsTotal') {
      return !isEmpty(filters[key]) && filters[key]?.selections?.length;
    }
    // type represents the channel that the current filters apply to (mainly kept for debugging purposes)
    if (key === 'type') {
      return false;
    }
    return !isNil(filters[key]);
  });

  return (
    <Chip
      label={`All Filters${activeAdvancedFilters.length ? ` • ${activeAdvancedFilters.length}` : ''}`}
      leadingIcon={<Config />}
      onClick={() => setShowAdvancedFilters(true)}
      preferLeadingIcon
      selected={activeAdvancedFilters.length}
    />
  );
}

const PortfolioOptionMulti = forwardRef((
  {
    optionValue: { id, name, displayName },
    optionSelected,
    className,
    children,
    ...props
  },
  ref,
) => {
  const Icon = optionSelected ? CheckboxFilled : CheckboxEmpty;

  return (
    <li
      ref={ref}
      className={cx('flex items-center justify-between', className)}
      {...props}
    >
      <span>{id === MULTI_PORTFOLIO_OPTION.id ? displayName[optionSelected] : name}</span>
      {id !== MULTI_PORTFOLIO_OPTION.id && <Icon className="w-6 h-6 flex-none" />}
    </li>
  );
});
PortfolioOptionMulti.displayName = 'PortfolioOptionMulti';

const PortfolioOptionSingle = forwardRef((
  {
    optionValue: { name },
    optionSelected,
    className,
    children,
    ...props
  },
  ref,
) => (
  <li
    ref={ref}
    className={cx('flex items-center justify-between', className)}
    {...props}
  >
    <span>{name}</span>
    <Check className={cx('w-5 h-5 flex-none', { invisible: !optionSelected })} />
  </li>
));
PortfolioOptionSingle.displayName = 'PortfolioOptionSingle';

export default function FilterSection(props) {
  const {
    filters,
    portfolio,
    portfolios,
    propertySubTypes,
    homeModelBuilders,
    homeModelSubdivisions,
    setFilters,
    setSettings,
    setShowAdvancedFilters,
    settings,
  } = props;

  const { channel, market } = settings;

  const handleMarketChange = ({ name: newMarket }) => {
    setSettings(prevSettings => {
      const marketDidChange = prevSettings.market !== newMarket;
      if (!marketDidChange) {
        return prevSettings;
      }

      setFilters(clearGeoFilters);

      return { ...prevSettings, market: newMarket };
    });
  };

  const [updateCurrentPortfolio, { isSuccess: didUpdateCurrentPortfolio }] = useUpdatePortfolioPreferenceMutation();
  useEffect(() => {
    if (didUpdateCurrentPortfolio) {
      setFilters(clearGeoFilters);
    }
  }, [didUpdateCurrentPortfolio, setFilters]);

  const handlePortfolioChange = (selectedPortfolio) => {
    setSettings(prevSettings => {
      // make sure channel and market are applicable to new portfolio
      const channelSetting = selectedPortfolio.channels.includes(prevSettings.channel) ? prevSettings.channel : selectedPortfolio.channels[0];
      const marketSetting = selectedPortfolio.markets.includes(prevSettings.market) ? prevSettings.market : selectedPortfolio.markets[0];

      if (selectedPortfolio.id !== prevSettings.portfolioId) {
        // update the session-stored portfolio setting
        updateCurrentPortfolio(selectedPortfolio);
      }

      return {
        ...prevSettings,
        channel: channelSetting,
        market: marketSetting,
        portfolioId: selectedPortfolio.id,
      };
    });
  };

  const handleChannelChange = ({ id: selected }) => {
    setSettings(prevSettings => {
      const channelDidChange = prevSettings.channel !== selected;

      if (!channelDidChange) {
        return prevSettings;
      }
      return { ...prevSettings, channel: selected };
    });
  };

  const sortedPortfolioOptions = useMemo(() => sortBy(portfolios, 'name'), [portfolios]);

  const availableMarkets = portfolio.markets;
  const sortedMarketOptions = useMemo(() => (
    [...availableMarkets].sort().map(m => ({ id: m, name: m }))
  ), [availableMarkets]);

  let filterComponents;
  if (channel !== filters.type) {
    // do this check to address timing issue where channel gets changed, but filters requires
    // a useEffect to get triggered before it gets set to the defaults for the new channel
    filterComponents = null;
  } else if (channel === CHANNEL_LISTED || channel === CHANNEL_OFF_MARKET_MARKETPLACE) {
    filterComponents = (
      <>
        <RangeFilter filterKey="listPrice" filters={filters} label="Price" labelFormatter={formatCurrencyAbbreviated} setFilters={setFilters} />
        <BedBathFilter filters={filters} setFilters={setFilters} />
        <RangeFilter filterKey="daysOnMarket" filters={filters} label="Days on Market" labelSuffix=" Days" setFilters={setFilters} />
        <CheckboxFilter filters={filters} setFilters={setFilters} options={propertySubTypes} filterKey="propertySubType" label="Property Type" />
        <ZipCodeFilter filters={filters} setFilters={setFilters} />
        <AllFiltersChip settings={settings} filters={filters} setShowAdvancedFilters={setShowAdvancedFilters} />
      </>
    );
  } else if (channel === CHANNEL_NEW_BUILD) {
    filterComponents = (
      <>
        <RangeFilter filterKey="listPrice" filters={filters} label="Price" labelFormatter={formatCurrencyAbbreviated} setFilters={setFilters} />
        <BedBathFilter filters={filters} setFilters={setFilters} />
        <RangeFilter filterKey="livingArea" filters={filters} label="Sq Ft" labelFormatter={formatInteger} setFilters={setFilters} />
        <AvailabilityFilter filters={filters} setFilters={setFilters} />
        <CheckboxFilter filters={filters} setFilters={setFilters} options={propertySubTypes} filterKey="propertySubType" label="Property Type" />
        <CheckboxFilter filters={filters} setFilters={setFilters} options={homeModelBuilders} filterKey="homeModelBuilder" label="Builder" />
        <CheckboxFilter filters={filters} setFilters={setFilters} options={homeModelSubdivisions} filterKey="homeModelSubdivision" label="Community" />
        <AllFiltersChip settings={settings} filters={filters} setShowAdvancedFilters={setShowAdvancedFilters} />
      </>
    );
  } else {
    filterComponents = (
      <>
        <RangeFilter filterKey="numberOfUnitsTotal" filters={filters} label="Unit Count" labelSuffix=" Units" setFilters={setFilters} />
        <AllFiltersChip settings={settings} filters={filters} setShowAdvancedFilters={setShowAdvancedFilters} />
      </>
    );
  }

  return (
    <div className="flex flex-col gap-4 w-full px-6 py-4">
      <div className="grid grid-rows-1 auto-cols-fr grid-flow-col gap-x-2 max-w-screen-lg">
        <div>
          <label className="text-xs text-gray-500">Portfolio</label>
          <Dropdown
            options={sortedPortfolioOptions}
            optionsWidth="w-full"
            selectedItem={sortedPortfolioOptions.find(p => p.id === portfolio.id)}
            setSelectedItem={handlePortfolioChange}
            width="w-full"
          />
        </div>
        <div>
          <label className="text-xs text-gray-500">Market</label>
          <Dropdown
            options={sortedMarketOptions}
            optionsWidth="w-full"
            selectedItem={sortedMarketOptions.find(m => m.id === market)}
            setSelectedItem={handleMarketChange}
            width="w-full"
          />
        </div>
        <div>
          <label className="text-xs text-gray-500">Channel</label>
          <Dropdown
            options={CHANNEL_OPTIONS.filter(c => portfolio.channels.includes(c.id))}
            optionsWidth="w-full"
            selectedItem={CHANNEL_OPTIONS.find(opt => opt.id === channel)}
            setSelectedItem={handleChannelChange}
            width="w-full"
          />
        </div>
      </div>
      <div className="flex flex-wrap items-center gap-2">
        {filterComponents}
      </div>
    </div>
  );
}
