/* eslint-disable jsx-a11y/alt-text */
import { Arrow, TrendingFlat } from 'components/icons';
import { formatCurrency, formatDate } from 'components/utils';
import { isNil, upperCase } from 'lodash';

export default function ListingClosedNotification({ notification }) {
  const { dealId, dealStage, portfolioName, priceChangeTimestamp, statusChangeTimestamp, previousListPrice, currentListPrice, source, seenAt, property: { market, photoUrl, address } } = notification.attributes;
  const timeStamp = statusChangeTimestamp || priceChangeTimestamp;

  const [textColor, backgroundColor, direction] = currentListPrice < previousListPrice ? ['text-success', 'bg-success-100', 'down'] : ['text-error', 'bg-error-100', 'up'];
  const percentageChange = (((currentListPrice * 100) / previousListPrice) - 100).toFixed(2);
  return (
    <a href={`/deals/${dealId}`} className={`mb-3 p-4 cursor-pointer border border-gray-300 rounded-lg ${isNil(seenAt) ? 'bg-neutral-surface-light' : 'hover:bg-indigo-50/75'}`}>
      <div className="flex gap-x-4">
        <img className="h-14 w-14 rounded-lg object-cover" src={photoUrl} />
        <div className="flex flex-col">
          <div className="text-neutral-dark font-medium text-xl">MLS Listing Closed</div>
          <div className="flex gap-x-1 h-8 items-center">
            <span className="text-neutral-light text-base">
              {previousListPrice ? formatCurrency(previousListPrice) : 'Off Market '}
            </span>
            <TrendingFlat />
            <span className="text-black text-base">
              {formatCurrency(currentListPrice)}
            </span>
            <span className={`ml-2 px-2 rounded-lg h-full w-auto flex gap-x-1 items-center text-center text-sm font-medium ${backgroundColor} ${textColor}`}>
              <Arrow className="w-3" fill={textColor} direction={direction} />
              {' '}
              {percentageChange}
              {' '}
              %
            </span>
          </div>
          <div className="mt-3 h-6 flex gap-x-2 items-center ">
            <div className="text-neutral-dark font-medium text-base ">{address}</div>
            <div className="px-3 py-px text-neutral-medium font-medium text-xs rounded-lg border border-gray-300">{dealStage}</div>
          </div>
          <div className="mt-1 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-xs ">{`${market} - ${portfolioName}`}</div>
          </div>
          <div className="mt-3 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-vxs ">{`Source: ${upperCase(source)} on ${timeStamp ? formatDate(timeStamp, 'PPPp') : '-'}`}</div>
          </div>
        </div>
      </div>
    </a>
  );
}
