import {
  cloneDeep,
  map,
  partial,
  pullAt,
  sum,
  take,
} from 'lodash';
import { calcIncomeItemCashFlow } from './dcf';
import { EXPENSE_INPUT_METHODS } from './expense';
import Input from '../Input';
import PerUnitContainer from './PerUnitContainer';
import SectionLabel from './SectionLabel';
import Select from '../Select';
import { TH, TR } from '../Table';
import { parseEventValue } from '../utils';
import {
  ITEMIZED_INPUT_METHOD_DPM,
  ITEMIZED_INPUT_METHOD_DPY,
  ITEMIZED_INPUT_METHOD_EGR,
  ITEMIZED_INPUT_METHOD_Y1EGR,
  ITEMIZED_INPUT_METHOD_EQUITY,
} from './itemizedItem';

function IncomeItemRow({ parameters, incomeItem, grossRent, isPortfolioParams, onChange, onRemove, disabled }) {
  const numberOfUnits = parameters.units?.length;
  const inputMethod = EXPENSE_INPUT_METHODS[incomeItem.inputMethod];
  const totalPerMonth = sum(take(calcIncomeItemCashFlow(incomeItem, parameters, grossRent), 12)) / 12;
  const inputOptions = cloneDeep(EXPENSE_INPUT_METHODS);
  // EGR depends on revenue, using EGR for revenue causes circular logic
  delete inputOptions[ITEMIZED_INPUT_METHOD_EGR];
  delete inputOptions[ITEMIZED_INPUT_METHOD_Y1EGR];
  delete inputOptions[ITEMIZED_INPUT_METHOD_EQUITY];
  if (isPortfolioParams) {
    delete inputOptions[ITEMIZED_INPUT_METHOD_DPM];
    delete inputOptions[ITEMIZED_INPUT_METHOD_DPY];
  }

  const label = (
    <>
      <button
        type="button"
        className="bg-tertiary hover:bg-tertiary-lighter mr-2 px-2 text-white rounded"
        onClick={onRemove}
      >
        -
      </button>
      <Input
        name="name"
        value={incomeItem.name}
        type="text"
        onChange={onChange}
        disabled={disabled}
      />
    </>
  );

  const data = [
    <Select
      name="inputMethod"
      value={incomeItem.inputMethod}
      options={map(inputOptions, (inputOption, key) => [key, inputOption.label])}
      onChange={onChange}
      width="w-56"
      disabled={disabled}
    />,
    <Input
      name="inputValue"
      value={incomeItem.inputValue}
      type={inputMethod.inputType}
      min="0"
      onChange={onChange}
      disabled={disabled}
    />,
  ];

  if (!isPortfolioParams) {
    data.push(
      <PerUnitContainer value={totalPerMonth} numberOfUnits={numberOfUnits} />,
      <PerUnitContainer annual value={totalPerMonth} numberOfUnits={numberOfUnits} />,
    );
  }

  const onGrowthRateChange = (event) => {
    const newValue = parseEventValue(event);
    onChange({
      target: {
        name: 'growthRates',
        value: new Array(incomeItem.growthRates).fill(newValue),
      },
    });
  };

  if (inputMethod.specifyGrowthRates) {
    data.push(
      <Input
        name="growthRates"
        value={incomeItem.growthRates[0]}
        type="percent"
        min="0"
        width="w-16"
        onChange={onGrowthRateChange}
        disabled={disabled}
      />,
    );
  } else {
    data.push(...new Array(incomeItem.growthRates.length).fill(null));
  }

  return (
    <TR data={data} label={label} />
  );
}

function AddIncomeItemRow({ onClick }) {
  const data = [
    <button
      type="button"
      className="bg-tertiary hover:bg-tertiary-lighter px-2 text-white rounded"
      onClick={onClick}
    >
      Add Income Item
    </button>,
  ];

  return (
    <TR center tdClassName="py-2" data={data} />
  );
}

function ItemizedOtherIncomeBody({ parameters, grossRent, onChange, disabled }) {
  const {
    incomeItems,
    inflationRate,
    inflationRates,
    units,
  } = parameters;
  const isPortfolioParams = !(units?.length);

  const addIncomeItem = () => {
    const updatedIncomeItems = cloneDeep(incomeItems);
    updatedIncomeItems.push({
      name: '',
      inputValue: 0,
      inputMethod: ITEMIZED_INPUT_METHOD_DPY,
      growthRates: inflationRates || [inflationRate],
    });
    onChange({
      target: {
        name: 'incomeItems',
        value: updatedIncomeItems,
      },
    });
  };

  const removeIncomeItem = (index) => {
    const updatedIncomeItems = cloneDeep(incomeItems);
    pullAt(updatedIncomeItems, [index]);
    onChange({
      target: {
        name: 'incomeItems',
        value: updatedIncomeItems,
      },
    });
  };

  const onIncomeItemChange = (index, event) => {
    const updatedIncomeItems = cloneDeep(incomeItems);
    const field = event.target.name;
    const newValue = parseEventValue(event);
    updatedIncomeItems[index][field] = newValue;
    onChange({
      target: {
        name: 'incomeItems',
        value: updatedIncomeItems,
      },
    });
  };

  return (
    <>
      <SectionLabel className="mt-4" text="Other Income" />
      <table className="min-w-full divide-y divide-gray-200 bg-white">
        <thead className="bg-gray-50 border-b-2">
          <tr>
            <TH value="Description" />
            <TH value="Input Methodology" />
            <TH value="Input Value" />
            {!isPortfolioParams && (
              <>
                <TH value="$ / Month" />
                <TH value="$ / Year" />
              </>
            )}
            <TH value="Growth Rate" />
          </tr>
        </thead>
        <tbody>
          {incomeItems.map((incomeItem, index) => (
            <IncomeItemRow
              key={index}
              incomeItem={incomeItem}
              grossRent={grossRent}
              isPortfolioParams={isPortfolioParams}
              parameters={parameters}
              onChange={partial(onIncomeItemChange, index)}
              onRemove={partial(removeIncomeItem, index)}
              disabled={disabled}
            />
          ))}
          { !disabled && <AddIncomeItemRow onClick={addIncomeItem} /> }
        </tbody>
      </table>
    </>
  );
}

export function PortfolioItemizedOtherIncomeTable({ annualRentGrowthEntry, parameters, onChange, setAnnualRentGrowthEntry, disabled }) {
  return (
    <ItemizedOtherIncomeBody
      annualRentGrowthEntry={annualRentGrowthEntry}
      parameters={parameters}
      grossRent={[]}
      onChange={onChange}
      setAnnualRentGrowthEntry={setAnnualRentGrowthEntry}
      disabled={disabled}
    />
  );
}

export function ModelItemizedOtherIncomeTable({ annualRentGrowthEntry, dcfParams, grossRent, onChange, setAnnualRentGrowthEntry }) {
  return (
    <ItemizedOtherIncomeBody
      annualRentGrowthEntry={annualRentGrowthEntry}
      parameters={dcfParams}
      grossRent={grossRent}
      onChange={onChange}
      setAnnualRentGrowthEntry={setAnnualRentGrowthEntry}
    />
  );
}
