import { useCallback, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { parseISO } from 'date-fns';
import { every, isEmpty, isNil, keys, map, sortBy, take, without } from 'lodash';
import { setShowInfoId, setShowPhotoId } from 'redux/saleCompsSlice';
import { formatCurrency, formatCurrencyAbbreviated, formatDate, formatInteger, formatListingSource, formatPercentage, parseArrayString } from 'components/utils';
import { bedBathFilter, dateRangeFilter, saleMapFilter, sourceNameFilter } from 'components/shared/Table/table.filterFns';
import BathFilter from 'components/shared/filters/BathFilter';
import BedFilter from 'components/shared/filters/BedFilter';
import CompScoreFilter from 'components/shared/filters/CompScoreFilter';
import ListFilter from 'components/shared/filters/ListFilter';
import RangeFilter from 'components/shared/filters/RangeFilter';
import RsfFilter from 'components/shared/filters/RsfFilter';
import SliderFilter from 'components/shared/filters/SliderFilter';
import { CompAddressToggleCell, LocationCompScoreCell, OverallCompScoreCell, PropertyCompScoreCell, RecencyCompScoreCell } from 'components/shared/Table/Cells';
import { useBuildTable } from 'components/shared/Table/DataTable';
import { EMPTY_PRESET, getDefaultPresetId } from 'components/shared/Table/dataTableConfig/utils';
import { dataTableMeta, enableConfigPresets } from 'components/shared/Table/table.helpers';
import StatusCell from 'components/rentComps/StatusCell';
import { CLOSED_STATUS } from 'components/constants';

const DEFAULT_NUMBER_OF_ACTIVE_COMPS = 5;
const MINIMUM_COMP_SCORE = 2.0;

export const DEFAULT_SALE_COMP_FILTERS = {
  bathroomsTotalInteger: [false],
  bedroomsTotal: [false],
  buildingArea: [null, null],
  referenceDate: [null, null],
  closePrice: [null, null],
  distance: [null, 1],
  geo: null,
  listPrice: [null, null],
  numberOfUnitsTotal: [null, null],
  propertyType: [],
  source: [],
  standardStatus: [],
  subdivision: [],
  yearBuilt: [null, null],
  zipCode: '',
  score: 0.5,
  propertyScore: 0.5,
  locationScore: 0.5,
  recencyScore: 0.5,
};

export const defaultActiveSaleComp = (comps) => {
  const closedComps = comps.filter(comp => comp.standardStatus === CLOSED_STATUS);
  const minScoreComps = closedComps.filter(({ compScores }) =>
    every([compScores.overall, compScores.location[0], compScores.property[0], compScores.recency[0]], score => score >= MINIMUM_COMP_SCORE));
  const sortedRecentComps = sortBy(minScoreComps, comp => comp.compScores.overall * -1);
  return take(sortedRecentComps, DEFAULT_NUMBER_OF_ACTIVE_COMPS).map(comp => comp.id);
};

export const multiFamilyFilterComponents = ({ propertyTypes, subdivisions, subjectRsf, statuses }) => [
  [CompScoreFilter, 'score', { name: 'score', label: 'Score' }],
  [SliderFilter, 'distance', { label: 'Distance', labelSuffix: 'mi', min: 0.1, max: 2, step: 0.1 }],
  [BedFilter, 'bedroomsTotal'],
  [BathFilter, 'bathroomsTotalInteger'],
  [RangeFilter, 'closePrice', { type: 'number', label: 'Sale Price', labelSuffix: '', labelFormatter: formatCurrencyAbbreviated }],
  [RsfFilter, 'buildingArea', { subjectRsf }],
  [RangeFilter, 'referenceDate', { type: 'date', labelSuffix: '', label: 'Date', labelFormatter: (date) => formatDate(date, 'MM/dd/yyyy') }],
  [ListFilter, 'standardStatus', { label: 'Status', values: statuses }],
  [ListFilter, 'subdivision', { label: 'Subdivision', values: subdivisions }],
  [ListFilter, 'propertyType', { label: 'Property Type', values: propertyTypes }],
];

export const singleFamilyFilterComponents = ({ propertyTypes, subdivisions, subjectRsf, statuses }) => [
  [CompScoreFilter, 'score', { name: 'score', label: 'Score' }],
  [BedFilter, 'bedroomsTotal'],
  [BathFilter, 'bathroomsTotalInteger'],
  [SliderFilter, 'distance', { label: 'Distance', labelSuffix: 'mi', min: 0.1, max: 2, step: 0.1 }],
  [RangeFilter, 'closePrice', { type: 'number', label: 'Sale Price', labelSuffix: '', labelFormatter: formatCurrencyAbbreviated }],
  [RsfFilter, 'buildingArea', { subjectRsf }],
  [RangeFilter, 'referenceDate', { type: 'date', labelSuffix: '', label: 'Date', labelFormatter: (date) => formatDate(date, 'MM/dd/yyyy') }],
  [ListFilter, 'standardStatus', { label: 'Status', values: statuses }],
  [ListFilter, 'subdivision', { label: 'Subdivision', values: subdivisions }],
  [ListFilter, 'propertyType', { label: 'Property Type', values: propertyTypes }],
  [RangeFilter, 'yearBuilt', { type: 'number', labelSuffix: '', label: 'Year Built' }],
];

export const useFilterComponents = ({ isSingleFamily, propertyTypes, subdivisions, subjectRsf, statuses }) => useMemo(() => (isSingleFamily
  ? singleFamilyFilterComponents({ propertyTypes, subdivisions, subjectRsf, statuses })
  : multiFamilyFilterComponents({ propertyTypes, subdivisions, subjectRsf, statuses })
), [isSingleFamily, propertyTypes, subdivisions, subjectRsf, statuses]);

export const useInitialFilters = (property, increaseRange) => useMemo(() => {
  const defaultFilters = { ...DEFAULT_SALE_COMP_FILTERS };
  defaultFilters.standardStatus = [CLOSED_STATUS];
  if (increaseRange) {
    defaultFilters.distance = 2;
  }
  if (property?.propertySubType) {
    // TODO: may need to be more general if subject is multi-family
    defaultFilters.propertyType = [property.propertySubType];
  }

  return map(keys(defaultFilters), (key => ({
    id: key,
    value: defaultFilters[key],
  })));
}, [property, increaseRange]);

const inFilterListFn = (row, columnId, filterValue) => {
  if (isEmpty(filterValue)) return true;
  return filterValue.includes(row.getValue(columnId));
};

const compScoreFn = (row, columnId, filterValue) => {
  if (filterValue === 0) return true;
  return row.getValue(columnId) >= filterValue;
};

const zipCodeFilter = (rows, _, zipCode) => {
  const zipCodes = zipCode.split(',').map(code => code.trim());
  let filtered = rows;

  if (!isEmpty(zipCode)) {
    filtered = filtered.filter(({ values: { zipCode: value } }) => !isNil(value) && zipCodes.includes(value));
  }
  return filtered;
};

const columnHelper = createColumnHelper();

const saleCompTableColumns = (selectedSaleCompIds, toggleActive, dispatch) => ([
  {
    id: 'isSubject',
    accessorFn: ({ isSubject }) => !!isSubject,
    meta: { tableConfig: false },
  },
  columnHelper.accessor('geo', {
    filterFn: saleMapFilter,
    meta: { tableConfig: false },
  }),
  {
    header: 'Address',
    cell: (props) => (
      <CompAddressToggleCell
        {...props}
        active={selectedSaleCompIds?.includes(props.row.original.id)}
        toggleActive={() => toggleActive(props.row.original.id)}
        onPhotoClick={() => dispatch(setShowPhotoId(props.row.original.id))}
        onInfoClick={() => dispatch(setShowInfoId(props.row.original.id))}
      />
    ),
    accessorKey: 'streetAddress',
    meta: { thClassName: 'pl-18' }
  },
  {
    id: 'city',
    header: 'City',
    accessorKey: 'city',
  },
  {
    id: 'state',
    header: 'State',
    accessorKey: 'stateOrProvince',
    cell: ({ getValue }) => getValue()?.toUpperCase(),
  },
  {
    id: 'zipCode',
    header: 'Zip Code',
    accessorKey: 'postalCode',
    filterFn: zipCodeFilter,
  },
  {
    id: 'score',
    header: 'Score',
    accessorFn: ({ compScores }) => compScores?.overall,
    cell: OverallCompScoreCell,
    sortingFn: 'basic',
    meta: { ...dataTableMeta.textCenter },
    filterFn: compScoreFn,
  },
  {
    id: 'propertyScore',
    header: 'Property Score',
    accessorFn: ({ compScores }) => compScores?.property?.[0],
    cell: PropertyCompScoreCell,
    sortingFn: 'basic',
    meta: { ...dataTableMeta.textCenter },
    filterFn: compScoreFn,
  },
  {
    id: 'locationScore',
    header: 'Location Score',
    accessorFn: ({ compScores }) => compScores?.location?.[0],
    cell: LocationCompScoreCell,
    sortingFn: 'basic',
    meta: { ...dataTableMeta.textCenter },
    filterFn: compScoreFn,
  },
  {
    id: 'recencyScore',
    header: 'Recency Score',
    accessorFn: ({ compScores }) => compScores?.recency?.[0],
    cell: RecencyCompScoreCell,
    sortingFn: 'basic',
    meta: { ...dataTableMeta.textCenter },
    filterFn: compScoreFn,
  },
  {
    header: 'Distance',
    accessorKey: 'distance',
    id: 'distance',
    cell: ({ getValue }) => getValue()?.toFixed(2),
    filterFn: 'inNumberRange',
    sortingFn: 'basic',
    meta: { ...dataTableMeta.textRight },
  },
  {
    header: 'Bed',
    accessorKey: 'bedroomsTotal',
    id: 'bedroomsTotal',
    sortingFn: 'basic',
    filterFn: bedBathFilter,
    meta: { ...dataTableMeta.textRight },
  },
  {
    header: 'Bath',
    accessorKey: 'bathrooms',
    id: 'bathroomsTotalInteger',
    sortingFn: 'basic',
    filterFn: bedBathFilter,
    meta: { ...dataTableMeta.textRight },
  },
  {
    header: 'Total Units',
    accessorKey: 'numberOfUnitsTotal',
    id: 'numberOfUnitsTotal',
    cell: ({ getValue }) => formatInteger(getValue()),
    sortingFn: 'basic',
    filterFn: 'inNumberRange',
    meta: { ...dataTableMeta.textRight },
  },
  {
    header: 'RSF',
    accessorKey: 'buildingArea',
    id: 'buildingArea',
    cell: ({ getValue }) => formatInteger(getValue()),
    filterFn: 'inNumberRange',
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'standardStatus',
    header: 'Status',
    accessorKey: 'standardStatus',
    cell: StatusCell,
    filterFn: inFilterListFn,
  },
  {
    id: 'listPrice',
    header: 'List Price',
    accessorKey: 'listPrice',
    cell: ({ getValue }) => formatCurrency(getValue()),
    filterFn: 'inNumberRange',
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'closePrice',
    header: 'Close Price',
    accessorKey: 'closePrice',
    cell: ({ getValue }) => formatCurrency(getValue()),
    filterFn: 'inNumberRange',
    meta: { ...dataTableMeta.textRight },
  },
  {
    header: '% of Listing',
    cell: ({ row: { original: { closePrice, listPrice } } }) => ((closePrice && listPrice) ? formatPercentage(closePrice / listPrice) : null),
    id: 'percentOfListing',
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'pricePerUnit',
    header: 'Sale Price / Unit',
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    header: 'Date',
    id: 'referenceDate',
    accessorFn: ({ referenceDate }) => (referenceDate ? parseISO(referenceDate) : referenceDate),
    cell: ({ getValue }) => formatDate(getValue(), 'MMM d, yyyy'),
    filterFn: dateRangeFilter,
  },
  {
    header: 'DOM',
    cell: ({ getValue }) => formatInteger(getValue()),
    accessorKey: 'daysOnMarket',
    filterFn: 'inNumberRange',
    sortDescFirst: false,
    meta: { ...dataTableMeta.textRight },
  },
  {
    header: 'CDOM',
    cell: ({ getValue }) => formatInteger(getValue()),
    accessorKey: 'cumulativeDaysOnMarket',
    filterFn: 'inNumberRange',
    sortDescFirst: false,
    meta: { ...dataTableMeta.textRight },
  },
  {
    header: 'Year Built',
    accessorKey: 'yearBuilt',
    id: 'yearBuilt',
    filterFn: 'inNumberRange',
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'propertyType',
    header: 'Property Type',
    accessorKey: 'propertySubType',
    filterFn: inFilterListFn,
  },
  {
    id: 'lotSize',
    header: 'Lot Size',
    accessorKey: 'lotSize',
    cell: ({ getValue }) => formatInteger(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'subdivision',
    header: 'Subdivision',
    accessorKey: 'subdivision',
    filterFn: inFilterListFn,
  },
  {
    id: 'phase',
    header: 'Phase',
    accessorKey: 'phase',
  },
  {
    id: 'garageSpaces',
    header: 'Garage Spaces',
    accessorKey: 'garageSpaces',
    cell: ({ getValue }) => formatInteger(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'pool',
    header: 'Pool',
    accessorKey: 'poolFeatures',
    cell: ({ getValue }) => parseArrayString(getValue()),
  },
  {
    header: 'Source',
    accessorKey: 'source',
    id: 'source',
    cell: ({ getValue }) => formatListingSource(getValue()),
    filterFn: sourceNameFilter,
  },
]);

export const useSaleCompTable = (props) => {
  const {
    columnFilters,
    setColumnFilters,
    comps,
    dispatch,
    listing,
    property,
    selectedSaleCompIds,
    setSelectedSaleCompIds,
    onRowMouseEnter,
    onRowMouseLeave,
    rowPinning,
    tableId,
  } = props;

  const initialState = { sorting: [{ id: 'score', desc: true }] };

  const compsWithSubject = useMemo(() => {
    // transform subject property to look like comp data
    const subjectProperty = {
      ...property,
      id: 'subject',
      streetAddress: property.address,
      bathrooms: property.bathrooms,
      bedroomsTotal: property.bedrooms,
      buildingArea: property.livingArea,
      closeDate: listing?.closeDate,
      closePrice: listing?.closePrice,
      listPrice: listing?.listPrice || listing?.price,
      numberOfUnitsTotal: property.numberOfUnits,
      source: formatListingSource(listing?.source),
      isSubject: true,
      media: property.pictures || property.photos,
    };

    return comps ? [subjectProperty, ...comps] : [];
  }, [comps, listing, property]);

  const toggleActive = useCallback(id => {
    dispatch(setSelectedSaleCompIds(selectedSaleCompIds.includes(id) ? without(selectedSaleCompIds, id) : [...selectedSaleCompIds, id]));
  }, [dispatch, selectedSaleCompIds, setSelectedSaleCompIds]);

  const defaultPresetId = getDefaultPresetId({ tableId });

  return useBuildTable({
    columns: useMemo(() => saleCompTableColumns(selectedSaleCompIds, toggleActive, dispatch), [selectedSaleCompIds, toggleActive, dispatch]),
    data: compsWithSubject,
    initialState,
    columnFilters,
    setColumnFilters,
    columnVisibility: property.isSingleFamily ? {
      isSubject: false,
      geo: false,
      numberOfUnitsTotal: false,
      unitMix: false,
      pricePerUnit: false,
    } : {
      isSubject: false,
      id: false,
      bedroomsTotal: false,
      bathrooms: false,
    },
    rowPinning,
    onRowMouseEnter,
    onRowMouseLeave,
    trClassName: row => (row.getIsPinned() ? 'bg-blue-50' : 'bg-white'),
    meta: { ...enableConfigPresets({ presets: { [defaultPresetId]: { ...EMPTY_PRESET, id: defaultPresetId } } }) },
  });
};
