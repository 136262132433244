import axios from '../utils/axios';
import { camelCaseKeys } from '../components/utils';

const API = '/deals';

export const setBaseParams = (payload) => ({
  type: 'setBaseParams',
  payload,
});

export const setPendingParams = (payload) => ({
  type: 'setPendingParams',
  payload,
});

export const resetPendingParams = () => ({ type: 'resetPendingParams' });

export const addModelNote = (note) => ({ type: 'addModelNote', payload: note });

// eslint-disable-next-line import/prefer-default-export
export const getModel = (dealId, scenarioId, propertyId = null) => (dispatch) => {
  const dealPath = propertyId ? `${API}/${dealId}/scenarios/${scenarioId}/properties/${propertyId}.json` : `${API}/${dealId}/scenarios/${scenarioId}.json`;
  axios.get(dealPath)
    .then((response) => {
      dispatch(setBaseParams(camelCaseKeys(response.data.model)));
    })
    .catch((error) => {
      console.error(error);
    });
};

export const newModel = (listingId, propertyId) => (dispatch) => {
  const params = (listingId ? { listing_id: listingId } : { property_id: propertyId });
  axios.get((`${API}/scenarios.json`), { params })
    .then((response) => {
      dispatch(setBaseParams(camelCaseKeys(response.data.model)));
    })
    .catch((error) => {
      console.error(error);
    });
};
