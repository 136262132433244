import { useState } from 'react';
import { partial } from 'lodash';
import classNames from 'classnames';
import Modal from '../Modal';
import { Chevron } from '../icons';

function PhotoCarouselModal({ photos, primaryPhotoIndex, showModal, setShowModal, setPrimaryPhotoIndex }) {
  const onClick = (left) => {
    if (left && primaryPhotoIndex === 0) {
      setPrimaryPhotoIndex(photos.length - 1);
    } else if (!left && primaryPhotoIndex === photos.length - 1) {
      setPrimaryPhotoIndex(0);
    } else {
      setPrimaryPhotoIndex(left ? primaryPhotoIndex - 1 : primaryPhotoIndex + 1);
    }
  };

  return (
    <Modal
      show={showModal}
      backgroundColor="bg-transparent"
      buttonColor="text-white hover:text-gray-300"
      fullScreen
      onClose={() => setShowModal(false)}
    >
      <div className="relative w-full h-full flex items-center justify-center z-5">
        <img
          className="w-5/6 h-5/6 p-8 object-contain select-none"
          referrerPolicy="no-referrer"
          src={photos[primaryPhotoIndex].url}
        />
        <Chevron
          direction="left"
          className="absolute text-white hover:text-gray-300 top-1/2 left-16 w-12 cursor-pointer"
          onClick={partial(onClick, true)}
        />
        <Chevron
          direction="right"
          className="absolute text-white hover:text-gray-300 top-1/2 right-16 w-12 cursor-pointer"
          onClick={partial(onClick, false)}
        />
      </div>
    </Modal>
  );
}

export default function ListingPhotos({ property }) {
  const [showModal, setShowModal] = useState(false);
  const [primaryPhotoIndex, setPrimaryPhotoIndex] = useState(0);

  return (
    <>
      <div>
        <div>
          <img
            className="mx-auto w-full h-64 object-contain cursor-pointer select-none"
            src={property.pictures[primaryPhotoIndex].url}
            referrerPolicy="no-referrer"
            onClick={() => setShowModal(true)}
          />
        </div>
        <div className="pt-1 flex flex-row overflow-auto">
          {property.pictures.map((photo, index) => (
            <img
              key={index}
              className={classNames('thumb-pic h-12 mb-1 cursor-pointer', index > 0 && 'ml-1')}
              onClick={() => setPrimaryPhotoIndex(index)}
              referrerPolicy="no-referrer"
              src={photo.thumb_url || photo.url}
            />
          ))}
        </div>
      </div>
      <PhotoCarouselModal
        primaryPhotoIndex={primaryPhotoIndex}
        photos={property.pictures}
        setPrimaryPhotoIndex={setPrimaryPhotoIndex}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
}
