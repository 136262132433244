import { AssignToSlot, RenameSlot, useCreatePortalSlot } from 'components/shared/LayoutSlots';
import Filter from './Filter';

function Section({ children }) {
  const refCallback = useCreatePortalSlot('sideSheetSection');

  return (
    <section ref={refCallback} className="flex flex-col gap-y-2">
      {children}
    </section>
  );
}

/**
 * @param {import('react').ReactNode} label
 * @param {import('react').ReactNode} children
 */
export default function AllFiltersSection({ label, children }) {
  return (
    <Filter label={label}>
      <RenameSlot from="sideSheetSection" to="sideSheet">
        {children}
      </RenameSlot>
      <AssignToSlot slotName="sideSheet">
        <Section>
          {/* TODO: styling */}
          <h3 className="border-b">
            {label}
          </h3>
        </Section>
      </AssignToSlot>
    </Filter>
  );
}
