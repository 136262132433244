import DataTable from 'components/shared/Table/DataTable';
import { compact, floor, mean, round, sortBy, sum, uniq } from 'lodash';
import { tdClassNameFn, theadClassName } from 'components/NhimbleDashboard/Shared';
import { formatCurrency, formatCurrencyAbbreviated, formatInteger, formatPercentage } from 'components/utils';

const countClosingThisWeek = (deals = []) => deals.filter(deal => deal.attributes.closingThisWeek).length;
const sumPurchasePrice = (deals = []) => sum(deals.map(deal => deal.attributes.purchasePrice));
const averagePurchasePrice = (deals = []) => mean(deals.map(deal => deal.attributes.purchasePrice));
const averageUnderwritingRent = (deals = []) => mean(deals.map(deal => deal.attributes.underwritingRent));
const averageBed = (deals = []) => round(mean(deals.map(deal => deal.attributes.property.bed)), 2);
const averageBath = (deals = []) => round(mean(deals.map(deal => deal.attributes.property.bath)), 2);
const averageSqft = (deals = []) => floor(mean(deals.map(deal => deal.attributes.property.sqft)));
const averageYearBuilt = (deals = []) => floor(mean(compact(deals.map(deal => deal.attributes.property.yearBuilt))));
const averageNetYield = (deals = []) => mean(deals.map(deal => deal.attributes.netYield));

const COLUMNS = [
  {
    header: '',
    accessorKey: 'market',
    meta: { textAlign: 'left' },
  },
  {
    header: '# in Contract',
    accessorKey: 'numberInContract',
  },
  {
    header: '$ in Contract',
    accessorKey: 'priceInContract',
  },
  {
    header: 'Avg. Purchase Price',
    accessorKey: 'averagePurchasePrice',
  },
  {
    header: 'Avg. Bed',
    accessorKey: 'averageBed',
  },
  {
    header: 'Avg. Bath',
    accessorKey: 'averageBath',
  },
  {
    header: 'Avg. Sqft',
    accessorKey: 'averageSqft',
  },
  {
    header: 'Avg. Year Built',
    accessorKey: 'averageYearBuilt',
  },
  {
    header: 'Avg. Rent',
    accessorKey: 'averageRent',
  },
  {
    header: 'Avg. Yield',
    accessorKey: 'averageNetYield',
  },
  {
    header: 'Closing This week',
    accessorKey: 'closingThisWeek',
  },
];

const TABLE_META = { textAlign: 'center' };

export default function OverallMarketSummary({ acquisitions }) {
  const overallMarketData = [
    {
      market: 'Overall',
      numberInContract: acquisitions.length,
      priceInContract: formatCurrencyAbbreviated(sumPurchasePrice(acquisitions)),
      averagePurchasePrice: formatCurrencyAbbreviated(averagePurchasePrice(acquisitions)),
      averageBed: averageBed(acquisitions),
      averageBath: averageBath(acquisitions),
      averageSqft: formatInteger(averageSqft(acquisitions)),
      averageYearBuilt: averageYearBuilt(acquisitions),
      averageRent: formatCurrency(averageUnderwritingRent(acquisitions)),
      averageNetYield: formatPercentage(averageNetYield(acquisitions)),
      closingThisWeek: countClosingThisWeek(acquisitions),
    },
  ];

  const markets = sortBy(uniq(acquisitions.map(acquisition => acquisition.attributes.property.market)));

  markets.forEach(market => {
    const marketData = acquisitions.filter(acquisition => acquisition.attributes.property.market === market);
    overallMarketData.push(
      {
        market: market || '-',
        numberInContract: marketData.length,
        priceInContract: formatCurrencyAbbreviated(sumPurchasePrice(marketData)),
        averagePurchasePrice: formatCurrencyAbbreviated(averagePurchasePrice(marketData)),
        averageBed: averageBed(marketData),
        averageBath: averageBath(marketData),
        averageSqft: formatInteger(averageSqft(marketData)),
        averageYearBuilt: averageYearBuilt(marketData),
        averageRent: formatCurrency(averageUnderwritingRent(marketData)),
        averageNetYield: formatPercentage(averageNetYield(marketData)),
        closingThisWeek: countClosingThisWeek(marketData),
      },
    );
  });

  return (
    <div className="border">
      <DataTable
        columns={COLUMNS}
        data={overallMarketData}
        emptyStateComponent="There are no available properties matching your criteria"
        theadClassName={theadClassName}
        tdClassName={tdClassNameFn}
        meta={TABLE_META}
      />
    </div>
  );
}
