export const PRICE_UPDATE_NOTIFICATION = 'PriceUpdate';
export const STATUS_UPDATE_NOTIFICATION = 'StatusUpdate';
export const TASK_ASSIGNED_NOTIFICATION = 'TaskAssigned';
export const TASK_UNASSIGNED_NOTIFICATION = 'TaskUnassigned';
export const TASK_COMPLETED_NOTIFICATION = 'TaskCompleted';
export const WORKFLOW_UPDATE_NOTIFICATION = 'WorkflowUpdate';
export const MENTIONED_IN_DEAL_NOTIFICATION = 'MentionedInDeal';
export const DUE_DATE_APPROACHING_NOTIFICATION = 'DueDateApproaching';
export const DUE_DATE_PASSED_NOTIFICATION = 'DueDatePassed';
export const LISTING_CLOSED_NOTIFICATION = 'ListingClosed';
export const CLOSING_DATE_APPROACHING_NOTIFICATION = 'ClosingDateApproaching';
export const CLOSING_DATE_PASSED_NOTIFICATION = 'ClosingDatePassed';
export const OFFER_RECEIVED_NOTIFICATION = 'OfferRecieved';

export const DEAL_NOTIFICATIONS = [OFFER_RECEIVED_NOTIFICATION, LISTING_CLOSED_NOTIFICATION, PRICE_UPDATE_NOTIFICATION, STATUS_UPDATE_NOTIFICATION];
export const TASK_NOTIFICATIONS = [DUE_DATE_APPROACHING_NOTIFICATION, DUE_DATE_PASSED_NOTIFICATION, TASK_ASSIGNED_NOTIFICATION, TASK_UNASSIGNED_NOTIFICATION, TASK_COMPLETED_NOTIFICATION, WORKFLOW_UPDATE_NOTIFICATION];
export const NOTIFICATION_TABS = ['All', 'Deal', 'Tasks'];
