import { useMemo, useState, useTransition } from 'react';
import { isEqual, partial, range, sortBy } from 'lodash';
import { addMonths, startOfMonth } from 'date-fns';
import { ModalEditCell } from 'components/shared/Table/Cells';
import { useUpdateRow } from 'components/shared/Table/DataTableContext';
import { formatDate, parseEventValue } from 'components/utils';
import Button from 'components/shared/NewButton';
import Input from 'components/Input';
import Select from 'components/Select';

export const formatDeliverySchedule = value => value.map(([date, quantity]) => `${formatDate(date, 'MMM yyyy')}: ${quantity}`).join('\n') || '—';

function DeliveryScheduleInputRow({ date, quantity, onChangeQuantity, onRemove }) {
  return (
    <div className="flex justify-between items-center gap-x-3">
      <div>{formatDate(date, 'MMM yyyy')}</div>
      <Input value={quantity} width="w-16" min={1} onChange={onChangeQuantity} />
      <Button small outlined danger label="Remove" onClick={onRemove} />
    </div>
  );
}

function AddDeliveryScheduleRow({ onAddDelivery, editedValue }) {
  const currentDate = startOfMonth(new Date());
  const deliveryDates = range(-5, 12).map(i => formatDate(addMonths(currentDate, i)));
  const currentDates = editedValue.map(tuple => tuple[0]);
  const dateOptions = [['', 'Select Delivery Month', true], ...deliveryDates.map(date => [date, formatDate(date, 'MMM yyyy'), currentDates.includes(date)])];
  const [date, setDate] = useState('');
  const [quantity, setQuantity] = useState(null);
  const onAdd = () => {
    onAddDelivery(date, quantity);
    setDate('');
    setQuantity(null);
  };
  const onDateChange = e => setDate(parseEventValue(e));

  return (
    <div className="flex justify-between items-center gap-x-3">
      <Select
        name="newInventoryDeliveryDate"
        value={date}
        options={dateOptions}
        onChange={onDateChange}
        width="w-36"
      />
      <Input value={quantity} width="w-16" min={1} onChange={e => setQuantity(parseEventValue(e))} />
      <Button small outlined danger label="Add" onClick={onAdd} disabled={!quantity || !date} />
    </div>
  );
}

function EditModalBody({ value, setUpdate, onClose }) {
  const [editedValue, setEditedValue] = useState(value);
  const isChanged = useMemo(() => !isEqual(value, editedValue), [value, editedValue]);
  const [, startTransition] = useTransition();
  const onSubmit = () => {
    startTransition(() => setUpdate(editedValue));
    onClose();
  };
  const onRemove = index => setEditedValue(prevValue => prevValue.toSpliced(index, 1));
  const onChangeQuantity = (index, event) => setEditedValue(prevValue => {
    const updatedValue = parseEventValue(event);
    // handle user inputting decimals by rounding down to integer
    const newQuantity = updatedValue ? Math.floor(updatedValue) : updatedValue;
    const newValue = [...prevValue];
    newValue[index] = [newValue[index][0], newQuantity];
    return newValue;
  });
  const onAddDelivery = (date, quantity) => setEditedValue(prevValue => {
    const newValue = [...prevValue];
    newValue.push([date, quantity]);
    return sortBy(newValue, tuple => tuple[0]);
  });

  return (
    <div className="w-64">
      <div className="mb-6 font-medium">Update Delivery Schedule</div>
      <div className="flex flex-col gap-y-2 pb-3 border-b">
        {editedValue.length ? editedValue.map((tuple, index) => (
          <DeliveryScheduleInputRow
            key={tuple[0]}
            index={index}
            date={tuple[0]}
            quantity={tuple[1]}
            onChangeQuantity={partial(onChangeQuantity, index)}
            onRemove={partial(onRemove, index)}
          />
        )) : <div className="text-gray-500">No future deliveries</div>}
      </div>
      <div className="mt-3">
        <AddDeliveryScheduleRow onAddDelivery={onAddDelivery} editedValue={editedValue} />
      </div>
      <div className="mt-6 flex justify-between">
        <Button textOnly label="Cancel" onClick={onClose} />
        <Button filled label="Submit" onClick={onSubmit} disabled={!isChanged} />
      </div>
    </div>
  );
}

export default function EditDeliveryScheduleCell({ getValue, row, column }) {
  const value = getValue();
  const [, setUpdate] = useUpdateRow({ rowId: row.id, columnId: column.id });

  return (
    <ModalEditCell displayValue={formatDeliverySchedule(value)}>
      {(onClose) => <EditModalBody value={value} setUpdate={setUpdate} onClose={onClose} />}
    </ModalEditCell>
  );
}
