import { StrictMode } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import cx from 'classnames';
import { LAYOUT } from 'components/constants';

const getNavTabClass = active => cx(
  'hover:text-gray-700 text-sm font-medium',
  'flex h-14 pl-6 items-center',
  'tracking-wider text-xs font-semibold',
  'rounded-full cursor-pointer',
  {
    'py-4.5 bg-primary-dark bg-opacity-8 text-gray-800': active,
    'text-gray-400 pl-10 text-neutral-dark': !active,
  },
);

function NavTab({ label, path }) {
  return (
    <NavLink
      to={path}
      className={({ isActive }) => getNavTabClass(isActive)}
    >
      {label}
    </NavLink>
  );
}

export default function NewBuildBase() {
  return (
    <div className="flex h-screen bg-gray-100" style={{ width: `calc(100vw - ${LAYOUT.sidebarWidth}px)` }}>
      <div className="w-52 h-full py-16 px-4">
        <NavTab label="Inventory" path="/new_builds/inventory" />
        <NavTab label="Import" path="/new_builds/import" />
        <NavTab label="Offers" path="/new_builds/offers" />
        <NavTab label="Underwriting" path="/new_builds/underwriting" />
      </div>
      <div style={{ width: `calc(100vw - ${LAYOUT.sidebarWidth + (52 * 4)}px)` }}>
        <StrictMode>
          <Outlet />
        </StrictMode>
      </div>
    </div>
  );
}
