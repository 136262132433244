// *** Navigation Paths ***

export const homePath = '/';
export const homePathWithHash = (hash) => `/${hash && `#${hash}`}`;
export const pipelinePath = '/pipeline';
export const sidekiqPath = '/sidekiq';

// *** Deal Sourcing paths ***

export const dealSourcingIndexPath = '/deal_sourcing';
export const dealSourcingPath = (property) => `/deal_sourcing?market=${property.market}`;
export const dealSourcingListingsPath = (market) => `/deal_sourcing/listings?market=${market}`;
export const dealSourcingPropertiesPath = (props) => {
  const { area, circle, polygon } = props;
  let params = '';

  if (area) {
    params = `bounds[]=${area.south}&bounds[]=${area.west}&bounds[]=${area.north}&bounds[]=${area.east}`;
  }
  if (circle) {
    const { center, radius } = circle;
    const { lat, lng } = center;
    params = `circle[]=${lat}&circle[]=${lng}&circle[]=${radius}`;
  }
  if (polygon) {
    const poly = [...polygon];
    poly.push(polygon[0]);
    const polygonPoints = poly.map(point => [point.lng, point.lat].join(' ')).join(',');
    params = `polygon=${polygonPoints}`;
  }

  return `/deal_sourcing/properties?${params}`;
};

// *** Deal paths ***

export const anonymousDealPath = '/deals/scenarios';
export const createDealAttachmentPath = ({ id }) => `/deals/${id}/deal_attachments`;
export const createScenarioPath = '/deals/scenarios';
export const createTaskPath = ({ dealId }) => `/deals/${dealId}/tasks`;
export const createDealPath = '/deals';
export const dealAttachmentPath = (dealAttachment) => `/deals/${dealAttachment.dealId}/deal_attachments/${dealAttachment.id}`;
export const dealPath = (deal) => `/deals/${deal.id || deal.data.id}`;
export const workflowPath = (deal) => `/deals/${deal.id || deal.data.id}/workflow`;
export const generateLoiPath = (deal) => `/deals/${deal.id || deal.data.id}/loi`;
export const generatePurchaseAgreementPath = (dealId, scenarioId) => `/deals/${dealId}/purchase_agreement?scenario_id=${scenarioId}`;
export const newScenarioPath = (deal) => `/deals/${deal.id || deal.data.id}/scenarios`;
export const scenarioPath = (scenario) => `/deals/${scenario.dealId || scenario.deal_id}/scenarios/${scenario.id}`;
export const propertyScenarioPath = (scenario) => `/deals/${scenario.dealId || scenario.deal_id}/scenarios/${scenario.id}/properties/${scenario.propertyId}`;
export const scenarioExportPath = (scenario) => `/deals/${scenario.dealId}/scenarios/${scenario.id}/export`;
export const scenarioPicturesPath = (scenario) => `/deals/${scenario.dealId}/scenarios/${scenario.id}/pictures`;
export const createScenarioNotePath = (scenario) => `/deals/${scenario.dealId}/scenarios/${scenario.id}/scenario_notes`;
export const taskPath = ({ dealId, id }) => `/deals/${dealId}/tasks/${id}`;
export const createTaskAttachmentPath = ({ id, dealId }) => `/deals/${dealId}/tasks/${id}/task_attachments`;
export const taskAttachmentPath = ({ dealId, id: taskId }, { id }) => `/deals/${dealId}/tasks/${taskId}/task_attachments/${id}`;
export const valuationsPath = '/deal_sourcing/valuations';

// *** Listing paths ***

export const createListingsPath = '/listings';
export const listingPath = (listing, property = null) => (property ? `/listings/${listing.id}/properties/${property.id}` : `/listings/${listing.id}`);

// *** Parcel paths ***

export const parcelPath = (parcel) => `/parcels/${parcel.id}`;

// *** Portfolio paths ***

export const createPortfolioPath = '/portfolios';
export const portfoliosIndexPath = '/portfolios';
export const portfoliosIndexApiPath = '/api/portfolios';
export const newPortfolioPath = '/portfolios/new';
export const portfolioPath = ({ id }) => `/portfolios/${id}`;
export const portfolioEditPath = ({ id }) => `/portfolios/${id}/edit`;
export const portfolioPreferencePath = '/portfolios/preference';

// *** Property paths ***

export const propertyPath = (property) => `/properties/${property.id}`;
export const historyPath = (property) => `/properties/${property.id}/history`;
export const propertyUpdatePath = (property) => `/properties/${property.id}`;
export const rentCompsPath = (property) => `/properties/${property.id}/rent_comps`;
export const searchPropertiesPath = '/properties/search';

// *** Settings paths ***

export const userPath = (user) => `/users/${user.id}`;
export const sessionPath = '/sessions';

// *** Organizations paths ***

export const createOrganizationPath = () => '/organizations';
export const organizationsIndexPath = (id) => `/organizations/${id}`;

// *** Census Tract paths ***

export const censusTractsInBounds = ({ swLat, swLng, neLat, neLng }) => (
  `/api/census_tracts/in_bounds?sw_lat=${swLat}&sw_lng=${swLng}&ne_lat=${neLat}&ne_lng=${neLng}`
);
export const censusTractsShape = (gids) => (
  `/api/census_tracts/shapes?${gids.map(gid => `gids[]=${gid}`).join('&')}`
);

// *** Neighborhoods paths ***

export const neighborhoodsInBounds = ({ swLat, swLng, neLat, neLng }) => (
  `/api/neighborhoods/in_bounds?sw_lat=${swLat}&sw_lng=${swLng}&ne_lat=${neLat}&ne_lng=${neLng}`
);
export const neighborhoodsShape = (ids) => (
  `/api/neighborhoods?${ids.map(id => `ids[]=${id}`).join('&')}`
);

// *** Password Reset paths ****
export const usersPasswordPath = '/users/password';

// *** Permissions paths ****
export const permissionsPath = '/users/permissions';

// *** Home Models paths ***
export const homeModelImagesApiPath = '/api/home_models/:homeModelId/images/:id?';

// *** Off-Market Marketplace paths ***
export const offMarketMarketplaceImportJobsApiPath = '/api/off_market_marketplace_import_jobs/:id?/:action?';

// *** React Router paths ***
export const LISTINGS_ROUTE = '/listings/:id';
export const PORTFOLIO_DEAL_PROPERTIES_ROUTE = '/listings/:id/properties';
export const PROPERTIES_ROUTE = '/properties/:id';
export const MODEL_ROUTE = '/deals/scenarios';
export const MODEL_SCENARIO_ROUTE = '/deals/:dealId/scenarios/:scenarioId';
export const MODEL_PROPERTY_SCENARIO_ROUTE = '/deals/:dealId/scenarios/:scenarioId/properties/:propertyId';
export const DEAL_DASHBOARD_ROUTE = '/deals/:dealId/*';
