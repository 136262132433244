import { useCallback, useEffect, useMemo } from 'react';
import { without } from 'lodash';
import GoogleMap from 'components/common/GoogleMap';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSaleCompIds } from 'redux/saleCompsSlice';
import { formatCurrency, formatInteger } from 'components/utils';

function InfoWindow(saleComp) {
  const {
    address,
    id,
    photoUrl,
    bedroomsTotalInteger,
    bedroomsTotal,
    propertyType,
    buildingArea,
    closePrice,
  } = saleComp;
  const dispatch = useDispatch();
  const { selectedSaleCompIds } = useSelector(state => state.saleComps);
  const buttonLabel = selectedSaleCompIds.includes(id) ? 'Remove' : 'Add';

  const handleUpdateSelectedComps = useCallback(() => {
    const isSelecting = !selectedSaleCompIds.includes(id);
    dispatch(setSelectedSaleCompIds(isSelecting ? [id, ...selectedSaleCompIds] : without(selectedSaleCompIds, id)));
  }, [id, selectedSaleCompIds, dispatch]);

  useEffect(() => {
    const el = document.querySelector(`#sale-comp-table tr[data-id='${id}']`);

    if (el) {
      el.querySelector('div').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });

      el.classList.add('bg-primary-50', 'animate-pulse');
      setTimeout(() => {
        el.classList.remove('bg-primary-50');
        el.classList.remove('animate-pulse');
      }, 3000);
    }
  }, [id]);

  return (
    <div onClick={e => e.stopPropagation()} className="z-50 cursor -top-24 relative w-60 h-64 left-10 block max-w-sm bg-white border rounded overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
      <div className="max-w-sm rounded overflow-hidden shadow-lg h-full flex flex-col">
        <div className="relative group h-40">
          <img className="w-full h-full object-cover" src={photoUrl} alt="House" referrerPolicy="no-referrer" />
          <button
            type="button"
            onClick={handleUpdateSelectedComps}
            className="absolute top-0 right-0 m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {buttonLabel}
          </button>
        </div>
        <div className="p-3 h-24">
          <div className="font-bold text-sm mb-2">{formatCurrency(closePrice)}</div>
          <p className="text-gray-700 text-xs">
            {propertyType}
            {' '}
            |
            {' '}
            {bedroomsTotal}
            {' '}
            Beds |
            {' '}
            {bedroomsTotalInteger}
            {' '}
            Baths
            |
            {' '}
            {formatInteger(buildingArea)}
            {' '}
            Sq.Ft
          </p>
          <p className="text-gray-700 text-sm mt-1">{address}</p>
        </div>
      </div>
    </div>
  );
}

export default function Map({ property, filteredComps, setDrawingFiltered }) {
  const { selectedSaleCompIds } = useSelector(state => state.saleComps);
  const { hoveredId } = useSelector(state => state.map);

  const places = useMemo(() => {
    const formattedPlaces = filteredComps.map(comp => ({
      active: selectedSaleCompIds?.includes(comp.id),
      lat: comp.latitude,
      lng: comp.longitude,
      subject: comp.isSubject,
      ...comp,
    }));

    // order places so that selected and subject property markers are on top
    formattedPlaces.sort((a, b) => {
      if (a.subject) return 1;
      if (b.subject) return -1;
      if (a.active && !b.active) return 1;
      if (!a.active && b.active) return -1;
      return b.score - a.score;
    });
    return formattedPlaces;
  }, [filteredComps, selectedSaleCompIds]);

  const defaultCenter = {
    lat: Number(property.latitude),
    lng: Number(property.longitude),
  };

  return (
    <div className="w-full h-full z-40">
      {places && (
        <GoogleMap
          defaultCenter={defaultCenter}
          hoveredId={hoveredId}
          infoWindow={InfoWindow}
          places={places}
          setDrawingFiltered={setDrawingFiltered}
        />
      )}
    </div>
  );
}
