import { formatCurrency, formatCurrencyAbbreviated } from 'components/utils';
import dayjs from 'dayjs';
import DataTable from 'components/shared/Table/DataTable';
import { tdClassNameFn, theadClassName } from 'components/NhimbleDashboard/Shared';
import { AddressCell } from '../Lease/LeaseTable';

const COLUMNS = [
  {
    header: 'Address',
    accessorKey: 'Address',
    cell: AddressCell,
  },
  {
    header: 'Market',
    accessorKey: 'attributes.property.market',
    meta: { textAlign: 'left' },
  },
  {
    header: 'Portfolio/Entity',
    accessorKey: 'attributes.property.entity',
  },
  {
    header: 'Acquisition Stage',
    accessorKey: 'attributes.stage',
  },
  {
    header: 'Purchase Price',
    accessorFn: row => `${formatCurrencyAbbreviated(row.attributes.purchasePrice)}`,
  },
  {
    header: 'Honeycomb Market Value',
    accessorFn: row => `${formatCurrencyAbbreviated(row.attributes.honeycombMarketValue)}`,
  },
  {
    header: 'Honeycomb Rent',
    accessorFn: row => `${formatCurrency(row.attributes.honeycombRent)}`,
  },
  {
    header: 'Underwriting Rent',
    accessorFn: row => `${formatCurrency(row.attributes.underwritingRent)}`,
  },
  {
    header: 'Closing Date',
    accessorFn: row => `${row.attributes.closingDate ? dayjs(row.attributes.closingDate).format('MM/DD/YYYY') : '-'}`,
  },
];

const TABLE_META = { textAlign: 'center' };

export default function AcquisitionTable({ filteredData }) {
  return (
    <div className="border">
      <DataTable
        columns={COLUMNS}
        data={filteredData}
        emptyStateComponent="There are no available properties matching your criteria"
        theadClassName={theadClassName}
        tdClassName={tdClassNameFn}
        meta={TABLE_META}
      />
    </div>
  );
}
