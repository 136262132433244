import cx from 'classnames';
import { isFunction } from 'lodash';
import { forwardRef } from 'react';

function RoundButton({
  onClick,
  padding,
  hoverClassName = 'hover:bg-opacity-8 hover:bg-black',
  disabled = false,
  loading = false,
  stopPropagation = false,
  children,
}, ref) {
  const onClickStopPropagation = (e) => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <button
      ref={ref}
      type="button"
      onClick={stopPropagation ? onClickStopPropagation : onClick}
      disabled={disabled || loading}
      className={cx(
        'focus:outline-none rounded-full',
        padding,
        disabled ? 'cursor-not-allowed' : hoverClassName,
      )}
    >
      {isFunction(children) ? children({ disabled }) : children}
    </button>
  );
}

export default forwardRef(RoundButton);
