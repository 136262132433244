import { chunk, orderBy, omit } from 'lodash';
import { formatDate, titleCase } from 'components/utils';
import { PropertyLayoutContainer } from 'components/property/PropertyLayout';
import RecordGrid, { RecordGridCell, RecordGridColumn } from 'components/property/components/RecordGrid';
import { getAssessorSiteUrl } from 'utils/assessor';

function ParcelInformation({ parcel }) {
  // omit fields includes fields from both tax_assessor and tax_assessor_v2 formats
  const parcelContext = omit(parcel, ['boundaryGeom', 'owners', 'ownerAssessor', 'ownersUnmask', 'ownerUnmask', 'taxes', 'location', 'year', 'month', 'day']);

  const propertyParcelFields = Object.keys(parcelContext).map(key => ({ label: titleCase(key), field: key }));
  const colSize = Math.ceil(propertyParcelFields.length / 3);
  const parcelChunks = chunk(propertyParcelFields, colSize);

  return (
    <>
      <div className="w-full flex flex-col pb-2 border-b">
        <span>Parcel Information</span>
      </div>
      <RecordGrid>
        {parcelChunks.map((ch, i) => (
          <RecordGridColumn key={i}>
            {ch.map(c => <RecordGridCell label={c.label} value={parcelContext?.[c.field] || '-'} key={c.field} />)}
          </RecordGridColumn>
        ))}
      </RecordGrid>
    </>
  );
}

function PropertyTaxInformation({ taxes }) {
  const mostRecentTaxes = orderBy(taxes, 'fiscalYear', 'desc')[0];

  if (!mostRecentTaxes) {
    return (
      <>
        <div className="w-full flex justify-between items-center mt-6 pb-2 border-b">
          <span>Tax Information</span>
        </div>
        No Tax Information
      </>
    );
  }

  const propertyTaxFields = Object.keys(mostRecentTaxes).map(key => ({ label: titleCase(key), field: key }));
  const colSize = Math.ceil(propertyTaxFields.length / 3);
  const taxChunks = chunk(propertyTaxFields, colSize);

  return (
    <>
      <div className="w-full flex justify-between items-center mt-6 pb-2 border-b">
        <span>Tax Information</span>
      </div>
      <RecordGrid>
        {taxChunks.map((ch, i) => (
          <RecordGridColumn key={i}>
            {ch.map(mapping => <RecordGridCell label={mapping.label} value={mostRecentTaxes?.[mapping.field] || '-'} key={mapping.field} />)}
          </RecordGridColumn>
        ))}
      </RecordGrid>
    </>
  );
}

function OwnerInfo({ owner, parcel }) {
  return (
    <>
      <div className="w-full flex justify-between items-center mt-6 pb-2 border-b">
        <span>Owner Information</span>
      </div>
      <div className="py-6">
        <RecordGrid>
          <RecordGridColumn>
            <RecordGridCell label="Name" value={owner?.name || owner?.ownerName || '-'} />
            <RecordGridCell label="Address" value={owner?.address || '-'} />
            <RecordGridCell label="City" value={owner?.city || '-'} />
            <RecordGridCell label="State" value={owner?.state || '-'} />
            <RecordGridCell label="Zip Code" value={owner?.zipCode || '-'} />
          </RecordGridColumn>
          <RecordGridColumn>
            <RecordGridCell label="Mailing Address" value={parcel?.mailingAddress || '-'} />
            <RecordGridCell label="Mailing City" value={parcel?.mailingCity || '-'} />
            <RecordGridCell label="Mailing State" value={parcel?.mailingState || '-'} />
            <RecordGridCell label="Mailing Zip" value={parcel?.mailingZip || '-'} />
          </RecordGridColumn>
          <RecordGridColumn>
            <RecordGridCell label="Is Company" value={owner?.isOwnerCompany?.toString() || '-'} />
            <RecordGridCell label="Phone" value={owner?.phone || '-'} />
            <RecordGridCell label="Email" value={owner?.email || '-'} />
          </RecordGridColumn>
        </RecordGrid>
      </div>
    </>
  );
}

export default function AssessorDetails({ owners, parcels, property, taxes }) {
  // just grab first Parcel due to data quirk where some property records do not have apnMin set
  const parcel = parcels.find(p => p.apn === property.apnMin) || parcels[0];
  const assessorSiteUrl = parcel && getAssessorSiteUrl(parcel);

  if (!parcel) {
    return (
      <div className="w-full mx-auto rounded-xl border bg-white mb-6">
        <p className="p-6 text-gray-400">Listing was not able to be matched to a parcel record</p>
      </div>
    );
  }

  return (
    <>
      <div className="mb-6">
        <div className="text-xl text-black mb-1">Assessor Details</div>
        {assessorSiteUrl && <a className="underline text-sm text-primary-dark pb-2" href={assessorSiteUrl} target="_blank" rel="noreferrer">Assessor Site</a>}
        <div className="text-gray-500 text-xs">{`Updated: ${formatDate(parcel?.dataPublishDate) || ''}`}</div>
      </div>
      <div className="flex flex-col w-full p-6 h-min max-h-fit bg-white rounded-xl">
        {parcel && <ParcelInformation parcel={parcel} />}
        {(owners.length || false) && <OwnerInfo owner={owners[0]} parcel={parcel} />}
        <PropertyTaxInformation taxes={taxes} />
      </div>
    </>
  );
}

export function EmbeddedAssessorDetails({ context }) {
  const { data } = context;
  const { owners, parcels, property, taxes } = data;

  return (
    <PropertyLayoutContainer>
      <AssessorDetails owners={owners} parcels={parcels} property={property} taxes={taxes} />
    </PropertyLayoutContainer>
  );
}
