import { useEffect, useRef } from 'react';
import RoundButton from 'components/common/RoundButton';
import classNames from 'classnames';
import { partial } from 'lodash';
import Modal from 'components/Modal';
import { Chevron, X } from 'components/icons';
import { hideImgOnError } from 'components/utils';

export default function PhotoModal({ photos, primaryPhotoIndex, setPrimaryPhotoIndex, showModal, setShowModal }) {
  const photoRef = useRef(null);

  const onClick = (left) => {
    if (left && primaryPhotoIndex === 0) {
      setPrimaryPhotoIndex(photos.length - 1);
    } else if (!left && primaryPhotoIndex === photos.length - 1) {
      setPrimaryPhotoIndex(0);
    } else {
      setPrimaryPhotoIndex(left ? primaryPhotoIndex - 1 : primaryPhotoIndex + 1);
    }
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
    if ((e.keyCode === 37 || e.keyCode === 38) && primaryPhotoIndex > 0) {
      setPrimaryPhotoIndex(primaryPhotoIndex - 1);
    } else if ((e.keyCode === 39 || e.keyCode === 40) && primaryPhotoIndex < photos.length - 1) {
      setPrimaryPhotoIndex(primaryPhotoIndex + 1);
    } else if (e.keyCode === 27) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    photoRef.current.focus();
  }, [showModal]);

  // Determine whether an element is visible in a scrollable container.
  // If the element is not visible, use primaryPhotoIndex to navigate to it.
  useEffect(() => {
    const container = document.getElementById('photos-container');
    const element = document.getElementById(`image-${primaryPhotoIndex}`);

    const { bottom, top } = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    const visible = top >= containerRect.top && top <= containerRect.bottom && bottom >= containerRect.top && bottom <= containerRect.bottom;
    if (!visible) document.getElementById(`image-${primaryPhotoIndex}`).scrollIntoView();
  }, [primaryPhotoIndex]);

  return (
    <Modal fullScreen show={showModal} backgroundColor="bg-transparent" onClose={() => setShowModal(false)} showCloseAction={false} onKeyDown={handleKeyDown}>
      <div ref={photoRef} className="flex w-auto h-full p-6 items-center justify-center" onClick={() => setShowModal(false)} onKeyDown={handleKeyDown} tabIndex="0">
        <div className="flex flex-col w-10/12 h-full overflow-hidden bg-white rounded-xl divide-y divide-neutral-dark divide-opacity-12" onClick={(e) => e.stopPropagation()}>
          <div className="flex justify-end items-center w-full px-6 py-2">
            <div name="outputModalButton" className="h-12 w-12 align-top">
              <RoundButton name="outputModalButton" padding="p-2 border border-neutral-dark border-opacity-12" onClick={() => setShowModal(false)}>
                <X className="w-7" />
              </RoundButton>
            </div>
          </div>
          <div className="grow flex p-6 select-none" style={{ height: 'calc(100% - 64px)' }}>
            <div id="photos-container" className="w-1/4 h-full overflow-y-auto">
              {photos.map(({ url }, index) => (
                <div id={`image-${index}`} key={index} className="cursor-pointer mb-3" onClick={() => setPrimaryPhotoIndex(index)}>
                  <img
                    className={classNames('object-cover w-full h-full rounded', { 'opacity-40 border-4 border-black': index === primaryPhotoIndex })}
                    referrerPolicy="no-referrer"
                    src={url}
                    alt={url}
                    onError={hideImgOnError}
                  />
                </div>
              ))}
            </div>
            <div className="px-12 relative h-full flex justify-center items-center rounded">
              <img className="object-contain h-full" referrerPolicy="no-referrer" src={photos[primaryPhotoIndex]?.url} alt={photos[primaryPhotoIndex]?.url} onError={hideImgOnError} />
              <div className="absolute text-center bottom-4 m-auto py-1.5 px-3 text-white text-sm font-medium rounded-lg bg-neutral-medium">
                {`${primaryPhotoIndex + 1} of ${photos.length}`}
              </div>
              <div className="absolute inset-0 flex justify-between items-center z-10">
                <div className="ml-6 w-12 h-12 py-2 px-2 cursor-pointer border border-neutral-dark/[.12] rounded-full bg-white" onClick={partial(onClick, true)}>
                  <Chevron direction="left" className="w-8 h-8 text-black" />
                </div>
                <div className="mr-6 w-12 h-12 py-2 px-2 cursor-pointer border border-neutral-dark/[.12] rounded-full bg-white" onClick={partial(onClick, false)}>
                  <Chevron direction="right" className="w-8 h-8 text-black" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
