import waterfallParameters from '../parameters/WaterfallParameters';
import { InlineFormField, InlineInfoField } from '../Form';

const WaterfallParams = waterfallParameters(InlineFormField, InlineInfoField, 'mt-3');

export default function WaterfallParameters({ dcfParams, onChange }) {
  return (
    <div className="flex flex-col w-120 p-3 bg-white">
      <WaterfallParams parameters={dcfParams} onChange={onChange} />
    </div>
  );
}