import MuteZero from './MuteZero';
import { deliveryScheduleMetaKey, getVisibleDeliveryScheduleColumnIds } from './subdivision';

/**
 * @param {import('@tanstack/react-table').HeaderContext['table']} table
 * @param {import('@tanstack/react-table').HeaderContext['column']} column
 */
export default function TotalQtyFooter({ table, column }) {
  const isDateColumn = Object.hasOwn(column.columnDef.meta ?? {}, deliveryScheduleMetaKey);
  const coverColumns = isDateColumn ? [column.id] : getVisibleDeliveryScheduleColumnIds({ table });

  const total = table.getRowModel()
    .flatRows
    .flatMap(({ getValue }) => coverColumns.map((colId) => getValue(colId)))
    .reduce((tot, curr) => tot + (curr || 0), 0);

  return <MuteZero value={total} />;
}
