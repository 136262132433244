import { forEach, identity } from 'lodash';
import { formatCurrency, formatInteger, formatPercentageGrowth } from '../utils';

export const DATA_LABELS = {
  tractId: ['Census Tract ID', (value) => value],
  MHIGRWCYFY: ['HH Income Growth 2021-2026', formatPercentageGrowth],
  POPGRWCYFY: ['Population Growth 2021-2026', formatPercentageGrowth],
  MEDVAL_CY: ['Median Home Value', formatCurrency],
  RENTER_CY: ['Renter Occupied Housing Units', formatInteger],
  MEDHINC_CY: ['Median Household Income', formatCurrency],
  POPDENS_CY: ['Population Density', formatInteger],
  PCI_CY: ['Per Capita Income', formatCurrency],
  VACANT_CY: ['Vacant Housing Units', formatInteger],
  HHGRWCYFY: ['Growth Rate: Households', formatPercentageGrowth],
  PCIGRWCYFY: ['Growth Rate: Per Capita Income', formatPercentageGrowth],
  OWNGRWCYFY: ['Growth Rate: Owner Occupied HUs', formatPercentageGrowth],
  UNEMPRT_CY: ['Unemployment Rate', formatPercentageGrowth],
  CRMCYTOTC: ['Crime Rate', formatInteger],
  CRMCYPROC: ['Property Crime Index', formatInteger],
  neighborhoodName: ['Neighborhood', identity],
  ancestorNeighborhoodName: ['Within Neighborhood', identity],
};

// coords: [SWLAT, SWLNG, NELAT, NELNG]
export const shrinkBounds = (coords, percent = 0.08) => {
  function adjustmentAmount(value1, value2) {
    return Math.abs(value1 - value2) * percent;
  }

  const latAdjustment = adjustmentAmount(+coords[0], +coords[2]);
  const lngAdjustment = adjustmentAmount(+coords[1], +coords[3]);

  return [
    +coords[0] + latAdjustment, // SW lat
    +coords[1] + lngAdjustment, // SW lng
    +coords[2] - latAdjustment, // NE lat
    +coords[3] - lngAdjustment, // NE lng
  ];
};

export const BOUNDS_TOLERANCE = 0.03;
export const tractDataHtml = (data) => {
  if (!data) {
    return '';
  }

  let htmlContent = '<div>';
  data.forEach(d => {
    forEach(d, (v, k) => {
      if (DATA_LABELS[k]) {
        htmlContent += `<div class="census-info">
          <span>${DATA_LABELS[k][0]}: </span>
          <strong>${DATA_LABELS[k][1](v)}</strong>
          </div>`;
      }
    });
  });

  return `${htmlContent}</div>`;
};

// Tailwind Green | 600 - 50
const getHHIncomeGrowthColor = (hhIncomeGrowthValue) => {
  let color;
  if (!hhIncomeGrowthValue || hhIncomeGrowthValue < 0) {
    color = '#ECFDF5';
  } else if (hhIncomeGrowthValue < 1) {
    color = '#D1FAE5';
  } else if (hhIncomeGrowthValue < 2) {
    color = '#A7F3D0';
  } else if (hhIncomeGrowthValue < 3) {
    color = '#6EE7B7';
  } else if (hhIncomeGrowthValue < 4) {
    color = '#34D399';
  } else if (hhIncomeGrowthValue < 5) {
    color = '#10B981';
  } else {
    color = '#059669';
  }
  return color;
};

// Tailwind Blue | 600 - 50
const getPopGrowthColor = (popGrowthValue) => {
  let color;
  if (!popGrowthValue || popGrowthValue < 0) {
    color = '#EFF6FF';
  } else if (popGrowthValue < 1) {
    color = '#DBEAFE';
  } else if (popGrowthValue < 2) {
    color = '#BFDBFE';
  } else if (popGrowthValue < 3) {
    color = '#93C5FD';
  } else if (popGrowthValue < 4) {
    color = '#60A5FA';
  } else if (popGrowthValue < 5) {
    color = '#3B82F6';
  } else {
    color = '#2563EB';
  }
  return color;
};

// Tailwind Indigo | 600 - 50
const getMedHomeValueColor = (medHomeValue) => {
  let color;
  if (!medHomeValue || medHomeValue < 0) {
    color = '#EEF2FF';
  } else if (medHomeValue < 100000) {
    color = '#C7D2FE';
  } else if (medHomeValue < 200000) {
    color = '#C7D2FE';
  } else if (medHomeValue < 300000) {
    color = '#A5B4FC';
  } else if (medHomeValue < 400000) {
    color = '#818CF8';
  } else if (medHomeValue < 500000) {
    color = '#6366F1';
  } else {
    color = '#4F46E5';
  }
  return color;
};

// Tailwind Pink | 600 - 50
const getPopDensityColor = (popDensityValue) => {
  let color;
  if (!popDensityValue || popDensityValue < 0) {
    color = '#FDF2F8';
  } else if (popDensityValue < 100) {
    color = '#FCE7F3';
  } else if (popDensityValue < 500) {
    color = '#FBCFE8';
  } else if (popDensityValue < 5000) {
    color = '#F9A8D4';
  } else if (popDensityValue < 15000) {
    color = '#F472B6';
  } else if (popDensityValue < 30000) {
    color = '#EC4899';
  } else {
    color = '#DB2777';
  }
  return color;
};

// Tailwind Red | 600 - 50
const getMedHomeIncomeColor = (medHomeIncomeValue) => {
  let color;
  if (!medHomeIncomeValue || medHomeIncomeValue < 0) {
    color = '#FEF2F2';
  } else if (medHomeIncomeValue < 50000) {
    color = '#FEE2E2';
  } else if (medHomeIncomeValue < 100000) {
    color = '#FECACA';
  } else if (medHomeIncomeValue < 200000) {
    color = '#FCA5A5';
  } else if (medHomeIncomeValue < 300000) {
    color = '#F87171';
  } else if (medHomeIncomeValue < 400000) {
    color = '#EF4444';
  } else {
    color = '#DC2626';
  }
  return color;
};

// Tailwind Yellow | 600 - 50
const getPerCapitaIncomeColor = (perCapitaIncomeValue) => {
  let color;
  if (!perCapitaIncomeValue || perCapitaIncomeValue < 0) {
    color = '#FFFBEB';
  } else if (perCapitaIncomeValue < 20000) {
    color = '#FEF3C7';
  } else if (perCapitaIncomeValue < 30000) {
    color = '#FDE68A';
  } else if (perCapitaIncomeValue < 40000) {
    color = '#FCD34D';
  } else if (perCapitaIncomeValue < 50000) {
    color = '#FBBF24';
  } else if (perCapitaIncomeValue < 75000) {
    color = '#F59E0B';
  } else {
    color = '#D97706';
  }
  return color;
};

// Tailwind Blue | 600 - 50
const getRenterOccupiedHousingUnitscolor = (renterOccupiedHousingUnitsValue) => {
  let color;
  if (!renterOccupiedHousingUnitsValue || renterOccupiedHousingUnitsValue < 0) {
    color = '#EFF6FF';
  } else if (renterOccupiedHousingUnitsValue < 50) {
    color = '#DBEAFE';
  } else if (renterOccupiedHousingUnitsValue < 200) {
    color = '#BFDBFE';
  } else if (renterOccupiedHousingUnitsValue < 500) {
    color = '#93C5FD';
  } else if (renterOccupiedHousingUnitsValue < 1000) {
    color = '#60A5FA';
  } else if (renterOccupiedHousingUnitsValue < 2000) {
    color = '#3B82F6';
  } else {
    color = '#2563EB';
  }
  return color;
};

// Tailwind Green | 600 - 50
const getVacantHousingUnitsColor = (vacantHousingUnitsValue) => {
  let color;
  if (!vacantHousingUnitsValue || vacantHousingUnitsValue < 0) {
    color = '#ECFDF5';
  } else if (vacantHousingUnitsValue < 1) {
    color = '#D1FAE5';
  } else if (vacantHousingUnitsValue < 100) {
    color = '#A7F3D0';
  } else if (vacantHousingUnitsValue < 500) {
    color = '#6EE7B7';
  } else if (vacantHousingUnitsValue < 1000) {
    color = '#34D399';
  } else if (vacantHousingUnitsValue < 2000) {
    color = '#10B981';
  } else {
    color = '#059669';
  }
  return color;
};

// Tailwind Indigo | 600 - 50
const getHouseholdGrowthRateColor = (householdGrowthRateValue) => {
  let color;
  if (!householdGrowthRateValue || householdGrowthRateValue < 0) {
    color = '#EEF2FF';
  } else if (householdGrowthRateValue < 0.5) {
    color = '#C7D2FE';
  } else if (householdGrowthRateValue < 0.75) {
    color = '#C7D2FE';
  } else if (householdGrowthRateValue < 1) {
    color = '#A5B4FC';
  } else if (householdGrowthRateValue < 2) {
    color = '#818CF8';
  } else if (householdGrowthRateValue < 3) {
    color = '#6366F1';
  } else {
    color = '#4F46E5';
  }
  return color;
};

// Tailwind Red | 600 - 50
const getPerCapitaIncomeGrowthColor = (perCapitaIncomeGrowthRateValue) => {
  let color;
  if (!perCapitaIncomeGrowthRateValue || perCapitaIncomeGrowthRateValue < 0) {
    color = '#FEF2F2';
  } else if (perCapitaIncomeGrowthRateValue < 0.5) {
    color = '#FEE2E2';
  } else if (perCapitaIncomeGrowthRateValue < 1.5) {
    color = '#FECACA';
  } else if (perCapitaIncomeGrowthRateValue < 2) {
    color = '#FCA5A5';
  } else if (perCapitaIncomeGrowthRateValue < 2.5) {
    color = '#F87171';
  } else if (perCapitaIncomeGrowthRateValue < 3) {
    color = '#EF4444';
  } else {
    color = '#DC2626';
  }
  return color;
};

// Tailwind Red | 600 - 50
const getOOHUGrowthRateColor = (OOHUGrowthRateValue) => {
  let color;
  if (!OOHUGrowthRateValue || OOHUGrowthRateValue < 0) {
    color = '#FEF2F2';
  } else if (OOHUGrowthRateValue < 0.5) {
    color = '#FEE2E2';
  } else if (OOHUGrowthRateValue < 1.5) {
    color = '#FECACA';
  } else if (OOHUGrowthRateValue < 2) {
    color = '#FCA5A5';
  } else if (OOHUGrowthRateValue < 2.5) {
    color = '#F87171';
  } else if (OOHUGrowthRateValue < 3) {
    color = '#EF4444';
  } else {
    color = '#DC2626';
  }
  return color;
};

// Tailwind Red | 600 - 50
const getUnemploymentRateColor = (unemploymentRateValue) => {
  let color;
  if (!unemploymentRateValue || unemploymentRateValue < 0) {
    color = '#FEF2F2';
  } else if (unemploymentRateValue < 1) {
    color = '#FEE2E2';
  } else if (unemploymentRateValue < 2) {
    color = '#FECACA';
  } else if (unemploymentRateValue < 3) {
    color = '#FCA5A5';
  } else if (unemploymentRateValue < 5) {
    color = '#F87171';
  } else if (unemploymentRateValue < 7) {
    color = '#EF4444';
  } else {
    color = '#DC2626';
  }
  return color;
};

// Tailwind Yellow | 600 - 50
const getCrimeRateColor = (crimeRateValue) => {
  let color;
  if (!crimeRateValue || crimeRateValue < 0) {
    color = '#FFFBEB';
  } else if (crimeRateValue < 100) {
    color = '#FEF3C7';
  } else if (crimeRateValue < 200) {
    color = '#FDE68A';
  } else if (crimeRateValue < 300) {
    color = '#FCD34D';
  } else if (crimeRateValue < 400) {
    color = '#FBBF24';
  } else if (crimeRateValue < 600) {
    color = '#F59E0B';
  } else {
    color = '#D97706';
  }
  return color;
};

// Tailwind Yellow | 600 - 50
const getPropertyCrimeIndexColor = (propertyCrimeIndexValue) => {
  let color;
  if (!propertyCrimeIndexValue || propertyCrimeIndexValue < 0) {
    color = '#FFFBEB';
  } else if (propertyCrimeIndexValue < 100) {
    color = '#FEF3C7';
  } else if (propertyCrimeIndexValue < 200) {
    color = '#FDE68A';
  } else if (propertyCrimeIndexValue < 300) {
    color = '#FCD34D';
  } else if (propertyCrimeIndexValue < 400) {
    color = '#FBBF24';
  } else if (propertyCrimeIndexValue < 600) {
    color = '#F59E0B';
  } else {
    color = '#D97706';
  }
  return color;
};

export const setHeatMapColor = (feature) => {
  const hhIncomeGrowthValue = feature.getProperty('data')[0].MHIGRWCYFY;

  return {
    fillColor: getHHIncomeGrowthColor(hhIncomeGrowthValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setPopGrowthColor = (feature) => {
  const popGrowthValue = feature.getProperty('data')[0].POPGRWCYFY;

  return {
    fillColor: getPopGrowthColor(popGrowthValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setPopDensityColor = (feature) => {
  const popDensityValue = feature.getProperty('data')[0].POPDENS_CY;

  return {
    fillColor: getPopDensityColor(popDensityValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setMedHomeValueColor = (feature) => {
  const medHomeValue = feature.getProperty('data')[0].MEDVAL_CY;

  return {
    fillColor: getMedHomeValueColor(medHomeValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setMedHomeIncomeColor = (feature) => {
  const medHomeIncomeValue = feature.getProperty('data')[0].MEDHINC_CY;

  return {
    fillColor: getMedHomeIncomeColor(medHomeIncomeValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setPerCapitaIncomeColor = (feature) => {
  const perCapitaIncomeValue = feature.getProperty('data')[0].PCI_CY;

  return {
    fillColor: getPerCapitaIncomeColor(perCapitaIncomeValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setRenterOccupiedHousingUnitsColor = (feature) => {
  const renterOccupiedHousingUnitsValue = feature.getProperty('data')[0].RENTER_CY;

  return {
    fillColor: getRenterOccupiedHousingUnitscolor(renterOccupiedHousingUnitsValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setVacantHousingUnitsColor = (feature) => {
  const vacantHousingUnitsValue = feature.getProperty('data')[0].VACANT_CY;

  return {
    fillColor: getVacantHousingUnitsColor(vacantHousingUnitsValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setHouseholdGrowthRateColor = (feature) => {
  const householdGrowthRateValue = feature.getProperty('data')[0].HHGRWCYFY;

  return {
    fillColor: getHouseholdGrowthRateColor(householdGrowthRateValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setPerCapitaIncomeGrowthRateColor = (feature) => {
  const perCapitaIncomeGrowthRateValue = feature.getProperty('data')[0].PCIGRWCYFY;

  return {
    fillColor: getPerCapitaIncomeGrowthColor(perCapitaIncomeGrowthRateValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setOOHUGrowthRateColor = (feature) => {
  const OOHUGrowthRateValue = feature.getProperty('data')[0].OWNGRWCYFY;

  return {
    fillColor: getOOHUGrowthRateColor(OOHUGrowthRateValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setUnemploymentRateColor = (feature) => {
  const unemploymentRateValue = feature.getProperty('data')[0].UNEMPRT_CY;

  return {
    fillColor: getUnemploymentRateColor(unemploymentRateValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setCrimeRateColor = (feature) => {
  const crimeRateValue = feature.getProperty('data')[0].CRMCYTOTC;

  return {
    fillColor: getCrimeRateColor(crimeRateValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setPropertyCrimeIndexColor = (feature) => {
  const propertyCrimeIndexValue = feature.getProperty('data')[0].CRMCYPROC;

  return {
    fillColor: getPropertyCrimeIndexColor(propertyCrimeIndexValue),
    fillOpacity: 0.6,
    strokeWeight: 1,
  };
};

export const setDefaultColor = (feature) => ({
  fillColor: 'green',
  strokeWeight: 1,
  visible: true,
  zIndex: feature?.getProperty('zHeight') ?? 0,
});

export const getRectangleArea = (rectangle) => {
  const bounds = rectangle.getBounds();

  const rectBoundsLatlng = [];
  const rectBoundsLatlngPath = [];

  rectBoundsLatlng[0] = new google.maps.LatLng(bounds.getNorthEast());
  rectBoundsLatlng[1] = new google.maps.LatLng(bounds.getSouthWest());
  rectBoundsLatlngPath[0] = new google.maps.LatLng(rectBoundsLatlng[1].lat(), rectBoundsLatlng[0].lng());
  rectBoundsLatlngPath[1] = new google.maps.LatLng(rectBoundsLatlng[0].lat(), rectBoundsLatlng[1].lng());

  const areaPath = [
    rectBoundsLatlng[0],
    rectBoundsLatlngPath[0],
    rectBoundsLatlng[1],
    rectBoundsLatlngPath[1],
    rectBoundsLatlng[0],
  ];

  return google.maps.geometry.spherical.computeArea(areaPath);
};
