import { LoadingIndicator, ZoomIn, QuestionMarker, DrawOutlined, ExclamationCircle } from 'components/icons';

const icons = {
  needsZoom: <ZoomIn className="w-6 text-gray-300 opacity-50" />,
  loading: <LoadingIndicator className="w-6 text-gray-300" />,
  empty: <QuestionMarker className="w-6 text-gray-300 opacity-50" />,
  needsShape: <DrawOutlined className="w-6 text-gray-300 opacity-50" />,
  shapeTooBig: <ExclamationCircle className="w-6 opacity-80" />,
};

const headings = {
  needsZoom: 'Cannot Display',
  loading: ' ',
  empty: 'No Results',
  needsShape: 'Draw a shape',
  shapeTooBig: 'Shape Too Big',
};

const subHeadings = {
  needsZoom: 'Draw a shape to view properties',
  loading: 'Loading results...',
  empty: 'No properties match your search criteria',
  needsShape: 'Use the draw tool to display properties in an area',
  shapeTooBig: 'Reduce size of drawn shape',
};

function EmptyState({ type, className }) {
  const icon = icons[type];
  const heading = headings[type];
  const subHeading = subHeadings[type];

  return (
    <div className={`${className}`}>
      <div className="w-64 flex flex-col justify-center items-center text-center text-gray-500">
        <div className="mb-6">{ icon }</div>
        <p className="text-xl text-black">{ heading }</p>
        <p className="text-sm">{ subHeading }</p>
      </div>

    </div>
  );
}

export default EmptyState;
