import { useState } from 'react';
import { DataTableContent } from 'components/shared/Table/DataTableContext';
import OffMarketInventoryTable from './OffMarketInventoryTable';
import OffMarketInventoryTableHeader from './OffMarketInventoryTableHeader';


export default function OffMarketInventory() {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <div className="flex flex-col h-container w-container">
      <OffMarketInventoryTable tableContainerClassName="h-max max-h-container" isEditMode={isEditMode}>
        <OffMarketInventoryTableHeader isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
        <div className="flex flex-row flex-1 [container-type:size] rounded-l-xl overflow-clip">
          <DataTableContent />
        </div>
      </OffMarketInventoryTable>
    </div>
  );
}
