import {
  range,
  zipWith
} from 'lodash';
import { arrayMax } from '../utils';
import { differenceInDays } from 'date-fns';

export function calcCumulativeOutstandingEquity(leveredCashFlows) {
  const cumulativeOutstandingEquity = [leveredCashFlows[0]];
  range(1, leveredCashFlows.length).forEach(index => {
    const prevOutstandingEquity = cumulativeOutstandingEquity[index - 1];
    const prevReturnOfCapital = Math.min(Math.max(leveredCashFlows[index - 1], 0), -1 * prevOutstandingEquity);
    const currentContribution = Math.min(leveredCashFlows[index], 0);
    cumulativeOutstandingEquity.push(prevOutstandingEquity + prevReturnOfCapital + currentContribution);
  });
  return cumulativeOutstandingEquity;
}

export function calcReturnOfCapitalReturns(leveredCashFlows, cumulativeOutstandingEquity) {
  return leveredCashFlows.map((cf, index) => {
    return Math.min(Math.max(cf, 0), -1 * cumulativeOutstandingEquity[index]);
  });
}

export function calcAdditions(cashForDistribution, lpOwnershipShare) {
  return cashForDistribution.map(cash => cash < 0 ? -cash * lpOwnershipShare : 0);
}

export function calcReturns(cashForSplits, additions, returnOfCapitalAndPreviousDistributions, lpOwnershipShare, hurdleIrr, cashFlowDates) {
  const cashEnd = new Array(cashForSplits.length).fill(0);
  // set initial cash-end to additions from first period
  cashEnd[0] = additions[0];
  const hurdleDistribution = new Array(cashForSplits.length).fill(0);
  const accrual = new Array(cashForSplits.length).fill(0);

  // loop through periods to populate hurdleDistribution array
  range(1, cashForSplits.length).forEach(i => {
    const daysInPeriod = differenceInDays(cashFlowDates[i], cashFlowDates[i - 1]);
    const adjustedIrrForPeriod = ((1 + hurdleIrr) ** (daysInPeriod / 365) - 1);
    accrual[i] = cashEnd[i - 1] * adjustedIrrForPeriod;

    if (cashForSplits[i] > 0) {
      hurdleDistribution[i] = -Math.min(
        cashForSplits[i] * lpOwnershipShare,
        cashEnd[i - 1] + accrual[i] + additions[i] + returnOfCapitalAndPreviousDistributions[i],
      );
    }

    cashEnd[i] = cashEnd[i - 1] + accrual[i] + additions[i] + returnOfCapitalAndPreviousDistributions[i] + hurdleDistribution[i];
  });

  const cashBeginning = [0].concat(cashEnd.slice(0, -1));

  return [
    cashBeginning,
    accrual,
    hurdleDistribution,
    cashEnd,
  ];
}

export function calcCashForSplits(cashSplits, cashLp, cashGp) {
  return arrayMax(zipWith(cashSplits, cashLp, cashGp, (available, lp, gp) => lp >= 0 ? available - (lp + gp) : 0), 0)
}

export function calcLpSplits(ownershipShareLp, hurdlePromotes) {
  const gpEquity = 1 - ownershipShareLp;
  return [ownershipShareLp, ...hurdlePromotes.map(promote => 1 - (gpEquity + (promote * ownershipShareLp)))];
}
