import cx from 'classnames';
import { isNil } from 'lodash';

export function DashboardCard({ label, value, valueClassName, grow, onClick, width = 'w-full' }) {
  const cardClassName = cx(
    width,
    grow && 'flex-grow',
    'transition-all duration-500 ease-in-out transform hover:scale-105 box-shadow box-border p-6 bg-white border border-gray-400 rounded-lg shadow-xl hover:bg-gray-100',
    onClick && 'cursor-pointer',
  );
  return (
    <div className={cardClassName} onClick={onClick}>
      <div className="flex flex-col items-center justify-center h-full">
        <dt className="mb-2 text-gray-900 text-md font-bold text-center">
          {label}
        </dt>
        <dd
          className={cx('text-gray-500 text-2xl font-semibold', { [valueClassName]: valueClassName })}
        >
          {value || '-'}
        </dd>
      </div>
    </div>
  );
}

export default function ReturnMetricBoxes({ metrics }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-3 gap-y-6">
      {metrics.map((metric, index) => (
        <div className="flex flex-col gap-y-2" key={index}>
          <DashboardCard grow label={metric.label} value={metric.value} valueClassName={metric.valueClassNames} />
          {!metric.hideLookup && (
            <>
              <div className="transition-all duration-500 ease-in-out transform hover:scale-105 bg-gradient-to-r from-gray-100 border border-gray-400 to-gray-200 text-black font-semibold text-md w-full rounded py-2 text-center">
                Last Week
                {' '}
                {isNil(metric.lastWeekValue) ? '-' : `- ${metric.lastWeekValue}`}
              </div>
              <div className="transition-all duration-500 ease-in-out transform hover:scale-105 bg-gradient-to-r from-gray-100 border border-gray-400 to-gray-200 text-black font-semibold text-md w-full rounded py-2 text-center">
                Last Month
                {' '}
                {isNil(metric.lastMonthValue) ? '-' : `- ${metric.lastMonthValue}`}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
}
