import { useOutletContext } from 'react-router-dom';

export const dealContextSymbol = Symbol('DealContext');
export const useIsInDealContext = () => dealContextSymbol in (useOutletContext() ?? {});

export const useIsPortfolioDeal = () => {
  const isInDealContext = useIsInDealContext();

  const {
    data: {
      deal,
      properties,
    } = {},
  } = useOutletContext() ?? {};
  return isInDealContext && (deal?.isPortfolioDeal ?? properties?.length > 1);
};

export const useShowPortfolioLayout = () => {
  const { propertyId } = useOutletContext();
  const isPortfolioDeal = useIsPortfolioDeal();
  return isPortfolioDeal && !propertyId;
};
