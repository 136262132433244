import { useCallback, useDebugValue, useMemo, useRef, useState, useSyncExternalStore } from 'react';

/** @type {WeakMap<Element, Set>} */
const roListeners = new WeakMap();

// share a single ResizeObserver, see https://github.com/WICG/resize-observer/issues/59
const resizeObserver = new ResizeObserver((entries) => {
  // note: ResizeObserver callback called after requestAnimationFrame and before paint
  entries.forEach((entry) => {
    const element = entry.target;
    roListeners.get(element)?.forEach((listener) => listener(entry));
  });
});

/**
 * @param {ResizeObserverSize[]} boxSize
 * @return {ResizeObserverSize}
 */
const sumBoxSizes = (boxSize) => (
  boxSize.reduce(({ blockSize, inlineSize }, next) => ({
    blockSize: blockSize + next.blockSize,
    inlineSize: inlineSize + next.inlineSize,
  }), { blockSize: 0, inlineSize: 0 })
);

/**
 * @param {boolean} defer
 * @param {boolean} observeBlockSize
 * @param {boolean} observeInlineSize
 * @param {number | undefined | any} initialBlockSize
 * @param {number | undefined | any} initialInlineSize
 * @return {{ refCallback: import('react').RefCallback<Element>, subscribe: (onStoreChange: () => void) => () => void, getSnapshot: () => string }}
 */
const useObserve = ({
  defer,
  observeBlockSize,
  observeInlineSize,
  initialBlockSize,
  initialInlineSize,
}) => {
  const optionsRef = useRef({});
  optionsRef.current.defer = defer;

  const elementRef = useRef();
  const onStoreChangeRef = useRef();
  const sizeSnapshotRef = useRef({ blockSize: initialBlockSize, inlineSize: initialInlineSize });

  const [[addListener, removeListener]] = useState(() => {
    const updateSnapshot = (totalSize) => {
      sizeSnapshotRef.current = totalSize;
      onStoreChangeRef.current?.();
    };

    const listener = (entry) => {
      if (entry.target !== elementRef.current) {
        return;
      }

      // TODO: option to read other boxes?
      const totalSize = sumBoxSizes(entry.borderBoxSize);

      if (optionsRef.current.defer) {
        requestAnimationFrame(() => {
          if (entry.target === elementRef.current) {
            updateSnapshot(totalSize);
          }
        });
      } else {
        updateSnapshot(totalSize);
      }
    };

    const add = (element) => {
      if (roListeners.has(element)) {
        roListeners.get(element).add(listener);
      } else {
        roListeners.set(element, new Set([listener]));
      }

      // update elementRef just before observe
      elementRef.current = element;
      // TODO: option to observe other boxes?
      resizeObserver.observe(element, { box: 'border-box' });
    };

    const remove = (element) => {
      if (!element) {
        return;
      }

      const elementListeners = roListeners.get(element);
      elementListeners?.delete(listener);
      if (elementListeners?.size === 0) {
        resizeObserver.unobserve(element);
      }
    };

    return [add, remove];
  });

  const refCallback = useCallback((element) => {
    if (element && element !== elementRef.current) {
      removeListener(element);
      addListener(element);
    } else if (element === null) {
      removeListener(element);
    }
  }, [addListener, removeListener]);

  const subscribe = useCallback((onStoreChange) => {
    onStoreChangeRef.current = onStoreChange;

    return () => {
      onStoreChangeRef.current = undefined;
    };
  }, []);

  const getSnapshot = useCallback(() => {
    const { blockSize, inlineSize } = sizeSnapshotRef.current;
    return JSON.stringify({
      blockSize: observeBlockSize ? blockSize : undefined,
      inlineSize: observeInlineSize ? inlineSize : undefined,
    });
  }, [observeBlockSize, observeInlineSize]);

  useDebugValue(sizeSnapshotRef.current);
  return useMemo(() => ({
    refCallback,
    subscribe,
    getSnapshot,
  }), [getSnapshot, refCallback, subscribe]);
};

/**
 * @param {boolean} [defer] defer callback to the next paint (Use this if you see undelivered notifications error)
 * @param {boolean} [observeBlockSize]
 * @param {boolean} [observeInlineSize]
 * @param {number | undefined | any} [initialBlockSize]
 * @param {number | undefined | any} [initialInlineSize]
 * @return {[ResizeObserverSize, import('react').RefCallback]}
 */
export default function useResizeObserver({
  defer = false,
  observeBlockSize = false,
  observeInlineSize = false,
  initialBlockSize = 0,
  initialInlineSize = 0,
} = {}) {
  const { refCallback, subscribe, getSnapshot } = useObserve({
    defer,
    observeBlockSize,
    observeInlineSize,
    initialBlockSize,
    initialInlineSize,
  });
  const size = useSyncExternalStore(subscribe, getSnapshot);
  useDebugValue(size, (sizeJson) => JSON.parse(sizeJson));

  return useMemo(() => [JSON.parse(size), refCallback], [refCallback, size]);
}
