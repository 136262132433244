import { useEffect, useState } from 'react';
import { isNil } from 'lodash';
import { RESIDENTIAL_PROPERTY_TYPE, RESIDENTIAL_INCOME_PROPERTY_TYPE } from 'components/constants';
import Filter from './Filter';

export default function ListingTypeFilter({ filters, setFilters }) {
  const [propertyTypeFilter, setPropertyTypeFilter] = useState(filters.propertyType);

  useEffect(() => {
    setPropertyTypeFilter(filters.propertyType);
  }, [filters.propertyType]);

  const onApply = (close) => {
    setFilters({ ...filters, propertyType: propertyTypeFilter });
    close();
  };

  const onReset = () => {
    setFilters({ ...filters, propertyType: [] });
  };

  const filtered = !!filters.propertyType;
  let label = 'Listing Type';
  if (filtered) {
    label = filters.propertyType === RESIDENTIAL_PROPERTY_TYPE ? 'Residential' : 'Residential Income';
  }

  return (
    <Filter label={label} onApply={onApply} onReset={onReset} filtered={filtered}>
      <fieldset>
        <div>
          <input
            checked={isNil(propertyTypeFilter)}
            className="mr-4 mb-6"
            id="all-checkbox"
            name="listingType"
            onChange={() => setPropertyTypeFilter(null)}
            type="radio"
          />
          <label className="font-light" htmlFor="all-checkbox">All</label>
        </div>
        <div>
          <input
            checked={propertyTypeFilter === RESIDENTIAL_PROPERTY_TYPE}
            className="mr-4 mb-6"
            id="residential-checkbox"
            name="listingType"
            onChange={() => setPropertyTypeFilter(RESIDENTIAL_PROPERTY_TYPE)}
            type="radio"
          />
          <label className="font-light" htmlFor="residential-checkbox">Residential</label>
        </div>
        <div>
          <input
            checked={propertyTypeFilter === RESIDENTIAL_INCOME_PROPERTY_TYPE}
            className="mr-4"
            id="multi-family-checkbox"
            name="listingType"
            onChange={() => setPropertyTypeFilter(RESIDENTIAL_INCOME_PROPERTY_TYPE)}
            type="radio"
          />
          <label className="font-light" htmlFor="multi-family-checkbox">Residential Income</label>
        </div>
      </fieldset>
    </Filter>
  );
}
