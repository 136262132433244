import cx from 'classnames';

function LocationPinPath({ punchHole = false, ...props }) {
  let data = 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z';
  if (punchHole) {
    data += 'm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z';
  }

  return <path {...props} d={data} />;
}

function EmptyMarker({
  dim,
  fill,
  strokeWidth,
  // neutral-dark
  stroke = '#191C1E',
  strokeOpacity = '0.4',
  punchHole = false,
  children,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={dim}
      width={dim}
      filter="drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))"
    >
      <clipPath id="pinClip">
        <LocationPinPath punchHole={punchHole} />
      </clipPath>
      <LocationPinPath
        punchHole={punchHole}
        clipPath="url(#pinClip)"
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        strokeWidth={strokeWidth}
        fill={fill}
      />
      {children}
    </svg>
  );
}

function MarkerInnerIcon({ As = 'path', scale = 0.4, ...props }) {
  return (
    <As
      fill="white"
      transform={`scale(${scale}) translate(0, ${Math.round(4 / scale)})`}
      transform-origin="center top"
      {...props}
    />
  );
}

export function SingleFamilyMarker({ dim, fill, strokeWidth }) {
  return (
    <EmptyMarker
      dim={dim}
      fill={fill}
      strokeWidth={strokeWidth}
    >
      <MarkerInnerIcon d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </EmptyMarker>
  );
}

export function MultiFamilyMarker({ dim, fill, strokeWidth }) {
  return (
    <EmptyMarker
      dim={dim}
      fill={fill}
      strokeWidth={strokeWidth}
    >
      <MarkerInnerIcon d="M18,20V8.35L13.65,4h-2.83L16,9.18V20H18z M22,20V6.69L19.31,4h-2.83L20,7.52V20H22z M8,4l-6,6v10h5v-5h2v5h5V10L8,4z M9,13 H7v-2h2V13z" />
    </EmptyMarker>
  );
}

export function MixedUseMarker({ dim, fill, strokeWidth }) {
  return (
    <EmptyMarker
      dim={dim}
      fill={fill}
      strokeWidth={strokeWidth}
    >
      <MarkerInnerIcon d="M17,11V3H7v4H3v14h8v-4h2v4h8V11H17z M7,19H5v-2h2V19z M7,15H5v-2h2V15z M7,11H5V9h2V11z M11,15H9v-2h2V15z M11,11H9V9h2 V11z M11,7H9V5h2V7z M15,15h-2v-2h2V15z M15,11h-2V9h2V11z M15,7h-2V5h2V7z M19,19h-2v-2h2V19z M19,15h-2v-2h2V15z" />
    </EmptyMarker>
  );
}

export function GenericMarker({ dim, fill, strokeWidth }) {
  return (
    <EmptyMarker
      dim={dim}
      fill={fill}
      strokeWidth={strokeWidth}
    >
      <MarkerInnerIcon As="circle" cx="12" cy="12" r="6.25" />
    </EmptyMarker>
  );
}

export function CancelMarker({ dim, fill, strokeWidth }) {
  return (
    <EmptyMarker
      dim={dim}
      fill={fill}
      strokeWidth={strokeWidth}
    >
      <MarkerInnerIcon d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
    </EmptyMarker>
  );
}

export function PunchHoleMarker({ dim, fill, strokeWidth }) {
  return (
    <EmptyMarker punchHole dim={dim} fill={fill} strokeWidth={strokeWidth} stroke="white" strokeOpacity="1" />
  );
}

/**
 * Center a marker (children prop), on its anchor. The marker should be a marker icon defined in this file.
 */
export function CenterOnAnchor({ className, children }) {
  // 5/12 = 1/2 - (1 - 5/6)/2
  //      = 1/2 bounding box height - (1 - marker height / bounding box height)/2
  // note that stroke width of the icon doesn't affect the offset
  // because all icons in this file are clipped to simulate an inner stroke effect
  return (
    <div className={cx('absolute translate-y-[calc(5/12*-100%)]', className)}>
      {children}
    </div>
  );
}
