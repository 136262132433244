import { Fragment } from 'react';
import { Tab } from '@headlessui/react';
import { capitalize } from 'lodash';
import General from './pages/General';
import Alerts from './pages/Alerts';
import Buttons from './pages/Buttons';
import Chips from './pages/Chips';
import IndicatorBadges from './pages/IndicatorBadges';

const tabClasses = (isActive) => `mr-2 px-6 py-1 bg-white border-l border-t border-r rounded-t-md${isActive ? ' font-semibold' : ''}`;
const TABS = {
  general: <General />,
  alert: <Alerts />,
  button: <Buttons />,
  chip: <Chips />,
  'indicator badge': <IndicatorBadges />,
};

export default function Design() {
  return (
    <div className="bg-gray-100 min-h-screen p-6">
      <Tab.Group>
        <Tab.List className="px-4 pt-8">
          {Object.keys(TABS).map((t, index) => (
            <Tab key={index} as={Fragment}>
              {({ selected }) => (
                <button className={tabClasses(selected)}>{capitalize(t)}</button>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="bg-white p-8 border rounded-xl">
          {Object.values(TABS).map((component, index) => (
            <Tab.Panel key={index}>
              {component}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
