import Model from 'components/dcf/Model';
import { Navigate, Route, Routes, useOutletContext } from 'react-router-dom';
import Dashboard from 'components/dashboard/Dashboard';
import { EmbeddedHistory as History } from 'components/property/History';
import ListingDetails from 'components/listing/Details';
import Overview from 'components/property/Overview';
import PortfolioDealOverview from 'components/PortfolioDeal/PortfolioDealOverView';
import PortfolioDealSummary from 'components/PortfolioDeal/PortfolioDealSummary';
import PortfolioDealDemographics from 'components/PortfolioDeal/PortfolioDealDemographics';
import PortfolioDealSensitivities from 'components/PortfolioDeal/PortfolioDealSensitivities';
import PortfolioLayout from 'components/property/PortfolioLayout';
import { EmbeddedAssessorDetails as AssessorDetails } from 'components/property/AssessorDetails';
import PropertyLayout from 'components/property/PropertyLayout';
import { EmbeddedRentComps as RentComps } from 'components/property/RentComps';
import { EmbeddedSaleComps as SaleComps } from 'components/property/SaleComps';
import SubdivisionDetail from 'components/Subdivision/SubdivisionDetail';
import { useFetchSubdivisionQuery } from 'redux/subdivisionApiSlice';
import { EmbeddedNeighborhood as Neighborhood } from 'components/property/Neighborhood';
import { useShowPortfolioLayout } from './hooks';

export default function DealContext() {
  const context = useOutletContext();
  const { data: { deal, subdivision: subdivisionData } } = context;
  const subdivisionId = subdivisionData?.id;
  const showPortfolioLayout = useShowPortfolioLayout();

  const { currentData: subdivision } = useFetchSubdivisionQuery(subdivisionId, { skip: !subdivisionId });

  return (
    <Routes>
      {showPortfolioLayout ? (
        <Route path="/" element={<PortfolioLayout />}>
          <Route path="/" element={<PortfolioDealOverview context={context} />} />
          <Route path="/demographics" element={<PortfolioDealDemographics context={context} />} />
          <Route path="/sensitivities" element={<PortfolioDealSensitivities context={context} />} />
          {subdivisionId && <Route path="/subdivision" element={<SubdivisionDetail subdivision={subdivision} />} />}
          <Route path="*" element={<Navigate replace to=".." />} />
        </Route>
      ) : (
        <>
          <Route path="/" element={<PropertyLayout context={context} />}>
            <Route path="/" element={<Overview context={context} />} />
            <Route path="/neighborhood" element={<Neighborhood context={context} />} />
            <Route path="/history" element={<History context={context} />} />
            <Route path="/listing_details" element={<ListingDetails context={context} />} />
            <Route path="/assessor_details" element={<AssessorDetails context={context} />} />
            {subdivisionId && <Route path="/subdivision" element={<SubdivisionDetail subdivision={subdivision} />} />}
          </Route>
          <Route path="/model" element={<Model context={context} />} />
          <Route path="/rent_comps" element={<RentComps context={context} />} />
          <Route path="/sale_comps" element={<SaleComps context={context} />} />
          <Route path="*" element={<Navigate replace to=".." />} />
        </>
      )}
      {deal?.modellingMethod === 'combined' ? (
        <Route path="/model" element={<Model context={context} />} />
      ) : (
        <Route path="/model" element={<PortfolioDealSummary context={context} />} />
      )}
      <Route path="/workflow/*" element={<Dashboard context={context} />} />
    </Routes>
  );
}
