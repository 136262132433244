import { createContext, useCallback, useContext, useEffect, useId, useMemo, useRef, useState } from 'react';
import { getFlatSubtree, makeNode, nodeIdKey, nodePathKey, nodeValueKey, removeNode, treeKey, upsertNode } from './util';

const contextDefaultValue = Object.freeze([]);
const TreeContext = createContext(contextDefaultValue);
TreeContext.displayName = 'TreeContext';

/**
 * @param {boolean} [root] Set to tree to ignore any ancestor {@link TreeContext}
 */
const useTreeContext = (root = false) => {
  const ctx = useContext(TreeContext);
  return root ? contextDefaultValue : ctx;
};

/**
 * @param {string} [nodeId]
 * @return {string}
 */
const useResolvedNodeId = (nodeId) => {
  const generatedNodeId = useId();
  return nodeId ?? generatedNodeId;
};

const useResolvedNodePath = (parentNode, nodeId) => {
  const parentNodePath = parentNode?.[nodePathKey];
  return useMemo(() => (
    [...(parentNodePath ?? []), nodeId]
  ), [nodeId, parentNodePath]);
};

export const useNodeState = () => {
  const [
    {
      [nodePathKey]: nodePath,
      [treeKey]: { [nodeValueKey]: nodeState },
    },
    setState,
  ] = useContext(TreeContext);
  const setNodeState = useCallback((value) => {
    upsertNode(setState, nodePath, value);
  }, [nodePath, setState]);

  return useMemo(() => [nodeState, setNodeState], [nodeState, setNodeState]);
};

export const useSubtree = () => {
  const [{ [treeKey]: subtree, [nodeIdKey]: subtreeRootNodeId }] = useContext(TreeContext);

  return useMemo(() => ({
    subtree() {
      return subtree;
    },
    flatNodes() {
      return getFlatSubtree(subtree, subtreeRootNodeId);
    },
  }), [subtree, subtreeRootNodeId]);
};

/**
 * @return {string[]}
 */
export const useNodePath = () => {
  const [{ [nodePathKey]: nodePath }] = useContext(TreeContext);
  return nodePath;
};

/**
 * @param {string | null | undefined} [nodeId]
 * @param {any} [value]
 * @param {boolean} [root]
 * @param {import('react').ReactNode} children
 */
export default function TreeProvider({ nodeId, value, root = false, children }) {
  const [parentNode, setParentNode] = useTreeContext(root);

  const resolvedNodeId = useResolvedNodeId(nodeId);
  const nodePath = useResolvedNodePath(parentNode, resolvedNodeId);
  const [node, setNode] = useState(() => {
    if (parentNode) {
      // only initialize at root
      return null;
    }

    return { [treeKey]: { [resolvedNodeId]: makeNode(value) } };
  });

  useEffect(() => {
    upsertNode(setParentNode ?? setNode, nodePath, value);
  }, [value, nodePath, setParentNode]);

  useEffect(() => (
    () => {
      removeNode(setParentNode ?? setNode, nodePath);
    }
  ), [nodePath, setParentNode]);

  const subtree = (parentNode ?? node)[treeKey][resolvedNodeId];
  const valueRef = useRef(value);
  valueRef.current = value;
  const contextValue = useMemo(() => (
    [
      {
        [nodeIdKey]: resolvedNodeId,
        [nodePathKey]: nodePath,
        [treeKey]: subtree ?? makeNode(valueRef.current),
      },
      setParentNode ?? setNode,
    ]
  ), [nodePath, resolvedNodeId, setParentNode, subtree]);

  return (
    <TreeContext.Provider value={contextValue}>
      {children}
    </TreeContext.Provider>
  );
}
