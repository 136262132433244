import { useMemo } from 'react';
import { addDays, parseISO } from 'date-fns';
import { meanBy, sumBy } from 'lodash';
import { formatCurrency, formatCurrencyAbbreviated, formatPercentage } from 'components/utils';
import ReturnMetricBoxes from '../Shared/ReturnMetricBoxes';
import { metricFunc } from './offer.utils';

const offerExpiringFunc = (offers, asOfDate) => offers.filter(({ attributes: { acceptanceDate, offerExpirationDate } }) => {
  const offerExpiringSoon = offerExpirationDate && !acceptanceDate && (parseISO(offerExpirationDate) <= addDays(asOfDate, 3));
  return offerExpiringSoon;
});

const metricsData = [
  { label: 'Expiring Offers - 3 Days', statFunc: (offers, asOfDate) => offerExpiringFunc(offers, asOfDate).length },
  { label: 'Total Value of Expiring Offers', statFunc: (offers, asOfDate) => sumBy(offerExpiringFunc(offers, asOfDate), 'attributes.offerPrice'), formatter: formatCurrencyAbbreviated },
  { label: 'Avg. Yield', statFunc: (offers, asOfDate) => sumBy(offerExpiringFunc(offers, asOfDate), 'attributes.netYield'), formatter: formatPercentage },
  { label: 'Avg. Offer Price', statFunc: (offers, asOfDate) => meanBy(offerExpiringFunc(offers, asOfDate), 'attributes.offerPrice'), formatter: formatCurrencyAbbreviated },
  { label: 'Avg. Rent', statFunc: (offers, asOfDate) => meanBy(offerExpiringFunc(offers, asOfDate), 'attributes.underwritingRent'), formatter: formatCurrency },
];

export default function OfferExpirations({ asOfDate, lastWeekDate, lastMonthDate, filteredOffers, oneWeekAgoFilteredOffers, oneMonthAgoFilteredOffers }) {
  const metrics = useMemo(
    () => metricsData.map(({ label, formatter, statFunc }) => metricFunc(label, statFunc, asOfDate, lastWeekDate, lastMonthDate, filteredOffers, oneWeekAgoFilteredOffers, oneMonthAgoFilteredOffers, formatter)),
    [filteredOffers],
  );

  return (
    <div>
      <ReturnMetricBoxes metrics={metrics} />
    </div>
  );
}
