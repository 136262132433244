/* eslint-disable react/jsx-boolean-value */
import { annualizeMonthlyReturns } from '../finance';
import Input, { PERCENT_TYPE } from '../Input';
import { formatCurrency } from '../utils';
import { TH, TR } from '../Table';
import { BelowTheLineExpenseTable } from './ItemizedExpenseTable';
import SectionLabel from './SectionLabel';

export default function RatioExpenseTable({ cashFlows, dcfParams, onChange }) {
  const { holdPeriod, expenseRatio } = dcfParams;

  const expenses = annualizeMonthlyReturns(cashFlows.expenses.totalOperatingExpenses);
  const data = [
    '% Effective Gross Revenue',
    <Input
      name="expenseRatio"
      value={expenseRatio}
      type={PERCENT_TYPE}
      onChange={onChange}
      width="w-full"
    />,
    formatCurrency(expenses[0] / 12),
    formatCurrency(expenses[0]),
  ];
  data.push(...new Array(1 + 2).fill(null));

  return (
    <table className="min-w-full divide-y divide-gray-200 bg-white">
      <thead className="bg-gray-50 border-b-2">
        <tr>
          <TH value="Description" />
          <TH value="Input Methodology" />
          <TH value="Input Value" />
          <TH value="$ / Month" />
          <TH value="$ / Year" />
          <TH value="" colSpan={holdPeriod + 2} />
        </tr>
      </thead>
      <tbody>
        <TR data={data} label="Expense Ratio-Based" />
      </tbody>

      <thead className="bg-gray-50">
        <tr>
          <TH
            value={<SectionLabel className="mt-12 text-left" text="Below the line Expenses" />}
            colSpan={holdPeriod + 7}
          />
        </tr>
      </thead>
      <BelowTheLineExpenseTable
        isModelParameter
        includeHeader
        opExY1MonthlyTotal={expenses[0] / 12}
        dcfParams={dcfParams}
        egr={cashFlows.revenue.effectiveGrossRevenues}
        onChange={onChange}
        canEditPortfolio={true}
      />
    </table>
  );
}
