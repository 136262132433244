import { useState } from 'react';
import Alert from '../Alert';
import { FormField } from '../Form';
import { camelCaseKeys, formatCurrency, parseEventValue, snakeCaseKeys } from '../utils';
import { parcelPath, propertyUpdatePath } from '../routes';
import _, { cloneDeep, find, pick, set, sum, values, map, startCase, camelCase } from 'lodash';

function InfoField({ className, href, label, value }) {
  return (
    <div className={`flex flex-row justify-center gap-x-4 py-1 ${className}`}>
      <label className="my-auto text-xs font-medium text-tertiary-darker text-right w-1/3">{label.toUpperCase()}</label>
      <div className="w-2/3 pr-5 text-right">
        {href ? <a className="underline text-tertiary" href={href} target="_blank">{value}</a> : value}
      </div>
    </div>
  );
}

export default function PropertyVerification({ property, financials, setProperty, setPropertyVerified, handleStepChange, currentStep, parcels }) {
  const initialState = cloneDeep(camelCaseKeys(property));
  const [propertyState, setPropertyState] = useState(initialState);
  const [alert, setAlert] = useState(null);
  const [propertyStep, setPropertyStep] = useState(currentStep && currentStep === 3 ? 3 : 0)
  const year = new Date().getFullYear();

  const onChange = (e) => {
    const path = e.target.name;
    const value = parseEventValue(e);
    const updatedState = cloneDeep(propertyState);
    set(updatedState, path, value);
    setPropertyState(updatedState);
  };

  let onContinue;
  handleStepChange ? onContinue = () => handleStepChange(1)
  : onContinue = () => setPropertyVerified(true);

  const numberOfUnits = sum(values(pick(propertyState, ['numberOfStudio', 'numberOf1Br', 'numberOf2Br', 'numberOf3Br', 'numberOf4Br'])));

  const onUpdateProperty = () => {
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    setAlert(null);
    const updateBody = cloneDeep(propertyState);
    updateBody.numberOfUnits = numberOfUnits || null;
    if (!handleStepChange) {
      fetch(propertyUpdatePath(updateBody), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
        },
        body: JSON.stringify(snakeCaseKeys(updateBody)),
      }).then(response => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      }).then(json => {
        setProperty(camelCaseKeys(json));
        setAlert({text: 'Updates saved', type: 'success'});
        setTimeout(() => setAlert(null), 4000);
      }).catch(error => {
        setAlert({text: `Save Failed: ${error.message}`, type: 'danger'})
      });
    } else {
      setProperty(updateBody)
      handleStepChange(5)
    }

  };

  const handleConfirm = () => {
    if (propertyStep === 3) {
      onUpdateProperty()
      handleStepChange ? handleStepChange(5) : null
    } else {
      handleStepChange ? handleStepChange(currentStep + 1) : null
      setPropertyStep(propertyStep + 1)
    }
  }

  const handleBack = () => {
    handleStepChange ? handleStepChange(currentStep - 1) : null
    setPropertyStep(propertyStep - 1)
  }

  let content = null;
  if (propertyStep === 0) {
    content = <>
      <h2 className="mt-8 mb-4 text-sm font-bold">Location</h2>
      <div className="md:container md:mx-auto rounded">
        <InfoField label="Street Address" value={propertyState.address || ''} />
        <InfoField className="mt-4" label="City" value={`${propertyState.city || ''}, ${propertyState.state || ''} ${propertyState.zipCode || ''}`} />
        {
          parcels.map(parcel => {
            return <InfoField className="mt-4" label="APN" href={parcel && parcelPath(parcel)} value={parcel.apn} key={parcel.apn} />
          })
        }
        <InfoField className="mt-4" label="Property Type" value={propertyState.propertyType} />
        <InfoField className="mt-4" label="Townership" value={propertyState.township} />
        <InfoField className="mt-4" label="Neighborhood" value={propertyState.neighborhood} />
        <InfoField className="mt-4" label="School District" value={propertyState.schoolDistrict} />
      </div>
    </>
  } else if (propertyStep === 1) {
    content = <>
      <h2 className="mt-8 mb-4 text-sm font-bold">Unit Mix</h2>
      <div className="md:container md:mx-auto">
        <InfoField label="Number of Units" value={numberOfUnits || propertyState.numberOfUnits} />
        <FormField className="mt-8" label="Number of Studio" name="numberOfStudio" value={propertyState.numberOfStudio || ''} min="0" onChange={onChange} />
        <FormField className="mt-8" label="Number of 1 BR" name="numberOf1Br" value={propertyState.numberOf1Br || ''} min="0" onChange={onChange} />
        <FormField className="mt-8" label="Number of 2 BR" name="numberOf2Br" value={propertyState.numberOf2Br || ''} min="0" onChange={onChange} />
        <FormField className="mt-8" label="Number of 3 BR" name="numberOf3Br" value={propertyState.numberOf3Br || ''} min="0" onChange={onChange} />
        <FormField className="mt-8" label="Number of 4 BR" name="numberOf4Br" value={propertyState.numberOf4Br || ''} min="0" onChange={onChange} />
      </div>
    </>
  } else if (propertyStep === 2) {
    content = <>
      <h2 className="mt-8 mb-4 text-sm font-bold">Property Details</h2>
      <div className="md:container md:mx-auto">
        <FormField label="Year Built" name="yearBuilt" value={propertyState.yearBuilt || ''} min="1700" max={year} onChange={onChange} />
        <FormField className="mt-8" label="Year Renovated" name="yearRenovated" value={propertyState.yearRenovated || ''} min="1900" max={year} onChange={onChange} />
        <FormField className="mt-8" label="Lot Size (sq ft.)" name="landAreaSf" value={propertyState.landAreaSf || ''} min="0" onChange={onChange} />
        <FormField className="mt-8" label="Number of Floors" name="numberOfFloors" value={propertyState.numberOfFloors || ''} min="0" onChange={onChange} />
        <FormField className="mt-8" label="Rentable Building Area" name="rentableBuildingArea" value={propertyState.rentableBuildingArea || ''} min="0" onChange={onChange} />
        <FormField className="mt-8" label="Number of Parking Spaces" name="numberOfParkingSpaces" value={propertyState.numberOfParkingSpaces || ''} min="0" onChange={onChange} />
      </div>
    </>
  } else if (propertyStep === 3) {
    content = <>
      <h2 className="mt-8 mb-4 text-sm font-bold">Financials</h2>
      <div className="md:container md:mx-auto">
        {
          financials ?
          _.map(financials, (v,k) => {
            return (
              <InfoField className="mt-8" label={startCase(camelCase(k))} value={formatCurrency(v) || ''} key={k + v.toString()} />
            )
          })
          : null
        }
      </div>
    </>
  }

  return (
    <>
      <h1 className="mt-8 text-lg">{`Verify property information for ${propertyState.address}`}</h1>
      { content }
      <div className='mt-8 flex flex-row align-center justify-center'>
        {
          propertyStep != 0 ?
          <button
          className="px-4 py-2 text-gray border-2 border-gray-100 rounded m-2"
          onClick={() => handleBack()}
          >
            Back
          </button>
          : null
        }
        <button
        className={"px-4 py-2 text-gray border-2 border-gray-100 rounded m-2"}
        onClick={handleConfirm}
        >
          Next
        </button>
      </div>

      <div className="mt-8 flex flex-row justify-center gap-x-4">
        {
        !handleStepChange ?
          <button
            className="px-4 py-2 rounded text-white bg-tertiary hover:bg-tertiary-lighter active:bg-tertiary-darker focus:outline-none"
            onClick={onUpdateProperty}
          >
            Update Property
          </button>
          : null
        }
        <button
          className={ handleStepChange ?
            "hidden"
          : "px-4 py-2 rounded text-white bg-tertiary hover:bg-tertiary-lighter active:bg-tertiary-darker focus:outline-none"
          }
          onClick={handleStepChange ? onUpdateProperty : onContinue}
        >
          Continue
        </button>
      </div>
      {alert && <Alert className="mt-4" {...alert} />}
    </>
  );
}
