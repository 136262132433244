import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, sortBy, uniq } from 'lodash';
import { parseISO, subMonths, subWeeks } from 'date-fns';
import { useFetchAcquisitionsQuery } from 'redux/dashboardApiSlice';
import { setMarkets, setPortfolios } from 'reducers/dashboard/filters';
import { LoadingIndicator } from 'components/icons';
import { ButtonTab, HorizontalTabs } from 'components/shared/Tabs';
import Overall from './Overall';
import NewConstruction from './NewConstruction';
import ClosingInTwoWeeks from './ClosingInTwoWeeks';
import CapitalNeeds from './CapitalNeeds';
import Historical from './Historical';
import Map from './Map';
import { NoDataMessage } from '../Shared';
import { selectFilteredAcquisitions } from '../Shared/selectors';

const FILTERS_AND_TABS_HEIGHT = 24 + 38 + 49 + 16 + 44;

const ACQUISITION_TABS = [
  { label: 'Overall', component: Overall },
  { label: 'New Construction', component: NewConstruction },
  { label: 'Closings 2 Weeks', component: ClosingInTwoWeeks },
  { label: 'Capital Needs', component: CapitalNeeds },
  { label: 'Map', component: Map },
  { label: 'Historical', component: Historical },
];

export default function AcquisitionDashboard() {
  const dispatch = useDispatch();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const ActiveComponent = ACQUISITION_TABS[activeTabIndex].component;
  const { asOfDate: asOfDateString, activeFilters: filters } = useSelector(state => state.dashboardFilter);
  const asOfDate = parseISO(asOfDateString);
  const lastWeekDate = subWeeks(asOfDate, 1);
  const lastMonthDate = subMonths(asOfDate, 1);

  const {
    acquisitions,
    filteredAcquisitions,
    oneWeekAgoFilteredAcquisitions,
    oneMonthAgoFilteredAcquisitions,
    isLoading,
  } = useFetchAcquisitionsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      acquisitions: result.data?.data,
      filteredAcquisitions: selectFilteredAcquisitions(result, filters, asOfDate),
      oneWeekAgoFilteredAcquisitions: selectFilteredAcquisitions(result, filters, lastWeekDate),
      oneMonthAgoFilteredAcquisitions: selectFilteredAcquisitions(result, filters, lastMonthDate),
    }),
  });

  useEffect(() => {
    if (acquisitions) {
      const markets = sortBy(uniq(acquisitions.map(acquisition => acquisition.attributes.property.market)));
      dispatch(setMarkets(markets));
      const portfolios = sortBy(uniq(acquisitions.map(acquisition => acquisition.attributes.portfolioName)));
      dispatch(setPortfolios(portfolios));
    }
  }, [acquisitions]);

  if (isLoading) {
    return <LoadingIndicator className="mt-16 h-12" />;
  }

  if (isEmpty(acquisitions)) {
    return <NoDataMessage />;
  }

  return (
    <div className="flex flex-col mb-16 h-full">
      <HorizontalTabs>
        {ACQUISITION_TABS.map(({ label }, index) => (
          <ButtonTab key={index} active={index === activeTabIndex} onClick={() => setActiveTabIndex(index)}>
            {label}
          </ButtonTab>
        ))}
      </HorizontalTabs>
      <div className="p-3 pb-12 overflow-y-auto" style={{ height: `calc(100vh - ${FILTERS_AND_TABS_HEIGHT}px)` }}>
        <ActiveComponent
          asOfDate={asOfDate}
          lastWeekDate={lastWeekDate}
          lastMonthDate={lastMonthDate}
          filteredAcquisitions={filteredAcquisitions}
          oneWeekAgoFilteredAcquisitions={oneWeekAgoFilteredAcquisitions}
          oneMonthAgoFilteredAcquisitions={oneMonthAgoFilteredAcquisitions}
        />
      </div>
    </div>
  );
}
