export function ArrowBack({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} viewBox="0 0 48 48" fill="currentColor">
      <path d="M24 40 8 24 24 8 26.1 10.1 13.7 22.5H40V25.5H13.7L26.1 37.9Z" />
    </svg>
  );
}

export function ArrowDropDown({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} viewBox="-10 0 48 48"><path d="M24 30 14 20.05H34Z" /></svg>
  );
}

export function Camera({ className, onClick }) {
  return (
    <svg className={className} onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1077_2165)">
        <path d="M14.5 4H9.5L7 7H4C3.46957 7 2.96086 7.21071 2.58579 7.58579C2.21071 7.96086 2 8.46957 2 9V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V9C22 8.46957 21.7893 7.96086 21.4142 7.58579C21.0391 7.21071 20.5304 7 20 7H17L14.5 4Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 16C13.6569 16 15 14.6569 15 13C15 11.3431 13.6569 10 12 10C10.3431 10 9 11.3431 9 13C9 14.6569 10.3431 16 12 16Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1077_2165">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CameraOff({ className, onClick }) {
  return (
    <svg className={className} onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1077_2191)">
        <path d="M2 2L22 22M7 7H4C3.46957 7 2.96086 7.21071 2.58579 7.58579C2.21071 7.96086 2 8.46957 2 9V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20M9.5 4H14.5L17 7H20C20.5304 7 21.0391 7.21071 21.4142 7.58579C21.7893 7.96086 22 8.46957 22 9V16.5M14.121 15.121C13.8455 15.4123 13.5143 15.6454 13.1471 15.8066C12.7799 15.9677 12.3842 16.0536 11.9832 16.0592C11.5823 16.0648 11.1843 15.9899 10.8128 15.839C10.4412 15.6882 10.1037 15.4644 9.82019 15.1808C9.53665 14.8973 9.31283 14.5598 9.16196 14.1882C9.01109 13.8167 8.93623 13.4187 8.9418 13.0178C8.94738 12.6168 9.03328 12.2211 9.19442 11.8539C9.35556 11.4867 9.58868 11.1555 9.88 10.88" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1077_2191">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function Check({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} height="20" width="20" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
    </svg>
  );
}

export function CheckCircle({ className, onClick, filled = false }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      {filled ? (
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
      ) : (
        <path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      )}
    </svg>
  );
}

export function Chevron({ className, direction, onClick }) {
  let d;
  if (direction === 'down') {
    d = 'M19 9l-7 7-7-7';
  } else if (direction === 'up') {
    d = 'M5 15l7-7 7 7';
  } else if (direction === 'left') {
    d = 'M15 19l-7-7 7-7';
  } else if (direction === 'right') {
    d = 'M9 5l7 7-7 7';
  } else {
    throw new Error('Must supply direction property of value: left, right, up or down');
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={d} />
    </svg>
  );
}

export function Config({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} viewBox="0 0 14 14" fill="none" stroke="currentColor">
      <path strokeWidth="0" d="M0.25 10.75V12.25H4.75V10.75H0.25ZM0.25 1.75V3.25H7.75V1.75H0.25ZM7.75 13.75V12.25H13.75V10.75H7.75V9.25H6.25V13.75H7.75ZM3.25 4.75V6.25H0.25V7.75H3.25V9.25H4.75V4.75H3.25ZM13.75 7.75V6.25H6.25V7.75H13.75ZM9.25 4.75H10.75V3.25H13.75V1.75H10.75V0.25H9.25V4.75Z" fill="currentColor" />
    </svg>
  );
}

export function Dots({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
    </svg>
  );
}

export function Download({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
    </svg>
  );
}

export function Link({ className, onClick }) {
  return (
    <svg className={className} onClick={onClick} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.8333 13.8333H2.16667V2.16667H8V0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8H13.8333V13.8333ZM9.66667 0.5V2.16667H12.6583L4.46667 10.3583L5.64167 11.5333L13.8333 3.34167V6.33333H15.5V0.5H9.66667Z" fill="#9DA3AE" />
    </svg>
  );
}

export function LoadingIndicator({ className }) {
  return (
    <svg className={`animate-spin ${className}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
    </svg>
  );
}

export function Mail({ className, onClick, filled = false }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      {filled ? (
        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
      ) : (
        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z" />
      )}
    </svg>
  );
}

export function Pencil({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} viewBox="0 0 24 24" fill="currentColor">
      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
    </svg>
  );
}

export function Plus({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
    </svg>
  );
}

export function PlusCircle({ className, onClick, filled = false }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      {filled ? (
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
      ) : (
        <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
      )}
    </svg>
  );
}

export function MinusCircle({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M5 9V11H15V9H5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" />
    </svg>
  );
}

export function Refresh({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={onClick}>
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
    </svg>
  );
}

export function Search({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
    </svg>
  );
}

export function X({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} viewBox="0 0 20 20" fill="currentColor">
      <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
    </svg>
  );
}

export function Output({ className, onClick, color }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12H13V10H0V12ZM0 7H10V5H0V7ZM0 0V2H13V0H0ZM18 9.59L14.42 6L18 2.41L16.59 1L11.59 6L16.59 11L18 9.59Z"
        fill={color}
      />
    </svg>
  );
}

export function AvatarCircle({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 3C11.66 3 13 4.34 13 6C13 7.66 11.66 9 10 9C8.34 9 7 7.66 7 6C7 4.34 8.34 3 10 3ZM10 17.2C7.5 17.2 5.29 15.92 4 13.98C4.03 11.99 8 10.9 10 10.9C11.99 10.9 15.97 11.99 16 13.98C14.71 15.92 12.5 17.2 10 17.2Z" fill="#69829B" />
    </svg>
  );
}

export function SelectorIcon({ className }) {
  return (
    <svg className={className} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.99994 1.88667L6.11328 4L7.05328 3.06L3.99994 0L0.939941 3.06L1.88661 4L3.99994 1.88667ZM3.99994 10.1133L1.88661 8L0.946608 8.94L3.99994 12L7.05994 8.94L6.11328 8L3.99994 10.1133Z" fill="black" fillOpacity="0.36" />
    </svg>
  );
}

export function LocationMarker() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100px" viewBox="0 0 24 24" width="100px" fill="rgba(0, 0, 0, 0.12)">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20 1v3h3v2h-3v3h-2V6h-3V4h3V1h2zm-8 12c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm2-9.75V7h3v3h2.92c.05.39.08.79.08 1.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 6.22 7.8 3 12 3c.68 0 1.35.08 2 .25z" />
    </svg>
  );
}

export function Circle({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="12"
      width="12"
      viewBox="0 0 12 12"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <circle cx="6" cy="6" r="5" />
    </svg>
  );
}

export function Exclamation({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 3 24 18"
      fill="currentColor"
    >
      <path d="M10 3h4v12h-4z" />
      <circle cx="12" cy="19" r="2" />
    </svg>
  );
}

export function Event({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z" />
    </svg>
  );
}

export function EventAvailable({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M16.53 11.06L15.47 10l-4.88 4.88-2.12-2.12-1.06 1.06L10.59 17l5.94-5.94zM19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z" />
    </svg>
  );
}

export function ExclamationCircle({ className, onClick, fillColor = 'currentColor', filled = false }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      fill={fillColor}
    >
      {filled ? (
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
      ) : (
        <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      )}
    </svg>
  );
}

export function Trash({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
    </svg>
  );
}

export function TrashOutline({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z" fill="#44464F" />
    </svg>
  );
}

export function Paperclip({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z" />
    </svg>
  );
}

export function ContentCopy({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
    </svg>
  );
}

export function MenuDots({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z" fill="#43483F" />
    </svg>
  );
}

export function Phone({ className }) {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.36 3.33333C4.4 3.92667 4.5 4.50667 4.66 5.06L3.86 5.86C3.58667 5.06 3.41333 4.21333 3.35333 3.33333H4.36ZM10.9333 11.3467C11.5 11.5067 12.08 11.6067 12.6667 11.6467V12.64C11.7867 12.58 10.94 12.4067 10.1333 12.14L10.9333 11.3467ZM5 2H2.66667C2.3 2 2 2.3 2 2.66667C2 8.92667 7.07333 14 13.3333 14C13.7 14 14 13.7 14 13.3333V11.0067C14 10.64 13.7 10.34 13.3333 10.34C12.5067 10.34 11.7 10.2067 10.9533 9.96C10.8867 9.93333 10.8133 9.92667 10.7467 9.92667C10.5733 9.92667 10.4067 9.99333 10.2733 10.12L8.80667 11.5867C6.92 10.62 5.37333 9.08 4.41333 7.19333L5.88 5.72667C6.06667 5.54 6.12 5.28 6.04667 5.04667C5.8 4.3 5.66667 3.5 5.66667 2.66667C5.66667 2.3 5.36667 2 5 2Z" fill="#43483F" />
    </svg>
  );
}

export function PhotoLibrary({ filled = true, className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
      <path d="M0 0h24v24H0z" fill="none" />
      {filled ? (
        <path d="M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71L16 11l4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z" fill="currentColor" />
      ) : (
        <path d="M20 4v12H8V4h12m0-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 9.67l1.69 2.26 2.48-3.1L19 15H9zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z" />
      )}
    </svg>
  );
}

export function Arrow({ className, onClick, direction, fill = '#000000' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      viewBox="0 0 24 24"
      width="24px"
      fill={fill}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      {
        direction === 'up' ? (<path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />) : (<path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />)
      }
    </svg>
  );
}

export function ZoomIn({ className }) {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.755 14.2549H14.965L14.685 13.9849C15.665 12.8449 16.255 11.3649 16.255 9.75488C16.255 6.16488 13.345 3.25488 9.755 3.25488C6.165 3.25488 3.255 6.16488 3.255 9.75488C3.255 13.3449 6.165 16.2549 9.755 16.2549C11.365 16.2549 12.845 15.6649 13.985 14.6849L14.255 14.9649V15.7549L19.255 20.7449L20.745 19.2549L15.755 14.2549ZM9.755 14.2549C7.26501 14.2549 5.255 12.2449 5.255 9.75488C5.255 7.26488 7.26501 5.25488 9.755 5.25488C12.245 5.25488 14.255 7.26488 14.255 9.75488C14.255 12.2449 12.245 14.2549 9.755 14.2549ZM10.255 7.25488H9.255V9.25488H7.255V10.2549H9.255V12.2549H10.255V10.2549H12.255V9.25488H10.255V7.25488Z" fill="black" />
    </svg>
  );
}

export function ExternalLink({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
    </svg>
  );
}

export function CircleExclamation({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  );
}

export function CircleInfo({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} viewBox="0 0 24 24" fill="#000000">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    </svg>
  );
}

export function CircleEmpty({ className, onClick }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
      <g><rect fill="none" height="24" width="24" /></g>
      <g><path d="M12,2C6.47,2,2,6.47,2,12c0,5.53,4.47,10,10,10s10-4.47,10-10C22,6.47,17.53,2,12,2z M12,20c-4.42,0-8-3.58-8-8 c0-4.42,3.58-8,8-8s8,3.58,8,8C20,16.42,16.42,20,12,20z" /></g>
    </svg>
  );
}

export function DotsVertical({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="4"
      height="16"
      viewBox="0 0 4 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z" fill="#444749" />
    </svg>

  );
}

export function Fullscreen({ className, onClick }) {
  return (
    <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M5 19v-5h2v3h3v2Zm0-9V5h5v2H7v3Zm9 9v-2h3v-3h2v5Zm3-9V7h-3V5h5v5Z" />
    </svg>
  );
}

export function Map({ className, filled, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      {filled ? (
        <path d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM15 19l-6-2.11V5l6 2.11V19z" />
      ) : (
        <path d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM10 5.47l4 1.4v11.66l-4-1.4V5.47zm-5 .99l3-1.01v11.7l-3 1.16V6.46zm14 11.08l-3 1.01V6.86l3-1.16v11.84z" />
      )}
    </svg>
  );
}

export function Filter({ className, filled, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      enableBackground="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#FFFFFF"
    >
      <g>
        <path d="M0,0h24 M24,24H0" fill="none" />
        {filled ? (
          <path d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" />
        ) : (
          <path d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" />
        )}
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
    </svg>
  );
}

export function Folder({ className, filled, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      {filled ? (
        <path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
      ) : (
        <path d="M9.17 6l2 2H20v10H4V6h5.17M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
      )}
    </svg>
  );
}

export function BarChart({ className, onClick, fill = '#FFFFFF' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      height="24"
      viewBox="0 -960 800 960"
      width="20"
      fill={fill}
    >
      <path d="M180-120q-24 0-42-18t-18-42v-660h60v660h660v60H180Zm75-135v-334h119v334H255Zm198 0v-540h119v540H453Zm194 0v-170h119v170H647Z" />
    </svg>
  );
}

export function User({ className, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      viewBox="0 0 20 20"
      fill="white"
    >
      <path
        fillRule="evenodd"
        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function QuestionMarker({ className }) {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.01 16C11.74 16 11.49 15.9 11.3 15.71C11.1 15.51 11.01 15.28 11.01 15C11 14.45 11.44 14.01 11.99 14C12 14 12 14 12.01 14C12.29 14 12.52 14.1 12.72 14.29C12.9 14.48 13 14.72 13 14.99C13 15.26 12.9 15.5 12.71 15.7C12.52 15.9 12.28 16 12.01 16ZM11.13 12.34C11.13 11.89 11.23 11.5 11.42 11.18C11.61 10.85 11.95 10.48 12.42 10.06C12.7 9.81 12.9 9.59 13.03 9.4C13.16 9.21 13.22 9 13.22 8.76C13.22 8.47 13.11 8.23 12.9 8.02C12.69 7.82 12.4 7.72 12.05 7.72C11.68 7.72 11.31 7.82 11.09 8.02C10.88 8.22 10.69 8.47 10.69 9H9C9 7.99 9.46 7.27 9.97 6.79C10.53 6.28 11.25 6 12 6C12.59 6 13.11 6.12 13.57 6.35C14.03 6.58 14.36 6.9 14.62 7.31C14.88 7.72 15 8.17 15 8.66C15 9.15 14.9 9.56 14.69 9.91C14.48 10.26 14.21 10.62 13.8 11C13.48 11.3 13.27 11.56 13.15 11.77C13.03 11.98 12.97 12.26 12.97 12.58V13H11.12V12.34H11.13ZM18 10.2C18 6.57 15.35 4 12 4C8.65 4 6 6.57 6 10.2C6 12.54 7.95 15.64 12 19.34C16.05 15.64 18 12.54 18 10.2ZM12 2C16.2 2 20 5.22 20 10.2C20 13.52 17.33 17.45 12 22C6.67 17.45 4 13.52 4 10.2C4 5.22 7.8 2 12 2Z" fill="black" />
    </svg>
  );
}

export function Linkedin({ className, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      viewBox="0 5 1036 990"
      fill="white"
    >
      <path d="M0 120c0-33.334 11.667-60.834 35-82.5C58.333 15.833 88.667 5 126 5c36.667 0 66.333 10.666 89 32 23.333 22 35 50.666 35 86 0 32-11.333 58.666-34 80-23.333 22-54 33-92 33h-1c-36.667 0-66.333-11-89-33S0 153.333 0 120zm13 875V327h222v668H13zm345 0h222V622c0-23.334 2.667-41.334 8-54 9.333-22.667 23.5-41.834 42.5-57.5 19-15.667 42.833-23.5 71.5-23.5 74.667 0 112 50.333 112 151v357h222V612c0-98.667-23.333-173.5-70-224.5S857.667 311 781 311c-86 0-153 37-201 111v2h-1l1-2v-95H358c1.333 21.333 2 87.666 2 199 0 111.333-.667 267.666-2 469z" />
    </svg>
  );
}

export function DrawOutlined({ className }) {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.1025 10.39L20.1625 9.33C20.9425 8.55 20.9425 7.28 20.1625 6.5L18.7525 5.09C17.9725 4.31 16.7025 4.31 15.9225 5.09L14.8625 6.15L19.1025 10.39ZM14.8625 11.81L7.6625 19H6.2525V17.59L13.4425 10.4L14.8625 11.81ZM13.4425 7.56L4.2525 16.76V21H8.4925L17.6825 11.81L13.4425 7.56ZM19.2525 17.5C19.2525 19.69 16.7125 21 14.2525 21C13.7025 21 13.2525 20.55 13.2525 20C13.2525 19.45 13.7025 19 14.2525 19C15.7925 19 17.2525 18.27 17.2525 17.5C17.2525 17.03 16.7725 16.63 16.0225 16.3L17.5025 14.82C18.5725 15.45 19.2525 16.29 19.2525 17.5ZM4.8325 13.35C3.8625 12.79 3.2525 12.06 3.2525 11C3.2525 9.2 5.1425 8.37 6.8125 7.64C7.8425 7.18 9.2525 6.56 9.2525 6C9.2525 5.59 8.4725 5 7.2525 5C5.9925 5 5.4525 5.61 5.4225 5.64C5.0725 6.05 4.4425 6.1 4.0225 5.76C3.6125 5.42 3.5325 4.81 3.8725 4.38C3.9825 4.24 5.0125 3 7.2525 3C9.4925 3 11.2525 4.32 11.2525 6C11.2525 7.87 9.3225 8.72 7.6125 9.47C6.6725 9.88 5.2525 10.5 5.2525 11C5.2525 11.31 5.6825 11.6 6.3225 11.86L4.8325 13.35Z" fill="black" />
    </svg>
  );
}

export function FilledChevron({ direction = 'down', className }) {
  let d;
  if (direction === 'down') {
    d = 'M7 10L12 15L17 10H7Z';
  } else if (direction === 'up') {
    d = 'M7 14L12 9L17 14H7Z';
  } else {
    throw new Error('Must supply direction property of value: up or down');
  }

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d={d} fill="#1F1F1F" />
    </svg>

  );
}

export function Home({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#191C1E"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
    </svg>
  );
}

export function Close({ className, onClick, fill = '#000000' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill={fill}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
    </svg>
  );
}
export function Undo({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4V0L3 5L8 10V6C11.31 6 14 8.69 14 12C14 15.31 11.31 18 8 18C4.69 18 2 15.31 2 12H0C0 16.42 3.58 20 8 20C12.42 20 16 16.42 16 12C16 7.58 12.42 4 8 4Z"
      />
    </svg>
  );
}

export function Lock({ className, onClick, fill = '#444749' }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6.58333H11.1667V4.91667C11.1667 2.61667 9.30004 0.75 7.00004 0.75C4.70004 0.75 2.83337 2.61667 2.83337 4.91667V6.58333H2.00004C1.08337 6.58333 0.333374 7.33333 0.333374 8.25V16.5833C0.333374 17.5 1.08337 18.25 2.00004 18.25H12C12.9167 18.25 13.6667 17.5 13.6667 16.5833V8.25C13.6667 7.33333 12.9167 6.58333 12 6.58333ZM4.50004 4.91667C4.50004 3.53333 5.61671 2.41667 7.00004 2.41667C8.38337 2.41667 9.50004 3.53333 9.50004 4.91667V6.58333H4.50004V4.91667ZM12 16.5833H2.00004V8.25H12V16.5833ZM7.00004 14.0833C7.91671 14.0833 8.66671 13.3333 8.66671 12.4167C8.66671 11.5 7.91671 10.75 7.00004 10.75C6.08337 10.75 5.33337 11.5 5.33337 12.4167C5.33337 13.3333 6.08337 14.0833 7.00004 14.0833Z"
        fill={fill}
      />
    </svg>
  );
}

export function LockFilled({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
    >
      <path d="M220 976q-24.75 0-42.375-17.625T160 916V482q0-24.75 17.625-42.375T220 422h70v-96q0-78.85 55.606-134.425Q401.212 136 480.106 136T614.5 191.575Q670 247.15 670 326v96h70q24.75 0 42.375 17.625T800 482v434q0 24.75-17.625 42.375T740 976H220Zm260.168-200Q512 776 534.5 753.969T557 701q0-30-22.668-54.5t-54.5-24.5Q448 622 425.5 646.5t-22.5 55q0 30.5 22.668 52.5t54.5 22ZM350 422h260v-96q0-54.167-37.882-92.083-37.883-37.917-92-37.917Q426 196 388 233.917 350 271.833 350 326v96Z" />
    </svg>
  );
}

export function Warning({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 19.5H22L11 0.5L0 19.5ZM12 16.5H10V14.5H12V16.5ZM12 12.5H10V8.5H12V12.5Z" />
    </svg>
  );
}

export function CheckboxEmpty({ className, onClick }) {
  return (
    <svg className={className} onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" fill="#002D6F" />
    </svg>
  );
}

export function CheckboxFilled({ className, onClick }) {
  return (
    <svg className={className} onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#002D6F" />
    </svg>

  );
}

export function NotificationBell({ className, filled = false, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!filled ? (<path d="M8 19.75C9.1 19.75 10 18.85 10 17.75H6C6 18.85 6.9 19.75 8 19.75ZM14 13.75V8.75C14 5.68 12.37 3.11 9.5 2.43V1.75C9.5 0.92 8.83 0.25 8 0.25C7.17 0.25 6.5 0.92 6.5 1.75V2.43C3.64 3.11 2 5.67 2 8.75V13.75L0 15.75V16.75H16V15.75L14 13.75ZM12 14.75H4V8.75C4 6.27 5.51 4.25 8 4.25C10.49 4.25 12 6.27 12 8.75V14.75Z" fill="white" />)
        : (
          <path
            d="M8 19.75C9.1 19.75 10 18.85 10 17.75H6C6 18.85 6.89 19.75 8 19.75ZM14 13.75V8.75C14 5.68 12.36 3.11 9.5 2.43V1.75C9.5 0.92 8.83 0.25 8 0.25C7.17 0.25 6.5 0.92 6.5 1.75V2.43C3.63 3.11 2 5.67 2 8.75V13.75L0 15.75V16.75H16V15.75L14 13.75Z"
            fill="white"
          />
        )}
    </svg>
  );
}

export function TrendingFlat() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m17.5 16.15-1.075-1.05 2.35-2.35H3.25v-1.5h15.525L16.45 8.9l1.075-1.05L21.65 12Z" /></svg>
  );
}

export function Task() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 3.18L8.59 14.6L4.35 10.36L5.76 8.95L8.59 11.78L18.59 1.78L20 3.18ZM17.79 8.22C17.92 8.79 18 9.39 18 10C18 14.42 14.42 18 10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C11.58 2 13.04 2.46 14.28 3.25L15.72 1.81C14.1 0.67 12.13 0 10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 8.81 19.78 7.67 19.4 6.61L17.79 8.22Z" fill="white" />
    </svg>
  );
}

export function Gear({ className }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M17.14 10.9404C17.18 10.6404 17.2 10.3304 17.2 10.0004C17.2 9.68039 17.18 9.36039 17.13 9.06039L19.16 7.48039C19.34 7.34039 19.39 7.07039 19.28 6.87039L17.36 3.55039C17.24 3.33039 16.99 3.26039 16.77 3.33039L14.38 4.29039C13.88 3.91039 13.35 3.59039 12.76 3.35039L12.4 0.81039C12.36 0.57039 12.16 0.400391 11.92 0.400391H8.07999C7.83999 0.400391 7.64999 0.57039 7.60999 0.81039L7.24999 3.35039C6.65999 3.59039 6.11999 3.92039 5.62999 4.29039L3.23999 3.33039C3.01999 3.25039 2.76999 3.33039 2.64999 3.55039L0.739988 6.87039C0.619988 7.08039 0.659988 7.34039 0.859988 7.48039L2.88999 9.06039C2.83999 9.36039 2.79999 9.69039 2.79999 10.0004C2.79999 10.3104 2.81999 10.6404 2.86999 10.9404L0.839988 12.5204C0.659988 12.6604 0.609988 12.9304 0.719988 13.1304L2.63999 16.4504C2.75999 16.6704 3.00999 16.7404 3.22999 16.6704L5.61999 15.7104C6.11999 16.0904 6.64999 16.4104 7.23999 16.6504L7.59999 19.1904C7.64999 19.4304 7.83999 19.6004 8.07999 19.6004H11.92C12.16 19.6004 12.36 19.4304 12.39 19.1904L12.75 16.6504C13.34 16.4104 13.88 16.0904 14.37 15.7104L16.76 16.6704C16.98 16.7504 17.23 16.6704 17.35 16.4504L19.27 13.1304C19.39 12.9104 19.34 12.6604 19.15 12.5204L17.14 10.9404ZM9.99999 13.6004C8.01999 13.6004 6.39999 11.9804 6.39999 10.0004C6.39999 8.02039 8.01999 6.40039 9.99999 6.40039C11.98 6.40039 13.6 8.02039 13.6 10.0004C13.6 11.9804 11.98 13.6004 9.99999 13.6004Z" fill="black" />
    </svg>
  );
}

export function NotAllowed({ className }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM2 10C2 5.6 5.6 2 10 2C11.8 2 13.5 2.6 14.9 3.7L3.7 14.9C2.6 13.5 2 11.8 2 10ZM10 18C8.2 18 6.5 17.4 5.1 16.3L16.3 5.1C17.4 6.5 18 8.2 18 10C18 14.4 14.4 18 10 18Z" fill="#002D6F" />
    </svg>
  );
}

export function FinancialsOutlined({ className }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z" fill="black" className={className} />
      <path d="M11.25 7.72H6.25V9.22H11.25V7.72Z" fill="black" />
      <path d="M18 15.75H13V17.25H18V15.75Z" fill="black" />
      <path d="M18 13.25H13V14.75H18V13.25Z" fill="black" />
      <path d="M8 18H9.5V16H11.5V14.5H9.5V12.5H8V14.5H6V16H8V18Z" fill="black" />
      <path d="M14.09 10.95L15.5 9.54L16.91 10.95L17.97 9.89L16.56 8.47L17.97 7.06L16.91 6L15.5 7.41L14.09 6L13.03 7.06L14.44 8.47L13.03 9.89L14.09 10.95Z" fill="black" />
    </svg>
  );
}

export function FinancialsFilled({ className }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM10.03 4.06L11.09 3L12.5 4.41L13.91 3L14.97 4.06L13.56 5.47L14.97 6.88L13.91 7.94L12.5 6.54L11.09 7.95L10.03 6.89L11.44 5.48L10.03 4.06ZM3.25 4.72H8.25V6.22H3.25V4.72ZM8.5 13H6.5V15H5V13H3V11.5H5V9.5H6.5V11.5H8.5V13ZM15 14.25H10V12.75H15V14.25ZM15 11.75H10V10.25H15V11.75Z" fill="black" />
    </svg>
  );
}

export function NotesOutlined({ className }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M21.99 4C21.99 2.9 21.1 2 20 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H18L22 22L21.99 4ZM20 4V17.17L18.83 16H4V4H20ZM6 12H18V14H6V12ZM6 9H18V11H6V9ZM6 6H18V8H6V6Z" fill="black" />
    </svg>
  );
}

export function NotesFilled({ className }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M19.99 2C19.99 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H16L20 20L19.99 2ZM16 12H4V10H16V12ZM16 9H4V7H16V9ZM16 6H4V4H16V6Z" fill="black" />
    </svg>

  );
}

export function StarFilled({ className }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M12 17.77L18.18 21.5L16.54 14.47L22 9.74L14.81 9.13L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77Z" fill="black" />
    </svg>
  );
}

export function StarOutlined({ className }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M20 7.74L12.81 7.12L10 0.5L7.19 7.13L0 7.74L5.46 12.47L3.82 19.5L10 15.77L16.18 19.5L14.55 12.47L20 7.74ZM10 13.9L6.24 16.17L7.24 11.89L3.92 9.01L8.3 8.63L10 4.6L11.71 8.64L16.09 9.02L12.77 11.9L13.77 16.18L10 13.9Z" fill="black" />
    </svg>
  );
}

export function Cancel({ className, onClick, fill = 'currentColor', filled = false }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="24"
      width="24"
      fill={fill}
      className={className}
      onClick={onClick}
    >
      {filled ? (
        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
      ) : (
        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z" />
      )}
    </svg>
  );
}

export function MultiFamily({ className }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M8 4L2 10V20H14V10L8 4ZM12 18H9V15H7V18H4V10.83L8 6.83L12 10.83V18ZM9 13H7V11H9V13ZM18 20V8.35L13.65 4H10.82L16 9.18V20H18ZM22 20V6.69L19.31 4H16.48L20 7.52V20H22Z" fill="black" />
    </svg>
  );
}

export function DocumentsOutlined({ className, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 -960 960 960"
      width="20"
      className={className}
      onClick={onClick}
    >
      <path d="M319-250h322v-60H319v60Zm0-170h322v-60H319v60ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554v-186H220v680h520v-494H551ZM220-820v186-186 680-680Z" />
    </svg>
  );
}

export function DocumentsFilled({ className, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 -960 960 960"
      width="20"
      className={className}
      onClick={onClick}
    >
      <path d="M319-250h322v-60H319v60Zm0-170h322v-60H319v60ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554h189L551-820v186Z" />
    </svg>
  );
}

export function ActivityOutlined({ className, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 -960 960 960"
      width="20"
      className={className}
      onClick={onClick}
    >
      <path d="M80-590v-190q0-24 18-42t42-18h680q24 0 42 18t18 42v190h-60v-190H140v190H80Zm60 350q-24 0-42-18t-18-42v-230h60v230h680v-230h60v230q0 24-18 42t-42 18H140ZM40-120v-60h880v60H40Zm440-420ZM80-530v-60h240q8.161 0 15.581 4Q343-582 347-574l55 112 131-231q4-8 11.5-11.5t15.619-3.5q8.119 0 15.5 3.5T587-693l50.553 103H880v60H619q-8.25 0-15.75-4T592-546l-34-69-131 229q-3.723 8-11.17 12t-15.639 4q-8.191 0-15.691-4T373-386l-72-144H80Z" />
    </svg>
  );
}

export function ActivityFilled({ className, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 -960 960 960"
      width="20"
      className={className}
      onClick={onClick}
    >
      <path d="M40-120v-60h880v60H40Zm100-120q-24 0-42-18t-18-42v-230h221l72 144q4 8 11.5 12t15.5 4q8 0 15.5-4t11.5-12l131-229 34 69q4 8 11.5 12t15.5 4h261v230q0 24-18 42t-42 18H140Zm262-222-55-112q-4-8-11.5-12t-15.5-4H80v-190q0-24 18-42t42-18h680q24 0 42 18t18 42v190H638l-51-103q-4-8-11.5-11.5T560-708q-8 0-15.5 3.5T533-693L402-462Z" />
    </svg>
  );
}

export function CompLocationMarker({ className, onClick, fillColor = '#FFFFFF' }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      className={className}
      onClick={onClick}
      style={{ fill: fillColor }}
    >
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5 7 13 7 13s7-8 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
    </svg>
  );
}

export function LocationIcon({ className, markerColor = '#0C4599', homeColor = '#fff' }) {
  return (
    <svg className={className}>
      <path d="M24 12c0 6.6-3.9 9.8-7.4 11.2-3.4 1.4-4 3.6-4.6 3.6-.5 0-1.4-2.4-4.2-3.6C3.6 21.6 0 18.6 0 12a12 12 0 0 1 24 0ZM13.2 28.8a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0Z" fill={markerColor} />
      <path d="m12.5 6.2 4 3.6c.2.1.3.3.3.6V16c0 .5-.4.8-.8.8H8a.8.8 0 0 1-.8-.8v-5.7c0-.3.1-.5.3-.6l4-3.6a.8.8 0 0 1 1 0Zm-2.1 10h3.2V13c0-.4-.4-.8-.8-.8h-1.6c-.4 0-.8.4-.8.8v3Z" fill={homeColor} />
    </svg>
  );
}

export function LocationIconWithText({ markerColor = '#0C4599', homeColor = '#fff', text, textClassName }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M24 12c0 6.6-3.9 9.8-7.4 11.2-3.4 1.4-4 3.6-4.6 3.6-.5 0-1.4-2.4-4.2-3.6C3.6 21.6 0 18.6 0 12a12 12 0 0 1 24 0ZM13.2 28.8a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0Z" fill={markerColor} />
        <path d="m12.5 6.2 4 3.6c.2.1.3.3.3.6V16c0 .5-.4.8-.8.8H8a.8.8 0 0 1-.8-.8v-5.7c0-.3.1-.5.3-.6l4-3.6a.8.8 0 0 1 1 0Zm-2.1 10h3.2V13c0-.4-.4-.8-.8-.8h-1.6c-.4 0-.8.4-.8.8v3Z" fill={homeColor} />
      </svg>
      {text && <span className={textClassName} style={{ marginLeft: '10px' }}>{text}</span>}
    </div>
  );
}

export function SecurityGateIcon() {
  return (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
      <path d="M41 25v462h46V71h31.3c-9-3.83-15.3-12.74-15.3-23s6.3-19.17 15.3-23H41zm96.7 0c9 3.83 15.3 12.74 15.3 23s-6.3 19.17-15.3 23h36.4c-4.5-6.57-7.1-14.49-7.1-23s2.6-16.43 7.1-23h-36.4zm70.3 0c-12.8 0-23 10.19-23 23s10.2 23 23 23 23-10.19 23-23-10.2-23-23-23zm33.9 0c4.5 6.57 7.1 14.49 7.1 23s-2.6 16.43-7.1 23h36.4c-9-3.83-15.3-12.74-15.3-23s6.3-19.17 15.3-23h-36.4zm55.8 0c9 3.83 15.3 12.74 15.3 23s-6.3 19.17-15.3 23H329v416h46V25h-77.3zm173.9 32.64l-32 32 12.8 12.76 32-32.04-12.8-12.72zM393 121v46h38.9c5.1 0 5.1-1.2 6-3 1-1.9 1.1-4 1.1-4v-32s-.1-2.1-1.1-4c-.9-1.8-.9-3-5.9-3h-39zm69 14v18h32v-18h-32zm-257.8 13.3c-17 2.2-32.5 22.1-32.5 48 0 14.2 5.1 26.8 12.3 35.4l8.5 10.3-13.3 2.5c-9.3 1.8-16.1 6.9-22 14.9-5.9 8-10.3 19.3-13.5 32.3-5.8 23.3-7 52.1-7.2 77.4h31.4l8.5 114.9c21.8 4.8 44.9 4.5 65.9 0l7.5-114.9h29.7c0-25.6-.4-54.7-5.5-78.4-2.9-12.9-7.2-24.2-13.1-32.1-6-8.1-13.3-13-23.8-14.7l-13.6-2 8.8-10.8c6.6-8.6 11.2-21 11.2-34.8 0-27.5-17.1-48-35.8-48h-3.5zm248.2 37.3l-12.8 12.8 32 32 12.8-12.8-32-32z" />
    </svg>
  );
}

export function RealEstateIcon() {
  return (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M21 6.5V14h-2V7.5L14 4 9 7.5V9H7V6.5l7-5 7 5zm-5.5.5h-1v1h1V7zm-2 0h-1v1h1V7zm2 2h-1v1h1V9zm-2 0h-1v1h1V9zm5.5 7h-2c0-1.2-.75-2.28-1.87-2.7L8.97 11H1v11h6v-1.44l7 1.94 8-2.5v-1c0-1.66-1.34-3-3-3zM3 20v-7h2v7H3zm10.97.41L7 18.48V13h1.61l5.82 2.17c.34.13.57.46.57.83 0 0-1.99-.05-2.3-.15l-2.38-.79-.63 1.9 2.38.79c.51.17 1.04.26 1.58.26H19c.39 0 .74.23.9.56l-5.93 1.84z" />
    </svg>
  );
}

export function UploadIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q25-92 100-149t170-57q117 0 198.5 81.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H520q-33 0-56.5-23.5T440-240v-206l-64 62-56-56 160-160 160 160-56 56-64-62v206h220q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h100v80H260Zm220-280Z" />
    </svg>
  );
}

export function InformationIcon({ className, onClick }) {
  return (
    <svg className={className || 'fill-current w-4'} onClick={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
    </svg>
  );
}

export function DashboardIcon({ filled, fill = '#FFFFFF' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill={fill}>
      {filled ? <path d="M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Z" />
        : <path d="M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Zm80-400h160v-240H200v240Zm400 320h160v-240H600v240Zm0-480h160v-80H600v80ZM200-200h160v-80H200v80Zm160-320Zm240-160Zm0 240ZM360-280Z" />}
    </svg>
  );
}

export function AcquisitionIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >

      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M80 4452 l0 -589 463 -167 c437 -159 469 -173 599 -250 83 -48 138
-87 138 -97 0 -42 37 -116 76 -156 32 -32 58 -47 102 -59 l59 -17 17 -59 c23
-81 82 -141 163 -164 l58 -16 18 -58 c18 -64 88 -145 133 -156 22 -6 34 -24
16 -24 -21 0 -224 -145 -294 -210 -160 -148 -313 -396 -372 -602 -75 -263 -72
-558 10 -806 116 -348 364 -636 690 -800 84 -42 242 -96 349 -118 121 -25 383
-26 505 -1 371 74 692 290 892 602 254 394 287 895 87 1314 -71 151 -150 262
-264 377 -103 104 -241 203 -366 264 l-66 33 13 36 c7 20 13 43 14 52 0 11 13
19 37 24 101 19 179 96 198 197 6 33 11 37 46 43 96 16 185 115 196 217 l5 55
177 105 176 105 480 148 c264 81 508 156 543 167 l62 19 0 589 0 590 -250 0
-250 0 -560 -320 -561 -320 -62 0 c-47 0 -125 18 -322 74 -143 40 -282 76
-310 80 -27 4 -135 34 -240 67 -280 89 -299 93 -465 93 -143 0 -149 -1 -444
-67 l-300 -68 -346 231 -345 230 -253 0 -252 0 0 -588z m781 210 l326 -217
-99 -365 c-55 -201 -102 -367 -104 -369 -2 -2 -170 56 -374 130 l-370 134 0
453 0 452 148 0 147 0 326 -218z m4017 -233 l-3 -451 -450 -139 c-247 -76
-456 -139 -462 -139 -7 0 -102 133 -210 296 -158 236 -194 298 -183 305 8 4
238 136 510 293 l495 284 153 1 152 1 -2 -451z m-2693 112 c50 -11 115 -28
145 -39 l55 -19 -222 -112 c-248 -125 -281 -151 -310 -250 -50 -173 59 -340
232 -358 74 -8 108 6 307 124 196 116 233 128 300 96 40 -20 715 -623 735
-657 20 -34 15 -66 -16 -97 -21 -21 -39 -29 -64 -29 -40 0 -40 0 -312 228
l-180 152 -52 -58 c-29 -31 -52 -59 -52 -62 0 -3 98 -86 217 -185 136 -113
220 -190 226 -207 19 -55 -26 -108 -94 -108 -31 0 -60 22 -330 249 l-155 130
-52 -57 c-29 -31 -52 -59 -52 -62 0 -3 98 -86 217 -185 136 -113 220 -190 226
-207 18 -52 -26 -108 -87 -108 -26 0 -80 31 -119 68 -23 21 -28 34 -28 72 0
117 -71 215 -178 247 l-60 18 -16 58 c-23 81 -83 140 -164 163 l-59 17 -17 59
c-23 82 -82 141 -164 164 l-59 17 -17 59 c-41 146 -206 218 -347 150 -28 -13
-96 -73 -178 -157 l-134 -134 -93 56 c-58 34 -94 62 -94 72 0 22 205 770 213
778 4 3 75 21 159 40 450 102 510 108 673 74z m804 -221 c218 -62 296 -80 345
-80 l63 0 201 -303 c111 -166 202 -305 202 -309 0 -4 -61 -43 -135 -88 l-135
-81 -357 322 c-375 337 -409 362 -509 375 -93 11 -144 -7 -344 -127 -169 -101
-188 -110 -224 -106 -55 8 -88 43 -93 104 -8 76 8 88 281 225 132 67 259 127
281 134 80 25 131 18 424 -66z m-1178 -669 c20 -21 29 -39 29 -62 0 -29 -17
-50 -138 -171 -121 -121 -142 -138 -171 -138 -45 0 -91 46 -91 91 0 29 17 50
138 171 121 121 142 138 171 138 23 0 41 -9 62 -29z m240 -240 c20 -21 29 -39
29 -62 0 -29 -17 -50 -138 -171 -121 -121 -142 -138 -171 -138 -45 0 -91 46
-91 91 0 29 17 50 138 171 121 121 142 138 171 138 23 0 41 -9 62 -29z m240
-240 c20 -21 29 -39 29 -62 0 -29 -17 -50 -138 -171 -121 -121 -142 -138 -171
-138 -45 0 -91 46 -91 91 0 29 17 50 138 171 121 121 142 138 171 138 23 0 41
-9 62 -29z m240 -240 c20 -21 29 -39 29 -62 0 -29 -17 -50 -138 -171 -121
-121 -142 -138 -171 -138 -45 0 -91 46 -91 91 0 29 17 50 138 171 121 121 142
138 171 138 23 0 41 -9 62 -29z m153 -301 c38 -42 43 -63 25 -103 -26 -58
-102 -62 -160 -10 l-40 36 58 59 c31 32 63 58 69 58 6 0 28 -18 48 -40z m402
-114 c432 -213 691 -646 671 -1121 -14 -314 -131 -580 -351 -801 -171 -170
-371 -280 -606 -330 -106 -23 -352 -26 -455 -5 -375 74 -689 312 -859 648
-131 261 -161 581 -79 863 29 102 105 264 163 350 90 132 245 275 387 356 l76
43 39 -40 c22 -22 55 -47 75 -55 l35 -15 -72 -35 c-153 -73 -323 -219 -411
-354 -212 -321 -236 -707 -67 -1044 53 -107 88 -156 177 -249 126 -132 257
-216 426 -274 296 -101 652 -50 915 130 67 47 194 172 249 246 295 401 260
957 -84 1320 -117 123 -231 200 -388 262 -43 16 -71 33 -70 41 11 68 28 107
47 111 52 10 78 4 182 -47z m-371 -211 c174 -31 337 -117 465 -245 344 -343
344 -898 1 -1241 -343 -343 -898 -343 -1241 1 -345 344 -345 896 0 1240 206
206 487 295 775 245z"
        />
        <path d="M2480 2081 l0 -79 -47 -7 c-103 -15 -196 -84 -243 -180 -19 -41 -24
-66 -24 -135 -1 -78 2 -90 33 -148 38 -70 70 -101 145 -139 46 -24 65 -27 203
-32 132 -5 155 -9 179 -27 53 -39 69 -71 69 -134 0 -63 -16 -95 -69 -134 -25
-19 -42 -21 -166 -21 -124 0 -141 2 -166 21 -53 39 -68 70 -72 144 l-4 70 -81
0 -80 0 5 -84 c9 -135 65 -224 177 -281 26 -13 68 -26 94 -30 l47 -7 0 -79 0
-79 80 0 80 0 0 79 0 79 47 7 c53 8 125 41 170 79 16 14 45 53 64 88 31 58 34
70 34 148 0 78 -3 90 -34 148 -38 70 -70 101 -145 139 -46 24 -65 27 -203 32
-164 6 -180 12 -227 75 -29 39 -29 133 0 172 45 61 66 69 192 72 67 2 132 -1
153 -7 46 -14 96 -69 105 -116 l6 -35 80 0 80 0 -7 48 c-8 59 -59 155 -98 188
-45 38 -117 71 -170 79 l-47 7 0 79 0 79 -80 0 -80 0 0 -79z"
        />
        <path d="M446 2489 c-148 -198 -267 -362 -264 -365 3 -2 31 -24 64 -48 l58
-44 166 221 165 221 3 -837 2 -837 80 0 80 0 2 837 3 837 165 -221 166 -221
58 44 c33 24 61 46 64 48 5 6 -529 725 -538 725 -3 0 -126 -162 -274 -360z"
        />
        <path d="M4126 2489 c-148 -198 -267 -362 -264 -365 3 -2 31 -24 64 -48 l58
-44 166 221 165 221 3 -837 2 -837 80 0 80 0 2 837 3 837 165 -221 166 -221
58 44 c33 24 61 46 64 48 5 6 -529 725 -538 725 -3 0 -126 -162 -274 -360z"
        />
        <path d="M640 560 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z" />
        <path d="M4320 560 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z" />
      </g>
    </svg>

  );
}

export function OfferIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
    </svg>
  );
}

export function VisibilityIcon({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
      <path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" />
    </svg>
  );
}

export function VisibilityOffIcon({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
      <path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z" />
    </svg>
  );
}

export function DragIndicator({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" className={className}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </svg>
  );
}

export function SendEmailIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="#5f6368"
    >
      <path d="M480-440 160-640v400h360v80H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v280h-80v-200L480-440Zm0-80 320-200H160l320 200ZM760-40l-56-56 63-64H600v-80h167l-64-64 57-56 160 160L760-40ZM160-640v440-240 3-283 80Z" />
    </svg>
  );
}
