import { useDispatch, useSelector } from 'react-redux';
import { isNil, sortBy } from 'lodash';
import { setShowCreateDealModal } from 'actions/deal_navigation';
import { setActiveSaleCompSetId } from 'redux/saleCompsSlice';
import { useCopySaleCompSetMutation, useUpdateSaleCompSetMutation } from 'redux/apiSlice';
import Menu from 'components/shared/Menu';
import { ContentCopy, LockFilled, Pencil, Plus, StarFilled, StarOutlined, TrashOutline } from 'components/icons';
import { DEFAULT_COMP_SET_NAME } from 'components/constants';
import { formatDate } from 'components/utils';

export default function SaleCompSetSelector({ activeSaleCompSet, deal, property, saleCompSets, setShowDeleteModal, setShowEditModal, propertyManagementRecord }) {
  const dispatch = useDispatch();
  const { activeSaleCompSetId, selectedSaleCompIds } = useSelector(state => state.saleComps);
  const [copySaleCompSetMutation] = useCopySaleCompSetMutation();
  const [updateSaleCompSetMutation] = useUpdateSaleCompSetMutation();

  const activeSetIsPrimary = activeSaleCompSet?.primary;
  const activeSetIsLocked = isNil(activeSaleCompSet);

  const menuItemGroups = [];
  if (deal?.id || propertyManagementRecord?.id) {
    const compSetItems = [{
      text: 'Change comp set...',
      className: 'text-xs text-gray-500',
      labelOnly: true,
    }, ...(saleCompSets ? sortBy(saleCompSets, 'name') : []).map(scs => ({
      text: scs.name,
      subtext: `${scs.userName} · ${formatDate(scs.createdAt)}`,
      onClick: () => { dispatch(setActiveSaleCompSetId(scs.id)); },
      className: scs.id === activeSaleCompSet?.id ? 'bg-primary-focus' : '',
      iconLeft: scs.primary ? <StarFilled /> : <div />, // empty div to get consistent left padding
    })), {
      text: DEFAULT_COMP_SET_NAME,
      onClick: () => { dispatch(setActiveSaleCompSetId(null)); },
      className: activeSaleCompSet?.id ? '' : 'bg-primary-focus',
      iconLeft: <LockFilled className="w-5 h-5 opacity-40" />,
    }];
    menuItemGroups.push(compSetItems);

    const duplicateCompSet = async () => {
      const copiedSaleCompSet = await copySaleCompSetMutation({
        dealId: deal?.id,
        id: activeSaleCompSetId,
        propertyId: property.id,
        propertyManagementRecordId: propertyManagementRecord?.id,
        selectedIds: selectedSaleCompIds,
      }).unwrap();
      dispatch(setActiveSaleCompSetId(copiedSaleCompSet.data.attributes.id));
    };

    const compSetActions = [{
      text: 'Comp Set Actions',
      className: 'text-xs text-gray-500',
      labelOnly: true,
    }, {
      text: 'Edit Comp Set',
      onClick: () => { setShowEditModal(true); },
      iconLeft: <Pencil />,
      disabled: !activeSaleCompSet,
    }, {
      text: 'Duplicate Comp Set',
      onClick: duplicateCompSet,
      iconLeft: <ContentCopy />,
    }, {
      text: 'Make Primary Comp Set',
      iconLeft: activeSetIsPrimary ? <StarFilled className="fill-gray-200 opacity-20" /> : <StarOutlined />,
      disabled: activeSetIsPrimary || !activeSaleCompSet,
      onClick: () => { updateSaleCompSetMutation({ id: activeSaleCompSet?.id, primary: true }); },
    }];
    menuItemGroups.push(compSetActions);

    menuItemGroups.push([{
      text: 'Delete Comp Set',
      onClick: () => { setShowDeleteModal(true); },
      iconLeft: <TrashOutline className={activeSetIsPrimary ? 'fill-gray-200 opacity-20' : ''} />,
      disabled: activeSetIsPrimary || !activeSaleCompSet,
    }]);
  } else {
    menuItemGroups.push([{
      text: 'Create a deal to edit comp sets',
      className: 'text-xs text-gray-500',
      labelOnly: true,
    }]);

    menuItemGroups.push([{
      text: 'Create Deal',
      onClick: () => dispatch(setShowCreateDealModal(true)),
      iconLeft: <Plus className="w-4" />,
    }]);
  }

  return (
    <Menu
      className="w-80"
      label={activeSaleCompSet?.name || DEFAULT_COMP_SET_NAME}
      itemGroups={menuItemGroups}
      icon={activeSetIsPrimary ? <StarFilled className="mr-2 w-6 opacity-40" /> : (activeSetIsLocked ? <LockFilled className="mr-2 w-5 h-5 opacity-40" /> : null)}
      alignLeft
    />
  );
}
